export const languageMessages = (language:string, key: string) => {
    const messages: any = {
      en: {
        required: 'Required field',
        invalidOption: 'Invalid option',
        emailInvalid: 'Invalid email',
        phoneInvalid: 'Invalid phone number',
        minCaracters: 'Insufficient characters',
        maxCaracters: 'Characters exceeded',
        invalidCaracters: 'Invalid characters',
        selectOption: 'Select',
      },
      es: {
        required: 'Campo requerido',
        invalidOption: 'Opción inválida',
        emailInvalid: 'Email inválido',
        phoneInvalid: 'Número de teléfono inválido',
        minCaracters: 'Caracteres insuficientes',
        maxCaracters: 'Caracteres excedidos',
        invalidCaracters: 'Caracteres no permitidos',
        selectOption: 'Seleccione',
      },
      pt: {
        required: 'Campo obrigatório',
        invalidOption: 'Opção inválida',
        emailInvalid: 'Email inválido',
        phoneInvalid: 'Telefone inválido',
        minCaracters: 'Caracteres insuficientes',
        maxCaracters: 'Caracteres excedidos',
        invalidCaracters: 'Invalid characters',
        selectOption: 'Selecione',
      },
    }
    return messages[language][key];
  };