import React, { useEffect, useState } from "react";
import '../styles/ScreenConfirmation.css'
import { languageMessages } from "traductor/PaymentConfirmation";
import HTMLRenderer from "components/GlobalHTMLParser";
import Dialog from "components/OleDialog";

const PaymentConfirmation = ({language, tipo, field1}: {language:string, tipo: string, field1: string}) => {
    const [open, setOpen] = useState(tipo === 'auto');

    useEffect(() => {
        setOpen(tipo === 'auto');
        // eslint-disable-next-line
    }, [tipo]);
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <div className='app-screen-confirmation'>
            {open && (
            <Dialog
                title={languageMessages[language].flywire.title}
                img="icon_information.svg"
                subtitle={languageMessages[language].flywire.subtitle}
                buttonText=""
                classname="dialog__succes_flywire"
                showClose={true}
                onClose={handleClose}
            />
            )}
            <div className='logo'>
                <img src="logo_ole_transparent.svg" alt="logo"/>
            </div>
            <div className="img-tablet">
                <img src="people-tablet.png" alt="img-mobile"/>
            </div>
            <div className="img-mobile">
                <img src="people-mobile.png" alt="img-mobile"/>
            </div>
            <div className="title">{languageMessages[language].title[tipo]}</div>
            <div className="subtitle"><HTMLRenderer html={languageMessages[language].subtitle[tipo].replace('{param1}', field1)}/></div>
            <div className="steps">
                <img src={languageMessages[language].step[tipo]} alt="steps"/>
            </div>
            <div className="people">
                <img src="ASA-people.png" alt="people"/>
            </div>
            <div className="flywire">
                {tipo === 'auto' && <HTMLRenderer html={languageMessages[language].flywire.disclaimer}/>}
            </div>
        </div>
    );
};

export default PaymentConfirmation;