import rum from './awsRumConfig';

function createConsoleOverride(level: string, originalMethod: Function) {
  return (...args: any[]) => {
    const step = args.length > 0 ? args[0] : '';
    const message = args.length > 1 ? args[1] : '';
    rum.recordEvent(level.toUpperCase(), {
      name: `Console${level.charAt(0).toUpperCase() + level.slice(1)}`,
      step,
      attributes: {
        message,
        level
      },
    });
    originalMethod.apply(console, args);
  };
}

export function captureConsoleLogs() {
  console.log = createConsoleOverride('info', console.log);
  console.error = createConsoleOverride('error', console.error);
  console.warn = createConsoleOverride('warning', console.warn);
}

