import React from "react";
import "./styles/skeleton.css";

const Skeleton = () => {
  return (
    <div className="skeleton-container">
      <div className="skeleton-header">
        <div className="logo">
            <div></div>
        </div>
        <div className="countries">
            <div></div>
        </div>
      </div>
      <div className="skeleton-body">
        <div className="skeleton-title"></div>
        <div className="skeleton-form">
            <div className="skeleton-row">
                <div className='description'></div>
            </div>
            <div className="skeleton-row">
                <div className='amount'></div>
            </div>
            <div className="skeleton-row">
                <div className='flag'></div>
            </div>
            <div className="skeleton-row">
                <div className='button'></div>
            </div>
        </div>
      </div>
      <div className="loading-container">
        <img src="load.gif" alt="Cargando..." width="48" height="48" />
        <p>Cargando...</p>
      </div>
    </div>
  );
};

export default Skeleton;