export const countries = [
  {
    name: "Anguila",
    code: "AI",
    dialCode: "+1-264",
    flag: "flag_anguilla.png",
    states: [
      {
          "name": "Anguilla",
          "code": "AI-01",
          "cities": []
      }
    ]
  },
  {
    name: "Antigua y Barbuda",
    code: "AG",
    dialCode: "+1-268",
    flag: "flag_antigua-and-barbuda.png",
    states: [
        {
            "name": "Saint George",
            "code": "AG-03",
            "cities": [
            ]
        },
        {
            "name": "Saint John",
            "code": "AG-04",
            "cities": [
              {"name": "Saint John", "code": "AG-04-TMP666"},
            ]
        },
        {
            "name": "Saint Mary",
            "code": "AG-05",
            "cities": [
            ]
        },
        {
            "name": "Saint Paul",
            "code": "AG-06",
            "cities": [
            ]
        },
        {
            "name": "Saint Peter",
            "code": "AG-07",
            "cities": [
            ]
        },
        {
            "name": "Saint Philip",
            "code": "AG-08",
            "cities": [
            ]
        },
        {
            "name": "Barbuda",
            "code": "AG-10",
            "cities": [
            ]
        },
        {
            "name": "Redonda",
            "code": "AG-11",
            "cities": [
            ]
        }
    ]
  },
  {
    name: "Argentina",
    code: "AR",
    dialCode: "+54",
    flag: "flag_argentina.png",
    states: [
        {
            "name": "Buenos Aires",
            "code": "AR-B",
            "cities": [
              {"name":"28 DE SEPTIEMBRE","code":"AR-B-TMP669"},
              {"name":"6 DE SEPTIEMBRE","code":"AR-B-TMP670"},
              {"name":"ACASSUSO","code":"AR-B-ACS"},
              {"name":"ACEVEDO","code":"AR-B-AVD"},
              {"name":"ADROGUE","code":"AR-B-TMP678"},
              {"name":"ALBERTI","code":"AR-B-ALI"},
              {"name":"ANCÓN","code":"AR-B-ANC"},
              {"name":"ARRECIFES","code":"AR-B-ACF"},
              {"name":"ATUCHA","code":"AR-B-ATU"},
              {"name":"AZUL","code":"AR-B-AZU"},
              {"name":"BAHÍA BLANCA","code":"AR-B-BHI"},
              {"name":"BAHIA SAN BLAS","code":"AR-B-BSB"},
              {"name":"BAJO HONDO","code":"AR-B-BHO"},
              {"name":"BALCARCE","code":"AR-B-BCA"},
              {"name":"BANFIELD","code":"AR-B-BAD"},
              {"name":"BARADERO","code":"AR-B-BAR"},
              {"name":"BARRANCO","code":"ARG-AR-B-BAR"},
              {"name":"BARRIO BATAN","code":"AR-B-BTN"},
              {"name":"BECCAR","code":"AR-B-BEC"},
              {"name":"BELÉN DE ESCOBAR","code":"AR-B-BDE"},
              {"name":"BELLA VISTA","code":"AR-B-BEL"},
              {"name":"BENAVIDEZ","code":"AR-B-BVZ"},
              {"name":"BENITO JUAREZ","code":"AR-B-BJR"},
              {"name":"BERAZATEGUI","code":"AR-B-BER"},
              {"name":"BERNAL","code":"AR-B-BRN"},
              {"name":"BOULOGNE","code":"AR-B-BOU"},
              {"name":"BRAGADO","code":"AR-B-BGO"},
              {"name":"BRANDSEN","code":"AR-B-BRD"},
              {"name":"BURZACO","code":"AR-B-BUR"},
              {"name":"CABALLITO","code":"AR-B-CAB"},
              {"name":"CAMPANA","code":"AR-B-CMP"},
              {"name":"CANNING","code":"AR-B-CNN"},
              {"name":"CANUELAS","code":"AR-B-CAE"},
              {"name":"CAPILLA DEL SEÑOR","code":"AR-B-CDS"},
              {"name":"CAPITÁN SARMIENTO","code":"AR-B-CSN"},
              {"name":"CARLOS CASARES","code":"AR-B-CCB"},
              {"name":"CARMEN DE ARECO","code":"AR-B-CAN"},
              {"name":"CARMEN DE PATAGONES","code":"AR-B-CPG"},
              {"name":"CASBAS","code":"AR-B-TMP764"},
              {"name":"CASEROS","code":"AR-B-ORE"},
              {"name":"CASTELAR","code":"AR-B-CTL"},
              {"name":"CENTENARIO","code":"AR-B-CEN"},
              {"name":"CHACABUCO","code":"AR-B-CCO"},
              {"name":"CHASCOMUS","code":"AR-B-CHS"},
              {"name":"CHIVILCOY","code":"AR-B-CVY"},
              {"name":"CITY BELL","code":"AR-B-CYB"},
              {"name":"CIUDAD EVITA","code":"AR-B-EVA"},
              {"name":"CIUDADELA","code":"AR-B-CIU"},
              {"name":"CORONEL SUAREZ","code":"AR-B-CSZ"},
              {"name":"CUATREROS/BAHIA BLANCA","code":"AR-B-CUA"},
              {"name":"DAIREAUX","code":"AR-B-DIX"},
              {"name":"DEL VISO","code":"AR-B-ADV"},
              {"name":"DOCK SUR","code":"AR-B-DSU"},
              {"name":"DON TORCUATO","code":"AR-B-DCU"},
              {"name":"EL PALOMAR","code":"AR-B-EPA"},
              {"name":"EL TALAR DE PACHECO","code":"AR-B-ETP"},
              {"name":"ENSENADA","code":"AR-B-EEA"},
              {"name":"ESPELETA","code":"AR-B-EPL"},
              {"name":"ESTEBAN ECHEVERRÍA","code":"AR-B-ESE"},
              {"name":"EZEIZA","code":"AR-B-EZA"},
              {"name":"FLORENCIO VARELA","code":"AR-B-FVB"},
              {"name":"FLORIDA","code":"AR-B-FLO"},
              {"name":"GARÍN","code":"AR-B-GAR"},
              {"name":"GENERAL BELGRANO","code":"AR-B-GBE"},
              {"name":"GENERAL LAVALLE","code":"AR-B-GLV"},
              {"name":"GENERAL MADARIAGA","code":"AR-B-GMA"},
              {"name":"GENERAL PACHECO","code":"AR-B-GPC"},
              {"name":"GENERAL RODRÍGUEZ","code":"AR-B-GRD"},
              {"name":"GENERAL VILLEGAS","code":"AR-B-VGS"},
              {"name":"GERLI","code":"AR-B-JGR"},
              {"name":"GONZÁLEZ CATÁN","code":"AR-B-GOC"},
              {"name":"GOWLAND","code":"AR-B-GOD"},
              {"name":"GRAND BOURG","code":"AR-B-GBG"},
              {"name":"GUERRICO","code":"AR-B-GUR"},
              {"name":"HAEDO","code":"AR-B-HAE"},
              {"name":"HERRERA VEGAS","code":"AR-B-HVB"},
              {"name":"HURLINGHAM","code":"AR-B-HUR"},
              {"name":"INGENIERO ALLAN","code":"AR-B-IAL"},
              {"name":"INGENIERO BUITRAGO","code":"AR-B-BUI"},
              {"name":"INGENIERO J. ALLAN","code":"AR-B-JJL"},
              {"name":"INGENIERO MASCHWITZ","code":"AR-B-IMZ"},
              {"name":"INGENIERO WHITE/BAHIA BLANCA","code":"AR-B-INW"},
              {"name":"IRENEO PORTELA","code":"AR-B-ICA"},
              {"name":"ITUZAINGO","code":"AR-B-ITZ"},
              {"name":"JAUREGUI","code":"AR-B-WNL"},
              {"name":"JORGE NEWBURY APT/BUENOS AIRES","code":"AR-B-AEP"},
              {"name":"JOSÉ C. PAZ","code":"AR-B-JPZ"},
              {"name":"JOSE LEON SUAREZ","code":"AR-B-JLS"},
              {"name":"JUNÍN","code":"AR-B-JNI"},
              {"name":"LA PLATA","code":"AR-B-LPG"},
              {"name":"LA REJA","code":"AR-B-LAR"},
              {"name":"LAFERRERE","code":"AR-B-LFR"},
              {"name":"LANÚS","code":"AR-B-LNS"},
              {"name":"LAS PALMAS","code":"AR-B-LPS"},
              {"name":"LEZAMA (MANUEL J. COBO)","code":"AR-B-LMC"},
              {"name":"LIMA","code":"AR-B-LIM"},
              {"name":"LLAVALLOL","code":"AR-B-LLO"},
              {"name":"LOBERIA","code":"AR-B-LBA"},
              {"name":"LOMA HERMOSA","code":"AR-B-LHR"},
              {"name":"LOMAS DE ZAMORA","code":"AR-B-LMZ"},
              {"name":"LOMAS DEL MIRADOR","code":"AR-B-LDM"},
              {"name":"LOS POLVORINES","code":"AR-B-JLF"},
              {"name":"LUIS GUILLON","code":"AR-B-LGN"},
              {"name":"LUJÁN","code":"AR-B-LUJ"},
              {"name":"MAGDALENA","code":"AR-B-MGD"},
              {"name":"MANUEL B. GONNET","code":"AR-B-JMG"},
              {"name":"MAR DEL PLATA","code":"AR-B-MDQ"},
              {"name":"MARIANO J. HAEDO","code":"AR-B-MJH"},
              {"name":"MARTIN CORONADO","code":"AR-B-MRT"},
              {"name":"MARTÍNEZ","code":"AR-B-MAR"},
              {"name":"MATADEROS","code":"AR-B-MDE"},
              {"name":"MATHEU","code":"AR-B-MTH"},
              {"name":"MELCHOR ROMERO","code":"AR-B-MRO"},
              {"name":"MINISTRO PISTARINI APT/BUENOS AIRES","code":"AR-B-EZE"},
              {"name":"MIRAMAR","code":"AR-B-MJR"},
              {"name":"MONTE CHINGOLO","code":"AR-B-MNT"},
              {"name":"MONTE GRAND","code":"AR-B-AMG"},
              {"name":"MOQUEHUÁ","code":"AR-B-MOQ"},
              {"name":"MORENO","code":"AR-B-MOR"},
              {"name":"MUNRO","code":"AR-B-MUN"},
              {"name":"NAVARRO","code":"AR-B-JNR"},
              {"name":"NECOCHEA","code":"AR-B-NEC"},
              {"name":"NUEVE DE JULIO","code":"AR-B-EJO"},
              {"name":"OLAVARRÍA","code":"AR-B-OVI"},
              {"name":"OLIVOS","code":"AR-B-QLV"},
              {"name":"OPEN DOOR","code":"AR-B-ODR"},
              {"name":"PABLO PODESTÁ","code":"AR-B-PP9"},
              {"name":"PEDRO LURO","code":"AR-B-PLU"},
              {"name":"PEHUAJÓ","code":"AR-B-PEH"},
              {"name":"PÉREZ MILLÁN","code":"AR-B-PML"},
              {"name":"PERGAMINO","code":"AR-B-PGO"},
              {"name":"PIEDRITAS","code":"AR-B-PDT"},
              {"name":"PIGUE","code":"AR-B-PGU"},
              {"name":"PILAR","code":"AR-B-PLR"},
              {"name":"PINAMAR","code":"AR-B-QPQ"},
              {"name":"PLATANOS","code":"AR-B-PTN"},
              {"name":"PUERTO BELGRANO/BAHIA BLANCA","code":"AR-B-PBG"},
              {"name":"PUERTO GALVAN/BAHIA BLANCA","code":"AR-B-PGV"},
              {"name":"PUERTO INGENIERO M. ROCCA","code":"AR-B-ROC"},
              {"name":"PUERTO MADERO","code":"AR-B-PMO"},
              {"name":"PUERTO NACIONAL/BAHIA BLANCA","code":"AR-B-PNL"},
              {"name":"PUERTO ROSALES/BAHIA BLANCA","code":"AR-B-PRS"},
              {"name":"PUNTA MEDANOS","code":"AR-B-PME"},
              {"name":"QUEQUÉN","code":"AR-B-QQN"},
              {"name":"QUILMES","code":"AR-B-QUI"},
              {"name":"RAMALLO","code":"AR-B-RAM"},
              {"name":"RAMOS MEJIA","code":"AR-B-RMJ"},
              {"name":"RANELAGH","code":"AR-B-TPV"},
              {"name":"RECALDE","code":"AR-B-RLA"},
              {"name":"REMEDIOS DE ESCALADA","code":"AR-B-RDE"},
              {"name":"RINGUELET","code":"AR-B-RNG"},
              {"name":"ROJAS","code":"AR-B-ROJ"},
              {"name":"SÁENZ PEÑA","code":"AR-B-SZQ"},
              {"name":"SALADILLO","code":"AR-B-SLL"},
              {"name":"SALTO","code":"AR-B-SAL"},
              {"name":"SAN ANDRES","code":"AR-B-SNN"},
              {"name":"SAN ANDRÉS DE GILES","code":"AR-B-SAG"},
              {"name":"SAN ANTONIO DE ARECO","code":"AR-B-SAO"},
              {"name":"SAN ANTONIO DE PADUA","code":"AR-B-SAP"},
              {"name":"SAN FERNANDO","code":"AR-B-SFE"},
              {"name":"SAN FRANCISCO SOLANO","code":"AR-B-SFS"},
              {"name":"SAN ISIDRO","code":"AR-B-SIS"},
              {"name":"SAN JUSTO","code":"AR-B-SJO"},
              {"name":"SAN MARTIN","code":"AR-B-SAT"},
              {"name":"SAN MIGUEL","code":"AR-B-SMG"},
              {"name":"SAN MIGUEL DEL MONTE","code":"AR-B-SMM"},
              {"name":"SAN NICOLÁS DE LOS ARROYOS","code":"AR-B-SNS"},
              {"name":"SAN PEDRO","code":"AR-B-SNP"},
              {"name":"SANTA TERESITA","code":"AR-B-SST"},
              {"name":"SARANDI","code":"AR-B-SAR"},
              {"name":"SUIPACHA","code":"AR-B-SUI"},
              {"name":"TABLADA","code":"AR-B-TAB"},
              {"name":"TANDIL","code":"AR-B-TDL"},
              {"name":"TAPALQUE","code":"AR-B-UKD"},
              {"name":"TAPIALES","code":"AR-B-TPL"},
              {"name":"TEMPERLEY","code":"AR-B-TEM"},
              {"name":"TIGRE","code":"AR-B-TGR"},
              {"name":"TORNQUIST","code":"AR-B-TOR"},
              {"name":"TORTUGUITAS","code":"AR-B-TOT"},
              {"name":"TRES ARROYOS","code":"AR-B-OYO"},
              {"name":"TRISTAN SUAREZ","code":"AR-B-TRS"},
              {"name":"VALENTIN ALSINA","code":"AR-B-VAA"},
              {"name":"VEDIA","code":"AR-B-VED"},
              {"name":"VICENTE LÓPEZ","code":"AR-B-VLO"},
              {"name":"VICTORIA","code":"AR-B-VIA"},
              {"name":"VILLA ADELINA","code":"AR-B-VAD"},
              {"name":"VILLA BALLESTER","code":"AR-B-VBA"},
              {"name":"VILLA BOSCH","code":"AR-B-VBS"},
              {"name":"VILLA CELINA","code":"AR-B-VCL"},
              {"name":"VILLA DOMINICO","code":"AR-B-VLD"},
              {"name":"VILLA GALICIA","code":"AR-B-VGB"},
              {"name":"VILLA GESELL","code":"AR-B-VLG"},
              {"name":"VILLA INSUPERABLE","code":"AR-B-VIN"},
              {"name":"VILLA LUZURIAGA","code":"AR-B-VLZ"},
              {"name":"VILLA LYNCH","code":"AR-B-VLY"},
              {"name":"VILLA MADERO","code":"AR-B-VIM"},
              {"name":"VILLA MAIPU","code":"AR-B-VMP"},
              {"name":"VILLA ROSA","code":"AR-B-VLR"},
              {"name":"VILLA TESEI","code":"AR-B-VTS"},
              {"name":"VILLA ZAGALA","code":"AR-B-ZAG"},
              {"name":"VILLALONGA","code":"AR-B-VLA"},
              {"name":"VIRREY DEL PINO","code":"AR-B-VDP"},
              {"name":"WILDE","code":"AR-B-WIL"},
              {"name":"ZÁRATE","code":"AR-B-ZAE"},
            ]
        },
        {
            "name": "Catamarca",
            "code": "AR-K",
            "cities": [
              {"name":"ANDALGALÁ","code":"AR-K-AND"},
              {"name":"CATAMARCA","code":"AR-K-CTC"},
              {"name":"EL BOLSÓN","code":"AR-K-ELB"},
              {"name":"ISIDRO CASANOVA","code":"AR-K-ICV"},
              {"name":"LAVALLE","code":"AR-K-LAV"},
              {"name":"MEDANITOS","code":"AR-K-MED"},
              {"name":"POMAN","code":"AR-K-POM"},
              {"name":"SAN FERNANDO DEL VALLE DE CATAMARCA","code":"AR-K-SFV"},
            ]
        },
        {
            "name": "Chaco",
            "code": "AR-H",
            "cities": [
              {"name":"BARRANQUERAS","code":"AR-H-BQS"},
              {"name":"CAMPO LARGO","code":"AR-H-CLO"},
              {"name":"CHARATA","code":"AR-H-CNT"},
              {"name":"LA ESCONDIDA","code":"AR-H-LES"},
              {"name":"PRESIDENTE ROQUE SÁENZ PEÑA","code":"AR-H-PRQ"},
              {"name":"PUERTO TIROL","code":"AR-H-PTC"},
              {"name":"RESISTENCIA","code":"AR-H-RES"},
            ]
        },
        {
            "name": "Chubut",
            "code": "AR-U",
            "cities": [
              {"name":"ALDEA ESCOLAR","code":"AR-U-AEA"},
              {"name":"ALTO RÍO SENGUERR","code":"AR-U-ARR"},
              {"name":"COLONIA SARMIENTO","code":"AR-U-OLN"},
              {"name":"COMODORO RIVADAVIA","code":"AR-U-CRD"},
              {"name":"EL MAITÉN","code":"AR-U-EMX"},
              {"name":"ESQUEL","code":"AR-U-EQS"},
              {"name":"JOSÉ DE SAN MARTÍN","code":"AR-U-JSM"},
              {"name":"PAMPA DEL CASTILLO","code":"AR-U-PDC"},
              {"name":"PUERTO MADRYN","code":"AR-U-PMY"},
              {"name":"RAWSON","code":"AR-U-RWO"},
              {"name":"RÍO MAYO","code":"AR-U-ROY"},
              {"name":"TRELEW","code":"AR-U-REL"},
            ]
        },
        {
            "name": "Córdoba",
            "code": "AR-X",
            "cities": [
              {"name":"ADELIA MARÍA","code":"AR-X-AMA"},
              {"name":"ALCIRA","code":"AR-X-TMP1312"},
              {"name":"ALMAFUERTE","code":"AR-X-ALE"},
              {"name":"ALTA CORDOBA","code":"AR-X-TMP1317"},
              {"name":"ALTA GRACIA","code":"AR-X-TMP1318"},
              {"name":"ARGUELLO","code":"AR-X-TMP1322"},
              {"name":"ARIAS","code":"AR-X-ARI"},
              {"name":"ARROYITO","code":"AR-X-AYO"},
              {"name":"ATAHONA","code":"AR-X-TMP1328"},
              {"name":"BALLESTEROS","code":"AR-X-BES"},
              {"name":"BARRIO MARQUEZ DE SOBREMONTE","code":"AR-X-BMS"},
              {"name":"BELL VILLE","code":"AR-X-BVL"},
              {"name":"BENGOLEA","code":"AR-X-BEA"},
              {"name":"CALCHIN","code":"AR-X-TMP1340"},
              {"name":"CALETA CÓRDOBA","code":"AR-X-CLC"},
              {"name":"CANALS","code":"AR-X-CNL"},
              {"name":"CARNERILLO","code":"AR-X-CAO"},
              {"name":"COLAZO","code":"AR-X-CZO"},
              {"name":"COLONIA CAROYA","code":"AR-X-CRY"},
              {"name":"COLONIA TIROLESA","code":"AR-X-TMP1363"},
              {"name":"CÓRDOBA","code":"AR-X-COR"},
              {"name":"CRUZ ALTA","code":"AR-X-CAZ"},
              {"name":"CRUZ DEL EJE","code":"AR-X-CDE"},
              {"name":"ETRURIA","code":"AR-X-JTR"},
              {"name":"FERREYRA","code":"AR-X-FRR"},
              {"name":"FREYRE","code":"AR-X-FRY"},
              {"name":"FRONTERA","code":"AR-X-FRT"},
              {"name":"GENERAL CABRERA","code":"AR-X-GCA"},
              {"name":"GENERAL DEHEZA","code":"AR-X-GDL"},
              {"name":"HERNANDO","code":"AR-X-HER"},
              {"name":"JUÁREZ CELMAN","code":"AR-X-JUA"},
              {"name":"JUSTINIANO POSSE","code":"AR-X-JPX"},
              {"name":"LA CALERA","code":"AR-X-LCR"},
              {"name":"LA CARLOTA","code":"AR-X-LCT"},
              {"name":"LA CUMBRE","code":"AR-X-LCM"},
              {"name":"LA FALDA","code":"AR-X-LFL"},
              {"name":"LAS HIGUERAS","code":"AR-X-LHG"},
              {"name":"LAS VARILLAS","code":"AR-X-LVL"},
              {"name":"LOS BOULEVARES","code":"AR-X-LBL"},
              {"name":"LUQUE","code":"AR-X-VLU"},
              {"name":"MARCOS JUAREZ","code":"AR-X-JMJ"},
              {"name":"MONTE CRISTO","code":"AR-X-MCR"},
              {"name":"MONTE MAÍZ","code":"AR-X-MML"},
              {"name":"ONCATIVO","code":"AR-X-OCT"},
              {"name":"PASCO","code":"AR-X-PAS"},
              {"name":"POZO DEL MOLLE","code":"AR-X-PDM"},
              {"name":"RÍO CUARTO","code":"AR-X-RCU"},
              {"name":"RIO SEGUNDO","code":"AR-X-RSG"},
              {"name":"RÍO TERCERO","code":"AR-X-RTO"},
              {"name":"SAN AGUSTIN","code":"AR-X-SNG"},
              {"name":"SAN ANTONIO DE ARREDONDO","code":"AR-X-SAA"},
              {"name":"SAN FRANCISCO","code":"AR-X-SFO"},
              {"name":"TICINO","code":"AR-X-TIC"},
              {"name":"VILLA ALLENDE","code":"AR-X-VLL"},
              {"name":"VILLA CARLOS PAZ","code":"AR-X-VCP"},
              {"name":"VILLA DEL ROSARIO","code":"AR-X-VRS"},
              {"name":"VILLA DEL TOTORAL","code":"AR-X-VTT"},
              {"name":"VILLA DOLORES","code":"AR-X-VDR"},
              {"name":"VILLA MARÍA","code":"AR-X-VMR"},
              {"name":"VILLA NUEVA","code":"AR-X-VNA"},
              {"name":"VILLA PAEZ","code":"AR-X-VLP"},
            ]
        },
        {
            "name": "Corrientes",
            "code": "AR-W",
            "cities": [
              {"name":"BELLA VISTA","code":"AR-W-BVC"},
              {"name":"CONCEPCIÓN","code":"AR-W-CON"},
              {"name":"CORRIENTES","code":"AR-W-CNQ"},
              {"name":"CURUZÚ CUATIÁ","code":"AR-W-UZU"},
              {"name":"GOYA","code":"AR-W-OYA"},
              {"name":"MARIANO I. LOZA","code":"AR-W-TMP1636"},
              {"name":"MERCEDES","code":"AR-W-MDX"},
              {"name":"MOCORETÁ","code":"AR-W-MOC"},
              {"name":"MONTE CASEROS","code":"AR-W-MCS"},
              {"name":"PASO DE LOS LIBRES","code":"AR-W-AOL"},
              {"name":"PUERTO LAVALLE","code":"AR-W-PLA"},
              {"name":"TABAY","code":"AR-W-CTA"},
            ]
        },
        {
            "name": "Entre Ríos",
            "code": "AR-E",
            "cities": [
              {"name":"ARANGUREN","code":"AR-E-ARN"},
              {"name":"ATENCIO","code":"AR-E-TMP1670"},
              {"name":"CALABACILLAS","code":"AR-E-CAS"},
              {"name":"CHAJARÍ","code":"AR-E-CHA"},
              {"name":"COLÓN","code":"AR-E-COL"},
              {"name":"CONCEPCIÓN DEL URUGUAY","code":"AR-E-COU"},
              {"name":"CONCORDIA","code":"AR-E-COC"},
              {"name":"CRESPO","code":"AR-E-CEO"},
              {"name":"DEL GUAZU","code":"AR-E-DGU"},
              {"name":"FEDERACIÓN","code":"AR-E-FEN"},
              {"name":"GENERAL RAMÍREZ","code":"AR-E-GRM"},
              {"name":"GUALEGUAY","code":"AR-E-GUY"},
              {"name":"GUALEGUAYCHÚ","code":"AR-E-GHU"},
              {"name":"IBICUY","code":"AR-E-IBY"},
              {"name":"LA PAZ","code":"AR-E-LPA"},
              {"name":"LARROQUE","code":"AR-E-VDY"},
              {"name":"LOS CHARRÚAS","code":"AR-E-LCH"},
              {"name":"MARIA GRANDE","code":"AR-E-MGA"},
              {"name":"NOGOYÁ","code":"AR-E-NOG"},
              {"name":"PARANÁ","code":"AR-E-PRA"},
              {"name":"SAN JOSE","code":"AR-E-SAJ"},
              {"name":"SAN PEDRO","code":"AR-E-SPD"},
              {"name":"SAN SALVADOR","code":"AR-E-SSA"},
              {"name":"VIALE","code":"AR-E-VIL"},
              {"name":"VILLA ELISA","code":"AR-E-VEL"},
              {"name":"VILLA HERNANDARIAS","code":"AR-E-VHE"},
              {"name":"YERUÁ","code":"AR-E-CYA"},
            ]
        },
        {
            "name": "Formosa",
            "code": "AR-P",
            "cities": [
              {"name":"FORMOSA","code":"AR-P-FMA"},
              {"name":"LAS LOMITAS","code":"AR-P-LLS"},
              {"name":"PILCOMAYO","code":"AR-P-PCY"},
            ]
        },
        {
            "name": "Jujuy",
            "code": "AR-Y",
            "cities": [
              {"name":"JUJUY","code":"AR-Y-JUJ"},
              {"name":"LA QUIACA","code":"AR-Y-LQU"},
              {"name":"LEDESMA","code":"AR-Y-XMX"},
              {"name":"LIBERTADOR GENERAL SAN MARTIN","code":"AR-Y-TMP1764"},
              {"name":"PALPALA","code":"AR-Y-PPL"},
              {"name":"SAN PEDRO","code":"AR-Y-XPD"},
              {"name":"SAN SALVADOR DE JUJUY","code":"AR-Y-SSJ"},
            ]
        },
        {
            "name": "La Pampa",
            "code": "AR-L",
            "cities": [
              {"name":"CALEUFU","code":"AR-L-TMP1787"},
              {"name":"CATRILO","code":"AR-L-CTO"},
              {"name":"CEBALLOS","code":"AR-L-TMP1790"},
              {"name":"GENERAL PICO","code":"AR-L-GPO"},
              {"name":"MACACHIN","code":"AR-L-MAC"},
              {"name":"SAN LORENZO","code":"AR-L-SLO"},
              {"name":"SANTA ROSA","code":"AR-L-RSA"},
              {"name":"URIBURU","code":"AR-L-ULP"},
            ]
        },
        {
            "name": "La Rioja",
            "code": "AR-F",
            "cities": [
              {"name":"AIMOGASTA","code":"AR-F-AIM"},
              {"name":"BAJO GRANDE","code":"AR-F-BAJ"},
              {"name":"CATINZACO","code":"AR-F-CAT"},
              {"name":"CHILECITO","code":"AR-F-CHO"},
              {"name":"CLORINDA","code":"AR-F-CLX"},
              {"name":"LA RIOJA","code":"AR-F-IRJ"},
              {"name":"MILAGRO","code":"AR-F-MIL"},
              {"name":"NONOGASTA","code":"AR-F-NNA"},
              {"name":"SANTA FLORENTINA","code":"AR-F-TMP1850"},
              {"name":"VICHIGASTA","code":"AR-F-VCH"},
            ]
        },
        {
            "name": "Mendoza",
            "code": "AR-M",
            "cities": [
              {"name":"AGRELO","code":"AR-M-GEO"},
              {"name":"ALTO VERDE","code":"AR-M-LTV"},
              {"name":"BARRANCAS","code":"AR-M-BAS"},
              {"name":"BOWEN","code":"AR-M-BON"},
              {"name":"CHACRAS DE CORIA","code":"AR-M-CDC"},
              {"name":"CHILECITO","code":"AR-M-TMP1863"},
              {"name":"COQUIMBITO","code":"AR-M-IQO"},
              {"name":"COSTA DE ARAUJO","code":"AR-M-CDA"},
              {"name":"CRUZ DE PIEDRA","code":"AR-M-CDP"},
              {"name":"CUADRO NACIONAL","code":"AR-M-CNO"},
              {"name":"DORREGO","code":"AR-M-DOR"},
              {"name":"EL PLUMERILLO","code":"AR-M-ELP"},
              {"name":"EUGENIO BUSTOS","code":"AR-M-EBA"},
              {"name":"FRAY LUIS BELTRAN","code":"AR-M-FBE"},
              {"name":"GENERAL ALVEAR","code":"AR-M-GVA"},
              {"name":"GENERAL GUTIÉRREZ","code":"AR-M-GDG"},
              {"name":"GOBERNADOR BENEGAS","code":"AR-M-GOB"},
              {"name":"GODOY CRUZ","code":"AR-M-GCR"},
              {"name":"GUAYMALLÉN","code":"AR-M-GYM"},
              {"name":"INGENIERO GIAGNONI","code":"AR-M-IGI"},
              {"name":"JUNIN","code":"AR-M-NIN"},
              {"name":"LA CONSULTA","code":"AR-M-LLM"},
              {"name":"LA PAZ","code":"AR-M-LPM"},
              {"name":"LAS CATITAS","code":"AR-M-LCS"},
              {"name":"LAS COMPUERTAS","code":"AR-M-COM"},
              {"name":"LAS HERAS","code":"AR-M-LHE"},
              {"name":"LAS PAREDES","code":"AR-M-LPD"},
              {"name":"LUJAN DE CUYO","code":"AR-M-LCU"},
              {"name":"LUZURIAGA","code":"AR-M-LZG"},
              {"name":"MAIPÚ","code":"AR-M-MPU"},
              {"name":"MALARGÜE","code":"AR-M-LGS"},
              {"name":"MAYOR DRUMMOND","code":"AR-M-MYR"},
              {"name":"MENDOZA","code":"AR-M-MDZ"},
              {"name":"PERDRIEL","code":"AR-M-PED"},
              {"name":"PUNTA DE VACAS","code":"AR-M-PVA"},
              {"name":"RIVADAVIA","code":"AR-M-RIV"},
              {"name":"RODEO DE LA CRUZ","code":"AR-M-RDC"},
              {"name":"RODEO DEL MEDIO","code":"AR-M-RDR"},
              {"name":"SALTO DE LAS ROSAS","code":"AR-M-SDR"},
              {"name":"SAN CARLOS","code":"AR-M-SCA"},
              {"name":"SAN MARTIN","code":"AR-M-SMR"},
              {"name":"SAN RAFAEL","code":"AR-M-AFA"},
              {"name":"SANTA ROSA","code":"AR-M-STA"},
              {"name":"TUNUYAN","code":"AR-M-TUN"},
              {"name":"UGARTECHE","code":"AR-M-URH"},
              {"name":"USPALLATA","code":"AR-M-USA"},
              {"name":"VISTALBA","code":"AR-M-VST"},
            ]
        },
        {
            "name": "Misiones",
            "code": "AR-N",
            "cities": [
              {"name":"ANDRESITO","code":"AR-N-TMP1937"},
              {"name":"APOSTOLES","code":"AR-N-APO"},
              {"name":"ARISTÓBULO DEL VALLE","code":"AR-N-AVO"},
              {"name":"CAMPO GRANDE","code":"AR-N-CGO"},
              {"name":"CAMPO RAMÓN","code":"AR-N-CRO"},
              {"name":"CAMPO VIERA","code":"AR-N-CVO"},
              {"name":"CANDELARIA","code":"AR-N-CDL"},
              {"name":"CERRO AZUL","code":"AR-N-CAL"},
              {"name":"CONCEPCION DE LA SIERRA","code":"AR-N-COS"},
              {"name":"ELDORADO","code":"AR-N-ELO"},
              {"name":"GARUPÁ","code":"AR-N-GRU"},
              {"name":"GOBERNADOR ROCA","code":"AR-N-GRC"},
              {"name":"IGUAZÚ","code":"AR-N-IGR"},
              {"name":"LEANDRO N. ALEM","code":"AR-N-LNO"},
              {"name":"MONTECARLO","code":"AR-N-MTC"},
              {"name":"OBERÁ","code":"AR-N-OBA"},
              {"name":"POSADAS","code":"AR-N-PSS"},
              {"name":"PUERTO ELDORADO","code":"AR-N-PEL"},
              {"name":"PUERTO MINERAL","code":"AR-N-PMN"},
              {"name":"PUERTO RICO","code":"AR-N-PUR"},
              {"name":"PUERTO WANDA","code":"AR-N-PWA"},
              {"name":"RUIZ DE MONTOYA","code":"AR-N-RDM"},
              {"name":"SAN IGNACIO","code":"AR-N-SIG"},
              {"name":"SAN JAVIER","code":"AR-N-SJV"},
              {"name":"SAN VICENTE","code":"AR-N-SVE"},
              {"name":"SANTA ANA","code":"AR-N-SAN"},
            ]
        },
        {
            "name": "Neuquén",
            "code": "AR-Q",
            "cities": [
              {"name":"ANDACOLLO","code":"AR-Q-ADC"},
              {"name":"AÑELO","code":"AR-Q-ALO"},
              {"name":"CAVIAHUE","code":"AR-Q-CVH"},
              {"name":"CHOS MALAL","code":"AR-Q-HOS"},
              {"name":"CUTRAL-CÓ","code":"AR-Q-CUT"},
              {"name":"JUNÍN DE LOS ANDES","code":"AR-Q-JUN"},
              {"name":"LONCOPUÉ","code":"AR-Q-LCP"},
              {"name":"NEUQUÉN","code":"AR-Q-NQN"},
              {"name":"PLOTTIER","code":"AR-Q-PLT"},
              {"name":"RINCÓN DE LOS SAUCES","code":"AR-Q-RDS"},
              {"name":"SAN MARTÍN DE LOS ANDES","code":"AR-Q-CPC"},
              {"name":"VILLA LA ANGOSTURA","code":"AR-Q-VLI"},
              {"name":"VISTA ALEGRE","code":"AR-Q-VAL"},
              {"name":"ZAPALA","code":"AR-Q-APZ"},
            ]
        },
        {
            "name": "Río Negro",
            "code": "AR-R",
            "cities": [
              {"name":"ALLEN","code":"AR-R-ALL"},
              {"name":"CHIMPAY","code":"AR-R-CPY"},
              {"name":"CHOELE CHOEL","code":"AR-R-CHL"},
              {"name":"CINCO SALTOS","code":"AR-R-CST"},
              {"name":"CIPOLETTI","code":"AR-R-CPA"},
              {"name":"COLONIA CATRIEL","code":"AR-R-CCT"},
              {"name":"CONTRALMIRANTE MARTÍN GUERRICO","code":"AR-R-CMG"},
              {"name":"DIAMANTE","code":"AR-R-DME"},
              {"name":"EL BOLSÓN","code":"AR-R-EHL"},
              {"name":"GENERAL ENRIQUE GODOY","code":"AR-R-XSA"},
              {"name":"GENERAL ROCA","code":"AR-R-GNR"},
              {"name":"INGENIERO JACOBACCI","code":"AR-R-IGB"},
              {"name":"INGENIERO LUIS A. HUERGO","code":"AR-R-ILH"},
              {"name":"LOS MENUCOS","code":"AR-R-LMD"},
              {"name":"MAINQUE","code":"AR-R-MAQ"},
              {"name":"MAQUINCHAO","code":"AR-R-MQD"},
              {"name":"PUNTA COLORADA","code":"AR-R-PCO"},
              {"name":"RIO COLORADO","code":"AR-R-JRC"},
              {"name":"SAN ANTONIO ESTE","code":"AR-R-SAE"},
              {"name":"SAN ANTONIO OESTE","code":"AR-R-OES"},
              {"name":"SAN CARLOS DE BARILOCHE","code":"AR-R-BRC"},
              {"name":"SIERRA GRANDE","code":"AR-R-SGV"},
              {"name":"VALCHETA","code":"AR-R-VCF"},
              {"name":"VIEDMA","code":"AR-R-VDM"},
              {"name":"VILLA MANZANO","code":"AR-R-VMZ"},
              {"name":"VILLA REGINA","code":"AR-R-VRE"},
            ]
        },
        {
            "name": "Salta",
            "code": "AR-A",
            "cities": [
              {"name":"APOLINARIO SARAVIA","code":"AR-A-ASA"},
              {"name":"CAFAYATE","code":"AR-A-CFY"},
              {"name":"CAMPO QUIJANO","code":"AR-A-CQO"},
              {"name":"CAMPO SALTO","code":"AR-A-MPO"},
              {"name":"CAMPO SANTO","code":"AR-A-CSO"},
              {"name":"EL BORDO","code":"AR-A-EBO"},
              {"name":"EL CARRIL","code":"AR-A-JEC"},
              {"name":"EMBARCACÍON","code":"AR-A-ZAI"},
              {"name":"GENERAL ENRIQUE MOSCONI","code":"AR-A-NES"},
              {"name":"GENERAL GÜEMES","code":"AR-A-GGL"},
              {"name":"HICKMANN","code":"AR-A-HIC"},
              {"name":"MOSCONI","code":"AR-A-XOS"},
              {"name":"ORAN","code":"AR-A-ORA"},
              {"name":"PICHANAL","code":"AR-A-XMV"},
              {"name":"POCITOS","code":"AR-A-POC"},
              {"name":"ROSARIO DE LA FRONTERA","code":"AR-A-RFO"},
              {"name":"ROSARIO DE LERMA","code":"AR-A-RDL"},
              {"name":"SALTA","code":"AR-A-SLA"},
              {"name":"SAN JOSÉ DE METÁN","code":"AR-A-SJM"},
              {"name":"TARTAGAL","code":"AR-A-TTG"},
            ]
        },
        {
            "name": "San Juan",
            "code": "AR-J",
            "cities": [
              {"name":"ALBARDÓN","code":"AR-J-ALN"},
              {"name":"ALTO DE SIERRA","code":"AR-J-ASL"},
              {"name":"ANGACO","code":"AR-J-ANO"},
              {"name":"BARREAL","code":"AR-J-BAL"},
              {"name":"CAUCETE","code":"AR-J-CUE"},
              {"name":"CHIMBAS","code":"AR-J-CBS"},
              {"name":"SAN JUAN","code":"AR-J-UAQ"},
              {"name":"SANTA LUCIA","code":"AR-J-STL"},
              {"name":"STA. LUCÍA","code":"AR-J-SLU"},
              {"name":"VILLA MERCEDES","code":"AR-J-VMD"},
            ]
        },
        {
            "name": "San Luis",
            "code": "AR-D",
            "cities": [
              {"name":"ZONDA","code":"AR-J-ZON"},
              {"name":"LA TOMA","code":"AR-D-LTA"},
              {"name":"MERLO","code":"AR-D-RLO"},
              {"name":"NASCHEL","code":"AR-D-NCL"},
              {"name":"SAN LUIS","code":"AR-D-LUQ"},
              {"name":"VILLA MERCEDES","code":"AR-D-VME"},
            ]
        },
        {
            "name": "Santa Cruz",
            "code": "AR-Z",
            "cities": [
              {"name":"CALETA OLIVIA","code":"AR-Z-CVI"},
              {"name":"EL CALAFATE","code":"AR-Z-FTE"},
              {"name":"GOBERNADOR GREGORES","code":"AR-Z-GGS"},
              {"name":"LAGO ARGENTINO","code":"AR-Z-ING"},
              {"name":"LAS HERAS","code":"AR-Z-LHS"},
              {"name":"PERITO MORENO","code":"AR-Z-PMQ"},
              {"name":"PICO TRUNCADO","code":"AR-Z-JPT"},
              {"name":"PUERTO DESEADO","code":"AR-Z-PUD"},
              {"name":"PUERTO SAN JULIAN","code":"AR-Z-SJL"},
              {"name":"RÍO GALLEGOS","code":"AR-Z-RGL"},
              {"name":"RÍO TURBIO","code":"AR-Z-RYO"},
              {"name":"SAN JULIAN","code":"AR-Z-ULA"},
              {"name":"SANTA CRUZ","code":"AR-Z-RZA"},
            ]
        },
        {
            "name": "Santa Fe",
            "code": "AR-S",
            "cities": [
              {"name":"ALVAREZ","code":"AR-S-ALZ"},
              {"name":"ALVEAR","code":"AR-S-AVR"},
              {"name":"ARMSTRONG","code":"AR-S-ARG"},
              {"name":"ATALIVA","code":"AR-S-TMP2101"},
              {"name":"AVELLANEDA","code":"AR-S-AVS"},
              {"name":"BOMBAL","code":"AR-S-BOL"},
              {"name":"CAÑADA DE GÓMEZ","code":"AR-S-CGA"},
              {"name":"CANADA ROSQUIN","code":"AR-S-CRL"},
              {"name":"CARCARAÑA","code":"AR-S-CAA"},
              {"name":"CARMEN DEL SAUCE","code":"AR-S-CDN"},
              {"name":"CASILDA","code":"AR-S-CSL"},
              {"name":"CERES","code":"AR-S-CRR"},
              {"name":"CHABÁS","code":"AR-S-CSS"},
              {"name":"CLASSON","code":"AR-S-CLN"},
              {"name":"CORONDA","code":"AR-S-COA"},
              {"name":"EL TRÉBOL","code":"AR-S-ETL"},
              {"name":"ESPERANZA","code":"AR-S-ESP"},
              {"name":"FRANCK","code":"AR-S-FRK"},
              {"name":"FRAY LUIS A. BELTRÁN","code":"AR-S-FLB"},
              {"name":"FUENTES","code":"AR-S-XLM"},
              {"name":"FUNES","code":"AR-S-FNS"},
              {"name":"GÁLVEZ","code":"AR-S-GVL"},
              {"name":"GENERAL LAGOS","code":"AR-S-GLS"},
              {"name":"GOBERNADOR CRESPO","code":"AR-S-VLV"},
              {"name":"GRANADERO BAIGORRIA","code":"AR-S-GRB"},
              {"name":"HUGHES","code":"AR-S-HUG"},
              {"name":"HUMBOLDT","code":"AR-S-HUM"},
              {"name":"LAS PAREJAS","code":"AR-S-LAP"},
              {"name":"PEREZ","code":"AR-S-PRZ"},
              {"name":"PUERTO ALUMBRERA","code":"AR-S-PAL"},
              {"name":"QUEBRACHO/SAN LORENZO","code":"AR-S-QBR"},
              {"name":"RAFAELA","code":"AR-S-RAF"},
              {"name":"RECONQUISTA","code":"AR-S-RCQ"},
              {"name":"RESINFOR","code":"AR-S-RSF"},
              {"name":"ROLDÁN","code":"AR-S-ROL"},
              {"name":"ROSARIO","code":"AR-S-ROS"},
              {"name":"RUFINO","code":"AR-S-RFN"},
              {"name":"SAN CARLOS CENTRO","code":"AR-S-RXV"},
              {"name":"SAN JAVIER","code":"AR-S-SJR"},
              {"name":"SAN JORGE","code":"AR-S-SJG"},
              {"name":"SAN MARTIN","code":"AR-S-SMA"},
              {"name":"SANTA FE","code":"AR-S-SFN"},
              {"name":"SANTA ISABEL","code":"AR-S-SIA"},
              {"name":"SANTO TOME","code":"AR-S-SA2"},
              {"name":"SAUCE VIEJO","code":"AR-S-RRS"},
              {"name":"SOLDINI","code":"AR-S-ODN"},
              {"name":"SUNCHALES","code":"AR-S-SUS"},
              {"name":"TEODELINA","code":"AR-S-TEO"},
              {"name":"TOSTADO","code":"AR-S-TTD"},
              {"name":"URANGA","code":"AR-S-URA"},
              {"name":"VENADO TUERTO","code":"AR-S-VNT"},
              {"name":"VILLA CANAS","code":"AR-S-VCA"},
              {"name":"VILLA CONSTITUCIÓN","code":"AR-S-VCN"},
              {"name":"VILLA GOBERNADOR GALVEZ","code":"AR-S-VGG"},
            ]
        },
        {
            "name": "Santiago del Estero",
            "code": "AR-G",
            "cities": [
              {"name":"AÑATUYA","code":"AR-G-ANA"},
              {"name":"FERNÁNDEZ","code":"AR-G-FER"},
              {"name":"LA TABLADA","code":"AR-G-LAT"},
              {"name":"MONTE QUEMADO","code":"AR-G-MQO"},
              {"name":"RÍO HONDO","code":"AR-G-RHD"},
              {"name":"SAN MATEO","code":"AR-G-SMT"},
              {"name":"SANTIAGO DEL ESTERO","code":"AR-G-SDE"},
            ]
        },
        {
            "name": "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
            "code": "AR-V",
            "cities": [
              {"name":"RÍO GRANDE","code":"AR-V-RGA"},
              {"name":"SAN SEBASTIÁN","code":"AR-V-SSN"},
              {"name":"USHUAIA","code":"AR-V-USH"},
            ]
        },
        {
            "name": "Tucumán",
            "code": "AR-T",
            "cities": [
              {"name":"ACHERAL","code":"AR-T-AC5"},
              {"name":"AGUILARES","code":"AR-T-AGS"},
              {"name":"ALDERETES","code":"AR-T-ADS"},
              {"name":"BANDA DEL RÍO SALÍ","code":"AR-T-BDR"},
              {"name":"BELLA VISTA","code":"AR-T-BVA"},
              {"name":"BURRUYACÚ","code":"AR-T-BUU"},
              {"name":"CEVIL POZO","code":"AR-T-CVP"},
              {"name":"CEVIL REDONDO","code":"AR-T-CRA"},
              {"name":"CONCEPTIÓN","code":"AR-T-CCP"},
              {"name":"FAMAILLÁ","code":"AR-T-FAM"},
              {"name":"GÜEMES","code":"AR-T-GUM"},
              {"name":"LEÓN ROUGES","code":"AR-T-LRS"},
              {"name":"LULES","code":"AR-T-LUL"},
              {"name":"SAN MIGUEL DE TUCUMÁN","code":"AR-T-SMC"},
              {"name":"TAFI VIEJO","code":"AR-T-TVJ"},
              {"name":"TUCUMÁN","code":"AR-T-TUC"},
            ]
          }
        ]
  },
  {
    name: "Aruba",
    code: "AW",
    dialCode: "+297",
    flag: "flag_aruba.png",
    states: [
      {
        "name": "Aruba",
        "code": "AW",
        "cities": []
      }
    ]
  },
  {
    name: "Bahamas",
    code: "BS",
    dialCode: "+1-242",
    flag: "flag_bahamas.png",
    states: [
      {
        "name": "Acklins",
        "code": "BS-AK",
        "cities": []
      },
      {
        "name": "Berry Islands",
        "code": "BS-BY",
        "cities": [{"name":"GREAT HARBOUR CAY","code":"BS-BY-GHC"},
          {"name":"GREAT STIRRUP CAY","code":"BS-BY-GSC"},
          {"name":"LITTLE STIRRUP CAY","code":"BS-BY-COC"},]
      },
      {
        "name": "Bimini",
        "code": "BS-BI",
        "cities": []
      },
      {
        "name": "Black Point",
        "code": "BS-BP",
        "cities": []
      },
      {
        "name": "Cat Island",
        "code": "BS-CI",
        "cities": [{"name":"ARTHUR'S TOWN, CAT ISLAND","code":"BS-CI-ATC"},
          {"name":"NEW BIGHT","code":"BS-CI-NET"},
          {"name":"STEPHENSON","code":"BS-CI-TMP12050"},
          {"name":"THE BIGHT","code":"BS-CI-TBI"},]
      },
      {
        "name": "Central Abaco",
        "code": "BS-CO",
        "cities": [{"name":"MARSH HARBOUR","code":"BS-CO-MHH"},]
      },
      {
        "name": "Central Andros",
        "code": "BS-CS",
        "cities": []
      },
      {
        "name": "Central Eleuthera",
        "code": "BS-CE",
        "cities": []
      },
      {
        "name": "City of Freeport",
        "code": "BS-FP",
        "cities": [{"name":"LUCAYA","code":"BS-FP-LUC"},
          {"name":"WEST END","code":"BS-FP-WTD"},]
      },
      {
        "name": "Crooked Island",
        "code": "BS-CK",
        "cities": []
      },
      {
        "name": "East Grand Bahama",
        "code": "BS-EG",
        "cities": []
      },
      {
        "name": "Exuma",
        "code": "BS-EX",
        "cities": [{"name":"BOCK CAY","code":"BS-EX-BKC"},
          {"name":"GREAT EXUMA ISLAND","code":"BS-EX-EXU"},]
      },
      {
        "name": "Grand Cay",
        "code": "BS-GC",
        "cities": []
      },
      {
        "name": "Harbor Island",
        "code": "BS-HI",
        "cities": [{"name":"HARBOUR ISLAND","code":"BS-HI-HBI"},
          {"name":"SPANISH WELLS","code":"BS-HI-SWL"},]
      },
      {
        "name": "Hope Town",
        "code": "BS-HT",
        "cities": [{"name":"GREEN TURTLE CAY","code":"BS-HT-GTC"},]
      },
      {
        "name": "Inagua",
        "code": "BS-IN",
        "cities": []
      },
      {
        "name": "Long Island",
        "code": "BS-LI",
        "cities": [{"name":"DEADMANS CAY","code":"BS-LI-LGI"},
          {"name":"STELLA MARIS","code":"BS-LI-SML"},]
      },
      {
        "name": "Mangrove Cay",
        "code": "BS-MC",
        "cities": []
      },
      {
        "name": "Mayaguana",
        "code": "BS-MG",
        "cities": []
      },
      {
        "name": "More Island",
        "code": "BS-MI",
        "cities": []
      },
      {
        "name": "New Providence",
        "code": "BS-NP",
        "cities": [{"name":"CABLE BEACH","code":"BS-NP-TMP12071"},
          {"name":"NASSAU","code":"BS-NP-TMP12074"},]
      },
      {
        "name": "North Abaco",
        "code": "BS-NO",
        "cities": []
      },
      {
        "name": "North Andros",
        "code": "BS-NS",
        "cities": [{"name":"SAN ANDROS","code":"BS-NS-SAQ"},]
      },
      {
        "name": "North Eleuthera",
        "code": "BS-NE",
        "cities": []
      },
      {
        "name": "Ragged Island",
        "code": "BS-RI",
        "cities": []
      },
      {
        "name": "Rum Cay",
        "code": "BS-RC",
        "cities": []
      },
      {
        "name": "San Salvador",
        "code": "BS-SS",
        "cities": [{"name":"COCKBURN TOWN","code":"BS-SS-CBT"},]
      },
      {
        "name": "South Abaco",
        "code": "BS-SO",
        "cities": []
      },
      {
        "name": "South Andros",
        "code": "BS-SA",
        "cities": [{"name":"CONGO TOWN","code":"BS-SA-COX"},]
      },
      {
        "name": "South Eleuthera",
        "code": "BS-SE",
        "cities": [{"name":"CAPE ELEUTHERA","code":"BS-SE-CEL"},
          {"name":"ROCK SOUND","code":"BS-SE-RSD"},]
      },
    ]
  },
  {
    name: "Barbados",
    code: "BB",
    dialCode: "+1-246",
    flag: "flag_barbados.png",
    states: [
      {
        "name": "Christ Church",
        "code": "BB-01",
        "cities": [{"name":"BANNATYNE","code":"BB-01-TMP164106"},
          {"name":"CHRIST CHURCH","code":"BB-01-CCH"},]
      },
      {
        "name": "Saint Andrew",
        "code": "BB-02",
        "cities": []
      },
      {
        "name": "Saint George",
        "code": "BB-03",
        "cities": []
      },
      {
        "name": "Saint James",
        "code": "BB-04",
        "cities": [{"name":"HOLETOWN","code":"BB-04-HLT"},]
      },
      {
        "name": "Saint John",
        "code": "BB-05",
        "cities": []
      },
      {
        "name": "Saint Joseph",
        "code": "BB-06",
        "cities": []
      },
      {
        "name": "Saint Lucy",
        "code": "BB-07",
        "cities": [{"name":"NESFIELD","code":"BB-07-NES"},]
      },
      {
        "name": "Saint Michael",
        "code": "BB-08",
        "cities": [{"name":"EDGE HILL","code":"BB-08-H9G"},
          {"name":"JACKSON","code":"BB-08-JCK"},
          {"name":"SAINT MICHAEL","code":"BB-08-STM"},]
      },
      {
        "name": "Saint Peter",
        "code": "BB-09",
        "cities": [{"name":"SAINT PETER","code":"BB-09-SPR"},]
      },
      {
        "name": "Saint Philip",
        "code": "BB-10",
        "cities": [{"name":"BRIGHTON","code":"BB-10-G5B"},
          {"name":"SAINT PHILIP","code":"BB-10-SAP"},]
      },
      {
        "name": "Saint Thomas",
        "code": "BB-11",
        "cities": []
      },
    ]
  },
  {
    name: "Belize",
    code: "BZ",
    dialCode: "+501",
    flag: "flag_belize.png",
    states: [
        {
            "name": "Belize",
            "code": "BZ-BZ",
            "cities": [{"name":"BELIZE CITY","code":"BZ-BZ-TMP164138"},
              {"name":"BELIZE CITY MUNICIPAL AIRPORT","code":"BZ-BZ-TZA"},
              {"name":"CAYE CAULKER","code":"BZ-BZ-CUK"},
              {"name":"CAYE CHAPEL","code":"BZ-BZ-CYC"},
              {"name":"CROOKED TREE","code":"BZ-BZ-CRR"},]
        },
        {
            "name": "Cayo",
            "code": "BZ-CY",
            "cities": [{"name":"BENQUE VIEJO DEL CARMEN","code":"BZ-CY-BVC"},
              {"name":"SAN IGNACIO","code":"BZ-CY-SQS"},
              {"name":"SANTA ELENA","code":"BZ-CY-4SA"},]
        },
        {
            "name": "Corozal",
            "code": "BZ-CZL",
            "cities": [{"name":"BENQUE VIEJO","code":"BZ-CZL-BVJ"},
              {"name":"SANTA CRUZ","code":"BZ-CZL-STU"},
              {"name":"SARTANEJA","code":"BZ-CZL-SJX"},]
        },
        {
            "name": "Orange Walk",
            "code": "BZ-OW",
            "cities": []
        },
        {
            "name": "Stann Creek",
            "code": "BZ-SC",
            "cities": [{"name":"BIG CREEK","code":"BZ-SC-BGK"},
              {"name":"PLACENCIA","code":"BZ-SC-PLJ"},]
        },
        {
            "name": "Toledo",
            "code": "BZ-TOL",
            "cities": [{"name":"INDEPENDENCE APT","code":"BZ-TOL-INB"},]
        }
    ]
  },
  {
    name: "Bolivia",
    code: "BO",
    dialCode: "+591",
    flag: "flag_bolivia.png",
    states: [
        {
            "name": "Beni",
            "code": "BO-B",
            "cities": [
              {"name":"BAURES","code":"BO-B-BVL"},
              {"name":"EXALTACIÓN","code":"BO-B-EXA"},
              {"name":"GUAYARAMERÍN","code":"BO-B-GYA"},
              {"name":"HUACARAJE","code":"BO-B-BVK"},
              {"name":"LORETO","code":"BO-B-LOR"},
              {"name":"MAGDALENA","code":"BO-B-MGD"},
              {"name":"PUERTO SILES","code":"BO-B-PTS"},
              {"name":"REYES","code":"BO-B-REY"},
              {"name":"RIBERALTA","code":"BO-B-RIB"},
              {"name":"RURRENABAQUE","code":"BO-B-RBQ"},
              {"name":"SAN BORJA","code":"BO-B-SRJ"},
              {"name":"SAN IGNACIO DE MOXO","code":"BO-B-SNM"},
              {"name":"SAN JOAQUÍN","code":"BO-B-SJB"},
              {"name":"SAN MATIAS","code":"BO-B-MQK"},
              {"name":"SAN RAMÓN","code":"BO-B-SRD"},
              {"name":"SANTA ANA","code":"BO-B-SBL"},
              {"name":"TRINIDAD","code":"BO-B-TDD"},
              {"name":"YUCUMO","code":"BO-B-YUO"},
            ]
        },
        {
            "name": "Chuquisaca",
            "code": "BO-H",
            "cities": [
              {"name":"ALCALÁ","code":"BO-H-ALC"},
              {"name":"AZURDUY","code":"BO-H-AZY"},
              {"name":"CAMARGO","code":"BO-H-CMR"},
              {"name":"CULPINA","code":"BO-H-CUL"},
              {"name":"HUACAYA","code":"BO-H-HUA"},
              {"name":"MACHARETÍ","code":"BO-H-MCH"},
              {"name":"MOJOCOYA","code":"BO-H-MOJ"},
              {"name":"MONTEAGUDO","code":"BO-H-MHW"},
              {"name":"PADILLA","code":"BO-H-PAD"},
              {"name":"POROMA","code":"BO-H-POR"},
              {"name":"PRESTO","code":"BO-H-PRE"},
              {"name":"SAN LUCAS","code":"BO-H-SLC"},
              {"name":"SAN PABLO DE HUACARETA","code":"BO-H-SPH"},
              {"name":"SUCRE","code":"BO-H-SRE"},
              {"name":"TARABUCO","code":"BO-H-TAR"},
              {"name":"TOMINA","code":"BO-H-TOM"},
              {"name":"VILLA ABECIA (CAMATAQUI)","code":"BO-H-VAB"},
              {"name":"VILLA SERRANO","code":"BO-H-VSR"},
              {"name":"VILLAZON","code":"BO-H-VL2"},
              {"name":"VIRU VIRU","code":"BO-H-VVI"},
              {"name":"YAMPARÁEZ","code":"BO-H-YAM"},
              {"name":"YOTALA","code":"BO-H-YOT"},
              {"name":"ZUDÁÑEZ","code":"BO-H-ZUD"},
            ]
        },
        {
            "name": "Cochabamba",
            "code": "BO-C",
            "cities": [
              {"name":"AIQUILE","code":"BO-C-AQL"},
              {"name":"COCHABAMBA","code":"BO-C-CBB"},
              {"name":"QUILLACOLLO","code":"BO-C-QUO"},
              {"name":"SIPE SIPE","code":"BO-C-SIP"},
              {"name":"TIQUIPAYA","code":"BO-C-TQY"},
            ]
        },
        {
            "name": "La Paz",
            "code": "BO-L",
            "cities": [
              {"name":"ACHACACHI","code":"BO-L-ACH"},
              {"name":"ACHOCALLA","code":"BO-L-ACL"},
              {"name":"ANCORAIMES","code":"BO-L-ANC"},
              {"name":"APOLO","code":"BO-L-APB"},
              {"name":"AUCAPATA","code":"BO-L-AUC"},
              {"name":"AYATA","code":"BO-L-AYT"},
              {"name":"AYO AYO","code":"BO-L-AYO"},
              {"name":"BATALLAS","code":"BO-L-BAT"},
              {"name":"CAJUATA","code":"BO-L-CJT"},
              {"name":"CALACOTO","code":"BO-L-CAO"},
              {"name":"CALACOTO","code":"BO-L-CCM"},
              {"name":"CALACOTO","code":"BO-L-CCO"},
              {"name":"CALAMARCA","code":"BO-L-CAL"},
              {"name":"CAQUIAVIRI","code":"BO-L-CAQ"},
              {"name":"CARANAVI","code":"BO-L-CRN"},
              {"name":"CATACORA","code":"BO-L-CTC"},
              {"name":"CHACARILLA","code":"BO-L-CHR"},
              {"name":"CHARAÑA","code":"BO-L-CHA"},
              {"name":"CHULUMANI","code":"BO-L-CHU"},
              {"name":"CHUMA","code":"BO-L-CHM"},
              {"name":"COLLANA","code":"BO-L-CLL"},
              {"name":"COLLPANI","code":"BO-L-COL"},
              {"name":"COLQUENCHA","code":"BO-L-CLQ"},
              {"name":"COMANCHE","code":"BO-L-CME"},
              {"name":"COMBAYA","code":"BO-L-COM"},
              {"name":"COPACABANA","code":"BO-L-CPB"},
              {"name":"CORIPATA","code":"BO-L-CRP"},
              {"name":"CORO CORO","code":"BO-L-CCR"},
              {"name":"COROICO","code":"BO-L-COR"},
              {"name":"CURVA","code":"BO-L-CUR"},
              {"name":"DESAGUADERO","code":"BO-L-DES"},
              {"name":"EL ALTO","code":"BO-L-ALT"},
              {"name":"ESCOMA","code":"BO-L-ESC"},
              {"name":"GUANAY","code":"BO-L-GUA"},
              {"name":"GUAQUI","code":"BO-L-GUQ"},
              {"name":"HUARINA","code":"BO-L-HUR"},
              {"name":"HUATAJATA","code":"BO-L-HTJ"},
              {"name":"HUMANATA","code":"BO-L-HUM"},
              {"name":"ICHOCA","code":"BO-L-ICH"},
              {"name":"INQUISIVI","code":"BO-L-INQ"},
              {"name":"IRUPANA","code":"BO-L-IRU"},
              {"name":"IXIAMAS","code":"BO-L-IXM"},
              {"name":"JESÚS DE MACHACA","code":"BO-L-JMC"},
              {"name":"LA ASUNTA","code":"BO-L-ASN"},
              {"name":"LA PAZ","code":"BO-L-LPB"},
              {"name":"LAJA","code":"BO-L-LAJ"},
              {"name":"LURIBAY","code":"BO-L-LUR"},
              {"name":"MALLA","code":"BO-L-MLL"},
              {"name":"MAPIRI","code":"BO-L-MAP"},
              {"name":"MECAPACA","code":"BO-L-MEC"},
              {"name":"MOCO MOCO","code":"BO-L-MOC"},
              {"name":"OBRAJES","code":"BO-L-OBS"},
              {"name":"PALCA","code":"BO-L-PAL"},
              {"name":"PAPEL PAMPA","code":"BO-L-PPM"},
              {"name":"PATACAMAYA","code":"BO-L-PAT"},
              {"name":"PELECHUCO","code":"BO-L-PEL"},
              {"name":"PUCARANI","code":"BO-L-PUC"},
              {"name":"PUERTO ACOSTA","code":"BO-L-PTA"},
              {"name":"PUERTO CARABUCO","code":"BO-L-PTC"},
              {"name":"PUERTO PÉREZ","code":"BO-L-PPR"},
              {"name":"QUIABAYA","code":"BO-L-QBA"},
              {"name":"QUIME","code":"BO-L-QME"},
              {"name":"SAN ANDRÉS DE MACHACA","code":"BO-L-SAM"},
              {"name":"SAN BUENAVENTURA","code":"BO-L-SBV"},
              {"name":"SAN PEDRO DE CURAHUARA","code":"BO-L-SCH"},
              {"name":"SANTA CRUZ","code":"BO-L-SRZ"},
              {"name":"SANTIAGO DE HUATA","code":"BO-L-STH"},
              {"name":"SANTIAGO DE MACHACA","code":"BO-L-SMC"},
              {"name":"SAPAHAQUI","code":"BO-L-SAP"},
              {"name":"SICA SICA","code":"BO-L-SIC"},
              {"name":"SORATA","code":"BO-L-SOR"},
              {"name":"TACACOMA","code":"BO-L-TAC"},
              {"name":"TARACO","code":"BO-L-TRC"},
              {"name":"TEOPONTE","code":"BO-L-TEO"},
              {"name":"TIAHUANACU","code":"BO-L-TIA"},
              {"name":"TIPUANI","code":"BO-L-TIP"},
              {"name":"UMALA","code":"BO-L-UMA"},
              {"name":"VIACHA","code":"BO-L-VIA"},
              {"name":"WALDO BALLIVIAN","code":"BO-L-WAB"},
              {"name":"YACO","code":"BO-L-YAC"},
              {"name":"YANACACHI","code":"BO-L-YAN"},
            ]
        },
        {
            "name": "Oruro",
            "code": "BO-O",
            "cities": [{"name":"ORURO","code":"BO-O-ORU"},
              {"name":"PISIGA","code":"BO-O-PIS"},
              {"name":"SANTIAGO DE HUARI","code":"BO-O-SDH"},
              {"name":"TAMBO QUEMADO","code":"BO-O-TAQ"},]
        },
        {
            "name": "Pando",
            "code": "BO-N",
            "cities": [{"name":"ARICA","code":"BO-N-ARC"},
              {"name":"BELLA FLOR","code":"BO-N-BEF"},
              {"name":"BOLPEBRA","code":"BO-N-BPB"},
              {"name":"COBIJA","code":"BO-N-CIJ"},
              {"name":"FILADELFIA","code":"BO-N-FIL"},
              {"name":"HUMAITÁ","code":"BO-N-HMT"},
              {"name":"NUEVA ESPERANZA","code":"BO-N-NES"},
              {"name":"PORVENIR","code":"BO-N-PRV"},
              {"name":"PUERTO GONZALO MORENO","code":"BO-N-PGM"},
              {"name":"PUERTO RICO","code":"BO-N-PUR"},
              {"name":"SAN LORENZO","code":"BO-N-SLR"},
              {"name":"SAN PEDRO","code":"BO-N-SPD"},
              {"name":"SANTA ROSA DEL ABUNÁ","code":"BO-N-SRA"},
              {"name":"SANTOS MERCADO","code":"BO-N-SME"},
              {"name":"VILLA NUEVA","code":"BO-N-VIN"},]
        },
        {
            "name": "Potosí",
            "code": "BO-P",
            "cities": [{"name":"AVAROA","code":"BO-P-ABA"},
              {"name":"COLLPANI","code":"BO-P-CLN"},
              {"name":"HITO CAJONES","code":"BO-P-HIT"},
              {"name":"POTOSÍ","code":"BO-P-POI"},
              {"name":"UYUNI","code":"BO-P-UYU"},
              {"name":"VILLAZÓN","code":"BO-P-VLL"},]
        },
        {
            "name": "Santa Cruz",
            "code": "BO-S",
            "cities": [{"name":"ASCENSIÓN","code":"BO-S-ASC"},
              {"name":"CAMIRI","code":"BO-S-CAM"},
              {"name":"CONCEPCIÓN","code":"BO-S-CEP"},
              {"name":"PUERTO BUSCH","code":"BO-S-PBU"},
              {"name":"PUERTO QUIJARRO","code":"BO-S-QJR"},
              {"name":"PUERTO SUÁREZ","code":"BO-S-PSZ"},
              {"name":"ROBORÉ","code":"BO-S-RBO"},
              {"name":"SAN IGNACIO DE VELASCO","code":"BO-S-SNG"},
              {"name":"SAN JAVIER","code":"BO-S-SJV"},
              {"name":"SAN JOSÉ","code":"BO-S-SJS"},
              {"name":"SANTA CRUZ","code":"BO-S-TMP15374"},
              {"name":"SANTA CRUZ DE LA SIERRA","code":"BO-S-SCS"},
              {"name":"VALLEGRANDE","code":"BO-S-VAH"},]
        },
        {
            "name": "Tarija",
            "code": "BO-T",
            "cities": [{"name":"BERMEJO","code":"BO-T-BJO"},
              {"name":"CAÑADA ORURO","code":"BO-T-CNO"},
              {"name":"CARAPARI","code":"BO-T-CAI"},
              {"name":"CERCADO","code":"BO-T-TMP15386"},
              {"name":"ENTRE RÍOS","code":"BO-T-ENT"},
              {"name":"PADCAYA","code":"BO-T-PDC"},
              {"name":"SAN LORENZO","code":"BO-T-SLO"},
              {"name":"TARIJA","code":"BO-T-TJA"},
              {"name":"URIONDO","code":"BO-T-URI"},
              {"name":"VILLAMONTES","code":"BO-T-VLM"},
              {"name":"YACUIBA","code":"BO-T-BYC"},
              {"name":"YUNCHARA","code":"BO-T-YUN"},]
        }
    ]
  },
  {
    name: "Bonair, Saint Eustatius and Saba",
    code: "BQ",
    dialCode: "+599",
    flag: "flag_bonaire.png",
    states: [
      {
        "name": "Bonaire",
        "code": "BQ-BO",
        "cities": []
      },
      {
        "name": "Saba",
        "code": "BQ-SA",
        "cities": []
      },
      {
        "name": "Saint Eustatius",
        "code": "BQ-SE",
        "cities": [{"name":"ORANJESTAD","code":"BQ-SE-ORA"},]
      }
    ]
  },
  {
    name: "Brasil",
    code: "BR",
    dialCode: "+55",
    flag: "flag_brazil.png",
    states: [
        {
            "name": "Acre",
            "code": "BR-AC",
            "cities": [
              {"name":"BELMONTE","code":"BR-AC-BVM"},
              {"name":"BRASILÉIA","code":"BR-AC-BRL"},
              {"name":"CAPIXABA","code":"BR-AC-CPX"},
              {"name":"CRUZEIRO DO SUL","code":"BR-AC-CZS"},
              {"name":"EPITACIOLÂNDIA","code":"BR-AC-EPI"},
              {"name":"ESTREMA","code":"BR-AC-ESA"},
              {"name":"FEIJO","code":"BR-AC-FEJ"},
              {"name":"RIO BRANCO","code":"BR-AC-RBR"},
              {"name":"SENA MADUREIRA","code":"BR-AC-SMA"},
              {"name":"TARAUACÁ","code":"BR-AC-TRQ"},
            ]
        },
        {
            "name": "Alagoas",
            "code": "BR-AL",
            "cities": [{"name":"ARAPIRACA","code":"BR-AL-APQ"},
              {"name":"BARRA DE SANTO ANTONIO","code":"BR-AL-BTO"},
              {"name":"BARRA DE SAO MIGUEL","code":"BR-AL-BSM"},
              {"name":"CORURIPE","code":"BR-AL-CUP"},
              {"name":"DELMIRO GOUVEIA","code":"BR-AL-DGU"},
              {"name":"MACEIÓ","code":"BR-AL-MCZ"},
              {"name":"MARAGOGI","code":"BR-AL-MGO"},
              {"name":"MARECHAL DEODORO","code":"BR-AL-MDE"},
              {"name":"MARITUBA","code":"BR-AL-MTB"},
              {"name":"MURICI","code":"BR-AL-MRZ"},
              {"name":"PALMEIRA DOS INDIOS","code":"BR-AL-PIS"},
              {"name":"PENEDO","code":"BR-AL-PNE"},
              {"name":"RIO LARGO","code":"BR-AL-RLO"},
              {"name":"SANTA LUZIA DO NORTE","code":"BR-AL-SLN"},
              {"name":"SANTANA DO IPANEMA","code":"BR-AL-SDM"},
              {"name":"SÃO JOSÉ DA LAJE","code":"BR-AL-SJH"},
              {"name":"SÃO LUÍS DO QUITUNDE","code":"BR-AL-SLQ"},
              {"name":"SAO MIGUEL DOS CAMPOS","code":"BR-AL-SMP"},
              {"name":"SAO MIGUEL MILAGRES","code":"BR-AL-SUM"},
              {"name":"UNIAO DOS PALMARES","code":"BR-AL-UPA"},]
        },
        {
            "name": "Amapá",
            "code": "BR-AP",
            "cities": [{"name":"LARANJAL DO JARI","code":"BR-AP-LJI"},
              {"name":"MACAPÁ","code":"BR-AP-MCA"},
              {"name":"OIAPOQUE","code":"BR-AP-OYK"},
              {"name":"SANTANA","code":"BR-AP-STN"},
              {"name":"SANTANA PT./MACAPÁ","code":"BR-AP-MCP"},
              {"name":"VITORIA DO JARI","code":"BR-AP-VAJ"},]
        },
        {
            "name": "Amazonas",
            "code": "BR-AM",
            "cities": [{"name":"BARCELOS","code":"BR-AM-BAZ"},
              {"name":"BOCA DO ACRE APT","code":"BR-AM-BCR"},
              {"name":"BORBA","code":"BR-AM-BOB"},
              {"name":"CARAUARI","code":"BR-AM-CAF"},
              {"name":"COARI","code":"BR-AM-CIZ"},
              {"name":"CODAJÁS","code":"BR-AM-CDA"},
              {"name":"EIRUNEPÉ","code":"BR-AM-ERN"},
              {"name":"HUMAITA","code":"BR-AM-HMA"},
              {"name":"IPIRANGA","code":"BR-AM-IPG"},
              {"name":"ITACOATIARA","code":"BR-AM-IAR"},
              {"name":"LÁBREA","code":"BR-AM-LBR"},
              {"name":"MANACAPURU","code":"BR-AM-MPR"},
              {"name":"MANAUS","code":"BR-AM-MAO"},
              {"name":"MANICORÉ","code":"BR-AM-MNX"},
              {"name":"MAUÉS","code":"BR-AM-MBZ"},
              {"name":"NOVO ARIPUANA","code":"BR-AM-NVP"},
              {"name":"PARINTINS","code":"BR-AM-PIN"},
              {"name":"PITINGA","code":"BR-AM-PIG"},
              {"name":"PONTA PELADA","code":"BR-AM-PLL"},
              {"name":"PRESIDENTE FIGUEIREDO","code":"BR-AM-PFU"},
              {"name":"RIO PRETO DA EVA","code":"BR-AM-RPV"},
              {"name":"SAO GABRIEL DA CACHOEIRA","code":"BR-AM-SJL"},
              {"name":"SAO PAULO DE OLIVENCA","code":"BR-AM-SPV"},
              {"name":"SILVES","code":"BR-AM-SIV"},
              {"name":"TABATINGA","code":"BR-AM-TBT"},
              {"name":"TEFÉ","code":"BR-AM-TFF"},]
        },
        {
            "name": "Bahia",
            "code": "BR-BA",
            "cities": [{"name":"ABARÉ","code":"BR-BA-ABR"},
              {"name":"ALCOBACA","code":"BR-BA-ALC"},
              {"name":"AMÉLIA RODRIGUES","code":"BR-BA-AEG"},
              {"name":"ANDARAI","code":"BR-BA-ARI"},
              {"name":"ARAÇÁS","code":"BR-BA-ARK"},
              {"name":"ARATU","code":"BR-BA-ARB"},
              {"name":"AREMBEPE","code":"BR-BA-APE"},
              {"name":"ARRAIAL","code":"BR-BA-ADJ"},
              {"name":"BARRA","code":"BR-BA-BQQ"},
              {"name":"BARRA GRANDE","code":"BR-BA-BGA"},
              {"name":"BARREIRAS","code":"BR-BA-BRA"},
              {"name":"BOM JESUS DA LAPA","code":"BR-BA-BJL"},
              {"name":"BONITO","code":"BR-BA-BON"},
              {"name":"BREJOLÂNDIA","code":"BR-BA-BJD"},
              {"name":"BRUMADO","code":"BR-BA-BMS"},
              {"name":"BUERAREMA","code":"BR-BA-BRM"},
              {"name":"CACHOEIRA","code":"BR-BA-CCQ"},
              {"name":"CAETITE","code":"BR-BA-CTI"},
              {"name":"CAIRU","code":"BR-BA-CIR"},
              {"name":"CAMACAN","code":"BR-BA-CCA"},
              {"name":"CAMAÇARI","code":"BR-BA-CCI"},
              {"name":"CAMPO FORMOSO","code":"BR-BA-CFM"},
              {"name":"CANAVIEIRAS","code":"BR-BA-CNV"},
              {"name":"CANDEIAS","code":"BR-BA-CIS"},
              {"name":"CANSANCAO","code":"BR-BA-CSC"},
              {"name":"CARAIVA","code":"BR-BA-CRV"},
              {"name":"CARAVELAS","code":"BR-BA-CRQ"},
              {"name":"CASA NOVA","code":"BR-BA-CAV"},
              {"name":"CASTRO ALVES","code":"BR-BA-CAH"},
              {"name":"CIPO","code":"BR-BA-CIO"},
              {"name":"COARACI","code":"BR-BA-CRC"},
              {"name":"CONCEIÇÃO DE JACUÍPE","code":"BR-BA-CJC"},
              {"name":"CONCEICAO DO COITE","code":"BR-BA-CEC"},
              {"name":"CORRENTINA","code":"BR-BA-COR"},
              {"name":"COTEGIPE","code":"BR-BA-CGP"},
              {"name":"CRUZ DAS ALMAS","code":"BR-BA-CZA"},
              {"name":"CURAÇÁ","code":"BR-BA-CUA"},
              {"name":"DIAS D'ÁVILA","code":"BR-BA-DIA"},
              {"name":"ENTRE RIOS","code":"BR-BA-ERS"},
              {"name":"EUCLIDES DA CUNHA","code":"BR-BA-ECU"},
              {"name":"EUNAPOLIS","code":"BR-BA-EPO"},
              {"name":"FEIRA DE SANTANA","code":"BR-BA-FEC"},
              {"name":"GANDU","code":"BR-BA-GDU"},
              {"name":"GUANAMBI","code":"BR-BA-GNM"},
              {"name":"GUARAJUBA","code":"BR-BA-GJU"},
              {"name":"IAÇU","code":"BR-BA-UCA"},
              {"name":"IBICARAI","code":"BR-BA-IRI"},
              {"name":"IBO","code":"BR-BA-IBO"},
              {"name":"IBOTIRAMA","code":"BR-BA-IBT"},
              {"name":"ILHA DE BOIPEBA","code":"BR-BA-IBP"},
              {"name":"ILHA DE COMANDATUBA","code":"BR-BA-ICD"},
              {"name":"ILHEUS","code":"BR-BA-IOS"},
              {"name":"IMBASSAI","code":"BR-BA-ISI"},
              {"name":"IPIAÚ","code":"BR-BA-IPU"},
              {"name":"IRAQUARA","code":"BR-BA-IRQ"},
              {"name":"IRECÊ","code":"BR-BA-IRE"},
              {"name":"ITABATAN","code":"BR-BA-IBN"},
              {"name":"ITABERABA","code":"BR-BA-IBA"},
              {"name":"ITABUNA","code":"BR-BA-ITA"},
              {"name":"ITACARE","code":"BR-BA-ITC"},
              {"name":"ITAGIMIRIM","code":"BR-BA-ITW"},
              {"name":"ITAGUAÇU","code":"BR-BA-IDB"},
              {"name":"ITAJIBÁ","code":"BR-BA-IJT"},
              {"name":"ITAJUIPE","code":"BR-BA-IJP"},
              {"name":"ITAMARAJU","code":"BR-BA-IRJ"},
              {"name":"ITAPARICA","code":"BR-BA-IIC"},
              {"name":"ITAPEBI","code":"BR-BA-IEB"},
              {"name":"ITAPETINGA","code":"BR-BA-ITG"},
              {"name":"ITORORO","code":"BR-BA-ITO"},
              {"name":"ITUBERÁ","code":"BR-BA-ITE"},
              {"name":"JACOBINA","code":"BR-BA-JCM"},
              {"name":"JAGUAQUARA","code":"BR-BA-JGA"},
              {"name":"JAGUARARI","code":"BR-BA-JRA"},
              {"name":"JAGUARIPE","code":"BR-BA-JGQ"},
              {"name":"JEQUIÉ","code":"BR-BA-JEQ"},
              {"name":"JIQUIRICA","code":"BR-BA-JQI"},
              {"name":"JUAZEIRO","code":"BR-BA-JZO"},
              {"name":"LAPÃO","code":"BR-BA-LPO"},
              {"name":"LAURO DE FREITAS","code":"BR-BA-LDF"},
              {"name":"LENCOIS","code":"BR-BA-LEN"},
              {"name":"LIVRAMENTO DE NOSSA SENHORA","code":"BR-BA-LNS"},
              {"name":"LIVRAMENTO DO BRUMADO","code":"BR-BA-LBO"},
              {"name":"LUIS EDUARDO MAGALHAES","code":"BR-BA-LDM"},
              {"name":"MANGUE SECO","code":"BR-BA-MSE"},
              {"name":"MARACAS","code":"BR-BA-MAK"},
              {"name":"MARAGOGIPE","code":"BR-BA-MAR"},
              {"name":"MATA DE SAO JOAO","code":"BR-BA-MSJ"},
              {"name":"MEDEIROS NETO","code":"BR-BA-MNO"},
              {"name":"MORRO DE SAO PAULO","code":"BR-BA-MSP"},
              {"name":"MUCUGÊ","code":"BR-BA-MUG"},
              {"name":"MUCURI","code":"BR-BA-MVS"},
              {"name":"MUNDAI","code":"BR-BA-MUN"},
              {"name":"NAZARE","code":"BR-BA-NZA"},
              {"name":"NOVA SOURE","code":"BR-BA-NSE"},
              {"name":"NOVA VICOSA","code":"BR-BA-NVO"},
              {"name":"OUROLANDIA","code":"BR-BA-OUR"},
              {"name":"PALMEIRAS","code":"BR-BA-PMR"},
              {"name":"PAULO AFONSO","code":"BR-BA-PAV"},
              {"name":"POCOES","code":"BR-BA-POC"},
              {"name":"POJUCA","code":"BR-BA-PJC"},
              {"name":"PORTO SEGURO","code":"BR-BA-BPS"},
              {"name":"POSTO DA MATA","code":"BR-BA-PTD"},
              {"name":"PRADO","code":"BR-BA-PDF"},
              {"name":"REMANSO","code":"BR-BA-RMA"},
              {"name":"RIACHÃO DO JACUÍPE","code":"BR-BA-RIJ"},
              {"name":"RIBEIRA DO AMPARO","code":"BR-BA-RBP"},
              {"name":"RIBEIRA DO POMBAL","code":"BR-BA-RPM"},
              {"name":"RIO DE CONTAS","code":"BR-BA-RCN"},
              {"name":"RIO DO ANTONIO","code":"BR-BA-RDA"},
              {"name":"RIO REAL","code":"BR-BA-ROR"},
              {"name":"RODELAS","code":"BR-BA-RDL"},
              {"name":"RUY BARBOSA","code":"BR-BA-RYB"},
              {"name":"SALVADOR","code":"BR-BA-SSA"},
              {"name":"SANTA CRUZ CABRALIA","code":"BR-BA-SNW"},
              {"name":"SANTA MARIA DA VITÓRIA","code":"BR-BA-SMV"},
              {"name":"SANTO AMARO","code":"BR-BA-SAM"},
              {"name":"SANTO ANTÔNIO DE JESUS","code":"BR-BA-SAJ"},
              {"name":"SANTO ESTEVAO","code":"BR-BA-SET"},
              {"name":"SÃO DESIDÉRIO","code":"BR-BA-SDD"},
              {"name":"SAO FELIX","code":"BR-BA-SFX"},
              {"name":"SAO FRANCISCO DO CONDE","code":"BR-BA-SFN"},
              {"name":"SAO GONCALO DOS CAMPOS","code":"BR-BA-SGC"},
              {"name":"SÃO SEBASTIÃO DO PASSÉ","code":"BR-BA-SPA"},
              {"name":"SAPEAÇU","code":"BR-BA-SAU"},
              {"name":"SÁTIRO DIAS","code":"BR-BA-SRD"},
              {"name":"SEABRA","code":"BR-BA-SEB"},
              {"name":"SEBASTIÃO LARANJEIRAS","code":"BR-BA-SLJ"},
              {"name":"SENHOR DO BONFIM","code":"BR-BA-SEI"},
              {"name":"SENTO SÉ","code":"BR-BA-STS"},
              {"name":"SERRINHA","code":"BR-BA-SHA"},
              {"name":"SIMOES FILHO","code":"BR-BA-SSF"},
              {"name":"SITIO DO CONDE","code":"BR-BA-STC"},
              {"name":"TABOCAS DO BREJO VELHO","code":"BR-BA-TBV"},
              {"name":"TAPEROÁ","code":"BR-BA-TPR"},
              {"name":"TEIXEIRA DE FREITAS","code":"BR-BA-TXF"},
              {"name":"TERRA NOVA","code":"BR-BA-TNV"},
              {"name":"TRANCOSO","code":"BR-BA-TCO"},
              {"name":"UAUA","code":"BR-BA-UAU"},
              {"name":"UBAITABA","code":"BR-BA-UTA"},
              {"name":"UBATA","code":"BR-BA-UTB"},
              {"name":"UNA","code":"BR-BA-UNA"},
              {"name":"VALENCA","code":"BR-BA-VAL"},
              {"name":"VALENTE","code":"BR-BA-VLT"},
              {"name":"VERA CRUZ","code":"BR-BA-VZC"},
              {"name":"VITÓRIA DA CONQUISTA","code":"BR-BA-VCA"},
              {"name":"WENCESLAU GUIMARÃES","code":"BR-BA-WGR"},
              {"name":"XIQUE-XIQUE","code":"BR-BA-XQI"},]
        },
        {
            "name": "Ceará",
            "code": "BR-CE",
            "cities": [{"name":"ACARAPE","code":"BR-CE-ACE"},
              {"name":"ACARAU","code":"BR-CE-ACU"},
              {"name":"AQUIRAZ","code":"BR-CE-AQR"},
              {"name":"ARACATI","code":"BR-CE-ART"},
              {"name":"BATURITE","code":"BR-CE-BTU"},
              {"name":"BEBERIBE","code":"BR-CE-BBI"},
              {"name":"CAMOCIM","code":"BR-CE-CMC"},
              {"name":"CANINDE","code":"BR-CE-CAD"},
              {"name":"CANOA QUEBRADA","code":"BR-CE-CQU"},
              {"name":"CAPONGA","code":"BR-CE-CGA"},
              {"name":"CASCAVEL","code":"BR-CE-CCV"},
              {"name":"CAUCAIA","code":"BR-CE-CUC"},
              {"name":"CRATEUS","code":"BR-CE-CTU"},
              {"name":"CRATO","code":"BR-CE-CAT"},
              {"name":"EUSÉBIO","code":"BR-CE-ESO"},
              {"name":"FORQUILHA","code":"BR-CE-FOQ"},
              {"name":"FORTALEZA","code":"BR-CE-FOR"},
              {"name":"FORTIM","code":"BR-CE-FTM"},
              {"name":"GUARACIABA DO NORTE","code":"BR-CE-GNO"},
              {"name":"HORIZONTE","code":"BR-CE-HZO"},
              {"name":"ICAPUÍ","code":"BR-CE-ICP"},
              {"name":"ITAPIPOCA","code":"BR-CE-IIP"},
              {"name":"ITAREMA","code":"BR-CE-IEA"},
              {"name":"JAGUARUANA","code":"BR-CE-JAA"},
              {"name":"JERICOACOARA","code":"BR-CE-JCO"},
              {"name":"JUAZEIRO DO NORTE","code":"BR-CE-JNE"},
              {"name":"LIMOEIRO DO NORTE","code":"BR-CE-LDN"},
              {"name":"MARACANAÚ","code":"BR-CE-MRU"},
              {"name":"MARANGUAPE","code":"BR-CE-MGE"},
              {"name":"MARCO","code":"BR-CE-MSC"},
              {"name":"MORADA NOVA","code":"BR-CE-MRV"},
              {"name":"OCARA","code":"BR-CE-OCR"},
              {"name":"PACAJUS","code":"BR-CE-PCJ"},
              {"name":"PACATUBA","code":"BR-CE-PTU"},
              {"name":"PARACURU","code":"BR-CE-PRU"},
              {"name":"PECÉM PT/SÃO GONÇALO DO AMARANTE","code":"BR-CE-PEC"},
              {"name":"PEREIRO","code":"BR-CE-PEI"},
              {"name":"PORTO DO MANGUE","code":"BR-CE-PTM"},
              {"name":"QUITERIANÓPOLIS","code":"BR-CE-QTR"},
              {"name":"QUIXADÁ","code":"BR-CE-QUI"},
              {"name":"QUIXERAMOBIM","code":"BR-CE-QMO"},
              {"name":"QUIXERÉ","code":"BR-CE-QUX"},
              {"name":"REDENÇÃO","code":"BR-CE-REO"},
              {"name":"RUSSAS","code":"BR-CE-RUS"},
              {"name":"SAO BENEDITO","code":"BR-CE-SBE"},
              {"name":"SAO GONCALO DO AMARANTE","code":"BR-CE-SGE"},
              {"name":"SOBRAL","code":"BR-CE-QBX"},
              {"name":"TABULEIRO DO NORTE","code":"BR-CE-TNO"},
              {"name":"TAUA","code":"BR-CE-TAU"},
              {"name":"TIANGUÁ","code":"BR-CE-TGA"},
              {"name":"TRAIRI","code":"BR-CE-TRI"},
              {"name":"UBAJARA","code":"BR-CE-UBJ"},
              {"name":"VARJOTA","code":"BR-CE-VJO"},]
        },
        {
            "name": "Distrito Federal",
            "code": "BR-DF",
            "cities": [
              {"name":"BRASÍLIA","code":"BR-DF-BSB"},
              {"name":"BRAZLÂNDIA","code":"BR-DF-TMP16462"},
            ]
        },
        {
            "name": "Espírito Santo",
            "code": "BR-ES",
            "cities": [{"name":"AFONSO CLAUDIO","code":"BR-ES-AFC"},
              {"name":"ALEGRE","code":"BR-ES-ALG"},
              {"name":"ALFREDO CHAVES","code":"BR-ES-AFE"},
              {"name":"ANCHIETA","code":"BR-ES-AHI"},
              {"name":"ARACRUZ","code":"BR-ES-ACZ"},
              {"name":"ATÍLIO VIVACQUA","code":"BR-ES-AVB"},
              {"name":"BARRA DO RIACHO PT / ARACRUZ","code":"BR-ES-RCH"},
              {"name":"BOA ESPERANCA","code":"BR-ES-BEE"},
              {"name":"CACHOEIRO DE ITAPEMIRIM","code":"BR-ES-CDI"},
              {"name":"CAPIXABA FPSO","code":"BR-ES-CXF"},
              {"name":"CARAPINA DA SERRA","code":"BR-ES-CNE"},
              {"name":"CARIACICA","code":"BR-ES-CII"},
              {"name":"CASTELO","code":"BR-ES-CEO"},
              {"name":"COLATINA","code":"BR-ES-CTN"},
              {"name":"CONCEICAO DA BARRA","code":"BR-ES-CNB"},
              {"name":"CONCEICAO DE CASTELO","code":"BR-ES-CCC"},
              {"name":"DOMINGOS MARTINS","code":"BR-ES-DMS"},
              {"name":"ECOPORANGA","code":"BR-ES-ECP"},
              {"name":"ESPÍRITO SANTO FPSO","code":"BR-ES-ESF"},
              {"name":"FUNDAO","code":"BR-ES-FDA"},
              {"name":"GOVERNADOR LACERDA DE AGUIAR","code":"BR-ES-GDA"},
              {"name":"GUACUI","code":"BR-ES-GCU"},
              {"name":"GUARAPARI","code":"BR-ES-QDL"},
              {"name":"IBIRACU","code":"BR-ES-ICU"},
              {"name":"ICONHA","code":"BR-ES-ICN"},
              {"name":"IRIRI","code":"BR-ES-IRR"},
              {"name":"ITAPEMIRIM","code":"BR-ES-ITZ"},
              {"name":"ITAUNAS","code":"BR-ES-INU"},
              {"name":"IÚNA","code":"BR-ES-IUN"},
              {"name":"JACARAIPE","code":"BR-ES-JCA"},
              {"name":"JOAO NEIVA","code":"BR-ES-JNI"},
              {"name":"LARANJEIRAS VELHA","code":"BR-ES-LJR"},
              {"name":"LINHARES","code":"BR-ES-LRS"},
              {"name":"MARATAIZES","code":"BR-ES-MTZ"},
              {"name":"MARECHAL FLORIANO","code":"BR-ES-MFO"},
              {"name":"MARILÂNDIA","code":"BR-ES-MRN"},
              {"name":"MIMOSO","code":"BR-ES-MMO"},
              {"name":"MONTANHA","code":"BR-ES-MNH"},
              {"name":"NOVA ALMEIDA","code":"BR-ES-NAI"},
              {"name":"NOVA VENECIA","code":"BR-ES-NVC"},
              {"name":"PEDRO CANARIO","code":"BR-ES-PCA"},
              {"name":"PIUMA","code":"BR-ES-PUM"},
              {"name":"PONTA DO UBU PT/ANCHIETA","code":"BR-ES-POU"},
              {"name":"REGÊNCIA TERMINAL/LINHARES","code":"BR-ES-REL"},
              {"name":"SANTA LEOPOLDINA","code":"BR-ES-SLE"},
              {"name":"SANTA MARIA DE JETIBÁ","code":"BR-ES-SMY"},
              {"name":"SANTA TERESA","code":"BR-ES-STE"},
              {"name":"SÃO DOMINGOS DO NORTE","code":"BR-ES-SDN"},
              {"name":"SAO GABRIEL DA PALHA","code":"BR-ES-SGP"},
              {"name":"SÃO MATEUS","code":"BR-ES-SBJ"},
              {"name":"SAO ROQUE DO CANAA","code":"BR-ES-SQA"},
              {"name":"SERRA","code":"BR-ES-SER"},
              {"name":"SOORETAMA","code":"BR-ES-SOR"},
              {"name":"TUBARÃO PT./VITÓRIA","code":"BR-ES-TUB"},
              {"name":"VENDA NOVA DO IMIGRANTE","code":"BR-ES-VNI"},
              {"name":"VIANA","code":"BR-ES-VNA"},
              {"name":"VILA VELHA","code":"BR-ES-VVE"},
              {"name":"VITÓRIA","code":"BR-ES-VIT"},
              {"name":"VITÓRIA PT","code":"BR-ES-VIX"},]
        },
        {
            "name": "Goiás",
            "code": "BR-GO",
            "cities": [{"name":"ACREUNA","code":"BR-GO-ACN"},
              {"name":"ALEXANIA","code":"BR-GO-AXN"},
              {"name":"ALOANDIA","code":"BR-GO-ALO"},
              {"name":"ALTO HORIZONTE","code":"BR-GO-AHZ"},
              {"name":"ALTO PARAISO DE GOIAS","code":"BR-GO-APG"},
              {"name":"ALVORADA DO NORTE","code":"BR-GO-AVN"},
              {"name":"AMERICANO DO BRASIL","code":"BR-GO-ADB"},
              {"name":"ANÁPOLIS","code":"BR-GO-APS"},
              {"name":"ANICUNS","code":"BR-GO-ANC"},
              {"name":"APARECIDA DE GOIANIA","code":"BR-GO-AGI"},
              {"name":"ARAGARÇAS","code":"BR-GO-ARS"},
              {"name":"ARAGUAPAZ","code":"BR-GO-AGZ"},
              {"name":"ARUANA","code":"BR-GO-AUN"},
              {"name":"BOM JARDIM DE GOIAS","code":"BR-GO-BJG"},
              {"name":"BOM JESUS DE GOIAS","code":"BR-GO-BJS"},
              {"name":"BRITANIA","code":"BR-GO-BTN"},
              {"name":"CABECEIRAS","code":"BR-GO-CCE"},
              {"name":"CACU","code":"BR-GO-CCU"},
              {"name":"CALDAS NOVAS","code":"BR-GO-CLV"},
              {"name":"CAMPOS BELOS","code":"BR-GO-CBS"},
              {"name":"CAMPOS VERDES","code":"BR-GO-CVD"},
              {"name":"CARMO DO RIO VERDE","code":"BR-GO-COV"},
              {"name":"CASTELANDIA","code":"BR-GO-CSE"},
              {"name":"CATALAO","code":"BR-GO-TLZ"},
              {"name":"CERES","code":"BR-GO-CES"},
              {"name":"CEZARINA","code":"BR-GO-CZR"},
              {"name":"CIDADE OCIDENTAL","code":"BR-GO-COC"},
              {"name":"CORUMBA DE GOIAS","code":"BR-GO-CGI"},
              {"name":"CRISTALINA","code":"BR-GO-CST"},
              {"name":"CRIXAS","code":"BR-GO-CRX"},
              {"name":"FORMOSA","code":"BR-GO-FMO"},
              {"name":"GOIANAPOLIS","code":"BR-GO-GPO"},
              {"name":"GOIANESIA","code":"BR-GO-GNI"},
              {"name":"GOIÂNIA","code":"BR-GO-GYN"},
              {"name":"GOIAS","code":"BR-GO-GIS"},
              {"name":"GUAPO","code":"BR-GO-GAP"},
              {"name":"HIDROLANDIA","code":"BR-GO-HLI"},
              {"name":"IACIARA","code":"BR-GO-ICA"},
              {"name":"INHUMAS","code":"BR-GO-IHS"},
              {"name":"IPORA","code":"BR-GO-IPR"},
              {"name":"ITAPURANGA","code":"BR-GO-IUG"},
              {"name":"ITUMBIARA","code":"BR-GO-ITR"},
              {"name":"JANDAIA","code":"BR-GO-JDA"},
              {"name":"JARAGUA","code":"BR-GO-JRU"},
              {"name":"JATAI","code":"BR-GO-JTI"},
              {"name":"JUSSARA","code":"BR-GO-JSA"},
              {"name":"LAGOA SANTA","code":"BR-GO-LSA"},
              {"name":"LUZIANIA","code":"BR-GO-LZA"},
              {"name":"MINACU","code":"BR-GO-MQH"},
              {"name":"MINEIROS","code":"BR-GO-MNS"},
              {"name":"MONTES CLAROS DE GOIAS","code":"BR-GO-MCG"},
              {"name":"MORRINHOS","code":"BR-GO-MHO"},
              {"name":"MOZARLANDIA","code":"BR-GO-MZA"},
              {"name":"MUNDO NOVO DE GOIAS","code":"BR-GO-MVG"},
              {"name":"NIQUELÂNDIA","code":"BR-GO-NQL"},
              {"name":"NOVA CAIAPONIA","code":"BR-GO-NCA"},
              {"name":"NOVA GLORIA","code":"BR-GO-NGO"},
              {"name":"NOVA VENEZA","code":"BR-GO-NVZ"},
              {"name":"OCIDENTAL","code":"BR-GO-ODI"},
              {"name":"OUVIDOR","code":"BR-GO-OUV"},
              {"name":"PADRE BERNARDO","code":"BR-GO-PBE"},
              {"name":"PALMEIRAS DE GOIAS","code":"BR-GO-PGI"},
              {"name":"PARANAUNA","code":"BR-GO-PNU"},
              {"name":"PARAUNA","code":"BR-GO-PRN"},
              {"name":"PERES","code":"BR-GO-PES"},
              {"name":"PIRACANJUBA","code":"BR-GO-PJU"},
              {"name":"PIRANHAS","code":"BR-GO-PNH"},
              {"name":"PIRENOPOLIS","code":"BR-GO-PLS"},
              {"name":"PIRES DO RIO","code":"BR-GO-PSR"},
              {"name":"PONTALINA","code":"BR-GO-PLI"},
              {"name":"POSSE","code":"BR-GO-PSS"},
              {"name":"QUIRINOPOLIS","code":"BR-GO-QPO"},
              {"name":"RIO QUENTE","code":"BR-GO-RQE"},
              {"name":"RIO VERDE","code":"BR-GO-RVD"},
              {"name":"RUBIATABA","code":"BR-GO-RUB"},
              {"name":"SANCLERLANDIA","code":"BR-GO-SNC"},
              {"name":"SANTA FÉ DE GOIÁS","code":"BR-GO-SFE"},
              {"name":"SANTA HELENA DE GOIAS","code":"BR-GO-SHG"},
              {"name":"SANTA RITA DO ARAGUAIA","code":"BR-GO-SRG"},
              {"name":"SANTA TEREZINHA DE GOIAS","code":"BR-GO-STG"},
              {"name":"SAO JOAO DA ALIANCA","code":"BR-GO-SJA"},
              {"name":"SAO LUIS DE MONTES BELOS","code":"BR-GO-SMB"},
              {"name":"SAO MIGUEL DO ARAGUAIA","code":"BR-GO-SQM"},
              {"name":"SAO SIMAO","code":"BR-GO-SSI"},
              {"name":"SENADOR CANEDO","code":"BR-GO-SEC"},
              {"name":"SERRANOPOLIS","code":"BR-GO-SNO"},
              {"name":"TRES RANCHOS","code":"BR-GO-TRR"},
              {"name":"TRINDADE","code":"BR-GO-TIN"},
              {"name":"TURVÂNIA","code":"BR-GO-TVA"},
              {"name":"URUACU","code":"BR-GO-URU"},
              {"name":"VALPARAISO DE GOIAS","code":"BR-GO-VGO"},
              {"name":"VIANOPOLIS","code":"BR-GO-VPS"},
              {"name":"VILA PROPÍCIO","code":"BR-GO-VPP"},]
        },
        {
            "name": "Maranhão",
            "code": "BR-MA",
            "cities": [{"name":"ACAILANDIA","code":"BR-MA-ACL"},
              {"name":"ALCANTARA","code":"BR-MA-ACT"},
              {"name":"ALTO PARNAÍBA","code":"BR-MA-APY"},
              {"name":"ALUMAR","code":"BR-MA-ALU"},
              {"name":"BACABAL","code":"BR-MA-BBA"},
              {"name":"BALSAS","code":"BR-MA-BSS"},
              {"name":"BARRA DO CORDA","code":"BR-MA-BDC"},
              {"name":"BARREIRINHAS","code":"BR-MA-BRB"},
              {"name":"CANDIDO MENDES","code":"BR-MA-CMN"},
              {"name":"CAROLINA","code":"BR-MA-CLN"},
              {"name":"CARUTAPERA","code":"BR-MA-CTP"},
              {"name":"CAXIAS","code":"BR-MA-CXS"},
              {"name":"CODO","code":"BR-MA-COO"},
              {"name":"COELHO NETO","code":"BR-MA-CHN"},
              {"name":"CURURUPU","code":"BR-MA-CPU"},
              {"name":"ESTREITO","code":"BR-MA-ETR"},
              {"name":"GRAJAU","code":"BR-MA-GJA"},
              {"name":"GUIMARAES","code":"BR-MA-GMS"},
              {"name":"IMPERATRIZ","code":"BR-MA-IMP"},
              {"name":"ITAPECURU-MIRIM","code":"BR-MA-IMM"},
              {"name":"ITAQUI","code":"BR-MA-IQI"},
              {"name":"ITINGA DO MARANHÃO","code":"BR-MA-IDM"},
              {"name":"LAGO DO JUNCO","code":"BR-MA-LDJ"},
              {"name":"PASTOS BONS","code":"BR-MA-PBS"},
              {"name":"PEDREIRAS","code":"BR-MA-PDA"},
              {"name":"PIMENTEL","code":"BR-MA-PEL"},
              {"name":"PINHEIRO","code":"BR-MA-PHI"},
              {"name":"PONTA DA MADEIRA","code":"BR-MA-PMA"},
              {"name":"PORTO FRANCO","code":"BR-MA-PTC"},
              {"name":"PRESIDENTE DUTRA","code":"BR-MA-PDR"},
              {"name":"SANTA INES","code":"BR-MA-SIN"},
              {"name":"SÃO LUÍS","code":"BR-MA-SLZ"},
              {"name":"TIMON","code":"BR-MA-TIM"},
              {"name":"TUTÓIA","code":"BR-MA-TUT"},]
        },
        {
            "name": "Mato Grosso",
            "code": "BR-MT",
            "cities": [{"name":"ALTA FLORESTA","code":"BR-MT-AFL"},
              {"name":"ALTO ARAGUAIA","code":"BR-MT-ATG"},
              {"name":"ALTO BOA VISTA","code":"BR-MT-ABV"},
              {"name":"ALTO GARCAS","code":"BR-MT-AGR"},
              {"name":"ALTO TAQUARI","code":"BR-MT-ATQ"},
              {"name":"ARAPUTANGA","code":"BR-MT-ATN"},
              {"name":"ARENAPOLIS","code":"BR-MT-APL"},
              {"name":"ARIPUANA","code":"BR-MT-AIR"},
              {"name":"BARRA DO BUGRES","code":"BR-MT-BBG"},
              {"name":"BARRA DO GARÇAS","code":"BR-MT-BPG"},
              {"name":"BRASNORTE","code":"BR-MT-BRT"},
              {"name":"CÁCERES","code":"BR-MT-CCX"},
              {"name":"CAMPINAPOLIS","code":"BR-MT-CPO"},
              {"name":"CAMPO NOVO DO PARECIS","code":"BR-MT-CNP"},
              {"name":"CAMPO VERDE","code":"BR-MT-CVE"},
              {"name":"CAMPOS DE JÚLIO","code":"BR-MT-CLJ"},
              {"name":"CANARANA","code":"BR-MT-CNN"},
              {"name":"CHAPADA DOS GUIMARAES","code":"BR-MT-CGM"},
              {"name":"CHARACAS","code":"BR-MT-CHC"},
              {"name":"CLAUDIA","code":"BR-MT-CIA"},
              {"name":"COLIDER","code":"BR-MT-CLD"},
              {"name":"COLNIZA","code":"BR-MT-CLZ"},
              {"name":"COMODORO","code":"BR-MT-CMO"},
              {"name":"COTRIGUAÇU","code":"BR-MT-COT"},
              {"name":"CUIABÁ","code":"BR-MT-CGB"},
              {"name":"DIAMANTINO","code":"BR-MT-DMT"},
              {"name":"GAUCHA","code":"BR-MT-GAU"},
              {"name":"GENERAL CARNEIRO","code":"BR-MT-GLC"},
              {"name":"GUARANTA DO NORTE","code":"BR-MT-GNR"},
              {"name":"GUIRATINGA","code":"BR-MT-GTI"},
              {"name":"ITAUBA","code":"BR-MT-AUB"},
              {"name":"JACIARA","code":"BR-MT-JCI"},
              {"name":"JANGADA","code":"BR-MT-JGD"},
              {"name":"JUARA","code":"BR-MT-JUA"},
              {"name":"JUINA","code":"BR-MT-JIA"},
              {"name":"JURUENA","code":"BR-MT-JRN"},
              {"name":"LUCAS DO RIO VERDE","code":"BR-MT-LRV"},
              {"name":"MARCELANDIA","code":"BR-MT-MCL"},
              {"name":"MATUPA","code":"BR-MT-MBK"},
              {"name":"MIRASSOL DO OESTE","code":"BR-MT-MSO"},
              {"name":"NOBRES","code":"BR-MT-NBE"},
              {"name":"NORTOLANDIA","code":"BR-MT-NTO"},
              {"name":"NOVA MUTUM","code":"BR-MT-NMU"},
              {"name":"NOVA UBIRATÃ","code":"BR-MT-NUB"},
              {"name":"NOVA XAVANTINA","code":"BR-MT-NOK"},
              {"name":"NOVO SAO JOAQUIM","code":"BR-MT-NSJ"},
              {"name":"PARANAITA","code":"BR-MT-PRT"},
              {"name":"PARANATINGA","code":"BR-MT-PTG"},
              {"name":"PEDRA PRETA","code":"BR-MT-PEP"},
              {"name":"PEIXOTO DE AZEVEDO","code":"BR-MT-PXA"},
              {"name":"POCONE","code":"BR-MT-PCN"},
              {"name":"PONTES E LACERDA","code":"BR-MT-PLC"},
              {"name":"PORTO ALEGRE DO NORT","code":"BR-MT-PBX"},
              {"name":"PÔRTO DOS GAÚCHOS","code":"BR-MT-PBV"},
              {"name":"PRIMAVERA DO LESTE","code":"BR-MT-PIL"},
              {"name":"RIBEIRAO CASCALHEIRA","code":"BR-MT-RCR"},
              {"name":"RONDONÓPOLIS","code":"BR-MT-ROO"},
              {"name":"SANTA CARMEM","code":"BR-MT-SNV"},
              {"name":"SANTA TEREZINHA","code":"BR-MT-STZ"},
              {"name":"SANTO ANTONIO LEVERGER","code":"BR-MT-SAV"},
              {"name":"SÃO FÉLIX DO ARAGUAIA","code":"BR-MT-SXO"},
              {"name":"SAO JOSE DO RIO CLARO","code":"BR-MT-SJR"},
              {"name":"SAO JOSE QUATRO MARCOS","code":"BR-MT-SQC"},
              {"name":"SAPEZAL","code":"BR-MT-SPZ"},
              {"name":"SINOP","code":"BR-MT-OPS"},
              {"name":"SORRISO","code":"BR-MT-SOO"},
              {"name":"TANGARÁ DA SERRA","code":"BR-MT-TGQ"},
              {"name":"VARZEA GRANDE","code":"BR-MT-VGR"},
              {"name":"VERA","code":"BR-MT-VER"},
              {"name":"VILA RICA","code":"BR-MT-VLP"},]
        },
        {
            "name": "Mato Grosso do Sul",
            "code": "BR-MS",
            "cities": [{"name":"AGUA CLARA","code":"BR-MS-AGC"},
              {"name":"AMAMBAI","code":"BR-MS-AMB"},
              {"name":"ANASTACIO","code":"BR-MS-ATC"},
              {"name":"ANGELICA","code":"BR-MS-AIC"},
              {"name":"ANTONIO JOAO","code":"BR-MS-AJO"},
              {"name":"APARECIDA DO TABOADO","code":"BR-MS-ATD"},
              {"name":"AQUIDAUANA","code":"BR-MS-AQD"},
              {"name":"BATAGUASSU","code":"BR-MS-BGU"},
              {"name":"BATAIPORÃ","code":"BR-MS-BAP"},
              {"name":"BELA VISTA","code":"BR-MS-BVT"},
              {"name":"BODOQUENA","code":"BR-MS-BQA"},
              {"name":"BONITO","code":"BR-MS-BYO"},
              {"name":"CAARAPO","code":"BR-MS-CRP"},
              {"name":"CAMAPUA","code":"BR-MS-CAA"},
              {"name":"CAMPO GRANDE","code":"BR-MS-CGR"},
              {"name":"CASSILÂNDIA","code":"BR-MS-CSS"},
              {"name":"CHAPADAO DO SUL","code":"BR-MS-CPL"},
              {"name":"CORUMBA","code":"BR-MS-CMG"},
              {"name":"COSTA RICA","code":"BR-MS-CSR"},
              {"name":"COXIM","code":"BR-MS-COX"},
              {"name":"DOURADINA","code":"BR-MS-DOA"},
              {"name":"DOURADOS","code":"BR-MS-DOU"},
              {"name":"ELDORADO","code":"BR-MS-ERA"},
              {"name":"FATIMA DO SUL","code":"BR-MS-FSU"},
              {"name":"GLORIA DE DOURADOS","code":"BR-MS-GDO"},
              {"name":"IGUATEMI","code":"BR-MS-IGM"},
              {"name":"INOCENCIA","code":"BR-MS-ICE"},
              {"name":"ITAPORA","code":"BR-MS-IAO"},
              {"name":"ITAQUIRAI","code":"BR-MS-IQR"},
              {"name":"IVINHEMA","code":"BR-MS-IHM"},
              {"name":"JARDIM","code":"BR-MS-JDM"},
              {"name":"MARACAJU","code":"BR-MS-MJU"},
              {"name":"MIRANDA","code":"BR-MS-MRD"},
              {"name":"MUNDO NOVO","code":"BR-MS-MNV"},
              {"name":"NAVIRAI","code":"BR-MS-NVI"},
              {"name":"NOVA ANDRADINA","code":"BR-MS-NAD"},
              {"name":"PARANAIBA","code":"BR-MS-PBB"},
              {"name":"PONTA PORA","code":"BR-MS-PMG"},
              {"name":"PORTO MURTINHO","code":"BR-MS-PMH"},
              {"name":"RIBAS DO RIO PARDO","code":"BR-MS-RRP"},
              {"name":"RIO BRILHANTE","code":"BR-MS-RBA"},
              {"name":"RIO VERDE DE MATO GROSSO","code":"BR-MS-VMG"},
              {"name":"SAO GABRIEL DO OESTE","code":"BR-MS-SGS"},
              {"name":"SIDROLANDIA","code":"BR-MS-SLD"},
              {"name":"SONORA","code":"BR-MS-SOA"},
              {"name":"TERENOS","code":"BR-MS-TRE"},
              {"name":"TRES LAGOAS","code":"BR-MS-TLG"},]
        },
        {
            "name": "Minas Gerais",
            "code": "BR-MG",
            "cities": [{"name":"ABAETÉ","code":"BR-MG-ABT"},
              {"name":"AÇUCENA","code":"BR-MG-AUC"},
              {"name":"ÁGUA BOA","code":"BR-MG-ABO"},
              {"name":"ÁGUAS VERMELHAS","code":"BR-MG-AVH"},
              {"name":"AIMORES","code":"BR-MG-AMS"},
              {"name":"AIURUOCA","code":"BR-MG-ARC"},
              {"name":"ALBERTINA","code":"BR-MG-ALB"},
              {"name":"ALEM PARAIBA","code":"BR-MG-APB"},
              {"name":"ALFENAS","code":"BR-MG-ALF"},
              {"name":"ALMENARA","code":"BR-MG-AMJ"},
              {"name":"ALPINÓPOLIS","code":"BR-MG-ALP"},
              {"name":"ALTEROSA","code":"BR-MG-ATS"},
              {"name":"ANDRADAS","code":"BR-MG-ARD"},
              {"name":"ANDRELANDIA","code":"BR-MG-AND"},
              {"name":"ANGELÂNDIA","code":"BR-MG-ANL"},
              {"name":"ANTÔNIO PRADO DE MINAS","code":"BR-MG-APM"},
              {"name":"ARACUAI","code":"BR-MG-AAA"},
              {"name":"ARAGUARI","code":"BR-MG-ARR"},
              {"name":"ARAPORA","code":"BR-MG-ARP"},
              {"name":"ARAXÁ","code":"BR-MG-AAX"},
              {"name":"ARCOS","code":"BR-MG-AOS"},
              {"name":"AREADO","code":"BR-MG-ADO"},
              {"name":"ATALEIA","code":"BR-MG-ATL"},
              {"name":"BAEPENDI","code":"BR-MG-BPI"},
              {"name":"BARAO DE COCAIS","code":"BR-MG-BCI"},
              {"name":"BARBACENA","code":"BR-MG-BCN"},
              {"name":"BARROSO","code":"BR-MG-BSO"},
              {"name":"BEIJA-FLOR","code":"BR-MG-BLO"},
              {"name":"BELO HORIZONTE","code":"BR-MG-BHZ"},
              {"name":"BELO ORIENTE","code":"BR-MG-BEO"},
              {"name":"BERTÓPOLIS","code":"BR-MG-BTP"},
              {"name":"BETIM","code":"BR-MG-BTI"},
              {"name":"BOA ESPERANCA","code":"BR-MG-BSE"},
              {"name":"BOCAIUVA","code":"BR-MG-BOV"},
              {"name":"BOM DESPACHO","code":"BR-MG-BDP"},
              {"name":"BOM SUCESSO","code":"BR-MG-BSC"},
              {"name":"BORDA DA MATA","code":"BR-MG-BDM"},
              {"name":"BRASILANDIA DE MINAS","code":"BR-MG-BMI"},
              {"name":"BRUMADINHO","code":"BR-MG-BHO"},
              {"name":"BUENO BRANDAO","code":"BR-MG-BBD"},
              {"name":"BURITIS","code":"BR-MG-BUT"},
              {"name":"BURITIZEIRO","code":"BR-MG-BTZ"},
              {"name":"CAETANOPOLIS","code":"BR-MG-CTO"},
              {"name":"CAETE","code":"BR-MG-CTE"},
              {"name":"CALDAS","code":"BR-MG-CLA"},
              {"name":"CAMANDUCAIA","code":"BR-MG-CMU"},
              {"name":"CAMBUI","code":"BR-MG-CMI"},
              {"name":"CAMBUQUIRA","code":"BR-MG-CBQ"},
              {"name":"CAMPANHA","code":"BR-MG-CPH"},
              {"name":"CAMPINA VERDE","code":"BR-MG-CVR"},
              {"name":"CAMPO BELO","code":"BR-MG-CPB"},
              {"name":"CAPARAO","code":"BR-MG-CPK"},
              {"name":"CAPELINHA","code":"BR-MG-CLH"},
              {"name":"CAPETINGA","code":"BR-MG-CPT"},
              {"name":"CAPINOPOLIS","code":"BR-MG-CPP"},
              {"name":"CAPITÃO ANDRADE","code":"BR-MG-CAE"},
              {"name":"CAPITOLIO","code":"BR-MG-CTL"},
              {"name":"CARANDAI","code":"BR-MG-CND"},
              {"name":"CARANGOLA","code":"BR-MG-CNG"},
              {"name":"CARATINGA","code":"BR-MG-CIG"},
              {"name":"CARBONITA","code":"BR-MG-CAN"},
              {"name":"CARDEAL MOTA","code":"BR-MG-CMA"},
              {"name":"CAREACU","code":"BR-MG-CEU"},
              {"name":"CARLOS CHAGAS","code":"BR-MG-CCG"},
              {"name":"CARMO DO PARANAIBA","code":"BR-MG-COP"},
              {"name":"CARMO DO RIO CLARO","code":"BR-MG-CRD"},
              {"name":"CARNEIRINHO","code":"BR-MG-CIH"},
              {"name":"CARRANCAS","code":"BR-MG-CNC"},
              {"name":"CASA GRANDE","code":"BR-MG-CSG"},
              {"name":"CASSIA","code":"BR-MG-CAI"},
              {"name":"CAXAMBU","code":"BR-MG-CXU"},
              {"name":"CENTRALINA","code":"BR-MG-CEL"},
              {"name":"CLAUDIO","code":"BR-MG-CLI"},
              {"name":"CONCEICAO DAS ALAGOAS","code":"BR-MG-CGS"},
              {"name":"CONCEICAO DO IBITIPOCA","code":"BR-MG-CCB"},
              {"name":"CONCEICAO DO MATO DENTRO","code":"BR-MG-CMD"},
              {"name":"CONCEIÇÃO DO PARÁ","code":"BR-MG-CDP"},
              {"name":"CONCEICAO DOS OUROS","code":"BR-MG-CCR"},
              {"name":"CONFINS INTERNATIONAL","code":"BR-MG-CNF"},
              {"name":"CONGONHAS","code":"BR-MG-COG"},
              {"name":"CONSELHEIRO PENA","code":"BR-MG-CHP"},
              {"name":"CONTAGEM","code":"BR-MG-CGE"},
              {"name":"CORDISBURGO","code":"BR-MG-CSO"},
              {"name":"CORINTO","code":"BR-MG-CRT"},
              {"name":"COROACI","code":"BR-MG-COI"},
              {"name":"COROMANDEL","code":"BR-MG-CML"},
              {"name":"CORONEL FABRICIANO","code":"BR-MG-CFB"},
              {"name":"CRUZILIA","code":"BR-MG-CRZ"},
              {"name":"CURVELO","code":"BR-MG-CVL"},
              {"name":"DELFIM MOREIRA","code":"BR-MG-DMA"},
              {"name":"DELFINOPOLIS","code":"BR-MG-DPO"},
              {"name":"DELTA","code":"BR-MG-DEL"},
              {"name":"DIAMANTINA","code":"BR-MG-DTA"},
              {"name":"DIVINO","code":"BR-MG-DIN"},
              {"name":"DIVINO DAS LARANJEIRAS","code":"BR-MG-DLJ"},
              {"name":"DIVINÓPOLIS","code":"BR-MG-DIV"},
              {"name":"DORES DE CAMPO","code":"BR-MG-DDC"},
              {"name":"DORES DO INDAIA","code":"BR-MG-DDI"},
              {"name":"ELÓI MENDES","code":"BR-MG-ELM"},
              {"name":"ESMERALDAS","code":"BR-MG-EMA"},
              {"name":"ESPERA FELIZ","code":"BR-MG-ESZ"},
              {"name":"ESPINOSA","code":"BR-MG-ESI"},
              {"name":"ESTRELA DO INDAIA","code":"BR-MG-EDI"},
              {"name":"EXTREMA","code":"BR-MG-EXA"},
              {"name":"FELIXLANDIA","code":"BR-MG-FND"},
              {"name":"FLORESTAL","code":"BR-MG-FSA"},
              {"name":"FORMIGA","code":"BR-MG-FMI"},
              {"name":"FORTALEZA DE MINAS","code":"BR-MG-FMS"},
              {"name":"FRUTAL","code":"BR-MG-FRU"},
              {"name":"GONCALVES","code":"BR-MG-GCS"},
              {"name":"GONZAGA","code":"BR-MG-GZG"},
              {"name":"GOVERNADOR VALADARES","code":"BR-MG-GVR"},
              {"name":"GUANHAES","code":"BR-MG-GHE"},
              {"name":"GUAPE","code":"BR-MG-GPE"},
              {"name":"GUARANÉSIA","code":"BR-MG-GAA"},
              {"name":"GUARDA-MOR","code":"BR-MG-GMO"},
              {"name":"GUAXUPÉ","code":"BR-MG-GXP"},
              {"name":"IAPU","code":"BR-MG-IAW"},
              {"name":"IBIA","code":"BR-MG-BIA"},
              {"name":"IBIRITE","code":"BR-MG-IRT"},
              {"name":"IGARAPE","code":"BR-MG-IGB"},
              {"name":"IGARATINGA","code":"BR-MG-IGT"},
              {"name":"IGUATAMA","code":"BR-MG-ITM"},
              {"name":"IJACI","code":"BR-MG-IJA"},
              {"name":"INHAUMA","code":"BR-MG-IHU"},
              {"name":"IPABA","code":"BR-MG-YPB"},
              {"name":"IPANEMA","code":"BR-MG-IPM"},
              {"name":"IPATINGA","code":"BR-MG-IPN"},
              {"name":"IPUIÚNA","code":"BR-MG-IUA"},
              {"name":"ITABIRA","code":"BR-MG-ITI"},
              {"name":"ITABIRITO","code":"BR-MG-IRO"},
              {"name":"ITAGUARA","code":"BR-MG-IAG"},
              {"name":"ITAIM","code":"BR-MG-IIM"},
              {"name":"ITAJUBA","code":"BR-MG-IAJ"},
              {"name":"ITAMARATI DE MINAS","code":"BR-MG-IMN"},
              {"name":"ITAMBACURI","code":"BR-MG-IUR"},
              {"name":"ITAMONTE","code":"BR-MG-IMT"},
              {"name":"ITANHANDU","code":"BR-MG-IHA"},
              {"name":"ITAPAGIPE","code":"BR-MG-IPI"},
              {"name":"ITAPECERICA","code":"BR-MG-ICC"},
              {"name":"ITATIAIUÇU","code":"BR-MG-IAU"},
              {"name":"ITAU DE MINAS","code":"BR-MG-IUM"},
              {"name":"ITAUNA","code":"BR-MG-IAN"},
              {"name":"ITUIUTABA","code":"BR-MG-IUB"},
              {"name":"ITURAMA","code":"BR-MG-IRA"},
              {"name":"ITUTINGA","code":"BR-MG-IUT"},
              {"name":"JACINTO","code":"BR-MG-JAC"},
              {"name":"JACUTINGA","code":"BR-MG-JTU"},
              {"name":"JAÍBA","code":"BR-MG-JBA"},
              {"name":"JANAUBA","code":"BR-MG-JNU"},
              {"name":"JANUÁRIA","code":"BR-MG-JNA"},
              {"name":"JECEABA","code":"BR-MG-JEC"},
              {"name":"JEQUITINHONHA","code":"BR-MG-JQA"},
              {"name":"JOAO MONLEVADE","code":"BR-MG-JMV"},
              {"name":"JOAO PINHEIRO","code":"BR-MG-JPH"},
              {"name":"JUIZ DE FORA","code":"BR-MG-JDF"},
              {"name":"LAFAETE","code":"BR-MG-LFA"},
              {"name":"LAGOA DA PRATA","code":"BR-MG-LPA"},
              {"name":"LAGOA SANTA","code":"BR-MG-LST"},
              {"name":"LAMBARI","code":"BR-MG-LBA"},
              {"name":"LAVRAS","code":"BR-MG-LAR"},
              {"name":"LAVRAS NOVAS","code":"BR-MG-LAW"},
              {"name":"LEOPOLDINA","code":"BR-MG-LEP"},
              {"name":"LUMINÁRIAS","code":"BR-MG-LUM"},
              {"name":"LUZ","code":"BR-MG-LUZ"},
              {"name":"MANHUACU","code":"BR-MG-MHU"},
              {"name":"MANHUMIRIM","code":"BR-MG-MHI"},
              {"name":"MANTENA","code":"BR-MG-MNA"},
              {"name":"MARAVILHAS","code":"BR-MG-MHS"},
              {"name":"MARIA DA FÉ","code":"BR-MG-MDF"},
              {"name":"MARIANA","code":"BR-MG-MRI"},
              {"name":"MÁRIO CAMPOS","code":"BR-MG-MCC"},
              {"name":"MARTINHO CAMPOS","code":"BR-MG-MCO"},
              {"name":"MATEUS LEME","code":"BR-MG-MTL"},
              {"name":"MATIAS BARBOSA","code":"BR-MG-MBA"},
              {"name":"MATIAS CARDOSO","code":"BR-MG-MCD"},
              {"name":"MATIPÓ","code":"BR-MG-MTP"},
              {"name":"MATOZINHOS","code":"BR-MG-MZS"},
              {"name":"MATUTINA","code":"BR-MG-MUT"},
              {"name":"MEDINA","code":"BR-MG-MDN"},
              {"name":"MIRAI","code":"BR-MG-MIA"},
              {"name":"MONSENHOR PAULO","code":"BR-MG-MPL"},
              {"name":"MONTE ALEGRE DE MINAS","code":"BR-MG-MAM"},
              {"name":"MONTE BELO","code":"BR-MG-MOB"},
              {"name":"MONTE CARMELO","code":"BR-MG-MCE"},
              {"name":"MONTE SIAO","code":"BR-MG-MSI"},
              {"name":"MONTE VERDE","code":"BR-MG-MVE"},
              {"name":"MONTES CLAROS","code":"BR-MG-MOC"},
              {"name":"MUNHOZ","code":"BR-MG-MHZ"},
              {"name":"MURIAE","code":"BR-MG-MUR"},
              {"name":"MUZAMBINHO","code":"BR-MG-MZI"},
              {"name":"NANUQUE","code":"BR-MG-NNU"},
              {"name":"NAZARENO","code":"BR-MG-NZN"},
              {"name":"NOVA ERA","code":"BR-MG-NER"},
              {"name":"NOVA LIMA","code":"BR-MG-NLI"},
              {"name":"NOVA PONTE","code":"BR-MG-NPT"},
              {"name":"NOVA RESENDE","code":"BR-MG-NRE"},
              {"name":"NOVA SERRANA","code":"BR-MG-NSA"},
              {"name":"NOVA UNIÃO","code":"BR-MG-NVU"},
              {"name":"OLIVEIRA","code":"BR-MG-OVA"},
              {"name":"OURO BRANCO","code":"BR-MG-OBR"},
              {"name":"OURO FINO","code":"BR-MG-OFI"},
              {"name":"OURO PRETO","code":"BR-MG-OPO"},
              {"name":"PAMPULHA","code":"BR-MG-PLU"},
              {"name":"PAPAGAIOS","code":"BR-MG-PAP"},
              {"name":"PARA DE MINAS","code":"BR-MG-PMN"},
              {"name":"PARACATU","code":"BR-MG-PCT"},
              {"name":"PARAGUACU","code":"BR-MG-PUC"},
              {"name":"PARAISOPOLIS","code":"BR-MG-PRO"},
              {"name":"PASSA QUATRO","code":"BR-MG-PQO"},
              {"name":"PASSOS","code":"BR-MG-PSW"},
              {"name":"PATOS DE MINAS","code":"BR-MG-POJ"},
              {"name":"PATROCINIO","code":"BR-MG-PCO"},
              {"name":"PAVAO","code":"BR-MG-PVA"},
              {"name":"PEDRA AZUL","code":"BR-MG-PAZ"},
              {"name":"PEDRO LEOPOLDO","code":"BR-MG-PLE"},
              {"name":"PERDIZES","code":"BR-MG-PZS"},
              {"name":"PERDOES","code":"BR-MG-PDS"},
              {"name":"PIMENTA","code":"BR-MG-PMT"},
              {"name":"PIRAPETINGA","code":"BR-MG-PTA"},
              {"name":"PIRAPORA","code":"BR-MG-PIV"},
              {"name":"PIRAUBA","code":"BR-MG-PIB"},
              {"name":"PITANGUI","code":"BR-MG-PAG"},
              {"name":"PIUMHI","code":"BR-MG-PUH"},
              {"name":"PLANURA","code":"BR-MG-PLN"},
              {"name":"POCO FUNDO","code":"BR-MG-PCF"},
              {"name":"POÇOS DE CALDAS","code":"BR-MG-POO"},
              {"name":"PONTE NOVA","code":"BR-MG-PNV"},
              {"name":"PORTEIRINHA","code":"BR-MG-PTH"},
              {"name":"POUSO ALEGRE","code":"BR-MG-PAR"},
              {"name":"POUSO ALTO","code":"BR-MG-PST"},
              {"name":"PRATA","code":"BR-MG-PAA"},
              {"name":"RIBEIRAO DAS NEVES","code":"BR-MG-RNV"},
              {"name":"RIBEIRAO VERMELHO","code":"BR-MG-RVO"},
              {"name":"RIO ACIMA","code":"BR-MG-RAC"},
              {"name":"RIO CASCA","code":"BR-MG-RCS"},
              {"name":"RIO PARANAIBA","code":"BR-MG-RPA"},
              {"name":"RIO POMBA","code":"BR-MG-RPB"},
              {"name":"SABARA","code":"BR-MG-SAB"},
              {"name":"SACRAMENTO","code":"BR-MG-SMO"},
              {"name":"SALINAS","code":"BR-MG-SAA"},
              {"name":"SANTA BARBARA","code":"BR-MG-SNR"},
              {"name":"SANTA LUZIA","code":"BR-MG-SLA"},
              {"name":"SANTA RITA DO SAPUCAI","code":"BR-MG-SRW"},
              {"name":"SANTA VITORIA","code":"BR-MG-SVI"},
              {"name":"SANTANA DO PARAISO","code":"BR-MG-SNP"},
              {"name":"SANTANA DO RIACHO","code":"BR-MG-SRC"},
              {"name":"SANTO ANTÔNIO DO AMPARO","code":"BR-MG-STP"},
              {"name":"SANTOS DUMONT","code":"BR-MG-SDO"},
              {"name":"SAO FRANCISCO DE PAULA","code":"BR-MG-SFP"},
              {"name":"SAO GERALDO","code":"BR-MG-SGL"},
              {"name":"SÃO GONÇALO DO RIO ABAIXO","code":"BR-MG-SDR"},
              {"name":"SÃO GONÇALO DO SAPUCAÍ","code":"BR-MG-SGI"},
              {"name":"SAO GOTARDO","code":"BR-MG-SGT"},
              {"name":"SÃO JOÃO DEL REI","code":"BR-MG-SJI"},
              {"name":"SAO JOAO NEPOMUCEMO","code":"BR-MG-SJN"},
              {"name":"SÃO JOAQUIM DE BICAS","code":"BR-MG-SBC"},
              {"name":"SÃO JOSÉ DA BARRA","code":"BR-MG-SZB"},
              {"name":"SAO JOSE DA LAPA","code":"BR-MG-SSL"},
              {"name":"SÃO JOSÉ DO JACURI","code":"BR-MG-SDJ"},
              {"name":"SAO PEDRO DO SUACUI","code":"BR-MG-SCU"},
              {"name":"SAO ROQUE DE MINAS","code":"BR-MG-SQN"},
              {"name":"SAO SEBASTIAO DO PARAISO","code":"BR-MG-SSP"},
              {"name":"SÃO SEBASTIÃO PARAÍSO","code":"BR-MG-TMP18158"},
              {"name":"SÃO TIAGO","code":"BR-MG-STI"},
              {"name":"SÃO TOMÁS DE AQUINO","code":"BR-MG-STQ"},
              {"name":"SAO TOME DAS LETRAS","code":"BR-MG-STL"},
              {"name":"SAPUCAÍ-MIRIM","code":"BR-MG-SPM"},
              {"name":"SARDOA","code":"BR-MG-SGZ"},
              {"name":"SARZEDO","code":"BR-MG-SZD"},
              {"name":"SENHORA DE OLIVEIRA","code":"BR-MG-SOI"},
              {"name":"SERRA DO SALITRE","code":"BR-MG-SST"},
              {"name":"SERRANIA","code":"BR-MG-SIA"},
              {"name":"SERRO","code":"BR-MG-SEO"},
              {"name":"SETE LAGOAS","code":"BR-MG-QHG"},
              {"name":"SILVIANOPOLIS","code":"BR-MG-SVL"},
              {"name":"SIMAO PEREIRA","code":"BR-MG-SOP"},
              {"name":"SOBRALIA","code":"BR-MG-SBL"},
              {"name":"TAIOBEIRAS","code":"BR-MG-TBE"},
              {"name":"TEÓFILO OTONI","code":"BR-MG-TFL"},
              {"name":"TIMOTEO","code":"BR-MG-TTO"},
              {"name":"TIRADENTES","code":"BR-MG-TDE"},
              {"name":"TOCANTINS","code":"BR-MG-TCT"},
              {"name":"TOMBOS","code":"BR-MG-TMS"},
              {"name":"TRÊS CORAÇOES","code":"BR-MG-QID"},
              {"name":"TRES MARIAS","code":"BR-MG-TMR"},
              {"name":"TRES PONTAS","code":"BR-MG-TRP"},
              {"name":"TUPACIGUARA","code":"BR-MG-TCI"},
              {"name":"UBA","code":"BR-MG-UBQ"},
              {"name":"UBERABA","code":"BR-MG-UBA"},
              {"name":"UBERLÂNDIA","code":"BR-MG-UDI"},
              {"name":"UNAI","code":"BR-MG-UNI"},
              {"name":"VARGINHA","code":"BR-MG-VAG"},
              {"name":"VARZEA DA PALMA","code":"BR-MG-VPM"},
              {"name":"VAZANTE","code":"BR-MG-VZA"},
              {"name":"VESPASIANO","code":"BR-MG-VSA"},
              {"name":"VICOSA","code":"BR-MG-VIS"},
              {"name":"VIRGEM DA LAPA","code":"BR-MG-VGL"},
              {"name":"VIRGINÓPOLIS","code":"BR-MG-VIG"},
              {"name":"VISCONDE DO RIO BRANCO","code":"BR-MG-VRB"},]
        },
        {
            "name": "Pará",
            "code": "BR-PA",
            "cities": [{"name":"ABAETETUBA","code":"BR-PA-ATB"},
              {"name":"AFUÁ","code":"BR-PA-AFU"},
              {"name":"ALENQUER","code":"BR-PA-ALT"},
              {"name":"ALMEIRIM","code":"BR-PA-AMM"},
              {"name":"ALTAMIRA","code":"BR-PA-ATM"},
              {"name":"ANANINDEUA","code":"BR-PA-ANA"},
              {"name":"BAGRE","code":"BR-PA-TMP18284"},
              {"name":"BARCARENA","code":"BR-PA-BCA"},
              {"name":"BELEM","code":"BR-PA-BEL"},
              {"name":"BENEVIDES","code":"BR-PA-BVI"},
              {"name":"BRAGANCA","code":"BR-PA-BNC"},
              {"name":"BREU BRANCO","code":"BR-PA-BBN"},
              {"name":"BREVES","code":"BR-PA-BVS"},
              {"name":"CAMETÁ APT","code":"BR-PA-CMR"},
              {"name":"CANAÃ DOS CARAJÁS","code":"BR-PA-CDC"},
              {"name":"CAPANEMA","code":"BR-PA-CPM"},
              {"name":"CAPITAO POCO","code":"BR-PA-CPC"},
              {"name":"CARAJÁS APT/PARAUAPEBAS","code":"BR-PA-CKS"},
              {"name":"CASTANHAL","code":"BR-PA-CAS"},
              {"name":"CONCEIÇÃO DO ARAGUAIA","code":"BR-PA-CDJ"},
              {"name":"DOM ELISEU","code":"BR-PA-DEU"},
              {"name":"IGARAPE-ACU","code":"BR-PA-IAC"},
              {"name":"ILHA DE MARAJO","code":"BR-PA-IMJ"},
              {"name":"IPIXUNA DO PARÁ","code":"BR-PA-IXP"},
              {"name":"ITAITUBA","code":"BR-PA-ITB"},
              {"name":"JACAREACANGA","code":"BR-PA-JCR"},
              {"name":"JACUNDA","code":"BR-PA-JCD"},
              {"name":"JURUTI","code":"BR-PA-JUR"},
              {"name":"MAE DO RIO","code":"BR-PA-MRO"},
              {"name":"MARABÁ","code":"BR-PA-MAB"},
              {"name":"MONTE ALEGRE","code":"BR-PA-MTE"},
              {"name":"MONTE DOURADO","code":"BR-PA-MEU"},
              {"name":"MOSQUEIRO","code":"BR-PA-MQR"},
              {"name":"MUNGUBA","code":"BR-PA-MGU"},
              {"name":"NOVO PROGRESSO","code":"BR-PA-NOV"},
              {"name":"OBIDOS","code":"BR-PA-OBI"},
              {"name":"ORIXIMINÁ","code":"BR-PA-ORX"},
              {"name":"OURILANDIA DO NORTE","code":"BR-PA-ONO"},
              {"name":"OUTEIRO","code":"BR-PA-OUT"},
              {"name":"PACAJÁ","code":"BR-PA-PAJ"},
              {"name":"PARAGOMINAS","code":"BR-PA-PGO"},
              {"name":"PARAUAPEBAS","code":"BR-PA-PRS"},
              {"name":"PÔRTO DE MOZ","code":"BR-PA-PTQ"},
              {"name":"PRIMAVERA","code":"BR-PA-PRV"},
              {"name":"PROGRESSO","code":"BR-PA-PGG"},
              {"name":"REDENÇÃO","code":"BR-PA-RDC"},
              {"name":"RIO MARIA","code":"BR-PA-RMI"},
              {"name":"RONDON DO PARA","code":"BR-PA-RPR"},
              {"name":"SALVATERRA","code":"BR-PA-SLV"},
              {"name":"SANTA BARBARA DO PARA","code":"BR-PA-SNT"},
              {"name":"SANTANA DO ARAGUAIA","code":"BR-PA-SAG"},
              {"name":"SANTARÉM","code":"BR-PA-STM"},
              {"name":"SAO FELIX DO XINGU","code":"BR-PA-SFG"},
              {"name":"SÃO JOÃO DA ARAGUAIA","code":"BR-PA-SJG"},
              {"name":"SÃO JOÃO DE PIRABAS","code":"BR-PA-SPI"},
              {"name":"SOURE","code":"BR-PA-SFK"},
              {"name":"TAILÂNDIA","code":"BR-PA-TLD"},
              {"name":"TANCREDO NEVES","code":"BR-PA-TNE"},
              {"name":"TOME-ACU","code":"BR-PA-TAC"},
              {"name":"TROMBETAS","code":"BR-PA-TMT"},
              {"name":"TUCUMA","code":"BR-PA-TUZ"},
              {"name":"TUCURUÍ","code":"BR-PA-TUR"},
              {"name":"TUCURUVI","code":"BR-PA-TVU"},
              {"name":"VILA DO CONDE PT/BARCARENA","code":"BR-PA-VDC"},
              {"name":"XINGUARA","code":"BR-PA-XIG"},]
        },
        {
            "name": "Paraíba",
            "code": "BR-PB",
            "cities": [{"name":"ALHANDRA","code":"BR-PB-ALH"},
              {"name":"AREIA","code":"BR-PB-AEI"},
              {"name":"BAYEUX","code":"BR-PB-BAY"},
              {"name":"BOQUEIRÃO","code":"BR-PB-BQR"},
              {"name":"BREJO DO CRUZ","code":"BR-PB-BDZ"},
              {"name":"CAAPORA","code":"BR-PB-CPA"},
              {"name":"CABEDELO","code":"BR-PB-CAB"},
              {"name":"CABEDELO PT","code":"BR-PB-CDO"},
              {"name":"CAJAZEIRAS","code":"BR-PB-CZJ"},
              {"name":"CAMPINA GRANDE","code":"BR-PB-CPV"},
              {"name":"CATOLE DO ROCHA","code":"BR-PB-CCH"},
              {"name":"CONDE","code":"BR-PB-CDE"},
              {"name":"CRUZ DO ESPÍRITO SANTO","code":"BR-PB-CEB"},
              {"name":"GUARABIRA","code":"BR-PB-GBA"},
              {"name":"ITABAIANA","code":"BR-PB-ITN"},
              {"name":"JACUMA","code":"BR-PB-JCU"},
              {"name":"JOAO PESSOA","code":"BR-PB-JPA"},
              {"name":"JUNCO DO SERIDÓ","code":"BR-PB-JSD"},
              {"name":"LUCENA","code":"BR-PB-LUA"},
              {"name":"MAMANGUAPE","code":"BR-PB-MPU"},
              {"name":"MONTEIRO","code":"BR-PB-MME"},
              {"name":"NOVA PALMEIRA","code":"BR-PB-NPM"},
              {"name":"PATOS","code":"BR-PB-PTS"},
              {"name":"PEDRAS DE FOGO","code":"BR-PB-PFG"},
              {"name":"PITIMBU","code":"BR-PB-PMU"},
              {"name":"RIO TINTO","code":"BR-PB-RTT"},
              {"name":"SALGADO DE SÃO FÉLIX","code":"BR-PB-SSX"},
              {"name":"SANTA RITA","code":"BR-PB-SRR"},
              {"name":"SAO BENTO","code":"BR-PB-SBT"},
              {"name":"SAPE","code":"BR-PB-SAE"},
              {"name":"SOUSA","code":"BR-PB-SOU"},
              {"name":"VISTA SERRANA","code":"BR-PB-VSE"},]
        },
        {
            "name": "Paraná",
            "code": "BR-PR",
            "cities": [{"name":"ALTO PARANA","code":"BR-PR-APN"},
              {"name":"ALTO PIQUIRI","code":"BR-PR-AQI"},
              {"name":"ALTONIA","code":"BR-PR-ATO"},
              {"name":"AMAPORA","code":"BR-PR-TMP18664"},
              {"name":"ANDIRA","code":"BR-PR-AIA"},
              {"name":"ANTONINA","code":"BR-PR-ANT"},
              {"name":"APUCARANA","code":"BR-PR-APU"},
              {"name":"ARAPONGAS","code":"BR-PR-APX"},
              {"name":"ARAPOTI","code":"BR-PR-AAG"},
              {"name":"ARARUNA","code":"BR-PR-AAU"},
              {"name":"ARAUCÁRIA","code":"BR-PR-ACA"},
              {"name":"ASSAI","code":"BR-PR-ASI"},
              {"name":"ASSIS CHATEAUBRIAND","code":"BR-PR-ACD"},
              {"name":"ASTORGA","code":"BR-PR-ASG"},
              {"name":"BANDEIRANTES","code":"BR-PR-BND"},
              {"name":"BARRACAO","code":"BR-PR-BRC"},
              {"name":"BELA VISTA DO PARAISO","code":"BR-PR-BVP"},
              {"name":"BITURUNA","code":"BR-PR-BIU"},
              {"name":"BOCAIÚVA DO SUL","code":"BR-PR-BDS"},
              {"name":"CAIOBA","code":"BR-PR-CBA"},
              {"name":"CAMBARA","code":"BR-PR-CBR"},
              {"name":"CAMBE","code":"BR-PR-CBE"},
              {"name":"CAMPINA GRANDE DO SUL","code":"BR-PR-CGL"},
              {"name":"CAMPO LARGO","code":"BR-PR-CLO"},
              {"name":"CAMPO MAGRO","code":"BR-PR-CPG"},
              {"name":"CAMPO MOURAO","code":"BR-PR-CBW"},
              {"name":"CARAMBEI","code":"BR-PR-CRM"},
              {"name":"CARLÓPOLIS","code":"BR-PR-CAL"},
              {"name":"CASCAVEL","code":"BR-PR-CAC"},
              {"name":"CASTRO","code":"BR-PR-CTR"},
              {"name":"CENTENARIO DO SUL","code":"BR-PR-CTD"},
              {"name":"CIANORTE","code":"BR-PR-CNO"},
              {"name":"CIDADE GAÚCHA","code":"BR-PR-CDH"},
              {"name":"CLEVELANDIA","code":"BR-PR-CVI"},
              {"name":"COLOMBO","code":"BR-PR-COL"},
              {"name":"COLORADO","code":"BR-PR-CRO"},
              {"name":"CONTENDA","code":"BR-PR-CON"},
              {"name":"CORNÉLIO PROCÓPIO","code":"BR-PR-CKO"},
              {"name":"CORONEL DOMINGOS SOLARES","code":"BR-PR-CDZ"},
              {"name":"CORONEL VIVIDA","code":"BR-PR-VIV"},
              {"name":"CURITIBA","code":"BR-PR-CWB"},
              {"name":"CURIÚVA","code":"BR-PR-CUV"},
              {"name":"DOIS VIZINHOS","code":"BR-PR-DVS"},
              {"name":"ENGENHEIRO BELTRAO","code":"BR-PR-EBR"},
              {"name":"FAZENDA RIO GRANDE","code":"BR-PR-FRG"},
              {"name":"FIGUEIRA","code":"BR-PR-FGU"},
              {"name":"FLORAI","code":"BR-PR-FLO"},
              {"name":"FLORESTOPOLIS","code":"BR-PR-FLP"},
              {"name":"FOZ DO IGUAÇU","code":"BR-PR-IGU"},
              {"name":"FRANCISCO BELTRAO","code":"BR-PR-FBE"},
              {"name":"GENERAL CARNEIRO","code":"BR-PR-GEC"},
              {"name":"GOIOERE","code":"BR-PR-GOE"},
              {"name":"GUABIROTUBA","code":"BR-PR-GOB"},
              {"name":"GUAIRA","code":"BR-PR-QGA"},
              {"name":"GUARAPUAVA","code":"BR-PR-GAV"},
              {"name":"GUARAQUECABA","code":"BR-PR-GQE"},
              {"name":"GUARATUBA","code":"BR-PR-GTB"},
              {"name":"HONORIO SERPA","code":"BR-PR-HNS"},
              {"name":"IBAITI","code":"BR-PR-BIT"},
              {"name":"IBATI","code":"BR-PR-IBI"},
              {"name":"IBEMA","code":"BR-PR-IBM"},
              {"name":"IBIPORA","code":"BR-PR-IPA"},
              {"name":"ICARAIMA","code":"BR-PR-IMI"},
              {"name":"IGUACU","code":"BR-PR-ICG"},
              {"name":"ILHA DO MEL","code":"BR-PR-IME"},
              {"name":"IMBITUVA","code":"BR-PR-IBV"},
              {"name":"INDIANÓPOLIS","code":"BR-PR-IND"},
              {"name":"IPORA","code":"BR-PR-ORA"},
              {"name":"IRATI","code":"BR-PR-ATI"},
              {"name":"IRETAMA","code":"BR-PR-IAM"},
              {"name":"ITAIPULÂNDIA","code":"BR-PR-IPD"},
              {"name":"ITAMBE","code":"BR-PR-IAE"},
              {"name":"ITAPEJARA D'OESTE","code":"BR-PR-IJW"},
              {"name":"ITAPERUCU","code":"BR-PR-IYT"},
              {"name":"ITAUNA DO SUL","code":"BR-PR-ISO"},
              {"name":"IVAIPORA","code":"BR-PR-IOR"},
              {"name":"IVATE","code":"BR-PR-IVT"},
              {"name":"JAGUARIAÍVA","code":"BR-PR-JRV"},
              {"name":"JANDAIA DO SUL","code":"BR-PR-JSU"},
              {"name":"JARDIM ALEGRE","code":"BR-PR-JAE"},
              {"name":"JESUÍTAS","code":"BR-PR-JES"},
              {"name":"JOAQUIM TÁVORA","code":"BR-PR-JTB"},
              {"name":"LAPA","code":"BR-PR-LAP"},
              {"name":"LARANJEIRAS DO SUL","code":"BR-PR-LSU"},
              {"name":"LOANDA","code":"BR-PR-LOD"},
              {"name":"LONDRINA","code":"BR-PR-LDB"},
              {"name":"MALLET","code":"BR-PR-MAL"},
              {"name":"MANDAGUACU","code":"BR-PR-MGC"},
              {"name":"MANDAGUARI","code":"BR-PR-MDI"},
              {"name":"MANDIRITUBA","code":"BR-PR-MAA"},
              {"name":"MANGUEIRINHA","code":"BR-PR-MGH"},
              {"name":"MARECHAL CÂNDIDO RONDON","code":"BR-PR-MCN"},
              {"name":"MARIALVA","code":"BR-PR-MAV"},
              {"name":"MARINGÁ","code":"BR-PR-MGF"},
              {"name":"MATELANDIA","code":"BR-PR-MLA"},
              {"name":"MATINHOS","code":"BR-PR-MTO"},
              {"name":"MAUA DA SERRA","code":"BR-PR-MSR"},
              {"name":"MEDIANEIRA","code":"BR-PR-MDA"},
              {"name":"MORRETES","code":"BR-PR-MRS"},
              {"name":"NOVA AURORA","code":"BR-PR-NUR"},
              {"name":"NOVA ESPERANCA","code":"BR-PR-NEC"},
              {"name":"NOVA LARANJEIRAS","code":"BR-PR-NVL"},
              {"name":"NOVA LONDRINA","code":"BR-PR-NLO"},
              {"name":"ORTIGUEIRA","code":"BR-PR-ORT"},
              {"name":"PAIÇANDU","code":"BR-PR-PAI"},
              {"name":"PALMAS PARANA","code":"BR-PR-PPR"},
              {"name":"PALMEIRA","code":"BR-PR-PMI"},
              {"name":"PALOTINA","code":"BR-PR-PLA"},
              {"name":"PARANAGUÁ","code":"BR-PR-PNG"},
              {"name":"PARANAVAÍ","code":"BR-PR-PVI"},
              {"name":"PATO BRANCO","code":"BR-PR-PTO"},
              {"name":"PATOLINA","code":"BR-PR-PTN"},
              {"name":"PAULA FREITAS","code":"BR-PR-PFT"},
              {"name":"PEROLA","code":"BR-PR-PRL"},
              {"name":"PIÊN","code":"BR-PR-PYN"},
              {"name":"PINHAIS","code":"BR-PR-PIH"},
              {"name":"PINHÃO","code":"BR-PR-PHW"},
              {"name":"PIRAÍ DO SUL","code":"BR-PR-PRZ"},
              {"name":"PIRAQUARA","code":"BR-PR-PQA"},
              {"name":"PONTA GROSSA","code":"BR-PR-PGZ"},
              {"name":"PORECATU","code":"BR-PR-POT"},
              {"name":"PORTO RICO","code":"BR-PR-PTR"},
              {"name":"PORTO VITORIA","code":"BR-PR-PVT"},
              {"name":"PRUDENTOPOLIS","code":"BR-PR-PUO"},
              {"name":"QUATRO BARRAS","code":"BR-PR-QBA"},
              {"name":"QUATRO PONTES","code":"BR-PR-QPT"},
              {"name":"QUATRO RODAS","code":"BR-PR-QRO"},
              {"name":"QUEDAS DO IGUAÇU","code":"BR-PR-QDI"},
              {"name":"REALEZA","code":"BR-PR-RZA"},
              {"name":"REBOUÇAS","code":"BR-PR-RBC"},
              {"name":"RESERVA","code":"BR-PR-RSE"},
              {"name":"RIO AZUL","code":"BR-PR-RAZ"},
              {"name":"RIO BRANCO DO SUL","code":"BR-PR-RBS"},
              {"name":"RIO NEGRO","code":"BR-PR-RNO"},
              {"name":"ROLANDIA","code":"BR-PR-ROL"},
              {"name":"RONDON","code":"BR-PR-RND"},
              {"name":"ROSARIO DO IVAI","code":"BR-PR-RIV"},
              {"name":"SABÁUDIA","code":"BR-PR-SBZ"},
              {"name":"SANTA HELENA","code":"BR-PR-SNH"},
              {"name":"SANTA ISABEL DO IVAI","code":"BR-PR-SII"},
              {"name":"SANTA MARIANA","code":"BR-PR-SMN"},
              {"name":"SANTA TEREZA DO OESTE","code":"BR-PR-STW"},
              {"name":"SANTA TEREZINHA DE ITAIPU","code":"BR-PR-STU"},
              {"name":"SANTO ANTONIO DA PLATINA","code":"BR-PR-SPN"},
              {"name":"SANTO ANTÔNIO DO PARAISO","code":"BR-PR-SAW"},
              {"name":"SÃO CARLOS DO IVAÍ","code":"BR-PR-SCI"},
              {"name":"SAO JOAO DO CAIUA","code":"BR-PR-SJC"},
              {"name":"SÃO JOSÉ DOS PINHAIS","code":"BR-PR-SJS"},
              {"name":"SAO MATEUS DO SUL","code":"BR-PR-SMS"},
              {"name":"SÃO MIGUEL DO IGUAÇU","code":"BR-PR-SIG"},
              {"name":"SAO PEDRO DO IVAI","code":"BR-PR-SDI"},
              {"name":"SÃO TOMÉ","code":"BR-PR-SOM"},
              {"name":"SARANDI","code":"BR-PR-SNI"},
              {"name":"SENGÉS","code":"BR-PR-SEN"},
              {"name":"SERTANOPOLIS","code":"BR-PR-SNS"},
              {"name":"SIQUEIRA CAMPOS","code":"BR-PR-SQP"},
              {"name":"TAMARANA","code":"BR-PR-TMN"},
              {"name":"TAMBOARA","code":"BR-PR-TBO"},
              {"name":"TAPEJARA","code":"BR-PR-TPJ"},
              {"name":"TELÊMACO BORBA","code":"BR-PR-TEC"},
              {"name":"TERRA BOA","code":"BR-PR-TRB"},
              {"name":"TERRA ROXA","code":"BR-PR-TRO"},
              {"name":"TIBAGI","code":"BR-PR-TGI"},
              {"name":"TIJUCA DO SUL","code":"BR-PR-TJS"},
              {"name":"TOLEDO","code":"BR-PR-TOW"},
              {"name":"TUNAS","code":"BR-PR-TUN"},
              {"name":"TURVO","code":"BR-PR-TVG"},
              {"name":"UBIRATA","code":"BR-PR-UBI"},
              {"name":"UMUARAMA","code":"BR-PR-UMU"},
              {"name":"UNIÃO DA VITÓRIA","code":"BR-PR-UVA"},
              {"name":"URAI","code":"BR-PR-URA"},
              {"name":"VENTANIA","code":"BR-PR-VET"},
              {"name":"VIRMOND","code":"BR-PR-VID"},
              {"name":"WENCESLAU BRAZ","code":"BR-PR-WBZ"},]
        },
        {
            "name": "Pernambuco",
            "code": "BR-PE",
            "cities": [{"name":"ABREU E LIMA","code":"BR-PE-ALM"},
              {"name":"AFOGADOS DA INGAZEIRA","code":"BR-PE-ADI"},
              {"name":"AMARAJI","code":"BR-PE-ARJ"},
              {"name":"ARARIPINA","code":"BR-PE-AIP"},
              {"name":"ARCOVERDE","code":"BR-PE-ARV"},
              {"name":"BARRA DO TARRACHIL","code":"BR-PE-TMP19118"},
              {"name":"BARREIROS","code":"BR-PE-BRS"},
              {"name":"BELO JARDIM","code":"BR-PE-BJB"},
              {"name":"BEZERROS","code":"BR-PE-BZE"},
              {"name":"BOA VIAGEM","code":"BR-PE-BVG"},
              {"name":"BONITO","code":"BR-PE-BNO"},
              {"name":"CABO DE SANTO AGOSTINHO","code":"BR-PE-CSA"},
              {"name":"CAMARAGIBE","code":"BR-PE-CME"},
              {"name":"CARPINA","code":"BR-PE-CIN"},
              {"name":"CARUARU","code":"BR-PE-CAU"},
              {"name":"CHÃ DE ALEGRIA","code":"BR-PE-CHG"},
              {"name":"CHA GRANDE","code":"BR-PE-CGD"},
              {"name":"CUSTÓDIA","code":"BR-PE-CUS"},
              {"name":"ESCADA","code":"BR-PE-ESD"},
              {"name":"FAZENDA NOVA","code":"BR-PE-FNA"},
              {"name":"FERNANDO DE NORONHA","code":"BR-PE-FEN"},
              {"name":"GAMELEIRA","code":"BR-PE-GLE"},
              {"name":"GARANHUNS","code":"BR-PE-GHU"},
              {"name":"GLÓRIA DO GOITÁ","code":"BR-PE-GDG"},
              {"name":"GOIANA","code":"BR-PE-GNA"},
              {"name":"GRAVATA","code":"BR-PE-GVT"},
              {"name":"IBIMIRIM","code":"BR-PE-IMR"},
              {"name":"IGARASSU","code":"BR-PE-IGA"},
              {"name":"ILHA DE ITAMARACÁ","code":"BR-PE-IDI"},
              {"name":"INAJÁ","code":"BR-PE-INJ"},
              {"name":"IPOJUCA","code":"BR-PE-IPO"},
              {"name":"ITAMARACA","code":"BR-PE-IMC"},
              {"name":"ITAMBE DE PERNAMBUCO","code":"BR-PE-IPB"},
              {"name":"ITAPISSUMA","code":"BR-PE-ISM"},
              {"name":"JABOATÃO DOS GUARARAPES","code":"BR-PE-JGS"},
              {"name":"LAGOA GRANDE","code":"BR-PE-LGP"},
              {"name":"LIMOEIRO","code":"BR-PE-LIM"},
              {"name":"MARIA FARINHA","code":"BR-PE-MFA"},
              {"name":"MORENO","code":"BR-PE-MRE"},
              {"name":"OLINDA","code":"BR-PE-OLA"},
              {"name":"PALMARES","code":"BR-PE-PMS"},
              {"name":"PAULISTA","code":"BR-PE-PAL"},
              {"name":"PESQUEIRA","code":"BR-PE-PQE"},
              {"name":"PETROLINA","code":"BR-PE-PNZ"},
              {"name":"POMBOS","code":"BR-PE-PMB"},
              {"name":"PORTO DE GALINHAS","code":"BR-PE-PGS"},
              {"name":"PRIMAVERA","code":"BR-PE-PMV"},
              {"name":"RECIFE","code":"BR-PE-REC"},
              {"name":"RIBEIRÃO","code":"BR-PE-RBE"},
              {"name":"SALGUEIRO","code":"BR-PE-SGU"},
              {"name":"SANTA CRUZ DO CAPIBARIBE","code":"BR-PE-SNF"},
              {"name":"SAO LOURENCO DA MATA","code":"BR-PE-SLM"},
              {"name":"SERRA TALHADA","code":"BR-PE-STD"},
              {"name":"SIRINHAEM","code":"BR-PE-SNE"},
              {"name":"SUAPE","code":"BR-PE-SUA"},
              {"name":"SURUBIM","code":"BR-PE-SRB"},
              {"name":"TAMANDARE","code":"BR-PE-TMA"},
              {"name":"TAQUARITINGA DO NORTE","code":"BR-PE-TQN"},
              {"name":"TIMBAUBA","code":"BR-PE-TMB"},
              {"name":"TORITAMA","code":"BR-PE-TRT"},
              {"name":"TRACUNHAEM","code":"BR-PE-TCU"},
              {"name":"VITÓRIA DE SANTO ANTÃO","code":"BR-PE-VDS"},]
        },
        {
            "name": "Piauí",
            "code": "BR-PI",
            "cities": [{"name":"ALTOS","code":"BR-PI-ALS"},
              {"name":"CAMPO MAIOR","code":"BR-PI-COM"},
              {"name":"CANTO DO BURITI","code":"BR-PI-CRR"},
              {"name":"CORRENTE","code":"BR-PI-CNT"},
              {"name":"ESPERANTINA","code":"BR-PI-ETI"},
              {"name":"FLORIANO","code":"BR-PI-FLB"},
              {"name":"GUADALUPE","code":"BR-PI-GDP"},
              {"name":"JUAZEIRO-PI","code":"BR-PI-JZP"},
              {"name":"LUIS CORREIA","code":"BR-PI-LCI"},
              {"name":"PARNAÍBA","code":"BR-PI-PHB"},
              {"name":"PICOS","code":"BR-PI-PCS"},
              {"name":"PIRIPIRI","code":"BR-PI-PIP"},
              {"name":"SAO RAIMUNDO NONATO","code":"BR-PI-SRN"},
              {"name":"SIMPLÍCIO MENDES","code":"BR-PI-SIM"},
              {"name":"TERESINA","code":"BR-PI-THE"},
              {"name":"URUÇUÍ","code":"BR-PI-URC"},]
        },
        {
            "name": "Rio de Janeiro",
            "code": "BR-RJ",
            "cities": [{"name":"AÇU PT/SÃO JOÃO DA BARRA","code":"BR-RJ-ACX"},
              {"name":"ANGRA DOS REIS","code":"BR-RJ-ADR"},
              {"name":"ARARUAMA","code":"BR-RJ-AMA"},
              {"name":"AREAL","code":"BR-RJ-ARL"},
              {"name":"ARMAÇÃO DOS BÚZIOS","code":"BR-RJ-ABZ"},
              {"name":"ARRAIAL DO CABO","code":"BR-RJ-ACB"},
              {"name":"ATAFONA","code":"BR-RJ-TMP19531"},
              {"name":"BARRA MANSA","code":"BR-RJ-BMA"},
              {"name":"BELFORD ROXO","code":"BR-RJ-BRX"},
              {"name":"BOM JESUS DE ITABAPOANA","code":"BR-RJ-BJI"},
              {"name":"CABO FRIO","code":"BR-RJ-CFO"},
              {"name":"CACHOEIRAS DE MACACU","code":"BR-RJ-CDM"},
              {"name":"CAMBUCI","code":"BR-RJ-CMB"},
              {"name":"CAMPOS","code":"BR-RJ-CAW"},
              {"name":"CAMPOS DOS GOYTACAZES","code":"BR-RJ-CGZ"},
              {"name":"CANTAGALO","code":"BR-RJ-CTG"},
              {"name":"CARAPEBUS","code":"BR-RJ-CRB"},
              {"name":"CARDOSO MOREIRA","code":"BR-RJ-CSM"},
              {"name":"CASIMIRO DE ABREU","code":"BR-RJ-CSD"},
              {"name":"CIDADE DE ANGRA DOS REIS FPSO","code":"BR-RJ-ANF"},
              {"name":"COLEGIO","code":"BR-RJ-CGO"},
              {"name":"CONSERVATORIA","code":"BR-RJ-CVO"},
              {"name":"CORDEIRO","code":"BR-RJ-CRE"},
              {"name":"DUQUE DE CAXIAS","code":"BR-RJ-DCS"},
              {"name":"ENGENHEIRO PASSOS","code":"BR-RJ-EPA"},
              {"name":"ENGENHEIRO PAULO DE FRONTIN","code":"BR-RJ-EPF"},
              {"name":"FLUMINENSE FPSO","code":"BR-RJ-FLU"},
              {"name":"FORNO PT/ARRAIAL DO CABO","code":"BR-RJ-FNO"},
              {"name":"GALEÃO APT/RIO DE JANEIRO","code":"BR-RJ-GIG"},
              {"name":"GUAPIMIRIM","code":"BR-RJ-GPM"},
              {"name":"ILHA DO GOVERNADOR","code":"BR-RJ-IGO"},
              {"name":"ILHA GRANDE","code":"BR-RJ-IGE"},
              {"name":"IRAJA","code":"BR-RJ-IAA"},
              {"name":"ITABORAI","code":"BR-RJ-IOI"},
              {"name":"ITACURUCA","code":"BR-RJ-IRC"},
              {"name":"ITAGUAÍ","code":"BR-RJ-IGI"},
              {"name":"ITAGUAÍ PT = SEPETIBA PT","code":"BR-RJ-SPB"},
              {"name":"ITAIPAVA","code":"BR-RJ-ITV"},
              {"name":"ITAOCARA","code":"BR-RJ-IOC"},
              {"name":"ITAPERUNA","code":"BR-RJ-ITP"},
              {"name":"ITATIAIA","code":"BR-RJ-ITT"},
              {"name":"JACAREPAGUA","code":"BR-RJ-JPU"},
              {"name":"MACAÉ","code":"BR-RJ-MEA"},
              {"name":"MAGE","code":"BR-RJ-MAG"},
              {"name":"MANGARATIBA","code":"BR-RJ-MGT"},
              {"name":"MARICA","code":"BR-RJ-MRC"},
              {"name":"MENDES","code":"BR-RJ-MDS"},
              {"name":"MESQUITA","code":"BR-RJ-MQU"},
              {"name":"MIGUEL PEREIRA","code":"BR-RJ-MPE"},
              {"name":"MIRACEMA","code":"BR-RJ-MCM"},
              {"name":"NILOPOLIS","code":"BR-RJ-NPO"},
              {"name":"NITEROI","code":"BR-RJ-NTR"},
              {"name":"NOVA FRIBURGO","code":"BR-RJ-NFU"},
              {"name":"NOVA IGUACU","code":"BR-RJ-NIU"},
              {"name":"PARACAMBI","code":"BR-RJ-PAB"},
              {"name":"PARAIBA DO SUL","code":"BR-RJ-PSU"},
              {"name":"PARATI","code":"BR-RJ-PAT"},
              {"name":"PATI DOS ALFERES","code":"BR-RJ-PAF"},
              {"name":"PAVUNA","code":"BR-RJ-PVU"},
              {"name":"PETRÓPOLIS","code":"BR-RJ-QPE"},
              {"name":"PIRAI","code":"BR-RJ-PIR"},
              {"name":"PORCIUNCULA","code":"BR-RJ-PNC"},
              {"name":"PORTO REAL","code":"BR-RJ-PTE"},
              {"name":"QUATIS","code":"BR-RJ-QTS"},
              {"name":"QUEIMADOS","code":"BR-RJ-QMA"},
              {"name":"RAPOSO","code":"BR-RJ-RPO"},
              {"name":"RESENDE","code":"BR-RJ-QRZ"},
              {"name":"RIO","code":"BR-RJ-TMP19743"},
              {"name":"RIO BONITO","code":"BR-RJ-RBO"},
              {"name":"RIO DAS FLORES","code":"BR-RJ-RDF"},
              {"name":"RIO DAS OSTRAS","code":"BR-RJ-RTA"},
              {"name":"RIO DE JANEIRO","code":"BR-RJ-RIO"},
              {"name":"SANA","code":"BR-RJ-SNB"},
              {"name":"SANTA MARIA MADALENA","code":"BR-RJ-SMM"},
              {"name":"SANTO ANTÔNIO DE PÁDUA","code":"BR-RJ-STA"},
              {"name":"SANTOS DUMONT APT/RIO DE JANEIRO","code":"BR-RJ-SDU"},
              {"name":"SAO FIDELIS","code":"BR-RJ-SFI"},
              {"name":"SAO GONCALO","code":"BR-RJ-QSD"},
              {"name":"SÃO JOÃO DA BARRA","code":"BR-RJ-SDB"},
              {"name":"SAO JOAO DO MERITI","code":"BR-RJ-SJM"},
              {"name":"SAO JOSE DO VALE RIO PRETO","code":"BR-RJ-SRT"},
              {"name":"SAO PEDRO DA ALDEIA","code":"BR-RJ-SRL"},
              {"name":"SAQUAREMA","code":"BR-RJ-SQR"},
              {"name":"SEROPEDICA","code":"BR-RJ-SDC"},
              {"name":"SUMIDOURO","code":"BR-RJ-SMD"},
              {"name":"TANGUÁ","code":"BR-RJ-TAG"},
              {"name":"TERESÓPOLIS","code":"BR-RJ-QHT"},
              {"name":"VALENCA","code":"BR-RJ-VLA"},
              {"name":"VARGEM GRANDE","code":"BR-RJ-VGA"},
              {"name":"VASSOURAS","code":"BR-RJ-VAS"},
              {"name":"VOLTA REDONDA","code":"BR-RJ-QVR"},]
        },
        {
            "name": "Rio Grande do Norte",
            "code": "BR-RN",
            "cities": [{"name":"ACARI","code":"BR-RN-ACR"},
              {"name":"APODI","code":"BR-RN-APD"},
              {"name":"AREIA BRANCA","code":"BR-RN-ARE"},
              {"name":"ARÊS","code":"BR-RN-AES"},
              {"name":"ASSU","code":"BR-RN-ASU"},
              {"name":"BAIA FORMOSA","code":"BR-RN-BFO"},
              {"name":"BARAUNA","code":"BR-RN-BUN"},
              {"name":"CAICO","code":"BR-RN-CIC"},
              {"name":"CEARÁ-MIRIM","code":"BR-RN-CEA"},
              {"name":"EXTREMOZ","code":"BR-RN-EXZ"},
              {"name":"GENIPABU","code":"BR-RN-GPU"},
              {"name":"GOVERNADOR DIX-SEPT ROSADO","code":"BR-RN-GDX"},
              {"name":"GROSSOS","code":"BR-RN-GSS"},
              {"name":"GUAMARÉ","code":"BR-RN-GUA"},
              {"name":"IPANGUACU","code":"BR-RN-IGC"},
              {"name":"JANDAÍRA","code":"BR-RN-JDR"},
              {"name":"LAJES","code":"BR-RN-LJE"},
              {"name":"MACAIBA","code":"BR-RN-MAI"},
              {"name":"MACAU","code":"BR-RN-MCU"},
              {"name":"MONTE ALEGRE","code":"BR-RN-MAC"},
              {"name":"MOSSORÓ","code":"BR-RN-MVF"},
              {"name":"NATAL","code":"BR-RN-NAT"},
              {"name":"NIZIA FLORESTA","code":"BR-RN-NFO"},
              {"name":"PARELHAS","code":"BR-RN-PAH"},
              {"name":"PARNAMIRIM","code":"BR-RN-PMM"},
              {"name":"RIO DO FOGO","code":"BR-RN-RFO"},
              {"name":"SANTA CRUZ","code":"BR-RN-SNZ"},
              {"name":"SÃO BENTO DO NORTE","code":"BR-RN-SBN"},
              {"name":"SÃO GONÇALO DO AMARANTE","code":"BR-RN-SAZ"},
              {"name":"SAO JOSE DE MIPIBO","code":"BR-RN-SSM"},
              {"name":"SAO MIGUEL DO GOSTOSO","code":"BR-RN-SMG"},
              {"name":"TIBAU DO SUL","code":"BR-RN-TBS"},
              {"name":"TOUROS","code":"BR-RN-TOS"},
              {"name":"VERA CRUZ","code":"BR-RN-VCZ"},]
        },
        {
            "name": "Rio Grande do Sul",
            "code": "BR-RS",
            "cities": [{"name":"AGUDO","code":"BR-RS-AGO"},
              {"name":"ALEGRETE","code":"BR-RS-ALQ"},
              {"name":"ALTO FELIZ","code":"BR-RS-AFZ"},
              {"name":"ALVORADA","code":"BR-RS-AVR"},
              {"name":"AMETISTA DO SUL","code":"BR-RS-ADS"},
              {"name":"ARROIO DO MEIO","code":"BR-RS-AMI"},
              {"name":"BAGÉ","code":"BR-RS-BGX"},
              {"name":"BALNEARIO PINHAL","code":"BR-RS-BAL"},
              {"name":"BARAO DE COTIGIPE","code":"BR-RS-BCG"},
              {"name":"BARÃO DO TRIUNFO","code":"BR-RS-BTF"},
              {"name":"BARRA DO RIBEIRO","code":"BR-RS-BRO"},
              {"name":"BENTO GONÇALVES","code":"BR-RS-BGV"},
              {"name":"BOA VISTA DO BURICA","code":"BR-RS-BVD"},
              {"name":"BOA VISTA DO SUL","code":"BR-RS-BOA"},
              {"name":"BOM PRINCIPIO","code":"BR-RS-BPP"},
              {"name":"CACAPAVA DO SUL","code":"BR-RS-CSL"},
              {"name":"CAMAQUA","code":"BR-RS-CQA"},
              {"name":"CAMARGO","code":"BR-RS-CRG"},
              {"name":"CAMBARA DO SUL","code":"BR-RS-CMS"},
              {"name":"CAMPO BOM","code":"BR-RS-QCD"},
              {"name":"CANABARRO TEUTONIA","code":"BR-RS-CTT"},
              {"name":"CANDELARIA","code":"BR-RS-CDL"},
              {"name":"CANDIOTA","code":"BR-RS-CDT"},
              {"name":"CANELA","code":"BR-RS-QCN"},
              {"name":"CANGUÇU","code":"BR-RS-CGC"},
              {"name":"CANOAS","code":"BR-RS-QNS"},
              {"name":"CAPAO DA CANOA","code":"BR-RS-CCN"},
              {"name":"CAPAO DO LEAO","code":"BR-RS-CPE"},
              {"name":"CAPELA DE SANTANA","code":"BR-RS-CLS"},
              {"name":"CARAÁ","code":"BR-RS-RAA"},
              {"name":"CARAZINHO","code":"BR-RS-ZHO"},
              {"name":"CARLOS BARBOSA","code":"BR-RS-CBB"},
              {"name":"CAXIAS DO SUL","code":"BR-RS-CXJ"},
              {"name":"CERRO LARGO","code":"BR-RS-CLG"},
              {"name":"CHAPADA","code":"BR-RS-CPD"},
              {"name":"CHARQUEADAS","code":"BR-RS-CQD"},
              {"name":"CHUI","code":"BR-RS-CUI"},
              {"name":"CIDREIRA","code":"BR-RS-CRI"},
              {"name":"CRISSIUMAL","code":"BR-RS-CUL"},
              {"name":"CRUZ ALTA","code":"BR-RS-CZB"},
              {"name":"DOIS IRMAOS","code":"BR-RS-DIS"},
              {"name":"DOUTOR MAURICIO CARDOSO","code":"BR-RS-DMC"},
              {"name":"ELDORADO DO SUL","code":"BR-RS-EDS"},
              {"name":"ENCANTADO","code":"BR-RS-ECO"},
              {"name":"ENCRUZILHADA DO SUL","code":"BR-RS-ECS"},
              {"name":"ERECHIM","code":"BR-RS-ERM"},
              {"name":"ERNESTINA","code":"BR-RS-ERT"},
              {"name":"ESPUMOSO","code":"BR-RS-EPU"},
              {"name":"ESTANCIA VELHA","code":"BR-RS-EVH"},
              {"name":"ESTEIO","code":"BR-RS-ETO"},
              {"name":"ESTRELA","code":"BR-RS-ETA"},
              {"name":"FARROUPILHA","code":"BR-RS-FPA"},
              {"name":"FLORES DA CUNHA","code":"BR-RS-FCU"},
              {"name":"FREDERICO WESTPHALEN","code":"BR-RS-FWP"},
              {"name":"GARIBALDI","code":"BR-RS-GBI"},
              {"name":"GETULIO VARGAS","code":"BR-RS-GVA"},
              {"name":"GIRUA","code":"BR-RS-GRA"},
              {"name":"GLORINHA","code":"BR-RS-GHA"},
              {"name":"GRAMADO","code":"BR-RS-QRP"},
              {"name":"GRAVATAÍ","code":"BR-RS-GCV"},
              {"name":"GUAÍBA","code":"BR-RS-GIB"},
              {"name":"GUAPORE","code":"BR-RS-GUP"},
              {"name":"GUARANI DAS MISSÕES","code":"BR-RS-GDM"},
              {"name":"HORIZONTINA","code":"BR-RS-HRZ"},
              {"name":"IBIAÇÁ","code":"BR-RS-IBX"},
              {"name":"IBIRUBA","code":"BR-RS-IRU"},
              {"name":"IGREJINHA","code":"BR-RS-IGJ"},
              {"name":"IJUÍ","code":"BR-RS-IJU"},
              {"name":"IMBE","code":"BR-RS-IMB"},
              {"name":"IMIGRANTE","code":"BR-RS-IGR"},
              {"name":"INDEPENDENCIA","code":"BR-RS-IDP"},
              {"name":"IRAI","code":"BR-RS-RAI"},
              {"name":"ITAQUI","code":"BR-RS-ITQ"},
              {"name":"IVOTI","code":"BR-RS-IVO"},
              {"name":"JAGUARAO","code":"BR-RS-JAG"},
              {"name":"JULIO DE CASTILHOS","code":"BR-RS-JCS"},
              {"name":"LAGOA VERMELHA","code":"BR-RS-LVE"},
              {"name":"LAJEADO","code":"BR-RS-QLB"},
              {"name":"LAVRAS DO SUL","code":"BR-RS-LAS"},
              {"name":"LINDOLFO COLLOR","code":"BR-RS-LIC"},
              {"name":"LIVRAMENTO","code":"BR-RS-LVB"},
              {"name":"MAQUINE","code":"BR-RS-MQI"},
              {"name":"MARAU","code":"BR-RS-MAU"},
              {"name":"MARCELINO RAMOS","code":"BR-RS-MRA"},
              {"name":"MATA","code":"BR-RS-MTA"},
              {"name":"MATO LEITÃO","code":"BR-RS-MLE"},
              {"name":"MAXIMILIANO DE ALMEIDA","code":"BR-RS-MXA"},
              {"name":"MINAS DO LEÃO","code":"BR-RS-MDL"},
              {"name":"MIRAGUAÍ","code":"BR-RS-MIU"},
              {"name":"MIRIM","code":"BR-RS-MRR"},
              {"name":"MONTAURI","code":"BR-RS-MON"},
              {"name":"MONTE BELO DO SUL","code":"BR-RS-MBS"},
              {"name":"MONTENEGRO","code":"BR-RS-QGF"},
              {"name":"MORMACO","code":"BR-RS-MMA"},
              {"name":"MORRO REUTER","code":"BR-RS-MGR"},
              {"name":"MOSTARDAS","code":"BR-RS-MTD"},
              {"name":"MUÇUM","code":"BR-RS-MUM"},
              {"name":"MUITOS CAPÕES","code":"BR-RS-MTC"},
              {"name":"NAO ME TOQUE","code":"BR-RS-NTQ"},
              {"name":"NOVA ARAÇÁ","code":"BR-RS-NOA"},
              {"name":"NOVA BASSANO","code":"BR-RS-NBA"},
              {"name":"NOVA ESPERANÇA DO SUL","code":"BR-RS-NES"},
              {"name":"NOVA HARTZ","code":"BR-RS-HAR"},
              {"name":"NOVA PALMA","code":"BR-RS-NOP"},
              {"name":"NOVA PETROPOLIS","code":"BR-RS-NPE"},
              {"name":"NOVA PRATA","code":"BR-RS-NPA"},
              {"name":"NOVA ROMA DO SUL","code":"BR-RS-NRS"},
              {"name":"NOVA SANTA RITA","code":"BR-RS-NSR"},
              {"name":"NOVO HAMBURGO","code":"BR-RS-NHO"},
              {"name":"OSORIO","code":"BR-RS-OSO"},
              {"name":"PALMEIRA DAS MISSOES","code":"BR-RS-PME"},
              {"name":"PANAMBI","code":"BR-RS-PNI"},
              {"name":"PARAÍ","code":"BR-RS-PRJ"},
              {"name":"PARAISO DO SUL","code":"BR-RS-PUL"},
              {"name":"PARECI NOVO","code":"BR-RS-PNO"},
              {"name":"PAROBE","code":"BR-RS-PRE"},
              {"name":"PASSO FUNDO","code":"BR-RS-PFB"},
              {"name":"PAVERAMA","code":"BR-RS-PAM"},
              {"name":"PELOTAS","code":"BR-RS-PET"},
              {"name":"PICADA CAFÉ","code":"BR-RS-PIC"},
              {"name":"PINHEIRO MACHADO","code":"BR-RS-PRC"},
              {"name":"PIRATINI","code":"BR-RS-PIA"},
              {"name":"PLANALTO","code":"BR-RS-PLT"},
              {"name":"PORTAO","code":"BR-RS-PAO"},
              {"name":"PÔRTO ALEGRE","code":"BR-RS-POA"},
              {"name":"PORTO XAVIER","code":"BR-RS-PXI"},
              {"name":"QUARAI","code":"BR-RS-QRA"},
              {"name":"RESTINGA SECA","code":"BR-RS-RSA"},
              {"name":"RIO GRANDE","code":"BR-RS-RIG"},
              {"name":"RIOZINHO","code":"BR-RS-RIZ"},
              {"name":"ROLANTE","code":"BR-RS-ROT"},
              {"name":"ROSARIO DO SUL","code":"BR-RS-RSO"},
              {"name":"SALVADOR DO SUL","code":"BR-RS-SAD"},
              {"name":"SANANDUVA","code":"BR-RS-SNU"},
              {"name":"SANTA CLARA DO SUL","code":"BR-RS-SCS"},
              {"name":"SANTA CRUZ DO SUL","code":"BR-RS-DSU"},
              {"name":"SANTA MARIA","code":"BR-RS-RIA"},
              {"name":"SANTA ROSA","code":"BR-RS-SRA"},
              {"name":"SANTA VITÓRIA DO PALMAR","code":"BR-RS-SVP"},
              {"name":"SANTANA DO LIVRAMENTO","code":"BR-RS-SLI"},
              {"name":"SANTIAGO","code":"BR-RS-SGO"},
              {"name":"SANTO ANGELO","code":"BR-RS-GEL"},
              {"name":"SANTO ANTONIO DA PATRULHA","code":"BR-RS-SPU"},
              {"name":"SANTO CRISTO","code":"BR-RS-SCT"},
              {"name":"SAO BORJA","code":"BR-RS-BJA"},
              {"name":"SAO FRANCISCO DE ASSIS","code":"BR-RS-SFA"},
              {"name":"SAO FRANCISCO DE PAULA","code":"BR-RS-SRP"},
              {"name":"SAO GABRIEL","code":"BR-RS-SGA"},
              {"name":"SAO JERONIMO","code":"BR-RS-SJO"},
              {"name":"SÃO JOSÉ DO NORTE","code":"BR-RS-SNN"},
              {"name":"SAO JOSE DOS AUSENTES","code":"BR-RS-SJT"},
              {"name":"SÃO LEOPOLDO","code":"BR-RS-SLO"},
              {"name":"SAO LOURENÇO DO SUL","code":"BR-RS-SQY"},
              {"name":"SAO LUIZ GONZAGA","code":"BR-RS-SZG"},
              {"name":"SAO MARCOS","code":"BR-RS-SMC"},
              {"name":"SAO MIGUEL DAS MISSOES","code":"BR-RS-SMI"},
              {"name":"SAO PEDRO DO SUL","code":"BR-RS-SDS"},
              {"name":"SAO SEBASTIAO DO CAÍ","code":"BR-RS-QHF"},
              {"name":"SAO SEPE","code":"BR-RS-SSE"},
              {"name":"SAPIRANGA","code":"BR-RS-SAP"},
              {"name":"SAPUCAIA DO SUL","code":"BR-RS-SUS"},
              {"name":"SARANDI","code":"BR-RS-SND"},
              {"name":"SARANDUVA","code":"BR-RS-SVA"},
              {"name":"SELBACH","code":"BR-RS-SBH"},
              {"name":"SERAFINA CORREA","code":"BR-RS-SCE"},
              {"name":"SOLEDADE","code":"BR-RS-SDA"},
              {"name":"TAPEJARA","code":"BR-RS-TAJ"},
              {"name":"TAPERA","code":"BR-RS-TPE"},
              {"name":"TAPES","code":"BR-RS-TAP"},
              {"name":"TAQUARA","code":"BR-RS-TQU"},
              {"name":"TAQUARI","code":"BR-RS-TAQ"},
              {"name":"TAVARES","code":"BR-RS-TVR"},
              {"name":"TENENTE PORTELA","code":"BR-RS-TPO"},
              {"name":"TERRA DE AREIA","code":"BR-RS-TAR"},
              {"name":"TEUTÔNIA","code":"BR-RS-TEU"},
              {"name":"TORRES","code":"BR-RS-TSQ"},
              {"name":"TRAMANDAÍ","code":"BR-RS-TRM"},
              {"name":"TRÊS COROAS","code":"BR-RS-ROA"},
              {"name":"TRES DE MAIO","code":"BR-RS-TMI"},
              {"name":"TRES PASSOS","code":"BR-RS-TPS"},
              {"name":"TRIUNFO","code":"BR-RS-TFO"},
              {"name":"TUPANCIRETA","code":"BR-RS-TPC"},
              {"name":"TUPANDI","code":"BR-RS-TUI"},
              {"name":"TUPARENDI","code":"BR-RS-TDI"},
              {"name":"URUGUAIANA","code":"BR-RS-URG"},
              {"name":"VACARIA","code":"BR-RS-VAC"},
              {"name":"VALE DO SOL","code":"BR-RS-VSO"},
              {"name":"VENANCIO AIRES","code":"BR-RS-VCE"},
              {"name":"VERANOPOLIS","code":"BR-RS-VOP"},
              {"name":"VIAMAO","code":"BR-RS-VMA"},
              {"name":"WESTFÁLIA","code":"BR-RS-WTF"},
              {"name":"XANGRILA","code":"BR-RS-XGA"},]
        },
        {
            "name": "Rondônia",
            "code": "BR-RO",
            "cities": [{"name":"ALTA FLORESTA DO OESTE","code":"BR-RO-AFO"},
              {"name":"ARIQUEMES","code":"BR-RO-AQM"},
              {"name":"BURITIS","code":"BR-RO-BUS"},
              {"name":"CACOAL","code":"BR-RO-CCL"},
              {"name":"CEREJEIRAS","code":"BR-RO-CJS"},
              {"name":"COLORADO DO OESTE","code":"BR-RO-CSW"},
              {"name":"COSTA MARQUES","code":"BR-RO-CQS"},
              {"name":"ESPIGAO DO OESTE","code":"BR-RO-EOT"},
              {"name":"GUAJARÁ-MIRIM","code":"BR-RO-GJM"},
              {"name":"ITAPUÃ DO OESTE","code":"BR-RO-IDW"},
              {"name":"JARU","code":"BR-RO-JAR"},
              {"name":"JI-PARANÁ","code":"BR-RO-JPR"},
              {"name":"MACHADINHO D'OESTE","code":"BR-RO-MDW"},
              {"name":"OURO PRETO DO OESTE","code":"BR-RO-OPE"},
              {"name":"PEDRAS NEGRAS","code":"BR-RO-PEN"},
              {"name":"PIMENTA BUENO","code":"BR-RO-PBQ"},
              {"name":"PIMENTEIRAS DO OESTE","code":"BR-RO-PSO"},
              {"name":"PÔRTO VELHO","code":"BR-RO-PVH"},
              {"name":"PRESIDENTE MEDICI","code":"BR-RO-PMC"},
              {"name":"ROLIM DE MOURA","code":"BR-RO-RMU"},
              {"name":"VILHENA","code":"BR-RO-VHA"},]
        },
        {
            "name": "Roraima",
            "code": "BR-RR",
            "cities": [{"name":"BOA VISTA","code":"BR-RR-BVB"},]
        },
        {
            "name": "Santa Catarina",
            "code": "BR-SC",
            "cities": [{"name":"ABELARDO LUZ","code":"BR-SC-ADL"},
              {"name":"AGROLANDIA","code":"BR-SC-ARO"},
              {"name":"AGUAS MORNAS","code":"BR-SC-AMR"},
              {"name":"ALFREDO WAGNER","code":"BR-SC-AWR"},
              {"name":"ANCHIETA","code":"BR-SC-ACH"},
              {"name":"ANTONIO CARLOS","code":"BR-SC-ACS"},
              {"name":"APIUNA","code":"BR-SC-AUA"},
              {"name":"ARAQUARI","code":"BR-SC-ARQ"},
              {"name":"ARARANGUA","code":"BR-SC-ARN"},
              {"name":"ASCURRA","code":"BR-SC-ASC"},
              {"name":"BALNEÁRIO BARRA DO SUL","code":"BR-SC-BCS"},
              {"name":"BALNEÁRIO DE CAMBORIÚ","code":"BR-SC-BAC"},
              {"name":"BALNEÁRIO DO RINCÃO","code":"BR-SC-BOR"},
              {"name":"BARRA VELHA","code":"BR-SC-BVA"},
              {"name":"BENEDITO NOVO","code":"BR-SC-BNV"},
              {"name":"BIGUACU","code":"BR-SC-BGC"},
              {"name":"BLUMENAU","code":"BR-SC-BNU"},
              {"name":"BOM RETIRO","code":"BR-SC-BRR"},
              {"name":"BOMBINHAS","code":"BR-SC-BBS"},
              {"name":"BRACO DO NORTE","code":"BR-SC-BNT"},
              {"name":"BRAÇO DO TROMBUDO","code":"BR-SC-BTR"},
              {"name":"BRUSQUE","code":"BR-SC-QAB"},
              {"name":"CACADOR","code":"BR-SC-CFC"},
              {"name":"CAIBI","code":"BR-SC-CBI"},
              {"name":"CAMBORIÚ","code":"BR-SC-CBU"},
              {"name":"CAMPO ALEGRE","code":"BR-SC-CAG"},
              {"name":"CAMPOS NOVOS","code":"BR-SC-CNS"},
              {"name":"CANOINHAS","code":"BR-SC-CHS"},
              {"name":"CAPÃO ALTO","code":"BR-SC-CAO"},
              {"name":"CAPINZAL","code":"BR-SC-CPZ"},
              {"name":"CAPIVARI DE BAIXO","code":"BR-SC-CBX"},
              {"name":"CATANDUVAS","code":"BR-SC-CTV"},
              {"name":"CHAPECÓ","code":"BR-SC-CHA"},
              {"name":"COCAL DO SUL","code":"BR-SC-CLL"},
              {"name":"CONCÓRDIA","code":"BR-SC-CCD"},
              {"name":"CORONEL FREITAS","code":"BR-SC-COF"},
              {"name":"CORREIO PINTO","code":"BR-SC-CPJ"},
              {"name":"CORUPA","code":"BR-SC-CRA"},
              {"name":"CRICIÚMA","code":"BR-SC-CCM"},
              {"name":"CURITIBANOS","code":"BR-SC-CRN"},
              {"name":"CURTIBANOS","code":"BR-SC-CUR"},
              {"name":"DIONISIO CERQUEIRA","code":"BR-SC-DCA"},
              {"name":"DONA EMMA","code":"BR-SC-DMM"},
              {"name":"FLORIANÓPOLIS","code":"BR-SC-FLN"},
              {"name":"FORQUILHINHA","code":"BR-SC-FOA"},
              {"name":"FRAIBURGO","code":"BR-SC-FGO"},
              {"name":"GAROPABA","code":"BR-SC-GPA"},
              {"name":"GASPAR","code":"BR-SC-GSA"},
              {"name":"GOVERNADOR CELSO RAMOS","code":"BR-SC-GCR"},
              {"name":"GRAO PARA","code":"BR-SC-GPR"},
              {"name":"GRAVATAL","code":"BR-SC-GVL"},
              {"name":"GUABIRUBA","code":"BR-SC-GUB"},
              {"name":"GUARAMIRIM","code":"BR-SC-GRM"},
              {"name":"GUARDA DO EMBAU","code":"BR-SC-GEM"},
              {"name":"GUARUJÁ DO SUL","code":"BR-SC-GDS"},
              {"name":"GUATAMBU","code":"BR-SC-GUT"},
              {"name":"HERVAL D OESTE","code":"BR-SC-HDT"},
              {"name":"IBIRAMA","code":"BR-SC-IMA"},
              {"name":"ICARA","code":"BR-SC-ICR"},
              {"name":"ILHA DO PAPAGAIO","code":"BR-SC-IPP"},
              {"name":"ILHOTA","code":"BR-SC-ILH"},
              {"name":"IMBITUBA","code":"BR-SC-IBB"},
              {"name":"INDAIAL","code":"BR-SC-IDA"},
              {"name":"IOMERÊ","code":"BR-SC-IOM"},
              {"name":"IPORA DO OESTE","code":"BR-SC-IOT"},
              {"name":"IRANI","code":"BR-SC-INI"},
              {"name":"ITA","code":"BR-SC-XTA"},
              {"name":"ITAIOPOLIS","code":"BR-SC-IPL"},
              {"name":"ITAJAI","code":"BR-SC-ITJ"},
              {"name":"ITAPEMA","code":"BR-SC-IEM"},
              {"name":"ITAPIRANGA","code":"BR-SC-IRN"},
              {"name":"ITAPOÁ","code":"BR-SC-IOA"},
              {"name":"ITUPORANGA","code":"BR-SC-ITD"},
              {"name":"JAGUARUNA","code":"BR-SC-JGG"},
              {"name":"JARAGUÁ DO SUL","code":"BR-SC-JSL"},
              {"name":"JOAÇABA","code":"BR-SC-JCB"},
              {"name":"JOINVILLE","code":"BR-SC-JOI"},
              {"name":"JOSÉ BOITEUX","code":"BR-SC-JBX"},
              {"name":"LAGES","code":"BR-SC-LAJ"},
              {"name":"LAGUNA","code":"BR-SC-LNG"},
              {"name":"LAURO MULLER","code":"BR-SC-LMU"},
              {"name":"LEBON REGIS","code":"BR-SC-LER"},
              {"name":"LINDÓIA DO SUL","code":"BR-SC-LDS"},
              {"name":"LONTRAS","code":"BR-SC-LOI"},
              {"name":"LUIZ ALVES","code":"BR-SC-LAL"},
              {"name":"LUZERNA","code":"BR-SC-LZB"},
              {"name":"MARAVILHA","code":"BR-SC-MVA"},
              {"name":"MASSARANDUBA","code":"BR-SC-MSA"},
              {"name":"MONDAÍ","code":"BR-SC-MOI"},
              {"name":"MORRO GRANDE","code":"BR-SC-MGD"},
              {"name":"NAVEGANTES","code":"BR-SC-NVT"},
              {"name":"NOVA TRENTO","code":"BR-SC-NOT"},
              {"name":"NOVA VENEZA","code":"BR-SC-NVE"},
              {"name":"ORLEANS","code":"BR-SC-OLS"},
              {"name":"OTACILIO COSTA","code":"BR-SC-OCA"},
              {"name":"PALHOCA","code":"BR-SC-PHO"},
              {"name":"PALMA SOLA","code":"BR-SC-PAS"},
              {"name":"PALMEIRA","code":"BR-SC-PSC"},
              {"name":"PALMITOS","code":"BR-SC-PLO"},
              {"name":"PENHA","code":"BR-SC-PHA"},
              {"name":"PICARRAS","code":"BR-SC-PRR"},
              {"name":"PINHALZINHO","code":"BR-SC-PHZ"},
              {"name":"PIRATUBA","code":"BR-SC-PRB"},
              {"name":"POMERODE","code":"BR-SC-POM"},
              {"name":"PORTO BELO","code":"BR-SC-PBO"},
              {"name":"PORTO UNIAO","code":"BR-SC-PUN"},
              {"name":"PRESIDENTE GETULIO","code":"BR-SC-PGL"},
              {"name":"RIO DO CAMPO","code":"BR-SC-RIC"},
              {"name":"RIO DO SUL","code":"BR-SC-RDS"},
              {"name":"RIO DOS CEDROS","code":"BR-SC-RCD"},
              {"name":"RIO NEGRINHO","code":"BR-SC-RNE"},
              {"name":"RODEIO","code":"BR-SC-ROD"},
              {"name":"SALETE","code":"BR-SC-SEE"},
              {"name":"SANTA CECÍLIA","code":"BR-SC-SCC"},
              {"name":"SANTA ROSA DO SUL","code":"BR-SC-SRU"},
              {"name":"SANTO AMARO DA IMPERATRIZ","code":"BR-SC-SRM"},
              {"name":"SAO CARLOS","code":"BR-SC-SCL"},
              {"name":"SÃO FRANCISCO DO SUL","code":"BR-SC-SFS"},
              {"name":"SÃO JOÃO BATISTA","code":"BR-SC-SJW"},
              {"name":"SAO JOAQUIM","code":"BR-SC-SJQ"},
              {"name":"SAO JOSE","code":"BR-SC-SJE"},
              {"name":"SAO JOSE DO CEDRO","code":"BR-SC-SJD"},
              {"name":"SAO LOURENCO DO OESTE","code":"BR-SC-SLT"},
              {"name":"SAO LUDGERO","code":"BR-SC-SLG"},
              {"name":"SÃO MIGUEL D'OESTE","code":"BR-SC-SMZ"},
              {"name":"SAO PEDRO ALCANTARA","code":"BR-SC-SPE"},
              {"name":"SAUDADES","code":"BR-SC-SUD"},
              {"name":"SCHROEDER","code":"BR-SC-SDE"},
              {"name":"SIDERÓPOLIS","code":"BR-SC-SIS"},
              {"name":"SINIMBU","code":"BR-SC-SBU"},
              {"name":"SOMBRIO","code":"BR-SC-SBI"},
              {"name":"TAIO","code":"BR-SC-TAI"},
              {"name":"TANGARA","code":"BR-SC-TGR"},
              {"name":"TIJUCAS","code":"BR-SC-TJA"},
              {"name":"TIMBÓ","code":"BR-SC-TMO"},
              {"name":"TRES BARRAS","code":"BR-SC-TRS"},
              {"name":"TREZE DE MAIO","code":"BR-SC-TRZ"},
              {"name":"TREZE TILIAS","code":"BR-SC-TZT"},
              {"name":"TURVO","code":"BR-SC-TVO"},
              {"name":"URUBICI","code":"BR-SC-UBC"},
              {"name":"URUSSANGA","code":"BR-SC-USS"},
              {"name":"VARGEM BONITA","code":"BR-SC-VBO"},
              {"name":"VIDAL RAMOS","code":"BR-SC-VRA"},
              {"name":"VIDEIRA","code":"BR-SC-VIA"},
              {"name":"WITMARSUM","code":"BR-SC-WIT"},
              {"name":"XAXIM","code":"BR-SC-XAX"},]
        },
        {
            "name": "São Paulo",
            "code": "BR-SP",
            "cities": [{"name":"ADAMANTINA","code":"BR-SP-AMT"},
              {"name":"AGUAI","code":"BR-SP-AGU"},
              {"name":"AGUAS DA PRATA","code":"BR-SP-ASP"},
              {"name":"AGUAS DE LINDOIA","code":"BR-SP-AGL"},
              {"name":"AGUAS DE SANTA BARBARA","code":"BR-SP-ASB"},
              {"name":"AGUAS DE SAO PEDRO","code":"BR-SP-ASO"},
              {"name":"AGUDOS","code":"BR-SP-AGD"},
              {"name":"ALTINOPOLIS","code":"BR-SP-APO"},
              {"name":"ALUMÍNIO","code":"BR-SP-AMO"},
              {"name":"ALVARES MACHADO","code":"BR-SP-AMH"},
              {"name":"ALVINLÂNDIA","code":"BR-SP-AVD"},
              {"name":"AMERICANA","code":"BR-SP-AME"},
              {"name":"AMERICO BRASILIENSE","code":"BR-SP-ABI"},
              {"name":"AMERICO DE CAMPOS","code":"BR-SP-ACP"},
              {"name":"AMPARO","code":"BR-SP-AMP"},
              {"name":"ANALANDIA","code":"BR-SP-ANI"},
              {"name":"ANDRADINA","code":"BR-SP-AAN"},
              {"name":"ANGATUBA","code":"BR-SP-AGT"},
              {"name":"ANHEMBI","code":"BR-SP-ANH"},
              {"name":"APARECIDA","code":"BR-SP-API"},
              {"name":"APIAI","code":"BR-SP-APA"},
              {"name":"ARAÇARIGUAMA","code":"BR-SP-ARM"},
              {"name":"ARAÇATUBA","code":"BR-SP-ARU"},
              {"name":"ARACOIABA DA SERRA","code":"BR-SP-ASR"},
              {"name":"ARARAQUARA","code":"BR-SP-AQA"},
              {"name":"ARARAS","code":"BR-SP-ARA"},
              {"name":"ARIRANHA","code":"BR-SP-ARH"},
              {"name":"ARTUR NOGUEIRA","code":"BR-SP-ANG"},
              {"name":"ARUJA","code":"BR-SP-AUJ"},
              {"name":"ASSIS","code":"BR-SP-AIF"},
              {"name":"ATIBAIA","code":"BR-SP-ABA"},
              {"name":"AURIFLAMA","code":"BR-SP-AFM"},
              {"name":"AVARE","code":"BR-SP-AVA"},
              {"name":"BALBINOS","code":"BR-SP-BLB"},
              {"name":"BÁLSAMO","code":"BR-SP-BLS"},
              {"name":"BANANAL","code":"BR-SP-BNA"},
              {"name":"BARBOSA","code":"BR-SP-BSA"},
              {"name":"BARIRI","code":"BR-SP-BIR"},
              {"name":"BARRA BONITA","code":"BR-SP-BBO"},
              {"name":"BARRA DO CHAPÉU","code":"BR-SP-BCH"},
              {"name":"BARRETOS","code":"BR-SP-BAT"},
              {"name":"BARRINHA","code":"BR-SP-BRH"},
              {"name":"BARUERI","code":"BR-SP-BRI"},
              {"name":"BASTOS","code":"BR-SP-BTS"},
              {"name":"BATATAIS","code":"BR-SP-BTA"},
              {"name":"BAURU","code":"BR-SP-BAU"},
              {"name":"BEBEDOURO","code":"BR-SP-BEB"},
              {"name":"BENTO DE ABREU","code":"BR-SP-BAB"},
              {"name":"BERNARDINO DE CAMPOS","code":"BR-SP-BCP"},
              {"name":"BERTIOGA","code":"BR-SP-BTG"},
              {"name":"BILAC","code":"BR-SP-BLA"},
              {"name":"BIRIGUI","code":"BR-SP-BII"},
              {"name":"BIRITIBA-MIRIM","code":"BR-SP-BBR"},
              {"name":"BOA ESPERANCA DO SUL","code":"BR-SP-BSU"},
              {"name":"BOCAINA","code":"BR-SP-BOC"},
              {"name":"BOFETE","code":"BR-SP-BOF"},
              {"name":"BOITUVA","code":"BR-SP-BTV"},
              {"name":"BOM JESUS DOS PERDOES","code":"BR-SP-BJO"},
              {"name":"BORACEIA","code":"BR-SP-BRE"},
              {"name":"BORBOREMA","code":"BR-SP-BBM"},
              {"name":"BOTUCATU","code":"BR-SP-QCJ"},
              {"name":"BRAGANCA PAULISTA","code":"BR-SP-BJP"},
              {"name":"BRAUNA","code":"BR-SP-BRN"},
              {"name":"BRODOWSKI","code":"BR-SP-BDW"},
              {"name":"BROTAS","code":"BR-SP-BOS"},
              {"name":"BURI","code":"BR-SP-BUR"},
              {"name":"BURITAMA","code":"BR-SP-BTM"},
              {"name":"CABREUVA","code":"BR-SP-CRU"},
              {"name":"CAÇAPAVA","code":"BR-SP-CAP"},
              {"name":"CACHOEIRA PAULISTA","code":"BR-SP-CPS"},
              {"name":"CACONDE","code":"BR-SP-CCO"},
              {"name":"CAFELANDIA","code":"BR-SP-CFL"},
              {"name":"CAIEIRAS","code":"BR-SP-CIE"},
              {"name":"CAJAMAR","code":"BR-SP-CJM"},
              {"name":"CAJATI","code":"BR-SP-CJI"},
              {"name":"CAJOBI","code":"BR-SP-CJO"},
              {"name":"CAJURU","code":"BR-SP-CJU"},
              {"name":"CAMPINA DO MONTE ALEGRE","code":"BR-SP-CAM"},
              {"name":"CAMPINAS","code":"BR-SP-CPQ"},
              {"name":"CAMPO LIMPO PAULISTA","code":"BR-SP-CLP"},
              {"name":"CAMPOS DO JORDAO","code":"BR-SP-QJO"},
              {"name":"CANANEIA","code":"BR-SP-CNI"},
              {"name":"CANDIDO MOTA","code":"BR-SP-CMT"},
              {"name":"CANDIDO RODRIGUES","code":"BR-SP-CDU"},
              {"name":"CANITAR","code":"BR-SP-CNR"},
              {"name":"CAPAO BONITO","code":"BR-SP-CBN"},
              {"name":"CAPELA DO ALTO","code":"BR-SP-CLT"},
              {"name":"CAPIVARI","code":"BR-SP-CPI"},
              {"name":"CARAGUATATUBA","code":"BR-SP-CGU"},
              {"name":"CARAPICUIBA","code":"BR-SP-CIU"},
              {"name":"CARDOSO","code":"BR-SP-CDR"},
              {"name":"CASA BRANCA","code":"BR-SP-CSN"},
              {"name":"CASTILHO","code":"BR-SP-CHO"},
              {"name":"CATANDUVA","code":"BR-SP-CDV"},
              {"name":"CEDRAL","code":"BR-SP-CED"},
              {"name":"CERQUEIRA CESAR","code":"BR-SP-CQR"},
              {"name":"CERQUILHO","code":"BR-SP-CQO"},
              {"name":"CESARIO LANGE","code":"BR-SP-CLE"},
              {"name":"CHARQUEADA","code":"BR-SP-CHQ"},
              {"name":"CHAVANTES","code":"BR-SP-CVA"},
              {"name":"CLEMENTINA","code":"BR-SP-CTA"},
              {"name":"COLINA","code":"BR-SP-CNA"},
              {"name":"COLOMBIA","code":"BR-SP-CLB"},
              {"name":"CONCHAL","code":"BR-SP-CHL"},
              {"name":"CONCHAS","code":"BR-SP-CCS"},
              {"name":"CONGONHAS APT/SÃO PAULO","code":"BR-SP-CGH"},
              {"name":"CORDEIRÓPOLIS","code":"BR-SP-CRS"},
              {"name":"COSMOPOLIS","code":"BR-SP-CSP"},
              {"name":"COSMORAMA","code":"BR-SP-CMM"},
              {"name":"COTIA","code":"BR-SP-COA"},
              {"name":"CRAVINHOS","code":"BR-SP-CVH"},
              {"name":"CRUZEIRO","code":"BR-SP-CZI"},
              {"name":"CUBATAO","code":"BR-SP-CUO"},
              {"name":"CUNHA","code":"BR-SP-CUH"},
              {"name":"DESCALVADO","code":"BR-SP-DCO"},
              {"name":"DIADEMA","code":"BR-SP-DID"},
              {"name":"DIRCE REIS","code":"BR-SP-DRS"},
              {"name":"DIVINOLANDIA","code":"BR-SP-DVD"},
              {"name":"DOIS CÓRREGOS","code":"BR-SP-DSC"},
              {"name":"DOLCINOPOLIS","code":"BR-SP-DCP"},
              {"name":"DOURADO","code":"BR-SP-DUO"},
              {"name":"DRACENA","code":"BR-SP-DRA"},
              {"name":"DUARTINA","code":"BR-SP-DTI"},
              {"name":"ELDORADO","code":"BR-SP-ELO"},
              {"name":"ELIAS FAUSTO","code":"BR-SP-EFU"},
              {"name":"EMBAUBA","code":"BR-SP-EUB"},
              {"name":"EMBU DAS ARTES","code":"BR-SP-EDA"},
              {"name":"EMBU-GUACU","code":"BR-SP-EGU"},
              {"name":"ENGENHEIRO COELHO","code":"BR-SP-ECH"},
              {"name":"ESTIVA GERBI","code":"BR-SP-EGI"},
              {"name":"ESTRELA DO OESTE","code":"BR-SP-EOS"},
              {"name":"EUGENIO DE MELO","code":"BR-SP-EDM"},
              {"name":"FARTURA","code":"BR-SP-FTU"},
              {"name":"FERNANDO PRESTES","code":"BR-SP-FPE"},
              {"name":"FERNANDOPOLIS","code":"BR-SP-FPO"},
              {"name":"FERRAZ DE VASCONCELOS","code":"BR-SP-FVA"},
              {"name":"FLORIDA PAULISTA","code":"BR-SP-FPS"},
              {"name":"FRANCA","code":"BR-SP-FRC"},
              {"name":"FRANCISCO MORATO","code":"BR-SP-FTO"},
              {"name":"FRANCO DA ROCHA","code":"BR-SP-FRO"},
              {"name":"GALIA","code":"BR-SP-GLA"},
              {"name":"GARCA","code":"BR-SP-GCA"},
              {"name":"GAVIÃO PEIXOTO","code":"BR-SP-GPX"},
              {"name":"GENERAL SALGADO","code":"BR-SP-GSO"},
              {"name":"GETULINA","code":"BR-SP-GTU"},
              {"name":"GUAIMBE","code":"BR-SP-GAB"},
              {"name":"GUAIRA","code":"BR-SP-GIR"},
              {"name":"GUAPIACU","code":"BR-SP-GPC"},
              {"name":"GUAPIARA","code":"BR-SP-GPI"},
              {"name":"GUARA","code":"BR-SP-GAR"},
              {"name":"GUARACAI","code":"BR-SP-GRI"},
              {"name":"GUARACI","code":"BR-SP-GAI"},
              {"name":"GUARARAPES","code":"BR-SP-GRE"},
              {"name":"GUARAREMA","code":"BR-SP-GAM"},
              {"name":"GUARATINGUETÁ","code":"BR-SP-GTA"},
              {"name":"GUARIBA","code":"BR-SP-GRB"},
              {"name":"GUARUJÁ","code":"BR-SP-GUJ"},
              {"name":"GUARULHOS","code":"BR-SP-QCV"},
              {"name":"GUARULHOS APT/SÃO PAULO","code":"BR-SP-GRU"},
              {"name":"GUZOLANDIA","code":"BR-SP-GZO"},
              {"name":"HERCULANDIA","code":"BR-SP-HLA"},
              {"name":"HOLAMBRA","code":"BR-SP-HLR"},
              {"name":"HORTOLÂNDIA","code":"BR-SP-HTL"},
              {"name":"IACRI","code":"BR-SP-ICI"},
              {"name":"IBATE","code":"BR-SP-IBC"},
              {"name":"IBIRA","code":"BR-SP-IBR"},
              {"name":"IBIRAREMA","code":"BR-SP-IRM"},
              {"name":"IBITINGA","code":"BR-SP-ING"},
              {"name":"IBITIUVA","code":"BR-SP-IVU"},
              {"name":"IBIUNA","code":"BR-SP-IBU"},
              {"name":"ICEM","code":"BR-SP-ICM"},
              {"name":"IEPE","code":"BR-SP-IEP"},
              {"name":"IGARAPAVA","code":"BR-SP-IRP"},
              {"name":"IGUAPE","code":"BR-SP-IGP"},
              {"name":"IGUARAPAVA","code":"BR-SP-IGV"},
              {"name":"ILHA COMPRIDA","code":"BR-SP-ICO"},
              {"name":"ILHA DO CARDOSO","code":"BR-SP-ICS"},
              {"name":"ILHA SOLTEIRA","code":"BR-SP-ISA"},
              {"name":"ILHABELA","code":"BR-SP-IBE"},
              {"name":"INDAIATUBA","code":"BR-SP-IDU"},
              {"name":"INDIANA","code":"BR-SP-INA"},
              {"name":"IPERÓ","code":"BR-SP-IPQ"},
              {"name":"IPEÚNA","code":"BR-SP-IPE"},
              {"name":"IPORANGA","code":"BR-SP-IRG"},
              {"name":"IPUA","code":"BR-SP-PUA"},
              {"name":"IRACEMAPOLIS","code":"BR-SP-IPS"},
              {"name":"IRAPUÃ","code":"BR-SP-RAP"},
              {"name":"ITABERA","code":"BR-SP-IAB"},
              {"name":"ITAI","code":"BR-SP-IAI"},
              {"name":"ITAJOBI","code":"BR-SP-IJB"},
              {"name":"ITANHAEM","code":"BR-SP-ITH"},
              {"name":"ITAPECERICA DA SERRA","code":"BR-SP-ISR"},
              {"name":"ITAPETININGA","code":"BR-SP-IIG"},
              {"name":"ITAPEVA","code":"BR-SP-IAV"},
              {"name":"ITAPEVI","code":"BR-SP-IEV"},
              {"name":"ITAPIRA","code":"BR-SP-TPI"},
              {"name":"ITAPOLIS","code":"BR-SP-ILS"},
              {"name":"ITAPORANGA","code":"BR-SP-IOG"},
              {"name":"ITAPUÍ","code":"BR-SP-IUI"},
              {"name":"ITAQUAQUECETUBA","code":"BR-SP-IQU"},
              {"name":"ITARARE","code":"BR-SP-TRA"},
              {"name":"ITARIRI","code":"BR-SP-III"},
              {"name":"ITATIBA","code":"BR-SP-IIA"},
              {"name":"ITATINGA","code":"BR-SP-IAT"},
              {"name":"ITIRAPINA","code":"BR-SP-IAP"},
              {"name":"ITOBI","code":"BR-SP-IOB"},
              {"name":"ITU","code":"BR-SP-ITU"},
              {"name":"ITUPEVA","code":"BR-SP-IPV"},
              {"name":"ITUVERAVA","code":"BR-SP-IVA"},
              {"name":"JABOTICABAL","code":"BR-SP-JBC"},
              {"name":"JACAREI","code":"BR-SP-JCE"},
              {"name":"JACI","code":"BR-SP-JCZ"},
              {"name":"JACUPIRANGA","code":"BR-SP-JPI"},
              {"name":"JAGUARE","code":"BR-SP-JGR"},
              {"name":"JAGUARIUNA","code":"BR-SP-JGN"},
              {"name":"JALES","code":"BR-SP-JLS"},
              {"name":"JAMBEIRO","code":"BR-SP-JBE"},
              {"name":"JANDIRA","code":"BR-SP-JDI"},
              {"name":"JARACUPIRANGA","code":"BR-SP-JPG"},
              {"name":"JARDIM SAO PAULO","code":"BR-SP-TMP21290"},
              {"name":"JARDINOPOLIS","code":"BR-SP-JPS"},
              {"name":"JARINU","code":"BR-SP-JRI"},
              {"name":"JAU","code":"BR-SP-JAU"},
              {"name":"JOANOPOLIS","code":"BR-SP-JOP"},
              {"name":"JOSE BONIFACIO","code":"BR-SP-JBF"},
              {"name":"JUNDIAÍ","code":"BR-SP-JUN"},
              {"name":"JUNQUEIROPOLIS","code":"BR-SP-JRO"},
              {"name":"JUQUIA","code":"BR-SP-JQU"},
              {"name":"JUQUITIBA","code":"BR-SP-JQB"},
              {"name":"JURUBATUBA","code":"BR-SP-JBU"},
              {"name":"LARANJAL PAULISTA","code":"BR-SP-LPU"},
              {"name":"LAVINIA","code":"BR-SP-LAV"},
              {"name":"LAVRINHAS","code":"BR-SP-LAX"},
              {"name":"LEME","code":"BR-SP-LEM"},
              {"name":"LIMEIRA","code":"BR-SP-LMR"},
              {"name":"LINDOIA","code":"BR-SP-LIN"},
              {"name":"LINS","code":"BR-SP-LIP"},
              {"name":"LORENA","code":"BR-SP-LRA"},
              {"name":"LOURDES","code":"BR-SP-LOU"},
              {"name":"LUCELIA","code":"BR-SP-LUC"},
              {"name":"LUIS ANTONIO","code":"BR-SP-LAN"},
              {"name":"LUIZIANIA","code":"BR-SP-LZI"},
              {"name":"MACATUBA","code":"BR-SP-MTU"},
              {"name":"MAFRA","code":"BR-SP-MAF"},
              {"name":"MAIRINQUE","code":"BR-SP-MQE"},
              {"name":"MAIRIPORÃ","code":"BR-SP-MPA"},
              {"name":"MANDURI","code":"BR-SP-MNI"},
              {"name":"MARACAI","code":"BR-SP-MCI"},
              {"name":"MARAPOAMA","code":"BR-SP-MKM"},
              {"name":"MARÍLIA","code":"BR-SP-MII"},
              {"name":"MARTINOPOLIS","code":"BR-SP-MPO"},
              {"name":"MATAO","code":"BR-SP-MAT"},
              {"name":"MAUA","code":"BR-SP-MUA"},
              {"name":"MENDONCA","code":"BR-SP-MDC"},
              {"name":"MIGUELOPOLIS","code":"BR-SP-MPI"},
              {"name":"MIRACATU","code":"BR-SP-MCT"},
              {"name":"MIRANDOPOLIS","code":"BR-SP-MDO"},
              {"name":"MIRANTE DO PARANAPANEMA","code":"BR-SP-MPM"},
              {"name":"MIRASSOL","code":"BR-SP-MIR"},
              {"name":"MOCOCA","code":"BR-SP-MOO"},
              {"name":"MOGI DAS CRUZES","code":"BR-SP-QMI"},
              {"name":"MOGI GUACU","code":"BR-SP-MGG"},
              {"name":"MOGI MIRIM","code":"BR-SP-MMI"},
              {"name":"MOMBUCA","code":"BR-SP-MBU"},
              {"name":"MONGAGUA","code":"BR-SP-MNU"},
              {"name":"MONTE ALEGRE DO SUL","code":"BR-SP-MAS"},
              {"name":"MONTE ALTO","code":"BR-SP-MTT"},
              {"name":"MONTE APRAZIVEL","code":"BR-SP-MAZ"},
              {"name":"MONTE AZUL PAULISTA","code":"BR-SP-MAP"},
              {"name":"MONTE MOR","code":"BR-SP-MTM"},
              {"name":"MONTE NEGRO","code":"BR-SP-MNG"},
              {"name":"MONTEIRO LOBATO","code":"BR-SP-MLO"},
              {"name":"MORRO AGUDO","code":"BR-SP-MOA"},
              {"name":"MORUNGABA","code":"BR-SP-MRG"},
              {"name":"NAZARE PAULISTA","code":"BR-SP-NPU"},
              {"name":"NHANDEARA","code":"BR-SP-NHA"},
              {"name":"NOVA EUROPA","code":"BR-SP-NEU"},
              {"name":"NOVA GRANADA","code":"BR-SP-NGA"},
              {"name":"NOVA ODESSA","code":"BR-SP-NOD"},
              {"name":"NUPORANGA","code":"BR-SP-NPG"},
              {"name":"OCAUÇU","code":"BR-SP-OCU"},
              {"name":"ÓLEO","code":"BR-SP-OLE"},
              {"name":"OLIMPIA","code":"BR-SP-OLI"},
              {"name":"ORIENTE","code":"BR-SP-ORI"},
              {"name":"ORLANDIA","code":"BR-SP-ORL"},
              {"name":"OSASCO","code":"BR-SP-QOC"},
              {"name":"OSVALDO CRUZ","code":"BR-SP-OCZ"},
              {"name":"OURINHOS","code":"BR-SP-OUS"},
              {"name":"OURO VERDE","code":"BR-SP-OVD"},
              {"name":"OUROESTE","code":"BR-SP-ORE"},
              {"name":"PACAEMBU","code":"BR-SP-PCU"},
              {"name":"PALESTINA","code":"BR-SP-PSI"},
              {"name":"PALMARES PAULISTA","code":"BR-SP-PAE"},
              {"name":"PALMEIRA DO OESTE","code":"BR-SP-POS"},
              {"name":"PALMITAL","code":"BR-SP-PML"},
              {"name":"PANORAMA","code":"BR-SP-PNA"},
              {"name":"PARAGUACU PAULISTA","code":"BR-SP-PPI"},
              {"name":"PARAIBUNA","code":"BR-SP-PBU"},
              {"name":"PARAISO","code":"BR-SP-PRI"},
              {"name":"PARANAPANEMA","code":"BR-SP-PPM"},
              {"name":"PARAPUA","code":"BR-SP-PPU"},
              {"name":"PARDINHO","code":"BR-SP-PRD"},
              {"name":"PARIQUERA-ACU","code":"BR-SP-PAC"},
              {"name":"PATROCINIO PAULISTA","code":"BR-SP-PPS"},
              {"name":"PAULINIA","code":"BR-SP-PUI"},
              {"name":"PAULO DE FARIA","code":"BR-SP-PFA"},
              {"name":"PEDERNEIRAS","code":"BR-SP-PNS"},
              {"name":"PEDREGULHO","code":"BR-SP-PGU"},
              {"name":"PEDREIRA","code":"BR-SP-PDI"},
              {"name":"PEDRINHAS PAULISTA","code":"BR-SP-PPT"},
              {"name":"PEDRO DE TOLEDO","code":"BR-SP-PED"},
              {"name":"PENAPOLIS","code":"BR-SP-PNP"},
              {"name":"PEREIRA BARRETO","code":"BR-SP-PBA"},
              {"name":"PEREIRAS","code":"BR-SP-PER"},
              {"name":"PERUIBE","code":"BR-SP-PUB"},
              {"name":"PIACATU","code":"BR-SP-PIT"},
              {"name":"PIEDADE","code":"BR-SP-PIE"},
              {"name":"PILAR DO SUL","code":"BR-SP-PSL"},
              {"name":"PINDAMONHANGABA","code":"BR-SP-PNM"},
              {"name":"PINDORAMA","code":"BR-SP-PND"},
              {"name":"PINHALZINHO","code":"BR-SP-PZI"},
              {"name":"PIRACAIA","code":"BR-SP-PCI"},
              {"name":"PIRACICABA","code":"BR-SP-QHB"},
              {"name":"PIRAJU","code":"BR-SP-PJA"},
              {"name":"PIRAJUI","code":"BR-SP-PJR"},
              {"name":"PIRANGI","code":"BR-SP-PRG"},
              {"name":"PIRAPORA DO BOM JESUS","code":"BR-SP-PBJ"},
              {"name":"PIRAPOZINHO","code":"BR-SP-PZO"},
              {"name":"PIRITUBA","code":"BR-SP-PTB"},
              {"name":"PITANGUEIRAS","code":"BR-SP-PGA"},
              {"name":"POA","code":"BR-SP-PQZ"},
              {"name":"POMPÉIA","code":"BR-SP-PPA"},
              {"name":"PONTAL","code":"BR-SP-PTL"},
              {"name":"PORANGABA","code":"BR-SP-POR"},
              {"name":"PORTO FELIZ","code":"BR-SP-PTZ"},
              {"name":"PORTO FERREIRA","code":"BR-SP-PTF"},
              {"name":"PORTO NOVO","code":"BR-SP-PTV"},
              {"name":"POTIM","code":"BR-SP-POI"},
              {"name":"POTIRENDABA","code":"BR-SP-POD"},
              {"name":"PRADOPOLIS","code":"BR-SP-PPL"},
              {"name":"PRAIA GRANDE","code":"BR-SP-PGR"},
              {"name":"PRESIDENTE BERNARDES","code":"BR-SP-PBR"},
              {"name":"PRESIDENTE EPITACIO","code":"BR-SP-PEO"},
              {"name":"PRESIDENTE PRUDENTE","code":"BR-SP-PPE"},
              {"name":"PRESIDENTE VENCESLAU","code":"BR-SP-PVE"},
              {"name":"PROMISSAO","code":"BR-SP-PMO"},
              {"name":"QUATA","code":"BR-SP-QTA"},
              {"name":"QUELUZ","code":"BR-SP-QLU"},
              {"name":"QUINTANA","code":"BR-SP-QUN"},
              {"name":"RAFARD","code":"BR-SP-RFA"},
              {"name":"RANCHARIA","code":"BR-SP-RCA"},
              {"name":"REGENTE FEIJO","code":"BR-SP-RFJ"},
              {"name":"REGISTRO","code":"BR-SP-RGI"},
              {"name":"RESTINGA","code":"BR-SP-RES"},
              {"name":"RIBEIRAO BONITO","code":"BR-SP-RBI"},
              {"name":"RIBEIRÃO BRANCO","code":"BR-SP-RIB"},
              {"name":"RIBEIRAO DO SUL","code":"BR-SP-RSU"},
              {"name":"RIBEIRAO GRANDE","code":"BR-SP-RGA"},
              {"name":"RIBEIRAO PIRES","code":"BR-SP-RPI"},
              {"name":"RIBEIRAO PRÊTO","code":"BR-SP-RAO"},
              {"name":"RIFAINA","code":"BR-SP-RFI"},
              {"name":"RINCAO","code":"BR-SP-RCO"},
              {"name":"RINOPOLIS","code":"BR-SP-RPS"},
              {"name":"RIO CLARO","code":"BR-SP-RCL"},
              {"name":"RIO DAS PEDRAS","code":"BR-SP-RPE"},
              {"name":"RIO GRANDE DA SERRA","code":"BR-SP-RGS"},
              {"name":"RIOLANDIA","code":"BR-SP-RLA"},
              {"name":"ROSANA","code":"BR-SP-ROS"},
              {"name":"ROSEIRA","code":"BR-SP-RSR"},
              {"name":"SALES OLIVEIRA","code":"BR-SP-SOV"},
              {"name":"SALTO","code":"BR-SP-SAT"},
              {"name":"SALTO DE PIRAPORA","code":"BR-SP-SAF"},
              {"name":"SALTO GRANDE","code":"BR-SP-SAL"},
              {"name":"SAN PAULO","code":"BR-SP-TMP21583"},
              {"name":"SANTA ADELIA","code":"BR-SP-SNL"},
              {"name":"SANTA BÁRBARA D'OESTE","code":"BR-SP-SBW"},
              {"name":"SANTA BRANCA","code":"BR-SP-SBR"},
              {"name":"SANTA CLARA D'OESTE","code":"BR-SP-SCD"},
              {"name":"SANTA CRUZ DAS PALMEIRAS","code":"BR-SP-SNY"},
              {"name":"SANTA CRUZ DO RIO PARDO","code":"BR-SP-SNQ"},
              {"name":"SANTA FÉ DO SUL","code":"BR-SP-SFV"},
              {"name":"SANTA GERTRUDES","code":"BR-SP-SGR"},
              {"name":"SANTA ISABEL","code":"BR-SP-SIB"},
              {"name":"SANTA MARIA DA SERRA","code":"BR-SP-SSR"},
              {"name":"SANTA RITA DO OESTE","code":"BR-SP-SRO"},
              {"name":"SANTA RITA DO PASSA QUATRO","code":"BR-SP-SRS"},
              {"name":"SANTA ROSA DE VITERBO","code":"BR-SP-SRV"},
              {"name":"SANTANA DE PARNAÍBA","code":"BR-SP-SDP"},
              {"name":"SANTO ANASTACIO","code":"BR-SP-SUN"},
              {"name":"SANTO ANDRÉ","code":"BR-SP-QSE"},
              {"name":"SANTO ANTÔNIO DE POSSE","code":"BR-SP-SAS"},
              {"name":"SANTO ANTONIO DO JARDIM","code":"BR-SP-STJ"},
              {"name":"SANTO ANTONIO DO PINHAL","code":"BR-SP-SPL"},
              {"name":"SANTOS","code":"BR-SP-SSZ"},
              {"name":"SAO BENTO DO SAPUCAI","code":"BR-SP-SBS"},
              {"name":"SAO BERNARDO DO CAMPO","code":"BR-SP-QSB"},
              {"name":"SAO CAETANO DO SUL","code":"BR-SP-QCX"},
              {"name":"SAO CARLOS","code":"BR-SP-QSC"},
              {"name":"SÃO FRANCISCO","code":"BR-SP-SFC"},
              {"name":"SÃO JOÃO DA BOA VISTA","code":"BR-SP-SBV"},
              {"name":"SAO JOAQUIM DA BARRA","code":"BR-SP-SJB"},
              {"name":"SÃO JOSÉ DO RIO PARDO","code":"BR-SP-JRP"},
              {"name":"SAO JOSÉ DO RIO PRÊTO","code":"BR-SP-SJP"},
              {"name":"SAO JOSÉ DOS CAMPOS","code":"BR-SP-SJK"},
              {"name":"SAO LOURENCO DA SERRA","code":"BR-SP-SLS"},
              {"name":"SAO LUIS DO PARAITINGA","code":"BR-SP-SLP"},
              {"name":"SAO MANUEL","code":"BR-SP-SML"},
              {"name":"SAO MIGUEL ARCANJO","code":"BR-SP-SMJ"},
              {"name":"SÃO PAULO","code":"BR-SP-SAO"},
              {"name":"SAO PEDRO","code":"BR-SP-SPD"},
              {"name":"SAO PEDRO DO TURVO","code":"BR-SP-SDT"},
              {"name":"SAO ROQUE","code":"BR-SP-SRQ"},
              {"name":"SÃO SEBASTIÃO","code":"BR-SP-SSO"},
              {"name":"SAO SEBASTIAO DA GRAMA","code":"BR-SP-SSG"},
              {"name":"SAO SIMAO","code":"BR-SP-SOS"},
              {"name":"SÃO VICENTE","code":"BR-SP-SVT"},
              {"name":"SARAPUÍ","code":"BR-SP-SRI"},
              {"name":"SERRA NEGRA","code":"BR-SP-SNG"},
              {"name":"SERRANA","code":"BR-SP-SNA"},
              {"name":"SERTÃOZINHO","code":"BR-SP-SZO"},
              {"name":"SETE BARRAS","code":"BR-SP-SBA"},
              {"name":"SEVERINIA","code":"BR-SP-SVE"},
              {"name":"SOCORRO","code":"BR-SP-SOC"},
              {"name":"SOROCABA","code":"BR-SP-SOD"},
              {"name":"SUD MENNUCCI","code":"BR-SP-SMU"},
              {"name":"SUMARÉ","code":"BR-SP-SME"},
              {"name":"SUZANO","code":"BR-SP-SZN"},
              {"name":"TABAPUA","code":"BR-SP-TPU"},
              {"name":"TABOAO DA SERRA","code":"BR-SP-TSA"},
              {"name":"TAIUVA","code":"BR-SP-TUV"},
              {"name":"TAMBAU","code":"BR-SP-TBU"},
              {"name":"TANABI","code":"BR-SP-TNA"},
              {"name":"TAPIRAI","code":"BR-SP-TPA"},
              {"name":"TAPIRATIBA","code":"BR-SP-TTA"},
              {"name":"TAQUARITINGA","code":"BR-SP-TQA"},
              {"name":"TAQUARITUBA","code":"BR-SP-TQB"},
              {"name":"TARUMA","code":"BR-SP-TRU"},
              {"name":"TATUI","code":"BR-SP-TAT"},
              {"name":"TAUBATE","code":"BR-SP-TBA"},
              {"name":"TEODORO SAMPAIO","code":"BR-SP-TSO"},
              {"name":"TERRA ROXA","code":"BR-SP-TRX"},
              {"name":"TIETE","code":"BR-SP-TIE"},
              {"name":"TORRINHA","code":"BR-SP-THA"},
              {"name":"TREMEMBE","code":"BR-SP-TME"},
              {"name":"TUPA","code":"BR-SP-TUP"},
              {"name":"TUPI PAULISTA","code":"BR-SP-TIP"},
              {"name":"UBATUBA","code":"BR-SP-UBT"},
              {"name":"UBIRAJARA","code":"BR-SP-UJR"},
              {"name":"UCHOA","code":"BR-SP-UCH"},
              {"name":"URUBUPUNGA","code":"BR-SP-URB"},
              {"name":"URUPES","code":"BR-SP-UPE"},
              {"name":"VALINHOS","code":"BR-SP-VAH"},
              {"name":"VALPARAISO","code":"BR-SP-VPO"},
              {"name":"VARGEM GRANDE DO SUL","code":"BR-SP-VGS"},
              {"name":"VARGEM GRANDE PAULISTA","code":"BR-SP-VGP"},
              {"name":"VARZEA PAULISTA","code":"BR-SP-VPU"},
              {"name":"VERA CRUZ","code":"BR-SP-VRC"},
              {"name":"VILA LIVIEIRA","code":"BR-SP-VLI"},
              {"name":"VINHEDO","code":"BR-SP-VIN"},
              {"name":"VIRACOPOS APT/SAO PAOLO","code":"BR-SP-VCP"},
              {"name":"VIRADOURO","code":"BR-SP-VDO"},
              {"name":"VISTA ALEGRA DO ALTO","code":"BR-SP-VAA"},
              {"name":"VOTORANTIM","code":"BR-SP-VTM"},
              {"name":"VOTUPORANGA","code":"BR-SP-VOT"},]
        },
        {
            "name": "Sergipe",
            "code": "BR-SE",
            "cities": [{"name":"ARACAJU","code":"BR-SE-AJU"},
              {"name":"BARRA DOS COQUEIROS","code":"BR-SE-BAR"},
              {"name":"BOQUIM","code":"BR-SE-BQU"},
              {"name":"CANINDE DO SAO FRANCISCO","code":"BR-SE-CFA"},
              {"name":"ESTÂNCIA","code":"BR-SE-EST"},
              {"name":"ITABAIANA","code":"BR-SE-INX"},
              {"name":"ITAPORANGA D'AJUDA","code":"BR-SE-IJJ"},
              {"name":"LAGARTO","code":"BR-SE-LGO"},
              {"name":"LARANJEIRAS","code":"BR-SE-LJS"},
              {"name":"MALHADOR","code":"BR-SE-MHA"},
              {"name":"MARUIM","code":"BR-SE-MRM"},
              {"name":"NEÓPOLIS","code":"BR-SE-NEO"},
              {"name":"NOSSA SENHORA DO SOCORRO","code":"BR-SE-NSS"},
              {"name":"PROPRIA","code":"BR-SE-PRP"},
              {"name":"RIBEIRÓPOLIS","code":"BR-SE-RBL"},
              {"name":"ROSÁRIO DO CATETE","code":"BR-SE-RSC"},
              {"name":"SAO CRISTOVAO","code":"BR-SE-SCV"},
              {"name":"SIMÃO DIAS","code":"BR-SE-SID"},]
        },
        {
            "name": "Tocantins",
            "code": "BR-TO",
            "cities": [{"name":"ALVORADA","code":"BR-TO-ALV"},
              {"name":"ARAGUACU","code":"BR-TO-ARG"},
              {"name":"ARAGUAÍNA","code":"BR-TO-AUX"},
              {"name":"ARRAIAS","code":"BR-TO-AAI"},
              {"name":"COLINAS DE TOCANTINS","code":"BR-TO-CTS"},
              {"name":"COLMEIA","code":"BR-TO-CLM"},
              {"name":"DIANÓPOLIS","code":"BR-TO-DNO"},
              {"name":"FORMOSO DO ARAGUAIA","code":"BR-TO-FAG"},
              {"name":"GUARAI","code":"BR-TO-GRQ"},
              {"name":"GURUPI","code":"BR-TO-GUR"},
              {"name":"JALAPAO","code":"BR-TO-JPO"},
              {"name":"LAGOA DA CONFUSÃO","code":"BR-TO-LCU"},
              {"name":"LAJEADO","code":"BR-TO-LJO"},
              {"name":"MIRACEMA DO TOCANTINS","code":"BR-TO-MTN"},
              {"name":"MIRACEMA DO TOCANTINS","code":"BR-TO-MTS"},
              {"name":"PALMAS","code":"BR-TO-PMW"},
              {"name":"PALMEIROPOLIS","code":"BR-TO-PPO"},
              {"name":"PARAISO DO TOCANTINS","code":"BR-TO-PTI"},
              {"name":"PARANÃ","code":"BR-TO-PNN"},
              {"name":"PORTO NACIONAL","code":"BR-TO-PNB"},
              {"name":"WANDERLÂNDIA","code":"BR-TO-WAN"},]
        }
    ]
  },
  {
    name: 'Canadá',
    code: 'CA',
    dialCode: '+1',
    flag: 'flag_canada.png',
    states: [
      {
        name: 'Alberta',
        code: 'AB',
        cities: [
          {"name":"ACADIA VALLEY","code":"CA-AB-AVY"},
          {"name":"ACHESON","code":"CA-AB-AHN"},
          {"name":"AIRDRIE","code":"CA-AB-AIR"},
          {"name":"ALDER FLATS","code":"CA-AB-LDF"},
          {"name":"ALDERSYDE","code":"CA-AB-ASY"},
          {"name":"ALHAMBRA","code":"CA-AB-ALH"},
          {"name":"ALIX","code":"CA-AB-ALI"},
          {"name":"ANZAC","code":"CA-AB-ANZ"},
          {"name":"ARDROSSAN","code":"CA-AB-ASS"},
          {"name":"ARMENA","code":"CA-AB-AME"},
          {"name":"ATHABASCA","code":"CA-AB-ATB"},
          {"name":"BALZAC","code":"CA-AB-BZC"},
          {"name":"BANFF","code":"CA-AB-BAF"},
          {"name":"BANTRY","code":"CA-AB-NTR"},
          {"name":"BARONS","code":"CA-AB-BN4"},
          {"name":"BARRHEAD","code":"CA-AB-BRH"},
          {"name":"BASHAW","code":"CA-AB-BHW"},
          {"name":"BASSANO","code":"CA-AB-BNO"},
          {"name":"BAY TREE","code":"CA-AB-BYT"},
          {"name":"BEAUMONT","code":"CA-AB-BET"},
          {"name":"BEAVERLODGE","code":"CA-AB-BLO"},
          {"name":"BEISEKER","code":"CA-AB-BEE"},
          {"name":"BELLEVUE","code":"CA-AB-BVU"},
          {"name":"BLACK DIAMOND","code":"CA-AB-BDM"},
          {"name":"BLACKFALDS","code":"CA-AB-BKF"},
          {"name":"BLACKFOOT","code":"CA-AB-BFO"},
          {"name":"BLACKIE","code":"CA-AB-BKI"},
          {"name":"BLUE RIDGE","code":"CA-AB-BLG"},
          {"name":"BLUFFTON","code":"CA-AB-BFT"},
          {"name":"BON ACCORD","code":"CA-AB-BAC"},
          {"name":"BONNYVILLE","code":"CA-AB-BOE"},
          {"name":"BOW CITY","code":"CA-AB-BC2"},
          {"name":"BOW ISLAND","code":"CA-AB-BWI"},
          {"name":"BOWDEN","code":"CA-AB-BOW"},
          {"name":"BOYLE","code":"CA-AB-BOY"},
          {"name":"BRAGG CREEK","code":"CA-AB-BCE"},
          {"name":"BRAZEAU","code":"CA-AB-BRZ"},
          {"name":"BROCKET","code":"CA-AB-BKA"},
          {"name":"BROOKS","code":"CA-AB-BKS"},
          {"name":"BRUCE","code":"CA-AB-BRC"},
          {"name":"BRUDERHEIM","code":"CA-AB-BDH"},
          {"name":"BURDETT","code":"CA-AB-BDT"},
          {"name":"CALGARY","code":"CA-AB-CAL"},
          {"name":"CALGARY APT","code":"CA-AB-YYC"},
          {"name":"CALMAR","code":"CA-AB-CMR"},
          {"name":"CAMROSE","code":"CA-AB-CAE"},
          {"name":"CANMORE","code":"CA-AB-CNM"},
          {"name":"CANYON CREEK","code":"CA-AB-CAY"},
          {"name":"CARBON","code":"CA-AB-CBN"},
          {"name":"CARDSTON","code":"CA-AB-CDS"},
          {"name":"CAROLINE","code":"CA-AB-CRN"},
          {"name":"CARSELAND","code":"CA-AB-CLD"},
          {"name":"CARSTAIRS","code":"CA-AB-CTI"},
          {"name":"CARVEL","code":"CA-AB-CRV"},
          {"name":"CASTOR","code":"CA-AB-CSR"},
          {"name":"CAYLEY","code":"CA-AB-CLY"},
          {"name":"CESSFORD","code":"CA-AB-CFO"},
          {"name":"CHESTERMERE","code":"CA-AB-CTR"},
          {"name":"CLAIRMONT","code":"CA-AB-CMT"},
          {"name":"CLANDONALD","code":"CA-AB-CDD"},
          {"name":"CLARESHOLM","code":"CA-AB-CSO"},
          {"name":"CLIVE","code":"CA-AB-5DV"},
          {"name":"CLUNY","code":"CA-AB-CNY"},
          {"name":"COALDALE","code":"CA-AB-CDE"},
          {"name":"COALHURST","code":"CA-AB-CLU"},
          {"name":"COCHRANE","code":"CA-AB-CCH"},
          {"name":"COLD LAKE","code":"CA-AB-YOD"},
          {"name":"COLEMAN","code":"CA-AB-CEM"},
          {"name":"CONKLIN","code":"CA-AB-CNN"},
          {"name":"CORONATION","code":"CA-AB-YCT"},
          {"name":"COUTTS","code":"CA-AB-CTS"},
          {"name":"COWLEY","code":"CA-AB-YYM"},
          {"name":"CRANFORD","code":"CA-AB-CRA"},
          {"name":"CREMONA","code":"CA-AB-CMA"},
          {"name":"CROSSFIELD","code":"CA-AB-CSF"},
          {"name":"DE WINTON","code":"CA-AB-DWI"},
          {"name":"DEBOLT","code":"CA-AB-DBL"},
          {"name":"DEVON","code":"CA-AB-DVN"},
          {"name":"DEWBERRY","code":"CA-AB-DEW"},
          {"name":"DIAMOND CITY","code":"CA-AB-DIC"},
          {"name":"DIDSBURY","code":"CA-AB-DID"},
          {"name":"DIMSDALE","code":"CA-AB-DMS"},
          {"name":"DRAYTON VALLEY","code":"CA-AB-YDC"},
          {"name":"DRUMHELLER","code":"CA-AB-DRM"},
          {"name":"DUCHESS","code":"CA-AB-DES"},
          {"name":"DUFFIELD","code":"CA-AB-DFL"},
          {"name":"DUNMORE","code":"CA-AB-DNM"},
          {"name":"ECKVILLE","code":"CA-AB-EKV"},
          {"name":"EDBERG","code":"CA-AB-EDB"},
          {"name":"EDMONTON","code":"CA-AB-EDM"},
          {"name":"EDMONTON INT APT","code":"CA-AB-YEG"},
          {"name":"EDSON","code":"CA-AB-EDS"},
          {"name":"ELK POINT","code":"CA-AB-ELT"},
          {"name":"ELLERSLIE/EDMONTON","code":"CA-AB-ELL"},
          {"name":"ELNORA","code":"CA-AB-ELN"},
          {"name":"EMPRESS","code":"CA-AB-EMP"},
          {"name":"ENTWISTLE","code":"CA-AB-ENT"},
          {"name":"ETZIKOM","code":"CA-AB-ETZ"},
          {"name":"EXSHAW","code":"CA-AB-EXS"},
          {"name":"FAIRVIEW","code":"CA-AB-ZFW"},
          {"name":"FALHER","code":"CA-AB-YOE"},
          {"name":"FERINTOSH","code":"CA-AB-FTO"},
          {"name":"FOREMOST","code":"CA-AB-FOM"},
          {"name":"FORESTBURG","code":"CA-AB-FBG"},
          {"name":"FORT CHIPEWYAN","code":"CA-AB-YPY"},
          {"name":"FORT MACKAY","code":"CA-AB-FMA"},
          {"name":"FORT MACLEOD","code":"CA-AB-FMC"},
          {"name":"FORT MCMURRAY","code":"CA-AB-YMM"},
          {"name":"FORT SASKATCHEWAN","code":"CA-AB-FSK"},
          {"name":"FORT VERMILION","code":"CA-AB-FVE"},
          {"name":"FOX CREEK","code":"CA-AB-FXC"},
          {"name":"FOX LAKE","code":"CA-AB-FLK"},
          {"name":"GEM","code":"CA-AB-GEM"},
          {"name":"GIBBONS","code":"CA-AB-GBO"},
          {"name":"GIROUXVILLE","code":"CA-AB-GXI"},
          {"name":"GLENDON","code":"CA-AB-GNO"},
          {"name":"GLENWOOD","code":"CA-AB-GWD"},
          {"name":"GRANDE CACHE","code":"CA-AB-YGC"},
          {"name":"GRANDE PRAIRIE","code":"CA-AB-YQU"},
          {"name":"GRANUM","code":"CA-AB-GRU"},
          {"name":"GRASSLAND","code":"CA-AB-GRD"},
          {"name":"GRASSY LAKE","code":"CA-AB-GLK"},
          {"name":"GRIMSHAW","code":"CA-AB-GSA"},
          {"name":"GUY","code":"CA-AB-GUY"},
          {"name":"HAIRY HILL","code":"CA-AB-HHL"},
          {"name":"HANNA","code":"CA-AB-HNA"},
          {"name":"HARDISTY","code":"CA-AB-HDY"},
          {"name":"HERITAGE POINTE","code":"CA-AB-HEP"},
          {"name":"HIGH LEVEL","code":"CA-AB-YOJ"},
          {"name":"HIGH PRAIRIE","code":"CA-AB-ZHP"},
          {"name":"HIGH RIVER","code":"CA-AB-HRI"},
          {"name":"HILDA","code":"CA-AB-HID"},
          {"name":"HINES CREEK","code":"CA-AB-HIN"},
          {"name":"HINTON","code":"CA-AB-HNT"},
          {"name":"HYTHE","code":"CA-AB-HYE"},
          {"name":"INNISFAIL","code":"CA-AB-INN"},
          {"name":"INNISFREE","code":"CA-AB-INF"},
          {"name":"INVERLAKE","code":"CA-AB-TIL"},
          {"name":"IRMA","code":"CA-AB-IRM"},
          {"name":"IRON SPRINGS","code":"CA-AB-ISP"},
          {"name":"IRRICANA","code":"CA-AB-ICA"},
          {"name":"IRVINE","code":"CA-AB-IVN"},
          {"name":"JASPER","code":"CA-AB-JPR"},
          {"name":"JENNER","code":"CA-AB-JNE"},
          {"name":"JOFFRE","code":"CA-AB-JOF"},
          {"name":"KANANASKIS","code":"CA-AB-KNA"},
          {"name":"KEARL LAKE","code":"CA-AB-KLL"},
          {"name":"KEG RIVER","code":"CA-AB-CNG"},
          {"name":"KEOMA","code":"CA-AB-KEA"},
          {"name":"KILLAM","code":"CA-AB-KLM"},
          {"name":"KINGMAN","code":"CA-AB-KGM"},
          {"name":"LA CRÊTE","code":"CA-AB-LCR"},
          {"name":"LA GLACE","code":"CA-AB-LGC"},
          {"name":"LAC BICHE","code":"CA-AB-YLB"},
          {"name":"LAC LA BICHE","code":"CA-AB-AAB"},
          {"name":"LACOMBE","code":"CA-AB-LCM"},
          {"name":"LAKE LOUISE","code":"CA-AB-LLI"},
          {"name":"LAMONT","code":"CA-AB-LMT"},
          {"name":"LANCASTER PARK","code":"CA-AB-LRP"},
          {"name":"LANGDON","code":"CA-AB-LGD"},
          {"name":"LEDUC","code":"CA-AB-LDC"},
          {"name":"LEGAL","code":"CA-AB-LGL"},
          {"name":"LETHBRIDGE","code":"CA-AB-LTB"},
          {"name":"LINDEN","code":"CA-AB-LDI"},
          {"name":"LITTLE SMOKY","code":"CA-AB-LSY"},
          {"name":"LLOYDMINSTER","code":"CA-AB-YLL"},
          {"name":"LODGEPOLE","code":"CA-AB-LDG"},
          {"name":"LOMOND","code":"CA-AB-LOM"},
          {"name":"LOUGHEED","code":"CA-AB-LOE"},
          {"name":"LYALTA","code":"CA-AB-LYA"},
          {"name":"MA-MA-O BEACH","code":"CA-AB-AEO"},
          {"name":"MAGRATH","code":"CA-AB-MTA"},
          {"name":"MAYERTHORPE","code":"CA-AB-MAY"},
          {"name":"MCLENNAN","code":"CA-AB-MNN"},
          {"name":"MEDICINE HAT","code":"CA-AB-YXH"},
          {"name":"METISKOW","code":"CA-AB-MSW"},
          {"name":"MILK RIVER","code":"CA-AB-MKV"},
          {"name":"MILLARVILLE","code":"CA-AB-MLV"},
          {"name":"MILLET","code":"CA-AB-MLE"},
          {"name":"MILO","code":"CA-AB-ILO"},
          {"name":"MONARCH","code":"CA-AB-MCH"},
          {"name":"MORINVILLE","code":"CA-AB-MRV"},
          {"name":"MOUNTAIN VIEW","code":"CA-AB-MVW"},
          {"name":"MUNDARE","code":"CA-AB-MUA"},
          {"name":"NAMAO","code":"CA-AB-NMO"},
          {"name":"NAMPA","code":"CA-AB-NMP"},
          {"name":"NANTON","code":"CA-AB-NTO"},
          {"name":"NEERLANDIA","code":"CA-AB-NRA"},
          {"name":"NEVIS","code":"CA-AB-NVS"},
          {"name":"NEW DAYTON","code":"CA-AB-NDA"},
          {"name":"NEW NORWAY","code":"CA-AB-NOY"},
          {"name":"NEWBROOK","code":"CA-AB-NWO"},
          {"name":"NISKU","code":"CA-AB-NIU"},
          {"name":"NISKU/EDMONTON","code":"CA-AB-NSK"},
          {"name":"NOBLEFORD","code":"CA-AB-NBF"},
          {"name":"OKOTOKS","code":"CA-AB-OTS"},
          {"name":"OLDS","code":"CA-AB-ODS"},
          {"name":"ONOWAY","code":"CA-AB-OWY"},
          {"name":"OYEN","code":"CA-AB-OYN"},
          {"name":"PARKLAND","code":"CA-AB-PKL"},
          {"name":"PEACE RIVER","code":"CA-AB-YPE"},
          {"name":"PEERS","code":"CA-AB-PEE"},
          {"name":"PENHOLD","code":"CA-AB-PNH"},
          {"name":"PICKARDVILLE","code":"CA-AB-PVI"},
          {"name":"PICTURE BUTTE","code":"CA-AB-PCB"},
          {"name":"PINCHER CREEK","code":"CA-AB-WPC"},
          {"name":"PLAMONDON","code":"CA-AB-PMD"},
          {"name":"PONOKA","code":"CA-AB-PKA"},
          {"name":"PRIDDIS","code":"CA-AB-PRD"},
          {"name":"PROVOST","code":"CA-AB-PVT"},
          {"name":"PURPLE SPRINGS","code":"CA-AB-PSG"},
          {"name":"QULIQURA","code":"CA-AB-QUL"},
          {"name":"RAINBOW LAKE","code":"CA-AB-RBL"},
          {"name":"RAINIER","code":"CA-AB-RNR"},
          {"name":"RALSTON","code":"CA-AB-RAJ"},
          {"name":"RAYMOND","code":"CA-AB-RYD"},
          {"name":"RED DEER","code":"CA-AB-YQF"},
          {"name":"REDCLIFF","code":"CA-AB-RCF"},
          {"name":"REDWATER","code":"CA-AB-RWA"},
          {"name":"REDWOOD MEADOW","code":"CA-AB-RWM"},
          {"name":"REDWOOD MEADOWS","code":"CA-AB-RDM"},
          {"name":"RIMBEY","code":"CA-AB-RIY"},
          {"name":"ROCHESTER","code":"CA-AB-RHS"},
          {"name":"ROCKY MOUNTAIN HOUSE","code":"CA-AB-YRM"},
          {"name":"ROCKY VIEW COUNTY","code":"CA-AB-ABR"},
          {"name":"ROCKYFORD","code":"CA-AB-ROC"},
          {"name":"ROLLING HILLS","code":"CA-AB-RLH"},
          {"name":"ROLLY VIEW","code":"CA-AB-RYV"},
          {"name":"ROSEMONT","code":"CA-AB-RSE"},
          {"name":"ROUND HILL","code":"CA-AB-RDH"},
          {"name":"RYLEY","code":"CA-AB-RLY"},
          {"name":"SAINT ALBERT","code":"CA-AB-S8A"},
          {"name":"SAINT-ALBERT","code":"CA-AB-SBT"},
          {"name":"SAINT-GERMAN","code":"CA-AB-GER"},
          {"name":"SAINT-PAUL","code":"CA-AB-SPL"},
          {"name":"SANGUDO","code":"CA-AB-GDU"},
          {"name":"SCOTFORD","code":"CA-AB-SCF"},
          {"name":"SEBA BEACH","code":"CA-AB-SBB"},
          {"name":"SEDGEWICK","code":"CA-AB-SED"},
          {"name":"SEXSMITH","code":"CA-AB-SEH"},
          {"name":"SHERWOOD PARK","code":"CA-AB-SWP"},
          {"name":"SLAVE LAKE","code":"CA-AB-YZH"},
          {"name":"SMOKY LAKE","code":"CA-AB-SYK"},
          {"name":"SPIRIT RIVER","code":"CA-AB-SIR"},
          {"name":"SPRING COULEE","code":"CA-AB-SUE"},
          {"name":"SPRING LAKE","code":"CA-AB-SGL"},
          {"name":"SPRUCE GROVE","code":"CA-AB-SPG"},
          {"name":"STANDARD","code":"CA-AB-SNA"},
          {"name":"STAVELY","code":"CA-AB-SVY"},
          {"name":"STETTLER","code":"CA-AB-SER"},
          {"name":"STONY PLAIN","code":"CA-AB-SYP"},
          {"name":"STRATHCONA","code":"CA-AB-SCN"},
          {"name":"STRATHMORE","code":"CA-AB-SOE"},
          {"name":"SUFFIELD","code":"CA-AB-YSD"},
          {"name":"SUNDRE","code":"CA-AB-SDR"},
          {"name":"SUNNYBROOK","code":"CA-AB-SUK"},
          {"name":"SWAN HILLS","code":"CA-AB-SWH"},
          {"name":"SYLVAN LAKE","code":"CA-AB-SYL"},
          {"name":"TABER","code":"CA-AB-TBR"},
          {"name":"TAR ISLAND","code":"CA-AB-TIS"},
          {"name":"TEES","code":"CA-AB-TES"},
          {"name":"THORHILD","code":"CA-AB-TRH"},
          {"name":"THORSBY","code":"CA-AB-THY"},
          {"name":"THREE HILLS","code":"CA-AB-THH"},
          {"name":"TIGERLILY","code":"CA-AB-TGY"},
          {"name":"TILLEY","code":"CA-AB-TLY"},
          {"name":"TOFIELD","code":"CA-AB-TFI"},
          {"name":"TROCHU","code":"CA-AB-TRO"},
          {"name":"TURIN","code":"CA-AB-TR8"},
          {"name":"TURNER VALLEY","code":"CA-AB-TNV"},
          {"name":"TWO HILLS","code":"CA-AB-THI"},
          {"name":"VALLEYVIEW","code":"CA-AB-VYV"},
          {"name":"VAUXHALL","code":"CA-AB-VXL"},
          {"name":"VEGREVILLE","code":"CA-AB-VEG"},
          {"name":"VERMILION","code":"CA-AB-YVG"},
          {"name":"VETERAN","code":"CA-AB-VRA"},
          {"name":"VIKING","code":"CA-AB-VIK"},
          {"name":"VULCAN","code":"CA-AB-VCA"},
          {"name":"WAINWRIGHT","code":"CA-AB-WWI"},
          {"name":"WANHAM","code":"CA-AB-WNH"},
          {"name":"WARBURG","code":"CA-AB-WBG"},
          {"name":"WARNER","code":"CA-AB-WNR"},
          {"name":"WASKATENAU","code":"CA-AB-WAS"},
          {"name":"WEMBLEY","code":"CA-AB-WMB"},
          {"name":"WESTEROSE","code":"CA-AB-WSO"},
          {"name":"WESTLOCK","code":"CA-AB-WEK"},
          {"name":"WETASKIWIN","code":"CA-AB-WET"},
          {"name":"WHITECOURT","code":"CA-AB-WTC"},
          {"name":"WILDWOOD","code":"CA-AB-WWO"},
          {"name":"WIMBORNE","code":"CA-AB-WBO"},
          {"name":"WINFIELD","code":"CA-AB-WNF"},
          {"name":"WINTERBURN","code":"CA-AB-WBN"},
          {"name":"WRENTHAM","code":"CA-AB-WTM"},
        ]
      },
      {
        name: 'British Columbia',
        code: 'BC',
        cities: [
          {"name":"100 MILE HOUSE (ONE HUNDRED MILE HOUSE)","code":"CA-BC-HOS"},
{"name":"150 MILE HOUSE","code":"CA-BC-MHS"},
{"name":"ABBOTSFORD","code":"CA-BC-ABB"},
{"name":"AGASSIZ","code":"CA-BC-AGZ"},
{"name":"ALDERGROVE","code":"CA-BC-ADE"},
{"name":"ALERT BAY","code":"CA-BC-AAL"},
{"name":"ALEXIS CREEK","code":"CA-BC-ASK"},
{"name":"ALTA LAKE","code":"CA-BC-YAE"},
{"name":"ANDYS BAY","code":"CA-BC-ANB"},
{"name":"ANNACIS ISLAND","code":"CA-BC-ANI"},
{"name":"ARMSTRONG","code":"CA-BC-ARM"},
{"name":"ASHCROFT","code":"CA-BC-YZA"},
{"name":"ATLIN","code":"CA-BC-ATL"},
{"name":"BAMBERTON","code":"CA-BC-BAM"},
{"name":"BARRIÈRE","code":"CA-BC-BRR"},
{"name":"BEALE COVE","code":"CA-BC-BCV"},
{"name":"BEAVER COVE","code":"CA-BC-BEA"},
{"name":"BELLA COOLA","code":"CA-BC-BLC"},
{"name":"BIG LAKE RANCH","code":"CA-BC-BKR"},
{"name":"BLACK CREEK","code":"CA-BC-BKC"},
{"name":"BLIND BAY","code":"CA-BC-BBY"},
{"name":"BLUBBER BAY","code":"CA-BC-BBB"},
{"name":"BLUE RIVER","code":"CA-BC-BVR"},
{"name":"BOBQUINN LAKE","code":"CA-BC-YBO"},
{"name":"BOWEN ISLAND","code":"CA-BC-BNI"},
{"name":"BRACKENDALE","code":"CA-BC-BCN"},
{"name":"BRENTWOOD BAY","code":"CA-BC-BRD"},
{"name":"BRIDESVILLE","code":"CA-BC-BIE"},
{"name":"BRIDGE LAKE","code":"CA-BC-BLE"},
{"name":"BRITANNIA BEACH","code":"CA-BC-BRI"},
{"name":"BRONSON CREEK","code":"CA-BC-YBM"},
{"name":"BULL HARBOUR","code":"CA-BC-YBH"},
{"name":"BULL RIVER","code":"CA-BC-BUL"},
{"name":"BURNABY","code":"CA-BC-BUB"},
{"name":"BURNS LAKE","code":"CA-BC-YPZ"},
{"name":"BURRARD INLET","code":"CA-BC-BIN"},
{"name":"BURTON","code":"CA-BC-BON"},
{"name":"BUTEDALE","code":"CA-BC-BUT"},
{"name":"BUTTERFLY BAY","code":"CA-BC-BTF"},
{"name":"CACHE CREEK","code":"CA-BC-CCE"},
{"name":"CAMPBELL RIVER","code":"CA-BC-CAM"},
{"name":"CANAL FLATS","code":"CA-BC-FTS"},
{"name":"CANIM LAKE","code":"CA-BC-CML"},
{"name":"CAPE-SAINT-JAMES","code":"CA-BC-YCJ"},
{"name":"CASSIDY","code":"CA-BC-CSD"},
{"name":"CASTLEGAR","code":"CA-BC-YCG"},
{"name":"CAWSTON","code":"CA-BC-CWO"},
{"name":"CHARLIE LAKE","code":"CA-BC-CKE"},
{"name":"CHARLOTTE (SKIDEGATE)","code":"CA-BC-SKI"},
{"name":"CHASE","code":"CA-BC-HAS"},
{"name":"CHEMAINUS","code":"CA-BC-CHM"},
{"name":"CHERRYVILLE","code":"CA-BC-CHE"},
{"name":"CHETWYND","code":"CA-BC-YCQ"},
{"name":"CHILKO LAKE","code":"CA-BC-CJH"},
{"name":"CHILLIWACK","code":"CA-BC-CWK"},
{"name":"CLEARBROOK","code":"CA-BC-COK"},
{"name":"CLEARWATER","code":"CA-BC-CWT"},
{"name":"CLINTON","code":"CA-BC-CON"},
{"name":"CLOVERDALE","code":"CA-BC-CVD"},
{"name":"COAL HARBOUR","code":"CA-BC-CAU"},
{"name":"COBBLE HILL","code":"CA-BC-CHL"},
{"name":"COLDSTREAM","code":"CA-BC-CDM"},
{"name":"COLWOOD","code":"CA-BC-CLW"},
{"name":"COMOX","code":"CA-BC-COX"},
{"name":"CONQUITLAM","code":"CA-BC-CQT"},
{"name":"COOMBS","code":"CA-BC-COO"},
{"name":"COQUITLAM","code":"CA-BC-COQ"},
{"name":"CORTES BAY","code":"CA-BC-YCF"},
{"name":"COURTENAY","code":"CA-BC-COU"},
{"name":"COWICHAN BAY","code":"CA-BC-CCB"},
{"name":"CRANBROOK","code":"CA-BC-YXC"},
{"name":"CRESTON","code":"CA-BC-CFQ"},
{"name":"CROFTON","code":"CA-BC-CRO"},
{"name":"CULTUS LAKE","code":"CA-BC-CUL"},
{"name":"DAWSON CREEK","code":"CA-BC-YDQ"},
{"name":"DEAN RIVER","code":"CA-BC-YRD"},
{"name":"DEASE LAKE","code":"CA-BC-YDL"},
{"name":"DELTA","code":"CA-BC-DEL"},
{"name":"DENMAN ISLAND","code":"CA-BC-DSA"},
{"name":"DEROCHE","code":"CA-BC-DER"},
{"name":"DESOLATION SOUND","code":"CA-BC-YDS"},
{"name":"DOC CREEK","code":"CA-BC-YDX"},
{"name":"DOUGLAS LAKE","code":"CA-BC-DGF"},
{"name":"DUNCAN BAY","code":"CA-BC-DCN"},
{"name":"DUNCAN/QUAM","code":"CA-BC-DUQ"},
{"name":"ELKFORD","code":"CA-BC-EKF"},
{"name":"ENDAKO","code":"CA-BC-END"},
{"name":"ENDERBY","code":"CA-BC-ENY"},
{"name":"ENGELWOOD","code":"CA-BC-ENG"},
{"name":"ENGLISH BAY","code":"CA-BC-EBY"},
{"name":"ERICKSON","code":"CA-BC-EKO"},
{"name":"ERRINGTON","code":"CA-BC-EGT"},
{"name":"ESQUIMALT","code":"CA-BC-ESQ"},
{"name":"ESTEVAN POINT","code":"CA-BC-YEP"},
{"name":"FAIRMONT HOT SPRINGS","code":"CA-BC-FHS"},
{"name":"FAIRMOUNT SPRINGS","code":"CA-BC-YCZ"},
{"name":"FALKLAND","code":"CA-BC-FLA"},
{"name":"FANNY BAY","code":"CA-BC-FYB"},
{"name":"FERNIE","code":"CA-BC-FNI"},
{"name":"FORT LANGLEY","code":"CA-BC-FLY"},
{"name":"FORT NELSON","code":"CA-BC-FNE"},
{"name":"FORT STEELE","code":"CA-BC-FST"},
{"name":"FORT WARE","code":"CA-BC-WAR"},
{"name":"FORT-SAINT-JAMES","code":"CA-BC-SJA"},
{"name":"FORT-SAINT-JOHN","code":"CA-BC-FSJ"},
{"name":"FRANÇOIS LAKE","code":"CA-BC-FRL"},
{"name":"FRASER","code":"CA-BC-FRM"},
{"name":"FRASER MILLS","code":"CA-BC-FRA"},
{"name":"FRASER RIVER","code":"CA-BC-FRR"},
{"name":"FRASER WHARVES","code":"CA-BC-FWS"},
{"name":"FRASER-SURREY DOCKS","code":"CA-BC-FSD"},
{"name":"FRUITVALE","code":"CA-BC-FRU"},
{"name":"GANGES","code":"CA-BC-GGS"},
{"name":"GERMANSEN","code":"CA-BC-YGS"},
{"name":"GIBSONS","code":"CA-BC-GIB"},
{"name":"GILLIES BAY","code":"CA-BC-YGB"},
{"name":"GIPSONS","code":"CA-BC-G3S"},
{"name":"GOLD RIVER","code":"CA-BC-GOR"},
{"name":"GOLDEN","code":"CA-BC-GDN"},
{"name":"GORGE HARBOR","code":"CA-BC-YGE"},
{"name":"GRAND FORKS","code":"CA-BC-ZGF"},
{"name":"GREENWAY SOUND","code":"CA-BC-YGN"},
{"name":"GREENWOOD","code":"CA-BC-GRW"},
{"name":"GRINDROD","code":"CA-BC-GDO"},
{"name":"GROUSE MOUNTAIN","code":"CA-BC-GRO"},
{"name":"HAKAI PASS","code":"CA-BC-YHC"},
{"name":"HALFMOON BAY","code":"CA-BC-HFB"},
{"name":"HANEY","code":"CA-BC-HAN"},
{"name":"HARMAC","code":"CA-BC-HMC"},
{"name":"HARRIET HARBOUR","code":"CA-BC-HAR"},
{"name":"HARRISON MILLS","code":"CA-BC-HMI"},
{"name":"HARROGATE","code":"CA-BC-HRG"},
{"name":"HARTLEY BAY","code":"CA-BC-YTB"},
{"name":"HATCH POINT","code":"CA-BC-HAT"},
{"name":"HERIOT BAY","code":"CA-BC-BYE"},
{"name":"HOPE","code":"CA-BC-YHE"},
{"name":"HORSEFLY","code":"CA-BC-HRF"},
{"name":"HOUSTON","code":"CA-BC-HOU"},
{"name":"HOWE SOUND","code":"CA-BC-HWS"},
{"name":"HUDSON HOPE","code":"CA-BC-YNH"},
{"name":"INDIAN ARM","code":"CA-BC-IAM"},
{"name":"INVERMERE","code":"CA-BC-IVM"},
{"name":"IOCO","code":"CA-BC-IOC"},
{"name":"ISKUT","code":"CA-BC-SKT"},
{"name":"JACKSON BAY","code":"CA-BC-JBA"},
{"name":"JEDWAY","code":"CA-BC-JED"},
{"name":"JERVIS INLET","code":"CA-BC-JEI"},
{"name":"JOHNNY MOUNTAIN","code":"CA-BC-YJO"},
{"name":"KAMLOOPS","code":"CA-BC-KML"},
{"name":"KASLO","code":"CA-BC-KLO"},
{"name":"KELOWNA","code":"CA-BC-KWL"},
{"name":"KEREMEOS","code":"CA-BC-KRO"},
{"name":"KIMBERLEY","code":"CA-BC-YQE"},
{"name":"KINGSGATE","code":"CA-BC-KNG"},
{"name":"KITIMAT","code":"CA-BC-KTM"},
{"name":"KITKATLA","code":"CA-BC-YKK"},
{"name":"KLEMTU","code":"CA-BC-YKT"},
{"name":"KNIGHTS INLET","code":"CA-BC-KNV"},
{"name":"LAC LA HACHE","code":"CA-BC-HLC"},
{"name":"LADNER","code":"CA-BC-LDN"},
{"name":"LADYSMITH","code":"CA-BC-LAD"},
{"name":"LAKE COWICHAN","code":"CA-BC-HOW"},
{"name":"LANGARA","code":"CA-BC-YLA"},
{"name":"LANGFORD STATION","code":"CA-BC-9FR"},
{"name":"LANGLEY","code":"CA-BC-LNG"},
{"name":"LANTZVILLE","code":"CA-BC-LZV"},
{"name":"LAVINGTON","code":"CA-BC-LVT"},
{"name":"LAZO","code":"CA-BC-LAZ"},
{"name":"LILLOOET","code":"CA-BC-LOO"},
{"name":"LINDELL BEACH","code":"CA-BC-LLB"},
{"name":"LIONS BAY","code":"CA-BC-LIB"},
{"name":"LOGAN LAKE","code":"CA-BC-LNL"},
{"name":"LONE BUTTE","code":"CA-BC-LNB"},
{"name":"LUMBY","code":"CA-BC-LUY"},
{"name":"LYALL HARBOUR","code":"CA-BC-YAJ"},
{"name":"LYTTON","code":"CA-BC-YLY"},
{"name":"MACKENZIE","code":"CA-BC-MNZ"},
{"name":"MALAKWA","code":"CA-BC-MKW"},
{"name":"MAPLE BAY","code":"CA-BC-YAQ"},
{"name":"MAPLE RIDGE","code":"CA-BC-MRI"},
{"name":"MASSET","code":"CA-BC-MSS"},
{"name":"MATSQUI","code":"CA-BC-TSQ"},
{"name":"MAYNE","code":"CA-BC-MYE"},
{"name":"MAYNE ISLAND","code":"CA-BC-MIE"},
{"name":"MCBRIDE","code":"CA-BC-MCB"},
{"name":"MCLEESE LAKE","code":"CA-BC-SEE"},
{"name":"MCLEOD LAKE","code":"CA-BC-MCL"},
{"name":"MEADOW CREEK","code":"CA-BC-MWC"},
{"name":"MENZIES BAY","code":"CA-BC-MEB"},
{"name":"MERRITT","code":"CA-BC-YMB"},
{"name":"MERRY ISLAND","code":"CA-BC-YMR"},
{"name":"MICA CREEK","code":"CA-BC-MIC"},
{"name":"MILL BAY","code":"CA-BC-ILB"},
{"name":"MILNER","code":"CA-BC-MLR"},
{"name":"MINERS BAY","code":"CA-BC-YAV"},
{"name":"MISSION","code":"CA-BC-MIB"},
{"name":"MONTAGNE HARBOR","code":"CA-BC-YMF"},
{"name":"MONTE LAKE","code":"CA-BC-LAE"},
{"name":"MONTNEY","code":"CA-BC-MNY"},
{"name":"NAKUSP","code":"CA-BC-NAK"},
{"name":"NAMU","code":"CA-BC-ZNU"},
{"name":"NANAIMO","code":"CA-BC-NNO"},
{"name":"NANOOSE BAY","code":"CA-BC-NNE"},
{"name":"NARAMATA","code":"CA-BC-NMT"},
{"name":"NELSON","code":"CA-BC-NLS"},
{"name":"NEW DENVER","code":"CA-BC-NDV"},
{"name":"NEW WESTMINSTER","code":"CA-BC-NWE"},
{"name":"NIMPKISH","code":"CA-BC-NIM"},
{"name":"NIMPO LAKE","code":"CA-BC-NPL"},
{"name":"NOOTKA","code":"CA-BC-NKA"},
{"name":"NOOTKA SOUND","code":"CA-BC-YNK"},
{"name":"NORTH ARM FRASER","code":"CA-BC-NAF"},
{"name":"NORTH BEND","code":"CA-BC-NRB"},
{"name":"NORTH PINE","code":"CA-BC-NPI"},
{"name":"NORTH SAANICH","code":"CA-BC-NSA"},
{"name":"NORTH VANCOUVER","code":"CA-BC-VAC"},
{"name":"OAK BAY","code":"CA-BC-OBY"},
{"name":"OCEAN FALLS","code":"CA-BC-OFA"},
{"name":"OKANAGAN FALLS","code":"CA-BC-OFL"},
{"name":"OLIVER","code":"CA-BC-OVR"},
{"name":"OSOYOOS","code":"CA-BC-OYS"},
{"name":"OYAMA","code":"CA-BC-OYM"},
{"name":"PANORAMA","code":"CA-BC-PRM"},
{"name":"PARKSVILLE","code":"CA-BC-PAE"},
{"name":"PARSON","code":"CA-BC-PXN"},
{"name":"PATRICIA BAY","code":"CA-BC-PTB"},
{"name":"PEACHLAND","code":"CA-BC-PHN"},
{"name":"PEMBERTON","code":"CA-BC-PBN"},
{"name":"PENDER HARBOUR","code":"CA-BC-PEH"},
{"name":"PENTICTON","code":"CA-BC-YYF"},
{"name":"PITT MEADOWS","code":"CA-BC-PIM"},
{"name":"PORT ALBERNI","code":"CA-BC-PAB"},
{"name":"PORT ALICE","code":"CA-BC-PAC"},
{"name":"PORT CLEMENTS","code":"CA-BC-PCS"},
{"name":"PORT COQUITLAM","code":"CA-BC-PTC"},
{"name":"PORT EDWARD","code":"CA-BC-PTE"},
{"name":"PORT HARDY","code":"CA-BC-PHY"},
{"name":"PORT MANN","code":"CA-BC-PNA"},
{"name":"PORT MCNEILL","code":"CA-BC-PMA"},
{"name":"PORT MELLON","code":"CA-BC-PML"},
{"name":"PORT MOODY/VANCOUVER","code":"CA-BC-PMO"},
{"name":"PORT SIMPSON","code":"CA-BC-PSI"},
{"name":"POUCE COUPE","code":"CA-BC-POU"},
{"name":"POWELL LAKE","code":"CA-BC-WPL"},
{"name":"POWELL RIVER","code":"CA-BC-POW"},
{"name":"PRINCE GEORGE","code":"CA-BC-PRG"},
{"name":"PRINCE RUPERT","code":"CA-BC-PRR"},
{"name":"PRINCETON","code":"CA-BC-PCT"},
{"name":"PRITCHARD","code":"CA-BC-PCH"},
{"name":"QUALICUM BEACH","code":"CA-BC-QUB"},
{"name":"QUATSINO","code":"CA-BC-QTS"},
{"name":"QUEEN CHARLOTTE","code":"CA-BC-QCH"},
{"name":"QUESNEL","code":"CA-BC-QSN"},
{"name":"RADIUM HOT SPRINGS","code":"CA-BC-RHP"},
{"name":"REDSTONE","code":"CA-BC-RED"},
{"name":"REVELSTOKE","code":"CA-BC-YRV"},
{"name":"RICHMOND","code":"CA-BC-RBC"},
{"name":"RIVERS INLET","code":"CA-BC-YRN"},
{"name":"ROBERT'S BANK","code":"CA-BC-RTB"},
{"name":"ROCK CREEK","code":"CA-BC-RKC"},
{"name":"ROOSVILLE","code":"CA-BC-ROO"},
{"name":"ROSEDALE","code":"CA-BC-RSO"},
{"name":"RUPERT INLET","code":"CA-BC-RUI"},
{"name":"RUSKIN","code":"CA-BC-RUS"},
{"name":"SAANICH","code":"CA-BC-SNH"},
{"name":"SAANICHTON","code":"CA-BC-SNC"},
{"name":"SAANICHTON BAY","code":"CA-BC-SXO"},
{"name":"SALMO","code":"CA-BC-SLM"},
{"name":"SALMON ARM","code":"CA-BC-YSN"},
{"name":"SALT SPRING ISLAND","code":"CA-BC-SSI"},
{"name":"SANDSPIT","code":"CA-BC-SSP"},
{"name":"SARDIS","code":"CA-BC-SAR"},
{"name":"SATURNA ISLAND","code":"CA-BC-SI3"},
{"name":"SAVONA","code":"CA-BC-SAV"},
{"name":"SAYWARD","code":"CA-BC-SYW"},
{"name":"SECHELT","code":"CA-BC-YHS"},
{"name":"SHALATH","code":"CA-BC-SHT"},
{"name":"SHAWNIGAN LAKE","code":"CA-BC-LSH"},
{"name":"SHEARWATER","code":"CA-BC-YSX"},
{"name":"SICAMOUS","code":"CA-BC-SIO"},
{"name":"SIDNEY","code":"CA-BC-SDY"},
{"name":"SILVA BAY","code":"CA-BC-SYF"},
{"name":"SKOOKUMCHUCK","code":"CA-BC-SKU"},
{"name":"SLOCAN","code":"CA-BC-SLQ"},
{"name":"SMITHERS","code":"CA-BC-YYD"},
{"name":"SOOKE","code":"CA-BC-SOO"},
{"name":"SORRENTO","code":"CA-BC-SRR"},
{"name":"SPARWOOD","code":"CA-BC-SPW"},
{"name":"SPENCES BRIDGE","code":"CA-BC-SCG"},
{"name":"SPRING ISLAND","code":"CA-BC-YSQ"},
{"name":"SQUAMISH","code":"CA-BC-SQA"},
{"name":"SQUIRREL COVE","code":"CA-BC-YSZ"},
{"name":"STEVESTON","code":"CA-BC-SVN"},
{"name":"STEWART","code":"CA-BC-STW"},
{"name":"STUART ISLAND","code":"CA-BC-YRR"},
{"name":"STURDEE","code":"CA-BC-YTC"},
{"name":"SULLIVAN BAY","code":"CA-BC-YTG"},
{"name":"SUMMERLAND","code":"CA-BC-SUZ"},
{"name":"SUMMIT LAKE","code":"CA-BC-IUM"},
{"name":"SUN PEAKS","code":"CA-BC-SPK"},
{"name":"SURREY","code":"CA-BC-SUY"},
{"name":"TAHSIS","code":"CA-BC-PTA"},
{"name":"TASU","code":"CA-BC-TAS"},
{"name":"TAYLOR","code":"CA-BC-TLO"},
{"name":"TELEGRAPH CREEK","code":"CA-BC-YTX"},
{"name":"TELEGRAPH HARBOUR","code":"CA-BC-YBQ"},
{"name":"TELKWA","code":"CA-BC-TWA"},
{"name":"TERRACE","code":"CA-BC-YXT"},
{"name":"TEXADA ISLAND","code":"CA-BC-TEX"},
{"name":"THAWWASSEN","code":"CA-BC-TWN"},
{"name":"THETIS ISLAND","code":"CA-BC-TTL"},
{"name":"TILBURY IS","code":"CA-BC-TLB"},
{"name":"TOFINO","code":"CA-BC-YAZ"},
{"name":"TRAIL","code":"CA-BC-TRL"},
{"name":"TRIPLE ISLAND","code":"CA-BC-YTI"},
{"name":"TSAWWASSEN","code":"CA-BC-TWS"},
{"name":"TUCKS","code":"CA-BC-TUC"},
{"name":"TUMBLER RIDGE","code":"CA-BC-TUX"},
{"name":"VANANDA","code":"CA-BC-VND"},
{"name":"VANCOUVER","code":"CA-BC-VAN"},
{"name":"VANCOUVER APT","code":"CA-BC-YVR"},
{"name":"VANCOUVER HARBOUR WATER APT/VANCOUVER","code":"CA-BC-CXH"},
{"name":"VANDERHOOF","code":"CA-BC-VHO"},
{"name":"VERNON","code":"CA-BC-VER"},
{"name":"VICTORIA","code":"CA-BC-VIC"},
{"name":"WATSON IS","code":"CA-BC-WAI"},
{"name":"WATSON ISLAND","code":"CA-BC-WAN"},
{"name":"WEST KELOWNA","code":"CA-BC-BCW"},
{"name":"WEST VANCOUVER","code":"CA-BC-WVR"},
{"name":"WESTBANK","code":"CA-BC-WBK"},
{"name":"WESTWOLD","code":"CA-BC-WSW"},
{"name":"WHISTLER","code":"CA-BC-YWS"},
{"name":"WHITE ROCK","code":"CA-BC-WHR"},
{"name":"WILLIAMS LAKE","code":"CA-BC-YWL"},
{"name":"WINDERMERE","code":"CA-BC-BHC"},
{"name":"WINFIELD","code":"CA-BC-WFD"},
{"name":"WOODFIBRE","code":"CA-BC-WOO"},
{"name":"YARROW","code":"CA-BC-YRW"},
{"name":"ZEBALLOS","code":"CA-BC-ZEB"},
        ]
      },
      {
        name: 'Manitoba',
        code: 'MB',
        cities: [
          {"name":"ALEXANDER","code":"CA-MB-ALD"},
{"name":"ALTONA","code":"CA-MB-AXO"},
{"name":"ANGUSVILLE","code":"CA-MB-AVM"},
{"name":"ANOLA","code":"CA-MB-ANL"},
{"name":"ARBORG","code":"CA-MB-ARG"},
{"name":"ARNAUD","code":"CA-MB-AAD"},
{"name":"ARNES","code":"CA-MB-YNR"},
{"name":"AUSTIN","code":"CA-MB-AUS"},
{"name":"BEAUSÉJOUR","code":"CA-MB-BSJ"},
{"name":"BENITO","code":"CA-MB-BNT"},
{"name":"BERENS RIVER APT","code":"CA-MB-YBV"},
{"name":"BINSCARTH","code":"CA-MB-BCH"},
{"name":"BIRDS HILL","code":"CA-MB-BHI"},
{"name":"BLOODVEIN","code":"CA-MB-YDV"},
{"name":"BLUMENORT","code":"CA-MB-BMN"},
{"name":"BOISSEVAIN","code":"CA-MB-BSS"},
{"name":"BRANDON","code":"CA-MB-YBR"},
{"name":"BROCHET","code":"CA-MB-YBT"},
{"name":"BRUNKILD","code":"CA-MB-BM3"},
{"name":"CARBERRY","code":"CA-MB-CBY"},
{"name":"CARMAN","code":"CA-MB-CMN"},
{"name":"CARTIER","code":"CA-MB-RTE"},
{"name":"CHURCHILL","code":"CA-MB-CHV"},
{"name":"CLANDEBOYE","code":"CA-MB-CYE"},
{"name":"CO-OP POINT","code":"CA-MB-YCP"},
{"name":"CRANBERRY PORTAGE","code":"CA-MB-CPG"},
{"name":"CROSS LAKE","code":"CA-MB-YCR"},
{"name":"CRYSTAL CITY","code":"CA-MB-CCI"},
{"name":"DAUPHIN","code":"CA-MB-YDN"},
{"name":"DECKER","code":"CA-MB-DEC"},
{"name":"DELORAINE","code":"CA-MB-DRN"},
{"name":"DUGALD","code":"CA-MB-DGD"},
{"name":"DUNREA","code":"CA-MB-DRA"},
{"name":"EAST SAINT-PAUL","code":"CA-MB-EPL"},
{"name":"ELIE","code":"CA-MB-ELI"},
{"name":"ELKHORN","code":"CA-MB-EHO"},
{"name":"ELM CREEK","code":"CA-MB-ECK"},
{"name":"ELMA","code":"CA-MB-EML"},
{"name":"EMERSON","code":"CA-MB-EME"},
{"name":"ETHELBERT","code":"CA-MB-ETH"},
{"name":"FAULKNER","code":"CA-MB-FKR"},
{"name":"FISHER BRANCH","code":"CA-MB-FIB"},
{"name":"FLIN FLON","code":"CA-MB-YFO"},
{"name":"GILBERT PLAINS","code":"CA-MB-GPS"},
{"name":"GILLAM","code":"CA-MB-YGX"},
{"name":"GIMLI","code":"CA-MB-YGM"},
{"name":"GODS NARROWS","code":"CA-MB-YGO"},
{"name":"GODS RIVER","code":"CA-MB-ZGI"},
{"name":"GRAND VIEW","code":"CA-MB-GVI"},
{"name":"GRANDE POINTE","code":"CA-MB-RPE"},
{"name":"GRETNA","code":"CA-MB-GRT"},
{"name":"GROSSE ISLE","code":"CA-MB-GRI"},
{"name":"GRUNTHAL","code":"CA-MB-GTH"},
{"name":"GUNTON","code":"CA-MB-GTN"},
{"name":"HADASHVILLE","code":"CA-MB-HHV"},
{"name":"HALBSTADT","code":"CA-MB-HBD"},
{"name":"HAMIOTA","code":"CA-MB-HMA"},
{"name":"HANOVER","code":"CA-MB-HHH"},
{"name":"HEADINGLEY","code":"CA-MB-HDL"},
{"name":"HIGH BLUFF","code":"CA-MB-HBL"},
{"name":"ILE DES CHÊNES","code":"CA-MB-IDC"},
{"name":"ILFORD","code":"CA-MB-ILF"},
{"name":"INGLIS","code":"CA-MB-IGI"},
{"name":"ISLAND LAKE","code":"CA-MB-ISL"},
{"name":"ISLAND LK/GARDEN HILL","code":"CA-MB-YIV"},
{"name":"JENPEG","code":"CA-MB-ZJG"},
{"name":"KELSEY","code":"CA-MB-KES"},
{"name":"KELWOOD","code":"CA-MB-KWO"},
{"name":"KENNOSAO LAKE","code":"CA-MB-YKI"},
{"name":"KENVILLE","code":"CA-MB-KVI"},
{"name":"KILLARNEY","code":"CA-MB-KRY"},
{"name":"KLEEFELD","code":"CA-MB-KFD"},
{"name":"KNEE LAKE","code":"CA-MB-YKE"},
{"name":"KOLA","code":"CA-MB-KOL"},
{"name":"LA BROQUERIE","code":"CA-MB-LBQ"},
{"name":"LA SALLE","code":"CA-MB-LMW"},
{"name":"LAC DU BONNET","code":"CA-MB-LDB"},
{"name":"LANDMARK","code":"CA-MB-LMK"},
{"name":"LANGRUTH","code":"CA-MB-LGR"},
{"name":"LAUDER","code":"CA-MB-DLE"},
{"name":"LAURIE RIVER","code":"CA-MB-LRQ"},
{"name":"LAURIER","code":"CA-MB-LAR"},
{"name":"LEAF RAPIDS","code":"CA-MB-LRA"},
{"name":"LIBAU","code":"CA-MB-LBU"},
{"name":"LITTLE GRAND RAPIDS","code":"CA-MB-ZGR"},
{"name":"LOCKPORT","code":"CA-MB-LKP"},
{"name":"LORETTE","code":"CA-MB-LRT"},
{"name":"LOWE FARM","code":"CA-MB-LWF"},
{"name":"LYNN LAKE","code":"CA-MB-YYL"},
{"name":"MACDONALD","code":"CA-MB-MD8"},
{"name":"MACGREGOR","code":"CA-MB-MGG"},
{"name":"MANITOU","code":"CA-MB-MTU"},
{"name":"MARCHAND","code":"CA-MB-MCD"},
{"name":"MARGARET","code":"CA-MB-MRG"},
{"name":"MARQUETTE","code":"CA-MB-MQT"},
{"name":"MATHER","code":"CA-MB-MAE"},
{"name":"MCCREARY","code":"CA-MB-MCY"},
{"name":"MELITA","code":"CA-MB-MLT"},
{"name":"MIAMI","code":"CA-MB-MMI"},
{"name":"MIDDLEBRO","code":"CA-MB-MDD"},
{"name":"MILNER RIDGE","code":"CA-MB-MLN"},
{"name":"MINITONAS","code":"CA-MB-TOS"},
{"name":"MINNEDOSA","code":"CA-MB-MDO"},
{"name":"MITCHELL","code":"CA-MB-MTH"},
{"name":"MOOSE LAKE","code":"CA-MB-YAD"},
{"name":"MORDEN","code":"CA-MB-MOR"},
{"name":"MORRIS","code":"CA-MB-MRS"},
{"name":"MYRTLE","code":"CA-MB-MYR"},
{"name":"NARCISSE","code":"CA-MB-NRS"},
{"name":"NEEPAWA","code":"CA-MB-NEE"},
{"name":"NEGGINAN","code":"CA-MB-ZNG"},
{"name":"NEW BOTHWELL","code":"CA-MB-NBM"},
{"name":"NEWTON","code":"CA-MB-EWT"},
{"name":"NIVERVILLE","code":"CA-MB-NVL"},
{"name":"NORWAY HOUSE","code":"CA-MB-YNE"},
{"name":"NOTRE-DAME-DE-LOURDES","code":"CA-MB-NDL"},
{"name":"OAK BLUFF","code":"CA-MB-KRV"},
{"name":"OAK LAKE","code":"CA-MB-OAL"},
{"name":"OAKBANK","code":"CA-MB-OBK"},
{"name":"OAKVILLE","code":"CA-MB-OAJ"},
{"name":"OTTERBURNE","code":"CA-MB-OTB"},
{"name":"OXFORD HOUSE","code":"CA-MB-YOH"},
{"name":"PETERSFIELD","code":"CA-MB-PFI"},
{"name":"PIERSON","code":"CA-MB-PSO"},
{"name":"PIKWITONEI","code":"CA-MB-PIW"},
{"name":"PINE FALLS","code":"CA-MB-PIF"},
{"name":"PINE RIVER","code":"CA-MB-PIR"},
{"name":"PINEY","code":"CA-MB-PNY"},
{"name":"PIPESTONE","code":"CA-MB-PPO"},
{"name":"PLUM COULEE","code":"CA-MB-PLC"},
{"name":"PLUMAS","code":"CA-MB-PMS"},
{"name":"PORT NELSON","code":"CA-MB-PNE"},
{"name":"PORTAGE LA PRAIRIE","code":"CA-MB-PLP"},
{"name":"RAPID CITY","code":"CA-MB-RPC"},
{"name":"REINFELD","code":"CA-MB-REI"},
{"name":"RIDGEVILLE","code":"CA-MB-RVL"},
{"name":"RIVERS","code":"CA-MB-YYI"},
{"name":"RIVERTON","code":"CA-MB-RVO"},
{"name":"ROBLIN","code":"CA-MB-ROB"},
{"name":"ROSENORT","code":"CA-MB-RNO"},
{"name":"ROSSER","code":"CA-MB-RSR"},
{"name":"RUSSELL","code":"CA-MB-RLL"},
{"name":"SAINT-BONIFACE","code":"CA-MB-BFW"},
{"name":"SAINT-CLAUDE","code":"CA-MB-SCU"},
{"name":"SAINT-EUSTACHE","code":"CA-MB-EMT"},
{"name":"SAINT-FRANÇOIS-XAVIER","code":"CA-MB-FCX"},
{"name":"SAINT-JEAN-BAPTISTE","code":"CA-MB-SAJ"},
{"name":"SAINT-JOSEPH","code":"CA-MB-JPH"},
{"name":"SAINT-LAURENT","code":"CA-MB-LRN"},
{"name":"SAINT-PIERRE-JOLYS","code":"CA-MB-SPJ"},
{"name":"SAINTE-AGATHE","code":"CA-MB-SAH"},
{"name":"SAINTE-ANNE","code":"CA-MB-SM8"},
{"name":"SAINTE-ANNE-DES-CHÊNES","code":"CA-MB-SAS"},
{"name":"SAINTE-THÉRÈSE-POINT","code":"CA-MB-YST"},
{"name":"SANFORD","code":"CA-MB-SF2"},
{"name":"SARTO","code":"CA-MB-RTO"},
{"name":"SCHANZENFELD","code":"CA-MB-SZF"},
{"name":"SELKIRK","code":"CA-MB-SEL"},
{"name":"SHAMATTAWA","code":"CA-MB-ZTM"},
{"name":"SHILO","code":"CA-MB-YLO"},
{"name":"SHOAL LAKE","code":"CA-MB-HOK"},
{"name":"SNOW LAKE","code":"CA-MB-SWL"},
{"name":"SNOWFLAKE","code":"CA-MB-SFK"},
{"name":"SOUTH INDIAN LAKE","code":"CA-MB-XSI"},
{"name":"SOUTHPORT","code":"CA-MB-SOH"},
{"name":"SPERLING","code":"CA-MB-SRG"},
{"name":"SPLIT LAKE","code":"CA-MB-SLK"},
{"name":"SPRAGUE","code":"CA-MB-SPE"},
{"name":"SPRINGFIELD","code":"CA-MB-SFQ"},
{"name":"STARBUCK","code":"CA-MB-SCK"},
{"name":"STEINBACH","code":"CA-MB-STB"},
{"name":"STEPHENFIELD","code":"CA-MB-SP5"},
{"name":"STOCKTON","code":"CA-MB-STK"},
{"name":"STONEWALL","code":"CA-MB-WLL"},
{"name":"STONY MOUNTAIN","code":"CA-MB-SYM"},
{"name":"STRATHCLAIR","code":"CA-MB-SCR"},
{"name":"SWAN RIVER","code":"CA-MB-ZJN"},
{"name":"TADOULE LAKE","code":"CA-MB-XTL"},
{"name":"TEULON","code":"CA-MB-TEU"},
{"name":"THE PAS","code":"CA-MB-YQD"},
{"name":"THICKET PORTAGE","code":"CA-MB-YTD"},
{"name":"THOMPSON","code":"CA-MB-YTH"},
{"name":"TOLSTOI","code":"CA-MB-TTI"},
{"name":"TREHERNE","code":"CA-MB-TEH"},
{"name":"VASSAR","code":"CA-MB-VSR"},
{"name":"VICTORIA BEACH","code":"CA-MB-ICV"},
{"name":"VIRDEN","code":"CA-MB-VRD"},
{"name":"VITA","code":"CA-MB-VTA"},
{"name":"WARREN","code":"CA-MB-WRR"},
{"name":"WAWANESA","code":"CA-MB-WAW"},
{"name":"WEST SAINT PAUL","code":"CA-MB-ESP"},
{"name":"WHITEMOUTH","code":"CA-MB-WMH"},
{"name":"WICOMICO","code":"CA-MB-WCM"},
{"name":"WINKLER","code":"CA-MB-WNK"},
{"name":"WINNIPEG","code":"CA-MB-WNP"},
{"name":"WINNIPEG APT","code":"CA-MB-YWG"},
{"name":"WOODLANDS","code":"CA-MB-WDL"},
{"name":"YORK LANDING","code":"CA-MB-ZAC"},
        ]
      },
      {
        name: 'New Brunswick',
        code: 'NB',
        cities: [
          {"name":"ALLARDVILLE","code":"CA-NB-ALE"},
{"name":"ANFIELD","code":"CA-NB-ANF"},
{"name":"APOHAQUI","code":"CA-NB-APO"},
{"name":"ATHOLVILLE","code":"CA-NB-ALV"},
{"name":"AULAC","code":"CA-NB-AUL"},
{"name":"BAIE-SAINTE-ANNE","code":"CA-NB-BSA"},
{"name":"BAIRDSVILLE","code":"CA-NB-BDI"},
{"name":"BALMORAL","code":"CA-NB-BL2"},
{"name":"BATHURST","code":"CA-NB-BAT"},
{"name":"BAYFIELD","code":"CA-NB-BYD"},
{"name":"BAYSIDE (ST ROBERTS)","code":"CA-NB-BAY"},
{"name":"BAYSIDE, CHARLOTTE","code":"CA-NB-BST"},
{"name":"BEAR ISLAND","code":"CA-NB-BEI"},
{"name":"BEAVER HARBOUR","code":"CA-NB-BEH"},
{"name":"BELLEDUNE","code":"CA-NB-BEL"},
{"name":"BELLEISLE CREEK","code":"CA-NB-EEK"},
{"name":"BERESFORD","code":"CA-NB-BFR"},
{"name":"BERRY MILLS","code":"CA-NB-BMS"},
{"name":"BIG RIVER","code":"CA-NB-BGR"},
{"name":"BLACKS HARBOUR","code":"CA-NB-BLH"},
{"name":"BLACKVILLE","code":"CA-NB-BKV"},
{"name":"BLOOMFIELD","code":"CA-NB-BMF"},
{"name":"BOIESTOWN","code":"CA-NB-BOI"},
{"name":"BOUCTOUCHE","code":"CA-NB-BUE"},
{"name":"BRISTOL","code":"CA-NB-BTO"},
{"name":"BURTTS CORNER","code":"CA-NB-BCR"},
{"name":"CAMPBELLTON","code":"CA-NB-COM"},
{"name":"CAMPOBELLO","code":"CA-NB-CPB"},
{"name":"CANAPORT","code":"CA-NB-CPT"},
{"name":"CAP-PELE","code":"CA-NB-CAP"},
{"name":"CAPE TORMENTINE","code":"CA-NB-CTO"},
{"name":"CARAQUET","code":"CA-NB-CAQ"},
{"name":"CENTRAL WATERVILLE","code":"CA-NB-CWR"},
{"name":"CENTREVILLE","code":"CA-NB-CEN"},
{"name":"CHARLO","code":"CA-NB-YCL"},
{"name":"CHATHAM","code":"CA-NB-CHN"},
{"name":"CHIPMAN","code":"CA-NB-CPN"},
{"name":"CLAIR","code":"CA-NB-CIR"},
{"name":"COCAGNE","code":"CA-NB-COE"},
{"name":"CORNHILL","code":"CA-NB-CNH"},
{"name":"CUMBERLAND BAY","code":"CA-NB-CUM"},
{"name":"DALHOUSIE","code":"CA-NB-DHS"},
{"name":"DEER ISLAND","code":"CA-NB-DEI"},
{"name":"DIEPPE","code":"CA-NB-DPP"},
{"name":"DOAKTOWN","code":"CA-NB-DOA"},
{"name":"DORCHESTER","code":"CA-NB-DHR"},
{"name":"DRUMMOND","code":"CA-NB-DMO"},
{"name":"EDMUNDSTON","code":"CA-NB-EDN"},
{"name":"ELGIN","code":"CA-NB-EGN"},
{"name":"FLORENCEVILLE","code":"CA-NB-FLV"},
{"name":"FOSTERVILLE","code":"CA-NB-FOS"},
{"name":"FREDERICTON","code":"CA-NB-FRE"},
{"name":"GAGETOWN","code":"CA-NB-YCX"},
{"name":"GRAND FALLS","code":"CA-NB-GRF"},
{"name":"GRAND MANAN","code":"CA-NB-GMA"},
{"name":"GRAND-SAULT","code":"CA-NB-GSU"},
{"name":"GRANDE ANSE","code":"CA-NB-GAE"},
{"name":"GRANDE-DIGUE","code":"CA-NB-EDI"},
{"name":"HANWELL","code":"CA-NB-HWL"},
{"name":"HARTLAND","code":"CA-NB-HTD"},
{"name":"HARVEY STATION","code":"CA-NB-HRV"},
{"name":"HATFIELD POINT","code":"CA-NB-HDP"},
{"name":"HAVELOCK","code":"CA-NB-HAV"},
{"name":"HILLSBOROUGH","code":"CA-NB-HIL"},
{"name":"INKERMAN","code":"CA-NB-KER"},
{"name":"JACKSONVILLE","code":"CA-NB-JAV"},
{"name":"JEMSEG","code":"CA-NB-JEG"},
{"name":"JUNIPER","code":"CA-NB-JUN"},
{"name":"KEDGWICK","code":"CA-NB-KGK"},
{"name":"KESWICK RIDGE","code":"CA-NB-KSW"},
{"name":"LAMÈQUE","code":"CA-NB-LQE"},
{"name":"LEPREAU","code":"CA-NB-LEP"},
{"name":"LIMESTONE","code":"CA-NB-LMS"},
{"name":"LITTLE SHEMOGUE","code":"CA-NB-LSO"},
{"name":"MACES BAY","code":"CA-NB-MBC"},
{"name":"MACTAQUAC","code":"CA-NB-MTQ"},
{"name":"MAISONNETTE POINT","code":"CA-NB-MAI"},
{"name":"MAPLE RIDGE","code":"CA-NB-MPR"},
{"name":"MATAPEDIA","code":"CA-NB-MPA"},
{"name":"MCADAM","code":"CA-NB-MCA"},
{"name":"MEDUCTIC","code":"CA-NB-MED"},
{"name":"MEMRAMCOOK","code":"CA-NB-MAM"},
{"name":"MILLBANK","code":"CA-NB-MLK"},
{"name":"MINTO","code":"CA-NB-MTO"},
{"name":"MIRAMICHI","code":"CA-NB-MIR"},
{"name":"MONCTON","code":"CA-NB-MNT"},
{"name":"NACKAWIC","code":"CA-NB-NAC"},
{"name":"NAPADOGAN","code":"CA-NB-NDN"},
{"name":"NAPAN","code":"CA-NB-APA"},
{"name":"NEGUAC","code":"CA-NB-NGU"},
{"name":"NELSON-MIRAMICHI","code":"CA-NB-NMI"},
{"name":"NEW CANAAN","code":"CA-NB-NCA"},
{"name":"NEW DENMARK","code":"CA-NB-NDK"},
{"name":"NEWCASTLE","code":"CA-NB-NCT"},
{"name":"NORTHAMPTON","code":"CA-NB-NPP"},
{"name":"OROMOCTO","code":"CA-NB-ORO"},
{"name":"PENNFIELD","code":"CA-NB-PFD"},
{"name":"PENNFIELD RIDGE","code":"CA-NB-PEN"},
{"name":"PENOBSQUIS","code":"CA-NB-PNB"},
{"name":"PERTH-ANDOVER","code":"CA-NB-PAN"},
{"name":"PETIT ROCHER","code":"CA-NB-PRO"},
{"name":"PETITCODIAC","code":"CA-NB-PCD"},
{"name":"PLASTER ROCK","code":"CA-NB-PLR"},
{"name":"POINT-ESCUMINAC","code":"CA-NB-ESX"},
{"name":"POINTE-SAPIN","code":"CA-NB-PSA"},
{"name":"PORT ELGIN","code":"CA-NB-PEL"},
{"name":"PRINCE WILLIAM","code":"CA-NB-PRW"},
{"name":"QUISPAMSIS","code":"CA-NB-QPS"},
{"name":"REXTON","code":"CA-NB-RXN"},
{"name":"RICHIBUCTO","code":"CA-NB-RBT"},
{"name":"RIVIÈRE-VERTE","code":"CA-NB-RII"},
{"name":"ROGERSVILLE","code":"CA-NB-RGV"},
{"name":"ROTHESAY","code":"CA-NB-RSZ"},
{"name":"SACKVILLE","code":"CA-NB-SAC"},
{"name":"SAINT FRANCOIS DE MADAWASKA","code":"CA-NB-FDM"},
{"name":"SAINT-ANDRÉ","code":"CA-NB-SRK"},
{"name":"SAINT-ANDREWS","code":"CA-NB-SAD"},
{"name":"SAINT-ANNE-DE-KENT","code":"CA-NB-SKE"},
{"name":"SAINT-GEORGE","code":"CA-NB-SGE"},
{"name":"SAINT-JACQUES","code":"CA-NB-SJJ"},
{"name":"SAINT-JOHN","code":"CA-NB-SJB"},
{"name":"SAINT-JOHN APT","code":"CA-NB-YSJ"},
{"name":"SAINT-LÉONARD","code":"CA-NB-YSL"},
{"name":"SAINT-LOUIS DE KENT","code":"CA-NB-SLS"},
{"name":"SAINT-QUENTIN","code":"CA-NB-SQE"},
{"name":"SAINT-SIMON","code":"CA-NB-SMN"},
{"name":"SAINT-STEPHEN","code":"CA-NB-STS"},
{"name":"SAINTE-ANNE","code":"CA-NB-STA"},
{"name":"SCOUDOUC","code":"CA-NB-SCC"},
{"name":"SEAL COVE","code":"CA-NB-SC8"},
{"name":"SHEDIAC","code":"CA-NB-SHE"},
{"name":"SHEMOGUE","code":"CA-NB-SMG"},
{"name":"SHIPPEGAN","code":"CA-NB-SHP"},
{"name":"SKIFF LAKE","code":"CA-NB-SKK"},
{"name":"SUSSEX","code":"CA-NB-SUS"},
{"name":"SUSSEX CORNER","code":"CA-NB-SXE"},
{"name":"TABUSINTAC","code":"CA-NB-TAB"},
{"name":"TRACADIE","code":"CA-NB-TRA"},
{"name":"UPPER COVERDALE","code":"CA-NB-PPE"},
{"name":"WELSHPOOL","code":"CA-NB-WEP"},
{"name":"WHITNEY","code":"CA-NB-WTY"},
{"name":"WOODSIDE","code":"CA-NB-OOW"},
{"name":"WOODSTOCK","code":"CA-NB-WDS"},
        ]
      },
      {
        name: 'Newfoundland and Labrador',
        code: 'NL',
        cities: [
          {"name":"ADLAVIK","code":"CA-NL-ALK"},
{"name":"AGUATHUNA","code":"CA-NL-AGU"},
{"name":"ARGENTIA","code":"CA-NL-NWP"},
{"name":"ARNOLD'S COVE","code":"CA-NL-ARC"},
{"name":"AVONDALE","code":"CA-NL-AVO"},
{"name":"BAIE VERTE","code":"CA-NL-BVE"},
{"name":"BAY BULLS","code":"CA-NL-BBU"},
{"name":"BAY DE VERDE","code":"CA-NL-BDV"},
{"name":"BAY ROBERTS","code":"CA-NL-BYR"},
{"name":"BENOIT'S COVE","code":"CA-NL-BSC"},
{"name":"BISHOP'S FALLS","code":"CA-NL-BPF"},
{"name":"BISHOPS FALLS","code":"CA-NL-BHF"},
{"name":"BLACK TICKLE","code":"CA-NL-YBI"},
{"name":"BOTWOOD","code":"CA-NL-BWD"},
{"name":"BUCHANS","code":"CA-NL-YZM"},
{"name":"BULL ARM","code":"CA-NL-BUA"},
{"name":"BURGEO","code":"CA-NL-BUO"},
{"name":"BURGOYNES COVE","code":"CA-NL-BGC"},
{"name":"BURIN","code":"CA-NL-BUI"},
{"name":"BURNSIDE","code":"CA-NL-BUS"},
{"name":"CAPE BROYLE","code":"CA-NL-CPR"},
{"name":"CAPE RAY","code":"CA-NL-CPY"},
{"name":"CARBONEAR","code":"CA-NL-CRB"},
{"name":"CARMANVILLE","code":"CA-NL-CMV"},
{"name":"CARTWRIGHT","code":"CA-NL-YRF"},
{"name":"CATALINA","code":"CA-NL-CAT"},
{"name":"CENTREVILLE","code":"CA-NL-NLC"},
{"name":"CHAMBERLAINS","code":"CA-NL-CBL"},
{"name":"CHANGE ISLANDS","code":"CA-NL-CIL"},
{"name":"CHANNEL-PORT AUX BASQUES","code":"CA-NL-PBQ"},
{"name":"CHAPEL ARM","code":"CA-NL-CP5"},
{"name":"CHURCHILL FALLS","code":"CA-NL-ZUM"},
{"name":"CLARENVILLE","code":"CA-NL-CLE"},
{"name":"CLARKE'S BEACH","code":"CA-NL-CBH"},
{"name":"COLEY'S POINT","code":"CA-NL-CYP"},
{"name":"COME BY CHANCE","code":"CA-NL-CBC"},
{"name":"COMFORT COVE","code":"CA-NL-CCV"},
{"name":"COMFORT COVE-NEWSTEAD","code":"CA-NL-CND"},
{"name":"CONCEPTION BAY SOUTH","code":"CA-NL-ZBL"},
{"name":"CORNER BROOK","code":"CA-NL-CBK"},
{"name":"COW HEAD","code":"CA-NL-CWH"},
{"name":"CUPIDS","code":"CA-NL-CPS"},
{"name":"DANIELS HARBOUR","code":"CA-NL-YDH"},
{"name":"DAVIS INLET","code":"CA-NL-YDI"},
{"name":"DEER LAKE","code":"CA-NL-YDF"},
{"name":"DILDO","code":"CA-NL-DIL"},
{"name":"EDWARDS COVE","code":"CA-NL-EDC"},
{"name":"FERMEUSE","code":"CA-NL-FME"},
{"name":"FLAT ROCK","code":"CA-NL-FLT"},
{"name":"FOGO","code":"CA-NL-FOG"},
{"name":"FORTUNE","code":"CA-NL-FOR"},
{"name":"FOX HARBOUR (ST LEWIS)","code":"CA-NL-YFX"},
{"name":"FRESHWATER","code":"CA-NL-FWP"},
{"name":"GANDER","code":"CA-NL-GAN"},
{"name":"GLOVERTOWN","code":"CA-NL-GVN"},
{"name":"GOOSE BAY","code":"CA-NL-GOO"},
{"name":"GRAND BANK","code":"CA-NL-GRB"},
{"name":"GRAND FALLS-WINDSOR","code":"CA-NL-GFA"},
{"name":"HAMPDEN","code":"CA-NL-HMP"},
{"name":"HANTS HARBOUR","code":"CA-NL-HHB"},
{"name":"HAPPY VALLEY","code":"CA-NL-HVY"},
{"name":"HARBOUR BRETON","code":"CA-NL-HBR"},
{"name":"HARBOUR GRACE","code":"CA-NL-HRE"},
{"name":"HEBRON","code":"CA-NL-HBN"},
{"name":"HOLYROOD","code":"CA-NL-HOD"},
{"name":"HOPEDALE","code":"CA-NL-HOP"},
{"name":"INDIAN HARBOUR","code":"CA-NL-IHR"},
{"name":"JACKSON ARM","code":"CA-NL-JAC"},
{"name":"L'ANSE-AU-LOUP","code":"CA-NL-ALO"},
{"name":"LABRADOR CITY","code":"CA-NL-LBR"},
{"name":"LAMALINE","code":"CA-NL-LMA"},
{"name":"LEWISPORTE","code":"CA-NL-LWP"},
{"name":"LOGY BAY","code":"CA-NL-LGY"},
{"name":"LOMOND","code":"CA-NL-LMD"},
{"name":"LONG HARBOUR","code":"CA-NL-LOH"},
{"name":"LONG POND","code":"CA-NL-LOP"},
{"name":"LOWER COVE","code":"CA-NL-LCV"},
{"name":"MAIN BROOK","code":"CA-NL-MBR"},
{"name":"MAKKOVIK","code":"CA-NL-YMN"},
{"name":"MARY'S HARBOUR","code":"CA-NL-YMH"},
{"name":"MARYSTOWN","code":"CA-NL-MTN"},
{"name":"MIQUELON","code":"CA-NL-MIQ"},
{"name":"MOUNT ARLINGTON HEIGHTS","code":"CA-NL-MNA"},
{"name":"MOUNT PEARL","code":"CA-NL-MPE"},
{"name":"NAIN","code":"CA-NL-NAI"},
{"name":"NEW HARBOUR","code":"CA-NL-NHU"},
{"name":"NORMAN'S COVE","code":"CA-NL-NCV"},
{"name":"OLD PERLICAN","code":"CA-NL-OPL"},
{"name":"PARADISE","code":"CA-NL-PRI"},
{"name":"PARADISE RIVER","code":"CA-NL-YDE"},
{"name":"PASADENA","code":"CA-NL-PSD"},
{"name":"PETTY HARBOUR","code":"CA-NL-PHB"},
{"name":"PORT AU PORT","code":"CA-NL-PAP"},
{"name":"PORT NELSON","code":"CA-NL-PNN"},
{"name":"RAMEA","code":"CA-NL-RAM"},
{"name":"REIDVILLE","code":"CA-NL-REV"},
{"name":"RIGOLET","code":"CA-NL-YRG"},
{"name":"ROACHES LINE","code":"CA-NL-ROA"},
{"name":"ROBINSONS","code":"CA-NL-RBN"},
{"name":"RODDICKTON","code":"CA-NL-ROD"},
{"name":"SAGLEK","code":"CA-NL-YSV"},
{"name":"SAINT-ANTHONY","code":"CA-NL-SAT"},
{"name":"SAINT-JOHN'S","code":"CA-NL-SJF"},
{"name":"SAINT-JOHNS-APT","code":"CA-NL-YYT"},
{"name":"SAINT-JOSEPH'S","code":"CA-NL-SJH"},
{"name":"SAINT-LAWRENCE","code":"CA-NL-SLW"},
{"name":"SAINT-MARYS","code":"CA-NL-SMS"},
{"name":"SAINT-SHOTTS","code":"CA-NL-SST"},
{"name":"SELDOM","code":"CA-NL-SEM"},
{"name":"SEVEN ISLANDS BAY","code":"CA-NL-SEB"},
{"name":"SPANIARDS BAY","code":"CA-NL-SPN"},
{"name":"SPRINGDALE","code":"CA-NL-SPD"},
{"name":"STEPHENVILLE","code":"CA-NL-STV"},
{"name":"SUNNYSIDE","code":"CA-NL-SYS"},
{"name":"TERRA NOVA","code":"CA-NL-TER"},
{"name":"TILT COVE","code":"CA-NL-TCV"},
{"name":"TORBAY","code":"CA-NL-TAY"},
{"name":"TREPASSEY","code":"CA-NL-TPS"},
{"name":"TRINITY BAY","code":"CA-NL-TRB"},
{"name":"TRITON","code":"CA-NL-TRI"},
{"name":"TROUT RIVER","code":"CA-NL-TTR"},
{"name":"TWILLINGATE","code":"CA-NL-TWI"},
{"name":"VALLEYFIELD","code":"CA-NL-VYD"},
{"name":"VOISEY'S BAY","code":"CA-NL-VOB"},
{"name":"WABUSH","code":"CA-NL-YWK"},
{"name":"WESLEYVILLE","code":"CA-NL-WYV"},
{"name":"WHIFFEN HEAD","code":"CA-NL-WHH"},
{"name":"WHITBOURNE","code":"CA-NL-WTB"},
{"name":"WILLIAMS HARBOUR","code":"CA-NL-YWM"},
{"name":"WITLESS BAY","code":"CA-NL-WB8"},
        ]
      },
      {
        name: 'Northwest Territories',
        code: 'NT',
        cities: [
          {"name":"AKLAVIK","code":"CA-NT-AKL"},
{"name":"COLVILLE LAKE","code":"CA-NT-YCK"},
{"name":"DÉLINE","code":"CA-NT-YWJ"},
{"name":"FORT FRANKLIN","code":"CA-NT-FKN"},
{"name":"FORT GOOD HOPE","code":"CA-NT-YGH"},
{"name":"FORT LIARD","code":"CA-NT-YJF"},
{"name":"FORT MCPHERSON","code":"CA-NT-ZFM"},
{"name":"FORT PROVIDENCE","code":"CA-NT-FPR"},
{"name":"FORT RELIANCE","code":"CA-NT-YFL"},
{"name":"FORT RESOLUTION","code":"CA-NT-YFR"},
{"name":"FORT SIMPSON","code":"CA-NT-FSI"},
{"name":"FORT SMITH","code":"CA-NT-FSM"},
{"name":"FROBISHER BAY APT","code":"CA-NT-YFB"},
{"name":"GREAT BEAR LAKE","code":"CA-NT-DAS"},
{"name":"HAY RIVER","code":"CA-NT-YHY"},
{"name":"HOLMAN","code":"CA-NT-YHI"},
{"name":"INUVIK","code":"CA-NT-YEV"},
{"name":"KASBA LAKE","code":"CA-NT-YDU"},
{"name":"KIMMIRUT/LAKE HARBOUR","code":"CA-NT-YLC"},
{"name":"LUTSELKE/SNOWDRIFT","code":"CA-NT-YSG"},
{"name":"MOULD BAY","code":"CA-NT-YMD"},
{"name":"NORMAN WELLS","code":"CA-NT-YVQ"},
{"name":"OBRE LAKE","code":"CA-NT-YDW"},
{"name":"PAULATUK","code":"CA-NT-YPC"},
{"name":"PELLY BAY","code":"CA-NT-YUF"},
{"name":"PINE POINT","code":"CA-NT-YPP"},
{"name":"RAE","code":"CA-NT-RAE"},
{"name":"SACHS HARBOUR","code":"CA-NT-YSY"},
{"name":"SNARE LAKE","code":"CA-NT-YFJ"},
{"name":"TALTHEILEI NARROWS","code":"CA-NT-GSL"},
{"name":"TUKTOYAKTUK","code":"CA-NT-TUK"},
{"name":"TULITA/FORT NORMAN","code":"CA-NT-ZFN"},
{"name":"TUNGSTEN","code":"CA-NT-TNS"},
{"name":"WHA TI/LAC LA MARTRE","code":"CA-NT-YLE"},
{"name":"WRIGLEY","code":"CA-NT-YWY"},
{"name":"YELLOWKNIFE","code":"CA-NT-YZF"},
        ]
      },
      {
        name: 'Nova Scotia',
        code: 'NS',
        cities: [
          {"name":"ABERCROMBIE","code":"CA-NS-ABE"},
{"name":"ADVOCATE HARBOUR","code":"CA-NS-ADV"},
{"name":"ALBERT BRIDGE","code":"CA-NS-ABG"},
{"name":"AMHERST","code":"CA-NS-AMN"},
{"name":"ANTIGONISH","code":"CA-NS-ANT"},
{"name":"ARGYLE","code":"CA-NS-AGY"},
{"name":"ARICHAT","code":"CA-NS-ARI"},
{"name":"AULDS COVE","code":"CA-NS-ACO"},
{"name":"AVONPORT","code":"CA-NS-AVP"},
{"name":"AYLESFORD","code":"CA-NS-AYD"},
{"name":"BADDECK","code":"CA-NS-BAD"},
{"name":"BALD ROCK","code":"CA-NS-BDR"},
{"name":"BARRINGTON","code":"CA-NS-BAN"},
{"name":"BARRINGTON PASSAGE","code":"CA-NS-BPP"},
{"name":"BARRS CORNER","code":"CA-NS-BRS"},
{"name":"BEAVER BANK","code":"CA-NS-BVB"},
{"name":"BEAVER BROOK","code":"CA-NS-BBR"},
{"name":"BEDFORD","code":"CA-NS-BDF"},
{"name":"BERWICK","code":"CA-NS-BWK"},
{"name":"BICKERTON WEST","code":"CA-NS-BTW"},
{"name":"BLOCKHOUSE","code":"CA-NS-BHH"},
{"name":"BRIDGETOWN","code":"CA-NS-BTN"},
{"name":"BRIDGEWATER","code":"CA-NS-BRW"},
{"name":"BROOKFIELD","code":"CA-NS-BFD"},
{"name":"BROOKLYN","code":"CA-NS-BKN"},
{"name":"CALEDONIA","code":"CA-NS-CAA"},
{"name":"CAMBRIDGE STATION","code":"CA-NS-CAS"},
{"name":"CANNING","code":"CA-NS-CAG"},
{"name":"CANSO","code":"CA-NS-CAH"},
{"name":"CENTRE BURLINGTON","code":"CA-NS-CBU"},
{"name":"CENTREVILLE","code":"CA-NS-CTV"},
{"name":"CHEBOGUE POINT","code":"CA-NS-CUN"},
{"name":"CHESTER","code":"CA-NS-CST"},
{"name":"CHÉTICAMP","code":"CA-NS-CHT"},
{"name":"CLAM POINT","code":"CA-NS-CLP"},
{"name":"CLARK'S HARBOUR","code":"CA-NS-CLH"},
{"name":"CLEMENTSPORT","code":"CA-NS-CSP"},
{"name":"CLEVELAND","code":"CA-NS-CVA"},
{"name":"COHASSET","code":"CA-NS-CHS"},
{"name":"COLCHESTER COUNTY","code":"CA-NS-CCC"},
{"name":"COLDBROOK","code":"CA-NS-CDB"},
{"name":"COLLINGWOOD CORNER","code":"CA-NS-ORE"},
{"name":"COOKVILLE","code":"CA-NS-OKC"},
{"name":"CORNWALLIS","code":"CA-NS-CWS"},
{"name":"COUNTRY HARBOUR","code":"CA-NS-COH"},
{"name":"DARTMOUTH","code":"CA-NS-DAR"},
{"name":"DEBERT STATION","code":"CA-NS-DET"},
{"name":"DIGBY","code":"CA-NS-DIG"},
{"name":"DINGWALL","code":"CA-NS-DWL"},
{"name":"DONKIN","code":"CA-NS-DOK"},
{"name":"EAST KEMPTVILLE","code":"CA-NS-EKE"},
{"name":"EAST RIVER","code":"CA-NS-ERV"},
{"name":"EASTERN PASSAGE","code":"CA-NS-EPG"},
{"name":"ECONOMY","code":"CA-NS-ECO"},
{"name":"EDWARDSVILLE","code":"CA-NS-ESN"},
{"name":"ELMSDALE","code":"CA-NS-ELE"},
{"name":"ENFIELD","code":"CA-NS-ENF"},
{"name":"FALL RIVER","code":"CA-NS-FLR"},
{"name":"FALMOUTH","code":"CA-NS-FAH"},
{"name":"GASPEREAU","code":"CA-NS-XP8"},
{"name":"GLACE BAY","code":"CA-NS-GLB"},
{"name":"GLEN MARGARET","code":"CA-NS-GMT"},
{"name":"GLENVILLE","code":"CA-NS-NS7"},
{"name":"GLENWOOD","code":"CA-NS-GNW"},
{"name":"GOFFS","code":"CA-NS-GOF"},
{"name":"GOLD RIVER","code":"CA-NS-GOL"},
{"name":"GOLDBORO","code":"CA-NS-GBR"},
{"name":"GOODWOOD","code":"CA-NS-GW2"},
{"name":"GRAND PRE","code":"CA-NS-4NG"},
{"name":"GRAND RIVER","code":"CA-NS-GDR"},
{"name":"GRANTON","code":"CA-NS-GRN"},
{"name":"GRANVILLE FERRY","code":"CA-NS-GFF"},
{"name":"GREEN OAKS","code":"CA-NS-GOS"},
{"name":"GREENFIELD","code":"CA-NS-GFL"},
{"name":"GREENWOOD","code":"CA-NS-YZX"},
{"name":"GUYSBOROUGH","code":"CA-NS-GYB"},
{"name":"HACKETTS COVE","code":"CA-NS-HCC"},
{"name":"HALIFAX","code":"CA-NS-HAL"},
{"name":"HALIFAX APT","code":"CA-NS-YHZ"},
{"name":"HANTSPORT","code":"CA-NS-HPT"},
{"name":"HARDWOOD LANDS","code":"CA-NS-HLD"},
{"name":"HARMONY","code":"CA-NS-HCA"},
{"name":"HARRIETSFIELD","code":"CA-NS-RRH"},
{"name":"HATCHET LAKE","code":"CA-NS-HCK"},
{"name":"HAVRE BOUCHER","code":"CA-NS-HBC"},
{"name":"HEAD OF CHEZZETCOOK","code":"CA-NS-HCZ"},
{"name":"HEAD OF JEDDORE","code":"CA-NS-JEJ"},
{"name":"HEBBVILLE","code":"CA-NS-HBV"},
{"name":"HIBERNIA","code":"CA-NS-HIB"},
{"name":"INGONISH","code":"CA-NS-ING"},
{"name":"INVERNESS","code":"CA-NS-IVS"},
{"name":"IONA","code":"CA-NS-ION"},
{"name":"JOGGINS","code":"CA-NS-JGG"},
{"name":"KENTVILLE","code":"CA-NS-KTL"},
{"name":"KETCH HARBOUR","code":"CA-NS-KHS"},
{"name":"KINGSTON","code":"CA-NS-KGN"},
{"name":"LA HAVE","code":"CA-NS-LHA"},
{"name":"LAKE ECHO","code":"CA-NS-LLE"},
{"name":"LAKESIDE","code":"CA-NS-LKD"},
{"name":"LANTZ","code":"CA-NS-LNZ"},
{"name":"LAWRENCETOWN","code":"CA-NS-LCT"},
{"name":"LINGAN","code":"CA-NS-LGN"},
{"name":"LITTLE BROOK","code":"CA-NS-LBK"},
{"name":"LITTLE NARROWS","code":"CA-NS-LIN"},
{"name":"LIVERPOOL","code":"CA-NS-LIV"},
{"name":"LOCKEPORT","code":"CA-NS-LCP"},
{"name":"LONDONDERRY","code":"CA-NS-ONL"},
{"name":"LOUISBOURG","code":"CA-NS-LOU"},
{"name":"LOWER EAST PUBNICO","code":"CA-NS-LEO"},
{"name":"LOWER SACKVILLE","code":"CA-NS-SKL"},
{"name":"LOWER WOODS HARBOUR","code":"CA-NS-LWH"},
{"name":"LUNENBURG","code":"CA-NS-LUN"},
{"name":"MABOU","code":"CA-NS-MAB"},
{"name":"MAHONE BAY","code":"CA-NS-MHB"},
{"name":"MALAGASH","code":"CA-NS-MA2"},
{"name":"MARGAREE","code":"CA-NS-MGE"},
{"name":"MARGAREE VALLEY","code":"CA-NS-MVA"},
{"name":"MARSHALLTOWN","code":"CA-NS-MAT"},
{"name":"MASSTOWN","code":"CA-NS-MSO"},
{"name":"MERIGOMISH","code":"CA-NS-MGH"},
{"name":"METEGHAN","code":"CA-NS-MET"},
{"name":"METEGHAN RIVER","code":"CA-NS-MGV"},
{"name":"MIDDLE MUSQUODOBOIT","code":"CA-NS-MMQ"},
{"name":"MIDDLETON","code":"CA-NS-MDL"},
{"name":"MILLVILLE","code":"CA-NS-MVI"},
{"name":"MILTON","code":"CA-NS-MIO"},
{"name":"MINESVILLE","code":"CA-NS-MSV"},
{"name":"MINEVILLE","code":"CA-NS-EVI"},
{"name":"MOUNT UNIACKE","code":"CA-NS-MUC"},
{"name":"MULGRAVE","code":"CA-NS-MUL"},
{"name":"MUSQUODOBOIT HARBOUR","code":"CA-NS-MUS"},
{"name":"NEIL'S HARBOUR","code":"CA-NS-NS3"},
{"name":"NEW GERMANY","code":"CA-NS-NGY"},
{"name":"NEW GLASGOW","code":"CA-NS-NGL"},
{"name":"NEW MINAS","code":"CA-NS-NMA"},
{"name":"NEW ROSS","code":"CA-NS-NWR"},
{"name":"NEW WATERFORD","code":"CA-NS-NWA"},
{"name":"NORTH EAST POINT","code":"CA-NS-ENP"},
{"name":"NORTH PRESTON","code":"CA-NS-XZ2"},
{"name":"NORTH SYDNEY","code":"CA-NS-NSY"},
{"name":"NUTTBY","code":"CA-NS-NTY"},
{"name":"ONSLOW","code":"CA-NS-S8W"},
{"name":"ORANGEDALE","code":"CA-NS-OGD"},
{"name":"OXFORD","code":"CA-NS-OXF"},
{"name":"PARRSBORO","code":"CA-NS-PAR"},
{"name":"PEGGYS COVE","code":"CA-NS-GGY"},
{"name":"PETIT-DE-GRAT","code":"CA-NS-PGR"},
{"name":"PICTOU","code":"CA-NS-PTO"},
{"name":"PLEASANTVILLE","code":"CA-NS-PSV"},
{"name":"POINT EDWARD","code":"CA-NS-PED"},
{"name":"POINT TUPPER","code":"CA-NS-PTU"},
{"name":"PORT BICKERTON","code":"CA-NS-PBI"},
{"name":"PORT GEORGE","code":"CA-NS-PGA"},
{"name":"PORT HASTINGS","code":"CA-NS-PHA"},
{"name":"PORT HAWKESBURY","code":"CA-NS-PHW"},
{"name":"PORT LA TOUR","code":"CA-NS-NSL"},
{"name":"PORT MAITLAND","code":"CA-NS-PTM"},
{"name":"PORT MEDWAY","code":"CA-NS-PMW"},
{"name":"PORT MOUTON","code":"CA-NS-PMN"},
{"name":"PORT SAXON","code":"CA-NS-PSX"},
{"name":"PORT WILLIAMS","code":"CA-NS-PWI"},
{"name":"PROSPECT","code":"CA-NS-PPT"},
{"name":"PUBNICO","code":"CA-NS-PUB"},
{"name":"PUGWASH","code":"CA-NS-PUG"},
{"name":"RIVER JOHN","code":"CA-NS-R8N"},
{"name":"RIVERPORT","code":"CA-NS-RIV"},
{"name":"SABLE ISLAND","code":"CA-NS-YSA"},
{"name":"SABLE RIVER","code":"CA-NS-SRI"},
{"name":"SACKVILLE","code":"CA-NS-XKV"},
{"name":"SAINT-PETERS","code":"CA-NS-SRS"},
{"name":"SAMBRO","code":"CA-NS-AQP"},
{"name":"SAULNIERVILLE","code":"CA-NS-SLV"},
{"name":"SEABRIGHT","code":"CA-NS-SEA"},
{"name":"SHAG HARBOUR","code":"CA-NS-SGH"},
{"name":"SHEARWATER","code":"CA-NS-YAW"},
{"name":"SHEET HARBOUR","code":"CA-NS-SHH"},
{"name":"SHEFFIELD MILLS","code":"CA-NS-SFM"},
{"name":"SHELBURNE","code":"CA-NS-SBU"},
{"name":"SHERBROOKE","code":"CA-NS-SBE"},
{"name":"SHUBENACADIE","code":"CA-NS-SCE"},
{"name":"SOUTH BROOKFIELD","code":"CA-NS-SBF"},
{"name":"SPRINGFIELD","code":"CA-NS-SPF"},
{"name":"SPRINGHILL","code":"CA-NS-SPO"},
{"name":"STELLARTON","code":"CA-NS-STN"},
{"name":"STEWIACKE","code":"CA-NS-SWE"},
{"name":"STONEY ISLAND","code":"CA-NS-SYI"},
{"name":"STORMONT","code":"CA-NS-SMT"},
{"name":"SYDNEY","code":"CA-NS-SYD"},
{"name":"SYDNEY MINES","code":"CA-NS-SMI"},
{"name":"SYDNEY RIVER","code":"CA-NS-SRV"},
{"name":"TANTALLON","code":"CA-NS-TTA"},
{"name":"TATAMAGOUCHE","code":"CA-NS-TGU"},
{"name":"THORBURN","code":"CA-NS-TBU"},
{"name":"TIMBERLEA","code":"CA-NS-TBL"},
{"name":"TIVERTON","code":"CA-NS-TIV"},
{"name":"TRACADIE-SHEILA","code":"CA-NS-TDS"},
{"name":"TRENTON","code":"CA-NS-TRN"},
{"name":"TRURO","code":"CA-NS-TRU"},
{"name":"TRURO HEIGHTS","code":"CA-NS-THG"},
{"name":"TUSKET","code":"CA-NS-TUS"},
{"name":"UPPER MUSQUODOBOIT","code":"CA-NS-UPO"},
{"name":"UPPER NORTHFIELD","code":"CA-NS-UPN"},
{"name":"UPPER TANTALLON","code":"CA-NS-UTN"},
{"name":"WALTON","code":"CA-NS-WTN"},
{"name":"WATERVILLE","code":"CA-NS-WVE"},
{"name":"WAVERLEY","code":"CA-NS-WVY"},
{"name":"WEDGEPORT","code":"CA-NS-WED"},
{"name":"WENTWORTH","code":"CA-NS-WWT"},
{"name":"WEST CHEZZETCOOK","code":"CA-NS-WCH"},
{"name":"WEST PUBNICO","code":"CA-NS-WPU"},
{"name":"WESTVILLE","code":"CA-NS-WEV"},
{"name":"WEYMOUTH","code":"CA-NS-WEY"},
{"name":"WHITES LAKE","code":"CA-NS-WLK"},
{"name":"WILEVILLE","code":"CA-NS-LLV"},
{"name":"WILLIAMSWOOD","code":"CA-NS-LOL"},
{"name":"WINDSOR","code":"CA-NS-WDR"},
{"name":"WOLFVILLE","code":"CA-NS-WOV"},
{"name":"YARMOUTH","code":"CA-NS-YRH"},
        ]
      },
      {
        name: 'Nunavut',
        code: 'NU',
        cities: [
          {"name":"ARCTIC BAY","code":"CA-NU-ARB"},
{"name":"ARVIAT","code":"CA-NU-YEK"},
{"name":"BAKER LAKE","code":"CA-NU-BLK"},
{"name":"BERNARD","code":"CA-NU-BBN"},
{"name":"BROUGHTON IS","code":"CA-NU-BRT"},
{"name":"CAMBRIDGE BAY","code":"CA-NU-YCB"},
{"name":"CAPE DORSET","code":"CA-NU-CDO"},
{"name":"CHESTERFIELD INLET","code":"CA-NU-YCS"},
{"name":"CLYDE RIVER","code":"CA-NU-CLR"},
{"name":"COPPERMINE","code":"CA-NU-COP"},
{"name":"CORAL HARBOUR","code":"CA-NU-YZS"},
{"name":"CORNWALLIS IS","code":"CA-NU-CWI"},
{"name":"GJOA HAVEN","code":"CA-NU-YHK"},
{"name":"GRISE FIORD","code":"CA-NU-YGZ"},
{"name":"HALL BEACH","code":"CA-NU-HAB"},
{"name":"IGLOOLIK","code":"CA-NU-IGL"},
{"name":"IQALUIT","code":"CA-NU-IQL"},
{"name":"KING WILLIAM IS","code":"CA-NU-KWI"},
{"name":"KUGAARUK","code":"CA-NU-YBB"},
{"name":"LAKE HARBOUR","code":"CA-NU-LAH"},
{"name":"MILNE INLET PORT","code":"CA-NU-MNI"},
{"name":"NANISIVIK","code":"CA-NU-NVK"},
{"name":"PANGNIRTUNG","code":"CA-NU-PNT"},
{"name":"POND INLET","code":"CA-NU-PDI"},
{"name":"RANKIN INLET","code":"CA-NU-RIT"},
{"name":"RESOLUTE BAY","code":"CA-NU-REB"},
{"name":"SANIKILUAQ","code":"CA-NU-YSK"},
{"name":"TALOYOAK","code":"CA-NU-YYH"},
{"name":"WHALE COVE","code":"CA-NU-YXN"},
        ]
      },
      {
        name: 'Ontario',
        code: 'ON',
        cities: [
          {"name":"ACTON","code":"CA-ON-ACT"},
{"name":"ADDISON","code":"CA-ON-DDD"},
{"name":"AGINCOURT","code":"CA-ON-AGI"},
{"name":"AILSA CRAIG","code":"CA-ON-AAC"},
{"name":"AJAX","code":"CA-ON-AJX"},
{"name":"ALBAN","code":"CA-ON-ABN"},
{"name":"ALEXANDRIA","code":"CA-ON-XFS"},
{"name":"ALFRED","code":"CA-ON-ALF"},
{"name":"ALGONQUIN HIGHLANDS","code":"CA-ON-AHH"},
{"name":"ALGONQUIN PARK","code":"CA-ON-ALP"},
{"name":"ALLANBURG","code":"CA-ON-ALL"},
{"name":"ALLISTON","code":"CA-ON-AMA"},
{"name":"ALMA","code":"CA-ON-ALM"},
{"name":"ALMONTE","code":"CA-ON-AMT"},
{"name":"ALNWICK/HALDIMAND","code":"CA-ON-AID"},
{"name":"ALTON","code":"CA-ON-ALT"},
{"name":"ALVINSTON","code":"CA-ON-ASO"},
{"name":"AMBERLY","code":"CA-ON-ABL"},
{"name":"AMHERSTBURG","code":"CA-ON-AMH"},
{"name":"ANCASTER","code":"CA-ON-ANC"},
{"name":"ANGLING LAKE","code":"CA-ON-YAX"},
{"name":"APPIN","code":"CA-ON-API"},
{"name":"APPLE HILL","code":"CA-ON-AHL"},
{"name":"ARISS","code":"CA-ON-ARX"},
{"name":"ARKONA","code":"CA-ON-AKN"},
{"name":"ARNPRIOR","code":"CA-ON-ANP"},
{"name":"ARTHUR","code":"CA-ON-ATH"},
{"name":"ASHBURN","code":"CA-ON-ABH"},
{"name":"ASPHODEL-NORWOOD","code":"CA-ON-ANN"},
{"name":"ASTRA","code":"CA-ON-ATA"},
{"name":"ATHENS","code":"CA-ON-ATS"},
{"name":"ATIKOKAN","code":"CA-ON-YIB"},
{"name":"ATTAWAPISKAT","code":"CA-ON-YAT"},
{"name":"ATWOOD","code":"CA-ON-AWD"},
{"name":"AURORA","code":"CA-ON-AUR"},
{"name":"AYLMER","code":"CA-ON-AYL"},
{"name":"AYR","code":"CA-ON-AYR"},
{"name":"AYTON","code":"CA-ON-AYO"},
{"name":"AZILDA","code":"CA-ON-AZI"},
{"name":"BADEN","code":"CA-ON-BDE"},
{"name":"BAINSVILLE","code":"CA-ON-BVS"},
{"name":"BALA","code":"CA-ON-BAA"},
{"name":"BALCKSTOCK","code":"CA-ON-BLS"},
{"name":"BALMERTOWN","code":"CA-ON-BAL"},
{"name":"BANCROFT","code":"CA-ON-BCT"},
{"name":"BAR RIVER","code":"CA-ON-BIV"},
{"name":"BARRIE","code":"CA-ON-BAR"},
{"name":"BARRYS BAY","code":"CA-ON-BYB"},
{"name":"BATAWA","code":"CA-ON-BAW"},
{"name":"BATH","code":"CA-ON-BTH"},
{"name":"BAYFIELD","code":"CA-ON-BYL"},
{"name":"BAYHAM","code":"CA-ON-BAH"},
{"name":"BAYSIDE","code":"CA-ON-BAS"},
{"name":"BEACHVILLE","code":"CA-ON-BHV"},
{"name":"BEAMSVILLE","code":"CA-ON-BMV"},
{"name":"BEARSKIN LAKE","code":"CA-ON-BKK"},
{"name":"BEETON","code":"CA-ON-BEN"},
{"name":"BELFOUNTAIN","code":"CA-ON-BTA"},
{"name":"BELLE RIVER","code":"CA-ON-BLI"},
{"name":"BELLEVILLE","code":"CA-ON-BLV"},
{"name":"BELMONT","code":"CA-ON-BLM"},
{"name":"BELWOOD","code":"CA-ON-BLD"},
{"name":"BETHANY","code":"CA-ON-BTY"},
{"name":"BIG TROUT LAKE","code":"CA-ON-YTL"},
{"name":"BILLY BISHOP TORONTO CITY APT/TORONTO","code":"CA-ON-YTZ"},
{"name":"BLACKSTOCK","code":"CA-ON-BSK"},
{"name":"BLENHEIM","code":"CA-ON-BHM"},
{"name":"BLIND RIVER","code":"CA-ON-BLR"},
{"name":"BLOOMFIELD","code":"CA-ON-BMI"},
{"name":"BLOOMINGDALE","code":"CA-ON-BOO"},
{"name":"BLUE MOUNTAIN","code":"CA-ON-BMT"},
{"name":"BLYTH","code":"CA-ON-BLY"},
{"name":"BOBCAYGEON","code":"CA-ON-BOB"},
{"name":"BOLTON","code":"CA-ON-BLT"},
{"name":"BOND HEAD","code":"CA-ON-BHD"},
{"name":"BORDEN","code":"CA-ON-YBN"},
{"name":"BORNHOLM","code":"CA-ON-BNM"},
{"name":"BOTHWELL","code":"CA-ON-BWL"},
{"name":"BOWMANVILLE","code":"CA-ON-BWV"},
{"name":"BRACEBRIDGE","code":"CA-ON-BRB"},
{"name":"BRADFORD","code":"CA-ON-BRA"},
{"name":"BRAMALEA","code":"CA-ON-BRM"},
{"name":"BRANT","code":"CA-ON-BNN"},
{"name":"BRANTFORD","code":"CA-ON-BRN"},
{"name":"BRECHIN","code":"CA-ON-BRE"},
{"name":"BRESLAU","code":"CA-ON-BSU"},
{"name":"BRIGHT","code":"CA-ON-BRG"},
{"name":"BRIGHTON","code":"CA-ON-BGH"},
{"name":"BRINSTON","code":"CA-ON-BNS"},
{"name":"BRITT","code":"CA-ON-BTT"},
{"name":"BROCKVILLE","code":"CA-ON-BCK"},
{"name":"BRODHAGEN","code":"CA-ON-BHG"},
{"name":"BROOKLIN","code":"CA-ON-BRK"},
{"name":"BROUGHAM","code":"CA-ON-BR9"},
{"name":"BROWNSVILLE","code":"CA-ON-BVL"},
{"name":"BRUCE COUNTY","code":"CA-ON-ONB"},
{"name":"BRUCE MINES","code":"CA-ON-BCM"},
{"name":"BRUCEFIELD","code":"CA-ON-BRF"},
{"name":"BRUNNER","code":"CA-ON-BNR"},
{"name":"BURFORD","code":"CA-ON-BUD"},
{"name":"BURGESSVILLE","code":"CA-ON-BUR"},
{"name":"BURK'S FALLS","code":"CA-ON-BSF"},
{"name":"BURKS FALLS","code":"CA-ON-BUK"},
{"name":"BURLINGTON","code":"CA-ON-BRL"},
{"name":"BURRITTS RAPIDS","code":"CA-ON-BTR"},
{"name":"BUTTONVILLE","code":"CA-ON-YKZ"},
{"name":"CAISTOR CENTRE","code":"CA-ON-CCT"},
{"name":"CALEDON","code":"CA-ON-CDA"},
{"name":"CALEDONIA","code":"CA-ON-CAD"},
{"name":"CAMBRIDGE (EX GALT)","code":"CA-ON-GAL"},
{"name":"CAMLACHIE","code":"CA-ON-CC2"},
{"name":"CAMPBELL CROFT","code":"CA-ON-CCF"},
{"name":"CAMPBELLFORD","code":"CA-ON-CBF"},
{"name":"CAMPBELLVILLE","code":"CA-ON-CBV"},
{"name":"CANFIELD","code":"CA-ON-CFD"},
{"name":"CANNINGTON","code":"CA-ON-CNT"},
{"name":"CARCON CITY/TORONTO","code":"CA-ON-CAR"},
{"name":"CARDINAL","code":"CA-ON-CDN"},
{"name":"CARGILL","code":"CA-ON-CGL"},
{"name":"CARIBOU ISLAND","code":"CA-ON-YCI"},
{"name":"CARLETON PLACE","code":"CA-ON-CLO"},
{"name":"CARLISLE","code":"CA-ON-CLS"},
{"name":"CARLSBAD SPRINGS","code":"CA-ON-RLS"},
{"name":"CARP","code":"CA-ON-CRP"},
{"name":"CARTHAGE","code":"CA-ON-HTC"},
{"name":"CARTIER","code":"CA-ON-CRT"},
{"name":"CASSELMAN","code":"CA-ON-CSS"},
{"name":"CASTLETON","code":"CA-ON-CTT"},
{"name":"CAT LAKE","code":"CA-ON-YAC"},
{"name":"CAVAN","code":"CA-ON-C8V"},
{"name":"CAYUGA","code":"CA-ON-CYG"},
{"name":"CENTRALIA","code":"CA-ON-YCE"},
{"name":"CENTRE ISLAND","code":"CA-ON-CIS"},
{"name":"CHALK RIVER","code":"CA-ON-CKR"},
{"name":"CHAPLEAU","code":"CA-ON-YLD"},
{"name":"CHARING CROSS","code":"CA-ON-CGS"},
{"name":"CHATHAM","code":"CA-ON-CTM"},
{"name":"CHATSWORTH","code":"CA-ON-CHW"},
{"name":"CHATSWORTH","code":"CA-ON-CTW"},
{"name":"CHELMSFORD","code":"CA-ON-OH2"},
{"name":"CHEPSTOW","code":"CA-ON-CPW"},
{"name":"CHESLEY","code":"CA-ON-CEY"},
{"name":"CHESTERVILLE","code":"CA-ON-CVL"},
{"name":"CHIPPAWA","code":"CA-ON-CHP"},
{"name":"CHURCHILL","code":"CA-ON-CHH"},
{"name":"CHUTE-A-BLONDEAU","code":"CA-ON-CAB"},
{"name":"CLAREMONT","code":"CA-ON-CLM"},
{"name":"CLARENCE CREEK","code":"CA-ON-CCK"},
{"name":"CLARINGTON","code":"CA-ON-CLG"},
{"name":"CLARKSBURG","code":"CA-ON-CSB"},
{"name":"CLARKSON","code":"CA-ON-CSN"},
{"name":"CLIFFORD","code":"CA-ON-LLF"},
{"name":"CLINTON","code":"CA-ON-CLI"},
{"name":"COATSWORTH","code":"CA-ON-CR5"},
{"name":"COBDEN","code":"CA-ON-CBD"},
{"name":"COBOURG","code":"CA-ON-CBG"},
{"name":"COCHRANE","code":"CA-ON-YCN"},
{"name":"COLBORNE","code":"CA-ON-CLB"},
{"name":"COLDWATER","code":"CA-ON-CDW"},
{"name":"COLLINGWOOD","code":"CA-ON-COL"},
{"name":"COMBER","code":"CA-ON-COB"},
{"name":"COMBERMERE","code":"CA-ON-CBM"},
{"name":"CONCORD","code":"CA-ON-CNC"},
{"name":"CONISTON","code":"CA-ON-CSJ"},
{"name":"COOKSTOWN","code":"CA-ON-CSW"},
{"name":"COOKSVILLE","code":"CA-ON-CVI"},
{"name":"COOPER CLIFF","code":"CA-ON-CFL"},
{"name":"COPPER CLIFF","code":"CA-ON-CPC"},
{"name":"CORBEIL","code":"CA-ON-CBR"},
{"name":"CORNWALL","code":"CA-ON-CWL"},
{"name":"CORUNNA","code":"CA-ON-COR"},
{"name":"COTTAM","code":"CA-ON-CTA"},
{"name":"COURTICE","code":"CA-ON-CRC"},
{"name":"COURTLAND","code":"CA-ON-CLN"},
{"name":"COURTRIGHT","code":"CA-ON-CRR"},
{"name":"CREEMORE","code":"CA-ON-CRM"},
{"name":"CRYSLER","code":"CA-ON-CYS"},
{"name":"CRYSTAL BEACH","code":"CA-ON-CBE"},
{"name":"CUMBERLAND","code":"CA-ON-OCL"},
{"name":"DACRE","code":"CA-ON-DAC"},
{"name":"DALKEITH","code":"CA-ON-DKH"},
{"name":"DASHWOOD","code":"CA-ON-DWO"},
{"name":"DEEP RIVER","code":"CA-ON-DRR"},
{"name":"DEER LAKE","code":"CA-ON-YVZ"},
{"name":"DELAWARE","code":"CA-ON-DWR"},
{"name":"DELHI","code":"CA-ON-DLI"},
{"name":"DEMORESTVILLE","code":"CA-ON-DMV"},
{"name":"DENFIELD","code":"CA-ON-DFI"},
{"name":"DESBORO","code":"CA-ON-DSO"},
{"name":"DESERONTO","code":"CA-ON-DSR"},
{"name":"DOBBINTON","code":"CA-ON-DTO"},
{"name":"DON MILLS","code":"CA-ON-DOM"},
{"name":"DORCHESTER","code":"CA-ON-DCS"},
{"name":"DOUGLAS","code":"CA-ON-DXS"},
{"name":"DOUGLAS POINT","code":"CA-ON-6OD"},
{"name":"DOWLING","code":"CA-ON-DWG"},
{"name":"DOWNSVIEW","code":"CA-ON-DVW"},
{"name":"DRAYTON","code":"CA-ON-DYT"},
{"name":"DRESDEN","code":"CA-ON-DRD"},
{"name":"DRYDEN","code":"CA-ON-DRY"},
{"name":"DUBLIN","code":"CA-ON-DLN"},
{"name":"DUBREUILVILLE","code":"CA-ON-D8O"},
{"name":"DUNCHURCH","code":"CA-ON-DUC"},
{"name":"DUNDALK","code":"CA-ON-DNK"},
{"name":"DUNDAS","code":"CA-ON-DUD"},
{"name":"DUNGANON","code":"CA-ON-GNX"},
{"name":"DUNMAN","code":"CA-ON-DHQ"},
{"name":"DUNNVILLE","code":"CA-ON-DUV"},
{"name":"DUNTROON","code":"CA-ON-DOO"},
{"name":"DURHAM","code":"CA-ON-DUR"},
{"name":"DUTTON","code":"CA-ON-DUO"},
{"name":"EAR FALLS","code":"CA-ON-EFS"},
{"name":"EARLTON","code":"CA-ON-YXR"},
{"name":"EAST GARAFRAXA","code":"CA-ON-EG5"},
{"name":"EAST GWILLIMBURY","code":"CA-ON-GWY"},
{"name":"EAST YORK","code":"CA-ON-EAS"},
{"name":"ECHO BAY","code":"CA-ON-ECB"},
{"name":"EGANVILLE","code":"CA-ON-EGV"},
{"name":"ELIZABETHTOWN","code":"CA-ON-EZT"},
{"name":"ELLIOT LAKE","code":"CA-ON-YEL"},
{"name":"ELMIRA","code":"CA-ON-ELM"},
{"name":"ELMVALE","code":"CA-ON-EVV"},
{"name":"ELMWOOD","code":"CA-ON-EMD"},
{"name":"ELORA","code":"CA-ON-ELO"},
{"name":"EMBRO","code":"CA-ON-EMB"},
{"name":"EMBRUN","code":"CA-ON-EBU"},
{"name":"EMERYVILLE","code":"CA-ON-EYV"},
{"name":"EMO","code":"CA-ON-EMO"},
{"name":"EMSDALE","code":"CA-ON-DSS"},
{"name":"ENGLEHART","code":"CA-ON-EGH"},
{"name":"ENNISKILLEN","code":"CA-ON-ENN"},
{"name":"ERIEAU","code":"CA-ON-ENE"},
{"name":"ERIN","code":"CA-ON-ERI"},
{"name":"ESPANOLA","code":"CA-ON-EPA"},
{"name":"ESSEX","code":"CA-ON-ESS"},
{"name":"ETHEL","code":"CA-ON-ETL"},
{"name":"ETOBICOKE","code":"CA-ON-ETC"},
{"name":"EXETER","code":"CA-ON-EXX"},
{"name":"FALCONBRIDGE","code":"CA-ON-FCB"},
{"name":"FARNHAM","code":"CA-ON-FRN"},
{"name":"FENELON FALLS","code":"CA-ON-FEF"},
{"name":"FENWICK","code":"CA-ON-FWC"},
{"name":"FERGUS","code":"CA-ON-FRG"},
{"name":"FEVERSHAM","code":"CA-ON-FFE"},
{"name":"FINCH","code":"CA-ON-FIN"},
{"name":"FINGAL","code":"CA-ON-FGA"},
{"name":"FLORADALE","code":"CA-ON-FDL"},
{"name":"FLORENCE","code":"CA-ON-XFL"},
{"name":"FOLEYET","code":"CA-ON-FYT"},
{"name":"FONTHILL","code":"CA-ON-FTH"},
{"name":"FORDWICH","code":"CA-ON-FWI"},
{"name":"FOREST","code":"CA-ON-ZFT"},
{"name":"FORESTERS FALLS","code":"CA-ON-FFL"},
{"name":"FORMOSA","code":"CA-ON-FMS"},
{"name":"FORT ALBANY","code":"CA-ON-FAL"},
{"name":"FORT ERIE","code":"CA-ON-FER"},
{"name":"FORT FRANCES","code":"CA-ON-FFS"},
{"name":"FORT HOPE","code":"CA-ON-YFH"},
{"name":"FORT SEVERN","code":"CA-ON-YER"},
{"name":"FORT WILLIAM","code":"CA-ON-FWL"},
{"name":"FOURNIER","code":"CA-ON-FNR"},
{"name":"FOXBORO","code":"CA-ON-FXO"},
{"name":"FRANKFORD","code":"CA-ON-FKO"},
{"name":"FRANKVILLE","code":"CA-ON-FRK"},
{"name":"FREELTON","code":"CA-ON-FRT"},
{"name":"GADS HILL STATION","code":"CA-ON-GHS"},
{"name":"GALT","code":"CA-ON-GLT"},
{"name":"GANANOQUE","code":"CA-ON-GNE"},
{"name":"GARDENVILLE","code":"CA-ON-GDV"},
{"name":"GARSON","code":"CA-ON-GAR"},
{"name":"GEORGETOWN","code":"CA-ON-GGW"},
{"name":"GEORGIAN BLUFF","code":"CA-ON-GIA"},
{"name":"GEORGIAN BLUFFS","code":"CA-ON-GBF"},
{"name":"GERALDTON","code":"CA-ON-YGQ"},
{"name":"GLEN HURON","code":"CA-ON-GHU"},
{"name":"GLEN MORRIS","code":"CA-ON-GMO"},
{"name":"GLENCOE","code":"CA-ON-GLE"},
{"name":"GLOUCESTER","code":"CA-ON-GCR"},
{"name":"GODERICH","code":"CA-ON-GOH"},
{"name":"GOODWOOD","code":"CA-ON-GDW"},
{"name":"GORE BAY","code":"CA-ON-YZE"},
{"name":"GORMLEY","code":"CA-ON-GMY"},
{"name":"GORRIE","code":"CA-ON-GRR"},
{"name":"GOWANSTOWN","code":"CA-ON-GWS"},
{"name":"GRAFTON","code":"CA-ON-GFT"},
{"name":"GRAND BEND","code":"CA-ON-GJI"},
{"name":"GRAND VALLEY","code":"CA-ON-GRV"},
{"name":"GRASSIE","code":"CA-ON-GAI"},
{"name":"GRAVENHURST","code":"CA-ON-GVH"},
{"name":"GRIMSBY","code":"CA-ON-XGY"},
{"name":"GUELPH","code":"CA-ON-GLP"},
{"name":"HAGERSVILLE","code":"CA-ON-HGS"},
{"name":"HAILEYBURY","code":"CA-ON-EYL"},
{"name":"HALEY STATION","code":"CA-ON-HSN"},
{"name":"HALIBURTON","code":"CA-ON-HBT"},
{"name":"HALLEBOURG","code":"CA-ON-HLB"},
{"name":"HALLOWELL","code":"CA-ON-H8O"},
{"name":"HALTON HILLS","code":"CA-ON-OON"},
{"name":"HAMILTON","code":"CA-ON-HAM"},
{"name":"HAMILTON APT","code":"CA-ON-YHM"},
{"name":"HANMER","code":"CA-ON-HMR"},
{"name":"HANNON","code":"CA-ON-HNO"},
{"name":"HANOVER","code":"CA-ON-HVR"},
{"name":"HARCOURT","code":"CA-ON-HCT"},
{"name":"HARLEY","code":"CA-ON-HYY"},
{"name":"HARRISTON","code":"CA-ON-HRR"},
{"name":"HARROW","code":"CA-ON-HRW"},
{"name":"HARTINGTON","code":"CA-ON-HGT"},
{"name":"HARTY","code":"CA-ON-TYR"},
{"name":"HARWOOD","code":"CA-ON-HON"},
{"name":"HASTINGS","code":"CA-ON-TIN"},
{"name":"HAVELOCK","code":"CA-ON-HVL"},
{"name":"HAWKESBURY","code":"CA-ON-HAW"},
{"name":"HAWKESTONE","code":"CA-ON-HWK"},
{"name":"HAWKESVILLE","code":"CA-ON-HAK"},
{"name":"HEARST","code":"CA-ON-YHF"},
{"name":"HEIDELBERG","code":"CA-ON-HDB"},
{"name":"HENSALL","code":"CA-ON-HEL"},
{"name":"HERON BAY","code":"CA-ON-HBY"},
{"name":"HICKSON","code":"CA-ON-HKS"},
{"name":"HILLSBURGH","code":"CA-ON-HBG"},
{"name":"HILLSDALE","code":"CA-ON-HLS"},
{"name":"HOLLAND CENTER","code":"CA-ON-HCE"},
{"name":"HOLLAND CENTRE","code":"CA-ON-HHC"},
{"name":"HOLLAND LANDING","code":"CA-ON-HLL"},
{"name":"HOLSTEIN","code":"CA-ON-HST"},
{"name":"HOLYROOD","code":"CA-ON-HYO"},
{"name":"HONEY HARBOUR","code":"CA-ON-HNH"},
{"name":"HORNBY","code":"CA-ON-HOR"},
{"name":"HORNEPAYNE","code":"CA-ON-YHN"},
{"name":"HUNTSVILLE","code":"CA-ON-HUN"},
{"name":"HURON PARK","code":"CA-ON-HUP"},
{"name":"IGNACE","code":"CA-ON-ZUC"},
{"name":"ILDERTON","code":"CA-ON-IDO"},
{"name":"INGERSOLL","code":"CA-ON-IGS"},
{"name":"INGLESIDE","code":"CA-ON-ISI"},
{"name":"INGLEWOOD","code":"CA-ON-IGE"},
{"name":"INKERMAN","code":"CA-ON-IKM"},
{"name":"INNERKIP","code":"CA-ON-IKP"},
{"name":"INNISFIL","code":"CA-ON-ISF"},
{"name":"INWOOD","code":"CA-ON-IWO"},
{"name":"IROQUOIS","code":"CA-ON-IRQ"},
{"name":"IROQUOIS FALLS","code":"CA-ON-IQF"},
{"name":"JARVIS","code":"CA-ON-JVS"},
{"name":"JERSEYVILLE","code":"CA-ON-JSS"},
{"name":"JOHNSTOWN","code":"CA-ON-JOH"},
{"name":"JOLY","code":"CA-ON-JYQ"},
{"name":"JORDAN STATION","code":"CA-ON-JST"},
{"name":"KAMINISTIQUIA","code":"CA-ON-KKM"},
{"name":"KANATA","code":"CA-ON-KAN"},
{"name":"KAPUSKASING","code":"CA-ON-YYU"},
{"name":"KASABONIKA","code":"CA-ON-XKS"},
{"name":"KASCHECHEWAN","code":"CA-ON-ZKE"},
{"name":"KEARNEY","code":"CA-ON-KEY"},
{"name":"KEENE","code":"CA-ON-KEE"},
{"name":"KEEWATIN","code":"CA-ON-KWN"},
{"name":"KEMPTVILLE","code":"CA-ON-KVE"},
{"name":"KENILWORTH","code":"CA-ON-KWH"},
{"name":"KENORA","code":"CA-ON-YQK"},
{"name":"KERWOOD","code":"CA-ON-KWD"},
{"name":"KESWICK","code":"CA-ON-KWK"},
{"name":"KETTLEBY","code":"CA-ON-KTB"},
{"name":"KEY HARBOUR","code":"CA-ON-KHO"},
{"name":"KILLALOE","code":"CA-ON-YXI"},
{"name":"KILLARNEY","code":"CA-ON-KLY"},
{"name":"KINBURN","code":"CA-ON-KBU"},
{"name":"KINCARDINE","code":"CA-ON-KAC"},
{"name":"KING","code":"CA-ON-KGG"},
{"name":"KING CITY","code":"CA-ON-KCN"},
{"name":"KINGFISHER LAKE","code":"CA-ON-KIF"},
{"name":"KINGSTON","code":"CA-ON-KIN"},
{"name":"KINGSVILLE","code":"CA-ON-KVL"},
{"name":"KINTORE","code":"CA-ON-KTO"},
{"name":"KIPPEN","code":"CA-ON-KIP"},
{"name":"KIRKLAND LAKE","code":"CA-ON-YKX"},
{"name":"KIRKTON","code":"CA-ON-KKT"},
{"name":"KITCHENER","code":"CA-ON-KTC"},
{"name":"KLEINBURG","code":"CA-ON-KBG"},
{"name":"KOMOKA","code":"CA-ON-KMK"},
{"name":"L'ORIGNAL","code":"CA-ON-LOR"},
{"name":"LA SALETTE","code":"CA-ON-LSL"},
{"name":"LAKEFIELD","code":"CA-ON-LKF"},
{"name":"LAKESHORE","code":"CA-ON-LSE"},
{"name":"LAKESIDE","code":"CA-ON-LKI"},
{"name":"LAKEVIEW","code":"CA-ON-LAW"},
{"name":"LAMBETH","code":"CA-ON-LMB"},
{"name":"LAMBTON SHORES","code":"CA-ON-LSR"},
{"name":"LANCASTER","code":"CA-ON-LCS"},
{"name":"LANGTON","code":"CA-ON-LGT"},
{"name":"LANSDOWNE","code":"CA-ON-LDS"},
{"name":"LANSDOWNE HOUSE","code":"CA-ON-YLH"},
{"name":"LATCHFORD","code":"CA-ON-LAT"},
{"name":"LEAMINGTON","code":"CA-ON-LMN"},
{"name":"LEASKDALE","code":"CA-ON-LDE"},
{"name":"LEFAIVRE","code":"CA-ON-LFA"},
{"name":"LIMEHOUSE","code":"CA-ON-LHO"},
{"name":"LINCOLN","code":"CA-ON-N9L"},
{"name":"LINDSAY","code":"CA-ON-LND"},
{"name":"LINWOOD","code":"CA-ON-LWD"},
{"name":"LISTOWEL","code":"CA-ON-LTW"},
{"name":"LITTLE BRITAIN","code":"CA-ON-LBO"},
{"name":"LITTLE CURRENT","code":"CA-ON-LIC"},
{"name":"LIVELY","code":"CA-ON-LVY"},
{"name":"LOBO","code":"CA-ON-LOB"},
{"name":"LOCUSTHILL","code":"CA-ON-OCH"},
{"name":"LOMBARDY","code":"CA-ON-LOY"},
{"name":"LONDON","code":"CA-ON-LOD"},
{"name":"LONG POINT","code":"CA-ON-YLX"},
{"name":"LONG SAULT","code":"CA-ON-LST"},
{"name":"LONGFORD MILLS","code":"CA-ON-LFM"},
{"name":"LONGLAC","code":"CA-ON-LOG"},
{"name":"LORETTO","code":"CA-ON-LTO"},
{"name":"LORNEVILLE","code":"CA-ON-LNV"},
{"name":"LOWBANKS","code":"CA-ON-LWB"},
{"name":"LUCAN","code":"CA-ON-LUU"},
{"name":"LUCKNOW","code":"CA-ON-LKN"},
{"name":"LYN","code":"CA-ON-LYN"},
{"name":"MACTIER","code":"CA-ON-MCT"},
{"name":"MADAWASKA","code":"CA-ON-MWA"},
{"name":"MADOC","code":"CA-ON-MAD"},
{"name":"MAGNETAWAN","code":"CA-ON-MNW"},
{"name":"MAIDSTONE","code":"CA-ON-MDS"},
{"name":"MAIN DUCK ISLAND","code":"CA-ON-YDK"},
{"name":"MAITLAND","code":"CA-ON-MTL"},
{"name":"MALTON","code":"CA-ON-MAL"},
{"name":"MANILLA","code":"CA-ON-MLL"},
{"name":"MANITOUWADGE","code":"CA-ON-YMG"},
{"name":"MANITOWANING","code":"CA-ON-YEM"},
{"name":"MANOTICK","code":"CA-ON-MTK"},
{"name":"MANSFIELD","code":"CA-ON-MSF"},
{"name":"MAPLE","code":"CA-ON-MAP"},
{"name":"MARATHON","code":"CA-ON-MAR"},
{"name":"MARIONVILLE","code":"CA-ON-MNV"},
{"name":"MARKDALE","code":"CA-ON-MKD"},
{"name":"MARKHAM","code":"CA-ON-MKH"},
{"name":"MARMORA","code":"CA-ON-MMA"},
{"name":"MARTINTOWN","code":"CA-ON-MTT"},
{"name":"MARYHILL","code":"CA-ON-MAH"},
{"name":"MATACHEWAN","code":"CA-ON-MHW"},
{"name":"MATHESON","code":"CA-ON-MTS"},
{"name":"MATTAWA","code":"CA-ON-MTW"},
{"name":"MAXVILLE","code":"CA-ON-MXV"},
{"name":"MAXWELL","code":"CA-ON-OML"},
{"name":"MCDOUGALL","code":"CA-ON-LLM"},
{"name":"MCGREGOR","code":"CA-ON-MGR"},
{"name":"MCKELLAR","code":"CA-ON-MKR"},
{"name":"MEAFORD","code":"CA-ON-MFD"},
{"name":"MELANCTHON","code":"CA-ON-MEO"},
{"name":"MELBOURNE","code":"CA-ON-MLU"},
{"name":"MELDRUM","code":"CA-ON-MEL"},
{"name":"MELDRUM BAY","code":"CA-ON-MDB"},
{"name":"MERLIN","code":"CA-ON-MLI"},
{"name":"MERRICKVILLE","code":"CA-ON-MRK"},
{"name":"METCALFE","code":"CA-ON-MTC"},
{"name":"METROPOLITAN AREA APT/TORONTO","code":"CA-ON-YTO"},
{"name":"MICHIPICOTEN","code":"CA-ON-MIH"},
{"name":"MIDHURST","code":"CA-ON-MDT"},
{"name":"MIDLAND","code":"CA-ON-MID"},
{"name":"MILDMAY","code":"CA-ON-MIY"},
{"name":"MILFORD","code":"CA-ON-ORD"},
{"name":"MILLBANK","code":"CA-ON-MLB"},
{"name":"MILLBROOK","code":"CA-ON-MBK"},
{"name":"MILLGROVE","code":"CA-ON-MLG"},
{"name":"MILLHAVEN","code":"CA-ON-ILL"},
{"name":"MILTON","code":"CA-ON-MIL"},
{"name":"MILVERTON","code":"CA-ON-MLO"},
{"name":"MINAKI","code":"CA-ON-YMI"},
{"name":"MINDEMOYA","code":"CA-ON-MYO"},
{"name":"MINDEN","code":"CA-ON-MIN"},
{"name":"MINESING","code":"CA-ON-MNS"},
{"name":"MISSISSAUGA","code":"CA-ON-MIS"},
{"name":"MITCHELL","code":"CA-ON-MHL"},
{"name":"MONKLAND","code":"CA-ON-MNK"},
{"name":"MONKTON","code":"CA-ON-MON"},
{"name":"MONO","code":"CA-ON-ONO"},
{"name":"MOONBEAM","code":"CA-ON-MBE"},
{"name":"MOOREFIELD","code":"CA-ON-MRF"},
{"name":"MOORETOWN","code":"CA-ON-MRW"},
{"name":"MOOSE CREEK","code":"CA-ON-MCK"},
{"name":"MOOSE FACTORY","code":"CA-ON-MOO"},
{"name":"MOOSONEE","code":"CA-ON-MOU"},
{"name":"MORRISBURG","code":"CA-ON-MOB"},
{"name":"MOSSLEY","code":"CA-ON-XSY"},
{"name":"MOUNT BRYDGES","code":"CA-ON-MBY"},
{"name":"MOUNT ELGIN","code":"CA-ON-MEG"},
{"name":"MOUNT FOREST","code":"CA-ON-MTF"},
{"name":"MOUNT HOPE","code":"CA-ON-MHO"},
{"name":"MOUNT PLEASANT","code":"CA-ON-MPS"},
{"name":"MUIRKIRK","code":"CA-ON-MKK"},
{"name":"MULMUR","code":"CA-ON-MRU"},
{"name":"MUSKOKA","code":"CA-ON-YQA"},
{"name":"MUSKRAT DAM","code":"CA-ON-MSA"},
{"name":"NAKINA","code":"CA-ON-YQN"},
{"name":"NANTICOKE","code":"CA-ON-NAN"},
{"name":"NAPANEE","code":"CA-ON-NPA"},
{"name":"NEEBING","code":"CA-ON-NBI"},
{"name":"NEPEAN","code":"CA-ON-NPN"},
{"name":"NEPEAU","code":"CA-ON-NPU"},
{"name":"NEPHTON","code":"CA-ON-NPH"},
{"name":"NEW DUNDEE","code":"CA-ON-NDU"},
{"name":"NEW HAMBURG","code":"CA-ON-NHM"},
{"name":"NEW LISKEARD","code":"CA-ON-NLI"},
{"name":"NEW LOWELL","code":"CA-ON-NLW"},
{"name":"NEWBURGH","code":"CA-ON-NBH"},
{"name":"NEWCASTLE","code":"CA-ON-NWT"},
{"name":"NEWMARKET","code":"CA-ON-NWM"},
{"name":"NIAGARA FALLS","code":"CA-ON-NIA"},
{"name":"NIAGARA-ON-THE-LAKE","code":"CA-ON-NOL"},
{"name":"NIPIGON","code":"CA-ON-NPG"},
{"name":"NOBLETON","code":"CA-ON-NOB"},
{"name":"NORFOLK COUNTY","code":"CA-ON-NKF"},
{"name":"NORTH BAY","code":"CA-ON-NOR"},
{"name":"NORTH GOWER","code":"CA-ON-NGW"},
{"name":"NORTH HURON","code":"CA-ON-NHR"},
{"name":"NORTH SPIRIT LAKE","code":"CA-ON-YNO"},
{"name":"NORTH YORK","code":"CA-ON-NYK"},
{"name":"NORTHBROOK","code":"CA-ON-ORT"},
{"name":"NORVAL","code":"CA-ON-NVA"},
{"name":"NORWICH (ONTARIO)","code":"CA-ON-NWC"},
{"name":"NOTRE-DAME-DU-NORD","code":"CA-ON-NUD"},
{"name":"NOTTAWA","code":"CA-ON-NTW"},
{"name":"OAKVILLE","code":"CA-ON-OAK"},
{"name":"ODESSA","code":"CA-ON-ODE"},
{"name":"OGOKI","code":"CA-ON-YOG"},
{"name":"OHSWEKEN","code":"CA-ON-OWK"},
{"name":"OIL SPRINGS","code":"CA-ON-OSG"},
{"name":"OLDCASTLE","code":"CA-ON-OCA"},
{"name":"OMEMEE","code":"CA-ON-OMM"},
{"name":"OMPAH","code":"CA-ON-OPH"},
{"name":"ONAPING","code":"CA-ON-ONP"},
{"name":"ORANGEVILLE","code":"CA-ON-ORA"},
{"name":"ORILLIA","code":"CA-ON-ORI"},
{"name":"ORLÉANS","code":"CA-ON-OLS"},
{"name":"ORO-MEDONTE","code":"CA-ON-OOO"},
{"name":"ORONO","code":"CA-ON-ORR"},
{"name":"ORTON","code":"CA-ON-ONT"},
{"name":"OSGOODE","code":"CA-ON-OGO"},
{"name":"OSHAWA","code":"CA-ON-OSH"},
{"name":"OTTAWA","code":"CA-ON-OTT"},
{"name":"OTTAWA APT","code":"CA-ON-YOW"},
{"name":"OTTERVILLE","code":"CA-ON-OTV"},
{"name":"OWEN SOUND","code":"CA-ON-OWS"},
{"name":"PAIN COURT","code":"CA-ON-PCU"},
{"name":"PAISLEY","code":"CA-ON-PLY"},
{"name":"PAKENHAM","code":"CA-ON-PAK"},
{"name":"PALGRAVE","code":"CA-ON-PLV"},
{"name":"PALMERSTON","code":"CA-ON-PMR"},
{"name":"PARIS","code":"CA-ON-PAO"},
{"name":"PARKHILL","code":"CA-ON-PKH"},
{"name":"PARRY SOUND","code":"CA-ON-PRS"},
{"name":"PEARSON INTERNATIONAL APT/TORONTO","code":"CA-ON-YYZ"},
{"name":"PEAWANUCK","code":"CA-ON-YPO"},
{"name":"PELHAM","code":"CA-ON-PLH"},
{"name":"PEMBROKE","code":"CA-ON-PEM"},
{"name":"PENETANGUISHENE","code":"CA-ON-PTH"},
{"name":"PERTH","code":"CA-ON-PER"},
{"name":"PETAWAWA","code":"CA-ON-YWA"},
{"name":"PETERBOROUGH","code":"CA-ON-PBH"},
{"name":"PETERSBURG","code":"CA-ON-PBR"},
{"name":"PETROLIA","code":"CA-ON-PET"},
{"name":"PICKERING","code":"CA-ON-PCK"},
{"name":"PICKLE LAKE","code":"CA-ON-YPL"},
{"name":"PICTON","code":"CA-ON-PIC"},
{"name":"PIKANGIKUM","code":"CA-ON-YPM"},
{"name":"PLANTAGENET","code":"CA-ON-PLN"},
{"name":"PLATTSVILLE","code":"CA-ON-PLA"},
{"name":"POINT EDWARD","code":"CA-ON-PEW"},
{"name":"POINT PELEE","code":"CA-ON-DLM"},
{"name":"PONTYPOOL","code":"CA-ON-PTY"},
{"name":"POPLAR HILL","code":"CA-ON-POH"},
{"name":"PORCUPINE","code":"CA-ON-PCP"},
{"name":"PORT ARTHUR","code":"CA-ON-POA"},
{"name":"PORT BURWELL","code":"CA-ON-BUZ"},
{"name":"PORT CARLING","code":"CA-ON-CR9"},
{"name":"PORT COLBORNE","code":"CA-ON-PCO"},
{"name":"PORT CREDIT","code":"CA-ON-PCR"},
{"name":"PORT DOVER","code":"CA-ON-PDV"},
{"name":"PORT ELGIN","code":"CA-ON-GIN"},
{"name":"PORT HOPE","code":"CA-ON-OPE"},
{"name":"PORT PERRY","code":"CA-ON-PRP"},
{"name":"PORT ROBINSON","code":"CA-ON-PRB"},
{"name":"PORT STANLEY","code":"CA-ON-PST"},
{"name":"PORT WELLER","code":"CA-ON-PWE"},
{"name":"PORTLAND","code":"CA-ON-PTL"},
{"name":"POWASSAN","code":"CA-ON-PWS"},
{"name":"PRESCOTT","code":"CA-ON-PRE"},
{"name":"PRINCETON","code":"CA-ON-PRT"},
{"name":"PUSLINCH","code":"CA-ON-PUH"},
{"name":"PUTNAM","code":"CA-ON-PUT"},
{"name":"QUEENSTON","code":"CA-ON-QNS"},
{"name":"RAINY RIVER","code":"CA-ON-RRV"},
{"name":"RED LAKE","code":"CA-ON-RLA"},
{"name":"RED ROCK","code":"CA-ON-RRC"},
{"name":"RENFREW","code":"CA-ON-RNF"},
{"name":"RESTOULE","code":"CA-ON-RST"},
{"name":"REXDALE/TORONTO","code":"CA-ON-REX"},
{"name":"RICHMOND","code":"CA-ON-RMD"},
{"name":"RICHMOND HILL","code":"CA-ON-RHI"},
{"name":"RIDEAU FERRY","code":"CA-ON-REF"},
{"name":"RIDGETOWN","code":"CA-ON-RTW"},
{"name":"RIDGEVILLE","code":"CA-ON-RGI"},
{"name":"RIDGEWAY","code":"CA-ON-ONR"},
{"name":"RIPLEY","code":"CA-ON-RTY"},
{"name":"ROBLIN","code":"CA-ON-RBI"},
{"name":"ROCKLAND","code":"CA-ON-ROK"},
{"name":"ROCKPORT","code":"CA-ON-GNN"},
{"name":"ROCKTON","code":"CA-ON-RQQ"},
{"name":"ROCKWOOD","code":"CA-ON-RCD"},
{"name":"RODNEY","code":"CA-ON-RDN"},
{"name":"ROSSLYN","code":"CA-ON-OSS"},
{"name":"ROSSPORT","code":"CA-ON-RPO"},
{"name":"ROUND LAKE","code":"CA-ON-ZRJ"},
{"name":"RUTHVEN","code":"CA-ON-RUV"},
{"name":"SACHIGO LAKE","code":"CA-ON-ZPB"},
{"name":"SAINT CATHERINES","code":"CA-ON-SC4"},
{"name":"SAINT CLEMENTS","code":"CA-ON-F5R"},
{"name":"SAINT THOMAS","code":"CA-ON-S7T"},
{"name":"SAINT WILLIAMS","code":"CA-ON-WON"},
{"name":"SAINT-CATHARINES","code":"CA-ON-SCA"},
{"name":"SAINT-CHARLES","code":"CA-ON-NSC"},
{"name":"SAINT-CLEMENTS","code":"CA-ON-SLN"},
{"name":"SAINT-DAVIDS","code":"CA-ON-DDS"},
{"name":"SAINT-EUGÈNE","code":"CA-ON-SUG"},
{"name":"SAINT-GEORGE","code":"CA-ON-STG"},
{"name":"SAINT-ISIDORE","code":"CA-ON-SID"},
{"name":"SAINT-JACOBS","code":"CA-ON-SJS"},
{"name":"SAINT-MARYS","code":"CA-ON-SMY"},
{"name":"SAINT-PAULS-STATION","code":"CA-ON-PLO"},
{"name":"SAINT-THOMAS","code":"CA-ON-STH"},
{"name":"SAINT-VALLIER","code":"CA-ON-SVL"},
{"name":"SALFORD","code":"CA-ON-SAL"},
{"name":"SANDY LAKE","code":"CA-ON-ZSJ"},
{"name":"SANS SOUCI","code":"CA-ON-YSI"},
{"name":"SARNIA","code":"CA-ON-SNI"},
{"name":"SARSFIELD","code":"CA-ON-SSF"},
{"name":"SAULT-SAINTE-MARIE","code":"CA-ON-SSM"},
{"name":"SCARBOROUGH","code":"CA-ON-SCB"},
{"name":"SCHOMBERG","code":"CA-ON-SOG"},
{"name":"SCHREIBER","code":"CA-ON-SHB"},
{"name":"SCHUMACHER","code":"CA-ON-SHM"},
{"name":"SCOTLAND","code":"CA-ON-SLD"},
{"name":"SCUDDER","code":"CA-ON-SDD"},
{"name":"SEARCHMONT","code":"CA-ON-6CA"},
{"name":"SEBRINGVILLE","code":"CA-ON-SGV"},
{"name":"SHAKESPEARE","code":"CA-ON-HAE"},
{"name":"SHAKESPEARE ISLAND","code":"CA-ON-SKP"},
{"name":"SHALLOWS LAKE","code":"CA-ON-SHL"},
{"name":"SHANNONVILLE","code":"CA-ON-SHV"},
{"name":"SHANTY BAY","code":"CA-ON-SYB"},
{"name":"SHARON","code":"CA-ON-SR9"},
{"name":"SHELBURNE","code":"CA-ON-SLB"},
{"name":"SHERKSTON","code":"CA-ON-SHK"},
{"name":"SHUNIAH","code":"CA-ON-SUI"},
{"name":"SILVER WATER","code":"CA-ON-SWT"},
{"name":"SIMCOE","code":"CA-ON-SIC"},
{"name":"SIOUX LOOKOUT","code":"CA-ON-YXL"},
{"name":"SKEAD","code":"CA-ON-SKD"},
{"name":"SLATE ISLAND","code":"CA-ON-YSS"},
{"name":"SMITH FALLS","code":"CA-ON-YSH"},
{"name":"SMITHS FALLS","code":"CA-ON-SMF"},
{"name":"SMITHVILLE","code":"CA-ON-SMV"},
{"name":"SOMBRA","code":"CA-ON-SOM"},
{"name":"SOUTH DUNDAS","code":"CA-ON-OUU"},
{"name":"SOUTH GLENGARRY","code":"CA-ON-SG2"},
{"name":"SOUTH MONAGHAN","code":"CA-ON-SM5"},
{"name":"SOUTH RIVER","code":"CA-ON-SRT"},
{"name":"SOUTH STORMONT","code":"CA-ON-SOT"},
{"name":"SOUTH TROUT LAKE","code":"CA-ON-ZFL"},
{"name":"SOUTHAMPTON","code":"CA-ON-SON"},
{"name":"SOUTHWOLD","code":"CA-ON-OWD"},
{"name":"SPENCERVILLE","code":"CA-ON-SPV"},
{"name":"SPRAGGE","code":"CA-ON-SPR"},
{"name":"SPRINGFILED","code":"CA-ON-OGN"},
{"name":"ST. ANNS / WEST LINCOLN","code":"CA-ON-AWL"},
{"name":"ST. BERNARDIN","code":"CA-ON-SBN"},
{"name":"ST. JOSEPH","code":"CA-ON-OJH"},
{"name":"STAFFA","code":"CA-ON-SFA"},
{"name":"STAYNER","code":"CA-ON-STY"},
{"name":"STEVENSVILLE","code":"CA-ON-SVS"},
{"name":"STIRLING","code":"CA-ON-SLG"},
{"name":"STITTSVILLE","code":"CA-ON-SLL"},
{"name":"STONEY CREEK","code":"CA-ON-STC"},
{"name":"STONEY POINT, ESSEX","code":"CA-ON-SYT"},
{"name":"STOUFFVILLE","code":"CA-ON-SFV"},
{"name":"STRAFFORDVILLE","code":"CA-ON-SFL"},
{"name":"STRATFORD","code":"CA-ON-STR"},
{"name":"STRATHROY","code":"CA-ON-STT"},
{"name":"STREETSVILLE","code":"CA-ON-SVI"},
{"name":"STURGEON FALLS","code":"CA-ON-SGF"},
{"name":"SUDBURY","code":"CA-ON-SUD"},
{"name":"SUMMER BEAVER","code":"CA-ON-SUR"},
{"name":"SUNDERLAND","code":"CA-ON-SDL"},
{"name":"SUTTON","code":"CA-ON-UTT"},
{"name":"SWASTIKA","code":"CA-ON-SWA"},
{"name":"TALBOTVILLE","code":"CA-ON-TAL"},
{"name":"TALBOTVILLE ROYAL","code":"CA-ON-TBA"},
{"name":"TARA","code":"CA-ON-TAR"},
{"name":"TAVISTOCK","code":"CA-ON-TAV"},
{"name":"TECUMSEH","code":"CA-ON-TEC"},
{"name":"TEMAGAMI","code":"CA-ON-TGI"},
{"name":"TERRACE BAY","code":"CA-ON-YTJ"},
{"name":"THAMESFORD","code":"CA-ON-THA"},
{"name":"THAMESVILLE","code":"CA-ON-OTE"},
{"name":"THEDFORD","code":"CA-ON-THF"},
{"name":"THESSALON","code":"CA-ON-THS"},
{"name":"THORNBURY","code":"CA-ON-TBY"},
{"name":"THORNDALE","code":"CA-ON-TND"},
{"name":"THORNHILL","code":"CA-ON-THL"},
{"name":"THORNTON","code":"CA-ON-THN"},
{"name":"THOROLD","code":"CA-ON-THD"},
{"name":"THUNDER BAY","code":"CA-ON-THU"},
{"name":"TILBURY","code":"CA-ON-TIY"},
{"name":"TILLSONBURG","code":"CA-ON-TBG"},
{"name":"TIMMINS","code":"CA-ON-YTS"},
{"name":"TINY","code":"CA-ON-TNY"},
{"name":"TIVERTON","code":"CA-ON-TVT"},
{"name":"TORONTO","code":"CA-ON-TOR"},
{"name":"TOTTENHAM","code":"CA-ON-TOT"},
{"name":"TRENTON","code":"CA-ON-TTC"},
{"name":"TROY","code":"CA-ON-TRY"},
{"name":"TUPPERVILLE","code":"CA-ON-TPY"},
{"name":"TWEED","code":"CA-ON-TWE"},
{"name":"UDORA","code":"CA-ON-ORU"},
{"name":"UNIONVILLE","code":"CA-ON-UNV"},
{"name":"UTOPIA","code":"CA-ON-UTP"},
{"name":"UTTERSON","code":"CA-ON-UTS"},
{"name":"UXBRIDGE","code":"CA-ON-UXB"},
{"name":"VANIER","code":"CA-ON-VNR"},
{"name":"VANKLEEK HILL","code":"CA-ON-VKH"},
{"name":"VARS","code":"CA-ON-VRS"},
{"name":"VAUGHAN","code":"CA-ON-VGN"},
{"name":"VICTORIA HARBOUR","code":"CA-ON-VIH"},
{"name":"VIENNA","code":"CA-ON-VIE"},
{"name":"VINELAND STATION","code":"CA-ON-VIN"},
{"name":"VIRGIL","code":"CA-ON-VRG"},
{"name":"WAINFLEET","code":"CA-ON-WFZ"},
{"name":"WALKERTON","code":"CA-ON-WOT"},
{"name":"WALKERVILLE","code":"CA-ON-WAV"},
{"name":"WALLACEBURG","code":"CA-ON-WAL"},
{"name":"WALLENSTEIN","code":"CA-ON-WLS"},
{"name":"WALSINGHAM","code":"CA-ON-WSG"},
{"name":"WALTON","code":"CA-ON-WLN"},
{"name":"WARDSVILLE","code":"CA-ON-WDI"},
{"name":"WARREN","code":"CA-ON-WRN"},
{"name":"WASAGA BEACH","code":"CA-ON-WAB"},
{"name":"WATERDOWN","code":"CA-ON-WTR"},
{"name":"WATERFORD","code":"CA-ON-WFF"},
{"name":"WATERLOO","code":"CA-ON-WTL"},
{"name":"WATFORD","code":"CA-ON-WTF"},
{"name":"WAWA","code":"CA-ON-YXZ"},
{"name":"WEBEQUIE","code":"CA-ON-YWP"},
{"name":"WELLAND","code":"CA-ON-WEL"},
{"name":"WELLESLEY","code":"CA-ON-WLY"},
{"name":"WELLINGTON","code":"CA-ON-WEN"},
{"name":"WENDOVER","code":"CA-ON-W2N"},
{"name":"WEST HILL","code":"CA-ON-WHL"},
{"name":"WEST LORNE","code":"CA-ON-WLO"},
{"name":"WEST MONTROSE","code":"CA-ON-WMR"},
{"name":"WESTON","code":"CA-ON-WES"},
{"name":"WESTPORT","code":"CA-ON-WTO"},
{"name":"WHEATLEY","code":"CA-ON-WHE"},
{"name":"WHITBY","code":"CA-ON-WHI"},
{"name":"WHITE RIVER","code":"CA-ON-YWR"},
{"name":"WHITEFISH","code":"CA-ON-WHF"},
{"name":"WIARTON","code":"CA-ON-YVV"},
{"name":"WILKESPORT","code":"CA-ON-WKP"},
{"name":"WILLIAMSBURG","code":"CA-ON-WGO"},
{"name":"WILLIAMSFORD","code":"CA-ON-WLF"},
{"name":"WILLIAMSTOWN","code":"CA-ON-WST"},
{"name":"WILLOWDALE","code":"CA-ON-WIL"},
{"name":"WINCHESTER","code":"CA-ON-WIH"},
{"name":"WINDERMERE","code":"CA-ON-WRM"},
{"name":"WINDHAM CENTRE","code":"CA-ON-WHC"},
{"name":"WINDSOR","code":"CA-ON-WND"},
{"name":"WINGHAM","code":"CA-ON-WIG"},
{"name":"WINISK","code":"CA-ON-YWN"},
{"name":"WINONA","code":"CA-ON-WIN"},
{"name":"WOLFE ISLAND","code":"CA-ON-WFI"},
{"name":"WOODBRIDGE","code":"CA-ON-WDB"},
{"name":"WOODHAM","code":"CA-ON-WDA"},
{"name":"WOODSTOCK","code":"CA-ON-WSK"},
{"name":"WORTHINGTON","code":"CA-ON-WRG"},
{"name":"WUNNUMMIN LAKE","code":"CA-ON-WNN"},
{"name":"WYOMING","code":"CA-ON-WYO"},
{"name":"YORK","code":"CA-ON-YRK"},
{"name":"ZEPHYR","code":"CA-ON-ZPY"},
{"name":"ZURICH","code":"CA-ON-ZUR"},
        ]
      },
      {
        "name": "Prince Edward Island",
        "code": "PE",
        "states": [
          {"name":"ABRAM-VILLAGE","code":"CA-PE-ABV"},
{"name":"ALBANY","code":"CA-PE-AYY"},
{"name":"ALBERTON","code":"CA-PE-ATO"},
{"name":"BEACH POINT","code":"CA-PE-BPO"},
{"name":"BEDEQUE","code":"CA-PE-BEQ"},
{"name":"BELFAST","code":"CA-PE-BFF"},
{"name":"BORDEN","code":"CA-PE-BOR"},
{"name":"BREADALBANE","code":"CA-PE-BBA"},
{"name":"CARDIGAN","code":"CA-PE-CDG"},
{"name":"CAVENDISH","code":"CA-PE-CAV"},
{"name":"CHARLOTTETOWN","code":"CA-PE-CHA"},
{"name":"COLEMAN","code":"CA-PE-CPD"},
{"name":"CORNWALL","code":"CA-PE-CNW"},
{"name":"COVEHEAD","code":"CA-PE-CVO"},
{"name":"CRAPAUD","code":"CA-PE-CRD"},
{"name":"EBENEZER","code":"CA-PE-EBZ"},
{"name":"ELLERSLIE","code":"CA-PE-ELS"},
{"name":"ELMIRA","code":"CA-PE-EMR"},
{"name":"FOXLEY RIVER","code":"CA-PE-FOX"},
{"name":"FREETOWN","code":"CA-PE-FTW"},
{"name":"GASPEREAUX","code":"CA-PE-GAX"},
{"name":"GEORGETOWN","code":"CA-PE-GEO"},
{"name":"HUNTER RIVER","code":"CA-PE-HUR"},
{"name":"KENSINGTON","code":"CA-PE-KSN"},
{"name":"KINKORA","code":"CA-PE-KKR"},
{"name":"MALPEQUE BAY","code":"CA-PE-MQE"},
{"name":"MIDDLETON","code":"CA-PE-MDP"},
{"name":"MIMINEGASH","code":"CA-PE-MIM"},
{"name":"MISCOUCHE","code":"CA-PE-MCE"},
{"name":"MONTAGUE","code":"CA-PE-MGU"},
{"name":"MORELL","code":"CA-PE-MOP"},
{"name":"MOUNT ALBION","code":"CA-PE-MBI"},
{"name":"MOUNT STEWART","code":"CA-PE-MOS"},
{"name":"MURRAY HARBOUR","code":"CA-PE-MUH"},
{"name":"MURRAY RIVER","code":"CA-PE-MUR"},
{"name":"NEW ANNAN","code":"CA-PE-NEA"},
{"name":"NORTH RUSTICO","code":"CA-PE-NRU"},
{"name":"NORTH WILTSHIRE","code":"CA-PE-NWH"},
{"name":"O'LEARY","code":"CA-PE-OLE"},
{"name":"PORTAGE","code":"CA-PE-POR"},
{"name":"PRINCE EDWARD ISLAND","code":"CA-PE-CRL"},
{"name":"ROBERTSON","code":"CA-PE-RSN"},
{"name":"ROSENEATH","code":"CA-PE-AQW"},
{"name":"SAINT PETERS","code":"CA-PE-S5S"},
{"name":"SHERWOOD","code":"CA-PE-SWD"},
{"name":"SOURIS","code":"CA-PE-SOU"},
{"name":"STRATFORD","code":"CA-PE-STF"},
{"name":"SUMMERSIDE","code":"CA-PE-SUM"},
{"name":"TIGNISH","code":"CA-PE-TIG"},
{"name":"VERNON BRIDGE","code":"CA-PE-VEB"},
{"name":"WELLINGTON","code":"CA-PE-EIW"},
{"name":"WINSLOE","code":"CA-PE-WSL"},
{"name":"WOOD ISLAND","code":"CA-PE-WOI"},
{"name":"WOOD ISLANDS","code":"CA-PE-WOP"},
{"name":"YORK","code":"CA-PE-YOK"},
        ]
      },
      {
        "name": "Quebec",
        "code": "QC",
        "states": [
          {"name":"ACTON VALE","code":"CA-QC-ACV"},
{"name":"ADSTOCK","code":"CA-QC-DST"},
{"name":"AKULIVIK APT","code":"CA-QC-AKV"},
{"name":"ALMA","code":"CA-QC-YTF"},
{"name":"AMOS","code":"CA-QC-YEY"},
{"name":"AMQUI","code":"CA-QC-AMQ"},
{"name":"ANGE GARDIEN DE ROUVILLE","code":"CA-QC-AGR"},
{"name":"ANJOU","code":"CA-QC-AJU"},
{"name":"ARTHABASKA","code":"CA-QC-ART"},
{"name":"ARUNDEL","code":"CA-QC-ARU"},
{"name":"ASBESTOS","code":"CA-QC-ASB"},
{"name":"ASBESTOS HILL","code":"CA-QC-YAF"},
{"name":"ASTON-JONCTION","code":"CA-QC-AJN"},
{"name":"AUPAULUK","code":"CA-QC-YPJ"},
{"name":"AUSTIN","code":"CA-QC-AUQ"},
{"name":"AYER'S CLIFF","code":"CA-QC-ACL"},
{"name":"AYLMER","code":"CA-QC-AYM"},
{"name":"BAGOTVILLE","code":"CA-QC-BGT"},
{"name":"BAGOTVILLE APT","code":"CA-QC-YBG"},
{"name":"BAIE COMEAU","code":"CA-QC-BCO"},
{"name":"BAIE D'URFÉ","code":"CA-QC-BDU"},
{"name":"BAIE-JOHAN-BEETZ","code":"CA-QC-BJO"},
{"name":"BAIE-ST-PAUL","code":"CA-QC-BSP"},
{"name":"BATISCAN","code":"CA-QC-BSN"},
{"name":"BEACONSFIELD","code":"CA-QC-BCF"},
{"name":"BEAUCEVILLE","code":"CA-QC-BEU"},
{"name":"BEAUHARNOIS","code":"CA-QC-BHA"},
{"name":"BEAUPORT","code":"CA-QC-BPT"},
{"name":"BEAUPRÉ","code":"CA-QC-BPB"},
{"name":"BÉCANCOUR","code":"CA-QC-BEC"},
{"name":"BEDFORD","code":"CA-QC-BED"},
{"name":"BELOEIL","code":"CA-QC-BLL"},
{"name":"BERTHIER-SUR-MER","code":"CA-QC-BSM"},
{"name":"BERTHIERVILLE","code":"CA-QC-BER"},
{"name":"BLACK LAKE","code":"CA-QC-BKL"},
{"name":"BLAINVILLE","code":"CA-QC-BLA"},
{"name":"BLANC-SABLON","code":"CA-QC-YBX"},
{"name":"BOIS-DES-FILION","code":"CA-QC-BFI"},
{"name":"BOISBRIAND","code":"CA-QC-BBD"},
{"name":"BON CONSEIL","code":"CA-QC-BCL"},
{"name":"BONAVENTURE","code":"CA-QC-YVB"},
{"name":"BOUCHERVILLE","code":"CA-QC-BOU"},
{"name":"BRISTOL","code":"CA-QC-BSL"},
{"name":"BROMONT","code":"CA-QC-ZBM"},
{"name":"BROMPTONVILLE","code":"CA-QC-BTV"},
{"name":"BROSSARD","code":"CA-QC-BRO"},
{"name":"BROWNSBURG","code":"CA-QC-OWN"},
{"name":"BROWNSBURG-CHATHAM","code":"CA-QC-BRJ"},
{"name":"BUCKINGHAM","code":"CA-QC-BUC"},
{"name":"BURY","code":"CA-QC-BUY"},
{"name":"CABANO","code":"CA-QC-CBO"},
{"name":"CACOUNA","code":"CA-QC-CCA"},
{"name":"CADILLAC","code":"CA-QC-CDL"},
{"name":"CANDIAC","code":"CA-QC-CDC"},
{"name":"CANTLEY","code":"CA-QC-C5L"},
{"name":"CAP-AUX-MEULES","code":"CA-QC-CMS"},
{"name":"CAP-CHAT","code":"CA-QC-CAC"},
{"name":"CAP-DE-LA-MADELEINE","code":"CA-QC-CME"},
{"name":"CAP-SANTÉ","code":"CA-QC-CEE"},
{"name":"CAP-ST-IGNACE","code":"CA-QC-CSI"},
{"name":"CARIGNAN","code":"CA-QC-CGN"},
{"name":"CARLETON","code":"CA-QC-CLT"},
{"name":"CARTIERVILLE","code":"CA-QC-YCV"},
{"name":"CAUSAPSCAL","code":"CA-QC-AAU"},
{"name":"CHAMBLY","code":"CA-QC-CHY"},
{"name":"CHAMBORD","code":"CA-QC-CMB"},
{"name":"CHAMPLAIN","code":"CA-QC-CPL"},
{"name":"CHANDLER","code":"CA-QC-CHR"},
{"name":"CHAPAIS","code":"CA-QC-CPH"},
{"name":"CHARETTE","code":"CA-QC-CTE"},
{"name":"CHARLESBOURG","code":"CA-QC-CHG"},
{"name":"CHARNY","code":"CA-QC-CRY"},
{"name":"CHARTIERVILLE","code":"CA-QC-CTL"},
{"name":"CHÂTEAU-RICHER","code":"CA-QC-RRE"},
{"name":"CHÂTEAUGUAY","code":"CA-QC-CGY"},
{"name":"CHELSEA","code":"CA-QC-CSA"},
{"name":"CHERTSEY","code":"CA-QC-CTY"},
{"name":"CHESTERVILLE","code":"CA-QC-CVE"},
{"name":"CHEVERY","code":"CA-QC-YHR"},
{"name":"CHIBOUGAMAU","code":"CA-QC-YMT"},
{"name":"CHICOUTIMI","code":"CA-QC-CHI"},
{"name":"CHISASIBI","code":"CA-QC-YKU"},
{"name":"CHOMEDEY","code":"CA-QC-CHO"},
{"name":"CLARENCEVILLE","code":"CA-QC-CLA"},
{"name":"CLARKE CITY","code":"CA-QC-CLC"},
{"name":"CLERMONT","code":"CA-QC-COT"},
{"name":"COATICOOK","code":"CA-QC-COA"},
{"name":"COMPTON","code":"CA-QC-CMP"},
{"name":"CONTRECOEUR","code":"CA-QC-COC"},
{"name":"COOKSHIRE","code":"CA-QC-CKS"},
{"name":"CÔTE-SAINT-LUC","code":"CA-QC-CSL"},
{"name":"CÔTE-SAINT-LUC","code":"CA-QC-CSU"},
{"name":"CÔTE-SAINTE-CATHERINE","code":"CA-QC-CSC"},
{"name":"COTEAU DU LAC","code":"CA-QC-CQA"},
{"name":"COURCELETTE","code":"CA-QC-CRE"},
{"name":"COURCELLES","code":"CA-QC-CCL"},
{"name":"COWANSVILLE","code":"CA-QC-COW"},
{"name":"CRABTREE","code":"CA-QC-TRE"},
{"name":"DANVILLE","code":"CA-QC-DVL"},
{"name":"DAVELUYVILLE","code":"CA-QC-DYV"},
{"name":"DAVIDSON","code":"CA-QC-DVO"},
{"name":"DECEPTION BAY","code":"CA-QC-DEB"},
{"name":"DEGELIS","code":"CA-QC-DGL"},
{"name":"DELEAGE","code":"CA-QC-DLG"},
{"name":"DELSON/QUEBEC","code":"CA-QC-DLS"},
{"name":"DESCHAILLONS-SUR-SAINT-LAURENT","code":"CA-QC-DCH"},
{"name":"DESCHAMBAULT","code":"CA-QC-DBT"},
{"name":"DEUX-MONTAGNES","code":"CA-QC-DMG"},
{"name":"DISRAELI","code":"CA-QC-DSL"},
{"name":"DOLBEAU","code":"CA-QC-YDO"},
{"name":"DOLLARD-DES-ORMEAUX","code":"CA-QC-DDO"},
{"name":"DONNACONA","code":"CA-QC-DNC"},
{"name":"DORION","code":"CA-QC-QCD"},
{"name":"DORVAL","code":"CA-QC-DOR"},
{"name":"DOSQUET","code":"CA-QC-DQT"},
{"name":"DRUMMONDVILLE","code":"CA-QC-DRU"},
{"name":"DUHAMEL-OUEST","code":"CA-QC-DHO"},
{"name":"DUNDEE","code":"CA-QC-DDE"},
{"name":"DUNHAM","code":"CA-QC-DQQ"},
{"name":"EAST ANGUS","code":"CA-QC-ETA"},
{"name":"EAST BROUGHTON STATION","code":"CA-QC-EBS"},
{"name":"EAST HEREFORD","code":"CA-QC-EHD"},
{"name":"EAST MAIN","code":"CA-QC-ZEM"},
{"name":"ETANG-DU-NORD","code":"CA-QC-ETN"},
{"name":"FABREVILLE","code":"CA-QC-FVL"},
{"name":"FARNHAM","code":"CA-QC-FAR"},
{"name":"FASSETT","code":"CA-QC-FSS"},
{"name":"FATHER POINT","code":"CA-QC-FAP"},
{"name":"FERME-NEUVE","code":"CA-QC-FNV"},
{"name":"FERMONT","code":"CA-QC-FMT"},
{"name":"FONTANGES","code":"CA-QC-YFG"},
{"name":"FORESTVILLE","code":"CA-QC-FRV"},
{"name":"FORT COULONGE","code":"CA-QC-FCO"},
{"name":"FORTIERVILLE","code":"CA-QC-Q9Q"},
{"name":"FOX RIVER (=RIVIERE-AU-RENARD)","code":"CA-QC-QCF"},
{"name":"FRAMPTON","code":"CA-QC-FPT"},
{"name":"FRANKLIN CENTRE","code":"CA-QC-JFK"},
{"name":"FRELIGHSBURG","code":"CA-QC-FGH"},
{"name":"GAGNON","code":"CA-QC-YGA"},
{"name":"GASPÉ","code":"CA-QC-GPE"},
{"name":"GATINEAU","code":"CA-QC-GAT"},
{"name":"GETHSÉMANI","code":"CA-QC-ZGS"},
{"name":"GIRARDVILLE","code":"CA-QC-GQC"},
{"name":"GODBOUT","code":"CA-QC-GBT"},
{"name":"GRANBY","code":"CA-QC-GRA"},
{"name":"GRAND MERE","code":"CA-QC-QGM"},
{"name":"GRAND TOYS DORVAL","code":"CA-QC-GTD"},
{"name":"GRAND-MERE","code":"CA-QC-GRM"},
{"name":"GRANDE RIVIERE","code":"CA-QC-GEE"},
{"name":"GRANDE-VALLÉE","code":"CA-QC-GVA"},
{"name":"GRANDES-PILES","code":"CA-QC-GPL"},
{"name":"GREENFIELD PARK","code":"CA-QC-GRE"},
{"name":"GRENVILLE","code":"CA-QC-GRZ"},
{"name":"GRINDSTONE","code":"CA-QC-GRS"},
{"name":"GRONDINES","code":"CA-QC-GOI"},
{"name":"GROS CACOUNA","code":"CA-QC-GCA"},
{"name":"GROSSE ISLE","code":"CA-QC-QEE"},
{"name":"HAM NORD","code":"CA-QC-HMN"},
{"name":"HAVRE-SAINT-PIERRE","code":"CA-QC-HSP"},
{"name":"HÉBERTVILLE","code":"CA-QC-HEB"},
{"name":"HEMMINGFORD","code":"CA-QC-HMM"},
{"name":"HENRYVILLE","code":"CA-QC-HLV"},
{"name":"HINCHINBROOKE","code":"CA-QC-HBK"},
{"name":"HONFLEUR","code":"CA-QC-HOF"},
{"name":"HUDSON","code":"CA-QC-HUD"},
{"name":"HULL","code":"CA-QC-HUL"},
{"name":"HUNTINGDON","code":"CA-QC-HDN"},
{"name":"IBERVILLE","code":"CA-QC-IBV"},
{"name":"ILE AUX COUDRES","code":"CA-QC-IXC"},
{"name":"ILE BIZARD","code":"CA-QC-BZD"},
{"name":"ILE PERROT","code":"CA-QC-IPE"},
{"name":"ÎLE-DU-GRAND-CALUMET","code":"CA-QC-IGT"},
{"name":"ILES DE BOUCHERVILLE","code":"CA-QC-BRV"},
{"name":"ILES DE LA MADELEINE","code":"CA-QC-YGR"},
{"name":"INUKJUAK","code":"CA-QC-YPH"},
{"name":"INVERNESS","code":"CA-QC-IVR"},
{"name":"ISSOUDUN","code":"CA-QC-ISD"},
{"name":"IVUJIVIK","code":"CA-QC-YIK"},
{"name":"JOLIETTE","code":"CA-QC-JOL"},
{"name":"JONQUIÈRE","code":"CA-QC-JON"},
{"name":"KAHNAWAKE","code":"CA-QC-KNW"},
{"name":"KANGIQSUALUJJUAQ","code":"CA-QC-XGR"},
{"name":"KANGIQSUJUAQ","code":"CA-QC-YWB"},
{"name":"KANGIRSUK","code":"CA-QC-YKG"},
{"name":"KATTINIQ/DONALDSON LAKE","code":"CA-QC-YAU"},
{"name":"KEGASKA","code":"CA-QC-ZKG"},
{"name":"KIAMIKA","code":"CA-QC-KQC"},
{"name":"KILLINEQ","code":"CA-QC-XBW"},
{"name":"KINGSBURY","code":"CA-QC-KGU"},
{"name":"KINGSEY FALLS","code":"CA-QC-KFS"},
{"name":"KINNEAR'S MILLS","code":"CA-QC-KNE"},
{"name":"KIRKLAND","code":"CA-QC-KIK"},
{"name":"KNOWLTON","code":"CA-QC-KNN"},
{"name":"KOVIK BAY","code":"CA-QC-KOV"},
{"name":"KUUJJUAQ","code":"CA-QC-FCM"},
{"name":"KUUJJUAQ APT","code":"CA-QC-YVP"},
{"name":"L'ANCIENNE-LORETTE","code":"CA-QC-LAL"},
{"name":"L'ANGE-GARDIEN","code":"CA-QC-LAG"},
{"name":"L'ANSE-À-BEAUFILS","code":"CA-QC-LB3"},
{"name":"L'ASCENSION-DE-NOTRE-SEIGNEUR","code":"CA-QC-LNS"},
{"name":"L'ASCENSION-DE-PATAPÉDIA","code":"CA-QC-LAP"},
{"name":"L'ASSOMPTION","code":"CA-QC-LAN"},
{"name":"L'EPIPHANIE","code":"CA-QC-EPN"},
{"name":"L'ÎLE-DES-SOEURS (NUNS ISLAND)","code":"CA-QC-ILS"},
{"name":"L'ISLE-VERTE","code":"CA-QC-IVT"},
{"name":"L'ISLET","code":"CA-QC-LIT"},
{"name":"L'ISLETVILLE","code":"CA-QC-LTV"},
{"name":"LA BAIE","code":"CA-QC-LBA"},
{"name":"LA GRANDE","code":"CA-QC-YGL"},
{"name":"LA GUADELOUPE","code":"CA-QC-LGE"},
{"name":"LA MALBAIE","code":"CA-QC-LAM"},
{"name":"LA PATRIE","code":"CA-QC-LPT"},
{"name":"LA POCATIÈRE","code":"CA-QC-LPO"},
{"name":"LA PRAIRIE","code":"CA-QC-LPE"},
{"name":"LA PRÉSENTATION","code":"CA-QC-RIE"},
{"name":"LA REINE","code":"CA-QC-LRE"},
{"name":"LA RELANCE","code":"CA-QC-REL"},
{"name":"LA SARRE","code":"CA-QC-SSQ"},
{"name":"LA TABATIÈRE","code":"CA-QC-ZLT"},
{"name":"LA TUQUE","code":"CA-QC-YLQ"},
{"name":"LA VISITATION-DE-YAMASKA","code":"CA-QC-LVD"},
{"name":"LAC BROME","code":"CA-QC-LBM"},
{"name":"LAC-BEAUPORT","code":"CA-QC-LBP"},
{"name":"LAC-BOUCHETTE","code":"CA-QC-LAB"},
{"name":"LAC-DES-ÉCORCES","code":"CA-QC-LEC"},
{"name":"LAC-DES-ÎLES","code":"CA-QC-LIL"},
{"name":"LAC-DROLET","code":"CA-QC-LDT"},
{"name":"LAC-DU-CERF","code":"CA-QC-CER"},
{"name":"LAC-MÉGANTIC","code":"CA-QC-LME"},
{"name":"LACHENAIE","code":"CA-QC-LCA"},
{"name":"LACHINE/MONTREAL","code":"CA-QC-LAC"},
{"name":"LACHUTE","code":"CA-QC-LCH"},
{"name":"LACOLLE","code":"CA-QC-LCO"},
{"name":"LACORNE","code":"CA-QC-LCN"},
{"name":"LAFORGES","code":"CA-QC-YLF"},
{"name":"LAGRANDE 3","code":"CA-QC-YAR"},
{"name":"LAGRANDE 4","code":"CA-QC-YAH"},
{"name":"LANDRIENNE","code":"CA-QC-LLA"},
{"name":"LANORAIE","code":"CA-QC-LRI"},
{"name":"LASALLE (LA SALLE)","code":"CA-QC-LAS"},
{"name":"LAURENTIDES","code":"CA-QC-LAU"},
{"name":"LAURIER-STATION","code":"CA-QC-LSA"},
{"name":"LAUZON","code":"CA-QC-LZN"},
{"name":"LAVAL","code":"CA-QC-LAV"},
{"name":"LAVALTRIE","code":"CA-QC-LVR"},
{"name":"LAVERLOCHÈRE","code":"CA-QC-LVH"},
{"name":"LAWRENCEVILLE","code":"CA-QC-LII"},
{"name":"LE BIC","code":"CA-QC-BC9"},
{"name":"LE GARDEUR","code":"CA-QC-LEG"},
{"name":"LEAF BAY","code":"CA-QC-XLF"},
{"name":"LEBEL-SUR-QUEVILLON","code":"CA-QC-YLS"},
{"name":"LEMOYNE","code":"CA-QC-LEM"},
{"name":"LENNOXVILLE","code":"CA-QC-LEN"},
{"name":"LÉRY","code":"CA-QC-LRY"},
{"name":"LES CÉDRES","code":"CA-QC-LCI"},
{"name":"LES COTEAUX","code":"CA-QC-LTX"},
{"name":"LES ESCOUMINS","code":"CA-QC-QLE"},
{"name":"LES MECHINS","code":"CA-QC-MHN"},
{"name":"LÉVIS","code":"CA-QC-LEV"},
{"name":"LONGUE-POINTE","code":"CA-QC-LNP"},
{"name":"LONGUEUIL","code":"CA-QC-LON"},
{"name":"LORETTEVILLE","code":"CA-QC-LVL"},
{"name":"LOTBINIÈRE","code":"CA-QC-LOT"},
{"name":"LOUISEVILLE","code":"CA-QC-LSV"},
{"name":"LUSKVILLE","code":"CA-QC-LKV"},
{"name":"LYSTER","code":"CA-QC-LYS"},
{"name":"MACAMIC","code":"CA-QC-MCC"},
{"name":"MAGDALEN IS","code":"CA-QC-MGD"},
{"name":"MAGOG","code":"CA-QC-MAG"},
{"name":"MAGPIE","code":"CA-QC-MGP"},
{"name":"MALARTIC","code":"CA-QC-MTI"},
{"name":"MANDEVILLE","code":"CA-QC-MAV"},
{"name":"MANIWAKI","code":"CA-QC-YMW"},
{"name":"MANSEAU","code":"CA-QC-MSU"},
{"name":"MANSONVILLE","code":"CA-QC-MAN"},
{"name":"MARIA","code":"CA-QC-MRR"},
{"name":"MARICOURT AIRSTRIP","code":"CA-QC-YMC"},
{"name":"MARIEVILLE","code":"CA-QC-MVL"},
{"name":"MASCOUCHE","code":"CA-QC-MSC"},
{"name":"MASKINONGE","code":"CA-QC-MKO"},
{"name":"MASSON","code":"CA-QC-MAS"},
{"name":"MASSUEVILLE","code":"CA-QC-SVV"},
{"name":"MATAGAMI","code":"CA-QC-YNM"},
{"name":"MATANE","code":"CA-QC-MNE"},
{"name":"MCMASTERVILLE","code":"CA-QC-MCM"},
{"name":"MELBOURNE","code":"CA-QC-ABU"},
{"name":"MELOCHEVILLE","code":"CA-QC-MOH"},
{"name":"MERCIER","code":"CA-QC-MER"},
{"name":"MÉTABETCHOUAN-LAC-À-LA-CROIX","code":"CA-QC-MBN"},
{"name":"METIS-SUR-MER","code":"CA-QC-MMR"},
{"name":"MINGAN","code":"CA-QC-YLP"},
{"name":"MIRABEL","code":"CA-QC-MRB"},
{"name":"MIRABEL APT/MONTREAL","code":"CA-QC-YMX"},
{"name":"MISTASSINI","code":"CA-QC-MST"},
{"name":"MISTISSINI","code":"CA-QC-MII"},
{"name":"MONT JOLI","code":"CA-QC-YYY"},
{"name":"MONT ROLLAND","code":"CA-QC-MRO"},
{"name":"MONT TREMBLANT","code":"CA-QC-MBL"},
{"name":"MONT-CARMEL","code":"CA-QC-NDM"},
{"name":"MONT-LAURIER","code":"CA-QC-MLA"},
{"name":"MONT-LOUIS","code":"CA-QC-MOL"},
{"name":"MONT-ROYAL","code":"CA-QC-MRL"},
{"name":"MONT-SAINT-GREGOIRE","code":"CA-QC-MSG"},
{"name":"MONT-SAINT-HILAIRE","code":"CA-QC-MSH"},
{"name":"MONTEBELLO","code":"CA-QC-MBO"},
{"name":"MONTMAGNY","code":"CA-QC-MGN"},
{"name":"MONTREAL","code":"CA-QC-MTR"},
{"name":"MONTREAL-DORVAL APT","code":"CA-QC-YUL"},
{"name":"MONTRÉAL-EST","code":"CA-QC-MRE"},
{"name":"MONTRÉAL-NORD","code":"CA-QC-MRN"},
{"name":"MONTREAL-OUEST","code":"CA-QC-8MT"},
{"name":"MORIN HEIGHTS","code":"CA-QC-MHT"},
{"name":"MURDOCHVILLE","code":"CA-QC-MVE"},
{"name":"MURRAY BAY","code":"CA-QC-YML"},
{"name":"NAPIERVILLE","code":"CA-QC-NAP"},
{"name":"NATASHQUAN","code":"CA-QC-YNA"},
{"name":"NÉMISCAU","code":"CA-QC-YNS"},
{"name":"NEUVILLE","code":"CA-QC-NVE"},
{"name":"NEW RICHMOND","code":"CA-QC-NRC"},
{"name":"NEWPORT","code":"CA-QC-N8Q"},
{"name":"NICOLET","code":"CA-QC-NLT"},
{"name":"NITCHEQUON","code":"CA-QC-YNI"},
{"name":"NORANDA","code":"CA-QC-NRN"},
{"name":"NORMANDIN","code":"CA-QC-NMN"},
{"name":"NOTRE-DAME-DE-L'ÎLE-PERROT","code":"CA-QC-NPT"},
{"name":"NOTRE-DAME-DE-LA-PAIX","code":"CA-QC-NDP"},
{"name":"NOTRE-DAME-DES-PINS","code":"CA-QC-NDS"},
{"name":"NOTRE-DAME-DES-PRAIRIES","code":"CA-QC-NPS"},
{"name":"NOTRE-DAME-DU-BON-CONSEIL","code":"CA-QC-NBC"},
{"name":"NOTRE-DAME-DU-MONT-CARMEL","code":"CA-QC-NND"},
{"name":"NOYAN","code":"CA-QC-NYN"},
{"name":"OKA","code":"CA-QC-OKA"},
{"name":"OLD FORT BAY","code":"CA-QC-ZFB"},
{"name":"ORFORD","code":"CA-QC-ORF"},
{"name":"ORMSTOWN","code":"CA-QC-ORM"},
{"name":"OUTREMONT","code":"CA-QC-OTM"},
{"name":"PAKUASHIPI","code":"CA-QC-YIF"},
{"name":"PALMAROLLE","code":"CA-QC-PLM"},
{"name":"PAPINEAUVILLE","code":"CA-QC-PPV"},
{"name":"PARISVILLE","code":"CA-QC-PAV"},
{"name":"PASPEBIAC","code":"CA-QC-PAS"},
{"name":"PERCÉ","code":"CA-QC-ERC"},
{"name":"PÉRIBONKA","code":"CA-QC-PBK"},
{"name":"PETITE-RIVIERE-SAINT-FRANCOIS","code":"CA-QC-PRF"},
{"name":"PHILIPSBURG","code":"CA-QC-PHL"},
{"name":"PIERREFONDS","code":"CA-QC-PIE"},
{"name":"PIERREVILLE","code":"CA-QC-PRV"},
{"name":"PINCOURT","code":"CA-QC-PIO"},
{"name":"PINTENDRE","code":"CA-QC-PIN"},
{"name":"PLAISANCE","code":"CA-QC-PLS"},
{"name":"PLESSISVILLE","code":"CA-QC-PLE"},
{"name":"POHÉNÉGAMOOK","code":"CA-QC-PHM"},
{"name":"POINTE AU PIC","code":"CA-QC-PPC"},
{"name":"POINTE NOIRE","code":"CA-QC-PNO"},
{"name":"POINTE-AU-PIC","code":"CA-QC-POP"},
{"name":"POINTE-AUX-BASQUES","code":"CA-QC-PAQ"},
{"name":"POINTE-AUX-OUTARDES","code":"CA-QC-AUX"},
{"name":"POINTE-AUX-TREMBLES","code":"CA-QC-PXT"},
{"name":"POINTE-CALUMET","code":"CA-QC-PNC"},
{"name":"POINTE-CLAIRE","code":"CA-QC-POI"},
{"name":"POINTE-FORTUNE","code":"CA-QC-PFT"},
{"name":"PONT-ROUGE","code":"CA-QC-PTQ"},
{"name":"PORT-ALFRED","code":"CA-QC-PAF"},
{"name":"PORT-CARTIER","code":"CA-QC-PCA"},
{"name":"PORT-DANIEL","code":"CA-QC-PDL"},
{"name":"PORT-DANIEL - GASCONS","code":"CA-QC-GPD"},
{"name":"PORT-MENIER","code":"CA-QC-PME"},
{"name":"PORTAGE-DU-FORT","code":"CA-QC-PGF"},
{"name":"PORTNEUF","code":"CA-QC-PTN"},
{"name":"POSTE MONTAGNAIS","code":"CA-QC-PMG"},
{"name":"POULARIES","code":"CA-QC-POE"},
{"name":"PREISSAC","code":"CA-QC-CQ5"},
{"name":"PRÉVOST","code":"CA-QC-VOS"},
{"name":"PRINCEVILLE","code":"CA-QC-PCE"},
{"name":"QUAQTAQ","code":"CA-QC-YQC"},
{"name":"QUEBEC","code":"CA-QC-QUE"},
{"name":"QUEBEC APT","code":"CA-QC-YQB"},
{"name":"QUYON","code":"CA-QC-QUY"},
{"name":"RADISSON","code":"CA-QC-RAD"},
{"name":"RAWDON","code":"CA-QC-RWN"},
{"name":"REPENTIGNY","code":"CA-QC-REP"},
{"name":"RICHELIEU","code":"CA-QC-RIC"},
{"name":"RICHMOND","code":"CA-QC-RCH"},
{"name":"RIGAUD","code":"CA-QC-RGD"},
{"name":"RIMOUSKI","code":"CA-QC-RIM"},
{"name":"RIPON","code":"CA-QC-RPN"},
{"name":"RIVIERE ROUGE","code":"CA-QC-RRG"},
{"name":"RIVIÈRE-AU-TONNERRE","code":"CA-QC-YTN"},
{"name":"RIVIÈRE-BEAUDETTE","code":"CA-QC-RBD"},
{"name":"RIVIÈRE-DU-LOUP","code":"CA-QC-RDL"},
{"name":"RIVIÈRE-HÉVA","code":"CA-QC-RHA"},
{"name":"RIVIÈRE-MATAWIN","code":"CA-QC-RRM"},
{"name":"RIVIERE-OUELLE","code":"CA-QC-6QC"},
{"name":"ROBERTSONVILLE","code":"CA-QC-RSV"},
{"name":"ROBERVAL","code":"CA-QC-YRJ"},
{"name":"ROCK FOREST","code":"CA-QC-ROF"},
{"name":"ROSEMÈRE","code":"CA-QC-ROS"},
{"name":"ROUGEMONT","code":"CA-QC-ROU"},
{"name":"ROUYN-NORANDA","code":"CA-QC-YUY"},
{"name":"ROXBORO","code":"CA-QC-ROX"},
{"name":"ROXTON FALLS","code":"CA-QC-RXF"},
{"name":"ROXTON POND","code":"CA-QC-RXP"},
{"name":"SACRÉ-COEUR-SAGUENAY","code":"CA-QC-Q4C"},
{"name":"SAGUENAY","code":"CA-QC-SAG"},
{"name":"SAINT FREDERIC","code":"CA-QC-SF6"},
{"name":"SAINT HILARION","code":"CA-QC-SHQ"},
{"name":"SAINT JEROME","code":"CA-QC-SJM"},
{"name":"SAINT LIGUORI","code":"CA-QC-SGR"},
{"name":"SAINT PAULIN","code":"CA-QC-SA9"},
{"name":"SAINT TELESPHORE","code":"CA-QC-TPR"},
{"name":"SAINT-ADRIEN","code":"CA-QC-ADI"},
{"name":"SAINT-AGAPIT","code":"CA-QC-APT"},
{"name":"SAINT-AIMÉ","code":"CA-QC-SAI"},
{"name":"SAINT-AIMÉ-DES-LACS","code":"CA-QC-SAQ"},
{"name":"SAINT-ALBAN","code":"CA-QC-SB5"},
{"name":"SAINT-ALBERT","code":"CA-QC-SLR"},
{"name":"SAINT-ALEXIS-DE-MONTCALM","code":"CA-QC-AXM"},
{"name":"SAINT-AMABLE","code":"CA-QC-MBA"},
{"name":"SAINT-AMBROISE","code":"CA-QC-AMB"},
{"name":"SAINT-AMBROISE-DE-KILDARE","code":"CA-QC-AQC"},
{"name":"SAINT-ANACLET","code":"CA-QC-ANA"},
{"name":"SAINT-ANDRÉ-AVELLIN","code":"CA-QC-SAA"},
{"name":"SAINT-ANDRÉ-D'ARGENTEUIL","code":"CA-QC-SNG"},
{"name":"SAINT-ANDRÉ-DE-KAMOURASKA","code":"CA-QC-SKA"},
{"name":"SAINT-ANDRÉ-EST","code":"CA-QC-SAE"},
{"name":"SAINT-ANICET","code":"CA-QC-AIT"},
{"name":"SAINT-ANSELME","code":"CA-QC-SIE"},
{"name":"SAINT-ANTOINE-DE-TILLY","code":"CA-QC-SAO"},
{"name":"SAINT-ANTOINE-DES-LAURENTIDES","code":"CA-QC-STL"},
{"name":"SAINT-ANTOINE-SUR-RICHELIEU","code":"CA-QC-SRU"},
{"name":"SAINT-APOLLINAIRE","code":"CA-QC-SAP"},
{"name":"SAINT-ARMAND","code":"CA-QC-STD"},
{"name":"SAINT-ARSÈNE","code":"CA-QC-AES"},
{"name":"SAINT-AUGUSTIN","code":"CA-QC-SAU"},
{"name":"SAINT-AUGUSTIN-DE-DESMAURES","code":"CA-QC-SAN"},
{"name":"SAINT-AUGUSTIN-DE-WOBURN","code":"CA-QC-AWB"},
{"name":"SAINT-AUGUSTIN-DEUX-MONTAGNES","code":"CA-QC-ADM"},
{"name":"SAINT-BARNABÉ-SUD","code":"CA-QC-BBS"},
{"name":"SAINT-BARTHELEMY","code":"CA-QC-SBM"},
{"name":"SAINT-BASILE","code":"CA-QC-SBA"},
{"name":"SAINT-BASILE-LE-GRAND","code":"CA-QC-GBA"},
{"name":"SAINT-BENOÎT-LABRE","code":"CA-QC-SBL"},
{"name":"SAINT-BERNARD","code":"CA-QC-SBD"},
{"name":"SAINT-BERNARD-DE-LACOLLE","code":"CA-QC-BDL"},
{"name":"SAINT-BERNARD-DE-MICHAUDVILLE","code":"CA-QC-QCS"},
{"name":"SAINT-BONAVENTURE","code":"CA-QC-TIA"},
{"name":"SAINT-BONIFACE","code":"CA-QC-SNF"},
{"name":"SAINT-BRUNO","code":"CA-QC-TIO"},
{"name":"SAINT-BRUNO-DE-GUIGUES","code":"CA-QC-SIB"},
{"name":"SAINT-BRUNO-DE-MONTARVILLE","code":"CA-QC-SBO"},
{"name":"SAINT-BRUNO-DU-LAC-SAINT-JEAN","code":"CA-QC-BLJ"},
{"name":"SAINT-CALIXTE","code":"CA-QC-CAX"},
{"name":"SAINT-CAMILLE-DE-BELLECHASSE","code":"CA-QC-SBC"},
{"name":"SAINT-CASIMIR","code":"CA-QC-SC3"},
{"name":"SAINT-CÉLESTIN","code":"CA-QC-CTN"},
{"name":"SAINT-CÉSAIRE","code":"CA-QC-SCS"},
{"name":"SAINT-CHARLES-BORROMÉE","code":"CA-QC-SNT"},
{"name":"SAINT-CHARLES-DE-BELLECHASSE","code":"CA-QC-SDC"},
{"name":"SAINT-CHARLES-SUR-RICHELIEU","code":"CA-QC-ZCR"},
{"name":"SAINT-CHRISTOPHE-D'ARTHABASKA","code":"CA-QC-SCP"},
{"name":"SAINT-CHRYSOSTOME","code":"CA-QC-SCH"},
{"name":"SAINT-CLÉMENT","code":"CA-QC-SIN"},
{"name":"SAINT-CLEOPHAS","code":"CA-QC-SOP"},
{"name":"SAINT-CLET","code":"CA-QC-SXC"},
{"name":"SAINT-CLOTHILDE","code":"CA-QC-SC2"},
{"name":"SAINT-COLOMBAN","code":"CA-QC-COS"},
{"name":"SAINT-CÔME","code":"CA-QC-SCM"},
{"name":"SAINT-CÔME-LINIÈRE","code":"CA-QC-SCI"},
{"name":"SAINT-CONSTANT","code":"CA-QC-STX"},
{"name":"SAINT-CUTHBERT","code":"CA-QC-CBT"},
{"name":"SAINT-CYRILLE","code":"CA-QC-CYR"},
{"name":"SAINT-CYRILLE-DE-WENDOVER","code":"CA-QC-CYW"},
{"name":"SAINT-DAMASE","code":"CA-QC-SDA"},
{"name":"SAINT-DAMIEN-DE-BRANDON","code":"CA-QC-SDN"},
{"name":"SAINT-DAMIEN-DE-BUCKLAND","code":"CA-QC-SDB"},
{"name":"SAINT-DAVID-DE-FALARDEAU","code":"CA-QC-DF8"},
{"name":"SAINT-DENIS-DE-BROMPTON","code":"CA-QC-DDB"},
{"name":"SAINT-DENIS-SUR-RICHELIEU","code":"CA-QC-DNR"},
{"name":"SAINT-DOMINIQUE","code":"CA-QC-SDQ"},
{"name":"SAINT-EDMOND-DE-GRANTHAM","code":"CA-QC-SQG"},
{"name":"SAINT-EDMOND-LES-PLAINES","code":"CA-QC-SMP"},
{"name":"SAINT-ÉDOUARD-DE-FABRE","code":"CA-QC-DEF"},
{"name":"SAINT-ÉDOUARD-DE-LOTBINIÈRE","code":"CA-QC-SEO"},
{"name":"SAINT-ÉLIE-DE-CAXTON","code":"CA-QC-ELC"},
{"name":"SAINT-ÉLOI","code":"CA-QC-SOI"},
{"name":"SAINT-ELZÉAR/LA-NOUVELLE-BEAU","code":"CA-QC-SEZ"},
{"name":"SAINT-EPHREM-DE-BEAUCE","code":"CA-QC-SEP"},
{"name":"SAINT-ESPRIT","code":"CA-QC-SPT"},
{"name":"SAINT-ÉTIENNE-DES-GRES","code":"CA-QC-QSS"},
{"name":"SAINT-EUGÈNE-DE-GRANTHAM","code":"CA-QC-EDD"},
{"name":"SAINT-EUSTACHE","code":"CA-QC-SEU"},
{"name":"SAINT-ÉVARISTE-DE-FORSYTH","code":"CA-QC-SEF"},
{"name":"SAINT-FAUSTIN","code":"CA-QC-FLC"},
{"name":"SAINT-FÉLICIEN","code":"CA-QC-SFE"},
{"name":"SAINT-FÉLIX-DE-KINGSEY","code":"CA-QC-ST3"},
{"name":"SAINT-FÉLIX-DE-VALOIS","code":"CA-QC-SFX"},
{"name":"SAINT-FERRÉOL-LES-NEIGES","code":"CA-QC-SFN"},
{"name":"SAINT-FRANÇOIS","code":"CA-QC-QSF"},
{"name":"SAINT-FRANÇOIS-DE-LA-RIVIÈRE-DU-SUD","code":"CA-QC-FRS"},
{"name":"SAINT-FRANÇOIS-DU-LAC","code":"CA-QC-SFR"},
{"name":"SAINT-FRANÇOIS-MONTMAGNY","code":"CA-QC-SFY"},
{"name":"SAINT-FRANÇOIS-XAVIER-DE-BROMPTON","code":"CA-QC-FXB"},
{"name":"SAINT-FRÉDÉRIC","code":"CA-QC-SFD"},
{"name":"SAINT-GABRIEL","code":"CA-QC-SGA"},
{"name":"SAINT-GABRIEL-DE-BRANDON","code":"CA-QC-SGD"},
{"name":"SAINT-GÉDÉON","code":"CA-QC-W4C"},
{"name":"SAINT-GEORGES","code":"CA-QC-SGG"},
{"name":"SAINT-GEORGES/BEAUCE-SARTIGAN","code":"CA-QC-SGB"},
{"name":"SAINT-GERMAIN-DE-GRANTHAM","code":"CA-QC-SGN"},
{"name":"SAINT-GERMAIN-DE-KAMOURASKA","code":"CA-QC-SGK"},
{"name":"SAINT-GERVAIS","code":"CA-QC-SGQ"},
{"name":"SAINT-GILLES","code":"CA-QC-SGI"},
{"name":"SAINT-GUILLAUME","code":"CA-QC-GUU"},
{"name":"SAINT-HENRI","code":"CA-QC-SHN"},
{"name":"SAINT-HERMÉNÉGILDE","code":"CA-QC-SHG"},
{"name":"SAINT-HILAIRE","code":"CA-QC-SHI"},
{"name":"SAINT-HIPPOLYTE","code":"CA-QC-SHO"},
{"name":"SAINT-HONORÉ-DE-CHICOUTIMI","code":"CA-QC-SHC"},
{"name":"SAINT-HONORÉ-DE-SHENLEY","code":"CA-QC-SHS"},
{"name":"SAINT-HUBERT","code":"CA-QC-SHU"},
{"name":"SAINT-HUGUES","code":"CA-QC-HUG"},
{"name":"SAINT-HYACINTHE","code":"CA-QC-SHY"},
{"name":"SAINT-IGNACE-DE-STANBRIDGE","code":"CA-QC-IGN"},
{"name":"SAINT-ISIDORE D'AUCKLAND","code":"CA-QC-XZC"},
{"name":"SAINT-JACQUES","code":"CA-QC-SCQ"},
{"name":"SAINT-JACQUES-LE-MINEUR","code":"CA-QC-SJQ"},
{"name":"SAINT-JANVIER","code":"CA-QC-SJV"},
{"name":"SAINT-JEAN","code":"CA-QC-YJN"},
{"name":"SAINT-JEAN-BAPTISTE","code":"CA-QC-BAZ"},
{"name":"SAINT-JEAN-CHRYSOSTOME","code":"CA-QC-SJC"},
{"name":"SAINT-JEAN-DE-DIEU","code":"CA-QC-JDD"},
{"name":"SAINT-JEAN-DE-L'ÎLE-D'ORLÉANS","code":"CA-QC-SDO"},
{"name":"SAINT-JEAN-PORT-JOLI","code":"CA-QC-JPJ"},
{"name":"SAINT-JEAN-SUR-RICHELIEU","code":"CA-QC-SJR"},
{"name":"SAINT-JEANILE-D'ORLEANS","code":"CA-QC-SJD"},
{"name":"SAINT-JÉRÔME","code":"CA-QC-SJE"},
{"name":"SAINT-JOACHIM-DE-COURVAL","code":"CA-QC-JCV"},
{"name":"SAINT-JOACHIM-DE-MONTMORENCY","code":"CA-QC-JMY"},
{"name":"SAINT-JOACHIM-DE-SHEFFORD","code":"CA-QC-SMO"},
{"name":"SAINT-JOSEPH-DE-BEAUCE","code":"CA-QC-XJH"},
{"name":"SAINT-JOSEPH-DE-COLERAINE","code":"CA-QC-SJP"},
{"name":"SAINT-JOSEPH-DE-SOREL","code":"CA-QC-JDS"},
{"name":"SAINT-JOSEPH-DU-LAC","code":"CA-QC-SJL"},
{"name":"SAINT-JOVITE","code":"CA-QC-JVT"},
{"name":"SAINT-JULES","code":"CA-QC-XTJ"},
{"name":"SAINT-JUSTIN","code":"CA-QC-SJT"},
{"name":"SAINT-LAMBERT","code":"CA-QC-SLT"},
{"name":"SAINT-LAMBERT-DE-LAUZON","code":"CA-QC-LTL"},
{"name":"SAINT-LAURENT","code":"CA-QC-SLA"},
{"name":"SAINT-LAURENT-DE-L'ÎLE-D'ORLÉANS","code":"CA-QC-SLO"},
{"name":"SAINT-LAZARE-DE-BELLECHASSE","code":"CA-QC-LZB"},
{"name":"SAINT-LAZARE-DE-BELLECHASSE","code":"CA-QC-ZLB"},
{"name":"SAINT-LAZARE-DE-VAUDREUIL","code":"CA-QC-SLZ"},
{"name":"SAINT-LÉONARD","code":"CA-QC-SLE"},
{"name":"SAINT-LÉONARD-D'ASTON","code":"CA-QC-ERO"},
{"name":"SAINT-LIBOIRE","code":"CA-QC-SLI"},
{"name":"SAINT-LOUIS-DE-BLANDFORD","code":"CA-QC-QEB"},
{"name":"SAINT-LOUIS-DE-GONZAGUE","code":"CA-QC-SGU"},
{"name":"SAINT-LUC","code":"CA-QC-SLU"},
{"name":"SAINT-LUC-DE-BELLECHASSE","code":"CA-QC-SUH"},
{"name":"SAINT-LUCIEN","code":"CA-QC-SLC"},
{"name":"SAINT-LUDGER","code":"CA-QC-SDU"},
{"name":"SAINT-MAJORIQUE-DE-GRANTHAM","code":"CA-QC-SMJ"},
{"name":"SAINT-MALO","code":"CA-QC-SM6"},
{"name":"SAINT-MARC-DES-CARRIÈRES","code":"CA-QC-SMC"},
{"name":"SAINT-MARC-DU-LAC-LONG","code":"CA-QC-MDG"},
{"name":"SAINT-MARC-SUR-RICHELIEU","code":"CA-QC-ARE"},
{"name":"SAINT-MATHIAS-SUR-RICHELIEU","code":"CA-QC-SMR"},
{"name":"SAINT-MATHIEU","code":"CA-QC-SM7"},
{"name":"SAINT-MATHIEU-D'HARRICANA","code":"CA-QC-SMH"},
{"name":"SAINT-MATHIEU-DE-BELOEIL","code":"CA-QC-SMD"},
{"name":"SAINT-MATHIEU-DU-PARC","code":"CA-QC-MHP"},
{"name":"SAINT-MICHEL","code":"CA-QC-SNM"},
{"name":"SAINT-MICHEL DE NAPIERVILLE","code":"CA-QC-SMX"},
{"name":"SAINT-MICHEL-DE-BELLECHASSE","code":"CA-QC-SLH"},
{"name":"SAINT-MICHEL-DES-SAINTS","code":"CA-QC-SMQ"},
{"name":"SAINT-MODESTE","code":"CA-QC-SM2"},
{"name":"SAINT-NARCISSE","code":"CA-QC-INT"},
{"name":"SAINT-NARCISSE-DE-BEAURIVAGE","code":"CA-QC-SBG"},
{"name":"SAINT-NAZAIRE","code":"CA-QC-SNZ"},
{"name":"SAINT-NICÉPHORE","code":"CA-QC-NCP"},
{"name":"SAINT-NICOLAS","code":"CA-QC-NOJ"},
{"name":"SAINT-NORBERT","code":"CA-QC-SNB"},
{"name":"SAINT-OMER","code":"CA-QC-ST5"},
{"name":"SAINT-PACÔME","code":"CA-QC-SPC"},
{"name":"SAINT-PAMPHILE","code":"CA-QC-SPM"},
{"name":"SAINT-PASCAL","code":"CA-QC-SPA"},
{"name":"SAINT-PATRICE-DE-BEAURIVAGE","code":"CA-QC-PBG"},
{"name":"SAINT-PAUL","code":"CA-QC-ZSP"},
{"name":"SAINT-PAUL-D'ABBOTSFORD","code":"CA-QC-SPB"},
{"name":"SAINT-PAUL-DE-L'ÎLE-AUX-NOIX","code":"CA-QC-SPX"},
{"name":"SAINT-PAUL-DE-LA-CROIX","code":"CA-QC-SPQ"},
{"name":"SAINT-PERPETUE","code":"CA-QC-SPP"},
{"name":"SAINT-PHILIPPE","code":"CA-QC-CIP"},
{"name":"SAINT-PIE","code":"CA-QC-STI"},
{"name":"SAINT-PIERRE","code":"CA-QC-SPI"},
{"name":"SAINT-PIERRE-D'ORLEANS","code":"CA-QC-SPS"},
{"name":"SAINT-PIERRE-DE-L'ÎLE-D'ORLÉANS","code":"CA-QC-PDO"},
{"name":"SAINT-PIERRE-LES-BECQUETS","code":"CA-QC-PBS"},
{"name":"SAINT-PLACIDE","code":"CA-QC-PLI"},
{"name":"SAINT-PLACIDE-DE-BÉARN","code":"CA-QC-P6B"},
{"name":"SAINT-POLYCARPE","code":"CA-QC-SPY"},
{"name":"SAINT-PRIME","code":"CA-QC-STP"},
{"name":"SAINT-PROSPER","code":"CA-QC-RRR"},
{"name":"SAINT-RAYMOND","code":"CA-QC-SRD"},
{"name":"SAINT-RÉDEMPTEUR","code":"CA-QC-SRP"},
{"name":"SAINT-RÉMI","code":"CA-QC-SRM"},
{"name":"SAINT-ROBERT-BELLARMIN","code":"CA-QC-SRB"},
{"name":"SAINT-ROCH","code":"CA-QC-SRH"},
{"name":"SAINT-ROCH-DE-L'ACHIGAN","code":"CA-QC-SRA"},
{"name":"SAINT-ROMUALD-D'ETCHEMIN","code":"CA-QC-SNR"},
{"name":"SAINT-ROMUALD-D'ETCHEMIN","code":"CA-QC-SRO"},
{"name":"SAINT-SAMUEL","code":"CA-QC-SSU"},
{"name":"SAINT-SAUVEUR-DES-MONTS","code":"CA-QC-SSA"},
{"name":"SAINT-SÉBASTIEN","code":"CA-QC-SBI"},
{"name":"SAINT-SÉBASTIEN","code":"CA-QC-SFC"},
{"name":"SAINT-SÉBASTIEN/LE-HAUT-RICHELIEU","code":"CA-QC-SSL"},
{"name":"SAINT-SÉVERIN","code":"CA-QC-SSC"},
{"name":"SAINT-SIMÉON","code":"CA-QC-SSE"},
{"name":"SAINT-SIMÉON-DE-BONAVENTURE","code":"CA-QC-SBV"},
{"name":"SAINT-SIMON-DE-BAGOT","code":"CA-QC-SSB"},
{"name":"SAINT-STANISLAS","code":"CA-QC-SSS"},
{"name":"SAINT-STANISLAS-DE-KOSTKA","code":"CA-QC-TKS"},
{"name":"SAINT-SYLVERE","code":"CA-QC-SSV"},
{"name":"SAINT-SYLVESTRE","code":"CA-QC-TYV"},
{"name":"SAINT-THARCISIUS","code":"CA-QC-QCT"},
{"name":"SAINT-THEOPHILE","code":"CA-QC-SNE"},
{"name":"SAINT-THOMAS","code":"CA-QC-SIT"},
{"name":"SAINT-THOMAS-DIDYME","code":"CA-QC-SDH"},
{"name":"SAINT-TIMOTHÉE","code":"CA-QC-STO"},
{"name":"SAINT-TITE","code":"CA-QC-ZTI"},
{"name":"SAINT-UBALD","code":"CA-QC-SUB"},
{"name":"SAINT-URBAIN","code":"CA-QC-SRX"},
{"name":"SAINT-URBAIN-PREMIER","code":"CA-QC-SUP"},
{"name":"SAINT-VALÈRE","code":"CA-QC-SVR"},
{"name":"SAINT-VALÉRIEN","code":"CA-QC-SVQ"},
{"name":"SAINT-VALLIER","code":"CA-QC-SVA"},
{"name":"SAINT-VICTOR","code":"CA-QC-QSV"},
{"name":"SAINT-VINCENT-DE-PAUL","code":"CA-QC-SVP"},
{"name":"SAINT-WENCESLAS","code":"CA-QC-SWC"},
{"name":"SAINT-ZÉNON","code":"CA-QC-SZZ"},
{"name":"SAINT-ZÉPHIRIN-DE-COURVAL","code":"CA-QC-SZC"},
{"name":"SAINT-ZOTIQUE","code":"CA-QC-SZT"},
{"name":"SAINTE-ADÈLE","code":"CA-QC-SDE"},
{"name":"SAINTE-AGATHE-DES-MONTS","code":"CA-QC-SAM"},
{"name":"SAINTE-ANGELE-DE-MERICI","code":"CA-QC-AMR"},
{"name":"SAINTE-ANNE-DE-BELLEVUE","code":"CA-QC-SAB"},
{"name":"SAINTE-ANNE-DE-LA-PERADE","code":"CA-QC-ADL"},
{"name":"SAINTE-ANNE-DE-LA-ROCHELLE","code":"CA-QC-ADR"},
{"name":"SAINTE-ANNE-DES-MONTS","code":"CA-QC-SDM"},
{"name":"SAINTE-ANNE-DES-PLAINES","code":"CA-QC-ADP"},
{"name":"SAINTE-BARBE","code":"CA-QC-S8B"},
{"name":"SAINTE-BEATRIX","code":"CA-QC-SBX"},
{"name":"SAINTE-BRIGITTE-DES-SAULTS","code":"CA-QC-SBS"},
{"name":"SAINTE-CATHERINE","code":"CA-QC-SCT"},
{"name":"SAINTE-CATHERINE-DE-LA-JACQUES-CARTIER","code":"CA-QC-CJC"},
{"name":"SAINTE-CECILE-DE-MILTON","code":"CA-QC-CCM"},
{"name":"SAINTE-CLAIRE","code":"CA-QC-SCL"},
{"name":"SAINTE-CLOTHILDE","code":"CA-QC-SCO"},
{"name":"SAINTE-CLOTILDE-DE-BEAUCE","code":"CA-QC-SCD"},
{"name":"SAINTE-CLOTILDE-DE-CHÂTEAUGUAY","code":"CA-QC-CCG"},
{"name":"SAINTE-CROIX","code":"CA-QC-SCX"},
{"name":"SAINTE-EDWIDGE-DE-CLIFTON","code":"CA-QC-EDW"},
{"name":"SAINTE-ELISABETH","code":"CA-QC-SET"},
{"name":"SAINTE-EMELIE-DE-L'ENERGIE","code":"CA-QC-SEG"},
{"name":"SAINTE-EULALIE","code":"CA-QC-SNL"},
{"name":"SAINTE-FAMILLE","code":"CA-QC-SFI"},
{"name":"SAINTE-FLORENCE","code":"CA-QC-SFF"},
{"name":"SAINTE-FOY","code":"CA-QC-SFO"},
{"name":"SAINTE-GENEVIÈVE","code":"CA-QC-TEE"},
{"name":"SAINTE-HÉLÈNE-DE-BAGOT","code":"CA-QC-HNB"},
{"name":"SAINTE-HÉNÉDINE","code":"CA-QC-HNN"},
{"name":"SAINTE-JULIE","code":"CA-QC-STJ"},
{"name":"SAINTE-JULIENNE","code":"CA-QC-SJU"},
{"name":"SAINTE-JUSTINE","code":"CA-QC-SNJ"},
{"name":"SAINTE-JUSTINE-DE-NEWTON","code":"CA-QC-SJN"},
{"name":"SAINTE-MADELEINE","code":"CA-QC-SMA"},
{"name":"SAINTE-MARGUERITE","code":"CA-QC-MGT"},
{"name":"SAINTE-MARIE","code":"CA-QC-SMB"},
{"name":"SAINTE-MARTHE-SUR-LE-LAC","code":"CA-QC-SML"},
{"name":"SAINTE-MARTINE","code":"CA-QC-STM"},
{"name":"SAINTE-MONIQUE","code":"CA-QC-MOC"},
{"name":"SAINTE-ROSALIE","code":"CA-QC-SRE"},
{"name":"SAINTE-ROSE","code":"CA-QC-RRQ"},
{"name":"SAINTE-SABINE","code":"CA-QC-SIS"},
{"name":"SAINTE-SOPHIE","code":"CA-QC-SSH"},
{"name":"SAINTE-THÉRÈSE","code":"CA-QC-STE"},
{"name":"SAINTE-THÉRÈSE-DE-GASPÉ","code":"CA-QC-SGP"},
{"name":"SAINTE-VERONIQUE","code":"CA-QC-SVE"},
{"name":"SAINTE-VICTOIRE-DE-SOREL","code":"CA-QC-SVD"},
{"name":"SALABERRY-DE-VALLEYFIELD","code":"CA-QC-SVF"},
{"name":"SALLUIT","code":"CA-QC-YSW"},
{"name":"SANDY BEACH","code":"CA-QC-SBH"},
{"name":"SAWYERVILLE","code":"CA-QC-SYV"},
{"name":"SAYABEC","code":"CA-QC-SAY"},
{"name":"SCHEFFERVILLE","code":"CA-QC-YKL"},
{"name":"SCOTT","code":"CA-QC-SQT"},
{"name":"SENNETERRE","code":"CA-QC-SEN"},
{"name":"SENNEVILLE","code":"CA-QC-6SE"},
{"name":"SEPT-ILES","code":"CA-QC-SEI"},
{"name":"SHANNON","code":"CA-QC-NON"},
{"name":"SHAWINIGAN","code":"CA-QC-SHA"},
{"name":"SHEFFORD","code":"CA-QC-FFO"},
{"name":"SHERBROOKE","code":"CA-QC-SBR"},
{"name":"SHERRINGTON","code":"CA-QC-SHR"},
{"name":"SILLERY","code":"CA-QC-SYQ"},
{"name":"SOREL","code":"CA-QC-SOR"},
{"name":"SOREL-TRACY","code":"CA-QC-ST6"},
{"name":"STANBRIDGE STATION","code":"CA-QC-SSN"},
{"name":"STANSTEAD PLAIN","code":"CA-QC-SNP"},
{"name":"STOKE","code":"CA-QC-7TK"},
{"name":"STONEHAM","code":"CA-QC-SOA"},
{"name":"STRATFORD","code":"CA-QC-SRF"},
{"name":"STS-ANGES","code":"CA-QC-SIA"},
{"name":"SUTTON","code":"CA-QC-SUT"},
{"name":"TADOUSSAC","code":"CA-QC-TAD"},
{"name":"TASCHEREAU","code":"CA-QC-TCH"},
{"name":"TASIUJAQ","code":"CA-QC-YTQ"},
{"name":"TEMISCAMING","code":"CA-QC-TCG"},
{"name":"TÉMISCOUATA-SUR-LE-LAC","code":"CA-QC-TMC"},
{"name":"TERREBONNE","code":"CA-QC-TBN"},
{"name":"TÊTE-À-LA-BALEINE","code":"CA-QC-ZTB"},
{"name":"THETFORD MINES","code":"CA-QC-THE"},
{"name":"THURSO","code":"CA-QC-URS"},
{"name":"TINGWICK","code":"CA-QC-TNG"},
{"name":"TOWN MOUNT ROYAL","code":"CA-QC-TMR"},
{"name":"TRACY","code":"CA-QC-TRC"},
{"name":"TRÉCESSON","code":"CA-QC-SSO"},
{"name":"TRING JONCTION","code":"CA-QC-TJN"},
{"name":"TROIS-PISTOLES","code":"CA-QC-TRP"},
{"name":"TROIS-RIVIERES (THREE RIVERS)","code":"CA-QC-TRR"},
{"name":"TULUGAK","code":"CA-QC-YTK"},
{"name":"UPTON","code":"CA-QC-UPT"},
{"name":"VAL MORIN","code":"CA-QC-VAM"},
{"name":"VAL-ALAIN","code":"CA-QC-VAA"},
{"name":"VAL-BÉLAIR","code":"CA-QC-VBR"},
{"name":"VAL-D'OR","code":"CA-QC-YVO"},
{"name":"VAL-DAVID","code":"CA-QC-VAD"},
{"name":"VAL-DES-MONTS","code":"CA-QC-MDV"},
{"name":"VAL-MORIN","code":"CA-QC-VOM"},
{"name":"VALCARTIER","code":"CA-QC-YOY"},
{"name":"VALCOURT","code":"CA-QC-VCT"},
{"name":"VALLÉE-JONCTION","code":"CA-QC-VAL"},
{"name":"VALLEYFIELD","code":"CA-QC-VLF"},
{"name":"VANIER","code":"CA-QC-VAE"},
{"name":"VARENNES","code":"CA-QC-VAR"},
{"name":"VAUDREUIL-DORION","code":"CA-QC-DON"},
{"name":"VERCHÈRES","code":"CA-QC-VCH"},
{"name":"VERDUN","code":"CA-QC-DUN"},
{"name":"VICTORIAVILLE","code":"CA-QC-VVL"},
{"name":"VILLE-MARIE","code":"CA-QC-VIJ"},
{"name":"VILLEROY","code":"CA-QC-VLR"},
{"name":"VIMONT","code":"CA-QC-VMT"},
{"name":"WAKEFIELD","code":"CA-QC-WAK"},
{"name":"WARWICK","code":"CA-QC-WWK"},
{"name":"WASKAGANISH","code":"CA-QC-YKQ"},
{"name":"WATERLOO","code":"CA-QC-WAO"},
{"name":"WATERVILLE","code":"CA-QC-WVL"},
{"name":"WEEDON","code":"CA-QC-WDO"},
{"name":"WEMINDJI","code":"CA-QC-YNC"},
{"name":"WENDAKE","code":"CA-QC-WDK"},
{"name":"WESTMOUNT","code":"CA-QC-WNT"},
{"name":"WICKHAM WEST","code":"CA-QC-WIC"},
{"name":"WINDSOR","code":"CA-QC-WSR"},
{"name":"YAMACHICHE","code":"CA-QC-ZYE"},
        ]
      },
      {
        "name": "Saskatchewan",
        "code": "CA-SK",
        "cities": [
          {"name":"AIR RONGE","code":"CA-SK-ARO"},
{"name":"ALLAN","code":"CA-SK-ALN"},
{"name":"ARDATH","code":"CA-SK-ADH"},
{"name":"ASQUITH","code":"CA-SK-ASQ"},
{"name":"ASSINIBOIA","code":"CA-SK-ABO"},
{"name":"AVONLEA","code":"CA-SK-AVL"},
{"name":"AYLSHAM","code":"CA-SK-AYH"},
{"name":"BANGOR","code":"CA-SK-BNG"},
{"name":"BATTLEFORD","code":"CA-SK-BLF"},
{"name":"BEECHY","code":"CA-SK-BCY"},
{"name":"BELLE PLAINE","code":"CA-SK-BPL"},
{"name":"BETHUNE","code":"CA-SK-BTE"},
{"name":"BIENFAIT","code":"CA-SK-BNF"},
{"name":"BIGGAR","code":"CA-SK-BIR"},
{"name":"BORDEN","code":"CA-SK-BOD"},
{"name":"BROADVIEW","code":"CA-SK-YDR"},
{"name":"BRODERICK","code":"CA-SK-BDC"},
{"name":"BROWNLEE","code":"CA-SK-BNL"},
{"name":"BRUNO","code":"CA-SK-BRU"},
{"name":"BUCHANAN","code":"CA-SK-BUN"},
{"name":"BUFFALO NARROWS","code":"CA-SK-YVT"},
{"name":"BULYEA","code":"CA-SK-BYA"},
{"name":"CABRI","code":"CA-SK-CRI"},
{"name":"CANDIAC","code":"CA-SK-CAN"},
{"name":"CANORA","code":"CA-SK-CNA"},
{"name":"CARLYLE","code":"CA-SK-CS8"},
{"name":"CHAPLIN","code":"CA-SK-HPN"},
{"name":"CHURCHBRIDGE","code":"CA-SK-CBI"},
{"name":"CLAVET","code":"CA-SK-CLV"},
{"name":"CLIMAX","code":"CA-SK-CMX"},
{"name":"COLLINS BAY","code":"CA-SK-YKC"},
{"name":"COLONSAY","code":"CA-SK-COY"},
{"name":"CRAVEN","code":"CA-SK-CVN"},
{"name":"CREIGHTON","code":"CA-SK-CGT"},
{"name":"CUT KNIFE","code":"CA-SK-CKF"},
{"name":"DELISLE","code":"CA-SK-LIS"},
{"name":"DILKE","code":"CA-SK-DLK"},
{"name":"DUCK LAKE","code":"CA-SK-DKL"},
{"name":"DUNDURN","code":"CA-SK-DDN"},
{"name":"DUVAL","code":"CA-SK-DUA"},
{"name":"DYSART","code":"CA-SK-DYA"},
{"name":"EDAM","code":"CA-SK-EDA"},
{"name":"ELROSE","code":"CA-SK-OSE"},
{"name":"EMERALD PARK","code":"CA-SK-XVX"},
{"name":"ENGLEFELD","code":"CA-SK-EGF"},
{"name":"ESTERHAZY","code":"CA-SK-EHZ"},
{"name":"ESTERHAZY","code":"CA-SK-EZY"},
{"name":"ESTEVAN","code":"CA-SK-EST"},
{"name":"FILLMORE","code":"CA-SK-FMO"},
{"name":"FOAM LAKE","code":"CA-SK-OLK"},
{"name":"FOND DU LAC","code":"CA-SK-ZFD"},
{"name":"FORT QU'APPELLE","code":"CA-SK-FQP"},
{"name":"FOX VALLEY","code":"CA-SK-FXV"},
{"name":"FRANCIS","code":"CA-SK-FCS"},
{"name":"FRONTIER","code":"CA-SK-FRO"},
{"name":"GERALD","code":"CA-SK-GLD"},
{"name":"GLENBUSH","code":"CA-SK-GBU"},
{"name":"GRAVELBOURG","code":"CA-SK-GBG"},
{"name":"GRENFELL","code":"CA-SK-GNF"},
{"name":"GUERNSEY","code":"CA-SK-SKG"},
{"name":"GULL LAKE","code":"CA-SK-GLL"},
{"name":"HAGUE","code":"CA-SK-HAG"},
{"name":"HATCHET LAKE","code":"CA-SK-YDJ"},
{"name":"HEPBURN","code":"CA-SK-HP8"},
{"name":"HODGEVILLE","code":"CA-SK-HGV"},
{"name":"HUDSON BAY","code":"CA-SK-YHB"},
{"name":"HUMBOLDT","code":"CA-SK-HMO"},
{"name":"IMPERIAL","code":"CA-SK-SIM"},
{"name":"INDIAN HEAD","code":"CA-SK-IHD"},
{"name":"ITUNA","code":"CA-SK-ITA"},
{"name":"JANSEN","code":"CA-SK-JNS"},
{"name":"KAMSACK","code":"CA-SK-KSA"},
{"name":"KELVINGTON","code":"CA-SK-KEL"},
{"name":"KENASTON","code":"CA-SK-KSO"},
{"name":"KEY LAKE","code":"CA-SK-YKJ"},
{"name":"KINDERSLEY","code":"CA-SK-YKY"},
{"name":"KINISTINO","code":"CA-SK-KST"},
{"name":"KINOOSAO","code":"CA-SK-KNY"},
{"name":"KIPLING","code":"CA-SK-KPL"},
{"name":"KISBEY","code":"CA-SK-KBY"},
{"name":"LA RONGE","code":"CA-SK-YVC"},
{"name":"LAMPMAN","code":"CA-SK-LPM"},
{"name":"LANGBANK","code":"CA-SK-LAK"},
{"name":"LANGENBURG","code":"CA-SK-LGB"},
{"name":"LANGHAM","code":"CA-SK-LGA"},
{"name":"LANIGAN","code":"CA-SK-LNA"},
{"name":"LEADER","code":"CA-SK-LDR"},
{"name":"LEASK","code":"CA-SK-LSK"},
{"name":"LEROSS","code":"CA-SK-LRS"},
{"name":"LEROY","code":"CA-SK-LRO"},
{"name":"LLOYDMINSTER","code":"CA-SK-LMR"},
{"name":"LUCKY LAKE","code":"CA-SK-LCL"},
{"name":"MACKLIN","code":"CA-SK-MKL"},
{"name":"MACOUN","code":"CA-SK-MCO"},
{"name":"MAPLE CREEK","code":"CA-SK-MCR"},
{"name":"MARTENSVILLE","code":"CA-SK-MVS"},
{"name":"MAYMONT","code":"CA-SK-MYM"},
{"name":"MCLEAN","code":"CA-SK-MCN"},
{"name":"MEACHAM","code":"CA-SK-MHA"},
{"name":"MEADOW LAKE","code":"CA-SK-YLJ"},
{"name":"MELFORT","code":"CA-SK-MFO"},
{"name":"MELVILLE","code":"CA-SK-MEV"},
{"name":"MOOSE JAW","code":"CA-SK-YMJ"},
{"name":"MOOSOMIN","code":"CA-SK-MSM"},
{"name":"MORSE","code":"CA-SK-MSE"},
{"name":"NAICAM","code":"CA-SK-NCM"},
{"name":"NEILBURG","code":"CA-SK-NLB"},
{"name":"NEPTUNE","code":"CA-SK-NEP"},
{"name":"NEUDORF","code":"CA-SK-NDO"},
{"name":"NIPAWIN","code":"CA-SK-NPW"},
{"name":"NORLUAY","code":"CA-SK-NQA"},
{"name":"NORTH BATTLEFORD","code":"CA-SK-YQW"},
{"name":"NORTH PORTAL","code":"CA-SK-NPR"},
{"name":"ONION LAKE","code":"CA-SK-OLL"},
{"name":"OSLER","code":"CA-SK-OSL"},
{"name":"OUTLOOK","code":"CA-SK-OOK"},
{"name":"OXBOW","code":"CA-SK-OXB"},
{"name":"PADDOCKWOOD","code":"CA-SK-PWD"},
{"name":"PANGMAN","code":"CA-SK-PGM"},
{"name":"PERDUE","code":"CA-SK-PDU"},
{"name":"PILOT BUTTE","code":"CA-SK-PBE"},
{"name":"PINE HOUSE","code":"CA-SK-ZPO"},
{"name":"PORCUPINE PLAIN","code":"CA-SK-PPL"},
{"name":"PREECEVILLE","code":"CA-SK-PPR"},
{"name":"PRINCE ALBERT","code":"CA-SK-PRA"},
{"name":"QU'APPELLE","code":"CA-SK-QUA"},
{"name":"RADISSON","code":"CA-SK-RDO"},
{"name":"RADVILLE","code":"CA-SK-RDV"},
{"name":"RAMA","code":"CA-SK-RMA"},
{"name":"REDVERS","code":"CA-SK-RVS"},
{"name":"REGINA","code":"CA-SK-REG"},
{"name":"REGINA APT","code":"CA-SK-YQR"},
{"name":"RICETON","code":"CA-SK-RCT"},
{"name":"RICHARDSON","code":"CA-SK-RRO"},
{"name":"ROCANVILLE","code":"CA-SK-RVI"},
{"name":"ROSETOWN","code":"CA-SK-RSW"},
{"name":"ROSTHERN","code":"CA-SK-2KS"},
{"name":"ROULEAU","code":"CA-SK-RSA"},
{"name":"ROWATT","code":"CA-SK-RWT"},
{"name":"RUSH LAKE","code":"CA-SK-RHL"},
{"name":"SAINT-BRIEUX","code":"CA-SK-BIX"},
{"name":"SAINT-GREGOR","code":"CA-SK-SGO"},
{"name":"SANDY BAY","code":"CA-SK-SYA"},
{"name":"SASKATOON","code":"CA-SK-SAK"},
{"name":"SASKATOON JOHN G. DIEFENBAKER APT","code":"CA-SK-YXE"},
{"name":"SEDLEY","code":"CA-SK-SLY"},
{"name":"SHAUNAVON","code":"CA-SK-SNV"},
{"name":"SHELLBROOK","code":"CA-SK-SBK"},
{"name":"SOUTHEY","code":"CA-SK-SOY"},
{"name":"SPALDING","code":"CA-SK-SDG"},
{"name":"SPRINGSIDE","code":"CA-SK-SGS"},
{"name":"STAR CITY","code":"CA-SK-SRC"},
{"name":"STEELMAN","code":"CA-SK-SME"},
{"name":"STONY RAPIDS","code":"CA-SK-YSF"},
{"name":"STRASBOURG","code":"CA-SK-SBQ"},
{"name":"STRONGFIELD","code":"CA-SK-SFS"},
{"name":"SUCCESS","code":"CA-SK-SUC"},
{"name":"SWIFT CURRENT","code":"CA-SK-YYN"},
{"name":"TISDALE","code":"CA-SK-YTT"},
{"name":"TOMPKINS","code":"CA-SK-TMK"},
{"name":"UNITY","code":"CA-SK-UTY"},
{"name":"URANIUM CITY","code":"CA-SK-YBE"},
{"name":"VANSCOY","code":"CA-SK-VSC"},
{"name":"VEREGIN","code":"CA-SK-ERE"},
{"name":"VIBANK","code":"CA-SK-VBA"},
{"name":"VONDA","code":"CA-SK-VON"},
{"name":"WALDHEIM","code":"CA-SK-WLH"},
{"name":"WARMAN","code":"CA-SK-WMA"},
{"name":"WASECA","code":"CA-SK-WSA"},
{"name":"WATROUS","code":"CA-SK-WTS"},
{"name":"WATSON","code":"CA-SK-ASW"},
{"name":"WAWOTA","code":"CA-SK-WWA"},
{"name":"WEYBURN","code":"CA-SK-WWB"},
{"name":"WHITE CITY","code":"CA-SK-WCY"},
{"name":"WHITEWOOD","code":"CA-SK-WTW"},
{"name":"WILCOX","code":"CA-SK-WIX"},
{"name":"WOLLASTON LAKE","code":"CA-SK-ZWL"},
{"name":"WOLSELEY","code":"CA-SK-WSY"},
{"name":"WYNYARD","code":"CA-SK-WYN"},
{"name":"YORKTON","code":"CA-SK-YQV"},
{"name":"ZEHNER","code":"CA-SK-ZNR"},
{"name":"ZENON PARK","code":"CA-SK-ZPK"},
        ],
      },
      {
        "name": "Yukon",
        "code": "CA-YT",
        "cities": [
          {"name":"BEAVER CREEK","code":"CA-YT-YT4"},
{"name":"BURWASH LANDINGS","code":"CA-YT-YDB"},
{"name":"CLINTON CREEK","code":"CA-YT-YLM"},
{"name":"DAWSON CITY","code":"CA-YT-YDA"},
{"name":"FARO","code":"CA-YT-ZFA"},
{"name":"HAINES JUNCTION","code":"CA-YT-HJU"},
{"name":"HERSCHEL ISLAND","code":"CA-YT-HLI"},
{"name":"MAYO","code":"CA-YT-YMA"},
{"name":"OLD CROW","code":"CA-YT-YOC"},
{"name":"SNAKE RIVER","code":"CA-YT-YXF"},
{"name":"TAGISH","code":"CA-YT-TAH"},
{"name":"TESLIN","code":"CA-YT-YZW"},
{"name":"WATSON LAKE","code":"CA-YT-WAT"},
{"name":"WATSON LAKE APT","code":"CA-YT-YQH"},
{"name":"WHITEHORSE","code":"CA-YT-YXY"},
        ]
      }
    ]
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    dialCode: '+1-345',
    flag: 'flag_cayman-islands.png',
    states: [
      {
        name: 'Grand Cayman',
        code: 'KY-GC',
        cities: []
      }
    ]
  },
  {
    name: 'Chile',
    code: 'CL',
    dialCode: '+56',
    flag: 'flag_chile.png',
    states: [
      {
        name: 'Aisén',
        code: 'CL-AI',
        cities: [{"name":"ALTO PALENA APT","code":"CL-AI-WAP"},
          {"name":"BALMACEDA","code":"CL-AI-BBA"},
          {"name":"CHACABUCO","code":"CL-AI-CHB"},
          {"name":"CHILE CHICO","code":"CL-AI-CCH"},
          {"name":"COCHRANE","code":"CL-AI-COC"},
          {"name":"COYHAIQUE","code":"CL-AI-CXQ"},
          {"name":"GUAITECAS","code":"CL-AI-GUA"},
          {"name":"LAGO VERDE","code":"CL-AI-LVD"},
          {"name":"O'HIGGINS","code":"CL-AI-OHI"},
          {"name":"PASO COYHAIQUE/COYHAIQUE","code":"CL-AI-CIQ"},
          {"name":"PASO HUEMULES-BALMACEDA/COYHAIQUE","code":"CL-AI-HLS"},
          {"name":"PASO INGENIERO IBÁÑEZ PALLAVICINI/RÍO IBÁÑEZ","code":"CL-AI-INI"},
          {"name":"PASO LAGO O'HIGGINS/O'HIGGINS","code":"CL-AI-LOH"},
          {"name":"PASO PAMPA ALTA (PUESTO VIEJO)/COYHAIQUE","code":"CL-AI-PAM"},
          {"name":"PASO RÍO FRÍAS APPELEG/CISNES","code":"CL-AI-RFA"},
          {"name":"PASO RÍO JEINIMENI/CHILE CHICO","code":"CL-AI-RIJ"},
          {"name":"PASO RÍO MAYER/O'HIGGINS","code":"CL-AI-RIM"},
          {"name":"PASO RÍO MOSCO/O'HIGGINS","code":"CL-AI-RMO"},
          {"name":"PASO ROBALLOS/COCHRANE","code":"CL-AI-ROB"},
          {"name":"PASO TRIANA/COYHAIQUE","code":"CL-AI-TRI"},
          {"name":"PUERTO AISÉN","code":"CL-AI-WPA"},
          {"name":"PUERTO AYSEN","code":"CL-AI-PSN"},
          {"name":"PUERTO CHACABUCO","code":"CL-AI-PCH"},
          {"name":"PUERTO CISNES","code":"CL-AI-CIS"},
          {"name":"RÍO IBÁÑEZ","code":"CL-AI-RIB"},
          {"name":"TORTEL","code":"CL-AI-TOR"},]
      },
      {
        name: 'Antofagasta',
        code: 'CL-AN',
        cities: [{"name":"ANTOFAGASTA","code":"CL-AN-ANF"},
          {"name":"BAQUEDANO","code":"CL-AN-BAQ"},
          {"name":"CALAMA","code":"CL-AN-CJC"},
          {"name":"CALETA COLOSO","code":"CL-AN-COL"},
          {"name":"CHUQUICAMATA","code":"CL-AN-CHU"},
          {"name":"GATICO","code":"CL-AN-GIO"},
          {"name":"LA CHIMBA","code":"CL-AN-LAC"},
          {"name":"LA NEGRA","code":"CL-AN-LNE"},
          {"name":"LOS VIENTOS","code":"CL-AN-LVT"},
          {"name":"MARÍA ELENA","code":"CL-AN-MAE"},
          {"name":"MEJILLONES","code":"CL-AN-MJS"},
          {"name":"MICHILLA","code":"CL-AN-MIC"},
          {"name":"OLLAGÜE","code":"CL-AN-OLL"},
          {"name":"PASO HITO CAJÓN/SAN PEDRO DE ATACAMA","code":"CL-AN-HIT"},
          {"name":"PASO JAMA/SAN PEDRO DE ATACAMA","code":"CL-AN-JAM"},
          {"name":"PASO OLLAGÜE/OLLAGÜE","code":"CL-AN-OLA"},
          {"name":"PASO SICO/SAN PEDRO DE ATACAMA","code":"CL-AN-SIC"},
          {"name":"PASO SOCOMPA/ANTOFAGASTA","code":"CL-AN-SOC"},
          {"name":"PEDRO DE VALDIVIA","code":"CL-AN-PDV"},
          {"name":"PUERTO ANGAMOS","code":"CL-AN-PAG"},
          {"name":"SAN PEDRO DE ATACAMA","code":"CL-AN-SPA"},
          {"name":"SIERRA GORDA","code":"CL-AN-SIG"},
          {"name":"TALTAL","code":"CL-AN-TTC"},
          {"name":"TOCOPILLA","code":"CL-AN-TOQ"},]
      },
      {
        name: 'Arica y Parinacota',
        code: 'CL-AP',
        cities: [{"name":"ARICA","code":"CL-AP-ARI"},
          {"name":"CAMARONES","code":"CL-AP-CAM"},
          {"name":"GENERAL LAGOS","code":"CL-AP-GLG"},
          {"name":"PASO CHUNGARÁ-TAMBO QUEMADO/PUTRE","code":"CL-AP-CGA"},
          {"name":"PASO CONCORDIA-CHACALLUTA/ARICA","code":"CL-AP-CCD"},
          {"name":"PASO VISVIRI/PUTRE","code":"CL-AP-VIS"},
          {"name":"PUTRE","code":"CL-AP-PUT"},]
      },
      {
        name: 'Atacama',
        code: 'CL-AT',
        cities: [{"name":"ALTO DEL CARMEN","code":"CL-AT-ADC"},
          {"name":"BARQUITO","code":"CL-AT-BAR"},
          {"name":"CALDERA","code":"CL-AT-CLD"},
          {"name":"CALDERILLA","code":"CL-AT-CAA"},
          {"name":"CHAÑARAL","code":"CL-AT-CNR"},
          {"name":"COPIAPÓ","code":"CL-AT-CPO"},
          {"name":"DIEGO DE ALMAGRO","code":"CL-AT-DDA"},
          {"name":"DOMEYKO","code":"CL-AT-A2T"},
          {"name":"EL SALVADOR","code":"CL-AT-ESR"},
          {"name":"FREIRINA","code":"CL-AT-FRN"},
          {"name":"HUASCO","code":"CL-AT-HSO"},
          {"name":"IGLESIA COLORADA","code":"CL-AT-IGC"},
          {"name":"PASO PIRCAS NEGRAS/TIERRA AMARILLA","code":"CL-AT-PIG"},
          {"name":"PASO SAN FRANCISCO/TIERRA AMARILLA","code":"CL-AT-FCO"},
          {"name":"TIERRA AMARILLA","code":"CL-AT-TAM"},
          {"name":"VALLENAR","code":"CL-AT-VLR"},
          {"name":"VENTANAS","code":"CL-AT-VEN"},]
      },
      {
        name: 'Biobío',
        code: 'CL-BI',
        cities: [{"name":"ALTO BÍO BÍO","code":"CL-BI-ABB"},
          {"name":"ANGELES","code":"CL-BI-TMP163079"},
          {"name":"ANTUCO","code":"CL-BI-ANT"},
          {"name":"ARAUCO","code":"CL-BI-ARA"},
          {"name":"BULNES","code":"CL-BI-BUL"},
          {"name":"CABRERO","code":"CL-BI-CAB"},
          {"name":"CAMPANARIO","code":"CL-BI-CMP"},
          {"name":"CAÑETE","code":"CL-BI-CTE"},
          {"name":"CHARRUA","code":"CL-BI-B7X"},
          {"name":"CHIGUAYANTE","code":"CL-BI-CHI"},
          {"name":"CHILLÁN","code":"CL-BI-YAI"},
          {"name":"CHILLÁN VIEJO","code":"CL-BI-CHV"},
          {"name":"CIUDAD DE CONCEPCION","code":"CL-BI-TMP163080"},
          {"name":"COBQUECURA","code":"CL-BI-CBQ"},
          {"name":"COELEMU","code":"CL-BI-COE"},
          {"name":"COIHUECO","code":"CL-BI-COI"},
          {"name":"CONCEPCIÓN","code":"CL-BI-CCP"},
          {"name":"CONTULMO","code":"CL-BI-CTM"},
          {"name":"CORONEL","code":"CL-BI-CNL"},
          {"name":"CURANILAHUE","code":"CL-BI-CRN"},
          {"name":"EL CARMEN","code":"CL-BI-ELC"},
          {"name":"ESCUADRÓN","code":"CL-BI-ESC"},
          {"name":"FLORIDA","code":"CL-BI-FLO"},
          {"name":"HUACHIPATO","code":"CL-BI-HPO"},
          {"name":"HUALPÉN","code":"CL-BI-HPN"},
          {"name":"HUALQUI","code":"CL-BI-HQI"},
          {"name":"HUEPIL","code":"CL-BI-HUL"},
          {"name":"LA LAJA","code":"CL-BI-LAJ"},
          {"name":"LEBU","code":"CL-BI-LEB"},
          {"name":"LIRQUÉN","code":"CL-BI-LQN"},
          {"name":"LLICO","code":"CL-BI-LLI"},
          {"name":"LOS ÁLAMOS","code":"CL-BI-LAL"},
          {"name":"LOS ÁNGELES","code":"CL-BI-LSQ"},
          {"name":"LOTA","code":"CL-BI-LTA"},
          {"name":"MULCHÉN","code":"CL-BI-MUL"},
          {"name":"NACIMIENTO","code":"CL-BI-NAC"},
          {"name":"NEGRETE","code":"CL-BI-NEG"},
          {"name":"NINHUE","code":"CL-BI-NIN"},
          {"name":"NIPAS","code":"CL-BI-NPS"},
          {"name":"ÑIQUÉN","code":"CL-BI-NQN"},
          {"name":"NUEVA ALDEA","code":"CL-BI-NAL"},
          {"name":"PASO PICHACHÉN/ANTUCO","code":"CL-BI-PIA"},
          {"name":"PEMUCO","code":"CL-BI-PEM"},
          {"name":"PENCO","code":"CL-BI-PEO"},
          {"name":"PINTO","code":"CL-BI-PIN"},
          {"name":"PORTEZUELO","code":"CL-BI-POR"},
          {"name":"QUILACO","code":"CL-BI-QLA"},
          {"name":"QUILLECO","code":"CL-BI-QLO"},
          {"name":"QUILLÓN","code":"CL-BI-QIN"},
          {"name":"QUIRIHUE","code":"CL-BI-QRH"},
          {"name":"RÁNQUIL","code":"CL-BI-RAN"},
          {"name":"SAN CARLOS","code":"CL-BI-SCR"},
          {"name":"SAN FABIÁN","code":"CL-BI-SFN"},
          {"name":"SAN IGNACIO","code":"CL-BI-SIN"},
          {"name":"SAN NICOLÁS","code":"CL-BI-SNI"},
          {"name":"SAN PEDRO DE LA PAZ","code":"CL-BI-SPP"},
          {"name":"SAN ROSENDO","code":"CL-BI-SRO"},
          {"name":"SAN VICENTE","code":"CL-BI-SVE"},
          {"name":"SANTA BÁRBARA","code":"CL-BI-SBA"},
          {"name":"SANTA JUANA","code":"CL-BI-SJU"},
          {"name":"TALCAHUANO","code":"CL-BI-TAL"},
          {"name":"TIRÚA","code":"CL-BI-TIR"},
          {"name":"TOMÉ","code":"CL-BI-TOM"},
          {"name":"TREGUACO","code":"CL-BI-TRE"},
          {"name":"TRES PINOS","code":"CL-BI-TPI"},
          {"name":"TUCAPEL","code":"CL-BI-TUC"},
          {"name":"YUMBEL","code":"CL-BI-YUM"},
          {"name":"YUNGAY","code":"CL-BI-YUN"},]
      },
      {
        name: 'Coquimbo',
        code: 'CL-CO',
        cities: [{"name":"ANDACOLLO","code":"CL-CO-AND"},
          {"name":"CANELA","code":"CL-CO-CAN"},
          {"name":"COMBARBALÁ","code":"CL-CO-CBB"},
          {"name":"COQUIMBO","code":"CL-CO-CQQ"},
          {"name":"CRUZ GRANDE","code":"CL-CO-CGR"},
          {"name":"EL PALQUI","code":"CL-CO-ELP"},
          {"name":"GUAYACÁN","code":"CL-CO-GYC"},
          {"name":"HURTADO","code":"CL-CO-RHU"},
          {"name":"ILLAPEL","code":"CL-CO-ILL"},
          {"name":"LA HIGUERA","code":"CL-CO-LAH"},
          {"name":"LA SERENA","code":"CL-CO-LSC"},
          {"name":"LIMARÍ","code":"CL-CO-LMR"},
          {"name":"LOS VILOS","code":"CL-CO-LOS"},
          {"name":"MONTE PATRIA","code":"CL-CO-MTP"},
          {"name":"OVALLE","code":"CL-CO-OVL"},
          {"name":"PAIGUANO","code":"CL-CO-PAI"},
          {"name":"PASO AGUA NEGRA-JUNTAS DE TORO/VICUÑA","code":"CL-CO-AGN"},
          {"name":"POSESIÓN","code":"CL-CO-POS"},
          {"name":"PUNITAQUI","code":"CL-CO-PUN"},
          {"name":"PUNTA CHUNGO","code":"CL-CO-PTC"},
          {"name":"SALAMANCA","code":"CL-CO-SAL"},
          {"name":"TONGOY","code":"CL-CO-TON"},
          {"name":"VICUÑA","code":"CL-CO-VIC"},]
      },
      {
        name: 'La Araucanía',
        code: 'CL-AR',
        cities: [{"name":"ANGOL","code":"CL-AR-ANG"},
          {"name":"CARAHUE","code":"CL-AR-CAR"},
          {"name":"CHOLCHOL","code":"CL-AR-CHL"},
          {"name":"COLLIPULLI","code":"CL-AR-CPI"},
          {"name":"CUNCO","code":"CL-AR-CUN"},
          {"name":"CURACAUTÍN","code":"CL-AR-CCN"},
          {"name":"CURARREHUE","code":"CL-AR-CRE"},
          {"name":"ERCILLA","code":"CL-AR-ERC"},
          {"name":"FREIRE","code":"CL-AR-FRE"},
          {"name":"GALVARINO","code":"CL-AR-GAL"},
          {"name":"GORBEA","code":"CL-AR-GOR"},
          {"name":"LA PAZ","code":"CL-AR-PAZ"},
          {"name":"LAUTARO","code":"CL-AR-LAU"},
          {"name":"LIUCURA","code":"CL-AR-LIU"},
          {"name":"LONCOCHE","code":"CL-AR-LNC"},
          {"name":"LONQUIMAY","code":"CL-AR-LQY"},
          {"name":"LOS SAUCES","code":"CL-AR-LSA"},
          {"name":"LUMACO","code":"CL-AR-LUM"},
          {"name":"MELIPEUCO","code":"CL-AR-MLP"},
          {"name":"NUEVA IMPERIAL","code":"CL-AR-NIM"},
          {"name":"PADRE LAS CASAS","code":"CL-AR-PLC"},
          {"name":"PASO ICALMA/LONQUIMAY","code":"CL-AR-ICA"},
          {"name":"PASO MAMUIL MALAL-PUESCO/CURRAREHUE","code":"CL-AR-MML"},
          {"name":"PASO PINO HACHADO/LONQUIMAY","code":"CL-AR-PAA"},
          {"name":"PERQUENCO","code":"CL-AR-PQC"},
          {"name":"PIDIMA","code":"CL-AR-PDM"},
          {"name":"PITRUFQUÉN","code":"CL-AR-PIT"},
          {"name":"PUCÓN","code":"CL-AR-PUC"},
          {"name":"PUCÓN APT","code":"CL-AR-ZPC"},
          {"name":"PURÉN","code":"CL-AR-PUR"},
          {"name":"RENAICO","code":"CL-AR-RNO"},
          {"name":"SAAVEDRA","code":"CL-AR-SAA"},
          {"name":"TEMUCO","code":"CL-AR-ZCO"},
          {"name":"TEODORO SCHMIDT","code":"CL-AR-TEO"},
          {"name":"TOLTÉN","code":"CL-AR-TOL"},
          {"name":"TRAIGUÉN","code":"CL-AR-TRA"},
          {"name":"VICTORIA","code":"CL-AR-ZIC"},
          {"name":"VILCÚN","code":"CL-AR-VCN"},
          {"name":"VILLARRICA","code":"CL-AR-VIL"},]
      },
      {
        name: 'Libertador General Bernardo O’Higgins',
        code: 'CL-LI',
        cities: [{"name":"CAMPAMENTO BARAHONA","code":"CL-LI-BBH"},
          {"name":"CHÉPICA","code":"CL-LI-CHE"},
          {"name":"CHIMBARONGO","code":"CL-LI-CBG"},
          {"name":"CODEGUA","code":"CL-LI-COD"},
          {"name":"COINCO","code":"CL-LI-COO"},
          {"name":"COLTAUCO","code":"CL-LI-CTC"},
          {"name":"DOÑIHUE","code":"CL-LI-DOH"},
          {"name":"GRANEROS","code":"CL-LI-GRA"},
          {"name":"LA ESTRELLA","code":"CL-LI-LES"},
          {"name":"LAS CABRAS","code":"CL-LI-LCB"},
          {"name":"LITUECHE","code":"CL-LI-LIT"},
          {"name":"LOLOL","code":"CL-LI-LOL"},
          {"name":"MACHALÍ","code":"CL-LI-MCI"},
          {"name":"MALLOA","code":"CL-LI-MAL"},
          {"name":"MARCHIHUE","code":"CL-LI-MAR"},
          {"name":"NANCAGUA","code":"CL-LI-NCG"},
          {"name":"NANCAHUA","code":"CL-LI-NAN"},
          {"name":"NAVIDAD","code":"CL-LI-NAV"},
          {"name":"OLIVAR","code":"CL-LI-OLI"},
          {"name":"PALMILLA","code":"CL-LI-PMA"},
          {"name":"PAREDONES","code":"CL-LI-PAR"},
          {"name":"PELEQUÉN","code":"CL-LI-PLE"},
          {"name":"PERALILLO","code":"CL-LI-PER"},
          {"name":"PEUMO","code":"CL-LI-PEU"},
          {"name":"PICHIDEGUA","code":"CL-LI-PDH"},
          {"name":"PICHILEMU","code":"CL-LI-PLM"},
          {"name":"PLACILLA","code":"CL-LI-PLA"},
          {"name":"PUENTE NEGRO","code":"CL-LI-PNG"},
          {"name":"PUMANQUE","code":"CL-LI-PUM"},
          {"name":"QUINTA DE TILCOCO","code":"CL-LI-QDT"},
          {"name":"RANCAGUA","code":"CL-LI-QRC"},
          {"name":"RAPEL","code":"CL-LI-RAP"},
          {"name":"RENGO","code":"CL-LI-RGO"},
          {"name":"REQUÍNOA","code":"CL-LI-REQ"},
          {"name":"ROSARIO","code":"CL-LI-ROS"},
          {"name":"SAN FERNANDO","code":"CL-LI-SFD"},
          {"name":"SAN FRANCISCO DE MOSTAZAL","code":"CL-LI-MOS"},
          {"name":"SAN VICENTE","code":"CL-LI-SVT"},
          {"name":"SAN VICENTE DE TAGUA TAGUA","code":"CL-LI-STT"},
          {"name":"SANTA CRUZ","code":"CL-LI-SCZ"},]
      },
      {
        name: 'Los Lagos',
        code: 'CL-LL',
        cities: [{"name":"ACHAO","code":"CL-LL-ACH"},
          {"name":"ANCUD","code":"CL-LL-ZUD"},
          {"name":"AYACARA","code":"CL-LL-AYA"},
          {"name":"CALBUCO","code":"CL-LL-CBC"},
          {"name":"CARAHUE","code":"CL-LL-CRH"},
          {"name":"CASTRO","code":"CL-LL-WCA"},
          {"name":"CHACAO","code":"CL-LL-CCA"},
          {"name":"CHAITÉN","code":"CL-LL-WCH"},
          {"name":"CHONCHI","code":"CL-LL-CHO"},
          {"name":"COCHAMÓ","code":"CL-LL-CMO"},
          {"name":"CORRAL","code":"CL-LL-CRR"},
          {"name":"CURACO DE VÉLEZ","code":"CL-LL-CDV"},
          {"name":"DALCAHUE","code":"CL-LL-DCH"},
          {"name":"FRESIA","code":"CL-LL-FRS"},
          {"name":"FRUTILLAR","code":"CL-LL-FRT"},
          {"name":"FUTALEUFÚ","code":"CL-LL-FFU"},
          {"name":"FUTRONO","code":"CL-LL-FUT"},
          {"name":"HUALAIHUÉ","code":"CL-LL-HUH"},
          {"name":"LA UNIÓN","code":"CL-LL-LUN"},
          {"name":"LAGO RANCO","code":"CL-LL-RNC"},
          {"name":"LANCO","code":"CL-LL-LAN"},
          {"name":"LLANQUIHUE","code":"CL-LL-LIA"},
          {"name":"LOS LAGOS","code":"CL-LL-LLG"},
          {"name":"LOS MUERMOS","code":"CL-LL-LMU"},
          {"name":"MÁFIL","code":"CL-LL-MAF"},
          {"name":"MARIQUINA","code":"CL-LL-MRQ"},
          {"name":"MAULLÍN","code":"CL-LL-MLL"},
          {"name":"MELINKA","code":"CL-LL-MLI"},
          {"name":"NIEBLA","code":"CL-LL-NBL"},
          {"name":"OSORNO","code":"CL-LL-ZOS"},
          {"name":"PAILLACO","code":"CL-LL-PAO"},
          {"name":"PALENA","code":"CL-LL-PAL"},
          {"name":"PANGUIPULLI","code":"CL-LL-PAN"},
          {"name":"PARGUA","code":"CL-LL-PGA"},
          {"name":"PASO CARDENAL A. SAMORÉ/PUYEHUE","code":"CL-LL-CSA"},
          {"name":"PASO FUTALEUFÚ/FUTALEUFÚ","code":"CL-LL-FTF"},
          {"name":"PASO HUA HUM/PANGUIPULLI","code":"CL-LL-HHM"},
          {"name":"PASO RÍO ENCUENTRO/PALENA","code":"CL-LL-RIE"},
          {"name":"PASO V. PÉREZ ROSALES-PEULLA/PUERTO VARAS","code":"CL-LL-VPR"},
          {"name":"PUERTO MONTT","code":"CL-LL-PMC"},
          {"name":"PUERTO OCTAY","code":"CL-LL-POC"},
          {"name":"PUERTO VARAS","code":"CL-LL-PVS"},
          {"name":"PUQUELDÓN","code":"CL-LL-PQD"},
          {"name":"PURRANQUE","code":"CL-LL-PRQ"},
          {"name":"PUYEHUE","code":"CL-LL-PYH"},
          {"name":"QUEILÉN","code":"CL-LL-QLN"},
          {"name":"QUELLÓN (PUERTO QUELLÓN)","code":"CL-LL-PTE"},
          {"name":"QUELLÓN VIEJO","code":"CL-LL-QUV"},
          {"name":"QUEMCHI","code":"CL-LL-QMC"},
          {"name":"QUINCHAO","code":"CL-LL-QCO"},
          {"name":"RAUCO","code":"CL-LL-RAF"},
          {"name":"RÍO BUENO","code":"CL-LL-RBU"},
          {"name":"RÍO NEGRO","code":"CL-LL-RNE"},
          {"name":"SAN JUAN DE LA COSTA","code":"CL-LL-SJC"},
          {"name":"SAN PABLO","code":"CL-LL-SPB"},
          {"name":"VALDIVIA","code":"CL-LL-ZAL"},]
      },
      {
        name: 'Los Ríos',
        code: 'CL-LR',
        cities: [{"name":"CORRAL","code":"CL-LR-TMP163172"},
          {"name":"PASO CARIRRIÑE/PANGUIPULLI","code":"CL-LR-CAI"},
          {"name":"VALDIVIA","code":"CL-LR-TMP163183"},
          {"name":"VALDIVIA","code":"CL-LR-TMP163288"},]
      },
      {
        name: 'Magallanes',
        code: 'CL-MA',
        cities: [{"name":"BAHIA LAREDO","code":"CL-MA-BLR"},
          {"name":"BAHÍA SAN GREGORIO","code":"CL-MA-BAG"},
          {"name":"CABO DE HORNOS","code":"CL-MA-CHR"},
          {"name":"CABO NEGRO","code":"CL-MA-CNX"},
          {"name":"CALETA CLARENCIA","code":"CL-MA-CLR"},
          {"name":"CERRO SOMBRERO","code":"CL-MA-SMB"},
          {"name":"ISLA GUARELLO","code":"CL-MA-ISG"},
          {"name":"LAGUNA BLANCA","code":"CL-MA-LBL"},
          {"name":"PASO DOROTEA/PUERTO NATALES","code":"CL-MA-DOR"},
          {"name":"PASO INTEGRACIÓN AUSTRAL-MONTE AYMOND/SAN GREGORIO","code":"CL-MA-IAU"},
          {"name":"PASO LAURITA CASAS VIEJAS/PUERTO NATALES","code":"CL-MA-CVJ"},
          {"name":"PASO RÍO BELLAVISTA/TIMAUKEL","code":"CL-MA-RBL"},
          {"name":"PASO RÍO DON GUILLERMO/TORRES DEL PAINE","code":"CL-MA-RDG"},
          {"name":"PASO SAN SEBASTIÁN/PORVENIR","code":"CL-MA-SSB"},
          {"name":"PECKET","code":"CL-MA-PCK"},
          {"name":"PORVENIR","code":"CL-MA-PVR"},
          {"name":"PORVENIR APT","code":"CL-MA-WPR"},
          {"name":"PRIMAVERA","code":"CL-MA-PRI"},
          {"name":"PUERTO EDÉN","code":"CL-MA-EDE"},
          {"name":"PUERTO NATALES","code":"CL-MA-PNT"},
          {"name":"PUERTO PERCY","code":"CL-MA-PPY"},
          {"name":"PUERTO WILLIAMS","code":"CL-MA-WPU"},
          {"name":"PUNTA ARENAS","code":"CL-MA-PUQ"},
          {"name":"RÍO VERDE","code":"CL-MA-RVE"},
          {"name":"SAN GREGORIO","code":"CL-MA-SGR"},
          {"name":"TIMAUKEL","code":"CL-MA-TIM"},
          {"name":"TORRES DEL PAINE","code":"CL-MA-TDP"},
          {"name":"TRES PUENTES","code":"CL-MA-TPT"},]
      },
      {
        name: 'Maule',
        code: 'CL-ML',
        cities: [{"name":"ANCOA","code":"CL-ML-M9Z"},
          {"name":"CAUQUENES","code":"CL-ML-CAU"},
          {"name":"CHANCO","code":"CL-ML-CHA"},
          {"name":"COLBÚN","code":"CL-ML-COB"},
          {"name":"CONSTITUCIÓN","code":"CL-ML-CST"},
          {"name":"CUREPTO","code":"CL-ML-CRT"},
          {"name":"CURICÓ","code":"CL-ML-CUR"},
          {"name":"EMPEDRADO","code":"CL-ML-EMP"},
          {"name":"HUALAÑÉ","code":"CL-ML-HLE"},
          {"name":"LICANTÉN","code":"CL-ML-LIC"},
          {"name":"LINARES","code":"CL-ML-LIN"},
          {"name":"LONGAVÍ","code":"CL-ML-LON"},
          {"name":"LONTUÉ","code":"CL-ML-LUE"},
          {"name":"MAULE","code":"CL-ML-MAU"},
          {"name":"MOLINA","code":"CL-ML-MOL"},
          {"name":"PARRAL","code":"CL-ML-PRL"},
          {"name":"PASO PEHUENCHE/SAN CLEMENTE","code":"CL-ML-PEH"},
          {"name":"PASO VERGARA-SAN PEDRO/ROMERAL","code":"CL-ML-VER"},
          {"name":"PELARCO","code":"CL-ML-PEL"},
          {"name":"PELLUHUE","code":"CL-ML-PLH"},
          {"name":"PENCAHUE","code":"CL-ML-PEN"},
          {"name":"RAUCO","code":"CL-ML-RAU"},
          {"name":"RETIRO","code":"CL-ML-RET"},
          {"name":"RÍO CLARO","code":"CL-ML-RCL"},
          {"name":"ROMERAL","code":"CL-ML-ROM"},
          {"name":"SAGRADA FAMILIA","code":"CL-ML-SAF"},
          {"name":"SAN CLEMENTE","code":"CL-ML-SCT"},
          {"name":"SAN JAVIER","code":"CL-ML-SJV"},
          {"name":"SAN RAFAEL","code":"CL-ML-SRA"},
          {"name":"TALCA","code":"CL-ML-TLX"},
          {"name":"TENO","code":"CL-ML-TEN"},
          {"name":"VICHUQUÉN","code":"CL-ML-VCQ"},
          {"name":"VILLA ALEGRE","code":"CL-ML-VAG"},
          {"name":"YERBAS BUENAS","code":"CL-ML-YBU"},]
      },
      {
        name: 'Región Metropolitana de Santiago',
        code: 'CL-RM',
        cities: [{"name":"ALHUÉ","code":"CL-RM-ALH"},
          {"name":"BUIN","code":"CL-RM-BUI"},
          {"name":"CALERA DE TANGO","code":"CL-RM-CDT"},
          {"name":"CERRILLOS","code":"CL-RM-CER"},
          {"name":"CERRO NAVIA","code":"CL-RM-CNV"},
          {"name":"CHICUREO","code":"CL-RM-CCO"},
          {"name":"COLINA","code":"CL-RM-CLN"},
          {"name":"CONCHALÍ","code":"CL-RM-CLI"},
          {"name":"CURACAVÍ","code":"CL-RM-CRV"},
          {"name":"EL BOSQUE","code":"CL-RM-ELB"},
          {"name":"EL MONTE","code":"CL-RM-ELM"},
          {"name":"ESPEJO","code":"CL-RM-TMP163423"},
          {"name":"ESTACIÓN CENTRAL","code":"CL-RM-ECT"},
          {"name":"HUECHURABA","code":"CL-RM-HUE"},
          {"name":"INDEPENDENCIA","code":"CL-RM-IND"},
          {"name":"ISLA DE MAIPO","code":"CL-RM-IDM"},
          {"name":"LA CISTERNA","code":"CL-RM-LCI"},
          {"name":"LA FLORIDA","code":"CL-RM-LFL"},
          {"name":"LA GRANJA","code":"CL-RM-LGJ"},
          {"name":"LA PINTANA","code":"CL-RM-LPI"},
          {"name":"LA REINA","code":"CL-RM-REI"},
          {"name":"LAMPA","code":"CL-RM-LAM"},
          {"name":"LAS CONDES","code":"CL-RM-LCO"},
          {"name":"LINDEROS","code":"CL-RM-LDR"},
          {"name":"LO BARNECHEA","code":"CL-RM-LBA"},
          {"name":"LO ESPEJO","code":"CL-RM-LEJ"},
          {"name":"LO PRADO","code":"CL-RM-LPR"},
          {"name":"LOS CERRILLOS APT/SANTIAGO","code":"CL-RM-ULC"},
          {"name":"MACUL","code":"CL-RM-MAC"},
          {"name":"MAIPO","code":"CL-RM-TMP163408"},
          {"name":"MAIPÚ","code":"CL-RM-MAI"},
          {"name":"MALLOCO","code":"CL-RM-MCO"},
          {"name":"MARÍA PINTO","code":"CL-RM-MPI"},
          {"name":"MELIPILLA","code":"CL-RM-MEL"},
          {"name":"NOS","code":"CL-RM-NOS"},
          {"name":"ÑUÑOA","code":"CL-RM-NUN"},
          {"name":"PADRE HURTADO","code":"CL-RM-PHU"},
          {"name":"PAINE","code":"CL-RM-PNE"},
          {"name":"PASO PIUQUENES/SAN JOSÉ DE MAIPO","code":"CL-RM-PIU"},
          {"name":"PEDRO AGUIRRE CERDA","code":"CL-RM-PAC"},
          {"name":"PEÑAFLOR","code":"CL-RM-PFL"},
          {"name":"PEÑALOLÉN","code":"CL-RM-PLN"},
          {"name":"PIRQUE","code":"CL-RM-PIR"},
          {"name":"PROVIDENCIA","code":"CL-RM-PRO"},
          {"name":"PUDAHUEL","code":"CL-RM-PUD"},
          {"name":"PUENTE ALTO","code":"CL-RM-PTA"},
          {"name":"QUILICURA","code":"CL-RM-QUI"},
          {"name":"QUINTA NORMAL","code":"CL-RM-QNO"},
          {"name":"RECOLETA","code":"CL-RM-REC"},
          {"name":"RENCA","code":"CL-RM-REN"},
          {"name":"SAN BERNARDO","code":"CL-RM-SBD"},
          {"name":"SAN JOAQUÍN","code":"CL-RM-SJQ"},
          {"name":"SAN JOSÉ DE MAIPO","code":"CL-RM-SJM"},
          {"name":"SAN MIGUEL","code":"CL-RM-SMG"},
          {"name":"SAN PEDRO","code":"CL-RM-SPE"},
          {"name":"SAN RAMÓN","code":"CL-RM-SRM"},
          {"name":"SANTIAGO","code":"CL-RM-SCL"},
          {"name":"TALAGANTE","code":"CL-RM-TLG"},
          {"name":"TILTIL","code":"CL-RM-TIL"},
          {"name":"VILUCO","code":"CL-RM-VLC"},
          {"name":"VITACURA","code":"CL-RM-VIT"},]
      },
      {
        name: 'Tarapacá',
        code: 'CL-TA',
        cities: [{"name":"ALTO HOSPICIO","code":"CL-TA-AHP"},
          {"name":"CAMIÑA","code":"CL-TA-CMA"},
          {"name":"COLCHANE","code":"CL-TA-CNE"},
          {"name":"HUARA","code":"CL-TA-HUA"},
          {"name":"IQUIQUE","code":"CL-TA-IQQ"},
          {"name":"PASO ABRA ORIENTE DE NAPA/PICA","code":"CL-TA-AON"},
          {"name":"PASO COLCHANE-PISIGA/COLCHANE","code":"CL-TA-COP"},
          {"name":"PATILLOS","code":"CL-TA-PTI"},
          {"name":"PICA","code":"CL-TA-PIC"},
          {"name":"PISAGUA","code":"CL-TA-PIS"},
          {"name":"POZO ALMONTE","code":"CL-TA-POZ"},
          {"name":"PUERTO PATACHE","code":"CL-TA-PAT"},
          {"name":"TAMENTICA","code":"CL-TA-TAA"},
          {"name":"ZONA FRANCA DE IQUIQUE","code":"CL-TA-ZFI"},]
      },
      {
        name: 'Valparaíso',
        code: 'CL-VS',
        cities: [{"name":"ALGARROBO","code":"CL-VS-ALG"},
          {"name":"CABILDO","code":"CL-VS-CBD"},
          {"name":"CALLE LARGA","code":"CL-VS-CLL"},
          {"name":"CARTAGENA","code":"CL-VS-CGN"},
          {"name":"CASABLANCA","code":"CL-VS-CAS"},
          {"name":"CATEMU","code":"CL-VS-CAT"},
          {"name":"CHAGRES","code":"CL-VS-CHG"},
          {"name":"CONCÓN","code":"CL-VS-CON"},
          {"name":"CURAUMA","code":"CL-VS-CRM"},
          {"name":"EL MELÓN","code":"CL-VS-EML"},
          {"name":"EL QUISCO","code":"CL-VS-ELQ"},
          {"name":"EL TABO","code":"CL-VS-ELT"},
          {"name":"HIJUELAS","code":"CL-VS-HIJ"},
          {"name":"HORCÓN","code":"CL-VS-HOR"},
          {"name":"ISLA DE PASCUA","code":"CL-VS-IPC"},
          {"name":"JUAN FERNÁNDEZ","code":"CL-VS-JFZ"},
          {"name":"LA CALERA","code":"CL-VS-LCL"},
          {"name":"LA CRUZ","code":"CL-VS-LCZ"},
          {"name":"LA LIGUA","code":"CL-VS-LIG"},
          {"name":"LAS SALINAS/QUINTERO","code":"CL-VS-LAS"},
          {"name":"LAS VENTANAS","code":"CL-VS-LVS"},
          {"name":"LIMACHE","code":"CL-VS-LIM"},
          {"name":"LLAILLAY","code":"CL-VS-LLA"},
          {"name":"LLOLLEO","code":"CL-VS-LLO"},
          {"name":"LOS ANDES","code":"CL-VS-LND"},
          {"name":"NOGALES","code":"CL-VS-NOG"},
          {"name":"OLMUÉ","code":"CL-VS-OLM"},
          {"name":"PANQUEHUE","code":"CL-VS-PQH"},
          {"name":"PAPUDO","code":"CL-VS-PAP"},
          {"name":"PASO CRISTO REDENTOR-LOS LIBERTADORES/LOS ANDES","code":"CL-VS-LIB"},
          {"name":"PETORCA","code":"CL-VS-PET"},
          {"name":"PLACILLA","code":"CL-VS-PLL"},
          {"name":"PLACILLA - VALPARAISO","code":"CL-VS-PAQ"},
          {"name":"PUCHUNCAVÍ","code":"CL-VS-PCI"},
          {"name":"PUTAENDO","code":"CL-VS-PTO"},
          {"name":"QUILLOTA","code":"CL-VS-QTA"},
          {"name":"QUILPUÉ","code":"CL-VS-QIL"},
          {"name":"QUINTERO","code":"CL-VS-QTV"},
          {"name":"RENACA","code":"CL-VS-TMP163329"},
          {"name":"RINCONADA DE LOS ANDES","code":"CL-VS-RIN"},
          {"name":"ROCAS DE SANTO DOMINGO","code":"CL-VS-RSD"},
          {"name":"SAN ANTONIO","code":"CL-VS-SAI"},
          {"name":"SAN ESTEBAN","code":"CL-VS-SES"},
          {"name":"SAN FELIPE","code":"CL-VS-SFP"},
          {"name":"SANTA MARÍA","code":"CL-VS-SMA"},
          {"name":"SANTO DOMINGO","code":"CL-VS-SDO"},
          {"name":"VALPARAISO","code":"CL-VS-VAP"},
          {"name":"VENTANAS","code":"CL-VS-VNT"},
          {"name":"VILLA ALEMANA","code":"CL-VS-VIA"},
          {"name":"VIÑA DEL MAR","code":"CL-VS-KNA"},
          {"name":"ZAPALLAR","code":"CL-VS-ZAP"},]
      },
    ]
  },
  {
    name: 'Colombia',
    code: 'CO',
    dialCode: '+57',
    flag: 'flag_colombia.png',
    states: [
      {
        name: 'Amazonas',
        code: 'CO-AMA',
        cities: [
          {"name":"LETICIA","code":"CO-AMA-LET"},
          {"name":"PUERTO NARIÑO","code":"COL-CO-AMA-PUE"},
        ]
      },
      {
        name: 'Antioquia',
        code: 'CO-ANT',
        cities: [
          {"name":"ABEJORRAL","code":"COL-CO-ANT-ABE"},
          {"name":"ABRIAQUÍ","code":"COL-CO-ANT-ABR"},
          {"name":"ALEJANDRÍA","code":"COL-CO-ANT-ALE"},
          {"name":"AMAGA","code":"COL-CO-ANT-AMA"},
          {"name":"AMALFI","code":"CO-ANT-AFI"},
          {"name":"ANDES","code":"CO-ANT-ADN"},
          {"name":"ANGELÓPOLIS","code":"COL-CO-ANT-ANG"},
          {"name":"ANGOSTURA","code":"COL-CO-ANT-ANG"},
          {"name":"ANORÍ","code":"COL-CO-ANT-ANO"},
          {"name":"ANZÁ","code":"COL-CO-ANT-ANZ"},
          {"name":"APARTADÓ","code":"CO-ANT-LAP"},
          {"name":"ARBOLETES","code":"CO-ANT-ARO"},
          {"name":"ARGELIA","code":"COL-CO-ANT-ARG"},
          {"name":"ARMENIA","code":"COL-CO-ANT-ARM"},
          {"name":"BARBOSA","code":"CO-ANT-BAA"},
          {"name":"BELLO","code":"CO-ANT-BLO"},
          {"name":"BELMIRA","code":"COL-CO-ANT-BEL"},
          {"name":"BETANIA","code":"COL-CO-ANT-BET"},
          {"name":"BETULIA","code":"COL-CO-ANT-BET"},
          {"name":"BRICEÑO","code":"COL-CO-ANT-BRI"},
          {"name":"BURITICÁ","code":"COL-CO-ANT-BUR"},
          {"name":"CÁCERES","code":"COL-CO-ANT-CÁC"},
          {"name":"CAICEDO","code":"COL-CO-ANT-CAI"},
          {"name":"CALDAS","code":"COL-CO-ANT-CAL"},
          {"name":"CAMPAMENTO","code":"COL-CO-ANT-CAM"},
          {"name":"CAÑASGORDAS","code":"COL-CO-ANT-CAÑ"},
          {"name":"CARACOLÍ","code":"COL-CO-ANT-CAR"},
          {"name":"CARAMANTA","code":"COL-CO-ANT-CAR"},
          {"name":"CAREPA","code":"COL-CO-ANT-CAR"},
          {"name":"CAROLINA DEL PRINCIPE","code":"COL-CO-ANT-CAR"},
          {"name":"CAUCASIA","code":"CO-ANT-CAQ"},
          {"name":"CHIGORODÓ","code":"CO-ANT-IGO"},
          {"name":"CISNEROS","code":"COL-CO-ANT-CIS"},
          {"name":"CIUDAD BOLÍVAR","code":"COL-CO-ANT-CIU"},
          {"name":"COCORNÁ","code":"COL-CO-ANT-COC"},
          {"name":"CONCEPCIÓN","code":"COL-CO-ANT-CON"},
          {"name":"CONCORDIA","code":"COL-CO-ANT-CON"},
          {"name":"COPACABANA","code":"COL-CO-ANT-COP"},
          {"name":"DABEIBA","code":"COL-CO-ANT-DAB"},
          {"name":"DON MATÍAS","code":"COL-CO-ANT-DON"},
          {"name":"EBÉJICO","code":"COL-CO-ANT-EBÉ"},
          {"name":"EL BAGRE","code":"COL-CO-ANT-ELB"},
          {"name":"EL CARMEN DE VIBORAL","code":"COL-CO-ANT-ELC"},
          {"name":"EL PEÑOL","code":"COL-CO-ANT-ELP"},
          {"name":"EL RETIRO","code":"COL-CO-ANT-ELR"},
          {"name":"ENTRERRÍOS","code":"COL-CO-ANT-ENT"},
          {"name":"ENVIGADO","code":"CO-ANT-ENV"},
          {"name":"FREDONIA","code":"COL-CO-ANT-FRE"},
          {"name":"FRONTINO","code":"COL-CO-ANT-FRO"},
          {"name":"GIRALDO","code":"COL-CO-ANT-GIR"},
          {"name":"GIRARDOTA","code":"COL-CO-ANT-GIR"},
          {"name":"GÓMEZ PLATA","code":"COL-CO-ANT-GÓM"},
          {"name":"GRANADA","code":"CO-ANT-GRD"},
          {"name":"GUADALUPE","code":"COL-CO-ANT-GUA"},
          {"name":"GUARNE","code":"COL-CO-ANT-GUA"},
          {"name":"GUATAPÉ","code":"COL-CO-ANT-GUA"},
          {"name":"HELICONIA","code":"COL-CO-ANT-HEL"},
          {"name":"HISPANIA","code":"COL-CO-ANT-HIS"},
          {"name":"ITAGÜÍ","code":"CO-ANT-ITG"},
          {"name":"ITUANGO","code":"COL-CO-ANT-ITU"},
          {"name":"JARDÍN","code":"COL-CO-ANT-JAR"},
          {"name":"JERICÓ","code":"CO-ANT-JER"},
          {"name":"LA CEJA DEL TAMBO","code":"COL-CO-ANT-LAC"},
          {"name":"LA ESTRELLA","code":"CO-ANT-LAA"},
          {"name":"LA PINTADA","code":"COL-CO-ANT-LAP"},
          {"name":"LA UNIÓN","code":"COL-CO-ANT-LAU"},
          {"name":"LIBORINA","code":"COL-CO-ANT-LIB"},
          {"name":"MACEO","code":"COL-CO-ANT-MAC"},
          {"name":"MARINILLA","code":"CO-ANT-MRA"},
          {"name":"MEDELLÍN","code":"CO-ANT-MDE"},
          {"name":"MONTEBELLO","code":"COL-CO-ANT-MON"},
          {"name":"MURINDÓ","code":"COL-CO-ANT-MUR"},
          {"name":"MUTATÁ","code":"COL-CO-ANT-MUT"},
          {"name":"NARIÑO","code":"COL-CO-ANT-NAR"},
          {"name":"NECHÍ","code":"COL-CO-ANT-NEC"},
          {"name":"NECOCLÍ","code":"CO-ANT-NCI"},
          {"name":"OLAYA","code":"COL-CO-ANT-OLA"},
          {"name":"PEQUE","code":"COL-CO-ANT-PEQ"},
          {"name":"PUEBLORRICO","code":"COL-CO-ANT-PUE"},
          {"name":"PUERTO BERRIO","code":"COL-CO-ANT-PUE"},
          {"name":"PUERTO NARE","code":"COL-CO-ANT-PUE"},
          {"name":"PUERTO TRIUNFO","code":"COL-CO-ANT-PUE"},
          {"name":"REMEDIOS","code":"COL-CO-ANT-REM"},
          {"name":"RIONEGRO","code":"CO-ANT-RRO"},
          {"name":"SABANALARGA","code":"CO-ANT-SA2"},
          {"name":"SABANETA","code":"CO-ANT-SAT"},
          {"name":"SALGAR","code":"COL-CO-ANT-SAL"},
          {"name":"SAN ANDRÉS DE CUERQUIA","code":"COL-CO-ANT-SAN"},
          {"name":"SAN CARLOS","code":"COL-CO-ANT-SAN"},
          {"name":"SAN FRANCISCO","code":"COL-CO-ANT-SAN"},
          {"name":"SAN JERÓNIMO","code":"COL-CO-ANT-SAN"},
          {"name":"SAN JOSÉ DE LA MONTAÑA","code":"COL-CO-ANT-SAN"},
          {"name":"SAN JUAN DE URABÁ","code":"CO-ANT-SJR"},
          {"name":"SAN LUIS","code":"COL-CO-ANT-SAN"},
          {"name":"SAN PEDRO DE LOS MILAGROS","code":"COL-CO-ANT-SAN"},
          {"name":"SAN PEDRO DE URABÁ","code":"COL-CO-ANT-SAN"},
          {"name":"SAN RAFAEL","code":"COL-CO-ANT-SAN"},
          {"name":"SAN ROQUE","code":"COL-CO-ANT-SAN"},
          {"name":"SAN VICENTE","code":"COL-CO-ANT-SAN"},
          {"name":"SANTA BÁRBARA","code":"COL-CO-ANT-SAN"},
          {"name":"SANTA ROSA DE OSOS","code":"COL-CO-ANT-SAN"},
          {"name":"SANTAFE DE ANTIOQUIA","code":"COL-CO-ANT-SAN"},
          {"name":"SANTO DOMINGO","code":"COL-CO-ANT-SAN"},
          {"name":"SANTUARIO","code":"CO-ANT-ESN"},
          {"name":"SEGOVIA","code":"COL-CO-ANT-SEG"},
          {"name":"SONSÓN","code":"COL-CO-ANT-SON"},
          {"name":"SOPETRÁN","code":"COL-CO-ANT-SOP"},
          {"name":"TÁMESIS","code":"COL-CO-ANT-TÁM"},
          {"name":"TARAZÁ","code":"COL-CO-ANT-TAR"},
          {"name":"TARSO","code":"COL-CO-ANT-TAR"},
          {"name":"TITIRIBÍ","code":"COL-CO-ANT-TIT"},
          {"name":"TOLEDO","code":"COL-CO-ANT-TOL"},
          {"name":"TURBO","code":"CO-ANT-TRB"},
          {"name":"URAMITA","code":"COL-CO-ANT-URA"},
          {"name":"URRAO","code":"CO-ANT-URR"},
          {"name":"VALDIVIA","code":"COL-CO-ANT-VAL"},
          {"name":"VALPARAÍSO","code":"COL-CO-ANT-VAL"},
          {"name":"VEGACHÍ","code":"COL-CO-ANT-VEG"},
          {"name":"VENECIA","code":"COL-CO-ANT-VEN"},
          {"name":"VIGÍA DEL FUERTE","code":"COL-CO-ANT-VIG"},
          {"name":"YALÍ","code":"COL-CO-ANT-YAL"},
          {"name":"YARUMAL","code":"COL-CO-ANT-YAR"},
          {"name":"YOLOMBÓ","code":"COL-CO-ANT-YOL"},
          {"name":"YONDO","code":"COL-CO-ANT-YON"},
          {"name":"ZARAGOZA","code":"COL-CO-ANT-ZAR"},
        ]
      },
      {
        name: 'Arauca',
        code: 'CO-ARA',
        cities: [
          {"name":"ARAUCA","code":"COL-CO-ARA-ARA"},
          {"name":"ARAUQUITA","code":"CO-ARA-ARQ"},
          {"name":"CRAVO NORTE","code":"CO-ARA-RAV"},
          {"name":"FORTUL","code":"COL-CO-ARA-FOR"},
          {"name":"PUERTO RONDÓN","code":"COL-CO-ARA-PUE"},
          {"name":"SARAVENA","code":"CO-ARA-RVE"},
          {"name":"TAME","code":"CO-ARA-TME"},
        ]
      },
      {
        name: 'Atlántico',
        code: 'CO-ATL',
        cities: [
          {"name":"BARANOA","code":"CO-ATL-BAR"},
          {"name":"BARRANQUILLA","code":"CO-ATL-TMP163553"},
          {"name":"CAMPO DE LA CRUZ","code":"CO-ATL-CLC"},
          {"name":"CANDELARIA","code":"COL-CO-ATL-CAN"},
          {"name":"GALAPA","code":"CO-ATL-GLP"},
          {"name":"JUAN DE ACOSTA","code":"CO-ATL-JDA"},
          {"name":"LURUACO","code":"COL-CO-ATL-LUR"},
          {"name":"MALAMBO","code":"CO-ATL-MAL"},
          {"name":"MANATÍ","code":"COL-CO-ATL-MAN"},
          {"name":"PALMAR DE VARELA","code":"COL-CO-ATL-PAL"},
          {"name":"PIOJO","code":"COL-CO-ATL-PIO"},
          {"name":"POLONUEVO","code":"COL-CO-ATL-POL"},
          {"name":"PONEDERA","code":"CO-ATL-PON"},
          {"name":"PUERTO COLOMBIA","code":"COL-CO-ATL-PUE"},
          {"name":"REPELÓN","code":"COL-CO-ATL-REP"},
          {"name":"SABANAGRANDE","code":"CO-ATL-SGR"},
          {"name":"SABANALARGA","code":"CO-ATL-SAB"},
          {"name":"SANTA LUCÍA","code":"COL-CO-ATL-SAN"},
          {"name":"SANTO TOMAS","code":"COL-CO-ATL-SAN"},
          {"name":"SOLEDAD","code":"CO-ATL-SOL"},
          {"name":"SUAN","code":"COL-CO-ATL-SUA"},
          {"name":"TUBARÁ","code":"COL-CO-ATL-TUB"},
          {"name":"USIACURÍ","code":"COL-CO-ATL-USI"},
        ]
      },
      {
        name: 'Bogotá D.C.',
        code: 'CO-DC',
        cities: [{"name":"BOGOTÁ D.C.","code":"CO-DC-TMP163565"},]
      },
      {
        name: 'Bolívar',
        code: 'CO-BOL',
        cities: [
          {"name":"ACHÍ","code":"COL-CO-BOL-ACH"},
          {"name":"ALTOS DEL ROSARIO","code":"COL-CO-BOL-ALT"},
          {"name":"ARENAL","code":"CO-BOL-ARE"},
          {"name":"ARJONA","code":"COL-CO-BOL-ARJ"},
          {"name":"ARROYOHONDO","code":"COL-CO-BOL-ARR"},
          {"name":"BARRANCO DE LOBA","code":"COL-CO-BOL-BAR"},
          {"name":"CALAMAR","code":"CO-BOL-CAL"},
          {"name":"CANTAGALLO","code":"COL-CO-BOL-CAN"},
          {"name":"CARTAGENA DE INDIAS","code":"COL-CO-BOL-CAR"},
          {"name":"CICUCO","code":"COL-CO-BOL-CIC"},
          {"name":"CLEMENCIA","code":"COL-CO-BOL-CLE"},
          {"name":"CÓRDOBA","code":"COL-CO-BOL-CÓR"},
          {"name":"EL CARMEN DE BOLÍVAR","code":"CO-BOL-ECB"},
          {"name":"EL GUAMO","code":"COL-CO-BOL-ELG"},
          {"name":"EL PEÑON","code":"COL-CO-BOL-ELP"},
          {"name":"HATILLO DE LOBA","code":"COL-CO-BOL-HAT"},
          {"name":"MAGANGUÉ","code":"CO-BOL-MGN"},
          {"name":"MAHATES","code":"COL-CO-BOL-MAH"},
          {"name":"MARGARITA","code":"COL-CO-BOL-MAR"},
          {"name":"MARÍA LA BAJA","code":"COL-CO-BOL-MAR"},
          {"name":"MONTECRISTO","code":"COL-CO-BOL-MON"},
          {"name":"MORALES","code":"COL-CO-BOL-MOR"},
          {"name":"NOROSÍ","code":"COL-CO-BOL-NOR"},
          {"name":"PINILLOS","code":"COL-CO-BOL-PIN"},
          {"name":"REGIDOR","code":"COL-CO-BOL-REG"},
          {"name":"RIOVIEJO","code":"COL-CO-BOL-RIO"},
          {"name":"SAN CRISTÓBAL","code":"COL-CO-BOL-SAN"},
          {"name":"SAN ESTANISLAO","code":"CO-BOL-SET"},
          {"name":"SAN FERNANDO","code":"COL-CO-BOL-SAN"},
          {"name":"SAN JACINTO","code":"COL-CO-BOL-SAN"},
          {"name":"SAN JACINTO DEL CAUCA","code":"CO-BOL-SJC"},
          {"name":"SAN JUAN NEPOMUCENO","code":"CO-BOL-SJN"},
          {"name":"SAN MARTÍN DE LOBA","code":"COL-CO-BOL-SAN"},
          {"name":"SAN PABLO","code":"COL-CO-BOL-SAN"},
          {"name":"SANTA CATALINA","code":"COL-CO-BOL-SAN"},
          {"name":"SANTA CRUZ DE MOMPÓS","code":"COL-CO-BOL-SAN"},
          {"name":"SANTA ROSA DEL NORTE","code":"COL-CO-BOL-SAN"},
          {"name":"SANTA ROSA DEL SUR","code":"COL-CO-BOL-SAN"},
          {"name":"SIMITÍ","code":"COL-CO-BOL-SIM"},
          {"name":"SOPLAVIENTO","code":"COL-CO-BOL-SOP"},
          {"name":"TALAIGUA NUEVO","code":"COL-CO-BOL-TAL"},
          {"name":"TIQUISIO","code":"COL-CO-BOL-TIQ"},
          {"name":"TURBACO","code":"CO-BOL-TBC"},
          {"name":"TURBANA","code":"COL-CO-BOL-TUR"},
          {"name":"VILLANUEVA","code":"COL-CO-BOL-VIL"},
        ]
      },
      {
        name: 'Boyacá',
        code: 'CO-BOY',
        cities: [
          {"name":"ALMEIDA","code":"COL-CO-BOY-ALM"},
          {"name":"AQUITANIA","code":"COL-CO-BOY-AQU"},
          {"name":"ARCABUCO","code":"COL-CO-BOY-ARC"},
          {"name":"BELÉN","code":"COL-CO-BOY-BEL"},
          {"name":"BERBEO","code":"COL-CO-BOY-BER"},
          {"name":"BETÉITIVA","code":"COL-CO-BOY-BET"},
          {"name":"BOAVITA","code":"COL-CO-BOY-BOA"},
          {"name":"BOYACÁ","code":"COL-CO-BOY-BOY"},
          {"name":"BRICEÑO","code":"COL-CO-BOY-BRI"},
          {"name":"BUENAVISTA","code":"COL-CO-BOY-BUE"},
          {"name":"BUSBANZÁ","code":"COL-CO-BOY-BUS"},
          {"name":"CALDAS","code":"COL-CO-BOY-CAL"},
          {"name":"CAMPOHERMOSO","code":"COL-CO-BOY-CAM"},
          {"name":"CERINZA","code":"COL-CO-BOY-CER"},
          {"name":"CHINAVITA","code":"COL-CO-BOY-CHI"},
          {"name":"CHIQUINQUIRÁ","code":"CO-BOY-CHQ"},
          {"name":"CHÍQUIZA","code":"COL-CO-BOY-CHÍ"},
          {"name":"CHISCAS","code":"COL-CO-BOY-CHI"},
          {"name":"CHITA","code":"COL-CO-BOY-CHI"},
          {"name":"CHITARAQUE","code":"COL-CO-BOY-CHI"},
          {"name":"CHIVATA","code":"COL-CO-BOY-CHI"},
          {"name":"CHIVOR","code":"COL-CO-BOY-CHI"},
          {"name":"CIÉNEGA","code":"COL-CO-BOY-CIÉ"},
          {"name":"CÓMBITA","code":"COL-CO-BOY-CÓM"},
          {"name":"COPER","code":"COL-CO-BOY-COP"},
          {"name":"CORRALES","code":"COL-CO-BOY-COR"},
          {"name":"COVARACHÍA","code":"COL-CO-BOY-COV"},
          {"name":"CUBARÁ","code":"COL-CO-BOY-CUB"},
          {"name":"CUCAITA","code":"COL-CO-BOY-CUC"},
          {"name":"CUÍTIVA","code":"COL-CO-BOY-CUÍ"},
          {"name":"DUITAMA","code":"CO-BOY-DUE"},
          {"name":"EL COCUY","code":"COL-CO-BOY-ELC"},
          {"name":"EL ESPINO","code":"COL-CO-BOY-ELE"},
          {"name":"FIRAVITOBA","code":"COL-CO-BOY-FIR"},
          {"name":"FLORESTA","code":"COL-CO-BOY-FLO"},
          {"name":"GACHANTIVÁ","code":"COL-CO-BOY-GAC"},
          {"name":"GÁMEZA","code":"COL-CO-BOY-GÁM"},
          {"name":"GARAGOA","code":"COL-CO-BOY-GAR"},
          {"name":"GUACAMAYAS","code":"COL-CO-BOY-GUA"},
          {"name":"GUATEQUE","code":"COL-CO-BOY-GUA"},
          {"name":"GUAYATÁ","code":"COL-CO-BOY-GUA"},
          {"name":"GÜICÁN","code":"COL-CO-BOY-GÜI"},
          {"name":"IZA","code":"COL-CO-BOY-IZA"},
          {"name":"JENESANO","code":"COL-CO-BOY-JEN"},
          {"name":"JERICÓ","code":"COL-CO-BOY-JER"},
          {"name":"LA CAPILLA","code":"COL-CO-BOY-LAC"},
          {"name":"LA UVITA","code":"COL-CO-BOY-LAU"},
          {"name":"LA VICTORIA","code":"COL-CO-BOY-LAV"},
          {"name":"LABRANZAGRANDE","code":"COL-CO-BOY-LAB"},
          {"name":"MACANAL","code":"COL-CO-BOY-MAC"},
          {"name":"MARIPI","code":"COL-CO-BOY-MAR"},
          {"name":"MIRAFLORES","code":"COL-CO-BOY-MIR"},
          {"name":"MONGUA","code":"COL-CO-BOY-MON"},
          {"name":"MONGUÍ","code":"COL-CO-BOY-MON"},
          {"name":"MONIQUIRÁ","code":"COL-CO-BOY-MON"},
          {"name":"MOTAVITA","code":"COL-CO-BOY-MOT"},
          {"name":"MUZO","code":"COL-CO-BOY-MUZ"},
          {"name":"NOBSA","code":"COL-CO-BOY-NOB"},
          {"name":"NUEVO COLÓN","code":"COL-CO-BOY-NUE"},
          {"name":"OICATÁ","code":"COL-CO-BOY-OIC"},
          {"name":"OTANCHE","code":"COL-CO-BOY-OTA"},
          {"name":"PACHAVITA","code":"COL-CO-BOY-PAC"},
          {"name":"PÁEZ","code":"COL-CO-BOY-PÁE"},
          {"name":"PAIPA","code":"COL-CO-BOY-PAI"},
          {"name":"PAJARITO","code":"COL-CO-BOY-PAJ"},
          {"name":"PANQUEBA","code":"COL-CO-BOY-PAN"},
          {"name":"PAUNA","code":"COL-CO-BOY-PAU"},
          {"name":"PAYA","code":"COL-CO-BOY-PAY"},
          {"name":"PAZ DE RÍO","code":"COL-CO-BOY-PAZ"},
          {"name":"PESCA","code":"COL-CO-BOY-PES"},
          {"name":"PISBA","code":"COL-CO-BOY-PIS"},
          {"name":"PUERTO BOYACA","code":"CO-BOY-PYA"},
          {"name":"QUÍPAMA","code":"COL-CO-BOY-QUÍ"},
          {"name":"RAMIRIQUÍ","code":"COL-CO-BOY-RAM"},
          {"name":"RÁQUIRA","code":"COL-CO-BOY-RÁQ"},
          {"name":"RONDÓN","code":"COL-CO-BOY-RON"},
          {"name":"SABOYA","code":"COL-CO-BOY-SAB"},
          {"name":"SÁCHICA","code":"COL-CO-BOY-SÁC"},
          {"name":"SAMACÁ","code":"COL-CO-BOY-SAM"},
          {"name":"SAN EDUARDO","code":"COL-CO-BOY-SAN"},
          {"name":"SAN JOSÉ DE PARE","code":"COL-CO-BOY-SAN"},
          {"name":"SAN LUIS DE GACENO","code":"COL-CO-BOY-SAN"},
          {"name":"SAN MATEO","code":"COL-CO-BOY-SAN"},
          {"name":"SAN MIGUEL DE SEMA","code":"COL-CO-BOY-SAN"},
          {"name":"SAN PABLO DE BORBUR","code":"COL-CO-BOY-SAN"},
          {"name":"SANTA MARÍA","code":"COL-CO-BOY-SAN"},
          {"name":"SANTA ROSA DE VITERBO","code":"COL-CO-BOY-SAN"},
          {"name":"SANTA SOFÍA","code":"COL-CO-BOY-SAN"},
          {"name":"SANTANA","code":"COL-CO-BOY-SAN"},
          {"name":"SATIVANORTE","code":"COL-CO-BOY-SAT"},
          {"name":"SATIVASUR","code":"COL-CO-BOY-SAT"},
          {"name":"SIACHOQUE","code":"COL-CO-BOY-SIA"},
          {"name":"SOATÁ","code":"COL-CO-BOY-SOA"},
          {"name":"SOCHA","code":"COL-CO-BOY-SOC"},
          {"name":"SOCOTÁ","code":"COL-CO-BOY-SOC"},
          {"name":"SOGAMOSO","code":"CO-BOY-SOX"},
          {"name":"SOMONDOCO","code":"COL-CO-BOY-SOM"},
          {"name":"SORA","code":"COL-CO-BOY-SOR"},
          {"name":"SORACÁ","code":"COL-CO-BOY-SOR"},
          {"name":"SOTAQUIRÁ","code":"COL-CO-BOY-SOT"},
          {"name":"SUSACÓN","code":"COL-CO-BOY-SUS"},
          {"name":"SUTAMARCHAN","code":"COL-CO-BOY-SUT"},
          {"name":"SUTATENZA","code":"COL-CO-BOY-SUT"},
          {"name":"TASCO","code":"COL-CO-BOY-TAS"},
          {"name":"TENZA","code":"COL-CO-BOY-TEN"},
          {"name":"TIBANÁ","code":"COL-CO-BOY-TIB"},
          {"name":"TIBASOSA","code":"COL-CO-BOY-TIB"},
          {"name":"TINJACÁ","code":"COL-CO-BOY-TIN"},
          {"name":"TIPACOQUE","code":"COL-CO-BOY-TIP"},
          {"name":"TOCA","code":"COL-CO-BOY-TOC"},
          {"name":"TOGÜÍ","code":"COL-CO-BOY-TOG"},
          {"name":"TÓPAGA","code":"COL-CO-BOY-TÓP"},
          {"name":"TOTA","code":"COL-CO-BOY-TOT"},
          {"name":"TUNJA","code":"CO-BOY-TUN"},
          {"name":"TUNUNGUA","code":"COL-CO-BOY-TUN"},
          {"name":"TURMEQUÉ","code":"COL-CO-BOY-TUR"},
          {"name":"TUTA","code":"COL-CO-BOY-TUT"},
          {"name":"TUTAZÁ","code":"COL-CO-BOY-TUT"},
          {"name":"ÚMBITA","code":"COL-CO-BOY-ÚMB"},
          {"name":"VENTAQUEMADA","code":"COL-CO-BOY-VEN"},
          {"name":"VILLA DE LEYVA","code":"COL-CO-BOY-VIL"},
          {"name":"VIRACACHÁ","code":"COL-CO-BOY-VIR"},
          {"name":"ZETAQUIRÁ","code":"COL-CO-BOY-ZET"},
        ]
      },
      {
        name: 'Caldas',
        code: 'CO-CAL',
        cities: [
          {"name":"AGUADAS","code":"CO-CAL-AGU"},
          {"name":"ANSERMA","code":"COL-CO-CAL-ANS"},
          {"name":"ARANZAZU","code":"COL-CO-CAL-ARA"},
          {"name":"BELALCAZAR","code":"COL-CO-CAL-BEL"},
          {"name":"CHINCHINÁ","code":"CO-CAL-NNA"},
          {"name":"FILADELFIA","code":"COL-CO-CAL-FIL"},
          {"name":"LA DORADA","code":"COL-CO-CAL-LAD"},
          {"name":"LA MERCED","code":"COL-CO-CAL-LAM"},
          {"name":"MANIZALES","code":"COL-CO-CAL-MAN"},
          {"name":"MANZANARES","code":"COL-CO-CAL-MAN"},
          {"name":"MARMATO","code":"COL-CO-CAL-MAR"},
          {"name":"MARQUETALIA","code":"COL-CO-CAL-MAR"},
          {"name":"MARULANDA","code":"COL-CO-CAL-MAR"},
          {"name":"NEIRA","code":"COL-CO-CAL-NEI"},
          {"name":"NORCASIA","code":"COL-CO-CAL-NOR"},
          {"name":"PACORA","code":"COL-CO-CAL-PAC"},
          {"name":"PALESTINA","code":"COL-CO-CAL-PAL"},
          {"name":"PENSILVANIA","code":"COL-CO-CAL-PEN"},
          {"name":"RIOSUCIO","code":"COL-CO-CAL-RIO"},
          {"name":"RISARALDA","code":"COL-CO-CAL-RIS"},
          {"name":"SALAMINA","code":"COL-CO-CAL-SAL"},
          {"name":"SAMANÁ","code":"COL-CO-CAL-SAM"},
          {"name":"SAN JOSÉ","code":"COL-CO-CAL-SAN"},
          {"name":"SUPÍA","code":"COL-CO-CAL-SUP"},
          {"name":"VICTORIA","code":"COL-CO-CAL-VIC"},
          {"name":"VILLAMARÍA","code":"COL-CO-CAL-VIL"},
          {"name":"VITERBO","code":"COL-CO-CAL-VIT"},
        ]
      },
      {
        name: 'Caquetá',
        code: 'CO-CAQ',
        cities: [
          {"name":"ALBANIA","code":"COL-CO-CAQ-ALB"},
          {"name":"BELÉN DE LOS ANDAQUIES","code":"COL-CO-CAQ-BEL"},
          {"name":"CARTAGENA DEL CHAIRÁ","code":"COL-CO-CAQ-CAR"},
          {"name":"CURILLO","code":"CO-CAQ-CUI"},
          {"name":"EL DONCELLO","code":"COL-CO-CAQ-ELD"},
          {"name":"EL PAUJIL","code":"COL-CO-CAQ-ELP"},
          {"name":"FLORENCIA","code":"COL-CO-CAQ-FLO"},
          {"name":"LA MONTAÑITA","code":"COL-CO-CAQ-LAM"},
          {"name":"MILÁN","code":"COL-CO-CAQ-MIL"},
          {"name":"MORELIA","code":"COL-CO-CAQ-MOR"},
          {"name":"PUERTO RICO","code":"CO-CAQ-PCC"},
          {"name":"SAN JOSÉ DEL FRAGUA","code":"COL-CO-CAQ-SAN"},
          {"name":"SAN VICENTE DEL CAGUÁN","code":"CO-CAQ-SVI"},
          {"name":"SOLANO","code":"CO-CAQ-SQF"},
          {"name":"SOLITA","code":"COL-CO-CAQ-SOL"},
          {"name":"VALPARAÍSO","code":"COL-CO-CAQ-VAL"},
        ]
      },
      {
        name: 'Casanare',
        code: 'CO-CAS',
        cities: [
          {"name":"AGUAZUL","code":"COL-CO-CAS-AGU"},
          {"name":"CHAMEZA","code":"COL-CO-CAS-CHA"},
          {"name":"HATO COROZAL","code":"CO-CAS-HTZ"},
          {"name":"LA SALINA","code":"COL-CO-CAS-LAS"},
          {"name":"MANÍ","code":"COL-CO-CAS-MAN"},
          {"name":"MONTERREY","code":"COL-CO-CAS-MON"},
          {"name":"NUNCHÍA","code":"CO-CAS-NUH"},
          {"name":"OROCUÉ","code":"CO-CAS-ORC"},
          {"name":"PAZ DE ARIPORO","code":"CO-CAS-PZA"},
          {"name":"PORE","code":"CO-CAS-PRE"},
          {"name":"RECETOR","code":"COL-CO-CAS-REC"},
          {"name":"SABANALARGA","code":"COL-CO-CAS-SAB"},
          {"name":"SÁCAMA","code":"COL-CO-CAS-SÁC"},
          {"name":"SAN LUIS DE PALENQUE","code":"COL-CO-CAS-SAN"},
          {"name":"TÁMARA","code":"COL-CO-CAS-TÁM"},
          {"name":"TAURAMENA","code":"CO-CAS-TAU"},
          {"name":"TRINIDAD","code":"COL-CO-CAS-TRI"},
          {"name":"VILLANUEVA","code":"COL-CO-CAS-VIL"},
          {"name":"YOPAL","code":"COL-CO-CAS-YOP"},
        ]
      },
      {
        name: 'Cauca',
        code: 'CO-CAU',
        cities: [
          {"name":"ALMAGUER","code":"COL-CO-CAU-ALM"},
          {"name":"ARGELIA","code":"COL-CO-CAU-ARG"},
          {"name":"BALBOA","code":"CO-CAU-BAL"},
          {"name":"BOLÍVAR","code":"COL-CO-CAU-BOL"},
          {"name":"BUENOS AIRES","code":"COL-CO-CAU-BUE"},
          {"name":"CAJIBIO","code":"COL-CO-CAU-CAJ"},
          {"name":"CALDONO","code":"COL-CO-CAU-CAL"},
          {"name":"CALOTO","code":"CO-CAU-XCO"},
          {"name":"CORINTO","code":"COL-CO-CAU-COR"},
          {"name":"EL TAMBO","code":"COL-CO-CAU-ELT"},
          {"name":"FLORENCIA","code":"COL-CO-CAU-FLO"},
          {"name":"GUACHENÉ","code":"CO-CAU-GHN"},
          {"name":"GUAPI","code":"CO-CAU-GPI"},
          {"name":"INZÁ","code":"COL-CO-CAU-INZ"},
          {"name":"JAMBALÓ","code":"COL-CO-CAU-JAM"},
          {"name":"LA SIERRA","code":"COL-CO-CAU-LAS"},
          {"name":"LA VEGA","code":"COL-CO-CAU-LAV"},
          {"name":"LÓPEZ DE MICAY","code":"COL-CO-CAU-LÓP"},
          {"name":"MERCADERES","code":"COL-CO-CAU-MER"},
          {"name":"MIRANDA","code":"COL-CO-CAU-MIR"},
          {"name":"MORALES","code":"COL-CO-CAU-MOR"},
          {"name":"PADILLA","code":"COL-CO-CAU-PAD"},
          {"name":"PÁEZ","code":"COL-CO-CAU-PÁE"},
          {"name":"PATÍA","code":"COL-CO-CAU-PAT"},
          {"name":"PIAMONTE","code":"COL-CO-CAU-PIA"},
          {"name":"PIENDAMÓ","code":"COL-CO-CAU-PIE"},
          {"name":"POPAYÁN","code":"CO-CAU-PPN"},
          {"name":"PUERTO TEJADA","code":"COL-CO-CAU-PUE"},
          {"name":"PURACÉ","code":"COL-CO-CAU-PUR"},
          {"name":"ROSAS","code":"COL-CO-CAU-ROS"},
          {"name":"SAN SEBASTIÁN","code":"COL-CO-CAU-SAN"},
          {"name":"SANTA ROSA","code":"COL-CO-CAU-SAN"},
          {"name":"SANTANDER DE QUILICHAO","code":"CO-CAU-SDQ"},
          {"name":"SILVIA","code":"COL-CO-CAU-SIL"},
          {"name":"SOTARÁ","code":"COL-CO-CAU-SOT"},
          {"name":"SUÁREZ","code":"COL-CO-CAU-SUÁ"},
          {"name":"SUCRE","code":"COL-CO-CAU-SUC"},
          {"name":"TIMBÍO","code":"COL-CO-CAU-TIM"},
          {"name":"TIMBIQUÍ","code":"CO-CAU-TBD"},
          {"name":"TORIBIO","code":"COL-CO-CAU-TOR"},
          {"name":"TOTORÓ","code":"COL-CO-CAU-TOT"},
          {"name":"VILLA RICA","code":"CO-CAU-VLR"},
        ]
      },
      {
        name: 'Cesar',
        code: 'CO-CES',
        cities: [
          {"name":"AGUACHICA","code":"CO-CES-AGC"},
          {"name":"AGUSTÍN CODAZZI","code":"COL-CO-CES-AGU"},
          {"name":"ASTREA","code":"COL-CO-CES-AST"},
          {"name":"BECERRIL","code":"CO-CES-BEC"},
          {"name":"BOSCONIA","code":"CO-CES-BOS"},
          {"name":"CHIMICHAGUA","code":"CO-CES-CHG"},
          {"name":"CHIRIGUANÁ","code":"CO-CES-CHN"},
          {"name":"CURUMANÍ","code":"CO-CES-CUR"},
          {"name":"EL COPEY","code":"COL-CO-CES-ELC"},
          {"name":"EL PASO","code":"CO-CES-ELP"},
          {"name":"GAMARRA","code":"CO-CES-GRA"},
          {"name":"GONZÁLEZ","code":"COL-CO-CES-GON"},
          {"name":"LA GLORIA","code":"COL-CO-CES-LAG"},
          {"name":"LA JAGUA DE IBIRICO","code":"CO-CES-LJG"},
          {"name":"LA PAZ","code":"COL-CO-CES-LAP"},
          {"name":"MANAURE BALCÓN DEL CÉSAR","code":"COL-CO-CES-MAN"},
          {"name":"PAILITAS","code":"CO-CES-PAI"},
          {"name":"PELAYA","code":"CO-CES-PEL"},
          {"name":"PUEBLO BELLO","code":"CO-CES-PBL"},
          {"name":"RIO DE ORO","code":"COL-CO-CES-RIO"},
          {"name":"SAN ALBERTO","code":"CO-CES-SAO"},
          {"name":"SAN DIEGO","code":"CO-CES-SDI"},
          {"name":"SAN MARTÍN","code":"CO-CES-SMA"},
          {"name":"TAMALAMEQUE","code":"CO-CES-TAL"},
          {"name":"VALLEDUPAR","code":"COL-CO-CES-VAL"},
        ]
      },
      {
        name: 'Chocó',
        code: 'CO-CHO',
        cities: [
          {"name":"ACANDÍ","code":"COL-CO-CHO-ACA"},
          {"name":"ALTO BAUDÓ","code":"COL-CO-CHO-ALT"},
          {"name":"ATRATO","code":"COL-CO-CHO-ATR"},
          {"name":"BAGADÓ","code":"COL-CO-CHO-BAG"},
          {"name":"BAHÍA SOLANO","code":"CO-CHO-BSC"},
          {"name":"BAJO BAUDÓ","code":"COL-CO-CHO-BAJ"},
          {"name":"BOJAYÁ","code":"COL-CO-CHO-BOJ"},
          {"name":"CARMEN DEL DARIÉN","code":"COL-CO-CHO-CAR"},
          {"name":"CÉRTEGUI","code":"COL-CO-CHO-CÉR"},
          {"name":"CONDOTO","code":"CO-CHO-COG"},
          {"name":"EL CANTÓN DE SAN PABLO","code":"COL-CO-CHO-ELC"},
          {"name":"EL CARMEN DE ATRATO","code":"COL-CO-CHO-ELC"},
          {"name":"EL LITORAL DE SAN JUAN","code":"COL-CO-CHO-ELL"},
          {"name":"ISTMINA","code":"CO-CHO-TMP163823"},
          {"name":"JURADÓ","code":"CO-CHO-JUO"},
          {"name":"LLORÓ","code":"COL-CO-CHO-LLO"},
          {"name":"MEDIO ATRATO","code":"COL-CO-CHO-MED"},
          {"name":"MEDIO BAUDÓ","code":"COL-CO-CHO-MED"},
          {"name":"MEDIO SAN JUAN","code":"COL-CO-CHO-MED"},
          {"name":"NÓVITA","code":"COL-CO-CHO-NÓV"},
          {"name":"NUQUÍ","code":"CO-CHO-NQU"},
          {"name":"QUIBDÓ","code":"CO-CHO-UIB"},
          {"name":"RÍO IRÓ","code":"COL-CO-CHO-RÍO"},
          {"name":"RÍO QUITO","code":"COL-CO-CHO-RÍO"},
          {"name":"RIOSUCIO","code":"COL-CO-CHO-RIO"},
          {"name":"SAN JOSÉ DE TADÓ","code":"COL-CO-CHO-SAN"},
          {"name":"SAN JOSÉ DEL PALMAR","code":"COL-CO-CHO-SAN"},
          {"name":"SIPÍ","code":"COL-CO-CHO-SIP"},
          {"name":"UNGÜÍA","code":"COL-CO-CHO-UNG"},
          {"name":"UNIÓN PANAMERICANA","code":"COL-CO-CHO-UNI"},
        ]
      },
      {
        name: 'Córdoba',
        code: 'CO-COR',
        cities: [
          {"name":"AYAPEL","code":"COL-CO-COR-AYA"},
          {"name":"BUENAVISTA","code":"CO-COR-BUE"},
          {"name":"CANALETE","code":"COL-CO-COR-CAN"},
          {"name":"CERETÉ","code":"CO-COR-CER"},
          {"name":"CHIMÁ","code":"COL-CO-COR-CHI"},
          {"name":"CHINÚ","code":"CO-COR-CHU"},
          {"name":"CIÉNAGA DE ORO","code":"CO-COR-CDO"},
          {"name":"COTORRA","code":"CO-COR-COR"},
          {"name":"LA APARTADA","code":"CO-COR-APA"},
          {"name":"LOS CÓRDOBAS","code":"CO-COR-LCO"},
          {"name":"MOMIL","code":"COL-CO-COR-MOM"},
          {"name":"MOÑITOS","code":"COL-CO-COR-MOÑ"},
          {"name":"MONTELIBANO","code":"CO-COR-MTB"},
          {"name":"MONTERÍA","code":"COL-CO-COR-MON"},
          {"name":"PLANETA RICA","code":"CO-COR-PLC"},
          {"name":"PUEBLO NUEVO","code":"CO-COR-PNU"},
          {"name":"PUERTO ESCONDIDO","code":"CO-COR-PES"},
          {"name":"PUERTO LIBERTADOR","code":"CO-COR-PLI"},
          {"name":"PURÍSIMA","code":"COL-CO-COR-PUR"},
          {"name":"SAHAGÚN","code":"CO-COR-SAH"},
          {"name":"SAN ANDRÉS DE SOTAVENTO","code":"COL-CO-COR-SAN"},
          {"name":"SAN ANTERO","code":"CO-COR-ANT"},
          {"name":"SAN BERNARDO DEL VIENTO","code":"CO-COR-SBV"},
          {"name":"SAN CARLOS","code":"COL-CO-COR-SAN"},
          {"name":"SAN JOSÉ DE URÉ","code":"COL-CO-COR-SAN"},
          {"name":"SAN PELAYO","code":"CO-COR-SPY"},
          {"name":"SANTA CRUZ DE LORICA","code":"CO-COR-LRI"},
          {"name":"TIERRALTA","code":"CO-COR-TIE"},
          {"name":"TUCHÍN","code":"COL-CO-COR-TUC"},
          {"name":"VALENCIA","code":"COL-CO-COR-VAL"},
        ]
      },
      {
        name: 'Cundinamarca',
        code: 'CO-CUN',
        cities: [
          {"name":"AGUA DE DIOS","code":"COL-CO-CUN-AGU"},
          {"name":"ALBÁN","code":"COL-CO-CUN-ALB"},
          {"name":"ANAPOIMA","code":"COL-CO-CUN-ANA"},
          {"name":"ANOLAIMA","code":"COL-CO-CUN-ANO"},
          {"name":"APULO","code":"COL-CO-CUN-APU"},
          {"name":"ARBELÁEZ","code":"COL-CO-CUN-ARB"},
          {"name":"BELTRÁN","code":"COL-CO-CUN-BEL"},
          {"name":"BITUIMA","code":"COL-CO-CUN-BIT"},
          {"name":"BOJACÁ","code":"COL-CO-CUN-BOJ"},
          {"name":"CABRERA","code":"COL-CO-CUN-CAB"},
          {"name":"CACHIPAY","code":"COL-CO-CUN-CAC"},
          {"name":"CAJICÁ","code":"CO-CUN-CAJ"},
          {"name":"CAPARRAPÍ","code":"COL-CO-CUN-CAP"},
          {"name":"CÁQUEZA","code":"COL-CO-CUN-CÁQ"},
          {"name":"CARMEN DE CARUPA","code":"COL-CO-CUN-CAR"},
          {"name":"CHAGUANÍ","code":"COL-CO-CUN-CHA"},
          {"name":"CHÍA","code":"CO-CUN-CHI"},
          {"name":"CHIPAQUE","code":"COL-CO-CUN-CHI"},
          {"name":"CHOACHÍ","code":"COL-CO-CUN-CHO"},
          {"name":"CHOCONTÁ","code":"COL-CO-CUN-CHO"},
          {"name":"COGUA","code":"COL-CO-CUN-COG"},
          {"name":"COTA","code":"CO-CUN-COT"},
          {"name":"CUCUNUBÁ","code":"COL-CO-CUN-CUC"},
          {"name":"EL COLEGIO","code":"COL-CO-CUN-ELC"},
          {"name":"EL PEÑÓN","code":"COL-CO-CUN-ELP"},
          {"name":"EL ROSAL","code":"COL-CO-CUN-ELR"},
          {"name":"FACATATIVÁ","code":"CO-CUN-9FC"},
          {"name":"FÓMEQUE","code":"COL-CO-CUN-FÓM"},
          {"name":"FOSCA","code":"COL-CO-CUN-FOS"},
          {"name":"FUNZA","code":"CO-CUN-FZA"},
          {"name":"FÚQUENE","code":"COL-CO-CUN-FÚQ"},
          {"name":"FUSAGASUGÁ","code":"COL-CO-CUN-FUS"},
          {"name":"GACHALÁ","code":"COL-CO-CUN-GAC"},
          {"name":"GACHANCIPÁ","code":"COL-CO-CUN-GAC"},
          {"name":"GACHETÁ","code":"COL-CO-CUN-GAC"},
          {"name":"GAMA","code":"COL-CO-CUN-GAM"},
          {"name":"GIRARDOT","code":"CO-CUN-GIR"},
          {"name":"GRANADA","code":"COL-CO-CUN-GRA"},
          {"name":"GUACHETÁ","code":"COL-CO-CUN-GUA"},
          {"name":"GUADUAS","code":"COL-CO-CUN-GUA"},
          {"name":"GUASCA","code":"COL-CO-CUN-GUA"},
          {"name":"GUATAQUÍ","code":"COL-CO-CUN-GUA"},
          {"name":"GUATAVITA","code":"COL-CO-CUN-GUA"},
          {"name":"GUAYABAL DE SIQUIMA","code":"COL-CO-CUN-GUA"},
          {"name":"GUAYABETAL","code":"COL-CO-CUN-GUA"},
          {"name":"GUTIERREZ","code":"COL-CO-CUN-GUT"},
          {"name":"JERUSALEN","code":"COL-CO-CUN-JER"},
          {"name":"JUNÍN","code":"COL-CO-CUN-JUN"},
          {"name":"LA CALERA","code":"COL-CO-CUN-LAC"},
          {"name":"LA MESA","code":"COL-CO-CUN-LAM"},
          {"name":"LA PALMA","code":"COL-CO-CUN-LAP"},
          {"name":"LA PEÑA","code":"COL-CO-CUN-LAP"},
          {"name":"LA VEGA","code":"COL-CO-CUN-LAV"},
          {"name":"LENGUAZAQUE","code":"COL-CO-CUN-LEN"},
          {"name":"MACHETÁ","code":"COL-CO-CUN-MAC"},
          {"name":"MADRID","code":"CO-CUN-MDD"},
          {"name":"MANTA","code":"COL-CO-CUN-MAN"},
          {"name":"MEDINA","code":"CO-CUN-MND"},
          {"name":"MOSQUERA","code":"COL-CO-CUN-MOS"},
          {"name":"NARIÑO","code":"COL-CO-CUN-NAR"},
          {"name":"NEMOCÓN","code":"COL-CO-CUN-NEM"},
          {"name":"NILO","code":"COL-CO-CUN-NIL"},
          {"name":"NIMAIMA","code":"COL-CO-CUN-NIM"},
          {"name":"NOCAIMA","code":"COL-CO-CUN-NOC"},
          {"name":"PACHO","code":"COL-CO-CUN-PAC"},
          {"name":"PAIME","code":"COL-CO-CUN-PAI"},
          {"name":"PANDI","code":"COL-CO-CUN-PAN"},
          {"name":"PARATEBUENO","code":"COL-CO-CUN-PAR"},
          {"name":"PASCA","code":"COL-CO-CUN-PAS"},
          {"name":"PUERTO SALGAR","code":"COL-CO-CUN-PUE"},
          {"name":"PULÍ","code":"COL-CO-CUN-PUL"},
          {"name":"QUEBRADANEGRA","code":"COL-CO-CUN-QUE"},
          {"name":"QUETAME","code":"COL-CO-CUN-QUE"},
          {"name":"QUIPILE","code":"COL-CO-CUN-QUI"},
          {"name":"RICAURTE","code":"COL-CO-CUN-RIC"},
          {"name":"SAN ANTONIO DEL TEQUENDAMA","code":"COL-CO-CUN-SAN"},
          {"name":"SAN BERNARDO","code":"COL-CO-CUN-SAN"},
          {"name":"SAN CAYETANO","code":"COL-CO-CUN-SAN"},
          {"name":"SAN FRANCISCO","code":"COL-CO-CUN-SAN"},
          {"name":"SAN JUAN DE RIOSECO","code":"COL-CO-CUN-SAN"},
          {"name":"SASAIMA","code":"COL-CO-CUN-SAS"},
          {"name":"SESQUILÉ","code":"COL-CO-CUN-SES"},
          {"name":"SIBATÉ","code":"CO-CUN-SIB"},
          {"name":"SILVANIA","code":"COL-CO-CUN-SIL"},
          {"name":"SIMIJACA","code":"COL-CO-CUN-SIM"},
          {"name":"SOACHA","code":"CO-CUN-SOA"},
          {"name":"SOPÓ","code":"COL-CO-CUN-SOP"},
          {"name":"SUBACHOQUE","code":"COL-CO-CUN-SUB"},
          {"name":"SUESCA","code":"COL-CO-CUN-SUE"},
          {"name":"SUPATÁ","code":"COL-CO-CUN-SUP"},
          {"name":"SUSA","code":"COL-CO-CUN-SUS"},
          {"name":"SUTATAUSA","code":"COL-CO-CUN-SUT"},
          {"name":"TABIO","code":"COL-CO-CUN-TAB"},
          {"name":"TAUSA","code":"COL-CO-CUN-TAU"},
          {"name":"TENA","code":"COL-CO-CUN-TEN"},
          {"name":"TENJO","code":"CO-CUN-TEO"},
          {"name":"TIBACUY","code":"COL-CO-CUN-TIB"},
          {"name":"TIBIRITA","code":"COL-CO-CUN-TIB"},
          {"name":"TOCAIMA","code":"COL-CO-CUN-TOC"},
          {"name":"TOCANCIPÁ","code":"CO-CUN-TOP"},
          {"name":"TOPAIPI","code":"COL-CO-CUN-TOP"},
          {"name":"UBALÁ","code":"COL-CO-CUN-UBA"},
          {"name":"UBAQUE","code":"COL-CO-CUN-UBA"},
          {"name":"UBATE","code":"COL-CO-CUN-UBA"},
          {"name":"UNE","code":"COL-CO-CUN-UNE"},
          {"name":"ÚTICA","code":"COL-CO-CUN-ÚTI"},
          {"name":"VENECIA","code":"COL-CO-CUN-VEN"},
          {"name":"VERGARA","code":"COL-CO-CUN-VER"},
          {"name":"VIANÍ","code":"COL-CO-CUN-VIA"},
          {"name":"VILLAGOMEZ","code":"COL-CO-CUN-VIL"},
          {"name":"VILLAPINZÓN","code":"COL-CO-CUN-VIL"},
          {"name":"VILLETA","code":"COL-CO-CUN-VIL"},
          {"name":"VIOTÁ","code":"COL-CO-CUN-VIO"},
          {"name":"YACOPÍ","code":"COL-CO-CUN-YAC"},
          {"name":"ZIPACÓN","code":"COL-CO-CUN-ZIP"},
          {"name":"ZIPAQUIRÁ","code":"CO-CUN-ZQA"},
        ]
      },
      {
        name: 'Guainía',
        code: 'CO-GUA',
        cities: [
          {"name":"BARRANCOMINAS","code":"CO-GUA-NBB"},
          {"name":"INIRIDA","code":"CO-GUA-PDA"},
        ]
      },
      {
        name: 'Guaviare',
        code: 'CO-GUV',
        cities: [
          {"name":"CALAMAR","code":"COL-CO-GUV-CAL"},
          {"name":"EL RETORNO","code":"COL-CO-GUV-ELR"},
          {"name":"MIRAFLORES","code":"COL-CO-GUV-MIR"},
          {"name":"SAN JOSÉ DEL GUAVIARE","code":"CO-GUV-SJE"},
        ]
      },
      {
        name: 'Huila',
        code: 'HUI',
        cities: [
          {"name":"ACEVEDO","code":"COL-CO-HUI-ACE"},
          {"name":"AGRADO","code":"COL-CO-HUI-AGR"},
          {"name":"AIPE","code":"COL-CO-HUI-AIP"},
          {"name":"ALGECIRAS","code":"COL-CO-HUI-ALG"},
          {"name":"ALTAMIRA","code":"COL-CO-HUI-ALT"},
          {"name":"BARAYA","code":"COL-CO-HUI-BAR"},
          {"name":"CAMPOALEGRE","code":"COL-CO-HUI-CAM"},
          {"name":"COLOMBIA","code":"COL-CO-HUI-COL"},
          {"name":"ELÍAS","code":"COL-CO-HUI-ELÍ"},
          {"name":"GARZÓN","code":"COL-CO-HUI-GAR"},
          {"name":"GIGANTE","code":"COL-CO-HUI-GIG"},
          {"name":"GUADALUPE","code":"COL-CO-HUI-GUA"},
          {"name":"HOBO","code":"COL-CO-HUI-HOB"},
          {"name":"IQUIRA","code":"COL-CO-HUI-IQU"},
          {"name":"ISNOS","code":"COL-CO-HUI-ISN"},
          {"name":"LA ARGENTINA","code":"COL-CO-HUI-LAA"},
          {"name":"LA PLATA","code":"COL-CO-HUI-LAP"},
          {"name":"NÁTAGA","code":"COL-CO-HUI-NÁT"},
          {"name":"NEIVA","code":"CO-HUI-NVA"},
          {"name":"OPORAPA","code":"COL-CO-HUI-OPO"},
          {"name":"PAICOL","code":"COL-CO-HUI-PAI"},
          {"name":"PALERMO","code":"COL-CO-HUI-PAL"},
          {"name":"PALESTINA","code":"COL-CO-HUI-PAL"},
          {"name":"PITAL","code":"COL-CO-HUI-PIT"},
          {"name":"PITALITO","code":"CO-HUI-PTX"},
          {"name":"RIVERA","code":"COL-CO-HUI-RIV"},
          {"name":"SALADOBLANCO","code":"COL-CO-HUI-SAL"},
          {"name":"SAN AGUSTÍN","code":"COL-CO-HUI-SAN"},
          {"name":"SANTA MARÍA","code":"COL-CO-HUI-SAN"},
          {"name":"SUAZA","code":"COL-CO-HUI-SUA"},
          {"name":"TARQUI","code":"COL-CO-HUI-TAR"},
          {"name":"TELLO","code":"COL-CO-HUI-TEL"},
          {"name":"TERUEL","code":"COL-CO-HUI-TER"},
          {"name":"TESALIA","code":"COL-CO-HUI-TES"},
          {"name":"TIMANÁ","code":"COL-CO-HUI-TIM"},
          {"name":"VILLAVIEJA","code":"COL-CO-HUI-VIL"},
          {"name":"YAGUARÁ","code":"CO-HUI-AYG"},
        ]
      },
      {
        name: 'La Guajira',
        code: 'CO-LAG',
        cities: [
          {"name":"ALBANIA","code":"COL-CO-LAG-ALB"},
          {"name":"BARRANCAS","code":"CO-LAG-BRR"},
          {"name":"DIBULLA","code":"COL-CO-LAG-DIB"},
          {"name":"DISTRACCIÓN","code":"COL-CO-LAG-DIS"},
          {"name":"EL MOLINO","code":"COL-CO-LAG-ELM"},
          {"name":"FONSECA","code":"CO-LAG-FON"},
          {"name":"HATONUEVO","code":"CO-LAG-HAT"},
          {"name":"LA JAGUA DEL PILAR","code":"COL-CO-LAG-LAJ"},
          {"name":"MAICAO","code":"COL-CO-LAG-MAI"},
          {"name":"MANAURE","code":"CO-LAG-MAU"},
          {"name":"RIOHACHA","code":"COL-CO-LAG-RIO"},
          {"name":"SAN JUAN DEL CESAR","code":"CO-LAG-SJH"},
          {"name":"URIBIA","code":"COL-CO-LAG-URI"},
          {"name":"URUMITA","code":"COL-CO-LAG-URU"},
          {"name":"VILLANUEVA","code":"CO-LAG-GVL"},
        ]
      },
      {
        name: 'Magdalena',
        code: 'CO-MAG',
        cities: [
          {"name":"ALGARROBO","code":"COL-CO-MAG-ALG"},
          {"name":"ARACATACA","code":"CO-MAG-ARA"},
          {"name":"ARIGUANÍ","code":"COL-CO-MAG-ARI"},
          {"name":"CERRO DE SAN ANTONIO","code":"COL-CO-MAG-CER"},
          {"name":"CHIVOLO","code":"CO-MAG-IVO"},
          {"name":"CIÉNAGA","code":"COL-CO-MAG-CIÉ"},
          {"name":"CONCORDIA","code":"COL-CO-MAG-CON"},
          {"name":"EL BANCO","code":"CO-MAG-ELB"},
          {"name":"EL PIÑÓN","code":"COL-CO-MAG-ELP"},
          {"name":"EL RETÉN","code":"COL-CO-MAG-ELR"},
          {"name":"FUNDACIÓN","code":"COL-CO-MAG-FUN"},
          {"name":"GUAMAL","code":"CO-MAG-GAA"},
          {"name":"NUEVA GRANADA","code":"COL-CO-MAG-NUE"},
          {"name":"PEDRAZA","code":"COL-CO-MAG-PED"},
          {"name":"PIJIÑO DEL CARMEN","code":"COL-CO-MAG-PIJ"},
          {"name":"PIVIJAY","code":"CO-MAG-PIV"},
          {"name":"PLATO","code":"CO-MAG-PLT"},
          {"name":"PUEBLOVIEJO","code":"COL-CO-MAG-PUE"},
          {"name":"REMOLINO","code":"COL-CO-MAG-REM"},
          {"name":"SABANAS DE SAN ÁNGEL","code":"COL-CO-MAG-SAB"},
          {"name":"SALAMINA","code":"COL-CO-MAG-SAL"},
          {"name":"SAN SEBASTIÁN DE BUENAVISTA","code":"COL-CO-MAG-SAN"},
          {"name":"SAN ZENÓN","code":"COL-CO-MAG-SAN"},
          {"name":"SANTA ANA","code":"COL-CO-MAG-SAN"},
          {"name":"SANTA BÁRBARA DE PINTO","code":"COL-CO-MAG-SAN"},
          {"name":"SANTA MARTA","code":"COL-CO-MAG-SAN"},
          {"name":"SITIONUEVO","code":"COL-CO-MAG-SIT"},
          {"name":"TENERIFE","code":"CO-MAG-TEN"},
          {"name":"ZAPAYÁN","code":"COL-CO-MAG-ZAP"},
          {"name":"ZONA BANANERA","code":"COL-CO-MAG-ZON"},
        ]
      },
      {
        name: 'Meta',
        code: 'CO-MET',
        cities: [
          {"name":"ACACÍAS","code":"CO-MET-AAS"},
          {"name":"BARRANCA DE UPÍA","code":"COL-CO-MET-BAR"},
          {"name":"CABUYARO","code":"COL-CO-MET-CAB"},
          {"name":"CASTILLA LA NUEVA","code":"COL-CO-MET-CAS"},
          {"name":"CUBARRAL","code":"COL-CO-MET-CUB"},
          {"name":"CUMARAL","code":"COL-CO-MET-CUM"},
          {"name":"EL CALVARIO","code":"COL-CO-MET-ELC"},
          {"name":"EL CASTILLO","code":"COL-CO-MET-ELC"},
          {"name":"EL DORADO","code":"COL-CO-MET-ELD"},
          {"name":"FUENTE DE ORO","code":"COL-CO-MET-FUE"},
          {"name":"GRANADA","code":"COL-CO-MET-GRA"},
          {"name":"GUAMAL","code":"COL-CO-MET-GUA"},
          {"name":"LA MACARENA","code":"CO-MET-LMC"},
          {"name":"LEJANIAS","code":"COL-CO-MET-LEJ"},
          {"name":"MAPIRIPÁN","code":"COL-CO-MET-MAP"},
          {"name":"MESETAS","code":"COL-CO-MET-MES"},
          {"name":"PUERTO CONCORDIA","code":"COL-CO-MET-PUE"},
          {"name":"PUERTO GAITÁN","code":"COL-CO-MET-PUE"},
          {"name":"PUERTO LLERAS","code":"COL-CO-MET-PUE"},
          {"name":"PUERTO LÓPEZ","code":"COL-CO-MET-PUE"},
          {"name":"PUERTO RICO","code":"COL-CO-MET-PUE"},
          {"name":"RESTREPO","code":"COL-CO-MET-RES"},
          {"name":"SAN CARLOS DE GUAROA","code":"CO-MET-8SG"},
          {"name":"SAN JUAN DE ARAMA","code":"COL-CO-MET-SAN"},
          {"name":"SAN JUANITO","code":"COL-CO-MET-SAN"},
          {"name":"SAN MARTÍN DE LOS LLANOS","code":"COL-CO-MET-SAN"},
          {"name":"URIBE","code":"CO-MET-URI"},
          {"name":"VILLAVICENCIO","code":"CO-MET-VVC"},
          {"name":"VISTA HERMOSA","code":"COL-CO-MET-VIS"},
        ]
      },
      {
        name: 'Nariño',
        code: 'CO-NAR',
        cities: [
          {"name":"ALBAN","code":"COL-CO-NAR-ALB"},
          {"name":"ALDANA","code":"COL-CO-NAR-ALD"},
          {"name":"ANCUYÁ","code":"COL-CO-NAR-ANC"},
          {"name":"ARBOLEDA","code":"COL-CO-NAR-ARB"},
          {"name":"BARBACOAS","code":"COL-CO-NAR-BAR"},
          {"name":"BELÉN","code":"COL-CO-NAR-BEL"},
          {"name":"BUESACO","code":"COL-CO-NAR-BUE"},
          {"name":"CHACHAGÜÍ","code":"COL-CO-NAR-CHA"},
          {"name":"COLÓN","code":"COL-CO-NAR-COL"},
          {"name":"CONSACÁ","code":"COL-CO-NAR-CON"},
          {"name":"CONTADERO","code":"COL-CO-NAR-CON"},
          {"name":"CÓRDOBA","code":"COL-CO-NAR-CÓR"},
          {"name":"CUASPUD","code":"COL-CO-NAR-CUA"},
          {"name":"CUMBAL","code":"COL-CO-NAR-CUM"},
          {"name":"CUMBITARA","code":"COL-CO-NAR-CUM"},
          {"name":"EL CHARCO","code":"CO-NAR-ECR"},
          {"name":"EL PEÑOL","code":"COL-CO-NAR-ELP"},
          {"name":"EL ROSARIO","code":"COL-CO-NAR-ELR"},
          {"name":"EL TABLÓN DE GÓMEZ","code":"COL-CO-NAR-ELT"},
          {"name":"EL TAMBO","code":"COL-CO-NAR-ELT"},
          {"name":"FRANCISCO PIZARRO","code":"COL-CO-NAR-FRA"},
          {"name":"FUNES","code":"COL-CO-NAR-FUN"},
          {"name":"GUACHUCAL","code":"COL-CO-NAR-GUA"},
          {"name":"GUAITARILLA","code":"COL-CO-NAR-GUA"},
          {"name":"GUALMATÁN","code":"COL-CO-NAR-GUA"},
          {"name":"ILES","code":"COL-CO-NAR-ILE"},
          {"name":"IMUÉS","code":"COL-CO-NAR-IMU"},
          {"name":"IPIALES","code":"CO-NAR-IPI"},
          {"name":"LA CRUZ","code":"COL-CO-NAR-LAC"},
          {"name":"LA FLORIDA","code":"COL-CO-NAR-LAF"},
          {"name":"LA LLANADA","code":"COL-CO-NAR-LAL"},
          {"name":"LA TOLA","code":"COL-CO-NAR-LAT"},
          {"name":"LA UNIÓN","code":"COL-CO-NAR-LAU"},
          {"name":"LEIVA","code":"COL-CO-NAR-LEI"},
          {"name":"LINARES","code":"COL-CO-NAR-LIN"},
          {"name":"LOS ANDES","code":"COL-CO-NAR-LOS"},
          {"name":"MAGÜI PAYÁN","code":"COL-CO-NAR-MAG"},
          {"name":"MALLAMA","code":"COL-CO-NAR-MAL"},
          {"name":"MOSQUERA","code":"COL-CO-NAR-MOS"},
          {"name":"NARIÑO","code":"COL-CO-NAR-NAR"},
          {"name":"OLAYA HERRERA","code":"COL-CO-NAR-OLA"},
          {"name":"OSPINA","code":"COL-CO-NAR-OSP"},
          {"name":"POLICARPA","code":"COL-CO-NAR-POL"},
          {"name":"POTOSÍ","code":"COL-CO-NAR-POT"},
          {"name":"PROVIDENCIA","code":"COL-CO-NAR-PRO"},
          {"name":"PUERRES","code":"COL-CO-NAR-PUE"},
          {"name":"PUPIALES","code":"COL-CO-NAR-PUP"},
          {"name":"RICAURTE","code":"COL-CO-NAR-RIC"},
          {"name":"ROBERTO PAYÁN","code":"COL-CO-NAR-ROB"},
          {"name":"SAMANIEGO","code":"COL-CO-NAR-SAM"},
          {"name":"SAN BERNARDO","code":"COL-CO-NAR-SAN"},
          {"name":"SAN JUAN DE PASTO","code":"COL-CO-NAR-SAN"},
          {"name":"SAN LORENZO","code":"COL-CO-NAR-SAN"},
          {"name":"SAN PABLO","code":"COL-CO-NAR-SAN"},
          {"name":"SAN PEDRO DE CARTAGO","code":"COL-CO-NAR-SAN"},
          {"name":"SANDONÁ","code":"COL-CO-NAR-SAN"},
          {"name":"SANTA BÁRBARA","code":"COL-CO-NAR-SAN"},
          {"name":"SANTACRUZ","code":"COL-CO-NAR-SAN"},
          {"name":"SAPUYES","code":"COL-CO-NAR-SAP"},
          {"name":"TAMINANGO","code":"COL-CO-NAR-TAM"},
          {"name":"TANGUA","code":"COL-CO-NAR-TAN"},
          {"name":"TUMACO","code":"COL-CO-NAR-TUM"},
          {"name":"TÚQUERRES","code":"COL-CO-NAR-TÚQ"},
          {"name":"YACUANQUER","code":"COL-CO-NAR-YAC"},
        ]
      },
      {
        name: 'Norte de Santander',
        code: 'CO-NSA',
        cities: [
          {"name":"ABREGO","code":"COL-CO-NSA-ABR"},
          {"name":"ARBOLEDAS","code":"COL-CO-NSA-ARB"},
          {"name":"BOCHALEMA","code":"COL-CO-NSA-BOC"},
          {"name":"BUCARASICA","code":"COL-CO-NSA-BUC"},
          {"name":"CÁCHIRA","code":"COL-CO-NSA-CÁC"},
          {"name":"CÁCOTA","code":"COL-CO-NSA-CÁC"},
          {"name":"CHINÁCOTA","code":"COL-CO-NSA-CHI"},
          {"name":"CHITAGÁ","code":"COL-CO-NSA-CHI"},
          {"name":"CONVENCIÓN","code":"COL-CO-NSA-CON"},
          {"name":"CUCUTILLA","code":"COL-CO-NSA-CUC"},
          {"name":"DURANIA","code":"COL-CO-NSA-DUR"},
          {"name":"EL CARMEN","code":"COL-CO-NSA-ELC"},
          {"name":"EL TARRA","code":"COL-CO-NSA-ELT"},
          {"name":"EL ZULIA","code":"COL-CO-NSA-ELZ"},
          {"name":"GRAMALOTE","code":"COL-CO-NSA-GRA"},
          {"name":"HACARÍ","code":"COL-CO-NSA-HAC"},
          {"name":"HERRÁN","code":"COL-CO-NSA-HER"},
          {"name":"LA ESPERANZA","code":"COL-CO-NSA-LAE"},
          {"name":"LA PLAYA DE BELÉN","code":"COL-CO-NSA-LAP"},
          {"name":"LABATECA","code":"COL-CO-NSA-LAB"},
          {"name":"LOS PATIOS","code":"COL-CO-NSA-LOS"},
          {"name":"LOURDES","code":"COL-CO-NSA-LOU"},
          {"name":"MUTISCUA","code":"COL-CO-NSA-MUT"},
          {"name":"OCAÑA","code":"CO-NSA-OCV"},
          {"name":"PAMPLONA","code":"COL-CO-NSA-PAM"},
          {"name":"PAMPLONITA","code":"COL-CO-NSA-PAM"},
          {"name":"PUERTO SANTANDER","code":"COL-CO-NSA-PUE"},
          {"name":"RAGONVALIA","code":"COL-CO-NSA-RAG"},
          {"name":"SALAZAR DE LAS PALMAS","code":"COL-CO-NSA-SAL"},
          {"name":"SAN CALIXTO","code":"COL-CO-NSA-SAN"},
          {"name":"SAN CAYETANO","code":"COL-CO-NSA-SAN"},
          {"name":"SAN JOSÉ DE CÚCUTA","code":"CO-NSA-TMP163738"},
          {"name":"SANTIAGO","code":"COL-CO-NSA-SAN"},
          {"name":"SARDINATA","code":"COL-CO-NSA-SAR"},
          {"name":"SILOS","code":"COL-CO-NSA-SIL"},
          {"name":"TEORAMA","code":"COL-CO-NSA-TEO"},
          {"name":"TIBÚ","code":"CO-NSA-TIB"},
          {"name":"TOLEDO","code":"COL-CO-NSA-TOL"},
          {"name":"VILLA CARO","code":"COL-CO-NSA-VIL"},
          {"name":"VILLA DEL ROSARIO","code":"COL-CO-NSA-VIL"},
        ]
      },
      {
        name: 'Putumayo',
        code: 'CO-PUT',
        cities: [
          {"name":"COLÓN","code":"CO-PUT-PCO"},
          {"name":"MOCOA","code":"COL-CO-PUT-MOC"},
          {"name":"ORITO","code":"COL-CO-PUT-ORI"},
          {"name":"PUERTO ASÍS","code":"CO-PUT-PUU"},
          {"name":"PUERTO CAICEDO","code":"COL-CO-PUT-PUE"},
          {"name":"PUERTO GUZMÁN","code":"COL-CO-PUT-PUE"},
          {"name":"PUERTO LEGUIZAMO","code":"CO-PUT-LQM"},
          {"name":"SAN FRANCISCO","code":"COL-CO-PUT-SAN"},
          {"name":"SAN MIGUEL","code":"COL-CO-PUT-SAN"},
          {"name":"SANTIAGO","code":"COL-CO-PUT-SAN"},
          {"name":"SIBUNDOY","code":"COL-CO-PUT-SIB"},
          {"name":"VALLE DEL GUAMUEZ","code":"COL-CO-PUT-VAL"},
          {"name":"VILLAGARZÓN","code":"CO-PUT-VGZ"},
        ]
      },
      {
        name: 'Quindío',
        code: 'CO-QUI',
        cities: [
          {"name":"ARMENIA","code":"CO-QUI-TMP163936"},
          {"name":"BUENAVISTA","code":"COL-CO-QUI-BUE"},
          {"name":"CALARCÁ","code":"COL-CO-QUI-CAL"},
          {"name":"CIRCASIA","code":"COL-CO-QUI-CIR"},
          {"name":"CÓRDOBA","code":"COL-CO-QUI-CÓR"},
          {"name":"FILANDIA","code":"COL-CO-QUI-FIL"},
          {"name":"GÉNOVA","code":"COL-CO-QUI-GÉN"},
          {"name":"LA TEBAIDA","code":"COL-CO-QUI-LAT"},
          {"name":"MONTENEGRO","code":"COL-CO-QUI-MON"},
          {"name":"PIJAO","code":"COL-CO-QUI-PIJ"},
          {"name":"QUIMBAYA","code":"COL-CO-QUI-QUI"},
          {"name":"SALENTO","code":"CO-QUI-SAL"},
        ]
      },
      {
        name: 'Risaralda',
        code: 'CO-RIS',
        cities: [
          {"name":"APÍA","code":"COL-CO-RIS-APÍ"},
          {"name":"BALBOA","code":"COL-CO-RIS-BAL"},
          {"name":"BELÉN DE UMBRÍA","code":"COL-CO-RIS-BEL"},
          {"name":"DOSQUEBRADAS","code":"CO-RIS-DQS"},
          {"name":"GUÁTICA","code":"COL-CO-RIS-GUÁ"},
          {"name":"LA CELIA","code":"COL-CO-RIS-LAC"},
          {"name":"LA VIRGINIA","code":"COL-CO-RIS-LAV"},
          {"name":"MARSELLA","code":"COL-CO-RIS-MAR"},
          {"name":"MISTRATO","code":"COL-CO-RIS-MIS"},
          {"name":"PEREIRA","code":"COL-CO-RIS-PER"},
          {"name":"PUEBLO RICO","code":"COL-CO-RIS-PUE"},
          {"name":"QUINCHÍA","code":"COL-CO-RIS-QUI"},
          {"name":"SANTA ROSA DE CABAL","code":"COL-CO-RIS-SAN"},
          {"name":"SANTUARIO","code":"COL-CO-RIS-SAN"},
        ]
      },
      {
        name: 'San Andrés y Providencia',
        code: 'CO-SAP',
        cities: [{"name":"PROVIDENCIA","code":"COL-CO-SAP-PRO"},]
      },
      {
        name: 'Santander',
        code: 'CO-SAN',
        cities: [
          {"name":"AGUADA","code":"COL-CO-SAN-AGU"},
          {"name":"ALBANIA","code":"COL-CO-SAN-ALB"},
          {"name":"ARATOCA","code":"COL-CO-SAN-ARA"},
          {"name":"BARBOSA","code":"COL-CO-SAN-BAR"},
          {"name":"BARICHARA","code":"COL-CO-SAN-BAR"},
          {"name":"BARRANCABERMEJA","code":"COL-CO-SAN-BAR"},
          {"name":"BETULIA","code":"COL-CO-SAN-BET"},
          {"name":"BOLÍVAR","code":"COL-CO-SAN-BOL"},
          {"name":"BUCARAMANGA","code":"CO-SAN-TMP163757"},
          {"name":"CABRERA","code":"COL-CO-SAN-CAB"},
          {"name":"CALIFORNIA","code":"CO-SAN-N6A"},
          {"name":"CAPITANEJO","code":"COL-CO-SAN-CAP"},
          {"name":"CARCASÍ","code":"COL-CO-SAN-CAR"},
          {"name":"CEPITA","code":"COL-CO-SAN-CEP"},
          {"name":"CERRITO","code":"COL-CO-SAN-CER"},
          {"name":"CHARALÁ","code":"COL-CO-SAN-CHA"},
          {"name":"CHARTA","code":"COL-CO-SAN-CHA"},
          {"name":"CHIMÁ","code":"COL-CO-SAN-CHI"},
          {"name":"CHIPATÁ","code":"COL-CO-SAN-CHI"},
          {"name":"CIMITARRA","code":"COL-CO-SAN-CIM"},
          {"name":"CONCEPCIÓN","code":"COL-CO-SAN-CON"},
          {"name":"CONFINES","code":"COL-CO-SAN-CON"},
          {"name":"CONTRATACIÓN","code":"COL-CO-SAN-CON"},
          {"name":"COROMORO","code":"COL-CO-SAN-COR"},
          {"name":"CURITÍ","code":"COL-CO-SAN-CUR"},
          {"name":"EL CARMEN DE CHUCURÍ","code":"COL-CO-SAN-ELC"},
          {"name":"EL GUACAMAYO","code":"COL-CO-SAN-ELG"},
          {"name":"EL PEÑÓN","code":"COL-CO-SAN-ELP"},
          {"name":"EL PLAYÓN","code":"COL-CO-SAN-ELP"},
          {"name":"ENCINO","code":"COL-CO-SAN-ENC"},
          {"name":"ENCISO","code":"COL-CO-SAN-ENC"},
          {"name":"FLORIÁN","code":"COL-CO-SAN-FLO"},
          {"name":"FLORIDABLANCA","code":"CO-SAN-FLC"},
          {"name":"GALÁN","code":"COL-CO-SAN-GAL"},
          {"name":"GAMBITA","code":"COL-CO-SAN-GAM"},
          {"name":"GIRÓN","code":"CO-SAN-GIN"},
          {"name":"GUACA","code":"COL-CO-SAN-GUA"},
          {"name":"GUADALUPE","code":"COL-CO-SAN-GUA"},
          {"name":"GUAPOTA","code":"COL-CO-SAN-GUA"},
          {"name":"GUAVATÁ","code":"COL-CO-SAN-GUA"},
          {"name":"GÜEPSA","code":"COL-CO-SAN-GÜE"},
          {"name":"HATO","code":"COL-CO-SAN-HAT"},
          {"name":"JESÚS MARÍA","code":"COL-CO-SAN-JES"},
          {"name":"JORDÁN","code":"COL-CO-SAN-JOR"},
          {"name":"LA BELLEZA","code":"COL-CO-SAN-LAB"},
          {"name":"LA PAZ","code":"COL-CO-SAN-LAP"},
          {"name":"LANDÁZURI","code":"COL-CO-SAN-LAN"},
          {"name":"LEBRIJA","code":"CO-SAN-LEB"},
          {"name":"LOS SANTOS ","code":"COL-CO-SAN-LOS"},
          {"name":"MACARAVITA","code":"COL-CO-SAN-MAC"},
          {"name":"MÁLAGA","code":"COL-CO-SAN-MÁL"},
          {"name":"MATANZA","code":"COL-CO-SAN-MAT"},
          {"name":"MOGOTES","code":"COL-CO-SAN-MOG"},
          {"name":"MOLAGAVITA","code":"COL-CO-SAN-MOL"},
          {"name":"OCAMONTE","code":"COL-CO-SAN-OCA"},
          {"name":"OIBA","code":"COL-CO-SAN-OIB"},
          {"name":"ONZAGA","code":"COL-CO-SAN-ONZ"},
          {"name":"PALMAR","code":"COL-CO-SAN-PAL"},
          {"name":"PALMAS DEL SOCORRO","code":"COL-CO-SAN-PAL"},
          {"name":"PARAMO","code":"COL-CO-SAN-PAR"},
          {"name":"PIEDECUESTA","code":"CO-SAN-PIE"},
          {"name":"PINCHOTE","code":"COL-CO-SAN-PIN"},
          {"name":"PUENTE NACIONAL","code":"COL-CO-SAN-PUE"},
          {"name":"PUERTO PARRA","code":"COL-CO-SAN-PUE"},
          {"name":"PUERTO WILCHES","code":"CO-SAN-PWI"},
          {"name":"RIONEGRO","code":"COL-CO-SAN-RIO"},
          {"name":"SABANA DE TORRES","code":"CO-SAN-SNT"},
          {"name":"SAN ANDRÉS","code":"COL-CO-SAN-SAN"},
          {"name":"SAN BENITO","code":"COL-CO-SAN-SAN"},
          {"name":"SAN GIL","code":"COL-CO-SAN-SAN"},
          {"name":"SAN JOAQUIN","code":"COL-CO-SAN-SAN"},
          {"name":"SAN JOSÉ DE MIRANDA","code":"COL-CO-SAN-SAN"},
          {"name":"SAN MIGUEL","code":"COL-CO-SAN-SAN"},
          {"name":"SAN VICENTE DEL CHUCURÍ","code":"COL-CO-SAN-SAN"},
          {"name":"SANTA BÁRBARA","code":"COL-CO-SAN-SAN"},
          {"name":"SANTA HELENA DE OPON","code":"COL-CO-SAN-SAN"},
          {"name":"SIMACOTA","code":"COL-CO-SAN-SIM"},
          {"name":"SOCORRO","code":"COL-CO-SAN-SOC"},
          {"name":"SUAITA","code":"COL-CO-SAN-SUA"},
          {"name":"SUCRE","code":"COL-CO-SAN-SUC"},
          {"name":"SURATA","code":"COL-CO-SAN-SUR"},
          {"name":"TONA","code":"COL-CO-SAN-TON"},
          {"name":"VALLE DE SAN JOSÉ","code":"COL-CO-SAN-VAL"},
          {"name":"VÉLEZ","code":"CO-SAN-VEL"},
          {"name":"VETAS","code":"COL-CO-SAN-VET"},
          {"name":"VILLANUEVA","code":"COL-CO-SAN-VIL"},
          {"name":"ZAPATOCA","code":"CO-SAN-AZT"},
        ]
      },
      {
        name: 'Sucre',
        code: 'CO-SUC',
        cities: [
          {"name":"BUENAVISTA","code":"COL-CO-SUC-BUE"},
          {"name":"CAIMITO","code":"COL-CO-SUC-CAI"},
          {"name":"CHALAN","code":"COL-CO-SUC-CHA"},
          {"name":"COLOSO","code":"COL-CO-SUC-COL"},
          {"name":"COROZAL","code":"COL-CO-SUC-COR"},
          {"name":"COVEÑAS","code":"CO-SUC-CVE"},
          {"name":"EL ROBLE","code":"COL-CO-SUC-ELR"},
          {"name":"GALERAS","code":"CO-SUC-GRL"},
          {"name":"GUARANDA","code":"COL-CO-SUC-GUA"},
          {"name":"LA UNIÓN","code":"COL-CO-SUC-LAU"},
          {"name":"LOS PALMITOS","code":"COL-CO-SUC-LOS"},
          {"name":"MAJAGUAL","code":"CO-SUC-MAJ"},
          {"name":"MORROA","code":"COL-CO-SUC-MOR"},
          {"name":"OVEJAS","code":"CO-SUC-OVE"},
          {"name":"SAMPUES","code":"CO-SUC-SAP"},
          {"name":"SAN ANTONIO DE PALMITO","code":"COL-CO-SUC-SAN"},
          {"name":"SAN BENITO ABAD","code":"COL-CO-SUC-SAN"},
          {"name":"SAN JUAN DE BETULIA","code":"COL-CO-SUC-SAN"},
          {"name":"SAN MARCOS","code":"CO-SUC-SRS"},
          {"name":"SAN ONOFRE","code":"CO-SUC-SON"},
          {"name":"SAN PEDRO","code":"CO-SUC-SPR"},
          {"name":"SANTIAGO DE TOLÚ","code":"CO-SUC-STT"},
          {"name":"SINCÉ","code":"COL-CO-SUC-SIN"},
          {"name":"SINCELEJO","code":"CO-SUC-SIJ"},
          {"name":"SUCRE","code":"CO-SUC-SUC"},
          {"name":"TOLUVIEJO","code":"CO-SUC-TOV"},
        ]
      },
      {
        name: 'Tolima',
        code: 'CO-TOL',
        cities: [
          {"name":"ALPUJARRA","code":"COL-CO-TOL-ALP"},
          {"name":"ALVARADO","code":"COL-CO-TOL-ALV"},
          {"name":"AMBALEMA","code":"COL-CO-TOL-AMB"},
          {"name":"ANZOÁTEGUI","code":"COL-CO-TOL-ANZ"},
          {"name":"ARMERO","code":"CO-TOL-AGY"},
          {"name":"ATACO","code":"COL-CO-TOL-ATA"},
          {"name":"CAJAMARCA","code":"COL-CO-TOL-CAJ"},
          {"name":"CARMEN DE APICALÁ","code":"COL-CO-TOL-CAR"},
          {"name":"CASABIANCA","code":"COL-CO-TOL-CAS"},
          {"name":"CHAPARRAL","code":"CO-TOL-CPL"},
          {"name":"COELLO","code":"COL-CO-TOL-COE"},
          {"name":"COYAIMA","code":"COL-CO-TOL-COY"},
          {"name":"CUNDAY","code":"COL-CO-TOL-CUN"},
          {"name":"DOLORES","code":"COL-CO-TOL-DOL"},
          {"name":"EL ESPINAL","code":"CO-TOL-TOL"},
          {"name":"EL GUAMO","code":"COL-CO-TOL-ELG"},
          {"name":"FALAN","code":"COL-CO-TOL-FAL"},
          {"name":"FLANDES","code":"COL-CO-TOL-FLA"},
          {"name":"FRESNO","code":"COL-CO-TOL-FRE"},
          {"name":"HERVEO","code":"COL-CO-TOL-HER"},
          {"name":"HONDA","code":"COL-CO-TOL-HON"},
          {"name":"IBAGUE","code":"CO-TOL-IBE"},
          {"name":"ICONONZO","code":"COL-CO-TOL-ICO"},
          {"name":"LÉRIDA","code":"COL-CO-TOL-LÉR"},
          {"name":"LIBANO","code":"COL-CO-TOL-LIB"},
          {"name":"MARIQUITA","code":"CO-TOL-MQU"},
          {"name":"MELGAR","code":"COL-CO-TOL-MEL"},
          {"name":"MURILLO","code":"COL-CO-TOL-MUR"},
          {"name":"NATAGAIMA","code":"COL-CO-TOL-NAT"},
          {"name":"ORTEGA","code":"COL-CO-TOL-ORT"},
          {"name":"PALOCABILDO","code":"COL-CO-TOL-PAL"},
          {"name":"PIEDRAS","code":"COL-CO-TOL-PIE"},
          {"name":"PLANADAS","code":"COL-CO-TOL-PLA"},
          {"name":"PRADO","code":"COL-CO-TOL-PRA"},
          {"name":"PURIFICACIÓN","code":"COL-CO-TOL-PUR"},
          {"name":"RIOBLANCO","code":"COL-CO-TOL-RIO"},
          {"name":"RONCESVALLES","code":"COL-CO-TOL-RON"},
          {"name":"ROVIRA","code":"COL-CO-TOL-ROV"},
          {"name":"SALDAÑA","code":"COL-CO-TOL-SAL"},
          {"name":"SAN ANTONIO","code":"COL-CO-TOL-SAN"},
          {"name":"SAN LUIS","code":"COL-CO-TOL-SAN"},
          {"name":"SANTA ISABEL","code":"COL-CO-TOL-SAN"},
          {"name":"SUAREZ","code":"COL-CO-TOL-SUA"},
          {"name":"VALLE DE SAN JUAN","code":"COL-CO-TOL-VAL"},
          {"name":"VENADILLO","code":"COL-CO-TOL-VEN"},
          {"name":"VILLAHERMOSA","code":"COL-CO-TOL-VIL"},
          {"name":"VILLARRICA","code":"COL-CO-TOL-VIL"},
        ]
      },
      {
        name: 'Valle del Cauca',
        code: 'CO-VAC',
        cities: [
          {"name":"ALCALÁ","code":"COL-CO-VAC-ALC"},
          {"name":"ANDALUCÍA","code":"COL-CO-VAC-AND"},
          {"name":"ANSERMANUEVO","code":"COL-CO-VAC-ANS"},
          {"name":"ARGELIA","code":"COL-CO-VAC-ARG"},
          {"name":"BOLÍVAR","code":"COL-CO-VAC-BOL"},
          {"name":"BUENAVENTURA","code":"COL-CO-VAC-BUE"},
          {"name":"BUGALAGRANDE","code":"COL-CO-VAC-BUG"},
          {"name":"CAICEDONIA","code":"COL-CO-VAC-CAI"},
          {"name":"CALIMA DEL DARIÉN","code":"COL-CO-VAC-CAL"},
          {"name":"CANDELARIA","code":"CO-VAC-CAN"},
          {"name":"CARTAGO","code":"COL-CO-VAC-CAR"},
          {"name":"DAGUA","code":"COL-CO-VAC-DAG"},
          {"name":"EL ÁGUILA","code":"COL-CO-VAC-ELÁ"},
          {"name":"EL CAIRO","code":"COL-CO-VAC-ELC"},
          {"name":"EL CERRITO","code":"COL-CO-VAC-ELC"},
          {"name":"EL DOVIO","code":"COL-CO-VAC-ELD"},
          {"name":"FLORIDA","code":"COL-CO-VAC-FLO"},
          {"name":"GINEBRA","code":"COL-CO-VAC-GIN"},
          {"name":"GUACARÍ","code":"COL-CO-VAC-GUA"},
          {"name":"GUADALAJARA DE BUGA","code":"COL-CO-VAC-GUA"},
          {"name":"JAMUNDÍ","code":"COL-CO-VAC-JAM"},
          {"name":"LA CUMBRE","code":"COL-CO-VAC-LAC"},
          {"name":"LA UNIÓN","code":"COL-CO-VAC-LAU"},
          {"name":"LA VICTORIA","code":"COL-CO-VAC-LAV"},
          {"name":"OBANDO","code":"COL-CO-VAC-OBA"},
          {"name":"PALMIRA","code":"COL-CO-VAC-PAL"},
          {"name":"PRADERA","code":"COL-CO-VAC-PRA"},
          {"name":"RESTREPO","code":"COL-CO-VAC-RES"},
          {"name":"RIOFRIO","code":"COL-CO-VAC-RIO"},
          {"name":"ROLDANILLO","code":"COL-CO-VAC-ROL"},
          {"name":"SAN PEDRO","code":"COL-CO-VAC-SAN"},
          {"name":"SANTIAGO DE CALI","code":"CO-VAC-TMP163955"},
          {"name":"SEVILLA","code":"COL-CO-VAC-SEV"},
          {"name":"TORO","code":"COL-CO-VAC-TOR"},
          {"name":"TRUJILLO","code":"COL-CO-VAC-TRU"},
          {"name":"TULUÁ","code":"CO-VAC-ULQ"},
          {"name":"ULLOA","code":"COL-CO-VAC-ULL"},
          {"name":"VERSALLES","code":"COL-CO-VAC-VER"},
          {"name":"VIJES","code":"COL-CO-VAC-VIJ"},
          {"name":"YOTOCO","code":"COL-CO-VAC-YOT"},
          {"name":"YUMBO","code":"COL-CO-VAC-YUM"},
          {"name":"ZARZAL","code":"COL-CO-VAC-ZAR"},
        ]
      },
      {
        name: 'Vaupés',
        code: 'CO-VAU',
        cities: [
          {"name":"CARURU","code":"CO-VAU-CUO"},
          {"name":"MITÚ","code":"CO-VAU-MVP"},
          {"name":"TARAIRA","code":"COL-CO-VAU-TAR"},
        ]
      },
      {
        name: 'Vichada',
        code: 'CO-VID',
        cities: [
          {"name":"CUMARIBO","code":"COL-CO-VID-CUM"},
          {"name":"LA PRIMAVERA","code":"COL-CO-VID-LAP"},
          {"name":"PUERTO CARREÑO","code":"CO-VID-PCR"},
          {"name":"SANTA ROSALÍA","code":"COL-CO-VID-SAN"},
        ]
      }
    ]
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    dialCode: '+506',
    flag: 'flag_costa-rica.png',
    states: [
      {
        name: 'Alajuela',
        code: 'CR-A',
        cities: [{"name":"AGUAS ZARCAS","code":"CR-A-AZA"},
          {"name":"ALAJUELA","code":"CR-A-AJU"},
          {"name":"ALTAMIRA","code":"CR-A-ATM"},
          {"name":"ATENAS","code":"CR-A-ATE"},
          {"name":"BARRANCA","code":"CR-A-BNC"},
          {"name":"BELLA VISTA","code":"CR-A-BVT"},
          {"name":"BOCA ARENAL","code":"CR-A-BAL"},
          {"name":"BUENOS AIRES","code":"CR-A-BSA"},
          {"name":"CAÑO NEGRO","code":"CR-A-CNG"},
          {"name":"CASCAJAL","code":"CR-A-CJS"},
          {"name":"CERRO CORTEZ","code":"CR-A-CZR"},
          {"name":"CHACHAGUA","code":"CR-A-CHA"},
          {"name":"CINCHONA","code":"CR-A-CNA"},
          {"name":"CIRUELAS","code":"CR-A-CIR"},
          {"name":"COYOL","code":"CR-A-CYL"},
          {"name":"COYOLAR","code":"CR-A-CYR"},
          {"name":"CUTRIS","code":"CR-A-CUT"},
          {"name":"FLORENCIA DE SAN CARLOS","code":"CR-A-AFS"},
          {"name":"FRAIJANES","code":"CR-A-FPN"},
          {"name":"GRECIA","code":"CR-A-GMI"},
          {"name":"GUÁCIMA","code":"CR-A-GCA"},
          {"name":"HACIENDA CORONADO","code":"CR-A-HCD"},
          {"name":"JABILLOS","code":"CR-A-JAB"},
          {"name":"LA CEIBA DE OROTINA","code":"CR-A-LCO"},
          {"name":"LA FORTUNA","code":"CR-A-LAF"},
          {"name":"LA GARITA","code":"CR-A-LAG"},
          {"name":"LEGUA","code":"CR-A-LEG"},
          {"name":"LOS CHILES","code":"CR-A-LSL"},
          {"name":"MUELLE DE SAN CARLOS","code":"CR-A-MUE"},
          {"name":"OROTINA","code":"CR-A-ORO"},
          {"name":"PALMARES","code":"CR-A-PAM"},
          {"name":"PAVAS","code":"CR-A-TMP26015"},
          {"name":"PEÑAS BLANCAS","code":"CR-A-PBS"},
          {"name":"PITAL","code":"CR-A-PTL"},
          {"name":"PITAL CON DESVIO","code":"CR-A-APO"},
          {"name":"POAS","code":"CR-A-POA"},
          {"name":"POCOSOL","code":"CR-A-PCS"},
          {"name":"QUESADA","code":"CR-A-CQA"},
          {"name":"RÍO CUARTO","code":"CR-A-RCD"},
          {"name":"RIO SEGUNDO","code":"CR-A-RIS"},
          {"name":"SAN ISIDRO DE PENAS BLANCAS","code":"CR-A-SIO"},
          {"name":"SAN LUIS","code":"CR-A-SLU"},
          {"name":"SAN PEDRO DE POAS","code":"CR-A-SSP"},
          {"name":"SAN RAFAEL GUATUSO","code":"CR-A-SRG"},
          {"name":"SANTA MARIA/POCOSOL","code":"CR-A-SMP"},
          {"name":"SANTA RITA","code":"CR-A-SRA"},
          {"name":"SANTA RITA","code":"CR-A-STR"},
          {"name":"SANTA ROSA DE POCOSOL","code":"CR-A-ASL"},
          {"name":"SANTA ROSA/POCOSOL","code":"CR-A-SRP"},
          {"name":"SARCHÍ","code":"CR-A-SRI"},
          {"name":"TIGRA","code":"CR-A-LTA"},
          {"name":"TURRUBARES","code":"CR-A-TUA"},
          {"name":"TURRUCARES","code":"CR-A-TRR"},
          {"name":"UJARRÁS","code":"CR-A-UJA"},
          {"name":"UPALA","code":"CR-A-UPA"},
          {"name":"VENECIA","code":"CR-A-VA2"},
          {"name":"VERACRUZ","code":"CR-A-VER"},
          {"name":"ZARCERO / NARANJO","code":"CR-A-ZAR"},]
      },
      {
        name: 'Cartago',
        code: 'CR-C',
        cities: [{"name":"CACHÍ","code":"CR-C-CCI"},
          {"name":"CARTAGO","code":"CR-C-CAR"},
          {"name":"CERVANTES","code":"CR-C-CCC"},
          {"name":"CORRALILLO","code":"CR-C-CRL"},
          {"name":"EL TEJAR","code":"CR-C-TMP26041"},
          {"name":"GUATUSO","code":"CR-C-GUA"},
          {"name":"LLANO GRANDE","code":"CR-C-LGR"},
          {"name":"MORAVIA","code":"CR-C-CTC"},
          {"name":"OCHOMOGO","code":"CR-C-OMO"},
          {"name":"OREAMUNO","code":"CR-C-ORE"},
          {"name":"OROSI","code":"CR-C-OSI"},
          {"name":"PACAYAS","code":"CR-C-CPS"},
          {"name":"PARAÍSO","code":"CR-C-PSO"},
          {"name":"PLAYA HERMOSA","code":"CR-C-CPA"},
          {"name":"TAPANTI","code":"CR-C-TAP"},
          {"name":"TIERRA BLANCA","code":"CR-C-TBC"},
          {"name":"TRES RÍOS","code":"CR-C-TRE"},
          {"name":"TUCURRIQUE","code":"CR-C-TUC"},
          {"name":"TURRIALBA","code":"CR-C-TUR"},
          {"name":"UJARRÁS","code":"CR-C-URS"},]
      },
      {
        name: 'Guanacaste',
        code: 'CR-G',
        cities: [{"name":"ABANGARES","code":"CR-G-ABG"},
          {"name":"BAGACES","code":"CR-G-BAG"},
          {"name":"BARBUDAL","code":"CR-G-BBD"},
          {"name":"BELÉN","code":"CR-G-BLN"},
          {"name":"CAIMITAL","code":"CR-G-CAI"},
          {"name":"CAÑAS","code":"CR-G-CAS"},
          {"name":"CANJEL","code":"CR-G-CJL"},
          {"name":"CARRILLO","code":"CR-G-RIK"},
          {"name":"COCO","code":"CR-G-COC"},
          {"name":"CURUBANDÉ","code":"CR-G-CUB"},
          {"name":"EL SILENCIO","code":"CR-G-ELS"},
          {"name":"FILADELFIA","code":"CR-G-FIL"},
          {"name":"FLAMINGO","code":"CR-G-FMG"},
          {"name":"FORTUNA","code":"CR-G-FON"},
          {"name":"GOLFO DE PAPAGAYO","code":"CR-G-GDP"},
          {"name":"GUARDIA","code":"CR-G-GDA"},
          {"name":"LA CRUZ","code":"CR-G-LCZ"},
          {"name":"LAS HUACAS","code":"CR-G-LHS"},
          {"name":"LIBERIA","code":"CR-G-LIR"},
          {"name":"LIMONAL","code":"CR-G-LIM"},
          {"name":"MANSIÓN","code":"CR-G-MSN"},
          {"name":"NACASCOLO","code":"CR-G-GAC"},
          {"name":"NANDAYURE","code":"CR-G-NAN"},
          {"name":"NICOYA","code":"CR-G-NCT"},
          {"name":"NOSARA BEACH","code":"CR-G-NOB"},
          {"name":"PASO REAL","code":"CR-G-PRL"},
          {"name":"PAVONES","code":"CR-G-PVN"},
          {"name":"PILAS DE CANJEL","code":"CR-G-PIL"},
          {"name":"PLAYA CONCHAL","code":"CR-G-PCL"},
          {"name":"PLAYA SAMARA","code":"CR-G-PLD"},
          {"name":"PUERTO COYOTE","code":"CR-G-COY"},
          {"name":"PUNTA ISLITA","code":"CR-G-PBP"},
          {"name":"QUEBRADA GRANDE","code":"CR-G-QGG"},
          {"name":"QUEBRADA HONDA","code":"CR-G-GHA"},
          {"name":"SÁMARA","code":"CR-G-SAM"},
          {"name":"SAN FRANCISCO DE COYOTE","code":"CR-G-SFC"},
          {"name":"SANTA CECILIA","code":"CR-G-SCC"},
          {"name":"SANTA CRUZ","code":"CR-G-SCZ"},
          {"name":"SANTA RITA","code":"CR-G-RIT"},
          {"name":"SARDINAL","code":"CR-G-SAR"},
          {"name":"TAMARINDO","code":"CR-G-TAM"},
          {"name":"TILARAN","code":"CR-G-TIL"},]
      },
      {
        name: 'Heredia',
        code: 'CR-H',
        cities: [{"name":"BARRIAL","code":"CR-H-BRL"},
          {"name":"BARVA","code":"CR-H-BAV"},
          {"name":"HEREDIA","code":"CR-H-HER"},
          {"name":"HORLUETAS/SARAPIQUI","code":"CR-H-HSA"},
          {"name":"LA ASUNCIÓN","code":"CR-H-LAN"},
          {"name":"LA RIBERA","code":"CR-H-RBR"},
          {"name":"LA VIRGEN","code":"CR-H-LAV"},
          {"name":"LAGUNILLA","code":"CR-H-LAA"},
          {"name":"PUERTO VIEJO DE SARAPIQUI","code":"CR-H-PVS"},
          {"name":"SAN ANTONIO","code":"CR-H-SAB"},
          {"name":"SAN ISIDRO","code":"CR-H-SID"},
          {"name":"SAN JOAQUÍN","code":"CR-H-SJN"},
          {"name":"SAN LUIS","code":"CR-H-SNL"},
          {"name":"SANTA BARBARAA","code":"CR-H-HSB"},
          {"name":"SANTO DOMINGO","code":"CR-H-SDG"},
          {"name":"SARAPIQUÍ","code":"CR-H-SPI"},
          {"name":"ZAPOTE","code":"CR-H-ZAP"},]
      },
      {
        name: 'Limón',
        code: 'CR-L',
        cities: [{"name":"AGUAS ZARCAS","code":"CR-L-AGU"},
          {"name":"BARRA DEL COLORADO","code":"CR-L-BCL"},
          {"name":"BATAN","code":"CR-L-BAT"},
          {"name":"BRI BRI","code":"CR-L-BRI"},
          {"name":"BÚFFALO","code":"CR-L-BFL"},
          {"name":"CAHUITA","code":"CR-L-CHT"},
          {"name":"FRANCIA","code":"CR-L-FRA"},
          {"name":"GUACIMO","code":"CR-L-GUC"},
          {"name":"GUÁPILES","code":"CR-L-CPL"},
          {"name":"JIMENEZ","code":"CR-L-JIM"},
          {"name":"LIMON VIA TURRIALBA","code":"CR-L-LLA"},
          {"name":"MATINA","code":"CR-L-MAT"},
          {"name":"MOÍN","code":"CR-L-MOB"},
          {"name":"PANDORA","code":"CR-L-PRA"},
          {"name":"PARISMINA","code":"CR-L-L6G"},
          {"name":"PENSHURST","code":"CR-L-PEN"},
          {"name":"POCORA","code":"CR-L-POC"},
          {"name":"PUERTO CARRILLO","code":"CR-L-PUC"},
          {"name":"PUERTO LIMÓN","code":"CR-L-LIO"},
          {"name":"PUERTO MOIN","code":"CR-L-PMN"},
          {"name":"PUERTO VIEJO","code":"CR-L-VDT"},
          {"name":"RIO JIMENEZ","code":"CR-L-RJI"},
          {"name":"ROXANA","code":"CR-L-ROX"},
          {"name":"SAN ALBERTO NUEVO","code":"CR-L-SAL"},
          {"name":"SANDOVAL","code":"CR-L-SVL"},
          {"name":"SIQUIRRES","code":"CR-L-SQR"},
          {"name":"SIXAOLA/TALAMANCA","code":"CR-L-SIX"},
          {"name":"TALAMANCA","code":"CR-L-TNC"},
          {"name":"TORTUGUERO","code":"CR-L-TTQ"},
          {"name":"VENECIA","code":"CR-L-FDF"},
          {"name":"VILLA FRANCA","code":"CR-L-VF2"},]
      },
      {
        name: 'Puntarenas',
        code: 'CR-P',
        cities: [{"name":"ABANGARITOS","code":"CR-P-ABA"},
          {"name":"ARANJUEZ","code":"CR-P-AJZ"},
          {"name":"BARRANCA","code":"CR-P-BAR"},
          {"name":"BIJAGUAL","code":"CR-P-BIJ"},
          {"name":"BOCA BARRANCA","code":"CR-P-BBA"},
          {"name":"CABO BLANCO","code":"CR-P-CAB"},
          {"name":"CABUYA COBANO","code":"CR-P-CBU"},
          {"name":"CALDERA","code":"CR-P-CAL"},
          {"name":"CANOAS","code":"CR-P-CAN"},
          {"name":"CAPULÍN","code":"CR-P-CAP"},
          {"name":"CARRIZAL","code":"CR-P-CAZ"},
          {"name":"CHOMES","code":"CR-P-COM"},
          {"name":"COBANO","code":"CR-P-CBN"},
          {"name":"CORONADO","code":"CR-P-COR"},
          {"name":"COTO 47","code":"CR-P-OTR"},
          {"name":"DOMINICAL","code":"CR-P-DOM"},
          {"name":"ESPARZA","code":"CR-P-ESP"},
          {"name":"GOLFITO","code":"CR-P-GLF"},
          {"name":"HERRADURA","code":"CR-P-HDA"},
          {"name":"JACÓ","code":"CR-P-JAC"},
          {"name":"JICARAL","code":"CR-P-JIC"},
          {"name":"LA LUCHA","code":"CR-P-LLC"},
          {"name":"LA TIGRA","code":"CR-P-TIG"},
          {"name":"LAUREL","code":"CR-P-PLL"},
          {"name":"LEPANTO","code":"CR-P-LPT"},
          {"name":"MATAPALO","code":"CR-P-MAO"},
          {"name":"MIRAMAR","code":"CR-P-MMR"},
          {"name":"NEILY","code":"CR-P-CDN"},
          {"name":"OJOCHAL","code":"CR-P-OJO"},
          {"name":"PALMAR","code":"CR-P-PMZ"},
          {"name":"PALMAR NORTE","code":"CR-P-PAN"},
          {"name":"PAQUERA","code":"CR-P-PQR"},
          {"name":"PARRITA","code":"CR-P-PAR"},
          {"name":"PITAL","code":"CR-P-PIT"},
          {"name":"PLATANARES","code":"CR-P-PTN"},
          {"name":"POTRERO GRANDE","code":"CR-P-OGE"},
          {"name":"PUERTO CALDERA","code":"CR-P-PTC"},
          {"name":"PUERTO JIMENEZ","code":"CR-P-PJM"},
          {"name":"PUNTA MORALES","code":"CR-P-PUM"},
          {"name":"PUNTARENAS","code":"CR-P-PAS"},
          {"name":"RÍO FRÍO","code":"CR-P-RIO"},
          {"name":"SABALITO","code":"CR-P-SAO"},
          {"name":"SALINAS","code":"CR-P-SLN"},
          {"name":"SAN VITO","code":"CR-P-TOO"},
          {"name":"TAMBOR","code":"CR-P-TBR"},
          {"name":"TARCOLES","code":"CR-P-TCS"},
          {"name":"UVITA","code":"CR-P-UTA"},
          {"name":"VERACRUZ","code":"CR-P-PVZ"},]
      },
      {
        name: 'San José',
        code: 'CR-SJ',
        cities: [{"name":"ALAJUELITA","code":"CR-SJ-ALI"},
          {"name":"CALLE BLANCOS","code":"CR-SJ-CAE"},
          {"name":"COLÓN","code":"CR-SJ-CLN"},
          {"name":"CURRIDABAT","code":"CR-SJ-CRR"},
          {"name":"DESAMPARADOS","code":"CR-SJ-DE2"},
          {"name":"ESCAZÚ","code":"CR-SJ-ESC"},
          {"name":"GUATUSO","code":"CR-SJ-GTS"},
          {"name":"HATILLO","code":"CR-SJ-TMP26167"},
          {"name":"ISLA DE TIBAS","code":"CR-SJ-TMP26169"},
          {"name":"JARIS","code":"CR-SJ-JAR"},
          {"name":"LIMON","code":"CR-SJ-LMN"},
          {"name":"NARANJO","code":"CR-SJ-NAR"},
          {"name":"PALMARES","code":"CR-SJ-PAL"},
          {"name":"PALMICHAL","code":"CR-SJ-PML"},
          {"name":"PAVAS","code":"CR-SJ-PVA"},
          {"name":"PEJIBAYE","code":"CR-SJ-PEJ"},
          {"name":"SAN CARLOS","code":"CR-SJ-SCS"},
          {"name":"SAN FRANCISCO DE DOS RIOS","code":"CR-SJ-SFO"},
          {"name":"SAN IGNACIO DE ACOSTA","code":"CR-SJ-SAN"},
          {"name":"SAN ISIDRO","code":"CR-SJ-SIS"},
          {"name":"SAN JOSÉ","code":"CR-SJ-SJO"},
          {"name":"SAN MARCOS DE TARRAZU","code":"CR-SJ-MTZ"},
          {"name":"SAN PABLO DE TURRUBARES","code":"CR-SJ-SPT"},
          {"name":"SAN RAFAEL","code":"CR-SJ-SRF"},
          {"name":"SAN RAFAEL DE ESCAZÚ","code":"CR-SJ-SRE"},
          {"name":"SAN VICENTE DE MORAVIA","code":"CR-SJ-TMP26199"},
          {"name":"SANTA ANA","code":"CR-SJ-ANA"},
          {"name":"SANTA CRUZ","code":"CR-SJ-SSC"},
          {"name":"SANTA ELENA","code":"CR-SJ-ENA"},
          {"name":"SANTA MARIA DE DOTA","code":"CR-SJ-SMD"},
          {"name":"SANTA ROSA","code":"CR-SJ-ROS"},
          {"name":"SANTIAGO DE PURISCAL","code":"CR-SJ-SPU"},
          {"name":"TABARCA","code":"CR-SJ-TCA"},
          {"name":"TARBACA","code":"CR-SJ-TAR"},
          {"name":"TEJAR","code":"CR-SJ-TJR"},
          {"name":"VUELTA DE JORCO","code":"CR-SJ-VDJ"},]
      },
    ]
  },
  {
    name: 'Curacao',
    code: 'CW',
    dialCode: '+599',
    flag: 'flag_curacao.png',
    states: [
      {
        name: 'Curacao',
        code: 'CW-CW',
        cities: []
      }
    ]
  },
  {
    name: 'Dominica',
    code: 'DM',
    dialCode: '+1-767',
    flag: 'flag_dominica.png',
    states: [
      {
        name: 'Saint Andrew',
        code: 'DM-02',
        cities: []
      },
      {
        name: 'Saint David',
        code: 'DM-03',
        cities: []
      },
      {
        name: 'Saint George',
        code: 'DM-04',
        cities: []
      },
      {
        name: 'Saint John',
        code: 'DM-05',
        cities: []
      },
      {
        name: 'Saint Joseph',
        code: 'DM-06',
        cities: []
      },
      {
        name: 'Saint Luke',
        code: 'DM-07',
        cities: []
      },
      {
        name: 'Saint Mark',
        code: 'DM-08',
        cities: []
      },
      {
        name: 'Saint Patrick',
        code: 'DM-09',
        cities: []
      },
      {
        name: 'Saint Paul',
        code: 'DM-10',
        cities: []
      },
      {
        name: 'Saint Peter',
        code: 'DM-11',
        cities: []
      }
    ]
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    dialCode: '+1-809',
    flag: 'flag_dominican-republic.png',
    states: [
      {
        name: 'Azua',
        code: 'DO-02',
        cities: [{"name":"AZUA","code":"DO-02-AZU"},]
      },
      {
        name: 'Baoruco',
        code: 'DO-03',
        cities: [{"name":"GALVAN","code":"DO-03-TMP29288"},
          {"name":"NEIBA","code":"DO-03-NEI"},]
      },
      {
        name: 'Barahona',
        code: 'DO-04',
        cities: []
      },
      {
        name: 'Cibao Nordeste',
        code: 'DO-33',
        cities: []
      },
      {
        name: 'Cibao Noroeste',
        code: 'DO-34',
        cities: []
      },
      {
        name: 'Cibao Norte',
        code: 'DO-35',
        cities: []
      },
      {
        name: 'Cibao Sur',
        code: 'DO-36',
        cities: []
      },
      {
        name: 'Dajabón',
        code: 'DO-05',
        cities: []
      },
      {
        name: 'Distrito Nacional',
        code: 'DO-01',
        cities: [{"name":"CAUCEDO","code":"DO-01-CAU"},
          {"name":"GUERRA","code":"DO-01-GRA"},
          {"name":"HIGUERO","code":"DO-01-JQB"},
          {"name":"PALABE","code":"DO-01-PLB"},
          {"name":"PEDRO BRAND","code":"DO-01-PDB"},
          {"name":"SAN FELIPE","code":"DO-01-SFP"},
          {"name":"SAN ISIDRO","code":"DO-01-SIO"},
          {"name":"SANTO DOMINGO","code":"DO-01-SDQ"},
          {"name":"SANTO DOMINGO CITY","code":"DO-01-TMP164323"},
          {"name":"SANTO DOMINGO DE GUZMÁN","code":"DO-01-TMP164324"},
          {"name":"VILLA MELLA","code":"DO-01-VML"},]
      },
      {
        name: 'Duarte',
        code: 'DO-06',
        cities: []
      },
      {
        name: 'El Seibo',
        code: 'DO-08',
        cities: []
      },
      {
        name: 'El Valle',
        code: 'DO-37',
        cities: []
      },
      {
        name: 'Elías Piña',
        code: 'DO-09',
        cities: []
      },
      {
        name: 'Enriquillo',
        code: 'DO-38',
        cities: []
      },
      {
        name: 'Espaillat',
        code: 'DO-19',
        cities: [{"name":"GASPAR HERNANDEZ","code":"DO-09-GAZ"},]
      },
      {
        name: 'Hato Mayor',
        code: 'DO-30',
        cities: [{"name":"HATO MAYOR DEL REY","code":"DO-30-HMR"},]
      },
      {
        name: 'Hermanas Mirabal',
        code: 'DO-19',
        cities: []
      },
      {
        name: 'Higuamo',
        code: 'DO-39',
        cities: []
      },
      {
        name: 'Independencia',
        code: 'DO-10',
        cities: []
      },
      {
        name: 'La Alta Gracia',
        code: 'DO-40',
        cities: [{"name":"PUNTA CANA","code":"DO-11-TMP29317"},]
      },
      {
        name: 'La Romana',
        code: 'DO-12',
        cities: [{"name":"CASA DE CAMPO","code":"DO-12-CDC"},
          {"name":"CATALINA ISLAND","code":"DO-12-CAI"},
          {"name":"GUAYMATE","code":"DO-12-TMP29321"},
          {"name":"LA ROMANA","code":"DO-12-TMP29322"},]
      },
      {
        name: 'La Vega',
        code: 'DO-13',
        cities: [{"name":"CONSTANZA","code":"DO-13-LVC"},
          {"name":"JARABACOA","code":"DO-13-JBC"},]
      },
      {
        name: 'Maria Trinidad Sánchez',
        code: 'DO-14',
        cities: []
      },
      {
        name: 'Monseñor Nouel',
        code: 'DO-28',
        cities: [{"name":"BONAO","code":"DO-28-BNO"},
          {"name":"PIEDRA BLANCA","code":"DO-28-PIA"},]
      },
      {
        name: 'Monte Cristi',
        code: 'DO-15',
        cities: [{"name":"GUAYUBÍN","code":"DO-15-GYB"},
          {"name":"PILOTO","code":"DO-15-PIL"},
          {"name":"VILLA VÁSQUEZ","code":"DO-15-VVA"},]
      },
    ]
  },
  {
    name: 'Ecuador',
    code: 'EC',
    dialCode: '+593',
    flag: 'flag_ecuador.png',
    states: [
      {
        name: 'Azuay',
        code: 'EC-A',
        cities: [{"name":"BELLAVISTA","code":"EC-A-TMP29626"},
          {"name":"CUENCA","code":"EC-A-CUE"},
          {"name":"GUALACEO","code":"EC-A-GUO"},
          {"name":"PAUTE","code":"EC-A-PTE"},
          {"name":"SIGSIG","code":"EC-A-TMP29636"},]
      },
      {
        name: 'Bolívar',
        code: 'EC-B',
        cities: [{"name":"ECHEANDIA","code":"EC-B-TMP29490"},
          {"name":"SAN JOSE DE CHIMBO","code":"EC-B-TMP29492"},]
      },
      {
        name: 'Cañar',
        code: 'EC-F',
        cities: [{"name":"LA TRONCAL","code":"EC-F-LTR"},]
      },
      {
        name: 'Carchi',
        code: 'EC-C',
        cities: []
      },
      {
        name: 'Chimborazo',
        code: 'EC-H',
        cities: [{"name":"CHAMBO","code":"EC-H-CHM"},
          {"name":"CUMANDÁ","code":"EC-H-CHI"},
          {"name":"GUANO","code":"EC-H-GNO"},]
      },
      {
        name: 'Cotopaxi',
        code: 'EC-X',
        cities: [{"name":"LA MANÁ","code":"EC-X-LAM"},
          {"name":"LASSO","code":"EC-X-LAS"},
          {"name":"LATACUNGA","code":"EC-X-LAT"},]
      },
      {
        name: 'El Oro',
        code: 'EC-O',
        cities: [{"name":"ARENILLAS","code":"EC-O-ARE"},
          {"name":"HUAQUILLAS","code":"EC-O-HQL"},
          {"name":"MACHALA","code":"EC-O-TMP29506"},
          {"name":"PASAJE","code":"EC-O-PJE"},
          {"name":"PIÑAS","code":"EC-O-PIN"},
          {"name":"PORTOVELO","code":"EC-O-PVL"},
          {"name":"PUERTO BOLÍVAR","code":"EC-O-PBO"},
          {"name":"SANTA ROSA","code":"EC-O-SAR"},]
      },
      {
        name: 'Esmeraldas',
        code: 'EC-E',
        cities: [{"name":"ESMERALDAS","code":"EC-E-ESM"},
          {"name":"LA CONCORDIA","code":"EC-E-LAC"},
          {"name":"QUININDE","code":"EC-E-EQA"},
          {"name":"SAN LORENZO","code":"EC-E-SLR"},]
      },
      {
        name: 'Galápagos',
        code: 'EC-W',
        cities: [{"name":"BARTOLOME ISLAND","code":"EC-W-BLI"},
          {"name":"CALETA TAGUS","code":"EC-W-CAT"},
          {"name":"ESPANOLA ISLAND","code":"EC-W-ESP"},
          {"name":"FLOREANA ISLAND","code":"EC-W-FLO"},
          {"name":"PUERTO AYORA","code":"EC-W-TMP29473"},
          {"name":"PUERTO AYORA, ISLA SANTA CRUZ","code":"EC-W-AYO"},
          {"name":"PUERTO BAQUERIZO MORENO","code":"EC-W-BAQ"},
          {"name":"PUERTO EGAS","code":"EC-W-PEG"},
          {"name":"PUERTO VILLAMIL, ISLA ISABELA","code":"EC-W-VIL"},
          {"name":"RABIDA ISLAND","code":"EC-W-RAB"},
          {"name":"SAN CRISTÓBAL","code":"EC-W-SCY"},
          {"name":"SEYMOUR","code":"EC-W-SYM"},]
      },
      {
        name: 'Guayas',
        code: 'EC-G',
        cities: [{"name":"BALAO","code":"EC-G-TMP29648"},
          {"name":"BALZAR","code":"EC-G-BAL"},
          {"name":"BUCAY","code":"EC-G-EQB"},
          {"name":"CERECITA","code":"EC-G-CRT"},
          {"name":"CHANDUY","code":"EC-G-CHA"},
          {"name":"CHONGÓN","code":"EC-G-CHG"},
          {"name":"CORONEL MARCELINO MARIDUENA","code":"EC-G-CMM"},
          {"name":"DAULE","code":"EC-G-TMP29655"},
          {"name":"DURÁN","code":"EC-G-DUN"},
          {"name":"EL EMPALME","code":"EC-G-EMP"},
          {"name":"GUAYAQUIL","code":"EC-G-GYE"},
          {"name":"GUAYAS","code":"EC-G-GUA"},
          {"name":"HACIENDA GUAYAS","code":"EC-G-HGU"},
          {"name":"JUNQUILLAL","code":"EC-G-JQU"},
          {"name":"LA LIBERTAD","code":"EC-G-LLD"},
          {"name":"MARISCAL SUCRE","code":"EC-G-MSU"},
          {"name":"NARANJAL","code":"EC-G-NAR"},
          {"name":"NARANJITO","code":"EC-G-NRJ"},
          {"name":"PALESTINA","code":"EC-G-PTL"},
          {"name":"PASAJE","code":"EC-G-PAS"},
          {"name":"PASCUALES","code":"EC-G-PSC"},
          {"name":"PLAYAS","code":"EC-G-PYS"},
          {"name":"POSORJA","code":"EC-G-PSJ"},
          {"name":"PROGRESO","code":"EC-G-PRO"},
          {"name":"PUERTO BALAO GRANDE","code":"EC-G-PBG"},
          {"name":"PUNÁ","code":"EC-G-PUN"},
          {"name":"SALINAS","code":"EC-G-SNC"},
          {"name":"SALITRE","code":"EC-G-SAL"},
          {"name":"SAMBORONDON","code":"EC-G-TMP29680"},
          {"name":"TENGUEL","code":"EC-G-TGL"},
          {"name":"VELASCO IBARRA","code":"EC-G-VEI"},
          {"name":"YAGUACHI","code":"EC-G-YGU"},
          {"name":"ZAPOTAL","code":"EC-G-ZTL"},
          {"name":"ZAPOTAL DE COLONCHE","code":"EC-G-ZPT"},]
      },
      {
        name: 'Imbabura',
        code: 'EC-I',
        cities: [{"name":"ATUNTAQUI","code":"EC-I-ATI"},
          {"name":"COTACACHI","code":"EC-I-CTI"},
          {"name":"IBARRA","code":"EC-I-IBA"},]
      },
      {
        name: 'Loja',
        code: 'EC-L',
        cities: [{"name":"MACARÁ","code":"EC-L-MRR"},
          {"name":"VALLE","code":"EC-L-TMP29539"},
          {"name":"VALLE HERMOSO","code":"EC-L-VHS"},]
      },
      {
        name: 'Los Ríos',
        code: 'EC-R',
        cities: [{"name":"BABA","code":"EC-R-BBA"},
          {"name":"BABAHOYO","code":"EC-R-BYO"},
          {"name":"BUENA FE","code":"EC-R-BUF"},
          {"name":"CATARAMA","code":"EC-R-CTM"},
          {"name":"LA UNIÓN","code":"EC-R-LUN"},
          {"name":"MONTALVO","code":"EC-R-MTO"},
          {"name":"PALENQUE","code":"EC-R-PLQ"},
          {"name":"PATRICIA PILAR","code":"EC-R-PPI"},
          {"name":"PUEBLOVIEJO","code":"EC-R-PUE"},
          {"name":"QUEVEDO","code":"EC-R-QVD"},
          {"name":"QUINSALOMA","code":"EC-R-QSA"},
          {"name":"SAN JUAN","code":"EC-R-SNJ"},
          {"name":"VALENCIA","code":"EC-R-VLC"},
          {"name":"VENTANAS","code":"EC-R-VEN"},
          {"name":"VINCES","code":"EC-R-VCS"},]
      },
      {
        name: 'Manabí',
        code: 'EC-M',
        cities: [{"name":"BAHÍA DE CARÁQUEZ","code":"EC-M-BHA"},
          {"name":"CANUTO","code":"EC-M-CNT"},
          {"name":"CHONE","code":"EC-M-EQC"},
          {"name":"EL CARMEN","code":"EC-M-ELC"},
          {"name":"JARAMIJÓ","code":"EC-M-JAO"},
          {"name":"JIPIJAPA","code":"EC-M-JIP"},
          {"name":"MANTA","code":"EC-M-MEC"},
          {"name":"MONTECRISTI","code":"EC-M-MTC"},
          {"name":"PEDERNALES","code":"EC-M-PDL"},
          {"name":"PORTOVIEJO","code":"EC-M-PVO"},
          {"name":"PUEBLO VIEJO","code":"EC-M-PUV"},]
      },
      {
        name: 'Morona Santiago',
        code: 'EC-S',
        cities: [{"name":"MACAS","code":"EC-S-XMS"},
          {"name":"MENDEZ","code":"EC-S-MZD"},
          {"name":"SUCUA","code":"EC-S-SUQ"},
          {"name":"TAISHA","code":"EC-S-TSC"},]
      },
      {
        name: 'Napo',
        code: 'EC-N',
        cities: [{"name":"COCA","code":"EC-N-OCC"},
          {"name":"EL CHACO","code":"EC-N-TMP29482"},
          {"name":"LA JOYA DE LOS SACHAS","code":"EC-N-LJS"},
          {"name":"TENA","code":"EC-N-TEN"},]
      },
      {
        name: 'Orellana',
        code: 'EC-D',
        cities: [{"name":"COCA","code":"EC-D-TMP29483"},
          {"name":"PUERTO FRANCISCO DE ORELLANA","code":"EC-D-PFO"},
          {"name":"TIPUTINI","code":"EC-D-TPN"},]
      },
      {
        name: 'Pastaza',
        code: 'EC-Y',
        cities: [{"name":"PUYO","code":"EC-Y-PUY"},]
      },
      {
        name: 'Pichincha',
        code: 'EC-P',
        cities: [{"name":"CARCELEN","code":"EC-P-TMP29584"},
          {"name":"CAYAMBE","code":"EC-P-CAY"},
          {"name":"CHECA","code":"EC-P-TMP29586"},
          {"name":"CHILLOGALLO","code":"EC-P-TMP29588"},
          {"name":"CONOCOTO","code":"EC-P-TMP29589"},
          {"name":"COTOCOLLAO","code":"EC-P-TMP29590"},
          {"name":"CUMBAYA","code":"EC-P-CUA"},
          {"name":"EL QUINCHE","code":"EC-P-EQH"},
          {"name":"GUAPULO","code":"EC-P-TMP29595"},
          {"name":"GUAYLLABAMBA","code":"EC-P-GBB"},
          {"name":"HACIENDA BOLIVIA","code":"EC-P-HBL"},
          {"name":"INAQUITO","code":"EC-P-TMP29602"},
          {"name":"LA MAGDALENA","code":"EC-P-TMP29604"},
          {"name":"MACHACHI","code":"EC-P-MAC"},
          {"name":"PIFO","code":"EC-P-PIF"},
          {"name":"QUINCHE","code":"EC-P-QUI"},
          {"name":"QUITO","code":"EC-P-TMP29611"},
          {"name":"RUMINAHUI","code":"EC-P-RMN"},
          {"name":"SAN CARLOS","code":"EC-P-SCS"},
          {"name":"SANGOLQUÍ","code":"EC-P-SAI"},
          {"name":"TABACUNDO","code":"EC-P-TAB"},
          {"name":"TUMBACO","code":"EC-P-TUO"},]
      },
      {
        name: 'Santa Elena',
        code: 'EC-SE',
        cities: [{"name":"SALINAS","code":"EC-SE-TMP29616"},
          {"name":"SANTA ELENA","code":"EC-SE-SEL"},]
      },
      {
        name: 'Santo Domingo de los Tsáchilas',
        code: 'EC-SD',
        cities: [{"name":"SANTA MARIA DEL TOACHI","code":"EC-SD-TMP29618"},
          {"name":"SANTO DOMINGO","code":"EC-SD-SGO"},
          {"name":"SANTO DOMINGO DE LOS COLORADOS","code":"EC-SD-TMP29619"},]
      },
      {
        name: 'Sucumbíos',
        code: 'EC-U',
        cities: []
      },
      {
        name: 'Tungurahua',
        code: 'EC-T',
        cities: [{"name":"AMBATO","code":"EC-T-TMP29690"},
          {"name":"BANOS","code":"EC-T-BAS"},
          {"name":"EL TRIUNFO","code":"EC-T-EQD"},
          {"name":"PELILEO","code":"EC-T-PEL"},
          {"name":"TISALEO","code":"EC-T-TSL"},]
      },
      {
        name: 'Zamora-Chinchipe',
        code: 'EC-Z',
        cities: [{"name":"TUNDAYME","code":"EC-Z-TDM"},]
      },
    ]
  },
  {
    name: 'El Salvador',
    code: 'SV',
    dialCode: '+503',
    flag: 'flag_el-salvador.png',
    states: [
      {
        name: 'Ahuachapán',
        code: 'SV-AH',
        cities: [{"name":"CHALCHUAPA","code":"SV-AH-CHA"},
          {"name":"MIRAMAR","code":"SV-AH-MIR"},
          {"name":"MONCAGUA","code":"SV-AH-TUE"},
          {"name":"NEJAPA","code":"SV-AH-NEJ"},]
      },
      {
        name: 'Cabanas',
        code: 'SV-CA',
        cities: [{"name":"ILOBASCO","code":"SV-CA-ILB"},
          {"name":"SAN ISIDRO","code":"SV-CA-SIS"},]
      },{
        name: 'Chalatenango',
        code: 'SV-CH',
        cities: [{"name":"COMALAPA","code":"SV-CH-CPL"},
          {"name":"EL POY","code":"SV-CH-EPY"},
          {"name":"NUEVA CONCEPCIÓN","code":"SV-CH-NCN"},
          {"name":"SAN BARTOLO","code":"SV-CH-SBO"},]
      },
      {
        name: 'Cuscatlán',
        code: 'SV-CU',
        cities: [{"name":"COJUTEPEQUE","code":"SV-CU-CJP"},
          {"name":"SAN BARTOLOME PERULAPIA","code":"SV-CU-ABP"},
          {"name":"TENANCINGO","code":"SV-CU-TAG"},]
      },
      {
        name: 'La Libertad',
        code: 'SV-LI',
        cities: [{"name":"ANTIGUO CUSCATLÁN","code":"SV-LI-CUS"},
          {"name":"ATEOS","code":"SV-LI-ATE"},
          {"name":"COLÓN","code":"SV-LI-COL"},
          {"name":"LA LIBERTAD","code":"SV-LI-LLD"},
          {"name":"LOURDES","code":"SV-LI-EZD"},
          {"name":"NUEVA SAN SALVADOR","code":"SV-LI-NSS"},
          {"name":"NUEVO CUSCATLAN","code":"SV-LI-NCU"},
          {"name":"QUEZALTEPEQUE","code":"SV-LI-QUE"},
          {"name":"SACACOYO","code":"SV-LI-SAC"},
          {"name":"SAN JUAN OPICO","code":"SV-LI-SJO"},
          {"name":"SANTA TECLA","code":"SV-LI-EZE"},
          {"name":"SITIO DEL NINO","code":"SV-LI-SIT"},
          {"name":"ZARAGOZA","code":"SV-LI-ZAR"},]
      },
      {
        name: 'La Paz',
        code: 'SV-PA',
        cities: [{"name":"EL ROSARIO","code":"SV-PA-ROS"},
          {"name":"SAN BARTOLO","code":"SV-PA-SBA"},
          {"name":"SAN LUIS TALPA","code":"SV-PA-SLT"},
          {"name":"ZACATECOLUCA","code":"SV-PA-ZAC"},]
      },
      {
        name: 'La Unión',
        code: 'SV-UN',
        cities: [{"name":"INTIPUCA","code":"SV-UN-TMP29980"},
          {"name":"SANTA ROSA DE LIMA","code":"SV-UN-SRL"},]
      },
      {
        name: 'Morazán',
        code: 'SV-MO',
        cities: [{"name":"EL SALVADOR","code":"SV-MO-ELS"},
          {"name":"OSICALA","code":"SV-MO-OSI"},
          {"name":"SAN FRANCISCO GOTERA","code":"SV-MO-SFG"},]
      },
      {
        name: 'San Miguel',
        code: 'SV-SM',
        cities: [{"name":"BELFAST","code":"SV-SM-BEL"},
          {"name":"CHAPELTIQUE","code":"SV-SM-CHQ"},
          {"name":"CHINAMECA","code":"SV-SM-CHM"},
          {"name":"CIUDAD BARRIOS","code":"SV-SM-CIB"},
          {"name":"EL MANZANILLO","code":"SV-SM-MAN"},]
      },
      {
        name: 'San Salvador',
        code: 'SV-SS',
        cities: [{"name":"AGUILARES","code":"SV-SS-EZA"},
          {"name":"APOPA","code":"SV-SS-APO"},
          {"name":"AYUTUXTEPEQUE","code":"SV-SS-AYE"},
          {"name":"COLONIA ESCALON","code":"SV-SS-TMP29992"},
          {"name":"DELGADO","code":"SV-SS-DEL"},
          {"name":"EL PAISNAL","code":"SV-SS-EPN"},
          {"name":"ESCALÓN","code":"SV-SS-TMP30037"},
          {"name":"ILOPANGO","code":"SV-SS-EZC"},
          {"name":"MEJICANOS","code":"SV-SS-MJS"},
          {"name":"SAN BARTOLO","code":"SV-SS-SBT"},
          {"name":"SAN SALVADOR","code":"SV-SS-TMP30002"},]
      },
      {
        name: 'San Vicente',
        code: 'SV-SV',
        cities: [{"name":"SAN BARTOLO ICHANMICO","code":"SV-SV-SBI"},
          {"name":"SAN VICENTE","code":"SV-SV-TMP30007"},]
      },
      {
        name: 'Santa Ana',
        code: 'SV-SA',
        cities: [{"name":"CIUDAD ARCE","code":"SV-SA-CAR"},
          {"name":"PASO DE LA CEIBA","code":"SV-SA-PDC"},]
      },
      {
        name: 'Sonsonate',
        code: 'SV-SO',
        cities: [{"name":"ARMENIA","code":"SV-SO-SSO"},
          {"name":"IZALCO","code":"SV-SO-IZO"},
          {"name":"NAHUIZALCO","code":"SV-SO-NHZ"},
          {"name":"SONSONATE","code":"SV-SO-TMP30024"},]
      },
    ]
  },
  {
    name: 'Estados Unidos',
    code: 'US',
    dialCode: '+1',
    flag: 'flag_eeuu.png',
    states: [
      {
        name: 'Alabama',
        code: 'US-AL',
        cities: [
          {"name":"ABBEVILLE","code":"US-AL-ABB"},
          {"name":"ADAMSVILLE","code":"US-AL-6LA"},
          {"name":"ADDISON","code":"US-AL-ADS"},
          {"name":"ADGER","code":"US-AL-AGE"},
          {"name":"ALABAMA CITY","code":"US-AL-ZAC"},
          {"name":"ALABAMA PT.","code":"US-AL-AA2"},
          {"name":"ALABASTER","code":"US-AL-LBA"},
          {"name":"ALBERTA","code":"US-AL-A8A"},
          {"name":"ALBERTVILLE","code":"US-AL-ABV"},
          {"name":"ALEXANDER CITY","code":"US-AL-ALX"},
          {"name":"ALICEVILLE","code":"US-AL-AIV"},
          {"name":"ALPINE","code":"US-AL-APL"},
          {"name":"ANDALUSIA","code":"US-AL-ADL"},
          {"name":"ANDERSON","code":"US-AL-ANE"},
          {"name":"ANNISTON","code":"US-AL-ANB"},
          {"name":"ARAB","code":"US-AL-RAB"},
          {"name":"ARDMORE","code":"US-AL-AR9"},
          {"name":"ARITON","code":"US-AL-RIU"},
          {"name":"ARLEY","code":"US-AL-ARL"},
          {"name":"ARLINGTON","code":"US-AL-R99"},
          {"name":"ASHFORD","code":"US-AL-AF2"},
          {"name":"ASHLAND","code":"US-AL-ALY"},
          {"name":"ASHVILLE","code":"US-AL-9AL"},
          {"name":"ATHENS","code":"US-AL-ATA"},
          {"name":"ATMORE","code":"US-AL-AMK"},
          {"name":"ATTALLA","code":"US-AL-AAA"},
          {"name":"AUBURN","code":"US-AL-AUO"},
          {"name":"AUTAUGAVILLE","code":"US-AL-AGV"},
          {"name":"AXIS","code":"US-AL-AXS"},
          {"name":"BAILEYTON","code":"US-AL-YBQ"},
          {"name":"BAKER HILL","code":"US-AL-B6H"},
          {"name":"BARTON","code":"US-AL-AQT"},
          {"name":"BAY MINETTE","code":"US-AL-BME"},
          {"name":"BAYOU LA BATRE","code":"US-AL-BYU"},
          {"name":"BEAR CREEK","code":"US-AL-BCR"},
          {"name":"BEATRICE","code":"US-AL-BE6"},
          {"name":"BELLE MINA","code":"US-AL-EEM"},
          {"name":"BELLWOOD","code":"US-AL-FBD"},
          {"name":"BENTON","code":"US-AL-JJN"},
          {"name":"BERRY","code":"US-AL-BY2"},
          {"name":"BESSEMER","code":"US-AL-BMV"},
          {"name":"BILLINGSLEY","code":"US-AL-B5Y"},
          {"name":"BIRMINGHAM","code":"US-AL-BHM"},
          {"name":"BIRMINGPORT","code":"US-AL-RMT"},
          {"name":"BLOUNTSVILLE","code":"US-AL-UTB"},
          {"name":"BOAZ","code":"US-AL-OAZ"},
          {"name":"BOLIGEE","code":"US-AL-B2G"},
          {"name":"BON AIR","code":"US-AL-BOA"},
          {"name":"BON SECOUR","code":"US-AL-ZJC"},
          {"name":"BRANCHVILLE","code":"US-AL-B86"},
          {"name":"BRANTLEY","code":"US-AL-RNY"},
          {"name":"BREMEN","code":"US-AL-REM"},
          {"name":"BRENT","code":"US-AL-BJN"},
          {"name":"BREWTON","code":"US-AL-BXJ"},
          {"name":"BRIDGEPORT","code":"US-AL-BXM"},
          {"name":"BROOKLEY","code":"US-AL-BKR"},
          {"name":"BROOKWOOD","code":"US-AL-BWA"},
          {"name":"BROWNSBORO","code":"US-AL-B2O"},
          {"name":"BRUNDIDGE","code":"US-AL-AGD"},
          {"name":"BUCKS","code":"US-AL-BKA"},
          {"name":"BUHL","code":"US-AL-B9U"},
          {"name":"BURKVILLE","code":"US-AL-BQL"},
          {"name":"BUTLER","code":"US-AL-BGF"},
          {"name":"CALERA","code":"US-AL-CEB"},
          {"name":"CALVERT","code":"US-AL-VEZ"},
          {"name":"CAMDEN","code":"US-AL-CDD"},
          {"name":"CAMP HILL","code":"US-AL-HCD"},
          {"name":"CARROLLTON","code":"US-AL-C2R"},
          {"name":"CASTLEBERRY","code":"US-AL-CJZ"},
          {"name":"CEDAR BLUFF","code":"US-AL-RBU"},
          {"name":"CENTER POINT","code":"US-AL-C7P"},
          {"name":"CENTRE","code":"US-AL-CER"},
          {"name":"CENTREVILLE","code":"US-AL-CJI"},
          {"name":"CHAPMAN","code":"US-AL-CEP"},
          {"name":"CHATOM","code":"US-AL-KCM"},
          {"name":"CHELSEA","code":"US-AL-YSC"},
          {"name":"CHEROKEE","code":"US-AL-CKG"},
          {"name":"CHICKASAW","code":"US-AL-CGW"},
          {"name":"CHILDERSBURG","code":"US-AL-ZCG"},
          {"name":"CHUNCHULA","code":"US-AL-CU4"},
          {"name":"CITRONELLE","code":"US-AL-CIT"},
          {"name":"CLANTON","code":"US-AL-QCT"},
          {"name":"CLAY","code":"US-AL-8CY"},
          {"name":"CLAYTON","code":"US-AL-CXQ"},
          {"name":"CLEVELAND","code":"US-AL-CE5"},
          {"name":"CLINTON","code":"US-AL-7CN"},
          {"name":"CODEN","code":"US-AL-C2N"},
          {"name":"COLLINSVILLE","code":"US-AL-OII"},
          {"name":"COLUMBIA","code":"US-AL-COZ"},
          {"name":"COLUMBIANA","code":"US-AL-UMI"},
          {"name":"COOKS SPRINGS","code":"US-AL-KPS"},
          {"name":"COOSA PINES","code":"US-AL-OOP"},
          {"name":"CORDOVA","code":"US-AL-299"},
          {"name":"COTTONDALE","code":"US-AL-CD3"},
          {"name":"COTTONTON","code":"US-AL-OTT"},
          {"name":"COTTONWOOD","code":"US-AL-JTD"},
          {"name":"COURTLAND","code":"US-AL-CBP"},
          {"name":"CRAGFORD","code":"US-AL-C6R"},
          {"name":"CREOLA","code":"US-AL-EOZ"},
          {"name":"CROPWELL","code":"US-AL-CW3"},
          {"name":"CROSSVILLE","code":"US-AL-CR9"},
          {"name":"CULLMAN","code":"US-AL-CUL"},
          {"name":"CUSSETA","code":"US-AL-JWS"},
          {"name":"DADEVILLE","code":"US-AL-DDL"},
          {"name":"DALEVILLE","code":"US-AL-DVY"},
          {"name":"DANVILLE","code":"US-AL-NDJ"},
          {"name":"DAPHNE","code":"US-AL-DHA"},
          {"name":"DAVISTON","code":"US-AL-DS9"},
          {"name":"DEATSVILLE","code":"US-AL-2DE"},
          {"name":"DECATUR","code":"US-AL-DCU"},
          {"name":"DELTA","code":"US-AL-AL5"},
          {"name":"DEMOPOLIS","code":"US-AL-DMP"},
          {"name":"DETROIT","code":"US-AL-7AL"},
          {"name":"DOLOMITE","code":"US-AL-DOL"},
          {"name":"DORA","code":"US-AL-DO6"},
          {"name":"DOTHAN","code":"US-AL-DHN"},
          {"name":"DOUBLE SPRINGS","code":"US-AL-DMB"},
          {"name":"DUTTON","code":"US-AL-ZDU"},
          {"name":"EASTABOGA","code":"US-AL-EBG"},
          {"name":"ECLECTIC","code":"US-AL-EC2"},
          {"name":"EDWARDSVILLE","code":"US-AL-EQV"},
          {"name":"EIGHT MILE","code":"US-AL-EIG"},
          {"name":"ELBA","code":"US-AL-EBA"},
          {"name":"ELBERTA","code":"US-AL-EB3"},
          {"name":"ELKMONT","code":"US-AL-ZEM"},
          {"name":"ELMORE","code":"US-AL-EXO"},
          {"name":"EMELLE","code":"US-AL-EMA"},
          {"name":"EMPIRE","code":"US-AL-EP8"},
          {"name":"ENSLEY","code":"US-AL-ENS"},
          {"name":"ENTERPRISE","code":"US-AL-ETS"},
          {"name":"EUFAULA","code":"US-AL-EUF"},
          {"name":"EUTAW","code":"US-AL-ET4"},
          {"name":"EVA","code":"US-AL-XVA"},
          {"name":"EVERGREEN","code":"US-AL-EVR"},
          {"name":"FACKLER","code":"US-AL-FKK"},
          {"name":"FAIRFAX","code":"US-AL-FFX"},
          {"name":"FAIRFIELD","code":"US-AL-FFB"},
          {"name":"FAIRHOPE","code":"US-AL-FH3"},
          {"name":"FALKVILLE","code":"US-AL-FKV"},
          {"name":"FAYETTE","code":"US-AL-FYT"},
          {"name":"FITZPATRICK","code":"US-AL-FZK"},
          {"name":"FLOMATON","code":"US-AL-FMM"},
          {"name":"FLORALA","code":"US-AL-FL7"},
          {"name":"FLORENCE","code":"US-AL-FNE"},
          {"name":"FLORENCE-MUSCLE SHOALS APT","code":"US-AL-MSL"},
          {"name":"FOLEY","code":"US-AL-NHX"},
          {"name":"FORKLAND","code":"US-AL-556"},
          {"name":"FORT DEPOSIT","code":"US-AL-FUP"},
          {"name":"FORT MITCHELL","code":"US-AL-FM2"},
          {"name":"FORT PAYNE","code":"US-AL-FPN"},
          {"name":"FORT RUCKER","code":"US-AL-FR3"},
          {"name":"FRISCO CITY","code":"US-AL-FCB"},
          {"name":"FRUITHURST","code":"US-AL-FUI"},
          {"name":"FULTONDALE","code":"US-AL-FTD"},
          {"name":"FYFFE","code":"US-AL-FIF"},
          {"name":"GADSDEN","code":"US-AL-GAD"},
          {"name":"GAINESVILLE","code":"US-AL-VG7"},
          {"name":"GANTT","code":"US-AL-2GA"},
          {"name":"GARDEN CITY","code":"US-AL-GZC"},
          {"name":"GARDENDALE","code":"US-AL-GQA"},
          {"name":"GAYLESVILLE","code":"US-AL-VBV"},
          {"name":"GENEVA","code":"US-AL-GXN"},
          {"name":"GEORGIA","code":"US-AL-GG3"},
          {"name":"GEORGIANA","code":"US-AL-GG4"},
          {"name":"GERALDINE","code":"US-AL-A5G"},
          {"name":"GLENCOE","code":"US-AL-GC2"},
          {"name":"GOODWATER","code":"US-AL-GWT"},
          {"name":"GORDO","code":"US-AL-6RD"},
          {"name":"GORDON","code":"US-AL-GQR"},
          {"name":"GOSHEN","code":"US-AL-GOS"},
          {"name":"GRAHAM","code":"US-AL-GHX"},
          {"name":"GRAND BAY","code":"US-AL-GDB"},
          {"name":"GRANT","code":"US-AL-GTA"},
          {"name":"GREENSBORO","code":"US-AL-GBS"},
          {"name":"GREENVILLE","code":"US-AL-GRN"},
          {"name":"GROVE HILL","code":"US-AL-GVH"},
          {"name":"GUIN","code":"US-AL-GWU"},
          {"name":"GULF SHORES","code":"US-AL-GUF"},
          {"name":"GUNTERSVILLE","code":"US-AL-GUE"},
          {"name":"GURLEY","code":"US-AL-GY4"},
          {"name":"HACKLEBURG","code":"US-AL-HB2"},
          {"name":"HALEYVILLE","code":"US-AL-HLY"},
          {"name":"HAMILTON","code":"US-AL-HAB"},
          {"name":"HAMPTON","code":"US-AL-AHM"},
          {"name":"HANCEVILLE","code":"US-AL-HCV"},
          {"name":"HARDAWAY","code":"US-AL-VWY"},
          {"name":"HARPERSVILLE","code":"US-AL-H8R"},
          {"name":"HARTFORD","code":"US-AL-HF3"},
          {"name":"HARTSELLE","code":"US-AL-HTE"},
          {"name":"HAYDEN","code":"US-AL-HY6"},
          {"name":"HAYNEVILLE","code":"US-AL-YNQ"},
          {"name":"HAZEL GREEN","code":"US-AL-H3G"},
          {"name":"HEADLAND","code":"US-AL-HLE"},
          {"name":"HEFLIN","code":"US-AL-HEF"},
          {"name":"HELENA","code":"US-AL-HZV"},
          {"name":"HENAGAR","code":"US-AL-HNQ"},
          {"name":"HIGHLAND HOME","code":"US-AL-HH3"},
          {"name":"HILLSBORO, LAWRENCE","code":"US-AL-OOH"},
          {"name":"HILLSBORO, MADISON","code":"US-AL-OOB"},
          {"name":"HODGES","code":"US-AL-HG5"},
          {"name":"HOKES BLUFF","code":"US-AL-H8F"},
          {"name":"HOLLY POND","code":"US-AL-HLP"},
          {"name":"HOLLYWOOD","code":"US-AL-HWW"},
          {"name":"HOMEWOOD","code":"US-AL-HWA"},
          {"name":"HOOVER","code":"US-AL-HVA"},
          {"name":"HOPE HULL","code":"US-AL-HHU"},
          {"name":"HOUSTON","code":"US-AL-H5S"},
          {"name":"HUEYTOWN","code":"US-AL-HOW"},
          {"name":"HUNTSVILLE","code":"US-AL-HSV"},
          {"name":"HURTSBORO","code":"US-AL-USO"},
          {"name":"HUXFORD","code":"US-AL-HXO"},
          {"name":"IDER","code":"US-AL-IXD"},
          {"name":"INDIAN SPRINGS","code":"US-AL-2IS"},
          {"name":"IRONDALE","code":"US-AL-ZIR"},
          {"name":"IRVINGTON","code":"US-AL-IRZ"},
          {"name":"JACK","code":"US-AL-JCK"},
          {"name":"JACKSON","code":"US-AL-JKS"},
          {"name":"JACKSONVILLE","code":"US-AL-JAO"},
          {"name":"JASPER","code":"US-AL-JSP"},
          {"name":"JEMISON","code":"US-AL-JEQ"},
          {"name":"KILLEN","code":"US-AL-KLI"},
          {"name":"KINSTON","code":"US-AL-QKL"},
          {"name":"KNOXVILLE","code":"US-AL-KZX"},
          {"name":"LACEYS SPRING","code":"US-AL-LS7"},
          {"name":"LAFAYETTE","code":"US-AL-YTF"},
          {"name":"LANETT","code":"US-AL-YLT"},
          {"name":"LE MOYNE","code":"US-AL-9LM"},
          {"name":"LEEDS","code":"US-AL-LDS"},
          {"name":"LEESBURG","code":"US-AL-LZG"},
          {"name":"LEIGHTON","code":"US-AL-LGT"},
          {"name":"LEROY","code":"US-AL-LR2"},
          {"name":"LETOHATCHEE","code":"US-AL-LHT"},
          {"name":"LEXINGTON","code":"US-AL-QKV"},
          {"name":"LILLIAN","code":"US-AL-IXA"},
          {"name":"LINCOLN","code":"US-AL-INN"},
          {"name":"LINDEN","code":"US-AL-LNA"},
          {"name":"LINEVILLE","code":"US-AL-LNV"},
          {"name":"LIPSCOMB","code":"US-AL-L7P"},
          {"name":"LISTERHILL","code":"US-AL-LIS"},
          {"name":"LIVINGSTON","code":"US-AL-LVT"},
          {"name":"LOACHAPOKA","code":"US-AL-LQP"},
          {"name":"LOGAN","code":"US-AL-L4G"},
          {"name":"LOUISVILLE","code":"US-AL-7LV"},
          {"name":"LOWNDESBORO","code":"US-AL-JDB"},
          {"name":"LOXLEY","code":"US-AL-LXY"},
          {"name":"LUVERNE","code":"US-AL-LZW"},
          {"name":"LYNN","code":"US-AL-LYA"},
          {"name":"MADISON","code":"US-AL-MAZ"},
          {"name":"MAHRT","code":"US-AL-MAT"},
          {"name":"MALVERN","code":"US-AL-VVM"},
          {"name":"MAPLESVILLE","code":"US-AL-AJV"},
          {"name":"MARION","code":"US-AL-PU8"},
          {"name":"MAXWELL","code":"US-AL-XWL"},
          {"name":"MAYLENE","code":"US-AL-ML9"},
          {"name":"MC SHAN","code":"US-AL-IZN"},
          {"name":"MCCALLA","code":"US-AL-MKX"},
          {"name":"MCINTOSH","code":"US-AL-MZI"},
          {"name":"MCKENZIE","code":"US-AL-MK2"},
          {"name":"MCWILLIAMS","code":"US-AL-DCM"},
          {"name":"MENTONE","code":"US-AL-MT6"},
          {"name":"MERIDIANVILLE","code":"US-AL-MLZ"},
          {"name":"MIDFIELD","code":"US-AL-IFE"},
          {"name":"MIDLAND CITY","code":"US-AL-IDY"},
          {"name":"MILLBROOK","code":"US-AL-MBJ"},
          {"name":"MILLPORT","code":"US-AL-IPR"},
          {"name":"MILLRY","code":"US-AL-M3Y"},
          {"name":"MITCHELL","code":"US-AL-M2L"},
          {"name":"MOBILE","code":"US-AL-MOB"},
          {"name":"MONROEVILLE","code":"US-AL-MVC"},
          {"name":"MONTEVALLO","code":"US-AL-MVO"},
          {"name":"MONTGOMERY","code":"US-AL-MGM"},
          {"name":"MONTROSE","code":"US-AL-OZ9"},
          {"name":"MOODY","code":"US-AL-MD7"},
          {"name":"MORGAN CITY","code":"US-AL-MGN"},
          {"name":"MORRIS","code":"US-AL-XRI"},
          {"name":"MOULTON","code":"US-AL-MUW"},
          {"name":"MOUNDVILLE","code":"US-AL-MOA"},
          {"name":"MOUNT HOPE","code":"US-AL-UHE"},
          {"name":"MOUNT MEIGS","code":"US-AL-OUM"},
          {"name":"MOUNT VERNON","code":"US-AL-UVO"},
          {"name":"MOUNTAIN BROOK","code":"US-AL-M77"},
          {"name":"MOUNTAIN VIEW","code":"US-AL-MUL"},
          {"name":"MULGA","code":"US-AL-UGM"},
          {"name":"MUNFORD","code":"US-AL-UOB"},
          {"name":"MUSCLE SHOALS","code":"US-AL-USH"},
          {"name":"MUSCLE SHOALS-FLORENCE APT","code":"US-AL-MSL"},
          {"name":"NAHEOLA","code":"US-AL-NHA"},
          {"name":"NAUVOO","code":"US-AL-NAV"},
          {"name":"NEEDHAM","code":"US-AL-8DE"},
          {"name":"NEW BROCKTON","code":"US-AL-BW5"},
          {"name":"NEW MARKET","code":"US-AL-NMT"},
          {"name":"NEWTON","code":"US-AL-2N2"},
          {"name":"NORTH ARAB","code":"US-AL-JRB"},
          {"name":"NORTH BIRMINGHAM","code":"US-AL-G8H"},
          {"name":"NORTHPORT","code":"US-AL-NHP"},
          {"name":"OAKMAN","code":"US-AL-OXM"},
          {"name":"ODENVILLE","code":"US-AL-QOD"},
          {"name":"OHATCHEE","code":"US-AL-OHH"},
          {"name":"ONEONTA","code":"US-AL-ONF"},
          {"name":"OPELIKA","code":"US-AL-OPI"},
          {"name":"OPP","code":"US-AL-OPA"},
          {"name":"ORANGE BEACH","code":"US-AL-OBC"},
          {"name":"OWENS CROSS ROADS","code":"US-AL-OQS"},
          {"name":"OXFORD","code":"US-AL-OXO"},
          {"name":"OZARK","code":"US-AL-OZR"},
          {"name":"PAINT ROCK","code":"US-AL-P5R"},
          {"name":"PARRISH","code":"US-AL-RRH"},
          {"name":"PELHAM","code":"US-AL-APM"},
          {"name":"PELL CITY","code":"US-AL-PLR"},
          {"name":"PENNINGTON","code":"US-AL-NIG"},
          {"name":"PERDUE HILL","code":"US-AL-XPE"},
          {"name":"PETERMAN","code":"US-AL-PEE"},
          {"name":"PHENIX CITY","code":"US-AL-PHA"},
          {"name":"PHIL CAMPBELL","code":"US-AL-PCB"},
          {"name":"PICKENSVILLE","code":"US-AL-YKV"},
          {"name":"PIEDMONT","code":"US-AL-XPI"},
          {"name":"PIKE ROAD","code":"US-AL-7PR"},
          {"name":"PINCKARD","code":"US-AL-PXP"},
          {"name":"PINE APPLE","code":"US-AL-PAA"},
          {"name":"PINE HILL","code":"US-AL-PID"},
          {"name":"PINEVILLE","code":"US-AL-PIV"},
          {"name":"PINSON","code":"US-AL-PYN"},
          {"name":"PINTO ISLAND","code":"US-AL-P9O"},
          {"name":"PISGAH","code":"US-AL-PAG"},
          {"name":"PITTSVIEW","code":"US-AL-EU7"},
          {"name":"PLEASANT GROVE","code":"US-AL-TGO"},
          {"name":"POINT CLEAR","code":"US-AL-PC8"},
          {"name":"PORT LIONS","code":"US-AL-PDL"},
          {"name":"PRATTVILLE","code":"US-AL-PRV"},
          {"name":"PRICEVILLE","code":"US-AL-P43"},
          {"name":"PRICHARD","code":"US-AL-PYC"},
          {"name":"QUINTON","code":"US-AL-JQT"},
          {"name":"RAGLAND","code":"US-AL-YRG"},
          {"name":"RAINBOW","code":"US-AL-YRI"},
          {"name":"RAINBOW CITY","code":"US-AL-XRC"},
          {"name":"RAINSVILLE","code":"US-AL-RNV"},
          {"name":"RAMER","code":"US-AL-RA2"},
          {"name":"RANBURNE","code":"US-AL-RBR"},
          {"name":"RED BAY","code":"US-AL-RDY"},
          {"name":"REFORM","code":"US-AL-RF2"},
          {"name":"RIVER FALLS","code":"US-AL-RFL"},
          {"name":"RIVERSIDE","code":"US-AL-2RV"},
          {"name":"ROANOKE","code":"US-AL-RN2"},
          {"name":"ROBERTSDALE","code":"US-AL-OBS"},
          {"name":"ROCKFORD","code":"US-AL-ZFE"},
          {"name":"ROGERSVILLE","code":"US-AL-OEI"},
          {"name":"RUSSELLVILLE","code":"US-AL-RUV"},
          {"name":"SAGINAW","code":"US-AL-SAI"},
          {"name":"SAINT PAUL","code":"US-AL-SXW"},
          {"name":"SALEM","code":"US-AL-AAM"},
          {"name":"SALEM","code":"US-AL-ZSA"},
          {"name":"SAMANTHA","code":"US-AL-JSH"},
          {"name":"SAMSON","code":"US-AL-SXN"},
          {"name":"SARALAND","code":"US-AL-SFQ"},
          {"name":"SATSUMA","code":"US-AL-2S2"},
          {"name":"SCOTTSBORO","code":"US-AL-SQB"},
          {"name":"SEALE","code":"US-AL-AQE"},
          {"name":"SECTION","code":"US-AL-S4E"},
          {"name":"SELMA","code":"US-AL-SES"},
          {"name":"SEMMES","code":"US-AL-EME"},
          {"name":"SHEFFIELD","code":"US-AL-SHF"},
          {"name":"SHORTER","code":"US-AL-EOH"},
          {"name":"SHORTERVILLE","code":"US-AL-TRV"},
          {"name":"SNEAD","code":"US-AL-SDD"},
          {"name":"SOMERVILLE","code":"US-AL-ILX"},
          {"name":"SOUTHSIDE","code":"US-AL-UIK"},
          {"name":"SPANISH FORT","code":"US-AL-IFO"},
          {"name":"SPRINGVILLE","code":"US-AL-SPL"},
          {"name":"SPRUCE PINE","code":"US-AL-S4P"},
          {"name":"STAPLETON","code":"US-AL-VSQ"},
          {"name":"STEELE","code":"US-AL-SXI"},
          {"name":"STERRETT","code":"US-AL-T67"},
          {"name":"STEVENSON","code":"US-AL-SJZ"},
          {"name":"STEWART","code":"US-AL-TER"},
          {"name":"STEWART","code":"US-AL-TWT"},
          {"name":"STOCKTON","code":"US-AL-TZK"},
          {"name":"SULLIGENT","code":"US-AL-SUG"},
          {"name":"SUMITON","code":"US-AL-ASZ"},
          {"name":"SUMMERDALE","code":"US-AL-URL"},
          {"name":"SUMMIT","code":"US-AL-SMZ"},
          {"name":"SUNSHINE","code":"US-AL-SHA"},
          {"name":"SYCAMORE","code":"US-AL-S3Y"},
          {"name":"SYLACAUGA","code":"US-AL-SYU"},
          {"name":"SYLVANIA","code":"US-AL-YNX"},
          {"name":"TALLADEGA","code":"US-AL-ASN"},
          {"name":"TALLASSEE, ELMORE","code":"US-AL-YTA"},
          {"name":"TANNER","code":"US-AL-NNR"},
          {"name":"TARRANT","code":"US-AL-TNW"},
          {"name":"THEODORE","code":"US-AL-TEO"},
          {"name":"THOMASVILLE","code":"US-AL-TVM"},
          {"name":"THORSBY","code":"US-AL-TB2"},
          {"name":"TONEY","code":"US-AL-TN2"},
          {"name":"TOWN CREEK","code":"US-AL-TC2"},
          {"name":"TRAFFORD","code":"US-AL-7TR"},
          {"name":"TRINITY","code":"US-AL-QTI"},
          {"name":"TROY","code":"US-AL-TOI"},
          {"name":"TRUSSVILLE","code":"US-AL-TRU"},
          {"name":"TUSCALOOSA","code":"US-AL-TCL"},
          {"name":"TUSCUMBIA","code":"US-AL-TSC"},
          {"name":"TUSKEGEE","code":"US-AL-TGE"},
          {"name":"TYLER","code":"US-AL-TYL"},
          {"name":"UNION GROVE","code":"US-AL-A8L"},
          {"name":"UNION SPRINGS","code":"US-AL-USP"},
          {"name":"URIAH","code":"US-AL-URH"},
          {"name":"VALLEY","code":"US-AL-VAY"},
          {"name":"VANCE","code":"US-AL-VCE"},
          {"name":"VERNON","code":"US-AL-VEN"},
          {"name":"VESTAVIA HILLS","code":"US-AL-VEH"},
          {"name":"VINCENT","code":"US-AL-YXV"},
          {"name":"VINEMONT","code":"US-AL-VMT"},
          {"name":"WADLEY","code":"US-AL-WY2"},
          {"name":"WARRIOR","code":"US-AL-RRP"},
          {"name":"WATERLOO","code":"US-AL-VTL"},
          {"name":"WEAVER","code":"US-AL-WWW"},
          {"name":"WEDOWEE","code":"US-AL-XWD"},
          {"name":"WELLINGTON","code":"US-AL-ZJJ"},
          {"name":"WEST BLOCTON","code":"US-AL-WCN"},
          {"name":"WESTOVER","code":"US-AL-YTZ"},
          {"name":"WETUMPKA","code":"US-AL-WUK"},
          {"name":"WHISTLER","code":"US-AL-HTR"},
          {"name":"WILSONVILLE","code":"US-AL-WV8"},
          {"name":"WINFIELD","code":"US-AL-WIF"},
          {"name":"WOODFORD","code":"US-AL-WFC"},
          {"name":"WOODLAND","code":"US-AL-WO2"},
          {"name":"WOODSTOCK","code":"US-AL-WOK"},
          {"name":"YORK","code":"US-AL-YRK"},
        ]
      },
      {
        name: 'Alaska',
        code: 'US-AK',
        cities: [
          {"name":"ADAK","code":"US-AK-AXK"},
          {"name":"ADAK ISLAND/ADAK APT","code":"US-AK-ADK"},
          {"name":"AFOGNAK","code":"US-AK-AFK"},
          {"name":"AKHIOK","code":"US-AK-AKK"},
          {"name":"AKIACHAK","code":"US-AK-KKI"},
          {"name":"AKIAK","code":"US-AK-AKI"},
          {"name":"AKUTAN","code":"US-AK-KQA"},
          {"name":"ALAKANUK","code":"US-AK-AUK"},
          {"name":"ALCAN","code":"US-AK-ZAK"},
          {"name":"ALEKNAGIK","code":"US-AK-WKK"},
          {"name":"ALENEVA","code":"US-AK-AED"},
          {"name":"ALITAK","code":"US-AK-ALZ"},
          {"name":"ALLAKAKET","code":"US-AK-AET"},
          {"name":"ALYESKA","code":"US-AK-AQY"},
          {"name":"AMBLER","code":"US-AK-ABL"},
          {"name":"AMCHITKA","code":"US-AK-AHT"},
          {"name":"AMOOK","code":"US-AK-AOS"},
          {"name":"ANAKTUVUK","code":"US-AK-AKP"},
          {"name":"ANCHOR POINT","code":"US-AK-ACP"},
          {"name":"ANCHORAGE","code":"US-AK-ANC"},
          {"name":"ANGOON","code":"US-AK-AGN"},
          {"name":"ANIAK","code":"US-AK-ANI"},
          {"name":"ANITA BAY","code":"US-AK-AIB"},
          {"name":"ANNETTE ISLAND","code":"US-AK-ANN"},
          {"name":"ANVIK","code":"US-AK-ANV"},
          {"name":"ARCTIC VILLAGE","code":"US-AK-ARC"},
          {"name":"ATKA","code":"US-AK-AKB"},
          {"name":"ATMAUTLUAK","code":"US-AK-ATT"},
          {"name":"ATQASUK","code":"US-AK-ATK"},
          {"name":"ATTU ISLAND","code":"US-AK-ATU"},
          {"name":"BAKER ISLAND","code":"US-AK-BAR"},
          {"name":"BARANOF","code":"US-AK-BNF"},
          {"name":"BARROW","code":"US-AK-BRW"},
          {"name":"BARTER ISLAND","code":"US-AK-BTI"},
          {"name":"BARTLETTS","code":"US-AK-BSZ"},
          {"name":"BEAR CREEK","code":"US-AK-BCC"},
          {"name":"BEAVER","code":"US-AK-WBQ"},
          {"name":"BEAVER INLET","code":"US-AK-BVD"},
          {"name":"BELL ISLAND","code":"US-AK-KBE"},
          {"name":"BELUGA","code":"US-AK-BVU"},
          {"name":"BETHEL","code":"US-AK-BET"},
          {"name":"BETTLES","code":"US-AK-BTT"},
          {"name":"BIG CREEK","code":"US-AK-BIC"},
          {"name":"BIG DELTA","code":"US-AK-BIG"},
          {"name":"BIG LAKE","code":"US-AK-BGQ"},
          {"name":"BIG MOUNTAIN","code":"US-AK-BMX"},
          {"name":"BIRCH CREEK","code":"US-AK-KBC"},
          {"name":"BIRCHWOOD","code":"US-AK-BIR"},
          {"name":"BLUE FOX BAY","code":"US-AK-BFB"},
          {"name":"BORNITE","code":"US-AK-RLU"},
          {"name":"BOSWELL BAY","code":"US-AK-BSW"},
          {"name":"BOUNDARY","code":"US-AK-BYA"},
          {"name":"BROOKS LAKE","code":"US-AK-BKF"},
          {"name":"BROOKS LODGE","code":"US-AK-RBH"},
          {"name":"BUCKLAND","code":"US-AK-BKC"},
          {"name":"CABIN CREEK","code":"US-AK-CBZ"},
          {"name":"CANDLE","code":"US-AK-CDL"},
          {"name":"CAPE LISBURNE","code":"US-AK-LUR"},
          {"name":"CAPE NEWENHAM","code":"US-AK-EHM"},
          {"name":"CAPE POLE","code":"US-AK-CZP"},
          {"name":"CAPE ROMANZOF","code":"US-AK-CZF"},
          {"name":"CAPE SABINE","code":"US-AK-CSA"},
          {"name":"CAPE SPENCER","code":"US-AK-CSP"},
          {"name":"CASTLE ISLAND","code":"US-AK-XSD"},
          {"name":"CENTRAL","code":"US-AK-CEM"},
          {"name":"CHALKYITSIK","code":"US-AK-CIK"},
          {"name":"CHANDALAR","code":"US-AK-WCR"},
          {"name":"CHATHAM","code":"US-AK-CYM"},
          {"name":"CHEFORNAK","code":"US-AK-CYF"},
          {"name":"CHENA HOT SPRINGS","code":"US-AK-CEX"},
          {"name":"CHERNOFSKI","code":"US-AK-KCN"},
          {"name":"CHEVAK","code":"US-AK-VAK"},
          {"name":"CHICKEN","code":"US-AK-CKX"},
          {"name":"CHIGNIK","code":"US-AK-KCL"},
          {"name":"CHISANA","code":"US-AK-CZN"},
          {"name":"CHISTOCHINA","code":"US-AK-CZO"},
          {"name":"CHITINA","code":"US-AK-CXC"},
          {"name":"CHOMLEY","code":"US-AK-CIV"},
          {"name":"CHUATHBALUK","code":"US-AK-CHU"},
          {"name":"CHUGIAK","code":"US-AK-CHR"},
          {"name":"CIRCLE","code":"US-AK-IRC"},
          {"name":"CIRCLE HOT SPRINGS","code":"US-AK-CHP"},
          {"name":"CLAM GULCH","code":"US-AK-CGU"},
          {"name":"CLARKS POINT","code":"US-AK-CLP"},
          {"name":"CLEAR","code":"US-AK-CXI"},
          {"name":"COFFEE POINT","code":"US-AK-CFA"},
          {"name":"COFFMAN COVE","code":"US-AK-KCC"},
          {"name":"COLD BAY","code":"US-AK-CDB"},
          {"name":"COLDFOOT","code":"US-AK-CXF"},
          {"name":"COLLEGE","code":"US-AK-CEQ"},
          {"name":"COLORADO CREEK","code":"US-AK-KCR"},
          {"name":"COOPER LANDING","code":"US-AK-CEI"},
          {"name":"COOPER LODGE","code":"US-AK-JLA"},
          {"name":"COPPER CENTRE","code":"US-AK-CZC"},
          {"name":"CORDOVA","code":"US-AK-CDV"},
          {"name":"CORNER BAY","code":"US-AK-CBA"},
          {"name":"COUNCIL","code":"US-AK-CIL"},
          {"name":"CRAIG","code":"US-AK-CGA"},
          {"name":"CROOKED CREEK","code":"US-AK-CKD"},
          {"name":"CUBE COVE","code":"US-AK-CUW"},
          {"name":"DAHL CREEK","code":"US-AK-DCK"},
          {"name":"DANGER BAY","code":"US-AK-DGB"},
          {"name":"DEEP BAY","code":"US-AK-WDB"},
          {"name":"DEERING","code":"US-AK-DRG"},
          {"name":"DELTA JUNCTION","code":"US-AK-DJN"},
          {"name":"DENALI","code":"US-AK-DNI"},
          {"name":"DILLINGHAM","code":"US-AK-DLG"},
          {"name":"DIOMEDE ISLAND","code":"US-AK-DIO"},
          {"name":"DOLOMI","code":"US-AK-DLO"},
          {"name":"DONNELLY","code":"US-AK-DYX"},
          {"name":"DORA BAY","code":"US-AK-DOF"},
          {"name":"DRIFT RIVER","code":"US-AK-DRF"},
          {"name":"DUTCH HARBOR","code":"US-AK-DUT"},
          {"name":"EAGLE","code":"US-AK-EAA"},
          {"name":"EAGLE RIVER","code":"US-AK-ERQ"},
          {"name":"EAST FORK","code":"US-AK-EFO"},
          {"name":"EDNA BAY","code":"US-AK-EDA"},
          {"name":"EEK","code":"US-AK-EEK"},
          {"name":"EGEGIK","code":"US-AK-EGX"},
          {"name":"EIGHT FATHOM BIGHT","code":"US-AK-EFB"},
          {"name":"EKLUTNA","code":"US-AK-EUL"},
          {"name":"EKUK","code":"US-AK-KKU"},
          {"name":"EKWOK","code":"US-AK-KEK"},
          {"name":"ELDRED ROCK","code":"US-AK-ERO"},
          {"name":"ELFIN COVE","code":"US-AK-ELV"},
          {"name":"ELIM","code":"US-AK-ELI"},
          {"name":"ELLAMAR","code":"US-AK-ELW"},
          {"name":"ELMENDORF AIR FORCE BASE","code":"US-AK-ZC7"},
          {"name":"EMMONAK","code":"US-AK-EMK"},
          {"name":"ENGLISH BAY","code":"US-AK-KEB"},
          {"name":"EXCURSION INLET","code":"US-AK-EXI"},
          {"name":"FAIRBANKS","code":"US-AK-FAI"},
          {"name":"FALLS BAY","code":"US-AK-FLJ"},
          {"name":"FALSE ISLAND","code":"US-AK-FAK"},
          {"name":"FALSE PASS","code":"US-AK-KFP"},
          {"name":"FAREWELL","code":"US-AK-FWL"},
          {"name":"FINK CREEK","code":"US-AK-FNK"},
          {"name":"FIRE COVE","code":"US-AK-FIC"},
          {"name":"FIVE FINGER","code":"US-AK-FIV"},
          {"name":"FIVE MILE","code":"US-AK-FMC"},
          {"name":"FLAT","code":"US-AK-FLT"},
          {"name":"FLAXMAN ISLAND","code":"US-AK-FXM"},
          {"name":"FORT RICHARDSON","code":"US-AK-FRN"},
          {"name":"FORT YUKON","code":"US-AK-FYU"},
          {"name":"FORTUNA LEDGE","code":"US-AK-FTL"},
          {"name":"FOX","code":"US-AK-FOX"},
          {"name":"FRESH WATER BAY","code":"US-AK-FRP"},
          {"name":"FUNTER BAY","code":"US-AK-FNR"},
          {"name":"GAKONA","code":"US-AK-GAK"},
          {"name":"GALBRAITH LAKE","code":"US-AK-GBH"},
          {"name":"GALENA","code":"US-AK-GAL"},
          {"name":"GAMBELL","code":"US-AK-GAM"},
          {"name":"GANES CREEK","code":"US-AK-GEK"},
          {"name":"GERTRUDE COVE","code":"US-AK-TUV"},
          {"name":"GIRDWOOD","code":"US-AK-GWK"},
          {"name":"GLACIER CREEK","code":"US-AK-KGZ"},
          {"name":"GLENNALLEN","code":"US-AK-GLQ"},
          {"name":"GOLDEN HORN","code":"US-AK-GDH"},
          {"name":"GOLOVIN","code":"US-AK-GLV"},
          {"name":"GOODNEWS BAY","code":"US-AK-GNU"},
          {"name":"GRAEHL","code":"US-AK-GHQ"},
          {"name":"GRANITE MOUNTAIN","code":"US-AK-GMT"},
          {"name":"GRAYLING","code":"US-AK-KGX"},
          {"name":"GULKANA","code":"US-AK-GKN"},
          {"name":"GUSTAVUS","code":"US-AK-GST"},
          {"name":"HAINES","code":"US-AK-HNS"},
          {"name":"HANUS BAY","code":"US-AK-HBC"},
          {"name":"HAWK INLET","code":"US-AK-HWI"},
          {"name":"HAYCOCK","code":"US-AK-HAY"},
          {"name":"HEALY","code":"US-AK-HLZ"},
          {"name":"HEALY LAKE","code":"US-AK-HKB"},
          {"name":"HERENDEEN","code":"US-AK-HED"},
          {"name":"HIDDEN FALLS","code":"US-AK-HDA"},
          {"name":"HOBART BAY","code":"US-AK-HBH"},
          {"name":"HOGATZA","code":"US-AK-HGZ"},
          {"name":"HOLIKACHUK","code":"US-AK-HOL"},
          {"name":"HOLLIS","code":"US-AK-HYL"},
          {"name":"HOLY CROSS","code":"US-AK-HCR"},
          {"name":"HOMER","code":"US-AK-HOM"},
          {"name":"HOMESHORE","code":"US-AK-HMS"},
          {"name":"HOONAH","code":"US-AK-HNH"},
          {"name":"HOOPER BAY","code":"US-AK-HPB"},
          {"name":"HOUSTON","code":"US-AK-HKA"},
          {"name":"HUGHES","code":"US-AK-HUS"},
          {"name":"HUSLIA","code":"US-AK-HSL"},
          {"name":"HYDABURG","code":"US-AK-HYG"},
          {"name":"HYDER","code":"US-AK-WHD"},
          {"name":"ICY BAY","code":"US-AK-ICY"},
          {"name":"IGIUGIG","code":"US-AK-IGG"},
          {"name":"ILIAMNA","code":"US-AK-ILI"},
          {"name":"ISABEL PASS","code":"US-AK-ISL"},
          {"name":"IVANOF BAY","code":"US-AK-KIB"},
          {"name":"IVISHAK","code":"US-AK-IVH"},
          {"name":"JAKOLOF BAY","code":"US-AK-JKF"},
          {"name":"JUNEAU","code":"US-AK-JNU"},
          {"name":"KAGUYAK","code":"US-AK-KAG"},
          {"name":"KAGVIK CREEK","code":"US-AK-KKF"},
          {"name":"KAKE","code":"US-AK-KAE"},
          {"name":"KAKHONAK","code":"US-AK-KNK"},
          {"name":"KALAKAKET","code":"US-AK-KKK"},
          {"name":"KALSKAG","code":"US-AK-KLG"},
          {"name":"KALTAG","code":"US-AK-KAL"},
          {"name":"KARLUK","code":"US-AK-KYK"},
          {"name":"KARLUK LAKE","code":"US-AK-KKL"},
          {"name":"KASAAN","code":"US-AK-KXA"},
          {"name":"KASIGLUK","code":"US-AK-KUK"},
          {"name":"KASILOF","code":"US-AK-KFO"},
          {"name":"KAVIK","code":"US-AK-VIK"},
          {"name":"KELLY BAR","code":"US-AK-KEU"},
          {"name":"KELP BAY","code":"US-AK-KLP"},
          {"name":"KENAI","code":"US-AK-ENA"},
          {"name":"KETCHIKAN","code":"US-AK-KTN"},
          {"name":"KIANA","code":"US-AK-IAN"},
          {"name":"KING COVE","code":"US-AK-KVC"},
          {"name":"KING SALMON","code":"US-AK-AKN"},
          {"name":"KIPNUK","code":"US-AK-KPN"},
          {"name":"KISKA HARBOUR","code":"US-AK-KAB"},
          {"name":"KISKA ISLAND","code":"US-AK-KIS"},
          {"name":"KITOI BAY","code":"US-AK-KKB"},
          {"name":"KIVALINA","code":"US-AK-KVL"},
          {"name":"KIZHUYAK","code":"US-AK-KZH"},
          {"name":"KLAG BAY","code":"US-AK-KBK"},
          {"name":"KLAWOCK","code":"US-AK-KLW"},
          {"name":"KOBUK","code":"US-AK-OBU"},
          {"name":"KODIAK","code":"US-AK-ADQ"},
          {"name":"KONGIGANAK","code":"US-AK-KKH"},
          {"name":"KOTLIK","code":"US-AK-KOT"},
          {"name":"KOTZEBUE","code":"US-AK-OTZ"},
          {"name":"KOYUK","code":"US-AK-KKA"},
          {"name":"KOYUKUK","code":"US-AK-KYU"},
          {"name":"KUGURUROK RIVER","code":"US-AK-KUW"},
          {"name":"KULIK LAKE","code":"US-AK-LKK"},
          {"name":"KULUK BAY","code":"US-AK-KUB"},
          {"name":"KUPARUK","code":"US-AK-UUK"},
          {"name":"KWETHLUK","code":"US-AK-KWT"},
          {"name":"KWIGILLINGOK","code":"US-AK-KWK"},
          {"name":"LABOUCHERE BAY","code":"US-AK-WLB"},
          {"name":"LAKE MINCHUMINA","code":"US-AK-LMA"},
          {"name":"LARSEN BAY","code":"US-AK-KLN"},
          {"name":"LEMETA","code":"US-AK-LMZ"},
          {"name":"LENARD HARBOUR","code":"US-AK-LH7"},
          {"name":"LEVELOCK","code":"US-AK-KLL"},
          {"name":"LIME VILLAGE","code":"US-AK-LVD"},
          {"name":"LINCOLN ROCK","code":"US-AK-LRK"},
          {"name":"LITTLE NAUKATI","code":"US-AK-WLN"},
          {"name":"LITTLE PORT WALTER","code":"US-AK-LPW"},
          {"name":"LIVENGOOD","code":"US-AK-LIV"},
          {"name":"LONELY","code":"US-AK-LNI"},
          {"name":"LONG ISLAND","code":"US-AK-LIJ"},
          {"name":"LORING","code":"US-AK-WLR"},
          {"name":"LOST HARBOR","code":"US-AK-LHB"},
          {"name":"LOST RIVER","code":"US-AK-LSR"},
          {"name":"MANLEY HOT SPRINGS","code":"US-AK-MLY"},
          {"name":"MANOKOTAK","code":"US-AK-KMO"},
          {"name":"MARGUERITE BAY","code":"US-AK-RTE"},
          {"name":"MARSHALL","code":"US-AK-M5H"},
          {"name":"MATANUSKA","code":"US-AK-MTU"},
          {"name":"MAY CREEK","code":"US-AK-MYK"},
          {"name":"MCCARTHY","code":"US-AK-MXY"},
          {"name":"MCGRATH","code":"US-AK-MCG"},
          {"name":"MCKINLEY PARK","code":"US-AK-MLA"},
          {"name":"MEDFRA","code":"US-AK-MDR"},
          {"name":"MEKORYUK","code":"US-AK-MYU"},
          {"name":"METLAKATLA","code":"US-AK-MTM"},
          {"name":"MEYERS CHUCK","code":"US-AK-WMK"},
          {"name":"MIDDLETON ISLAND","code":"US-AK-MDO"},
          {"name":"MINCHUMINA","code":"US-AK-MHM"},
          {"name":"MINTO","code":"US-AK-MNT"},
          {"name":"MOOSE PASS","code":"US-AK-MSZ"},
          {"name":"MOSER BAY","code":"US-AK-KMY"},
          {"name":"MOSES POINT","code":"US-AK-MOS"},
          {"name":"MOUNT MCKINLEY","code":"US-AK-MCL"},
          {"name":"MOUNTAIN VILLAGE","code":"US-AK-MOU"},
          {"name":"NAKNEK","code":"US-AK-NNK"},
          {"name":"NAKOLIK RIVER","code":"US-AK-NOL"},
          {"name":"NAPAKIAK","code":"US-AK-WNA"},
          {"name":"NAPASKIAK","code":"US-AK-PKA"},
          {"name":"NAUKITI","code":"US-AK-NKI"},
          {"name":"NEETS BAY","code":"US-AK-NB4"},
          {"name":"NELSON LAGOON","code":"US-AK-NLG"},
          {"name":"NENANA","code":"US-AK-ENN"},
          {"name":"NEW CHENEGA","code":"US-AK-NCN"},
          {"name":"NEW KOLIGANEK","code":"US-AK-KGK"},
          {"name":"NEW STUYAHOK","code":"US-AK-KNW"},
          {"name":"NEWTOK","code":"US-AK-WWT"},
          {"name":"NIBLACK","code":"US-AK-NIE"},
          {"name":"NICHEN COVE","code":"US-AK-NKV"},
          {"name":"NIGHTMUTE","code":"US-AK-NME"},
          {"name":"NIKISHKA","code":"US-AK-NQK"},
          {"name":"NIKISKI","code":"US-AK-NIK"},
          {"name":"NIKOLAI","code":"US-AK-NIB"},
          {"name":"NIKOLSKI","code":"US-AK-IKO"},
          {"name":"NINILCHIK","code":"US-AK-NIN"},
          {"name":"NOATAK","code":"US-AK-WTK"},
          {"name":"NOME","code":"US-AK-OME"},
          {"name":"NONDALTON","code":"US-AK-NNL"},
          {"name":"NOORVIK","code":"US-AK-ORV"},
          {"name":"NORTH POLE","code":"US-AK-NTP"},
          {"name":"NORTHEAST CAPE","code":"US-AK-OHC"},
          {"name":"NORTHWAY","code":"US-AK-ORT"},
          {"name":"NUIQSUT","code":"US-AK-NUI"},
          {"name":"NULATO","code":"US-AK-NUL"},
          {"name":"NUNAPITCHUK","code":"US-AK-NUP"},
          {"name":"NYAC","code":"US-AK-ZNC"},
          {"name":"OCEANIC","code":"US-AK-OCI"},
          {"name":"OLD HARBOR","code":"US-AK-OLH"},
          {"name":"OLGA BAY","code":"US-AK-KOY"},
          {"name":"ONION BAY","code":"US-AK-ONN"},
          {"name":"ORCA","code":"US-AK-OSZ"},
          {"name":"OUZINKIE","code":"US-AK-KOZ"},
          {"name":"PACK CREEK","code":"US-AK-PBK"},
          {"name":"PAF WARREN","code":"US-AK-PFA"},
          {"name":"PAIMIUT","code":"US-AK-PMU"},
          {"name":"PAINTER CREEK","code":"US-AK-PCE"},
          {"name":"PALMER","code":"US-AK-PAQ"},
          {"name":"PARKS","code":"US-AK-KPK"},
          {"name":"PAULOFF HARBOR","code":"US-AK-KPH"},
          {"name":"PAXSON","code":"US-AK-PAX"},
          {"name":"PEDRO BAY","code":"US-AK-PDB"},
          {"name":"PELICAN","code":"US-AK-PEC"},
          {"name":"PERRY ISLAND","code":"US-AK-PYL"},
          {"name":"PERRYVILLE","code":"US-AK-KPV"},
          {"name":"PETERSBURG","code":"US-AK-PSG"},
          {"name":"PETERSON'S POINT","code":"US-AK-PNF"},
          {"name":"PILOT POINT","code":"US-AK-PIP"},
          {"name":"PILOT STATION","code":"US-AK-PQS"},
          {"name":"PLATINUM","code":"US-AK-PTU"},
          {"name":"PLEASANT HARBOUR","code":"US-AK-PTR"},
          {"name":"PLEASANT ISLAND","code":"US-AK-PI7"},
          {"name":"POINT BAKER","code":"US-AK-KPB"},
          {"name":"POINT BARROW","code":"US-AK-TBW"},
          {"name":"POINT HOPE","code":"US-AK-PHO"},
          {"name":"POINT LAY","code":"US-AK-PIZ"},
          {"name":"POINT RETREAT","code":"US-AK-PRT"},
          {"name":"POLK INLET","code":"US-AK-POQ"},
          {"name":"POPE VANOY","code":"US-AK-PVY"},
          {"name":"PORCUPINE CREEK","code":"US-AK-PCK"},
          {"name":"PORT ALEXANDER","code":"US-AK-PTD"},
          {"name":"PORT ALICE","code":"US-AK-PTC"},
          {"name":"PORT ALSWORTH","code":"US-AK-PTA"},
          {"name":"PORT ARMSTRONG","code":"US-AK-PTL"},
          {"name":"PORT BAILEY","code":"US-AK-KPY"},
          {"name":"PORT CLARENCE","code":"US-AK-KPC"},
          {"name":"PORT FREDERICK","code":"US-AK-PFD"},
          {"name":"PORT GRAHAM","code":"US-AK-PGM"},
          {"name":"PORT HEIDEN","code":"US-AK-PTH"},
          {"name":"PORT JOHNSON","code":"US-AK-PRF"},
          {"name":"PORT LIONS","code":"US-AK-ORI"},
          {"name":"PORT MACKENZIE","code":"US-AK-PMV"},
          {"name":"PORT MOLLER","code":"US-AK-PML"},
          {"name":"PORT OCEANIC","code":"US-AK-PRL"},
          {"name":"PORT PROTECTION","code":"US-AK-PPV"},
          {"name":"PORT SAN JUAN","code":"US-AK-PJS"},
          {"name":"PORT WAKEFIELD","code":"US-AK-PWF"},
          {"name":"PORT WALTER","code":"US-AK-PWR"},
          {"name":"PORT WILLIAMS","code":"US-AK-PWI"},
          {"name":"PORTAGE CREEK","code":"US-AK-PCA"},
          {"name":"PROSPECT CREEK","code":"US-AK-PPC"},
          {"name":"PRUDHOE BAY","code":"US-AK-SCC"},
          {"name":"QUEEN","code":"US-AK-UQE"},
          {"name":"QUINHAGAK","code":"US-AK-KWN"},
          {"name":"RAMPART","code":"US-AK-RMP"},
          {"name":"RASPBERRY STRAIT","code":"US-AK-RSP"},
          {"name":"RED DEVIL","code":"US-AK-RDV"},
          {"name":"RED DOG","code":"US-AK-RDB"},
          {"name":"ROWAN BAY","code":"US-AK-RWB"},
          {"name":"RUBY","code":"US-AK-RBY"},
          {"name":"RUSSIAN MISSION","code":"US-AK-RSH"},
          {"name":"SAGINAW BAY","code":"US-AK-SGW"},
          {"name":"SAGWON","code":"US-AK-SAG"},
          {"name":"SAINT GEORGE ISLAND","code":"US-AK-STG"},
          {"name":"SAINT MARYS","code":"US-AK-KSM"},
          {"name":"SAINT MICHAEL","code":"US-AK-SMK"},
          {"name":"SAINT PAUL ISLAND","code":"US-AK-SNP"},
          {"name":"SAN JUAN","code":"US-AK-WSJ"},
          {"name":"SAND POINT","code":"US-AK-SDP"},
          {"name":"SANDY RIVER","code":"US-AK-KSR"},
          {"name":"SARICHEF","code":"US-AK-WSF"},
          {"name":"SAVOONGA","code":"US-AK-SVA"},
          {"name":"SCAMMON BAY","code":"US-AK-SCM"},
          {"name":"SEAL BAY","code":"US-AK-SYB"},
          {"name":"SELAWIK","code":"US-AK-WLK"},
          {"name":"SELDOVIA","code":"US-AK-SOV"},
          {"name":"SEWARD","code":"US-AK-SWD"},
          {"name":"SHAGELUK","code":"US-AK-SHX"},
          {"name":"SHAKTOOLIK","code":"US-AK-SKK"},
          {"name":"SHEEP MOUNTAIN","code":"US-AK-SMU"},
          {"name":"SHELDON POINT","code":"US-AK-SXP"},
          {"name":"SHEMYA","code":"US-AK-SYA"},
          {"name":"SHISHMAREF","code":"US-AK-SHH"},
          {"name":"SHOAL COVE","code":"US-AK-HCB"},
          {"name":"SHUNGNAK","code":"US-AK-SHG"},
          {"name":"SITKA","code":"US-AK-SIT"},
          {"name":"SITKINAK ISLAND","code":"US-AK-SKJ"},
          {"name":"SKAGWAY","code":"US-AK-SGY"},
          {"name":"SKWENTNA","code":"US-AK-SKW"},
          {"name":"SLEDGE ISLAND","code":"US-AK-SI2"},
          {"name":"SLEETMUTE","code":"US-AK-SLQ"},
          {"name":"SMITH COVE","code":"US-AK-SCJ"},
          {"name":"SOLDOTNA","code":"US-AK-SXQ"},
          {"name":"SOLOMON","code":"US-AK-SOL"},
          {"name":"SOUTH NAKNEK","code":"US-AK-WSN"},
          {"name":"SPARREVOHN","code":"US-AK-SVW"},
          {"name":"SPENARD","code":"US-AK-SNX"},
          {"name":"STEAMBOAT BAY","code":"US-AK-WSB"},
          {"name":"STEBBINS","code":"US-AK-WBB"},
          {"name":"STERLING","code":"US-AK-SIG"},
          {"name":"STEVENS VILLAGE","code":"US-AK-SVS"},
          {"name":"STONY RIVER","code":"US-AK-SRV"},
          {"name":"SUMMIT","code":"US-AK-UMM"},
          {"name":"SUSITNA","code":"US-AK-UIT"},
          {"name":"SUTTON","code":"US-AK-SXU"},
          {"name":"TAHNETA PASS LODGE","code":"US-AK-HNE"},
          {"name":"TAKOTNA","code":"US-AK-TCT"},
          {"name":"TAKU LODGE","code":"US-AK-TKL"},
          {"name":"TALKEETNA","code":"US-AK-TKA"},
          {"name":"TANACROSS","code":"US-AK-TSG"},
          {"name":"TANAGA BAY","code":"US-AK-TBJ"},
          {"name":"TANALIAN POINT","code":"US-AK-TPO"},
          {"name":"TANANA","code":"US-AK-TAL"},
          {"name":"TATALINA","code":"US-AK-TLJ"},
          {"name":"TATITLEK","code":"US-AK-TEK"},
          {"name":"TAYLOR","code":"US-AK-TWE"},
          {"name":"TELIDA","code":"US-AK-TLF"},
          {"name":"TELLER","code":"US-AK-TLA"},
          {"name":"TELLER MISSION","code":"US-AK-KTS"},
          {"name":"TENAKEE SPRINGS","code":"US-AK-TKE"},
          {"name":"TETLIN","code":"US-AK-TEH"},
          {"name":"THORNE BAY","code":"US-AK-KTB"},
          {"name":"TIKCHIK","code":"US-AK-KTH"},
          {"name":"TIN CITY","code":"US-AK-TNC"},
          {"name":"TOGIAK","code":"US-AK-TGK"},
          {"name":"TOGIAK FISH","code":"US-AK-GFB"},
          {"name":"TOGIAK VILLAGE","code":"US-AK-TOG"},
          {"name":"TOK","code":"US-AK-TKJ"},
          {"name":"TOKEEN","code":"US-AK-TKI"},
          {"name":"TOKSOOK BAY","code":"US-AK-OOK"},
          {"name":"TREE POINT","code":"US-AK-TRP"},
          {"name":"TULUKSAK","code":"US-AK-TLT"},
          {"name":"TUNTUTULIAK","code":"US-AK-WTL"},
          {"name":"TUNUNAK","code":"US-AK-TNK"},
          {"name":"TUXEKAN ISLAND","code":"US-AK-WNC"},
          {"name":"TWIN HILLS","code":"US-AK-TWA"},
          {"name":"TYEE","code":"US-AK-TE8"},
          {"name":"TYONEK","code":"US-AK-TYE"},
          {"name":"UGANIK","code":"US-AK-UGI"},
          {"name":"UGASHIK","code":"US-AK-UGS"},
          {"name":"UMIAT","code":"US-AK-UMT"},
          {"name":"UMNAK ISLAND","code":"US-AK-UMB"},
          {"name":"UNALAKLEET","code":"US-AK-UNK"},
          {"name":"UNALASKA","code":"US-AK-UAA"},
          {"name":"USIBELLI","code":"US-AK-UEI"},
          {"name":"UTOPIA CREEK","code":"US-AK-UTO"},
          {"name":"UYAK","code":"US-AK-KUY"},
          {"name":"VALDEZ","code":"US-AK-VDZ"},
          {"name":"VENETIE","code":"US-AK-VEE"},
          {"name":"WAINWRIGHT","code":"US-AK-AIN"},
          {"name":"WALES","code":"US-AK-WAA"},
          {"name":"WARD COVE","code":"US-AK-WDC"},
          {"name":"WASILLA","code":"US-AK-WWA"},
          {"name":"WATERFALL","code":"US-AK-KWF"},
          {"name":"WEST KAVIK","code":"US-AK-VKW"},
          {"name":"WEST KUPARUK","code":"US-AK-XPU"},
          {"name":"WEST POINT","code":"US-AK-KWP"},
          {"name":"WHALE PASS","code":"US-AK-WWP"},
          {"name":"WHITE MOUNTAIN","code":"US-AK-WMO"},
          {"name":"WHITTIER","code":"US-AK-WQF"},
          {"name":"WILLOW","code":"US-AK-WOW"},
          {"name":"WRANGELL","code":"US-AK-WRG"},
          {"name":"YAKUTAT","code":"US-AK-YAK"},
          {"name":"YOKE BAY","code":"US-AK-YOK"},
          {"name":"ZACHAR BAY","code":"US-AK-KZB"},
        ]
      },
      {
        name: 'Arizona',
        code: 'US-AZ',
        cities: [
          {"name":"AJO","code":"US-AZ-AJ9"},
          {"name":"AMADO","code":"US-AZ-AZ6"},
          {"name":"ANTHEM","code":"US-AZ-AJH"},
          {"name":"APACHE JUNCTION","code":"US-AZ-APJ"},
          {"name":"ARLINGTON","code":"US-AZ-AGZ"},
          {"name":"ASH FORK","code":"US-AZ-AF8"},
          {"name":"AVONDALE","code":"US-AZ-AVG"},
          {"name":"BAGDAD","code":"US-AZ-BGT"},
          {"name":"BELLEMONT","code":"US-AZ-BM2"},
          {"name":"BENSON","code":"US-AZ-BN6"},
          {"name":"BISBEE","code":"US-AZ-BSQ"},
          {"name":"BLACK CANYON CITY","code":"US-AZ-ZA7"},
          {"name":"BOUSE","code":"US-AZ-XUS"},
          {"name":"BOWIE","code":"US-AZ-AGF"},
          {"name":"BUCKEYE","code":"US-AZ-BXK"},
          {"name":"BULLHEAD CITY","code":"US-AZ-IFP"},
          {"name":"CAMP VERDE","code":"US-AZ-CP9"},
          {"name":"CAREFREE","code":"US-AZ-EFQ"},
          {"name":"CASA GRANDE","code":"US-AZ-CGZ"},
          {"name":"CASHION","code":"US-AZ-C4S"},
          {"name":"CATALINA","code":"US-AZ-QTA"},
          {"name":"CAVE CREEK","code":"US-AZ-VCC"},
          {"name":"CENTRAL","code":"US-AZ-IJU"},
          {"name":"CHAMBERS","code":"US-AZ-M9B"},
          {"name":"CHANDLER","code":"US-AZ-CHD"},
          {"name":"CHINLE","code":"US-AZ-H5C"},
          {"name":"CHINO VALLEY","code":"US-AZ-CIO"},
          {"name":"CIBECUE","code":"US-AZ-C5C"},
          {"name":"CLARKDALE","code":"US-AZ-KFQ"},
          {"name":"CLAYPOOL","code":"US-AZ-CP2"},
          {"name":"CLIFTON","code":"US-AZ-CFT"},
          {"name":"COCHISE","code":"US-AZ-C55"},
          {"name":"CONCHO","code":"US-AZ-KZO"},
          {"name":"CONGRESS","code":"US-AZ-NZF"},
          {"name":"COOLIDGE","code":"US-AZ-YDG"},
          {"name":"CORNVILLE","code":"US-AZ-C35"},
          {"name":"COTTONWOOD","code":"US-AZ-CTW"},
          {"name":"DATELAND","code":"US-AZ-DL3"},
          {"name":"DAVIS MONTHAN AFB","code":"US-AZ-DFB"},
          {"name":"DESERT HILLS","code":"US-AZ-DDH"},
          {"name":"DEWEY","code":"US-AZ-DE9"},
          {"name":"DOUGLAS","code":"US-AZ-DUG"},
          {"name":"DRAKE","code":"US-AZ-DK2"},
          {"name":"EAGAR","code":"US-AZ-E4G"},
          {"name":"EHRENBERG","code":"US-AZ-EH7"},
          {"name":"EL MIRAGE","code":"US-AZ-EMZ"},
          {"name":"ELFRIDA","code":"US-AZ-ERD"},
          {"name":"ELOY","code":"US-AZ-EOY"},
          {"name":"FLAGSTAFF","code":"US-AZ-FLG"},
          {"name":"FLORENCE","code":"US-AZ-ZZO"},
          {"name":"FORT HUACHUCA-SIERRA VISTA APT","code":"US-AZ-FHU"},
          {"name":"FORT MOHAVE","code":"US-AZ-F7M"},
          {"name":"FOUNTAIN HILLS","code":"US-AZ-QWI"},
          {"name":"FREDONIA","code":"US-AZ-DZX"},
          {"name":"GANADO","code":"US-AZ-GD2"},
          {"name":"GILA BEND","code":"US-AZ-GBN"},
          {"name":"GILBERT","code":"US-AZ-GBE"},
          {"name":"GLENDALE","code":"US-AZ-GDA"},
          {"name":"GLOBE","code":"US-AZ-GBA"},
          {"name":"GOLD CANYON","code":"US-AZ-G9Y"},
          {"name":"GOLDEN VALLEY","code":"US-AZ-G9V"},
          {"name":"GOODYEAR","code":"US-AZ-GYR"},
          {"name":"GRAND CANYON","code":"US-AZ-GCN"},
          {"name":"GRAND CANYON WEST APT","code":"US-AZ-GCW"},
          {"name":"GREEN VALLEY","code":"US-AZ-GV6"},
          {"name":"GREER","code":"US-AZ-GY8"},
          {"name":"GRIFFITH","code":"US-AZ-GYT"},
          {"name":"HAVASUPAI","code":"US-AZ-HAE"},
          {"name":"HAYDEN","code":"US-AZ-HY2"},
          {"name":"HIGLEY","code":"US-AZ-HGE"},
          {"name":"HOLBROOK","code":"US-AZ-HB8"},
          {"name":"HUMBOLDT","code":"US-AZ-ZD7"},
          {"name":"JEROME","code":"US-AZ-J23"},
          {"name":"JOSEPH CITY","code":"US-AZ-JPC"},
          {"name":"KAYENTA","code":"US-AZ-MVM"},
          {"name":"KEARNY","code":"US-AZ-NYF"},
          {"name":"KINGMAN","code":"US-AZ-IGM"},
          {"name":"LAKE HAVASU CITY","code":"US-AZ-HII"},
          {"name":"LAKESIDE","code":"US-AZ-L9S"},
          {"name":"LAVEEN","code":"US-AZ-LVH"},
          {"name":"LITCHFIELD PARK","code":"US-AZ-IEL"},
          {"name":"LUKEVILLE","code":"US-AZ-LUD"},
          {"name":"MAGMA","code":"US-AZ-MG7"},
          {"name":"MANY FARMS","code":"US-AZ-F8M"},
          {"name":"MARANA","code":"US-AZ-MZJ"},
          {"name":"MARBLE CANYON","code":"US-AZ-MYH"},
          {"name":"MARICOPA","code":"US-AZ-EPC"},
          {"name":"MAYER","code":"US-AZ-M9Y"},
          {"name":"MESA","code":"US-AZ-MSC"},
          {"name":"MIAMI","code":"US-AZ-4MI"},
          {"name":"MOHAVE VALLEY","code":"US-AZ-MY7"},
          {"name":"MORENCI","code":"US-AZ-ONJ"},
          {"name":"NACO","code":"US-AZ-NAC"},
          {"name":"NEW RIVER","code":"US-AZ-NZR"},
          {"name":"NOGALES","code":"US-AZ-OLS"},
          {"name":"OATMAN","code":"US-AZ-OMX"},
          {"name":"ORO VALLEY","code":"US-AZ-OVU"},
          {"name":"OVERGAARD","code":"US-AZ-3QW"},
          {"name":"PAGE","code":"US-AZ-PGA"},
          {"name":"PARADISE VALLEY","code":"US-AZ-PDV"},
          {"name":"PARKER","code":"US-AZ-AAZ"},
          {"name":"PARKS","code":"US-AZ-PK3"},
          {"name":"PAULDEN","code":"US-AZ-PD6"},
          {"name":"PAYSON","code":"US-AZ-PJB"},
          {"name":"PEACH SPRINGS","code":"US-AZ-PGS"},
          {"name":"PEARCE","code":"US-AZ-PE6"},
          {"name":"PEORIA","code":"US-AZ-PEI"},
          {"name":"PHOENIX","code":"US-AZ-PHX"},
          {"name":"PICACHO","code":"US-AZ-PIC"},
          {"name":"PIMA","code":"US-AZ-GPM"},
          {"name":"PINE","code":"US-AZ-JPN"},
          {"name":"PINEDALE","code":"US-AZ-PNA"},
          {"name":"PINETOP","code":"US-AZ-P2T"},
          {"name":"POLACCA","code":"US-AZ-PXL"},
          {"name":"PRESCOTT","code":"US-AZ-PRC"},
          {"name":"PRESCOTT VALLEY","code":"US-AZ-PV4"},
          {"name":"QUARTZSITE","code":"US-AZ-QTZ"},
          {"name":"QUEEN CREEK","code":"US-AZ-QCK"},
          {"name":"RED ROCK","code":"US-AZ-9RR"},
          {"name":"RED VALLEY","code":"US-AZ-VFA"},
          {"name":"RILLITO","code":"US-AZ-RI9"},
          {"name":"RIO RICO","code":"US-AZ-RRQ"},
          {"name":"ROLL","code":"US-AZ-3IX"},
          {"name":"SAFFORD","code":"US-AZ-SAD"},
          {"name":"SAHUARITA","code":"US-AZ-SR6"},
          {"name":"SAINT JOHNS","code":"US-AZ-SJN"},
          {"name":"SALOME","code":"US-AZ-ZOM"},
          {"name":"SAN CARLOS, GRAHAM","code":"US-AZ-SZQ"},
          {"name":"SAN CARLOS, MARICOPA","code":"US-AZ-XSC"},
          {"name":"SAN LUIS","code":"US-AZ-SLI"},
          {"name":"SAN MANUEL","code":"US-AZ-2SM"},
          {"name":"SAN TAN VALLEY","code":"US-AZ-ZVP"},
          {"name":"SANDERS","code":"US-AZ-DHK"},
          {"name":"SASABE","code":"US-AZ-SAQ"},
          {"name":"SCOTTSDALE","code":"US-AZ-STZ"},
          {"name":"SEDONA","code":"US-AZ-SDX"},
          {"name":"SELIGMAN","code":"US-AZ-S66"},
          {"name":"SHOW LOW","code":"US-AZ-SOW"},
          {"name":"SIERRA VISTA","code":"US-AZ-AZS"},
          {"name":"SIERRA VISTA-FORT HUACHUCA APT","code":"US-AZ-FHU"},
          {"name":"SKULL VALLEY","code":"US-AZ-KVQ"},
          {"name":"SNOWFLAKE","code":"US-AZ-SFL"},
          {"name":"SOMERTON","code":"US-AZ-SN7"},
          {"name":"SONOITA","code":"US-AZ-QNI"},
          {"name":"SOUTH TUCSON","code":"US-AZ-UT3"},
          {"name":"SPRINGERVILLE","code":"US-AZ-AZV"},
          {"name":"STANFIELD","code":"US-AZ-TFD"},
          {"name":"SUN CITY","code":"US-AZ-SCI"},
          {"name":"SUPERIOR","code":"US-AZ-AZ3"},
          {"name":"SURPRISE","code":"US-AZ-URS"},
          {"name":"TAYLOR","code":"US-AZ-TYZ"},
          {"name":"TEMPE","code":"US-AZ-TPE"},
          {"name":"THATCHER","code":"US-AZ-YUZ"},
          {"name":"TOLLESON","code":"US-AZ-OLQ"},
          {"name":"TOMBSTONE","code":"US-AZ-TBS"},
          {"name":"TONOPAH","code":"US-AZ-TOH"},
          {"name":"TUBA CITY","code":"US-AZ-TBC"},
          {"name":"TUBAC","code":"US-AZ-TBZ"},
          {"name":"TUCSON","code":"US-AZ-TUZ"},
          {"name":"TUCSON INTERNATIONAL APT","code":"US-AZ-TUS"},
          {"name":"TUSAYAN","code":"US-AZ-T2N"},
          {"name":"VAIL","code":"US-AZ-VAH"},
          {"name":"VALLE","code":"US-AZ-VLE"},
          {"name":"VANAR","code":"US-AZ-VAR"},
          {"name":"WADDELL","code":"US-AZ-W9D"},
          {"name":"WELLTON","code":"US-AZ-WZD"},
          {"name":"WEST SEDONA","code":"US-AZ-XOM"},
          {"name":"WHITE RIVER","code":"US-AZ-WTR"},
          {"name":"WHITERIVER","code":"US-AZ-IIK"},
          {"name":"WICKENBURG","code":"US-AZ-APW"},
          {"name":"WILLCOX","code":"US-AZ-ICX"},
          {"name":"WILLIAMS","code":"US-AZ-IFM"},
          {"name":"WINSLOW","code":"US-AZ-INW"},
          {"name":"WITTMANN","code":"US-AZ-WTJ"},
          {"name":"YUCCA","code":"US-AZ-YCC"},
          {"name":"YUMA","code":"US-AZ-YUM"},
        ]
      },
      {
        name: 'Arkansas',
        code: 'US-AR',
        cities: [
          {"name":"ALEXANDER","code":"US-AR-YAX"},
          {"name":"ALMA","code":"US-AR-RAA"},
          {"name":"ALTHEIMER","code":"US-AR-TMR"},
          {"name":"ALTUS","code":"US-AR-AZX"},
          {"name":"AMITY","code":"US-AR-IY3"},
          {"name":"ARKADELPHIA","code":"US-AR-ADA"},
          {"name":"ARKANSAS CITY","code":"US-AR-ASC"},
          {"name":"ARMOREL","code":"US-AR-AXE"},
          {"name":"ASH FLAT","code":"US-AR-AZF"},
          {"name":"ASHDOWN","code":"US-AR-ADN"},
          {"name":"ATKINS","code":"US-AR-ATF"},
          {"name":"AUGUSTA","code":"US-AR-AUT"},
          {"name":"AUSTIN","code":"US-AR-AKU"},
          {"name":"AUSTIN","code":"US-AR-NA8"},
          {"name":"BALD KNOB","code":"US-AR-ZBB"},
          {"name":"BARLING","code":"US-AR-ZA2"},
          {"name":"BARTON","code":"US-AR-OBN"},
          {"name":"BATESVILLE","code":"US-AR-BTS"},
          {"name":"BAUXITE","code":"US-AR-BXT"},
          {"name":"BEARDEN","code":"US-AR-BJD"},
          {"name":"BEEBE","code":"US-AR-YEE"},
          {"name":"BEECH GLOVE","code":"US-AR-BV7"},
          {"name":"BEIRNE","code":"US-AR-BR3"},
          {"name":"BELLA VISTA","code":"US-AR-EIS"},
          {"name":"BENTON","code":"US-AR-BXN"},
          {"name":"BENTONVILLE","code":"US-AR-BNT"},
          {"name":"BERRYVILLE","code":"US-AR-BVV"},
          {"name":"BETHEL HEIGHTS","code":"US-AR-BH6"},
          {"name":"BIGELOW","code":"US-AR-BWH"},
          {"name":"BIGFORK","code":"US-AR-IFK"},
          {"name":"BISMARCK","code":"US-AR-B2S"},
          {"name":"BLACK ROCK","code":"US-AR-B4R"},
          {"name":"BLACKWELL","code":"US-AR-2BL"},
          {"name":"BLYTHEVILLE","code":"US-AR-BYH"},
          {"name":"BONO","code":"US-AR-QOO"},
          {"name":"BOONEVILLE","code":"US-AR-BOQ"},
          {"name":"BRADFORD","code":"US-AR-BDO"},
          {"name":"BRADLEY","code":"US-AR-YBA"},
          {"name":"BRINKLEY","code":"US-AR-RIY"},
          {"name":"BROCKWELL","code":"US-AR-BWJ"},
          {"name":"BROOKLAND","code":"US-AR-9BE"},
          {"name":"BRUNO","code":"US-AR-B6U"},
          {"name":"BRYANT","code":"US-AR-YNT"},
          {"name":"BUCKEYE","code":"US-AR-BKI"},
          {"name":"BYANT","code":"US-AR-TNA"},
          {"name":"CABOT","code":"US-AR-EQA"},
          {"name":"CAMDEN","code":"US-AR-CDH"},
          {"name":"CARAWAY","code":"US-AR-XYY"},
          {"name":"CARLISLE","code":"US-AR-CUZ"},
          {"name":"CASH","code":"US-AR-CS2"},
          {"name":"CAVE CITY","code":"US-AR-VIY"},
          {"name":"CAVE SPRING","code":"US-AR-4CS"},
          {"name":"CECIL","code":"US-AR-IQC"},
          {"name":"CENTERTON","code":"US-AR-C6N"},
          {"name":"CENTERVILLE","code":"US-AR-EZZ"},
          {"name":"CHARLESTON","code":"US-AR-ZB4"},
          {"name":"CHEROKEE","code":"US-AR-CKK"},
          {"name":"CHEROKEE VILLAGE","code":"US-AR-ZXV"},
          {"name":"CHERRY VALLEY","code":"US-AR-9CV"},
          {"name":"CLARENDON","code":"US-AR-RNN"},
          {"name":"CLARKSVILLE","code":"US-AR-CLV"},
          {"name":"CLINTON","code":"US-AR-QLA"},
          {"name":"COLT","code":"US-AR-RLR"},
          {"name":"CONWAY","code":"US-AR-ADP"},
          {"name":"CORNING","code":"US-AR-CGC"},
          {"name":"COTTON PLANT","code":"US-AR-OTA"},
          {"name":"COVE","code":"US-AR-CV7"},
          {"name":"CRAWFORDSVILLE","code":"US-AR-CF6"},
          {"name":"CROSSETT","code":"US-AR-CRT"},
          {"name":"CYPRESS BEND","code":"US-AR-YXB"},
          {"name":"DAMASCUS","code":"US-AR-AR5"},
          {"name":"DANVILLE","code":"US-AR-DZV"},
          {"name":"DARDANELLE","code":"US-AR-DAR"},
          {"name":"DE QUEEN","code":"US-AR-DQZ"},
          {"name":"DE WITT","code":"US-AR-DWA"},
          {"name":"DECATUR","code":"US-AR-DTU"},
          {"name":"DEER","code":"US-AR-DE8"},
          {"name":"DELL","code":"US-AR-LLR"},
          {"name":"DERMOTT","code":"US-AR-DEM"},
          {"name":"DES ARC","code":"US-AR-DA9"},
          {"name":"DESHA","code":"US-AR-DE2"},
          {"name":"DIERKS","code":"US-AR-DKS"},
          {"name":"DOUGLAS","code":"US-AR-DGZ"},
          {"name":"DRAPER","code":"US-AR-DRK"},
          {"name":"DUMAS","code":"US-AR-DMS"},
          {"name":"EARLE","code":"US-AR-EAM"},
          {"name":"EAST CAMDEN","code":"US-AR-ECN"},
          {"name":"ECONOMY","code":"US-AR-8AE"},
          {"name":"EL DORADO","code":"US-AR-ELD"},
          {"name":"ELIZABETH","code":"US-AR-EB6"},
          {"name":"ELM SPRINGS","code":"US-AR-ESQ"},
          {"name":"EMMET","code":"US-AR-ET6"},
          {"name":"ENGLAND","code":"US-AR-EAD"},
          {"name":"EUDORA","code":"US-AR-EUD"},
          {"name":"EUREKA SPRINGS","code":"US-AR-E9S"},
          {"name":"EVADALE","code":"US-AR-AE2"},
          {"name":"FAIR OAKS","code":"US-AR-YFO"},
          {"name":"FAIRFIELD BAY","code":"US-AR-A7R"},
          {"name":"FARMINGTON","code":"US-AR-FA4"},
          {"name":"FAYETTEVILLE","code":"US-AR-FYV"},
          {"name":"FLIPPIN","code":"US-AR-FPP"},
          {"name":"FORDYCE","code":"US-AR-FDC"},
          {"name":"FOREMAN","code":"US-AR-FM8"},
          {"name":"FOREST PARK","code":"US-AR-FTA"},
          {"name":"FORREST CITY","code":"US-AR-FCY"},
          {"name":"FORT CHAFFEE","code":"US-AR-CCA"},
          {"name":"FORT SMITH","code":"US-AR-FSM"},
          {"name":"FREDONIA","code":"US-AR-FDO"},
          {"name":"FULTON","code":"US-AR-FUB"},
          {"name":"GARFIELD","code":"US-AR-GPB"},
          {"name":"GASSVILLE","code":"US-AR-GAV"},
          {"name":"GENTRY","code":"US-AR-GTR"},
          {"name":"GILLETT","code":"US-AR-GJZ"},
          {"name":"GILLHAM","code":"US-AR-IHM"},
          {"name":"GLENCOE","code":"US-AR-G4K"},
          {"name":"GLENWOOD","code":"US-AR-GWB"},
          {"name":"GRADY","code":"US-AR-7TP"},
          {"name":"GRAVETTE","code":"US-AR-GET"},
          {"name":"GREEN FOREST","code":"US-AR-GFG"},
          {"name":"GREENBRIER","code":"US-AR-GR8"},
          {"name":"GREENWOOD, SEBASTIAN","code":"US-AR-GNQ"},
          {"name":"GUION","code":"US-AR-GUB"},
          {"name":"GURDON","code":"US-AR-GUO"},
          {"name":"GUY","code":"US-AR-G8Y"},
          {"name":"HACKETT","code":"US-AR-KTJ"},
          {"name":"HAMBURG","code":"US-AR-ZDT"},
          {"name":"HAMPTON","code":"US-AR-HA4"},
          {"name":"HARDY","code":"US-AR-KHY"},
          {"name":"HARRISBURG","code":"US-AR-H3A"},
          {"name":"HARRISON","code":"US-AR-HRO"},
          {"name":"HARTFORD","code":"US-AR-H9F"},
          {"name":"HARTMAN","code":"US-AR-H5N"},
          {"name":"HASKELL","code":"US-AR-HKU"},
          {"name":"HATFIELD","code":"US-AR-HFE"},
          {"name":"HAVANA","code":"US-AR-HVK"},
          {"name":"HEBER SPRINGS","code":"US-AR-HBS"},
          {"name":"HELENA","code":"US-AR-HEE"},
          {"name":"HERMITAGE","code":"US-AR-267"},
          {"name":"HIGHLAND","code":"US-AR-H9H"},
          {"name":"HINDSVILLE","code":"US-AR-HKV"},
          {"name":"HOLIDAY ISLAND","code":"US-AR-H9I"},
          {"name":"HOPE","code":"US-AR-HPR"},
          {"name":"HOT SPRINGS","code":"US-AR-HOT"},
          {"name":"HOT SPRINGS NATIONAL PARK","code":"US-AR-HPP"},
          {"name":"HOXIE","code":"US-AR-HX2"},
          {"name":"HUGHES","code":"US-AR-HG4"},
          {"name":"HUMNOKE","code":"US-AR-HA3"},
          {"name":"HUTCHINSON","code":"US-AR-HUT"},
          {"name":"IDA","code":"US-AR-ID8"},
          {"name":"IMBODEN","code":"US-AR-IMH"},
          {"name":"JACKSONVILLE","code":"US-AR-LRF"},
          {"name":"JASPER","code":"US-AR-JPR"},
          {"name":"JOHNSON","code":"US-AR-JOA"},
          {"name":"JOINER","code":"US-AR-JN8"},
          {"name":"JONES MILL","code":"US-AR-ZLL"},
          {"name":"JONESBORO","code":"US-AR-JBR"},
          {"name":"JORDAN","code":"US-AR-J2O"},
          {"name":"JUDSONIA","code":"US-AR-JSA"},
          {"name":"KANSAS","code":"US-AR-2KS"},
          {"name":"KENSETT","code":"US-AR-KSZ"},
          {"name":"KNOBEL","code":"US-AR-KL2"},
          {"name":"KNOXVILLE","code":"US-AR-KXV"},
          {"name":"LAKE VILLAGE","code":"US-AR-L9V"},
          {"name":"LAVACA","code":"US-AR-AC4"},
          {"name":"LEACHVILLE","code":"US-AR-LHL"},
          {"name":"LEAD HILL","code":"US-AR-LHZ"},
          {"name":"LEOLA","code":"US-AR-L9A"},
          {"name":"LEWISVILLE","code":"US-AR-LWI"},
          {"name":"LEXA","code":"US-AR-3LX"},
          {"name":"LINCOLN","code":"US-AR-INC"},
          {"name":"LITTLE ROCK","code":"US-AR-LIT"},
          {"name":"LONDON","code":"US-AR-L9N"},
          {"name":"LONOKE","code":"US-AR-LOK"},
          {"name":"LONSDALE","code":"US-AR-9LD"},
          {"name":"LOWELL","code":"US-AR-LWY"},
          {"name":"LYNN","code":"US-AR-L2N"},
          {"name":"MABELVALE","code":"US-AR-YMB"},
          {"name":"MAGAZINE","code":"US-AR-MZ5"},
          {"name":"MAGNESS","code":"US-AR-ZG3"},
          {"name":"MAGNOLIA","code":"US-AR-AGO"},
          {"name":"MALVERN","code":"US-AR-MV6"},
          {"name":"MAMMOTH SPRING","code":"US-AR-M3S"},
          {"name":"MANILA","code":"US-AR-MXA"},
          {"name":"MANSFIELD","code":"US-AR-XMW"},
          {"name":"MARIANNA","code":"US-AR-RIA"},
          {"name":"MARION","code":"US-AR-MQM"},
          {"name":"MARKED TREE","code":"US-AR-AKT"},
          {"name":"MARMADUKE","code":"US-AR-ZRU"},
          {"name":"MARSHALL","code":"US-AR-KTM"},
          {"name":"MARVELL","code":"US-AR-AMV"},
          {"name":"MAUMELLE","code":"US-AR-MLE"},
          {"name":"MAYFLOWER","code":"US-AR-M8F"},
          {"name":"MCCRORY","code":"US-AR-MCC"},
          {"name":"MCGEHEE","code":"US-AR-GEH"},
          {"name":"MELBOURNE","code":"US-AR-MQJ"},
          {"name":"MELLWOOD","code":"US-AR-DMW"},
          {"name":"MENA","code":"US-AR-AE4"},
          {"name":"MESA","code":"US-AR-MQA"},
          {"name":"MIDWAY","code":"US-AR-IWY"},
          {"name":"MINERAL SPRINGS","code":"US-AR-M5S"},
          {"name":"MONETTE","code":"US-AR-QTE"},
          {"name":"MONTICELLO","code":"US-AR-MOQ"},
          {"name":"MORO","code":"US-AR-OYO"},
          {"name":"MORRILTON","code":"US-AR-MPJ"},
          {"name":"MOUNT HOLLY","code":"US-AR-MH6"},
          {"name":"MOUNT IDA","code":"US-AR-UIA"},
          {"name":"MOUNTAIN HOME","code":"US-AR-WMH"},
          {"name":"MOUNTAIN VIEW","code":"US-AR-VAJ"},
          {"name":"MULBERRY","code":"US-AR-EYQ"},
          {"name":"MURFREESBORO","code":"US-AR-FB3"},
          {"name":"NASHVILLE","code":"US-AR-ZNV"},
          {"name":"NEWARK","code":"US-AR-N2K"},
          {"name":"NEWPORT","code":"US-AR-NPN"},
          {"name":"NORFORK","code":"US-AR-OOJ"},
          {"name":"NORMAN","code":"US-AR-NMN"},
          {"name":"NORPHLET","code":"US-AR-NFA"},
          {"name":"NORTH LITTLE ROCK","code":"US-AR-NLW"},
          {"name":"OLA","code":"US-AR-O3L"},
          {"name":"OLD HICKORY","code":"US-AR-OHI"},
          {"name":"OMAHA","code":"US-AR-OH8"},
          {"name":"OSCEOLA","code":"US-AR-OSA"},
          {"name":"OTWELL","code":"US-AR-OVZ"},
          {"name":"OZARK","code":"US-AR-OZK"},
          {"name":"OZONE","code":"US-AR-Z6O"},
          {"name":"PALESTINE","code":"US-AR-P3S"},
          {"name":"PARAGOULD","code":"US-AR-PGR"},
          {"name":"PARIS","code":"US-AR-PS5"},
          {"name":"PARON","code":"US-AR-PXA"},
          {"name":"PATTERSON","code":"US-AR-P7A"},
          {"name":"PEA RIDGE","code":"US-AR-ZPR"},
          {"name":"PEARCY","code":"US-AR-PE5"},
          {"name":"PENNINGTON","code":"US-AR-PGT"},
          {"name":"PERKINS","code":"US-AR-PKQ"},
          {"name":"PHOENIX TOWNSHIP","code":"US-AR-PXT"},
          {"name":"PIGGOTT","code":"US-AR-PZY"},
          {"name":"PINE BLUFF","code":"US-AR-PBF"},
          {"name":"PINEVILLE","code":"US-AR-PVL"},
          {"name":"PLUMERVILLE","code":"US-AR-PUR"},
          {"name":"POCAHONTAS","code":"US-AR-PXH"},
          {"name":"PORTLAND","code":"US-AR-LDP"},
          {"name":"PRAIRIE GROVE","code":"US-AR-PEG"},
          {"name":"PRATTSVILLE","code":"US-AR-PV3"},
          {"name":"PRESCOTT","code":"US-AR-PS8"},
          {"name":"QUITMAN","code":"US-AR-QUM"},
          {"name":"RAVENDEN SPRINGS","code":"US-AR-RA4"},
          {"name":"RECTOR","code":"US-AR-3RT"},
          {"name":"RED HILL","code":"US-AR-RHX"},
          {"name":"REDFIELD","code":"US-AR-R3D"},
          {"name":"ROE","code":"US-AR-R9E"},
          {"name":"ROGERS","code":"US-AR-ROG"},
          {"name":"ROSE BUD","code":"US-AR-RB9"},
          {"name":"ROSEDALE","code":"US-AR-RDA"},
          {"name":"ROYAL","code":"US-AR-RYA"},
          {"name":"RUSSELLVILLE","code":"US-AR-RUS"},
          {"name":"SAINT CHARLES","code":"US-AR-XTJ"},
          {"name":"SAINT PAUL","code":"US-AR-PAJ"},
          {"name":"SALEM","code":"US-AR-L26"},
          {"name":"SCRANTON","code":"US-AR-RRA"},
          {"name":"SEARCY","code":"US-AR-SRC"},
          {"name":"SHERIDAN","code":"US-AR-SIW"},
          {"name":"SHERRILL","code":"US-AR-RL8"},
          {"name":"SHERWOOD","code":"US-AR-YRW"},
          {"name":"SILOAM SPRINGS","code":"US-AR-SLG"},
          {"name":"SMACKOVER","code":"US-AR-SK4"},
          {"name":"SMITHVILLE","code":"US-AR-SKH"},
          {"name":"SPRINGDALE","code":"US-AR-SPZ"},
          {"name":"STAMPS","code":"US-AR-STM"},
          {"name":"STAR CITY","code":"US-AR-5ST"},
          {"name":"STRONG","code":"US-AR-TAQ"},
          {"name":"STUTTGART","code":"US-AR-SGT"},
          {"name":"STUTTGART","code":"US-AR-VUR"},
          {"name":"SULPHUR SPRINGS","code":"US-AR-UUS"},
          {"name":"SUMMERS","code":"US-AR-S3U"},
          {"name":"SWIFTON","code":"US-AR-XSN"},
          {"name":"TEXARKANA","code":"US-AR-TXK"},
          {"name":"THATCHER","code":"US-AR-ZTH"},
          {"name":"TILLAR","code":"US-AR-ZTR"},
          {"name":"TONTITOWN","code":"US-AR-TW7"},
          {"name":"TRUMANN","code":"US-AR-TMN"},
          {"name":"TUCKER","code":"US-AR-ZSQ"},
          {"name":"TUCKERMAN","code":"US-AR-DDA"},
          {"name":"ULM","code":"US-AR-4TP"},
          {"name":"UMPIRE","code":"US-AR-UMP"},
          {"name":"VAN BUREN","code":"US-AR-VBU"},
          {"name":"VILONIA","code":"US-AR-VI3"},
          {"name":"VIOLA","code":"US-AR-VIP"},
          {"name":"WALDENBURG","code":"US-AR-WD9"},
          {"name":"WALDRON","code":"US-AR-WN6"},
          {"name":"WALNUT RIDGE","code":"US-AR-ARG"},
          {"name":"WARD","code":"US-AR-WD7"},
          {"name":"WARM SPRINGS","code":"US-AR-3AR"},
          {"name":"WARREN","code":"US-AR-WQR"},
          {"name":"WEINER","code":"US-AR-WNR"},
          {"name":"WEST FORK","code":"US-AR-WQQ"},
          {"name":"WEST HELENA","code":"US-AR-WHQ"},
          {"name":"WEST MEMPHIS","code":"US-AR-AWM"},
          {"name":"WEST RIDGE","code":"US-AR-WJG"},
          {"name":"WESTERN GROVE","code":"US-AR-VGN"},
          {"name":"WHEATLEY","code":"US-AR-WE4"},
          {"name":"WHITE HALL","code":"US-AR-IHJ"},
          {"name":"WIDENER","code":"US-AR-IDR"},
          {"name":"WILLISVILLE","code":"US-AR-VVS"},
          {"name":"WILSON","code":"US-AR-WIQ"},
          {"name":"WINTHROP","code":"US-AR-WT9"},
          {"name":"WYNNE","code":"US-AR-WYQ"},
          {"name":"YELLVILLE","code":"US-AR-YEL"},
        ]
      },
      {
        name: 'California',
        code: 'US-CA',
        cities: [
          {"name":"ACAMPO","code":"US-CA-AA4"},
          {"name":"ACTON","code":"US-CA-AXJ"},
          {"name":"ADELANTO","code":"US-CA-ADU"},
          {"name":"AGOURA","code":"US-CA-UAX"},
          {"name":"AGOURA HILLS","code":"US-CA-AGH"},
          {"name":"AGUA DULCE","code":"US-CA-A2G"},
          {"name":"AGUANGA","code":"US-CA-GKG"},
          {"name":"ALAMEDA","code":"US-CA-NGZ"},
          {"name":"ALAMO","code":"US-CA-UBB"},
          {"name":"ALBANY","code":"US-CA-ALA"},
          {"name":"ALHAMBRA","code":"US-CA-ALH"},
          {"name":"ALISO VIEJO","code":"US-CA-AVJ"},
          {"name":"ALLENSWORTH","code":"US-CA-AWF"},
          {"name":"ALPAUGH","code":"US-CA-A3P"},
          {"name":"ALPINE MEADOWS","code":"US-CA-AWU"},
          {"name":"ALPINE, LOS ANGELES","code":"US-CA-ZJI"},
          {"name":"ALPINE, SAN DIEGO","code":"US-CA-JIN"},
          {"name":"ALTA","code":"US-CA-AJL"},
          {"name":"ALTA LOMA","code":"US-CA-TAJ"},
          {"name":"ALTADENA","code":"US-CA-AZA"},
          {"name":"ALTURAS","code":"US-CA-ZIF"},
          {"name":"ALUM ROCK","code":"US-CA-AZT"},
          {"name":"ALVARADO","code":"US-CA-AZB"},
          {"name":"ALVISO","code":"US-CA-AZD"},
          {"name":"AMBOY","code":"US-CA-OPP"},
          {"name":"AMERICAN CANYON","code":"US-CA-ACY"},
          {"name":"ANAHEIM","code":"US-CA-ANA"},
          {"name":"ANDERSON","code":"US-CA-HJG"},
          {"name":"ANDRADE","code":"US-CA-ANU"},
          {"name":"ANGELES OAKS","code":"US-CA-A2O"},
          {"name":"ANGELS CAMP","code":"US-CA-4NV"},
          {"name":"ANGWIN","code":"US-CA-AWG"},
          {"name":"ANTELOPE","code":"US-CA-QTP"},
          {"name":"ANTIOCH","code":"US-CA-ANZ"},
          {"name":"ANZA","code":"US-CA-AKG"},
          {"name":"APPLE VALLEY","code":"US-CA-APV"},
          {"name":"APTOS","code":"US-CA-APO"},
          {"name":"ARBUCKLE","code":"US-CA-ARU"},
          {"name":"ARCADIA","code":"US-CA-JAR"},
          {"name":"ARCATA","code":"US-CA-ACV"},
          {"name":"ARLETA","code":"US-CA-ARR"},
          {"name":"ARNOLD","code":"US-CA-YAJ"},
          {"name":"AROMAS","code":"US-CA-AQR"},
          {"name":"ARROYO GRANDE","code":"US-CA-AG2"},
          {"name":"ARTESIA","code":"US-CA-ATM"},
          {"name":"ARTOIS","code":"US-CA-AO8"},
          {"name":"ARVIN","code":"US-CA-AXI"},
          {"name":"ASHLAND","code":"US-CA-3AL"},
          {"name":"ASTI","code":"US-CA-A8T"},
          {"name":"ATASCADERO","code":"US-CA-AFC"},
          {"name":"ATHERTON","code":"US-CA-ATC"},
          {"name":"ATLANTA","code":"US-CA-A2N"},
          {"name":"ATWATER","code":"US-CA-ATZ"},
          {"name":"ATWOOD","code":"US-CA-ATG"},
          {"name":"AUBURN","code":"US-CA-AUN"},
          {"name":"AUCKLAND","code":"US-CA-8AC"},
          {"name":"AUGUST F. HAW","code":"US-CA-TFH"},
          {"name":"AUKUM","code":"US-CA-U9A"},
          {"name":"AVALON","code":"US-CA-AOU"},
          {"name":"AVENAL","code":"US-CA-AL9"},
          {"name":"AVERY","code":"US-CA-A3V"},
          {"name":"AZUSA","code":"US-CA-AZU"},
          {"name":"BAKER","code":"US-CA-BA5"},
          {"name":"BAKERSFIELD","code":"US-CA-BFL"},
          {"name":"BALBOA","code":"US-CA-BAZ"},
          {"name":"BALDWIN PARK","code":"US-CA-BWP"},
          {"name":"BALLARD","code":"US-CA-QLL"},
          {"name":"BALLICO","code":"US-CA-B5A"},
          {"name":"BANNING","code":"US-CA-BNG"},
          {"name":"BARSTOW","code":"US-CA-BZW"},
          {"name":"BARTLETT SPRINGS","code":"US-CA-BSP"},
          {"name":"BASSETT","code":"US-CA-BAC"},
          {"name":"BAY POINT","code":"US-CA-ZA3"},
          {"name":"BEALE AIR FORCE BASE","code":"US-CA-ZA4"},
          {"name":"BEAR VALLEY","code":"US-CA-VKZ"},
          {"name":"BEAUMONT","code":"US-CA-BUO"},
          {"name":"BELAIR","code":"US-CA-BLB"},
          {"name":"BELL","code":"US-CA-BQC"},
          {"name":"BELL GARDENS","code":"US-CA-BLG"},
          {"name":"BELLA VISTA","code":"US-CA-9BV"},
          {"name":"BELLFLOWER","code":"US-CA-BFX"},
          {"name":"BELMONT","code":"US-CA-BVT"},
          {"name":"BELVEDERE","code":"US-CA-VD7"},
          {"name":"BENICIA","code":"US-CA-BNC"},
          {"name":"BERKELEY","code":"US-CA-JBK"},
          {"name":"BERRY CREEK","code":"US-CA-3BR"},
          {"name":"BETHEL ISLAND","code":"US-CA-XTD"},
          {"name":"BEVERLY HILLS","code":"US-CA-BVH"},
          {"name":"BIEBER","code":"US-CA-JSF"},
          {"name":"BIG BEAR","code":"US-CA-RBF"},
          {"name":"BIG BEAR LAKE","code":"US-CA-BBL"},
          {"name":"BIG BEND","code":"US-CA-B3B"},
          {"name":"BIG SUR","code":"US-CA-GSW"},
          {"name":"BIGGS","code":"US-CA-ISX"},
          {"name":"BIOLA","code":"US-CA-ZA6"},
          {"name":"BIRDS LANDING","code":"US-CA-BG6"},
          {"name":"BISHOP","code":"US-CA-BIH"},
          {"name":"BLAIRSDEN-GRAEAGLE","code":"US-CA-GKZ"},
          {"name":"BLOOMINGTON","code":"US-CA-BQT"},
          {"name":"BLUE CANYON","code":"US-CA-BLU"},
          {"name":"BLUE LAKE","code":"US-CA-BUL"},
          {"name":"BLYTHE","code":"US-CA-BLH"},
          {"name":"BODEGA BAY","code":"US-CA-DHB"},
          {"name":"BOLINAS","code":"US-CA-B27"},
          {"name":"BONITA","code":"US-CA-BZ9"},
          {"name":"BONSALL","code":"US-CA-BPE"},
          {"name":"BOONVILLE","code":"US-CA-OXJ"},
          {"name":"BORON","code":"US-CA-BJO"},
          {"name":"BORREGO SPRINGS","code":"US-CA-BXS"},
          {"name":"BOULDER CREEK","code":"US-CA-UCB"},
          {"name":"BOULEVARD","code":"US-CA-VBF"},
          {"name":"BOX SPRINGS","code":"US-CA-BXP"},
          {"name":"BOYLE HEIGHTS","code":"US-CA-XDK"},
          {"name":"BRADBURY","code":"US-CA-BUA"},
          {"name":"BRADLEY","code":"US-CA-R3L"},
          {"name":"BRAWLEY","code":"US-CA-BWC"},
          {"name":"BREA","code":"US-CA-BQE"},
          {"name":"BRENTWOOD","code":"US-CA-BXF"},
          {"name":"BRIDGEPORT","code":"US-CA-IEP"},
          {"name":"BRISBANE","code":"US-CA-BSA"},
          {"name":"BROOKDALE","code":"US-CA-4BD"},
          {"name":"BROOKS","code":"US-CA-3BK"},
          {"name":"BUELLTON","code":"US-CA-BLT"},
          {"name":"BUENA PARK","code":"US-CA-BUN"},
          {"name":"BURBANK","code":"US-CA-BUR"},
          {"name":"BURLINGAME","code":"US-CA-BGI"},
          {"name":"BURNEY","code":"US-CA-BYN"},
          {"name":"BUTLER","code":"US-CA-6BL"},
          {"name":"BUTTE MEADOWS","code":"US-CA-BU2"},
          {"name":"BUTTONWILLOW","code":"US-CA-BIM"},
          {"name":"BYRON","code":"US-CA-BYR"},
          {"name":"CABAZON","code":"US-CA-CJB"},
          {"name":"CADIZ","code":"US-CA-JCZ"},
          {"name":"CALABASAS","code":"US-CA-LBJ"},
          {"name":"CALEXICO","code":"US-CA-CXL"},
          {"name":"CALIFORNIA CITY","code":"US-CA-ZTZ"},
          {"name":"CALIMESA","code":"US-CA-CAA"},
          {"name":"CALIPATRIA","code":"US-CA-CLR"},
          {"name":"CALISTOGA","code":"US-CA-CAG"},
          {"name":"CALPELLA","code":"US-CA-L3E"},
          {"name":"CALWA","code":"US-CA-XAW"},
          {"name":"CAMARILLO","code":"US-CA-CMR"},
          {"name":"CAMERON PARK","code":"US-CA-MHO"},
          {"name":"CAMINO","code":"US-CA-CMN"},
          {"name":"CAMP PENDLETON","code":"US-CA-PPD"},
          {"name":"CAMPBELL","code":"US-CA-CPB"},
          {"name":"CAMPO","code":"US-CA-CZZ"},
          {"name":"CAMPTONVILLE","code":"US-CA-OVF"},
          {"name":"CANBY","code":"US-CA-XC3"},
          {"name":"CANOGA PARK","code":"US-CA-CPA"},
          {"name":"CANTIL","code":"US-CA-U5C"},
          {"name":"CANTUA CREEK","code":"US-CA-QUK"},
          {"name":"CANYON","code":"US-CA-CJU"},
          {"name":"CANYON","code":"US-CA-NYO"},
          {"name":"CANYON COUNTRY","code":"US-CA-AYC"},
          {"name":"CANYON LAKE","code":"US-CA-C5L"},
          {"name":"CAPAY","code":"US-CA-A5Y"},
          {"name":"CAPISTRANO BEACH","code":"US-CA-CPZ"},
          {"name":"CAPITAN","code":"US-CA-CAP"},
          {"name":"CAPITOLA","code":"US-CA-CGP"},
          {"name":"CARDIFF BY THE SEA","code":"US-CA-ZBZ"},
          {"name":"CARIBOU","code":"US-CA-CI5"},
          {"name":"CARLSBAD","code":"US-CA-CLD"},
          {"name":"CARMEL","code":"US-CA-ZCA"},
          {"name":"CARMEL VALLEY","code":"US-CA-CMV"},
          {"name":"CARMEL-MONTEREY APT","code":"US-CA-MRY"},
          {"name":"CARMICHAEL","code":"US-CA-CM3"},
          {"name":"CARNELIAN BAY","code":"US-CA-NAF"},
          {"name":"CARPINTERIA","code":"US-CA-CPT"},
          {"name":"CARSON","code":"US-CA-CRU"},
          {"name":"CARUTHERS","code":"US-CA-UHQ"},
          {"name":"CASTAIC","code":"US-CA-QIC"},
          {"name":"CASTRO VALLEY","code":"US-CA-CVA"},
          {"name":"CASTROVILLE","code":"US-CA-CTV"},
          {"name":"CATALINA ISLAND","code":"US-CA-CKI"},
          {"name":"CATHEDRAL CITY","code":"US-CA-YCD"},
          {"name":"CATHEYS VALLEY","code":"US-CA-8TX"},
          {"name":"CAYUCOS","code":"US-CA-YU5"},
          {"name":"CAZADERO","code":"US-CA-CZ3"},
          {"name":"CEDAR GLEN","code":"US-CA-DGC"},
          {"name":"CEDAR STOCK","code":"US-CA-CJ3"},
          {"name":"CEDARPINES PARK","code":"US-CA-T4Z"},
          {"name":"CEDARVILLE","code":"US-CA-VKJ"},
          {"name":"CENTERVILLE","code":"US-CA-CEE"},
          {"name":"CENTURY CITY","code":"US-CA-YCI"},
          {"name":"CERES","code":"US-CA-CRZ"},
          {"name":"CERRITOS","code":"US-CA-CEK"},
          {"name":"CHALLENGE","code":"US-CA-GXM"},
          {"name":"CHARTER OAK","code":"US-CA-CQO"},
          {"name":"CHATSWORTH","code":"US-CA-CWH"},
          {"name":"CHERRY VALLEY","code":"US-CA-CHV"},
          {"name":"CHESTER","code":"US-CA-XSE"},
          {"name":"CHESTNUT","code":"US-CA-CUT"},
          {"name":"CHICO","code":"US-CA-CIC"},
          {"name":"CHINA LAKE","code":"US-CA-C3K"},
          {"name":"CHINESE CAMP","code":"US-CA-ZB6"},
          {"name":"CHINO","code":"US-CA-CNO"},
          {"name":"CHINO HILLS","code":"US-CA-CH3"},
          {"name":"CHOWCHILLA","code":"US-CA-CWC"},
          {"name":"CHUALAR","code":"US-CA-CL3"},
          {"name":"CHULA VISTA","code":"US-CA-CUV"},
          {"name":"CITRUS HEIGHTS","code":"US-CA-CTU"},
          {"name":"CITY OF COMMERCE","code":"US-CA-CC5"},
          {"name":"CITY OF INDUSTRY","code":"US-CA-JID"},
          {"name":"CLAREMONT","code":"US-CA-CMQ"},
          {"name":"CLARKSBURG","code":"US-CA-5CB"},
          {"name":"CLAYTON","code":"US-CA-CJX"},
          {"name":"CLEARLAKE","code":"US-CA-EAK"},
          {"name":"CLEARLAKE OAKS","code":"US-CA-RXO"},
          {"name":"CLEMENTS","code":"US-CA-CLZ"},
          {"name":"CLOVERDALE","code":"US-CA-CYI"},
          {"name":"CLOVIS","code":"US-CA-CVS"},
          {"name":"COACHELLA","code":"US-CA-COC"},
          {"name":"COALINGA","code":"US-CA-CLG"},
          {"name":"COARSEGOLD","code":"US-CA-CG7"},
          {"name":"COBB","code":"US-CA-C5B"},
          {"name":"COLEVILLE","code":"US-CA-CE8"},
          {"name":"COLFAX","code":"US-CA-OLX"},
          {"name":"COLLEGE CITY","code":"US-CA-GQC"},
          {"name":"COLLEGEVILLE","code":"US-CA-L55"},
          {"name":"COLMA","code":"US-CA-CQA"},
          {"name":"COLOMA","code":"US-CA-QLZ"},
          {"name":"COLTON","code":"US-CA-RTO"},
          {"name":"COLUMBIA","code":"US-CA-COA"},
          {"name":"COLUSA","code":"US-CA-CQC"},
          {"name":"COMMERCE/LOS ANGELES","code":"US-CA-CRC"},
          {"name":"COMPTON","code":"US-CA-CPM"},
          {"name":"CONCORD","code":"US-CA-CCR"},
          {"name":"COOL","code":"US-CA-CO4"},
          {"name":"COPPEROPOLIS","code":"US-CA-6OP"},
          {"name":"CORCORAN","code":"US-CA-CRO"},
          {"name":"CORDELIA","code":"US-CA-LJA"},
          {"name":"CORNING","code":"US-CA-CJG"},
          {"name":"CORONA","code":"US-CA-CZE"},
          {"name":"CORONA DEL MAR","code":"US-CA-CM2"},
          {"name":"CORONADO","code":"US-CA-CD4"},
          {"name":"CORRALITOS","code":"US-CA-TO3"},
          {"name":"CORTE MADERA","code":"US-CA-CZL"},
          {"name":"COSTA MESA","code":"US-CA-CZM"},
          {"name":"COTATI","code":"US-CA-IAO"},
          {"name":"COTO DE CAZA","code":"US-CA-OCZ"},
          {"name":"COTTONWOOD","code":"US-CA-CZV"},
          {"name":"COULTERVILLE","code":"US-CA-KVJ"},
          {"name":"COURTLAND","code":"US-CA-URD"},
          {"name":"COVINA","code":"US-CA-CVQ"},
          {"name":"CRESCENT CITY","code":"US-CA-CEC"},
          {"name":"CRESTLINE","code":"US-CA-GKM"},
          {"name":"CRESTON","code":"US-CA-C8T"},
          {"name":"CROCKETT","code":"US-CA-CRM"},
          {"name":"CROMBERG","code":"US-CA-C8G"},
          {"name":"CROWS LANDING","code":"US-CA-NRC"},
          {"name":"CUDAHY","code":"US-CA-CUY"},
          {"name":"CULVER CITY","code":"US-CA-CVR"},
          {"name":"CUPERTINO","code":"US-CA-CUO"},
          {"name":"CUTLER","code":"US-CA-CUG"},
          {"name":"CYPRESS","code":"US-CA-CYQ"},
          {"name":"DAGGETT","code":"US-CA-DAG"},
          {"name":"DALY CITY","code":"US-CA-DXL"},
          {"name":"DANA POINT","code":"US-CA-DPT"},
          {"name":"DANVILLE","code":"US-CA-SXC"},
          {"name":"DAVENPORT","code":"US-CA-DAV"},
          {"name":"DAVIS","code":"US-CA-DAS"},
          {"name":"DEATH VALLEY","code":"US-CA-DTH"},
          {"name":"DEER PARK","code":"US-CA-DP5"},
          {"name":"DEL MAR","code":"US-CA-DMA"},
          {"name":"DEL MAR, SAN DIEGO","code":"US-CA-DM2"},
          {"name":"DEL REY","code":"US-CA-DLY"},
          {"name":"DEL REY OAKS","code":"US-CA-EKS"},
          {"name":"DELANO","code":"US-CA-DNO"},
          {"name":"DENAIR","code":"US-CA-YDI"},
          {"name":"DESCANSO","code":"US-CA-DJC"},
          {"name":"DESERT CENTER","code":"US-CA-DTJ"},
          {"name":"DESERT HOT SPRINGS","code":"US-CA-DHS"},
          {"name":"DI GIORGIO","code":"US-CA-IOG"},
          {"name":"DIABLO","code":"US-CA-DB7"},
          {"name":"DIAMOND BAR","code":"US-CA-DBR"},
          {"name":"DIAMOND SPRINGS","code":"US-CA-DXG"},
          {"name":"DINUBA","code":"US-CA-DIU"},
          {"name":"DISCOVERY BAY","code":"US-CA-DYY"},
          {"name":"DIXON","code":"US-CA-DXC"},
          {"name":"DOBBINS","code":"US-CA-DB4"},
          {"name":"DOMINGUEZ","code":"US-CA-DMZ"},
          {"name":"DOMINGUEZ HILLS","code":"US-CA-DIZ"},
          {"name":"DOS PALOS","code":"US-CA-D2S"},
          {"name":"DOWNEY","code":"US-CA-JDY"},
          {"name":"DOWNIEVILLE","code":"US-CA-DYH"},
          {"name":"DOYLE","code":"US-CA-DOY"},
          {"name":"DRYTOWN","code":"US-CA-DR3"},
          {"name":"DUARTE","code":"US-CA-DUB"},
          {"name":"DUBLIN","code":"US-CA-DQB"},
          {"name":"DUCOR","code":"US-CA-DCO"},
          {"name":"DULZURA","code":"US-CA-DZU"},
          {"name":"DUNCANS MILLS","code":"US-CA-DMX"},
          {"name":"DUNNIGAN","code":"US-CA-DIG"},
          {"name":"DURHAM","code":"US-CA-DH8"},
          {"name":"EAGLE ROCK","code":"US-CA-EGR"},
          {"name":"EARLIMART","code":"US-CA-EYM"},
          {"name":"EAST IRVINE","code":"US-CA-EAI"},
          {"name":"EAST LOS ANGELES","code":"US-CA-ETA"},
          {"name":"EAST PALO ALTO","code":"US-CA-EPD"},
          {"name":"EAST RANCHO DOMINGUEZ","code":"US-CA-E2R"},
          {"name":"EAST STOCKTON","code":"US-CA-KCO"},
          {"name":"EASTVALE","code":"US-CA-EV5"},
          {"name":"EDISON","code":"US-CA-EDS"},
          {"name":"EDWARDS","code":"US-CA-EWA"},
          {"name":"EL CAJON","code":"US-CA-CJN"},
          {"name":"EL CENTRO","code":"US-CA-ECO"},
          {"name":"EL CERRITO","code":"US-CA-ECQ"},
          {"name":"EL DORADO","code":"US-CA-EDJ"},
          {"name":"EL DORADO HILLS","code":"US-CA-EHI"},
          {"name":"EL MODENA/ORANGE","code":"US-CA-EMD"},
          {"name":"EL MONTE","code":"US-CA-EMT"},
          {"name":"EL NIDO","code":"US-CA-JDO"},
          {"name":"EL PORTAL","code":"US-CA-EPQ"},
          {"name":"EL SEGUNDO","code":"US-CA-ELS"},
          {"name":"EL SERENO","code":"US-CA-EZR"},
          {"name":"EL SOBRANTE","code":"US-CA-KTK"},
          {"name":"EL TORO","code":"US-CA-ETR"},
          {"name":"ELDRIDGE","code":"US-CA-EBP"},
          {"name":"ELFIN FOREST","code":"US-CA-EJF"},
          {"name":"ELK","code":"US-CA-KKN"},
          {"name":"ELK CREEK","code":"US-CA-EYJ"},
          {"name":"ELK GROVE","code":"US-CA-EGK"},
          {"name":"ELLWOOD","code":"US-CA-EL3"},
          {"name":"ELVERTA","code":"US-CA-E2V"},
          {"name":"ELWOOD","code":"US-CA-EQW"},
          {"name":"EMBARCADERO/SAN FRANCISCO","code":"US-CA-EMB"},
          {"name":"EMERYVILLE","code":"US-CA-JEM"},
          {"name":"EMIGRANT GAP","code":"US-CA-EM4"},
          {"name":"ENCINITAS","code":"US-CA-XFE"},
          {"name":"ENCINO","code":"US-CA-ENO"},
          {"name":"ESCALON","code":"US-CA-EXC"},
          {"name":"ESCONDIDO","code":"US-CA-ECD"},
          {"name":"ESPARTO","code":"US-CA-EZO"},
          {"name":"ESPERANZA","code":"US-CA-GZA"},
          {"name":"ESSEX","code":"US-CA-EVB"},
          {"name":"ESTERO BAY","code":"US-CA-ESB"},
          {"name":"ETIWANDA","code":"US-CA-EFS"},
          {"name":"ETNA","code":"US-CA-E2T"},
          {"name":"EUREKA","code":"US-CA-EKA"},
          {"name":"EXETER","code":"US-CA-EXT"},
          {"name":"FAIR OAKS","code":"US-CA-FOS"},
          {"name":"FAIR PLAY","code":"US-CA-FPL"},
          {"name":"FAIRFAX","code":"US-CA-AIF"},
          {"name":"FAIRFIELD","code":"US-CA-SUU"},
          {"name":"FAIRMONT","code":"US-CA-ON7"},
          {"name":"FALL RIVER MILLS","code":"US-CA-FPM"},
          {"name":"FALLBROOK","code":"US-CA-FOR"},
          {"name":"FALLON","code":"US-CA-FXN"},
          {"name":"FARMERSVILLE","code":"US-CA-FC3"},
          {"name":"FARMINGTON","code":"US-CA-ZYQ"},
          {"name":"FAWNSKIN","code":"US-CA-F2S"},
          {"name":"FELLOWS","code":"US-CA-FW5"},
          {"name":"FELTON","code":"US-CA-FXT"},
          {"name":"FERNDALE","code":"US-CA-QFE"},
          {"name":"FIDDLETOWN","code":"US-CA-FI3"},
          {"name":"FIELDS LANDING","code":"US-CA-F5L"},
          {"name":"FILLMORE","code":"US-CA-FIM"},
          {"name":"FIREBAUGH","code":"US-CA-FIR"},
          {"name":"FISH CAMP","code":"US-CA-IPF"},
          {"name":"FIVE POINTS","code":"US-CA-FIP"},
          {"name":"FLORENCE","code":"US-CA-FCL"},
          {"name":"FOLSOM","code":"US-CA-FOU"},
          {"name":"FONTANA","code":"US-CA-XFR"},
          {"name":"FOOTHILL FARMS","code":"US-CA-FHH"},
          {"name":"FOOTHILL RANCH","code":"US-CA-FOH"},
          {"name":"FOREST KNOLLS","code":"US-CA-F5K"},
          {"name":"FOREST RANCH","code":"US-CA-RA3"},
          {"name":"FORESTHILL","code":"US-CA-C27"},
          {"name":"FORESTVILLE","code":"US-CA-FO9"},
          {"name":"FORT BRAGG","code":"US-CA-FOB"},
          {"name":"FORT HUNTER LIGGETT","code":"US-CA-FH4"},
          {"name":"FORT IRWIN","code":"US-CA-BYS"},
          {"name":"FORT JONES, SISKIYOU","code":"US-CA-FJ2"},
          {"name":"FORTUNA","code":"US-CA-FTQ"},
          {"name":"FOSTER CITY","code":"US-CA-FCI"},
          {"name":"FOUNTAIN VALLEY","code":"US-CA-FVC"},
          {"name":"FOWLER","code":"US-CA-FOW"},
          {"name":"FRAZIER PARK","code":"US-CA-FZP"},
          {"name":"FREEDOM","code":"US-CA-FJM"},
          {"name":"FREMONT","code":"US-CA-FNC"},
          {"name":"FRENCH CAMP","code":"US-CA-FCP"},
          {"name":"FRESNO","code":"US-CA-FAT"},
          {"name":"FRIANT","code":"US-CA-FRJ"},
          {"name":"FULLERTON","code":"US-CA-FUL"},
          {"name":"FULTON","code":"US-CA-VUL"},
          {"name":"GALT","code":"US-CA-GAQ"},
          {"name":"GARBERVILLE","code":"US-CA-GBV"},
          {"name":"GARDEN GROVE","code":"US-CA-GGR"},
          {"name":"GARDEN VALLEY","code":"US-CA-G44"},
          {"name":"GARDENA","code":"US-CA-GAC"},
          {"name":"GASQUET","code":"US-CA-GXK"},
          {"name":"GEORGETOWN","code":"US-CA-ZDJ"},
          {"name":"GEYSERVILLE","code":"US-CA-GSL"},
          {"name":"GILROY","code":"US-CA-GIR"},
          {"name":"GLEN ELLEN","code":"US-CA-JQL"},
          {"name":"GLENDALE","code":"US-CA-JGX"},
          {"name":"GLENDORA","code":"US-CA-GDQ"},
          {"name":"GLENN","code":"US-CA-GL9"},
          {"name":"GOLD RIVER","code":"US-CA-GXR"},
          {"name":"GOLD RUN","code":"US-CA-GKP"},
          {"name":"GOLETA","code":"US-CA-GO2"},
          {"name":"GONZALES","code":"US-CA-GZS"},
          {"name":"GORMAN","code":"US-CA-GMJ"},
          {"name":"GOSHEN","code":"US-CA-GHS"},
          {"name":"GRANADA HILLS","code":"US-CA-GHL"},
          {"name":"GRAND TERRACE","code":"US-CA-GTC"},
          {"name":"GRANITE BAY","code":"US-CA-GTB"},
          {"name":"GRAPEVINE","code":"US-CA-GPI"},
          {"name":"GRASS VALLEY","code":"US-CA-GYC"},
          {"name":"GRATON","code":"US-CA-GR2"},
          {"name":"GREEN BRAE","code":"US-CA-GNX"},
          {"name":"GREENBRAE","code":"US-CA-GBJ"},
          {"name":"GREENFIELD","code":"US-CA-GFC"},
          {"name":"GREENVILLE","code":"US-CA-GHV"},
          {"name":"GREENWOOD, EL DORADO","code":"US-CA-GMW"},
          {"name":"GRENADA","code":"US-CA-D9D"},
          {"name":"GRIDLEY","code":"US-CA-GY3"},
          {"name":"GRIFFITH PARK","code":"US-CA-G5P"},
          {"name":"GRIMES","code":"US-CA-QR5"},
          {"name":"GROVER BEACH","code":"US-CA-GVB"},
          {"name":"GUADALUPE","code":"US-CA-GU9"},
          {"name":"GUERNEVILLE","code":"US-CA-GUJ"},
          {"name":"GUSTINE","code":"US-CA-GUT"},
          {"name":"HACIENDA HEIGHTS","code":"US-CA-HHE"},
          {"name":"HALF MOON BAY","code":"US-CA-HAF"},
          {"name":"HAMILTON CITY","code":"US-CA-HAC"},
          {"name":"HANFORD","code":"US-CA-HNF"},
          {"name":"HAPPY CAMP","code":"US-CA-IHC"},
          {"name":"HARBOR CITY","code":"US-CA-HRB"},
          {"name":"HAWAIIAN GARDENS","code":"US-CA-HGN"},
          {"name":"HAWTHORNE","code":"US-CA-HHR"},
          {"name":"HAYFORK","code":"US-CA-H3Y"},
          {"name":"HAYWARD","code":"US-CA-HWD"},
          {"name":"HEALDSBURG","code":"US-CA-HLA"},
          {"name":"HEBER","code":"US-CA-ZD3"},
          {"name":"HELENDALE","code":"US-CA-XHD"},
          {"name":"HELM","code":"US-CA-HEU"},
          {"name":"HEMET","code":"US-CA-HMT"},
          {"name":"HERCULES","code":"US-CA-HXU"},
          {"name":"HERLONG","code":"US-CA-AHC"},
          {"name":"HERMOSA BEACH","code":"US-CA-HEC"},
          {"name":"HESPERIA","code":"US-CA-HQP"},
          {"name":"HICKMAN","code":"US-CA-HC7"},
          {"name":"HIDDEN HILLS","code":"US-CA-IL4"},
          {"name":"HIGHGROVE","code":"US-CA-HQF"},
          {"name":"HIGHLAND","code":"US-CA-HIQ"},
          {"name":"HIGHLAND PARK","code":"US-CA-HGH"},
          {"name":"HIGHWAY HIGHLANDS","code":"US-CA-HQH"},
          {"name":"HILLSBOROUGH","code":"US-CA-HQB"},
          {"name":"HILMAR","code":"US-CA-ZHM"},
          {"name":"HINKLEY","code":"US-CA-HLH"},
          {"name":"HOLLISTER","code":"US-CA-HLI"},
          {"name":"HOLLYWOOD","code":"US-CA-HLW"},
          {"name":"HOLT","code":"US-CA-OT8"},
          {"name":"HOLTVILLE","code":"US-CA-H2O"},
          {"name":"HOMELAND","code":"US-CA-HHP"},
          {"name":"HOMEWOOD","code":"US-CA-HFJ"},
          {"name":"HOOD","code":"US-CA-HJQ"},
          {"name":"HOOPA","code":"US-CA-HPU"},
          {"name":"HOPLAND","code":"US-CA-YHD"},
          {"name":"HORNBROOK","code":"US-CA-HPM"},
          {"name":"HUGHSON","code":"US-CA-HSZ"},
          {"name":"HUNTINGTON BEACH","code":"US-CA-HTD"},
          {"name":"HUNTINGTON PARK","code":"US-CA-HNG"},
          {"name":"HURON","code":"US-CA-HQR"},
          {"name":"HYDE PARK","code":"US-CA-HPA"},
          {"name":"HYDESVILLE","code":"US-CA-YDH"},
          {"name":"IDYLLWILD","code":"US-CA-IDB"},
          {"name":"IGO","code":"US-CA-IHX"},
          {"name":"IMPERIAL","code":"US-CA-IDO"},
          {"name":"IMPERIAL BEACH","code":"US-CA-NRS"},
          {"name":"INDIAN WELLS","code":"US-CA-IDZ"},
          {"name":"INDIANOLA","code":"US-CA-ID4"},
          {"name":"INDIO","code":"US-CA-IOC"},
          {"name":"INDUSTRY","code":"US-CA-IUY"},
          {"name":"INGLEWOOD","code":"US-CA-ING"},
          {"name":"INVERNESS","code":"US-CA-QIS"},
          {"name":"INYOKERN","code":"US-CA-IYK"},
          {"name":"IONE","code":"US-CA-ION"},
          {"name":"IRVINE","code":"US-CA-IVN"},
          {"name":"IRVINGTON","code":"US-CA-IRG"},
          {"name":"IRWINDALE","code":"US-CA-IRD"},
          {"name":"ISLETON","code":"US-CA-IL8"},
          {"name":"IVANHOE","code":"US-CA-IVO"},
          {"name":"JACKSON","code":"US-CA-JS2"},
          {"name":"JAMESTOWN","code":"US-CA-JMW"},
          {"name":"JAMUL","code":"US-CA-JAL"},
          {"name":"JAYHAWK","code":"US-CA-JYK"},
          {"name":"JENNER","code":"US-CA-JN4"},
          {"name":"JOLON","code":"US-CA-HGT"},
          {"name":"JOSHUA TREE","code":"US-CA-JTJ"},
          {"name":"JULIAN","code":"US-CA-UL8"},
          {"name":"JUNCTION CITY","code":"US-CA-JUT"},
          {"name":"JURUPA VALLEY","code":"US-CA-JYV"},
          {"name":"KAISER","code":"US-CA-KAI"},
          {"name":"KELSEYVILLE","code":"US-CA-KV3"},
          {"name":"KENSINGTON","code":"US-CA-C3A"},
          {"name":"KENTFIELD","code":"US-CA-KF4"},
          {"name":"KENWOOD","code":"US-CA-KE7"},
          {"name":"KERMAN","code":"US-CA-KMQ"},
          {"name":"KERNVILLE","code":"US-CA-KV7"},
          {"name":"KETTLEMAN CITY","code":"US-CA-KTL"},
          {"name":"KEYES","code":"US-CA-YEJ"},
          {"name":"KING CITY","code":"US-CA-KIC"},
          {"name":"KINGS BEACH","code":"US-CA-KBJ"},
          {"name":"KINGSBURG","code":"US-CA-KBG"},
          {"name":"KIRKWOOD","code":"US-CA-KRQ"},
          {"name":"KNEELAND","code":"US-CA-KLX"},
          {"name":"KNIGHTS LANDING","code":"US-CA-KL7"},
          {"name":"LA CAÑADA-FLINTRIDGE","code":"US-CA-LCA"},
          {"name":"LA CRESCENTA","code":"US-CA-LCR"},
          {"name":"LA GRANGE","code":"US-CA-LG9"},
          {"name":"LA HABRA HEIGHTS","code":"US-CA-C7D"},
          {"name":"LA HABRA, ORANGE","code":"US-CA-LHA"},
          {"name":"LA HONDA","code":"US-CA-55C"},
          {"name":"LA JOLLA","code":"US-CA-LJL"},
          {"name":"LA MESA","code":"US-CA-LAE"},
          {"name":"LA MIRADA","code":"US-CA-LMI"},
          {"name":"LA PALMA","code":"US-CA-LPA"},
          {"name":"LA PORTE","code":"US-CA-LPE"},
          {"name":"LA PUENTE","code":"US-CA-LVR"},
          {"name":"LA QUINTA","code":"US-CA-LQ8"},
          {"name":"LA SELVA BEACH","code":"US-CA-KLH"},
          {"name":"LA VERNE","code":"US-CA-POC"},
          {"name":"LADERA RANCH","code":"US-CA-XXL"},
          {"name":"LAFAYETTE","code":"US-CA-LZX"},
          {"name":"LAGUNA BEACH","code":"US-CA-LAB"},
          {"name":"LAGUNA HILLS","code":"US-CA-LHI"},
          {"name":"LAGUNA NIGUEL","code":"US-CA-LMG"},
          {"name":"LAGUNA WOODS","code":"US-CA-LSW"},
          {"name":"LAGUNITAS","code":"US-CA-L5A"},
          {"name":"LAKE ALMANOR","code":"US-CA-LKU"},
          {"name":"LAKE ARROWHEAD","code":"US-CA-LA2"},
          {"name":"LAKE ELSINORE","code":"US-CA-LE3"},
          {"name":"LAKE FOREST","code":"US-CA-LFC"},
          {"name":"LAKE ISABELLA","code":"US-CA-IBD"},
          {"name":"LAKE VIEW TERRACE","code":"US-CA-VWJ"},
          {"name":"LAKEHEAD","code":"US-CA-LB5"},
          {"name":"LAKEPORT","code":"US-CA-LPR"},
          {"name":"LAKESHORE","code":"US-CA-LS4"},
          {"name":"LAKESIDE","code":"US-CA-KSI"},
          {"name":"LAKEVIEW","code":"US-CA-LQW"},
          {"name":"LAKEWOOD","code":"US-CA-LQQ"},
          {"name":"LAMONT","code":"US-CA-LQM"},
          {"name":"LANCASTER","code":"US-CA-WJF"},
          {"name":"LARKSPUR","code":"US-CA-KSU"},
          {"name":"LAS FLORES","code":"US-CA-LF9"},
          {"name":"LATHROP","code":"US-CA-LRO"},
          {"name":"LATON","code":"US-CA-LJO"},
          {"name":"LATROBE","code":"US-CA-TRB"},
          {"name":"LAWNDALE","code":"US-CA-LWJ"},
          {"name":"LAYTONVILLE","code":"US-CA-AY5"},
          {"name":"LE GRAND","code":"US-CA-LZQ"},
          {"name":"LEBEC","code":"US-CA-YLB"},
          {"name":"LEMON GROVE","code":"US-CA-LG3"},
          {"name":"LEMOORE","code":"US-CA-NLC"},
          {"name":"LENNOX","code":"US-CA-LOX"},
          {"name":"LEUCADIA","code":"US-CA-EU3"},
          {"name":"LEWISTON","code":"US-CA-XWS"},
          {"name":"LINCOLN","code":"US-CA-LLN"},
          {"name":"LINDEN","code":"US-CA-LYD"},
          {"name":"LINDSAY","code":"US-CA-LSY"},
          {"name":"LITTLE LAKE","code":"US-CA-LKF"},
          {"name":"LITTLE RIVER","code":"US-CA-RWJ"},
          {"name":"LITTLEROCK","code":"US-CA-YLR"},
          {"name":"LIVE OAK","code":"US-CA-LO2"},
          {"name":"LIVERMORE","code":"US-CA-LVK"},
          {"name":"LIVINGSTON","code":"US-CA-LGS"},
          {"name":"LLANO","code":"US-CA-LXQ"},
          {"name":"LOCKEFORD","code":"US-CA-LF8"},
          {"name":"LOCKWOOD","code":"US-CA-LW9"},
          {"name":"LODI","code":"US-CA-LDI"},
          {"name":"LOLETA","code":"US-CA-TZA"},
          {"name":"LOMA LINDA","code":"US-CA-LL3"},
          {"name":"LOMITA","code":"US-CA-LOA"},
          {"name":"LOMPOC","code":"US-CA-LPC"},
          {"name":"LONE PINE","code":"US-CA-PWW"},
          {"name":"LONG BARN","code":"US-CA-L7G"},
          {"name":"LONG BEACH","code":"US-CA-LGB"},
          {"name":"LONGVIEW","code":"US-CA-UFV"},
          {"name":"LOOMIS","code":"US-CA-OXZ"},
          {"name":"LOS ALAMITOS","code":"US-CA-LAT"},
          {"name":"LOS ALAMOS","code":"US-CA-XYL"},
          {"name":"LOS ALTOS","code":"US-CA-LLT"},
          {"name":"LOS ALTOS HILLS","code":"US-CA-LAH"},
          {"name":"LOS ANGELES","code":"US-CA-LAX"},
          {"name":"LOS BANOS","code":"US-CA-LSN"},
          {"name":"LOS GATOS","code":"US-CA-LXX"},
          {"name":"LOS MOLINOS","code":"US-CA-IOS"},
          {"name":"LOS NIETOS","code":"US-CA-LNE"},
          {"name":"LOS OLIVOS","code":"US-CA-OVS"},
          {"name":"LOS OSOS","code":"US-CA-OS9"},
          {"name":"LOST HILLS","code":"US-CA-TSO"},
          {"name":"LOTUS","code":"US-CA-LU3"},
          {"name":"LOWER LAKE","code":"US-CA-9LL"},
          {"name":"LOYALTON","code":"US-CA-TJL"},
          {"name":"LUCERNE VALLEY","code":"US-CA-LCB"},
          {"name":"LYNWOOD","code":"US-CA-LYJ"},
          {"name":"LYOTH","code":"US-CA-LOH"},
          {"name":"MACDOEL","code":"US-CA-MKD"},
          {"name":"MAD RIVER","code":"US-CA-RXU"},
          {"name":"MADERA","code":"US-CA-MAE"},
          {"name":"MAGALIA","code":"US-CA-MA6"},
          {"name":"MAGNOLIA PARK","code":"US-CA-MLP"},
          {"name":"MALIBU","code":"US-CA-MAL"},
          {"name":"MAMMOTH","code":"US-CA-ZMH"},
          {"name":"MAMMOTH LAKES","code":"US-CA-MMH"},
          {"name":"MANCHESTER","code":"US-CA-M3T"},
          {"name":"MANHATTAN BEACH","code":"US-CA-MBQ"},
          {"name":"MANTECA","code":"US-CA-MTA"},
          {"name":"MAR VISTA","code":"US-CA-MVA"},
          {"name":"MARCH AIR RESERVE BASE","code":"US-CA-RSY"},
          {"name":"MARE ISLAND","code":"US-CA-MI8"},
          {"name":"MARICOPA","code":"US-CA-AIC"},
          {"name":"MARIN CITY","code":"US-CA-DXM"},
          {"name":"MARINA","code":"US-CA-MAK"},
          {"name":"MARINA DEL REY","code":"US-CA-MDX"},
          {"name":"MARIPOSA","code":"US-CA-MP8"},
          {"name":"MARKLEEVILLE","code":"US-CA-VFH"},
          {"name":"MARSHALL","code":"US-CA-QMH"},
          {"name":"MARTINEZ","code":"US-CA-MRZ"},
          {"name":"MARYSVILLE","code":"US-CA-MYV"},
          {"name":"MATHER","code":"US-CA-THC"},
          {"name":"MAXWELL","code":"US-CA-AXW"},
          {"name":"MAYWOOD","code":"US-CA-MYA"},
          {"name":"MCCLELLAN","code":"US-CA-NKF"},
          {"name":"MCCLELLAN AIR FORCE BASE","code":"US-CA-MC2"},
          {"name":"MCCLELLAN PLACE","code":"US-CA-MP6"},
          {"name":"MCCLOUD","code":"US-CA-OJC"},
          {"name":"MCFARLAND","code":"US-CA-MCF"},
          {"name":"MCKINLEYVILLE","code":"US-CA-KNY"},
          {"name":"MCKITTRICK","code":"US-CA-MK4"},
          {"name":"MECCA","code":"US-CA-MEX"},
          {"name":"MENDOCINO","code":"US-CA-MND"},
          {"name":"MENDOTA","code":"US-CA-ZMC"},
          {"name":"MENIFEE","code":"US-CA-EFE"},
          {"name":"MENLO PARK","code":"US-CA-MKU"},
          {"name":"MENTONE","code":"US-CA-MZQ"},
          {"name":"MERCED","code":"US-CA-MCE"},
          {"name":"MERIDIAN","code":"US-CA-MZA"},
          {"name":"METTLER","code":"US-CA-M8T"},
          {"name":"MIDDLETOWN","code":"US-CA-DLW"},
          {"name":"MIDPINES","code":"US-CA-M9P"},
          {"name":"MIDWAY","code":"US-CA-M8W"},
          {"name":"MIDWAY CITY","code":"US-CA-MWC"},
          {"name":"MILL VALLEY","code":"US-CA-VYV"},
          {"name":"MILLBRAE","code":"US-CA-MBA"},
          {"name":"MILPITAS","code":"US-CA-MPT"},
          {"name":"MINERAL","code":"US-CA-C87"},
          {"name":"MIRA LOMA","code":"US-CA-MIK"},
          {"name":"MIRA MESA","code":"US-CA-MLM"},
          {"name":"MIRANDA","code":"US-CA-NJK"},
          {"name":"MISSION HILLS","code":"US-CA-MJH"},
          {"name":"MISSION VALLEY,SAN DIEGO","code":"US-CA-M6V"},
          {"name":"MISSION VIEJO","code":"US-CA-MJI"},
          {"name":"MODESTO","code":"US-CA-MOD"},
          {"name":"MOFFETT FIELD","code":"US-CA-MEF"},
          {"name":"MOJAVE","code":"US-CA-MOJ"},
          {"name":"MONROVIA","code":"US-CA-XIY"},
          {"name":"MONTAGUE","code":"US-CA-SIY"},
          {"name":"MONTARA","code":"US-CA-OQT"},
          {"name":"MONTCLAIR","code":"US-CA-MZT"},
          {"name":"MONTE RIO","code":"US-CA-C4F"},
          {"name":"MONTEBELLO","code":"US-CA-MTB"},
          {"name":"MONTECITO","code":"US-CA-ZMO"},
          {"name":"MONTEREY","code":"US-CA-MY3"},
          {"name":"MONTEREY PARK","code":"US-CA-MZU"},
          {"name":"MONTEREY-CARMEL APT","code":"US-CA-MRY"},
          {"name":"MONTROSE","code":"US-CA-MBH"},
          {"name":"MOORPARK","code":"US-CA-MQK"},
          {"name":"MORAGA","code":"US-CA-QMO"},
          {"name":"MORENO VALLEY","code":"US-CA-MV4"},
          {"name":"MORGAN HILL","code":"US-CA-MJV"},
          {"name":"MORRO BAY","code":"US-CA-MJK"},
          {"name":"MOSS BEACH","code":"US-CA-ZGA"},
          {"name":"MOSS LANDING","code":"US-CA-MLG"},
          {"name":"MOUNT HERMON","code":"US-CA-NHR"},
          {"name":"MOUNT SHASTA","code":"US-CA-MHS"},
          {"name":"MOUNT WILSON","code":"US-CA-MWS"},
          {"name":"MOUNTAIN CENTER","code":"US-CA-CZJ"},
          {"name":"MOUNTAIN HOUSE","code":"US-CA-HPQ"},
          {"name":"MOUNTAIN PASS","code":"US-CA-MTS"},
          {"name":"MOUNTAIN RANCH","code":"US-CA-M6O"},
          {"name":"MOUNTAIN VIEW","code":"US-CA-NUQ"},
          {"name":"MURPHYS","code":"US-CA-MS5"},
          {"name":"MURRIETA","code":"US-CA-MHQ"},
          {"name":"MYERS FLAT","code":"US-CA-DYF"},
          {"name":"NAPA","code":"US-CA-APC"},
          {"name":"NASHVILLE","code":"US-CA-N5V"},
          {"name":"NATIONAL CITY","code":"US-CA-NLN"},
          {"name":"NEEDLES","code":"US-CA-EED"},
          {"name":"NELSON","code":"US-CA-QSO"},
          {"name":"NEVADA CITY","code":"US-CA-NFC"},
          {"name":"NEW CUYAMA","code":"US-CA-NYV"},
          {"name":"NEWARK","code":"US-CA-NKQ"},
          {"name":"NEWBERRY SPRINGS","code":"US-CA-NYS"},
          {"name":"NEWBURY PARK","code":"US-CA-NVK"},
          {"name":"NEWCASTLE","code":"US-CA-NUC"},
          {"name":"NEWHALL","code":"US-CA-NW6"},
          {"name":"NEWMAN","code":"US-CA-NMW"},
          {"name":"NEWPORT","code":"US-CA-ZNP"},
          {"name":"NEWPORT BEACH","code":"US-CA-JNP"},
          {"name":"NEWPORT COAST","code":"US-CA-QPQ"},
          {"name":"NICASIO","code":"US-CA-NC8"},
          {"name":"NICE","code":"US-CA-NCQ"},
          {"name":"NILAND","code":"US-CA-NNQ"},
          {"name":"NILES","code":"US-CA-NIZ"},
          {"name":"NIPOMO","code":"US-CA-NIO"},
          {"name":"NIPTON","code":"US-CA-NP5"},
          {"name":"NORCO","code":"US-CA-NQO"},
          {"name":"NORDEN","code":"US-CA-NDQ"},
          {"name":"NORTH FORK","code":"US-CA-88C"},
          {"name":"NORTH HIGHLANDS","code":"US-CA-NDC"},
          {"name":"NORTH HILLS","code":"US-CA-NHH"},
          {"name":"NORTH HOLLYWOOD","code":"US-CA-NHD"},
          {"name":"NORTH LONG BEACH","code":"US-CA-NL3"},
          {"name":"NORTH PALM SPRINGS","code":"US-CA-RTP"},
          {"name":"NORTH SHORE","code":"US-CA-NSD"},
          {"name":"NORTHRIDGE","code":"US-CA-NRJ"},
          {"name":"NORTHSTAR","code":"US-CA-NJX"},
          {"name":"NORWALK","code":"US-CA-NAQ"},
          {"name":"NOVATO","code":"US-CA-NOT"},
          {"name":"NUEVO","code":"US-CA-NUE"},
          {"name":"OAK HILLS","code":"US-CA-OJH"},
          {"name":"OAK PARK","code":"US-CA-Q8P"},
          {"name":"OAK VIEW","code":"US-CA-OFW"},
          {"name":"OAKDALE","code":"US-CA-OKQ"},
          {"name":"OAKHURST","code":"US-CA-OHU"},
          {"name":"OAKLAND","code":"US-CA-OAK"},
          {"name":"OAKLEY","code":"US-CA-OQY"},
          {"name":"OAKVILLE","code":"US-CA-OVY"},
          {"name":"OASIS","code":"US-CA-OXX"},
          {"name":"OCCIDENTAL","code":"US-CA-O9C"},
          {"name":"OCEAN BEACH","code":"US-CA-OB3"},
          {"name":"OCEANO","code":"US-CA-VQC"},
          {"name":"OCEANSIDE","code":"US-CA-OCN"},
          {"name":"OILDALE","code":"US-CA-ODC"},
          {"name":"OJAI","code":"US-CA-OJA"},
          {"name":"OLANCHA","code":"US-CA-ZE3"},
          {"name":"OLEUM","code":"US-CA-OL2"},
          {"name":"OLIVE","code":"US-CA-OIE"},
          {"name":"OLIVEHURST","code":"US-CA-OVH"},
          {"name":"OLYMPIC VALLEY","code":"US-CA-ZOY"},
          {"name":"ONTARIO","code":"US-CA-ONT"},
          {"name":"ORANGE","code":"US-CA-JOR"},
          {"name":"ORANGE COVE","code":"US-CA-OCC"},
          {"name":"ORANGEVALE","code":"US-CA-OEV"},
          {"name":"ORCUTT","code":"US-CA-8MN"},
          {"name":"ORDBEND","code":"US-CA-ORY"},
          {"name":"OREGON HOUSE","code":"US-CA-OJG"},
          {"name":"ORICK","code":"US-CA-OQE"},
          {"name":"ORINDA","code":"US-CA-4CA"},
          {"name":"ORLAND","code":"US-CA-ORN"},
          {"name":"ORLEANS","code":"US-CA-OFZ"},
          {"name":"ORO GRANDE","code":"US-CA-OOQ"},
          {"name":"OROSI","code":"US-CA-ORO"},
          {"name":"OROVILLE","code":"US-CA-OVE"},
          {"name":"OTAY MESA","code":"US-CA-OYM"},
          {"name":"OXNARD","code":"US-CA-OXN"},
          {"name":"OXNARD-VENTURA APT","code":"US-CA-OXR"},
          {"name":"PACHECO","code":"US-CA-ZE4"},
          {"name":"PACIFIC GROVE","code":"US-CA-ICG"},
          {"name":"PACIFIC PALISADES","code":"US-CA-PPH"},
          {"name":"PACIFICA","code":"US-CA-PFQ"},
          {"name":"PACOIMA","code":"US-CA-PUQ"},
          {"name":"PAICINES","code":"US-CA-ZQ3"},
          {"name":"PAJARO","code":"US-CA-AJO"},
          {"name":"PALA","code":"US-CA-JPL"},
          {"name":"PALM DESERT","code":"US-CA-PMD"},
          {"name":"PALM SPRINGS","code":"US-CA-PSP"},
          {"name":"PALMDALE","code":"US-CA-QPL"},
          {"name":"PALO ALTO","code":"US-CA-PAO"},
          {"name":"PALO CEDRO","code":"US-CA-FQO"},
          {"name":"PALOMAR MOUNTAIN","code":"US-CA-PXF"},
          {"name":"PALOS VERDES ESTATES","code":"US-CA-PVP"},
          {"name":"PANAMA","code":"US-CA-4PA"},
          {"name":"PANORAMA CITY","code":"US-CA-PHQ"},
          {"name":"PARADISE","code":"US-CA-PD9"},
          {"name":"PARAMOUNT","code":"US-CA-PMQ"},
          {"name":"PARKFIELD","code":"US-CA-P8D"},
          {"name":"PARLIER","code":"US-CA-PAR"},
          {"name":"PASADENA","code":"US-CA-PSD"},
          {"name":"PASO ROBLES","code":"US-CA-PRB"},
          {"name":"PATTERSON","code":"US-CA-PCQ"},
          {"name":"PATTON","code":"US-CA-VQW"},
          {"name":"PAUMA VALLEY","code":"US-CA-UVY"},
          {"name":"PEBBLE BEACH","code":"US-CA-PBA"},
          {"name":"PEDLEY","code":"US-CA-PJZ"},
          {"name":"PENN VALLEY","code":"US-CA-7CA"},
          {"name":"PENNGROVE","code":"US-CA-9DK"},
          {"name":"PERRIS","code":"US-CA-PER"},
          {"name":"PESCADERO","code":"US-CA-YPQ"},
          {"name":"PETALUMA","code":"US-CA-PUM"},
          {"name":"PETROLIA","code":"US-CA-EJI"},
          {"name":"PHELAN","code":"US-CA-VPH"},
          {"name":"PHILO","code":"US-CA-PL6"},
          {"name":"PICO RIVERA","code":"US-CA-PQU"},
          {"name":"PIEDMONT","code":"US-CA-PVK"},
          {"name":"PIERCY","code":"US-CA-PR4"},
          {"name":"PINE COVE","code":"US-CA-PJF"},
          {"name":"PINE GROVE","code":"US-CA-VZV"},
          {"name":"PINE VALLEY","code":"US-CA-PVA"},
          {"name":"PINECREST","code":"US-CA-PC2"},
          {"name":"PINEDALE","code":"US-CA-PNL"},
          {"name":"PINOLE","code":"US-CA-P2C"},
          {"name":"PINYON PINES","code":"US-CA-PJM"},
          {"name":"PIONEER, AMADOR","code":"US-CA-PN3"},
          {"name":"PIRU","code":"US-CA-PIU"},
          {"name":"PISMO BEACH","code":"US-CA-PB5"},
          {"name":"PITTSBURG","code":"US-CA-PBG"},
          {"name":"PIXLEY","code":"US-CA-PXY"},
          {"name":"PLACENTIA","code":"US-CA-PCC"},
          {"name":"PLACERVILLE","code":"US-CA-PVF"},
          {"name":"PLASTER CITY","code":"US-CA-JRC"},
          {"name":"PLAYA DEL REY","code":"US-CA-PYQ"},
          {"name":"PLAYA VISTA","code":"US-CA-PDG"},
          {"name":"PLEASANT GROVE","code":"US-CA-YPG"},
          {"name":"PLEASANT HILL","code":"US-CA-PFH"},
          {"name":"PLEASANTON","code":"US-CA-JBS"},
          {"name":"PLYMOUTH","code":"US-CA-PYT"},
          {"name":"POINT ARENA","code":"US-CA-PA2"},
          {"name":"POINT CONCEPTION","code":"US-CA-PP5"},
          {"name":"POINT MUGU NAWC","code":"US-CA-PWC"},
          {"name":"POINT REYES STATION","code":"US-CA-9IU"},
          {"name":"POLLOCK PINES","code":"US-CA-9PL"},
          {"name":"POMONA","code":"US-CA-PQC"},
          {"name":"POPE VALLEY","code":"US-CA-PV9"},
          {"name":"PORT CHICAGO","code":"US-CA-PZH"},
          {"name":"PORT COSTA","code":"US-CA-PCX"},
          {"name":"PORT HUENEME","code":"US-CA-NTD"},
          {"name":"PORT SAN LUIS","code":"US-CA-PSL"},
          {"name":"PORTER RANCH","code":"US-CA-PO4"},
          {"name":"PORTERVILLE","code":"US-CA-PTV"},
          {"name":"PORTOLA","code":"US-CA-PXB"},
          {"name":"PORTOLA VALLEY","code":"US-CA-XPT"},
          {"name":"POTRERO","code":"US-CA-T8P"},
          {"name":"POTTER VALLEY","code":"US-CA-PV2"},
          {"name":"POWAY","code":"US-CA-QPW"},
          {"name":"PROBERTA","code":"US-CA-PJA"},
          {"name":"PRUNEDALE","code":"US-CA-PZB"},
          {"name":"QUAIL VALLEY","code":"US-CA-QUV"},
          {"name":"QUARTZ HILL","code":"US-CA-QUA"},
          {"name":"QUINCY","code":"US-CA-QUI"},
          {"name":"RAMONA","code":"US-CA-RNX"},
          {"name":"RANCHO","code":"US-CA-RBK"},
          {"name":"RANCHO BELAGO","code":"US-CA-RBQ"},
          {"name":"RANCHO BERNARDO","code":"US-CA-RBO"},
          {"name":"RANCHO CORDOVA","code":"US-CA-RZC"},
          {"name":"RANCHO CUCAMONGA","code":"US-CA-RCU"},
          {"name":"RANCHO DOMINGUEZ","code":"US-CA-RDO"},
          {"name":"RANCHO MIRAGE","code":"US-CA-RHG"},
          {"name":"RANCHO MURIETA","code":"US-CA-5RM"},
          {"name":"RANCHO PALOS VERDES","code":"US-CA-RAZ"},
          {"name":"RANCHO PARK","code":"US-CA-RPQ"},
          {"name":"RANCHO SANTA FE","code":"US-CA-RSU"},
          {"name":"RANCHO SANTA MARGARITA","code":"US-CA-RSM"},
          {"name":"RAYMOND","code":"US-CA-AYM"},
          {"name":"RED BLUFF","code":"US-CA-RBL"},
          {"name":"REDDING","code":"US-CA-RDD"},
          {"name":"REDLANDS","code":"US-CA-RLS"},
          {"name":"REDONDO BEACH","code":"US-CA-RBD"},
          {"name":"REDWAY","code":"US-CA-RD7"},
          {"name":"REDWOOD CITY","code":"US-CA-RWC"},
          {"name":"REDWOOD VALLEY","code":"US-CA-RR6"},
          {"name":"REEDLEY","code":"US-CA-RDC"},
          {"name":"RESCUE","code":"US-CA-RS9"},
          {"name":"RESEDA","code":"US-CA-RSE"},
          {"name":"RIALTO","code":"US-CA-RLO"},
          {"name":"RICHGROVE","code":"US-CA-RG2"},
          {"name":"RICHMOND","code":"US-CA-RCH"},
          {"name":"RICHVALE","code":"US-CA-ZIA"},
          {"name":"RIDGECREST","code":"US-CA-IGD"},
          {"name":"RIO DELL","code":"US-CA-DJL"},
          {"name":"RIO LINDA","code":"US-CA-RLI"},
          {"name":"RIO OSO","code":"US-CA-RO6"},
          {"name":"RIO VISTA","code":"US-CA-RVA"},
          {"name":"RIPLEY","code":"US-CA-RPP"},
          {"name":"RIPON","code":"US-CA-RIP"},
          {"name":"RIVERBANK","code":"US-CA-ZRB"},
          {"name":"RIVERDALE","code":"US-CA-RVX"},
          {"name":"RIVERSIDE","code":"US-CA-RAL"},
          {"name":"ROBBINS","code":"US-CA-RO7"},
          {"name":"ROCKLIN","code":"US-CA-RKL"},
          {"name":"RODEO","code":"US-CA-RD4"},
          {"name":"ROHNERT PARK","code":"US-CA-RFH"},
          {"name":"ROLLING HILLS","code":"US-CA-5RH"},
          {"name":"ROLLING HILLS ESTATES","code":"US-CA-RHJ"},
          {"name":"ROMOLAND","code":"US-CA-OMD"},
          {"name":"ROSAMOND","code":"US-CA-RSZ"},
          {"name":"ROSEMEAD","code":"US-CA-RFS"},
          {"name":"ROSEVILLE","code":"US-CA-RVC"},
          {"name":"ROSS","code":"US-CA-RZO"},
          {"name":"ROSSMOOR","code":"US-CA-RRY"},
          {"name":"ROWLAND HEIGHTS","code":"US-CA-RHW"},
          {"name":"RUBIDOUX","code":"US-CA-XUO"},
          {"name":"RUNNING SPRINGS","code":"US-CA-RKX"},
          {"name":"RUTHERFORD","code":"US-CA-RTH"},
          {"name":"RYDE","code":"US-CA-RYB"},
          {"name":"SACRAMENTO","code":"US-CA-SAC"},
          {"name":"SAGE","code":"US-CA-SLJ"},
          {"name":"SAINT HELENA","code":"US-CA-SHE"},
          {"name":"SAINT LOUIS","code":"US-CA-UIS"},
          {"name":"SAINT LOUIS/SIERRA COUNTY","code":"US-CA-S2L"},
          {"name":"SALIDA","code":"US-CA-ZIG"},
          {"name":"SALINAS","code":"US-CA-SNS"},
          {"name":"SALTON CITY","code":"US-CA-SAS"},
          {"name":"SAMOA","code":"US-CA-IOR"},
          {"name":"SAN ANDREAS","code":"US-CA-SA7"},
          {"name":"SAN ANSELMO","code":"US-CA-DLM"},
          {"name":"SAN ANTONIO HEIGHTS","code":"US-CA-NJZ"},
          {"name":"SAN ARDO","code":"US-CA-SA9"},
          {"name":"SAN BERNARDINO","code":"US-CA-SBT"},
          {"name":"SAN BRUNO","code":"US-CA-SXB"},
          {"name":"SAN CARLOS","code":"US-CA-SQL"},
          {"name":"SAN CLEMENTE","code":"US-CA-XMO"},
          {"name":"SAN DIEGO","code":"US-CA-SAN"},
          {"name":"SAN DIMAS","code":"US-CA-SDA"},
          {"name":"SAN FERNANDO","code":"US-CA-SFR"},
          {"name":"SAN FRANCISCO","code":"US-CA-SFO"},
          {"name":"SAN GABRIEL","code":"US-CA-SGG"},
          {"name":"SAN GERONIMO","code":"US-CA-GE2"},
          {"name":"SAN GREGORIO","code":"US-CA-GG5"},
          {"name":"SAN JACINTO","code":"US-CA-JNO"},
          {"name":"SAN JOAQUIN","code":"US-CA-JOQ"},
          {"name":"SAN JOAQUIN RIVER","code":"US-CA-ANJ"},
          {"name":"SAN JOSE","code":"US-CA-SJC"},
          {"name":"SAN JUAN BAUTISTA","code":"US-CA-ZFX"},
          {"name":"SAN JUAN CAPISTRANO","code":"US-CA-SAJ"},
          {"name":"SAN LEANDRO","code":"US-CA-SNH"},
          {"name":"SAN LORENZO","code":"US-CA-YSZ"},
          {"name":"SAN LUCAS","code":"US-CA-ZXZ"},
          {"name":"SAN LUIS OBISPO","code":"US-CA-CSL"},
          {"name":"SAN MARCOS","code":"US-CA-SNM"},
          {"name":"SAN MARINO","code":"US-CA-SMD"},
          {"name":"SAN MARTIN","code":"US-CA-SXJ"},
          {"name":"SAN MATEO","code":"US-CA-SXF"},
          {"name":"SAN MIGUEL","code":"US-CA-SYL"},
          {"name":"SAN PABLO","code":"US-CA-SAK"},
          {"name":"SAN PEDRO","code":"US-CA-SPQ"},
          {"name":"SAN QUENTIN","code":"US-CA-QT2"},
          {"name":"SAN RAFAEL","code":"US-CA-SRF"},
          {"name":"SAN RAMON","code":"US-CA-SJB"},
          {"name":"SAN YSIDRO","code":"US-CA-SYO"},
          {"name":"SAND CITY","code":"US-CA-SDC"},
          {"name":"SANFORD","code":"US-CA-SF6"},
          {"name":"SANGER","code":"US-CA-SRK"},
          {"name":"SANTA ANA","code":"US-CA-SNA"},
          {"name":"SANTA BARBARA","code":"US-CA-SBA"},
          {"name":"SANTA CLARA","code":"US-CA-SCZ"},
          {"name":"SANTA CLARITA","code":"US-CA-SAM"},
          {"name":"SANTA CRUZ","code":"US-CA-SRU"},
          {"name":"SANTA FE SPRINGS","code":"US-CA-UYR"},
          {"name":"SANTA MARGARITA","code":"US-CA-SMG"},
          {"name":"SANTA MARIA","code":"US-CA-SMX"},
          {"name":"SANTA MONICA","code":"US-CA-SMO"},
          {"name":"SANTA PAULA","code":"US-CA-SZP"},
          {"name":"SANTA ROSA","code":"US-CA-STS"},
          {"name":"SANTA ROSA ISLAND","code":"US-CA-RO2"},
          {"name":"SANTA YNEZ","code":"US-CA-SQA"},
          {"name":"SANTA YSABEL","code":"US-CA-SY9"},
          {"name":"SANTEE","code":"US-CA-SXD"},
          {"name":"SARATOGA","code":"US-CA-XMQ"},
          {"name":"SATICOY","code":"US-CA-SY5"},
          {"name":"SAUGUS","code":"US-CA-UGU"},
          {"name":"SAUSALITO","code":"US-CA-JMC"},
          {"name":"SCOTIA","code":"US-CA-SIA"},
          {"name":"SCOTTS VALLEY","code":"US-CA-SV4"},
          {"name":"SEAL BEACH","code":"US-CA-SLH"},
          {"name":"SEARLES","code":"US-CA-SL5"},
          {"name":"SEASIDE","code":"US-CA-XLU"},
          {"name":"SEBASTOPOL","code":"US-CA-SYD"},
          {"name":"SEELEY","code":"US-CA-SY4"},
          {"name":"SELBY","code":"US-CA-SEL"},
          {"name":"SELMA","code":"US-CA-SQZ"},
          {"name":"SEPULVEDA","code":"US-CA-SQF"},
          {"name":"SHAFTER","code":"US-CA-MIT"},
          {"name":"SHAFTER, KERN","code":"US-CA-SH3"},
          {"name":"SHANDON","code":"US-CA-JHD"},
          {"name":"SHASTA","code":"US-CA-4SC"},
          {"name":"SHASTA LAKE","code":"US-CA-S9K"},
          {"name":"SHAVER LAKE","code":"US-CA-2SL"},
          {"name":"SHERMAN OAKS","code":"US-CA-SJJ"},
          {"name":"SHINGLE SPRINGS","code":"US-CA-XSH"},
          {"name":"SHINGLETOWN","code":"US-CA-S3O"},
          {"name":"SHOSHONE","code":"US-CA-HQS"},
          {"name":"SIERRA MADRE","code":"US-CA-SIM"},
          {"name":"SIERRA VISTA","code":"US-CA-SI7"},
          {"name":"SIGNAL HILL","code":"US-CA-SII"},
          {"name":"SILVERADO","code":"US-CA-SI3"},
          {"name":"SIMI VALLEY","code":"US-CA-SIU"},
          {"name":"SMITH RIVER","code":"US-CA-IXR"},
          {"name":"SODA SPRINGS","code":"US-CA-KQP"},
          {"name":"SOLANA BEACH","code":"US-CA-SZB"},
          {"name":"SOLEDAD","code":"US-CA-ZOL"},
          {"name":"SOLVANG","code":"US-CA-OLG"},
          {"name":"SOMERSET","code":"US-CA-6ST"},
          {"name":"SOMIS","code":"US-CA-SSJ"},
          {"name":"SONOMA","code":"US-CA-SOY"},
          {"name":"SONORA","code":"US-CA-SOR"},
          {"name":"SOQUEL","code":"US-CA-SQQ"},
          {"name":"SORRENTO","code":"US-CA-OET"},
          {"name":"SOUTH EL MONTE","code":"US-CA-SDM"},
          {"name":"SOUTH GATE","code":"US-CA-SGC"},
          {"name":"SOUTH LAKE TAHOE","code":"US-CA-TVL"},
          {"name":"SOUTH PASADENA","code":"US-CA-SSD"},
          {"name":"SOUTH SAN FRANCISCO","code":"US-CA-SYF"},
          {"name":"SPRING VALLEY, SAN DIEGO","code":"US-CA-PGY"},
          {"name":"SPRINGVILLE","code":"US-CA-SG8"},
          {"name":"SQUAW VALLEY","code":"US-CA-SQT"},
          {"name":"STANDARD","code":"US-CA-5SC"},
          {"name":"STANFORD","code":"US-CA-TNQ"},
          {"name":"STANTON","code":"US-CA-STI"},
          {"name":"STEVENSON RANCH","code":"US-CA-S3H"},
          {"name":"STEVINSON","code":"US-CA-F8S"},
          {"name":"STINSON BEACH","code":"US-CA-3SB"},
          {"name":"STOCKTON","code":"US-CA-SCK"},
          {"name":"STONEHURST","code":"US-CA-SHT"},
          {"name":"STORRIE","code":"US-CA-S7R"},
          {"name":"STRATFORD","code":"US-CA-9SF"},
          {"name":"STRATHMORE","code":"US-CA-SMJ"},
          {"name":"STRAWBERRY","code":"US-CA-YYB"},
          {"name":"STUDIO CITY","code":"US-CA-SCY"},
          {"name":"SUISUN CITY","code":"US-CA-SIX"},
          {"name":"SULTANA","code":"US-CA-ULT"},
          {"name":"SUMMERLAND","code":"US-CA-UMD"},
          {"name":"SUN CITY","code":"US-CA-XZI"},
          {"name":"SUN VALLEY","code":"US-CA-SVY"},
          {"name":"SUNLAND","code":"US-CA-SND"},
          {"name":"SUNNYVALE","code":"US-CA-SNN"},
          {"name":"SUNOL","code":"US-CA-UOL"},
          {"name":"SUNSET","code":"US-CA-SNC"},
          {"name":"SUNSET BEACH","code":"US-CA-SBE"},
          {"name":"SUNSET WHITNEY RANCH","code":"US-CA-SWE"},
          {"name":"SUSANVILLE","code":"US-CA-SVE"},
          {"name":"SUTTER","code":"US-CA-SMS"},
          {"name":"SUTTER CREEK","code":"US-CA-XXK"},
          {"name":"SYLMAR","code":"US-CA-SYY"},
          {"name":"TAFT","code":"US-CA-TF2"},
          {"name":"TAHOE CITY","code":"US-CA-TAC"},
          {"name":"TAHOMA","code":"US-CA-AQK"},
          {"name":"TARZANA","code":"US-CA-TZR"},
          {"name":"TECATE","code":"US-CA-TEC"},
          {"name":"TECOPA","code":"US-CA-TVP"},
          {"name":"TEHACHAPI","code":"US-CA-TSP"},
          {"name":"TEMECULA","code":"US-CA-TMC"},
          {"name":"TEMPLE CITY","code":"US-CA-TCI"},
          {"name":"TEMPLETON","code":"US-CA-TT6"},
          {"name":"TERMINAL ISLAND","code":"US-CA-TIY"},
          {"name":"TERRA BELLA","code":"US-CA-VVC"},
          {"name":"THENARD","code":"US-CA-OJL"},
          {"name":"THERMAL","code":"US-CA-TRM"},
          {"name":"THORNTON","code":"US-CA-TNN"},
          {"name":"THOUSAND OAKS","code":"US-CA-JTO"},
          {"name":"THOUSAND PALMS","code":"US-CA-UNG"},
          {"name":"THREE RIVERS","code":"US-CA-TR8"},
          {"name":"TIBURON","code":"US-CA-XTN"},
          {"name":"TIPTON","code":"US-CA-TPT"},
          {"name":"TOLUCA LAKE","code":"US-CA-TQL"},
          {"name":"TOPANGA","code":"US-CA-TGP"},
          {"name":"TORRANCE","code":"US-CA-TOA"},
          {"name":"TRABUCO CANYON","code":"US-CA-TC9"},
          {"name":"TRACY","code":"US-CA-TRC"},
          {"name":"TRAVER","code":"US-CA-TVY"},
          {"name":"TRAVIS AIR FORCE BASE","code":"US-CA-VFB"},
          {"name":"TREASURE ISLAND/LOS ANGELES","code":"US-CA-TSU"},
          {"name":"TRENTON","code":"US-CA-TNR"},
          {"name":"TRES PINOS","code":"US-CA-8YI"},
          {"name":"TRINIDAD","code":"US-CA-AK4"},
          {"name":"TRONA","code":"US-CA-TRH"},
          {"name":"TRUCKEE","code":"US-CA-TKF"},
          {"name":"TUJUNGA","code":"US-CA-TJN"},
          {"name":"TULARE","code":"US-CA-TLR"},
          {"name":"TULELAKE","code":"US-CA-TLL"},
          {"name":"TUOLUMNE","code":"US-CA-TL4"},
          {"name":"TUPMAN","code":"US-CA-TP3"},
          {"name":"TURLOCK","code":"US-CA-TLK"},
          {"name":"TUSTIN","code":"US-CA-TUN"},
          {"name":"TWENTYNINE PALMS","code":"US-CA-TNP"},
          {"name":"UKIAH","code":"US-CA-UKI"},
          {"name":"UNION CITY","code":"US-CA-UNC"},
          {"name":"UNIVERSAL CITY","code":"US-CA-UVC"},
          {"name":"UPLAND","code":"US-CA-CCB"},
          {"name":"VACAVILLE","code":"US-CA-VCX"},
          {"name":"VALENCIA","code":"US-CA-VLC"},
          {"name":"VALLECITO","code":"US-CA-8QA"},
          {"name":"VALLEJO","code":"US-CA-VLO"},
          {"name":"VALLEY CENTER","code":"US-CA-VCY"},
          {"name":"VALLEY SPRINGS","code":"US-CA-VLI"},
          {"name":"VALLEY VILLAGE","code":"US-CA-VYB"},
          {"name":"VAN NUYS","code":"US-CA-VNY"},
          {"name":"VANDENBERG AIR FORCE BASE","code":"US-CA-VA3"},
          {"name":"VENICE","code":"US-CA-VCI"},
          {"name":"VENTURA","code":"US-CA-VNT"},
          {"name":"VENTURA-OXNARD APT","code":"US-CA-OXR"},
          {"name":"VERDUGO CITY","code":"US-CA-VCU"},
          {"name":"VERMONT","code":"US-CA-ZFQ"},
          {"name":"VERNALIS","code":"US-CA-VRI"},
          {"name":"VERNON","code":"US-CA-VCA"},
          {"name":"VERONA","code":"US-CA-VN4"},
          {"name":"VICTOR","code":"US-CA-3DU"},
          {"name":"VICTORVILLE","code":"US-CA-VCV"},
          {"name":"VILLA PARK","code":"US-CA-VPK"},
          {"name":"VINA","code":"US-CA-VJN"},
          {"name":"VINEBURG","code":"US-CA-3TR"},
          {"name":"VISALIA","code":"US-CA-VIS"},
          {"name":"VISTA","code":"US-CA-VTI"},
          {"name":"VOLCANO","code":"US-CA-VCQ"},
          {"name":"VOLTA","code":"US-CA-VTA"},
          {"name":"WALKER","code":"US-CA-VWE"},
          {"name":"WALLACE","code":"US-CA-WA2"},
          {"name":"WALNUT","code":"US-CA-WLU"},
          {"name":"WALNUT CREEK","code":"US-CA-WAC"},
          {"name":"WALNUT GROVE","code":"US-CA-YXG"},
          {"name":"WALSH STATION","code":"US-CA-WQC"},
          {"name":"WARM SPRINGS","code":"US-CA-WJY"},
          {"name":"WARNER SPRINGS","code":"US-CA-WS3"},
          {"name":"WASCO","code":"US-CA-WQS"},
          {"name":"WASHINGTON","code":"US-CA-WT3"},
          {"name":"WATERFORD","code":"US-CA-WVD"},
          {"name":"WATSON","code":"US-CA-WTC"},
          {"name":"WATSONVILLE","code":"US-CA-WVI"},
          {"name":"WATTS","code":"US-CA-WJZ"},
          {"name":"WEAVERVILLE","code":"US-CA-VWX"},
          {"name":"WEED","code":"US-CA-WD3"},
          {"name":"WELDON","code":"US-CA-WL4"},
          {"name":"WEOTT","code":"US-CA-WBI"},
          {"name":"WEST COVINA","code":"US-CA-WKZ"},
          {"name":"WEST HILLS","code":"US-CA-TLS"},
          {"name":"WEST HOLLYWOOD","code":"US-CA-WWU"},
          {"name":"WEST LOS ANGELES","code":"US-CA-WQJ"},
          {"name":"WEST SACRAMENTO","code":"US-CA-WSM"},
          {"name":"WESTCHESTER","code":"US-CA-WQH"},
          {"name":"WESTLAKE VILLAGE","code":"US-CA-WQV"},
          {"name":"WESTLEY","code":"US-CA-QWY"},
          {"name":"WESTMINSTER","code":"US-CA-WMZ"},
          {"name":"WESTPORT","code":"US-CA-WPT"},
          {"name":"WESTWOOD","code":"US-CA-3DC"},
          {"name":"WESTWOOD VILLAGE","code":"US-CA-WPJ"},
          {"name":"WHITEWATER","code":"US-CA-DXA"},
          {"name":"WHITTIER","code":"US-CA-WHR"},
          {"name":"WILDOMAR","code":"US-CA-IOM"},
          {"name":"WILLIAMS","code":"US-CA-IIW"},
          {"name":"WILLITS","code":"US-CA-WZI"},
          {"name":"WILLOW CREEK","code":"US-CA-WVU"},
          {"name":"WILLOWBROOK","code":"US-CA-WVW"},
          {"name":"WILLOWS","code":"US-CA-WLW"},
          {"name":"WILMINGTON","code":"US-CA-WTN"},
          {"name":"WILTON","code":"US-CA-UWO"},
          {"name":"WINCHESTER","code":"US-CA-IHR"},
          {"name":"WINDSOR","code":"US-CA-WOX"},
          {"name":"WINNETKA","code":"US-CA-QW2"},
          {"name":"WINTERHAVEN","code":"US-CA-TVA"},
          {"name":"WINTERS","code":"US-CA-IES"},
          {"name":"WINTON","code":"US-CA-WXZ"},
          {"name":"WOODACRE","code":"US-CA-8DC"},
          {"name":"WOODBRIDGE","code":"US-CA-W9R"},
          {"name":"WOODLAKE","code":"US-CA-WUL"},
          {"name":"WOODLAND","code":"US-CA-WDQ"},
          {"name":"WOODLAND HILLS","code":"US-CA-WPD"},
          {"name":"WOODSIDE","code":"US-CA-WDZ"},
          {"name":"WRIGHTWOOD","code":"US-CA-WNW"},
          {"name":"YERMO","code":"US-CA-YOY"},
          {"name":"YOLO","code":"US-CA-YOO"},
          {"name":"YORBA LINDA","code":"US-CA-YBL"},
          {"name":"YORKVILLE","code":"US-CA-C7Q"},
          {"name":"YOSEMITE NTL PARK","code":"US-CA-OYS"},
          {"name":"YOUNTVILLE","code":"US-CA-YTV"},
          {"name":"YREKA","code":"US-CA-RKC"},
          {"name":"YUBA CITY","code":"US-CA-YUB"},
          {"name":"YUCAIPA","code":"US-CA-YUC"},
          {"name":"YUCCA VALLEY","code":"US-CA-YAV"},
          {"name":"ZAMORA","code":"US-CA-8PR"},
        ]
      },
      {
        name: 'Colorado',
        code: 'US-CO',
        cities: [
          {"name":"ADAMS CITY","code":"US-CO-YP2"},
          {"name":"AKRON","code":"US-CO-AKO"},
          {"name":"ALAMOSA","code":"US-CO-ALS"},
          {"name":"ALMA","code":"US-CO-AM2"},
          {"name":"ANTONITO","code":"US-CO-ZJK"},
          {"name":"ARAPAHOE","code":"US-CO-AAE"},
          {"name":"ARVADA","code":"US-CO-AVA"},
          {"name":"ASPEN","code":"US-CO-ASE"},
          {"name":"AULT","code":"US-CO-AL4"},
          {"name":"AURORA","code":"US-CO-AUC"},
          {"name":"AUSTIN","code":"US-CO-ZAW"},
          {"name":"AVON","code":"US-CO-ANW"},
          {"name":"AVONDALE","code":"US-CO-AJW"},
          {"name":"BAILEY","code":"US-CO-YB2"},
          {"name":"BASALT","code":"US-CO-ZBS"},
          {"name":"BAYFIELD","code":"US-CO-YFI"},
          {"name":"BEAVER CREEK","code":"US-CO-BC6"},
          {"name":"BELLVUE","code":"US-CO-VUE"},
          {"name":"BENNETT","code":"US-CO-EN8"},
          {"name":"BERTHOUD","code":"US-CO-BVC"},
          {"name":"BLACK HAWK","code":"US-CO-HWJ"},
          {"name":"BLANCA","code":"US-CO-JBN"},
          {"name":"BOONE","code":"US-CO-B9O"},
          {"name":"BOULDER","code":"US-CO-WBU"},
          {"name":"BRECKENRIDGE","code":"US-CO-EKG"},
          {"name":"BRIDGEPORT","code":"US-CO-BP3"},
          {"name":"BRIGHTON","code":"US-CO-BGK"},
          {"name":"BRISTOL","code":"US-CO-BRT"},
          {"name":"BROOKFIELD","code":"US-CO-KFR"},
          {"name":"BROOMFIELD","code":"US-CO-BJC"},
          {"name":"BRUSH","code":"US-CO-BHQ"},
          {"name":"BUENA VISTA","code":"US-CO-JBU"},
          {"name":"BURLINGTON","code":"US-CO-LGO"},
          {"name":"CALHAN","code":"US-CO-CLH"},
          {"name":"CANON CITY","code":"US-CO-CNE"},
          {"name":"CAPULIN","code":"US-CO-C5P"},
          {"name":"CARBONDALE","code":"US-CO-ZXP"},
          {"name":"CASCADE","code":"US-CO-KQE"},
          {"name":"CASTLE PINES","code":"US-CO-DP9"},
          {"name":"CASTLE ROCK","code":"US-CO-CSK"},
          {"name":"CEDAREDGE","code":"US-CO-5CR"},
          {"name":"CENTENNIAL","code":"US-CO-NNN"},
          {"name":"CENTER","code":"US-CO-XXC"},
          {"name":"CENTRAL CITY","code":"US-CO-KTC"},
          {"name":"CHERRY HILLS VILLAGE","code":"US-CO-C5V"},
          {"name":"CHEYENNE WELLS","code":"US-CO-ZYN"},
          {"name":"CIMARRON","code":"US-CO-CM8"},
          {"name":"CLARK","code":"US-CO-C24"},
          {"name":"CLIFTON, MESA","code":"US-CO-CF3"},
          {"name":"CLIMAX","code":"US-CO-JMX"},
          {"name":"COLORADO CITY","code":"US-CO-XOY"},
          {"name":"COLORADO SPRINGS","code":"US-CO-COS"},
          {"name":"COMMERCE CITY","code":"US-CO-CMT"},
          {"name":"CONIFER","code":"US-CO-OIF"},
          {"name":"COPE","code":"US-CO-OPU"},
          {"name":"COPPER MOUNTAIN","code":"US-CO-QCE"},
          {"name":"CORTEZ","code":"US-CO-CEZ"},
          {"name":"CRAIG","code":"US-CO-CIG"},
          {"name":"CRAWFORD","code":"US-CO-W7D"},
          {"name":"CRESTED BUTTE","code":"US-CO-CSE"},
          {"name":"CRESTONE","code":"US-CO-C3E"},
          {"name":"CRIPPLE CREEK","code":"US-CO-UCZ"},
          {"name":"DACONO","code":"US-CO-DCQ"},
          {"name":"DE BEQUE","code":"US-CO-DQU"},
          {"name":"DEL NORTE","code":"US-CO-DN2"},
          {"name":"DELTA","code":"US-CO-DTC"},
          {"name":"DENVER","code":"US-CO-DEN"},
          {"name":"DILLON","code":"US-CO-4DI"},
          {"name":"DIVIDE","code":"US-CO-DV5"},
          {"name":"DOLORES","code":"US-CO-ZDE"},
          {"name":"DOVE CREEK","code":"US-CO-DC7"},
          {"name":"DUPONT","code":"US-CO-DPN"},
          {"name":"DURANGO","code":"US-CO-DRO"},
          {"name":"EAGLE","code":"US-CO-EG3"},
          {"name":"EATON","code":"US-CO-ETQ"},
          {"name":"EDGEWATER","code":"US-CO-EZQ"},
          {"name":"EDWARDS","code":"US-CO-EDZ"},
          {"name":"EGNAR","code":"US-CO-EJN"},
          {"name":"ELBERT","code":"US-CO-EB4"},
          {"name":"ELIZABETH","code":"US-CO-EZ8"},
          {"name":"ENGLEWOOD","code":"US-CO-QTS"},
          {"name":"ERIE","code":"US-CO-XEH"},
          {"name":"ESTES PARK","code":"US-CO-EP9"},
          {"name":"EVANS","code":"US-CO-EC9"},
          {"name":"EVERGREEN","code":"US-CO-YEG"},
          {"name":"FAIR PLAY","code":"US-CO-RPY"},
          {"name":"FAIRPLAY","code":"US-CO-FPD"},
          {"name":"FEDERAL HEIGHTS","code":"US-CO-FH6"},
          {"name":"FIRESTONE","code":"US-CO-RTC"},
          {"name":"FLAGLER","code":"US-CO-FGL"},
          {"name":"FLORENCE","code":"US-CO-FLZ"},
          {"name":"FORT CARSON","code":"US-CO-CFZ"},
          {"name":"FORT COLLINS","code":"US-CO-FNL"},
          {"name":"FORT GARLAND","code":"US-CO-FG9"},
          {"name":"FORT LUPTON","code":"US-CO-QFU"},
          {"name":"FORT MORGAN","code":"US-CO-FMG"},
          {"name":"FOUNTAIN","code":"US-CO-FYN"},
          {"name":"FRANKTOWN","code":"US-CO-5FT"},
          {"name":"FRASER","code":"US-CO-5RF"},
          {"name":"FREDERICK","code":"US-CO-FK2"},
          {"name":"FRISCO","code":"US-CO-FSC"},
          {"name":"FRUITA","code":"US-CO-FT4"},
          {"name":"GARDNER","code":"US-CO-GD7"},
          {"name":"GATEWAY","code":"US-CO-GWZ"},
          {"name":"GENOA","code":"US-CO-GN3"},
          {"name":"GILCREST","code":"US-CO-GCT"},
          {"name":"GILL","code":"US-CO-GJL"},
          {"name":"GLADE PARK","code":"US-CO-5GP"},
          {"name":"GLENDALE","code":"US-CO-GEP"},
          {"name":"GLENWOOD SPRINGS","code":"US-CO-GWS"},
          {"name":"GOLDEN","code":"US-CO-GOE"},
          {"name":"GRANBY","code":"US-CO-RNB"},
          {"name":"GRAND JUNCTION","code":"US-CO-GJT"},
          {"name":"GRAND LAKE","code":"US-CO-GK2"},
          {"name":"GRANITE","code":"US-CO-GKI"},
          {"name":"GREELEY","code":"US-CO-GXY"},
          {"name":"GREENWOOD VILLAGE","code":"US-CO-GV4"},
          {"name":"GROVER","code":"US-CO-OVK"},
          {"name":"GUNNISON","code":"US-CO-GUC"},
          {"name":"GYPSUM","code":"US-CO-GM2"},
          {"name":"HARTSEL","code":"US-CO-HE3"},
          {"name":"HAXTUN","code":"US-CO-HX9"},
          {"name":"HAYDEN","code":"US-CO-HDN"},
          {"name":"HENDERSON","code":"US-CO-HDO"},
          {"name":"HESPERUS","code":"US-CO-HP6"},
          {"name":"HIGHLANDS RANCH","code":"US-CO-HRZ"},
          {"name":"HILLSIDE","code":"US-CO-HSD"},
          {"name":"HOLLY","code":"US-CO-H2Y"},
          {"name":"HOLYOKE","code":"US-CO-HYK"},
          {"name":"HOOPER","code":"US-CO-HP7"},
          {"name":"HOTCHKISS","code":"US-CO-HKT"},
          {"name":"HUDSON","code":"US-CO-HDS"},
          {"name":"HUGO","code":"US-CO-C3D"},
          {"name":"IDAHO SPRINGS","code":"US-CO-IDH"},
          {"name":"IDALIA","code":"US-CO-IL3"},
          {"name":"IGNACIO","code":"US-CO-IGA"},
          {"name":"IRONDALE","code":"US-CO-IRO"},
          {"name":"JAMESTOWN","code":"US-CO-JSO"},
          {"name":"JOHNSTOWN","code":"US-CO-JZW"},
          {"name":"KEENESBURG","code":"US-CO-ZOZ"},
          {"name":"KEYSTONE","code":"US-CO-KYS"},
          {"name":"KIOWA","code":"US-CO-KW8"},
          {"name":"KREMMLING","code":"US-CO-KM3"},
          {"name":"LA JUNTA","code":"US-CO-LJH"},
          {"name":"LA SALLE","code":"US-CO-QAA"},
          {"name":"LAFAYETTE","code":"US-CO-LFY"},
          {"name":"LAKE GEORGE","code":"US-CO-ULE"},
          {"name":"LAKEWOOD","code":"US-CO-LJK"},
          {"name":"LAMAR","code":"US-CO-LAA"},
          {"name":"LAPORTE","code":"US-CO-ZEE"},
          {"name":"LARKSPUR","code":"US-CO-LP7"},
          {"name":"LEADVILLE","code":"US-CO-LXV"},
          {"name":"LIMON","code":"US-CO-LIC"},
          {"name":"LITTLETON","code":"US-CO-LTO"},
          {"name":"LOAN TREE","code":"US-CO-LT4"},
          {"name":"LONE TREE","code":"US-CO-LT5"},
          {"name":"LONGMONT","code":"US-CO-LNG"},
          {"name":"LOUISVILLE","code":"US-CO-LUE"},
          {"name":"LOVELAND","code":"US-CO-LOV"},
          {"name":"LUCERNE","code":"US-CO-XZC"},
          {"name":"LYONS","code":"US-CO-YNS"},
          {"name":"MAHER","code":"US-CO-3MH"},
          {"name":"MANASSA","code":"US-CO-MX4"},
          {"name":"MANCOS","code":"US-CO-MCS"},
          {"name":"MANITOU SPRINGS","code":"US-CO-MS8"},
          {"name":"MANZANOLA","code":"US-CO-M5Z"},
          {"name":"MARBLE","code":"US-CO-MX2"},
          {"name":"MCCLAVE","code":"US-CO-VCZ"},
          {"name":"MCCOY","code":"US-CO-QQM"},
          {"name":"MEAD","code":"US-CO-M9E"},
          {"name":"MEEKER","code":"US-CO-MRU"},
          {"name":"MERINO","code":"US-CO-IJO"},
          {"name":"MESA","code":"US-CO-JME"},
          {"name":"MILLIKEN","code":"US-CO-5MK"},
          {"name":"MINTURN","code":"US-CO-M65"},
          {"name":"MOFFAT","code":"US-CO-MA3"},
          {"name":"MONTE VISTA","code":"US-CO-VMZ"},
          {"name":"MONTROSE","code":"US-CO-MTJ"},
          {"name":"MONUMENT","code":"US-CO-MEB"},
          {"name":"MORRISON","code":"US-CO-MJE"},
          {"name":"NATHROP","code":"US-CO-N2H"},
          {"name":"NEDERLAND","code":"US-CO-NDD"},
          {"name":"NEW CASTLE","code":"US-CO-NSX"},
          {"name":"NIWOT","code":"US-CO-N2W"},
          {"name":"NORTHGLENN","code":"US-CO-NGL"},
          {"name":"NUCLA","code":"US-CO-NUA"},
          {"name":"NUNN","code":"US-CO-VUN"},
          {"name":"OAK CREEK","code":"US-CO-OCK"},
          {"name":"OHIO CITY","code":"US-CO-Q32"},
          {"name":"OLATHE","code":"US-CO-OZT"},
          {"name":"OTIS","code":"US-CO-O5T"},
          {"name":"OURAY","code":"US-CO-OUR"},
          {"name":"PAGOSA SPRINGS","code":"US-CO-PGO"},
          {"name":"PALISADE","code":"US-CO-PDZ"},
          {"name":"PALMER LAKE","code":"US-CO-PE4"},
          {"name":"PAONIA","code":"US-CO-WPO"},
          {"name":"PARACHUTE","code":"US-CO-PHU"},
          {"name":"PARKER","code":"US-CO-ZKR"},
          {"name":"PARSHALL","code":"US-CO-P4A"},
          {"name":"PEETZ","code":"US-CO-EEZ"},
          {"name":"PENROSE","code":"US-CO-PR2"},
          {"name":"PEYTON","code":"US-CO-YPT"},
          {"name":"PINE","code":"US-CO-P5N"},
          {"name":"PLATTEVILLE","code":"US-CO-PLO"},
          {"name":"PONCHA SPRINGS","code":"US-CO-OPS"},
          {"name":"PUEBLO","code":"US-CO-PUB"},
          {"name":"RANGELY","code":"US-CO-RNG"},
          {"name":"RED FEATHER LAKES","code":"US-CO-RF5"},
          {"name":"REDSTONE","code":"US-CO-N9S"},
          {"name":"REDVALE","code":"US-CO-R3V"},
          {"name":"RICO","code":"US-CO-R2C"},
          {"name":"RIDGEWAY","code":"US-CO-XRY"},
          {"name":"RIDGWAY","code":"US-CO-5RW"},
          {"name":"RIFLE","code":"US-CO-RIL"},
          {"name":"RIO BLANCO","code":"US-CO-RB8"},
          {"name":"ROCKY FORD","code":"US-CO-RKF"},
          {"name":"ROGGEN","code":"US-CO-RJQ"},
          {"name":"SALIDA","code":"US-CO-SLT"},
          {"name":"SAN ACACIO","code":"US-CO-ZSI"},
          {"name":"SAN LUIS","code":"US-CO-SLU"},
          {"name":"SANFORD","code":"US-CO-S9F"},
          {"name":"SECURITY","code":"US-CO-ZSY"},
          {"name":"SEDALIA","code":"US-CO-S3D"},
          {"name":"SEVERANCE","code":"US-CO-S7V"},
          {"name":"SHERIDAN","code":"US-CO-SDV"},
          {"name":"SILT","code":"US-CO-S8I"},
          {"name":"SILVER PLUME","code":"US-CO-JVP"},
          {"name":"SILVERTHORNE","code":"US-CO-SO5"},
          {"name":"SILVERTON","code":"US-CO-IER"},
          {"name":"SLATER","code":"US-CO-XSL"},
          {"name":"SNOWMASS","code":"US-CO-SM6"},
          {"name":"SNOWMASS VILLAGE","code":"US-CO-SWM"},
          {"name":"SOMERSET","code":"US-CO-OMT"},
          {"name":"STEAMBOAT SPRINGS","code":"US-CO-SBS"},
          {"name":"STERLING","code":"US-CO-STK"},
          {"name":"SUPERIOR","code":"US-CO-9OQ"},
          {"name":"TABERNASH","code":"US-CO-TA4"},
          {"name":"TELLURIDE","code":"US-CO-QTR"},
          {"name":"THORNTON","code":"US-CO-THT"},
          {"name":"THORP","code":"US-CO-TP6"},
          {"name":"TIMNATH","code":"US-CO-TIM"},
          {"name":"TRINIDAD","code":"US-CO-TAD"},
          {"name":"VAIL","code":"US-CO-VAC"},
          {"name":"VERNON","code":"US-CO-VGO"},
          {"name":"VICTOR","code":"US-CO-VIT"},
          {"name":"VILLA GROVE","code":"US-CO-VGV"},
          {"name":"WALDEN","code":"US-CO-WA3"},
          {"name":"WALSH","code":"US-CO-WA4"},
          {"name":"WARD","code":"US-CO-D5R"},
          {"name":"WATKINS","code":"US-CO-UWK"},
          {"name":"WELDONA","code":"US-CO-W3N"},
          {"name":"WELLINGTON","code":"US-CO-W4G"},
          {"name":"WESTCLIFFE","code":"US-CO-WFF"},
          {"name":"WESTMINSTER","code":"US-CO-WME"},
          {"name":"WHEAT RIDGE","code":"US-CO-WQE"},
          {"name":"WHITEWATER","code":"US-CO-W7T"},
          {"name":"WIGGINS","code":"US-CO-WGG"},
          {"name":"WINDSOR","code":"US-CO-WSR"},
          {"name":"WINTER PARK","code":"US-CO-WIP"},
          {"name":"WOODLAND PARK","code":"US-CO-OPR"},
          {"name":"WRAY","code":"US-CO-W5R"},
          {"name":"YUMA","code":"US-CO-UM2"},
        ]
      },
      {
        name: 'Connecticut',
        code: 'US-CT',
        cities: [
          {"name":"ANDOVER","code":"US-CT-6VR"},
          {"name":"ANSONIA","code":"US-CT-AQZ"},
          {"name":"ASHFORD","code":"US-CT-A3F"},
          {"name":"AVON","code":"US-CT-AVZ"},
          {"name":"BALLOUVILLE","code":"US-CT-BZK"},
          {"name":"BALTIC","code":"US-CT-QBM"},
          {"name":"BANTAM","code":"US-CT-YBM"},
          {"name":"BARKHAMSTED","code":"US-CT-BH5"},
          {"name":"BAYVIEW","code":"US-CT-B3A"},
          {"name":"BEACON FALLS","code":"US-CT-BEK"},
          {"name":"BERLIN","code":"US-CT-BIK"},
          {"name":"BETHANY","code":"US-CT-BTW"},
          {"name":"BETHEL","code":"US-CT-BTJ"},
          {"name":"BETHLEHEM","code":"US-CT-ZA5"},
          {"name":"BLOOMFIELD","code":"US-CT-AAC"},
          {"name":"BOLTON","code":"US-CT-BL8"},
          {"name":"BOTSFORD","code":"US-CT-BOF"},
          {"name":"BOZRAH","code":"US-CT-OZH"},
          {"name":"BRANFORD","code":"US-CT-BFC"},
          {"name":"BRIDGEPORT","code":"US-CT-BDR"},
          {"name":"BRIDGEWATER","code":"US-CT-GWR"},
          {"name":"BRISTOL","code":"US-CT-BCO"},
          {"name":"BROAD BROOK","code":"US-CT-BB8"},
          {"name":"BROOKFIELD","code":"US-CT-BRK"},
          {"name":"BROOKLYN","code":"US-CT-BOC"},
          {"name":"BURLINGTON","code":"US-CT-BUU"},
          {"name":"BYRAM","code":"US-CT-BYM"},
          {"name":"CANAAN","code":"US-CT-CUM"},
          {"name":"CANTON","code":"US-CT-AJX"},
          {"name":"CENTERBROOK","code":"US-CT-CBC"},
          {"name":"CHAPLIN","code":"US-CT-6NC"},
          {"name":"CHESHIRE","code":"US-CT-CHC"},
          {"name":"CHESTER","code":"US-CT-ADJ"},
          {"name":"CLINTON","code":"US-CT-CLB"},
          {"name":"COLCHESTER","code":"US-CT-CE7"},
          {"name":"COLEBROOK","code":"US-CT-CZ9"},
          {"name":"COLLINSVILLE","code":"US-CT-775"},
          {"name":"COLUMBIA","code":"US-CT-CX2"},
          {"name":"CORNWALL","code":"US-CT-W7N"},
          {"name":"COS COB","code":"US-CT-OCB"},
          {"name":"COVENTRY","code":"US-CT-ZCJ"},
          {"name":"CROMWELL","code":"US-CT-ZCW"},
          {"name":"DANBURY","code":"US-CT-DXR"},
          {"name":"DANIELSON","code":"US-CT-DSO"},
          {"name":"DARIEN","code":"US-CT-DAQ"},
          {"name":"DAYVILLE","code":"US-CT-DLK"},
          {"name":"DEEP RIVER","code":"US-CT-DEQ"},
          {"name":"DERBY","code":"US-CT-DCT"},
          {"name":"DURHAM","code":"US-CT-DHQ"},
          {"name":"EAST BERLIN","code":"US-CT-AAV"},
          {"name":"EAST BRIDGEPORT","code":"US-CT-EBC"},
          {"name":"EAST CANAAN","code":"US-CT-5EC"},
          {"name":"EAST GRANBY","code":"US-CT-EBY"},
          {"name":"EAST HADDAM","code":"US-CT-TDM"},
          {"name":"EAST HAMPTON","code":"US-CT-EAH"},
          {"name":"EAST HARTFORD","code":"US-CT-EHT"},
          {"name":"EAST HAVEN","code":"US-CT-EHV"},
          {"name":"EAST LYME","code":"US-CT-EAY"},
          {"name":"EAST NORWALK","code":"US-CT-ENQ"},
          {"name":"EAST WINDSOR","code":"US-CT-99W"},
          {"name":"EAST WOODSTOCK","code":"US-CT-EW2"},
          {"name":"EASTFORD","code":"US-CT-EFD"},
          {"name":"EASTON","code":"US-CT-8MB"},
          {"name":"ELLINGTON","code":"US-CT-ZET"},
          {"name":"ELMWOOD","code":"US-CT-IMZ"},
          {"name":"ENFIELD","code":"US-CT-ENF"},
          {"name":"ESSEX","code":"US-CT-EEX"},
          {"name":"FABYAN","code":"US-CT-7FA"},
          {"name":"FAIRFIELD","code":"US-CT-FFI"},
          {"name":"FALLS VILLAGE","code":"US-CT-FVG"},
          {"name":"FARMINGTON","code":"US-CT-FMF"},
          {"name":"FITCHVILLE","code":"US-CT-FCH"},
          {"name":"FORESTVILLE","code":"US-CT-FOE"},
          {"name":"GALES FERRY","code":"US-CT-GSF"},
          {"name":"GAYLORDSVILLE","code":"US-CT-GYI"},
          {"name":"GEORGETOWN, FAIRFIELD","code":"US-CT-GGN"},
          {"name":"GILMAN","code":"US-CT-GIN"},
          {"name":"GLASTONBURY","code":"US-CT-GLL"},
          {"name":"GLENBROOK","code":"US-CT-GLM"},
          {"name":"GOSHEN","code":"US-CT-GOH"},
          {"name":"GRANBY","code":"US-CT-EGC"},
          {"name":"GREENWICH","code":"US-CT-GRH"},
          {"name":"GRISWOLD","code":"US-CT-G77"},
          {"name":"GROTON","code":"US-CT-ZGO"},
          {"name":"GROTON-NEW LONDON APT","code":"US-CT-GON"},
          {"name":"GUILFORD","code":"US-CT-GF3"},
          {"name":"HADDAM","code":"US-CT-DDM"},
          {"name":"HAMDEN","code":"US-CT-AEU"},
          {"name":"HARTFORD","code":"US-CT-HFD"},
          {"name":"HEBRON","code":"US-CT-QKX"},
          {"name":"HIGGANUM","code":"US-CT-HIG"},
          {"name":"HUNTINGTON","code":"US-CT-UTU"},
          {"name":"IVORYTON","code":"US-CT-IYN"},
          {"name":"JEWETT CITY","code":"US-CT-JWT"},
          {"name":"KENSINGTON","code":"US-CT-KXX"},
          {"name":"KENT","code":"US-CT-KTV"},
          {"name":"KILLINGLY","code":"US-CT-KIL"},
          {"name":"KILLINGWORTH","code":"US-CT-KNH"},
          {"name":"LAKEVILLE","code":"US-CT-LQV"},
          {"name":"LEBANON","code":"US-CT-LBO"},
          {"name":"LEDYARD CENTER","code":"US-CT-LDY"},
          {"name":"LISBON","code":"US-CT-9LS"},
          {"name":"LITCHFIELD","code":"US-CT-YCF"},
          {"name":"LYME","code":"US-CT-LYE"},
          {"name":"MADISON","code":"US-CT-MPE"},
          {"name":"MANCHESTER","code":"US-CT-MNE"},
          {"name":"MANSFIELD CENTER","code":"US-CT-MC8"},
          {"name":"MARLBOROUGH","code":"US-CT-UUG"},
          {"name":"MASHANTUCKET","code":"US-CT-UCH"},
          {"name":"MERIDEN","code":"US-CT-MRD"},
          {"name":"MIDDLEBURY","code":"US-CT-M2D"},
          {"name":"MIDDLEFIELD","code":"US-CT-XXD"},
          {"name":"MIDDLETOWN","code":"US-CT-XIN"},
          {"name":"MILFORD","code":"US-CT-MFX"},
          {"name":"MILLDALE","code":"US-CT-5MD"},
          {"name":"MONROE","code":"US-CT-MOM"},
          {"name":"MONTVILLE","code":"US-CT-MBM"},
          {"name":"MOODUS","code":"US-CT-MU2"},
          {"name":"MOOSUP","code":"US-CT-OOU"},
          {"name":"MORRIS","code":"US-CT-MR4"},
          {"name":"MYSTIC","code":"US-CT-MYC"},
          {"name":"NAUGATUCK","code":"US-CT-NGK"},
          {"name":"NEW BRITAIN","code":"US-CT-NWT"},
          {"name":"NEW CANAAN","code":"US-CT-NWC"},
          {"name":"NEW FAIRFIELD","code":"US-CT-FUU"},
          {"name":"NEW HARTFORD","code":"US-CT-NHT"},
          {"name":"NEW HAVEN","code":"US-CT-HVN"},
          {"name":"NEW LONDON","code":"US-CT-NLO"},
          {"name":"NEW LONDON-GROTON APT","code":"US-CT-GON"},
          {"name":"NEW MILFORD","code":"US-CT-NEM"},
          {"name":"NEW PRESTON","code":"US-CT-NP2"},
          {"name":"NEWINGTON","code":"US-CT-NWG"},
          {"name":"NEWTOWN","code":"US-CT-NTC"},
          {"name":"NIANTIC","code":"US-CT-NII"},
          {"name":"NOANK","code":"US-CT-NK3"},
          {"name":"NORFOLK","code":"US-CT-NFF"},
          {"name":"NORTH BRANFORD","code":"US-CT-NBC"},
          {"name":"NORTH CANAAN","code":"US-CT-N7C"},
          {"name":"NORTH CANTON","code":"US-CT-NQN"},
          {"name":"NORTH FRANKLIN","code":"US-CT-TFA"},
          {"name":"NORTH GRANBY","code":"US-CT-NBG"},
          {"name":"NORTH GROSVENOR DALE","code":"US-CT-ZGN"},
          {"name":"NORTH HAVEN","code":"US-CT-NOH"},
          {"name":"NORTH STONINGTON","code":"US-CT-NO3"},
          {"name":"NORTH WINDHAM","code":"US-CT-NW3"},
          {"name":"NORTHFORD","code":"US-CT-NT2"},
          {"name":"NORWALK","code":"US-CT-ORQ"},
          {"name":"NORWICH","code":"US-CT-NOC"},
          {"name":"OAKDALE","code":"US-CT-M8E"},
          {"name":"OAKVILLE","code":"US-CT-OKV"},
          {"name":"OLD GREENWICH","code":"US-CT-OGW"},
          {"name":"OLD LYME","code":"US-CT-OLL"},
          {"name":"OLD SAYBROOK","code":"US-CT-OSY"},
          {"name":"ORANGE","code":"US-CT-ORB"},
          {"name":"OXFORD","code":"US-CT-OXC"},
          {"name":"PAWCATUCK","code":"US-CT-PWP"},
          {"name":"PLAINFIELD","code":"US-CT-PFE"},
          {"name":"PLAINVILLE","code":"US-CT-PVI"},
          {"name":"PLANTSVILLE","code":"US-CT-P5V"},
          {"name":"PLYMOUTH","code":"US-CT-YMU"},
          {"name":"POMFRET","code":"US-CT-PFR"},
          {"name":"POMFRET CENTER","code":"US-CT-PFF"},
          {"name":"POQUONOCK","code":"US-CT-7PO"},
          {"name":"PORTLAND","code":"US-CT-PTQ"},
          {"name":"PRESTON","code":"US-CT-PVS"},
          {"name":"PROSPECT","code":"US-CT-PPU"},
          {"name":"PUTNAM","code":"US-CT-PUN"},
          {"name":"PUTNAM HEIGHTS","code":"US-CT-PM5"},
          {"name":"REDDING","code":"US-CT-RGX"},
          {"name":"REDDING RIDGE","code":"US-CT-RRG"},
          {"name":"RIDGEFIELD","code":"US-CT-RF3"},
          {"name":"RIVERSIDE","code":"US-CT-VRD"},
          {"name":"ROCKFALL","code":"US-CT-RF4"},
          {"name":"ROCKVILLE","code":"US-CT-OCV"},
          {"name":"ROCKY HILL","code":"US-CT-ROK"},
          {"name":"ROGERS","code":"US-CT-RGS"},
          {"name":"ROXBURY","code":"US-CT-RXQ"},
          {"name":"SALEM","code":"US-CT-3SE"},
          {"name":"SALISBURY","code":"US-CT-ZYS"},
          {"name":"SANDY HOOK","code":"US-CT-YHO"},
          {"name":"SAYBROOK","code":"US-CT-SYK"},
          {"name":"SEYMOUR","code":"US-CT-SZK"},
          {"name":"SHARON","code":"US-CT-S7H"},
          {"name":"SHELTON","code":"US-CT-SQR"},
          {"name":"SHERMAN","code":"US-CT-XMP"},
          {"name":"SIMSBURY","code":"US-CT-SZU"},
          {"name":"SOMERS","code":"US-CT-TSF"},
          {"name":"SOMERSVILLE","code":"US-CT-OFV"},
          {"name":"SOUTH GLASTONBURY","code":"US-CT-YSY"},
          {"name":"SOUTH KENT","code":"US-CT-SK7"},
          {"name":"SOUTH NORWALK","code":"US-CT-SON"},
          {"name":"SOUTH WINDHAM","code":"US-CT-UWH"},
          {"name":"SOUTH WINDSOR","code":"US-CT-XMM"},
          {"name":"SOUTHBURY","code":"US-CT-SBU"},
          {"name":"SOUTHINGTON","code":"US-CT-SNG"},
          {"name":"SOUTHPORT","code":"US-CT-UTP"},
          {"name":"STAFFORD","code":"US-CT-JCU"},
          {"name":"STAFFORD SPRINGS","code":"US-CT-SFC"},
          {"name":"STAMFORD","code":"US-CT-XLX"},
          {"name":"STERLING","code":"US-CT-S9G"},
          {"name":"STONINGTON","code":"US-CT-SGN"},
          {"name":"STORRS","code":"US-CT-9ST"},
          {"name":"STRATFORD","code":"US-CT-JSD"},
          {"name":"SUFFIELD","code":"US-CT-XLV"},
          {"name":"TAFTVILLE","code":"US-CT-TAF"},
          {"name":"TARIFFVILLE","code":"US-CT-FF9"},
          {"name":"TERRYVILLE","code":"US-CT-TZX"},
          {"name":"THOMASTON","code":"US-CT-THO"},
          {"name":"THOMPSON","code":"US-CT-3TH"},
          {"name":"TOLLAND","code":"US-CT-TLD"},
          {"name":"TORRINGTON","code":"US-CT-TGT"},
          {"name":"TRUMBULL","code":"US-CT-TBL"},
          {"name":"UNCASVILLE","code":"US-CT-UVL"},
          {"name":"UNIONVILLE","code":"US-CT-7UN"},
          {"name":"VERNON","code":"US-CT-VRN"},
          {"name":"VERSAILLES","code":"US-CT-VSI"},
          {"name":"VOLUNTOWN","code":"US-CT-4VC"},
          {"name":"WALLINGFORD","code":"US-CT-WFD"},
          {"name":"WAREHOUSE POINT","code":"US-CT-WPC"},
          {"name":"WARREN","code":"US-CT-WR5"},
          {"name":"WASHINGTON","code":"US-CT-KJH"},
          {"name":"WATERBURY","code":"US-CT-WAT"},
          {"name":"WATERFORD","code":"US-CT-TQF"},
          {"name":"WATERTOWN","code":"US-CT-WTT"},
          {"name":"WAUREGAN","code":"US-CT-QWR"},
          {"name":"WEST HARTFORD","code":"US-CT-WHF"},
          {"name":"WEST HAVEN","code":"US-CT-WHC"},
          {"name":"WEST MYSTIC","code":"US-CT-W9S"},
          {"name":"WEST REDDING","code":"US-CT-WRD"},
          {"name":"WESTBROOK","code":"US-CT-WZK"},
          {"name":"WESTON","code":"US-CT-WSC"},
          {"name":"WESTPORT","code":"US-CT-WPR"},
          {"name":"WETHERSFIELD","code":"US-CT-WRF"},
          {"name":"WILLIMANTIC","code":"US-CT-QWL"},
          {"name":"WILLINGTON","code":"US-CT-IGV"},
          {"name":"WILTON","code":"US-CT-QCW"},
          {"name":"WINCHESTER CENTER","code":"US-CT-WCC"},
          {"name":"WINDHAM","code":"US-CT-WMJ"},
          {"name":"WINDSOR","code":"US-CT-WND"},
          {"name":"WINDSOR LOCKS","code":"US-CT-WLC"},
          {"name":"WINDSOR LOCKS-HARTFORD APT","code":"US-CT-BDL"},
          {"name":"WINSTED","code":"US-CT-WNS"},
          {"name":"WOLCOTT","code":"US-CT-XWO"},
          {"name":"WOODBRIDGE","code":"US-CT-WOB"},
          {"name":"WOODBURY","code":"US-CT-XNO"},
          {"name":"WOODSTOCK","code":"US-CT-WDS"},
          {"name":"WOODSTOCK VALLEY","code":"US-CT-5WD"},
          {"name":"YALESVILLE","code":"US-CT-YVE"},
          {"name":"YANTIC","code":"US-CT-YNI"},
        ]
      },
      {
        name: 'Delaware',
        code: 'US-DE',
        cities: [
          {"name":"ATLAS POINT","code":"US-DE-ATP"},
          {"name":"BEAR","code":"US-DE-AGC"},
          {"name":"BELLEFONTE","code":"US-DE-B3F"},
          {"name":"BETHANY BEACH","code":"US-DE-BBA"},
          {"name":"BIG STONE","code":"US-DE-IGS"},
          {"name":"BRIDGEVILLE","code":"US-DE-XZX"},
          {"name":"CAMDEN","code":"US-DE-XAE"},
          {"name":"CAPE HENLOPEN","code":"US-DE-C65"},
          {"name":"CHESWOLD","code":"US-DE-CHW"},
          {"name":"CHRISTIANA","code":"US-DE-IIZ"},
          {"name":"CLAYMONT","code":"US-DE-CLA"},
          {"name":"CLAYTON","code":"US-DE-CQY"},
          {"name":"DAGSBORO","code":"US-DE-D5W"},
          {"name":"DELAWARE CITY","code":"US-DE-DCI"},
          {"name":"DELMAR","code":"US-DE-DM3"},
          {"name":"DOVER","code":"US-DE-XEZ"},
          {"name":"DOVER-CHESWOLD","code":"US-DE-DOV"},
          {"name":"EDGEMOOR","code":"US-DE-EGU"},
          {"name":"ELSMERE","code":"US-DE-EM2"},
          {"name":"FELTON","code":"US-DE-F3T"},
          {"name":"FENWICK ISLAND","code":"US-DE-FEF"},
          {"name":"FRANKFORD","code":"US-DE-FF3"},
          {"name":"FREDERICA","code":"US-DE-ZDH"},
          {"name":"GEORGETOWN","code":"US-DE-GED"},
          {"name":"GLASGOW","code":"US-DE-GGW"},
          {"name":"GREENVILLE","code":"US-DE-GMI"},
          {"name":"GREENWOOD","code":"US-DE-GKO"},
          {"name":"HARBESON","code":"US-DE-HBD"},
          {"name":"HARRINGTON","code":"US-DE-HRR"},
          {"name":"HARTLY","code":"US-DE-HA8"},
          {"name":"HOCKESSIN","code":"US-DE-HCS"},
          {"name":"KIRKWOOD","code":"US-DE-IWJ"},
          {"name":"LANCASHIRE","code":"US-DE-IEN"},
          {"name":"LAUREL","code":"US-DE-URE"},
          {"name":"LEWES","code":"US-DE-LWP"},
          {"name":"MARSHALLTON","code":"US-DE-SL7"},
          {"name":"MIDDLETOWN","code":"US-DE-MIU"},
          {"name":"MILFORD","code":"US-DE-MEQ"},
          {"name":"MILLSBORO","code":"US-DE-MBD"},
          {"name":"MILTON","code":"US-DE-MLW"},
          {"name":"NEW CASTLE","code":"US-DE-NCD"},
          {"name":"NEWARK","code":"US-DE-NWK"},
          {"name":"NEWPORT","code":"US-DE-NPD"},
          {"name":"OCEAN VIEW","code":"US-DE-OCX"},
          {"name":"ODESSA","code":"US-DE-ZGY"},
          {"name":"PORT PENN","code":"US-DE-P5P"},
          {"name":"REHOBOTH BEACH","code":"US-DE-REH"},
          {"name":"ROGERS HAVEN","code":"US-DE-RH2"},
          {"name":"SEAFORD","code":"US-DE-SFE"},
          {"name":"SELBYVILLE","code":"US-DE-YVD"},
          {"name":"SLAUGHTER BEACH","code":"US-DE-G9H"},
          {"name":"SMYRNA","code":"US-DE-SDK"},
          {"name":"STANTON","code":"US-DE-S8D"},
          {"name":"TALLEYVILLE","code":"US-DE-TV3"},
          {"name":"TOWNSEND","code":"US-DE-TXW"},
          {"name":"WILMINGTON","code":"US-DE-ILG"},
          {"name":"WINTERTHUR","code":"US-DE-WRP"},
          {"name":"WOODSIDE","code":"US-DE-WD5"},
          {"name":"WYOMING","code":"US-DE-WVX"},
          {"name":"YORKLYN","code":"US-DE-YRL"},
        ]
      },
      {
        name: 'Florida',
        code: 'US-FL',
        cities: [
          {"name":"ALACHUA","code":"US-FL-AHU"},
          {"name":"ALTAMONTE SPRINGS","code":"US-FL-ASP"},
          {"name":"ALTHA","code":"US-FL-A7H"},
          {"name":"ALTOONA","code":"US-FL-AZQ"},
          {"name":"ALVA","code":"US-FL-ALF"},
          {"name":"ALYS BEACH","code":"US-FL-A5S"},
          {"name":"AMELIA CITY","code":"US-FL-ALC"},
          {"name":"AMELIA ISLAND","code":"US-FL-AD6"},
          {"name":"ANNA MARIA","code":"US-FL-8NN"},
          {"name":"ANTHONY","code":"US-FL-AT7"},
          {"name":"APALACHICOLA","code":"US-FL-AAF"},
          {"name":"APOLLO BEACH","code":"US-FL-ZAB"},
          {"name":"APOPKA","code":"US-FL-AOP"},
          {"name":"ARCADIA","code":"US-FL-ARI"},
          {"name":"ASTATULA","code":"US-FL-AUA"},
          {"name":"ATLANTIC BEACH","code":"US-FL-QAT"},
          {"name":"AUBURNDALE","code":"US-FL-AUD"},
          {"name":"AVENTURA","code":"US-FL-AVU"},
          {"name":"AVON PARK","code":"US-FL-AVO"},
          {"name":"AZALEA PARK","code":"US-FL-ZKK"},
          {"name":"BAKER","code":"US-FL-BKG"},
          {"name":"BAL HARBOUR","code":"US-FL-FL8"},
          {"name":"BALDWIN","code":"US-FL-BAX"},
          {"name":"BARTOW","code":"US-FL-BOW"},
          {"name":"BAY HARBOR ISLANDS","code":"US-FL-BH9"},
          {"name":"BAY LAKE","code":"US-FL-BY9"},
          {"name":"BAY PINES","code":"US-FL-8AY"},
          {"name":"BAYONET POINT","code":"US-FL-YBP"},
          {"name":"BEAN CITY","code":"US-FL-BAP"},
          {"name":"BELL","code":"US-FL-5BF"},
          {"name":"BELLE GLADE","code":"US-FL-BCF"},
          {"name":"BELLEVIEW","code":"US-FL-EIW"},
          {"name":"BELMONT","code":"US-FL-FQJ"},
          {"name":"BEVERLY HILLS","code":"US-FL-YHL"},
          {"name":"BIG PINE KEY","code":"US-FL-BPY"},
          {"name":"BISCAYNE PARK","code":"US-FL-5BP"},
          {"name":"BLOUNT ISLAND","code":"US-FL-BI8"},
          {"name":"BLOUNTSTOWN","code":"US-FL-UTW"},
          {"name":"BOCA GRANDE","code":"US-FL-BCG"},
          {"name":"BOCA RATON","code":"US-FL-BCT"},
          {"name":"BOKEELIA","code":"US-FL-BK5"},
          {"name":"BONIFAY","code":"US-FL-YBY"},
          {"name":"BONITA SPRINGS","code":"US-FL-BOP"},
          {"name":"BOSTWICK","code":"US-FL-B8W"},
          {"name":"BOWLING GREEN","code":"US-FL-IEX"},
          {"name":"BOYNTON BEACH","code":"US-FL-BYB"},
          {"name":"BRADENTON","code":"US-FL-BBI"},
          {"name":"BRADENTON BEACH","code":"US-FL-RDT"},
          {"name":"BRADENTON-SARASOTA APT","code":"US-FL-SRQ"},
          {"name":"BRADLEY","code":"US-FL-DYZ"},
          {"name":"BRANDON","code":"US-FL-BXX"},
          {"name":"BRANFORD","code":"US-FL-BJ4"},
          {"name":"BRISTOL","code":"US-FL-BT5"},
          {"name":"BROOKSVILLE","code":"US-FL-BKJ"},
          {"name":"BROWNSVILLE","code":"US-FL-QBR"},
          {"name":"BUNNELL","code":"US-FL-UEL"},
          {"name":"BUSHNELL","code":"US-FL-BJL"},
          {"name":"BUSHNELL","code":"US-FL-UHN"},
          {"name":"CALLAHAN","code":"US-FL-CH6"},
          {"name":"CALLAWAY","code":"US-FL-CW6"},
          {"name":"CAMPBELLTON","code":"US-FL-TCP"},
          {"name":"CANAL POINT","code":"US-FL-C2P"},
          {"name":"CANDLER","code":"US-FL-FCD"},
          {"name":"CANTONMENT","code":"US-FL-CQF"},
          {"name":"CAPE CANAVERAL","code":"US-FL-CPV"},
          {"name":"CAPE CORAL","code":"US-FL-CC2"},
          {"name":"CAPTIVA","code":"US-FL-4CP"},
          {"name":"CAROL CITY","code":"US-FL-CI3"},
          {"name":"CARRABELLE","code":"US-FL-CRB"},
          {"name":"CASSELBERRY","code":"US-FL-CIB"},
          {"name":"CEDAR KEY","code":"US-FL-CDK"},
          {"name":"CELEBRATION","code":"US-FL-CN5"},
          {"name":"CENTER HILL","code":"US-FL-ZRH"},
          {"name":"CHAMPIONS GATE","code":"US-FL-HZG"},
          {"name":"CHATTAHOOCHEE","code":"US-FL-CTE"},
          {"name":"CHIEFLAND","code":"US-FL-CF8"},
          {"name":"CHIPLEY","code":"US-FL-IPY"},
          {"name":"CITRA","code":"US-FL-IRA"},
          {"name":"CITRUS SPRINGS","code":"US-FL-C34"},
          {"name":"CLAIR MEL CITY","code":"US-FL-IMF"},
          {"name":"CLEARWATER","code":"US-FL-CLW"},
          {"name":"CLEARWATER BEACH","code":"US-FL-VWA"},
          {"name":"CLERMONT","code":"US-FL-CFW"},
          {"name":"CLEWISTON","code":"US-FL-XDX"},
          {"name":"COCOA","code":"US-FL-COI"},
          {"name":"COCOA BEACH","code":"US-FL-C2B"},
          {"name":"COCONUT CREEK","code":"US-FL-CCU"},
          {"name":"COCONUT GROVE","code":"US-FL-NVV"},
          {"name":"COOPER CITY","code":"US-FL-CPW"},
          {"name":"CORAL GABLES","code":"US-FL-CGB"},
          {"name":"CORAL SPRINGS","code":"US-FL-CPJ"},
          {"name":"CORAL WAY VILLAGE/MIAMI","code":"US-FL-CWV"},
          {"name":"CORTEZ","code":"US-FL-C5Z"},
          {"name":"COTTONDALE","code":"US-FL-CTM"},
          {"name":"CRAWFORDVILLE","code":"US-FL-RFV"},
          {"name":"CRESCENT CITY","code":"US-FL-TCJ"},
          {"name":"CRESTVIEW","code":"US-FL-CEW"},
          {"name":"CROSS CITY","code":"US-FL-CTY"},
          {"name":"CRYSTAL BEACH","code":"US-FL-5KB"},
          {"name":"CRYSTAL RIVER","code":"US-FL-CJF"},
          {"name":"CUDJOE KEY","code":"US-FL-9CY"},
          {"name":"CUTLER BAY","code":"US-FL-C6B"},
          {"name":"CUTLER RIDGE","code":"US-FL-CI4"},
          {"name":"CYPRESS","code":"US-FL-4CY"},
          {"name":"DADE CITY","code":"US-FL-DDB"},
          {"name":"DANIA BEACH","code":"US-FL-DAD"},
          {"name":"DAVENPORT","code":"US-FL-DVP"},
          {"name":"DAVIE","code":"US-FL-DVF"},
          {"name":"DAYTONA BEACH","code":"US-FL-DAB"},
          {"name":"DE FUNIAK SPRINGS","code":"US-FL-DFN"},
          {"name":"DE FUNIAK SPRINGS","code":"US-FL-DU5"},
          {"name":"DE LAND","code":"US-FL-DLD"},
          {"name":"DE LEON SPRINGS","code":"US-FL-DLF"},
          {"name":"DEBARY","code":"US-FL-DB2"},
          {"name":"DEERFIELD BEACH","code":"US-FL-DEE"},
          {"name":"DEERPARK","code":"US-FL-DPA"},
          {"name":"DELAND","code":"US-FL-DD5"},
          {"name":"DELRAY BEACH","code":"US-FL-DEY"},
          {"name":"DELTONA","code":"US-FL-DLT"},
          {"name":"DENAUD","code":"US-FL-D4D"},
          {"name":"DESTIN","code":"US-FL-DSI"},
          {"name":"DORAL","code":"US-FL-PDQ"},
          {"name":"DOVER","code":"US-FL-DOR"},
          {"name":"DUNDEE","code":"US-FL-DYU"},
          {"name":"DUNEDIN","code":"US-FL-DNB"},
          {"name":"DUNNELLON","code":"US-FL-DFU"},
          {"name":"DURANT","code":"US-FL-D3F"},
          {"name":"EAGLE LAKE","code":"US-FL-5EL"},
          {"name":"EASTPOINT","code":"US-FL-VVT"},
          {"name":"EATON PARK","code":"US-FL-EPK"},
          {"name":"EBRO","code":"US-FL-EB5"},
          {"name":"EDGAR","code":"US-FL-EG4"},
          {"name":"EDGEWATER","code":"US-FL-EJW"},
          {"name":"EGLIN AIR FORCE BASE","code":"US-FL-EF2"},
          {"name":"ELKTON","code":"US-FL-YEK"},
          {"name":"ELLENTON","code":"US-FL-ENJ"},
          {"name":"ELOISE","code":"US-FL-EE6"},
          {"name":"ENGLEWOOD","code":"US-FL-XEW"},
          {"name":"ESTERO","code":"US-FL-ESG"},
          {"name":"EUSTIS","code":"US-FL-EUS"},
          {"name":"EVERGLADES CITY","code":"US-FL-EFG"},
          {"name":"FELDA","code":"US-FL-FA2"},
          {"name":"FELLSMERE","code":"US-FL-FEL"},
          {"name":"FERNANDINA BEACH","code":"US-FL-FEB"},
          {"name":"FISHER ISLAND, MIAMI","code":"US-FL-ZFI"},
          {"name":"FLAGLER BEACH","code":"US-FL-FBF"},
          {"name":"FLEMING ISLAND","code":"US-FL-FI7"},
          {"name":"FLEMING ISLAND","code":"US-FL-FL5"},
          {"name":"FLORAL CITY","code":"US-FL-F22"},
          {"name":"FLORIDA CITY","code":"US-FL-YFA"},
          {"name":"FOLEY","code":"US-FL-FLS"},
          {"name":"FORT DRUM","code":"US-FL-FR8"},
          {"name":"FORT JEFFERSON","code":"US-FL-RBN"},
          {"name":"FORT LAUDERDALE","code":"US-FL-FLL"},
          {"name":"FORT MCCOY","code":"US-FL-FMA"},
          {"name":"FORT MEADE","code":"US-FL-ZDF"},
          {"name":"FORT MYERS","code":"US-FL-FMY"},
          {"name":"FORT MYERS BEACH","code":"US-FL-FMX"},
          {"name":"FORT PIERCE","code":"US-FL-FPR"},
          {"name":"FORT WALTON BEACH","code":"US-FL-FWB"},
          {"name":"FORT WHITE","code":"US-FL-FW4"},
          {"name":"FREEPORT","code":"US-FL-XAS"},
          {"name":"FROSTPROOF","code":"US-FL-FRU"},
          {"name":"FRUITLAND PARK","code":"US-FL-F2P"},
          {"name":"GAINESVILLE","code":"US-FL-GNV"},
          {"name":"GENEVA","code":"US-FL-GXA"},
          {"name":"GEORGETOWN","code":"US-FL-GEQ"},
          {"name":"GIBSONTON","code":"US-FL-AEF"},
          {"name":"GOLDEN BEACH","code":"US-FL-G3B"},
          {"name":"GONZALEZ","code":"US-FL-GZL"},
          {"name":"GOULDS","code":"US-FL-GOU"},
          {"name":"GRACEVILLE","code":"US-FL-GCV"},
          {"name":"GRACEVILLE","code":"US-FL-GRC"},
          {"name":"GRAND ISLAND","code":"US-FL-4QM"},
          {"name":"GREEN COVE SPRINGS","code":"US-FL-GCS"},
          {"name":"GREENACRES","code":"US-FL-GCR"},
          {"name":"GREENSBORO","code":"US-FL-G6B"},
          {"name":"GREENVILLE","code":"US-FL-FT5"},
          {"name":"GREENWOOD","code":"US-FL-GWD"},
          {"name":"GROVELAND","code":"US-FL-GVD"},
          {"name":"GULF BREEZE","code":"US-FL-GBZ"},
          {"name":"GULFPORT","code":"US-FL-GFP"},
          {"name":"HAINES CITY","code":"US-FL-HCF"},
          {"name":"HALLANDALE","code":"US-FL-HAL"},
          {"name":"HAMPTON","code":"US-FL-HPF"},
          {"name":"HASTINGS","code":"US-FL-TGS"},
          {"name":"HAVANA","code":"US-FL-HV3"},
          {"name":"HAWTHORNE","code":"US-FL-XVB"},
          {"name":"HEATHROW","code":"US-FL-HHW"},
          {"name":"HERNANDO","code":"US-FL-HEJ"},
          {"name":"HIALEAH","code":"US-FL-HLF"},
          {"name":"HIALEAH GARDENS","code":"US-FL-HGF"},
          {"name":"HIGH SPRINGS","code":"US-FL-HSS"},
          {"name":"HIGHLAND CITY","code":"US-FL-HIC"},
          {"name":"HILLIARD","code":"US-FL-HDF"},
          {"name":"HILLSBORO BEACH","code":"US-FL-HHJ"},
          {"name":"HOBE SOUND","code":"US-FL-HBE"},
          {"name":"HOLIDAY","code":"US-FL-YHF"},
          {"name":"HOLLISTER","code":"US-FL-HXX"},
          {"name":"HOLLY HILL","code":"US-FL-HHL"},
          {"name":"HOLLYWOOD","code":"US-FL-HWO"},
          {"name":"HOLT","code":"US-FL-HT7"},
          {"name":"HOMESTEAD","code":"US-FL-HST"},
          {"name":"HOMOSASSA","code":"US-FL-HMF"},
          {"name":"HOSFORD","code":"US-FL-HS8"},
          {"name":"HOUSTON","code":"US-FL-ZD5"},
          {"name":"HOWEY IN THE HILLS","code":"US-FL-HWE"},
          {"name":"HUDSON","code":"US-FL-HSF"},
          {"name":"HUNTERS CREEK","code":"US-FL-HC4"},
          {"name":"HURLBURT FIELD","code":"US-FL-HF8"},
          {"name":"HYPOLUXO","code":"US-FL-HYP"},
          {"name":"IMMOKALEE","code":"US-FL-IMM"},
          {"name":"INDIALANTIC","code":"US-FL-YIL"},
          {"name":"INDIAN HARBOUR BEACH","code":"US-FL-ZD8"},
          {"name":"INDIAN LAKE ESTATES","code":"US-FL-QVI"},
          {"name":"INDIAN RIVER CITY","code":"US-FL-IR2"},
          {"name":"INDIAN ROCKS BEACH","code":"US-FL-8RB"},
          {"name":"INDIANOLA","code":"US-FL-INI"},
          {"name":"INDIANTOWN","code":"US-FL-IDT"},
          {"name":"INGLIS","code":"US-FL-IG2"},
          {"name":"INTERCESSION CITY","code":"US-FL-IEC"},
          {"name":"INVERNESS","code":"US-FL-VRE"},
          {"name":"ISLAMORADA","code":"US-FL-ISR"},
          {"name":"JACKSONVILLE","code":"US-FL-JAX"},
          {"name":"JACKSONVILLE BEACH","code":"US-FL-JVB"},
          {"name":"JASPER","code":"US-FL-JPZ"},
          {"name":"JAY","code":"US-FL-JAF"},
          {"name":"JENNINGS","code":"US-FL-JE3"},
          {"name":"JENSEN BEACH","code":"US-FL-JBC"},
          {"name":"JOHN F KENNEDY SPACE CENTER/CAPE CANAVERAL","code":"US-FL-KDC"},
          {"name":"JUNO BEACH","code":"US-FL-JB2"},
          {"name":"JUPITER","code":"US-FL-JPT"},
          {"name":"KATHLEEN","code":"US-FL-5EE"},
          {"name":"KENANSVILLE","code":"US-FL-KV6"},
          {"name":"KENDALL","code":"US-FL-KEL"},
          {"name":"KEY BISCAYNE","code":"US-FL-KBS"},
          {"name":"KEY LARGO","code":"US-FL-KYL"},
          {"name":"KEY WEST","code":"US-FL-EYW"},
          {"name":"KINGS BAY","code":"US-FL-KBA"},
          {"name":"KISSIMMEE","code":"US-FL-ISM"},
          {"name":"LA BELLE","code":"US-FL-LFL"},
          {"name":"LA CROSSE","code":"US-FL-XX4"},
          {"name":"LADY LAKE","code":"US-FL-LAK"},
          {"name":"LAKE ALFRED","code":"US-FL-LKA"},
          {"name":"LAKE BUENA VISTA","code":"US-FL-LBW"},
          {"name":"LAKE CITY","code":"US-FL-LKB"},
          {"name":"LAKE GARFIELD","code":"US-FL-ZLG"},
          {"name":"LAKE HAMILTON","code":"US-FL-LHM"},
          {"name":"LAKE HARBOR","code":"US-FL-LKH"},
          {"name":"LAKE MARY","code":"US-FL-LKM"},
          {"name":"LAKE MONROE","code":"US-FL-5MR"},
          {"name":"LAKE PANASOFFKEE","code":"US-FL-DKP"},
          {"name":"LAKE PARK","code":"US-FL-LPQ"},
          {"name":"LAKE PLACID","code":"US-FL-LPF"},
          {"name":"LAKE SUZY","code":"US-FL-5LS"},
          {"name":"LAKE WALES","code":"US-FL-LWA"},
          {"name":"LAKE WORTH","code":"US-FL-LWH"},
          {"name":"LAKELAND","code":"US-FL-LAL"},
          {"name":"LAKEVIEW","code":"US-FL-LWU"},
          {"name":"LAKEWOOD","code":"US-FL-L3K"},
          {"name":"LAKEWOOD RANCH","code":"US-FL-IKW"},
          {"name":"LAND O'LAKES","code":"US-FL-KAJ"},
          {"name":"LANTANA","code":"US-FL-LNT"},
          {"name":"LARGO","code":"US-FL-XHV"},
          {"name":"LAUDERDALE BY THE SEA","code":"US-FL-5SL"},
          {"name":"LAUDERDALE LAKES","code":"US-FL-ZF3"},
          {"name":"LAUDERHILL","code":"US-FL-LAD"},
          {"name":"LAUREL HILL","code":"US-FL-TMP139113"},
          {"name":"LECANTO","code":"US-FL-LTQ"},
          {"name":"LEE","code":"US-FL-QLE"},
          {"name":"LEESBURG","code":"US-FL-LEE"},
          {"name":"LEHIGH ACRES","code":"US-FL-LEF"},
          {"name":"LEMON CITY","code":"US-FL-L9C"},
          {"name":"LIGHTHOUSE POINT","code":"US-FL-L2P"},
          {"name":"LITHIA","code":"US-FL-ZLH"},
          {"name":"LITTLE TORCH KEY","code":"US-FL-5LK"},
          {"name":"LIVE OAK","code":"US-FL-LOQ"},
          {"name":"LONGBOAT KEY","code":"US-FL-L9K"},
          {"name":"LONGWOOD","code":"US-FL-LGW"},
          {"name":"LORIDA","code":"US-FL-L6D"},
          {"name":"LOXAHATCHEE","code":"US-FL-LXC"},
          {"name":"LUTZ","code":"US-FL-LTZ"},
          {"name":"LYNN HAVEN","code":"US-FL-LYM"},
          {"name":"MACCLENNY","code":"US-FL-MQ5"},
          {"name":"MACDILL AFB","code":"US-FL-AF3"},
          {"name":"MADEIRA BEACH","code":"US-FL-EIF"},
          {"name":"MADISON","code":"US-FL-DIC"},
          {"name":"MAITLAND","code":"US-FL-ML3"},
          {"name":"MALABAR","code":"US-FL-UMJ"},
          {"name":"MANALAPAN","code":"US-FL-MQN"},
          {"name":"MANATEE","code":"US-FL-MEE"},
          {"name":"MANDARIN","code":"US-FL-NDR"},
          {"name":"MARATHON","code":"US-FL-MTH"},
          {"name":"MARCO ISLAND","code":"US-FL-MRK"},
          {"name":"MARGATE","code":"US-FL-MGT"},
          {"name":"MARIANNA","code":"US-FL-MNF"},
          {"name":"MARIETTA","code":"US-FL-IET"},
          {"name":"MARY ESTHER","code":"US-FL-MYT"},
          {"name":"MASARYKTOWN","code":"US-FL-MSA"},
          {"name":"MASCOTTE","code":"US-FL-MO5"},
          {"name":"MAYO","code":"US-FL-MAA"},
          {"name":"MAYPORT","code":"US-FL-NRB"},
          {"name":"MCALPIN","code":"US-FL-LPN"},
          {"name":"MEDLEY","code":"US-FL-MDY"},
          {"name":"MELBOURNE","code":"US-FL-MLB"},
          {"name":"MELBOURNE BEACH","code":"US-FL-MBE"},
          {"name":"MEMPHIS","code":"US-FL-ZGD"},
          {"name":"MERRITT ISLAND","code":"US-FL-MBF"},
          {"name":"MEXICO BEACH","code":"US-FL-ZGJ"},
          {"name":"MIAMI","code":"US-FL-MIA"},
          {"name":"MIAMI BEACH","code":"US-FL-IYH"},
          {"name":"MIAMI DOWNTOWN HELIPORT AIRPORT","code":"US-FL-JDM"},
          {"name":"MIAMI GARDENS","code":"US-FL-ZMG"},
          {"name":"MIAMI LAKES","code":"US-FL-MMK"},
          {"name":"MIAMI RIVER","code":"US-FL-KKV"},
          {"name":"MIAMI SHORES","code":"US-FL-M4I"},
          {"name":"MIAMI SPRINGS","code":"US-FL-MIZ"},
          {"name":"MICANOPY","code":"US-FL-ICA"},
          {"name":"MIDDLEBURG","code":"US-FL-MB8"},
          {"name":"MIDWAY","code":"US-FL-MYF"},
          {"name":"MILES CITY","code":"US-FL-YMI"},
          {"name":"MILTON","code":"US-FL-NSE"},
          {"name":"MIMS","code":"US-FL-MS6"},
          {"name":"MINNEOLA","code":"US-FL-M9N"},
          {"name":"MIRAMAR","code":"US-FL-MMR"},
          {"name":"MIRAMAR BEACH","code":"US-FL-IBC"},
          {"name":"MONTICELLO","code":"US-FL-VMQ"},
          {"name":"MOORE HAVEN","code":"US-FL-QMF"},
          {"name":"MORRISTON","code":"US-FL-6MT"},
          {"name":"MOUNT DORA","code":"US-FL-MUD"},
          {"name":"MOUNT PLEASANT","code":"US-FL-MPY"},
          {"name":"MULBERRY","code":"US-FL-MUF"},
          {"name":"MYAKKA CITY","code":"US-FL-YKC"},
          {"name":"MYRTLE GROVE","code":"US-FL-GY7"},
          {"name":"NAPLES","code":"US-FL-APF"},
          {"name":"NARANJA","code":"US-FL-NJ3"},
          {"name":"NAS PENSACOLA","code":"US-FL-P2A"},
          {"name":"NATIONAL GARDENS","code":"US-FL-NTL"},
          {"name":"NAVARRE","code":"US-FL-NVA"},
          {"name":"NEPTUNE BEACH","code":"US-FL-NPB"},
          {"name":"NEW PORT RICHEY","code":"US-FL-NRH"},
          {"name":"NEW SMYRNA BEACH","code":"US-FL-NSB"},
          {"name":"NEWBERRY","code":"US-FL-NBF"},
          {"name":"NICEVILLE","code":"US-FL-NIV"},
          {"name":"NOKOMIS","code":"US-FL-NQM"},
          {"name":"NORTH BAY VILLAGE","code":"US-FL-N99"},
          {"name":"NORTH FORT MYERS","code":"US-FL-N8F"},
          {"name":"NORTH LAUDERDALE","code":"US-FL-RW4"},
          {"name":"NORTH MIAMI","code":"US-FL-QNM"},
          {"name":"NORTH MIAMI BEACH","code":"US-FL-ZGV"},
          {"name":"NORTH PALM BEACH","code":"US-FL-PBN"},
          {"name":"NORTH PORT","code":"US-FL-NHF"},
          {"name":"O'BRIEN","code":"US-FL-OIN"},
          {"name":"OAK HILL","code":"US-FL-OQH"},
          {"name":"OAKLAND","code":"US-FL-OQA"},
          {"name":"OAKLAND PARK, BROWARD","code":"US-FL-OP2"},
          {"name":"OCALA","code":"US-FL-OCF"},
          {"name":"OCEAN REEF","code":"US-FL-OCA"},
          {"name":"OCEANWAY","code":"US-FL-ONW"},
          {"name":"OCHOPEE","code":"US-FL-OC8"},
          {"name":"OCOEE","code":"US-FL-OCM"},
          {"name":"ODESSA","code":"US-FL-ODF"},
          {"name":"OKAHUMPKA","code":"US-FL-OHP"},
          {"name":"OKEECHOBEE","code":"US-FL-OKB"},
          {"name":"OKEELANTA","code":"US-FL-OLT"},
          {"name":"OKLAWAHA","code":"US-FL-OKJ"},
          {"name":"OLDSMAR","code":"US-FL-ABA"},
          {"name":"OLUSTEE","code":"US-FL-OEJ"},
          {"name":"ONA","code":"US-FL-ON3"},
          {"name":"ONECO","code":"US-FL-ONE"},
          {"name":"OPA LOCKA","code":"US-FL-OPK"},
          {"name":"ORANGE","code":"US-FL-OGJ"},
          {"name":"ORANGE CITY","code":"US-FL-OGC"},
          {"name":"ORANGE PARK","code":"US-FL-ORP"},
          {"name":"ORLANDO","code":"US-FL-ORL"},
          {"name":"ORMOND BEACH","code":"US-FL-ODB"},
          {"name":"OSTEEN","code":"US-FL-OE2"},
          {"name":"OVIEDO","code":"US-FL-ZOD"},
          {"name":"OXFORD","code":"US-FL-OFD"},
          {"name":"PACE","code":"US-FL-PCZ"},
          {"name":"PAHOKEE","code":"US-FL-PHK"},
          {"name":"PAISLEY","code":"US-FL-PY6"},
          {"name":"PALATKA","code":"US-FL-PLT"},
          {"name":"PALM BAY","code":"US-FL-PBY"},
          {"name":"PALM BEACH","code":"US-FL-PAB"},
          {"name":"PALM BEACH GARDENS","code":"US-FL-PMG"},
          {"name":"PALM CITY","code":"US-FL-PMI"},
          {"name":"PALM COAST","code":"US-FL-PLM"},
          {"name":"PALM HARBOR","code":"US-FL-XKI"},
          {"name":"PALM SPRINGS","code":"US-FL-P2S"},
          {"name":"PALMDALE","code":"US-FL-ZPF"},
          {"name":"PALMETTO","code":"US-FL-PMT"},
          {"name":"PALMETTO BAY","code":"US-FL-P64"},
          {"name":"PANAMA CITY","code":"US-FL-PFN"},
          {"name":"PANAMA CITY BEACH","code":"US-FL-ZQV"},
          {"name":"PARISH","code":"US-FL-P3H"},
          {"name":"PARKLAND","code":"US-FL-P7K"},
          {"name":"PARRISH","code":"US-FL-8RW"},
          {"name":"PAXTON","code":"US-FL-PX9"},
          {"name":"PEMBROKE","code":"US-FL-PBD"},
          {"name":"PEMBROKE PARK","code":"US-FL-ZPB"},
          {"name":"PEMBROKE PINES","code":"US-FL-P2P"},
          {"name":"PENSACOLA","code":"US-FL-PNS"},
          {"name":"PENSACOLA BEACH","code":"US-FL-PB4"},
          {"name":"PERRINE","code":"US-FL-PRI"},
          {"name":"PERRY","code":"US-FL-FPY"},
          {"name":"PIERSON","code":"US-FL-PIF"},
          {"name":"PINELAND","code":"US-FL-PLN"},
          {"name":"PINELLAS PARK","code":"US-FL-PPB"},
          {"name":"PLACIDA","code":"US-FL-EUU"},
          {"name":"PLANT CITY","code":"US-FL-PCI"},
          {"name":"PLANTATION","code":"US-FL-PL9"},
          {"name":"PLANTATION","code":"US-FL-YIT"},
          {"name":"PLYMOUTH","code":"US-FL-PMF"},
          {"name":"POINCIANA","code":"US-FL-PFL"},
          {"name":"POLK CITY","code":"US-FL-P3K"},
          {"name":"POMPANO BEACH","code":"US-FL-PPM"},
          {"name":"PONCE DE LEON","code":"US-FL-4NM"},
          {"name":"PONTE VEDRA","code":"US-FL-QPV"},
          {"name":"PONTE VEDRA BEACH","code":"US-FL-P4V"},
          {"name":"PORT CANAVERAL","code":"US-FL-PCV"},
          {"name":"PORT CHARLOTTE","code":"US-FL-PCH"},
          {"name":"PORT EVERGLADES","code":"US-FL-PEF"},
          {"name":"PORT LAUDANIA","code":"US-FL-JDT"},
          {"name":"PORT MANATEE","code":"US-FL-PME"},
          {"name":"PORT OF PALM BEACH","code":"US-FL-PBC"},
          {"name":"PORT ORANGE","code":"US-FL-PO9"},
          {"name":"PORT RICHEY","code":"US-FL-PY9"},
          {"name":"PORT SAINT JOE","code":"US-FL-PSJ"},
          {"name":"PORT SAINT LUCIE","code":"US-FL-PZL"},
          {"name":"PORT TAMPA","code":"US-FL-POT"},
          {"name":"PRINCETON","code":"US-FL-XKU"},
          {"name":"PUNTA GORDA","code":"US-FL-PGD"},
          {"name":"QUINCY","code":"US-FL-QCY"},
          {"name":"RAIFORD","code":"US-FL-RAU"},
          {"name":"REDDICK","code":"US-FL-REB"},
          {"name":"REUNION","code":"US-FL-RUJ"},
          {"name":"RIVERA BEACH","code":"US-FL-RR8"},
          {"name":"RIVERVIEW","code":"US-FL-RRW"},
          {"name":"RIVIERA BEACH","code":"US-FL-RBC"},
          {"name":"ROCKLEDGE","code":"US-FL-RKE"},
          {"name":"ROSEMARY BEACH","code":"US-FL-YFM"},
          {"name":"ROTUNDA","code":"US-FL-RTD"},
          {"name":"ROYAL PALM BEACH","code":"US-FL-FPB"},
          {"name":"RUBONIA","code":"US-FL-RUB"},
          {"name":"RUSKIN","code":"US-FL-RKN"},
          {"name":"SAFETY HARBOR","code":"US-FL-SFP"},
          {"name":"SAINT AUGUSTINE","code":"US-FL-UST"},
          {"name":"SAINT CLOUD","code":"US-FL-SOC"},
          {"name":"SAINT JAMES CITY","code":"US-FL-JSY"},
          {"name":"SAINT JOHNS","code":"US-FL-JO4"},
          {"name":"SAINT JOSEPH","code":"US-FL-SJF"},
          {"name":"SAINT LUCIE","code":"US-FL-SL2"},
          {"name":"SAINT MARKS","code":"US-FL-AIT"},
          {"name":"SAINT PETE BEACH","code":"US-FL-6TT"},
          {"name":"SAINT PETERSBURG","code":"US-FL-PIE"},
          {"name":"SAN ANTONIO","code":"US-FL-AT8"},
          {"name":"SAND LAKE","code":"US-FL-QDK"},
          {"name":"SANDERSON","code":"US-FL-SDI"},
          {"name":"SANFORD","code":"US-FL-SFB"},
          {"name":"SANIBEL","code":"US-FL-SBP"},
          {"name":"SANTA ROSA BEACH","code":"US-FL-JQJ"},
          {"name":"SARASOTA","code":"US-FL-ZOR"},
          {"name":"SARASOTA-BRADENTON APT","code":"US-FL-SRQ"},
          {"name":"SATELLITE BEACH","code":"US-FL-LTB"},
          {"name":"SEBASTIAN","code":"US-FL-SZG"},
          {"name":"SEBRING","code":"US-FL-SEF"},
          {"name":"SEFFNER","code":"US-FL-EFF"},
          {"name":"SEMINOLE","code":"US-FL-SE5"},
          {"name":"SHALIMAR","code":"US-FL-SR4"},
          {"name":"SHARPES","code":"US-FL-S22"},
          {"name":"SILVER SPRINGS","code":"US-FL-SIE"},
          {"name":"SINGER ISLAND","code":"US-FL-SIN"},
          {"name":"SNEADS","code":"US-FL-QSD"},
          {"name":"SORRENTO","code":"US-FL-RRN"},
          {"name":"SOUTH BAY","code":"US-FL-SOF"},
          {"name":"SOUTH DAYTONA","code":"US-FL-8DA"},
          {"name":"SOUTH GATE","code":"US-FL-OUG"},
          {"name":"SOUTH MIAMI","code":"US-FL-XMF"},
          {"name":"SOUTH PIERCE","code":"US-FL-SSQ"},
          {"name":"SOUTHWEST RANCHES","code":"US-FL-9SW"},
          {"name":"SPRING HILL","code":"US-FL-SHI"},
          {"name":"STARKE","code":"US-FL-XMU"},
          {"name":"STUART","code":"US-FL-SUA"},
          {"name":"SUMMERFIELD","code":"US-FL-FSF"},
          {"name":"SUMMERLAND KEY","code":"US-FL-SK3"},
          {"name":"SUMTERVILLE","code":"US-FL-UMV"},
          {"name":"SUN CITY","code":"US-FL-SJY"},
          {"name":"SUNNY ISLES","code":"US-FL-XSU"},
          {"name":"SUNRISE","code":"US-FL-XUN"},
          {"name":"SURFSIDE","code":"US-FL-FUF"},
          {"name":"SUWANNEE","code":"US-FL-S8E"},
          {"name":"SWEETWATER","code":"US-FL-AAJ"},
          {"name":"TAFT, ORANGE","code":"US-FL-TF4"},
          {"name":"TALLAHASSEE","code":"US-FL-TLH"},
          {"name":"TALLEYRAND","code":"US-FL-TA9"},
          {"name":"TAMARAC","code":"US-FL-TRK"},
          {"name":"TAMPA","code":"US-FL-TPA"},
          {"name":"TARPON SPINGS","code":"US-FL-ZIU"},
          {"name":"TARPON SPRINGS","code":"US-FL-ZKL"},
          {"name":"TAVARES","code":"US-FL-TAV"},
          {"name":"TAVERNIER","code":"US-FL-TNE"},
          {"name":"TELOGIA","code":"US-FL-TLG"},
          {"name":"TEMPLE TERRACE","code":"US-FL-TJT"},
          {"name":"TEQUESTA","code":"US-FL-TEU"},
          {"name":"THE VILLAGES","code":"US-FL-VGZ"},
          {"name":"THONOTOSASSA","code":"US-FL-TOS"},
          {"name":"TIERRA VERDE","code":"US-FL-TDE"},
          {"name":"TITUSVILLE","code":"US-FL-TIX"},
          {"name":"TREASURE ISLAND","code":"US-FL-EUA"},
          {"name":"TRENTON","code":"US-FL-TOE"},
          {"name":"TYNDALL AFB","code":"US-FL-TJF"},
          {"name":"UMATILLA","code":"US-FL-UML"},
          {"name":"UNIVERSITY PARK","code":"US-FL-UP2"},
          {"name":"VALPARAISO","code":"US-FL-VPS"},
          {"name":"VALRICO","code":"US-FL-VLQ"},
          {"name":"VENICE","code":"US-FL-VNC"},
          {"name":"VENUS","code":"US-FL-VS8"},
          {"name":"VERO BEACH","code":"US-FL-VRB"},
          {"name":"VIERA","code":"US-FL-V2A"},
          {"name":"WABASSO","code":"US-FL-WPS"},
          {"name":"WALDO","code":"US-FL-WLF"},
          {"name":"WALNUT HILL","code":"US-FL-W32"},
          {"name":"WAUCHULA","code":"US-FL-WU9"},
          {"name":"WEBSTER","code":"US-FL-FWS"},
          {"name":"WELLBORN","code":"US-FL-ZF4"},
          {"name":"WELLINGTON","code":"US-FL-WJT"},
          {"name":"WESLEY CHAPEL","code":"US-FL-WC3"},
          {"name":"WEST MELBOURNE","code":"US-FL-TMU"},
          {"name":"WEST MIAMI","code":"US-FL-W5M"},
          {"name":"WEST MIAMI","code":"US-FL-WM5"},
          {"name":"WEST PALM BEACH","code":"US-FL-PBI"},
          {"name":"WEST PARK","code":"US-FL-QWP"},
          {"name":"WESTON","code":"US-FL-WNO"},
          {"name":"WEWAHITCHKA","code":"US-FL-W3W"},
          {"name":"WHITE SPRINGS","code":"US-FL-WTH"},
          {"name":"WHITEHOUSE","code":"US-FL-NEN"},
          {"name":"WILDWOOD","code":"US-FL-WWF"},
          {"name":"WILLISTON","code":"US-FL-WIT"},
          {"name":"WILTON MANORS","code":"US-FL-WM8"},
          {"name":"WIMAUMA","code":"US-FL-WIU"},
          {"name":"WINDERMERE","code":"US-FL-WMY"},
          {"name":"WINTER GARDEN","code":"US-FL-WTG"},
          {"name":"WINTER HAVEN","code":"US-FL-GIF"},
          {"name":"WINTER PARK","code":"US-FL-WPK"},
          {"name":"WINTER SPRINGS","code":"US-FL-ZIW"},
          {"name":"WORTHINGTON SPRINGS","code":"US-FL-WG4"},
          {"name":"YULEE","code":"US-FL-YUL"},
          {"name":"ZELLWOOD","code":"US-FL-ZWD"},
          {"name":"ZEPHYRHILLS","code":"US-FL-ZPH"},
          {"name":"ZOLFO SPRINGS","code":"US-FL-ZPS"},
        ]
      },
      {
        name: 'Georgia',
        code: 'US-GA',
        cities: [
          {"name":"AARON","code":"US-GA-OQU"},
          {"name":"ACKERMAN","code":"US-GA-ACR"},
          {"name":"ACWORTH","code":"US-GA-AWR"},
          {"name":"ADAIRSVILLE","code":"US-GA-ADV"},
          {"name":"ADEL","code":"US-GA-ADE"},
          {"name":"AILEY","code":"US-GA-YAY"},
          {"name":"ALBANY","code":"US-GA-ABY"},
          {"name":"ALMA","code":"US-GA-AMQ"},
          {"name":"ALPHARETTA","code":"US-GA-AAI"},
          {"name":"ALTO","code":"US-GA-AOQ"},
          {"name":"ALVATON","code":"US-GA-A2T"},
          {"name":"AMBROSE","code":"US-GA-SBR"},
          {"name":"AMERICUS","code":"US-GA-AMU"},
          {"name":"ANDERSON CITY","code":"US-GA-AEQ"},
          {"name":"ANDERSONVILLE","code":"US-GA-UBK"},
          {"name":"APPLING","code":"US-GA-AP7"},
          {"name":"ARABI","code":"US-GA-AJF"},
          {"name":"ARAGON","code":"US-GA-YGN"},
          {"name":"ARLINGTON","code":"US-GA-ARD"},
          {"name":"ARMUCHEE","code":"US-GA-ARM"},
          {"name":"ASHBURN","code":"US-GA-ABN"},
          {"name":"ATHENS","code":"US-GA-AHN"},
          {"name":"ATLANTA","code":"US-GA-ATL"},
          {"name":"ATTAPULGUS","code":"US-GA-APS"},
          {"name":"AUBURN","code":"US-GA-XUB"},
          {"name":"AUGUSTA","code":"US-GA-AGS"},
          {"name":"AUSTELL","code":"US-GA-AUL"},
          {"name":"AVALON","code":"US-GA-AVS"},
          {"name":"AXSON","code":"US-GA-AX3"},
          {"name":"AYERSVILLE","code":"US-GA-AYR"},
          {"name":"BACONTON","code":"US-GA-BO5"},
          {"name":"BAINBRIDGE","code":"US-GA-BGE"},
          {"name":"BALDWIN","code":"US-GA-BBJ"},
          {"name":"BALL GROUND","code":"US-GA-BBN"},
          {"name":"BARNESVILLE","code":"US-GA-BAV"},
          {"name":"BARTOW","code":"US-GA-ABO"},
          {"name":"BARWICK","code":"US-GA-KBW"},
          {"name":"BAXLEY","code":"US-GA-BYX"},
          {"name":"BAY MEADOWS","code":"US-GA-BYT"},
          {"name":"BENTON","code":"US-GA-B4G"},
          {"name":"BERKELEY LAKE","code":"US-GA-K2L"},
          {"name":"BETHLEHEM","code":"US-GA-EHL"},
          {"name":"BIRMINGHAM ROAD ESTATES","code":"US-GA-BM9"},
          {"name":"BISHOP","code":"US-GA-YHP"},
          {"name":"BLACK CREEK","code":"US-GA-KAK"},
          {"name":"BLACKSHEAR","code":"US-GA-XBG"},
          {"name":"BLACKSHEAR, PIERCE","code":"US-GA-BS2"},
          {"name":"BLAINE","code":"US-GA-QBE"},
          {"name":"BLAIRESVILLE","code":"US-GA-BV6"},
          {"name":"BLAIRSVILLE","code":"US-GA-IVL"},
          {"name":"BLAKELY","code":"US-GA-BMU"},
          {"name":"BLOOMINGDALE","code":"US-GA-BMD"},
          {"name":"BLUE RIDGE","code":"US-GA-YBR"},
          {"name":"BLUFFTON","code":"US-GA-BL9"},
          {"name":"BOGART","code":"US-GA-ZZB"},
          {"name":"BONAIRE","code":"US-GA-BG9"},
          {"name":"BONEVILLE","code":"US-GA-BO3"},
          {"name":"BOSTON","code":"US-GA-BZD"},
          {"name":"BOWDON","code":"US-GA-BGD"},
          {"name":"BOWERSVILLE","code":"US-GA-QBW"},
          {"name":"BOWMAN","code":"US-GA-OWM"},
          {"name":"BOX SPRINGS","code":"US-GA-9BS"},
          {"name":"BOYKIN","code":"US-GA-KBY"},
          {"name":"BRASELTON","code":"US-GA-GBL"},
          {"name":"BREMEN","code":"US-GA-EEL"},
          {"name":"BRONWOOD","code":"US-GA-OOO"},
          {"name":"BROOKFIELD","code":"US-GA-IKD"},
          {"name":"BROOKHAVEN","code":"US-GA-9BH"},
          {"name":"BROOKLET","code":"US-GA-OKT"},
          {"name":"BROOKS","code":"US-GA-B5O"},
          {"name":"BRUNSWICK","code":"US-GA-SSI"},
          {"name":"BUCKHEAD","code":"US-GA-UKB"},
          {"name":"BUENA VISTA","code":"US-GA-B2V"},
          {"name":"BUFORD","code":"US-GA-BFA"},
          {"name":"BUTLER","code":"US-GA-AFU"},
          {"name":"BYROMVILLE","code":"US-GA-YRM"},
          {"name":"BYRON","code":"US-GA-BY8"},
          {"name":"CAIRO","code":"US-GA-CJR"},
          {"name":"CALHOUN","code":"US-GA-CAH"},
          {"name":"CALLAWAY GARDENS","code":"US-GA-CWG"},
          {"name":"CAMAK","code":"US-GA-KMM"},
          {"name":"CAMILLA","code":"US-GA-CML"},
          {"name":"CANON","code":"US-GA-CG6"},
          {"name":"CANTON","code":"US-GA-CNX"},
          {"name":"CARLTON","code":"US-GA-4CT"},
          {"name":"CARNESVILLE","code":"US-GA-C2A"},
          {"name":"CARROLLTON","code":"US-GA-CRR"},
          {"name":"CARTERSVILLE","code":"US-GA-UFK"},
          {"name":"CATAULA","code":"US-GA-C47"},
          {"name":"CAVE SPRING","code":"US-GA-VSP"},
          {"name":"CEDAR SPRINGS","code":"US-GA-CES"},
          {"name":"CEDARTOWN","code":"US-GA-CDI"},
          {"name":"CENTERVILLE, TALBOT","code":"US-GA-CVE"},
          {"name":"CENTREVILLE","code":"US-GA-CV2"},
          {"name":"CHAMBLEE","code":"US-GA-CMM"},
          {"name":"CHATSWORTH","code":"US-GA-XDT"},
          {"name":"CHATTAHOOCHEE","code":"US-GA-C9T"},
          {"name":"CHATTAHOOCHEE HILLS","code":"US-GA-HH5"},
          {"name":"CHERRY LOG","code":"US-GA-5CL"},
          {"name":"CHESTER","code":"US-GA-VKR"},
          {"name":"CHICKAMAUGA","code":"US-GA-CSW"},
          {"name":"CLARKESVILLE","code":"US-GA-CWW"},
          {"name":"CLARKSTON","code":"US-GA-CKS"},
          {"name":"CLAXTON","code":"US-GA-CXT"},
          {"name":"CLAYTON","code":"US-GA-CN3"},
          {"name":"CLERMONT","code":"US-GA-LMN"},
          {"name":"CLEVELAND","code":"US-GA-CQV"},
          {"name":"CLIMAX","code":"US-GA-IMX"},
          {"name":"CLINCHFIELD","code":"US-GA-ZQC"},
          {"name":"CLYATTVILLE","code":"US-GA-CYW"},
          {"name":"CLYO","code":"US-GA-YYO"},
          {"name":"COCHRAN","code":"US-GA-OHN"},
          {"name":"COHUTTA","code":"US-GA-AAG"},
          {"name":"COLBERT","code":"US-GA-OBR"},
          {"name":"COLLEGE PARK","code":"US-GA-CPG"},
          {"name":"COLLINS","code":"US-GA-XPX"},
          {"name":"COLQUITT","code":"US-GA-COQ"},
          {"name":"COLUMBUS","code":"US-GA-CSG"},
          {"name":"COMER","code":"US-GA-6CR"},
          {"name":"COMMERCE","code":"US-GA-ZEC"},
          {"name":"CONCORD","code":"US-GA-JQQ"},
          {"name":"CONLEY","code":"US-GA-CZU"},
          {"name":"CONYERS","code":"US-GA-CYR"},
          {"name":"COOLIDGE","code":"US-GA-GCL"},
          {"name":"COOSA","code":"US-GA-OOZ"},
          {"name":"CORDELE","code":"US-GA-ADF"},
          {"name":"CORNELIA","code":"US-GA-CZA"},
          {"name":"COVINGTON","code":"US-GA-CVF"},
          {"name":"CRANDALL","code":"US-GA-KR7"},
          {"name":"CRAWFORDVILLE","code":"US-GA-FDI"},
          {"name":"CRESCENT","code":"US-GA-ECJ"},
          {"name":"CROSSROADS","code":"US-GA-XRD"},
          {"name":"CUMMING","code":"US-GA-CMG"},
          {"name":"CUTHBERT","code":"US-GA-H8B"},
          {"name":"DACULA","code":"US-GA-DQL"},
          {"name":"DAHLONEGA","code":"US-GA-DHL"},
          {"name":"DALLAS","code":"US-GA-DLX"},
          {"name":"DALTON","code":"US-GA-DNN"},
          {"name":"DAMASCUS","code":"US-GA-DAM"},
          {"name":"DANIELSVILLE","code":"US-GA-DA4"},
          {"name":"DANVILLE","code":"US-GA-DQ6"},
          {"name":"DARIEN","code":"US-GA-DIE"},
          {"name":"DAWSON","code":"US-GA-DAW"},
          {"name":"DAWSONVILLE","code":"US-GA-DVX"},
          {"name":"DEARING","code":"US-GA-DR4"},
          {"name":"DECATUR","code":"US-GA-DCG"},
          {"name":"DEMOREST","code":"US-GA-OSQ"},
          {"name":"DENTON","code":"US-GA-DTO"},
          {"name":"DEWY ROSE","code":"US-GA-DWE"},
          {"name":"DILLON","code":"US-GA-8DL"},
          {"name":"DIXIE","code":"US-GA-IXE"},
          {"name":"DOERUN","code":"US-GA-DU9"},
          {"name":"DONALSONVILLE","code":"US-GA-DSL"},
          {"name":"DORAVILLE","code":"US-GA-DRH"},
          {"name":"DOUGLAS","code":"US-GA-DOU"},
          {"name":"DOUGLASVILLE","code":"US-GA-DGV"},
          {"name":"DOVER","code":"US-GA-DO5"},
          {"name":"DRY BRANCH","code":"US-GA-DRY"},
          {"name":"DUBLIN","code":"US-GA-DBN"},
          {"name":"DULUTH","code":"US-GA-DGA"},
          {"name":"DUNWOODY","code":"US-GA-DW3"},
          {"name":"EAST DUBLIN","code":"US-GA-EDU"},
          {"name":"EAST POINT","code":"US-GA-EPN"},
          {"name":"EASTANOLLEE","code":"US-GA-EE4"},
          {"name":"EASTMAN","code":"US-GA-EZG"},
          {"name":"EATONTON","code":"US-GA-AAX"},
          {"name":"EDEN","code":"US-GA-QED"},
          {"name":"EDISON","code":"US-GA-ED4"},
          {"name":"ELBERTON","code":"US-GA-ELB"},
          {"name":"ELKO","code":"US-GA-3KO"},
          {"name":"ELLABELL","code":"US-GA-EBZ"},
          {"name":"ELLAVILLE","code":"US-GA-ZEV"},
          {"name":"ELLENWOOD","code":"US-GA-ZEW"},
          {"name":"ELLIJAY","code":"US-GA-ELJ"},
          {"name":"EMERSON","code":"US-GA-ZQN"},
          {"name":"ENIGMA","code":"US-GA-EIM"},
          {"name":"ETON","code":"US-GA-ETG"},
          {"name":"EUHARLEE","code":"US-GA-E8E"},
          {"name":"EVANS","code":"US-GA-EVG"},
          {"name":"FAIRBURN","code":"US-GA-FBN"},
          {"name":"FAIRFIELD","code":"US-GA-FGX"},
          {"name":"FAIRMOUNT","code":"US-GA-FMU"},
          {"name":"FARGO","code":"US-GA-JGO"},
          {"name":"FAYETTEVILLE","code":"US-GA-FYE"},
          {"name":"FITZGERALD","code":"US-GA-FZG"},
          {"name":"FLEMING","code":"US-GA-FEG"},
          {"name":"FLEMINGTON","code":"US-GA-G3N"},
          {"name":"FLINTSTONE","code":"US-GA-ZDB"},
          {"name":"FLOWERY BRANCH","code":"US-GA-FLB"},
          {"name":"FOLKSTON","code":"US-GA-FON"},
          {"name":"FOREST PARK","code":"US-GA-FOP"},
          {"name":"FORSYTH","code":"US-GA-ZYF"},
          {"name":"FORT BENNING","code":"US-GA-LSF"},
          {"name":"FORT GAINES","code":"US-GA-FTG"},
          {"name":"FORT GILLEM","code":"US-GA-UJF"},
          {"name":"FORT GORDON","code":"US-GA-ZDD"},
          {"name":"FORT MCPHERSON","code":"US-GA-9FM"},
          {"name":"FORT OGLETHORPE","code":"US-GA-FTT"},
          {"name":"FORT STEWART","code":"US-GA-RWT"},
          {"name":"FORT VALLEY","code":"US-GA-FTV"},
          {"name":"FRANKLIN","code":"US-GA-RAK"},
          {"name":"FRANKLIN SPRINGS","code":"US-GA-KFG"},
          {"name":"FUNSTON","code":"US-GA-F4S"},
          {"name":"GAINESVILLE","code":"US-GA-GVL"},
          {"name":"GARDEN CITY","code":"US-GA-GCG"},
          {"name":"GAY","code":"US-GA-GGY"},
          {"name":"GEORGETOWN","code":"US-GA-GER"},
          {"name":"GIBSON","code":"US-GA-GOB"},
          {"name":"GILLSVILLE","code":"US-GA-GV3"},
          {"name":"GIRARD","code":"US-GA-GDX"},
          {"name":"GLENNVILLE","code":"US-GA-YGL"},
          {"name":"GLENWOOD","code":"US-GA-GKX"},
          {"name":"GLYNCO","code":"US-GA-NEA"},
          {"name":"GORDON","code":"US-GA-GOR"},
          {"name":"GRAHAM","code":"US-GA-GAA"},
          {"name":"GRANTVILLE","code":"US-GA-GTI"},
          {"name":"GRAY","code":"US-GA-G9R"},
          {"name":"GRAYSON","code":"US-GA-YYG"},
          {"name":"GREENSBORO","code":"US-GA-GNO"},
          {"name":"GREENVILLE","code":"US-GA-GVG"},
          {"name":"GREENWOOD, HENRY","code":"US-GA-GW4"},
          {"name":"GRIFFIN","code":"US-GA-GRZ"},
          {"name":"GROVETOWN","code":"US-GA-GTG"},
          {"name":"HADDOCK","code":"US-GA-HDK"},
          {"name":"HAHIRA","code":"US-GA-XGF"},
          {"name":"HAMILTON","code":"US-GA-3GA"},
          {"name":"HAMPTON","code":"US-GA-HT3"},
          {"name":"HAPEVILLE","code":"US-GA-HPL"},
          {"name":"HARLEM","code":"US-GA-HRE"},
          {"name":"HARRISON","code":"US-GA-H5R"},
          {"name":"HARTWELL","code":"US-GA-HTM"},
          {"name":"HAWKINSVILLE","code":"US-GA-HAZ"},
          {"name":"HAZLEHURST","code":"US-GA-HZH"},
          {"name":"HELEN","code":"US-GA-ZEH"},
          {"name":"HEPHZIBAH","code":"US-GA-HZI"},
          {"name":"HINESVILLE","code":"US-GA-LIY"},
          {"name":"HIRAM","code":"US-GA-HR7"},
          {"name":"HOBOKEN","code":"US-GA-OBP"},
          {"name":"HOGANSVILLE","code":"US-GA-HGA"},
          {"name":"HOLY SPRINGS","code":"US-GA-OYP"},
          {"name":"HOMER","code":"US-GA-4GA"},
          {"name":"HOMERVILLE","code":"US-GA-HMV"},
          {"name":"HOSCHTON","code":"US-GA-HSX"},
          {"name":"HUBER","code":"US-GA-HUA"},
          {"name":"HULL","code":"US-GA-UKL"},
          {"name":"IRON CITY","code":"US-GA-GA8"},
          {"name":"IRWINTON","code":"US-GA-IR4"},
          {"name":"JACKSON","code":"US-GA-JKG"},
          {"name":"JACKSONVILLE, TELFAIR","code":"US-GA-JKE"},
          {"name":"JASPER","code":"US-GA-ZJP"},
          {"name":"JEFFERSON","code":"US-GA-JFG"},
          {"name":"JEFFERSON, JACKSON","code":"US-GA-JF2"},
          {"name":"JEFFERSONVILLE","code":"US-GA-JEV"},
          {"name":"JEKYLL ISLAND","code":"US-GA-IKI"},
          {"name":"JENKINSBURG","code":"US-GA-Z2F"},
          {"name":"JESUP","code":"US-GA-JES"},
          {"name":"JOHNS CREEK","code":"US-GA-JC2"},
          {"name":"JONESBORO","code":"US-GA-JBO"},
          {"name":"JULIETTE","code":"US-GA-JL2"},
          {"name":"JUNCTION CITY","code":"US-GA-JNC"},
          {"name":"KATHLEEN","code":"US-GA-KFN"},
          {"name":"KENNESAW","code":"US-GA-KSW"},
          {"name":"KENSINGTON","code":"US-GA-KEN"},
          {"name":"KINGS BAY","code":"US-GA-ZKB"},
          {"name":"KINGSLAND","code":"US-GA-KNL"},
          {"name":"KINGSTON","code":"US-GA-KT4"},
          {"name":"KITE","code":"US-GA-KT2"},
          {"name":"KNOXVILLE","code":"US-GA-ZF2"},
          {"name":"LAFAYETTE","code":"US-GA-LFG"},
          {"name":"LAGRANGE","code":"US-GA-L9G"},
          {"name":"LAGRANGE","code":"US-GA-LGC"},
          {"name":"LAKE CITY","code":"US-GA-JLY"},
          {"name":"LAKE PARK","code":"US-GA-KPA"},
          {"name":"LAKELAND","code":"US-GA-LKG"},
          {"name":"LAKEVIEW","code":"US-GA-LKX"},
          {"name":"LAKEWOOD","code":"US-GA-LWG"},
          {"name":"LANETT","code":"US-GA-LTE"},
          {"name":"LANIER","code":"US-GA-LIR"},
          {"name":"LAVONIA","code":"US-GA-AOI"},
          {"name":"LAWRENCEVILLE","code":"US-GA-LRW"},
          {"name":"LEESBURG","code":"US-GA-LES"},
          {"name":"LESLIE","code":"US-GA-LSQ"},
          {"name":"LEXINGTON","code":"US-GA-L2G"},
          {"name":"LILBURN","code":"US-GA-LLG"},
          {"name":"LILLY","code":"US-GA-LY9"},
          {"name":"LINCOLNTON","code":"US-GA-ZLC"},
          {"name":"LINDALE","code":"US-GA-LDL"},
          {"name":"LITHIA SPRINGS","code":"US-GA-LHS"},
          {"name":"LITHONIA","code":"US-GA-LIO"},
          {"name":"LOCUST GROVE","code":"US-GA-LCZ"},
          {"name":"LOGANVILLE","code":"US-GA-LGE"},
          {"name":"LOOKOUT MOUNTAIN","code":"US-GA-LM6"},
          {"name":"LOUISVILLE","code":"US-GA-LUY"},
          {"name":"LOVEJOY","code":"US-GA-LVJ"},
          {"name":"LULA","code":"US-GA-YLU"},
          {"name":"LUMBER CITY","code":"US-GA-QUQ"},
          {"name":"LUMPKIN","code":"US-GA-LKN"},
          {"name":"LUTHERSVILLE","code":"US-GA-UTH"},
          {"name":"LYERLY","code":"US-GA-LYR"},
          {"name":"LYONS","code":"US-GA-YOS"},
          {"name":"MABLETON","code":"US-GA-UQL"},
          {"name":"MACHEN","code":"US-GA-8U6"},
          {"name":"MACON","code":"US-GA-MCN"},
          {"name":"MADISON","code":"US-GA-MDS"},
          {"name":"MANASSAS","code":"US-GA-XM2"},
          {"name":"MANCHESTER","code":"US-GA-MHI"},
          {"name":"MANOR","code":"US-GA-ZMA"},
          {"name":"MANSFIELD","code":"US-GA-MSF"},
          {"name":"MARBLE HILL","code":"US-GA-ZMB"},
          {"name":"MARIETTA","code":"US-GA-MGE"},
          {"name":"MARSHALLVILLE","code":"US-GA-M22"},
          {"name":"MARTINEZ","code":"US-GA-RTI"},
          {"name":"MAYSVILLE","code":"US-GA-YVV"},
          {"name":"MCDONOUGH","code":"US-GA-MCH"},
          {"name":"MCINTOSH","code":"US-GA-M25"},
          {"name":"MCINTYRE","code":"US-GA-MCQ"},
          {"name":"MCRAE","code":"US-GA-MQW"},
          {"name":"MEANSVILLE","code":"US-GA-M3I"},
          {"name":"MEIGS","code":"US-GA-QIG"},
          {"name":"MENLO","code":"US-GA-MNL"},
          {"name":"MERSHON","code":"US-GA-M7S"},
          {"name":"METTER","code":"US-GA-TTR"},
          {"name":"MIDLAND","code":"US-GA-MJD"},
          {"name":"MIDVILLE","code":"US-GA-M5E"},
          {"name":"MIDWAY","code":"US-GA-IWA"},
          {"name":"MIDWAY, LIBERTY","code":"US-GA-QMY"},
          {"name":"MILAN","code":"US-GA-ML7"},
          {"name":"MILLEDGEVILLE","code":"US-GA-MLJ"},
          {"name":"MILLEN","code":"US-GA-MI4"},
          {"name":"MILNER","code":"US-GA-MN4"},
          {"name":"MILTON","code":"US-GA-M3N"},
          {"name":"MINERAL BLUFF","code":"US-GA-M5B"},
          {"name":"MOLENA","code":"US-GA-M47"},
          {"name":"MONROE","code":"US-GA-MOE"},
          {"name":"MONTEZUMA","code":"US-GA-MOZ"},
          {"name":"MONTICELLO","code":"US-GA-MQC"},
          {"name":"MORGAN","code":"US-GA-NGM"},
          {"name":"MORGANTON","code":"US-GA-O99"},
          {"name":"MORROW","code":"US-GA-MRW"},
          {"name":"MOULTRIE","code":"US-GA-MGR"},
          {"name":"MOUNT AIRY","code":"US-GA-NQU"},
          {"name":"MOUNT BERRY","code":"US-GA-MJR"},
          {"name":"MOUNTAIN CITY","code":"US-GA-TKM"},
          {"name":"MOUNTAIN VIEW","code":"US-GA-MUK"},
          {"name":"MOUNTVILLE","code":"US-GA-TVU"},
          {"name":"MURRAYVILLE","code":"US-GA-GGQ"},
          {"name":"NAHUNTA","code":"US-GA-NNH"},
          {"name":"NASHVILLE","code":"US-GA-NSH"},
          {"name":"NELSON","code":"US-GA-NON"},
          {"name":"NEWBORN","code":"US-GA-E8N"},
          {"name":"NEWNAN","code":"US-GA-NAN"},
          {"name":"NEWTON","code":"US-GA-NEO"},
          {"name":"NICHOLLS","code":"US-GA-ICH"},
          {"name":"NORCROSS","code":"US-GA-UXN"},
          {"name":"NORMAN PARK","code":"US-GA-NZM"},
          {"name":"OAKWOOD","code":"US-GA-OKW"},
          {"name":"OCHLOCKNEE","code":"US-GA-OCG"},
          {"name":"OCILLA","code":"US-GA-OLA"},
          {"name":"OGLETHORPE","code":"US-GA-OHQ"},
          {"name":"OLD ROCK HILL","code":"US-GA-DKH"},
          {"name":"OMEGA","code":"US-GA-9OM"},
          {"name":"OXFORD","code":"US-GA-OXG"},
          {"name":"PABST","code":"US-GA-PBT"},
          {"name":"PALMETTO","code":"US-GA-PFP"},
          {"name":"PATTERSON","code":"US-GA-GPO"},
          {"name":"PAVO","code":"US-GA-P22"},
          {"name":"PEACHTREE CITY","code":"US-GA-PQH"},
          {"name":"PEACHTREE CORNERS","code":"US-GA-G9T"},
          {"name":"PEARSON","code":"US-GA-PEJ"},
          {"name":"PELHAM","code":"US-GA-PEH"},
          {"name":"PEMBROKE","code":"US-GA-QPB"},
          {"name":"PENDERGRASS","code":"US-GA-PGG"},
          {"name":"PERRY","code":"US-GA-PYG"},
          {"name":"PINE MOUNTAIN","code":"US-GA-PIM"},
          {"name":"PINE MOUNTAIN VALLEY","code":"US-GA-PM2"},
          {"name":"PINEHURST","code":"US-GA-P5T"},
          {"name":"PITTS","code":"US-GA-QQQ"},
          {"name":"PLAINS","code":"US-GA-PLS"},
          {"name":"PLAINVILLE","code":"US-GA-PVG"},
          {"name":"POOLER","code":"US-GA-POZ"},
          {"name":"PORT WENTWORTH","code":"US-GA-PWN"},
          {"name":"PORTAL","code":"US-GA-P8T"},
          {"name":"PORTERDALE","code":"US-GA-POD"},
          {"name":"POULAN","code":"US-GA-UPU"},
          {"name":"POWDER SPRINGS","code":"US-GA-PDS"},
          {"name":"PRESTON","code":"US-GA-PSO"},
          {"name":"QUITMAN","code":"US-GA-QTM"},
          {"name":"RABUN GAP","code":"US-GA-RGG"},
          {"name":"RANGER","code":"US-GA-JGR"},
          {"name":"RAY CITY","code":"US-GA-YDT"},
          {"name":"RAYLE","code":"US-GA-R3Y"},
          {"name":"RAYMOND","code":"US-GA-R4Y"},
          {"name":"REBECCA","code":"US-GA-RCC"},
          {"name":"REDAN","code":"US-GA-QAD"},
          {"name":"REGISTER","code":"US-GA-RGT"},
          {"name":"REIDSVILLE","code":"US-GA-REE"},
          {"name":"RENTZ","code":"US-GA-RTZ"},
          {"name":"RESACA","code":"US-GA-RQG"},
          {"name":"REX","code":"US-GA-REX"},
          {"name":"REYNOLDS","code":"US-GA-REY"},
          {"name":"RHINE","code":"US-GA-RHE"},
          {"name":"RICEBORO","code":"US-GA-RCG"},
          {"name":"RICHLAND","code":"US-GA-R4L"},
          {"name":"RICHMOND HILL","code":"US-GA-RJK"},
          {"name":"RINCON","code":"US-GA-RIK"},
          {"name":"RINGGOLD","code":"US-GA-RGD"},
          {"name":"RISING FAWN","code":"US-GA-IWK"},
          {"name":"RIVERDALE","code":"US-GA-RQV"},
          {"name":"ROBERTA","code":"US-GA-OBA"},
          {"name":"ROBINS AIR FORCE BASE","code":"US-GA-OBF"},
          {"name":"ROCHELLE","code":"US-GA-RCL"},
          {"name":"ROCK SPRINGS","code":"US-GA-XRS"},
          {"name":"ROCKLEDGE","code":"US-GA-K8L"},
          {"name":"ROCKMART","code":"US-GA-RKA"},
          {"name":"ROCKY FACE","code":"US-GA-RYF"},
          {"name":"ROME","code":"US-GA-RMG"},
          {"name":"ROOPVILLE","code":"US-GA-RPV"},
          {"name":"ROSSVILLE","code":"US-GA-RSS"},
          {"name":"ROSWELL","code":"US-GA-RWE"},
          {"name":"ROYSTON","code":"US-GA-ROY"},
          {"name":"RUTLEDGE","code":"US-GA-RUA"},
          {"name":"RYDAL","code":"US-GA-YYA"},
          {"name":"SAINT MARYS","code":"US-GA-SJW"},
          {"name":"SAINT SIMONS ISLAND","code":"US-GA-SSS"},
          {"name":"SANDERSVILLE","code":"US-GA-SNV"},
          {"name":"SANDY SPRINGS","code":"US-GA-YSG"},
          {"name":"SASSER","code":"US-GA-SAH"},
          {"name":"SAUTEE NACOOCHEE","code":"US-GA-9SN"},
          {"name":"SAVANNAH","code":"US-GA-SAV"},
          {"name":"SCOTTDALE","code":"US-GA-SZD"},
          {"name":"SEA ISLAND","code":"US-GA-QSI"},
          {"name":"SENOIA","code":"US-GA-SN2"},
          {"name":"SHADY DALE","code":"US-GA-SZJ"},
          {"name":"SHANNON","code":"US-GA-SHO"},
          {"name":"SHELLMAN","code":"US-GA-XMC"},
          {"name":"SHENANDOAH","code":"US-GA-UYS"},
          {"name":"SILVER CREEK","code":"US-GA-SXV"},
          {"name":"SMARR","code":"US-GA-RQA"},
          {"name":"SMITHVILLE","code":"US-GA-SMI"},
          {"name":"SMYRNA","code":"US-GA-SMY"},
          {"name":"SNELLVILLE","code":"US-GA-SEE"},
          {"name":"SOCIAL CIRCLE","code":"US-GA-OCR"},
          {"name":"SOPERTON","code":"US-GA-QS3"},
          {"name":"SPARKS","code":"US-GA-SPM"},
          {"name":"SPARTA","code":"US-GA-AA5"},
          {"name":"STAPLETON","code":"US-GA-TAE"},
          {"name":"STATESBORO","code":"US-GA-TBR"},
          {"name":"STATHAM","code":"US-GA-ST3"},
          {"name":"STILLMORE","code":"US-GA-LLM"},
          {"name":"STOCKBRIDGE","code":"US-GA-SKB"},
          {"name":"STOCKTON","code":"US-GA-9SK"},
          {"name":"STONE MOUNTAIN","code":"US-GA-SUM"},
          {"name":"SUGAR HILL","code":"US-GA-UGH"},
          {"name":"SUGAR VALLEY","code":"US-GA-UGA"},
          {"name":"SUMMERVILLE","code":"US-GA-ZSE"},
          {"name":"SUMNER","code":"US-GA-SXG"},
          {"name":"SUNNY SIDE","code":"US-GA-YUY"},
          {"name":"SURRENCY","code":"US-GA-RRU"},
          {"name":"SUWANEE","code":"US-GA-SVG"},
          {"name":"SWAINSBORO","code":"US-GA-SWJ"},
          {"name":"SYCAMORE","code":"US-GA-YYC"},
          {"name":"SYLVANIA","code":"US-GA-XMZ"},
          {"name":"SYLVESTER","code":"US-GA-SYV"},
          {"name":"TALLAPOOSA","code":"US-GA-TAA"},
          {"name":"TALMO","code":"US-GA-TLO"},
          {"name":"TATE","code":"US-GA-TTE"},
          {"name":"TAYLORSVILLE","code":"US-GA-XYV"},
          {"name":"TAZEWELL","code":"US-GA-ZWE"},
          {"name":"TEMPLE","code":"US-GA-TML"},
          {"name":"TENNILLE","code":"US-GA-ELE"},
          {"name":"THE ROCK","code":"US-GA-RKG"},
          {"name":"THOMASTON","code":"US-GA-THS"},
          {"name":"THOMASVILLE","code":"US-GA-TVI"},
          {"name":"THOMSON","code":"US-GA-TSJ"},
          {"name":"TIFTON","code":"US-GA-TMA"},
          {"name":"TIGER","code":"US-GA-TG9"},
          {"name":"TIGNALL","code":"US-GA-TGL"},
          {"name":"TOCCOA","code":"US-GA-TOC"},
          {"name":"TOOMSBORO","code":"US-GA-TO2"},
          {"name":"TOWNSEND","code":"US-GA-TQW"},
          {"name":"TRENTON","code":"US-GA-ZJO"},
          {"name":"TRION","code":"US-GA-TNG"},
          {"name":"TUCKER","code":"US-GA-TUC"},
          {"name":"TUNNEL HILL","code":"US-GA-XNI"},
          {"name":"TWIN CITY","code":"US-GA-TWN"},
          {"name":"TY TY","code":"US-GA-TYA"},
          {"name":"TYBEE ISLAND","code":"US-GA-ZYI"},
          {"name":"TYRONE","code":"US-GA-TYF"},
          {"name":"UNADILLA","code":"US-GA-UDL"},
          {"name":"UNION CITY","code":"US-GA-UCG"},
          {"name":"UNION POINT","code":"US-GA-QUP"},
          {"name":"UVALDA","code":"US-GA-UVD"},
          {"name":"VALDOSTA","code":"US-GA-VLD"},
          {"name":"VALLEY","code":"US-GA-VAE"},
          {"name":"VARNELL","code":"US-GA-YVR"},
          {"name":"VIDALIA","code":"US-GA-VDI"},
          {"name":"VIENNA","code":"US-GA-VIG"},
          {"name":"VILLA RICA","code":"US-GA-VLR"},
          {"name":"WACO","code":"US-GA-W9O"},
          {"name":"WADLEY","code":"US-GA-WY6"},
          {"name":"WALTHOURVILLE","code":"US-GA-WXA"},
          {"name":"WARNER ROBINS","code":"US-GA-WRB"},
          {"name":"WARRENTON","code":"US-GA-WRE"},
          {"name":"WARTHEN","code":"US-GA-WH9"},
          {"name":"WARWICK","code":"US-GA-DWK"},
          {"name":"WASHINGTON","code":"US-GA-WAB"},
          {"name":"WATKINSVILLE","code":"US-GA-VKV"},
          {"name":"WAVERLY","code":"US-GA-VVE"},
          {"name":"WAVERLY HALL","code":"US-GA-ZAL"},
          {"name":"WAYCROSS","code":"US-GA-AYS"},
          {"name":"WAYNESBORO","code":"US-GA-WYG"},
          {"name":"WEST POINT","code":"US-GA-WOI"},
          {"name":"WESTON","code":"US-GA-LY3"},
          {"name":"WESTOVER","code":"US-GA-WXO"},
          {"name":"WHIGHAM","code":"US-GA-W5G"},
          {"name":"WHITE","code":"US-GA-WUH"},
          {"name":"WHITE","code":"US-GA-YWX"},
          {"name":"WHITESTONE","code":"US-GA-ZWS"},
          {"name":"WILLACOOCHEE","code":"US-GA-YWA"},
          {"name":"WILLIAMSON","code":"US-GA-WDL"},
          {"name":"WINDER","code":"US-GA-WDR"},
          {"name":"WINOKUR","code":"US-GA-WI5"},
          {"name":"WINSTON","code":"US-GA-VSN"},
          {"name":"WINTERVILLE","code":"US-GA-WNL"},
          {"name":"WOODBINE","code":"US-GA-OO4"},
          {"name":"WOODBURY","code":"US-GA-ZYB"},
          {"name":"WOODSTOCK","code":"US-GA-WOY"},
          {"name":"WRAY","code":"US-GA-WRJ"},
          {"name":"WRENS","code":"US-GA-UZM"},
          {"name":"WRIGHTSVILLE","code":"US-GA-WVF"},
          {"name":"ZEBULON","code":"US-GA-ZSF"},
        ]
      },
      {
        name: 'Hawái',
        code: 'US-HI',
        cities: [
          {"name":"AIEA (OAHU)","code":"US-HI-AIE"},
          {"name":"ANAHOLA","code":"US-HI-A79"},
          {"name":"BARBERS POINT (OAHU)","code":"US-HI-NAX"},
          {"name":"CAPTAIN COOK","code":"US-HI-9CC"},
          {"name":"ELEELE","code":"US-HI-KHW"},
          {"name":"EWA BEACH (OAHU)","code":"US-HI-EWH"},
          {"name":"HAIKU","code":"US-HI-HXK"},
          {"name":"HALEIWA","code":"US-HI-HA7"},
          {"name":"HANA","code":"US-HI-HNM"},
          {"name":"HANAPEPE","code":"US-HI-PAK"},
          {"name":"HAUULA","code":"US-HI-H7L"},
          {"name":"HICKAM AFB","code":"US-HI-H4M"},
          {"name":"HILO","code":"US-HI-ITO"},
          {"name":"HONOLULU","code":"US-HI-HNL"},
          {"name":"HOOLEHUA","code":"US-HI-MKK"},
          {"name":"KAHUKU","code":"US-HI-KL9"},
          {"name":"KAHULUI","code":"US-HI-OGG"},
          {"name":"KAILUA","code":"US-HI-KA7"},
          {"name":"KAILUA (MAUI)","code":"US-HI-KUI"},
          {"name":"KAILUA (OAHU)","code":"US-HI-KQO"},
          {"name":"KAILUA KONA","code":"US-HI-KHH"},
          {"name":"KALAHEO","code":"US-HI-9KA"},
          {"name":"KALAUPAPA","code":"US-HI-LUP"},
          {"name":"KAMUELA","code":"US-HI-MUE"},
          {"name":"KANEOHE","code":"US-HI-KHE"},
          {"name":"KAPAA","code":"US-HI-KA2"},
          {"name":"KAPALUA","code":"US-HI-JHM"},
          {"name":"KAPOLEI","code":"US-HI-KPI"},
          {"name":"KAUAI ISLAND APT","code":"US-HI-LIH"},
          {"name":"KAUNAKAKAI (MOLOKAI)","code":"US-HI-KUA"},
          {"name":"KAUPULEHU","code":"US-HI-KP5"},
          {"name":"KAWAIHAE","code":"US-HI-KWH"},
          {"name":"KEAAU","code":"US-HI-6KE"},
          {"name":"KEALAKEKUA","code":"US-HI-KK2"},
          {"name":"KEKAHA","code":"US-HI-BKH"},
          {"name":"KIHEI","code":"US-HI-KIH"},
          {"name":"KILAUEA","code":"US-HI-967"},
          {"name":"KOHALA","code":"US-HI-KOH"},
          {"name":"KOLOA","code":"US-HI-KCI"},
          {"name":"KULA","code":"US-HI-KU2"},
          {"name":"KUNIA CAMP","code":"US-HI-KC2"},
          {"name":"LAHAINA","code":"US-HI-LHN"},
          {"name":"LANAI CITY","code":"US-HI-LNY"},
          {"name":"LIHUE (KAUAI)","code":"US-HI-LIU"},
          {"name":"MAKAWAO","code":"US-HI-MK8"},
          {"name":"MILILANI TOWN","code":"US-HI-MM7"},
          {"name":"MOUNTAIN VIEW","code":"US-HI-5MV"},
          {"name":"NAWILIWILI","code":"US-HI-NIJ"},
          {"name":"PEARL CITY (OAHU)","code":"US-HI-PLC"},
          {"name":"PEARL HARBOR","code":"US-HI-PEA"},
          {"name":"POHAKULOA","code":"US-HI-BSF"},
          {"name":"PORT ALLEN","code":"US-HI-PAL"},
          {"name":"PRINCEVILLE","code":"US-HI-QZL"},
          {"name":"PU'UNENE","code":"US-HI-5PU"},
          {"name":"ULUPALAKUA","code":"US-HI-3DV"},
          {"name":"UPOLU POINT","code":"US-HI-UPP"},
          {"name":"WAHIAWA","code":"US-HI-HHI"},
          {"name":"WAIALUA","code":"US-HI-5WA"},
          {"name":"WAIANAE","code":"US-HI-9AI"},
          {"name":"WAIANAE","code":"US-HI-WH7"},
          {"name":"WAIKOLOA","code":"US-HI-WKL"},
          {"name":"WAILEA","code":"US-HI-W8I"},
          {"name":"WAILUKU (MAUI)","code":"US-HI-WZV"},
          {"name":"WAIMANALO","code":"US-HI-BLW"},
          {"name":"WAIMEA","code":"US-HI-WM3"},
          {"name":"WAIPAHU (OAHU)","code":"US-HI-WPH"},
        ]
      },
      {
        name: 'Idaho',
        code: 'US-ID',
        cities: [
          {"name":"ABERDEEN","code":"US-ID-APD"},
          {"name":"AHSAHKA","code":"US-ID-AH2"},
          {"name":"ALBION","code":"US-ID-AN5"},
          {"name":"AMERICAN FALLS","code":"US-ID-YAF"},
          {"name":"AMMON","code":"US-ID-AM5"},
          {"name":"ARIMO","code":"US-ID-ID7"},
          {"name":"ASHTON","code":"US-ID-ASV"},
          {"name":"ATHOL","code":"US-ID-ATD"},
          {"name":"BANCROFT","code":"US-ID-XRF"},
          {"name":"BLACKFOOT","code":"US-ID-BLK"},
          {"name":"BLANCHARD","code":"US-ID-B8D"},
          {"name":"BOISE","code":"US-ID-BOI"},
          {"name":"BONNERS FERRY","code":"US-ID-BF6"},
          {"name":"BOVILL","code":"US-ID-B9V"},
          {"name":"BUHL","code":"US-ID-BHL"},
          {"name":"BURLEY","code":"US-ID-ZBU"},
          {"name":"BURLEY-RUPERT APT","code":"US-ID-BYI"},
          {"name":"CALDWELL","code":"US-ID-CDX"},
          {"name":"CAMAS","code":"US-ID-CM4"},
          {"name":"CAMBRIDGE","code":"US-ID-3CB"},
          {"name":"CAREYWOOD","code":"US-ID-CRY"},
          {"name":"CASCADE","code":"US-ID-KSC"},
          {"name":"CATALDO","code":"US-ID-2CD"},
          {"name":"CHALLIS","code":"US-ID-CHL"},
          {"name":"CHUBBUCK","code":"US-ID-UCK"},
          {"name":"CLARK FORK","code":"US-ID-CAK"},
          {"name":"CLAYTON","code":"US-ID-YTQ"},
          {"name":"CLEARWATER","code":"US-ID-AWT"},
          {"name":"CLOVER","code":"US-ID-XEP"},
          {"name":"COCOLALLA","code":"US-ID-6CC"},
          {"name":"COEUR D'ALENE","code":"US-ID-COE"},
          {"name":"COLLINS","code":"US-ID-CL9"},
          {"name":"COTTONWOOD","code":"US-ID-C8D"},
          {"name":"COUNCIL","code":"US-ID-OUC"},
          {"name":"CRAIGMONT","code":"US-ID-R9M"},
          {"name":"DALTON GARDENS","code":"US-ID-DTG"},
          {"name":"DECLO","code":"US-ID-DCL"},
          {"name":"DIRGGS","code":"US-ID-DG9"},
          {"name":"DONNELLY","code":"US-ID-DL2"},
          {"name":"DRIGGS","code":"US-ID-DGX"},
          {"name":"DUBOIS","code":"US-ID-DBS"},
          {"name":"EAGLE","code":"US-ID-G9L"},
          {"name":"EAGLE, ADA","code":"US-ID-YEA"},
          {"name":"EASTPORT","code":"US-ID-EPI"},
          {"name":"EDEN","code":"US-ID-EDI"},
          {"name":"EMMETT","code":"US-ID-EEI"},
          {"name":"FERNWOOD","code":"US-ID-FWE"},
          {"name":"FILER","code":"US-ID-FII"},
          {"name":"FIRTH","code":"US-ID-FHQ"},
          {"name":"FORT HALL","code":"US-ID-QFH"},
          {"name":"FRANKLIN","code":"US-ID-FBK"},
          {"name":"FRUITLAND","code":"US-ID-RXR"},
          {"name":"GARDEN CITY","code":"US-ID-TXU"},
          {"name":"GARDEN VALLEY","code":"US-ID-VGG"},
          {"name":"GENESEE","code":"US-ID-YGE"},
          {"name":"GLENNS FERRY","code":"US-ID-GFY"},
          {"name":"GOODING","code":"US-ID-GNG"},
          {"name":"GRACE","code":"US-ID-GCF"},
          {"name":"GRAND VIEW","code":"US-ID-JVW"},
          {"name":"GRANGEVILLE","code":"US-ID-GGJ"},
          {"name":"GREENLEAF","code":"US-ID-GWF"},
          {"name":"GWINNETT","code":"US-ID-GW2"},
          {"name":"HAILEY","code":"US-ID-IKY"},
          {"name":"HAILEY-SUN VALLEY APT","code":"US-ID-SUN"},
          {"name":"HAMER","code":"US-ID-HMR"},
          {"name":"HANSEN","code":"US-ID-HNI"},
          {"name":"HARRISON","code":"US-ID-H3S"},
          {"name":"HAUSER","code":"US-ID-ZUE"},
          {"name":"HAYDEN","code":"US-ID-YFN"},
          {"name":"HAYDEN LAKE","code":"US-ID-HYB"},
          {"name":"HAZELTON","code":"US-ID-HZT"},
          {"name":"HEYBURN","code":"US-ID-YHB"},
          {"name":"HOMEDALE","code":"US-ID-HMI"},
          {"name":"HOPE","code":"US-ID-HJP"},
          {"name":"IDAHO CITY","code":"US-ID-IDX"},
          {"name":"IDAHO FALLS","code":"US-ID-IDA"},
          {"name":"INKOM","code":"US-ID-ZIK"},
          {"name":"JEROME","code":"US-ID-JER"},
          {"name":"KAMIAH","code":"US-ID-KM2"},
          {"name":"KELLOGG","code":"US-ID-KGI"},
          {"name":"KENDRICK","code":"US-ID-KDR"},
          {"name":"KETCHUM","code":"US-ID-QKM"},
          {"name":"KIMBERLY","code":"US-ID-KIY"},
          {"name":"KOOTENAI","code":"US-ID-KO2"},
          {"name":"KUNA","code":"US-ID-KUN"},
          {"name":"LACLEDE","code":"US-ID-QDE"},
          {"name":"LEADORE","code":"US-ID-DZE"},
          {"name":"LETHA","code":"US-ID-LE8"},
          {"name":"LEWISTON","code":"US-ID-LWS"},
          {"name":"LEWISVILLE","code":"US-ID-V7L"},
          {"name":"MACKS INN","code":"US-ID-KNF"},
          {"name":"MALAD CITY","code":"US-ID-MLD"},
          {"name":"MARSING","code":"US-ID-MSG"},
          {"name":"MAYFIELD","code":"US-ID-MY8"},
          {"name":"MCCALL","code":"US-ID-MYL"},
          {"name":"MELBA","code":"US-ID-MKB"},
          {"name":"MERIDIAN","code":"US-ID-MJA"},
          {"name":"MIDDLETON","code":"US-ID-ID9"},
          {"name":"MILNER","code":"US-ID-MXD"},
          {"name":"MINIDOKA","code":"US-ID-MD9"},
          {"name":"MONTPELIER","code":"US-ID-IUW"},
          {"name":"MOROCCO","code":"US-ID-OOC"},
          {"name":"MOSCOW","code":"US-ID-MJL"},
          {"name":"MOUNTAIN HOME","code":"US-ID-MUO"},
          {"name":"MOYIE SPRINGS","code":"US-ID-IPG"},
          {"name":"MURTAUGH","code":"US-ID-MMG"},
          {"name":"NAMPA","code":"US-ID-NPA"},
          {"name":"NEW MEADOWS","code":"US-ID-NM6"},
          {"name":"NEW PLYMOUTH","code":"US-ID-ZGX"},
          {"name":"NEWDALE","code":"US-ID-5NW"},
          {"name":"NEZPERCE","code":"US-ID-NZP"},
          {"name":"NOTUS","code":"US-ID-NT9"},
          {"name":"OROFINO","code":"US-ID-OFG"},
          {"name":"OSBURN","code":"US-ID-OJB"},
          {"name":"OVID","code":"US-ID-OD2"},
          {"name":"PARMA","code":"US-ID-QPA"},
          {"name":"PAUL","code":"US-ID-PAZ"},
          {"name":"PAYETTE","code":"US-ID-PYE"},
          {"name":"PIERCE","code":"US-ID-PJK"},
          {"name":"PINGREE","code":"US-ID-PQG"},
          {"name":"POCATELLO","code":"US-ID-PIH"},
          {"name":"PONDERAY","code":"US-ID-PYF"},
          {"name":"PORTHILL","code":"US-ID-PJL"},
          {"name":"POST FALLS","code":"US-ID-PFI"},
          {"name":"POTLATCH","code":"US-ID-5PT"},
          {"name":"PRESTON","code":"US-ID-PS9"},
          {"name":"PRIEST RIVER","code":"US-ID-RP8"},
          {"name":"PRINCETON","code":"US-ID-FPC"},
          {"name":"RATHDRUM","code":"US-ID-RHU"},
          {"name":"REXBURG","code":"US-ID-RBI"},
          {"name":"RICHFIELD","code":"US-ID-RII"},
          {"name":"RIGBY","code":"US-ID-RG3"},
          {"name":"RIGGINS","code":"US-ID-RG7"},
          {"name":"RIRIE","code":"US-ID-RIH"},
          {"name":"RUPERT","code":"US-ID-RPT"},
          {"name":"RUPERT-BURLEY APT","code":"US-ID-BYI"},
          {"name":"SAGLE","code":"US-ID-IJE"},
          {"name":"SAINT ANTHONY","code":"US-ID-S7A"},
          {"name":"SALMON","code":"US-ID-SMN"},
          {"name":"SANDPOINT","code":"US-ID-NDP"},
          {"name":"SANTA","code":"US-ID-NXJ"},
          {"name":"SHELLEY","code":"US-ID-SHZ"},
          {"name":"SHOSHONE","code":"US-ID-OHZ"},
          {"name":"SHOSHONE COUNTY","code":"US-ID-GL5"},
          {"name":"SMELTERVILLE","code":"US-ID-YSV"},
          {"name":"SODA SPRINGS","code":"US-ID-YSS"},
          {"name":"SPIRIT LAKE","code":"US-ID-PL8"},
          {"name":"ST. MARIES","code":"US-ID-S2M"},
          {"name":"STANLEY","code":"US-ID-ST4"},
          {"name":"STAR","code":"US-ID-S5R"},
          {"name":"SUGAR CITY","code":"US-ID-HJD"},
          {"name":"SUN VALLEY","code":"US-ID-5SV"},
          {"name":"SUN VALLEY-HAILEY APT","code":"US-ID-SUN"},
          {"name":"SUNNYSLOPE","code":"US-ID-UNP"},
          {"name":"SWAN VALLEY","code":"US-ID-VXA"},
          {"name":"TERRETON","code":"US-ID-TOM"},
          {"name":"TROY","code":"US-ID-TOJ"},
          {"name":"TWIN FALLS","code":"US-ID-TWF"},
          {"name":"VICTOR","code":"US-ID-VOR"},
          {"name":"VIOLA","code":"US-ID-VOA"},
          {"name":"WALLACE","code":"US-ID-AW6"},
          {"name":"WALLACE","code":"US-ID-AWC"},
          {"name":"WEISER","code":"US-ID-IWI"},
          {"name":"WENDELL","code":"US-ID-WDE"},
          {"name":"WESTON","code":"US-ID-WE3"},
          {"name":"WILDER","code":"US-ID-WIJ"},
        ]
      },
      {
        name: 'Illinois',
        code: 'US-IL',
        cities: [
          {"name":"ABBOTT PARK","code":"US-IL-ABJ"},
          {"name":"ABINGDON","code":"US-IL-ABG"},
          {"name":"ADDISON","code":"US-IL-UAP"},
          {"name":"ALBANY","code":"US-IL-AJB"},
          {"name":"ALBERS","code":"US-IL-LXR"},
          {"name":"ALBION","code":"US-IL-AOB"},
          {"name":"ALEDO","code":"US-IL-AD5"},
          {"name":"ALEXANDER","code":"US-IL-ZXA"},
          {"name":"ALEXIS","code":"US-IL-LEV"},
          {"name":"ALGONQUIN","code":"US-IL-AGQ"},
          {"name":"ALORTON","code":"US-IL-ZON"},
          {"name":"ALPHA","code":"US-IL-AJQ"},
          {"name":"ALSEY","code":"US-IL-AY4"},
          {"name":"ALSIP","code":"US-IL-UBC"},
          {"name":"ALTAMONT","code":"US-IL-AZN"},
          {"name":"ALTON","code":"US-IL-ALN"},
          {"name":"AMBOY","code":"US-IL-AMY"},
          {"name":"ANDALUSIA","code":"US-IL-USQ"},
          {"name":"ANNA","code":"US-IL-YRU"},
          {"name":"ANTIOCH","code":"US-IL-TIH"},
          {"name":"ARCOLA","code":"US-IL-AKV"},
          {"name":"ARENZVILLE","code":"US-IL-ARS"},
          {"name":"ARGENTA","code":"US-IL-7AR"},
          {"name":"ARGO","code":"US-IL-XBB"},
          {"name":"ARLINGTON","code":"US-IL-RLG"},
          {"name":"ARLINGTON HEIGHTS","code":"US-IL-JLH"},
          {"name":"AROMA PARK","code":"US-IL-6AP"},
          {"name":"ARTHUR","code":"US-IL-AXH"},
          {"name":"ASHKUM","code":"US-IL-ASM"},
          {"name":"ASHLAND","code":"US-IL-7AI"},
          {"name":"ASHLEY","code":"US-IL-AS4"},
          {"name":"ASHMORE","code":"US-IL-AOH"},
          {"name":"ASHTON","code":"US-IL-AYA"},
          {"name":"ASSUMPTION","code":"US-IL-AMP"},
          {"name":"ASTORIA","code":"US-IL-AT4"},
          {"name":"ATHENS","code":"US-IL-AZH"},
          {"name":"ATKINSON","code":"US-IL-3AI"},
          {"name":"ATLANTA","code":"US-IL-AZI"},
          {"name":"ATTICA","code":"US-IL-AJZ"},
          {"name":"ATWOOD","code":"US-IL-6AT"},
          {"name":"AUBURN","code":"US-IL-UUN"},
          {"name":"AUGUSTA","code":"US-IL-7AU"},
          {"name":"AURORA","code":"US-IL-AUZ"},
          {"name":"AVA","code":"US-IL-A2V"},
          {"name":"AVERY HILL","code":"US-IL-AYB"},
          {"name":"AVON","code":"US-IL-VON"},
          {"name":"BALDWIN","code":"US-IL-ZBN"},
          {"name":"BANNOCKBURN","code":"US-IL-BNN"},
          {"name":"BARRINGTON","code":"US-IL-BRQ"},
          {"name":"BARRY","code":"US-IL-YRB"},
          {"name":"BARTLETT","code":"US-IL-BIJ"},
          {"name":"BARTONVILLE","code":"US-IL-BCJ"},
          {"name":"BASCO","code":"US-IL-B4A"},
          {"name":"BATAVIA","code":"US-IL-BTA"},
          {"name":"BEARDSTOWN","code":"US-IL-BEA"},
          {"name":"BEAVERVILLE","code":"US-IL-VL8"},
          {"name":"BECKEMEYER","code":"US-IL-BEC"},
          {"name":"BEDFORD PARK","code":"US-IL-BDP"},
          {"name":"BEECHER","code":"US-IL-DBC"},
          {"name":"BELLEVILLE","code":"US-IL-BLV"},
          {"name":"BELLEVUE","code":"US-IL-6BV"},
          {"name":"BELLFLOWER","code":"US-IL-B2F"},
          {"name":"BELLWOOD","code":"US-IL-UFA"},
          {"name":"BELOIT","code":"US-IL-BT7"},
          {"name":"BELVIDERE","code":"US-IL-BVJ"},
          {"name":"BEMENT","code":"US-IL-7BM"},
          {"name":"BENSENVILLE","code":"US-IL-BNV"},
          {"name":"BENSON","code":"US-IL-QES"},
          {"name":"BENTON","code":"US-IL-BTQ"},
          {"name":"BERKELEY","code":"US-IL-BKK"},
          {"name":"BERWYN","code":"US-IL-BWY"},
          {"name":"BETHALTO","code":"US-IL-6BT"},
          {"name":"BETHANY","code":"US-IL-BTX"},
          {"name":"BIG ROCK","code":"US-IL-B7R"},
          {"name":"BINGHAMPTON","code":"US-IL-BH2"},
          {"name":"BISMARCK","code":"US-IL-2BS"},
          {"name":"BLANDINSVILLE","code":"US-IL-4BV"},
          {"name":"BLOOMINGDALE","code":"US-IL-OMI"},
          {"name":"BLOOMINGTON","code":"US-IL-B2L"},
          {"name":"BLOOMINGTON-NORMAL APT","code":"US-IL-BMI"},
          {"name":"BLUE ISLAND","code":"US-IL-BCU"},
          {"name":"BLUE MOUND","code":"US-IL-5BM"},
          {"name":"BLUFFS","code":"US-IL-BJU"},
          {"name":"BOLINGBROOK","code":"US-IL-BNK"},
          {"name":"BOURBONNAIS","code":"US-IL-USA"},
          {"name":"BRACEVILLE","code":"US-IL-9BR"},
          {"name":"BRADFORD","code":"US-IL-BDF"},
          {"name":"BRADLEY","code":"US-IL-BYL"},
          {"name":"BRAIDWOOD","code":"US-IL-6BW"},
          {"name":"BREESE","code":"US-IL-BXD"},
          {"name":"BRIDGEPORT","code":"US-IL-4BP"},
          {"name":"BRIDGEVIEW","code":"US-IL-QVW"},
          {"name":"BRIGHTON","code":"US-IL-GXO"},
          {"name":"BRIMFIELD","code":"US-IL-BRM"},
          {"name":"BRISTOL","code":"US-IL-QBX"},
          {"name":"BROADVIEW","code":"US-IL-BRJ"},
          {"name":"BROOKFIELD","code":"US-IL-IBF"},
          {"name":"BROOKPORT","code":"US-IL-8BR"},
          {"name":"BROWNING","code":"US-IL-BI9"},
          {"name":"BROWNSTOWN","code":"US-IL-5BR"},
          {"name":"BUDA","code":"US-IL-ILU"},
          {"name":"BUFFALO","code":"US-IL-IB8"},
          {"name":"BUFFALO GROVE","code":"US-IL-BGO"},
          {"name":"BULL VALLEY","code":"US-IL-BU3"},
          {"name":"BUNKER HILL","code":"US-IL-ZBA"},
          {"name":"BURBANK","code":"US-IL-BUK"},
          {"name":"BUREAU","code":"US-IL-2BU"},
          {"name":"BURLINGTON","code":"US-IL-BL7"},
          {"name":"BURNHAM","code":"US-IL-4BH"},
          {"name":"BURNT PRAIRIE","code":"US-IL-7BN"},
          {"name":"BURR RIDGE","code":"US-IL-BGB"},
          {"name":"BUSHNELL","code":"US-IL-BGC"},
          {"name":"BUTLER","code":"US-IL-QUR"},
          {"name":"BYRON","code":"US-IL-XYB"},
          {"name":"BYRON HILLS ESTATES","code":"US-IL-7BY"},
          {"name":"CACHE","code":"US-IL-ZVC"},
          {"name":"CAHOKIA","code":"US-IL-CK5"},
          {"name":"CAIRO","code":"US-IL-CIR"},
          {"name":"CALHOUN","code":"US-IL-C6H"},
          {"name":"CALUMET CITY","code":"US-IL-CAY"},
          {"name":"CALUMET PARK","code":"US-IL-CUP"},
          {"name":"CAMBRIA","code":"US-IL-9CM"},
          {"name":"CAMBRIDGE","code":"US-IL-2CB"},
          {"name":"CAMP POINT","code":"US-IL-3CP"},
          {"name":"CAMPBELL HILL","code":"US-IL-JPI"},
          {"name":"CANTON","code":"US-IL-CTJ"},
          {"name":"CAPRON","code":"US-IL-CJ7"},
          {"name":"CARBON CLIFF","code":"US-IL-4CC"},
          {"name":"CARBONDALE","code":"US-IL-MDH"},
          {"name":"CARLINVILLE","code":"US-IL-VCN"},
          {"name":"CARLOCK","code":"US-IL-C9A"},
          {"name":"CARLYLE","code":"US-IL-IYC"},
          {"name":"CARMI","code":"US-IL-AMI"},
          {"name":"CAROL STREAM","code":"US-IL-ACE"},
          {"name":"CARPENTERSVILLE","code":"US-IL-CRV"},
          {"name":"CARRIER MILLS","code":"US-IL-CM9"},
          {"name":"CARROLLTON","code":"US-IL-C5K"},
          {"name":"CARTERVILLE","code":"US-IL-4CV"},
          {"name":"CARTHAGE","code":"US-IL-RTG"},
          {"name":"CARY","code":"US-IL-AYI"},
          {"name":"CASEY","code":"US-IL-AJY"},
          {"name":"CASEYVILLE","code":"US-IL-CV9"},
          {"name":"CATLIN","code":"US-IL-8CL"},
          {"name":"CAVE-IN-ROCK","code":"US-IL-VRK"},
          {"name":"CENTERVILLE","code":"US-IL-C2T"},
          {"name":"CENTRAL CITY","code":"US-IL-6CN"},
          {"name":"CENTRALIA","code":"US-IL-ENL"},
          {"name":"CENTREVILLE","code":"US-IL-CIU"},
          {"name":"CERRO GORDO","code":"US-IL-CGJ"},
          {"name":"CHADWICK","code":"US-IL-8CW"},
          {"name":"CHAMPAIGN","code":"US-IL-CMI"},
          {"name":"CHANDLERVILLE","code":"US-IL-5CH"},
          {"name":"CHANNAHON","code":"US-IL-CHH"},
          {"name":"CHARLESTON","code":"US-IL-ILC"},
          {"name":"CHATHAM","code":"US-IL-CZ7"},
          {"name":"CHATSWORTH","code":"US-IL-TS4"},
          {"name":"CHEBANSE","code":"US-IL-CSB"},
          {"name":"CHENOA","code":"US-IL-JHA"},
          {"name":"CHERRY VALLEY","code":"US-IL-7CV"},
          {"name":"CHESTER","code":"US-IL-CSI"},
          {"name":"CHICAGO","code":"US-IL-CHI"},
          {"name":"CHICAGO HEIGHTS","code":"US-IL-CGH"},
          {"name":"CHICAGO RIDGE","code":"US-IL-CRG"},
          {"name":"CHILLICOTHE","code":"US-IL-CH2"},
          {"name":"CHRISMAN","code":"US-IL-HJ9"},
          {"name":"CHRISTOPHER","code":"US-IL-IPH"},
          {"name":"CICERO","code":"US-IL-XDN"},
          {"name":"CISNE","code":"US-IL-9CS"},
          {"name":"CISSNA PARK","code":"US-IL-8CS"},
          {"name":"CLARE","code":"US-IL-QAI"},
          {"name":"CLAREMONT","code":"US-IL-9CL"},
          {"name":"CLARENDON HILL","code":"US-IL-CH4"},
          {"name":"CLARENDON HILLS","code":"US-IL-VNH"},
          {"name":"CLARK","code":"US-IL-6CK"},
          {"name":"CLAY CITY","code":"US-IL-5CT"},
          {"name":"CLAYTON","code":"US-IL-CKY"},
          {"name":"CLEARING","code":"US-IL-CIZ"},
          {"name":"CLEVELAND","code":"US-IL-CL5"},
          {"name":"CLINTON","code":"US-IL-ICC"},
          {"name":"COAL CITY","code":"US-IL-COY"},
          {"name":"COAL VALLEY","code":"US-IL-ZCV"},
          {"name":"COBDEN","code":"US-IL-5CD"},
          {"name":"COFFEEN","code":"US-IL-C5N"},
          {"name":"COLCHESTER","code":"US-IL-DGI"},
          {"name":"COLES","code":"US-IL-7CO"},
          {"name":"COLFAX","code":"US-IL-9CF"},
          {"name":"COLLINSVILLE","code":"US-IL-YCL"},
          {"name":"COLONA","code":"US-IL-2CO"},
          {"name":"COLUMBIA","code":"US-IL-CL6"},
          {"name":"COLUMBUS","code":"US-IL-CB9"},
          {"name":"COMPTON","code":"US-IL-KVO"},
          {"name":"COOKSVILLE","code":"US-IL-KS9"},
          {"name":"CORDOVA","code":"US-IL-ODV"},
          {"name":"CORTLAND","code":"US-IL-TND"},
          {"name":"COTTAGE GROVE","code":"US-IL-QGV"},
          {"name":"COTTAGE HILLS","code":"US-IL-2CH"},
          {"name":"COULTERVILLE","code":"US-IL-CV6"},
          {"name":"COUNTRY CLUB HILLS","code":"US-IL-CU9"},
          {"name":"COUNTRY SIDE","code":"US-IL-CBJ"},
          {"name":"COUNTRYSIDE","code":"US-IL-3CS"},
          {"name":"CREAL SPRINGS","code":"US-IL-EFP"},
          {"name":"CREST HILL","code":"US-IL-CH5"},
          {"name":"CRESTWOOD","code":"US-IL-CWB"},
          {"name":"CRETE","code":"US-IL-CQQ"},
          {"name":"CREVE COEUR","code":"US-IL-CXD"},
          {"name":"CROPSEY","code":"US-IL-3RP"},
          {"name":"CROSSVILLE","code":"US-IL-7CR"},
          {"name":"CRYSTAL LAKE","code":"US-IL-CTD"},
          {"name":"CRYSTAL LAWNS","code":"US-IL-2CL"},
          {"name":"CUBA","code":"US-IL-JCB"},
          {"name":"CUMBERLAND","code":"US-IL-4CM"},
          {"name":"CUTLER","code":"US-IL-XUT"},
          {"name":"DAHLGREN","code":"US-IL-DQG"},
          {"name":"DAKOTA","code":"US-IL-DKO"},
          {"name":"DALLAS CITY","code":"US-IL-DCX"},
          {"name":"DALTON CITY","code":"US-IL-DK9"},
          {"name":"DANVERS","code":"US-IL-YYS"},
          {"name":"DANVILLE","code":"US-IL-DVI"},
          {"name":"DARIEN","code":"US-IL-DII"},
          {"name":"DAVIS JUNCTION","code":"US-IL-DJ9"},
          {"name":"DAYTON","code":"US-IL-DT5"},
          {"name":"DE KALB","code":"US-IL-DEK"},
          {"name":"DE SOTO","code":"US-IL-OT4"},
          {"name":"DECATUR","code":"US-IL-DEC"},
          {"name":"DEER GROVE","code":"US-IL-DGR"},
          {"name":"DEER PARK","code":"US-IL-FJY"},
          {"name":"DEERFIELD","code":"US-IL-DRD"},
          {"name":"DEKALB","code":"US-IL-DCB"},
          {"name":"DELAVAN","code":"US-IL-D9E"},
          {"name":"DEPUE","code":"US-IL-DE5"},
          {"name":"DES PLAINES","code":"US-IL-DES"},
          {"name":"DETROIT","code":"US-IL-DTT"},
          {"name":"DEWITT","code":"US-IL-DEZ"},
          {"name":"DIAMOND LAKE","code":"US-IL-2DK"},
          {"name":"DIETERICH","code":"US-IL-D2H"},
          {"name":"DIVERNON","code":"US-IL-4DV"},
          {"name":"DIX","code":"US-IL-7DI"},
          {"name":"DIXMOOR","code":"US-IL-DMR"},
          {"name":"DIXON","code":"US-IL-DXN"},
          {"name":"DOLTON","code":"US-IL-DQN"},
          {"name":"DONGOLA","code":"US-IL-DNG"},
          {"name":"DONOVAN","code":"US-IL-ZDV"},
          {"name":"DORCHESTER","code":"US-IL-DQH"},
          {"name":"DOUGLAS","code":"US-IL-3DL"},
          {"name":"DOVER","code":"US-IL-DER"},
          {"name":"DOWNERS GROVE","code":"US-IL-UFO"},
          {"name":"DOWNS","code":"US-IL-4DO"},
          {"name":"DU QUOIN","code":"US-IL-DUU"},
          {"name":"DUBOIS","code":"US-IL-DB5"},
          {"name":"DUNDEE","code":"US-IL-DYI"},
          {"name":"DUNLAP","code":"US-IL-DJR"},
          {"name":"DUPO","code":"US-IL-DUP"},
          {"name":"DUQUOIN","code":"US-IL-DUQ"},
          {"name":"DURAND","code":"US-IL-DDI"},
          {"name":"DWIGHT","code":"US-IL-DWI"},
          {"name":"EARLVILLE","code":"US-IL-8EV"},
          {"name":"EAST ALTON","code":"US-IL-EOL"},
          {"name":"EAST CARONDELET","code":"US-IL-TCD"},
          {"name":"EAST CLINTON","code":"US-IL-ECL"},
          {"name":"EAST DUBUQUE","code":"US-IL-EQE"},
          {"name":"EAST DUNDEE","code":"US-IL-9ED"},
          {"name":"EAST HAZEL CREST","code":"US-IL-EHC"},
          {"name":"EAST MOLINE","code":"US-IL-EMN"},
          {"name":"EAST PEORIA","code":"US-IL-EPO"},
          {"name":"EAST SAINT LOUIS","code":"US-IL-ESL"},
          {"name":"EDWARDS","code":"US-IL-EZD"},
          {"name":"EDWARDSVILLE","code":"US-IL-EWV"},
          {"name":"EFFINGHAM","code":"US-IL-EFI"},
          {"name":"EL PASO","code":"US-IL-EPX"},
          {"name":"ELBURN","code":"US-IL-EB2"},
          {"name":"ELCO","code":"US-IL-EQO"},
          {"name":"ELDORADO","code":"US-IL-ED2"},
          {"name":"ELGIN","code":"US-IL-EGN"},
          {"name":"ELIZABETH","code":"US-IL-6EZ"},
          {"name":"ELIZABETHTOWN","code":"US-IL-EZ7"},
          {"name":"ELK GROVE VILLAGE","code":"US-IL-EVI"},
          {"name":"ELKHART","code":"US-IL-EKJ"},
          {"name":"ELKVILLE","code":"US-IL-5EK"},
          {"name":"ELLIS GROVE","code":"US-IL-EUQ"},
          {"name":"ELLSWORTH","code":"US-IL-EXH"},
          {"name":"ELMHURST","code":"US-IL-ELR"},
          {"name":"ELMWOOD","code":"US-IL-EXW"},
          {"name":"ELMWOOD PARK","code":"US-IL-EMW"},
          {"name":"ELSAH","code":"US-IL-4EL"},
          {"name":"ELWOOD","code":"US-IL-EWI"},
          {"name":"EMDEN","code":"US-IL-EJX"},
          {"name":"ENERGY","code":"US-IL-ERG"},
          {"name":"ENFIELD","code":"US-IL-6EF"},
          {"name":"EQUALITY","code":"US-IL-QIY"},
          {"name":"ERIE","code":"US-IL-ERZ"},
          {"name":"EUREKA","code":"US-IL-2EK"},
          {"name":"EVANSTON","code":"US-IL-EVD"},
          {"name":"EVANSVILLE","code":"US-IL-EVN"},
          {"name":"EVERGREEN PARK","code":"US-IL-EP3"},
          {"name":"FAIRBURY","code":"US-IL-FB2"},
          {"name":"FAIRFIELD","code":"US-IL-FAD"},
          {"name":"FAIRFIELD","code":"US-IL-YFL"},
          {"name":"FAIRMONT","code":"US-IL-3FR"},
          {"name":"FAIRMONT CITY","code":"US-IL-QFY"},
          {"name":"FAIRMONT, WILL","code":"US-IL-FMW"},
          {"name":"FAIRMOUNT","code":"US-IL-FIO"},
          {"name":"FAIRVIEW","code":"US-IL-4FV"},
          {"name":"FAIRVIEW HEIGHTS","code":"US-IL-FIH"},
          {"name":"FARGO","code":"US-IL-ZFG"},
          {"name":"FARINA","code":"US-IL-5FI"},
          {"name":"FARMER CITY","code":"US-IL-5FC"},
          {"name":"FARMERSVILLE","code":"US-IL-F2V"},
          {"name":"FARMINGDALE","code":"US-IL-8FD"},
          {"name":"FARMINGTON","code":"US-IL-7FG"},
          {"name":"FAYETTE","code":"US-IL-3FY"},
          {"name":"FAYETTEVILLE","code":"US-IL-FYZ"},
          {"name":"FINDLAY","code":"US-IL-FY8"},
          {"name":"FISHER","code":"US-IL-FHR"},
          {"name":"FITHIAN","code":"US-IL-IHN"},
          {"name":"FLANAGAN","code":"US-IL-FG7"},
          {"name":"FLAT ROCK","code":"US-IL-LTK"},
          {"name":"FLORA","code":"US-IL-AEA"},
          {"name":"FLOSSMOOR","code":"US-IL-FSX"},
          {"name":"FORD HEIGHTS","code":"US-IL-FHG"},
          {"name":"FOREST CITY","code":"US-IL-FCC"},
          {"name":"FOREST PARK","code":"US-IL-FPA"},
          {"name":"FOREST VIEW","code":"US-IL-FTW"},
          {"name":"FORREST","code":"US-IL-FZE"},
          {"name":"FORRESTON","code":"US-IL-FRB"},
          {"name":"FORSYTH","code":"US-IL-FYH"},
          {"name":"FORT SHERIDAN","code":"US-IL-FSN"},
          {"name":"FOWLER","code":"US-IL-FW7"},
          {"name":"FOX LAKE","code":"US-IL-F8X"},
          {"name":"FOX RIVER GROVE","code":"US-IL-FOZ"},
          {"name":"FRANKFORT","code":"US-IL-FJK"},
          {"name":"FRANKLIN PARK","code":"US-IL-FLI"},
          {"name":"FREEBURG","code":"US-IL-FBU"},
          {"name":"FREEPORT","code":"US-IL-FEP"},
          {"name":"FULTON","code":"US-IL-ZFL"},
          {"name":"GALENA","code":"US-IL-VKA"},
          {"name":"GALESBURG","code":"US-IL-GBG"},
          {"name":"GALVA","code":"US-IL-GVS"},
          {"name":"GARDNER","code":"US-IL-GJD"},
          {"name":"GENESEO","code":"US-IL-ZGZ"},
          {"name":"GENEVA","code":"US-IL-GNI"},
          {"name":"GENOA","code":"US-IL-GOI"},
          {"name":"GEORGETOWN, FAYETTE","code":"US-IL-GWN"},
          {"name":"GERMANTOWN","code":"US-IL-GEA"},
          {"name":"GIBSON CITY","code":"US-IL-GBC"},
          {"name":"GILBERTS","code":"US-IL-YOG"},
          {"name":"GILLESPIE","code":"US-IL-G3L"},
          {"name":"GILMAN","code":"US-IL-GMA"},
          {"name":"GILSON","code":"US-IL-GIK"},
          {"name":"GIRARD","code":"US-IL-IAR"},
          {"name":"GLEN ELLYN","code":"US-IL-GEL"},
          {"name":"GLEN VIEW","code":"US-IL-GVX"},
          {"name":"GLENCOE","code":"US-IL-EOE"},
          {"name":"GLENDALE HEIGHTS","code":"US-IL-G2H"},
          {"name":"GLENDALE HGHTS","code":"US-IL-GHI"},
          {"name":"GLENVIEW","code":"US-IL-NBU"},
          {"name":"GLENWOOD","code":"US-IL-OOG"},
          {"name":"GODFREY","code":"US-IL-GDF"},
          {"name":"GOLDEN","code":"US-IL-JGN"},
          {"name":"GOLF","code":"US-IL-QGL"},
          {"name":"GOODFIELD","code":"US-IL-GF4"},
          {"name":"GRAND CHAIN","code":"US-IL-GC5"},
          {"name":"GRAND RIDGE","code":"US-IL-GYJ"},
          {"name":"GRANDVILLE","code":"US-IL-U4B"},
          {"name":"GRANITE CITY","code":"US-IL-GZI"},
          {"name":"GRANT PARK","code":"US-IL-GRK"},
          {"name":"GRANVILLE","code":"US-IL-ZVG"},
          {"name":"GRAYSLAKE","code":"US-IL-GYL"},
          {"name":"GRAYVILLE","code":"US-IL-GQE"},
          {"name":"GREAT LAKES","code":"US-IL-GRL"},
          {"name":"GREEN OAKS","code":"US-IL-ZDM"},
          {"name":"GREENFIELD","code":"US-IL-G6R"},
          {"name":"GREENUP","code":"US-IL-GU2"},
          {"name":"GREENVIEW","code":"US-IL-USI"},
          {"name":"GREENVILLE","code":"US-IL-GRE"},
          {"name":"GREENWOOD","code":"US-IL-GW5"},
          {"name":"GRIDLEY","code":"US-IL-XFY"},
          {"name":"GRIGGSVILLE","code":"US-IL-GGF"},
          {"name":"GURNEE","code":"US-IL-GUR"},
          {"name":"HAMEL","code":"US-IL-QHL"},
          {"name":"HAMILTON","code":"US-IL-ZDX"},
          {"name":"HAMMOND","code":"US-IL-HM9"},
          {"name":"HAMPSHIRE","code":"US-IL-HPS"},
          {"name":"HANOVER","code":"US-IL-QHV"},
          {"name":"HANOVER PARK","code":"US-IL-HPK"},
          {"name":"HARDIN","code":"US-IL-4HD"},
          {"name":"HARMON","code":"US-IL-HVO"},
          {"name":"HARRISBURG","code":"US-IL-HSB"},
          {"name":"HARRISTOWN","code":"US-IL-HS9"},
          {"name":"HARTFORD","code":"US-IL-HFI"},
          {"name":"HARVARD","code":"US-IL-HRU"},
          {"name":"HARVEY","code":"US-IL-HRY"},
          {"name":"HARWOOD HEIGHTS","code":"US-IL-HHT"},
          {"name":"HAVANA","code":"US-IL-HVV"},
          {"name":"HAZEL CREST","code":"US-IL-HZS"},
          {"name":"HEBRON","code":"US-IL-HEO"},
          {"name":"HENNEPIN","code":"US-IL-NNP"},
          {"name":"HENNING","code":"US-IL-HN6"},
          {"name":"HENRY","code":"US-IL-HYY"},
          {"name":"HEROD","code":"US-IL-KHD"},
          {"name":"HERRIN","code":"US-IL-HER"},
          {"name":"HETTICK","code":"US-IL-HK9"},
          {"name":"HEYWORTH","code":"US-IL-HYW"},
          {"name":"HICKORY HILLS, COOK","code":"US-IL-HHC"},
          {"name":"HIDALGO","code":"US-IL-HI8"},
          {"name":"HIGHLAND","code":"US-IL-HGL"},
          {"name":"HIGHLAND PARK","code":"US-IL-HIA"},
          {"name":"HIGHWOOD","code":"US-IL-HH8"},
          {"name":"HILLSDALE","code":"US-IL-KHI"},
          {"name":"HILLSIDE","code":"US-IL-UJO"},
          {"name":"HINCKLEY","code":"US-IL-4HN"},
          {"name":"HINSDALE","code":"US-IL-HSA"},
          {"name":"HODGKINS","code":"US-IL-HOK"},
          {"name":"HOFFMAN ESTATES","code":"US-IL-HME"},
          {"name":"HOLCOMB","code":"US-IL-HCM"},
          {"name":"HOLLAND, EFFINGHAM","code":"US-IL-HL6"},
          {"name":"HOMER GLEN","code":"US-IL-AOF"},
          {"name":"HOMEWOOD","code":"US-IL-HMW"},
          {"name":"HOOPESTON","code":"US-IL-HUP"},
          {"name":"HUDSON","code":"US-IL-HCU"},
          {"name":"HUNTLEY","code":"US-IL-HTQ"},
          {"name":"ILLINOIS CITY","code":"US-IL-ZFT"},
          {"name":"ILLIOPOLIS","code":"US-IL-ILP"},
          {"name":"INDIANOLA","code":"US-IL-I7D"},
          {"name":"INGLESIDE","code":"US-IL-IEG"},
          {"name":"INVERNESS","code":"US-IL-IVS"},
          {"name":"ISLAND LAKE","code":"US-IL-I8L"},
          {"name":"ITASCA","code":"US-IL-ITS"},
          {"name":"IVESDALE","code":"US-IL-VDE"},
          {"name":"JACKSONVILLE","code":"US-IL-IJX"},
          {"name":"JANESVILLE","code":"US-IL-JVV"},
          {"name":"JERSEYVILLE","code":"US-IL-YJY"},
          {"name":"JOHNSBURG","code":"US-IL-JBG"},
          {"name":"JOHNSONVILLE","code":"US-IL-JS8"},
          {"name":"JOLIET","code":"US-IL-JOT"},
          {"name":"JOPPA","code":"US-IL-JPA"},
          {"name":"JOSLIN","code":"US-IL-JSL"},
          {"name":"JUSTICE","code":"US-IL-JTC"},
          {"name":"KANEVILLE","code":"US-IL-KAV"},
          {"name":"KANKAKEE","code":"US-IL-IKK"},
          {"name":"KEITHSBURG","code":"US-IL-KBU"},
          {"name":"KENILWORTH","code":"US-IL-KWR"},
          {"name":"KENNEY","code":"US-IL-KQS"},
          {"name":"KEWANEE","code":"US-IL-KEW"},
          {"name":"KEYESPORT","code":"US-IL-KEE"},
          {"name":"KILDEER","code":"US-IL-KD4"},
          {"name":"KINMUNDY","code":"US-IL-KMU"},
          {"name":"KINSMAN","code":"US-IL-KFM"},
          {"name":"KIRKLAND","code":"US-IL-KKZ"},
          {"name":"KNOXVILLE","code":"US-IL-OXV"},
          {"name":"LA FAYETTE","code":"US-IL-VLF"},
          {"name":"LA FOX","code":"US-IL-LN6"},
          {"name":"LA GRANGE","code":"US-IL-LAI"},
          {"name":"LA GRANGE HIGHLANDS","code":"US-IL-LGZ"},
          {"name":"LA GRANGE PARK","code":"US-IL-LPG"},
          {"name":"LA HARPE","code":"US-IL-LA4"},
          {"name":"LA SALLE","code":"US-IL-UJY"},
          {"name":"LACON","code":"US-IL-L7A"},
          {"name":"LADD","code":"US-IL-LDD"},
          {"name":"LAFAYETTE","code":"US-IL-LA7"},
          {"name":"LAKE BARRINGTON","code":"US-IL-KBO"},
          {"name":"LAKE BLUFF","code":"US-IL-LBC"},
          {"name":"LAKE FOREST","code":"US-IL-LFR"},
          {"name":"LAKE IN THE HILLS","code":"US-IL-LKI"},
          {"name":"LAKE VILLA","code":"US-IL-VIL"},
          {"name":"LAKE ZURICH","code":"US-IL-LKZ"},
          {"name":"LAKEMOOR","code":"US-IL-ZZS"},
          {"name":"LAKEWOOD","code":"US-IL-L8S"},
          {"name":"LANARK","code":"US-IL-NAR"},
          {"name":"LANSING","code":"US-IL-LSI"},
          {"name":"LAWRENCEVILLE","code":"US-IL-LWV"},
          {"name":"LE ROY","code":"US-IL-LRQ"},
          {"name":"LEBANON","code":"US-IL-LVB"},
          {"name":"LEE","code":"US-IL-LJE"},
          {"name":"LELAND","code":"US-IL-4LL"},
          {"name":"LEMMON","code":"US-IL-LMC"},
          {"name":"LEMONT","code":"US-IL-LMJ"},
          {"name":"LENA","code":"US-IL-LEN"},
          {"name":"LENOX","code":"US-IL-LXK"},
          {"name":"LEWISTOWN","code":"US-IL-L2T"},
          {"name":"LEXINGTON","code":"US-IL-LXT"},
          {"name":"LIBERTY","code":"US-IL-IBX"},
          {"name":"LIBERTYVILLE","code":"US-IL-LBV"},
          {"name":"LINCOLN","code":"US-IL-LCL"},
          {"name":"LINCOLN PARK, CHICAGO","code":"US-IL-LPD"},
          {"name":"LINCOLNSHIRE","code":"US-IL-LCO"},
          {"name":"LINCOLNWOOD","code":"US-IL-LCW"},
          {"name":"LINDENHURST","code":"US-IL-LH4"},
          {"name":"LINDENWOOD","code":"US-IL-ZF9"},
          {"name":"LISLE","code":"US-IL-LSA"},
          {"name":"LITCHFIELD","code":"US-IL-XHZ"},
          {"name":"LOCKPORT","code":"US-IL-LOT"},
          {"name":"LODA","code":"US-IL-XWX"},
          {"name":"LOGAN, EDGAR","code":"US-IL-UZQ"},
          {"name":"LOMBARD","code":"US-IL-LOM"},
          {"name":"LONDON MILLS","code":"US-IL-L4O"},
          {"name":"LONG GROVE","code":"US-IL-LGG"},
          {"name":"LONGVIEW","code":"US-IL-JLG"},
          {"name":"LOSTANT","code":"US-IL-UZT"},
          {"name":"LOUISVILLE","code":"US-IL-ZF8"},
          {"name":"LOVES PARK","code":"US-IL-QLI"},
          {"name":"LOVINGTON","code":"US-IL-IOI"},
          {"name":"LYNWOOD","code":"US-IL-LYW"},
          {"name":"LYONS","code":"US-IL-LYS"},
          {"name":"MACEDONIA","code":"US-IL-M9Z"},
          {"name":"MACHESNEY PARK","code":"US-IL-MP5"},
          {"name":"MACKINAW","code":"US-IL-KWZ"},
          {"name":"MACOMB","code":"US-IL-MQB"},
          {"name":"MACON","code":"US-IL-MO8"},
          {"name":"MADISON","code":"US-IL-MZX"},
          {"name":"MAHOMET","code":"US-IL-JMM"},
          {"name":"MAKANDA","code":"US-IL-MK7"},
          {"name":"MALTA","code":"US-IL-TQM"},
          {"name":"MANHATTAN","code":"US-IL-HM8"},
          {"name":"MANITO","code":"US-IL-ILT"},
          {"name":"MANTENO","code":"US-IL-MTR"},
          {"name":"MAPLE GROVE","code":"US-IL-MPG"},
          {"name":"MAPLE PARK","code":"US-IL-MEP"},
          {"name":"MAPLETON","code":"US-IL-XIS"},
          {"name":"MARBLEHEAD","code":"US-IL-4MH"},
          {"name":"MARENGO","code":"US-IL-REG"},
          {"name":"MARIETTA","code":"US-IL-T6T"},
          {"name":"MARION","code":"US-IL-MWA"},
          {"name":"MARISSA","code":"US-IL-JMA"},
          {"name":"MARK","code":"US-IL-AWK"},
          {"name":"MARKHAM","code":"US-IL-MKM"},
          {"name":"MAROA","code":"US-IL-MUR"},
          {"name":"MARSEILLES","code":"US-IL-MR2"},
          {"name":"MARSHALL","code":"US-IL-ML8"},
          {"name":"MARTINSVILLE","code":"US-IL-IIE"},
          {"name":"MARYVILLE","code":"US-IL-VLP"},
          {"name":"MARYVILLE","code":"US-IL-YIB"},
          {"name":"MASCOUTAH","code":"US-IL-MSK"},
          {"name":"MASON CITY","code":"US-IL-M2C"},
          {"name":"MATTESON","code":"US-IL-AES"},
          {"name":"MATTOON","code":"US-IL-MTO"},
          {"name":"MAYWOOD/CHICAGO","code":"US-IL-MWQ"},
          {"name":"MAZON","code":"US-IL-MZF"},
          {"name":"MCCOOK","code":"US-IL-MCO"},
          {"name":"MCGAW PARK","code":"US-IL-MWK"},
          {"name":"MCHENRY","code":"US-IL-MHB"},
          {"name":"MCLEANSBORO","code":"US-IL-IMQ"},
          {"name":"MECHANICSBURG","code":"US-IL-HCG"},
          {"name":"MEDINAH","code":"US-IL-EQH"},
          {"name":"MELROSE PARK","code":"US-IL-MRP"},
          {"name":"MELVIN","code":"US-IL-ZVM"},
          {"name":"MENDOTA","code":"US-IL-MEN"},
          {"name":"MEREDOSIA","code":"US-IL-OIS"},
          {"name":"METAMORA","code":"US-IL-EOA"},
          {"name":"METROPOLIS","code":"US-IL-MET"},
          {"name":"METTAWA","code":"US-IL-MT2"},
          {"name":"MICHAEL","code":"US-IL-MI7"},
          {"name":"MIDDLETON","code":"US-IL-MT3"},
          {"name":"MIDLOTHIAN","code":"US-IL-MLH"},
          {"name":"MIDWAY","code":"US-IL-DBD"},
          {"name":"MILAN","code":"US-IL-MIQ"},
          {"name":"MILFORD","code":"US-IL-MFF"},
          {"name":"MILLEDGEVILLE","code":"US-IL-QLB"},
          {"name":"MILLSDALE","code":"US-IL-KYA"},
          {"name":"MILLSTADT","code":"US-IL-MS7"},
          {"name":"MINONK","code":"US-IL-M4K"},
          {"name":"MINOOKA","code":"US-IL-IKA"},
          {"name":"MOKENA","code":"US-IL-IMK"},
          {"name":"MOLINE","code":"US-IL-MLI"},
          {"name":"MOMENCE","code":"US-IL-MME"},
          {"name":"MONEE","code":"US-IL-QZE"},
          {"name":"MONMOUTH","code":"US-IL-MNI"},
          {"name":"MONTGOMERY","code":"US-IL-XIL"},
          {"name":"MONTICELLO","code":"US-IL-MQD"},
          {"name":"MORO","code":"US-IL-O4O"},
          {"name":"MORRIS","code":"US-IL-MSW"},
          {"name":"MORRISON","code":"US-IL-MMQ"},
          {"name":"MORRISONVILLE","code":"US-IL-M5V"},
          {"name":"MORTON","code":"US-IL-MTI"},
          {"name":"MORTON GROVE","code":"US-IL-MGV"},
          {"name":"MOSSVILLE","code":"US-IL-MVJ"},
          {"name":"MOUND CITY","code":"US-IL-4MC"},
          {"name":"MOUNDS","code":"US-IL-7Q9"},
          {"name":"MOUNT CARMEL","code":"US-IL-MC6"},
          {"name":"MOUNT CARROLL","code":"US-IL-KRL"},
          {"name":"MOUNT ERIE","code":"US-IL-IUE"},
          {"name":"MOUNT MORRIS","code":"US-IL-MXR"},
          {"name":"MOUNT PROSPECT","code":"US-IL-MUP"},
          {"name":"MOUNT PULASKI","code":"US-IL-MPK"},
          {"name":"MOUNT STERLING","code":"US-IL-GTM"},
          {"name":"MOUNT VERNON","code":"US-IL-MVN"},
          {"name":"MOUNT ZION","code":"US-IL-UTZ"},
          {"name":"MOWEAQUA","code":"US-IL-YMQ"},
          {"name":"MUNCIE","code":"US-IL-MUB"},
          {"name":"MUNDELEIN","code":"US-IL-MUJ"},
          {"name":"MURDOCK","code":"US-IL-MR5"},
          {"name":"MURPHYSBORO","code":"US-IL-M9U"},
          {"name":"NAPERVILLE","code":"US-IL-NPV"},
          {"name":"NAPLES","code":"US-IL-N6P"},
          {"name":"NASHVILLE","code":"US-IL-NXV"},
          {"name":"NATIONAL CITY","code":"US-IL-NCI"},
          {"name":"NAUVOO","code":"US-IL-NV7"},
          {"name":"NEOGA","code":"US-IL-NE2"},
          {"name":"NEPONSET","code":"US-IL-N8P"},
          {"name":"NEW BADEN","code":"US-IL-NDI"},
          {"name":"NEW BEDFORD","code":"US-IL-EFC"},
          {"name":"NEW BERLIN","code":"US-IL-8LL"},
          {"name":"NEW HAVEN","code":"US-IL-NVQ"},
          {"name":"NEW LENOX","code":"US-IL-NOX"},
          {"name":"NEW SALEM","code":"US-IL-NZS"},
          {"name":"NEWARK","code":"US-IL-NKW"},
          {"name":"NEWTON","code":"US-IL-NMQ"},
          {"name":"NILES","code":"US-IL-NIL"},
          {"name":"NOBLE","code":"US-IL-NO9"},
          {"name":"NORMAL","code":"US-IL-NOM"},
          {"name":"NORRIDGE","code":"US-IL-NRF"},
          {"name":"NORRIS","code":"US-IL-OZS"},
          {"name":"NORTH AURORA","code":"US-IL-NOA"},
          {"name":"NORTH BARRINGTON","code":"US-IL-5NB"},
          {"name":"NORTH CHICAGO","code":"US-IL-NTA"},
          {"name":"NORTH PEKIN","code":"US-IL-N4P"},
          {"name":"NORTH RIVERSIDE","code":"US-IL-NRU"},
          {"name":"NORTH VENICE","code":"US-IL-NVZ"},
          {"name":"NORTHBROOK","code":"US-IL-OBK"},
          {"name":"NORTHFIELD","code":"US-IL-XJO"},
          {"name":"NORTHLAKE","code":"US-IL-NTI"},
          {"name":"O'FALLON","code":"US-IL-OFA"},
          {"name":"O'HARE APT/CHICAGO","code":"US-IL-ORD"},
          {"name":"OAK BROOK","code":"US-IL-QHO"},
          {"name":"OAK FOREST","code":"US-IL-OFR"},
          {"name":"OAK GROVE","code":"US-IL-QOG"},
          {"name":"OAK LAWN","code":"US-IL-OKL"},
          {"name":"OAK PARK","code":"US-IL-QOP"},
          {"name":"OAKBROOK TERRACE","code":"US-IL-OT2"},
          {"name":"OAKDALE","code":"US-IL-ODH"},
          {"name":"OAKLAND","code":"US-IL-OUF"},
          {"name":"OAKLEY","code":"US-IL-KLY"},
          {"name":"OBLONG","code":"US-IL-O6G"},
          {"name":"OGDEN","code":"US-IL-OGZ"},
          {"name":"OGLESBY","code":"US-IL-OGY"},
          {"name":"OHIO","code":"US-IL-OH3"},
          {"name":"OKAWVILLE","code":"US-IL-QXW"},
          {"name":"OLIVE BRANCH","code":"US-IL-OQB"},
          {"name":"OLMSTED","code":"US-IL-OZM"},
          {"name":"OLNEY","code":"US-IL-OLY"},
          {"name":"OLYMPIA FIELDS","code":"US-IL-OAN"},
          {"name":"OMAHA","code":"US-IL-OM3"},
          {"name":"ONARGA","code":"US-IL-ON2"},
          {"name":"ORANGEVILLE","code":"US-IL-OAG"},
          {"name":"OREGON","code":"US-IL-OGI"},
          {"name":"ORION","code":"US-IL-OR2"},
          {"name":"ORLAND PARK","code":"US-IL-XKC"},
          {"name":"OSCO","code":"US-IL-OC3"},
          {"name":"OSWEGO","code":"US-IL-OSG"},
          {"name":"OTTAWA","code":"US-IL-OTW"},
          {"name":"PALATINE","code":"US-IL-PLE"},
          {"name":"PALESTINE","code":"US-IL-ZH4"},
          {"name":"PALOS HEIGHTS","code":"US-IL-P9H"},
          {"name":"PALOS HILLS","code":"US-IL-PH5"},
          {"name":"PALOS PARK","code":"US-IL-PP2"},
          {"name":"PANA","code":"US-IL-PN2"},
          {"name":"PARIS","code":"US-IL-PRP"},
          {"name":"PARK CITY","code":"US-IL-PKC"},
          {"name":"PARK FOREST","code":"US-IL-PFO"},
          {"name":"PARK RIDGE","code":"US-IL-PKG"},
          {"name":"PATOKA","code":"US-IL-P5K"},
          {"name":"PAWNEE","code":"US-IL-PWE"},
          {"name":"PAXTON","code":"US-IL-PFB"},
          {"name":"PEARL CITY","code":"US-IL-PEZ"},
          {"name":"PECATONICA","code":"US-IL-ECT"},
          {"name":"PEKIN","code":"US-IL-PKI"},
          {"name":"PEORIA","code":"US-IL-PIA"},
          {"name":"PEORIA HEIGHTS","code":"US-IL-PHB"},
          {"name":"PEOTONE","code":"US-IL-PJN"},
          {"name":"PERU","code":"US-IL-VYS"},
          {"name":"PETERSBURG, MENARD","code":"US-IL-PB2"},
          {"name":"PHOENIX","code":"US-IL-XPN"},
          {"name":"PINCKNEYVILLE","code":"US-IL-YVP"},
          {"name":"PINGREE GROVE","code":"US-IL-IEE"},
          {"name":"PIPER CITY","code":"US-IL-PC4"},
          {"name":"PITTSBURG","code":"US-IL-P7B"},
          {"name":"PITTSFIELD","code":"US-IL-TFE"},
          {"name":"PLAINES","code":"US-IL-PLG"},
          {"name":"PLAINFIELD","code":"US-IL-PLI"},
          {"name":"PLANO","code":"US-IL-PNO"},
          {"name":"PLEASANT HILL","code":"US-IL-PT9"},
          {"name":"PLEASANT PLAINS","code":"US-IL-PPL"},
          {"name":"PLYMOUTH","code":"US-IL-YIQ"},
          {"name":"POLO","code":"US-IL-POO"},
          {"name":"POMONA","code":"US-IL-PQO"},
          {"name":"PONTIAC","code":"US-IL-POI"},
          {"name":"PONTOON BEACH","code":"US-IL-PNB"},
          {"name":"POPLAR GROVE","code":"US-IL-8AA"},
          {"name":"PORT BYRON","code":"US-IL-4PB"},
          {"name":"POSEN","code":"US-IL-PZO"},
          {"name":"PRAIRIE VIEW","code":"US-IL-2LL"},
          {"name":"PRINCETON","code":"US-IL-PCO"},
          {"name":"PRINCEVILLE","code":"US-IL-PNZ"},
          {"name":"PROPHETSTOWN","code":"US-IL-PPN"},
          {"name":"PROSPECT","code":"US-IL-PP8"},
          {"name":"PROSPECT HEIGHTS","code":"US-IL-PPG"},
          {"name":"QUINCY","code":"US-IL-UIN"},
          {"name":"RADOM","code":"US-IL-RM9"},
          {"name":"RALEIGH","code":"US-IL-RA6"},
          {"name":"RANTOUL","code":"US-IL-RNL"},
          {"name":"RED BUD","code":"US-IL-RRB"},
          {"name":"REYNOLDS","code":"US-IL-4RD"},
          {"name":"RICHMOND","code":"US-IL-RMN"},
          {"name":"RICHTON PARK","code":"US-IL-R5P"},
          {"name":"RICHTON PARK","code":"US-IL-RPJ"},
          {"name":"RIDGWAY","code":"US-IL-RJW"},
          {"name":"RIDOTT","code":"US-IL-RZT"},
          {"name":"RINGWOOD","code":"US-IL-RIO"},
          {"name":"RIVER FOREST","code":"US-IL-RVO"},
          {"name":"RIVER GROVE","code":"US-IL-RG6"},
          {"name":"RIVER GROVE/CHICAGO","code":"US-IL-RIG"},
          {"name":"RIVERDALE","code":"US-IL-RRD"},
          {"name":"RIVERSIDE","code":"US-IL-IVD"},
          {"name":"RIVERTON","code":"US-IL-RN7"},
          {"name":"RIVERWOODS","code":"US-IL-VWD"},
          {"name":"ROANOKE","code":"US-IL-RQX"},
          {"name":"ROBERTS","code":"US-IL-R2O"},
          {"name":"ROBINSON","code":"US-IL-ROB"},
          {"name":"ROCHELLE","code":"US-IL-RCX"},
          {"name":"ROCHESTER","code":"US-IL-ROH"},
          {"name":"ROCK CITY","code":"US-IL-RC8"},
          {"name":"ROCK FALLS","code":"US-IL-RFI"},
          {"name":"ROCK ISLAND","code":"US-IL-RKI"},
          {"name":"ROCKDALE","code":"US-IL-ROE"},
          {"name":"ROCKFORD","code":"US-IL-RFD"},
          {"name":"ROCKPORT","code":"US-IL-R63"},
          {"name":"ROCKTON","code":"US-IL-RKT"},
          {"name":"ROCKVILLE TOWNSHIP","code":"US-IL-R7P"},
          {"name":"ROCKWOOD","code":"US-IL-KOO"},
          {"name":"ROLLING MEADOWS","code":"US-IL-RLM"},
          {"name":"ROMEOVILLE","code":"US-IL-ROI"},
          {"name":"ROODHOUSE","code":"US-IL-RO3"},
          {"name":"ROSCOE","code":"US-IL-RSQ"},
          {"name":"ROSELLE","code":"US-IL-RLL"},
          {"name":"ROSEMONT","code":"US-IL-RSI"},
          {"name":"ROSICLAIRE","code":"US-IL-RO9"},
          {"name":"ROSICLARE","code":"US-IL-RSK"},
          {"name":"ROSSVILLE","code":"US-IL-RXL"},
          {"name":"ROUND LAKE","code":"US-IL-RUC"},
          {"name":"ROUND LAKE BEACH","code":"US-IL-UDH"},
          {"name":"ROUND LAKE PARK","code":"US-IL-ULP"},
          {"name":"ROXANA","code":"US-IL-RZM"},
          {"name":"RUSHVILLE","code":"US-IL-R5E"},
          {"name":"RUSSELL","code":"US-IL-ZG2"},
          {"name":"RUSSELLVILLE","code":"US-IL-V9L"},
          {"name":"SADORUS","code":"US-IL-SD6"},
          {"name":"SAINT ANNE","code":"US-IL-SE7"},
          {"name":"SAINT CHARLES","code":"US-IL-SSL"},
          {"name":"SAINT CHARLES","code":"US-IL-TCA"},
          {"name":"SAINT ELMO","code":"US-IL-EL5"},
          {"name":"SAINT JOSEPH","code":"US-IL-XLM"},
          {"name":"SALEM","code":"US-IL-SLO"},
          {"name":"SANDOVAL","code":"US-IL-BMH"},
          {"name":"SANDWICH","code":"US-IL-SWH"},
          {"name":"SAUGET","code":"US-IL-SAU"},
          {"name":"SAUK VILLAGE","code":"US-IL-SXL"},
          {"name":"SAVANNA","code":"US-IL-SNQ"},
          {"name":"SAVOY","code":"US-IL-SYG"},
          {"name":"SCALES MOUND","code":"US-IL-QJZ"},
          {"name":"SCHAUMBURG","code":"US-IL-JMH"},
          {"name":"SCHILLER PARK","code":"US-IL-SID"},
          {"name":"SCOTT AIR FORCE BASE","code":"US-IL-SB3"},
          {"name":"SECOR","code":"US-IL-OZQ"},
          {"name":"SENECA","code":"US-IL-XMI"},
          {"name":"SERENA","code":"US-IL-KSS"},
          {"name":"SESSER","code":"US-IL-KQ3"},
          {"name":"SEWARD","code":"US-IL-QSR"},
          {"name":"SHANNON","code":"US-IL-S7N"},
          {"name":"SHEFFIELD","code":"US-IL-6FL"},
          {"name":"SHELBYVILLE","code":"US-IL-SBV"},
          {"name":"SHERIDAN","code":"US-IL-5SD"},
          {"name":"SHERRARD","code":"US-IL-SJK"},
          {"name":"SHILOH","code":"US-IL-ILH"},
          {"name":"SHOREWOOD","code":"US-IL-SW3"},
          {"name":"SHUMWAY","code":"US-IL-UWX"},
          {"name":"SIDELL","code":"US-IL-SD3"},
          {"name":"SIGEL","code":"US-IL-83W"},
          {"name":"SILVIS","code":"US-IL-ILV"},
          {"name":"SKOKIE","code":"US-IL-SOK"},
          {"name":"SMITHFIELD","code":"US-IL-SM5"},
          {"name":"SOMONAUK","code":"US-IL-SM7"},
          {"name":"SOUTH BARRINGTON","code":"US-IL-UHB"},
          {"name":"SOUTH BELOIT","code":"US-IL-SBL"},
          {"name":"SOUTH CHICAGO HEIGHTS","code":"US-IL-OUS"},
          {"name":"SOUTH ELGIN","code":"US-IL-SE2"},
          {"name":"SOUTH HOLLAND","code":"US-IL-SUI"},
          {"name":"SOUTH ROXANA","code":"US-IL-RXS"},
          {"name":"SPARTA","code":"US-IL-SAR"},
          {"name":"SPRING GROVE","code":"US-IL-SG3"},
          {"name":"SPRING VALLEY","code":"US-IL-SPN"},
          {"name":"SPRINGFIELD","code":"US-IL-SPI"},
          {"name":"STAUNTON","code":"US-IL-UNT"},
          {"name":"STEELEVILLE","code":"US-IL-VTS"},
          {"name":"STEGER","code":"US-IL-SGQ"},
          {"name":"STERLING","code":"US-IL-SGI"},
          {"name":"STERLING-ROCKFALLS APT","code":"US-IL-SQI"},
          {"name":"STICKNEY","code":"US-IL-SKT"},
          {"name":"STOCKTON","code":"US-IL-TC8"},
          {"name":"STONE PARK","code":"US-IL-OEK"},
          {"name":"STONINGTON","code":"US-IL-SN6"},
          {"name":"STOOKEY","code":"US-IL-SO9"},
          {"name":"STREAMWOOD","code":"US-IL-SMW"},
          {"name":"STREATOR","code":"US-IL-STR"},
          {"name":"SUGAR GROVE","code":"US-IL-S2G"},
          {"name":"SULLIVAN","code":"US-IL-ULI"},
          {"name":"SUMMIT","code":"US-IL-ITG"},
          {"name":"SUMMIT","code":"US-IL-XMV"},
          {"name":"SWANSEA","code":"US-IL-SW6"},
          {"name":"SYCAMORE","code":"US-IL-SYC"},
          {"name":"TAMMS","code":"US-IL-TMM"},
          {"name":"TAMPICO","code":"US-IL-TMK"},
          {"name":"TAYLOR RIDGE","code":"US-IL-4TR"},
          {"name":"TAYLORVILLE","code":"US-IL-TBD"},
          {"name":"TENNESSEE","code":"US-IL-5LL"},
          {"name":"TERRE HAUTE","code":"US-IL-Z2T"},
          {"name":"TEUTOPOLIS","code":"US-IL-TTX"},
          {"name":"THOMASBORO","code":"US-IL-TH9"},
          {"name":"THOMSON","code":"US-IL-TXN"},
          {"name":"THORNTON","code":"US-IL-THQ"},
          {"name":"TILTON","code":"US-IL-TT3"},
          {"name":"TINLEY PARK","code":"US-IL-TNL"},
          {"name":"TISKILWA","code":"US-IL-TKW"},
          {"name":"TOLEDO","code":"US-IL-TQD"},
          {"name":"TOLONO","code":"US-IL-KUO"},
          {"name":"TOLUCA","code":"US-IL-TLU"},
          {"name":"TONICA","code":"US-IL-QTO"},
          {"name":"TOPEKA","code":"US-IL-TQK"},
          {"name":"TOULON","code":"US-IL-TU9"},
          {"name":"TOWER LAKES","code":"US-IL-TO7"},
          {"name":"TREMONT","code":"US-IL-TR9"},
          {"name":"TRENTON","code":"US-IL-TEQ"},
          {"name":"TRILLA","code":"US-IL-II9"},
          {"name":"TROY","code":"US-IL-TGY"},
          {"name":"TROY GROVE","code":"US-IL-TYG"},
          {"name":"TUSCOLA","code":"US-IL-XNH"},
          {"name":"UNION","code":"US-IL-UNZ"},
          {"name":"UNIVERSITY PARK","code":"US-IL-UPK"},
          {"name":"URBANA","code":"US-IL-URB"},
          {"name":"UTICA","code":"US-IL-UTQ"},
          {"name":"VALMEYER","code":"US-IL-VYR"},
          {"name":"VANDALIA","code":"US-IL-VLA"},
          {"name":"VARNA","code":"US-IL-VV2"},
          {"name":"VENICE","code":"US-IL-XNL"},
          {"name":"VERGENNES","code":"US-IL-VGX"},
          {"name":"VERMILION","code":"US-IL-4VN"},
          {"name":"VERMONT","code":"US-IL-VMN"},
          {"name":"VERNON","code":"US-IL-VEQ"},
          {"name":"VERNON HILLS","code":"US-IL-VHI"},
          {"name":"VICTORIA","code":"US-IL-VJO"},
          {"name":"VIENNA","code":"US-IL-VIE"},
          {"name":"VILLA GROVE","code":"US-IL-VG9"},
          {"name":"VILLA PARK","code":"US-IL-VPR"},
          {"name":"VIRDEN","code":"US-IL-V6E"},
          {"name":"VIRGIL","code":"US-IL-VRG"},
          {"name":"VIRGINIA","code":"US-IL-VGA"},
          {"name":"VOLO","code":"US-IL-VO2"},
          {"name":"WADSWORTH","code":"US-IL-QWO"},
          {"name":"WALNUT HILL","code":"US-IL-VTH"},
          {"name":"WARREN","code":"US-IL-WEN"},
          {"name":"WARRENSBURG","code":"US-IL-JCQ"},
          {"name":"WARRENVILLE","code":"US-IL-XWV"},
          {"name":"WARSAW","code":"US-IL-WXW"},
          {"name":"WASCO","code":"US-IL-WO5"},
          {"name":"WASHBURN","code":"US-IL-HBU"},
          {"name":"WASHINGTON","code":"US-IL-WGI"},
          {"name":"WASHINGTON PARK","code":"US-IL-WGK"},
          {"name":"WATAGA","code":"US-IL-4AG"},
          {"name":"WATERLOO","code":"US-IL-ZTE"},
          {"name":"WATERMAN","code":"US-IL-WTA"},
          {"name":"WATSEKA","code":"US-IL-XOD"},
          {"name":"WAUCONDA","code":"US-IL-WQN"},
          {"name":"WAUKEGAN","code":"US-IL-UGN"},
          {"name":"WAYNE","code":"US-IL-W2Y"},
          {"name":"WEDRON","code":"US-IL-WRW"},
          {"name":"WELDON","code":"US-IL-KDO"},
          {"name":"WELLINGTON","code":"US-IL-WCZ"},
          {"name":"WEST CHICAGO","code":"US-IL-WCI"},
          {"name":"WEST DUNDEE","code":"US-IL-WD8"},
          {"name":"WEST FRANKFORT","code":"US-IL-KFT"},
          {"name":"WEST PEORIA","code":"US-IL-TPR"},
          {"name":"WEST SALEM","code":"US-IL-WS5"},
          {"name":"WEST SPRINGFIELD","code":"US-IL-WSU"},
          {"name":"WESTCHESTER","code":"US-IL-ES3"},
          {"name":"WESTERN SPRINGS","code":"US-IL-3WS"},
          {"name":"WESTMONT","code":"US-IL-WJO"},
          {"name":"WESTVILLE","code":"US-IL-ZTV"},
          {"name":"WHEATON","code":"US-IL-WHX"},
          {"name":"WHEELING","code":"US-IL-WHE"},
          {"name":"WHITE HALL","code":"US-IL-WH6"},
          {"name":"WHITTINGTON","code":"US-IL-WUG"},
          {"name":"WICKER PARK","code":"US-IL-WCP"},
          {"name":"WILLOW BROOK","code":"US-IL-WIB"},
          {"name":"WILLOW SPRINGS","code":"US-IL-WZG"},
          {"name":"WILLOWBROOK, DUPAGE","code":"US-IL-WOP"},
          {"name":"WILMETTE","code":"US-IL-XTT"},
          {"name":"WILMINGTON","code":"US-IL-WQG"},
          {"name":"WILSONVILLE","code":"US-IL-WL9"},
          {"name":"WINCHESTER","code":"US-IL-IHS"},
          {"name":"WINDSOR","code":"US-IL-DZS"},
          {"name":"WINFIELD","code":"US-IL-NFV"},
          {"name":"WINNEBAGO","code":"US-IL-WNN"},
          {"name":"WINNETKA","code":"US-IL-ZTK"},
          {"name":"WINSLOW","code":"US-IL-WW7"},
          {"name":"WINTHROP HARBOR","code":"US-IL-WPX"},
          {"name":"WOLF LAKE","code":"US-IL-WL8"},
          {"name":"WONDER LAKE","code":"US-IL-QOK"},
          {"name":"WOOD DALE","code":"US-IL-WDD"},
          {"name":"WOOD RIVER","code":"US-IL-WRV"},
          {"name":"WOODHULL","code":"US-IL-WXU"},
          {"name":"WOODLAWN","code":"US-IL-WDI"},
          {"name":"WOODRIDGE","code":"US-IL-WOQ"},
          {"name":"WOODSTOCK","code":"US-IL-WOZ"},
          {"name":"WORDEN","code":"US-IL-WI2"},
          {"name":"WORTH","code":"US-IL-WRH"},
          {"name":"WYANET","code":"US-IL-YAE"},
          {"name":"WYOMING","code":"US-IL-YOJ"},
          {"name":"YORKVILLE","code":"US-IL-YRV"},
          {"name":"ZION","code":"US-IL-ZIO"},
        ]
      },
      {
        name: 'Indiana',
        code: 'US-IN',
        cities: [
          {"name":"ACTON","code":"US-IN-T5C"},
          {"name":"ADVANCE","code":"US-IN-A2D"},
          {"name":"AKRON","code":"US-IN-AKC"},
          {"name":"ALBANY","code":"US-IN-AB5"},
          {"name":"ALBION","code":"US-IN-YAB"},
          {"name":"ALEXANDRIA","code":"US-IN-AXD"},
          {"name":"AMBIA","code":"US-IN-2AA"},
          {"name":"ANDERSON","code":"US-IN-AID"},
          {"name":"ANDREWS","code":"US-IN-ANF"},
          {"name":"ANGOLA","code":"US-IN-ANQ"},
          {"name":"ARCADIA","code":"US-IN-JLM"},
          {"name":"ARCOLA","code":"US-IN-AXL"},
          {"name":"ARGOS","code":"US-IN-AGJ"},
          {"name":"ARLINGTON","code":"US-IN-IGB"},
          {"name":"ASHLEY","code":"US-IN-ESH"},
          {"name":"ATLANTA","code":"US-IN-TLN"},
          {"name":"ATTICA","code":"US-IN-A2Q"},
          {"name":"AUBURN","code":"US-IN-AUI"},
          {"name":"AURORA","code":"US-IN-YAO"},
          {"name":"AUSTIN","code":"US-IN-XBJ"},
          {"name":"AVILLA","code":"US-IN-ALQ"},
          {"name":"AVON","code":"US-IN-VAO"},
          {"name":"BARGERSVILLE","code":"US-IN-B6R"},
          {"name":"BARTLETTSVILLE","code":"US-IN-B34"},
          {"name":"BATESVILLE","code":"US-IN-HLB"},
          {"name":"BATTLE GROUND","code":"US-IN-BN9"},
          {"name":"BEDFORD","code":"US-IN-BFR"},
          {"name":"BERNE","code":"US-IN-EXB"},
          {"name":"BICKNELL","code":"US-IN-ICB"},
          {"name":"BLOOMFIELD","code":"US-IN-BMF"},
          {"name":"BLOOMINGDALE","code":"US-IN-B87"},
          {"name":"BLOOMINGTON","code":"US-IN-BMG"},
          {"name":"BLUFFTON","code":"US-IN-BFE"},
          {"name":"BOONVILLE","code":"US-IN-BVQ"},
          {"name":"BORDEN","code":"US-IN-BDI"},
          {"name":"BOSWELL","code":"US-IN-BZS"},
          {"name":"BOURBON","code":"US-IN-QRB"},
          {"name":"BOWLING GREEN","code":"US-IN-OWR"},
          {"name":"BRAZIL","code":"US-IN-BZA"},
          {"name":"BREMEN","code":"US-IN-BXE"},
          {"name":"BRIDGETON","code":"US-IN-5BT"},
          {"name":"BRIMFIELD","code":"US-IN-BMY"},
          {"name":"BRISTOL","code":"US-IN-BSX"},
          {"name":"BROOK","code":"US-IN-NA6"},
          {"name":"BROOKSTON","code":"US-IN-KII"},
          {"name":"BROOKSVILLE","code":"US-IN-BKQ"},
          {"name":"BROOKVILLE","code":"US-IN-BV4"},
          {"name":"BROWNSBURG","code":"US-IN-BWB"},
          {"name":"BROWNSTOWN","code":"US-IN-BXV"},
          {"name":"BROWNSVILLE","code":"US-IN-BW8"},
          {"name":"BRUCEVILLE","code":"US-IN-BC8"},
          {"name":"BUCKSKIN","code":"US-IN-KKD"},
          {"name":"BUFFALO","code":"US-IN-BU4"},
          {"name":"BUNKER HILL","code":"US-IN-JBH"},
          {"name":"BURLINGTON","code":"US-IN-BGZ"},
          {"name":"BURNEY","code":"US-IN-UGY"},
          {"name":"BURNS HARBOR","code":"US-IN-BNB"},
          {"name":"BUTLER","code":"US-IN-BUI"},
          {"name":"BUTLERVILLE","code":"US-IN-ZBD"},
          {"name":"CAMBRIDGE CITY","code":"US-IN-CAM"},
          {"name":"CAMBY","code":"US-IN-YCA"},
          {"name":"CAMDEN","code":"US-IN-KDN"},
          {"name":"CAMPBELLSBURG","code":"US-IN-AR7"},
          {"name":"CANNELBURG","code":"US-IN-IBU"},
          {"name":"CANNELTON","code":"US-IN-CUU"},
          {"name":"CARBON","code":"US-IN-C8N"},
          {"name":"CARLISLE","code":"US-IN-CQI"},
          {"name":"CARMEL","code":"US-IN-RML"},
          {"name":"CARTHAGE","code":"US-IN-CAW"},
          {"name":"CAYUGA","code":"US-IN-CYG"},
          {"name":"CEDAR LAKE","code":"US-IN-C4K"},
          {"name":"CENTER POINT","code":"US-IN-C3P"},
          {"name":"CENTERVILLE","code":"US-IN-L99"},
          {"name":"CHALMERS","code":"US-IN-7BE"},
          {"name":"CHANDLER","code":"US-IN-CJL"},
          {"name":"CHARLESTOWN","code":"US-IN-XEK"},
          {"name":"CHESTERTON","code":"US-IN-OOX"},
          {"name":"CHRISNEY","code":"US-IN-IYY"},
          {"name":"CHURUBUSCO","code":"US-IN-UUO"},
          {"name":"CICERO","code":"US-IN-C9O"},
          {"name":"CLARKS HILL","code":"US-IN-CS9"},
          {"name":"CLARKSVILLE","code":"US-IN-CKQ"},
          {"name":"CLAYPOOL","code":"US-IN-YPO"},
          {"name":"CLAYTON","code":"US-IN-CKZ"},
          {"name":"CLERMONT","code":"US-IN-CM7"},
          {"name":"CLINTON","code":"US-IN-CIX"},
          {"name":"CLOVERDALE","code":"US-IN-KVD"},
          {"name":"COATESVILLE","code":"US-IN-CS7"},
          {"name":"COLLEGEVILLE","code":"US-IN-CQ4"},
          {"name":"COLUMBIA CITY","code":"US-IN-CAJ"},
          {"name":"COLUMBUS","code":"US-IN-CLU"},
          {"name":"CONNERSVILLE","code":"US-IN-CEV"},
          {"name":"CORTLAND","code":"US-IN-CJ8"},
          {"name":"CORUNNA","code":"US-IN-OXQ"},
          {"name":"CORY","code":"US-IN-CY8"},
          {"name":"CORYDON","code":"US-IN-CZB"},
          {"name":"COVINGTON","code":"US-IN-OVT"},
          {"name":"CRANE","code":"US-IN-ZCR"},
          {"name":"CRAWFORDSVILLE","code":"US-IN-CFB"},
          {"name":"CROMWELL","code":"US-IN-XYX"},
          {"name":"CROSS PLAINS","code":"US-IN-5CP"},
          {"name":"CROTHERSVILLE","code":"US-IN-CVH"},
          {"name":"CROWN POINT","code":"US-IN-YOX"},
          {"name":"CULVER","code":"US-IN-KUV"},
          {"name":"CUMBERLAND","code":"US-IN-UMC"},
          {"name":"CUTLER","code":"US-IN-2CU"},
          {"name":"CYNTHIANA","code":"US-IN-YJA"},
          {"name":"DALE","code":"US-IN-OA8"},
          {"name":"DALEVILLE","code":"US-IN-DKV"},
          {"name":"DANVILLE","code":"US-IN-DLI"},
          {"name":"DARLINGTON","code":"US-IN-DIF"},
          {"name":"DECATUR","code":"US-IN-DCR"},
          {"name":"DECKER","code":"US-IN-DKR"},
          {"name":"DELPHI","code":"US-IN-DEI"},
          {"name":"DEMOTTE","code":"US-IN-EYY"},
          {"name":"DENHAM","code":"US-IN-DQQ"},
          {"name":"DEPAUW","code":"US-IN-D9N"},
          {"name":"DILLSBORO","code":"US-IN-DO3"},
          {"name":"DUBOIS","code":"US-IN-DBI"},
          {"name":"DUNKIRK","code":"US-IN-DKD"},
          {"name":"DUNLAP","code":"US-IN-ULJ"},
          {"name":"DYER","code":"US-IN-DYQ"},
          {"name":"EARL PARK","code":"US-IN-EPA"},
          {"name":"EAST CHICAGO","code":"US-IN-ECH"},
          {"name":"EATON","code":"US-IN-E9A"},
          {"name":"EDINBURGH","code":"US-IN-ENB"},
          {"name":"EDWARDSPORT","code":"US-IN-DWP"},
          {"name":"ELBERFELD","code":"US-IN-JLF"},
          {"name":"ELIZABETH","code":"US-IN-EZH"},
          {"name":"ELIZABETHTOWN","code":"US-IN-4EI"},
          {"name":"ELKHART","code":"US-IN-EKI"},
          {"name":"ELLETTSVILLE","code":"US-IN-ZEN"},
          {"name":"ELWOOD","code":"US-IN-ELL"},
          {"name":"ENGLISH","code":"US-IN-EN3"},
          {"name":"EVANSVILLE","code":"US-IN-EVV"},
          {"name":"EVENSVILLE","code":"US-IN-3IN"},
          {"name":"FAIR OAKS","code":"US-IN-FXR"},
          {"name":"FAIRLAND","code":"US-IN-FDN"},
          {"name":"FAIRMOUNT","code":"US-IN-FQX"},
          {"name":"FALMOUTH","code":"US-IN-F3M"},
          {"name":"FARMERSBURG","code":"US-IN-FSB"},
          {"name":"FERDINAND","code":"US-IN-FDA"},
          {"name":"FISHERS","code":"US-IN-FSH"},
          {"name":"FLAT ROCK","code":"US-IN-FR4"},
          {"name":"FLORA","code":"US-IN-FOI"},
          {"name":"FLORENCE","code":"US-IN-F77"},
          {"name":"FLOYDS KNOBS","code":"US-IN-FNI"},
          {"name":"FONTANET","code":"US-IN-FT2"},
          {"name":"FORT BRANCH","code":"US-IN-FTH"},
          {"name":"FORT WAYNE","code":"US-IN-FWA"},
          {"name":"FORTVILLE","code":"US-IN-YFV"},
          {"name":"FOUNTAIN CITY","code":"US-IN-FNY"},
          {"name":"FOWLER","code":"US-IN-FWI"},
          {"name":"FRANCESVILLE","code":"US-IN-FCV"},
          {"name":"FRANCISCO","code":"US-IN-YIS"},
          {"name":"FRANKFORT","code":"US-IN-FRQ"},
          {"name":"FRANKLIN","code":"US-IN-FKA"},
          {"name":"FRANKTON","code":"US-IN-NKU"},
          {"name":"FREEDOM","code":"US-IN-F7N"},
          {"name":"FREMONT","code":"US-IN-FMT"},
          {"name":"FRENCH LICK","code":"US-IN-FRH"},
          {"name":"FULDA","code":"US-IN-FUD"},
          {"name":"GARRETT","code":"US-IN-QGT"},
          {"name":"GARY","code":"US-IN-GYY"},
          {"name":"GAS CITY","code":"US-IN-GCI"},
          {"name":"GENEVA","code":"US-IN-IUV"},
          {"name":"GEORGETOWN","code":"US-IN-G2G"},
          {"name":"GLENDALE","code":"US-IN-GD6"},
          {"name":"GOODLAND","code":"US-IN-GOQ"},
          {"name":"GOSHEN","code":"US-IN-GSH"},
          {"name":"GOSHEN, SCOTT","code":"US-IN-GQN"},
          {"name":"GOSPORT","code":"US-IN-GP2"},
          {"name":"GRABILL","code":"US-IN-GZB"},
          {"name":"GRANDVIEW","code":"US-IN-DVW"},
          {"name":"GRANGER","code":"US-IN-ZGR"},
          {"name":"GRAYSVILLE","code":"US-IN-8GY"},
          {"name":"GREEN TOWN","code":"US-IN-FWZ"},
          {"name":"GREENCASTLE","code":"US-IN-GXQ"},
          {"name":"GREENDALE","code":"US-IN-GJA"},
          {"name":"GREENDALE","code":"US-IN-GUQ"},
          {"name":"GREENFIELD","code":"US-IN-GFD"},
          {"name":"GREENS FORK","code":"US-IN-GKJ"},
          {"name":"GREENSBURG","code":"US-IN-GRS"},
          {"name":"GREENVILLE","code":"US-IN-9GV"},
          {"name":"GREENWOOD, JOHNSON","code":"US-IN-GNW"},
          {"name":"GRIFFIN","code":"US-IN-GFF"},
          {"name":"GRIFFITH","code":"US-IN-GYF"},
          {"name":"GUILFORD","code":"US-IN-JGU"},
          {"name":"HAGERSTOWN","code":"US-IN-HWN"},
          {"name":"HAMILTON","code":"US-IN-HFN"},
          {"name":"HAMLET","code":"US-IN-H8M"},
          {"name":"HAMMOND","code":"US-IN-HMN"},
          {"name":"HANOVER","code":"US-IN-H2R"},
          {"name":"HARLAN","code":"US-IN-VRL"},
          {"name":"HARTFORD CITY","code":"US-IN-HFC"},
          {"name":"HARTSDALE","code":"US-IN-H4D"},
          {"name":"HEBRON","code":"US-IN-HBW"},
          {"name":"HELMSBURG","code":"US-IN-2HE"},
          {"name":"HELTONVILLE","code":"US-IN-8LT"},
          {"name":"HENDERSON","code":"US-IN-HD7"},
          {"name":"HENRYVILLE","code":"US-IN-YVH"},
          {"name":"HIGHLAND","code":"US-IN-HIJ"},
          {"name":"HILLSBORO","code":"US-IN-HJW"},
          {"name":"HILLSDALE","code":"US-IN-HAK"},
          {"name":"HILLSDALE","code":"US-IN-HD5"},
          {"name":"HOAGLAND","code":"US-IN-HL4"},
          {"name":"HOBART","code":"US-IN-HBA"},
          {"name":"HOBBS","code":"US-IN-IHB"},
          {"name":"HOLLAND","code":"US-IN-HD8"},
          {"name":"HOLTON","code":"US-IN-HTJ"},
          {"name":"HOOSIERLIFT","code":"US-IN-HOF"},
          {"name":"HOPE","code":"US-IN-HPI"},
          {"name":"HOWE","code":"US-IN-HOY"},
          {"name":"HUDSON","code":"US-IN-H8N"},
          {"name":"HUNTERTOWN","code":"US-IN-H3N"},
          {"name":"HUNTINGBURG","code":"US-IN-HNB"},
          {"name":"HUNTINGTON","code":"US-IN-HUN"},
          {"name":"INDIANAPOLIS","code":"US-IN-IND"},
          {"name":"INGALLS","code":"US-IN-JG7"},
          {"name":"INGLEFIELD","code":"US-IN-IJG"},
          {"name":"INWOOD","code":"US-IN-IQW"},
          {"name":"IRELAND","code":"US-IN-ZG6"},
          {"name":"JAMESTOWN /BOONE","code":"US-IN-JQM"},
          {"name":"JASONVILLE","code":"US-IN-JSJ"},
          {"name":"JASPER","code":"US-IN-JAP"},
          {"name":"JEFFERSON","code":"US-IN-JFE"},
          {"name":"JEFFERSONVILLE","code":"US-IN-JFV"},
          {"name":"JEROME","code":"US-IN-JRM"},
          {"name":"JONESVILLE","code":"US-IN-JEZ"},
          {"name":"KENDALLVILLE","code":"US-IN-KDV"},
          {"name":"KENTLAND","code":"US-IN-KKT"},
          {"name":"KEWANNA","code":"US-IN-KAA"},
          {"name":"KEYSTONE","code":"US-IN-JYS"},
          {"name":"KINGMAN","code":"US-IN-KNG"},
          {"name":"KINGSBURY","code":"US-IN-KB2"},
          {"name":"KIRKLIN","code":"US-IN-IKL"},
          {"name":"KNIGHTSTOWN","code":"US-IN-KG9"},
          {"name":"KNOX","code":"US-IN-KNX"},
          {"name":"KOKOMO","code":"US-IN-OKK"},
          {"name":"KOUTS","code":"US-IN-ZKT"},
          {"name":"KYANA","code":"US-IN-KAZ"},
          {"name":"LA PAZ","code":"US-IN-LPZ"},
          {"name":"LA PORTE","code":"US-IN-LAO"},
          {"name":"LAFAYETTE","code":"US-IN-LAF"},
          {"name":"LAGRANGE","code":"US-IN-LGN"},
          {"name":"LAGRO","code":"US-IN-5GR"},
          {"name":"LAKE STATION","code":"US-IN-LKT"},
          {"name":"LAKE VILLAGE","code":"US-IN-LK8"},
          {"name":"LAKEVILLE","code":"US-IN-LXE"},
          {"name":"LAMAR","code":"US-IN-OCS"},
          {"name":"LANESVILLE","code":"US-IN-L54"},
          {"name":"LAOTTO","code":"US-IN-OTJ"},
          {"name":"LAPEL","code":"US-IN-L4A"},
          {"name":"LAPORTE","code":"US-IN-LPO"},
          {"name":"LARWILL","code":"US-IN-IN2"},
          {"name":"LAWRENCEBURG","code":"US-IN-LVU"},
          {"name":"LEAVENWORTH","code":"US-IN-L2V"},
          {"name":"LEBANON","code":"US-IN-LBD"},
          {"name":"LEESBURG","code":"US-IN-EYG"},
          {"name":"LEO-CEDARVILLE","code":"US-IN-IN7"},
          {"name":"LIBERTY","code":"US-IN-LBK"},
          {"name":"LIGONIER","code":"US-IN-LGI"},
          {"name":"LINTON","code":"US-IN-JIT"},
          {"name":"LIZTON","code":"US-IN-LZT"},
          {"name":"LOGANSPORT","code":"US-IN-LGP"},
          {"name":"LOOGOOTEE","code":"US-IN-LT3"},
          {"name":"LOWELL, LAKE","code":"US-IN-LWQ"},
          {"name":"LYNN","code":"US-IN-LYI"},
          {"name":"MADISON","code":"US-IN-MDN"},
          {"name":"MARENGO","code":"US-IN-AEG"},
          {"name":"MARION","code":"US-IN-MZZ"},
          {"name":"MARKLE","code":"US-IN-MQL"},
          {"name":"MARKLEVILLE","code":"US-IN-QMK"},
          {"name":"MARTINSVILLE","code":"US-IN-MXS"},
          {"name":"MATTHEWS","code":"US-IN-H33"},
          {"name":"MAUCKPORT","code":"US-IN-MP2"},
          {"name":"MAXWELL","code":"US-IN-XW3"},
          {"name":"MECCA","code":"US-IN-KMC"},
          {"name":"MEMPHIS","code":"US-IN-8MI"},
          {"name":"MENTONE","code":"US-IN-NNE"},
          {"name":"MEROM","code":"US-IN-KXM"},
          {"name":"MERRILLVILLE","code":"US-IN-MEL"},
          {"name":"MICHIGAN CITY","code":"US-IN-MGC"},
          {"name":"MICHIGANTOWN","code":"US-IN-KMH"},
          {"name":"MIDDLEBURY","code":"US-IN-MDU"},
          {"name":"MIDDLETOWN","code":"US-IN-JDD"},
          {"name":"MIDDLETOWN, ALLEN","code":"US-IN-DDN"},
          {"name":"MIDDLETOWN, SHELBY","code":"US-IN-DD3"},
          {"name":"MILAN","code":"US-IN-NMM"},
          {"name":"MILFORD","code":"US-IN-MII"},
          {"name":"MILLERSBURG","code":"US-IN-ZQS"},
          {"name":"MILLVILLE","code":"US-IN-V6M"},
          {"name":"MILROY","code":"US-IN-YMY"},
          {"name":"MISHAWAKA","code":"US-IN-MCJ"},
          {"name":"MITCHELL","code":"US-IN-MHC"},
          {"name":"MODOC","code":"US-IN-M98"},
          {"name":"MONON","code":"US-IN-OON"},
          {"name":"MONROE","code":"US-IN-INQ"},
          {"name":"MONROE, TIPPECANOE","code":"US-IN-OOE"},
          {"name":"MONROEVILLE","code":"US-IN-9MO"},
          {"name":"MONROVIA","code":"US-IN-ON5"},
          {"name":"MONTEZUMA","code":"US-IN-MZM"},
          {"name":"MONTGOMERY","code":"US-IN-OGM"},
          {"name":"MONTICELLO","code":"US-IN-OIO"},
          {"name":"MONTMORENCI","code":"US-IN-ZGI"},
          {"name":"MONTPELIER","code":"US-IN-OPQ"},
          {"name":"MOORESVILLE","code":"US-IN-MYZ"},
          {"name":"MORRISTOWN","code":"US-IN-MJG"},
          {"name":"MOUNT SAINT FRANCIS","code":"US-IN-5MS"},
          {"name":"MOUNT SUMMIT","code":"US-IN-SU3"},
          {"name":"MOUNT VERNON","code":"US-IN-MXH"},
          {"name":"MOUNT VERNON","code":"US-IN-OVO"},
          {"name":"MUNCIE","code":"US-IN-MIE"},
          {"name":"MUNSTER","code":"US-IN-MUQ"},
          {"name":"NABB","code":"US-IN-NJB"},
          {"name":"NAPPANEE","code":"US-IN-NAI"},
          {"name":"NASHVILLE","code":"US-IN-NJH"},
          {"name":"NEW ALBANY","code":"US-IN-NWA"},
          {"name":"NEW AUGUSTA","code":"US-IN-N7G"},
          {"name":"NEW CARLISLE","code":"US-IN-NC3"},
          {"name":"NEW CASTLE","code":"US-IN-NQS"},
          {"name":"NEW HARMONY","code":"US-IN-NJM"},
          {"name":"NEW HAVEN","code":"US-IN-NEV"},
          {"name":"NEW MIDDLETOWN","code":"US-IN-NM3"},
          {"name":"NEW PALESTINE","code":"US-IN-IN5"},
          {"name":"NEW PARIS","code":"US-IN-NPI"},
          {"name":"NEW PEKIN","code":"US-IN-NP9"},
          {"name":"NEW RICHMOND","code":"US-IN-8QJ"},
          {"name":"NEW SALISBURY","code":"US-IN-NS2"},
          {"name":"NEW WASHINGTON","code":"US-IN-NYW"},
          {"name":"NEWBERRY","code":"US-IN-WY5"},
          {"name":"NEWBURGH","code":"US-IN-XJT"},
          {"name":"NEWPORT","code":"US-IN-NPF"},
          {"name":"NEWTONVILLE","code":"US-IN-NN4"},
          {"name":"NEWTOWN","code":"US-IN-NXE"},
          {"name":"NINEVEH","code":"US-IN-N2E"},
          {"name":"NOBLESVILLE","code":"US-IN-NVB"},
          {"name":"NORMAN","code":"US-IN-NI2"},
          {"name":"NORTH JUDSON","code":"US-IN-IVJ"},
          {"name":"NORTH LIBERTY","code":"US-IN-NL9"},
          {"name":"NORTH MANCHESTER","code":"US-IN-NOS"},
          {"name":"NORTH VERNON","code":"US-IN-NVY"},
          {"name":"NORTH WEBSTER","code":"US-IN-WN3"},
          {"name":"OAKFORD","code":"US-IN-K9F"},
          {"name":"OAKLAND CITY","code":"US-IN-ZOC"},
          {"name":"OAKLANDON","code":"US-IN-IM5"},
          {"name":"OAKTOWN","code":"US-IN-OTN"},
          {"name":"ODON","code":"US-IN-QVJ"},
          {"name":"ORESTES","code":"US-IN-ORU"},
          {"name":"ORLAND","code":"US-IN-OLJ"},
          {"name":"ORLEANS","code":"US-IN-ORZ"},
          {"name":"OSGOOD","code":"US-IN-YZD"},
          {"name":"OSSIAN","code":"US-IN-O2S"},
          {"name":"OTTERBEIN","code":"US-IN-O2T"},
          {"name":"OWENSVILLE","code":"US-IN-OWJ"},
          {"name":"OXFORD","code":"US-IN-OFC"},
          {"name":"PALMYRA","code":"US-IN-PYA"},
          {"name":"PAOLI","code":"US-IN-IPO"},
          {"name":"PARAGON","code":"US-IN-QPG"},
          {"name":"PATOKA","code":"US-IN-PXK"},
          {"name":"PEKIN","code":"US-IN-PKX"},
          {"name":"PENDLETON","code":"US-IN-PDN"},
          {"name":"PERU","code":"US-IN-GUS"},
          {"name":"PETERSBURG","code":"US-IN-PB8"},
          {"name":"PETROLEUM","code":"US-IN-EUM"},
          {"name":"PIERCETON","code":"US-IN-P8N"},
          {"name":"PIMENTO","code":"US-IN-IHO"},
          {"name":"PITTSBORO","code":"US-IN-PBQ"},
          {"name":"PLAINFIELD","code":"US-IN-PAF"},
          {"name":"PLAINVILLE","code":"US-IN-IN8"},
          {"name":"PLEASANT LAKE","code":"US-IN-P2L"},
          {"name":"PLYMOUTH","code":"US-IN-PLY"},
          {"name":"PORTAGE","code":"US-IN-POJ"},
          {"name":"PORTER","code":"US-IN-PWO"},
          {"name":"PORTLAND","code":"US-IN-PJD"},
          {"name":"POSEYVILLE","code":"US-IN-PYZ"},
          {"name":"PREBLE","code":"US-IN-XPL"},
          {"name":"PRINCETON","code":"US-IN-PCJ"},
          {"name":"PUTNAMVILLE","code":"US-IN-IUT"},
          {"name":"RAMSEY","code":"US-IN-RMY"},
          {"name":"REELSVILLE","code":"US-IN-RLZ"},
          {"name":"REMINGTON","code":"US-IN-RXG"},
          {"name":"RENSSELAER","code":"US-IN-RNZ"},
          {"name":"REYNOLDS","code":"US-IN-RYQ"},
          {"name":"RICHMOND","code":"US-IN-RID"},
          {"name":"RIDGEVILLE","code":"US-IN-RVQ"},
          {"name":"ROANN","code":"US-IN-RO8"},
          {"name":"ROANOKE","code":"US-IN-UOK"},
          {"name":"ROCHESTER","code":"US-IN-RCR"},
          {"name":"ROCKPORT","code":"US-IN-RQP"},
          {"name":"ROCKVILLE","code":"US-IN-XXN"},
          {"name":"ROLLING PRAIRIE","code":"US-IN-RPA"},
          {"name":"ROMNEY","code":"US-IN-ZYZ"},
          {"name":"ROSEDALE","code":"US-IN-RN9"},
          {"name":"ROSELAWN","code":"US-IN-OEW"},
          {"name":"ROSSVILLE","code":"US-IN-R2S"},
          {"name":"ROYAL CENTER","code":"US-IN-RYC"},
          {"name":"RUSHVILLE","code":"US-IN-RUH"},
          {"name":"RUSSELLVILLE","code":"US-IN-UVI"},
          {"name":"RUSSIAVILLE","code":"US-IN-RV3"},
          {"name":"SAINT ANTHONY","code":"US-IN-AAY"},
          {"name":"SAINT CROIX","code":"US-IN-TCX"},
          {"name":"SAINT JOE","code":"US-IN-JOJ"},
          {"name":"SAINT JOHN","code":"US-IN-JHH"},
          {"name":"SAINT MARYS","code":"US-IN-INY"},
          {"name":"SAINT MEINRAD","code":"US-IN-RAD"},
          {"name":"SAINT PAUL","code":"US-IN-8NE"},
          {"name":"SALEM","code":"US-IN-SLF"},
          {"name":"SANDFORD","code":"US-IN-3SF"},
          {"name":"SANTA CLAUS","code":"US-IN-SC6"},
          {"name":"SCHERERVILLE","code":"US-IN-SVL"},
          {"name":"SCOTTSBURG","code":"US-IN-SSZ"},
          {"name":"SELLERSBURG","code":"US-IN-SEU"},
          {"name":"SELMA","code":"US-IN-XZY"},
          {"name":"SEYMOUR","code":"US-IN-SER"},
          {"name":"SHADELAND, TIPPECANOE","code":"US-IN-AD2"},
          {"name":"SHARPSVILLE","code":"US-IN-I9H"},
          {"name":"SHELBY","code":"US-IN-INH"},
          {"name":"SHELBYVILLE","code":"US-IN-SYH"},
          {"name":"SHERIDAN","code":"US-IN-SD9"},
          {"name":"SHIPSHEWANA","code":"US-IN-S2P"},
          {"name":"SHOALS","code":"US-IN-YHZ"},
          {"name":"SIBERIA","code":"US-IN-PS4"},
          {"name":"SILVER LAKE","code":"US-IN-ILK"},
          {"name":"SOUTH BEND","code":"US-IN-SBN"},
          {"name":"SOUTH MILFORD","code":"US-IN-MR8"},
          {"name":"SOUTH WHITLEY","code":"US-IN-QHW"},
          {"name":"SOUTHPORT","code":"US-IN-SP4"},
          {"name":"SPEED","code":"US-IN-QSP"},
          {"name":"SPEEDWAY","code":"US-IN-S8W"},
          {"name":"SPENCER","code":"US-IN-RWQ"},
          {"name":"SPENCERVILLE","code":"US-IN-S9P"},
          {"name":"SPICELAND","code":"US-IN-ICE"},
          {"name":"STARLIGHT","code":"US-IN-RL5"},
          {"name":"STRAUGHN","code":"US-IN-UYY"},
          {"name":"SULLIVAN","code":"US-IN-SIV"},
          {"name":"SUMMITVILLE","code":"US-IN-VVL"},
          {"name":"SUNMAN","code":"US-IN-SM3"},
          {"name":"SWITZ CITY","code":"US-IN-S4W"},
          {"name":"SYRACUSE","code":"US-IN-SYZ"},
          {"name":"TAYLORSVILLE","code":"US-IN-YID"},
          {"name":"TELL CITY","code":"US-IN-TEL"},
          {"name":"TERRE HAUTE","code":"US-IN-HUF"},
          {"name":"TIPPECANOE","code":"US-IN-TPQ"},
          {"name":"TIPTON","code":"US-IN-TII"},
          {"name":"TOPEKA","code":"US-IN-TPK"},
          {"name":"TRAFALGAR","code":"US-IN-TFG"},
          {"name":"TROY","code":"US-IN-OYY"},
          {"name":"TWELVE MILE","code":"US-IN-TW3"},
          {"name":"UNION CITY","code":"US-IN-UNO"},
          {"name":"UPLAND","code":"US-IN-UPL"},
          {"name":"URBANA","code":"US-IN-IUA"},
          {"name":"VALPARAISO","code":"US-IN-VPZ"},
          {"name":"VAN BUREN","code":"US-IN-VAM"},
          {"name":"VEEDERSBURG","code":"US-IN-VVG"},
          {"name":"VERNON","code":"US-IN-2ON"},
          {"name":"VERSAILLES","code":"US-IN-VFS"},
          {"name":"VEVAY","code":"US-IN-VYI"},
          {"name":"VINCENNES","code":"US-IN-OEA"},
          {"name":"WABASH","code":"US-IN-WBH"},
          {"name":"WADESVILLE","code":"US-IN-ZWV"},
          {"name":"WAKARUSA","code":"US-IN-WK9"},
          {"name":"WALKERTON","code":"US-IN-WKC"},
          {"name":"WALTON","code":"US-IN-WL6"},
          {"name":"WANATAH","code":"US-IN-ZUB"},
          {"name":"WARREN","code":"US-IN-ZWR"},
          {"name":"WARRICK","code":"US-IN-WRZ"},
          {"name":"WARSAW","code":"US-IN-WAP"},
          {"name":"WASHINGTON","code":"US-IN-WSQ"},
          {"name":"WATERLOO","code":"US-IN-ZZE"},
          {"name":"WATERLOO, JOHNSON","code":"US-IN-WPV"},
          {"name":"WAVELAND","code":"US-IN-92U"},
          {"name":"WAWAKA","code":"US-IN-WKJ"},
          {"name":"WAYNE","code":"US-IN-WYJ"},
          {"name":"WEST COLLEGE CORNER","code":"US-IN-WGD"},
          {"name":"WEST HARRISON","code":"US-IN-WJH"},
          {"name":"WEST LAFAYETTE","code":"US-IN-WLY"},
          {"name":"WEST LEBANON","code":"US-IN-WLI"},
          {"name":"WEST SAYVILLE","code":"US-IN-WS2"},
          {"name":"WEST TERRE HAUTE","code":"US-IN-WZH"},
          {"name":"WESTFIELD","code":"US-IN-WFI"},
          {"name":"WESTPORT","code":"US-IN-WP5"},
          {"name":"WESTVILLE","code":"US-IN-WVK"},
          {"name":"WHEATFIELD","code":"US-IN-FWH"},
          {"name":"WHITELAND","code":"US-IN-ITL"},
          {"name":"WHITESTOWN","code":"US-IN-XWT"},
          {"name":"WHITING","code":"US-IN-WHG"},
          {"name":"WINAMAC","code":"US-IN-WLZ"},
          {"name":"WINCHESTER","code":"US-IN-WCS"},
          {"name":"WINDFALL","code":"US-IN-WF4"},
          {"name":"WOLCOTT","code":"US-IN-OJO"},
          {"name":"WOLCOTTVILLE","code":"US-IN-TIE"},
          {"name":"WOODBURN","code":"US-IN-WUB"},
          {"name":"WOODSTOCK","code":"US-IN-WQK"},
          {"name":"WORTHINGTON","code":"US-IN-JTI"},
          {"name":"WYATT","code":"US-IN-XYT"},
          {"name":"YODER","code":"US-IN-YDR"},
          {"name":"YORKTOWN","code":"US-IN-YRT"},
          {"name":"ZIONSVILLE","code":"US-IN-ZZL"},
        ]
      },
      {
        name: 'Iowa',
        code: 'US-IA',
        cities: [
          {"name":"ACKLEY","code":"US-IA-AKY"},
          {"name":"ADAIR","code":"US-IA-A59"},
          {"name":"ADEL","code":"US-IA-ZAE"},
          {"name":"AFTON","code":"US-IA-AF5"},
          {"name":"AKRON","code":"US-IA-AK5"},
          {"name":"ALBIA","code":"US-IA-AL6"},
          {"name":"ALBION","code":"US-IA-AB7"},
          {"name":"ALBURNETT","code":"US-IA-EUZ"},
          {"name":"ALDEN","code":"US-IA-EDH"},
          {"name":"ALEXANDER","code":"US-IA-AEX"},
          {"name":"ALGONA","code":"US-IA-AXG"},
          {"name":"ALLERTON","code":"US-IA-ALT"},
          {"name":"ALTA","code":"US-IA-W82"},
          {"name":"ALTOONA","code":"US-IA-NOF"},
          {"name":"ALVORD","code":"US-IA-6AV"},
          {"name":"AMADOR","code":"US-IA-AA6"},
          {"name":"AMANA","code":"US-IA-UBG"},
          {"name":"AMES","code":"US-IA-AMW"},
          {"name":"ANAMOSA","code":"US-IA-AAB"},
          {"name":"ANITA","code":"US-IA-NAJ"},
          {"name":"ANKENY","code":"US-IA-AKE"},
          {"name":"ARION","code":"US-IA-IOA"},
          {"name":"ARLINGTON","code":"US-IA-IGN"},
          {"name":"ARMSTRONG","code":"US-IA-AEZ"},
          {"name":"ARNOLDS PARK","code":"US-IA-RNR"},
          {"name":"ARTHUR","code":"US-IA-A6T"},
          {"name":"ASBURY","code":"US-IA-AS9"},
          {"name":"ATLANTIC","code":"US-IA-AIO"},
          {"name":"AUBURN","code":"US-IA-UUR"},
          {"name":"AVOCA","code":"US-IA-VOC"},
          {"name":"BAGLEY","code":"US-IA-XAG"},
          {"name":"BANCROFT","code":"US-IA-RT2"},
          {"name":"BAXTER","code":"US-IA-AXQ"},
          {"name":"BAYARD","code":"US-IA-5BD"},
          {"name":"BEACON","code":"US-IA-B4E"},
          {"name":"BEAMAN","code":"US-IA-IAZ"},
          {"name":"BEDFORD","code":"US-IA-BD9"},
          {"name":"BELLE PLAINE","code":"US-IA-BLP"},
          {"name":"BELLEVUE","code":"US-IA-KVU"},
          {"name":"BELMOND","code":"US-IA-BMK"},
          {"name":"BETTENDORF","code":"US-IA-BTD"},
          {"name":"BEVINGTON","code":"US-IA-BVG"},
          {"name":"BLAIRSBURG","code":"US-IA-BZ5"},
          {"name":"BLAIRSTOWN","code":"US-IA-QWN"},
          {"name":"BLANCHARD","code":"US-IA-I95"},
          {"name":"BLOOMFIELD","code":"US-IA-LMH"},
          {"name":"BLUE GRASS","code":"US-IA-B8A"},
          {"name":"BODE","code":"US-IA-B5E"},
          {"name":"BONAPARTE","code":"US-IA-YBJ"},
          {"name":"BONDURANT","code":"US-IA-NDU"},
          {"name":"BOONE","code":"US-IA-BNW"},
          {"name":"BOUTON","code":"US-IA-BJ9"},
          {"name":"BOYDEN","code":"US-IA-OYD"},
          {"name":"BREDA","code":"US-IA-BD6"},
          {"name":"BRISTOW","code":"US-IA-P7D"},
          {"name":"BRITT","code":"US-IA-DJT"},
          {"name":"BRONSON","code":"US-IA-WO9"},
          {"name":"BROOKLYN","code":"US-IA-OYN"},
          {"name":"BUFFALO","code":"US-IA-BU5"},
          {"name":"BURLINGTON","code":"US-IA-BRL"},
          {"name":"CAMANCHE","code":"US-IA-CAN"},
          {"name":"CAMBRIDGE","code":"US-IA-C4M"},
          {"name":"CARLISLE","code":"US-IA-C9S"},
          {"name":"CARROLL","code":"US-IA-CIN"},
          {"name":"CARTER LAKE","code":"US-IA-CTR"},
          {"name":"CASCADE","code":"US-IA-JDE"},
          {"name":"CEDAR FALLS","code":"US-IA-CRD"},
          {"name":"CEDAR RAPIDS","code":"US-IA-CID"},
          {"name":"CENTERVILLE","code":"US-IA-CIA"},
          {"name":"CENTRAL CITY","code":"US-IA-IW8"},
          {"name":"CHARITON","code":"US-IA-CIQ"},
          {"name":"CHARLES CITY","code":"US-IA-CCY"},
          {"name":"CHATSWORTH","code":"US-IA-4CI"},
          {"name":"CHELSEA","code":"US-IA-C67"},
          {"name":"CHEROKEE","code":"US-IA-CKE"},
          {"name":"CLARE","code":"US-IA-9CE"},
          {"name":"CLARENCE","code":"US-IA-EJC"},
          {"name":"CLARINDA","code":"US-IA-ICL"},
          {"name":"CLARION","code":"US-IA-LRI"},
          {"name":"CLEAR LAKE","code":"US-IA-EAF"},
          {"name":"CLEARFIELD","code":"US-IA-U7Y"},
          {"name":"CLEMONS","code":"US-IA-5EI"},
          {"name":"CLERMONT","code":"US-IA-ERN"},
          {"name":"CLINTON","code":"US-IA-CWI"},
          {"name":"CLIVE","code":"US-IA-IVB"},
          {"name":"COGGON","code":"US-IA-KOA"},
          {"name":"COLESBURG","code":"US-IA-CU6"},
          {"name":"COLO","code":"US-IA-CQL"},
          {"name":"COLUMBUS CITY","code":"US-IA-L9M"},
          {"name":"COLUMBUS JUNCTION","code":"US-IA-CQB"},
          {"name":"CONRAD","code":"US-IA-IWR"},
          {"name":"CONROY","code":"US-IA-OQO"},
          {"name":"COON RAPIDS","code":"US-IA-CRN"},
          {"name":"CORALVILLE","code":"US-IA-CVB"},
          {"name":"CORNING","code":"US-IA-ADB"},
          {"name":"CORRECTIONVILLE","code":"US-IA-CV4"},
          {"name":"CORWITH","code":"US-IA-OWZ"},
          {"name":"CORYDON, WAYNE","code":"US-IA-CD2"},
          {"name":"COULTER","code":"US-IA-JOU"},
          {"name":"COUNCIL BLUFFS","code":"US-IA-CBF"},
          {"name":"CRESCENT","code":"US-IA-CQN"},
          {"name":"CRESCO","code":"US-IA-CFK"},
          {"name":"CRESTON","code":"US-IA-CSQ"},
          {"name":"CUMMING","code":"US-IA-IUU"},
          {"name":"DAKOTA CITY","code":"US-IA-DAK"},
          {"name":"DALLAS","code":"US-IA-ZCO"},
          {"name":"DALLAS CENTER","code":"US-IA-DC6"},
          {"name":"DANVILLE","code":"US-IA-DLV"},
          {"name":"DAVENPORT","code":"US-IA-DVN"},
          {"name":"DE SOTO","code":"US-IA-5FR"},
          {"name":"DE WITT","code":"US-IA-DWJ"},
          {"name":"DECATUR CITY","code":"US-IA-DT2"},
          {"name":"DECORAH","code":"US-IA-DEH"},
          {"name":"DEFIANCE","code":"US-IA-DFA"},
          {"name":"DELHI","code":"US-IA-DHI"},
          {"name":"DELOIT","code":"US-IA-DJI"},
          {"name":"DENISON","code":"US-IA-DNS"},
          {"name":"DES MOINES","code":"US-IA-DSM"},
          {"name":"DEWITT","code":"US-IA-DWW"},
          {"name":"DIKE","code":"US-IA-DKE"},
          {"name":"DONNELLSON","code":"US-IA-DL9"},
          {"name":"DOON","code":"US-IA-QDO"},
          {"name":"DUBUQUE","code":"US-IA-DBQ"},
          {"name":"DUNCOMBE","code":"US-IA-DU7"},
          {"name":"DUNLAP","code":"US-IA-DZP"},
          {"name":"DURANT","code":"US-IA-D2U"},
          {"name":"DYERSVILLE","code":"US-IA-DYE"},
          {"name":"DYSART","code":"US-IA-DZY"},
          {"name":"EAGLE GROVE","code":"US-IA-EGF"},
          {"name":"EARLHAM","code":"US-IA-5EM"},
          {"name":"EARLING","code":"US-IA-E2I"},
          {"name":"EARLVILLE","code":"US-IA-QEV"},
          {"name":"EDDYVILLE","code":"US-IA-EYV"},
          {"name":"EDGEWOOD","code":"US-IA-EW6"},
          {"name":"ELBERON","code":"US-IA-KLB"},
          {"name":"ELDON","code":"US-IA-7EL"},
          {"name":"ELDORA","code":"US-IA-JDR"},
          {"name":"ELDRIDGE","code":"US-IA-EDR"},
          {"name":"ELK HORN","code":"US-IA-E7H"},
          {"name":"ELKADER","code":"US-IA-QEK"},
          {"name":"ELKHART","code":"US-IA-EKH"},
          {"name":"ELLSWORTH","code":"US-IA-QIW"},
          {"name":"ELMA","code":"US-IA-EA2"},
          {"name":"ELY","code":"US-IA-EY4"},
          {"name":"EMERSON","code":"US-IA-IAE"},
          {"name":"EMMETSBURG","code":"US-IA-EMG"},
          {"name":"ESSEX","code":"US-IA-8F6"},
          {"name":"ESTHERVILLE","code":"US-IA-EST"},
          {"name":"EVANSDALE","code":"US-IA-E5S"},
          {"name":"EVERLY","code":"US-IA-EV8"},
          {"name":"FAIRBANK","code":"US-IA-FZB"},
          {"name":"FAIRFAX","code":"US-IA-FFP"},
          {"name":"FAIRFIELD","code":"US-IA-FFL"},
          {"name":"FARLEY","code":"US-IA-FY4"},
          {"name":"FARRAGUT","code":"US-IA-FJR"},
          {"name":"FAYETTE","code":"US-IA-FQT"},
          {"name":"FENTON","code":"US-IA-IFN"},
          {"name":"FOREST CITY","code":"US-IA-FXY"},
          {"name":"FORT ATKINSON","code":"US-IA-FKS"},
          {"name":"FORT DODGE","code":"US-IA-FOD"},
          {"name":"FORT MADISON","code":"US-IA-FMS"},
          {"name":"FRANKLIN","code":"US-IA-F2K"},
          {"name":"FREDERICKSBURG","code":"US-IA-FKX"},
          {"name":"FREMONT","code":"US-IA-EMQ"},
          {"name":"FULTON","code":"US-IA-FNQ"},
          {"name":"GALVA","code":"US-IA-GL8"},
          {"name":"GARNAVILLO","code":"US-IA-RW9"},
          {"name":"GARNER","code":"US-IA-GNR"},
          {"name":"GARRISON","code":"US-IA-JQX"},
          {"name":"GEORGE","code":"US-IA-OQX"},
          {"name":"GILMORE CITY","code":"US-IA-GIO"},
          {"name":"GLENWOOD","code":"US-IA-GWX"},
          {"name":"GLIDDEN","code":"US-IA-GJK"},
          {"name":"GOLDFIELD","code":"US-IA-GF5"},
          {"name":"GOODELL","code":"US-IA-GD8"},
          {"name":"GRAETTINGER","code":"US-IA-GKR"},
          {"name":"GRAND JUNCTION","code":"US-IA-GJW"},
          {"name":"GRAND MOUND","code":"US-IA-GD9"},
          {"name":"GRANGER","code":"US-IA-YGR"},
          {"name":"GRANVILLE","code":"US-IA-GKA"},
          {"name":"GREENE","code":"US-IA-GEX"},
          {"name":"GREENFIELD","code":"US-IA-GIA"},
          {"name":"GRIMES","code":"US-IA-GJM"},
          {"name":"GRINNELL","code":"US-IA-GIW"},
          {"name":"GRISWOLD","code":"US-IA-GZD"},
          {"name":"GRUNDY CENTER","code":"US-IA-GRU"},
          {"name":"GUTTENBERG","code":"US-IA-GT6"},
          {"name":"HALBUR","code":"US-IA-HJU"},
          {"name":"HAMBURG","code":"US-IA-HBQ"},
          {"name":"HAMPTON","code":"US-IA-HPT"},
          {"name":"HANLONTOWN","code":"US-IA-5HA"},
          {"name":"HARCOURT","code":"US-IA-HC8"},
          {"name":"HARLAN","code":"US-IA-HYI"},
          {"name":"HARPERS FERRY","code":"US-IA-RPF"},
          {"name":"HARRIS","code":"US-IA-H9R"},
          {"name":"HARTLEY","code":"US-IA-HTY"},
          {"name":"HARVEY","code":"US-IA-HV4"},
          {"name":"HAWARDEN","code":"US-IA-XGX"},
          {"name":"HIAWATHA","code":"US-IA-HW5"},
          {"name":"HILLS","code":"US-IA-HIL"},
          {"name":"HINTON","code":"US-IA-9HT"},
          {"name":"HOLSTEIN","code":"US-IA-HSN"},
          {"name":"HOLY CROSS","code":"US-IA-L63"},
          {"name":"HOPKINTON","code":"US-IA-QHK"},
          {"name":"HOSPERS","code":"US-IA-HSJ"},
          {"name":"HOUGHTON","code":"US-IA-HQQ"},
          {"name":"HUDSON","code":"US-IA-HJF"},
          {"name":"HULL","code":"US-IA-ULL"},
          {"name":"HUMBOLDT","code":"US-IA-HUD"},
          {"name":"HUXLEY","code":"US-IA-IHY"},
          {"name":"IDA GROVE","code":"US-IA-IDG"},
          {"name":"INDEPENDENCE","code":"US-IA-INE"},
          {"name":"INDIANOLA","code":"US-IA-IAA"},
          {"name":"INWOOD","code":"US-IA-IIN"},
          {"name":"IONIA","code":"US-IA-IA8"},
          {"name":"IOWA CITY","code":"US-IA-IOW"},
          {"name":"IOWA FALLS","code":"US-IA-IFA"},
          {"name":"IRETON","code":"US-IA-ITF"},
          {"name":"JANESVILLE","code":"US-IA-JAV"},
          {"name":"JEFFERSON","code":"US-IA-EFW"},
          {"name":"JESUP","code":"US-IA-JSU"},
          {"name":"JEWELL","code":"US-IA-JEW"},
          {"name":"JOHNSTON","code":"US-IA-JHS"},
          {"name":"KALONA","code":"US-IA-KAC"},
          {"name":"KANAWHA","code":"US-IA-K2W"},
          {"name":"KELLOGG","code":"US-IA-KEC"},
          {"name":"KENSETT","code":"US-IA-KZT"},
          {"name":"KEOKUK","code":"US-IA-EOK"},
          {"name":"KEOSAUQUA","code":"US-IA-K28"},
          {"name":"KEOTA","code":"US-IA-KJO"},
          {"name":"KESWICK","code":"US-IA-QKK"},
          {"name":"KILBOURN","code":"US-IA-KBZ"},
          {"name":"KIMBALLTON","code":"US-IA-4KI"},
          {"name":"KIRON","code":"US-IA-KQN"},
          {"name":"KNOXVILLE","code":"US-IA-KXE"},
          {"name":"LA MOTTE","code":"US-IA-L2M"},
          {"name":"LA PORTE CITY","code":"US-IA-8LP"},
          {"name":"LAKE CITY","code":"US-IA-KIT"},
          {"name":"LAKE MILLS","code":"US-IA-KMI"},
          {"name":"LAKE PARK","code":"US-IA-QJC"},
          {"name":"LAKEVIEW","code":"US-IA-LVE"},
          {"name":"LAKOTA","code":"US-IA-LK5"},
          {"name":"LANSING","code":"US-IA-W77"},
          {"name":"LARCHWOOD","code":"US-IA-LA8"},
          {"name":"LATIMER","code":"US-IA-L5R"},
          {"name":"LAURENS","code":"US-IA-LRX"},
          {"name":"LAWLER","code":"US-IA-S6S"},
          {"name":"LAWTON","code":"US-IA-TWW"},
          {"name":"LE CLAIRE","code":"US-IA-C6L"},
          {"name":"LE MARS","code":"US-IA-EMS"},
          {"name":"LELAND","code":"US-IA-LEJ"},
          {"name":"LEMARS","code":"US-IA-LRJ"},
          {"name":"LENOX","code":"US-IA-YLX"},
          {"name":"LESTER","code":"US-IA-T8S"},
          {"name":"LIBERTY","code":"US-IA-ZLY"},
          {"name":"LIBERTYVILLE","code":"US-IA-5LV"},
          {"name":"LIME SPRINGS","code":"US-IA-L5I"},
          {"name":"LINEVILLE","code":"US-IA-LN4"},
          {"name":"LISBON","code":"US-IA-LZB"},
          {"name":"LOHRVILLE","code":"US-IA-LHQ"},
          {"name":"LORIMOR","code":"US-IA-L9O"},
          {"name":"LOWDEN","code":"US-IA-5LD"},
          {"name":"LUANA","code":"US-IA-LUN"},
          {"name":"LYNNVILLE","code":"US-IA-LYL"},
          {"name":"LYTTON","code":"US-IA-LYT"},
          {"name":"MADRID","code":"US-IA-UID"},
          {"name":"MALCOM","code":"US-IA-GMM"},
          {"name":"MALVERN","code":"US-IA-3MV"},
          {"name":"MANCHESTER","code":"US-IA-MNX"},
          {"name":"MANLY","code":"US-IA-9ML"},
          {"name":"MANNING","code":"US-IA-IA2"},
          {"name":"MANSON","code":"US-IA-MON"},
          {"name":"MAQUOKETA","code":"US-IA-MQO"},
          {"name":"MARATHON","code":"US-IA-OM9"},
          {"name":"MARENGO","code":"US-IA-MGO"},
          {"name":"MARION","code":"US-IA-MMX"},
          {"name":"MARNE","code":"US-IA-M26"},
          {"name":"MARSHALLTOWN","code":"US-IA-MIW"},
          {"name":"MASON CITY","code":"US-IA-MCW"},
          {"name":"MASONVILLE","code":"US-IA-MI5"},
          {"name":"MAXWELL","code":"US-IA-WX3"},
          {"name":"MCGREGOR","code":"US-IA-MG2"},
          {"name":"MEDIAPOLIS","code":"US-IA-MP7"},
          {"name":"MELBOURNE","code":"US-IA-ZJG"},
          {"name":"MENLO","code":"US-IA-5LO"},
          {"name":"MERRILL","code":"US-IA-9MR"},
          {"name":"MIDDLETOWN","code":"US-IA-M76"},
          {"name":"MILFORD","code":"US-IA-MFJ"},
          {"name":"MILTON","code":"US-IA-MXW"},
          {"name":"MINGO","code":"US-IA-IQG"},
          {"name":"MISSOURI VALLEY","code":"US-IA-IFV"},
          {"name":"MITCHELLVILLE","code":"US-IA-ITX"},
          {"name":"MONMOUTH","code":"US-IA-OMH"},
          {"name":"MONONA","code":"US-IA-MXJ"},
          {"name":"MONROE","code":"US-IA-NRQ"},
          {"name":"MONTEZUMA","code":"US-IA-YMZ"},
          {"name":"MONTICELLO","code":"US-IA-MXO"},
          {"name":"MONTROSE","code":"US-IA-4MO"},
          {"name":"MORAVIA","code":"US-IA-QOR"},
          {"name":"MOSCOW","code":"US-IA-MW3"},
          {"name":"MOUNT AYR","code":"US-IA-OUA"},
          {"name":"MOUNT PLEASANT","code":"US-IA-MPZ"},
          {"name":"MOUNT VERNON","code":"US-IA-MXF"},
          {"name":"MOVILLE","code":"US-IA-M5O"},
          {"name":"MURRAY","code":"US-IA-QRU"},
          {"name":"MUSCATINE","code":"US-IA-MUT"},
          {"name":"NASHUA","code":"US-IA-N2S"},
          {"name":"NEVADA","code":"US-IA-NVN"},
          {"name":"NEW ALBIN","code":"US-IA-N4W"},
          {"name":"NEW HAMPTON","code":"US-IA-NIM"},
          {"name":"NEW LIBERTY","code":"US-IA-NLR"},
          {"name":"NEW LONDON","code":"US-IA-NML"},
          {"name":"NEW MARKET","code":"US-IA-NM5"},
          {"name":"NEW PROVIDENCE","code":"US-IA-NPG"},
          {"name":"NEW VIENNA","code":"US-IA-QIA"},
          {"name":"NEW VIRGINIA","code":"US-IA-EUV"},
          {"name":"NEWELL","code":"US-IA-4IU"},
          {"name":"NEWTON","code":"US-IA-TNU"},
          {"name":"NORA SPRINGS","code":"US-IA-OIU"},
          {"name":"NORTH LIBERTY","code":"US-IA-NRY"},
          {"name":"NORTHWOOD","code":"US-IA-NZD"},
          {"name":"NORWALK","code":"US-IA-NKJ"},
          {"name":"NORWAY","code":"US-IA-NJO"},
          {"name":"OAKLAND","code":"US-IA-OLI"},
          {"name":"ODEBOLT","code":"US-IA-5OT"},
          {"name":"OELWEIN","code":"US-IA-OLW"},
          {"name":"OGDEN","code":"US-IA-OGO"},
          {"name":"OKOBOJI","code":"US-IA-O2K"},
          {"name":"ONAWA","code":"US-IA-IA3"},
          {"name":"ORANGE CITY","code":"US-IA-OC2"},
          {"name":"ORCHARD","code":"US-IA-OR5"},
          {"name":"OSAGE","code":"US-IA-OAE"},
          {"name":"OSCEOLA","code":"US-IA-OCL"},
          {"name":"OSKALOOSA","code":"US-IA-OOA"},
          {"name":"OTTUMWA","code":"US-IA-OTM"},
          {"name":"OXFORD","code":"US-IA-OXA"},
          {"name":"PACIFIC JUNCTION","code":"US-IA-PJ8"},
          {"name":"PALMER","code":"US-IA-PI8"},
          {"name":"PALO","code":"US-IA-5PA"},
          {"name":"PANAMA","code":"US-IA-P99"},
          {"name":"PANORA","code":"US-IA-P77"},
          {"name":"PARNELL","code":"US-IA-PN7"},
          {"name":"PATON","code":"US-IA-PQB"},
          {"name":"PAULLINA","code":"US-IA-9AU"},
          {"name":"PELLA","code":"US-IA-PEL"},
          {"name":"PEORIA","code":"US-IA-JPO"},
          {"name":"PEOSTA","code":"US-IA-PST"},
          {"name":"PERRY","code":"US-IA-PRO"},
          {"name":"PLAINFIELD","code":"US-IA-NLF"},
          {"name":"PLANO","code":"US-IA-IP2"},
          {"name":"PLEASANT HILL","code":"US-IA-LSH"},
          {"name":"PLEASANT PRAIRIE","code":"US-IA-XPP"},
          {"name":"PLEASANTVILLE","code":"US-IA-VV3"},
          {"name":"PLOVER","code":"US-IA-QOV"},
          {"name":"POCAHONTAS","code":"US-IA-POH"},
          {"name":"POMEROY","code":"US-IA-OYZ"},
          {"name":"PORTSMOUTH","code":"US-IA-OUQ"},
          {"name":"POSTVILLE","code":"US-IA-VVI"},
          {"name":"PRAIRIE CITY","code":"US-IA-PII"},
          {"name":"PRESTON","code":"US-IA-PRN"},
          {"name":"PRIMGHAR","code":"US-IA-P2R"},
          {"name":"PRINCETON","code":"US-IA-PC3"},
          {"name":"PROMISE CITY","code":"US-IA-IIX"},
          {"name":"RADCLIFFE","code":"US-IA-DCF"},
          {"name":"RALSTON","code":"US-IA-RLQ"},
          {"name":"REASNOR","code":"US-IA-RJA"},
          {"name":"RED OAK","code":"US-IA-RDK"},
          {"name":"REDDING","code":"US-IA-DDP"},
          {"name":"REDFIELD","code":"US-IA-RFQ"},
          {"name":"REINBECK","code":"US-IA-RB2"},
          {"name":"REMBRANDT","code":"US-IA-RJR"},
          {"name":"REMSEN","code":"US-IA-RS8"},
          {"name":"RENWICK","code":"US-IA-RWG"},
          {"name":"RICEVILLE","code":"US-IA-P44"},
          {"name":"RICHLAND","code":"US-IA-RH7"},
          {"name":"RINGSTED","code":"US-IA-GSD"},
          {"name":"RIVERDALE","code":"US-IA-IVU"},
          {"name":"RIVERSIDE","code":"US-IA-VSD"},
          {"name":"ROCK RAPIDS","code":"US-IA-RZR"},
          {"name":"ROCK VALLEY","code":"US-IA-RCV"},
          {"name":"ROCKFORD","code":"US-IA-7RO"},
          {"name":"ROCKWELL CITY","code":"US-IA-RY3"},
          {"name":"ROWLEY","code":"US-IA-RL3"},
          {"name":"SAC CITY","code":"US-IA-JTY"},
          {"name":"SAINT ANSGAR","code":"US-IA-JSS"},
          {"name":"SAINT CHARLES","code":"US-IA-ISJ"},
          {"name":"SAINT LUCAS","code":"US-IA-S4L"},
          {"name":"SAINT MARYS","code":"US-IA-IYQ"},
          {"name":"SAINT OLAF","code":"US-IA-SO4"},
          {"name":"SANBORN","code":"US-IA-ZOJ"},
          {"name":"SCHALLER","code":"US-IA-SCX"},
          {"name":"SCHLESWIG","code":"US-IA-HSW"},
          {"name":"SCRANTON","code":"US-IA-S53"},
          {"name":"SENECA","code":"US-IA-S7C"},
          {"name":"SERGEANT BLUFF","code":"US-IA-SGB"},
          {"name":"SEYMOUR","code":"US-IA-EOU"},
          {"name":"SHEFFIELD","code":"US-IA-SED"},
          {"name":"SHELBY","code":"US-IA-ISY"},
          {"name":"SHELDON","code":"US-IA-SEI"},
          {"name":"SHELL ROCK","code":"US-IA-SR2"},
          {"name":"SHENANDOAH","code":"US-IA-SDH"},
          {"name":"SIBLEY","code":"US-IA-ZSL"},
          {"name":"SIDNEY","code":"US-IA-S23"},
          {"name":"SIGOURNEY","code":"US-IA-QGU"},
          {"name":"SIOUX CENTER","code":"US-IA-SNT"},
          {"name":"SIOUX CITY","code":"US-IA-SUX"},
          {"name":"SIOUX RAPIDS","code":"US-IA-XAF"},
          {"name":"SLATER","code":"US-IA-AI9"},
          {"name":"SOLON","code":"US-IA-SI5"},
          {"name":"SPENCER","code":"US-IA-SPW"},
          {"name":"SPILLVILLE","code":"US-IA-LX8"},
          {"name":"SPIRIT LAKE","code":"US-IA-RTL"},
          {"name":"SPRAGUEVILLE","code":"US-IA-S89"},
          {"name":"SPRINGVILLE","code":"US-IA-SG5"},
          {"name":"STACYVILLE","code":"US-IA-TVX"},
          {"name":"STANTON","code":"US-IA-NZK"},
          {"name":"STOCKTON","code":"US-IA-87O"},
          {"name":"STORM LAKE","code":"US-IA-SLB"},
          {"name":"STORY CITY","code":"US-IA-ITY"},
          {"name":"STRAWBERRY POINT","code":"US-IA-SP5"},
          {"name":"STUART","code":"US-IA-TZT"},
          {"name":"SULLY","code":"US-IA-ULX"},
          {"name":"SUMMERSET","code":"US-IA-SU8"},
          {"name":"SUMNER","code":"US-IA-5SU"},
          {"name":"SWISHER","code":"US-IA-JWI"},
          {"name":"TABOR","code":"US-IA-XNC"},
          {"name":"TAINTOR","code":"US-IA-ZAO"},
          {"name":"TAMA","code":"US-IA-TAM"},
          {"name":"TEMPLETON","code":"US-IA-TT4"},
          {"name":"THOMPSON","code":"US-IA-TPF"},
          {"name":"TIFFIN","code":"US-IA-3TF"},
          {"name":"TIPTON","code":"US-IA-TT2"},
          {"name":"TOLEDA","code":"US-IA-TED"},
          {"name":"TOLEDO","code":"US-IA-I9W"},
          {"name":"TROY MILLS","code":"US-IA-TMS"},
          {"name":"UNDERWOOD","code":"US-IA-UWD"},
          {"name":"UNION","code":"US-IA-UOM"},
          {"name":"URBANA","code":"US-IA-URC"},
          {"name":"URBANDALE","code":"US-IA-UBL"},
          {"name":"UTE","code":"US-IA-QTU"},
          {"name":"VAIL","code":"US-IA-IVI"},
          {"name":"VAN HORNE","code":"US-IA-JVH"},
          {"name":"VAN METER","code":"US-IA-7VM"},
          {"name":"VAN WERT","code":"US-IA-VWT"},
          {"name":"VENTURA","code":"US-IA-VTU"},
          {"name":"VICTOR","code":"US-IA-VTC"},
          {"name":"VILLISCA","code":"US-IA-5VI"},
          {"name":"VINCENT","code":"US-IA-VDT"},
          {"name":"VINTON","code":"US-IA-VTW"},
          {"name":"VOORHIES","code":"US-IA-VH7"},
          {"name":"WALCOTT","code":"US-IA-OJT"},
          {"name":"WALFORD","code":"US-IA-WFQ"},
          {"name":"WALL LAKE","code":"US-IA-WKP"},
          {"name":"WALNUT","code":"US-IA-WU7"},
          {"name":"WAPELLO","code":"US-IA-5WP"},
          {"name":"WASHINGTON","code":"US-IA-VTO"},
          {"name":"WATERLOO","code":"US-IA-ALO"},
          {"name":"WAUKEE","code":"US-IA-WKE"},
          {"name":"WAUKON","code":"US-IA-UKN"},
          {"name":"WAVERLY","code":"US-IA-WVY"},
          {"name":"WEBSTER CITY","code":"US-IA-EBS"},
          {"name":"WELDON","code":"US-IA-I6O"},
          {"name":"WELLMAN","code":"US-IA-7WM"},
          {"name":"WELLSBURG","code":"US-IA-WB5"},
          {"name":"WEST BEND","code":"US-IA-ETE"},
          {"name":"WEST BRANCH","code":"US-IA-IWB"},
          {"name":"WEST BURLINGTON","code":"US-IA-WBO"},
          {"name":"WEST DES MOINES","code":"US-IA-WEM"},
          {"name":"WEST LIBERTY","code":"US-IA-WL3"},
          {"name":"WEST POINT","code":"US-IA-XTP"},
          {"name":"WEST UNION","code":"US-IA-WUI"},
          {"name":"WESTSIDE","code":"US-IA-WJS"},
          {"name":"WEVER","code":"US-IA-VRQ"},
          {"name":"WHEATLAND","code":"US-IA-W3D"},
          {"name":"WHITTEMORE","code":"US-IA-W4M"},
          {"name":"WILLIAMSBURG","code":"US-IA-WUS"},
          {"name":"WILTON","code":"US-IA-IOV"},
          {"name":"WINDSOR HEIGHTS","code":"US-IA-W5H"},
          {"name":"WINTERSET","code":"US-IA-WNT"},
          {"name":"WINTHROP","code":"US-IA-YWT"},
          {"name":"WOODWARD","code":"US-IA-UWW"},
          {"name":"YARMOUTH","code":"US-IA-YOH"},
          {"name":"ZEARING","code":"US-IA-ZEA"},
        ]
      },
      {
        name: 'Kansas',
        code: 'US-KS',
        cities: [
          {"name":"ABBYVILLE","code":"US-KS-A4B"},
          {"name":"ABILENE","code":"US-KS-ABZ"},
          {"name":"ALMA","code":"US-KS-AM6"},
          {"name":"ALTA VISTA","code":"US-KS-AV3"},
          {"name":"ALTAMONT","code":"US-KS-A7L"},
          {"name":"ANDALE","code":"US-KS-A3D"},
          {"name":"ANDOVER","code":"US-KS-AXZ"},
          {"name":"ANTHONY","code":"US-KS-ANY"},
          {"name":"ARKANSAS CITY","code":"US-KS-ARK"},
          {"name":"ASHERVILLE","code":"US-KS-XA2"},
          {"name":"ASHLAND","code":"US-KS-IZH"},
          {"name":"ASSARIA","code":"US-KS-AI7"},
          {"name":"ATCHISON","code":"US-KS-ATQ"},
          {"name":"ATTICA","code":"US-KS-IAX"},
          {"name":"ATWOOD","code":"US-KS-AWD"},
          {"name":"AUBURN","code":"US-KS-AU9"},
          {"name":"AUGUSTA","code":"US-KS-T99"},
          {"name":"BALDWIN CITY","code":"US-KS-BZI"},
          {"name":"BAXTER SPRINGS","code":"US-KS-BSG"},
          {"name":"BAZINE","code":"US-KS-FZI"},
          {"name":"BELLEVILLE","code":"US-KS-IEQ"},
          {"name":"BELMONT","code":"US-KS-BFM"},
          {"name":"BELOIT","code":"US-KS-VBT"},
          {"name":"BELVUE","code":"US-KS-B5V"},
          {"name":"BENTON","code":"US-KS-NQT"},
          {"name":"BERN","code":"US-KS-BRC"},
          {"name":"BERRYTON","code":"US-KS-BEY"},
          {"name":"BISON","code":"US-KS-N8S"},
          {"name":"BLUE MOUND","code":"US-KS-6BM"},
          {"name":"BLUE RAPIDS","code":"US-KS-BPD"},
          {"name":"BONNER SPRINGS","code":"US-KS-BSU"},
          {"name":"BRAZILTON","code":"US-KS-BQ3"},
          {"name":"BUHLER","code":"US-KS-UHL"},
          {"name":"BURDETT","code":"US-KS-QBG"},
          {"name":"BURLINGTON","code":"US-KS-D3X"},
          {"name":"BURRTON","code":"US-KS-YBU"},
          {"name":"BUSHTON","code":"US-KS-B9S"},
          {"name":"CALDWELL","code":"US-KS-DWL"},
          {"name":"CANEY","code":"US-KS-CUQ"},
          {"name":"CARBONDALE","code":"US-KS-K7S"},
          {"name":"CENTRALIA","code":"US-KS-RXC"},
          {"name":"CHANUTE","code":"US-KS-CNU"},
          {"name":"CHAPMAN","code":"US-KS-9CP"},
          {"name":"CHASE","code":"US-KS-C9E"},
          {"name":"CHENEY","code":"US-KS-CN2"},
          {"name":"CHERRYVALE","code":"US-KS-RYE"},
          {"name":"CIRCLEVILLE","code":"US-KS-C7V"},
          {"name":"CLAFLIN","code":"US-KS-CF2"},
          {"name":"CLAY CENTER","code":"US-KS-CYK"},
          {"name":"CLEARWATER","code":"US-KS-JWA"},
          {"name":"COFFEYVILLE","code":"US-KS-CFV"},
          {"name":"COLBY","code":"US-KS-CBK"},
          {"name":"COLDWATER","code":"US-KS-CQ8"},
          {"name":"COLONY","code":"US-KS-4CL"},
          {"name":"COLUMBUS","code":"US-KS-UCU"},
          {"name":"COLWICH","code":"US-KS-CWO"},
          {"name":"CONCORDIA","code":"US-KS-CNK"},
          {"name":"CONWAY","code":"US-KS-C9W"},
          {"name":"COUNCIL GROVE","code":"US-KS-UGE"},
          {"name":"COURTLAND","code":"US-KS-CBQ"},
          {"name":"CRESTLINE","code":"US-KS-RZN"},
          {"name":"CUNNINGHAM","code":"US-KS-UKM"},
          {"name":"DANVILLE","code":"US-KS-DIH"},
          {"name":"DE SOTO","code":"US-KS-DZO"},
          {"name":"DERBY","code":"US-KS-DPB"},
          {"name":"DEXTER","code":"US-KS-DXP"},
          {"name":"DODGE CITY","code":"US-KS-DDC"},
          {"name":"DORRANCE","code":"US-KS-DNC"},
          {"name":"DOUGLASS","code":"US-KS-UGW"},
          {"name":"DOWNS","code":"US-KS-DQW"},
          {"name":"DURHAM","code":"US-KS-R9D"},
          {"name":"EDGERTON","code":"US-KS-EEU"},
          {"name":"EDSON","code":"US-KS-ZYL"},
          {"name":"EDWARDSVILLE","code":"US-KS-EDW"},
          {"name":"EL DORADO","code":"US-KS-EDK"},
          {"name":"ELKHART","code":"US-KS-EHR"},
          {"name":"ELLIS","code":"US-KS-EIJ"},
          {"name":"ELLSWORTH","code":"US-KS-ZC6"},
          {"name":"ELWOOD","code":"US-KS-EWW"},
          {"name":"EMPORIA","code":"US-KS-EMP"},
          {"name":"ENTERPRISE","code":"US-KS-KFJ"},
          {"name":"ENTERPRISE","code":"US-KS-KJF"},
          {"name":"EUDORA","code":"US-KS-EK2"},
          {"name":"EUREKA","code":"US-KS-7EU"},
          {"name":"FAIRVIEW","code":"US-KS-FVK"},
          {"name":"FLORENCE","code":"US-KS-S4F"},
          {"name":"FORT LEAVENWORTH","code":"US-KS-FLV"},
          {"name":"FORT RILEY","code":"US-KS-FRI"},
          {"name":"FORT SCOTT","code":"US-KS-FSK"},
          {"name":"FRANKLIN","code":"US-KS-98R"},
          {"name":"FRONTENAC","code":"US-KS-FT8"},
          {"name":"GALENA","code":"US-KS-GA2"},
          {"name":"GALESBURG","code":"US-KS-G5B"},
          {"name":"GARDEN CITY","code":"US-KS-GCK"},
          {"name":"GARDNER","code":"US-KS-GDZ"},
          {"name":"GARNETT","code":"US-KS-G7R"},
          {"name":"GAS","code":"US-KS-KAS"},
          {"name":"GEM","code":"US-KS-QGA"},
          {"name":"GIRARD","code":"US-KS-GD4"},
          {"name":"GLEN ELDER","code":"US-KS-GE4"},
          {"name":"GODDARD","code":"US-KS-GOA"},
          {"name":"GOODLAND","code":"US-KS-GLD"},
          {"name":"GREAT BEND","code":"US-KS-GBD"},
          {"name":"GREENLEAF","code":"US-KS-7GR"},
          {"name":"GREENSBURG","code":"US-KS-GB2"},
          {"name":"GRIDLEY","code":"US-KS-GYB"},
          {"name":"GYPSUM","code":"US-KS-6GS"},
          {"name":"HALLOWELL","code":"US-KS-HWF"},
          {"name":"HALSTEAD","code":"US-KS-AH7"},
          {"name":"HARPER","code":"US-KS-VRP"},
          {"name":"HAVEN","code":"US-KS-AE6"},
          {"name":"HAYS","code":"US-KS-HYS"},
          {"name":"HAYSVILLE","code":"US-KS-HZY"},
          {"name":"HEALY","code":"US-KS-LY5"},
          {"name":"HERKIMER","code":"US-KS-HKM"},
          {"name":"HESSTON","code":"US-KS-HET"},
          {"name":"HIAWATHA","code":"US-KS-HW4"},
          {"name":"HICKOK","code":"US-KS-HKK"},
          {"name":"HIGHLAND","code":"US-KS-FJF"},
          {"name":"HILL CITY","code":"US-KS-HLC"},
          {"name":"HILLSBORO","code":"US-KS-HB7"},
          {"name":"HILLSDALE","code":"US-KS-LSD"},
          {"name":"HOISINGTON","code":"US-KS-ZHT"},
          {"name":"HOLCOMB","code":"US-KS-HLQ"},
          {"name":"HOLTON","code":"US-KS-HQT"},
          {"name":"HOPE","code":"US-KS-5QB"},
          {"name":"HORTON","code":"US-KS-8GT"},
          {"name":"HOXIE","code":"US-KS-HXE"},
          {"name":"HOYT","code":"US-KS-H9K"},
          {"name":"HUGOTON","code":"US-KS-UGT"},
          {"name":"HUMBOLDT","code":"US-KS-HUE"},
          {"name":"HUTCHINSON","code":"US-KS-HTK"},
          {"name":"INDEPENDENCE","code":"US-KS-IDP"},
          {"name":"IOLA","code":"US-KS-IOL"},
          {"name":"JEWELL","code":"US-KS-JWL"},
          {"name":"JOHNSON","code":"US-KS-JJO"},
          {"name":"JUNCTION CITY","code":"US-KS-JCC"},
          {"name":"KALVESTA","code":"US-KS-K9V"},
          {"name":"KANSAS CITY","code":"US-KS-KCK"},
          {"name":"KINCAID","code":"US-KS-KXC"},
          {"name":"KINGMAN","code":"US-KS-KNM"},
          {"name":"KIRWIN","code":"US-KS-KI7"},
          {"name":"KISMET","code":"US-KS-K3S"},
          {"name":"LA CROSSE","code":"US-KS-RQS"},
          {"name":"LA HARPE","code":"US-KS-KHP"},
          {"name":"LACYGNE","code":"US-KS-LG6"},
          {"name":"LAKE CITY","code":"US-KS-KLC"},
          {"name":"LANSING","code":"US-KS-LSG"},
          {"name":"LARNED","code":"US-KS-LD7"},
          {"name":"LATHAM","code":"US-KS-KLM"},
          {"name":"LAWRENCE","code":"US-KS-LWC"},
          {"name":"LE ROY","code":"US-KS-LQR"},
          {"name":"LEAVENWORTH","code":"US-KS-XIA"},
          {"name":"LEAWOOD","code":"US-KS-LWK"},
          {"name":"LEBANON","code":"US-KS-4NB"},
          {"name":"LENEXA","code":"US-KS-LXA"},
          {"name":"LENORA","code":"US-KS-LR7"},
          {"name":"LEONARDVILLE","code":"US-KS-LE9"},
          {"name":"LEOTI","code":"US-KS-XTI"},
          {"name":"LIBERAL","code":"US-KS-LBL"},
          {"name":"LIBERTY","code":"US-KS-IYB"},
          {"name":"LINCOLN","code":"US-KS-LQC"},
          {"name":"LINDSBORG","code":"US-KS-LDB"},
          {"name":"LINWOOD","code":"US-KS-LW4"},
          {"name":"LONG ISLAND","code":"US-KS-YGI"},
          {"name":"LORRAINE","code":"US-KS-LR8"},
          {"name":"LOUISBURG","code":"US-KS-UIJ"},
          {"name":"LOUISVILLE","code":"US-KS-L5V"},
          {"name":"LUCAS","code":"US-KS-LCC"},
          {"name":"LYNDON","code":"US-KS-YDN"},
          {"name":"LYONS","code":"US-KS-LYO"},
          {"name":"MAIZE","code":"US-KS-Z38"},
          {"name":"MANHATTAN","code":"US-KS-MHK"},
          {"name":"MAPLE HILL","code":"US-KS-MPU"},
          {"name":"MARION","code":"US-KS-9MN"},
          {"name":"MARYSVILLE","code":"US-KS-MYE"},
          {"name":"MAYETTA","code":"US-KS-YEI"},
          {"name":"MCLOUTH","code":"US-KS-C3U"},
          {"name":"MCPHERSON","code":"US-KS-MPR"},
          {"name":"MEADE","code":"US-KS-4MA"},
          {"name":"MEDICINE LODGE","code":"US-KS-JMI"},
          {"name":"MEDORA","code":"US-KS-MD6"},
          {"name":"MERIDEN","code":"US-KS-MN7"},
          {"name":"MERRIAM","code":"US-KS-MEW"},
          {"name":"MILAN","code":"US-KS-YIM"},
          {"name":"MINGO","code":"US-KS-MIG"},
          {"name":"MINNEAPOLIS","code":"US-KS-IPS"},
          {"name":"MINNEOLA","code":"US-KS-9MA"},
          {"name":"MISSION","code":"US-KS-MSI"},
          {"name":"MONETT","code":"US-KS-TTF"},
          {"name":"MONTEZUMA","code":"US-KS-TZM"},
          {"name":"MONUMENT","code":"US-KS-JUM"},
          {"name":"MOSCOW","code":"US-KS-ZGC"},
          {"name":"MOUNDRIDGE","code":"US-KS-MDZ"},
          {"name":"MOUNT HOPE","code":"US-KS-M7H"},
          {"name":"MULVANE","code":"US-KS-VNE"},
          {"name":"MUNCIE","code":"US-KS-UNE"},
          {"name":"NASHVILLE","code":"US-KS-NIF"},
          {"name":"NATOMA","code":"US-KS-TJA"},
          {"name":"NEODESHA","code":"US-KS-NDA"},
          {"name":"NESS CITY","code":"US-KS-NCJ"},
          {"name":"NEW CENTURY","code":"US-KS-NXC"},
          {"name":"NEW STRAWN","code":"US-KS-NRW"},
          {"name":"NEWTON","code":"US-KS-EWK"},
          {"name":"NORTH NEWTON","code":"US-KS-NNW"},
          {"name":"NORTON","code":"US-KS-NT8"},
          {"name":"NORTONVILLE","code":"US-KS-NV2"},
          {"name":"NORWICH","code":"US-KS-NW5"},
          {"name":"OAKLEY","code":"US-KS-OQK"},
          {"name":"OLATHE","code":"US-KS-OLB"},
          {"name":"OLSBURG","code":"US-KS-OFU"},
          {"name":"ONAGA","code":"US-KS-KGA"},
          {"name":"OSAGE CITY","code":"US-KS-OC9"},
          {"name":"OSBORNE","code":"US-KS-OB4"},
          {"name":"OSKALOOSA","code":"US-KS-OS3"},
          {"name":"OTIS","code":"US-KS-TJI"},
          {"name":"OTTAWA","code":"US-KS-OTR"},
          {"name":"OVERBROOK","code":"US-KS-VEK"},
          {"name":"OVERLAND PARK","code":"US-KS-OVL"},
          {"name":"PARADISE","code":"US-KS-P9R"},
          {"name":"PARK","code":"US-KS-KPZ"},
          {"name":"PARK CITY","code":"US-KS-PC9"},
          {"name":"PARSONS","code":"US-KS-PPF"},
          {"name":"PAULINE","code":"US-KS-PUE"},
          {"name":"PAXICO","code":"US-KS-PX6"},
          {"name":"PEABODY","code":"US-KS-YPA"},
          {"name":"PHILLIPSBURG","code":"US-KS-PHJ"},
          {"name":"PIEDMONT","code":"US-KS-PIX"},
          {"name":"PITTSBURG","code":"US-KS-PTS"},
          {"name":"PLAINS","code":"US-KS-P4L"},
          {"name":"PLAINVILLE","code":"US-KS-IIB"},
          {"name":"PLEASANTON","code":"US-KS-DTF"},
          {"name":"PRAIRIE VILLAGE","code":"US-KS-VGP"},
          {"name":"PRATT","code":"US-KS-PTT"},
          {"name":"PROTECTION","code":"US-KS-PT3"},
          {"name":"QUINTER","code":"US-KS-QNR"},
          {"name":"RANTOUL","code":"US-KS-RTU"},
          {"name":"REXFORD","code":"US-KS-RXK"},
          {"name":"RICHMOND","code":"US-KS-RM5"},
          {"name":"RILEY","code":"US-KS-IEY"},
          {"name":"RIVERTON","code":"US-KS-RJE"},
          {"name":"ROELAND PARK","code":"US-KS-REJ"},
          {"name":"ROLLA","code":"US-KS-RK3"},
          {"name":"ROSE HILL","code":"US-KS-7RH"},
          {"name":"ROSEDALE","code":"US-KS-ZED"},
          {"name":"ROSSVILLE","code":"US-KS-RI3"},
          {"name":"RUSH CENTER","code":"US-KS-UEF"},
          {"name":"RUSSELL","code":"US-KS-RSL"},
          {"name":"RUSSELL SPRINGS","code":"US-KS-RUP"},
          {"name":"SABETHA","code":"US-KS-ZHB"},
          {"name":"SAINT FRANCIS","code":"US-KS-SF2"},
          {"name":"SAINT GEORGE","code":"US-KS-GQG"},
          {"name":"SAINT JOHN","code":"US-KS-2JO"},
          {"name":"SAINT MARYS","code":"US-KS-YSR"},
          {"name":"SAINT MARYS","code":"US-KS-YTS"},
          {"name":"SALINA","code":"US-KS-SLN"},
          {"name":"SATANTA","code":"US-KS-S9T"},
          {"name":"SCAMMON","code":"US-KS-ZVN"},
          {"name":"SCOTT CITY","code":"US-KS-ZSN"},
          {"name":"SEDAN","code":"US-KS-SE4"},
          {"name":"SENECA","code":"US-KS-EEC"},
          {"name":"SHAWNEE","code":"US-KS-SHC"},
          {"name":"SHAWNEE MISSION","code":"US-KS-SM2"},
          {"name":"SMITH CENTER","code":"US-KS-9SC"},
          {"name":"SOLOMON","code":"US-KS-SZO"},
          {"name":"SOUTH HUTCHINSON","code":"US-KS-HHS"},
          {"name":"SPEARVILLE","code":"US-KS-KRI"},
          {"name":"SPRING HILL","code":"US-KS-RGI"},
          {"name":"STAFFORD","code":"US-KS-VSZ"},
          {"name":"STERLING","code":"US-KS-S8R"},
          {"name":"STILWELL","code":"US-KS-TLZ"},
          {"name":"STOCKTON","code":"US-KS-S3T"},
          {"name":"SUBLETTE","code":"US-KS-SB9"},
          {"name":"SUN CITY","code":"US-KS-UNY"},
          {"name":"TAMPA","code":"US-KS-TK8"},
          {"name":"TAMPA","code":"US-KS-TM3"},
          {"name":"TECUMSEH","code":"US-KS-TCU"},
          {"name":"THAYER","code":"US-KS-TAY"},
          {"name":"TIPTON","code":"US-KS-TP7"},
          {"name":"TONGANOXIE","code":"US-KS-TN3"},
          {"name":"TOPEKA","code":"US-KS-TOP"},
          {"name":"TORONTO","code":"US-KS-3TT"},
          {"name":"TOWANDA","code":"US-KS-TWK"},
          {"name":"TROY","code":"US-KS-T77"},
          {"name":"ULYSSES","code":"US-KS-USY"},
          {"name":"VALLEY CENTER","code":"US-KS-VC2"},
          {"name":"VALLEY FALLS","code":"US-KS-7VF"},
          {"name":"VASSAR","code":"US-KS-V8K"},
          {"name":"WAKEENEY","code":"US-KS-WKQ"},
          {"name":"WALNUT","code":"US-KS-W5A"},
          {"name":"WALTON","code":"US-KS-WKU"},
          {"name":"WAMEGO","code":"US-KS-WAO"},
          {"name":"WASHINGTON","code":"US-KS-WS9"},
          {"name":"WATERVILLE","code":"US-KS-VWL"},
          {"name":"WATHENA","code":"US-KS-WJA"},
          {"name":"WAVERLY","code":"US-KS-WV6"},
          {"name":"WELLINGTON","code":"US-KS-WEX"},
          {"name":"WELLSVILLE","code":"US-KS-WSV"},
          {"name":"WESKAN","code":"US-KS-WKX"},
          {"name":"WESTMORELAND","code":"US-KS-K5W"},
          {"name":"WESTWOOD","code":"US-KS-XOA"},
          {"name":"WHITE CLOUD","code":"US-KS-ECU"},
          {"name":"WICHITA","code":"US-KS-ICT"},
          {"name":"WINDOM","code":"US-KS-W7M"},
          {"name":"WINFIELD","code":"US-KS-WLD"},
          {"name":"WRIGHT","code":"US-KS-IGF"},
        ]
      },
      {
        name: 'Kentucky',
        code: 'US-KY',
        cities: [
          {"name":"ABERDEEN","code":"US-KY-ABK"},
          {"name":"ADAIRVILLE","code":"US-KY-AD3"},
          {"name":"ALBANY","code":"US-KY-AY9"},
          {"name":"ALEXANDRIA","code":"US-KY-AXP"},
          {"name":"ALLEN","code":"US-KY-AQ9"},
          {"name":"ANNVILLE","code":"US-KY-VVY"},
          {"name":"ASHLAND","code":"US-KY-ANL"},
          {"name":"AUBURN","code":"US-KY-A2U"},
          {"name":"AUGUSTA","code":"US-KY-AGU"},
          {"name":"AUXIER","code":"US-KY-KYX"},
          {"name":"AVON","code":"US-KY-AVY"},
          {"name":"BARBOURVILLE","code":"US-KY-BBZ"},
          {"name":"BARDSTOWN","code":"US-KY-XCV"},
          {"name":"BARLOW","code":"US-KY-KB5"},
          {"name":"BATTLETOWN","code":"US-KY-TVW"},
          {"name":"BEATTYVILLE","code":"US-KY-YVK"},
          {"name":"BEAVER DAM","code":"US-KY-BD3"},
          {"name":"BEDFORD","code":"US-KY-VBD"},
          {"name":"BEECH GROVE","code":"US-KY-GE3"},
          {"name":"BELFRY","code":"US-KY-QFR"},
          {"name":"BELLEVUE","code":"US-KY-XDE"},
          {"name":"BELTON","code":"US-KY-BPL"},
          {"name":"BENTON","code":"US-KY-ZBO"},
          {"name":"BEREA","code":"US-KY-BEU"},
          {"name":"BERLIN","code":"US-KY-BR8"},
          {"name":"BERRY","code":"US-KY-AH8"},
          {"name":"BETHANNA","code":"US-KY-H7N"},
          {"name":"BETHLEHEM","code":"US-KY-BE5"},
          {"name":"BLAZE","code":"US-KY-KYB"},
          {"name":"BLOOMFIELD","code":"US-KY-BF8"},
          {"name":"BOSTON","code":"US-KY-TQN"},
          {"name":"BOWLING GREEN","code":"US-KY-BWG"},
          {"name":"BRANDENBURG","code":"US-KY-BNR"},
          {"name":"BREEDING","code":"US-KY-BG8"},
          {"name":"BRODHEAD","code":"US-KY-BD8"},
          {"name":"BROMLEY","code":"US-KY-BM3"},
          {"name":"BRONSTON","code":"US-KY-TSZ"},
          {"name":"BROOKLYN","code":"US-KY-OYK"},
          {"name":"BROOKS","code":"US-KY-AFR"},
          {"name":"BROOKSVILLE","code":"US-KY-VJL"},
          {"name":"BUCKNER","code":"US-KY-CNW"},
          {"name":"BUECHEL","code":"US-KY-UHU"},
          {"name":"BURKESVILLE","code":"US-KY-ZBV"},
          {"name":"BURLINGTON","code":"US-KY-BHJ"},
          {"name":"BURNSIDE","code":"US-KY-BD5"},
          {"name":"BUTLER","code":"US-KY-BUJ"},
          {"name":"CADIZ","code":"US-KY-YCZ"},
          {"name":"CALHOUN","code":"US-KY-CU3"},
          {"name":"CALVERT CITY","code":"US-KY-CCT"},
          {"name":"CAMPBELLSBURG","code":"US-KY-CBU"},
          {"name":"CAMPBELLSVILLE","code":"US-KY-CMP"},
          {"name":"CAMPTON","code":"US-KY-CTP"},
          {"name":"CANEYVILLE","code":"US-KY-C8V"},
          {"name":"CANMER","code":"US-KY-K85"},
          {"name":"CARROLLTON","code":"US-KY-CRK"},
          {"name":"CARTER","code":"US-KY-ZQR"},
          {"name":"CATLETTSBURG","code":"US-KY-CGK"},
          {"name":"CAVE CITY","code":"US-KY-CCS"},
          {"name":"CECILIA","code":"US-KY-JIA"},
          {"name":"CENTERTOWN","code":"US-KY-CT8"},
          {"name":"CENTRAL CITY","code":"US-KY-CUK"},
          {"name":"CHAVIES","code":"US-KY-KYC"},
          {"name":"CINCINNATI","code":"US-KY-CI2"},
          {"name":"CLAY","code":"US-KY-UKY"},
          {"name":"CLAY CITY","code":"US-KY-CI8"},
          {"name":"CLERMONT","code":"US-KY-CLO"},
          {"name":"CLIFFORD","code":"US-KY-QFF"},
          {"name":"CLOVERPORT","code":"US-KY-CVP"},
          {"name":"COLD SPRING","code":"US-KY-FCG"},
          {"name":"COLUMBIA","code":"US-KY-QOU"},
          {"name":"COLUMBUS","code":"US-KY-UQM"},
          {"name":"CONLEY","code":"US-KY-7CE"},
          {"name":"CONWAY","code":"US-KY-C8K"},
          {"name":"CORBIN","code":"US-KY-CBI"},
          {"name":"COVINGTON","code":"US-KY-CVK"},
          {"name":"COXS CREEK","code":"US-KY-C2C"},
          {"name":"CRESTVIEW HILLS","code":"US-KY-RHS"},
          {"name":"CRESTWOOD","code":"US-KY-CW7"},
          {"name":"CRITTENDEN","code":"US-KY-ITD"},
          {"name":"CROCKETT","code":"US-KY-KHT"},
          {"name":"CROFTON","code":"US-KY-OFN"},
          {"name":"CROMWELL","code":"US-KY-C8W"},
          {"name":"CUMBERLAND","code":"US-KY-HXD"},
          {"name":"CYNTHIANA","code":"US-KY-CAZ"},
          {"name":"DANVILLE","code":"US-KY-DVK"},
          {"name":"DAWSON SPRINGS","code":"US-KY-DSG"},
          {"name":"DAYTON","code":"US-KY-DY2"},
          {"name":"DEBORD","code":"US-KY-DB6"},
          {"name":"DERBY","code":"US-KY-8DB"},
          {"name":"DIXON","code":"US-KY-KDX"},
          {"name":"DOE RUN","code":"US-KY-DOE"},
          {"name":"DOVER","code":"US-KY-DQV"},
          {"name":"DRAKESBORO","code":"US-KY-DSB"},
          {"name":"DRY RIDGE","code":"US-KY-DDJ"},
          {"name":"DUNNVILLE","code":"US-KY-DNV"},
          {"name":"EARLINGTON","code":"US-KY-QEO"},
          {"name":"EAST BERNSTADT","code":"US-KY-ETD"},
          {"name":"EAST POINT","code":"US-KY-EPY"},
          {"name":"EASTWOOD","code":"US-KY-ESZ"},
          {"name":"EDDYVILLE","code":"US-KY-EDV"},
          {"name":"EDGEWOOD","code":"US-KY-GHW"},
          {"name":"EDMONTON","code":"US-KY-EDC"},
          {"name":"ELIZABETHTOWN","code":"US-KY-EKX"},
          {"name":"ELK HORN","code":"US-KY-E3K"},
          {"name":"ELKTON","code":"US-KY-EKT"},
          {"name":"EMINENCE","code":"US-KY-EN2"},
          {"name":"ERLANGER","code":"US-KY-ERL"},
          {"name":"EUBANK","code":"US-KY-9UB"},
          {"name":"FAIRDALE","code":"US-KY-FIA"},
          {"name":"FALLS OF ROUGH","code":"US-KY-KYF"},
          {"name":"FALMOUTH","code":"US-KY-FQZ"},
          {"name":"FARMINGTON","code":"US-KY-FA3"},
          {"name":"FIREBRICK","code":"US-KY-9FB"},
          {"name":"FLEMINGSBURG","code":"US-KY-FMB"},
          {"name":"FLORENCE","code":"US-KY-FKY"},
          {"name":"FORDSVILLE","code":"US-KY-FSV"},
          {"name":"FORT CAMPBELL","code":"US-KY-FCM"},
          {"name":"FORT KNOX","code":"US-KY-FTK"},
          {"name":"FORT MITCHELL","code":"US-KY-FQK"},
          {"name":"FORT THOMAS","code":"US-KY-FHT"},
          {"name":"FORT WRIGHT","code":"US-KY-FWR"},
          {"name":"FOUNTAIN RUN","code":"US-KY-7FR"},
          {"name":"FRANKFORT","code":"US-KY-FFT"},
          {"name":"FRANKLIN","code":"US-KY-FLK"},
          {"name":"FREDONIA","code":"US-KY-FGD"},
          {"name":"FULTON","code":"US-KY-FTB"},
          {"name":"GEORGETOWN","code":"US-KY-GEO"},
          {"name":"GERMANTOWN","code":"US-KY-GMQ"},
          {"name":"GHENT","code":"US-KY-GHE"},
          {"name":"GILBERTSVILLE","code":"US-KY-GI8"},
          {"name":"GLASGOW","code":"US-KY-GLW"},
          {"name":"GLENCOE","code":"US-KY-G5N"},
          {"name":"GORDONVILLE","code":"US-KY-LLJ"},
          {"name":"GOSHEN","code":"US-KY-GQH"},
          {"name":"GRAHAM","code":"US-KY-G3H"},
          {"name":"GRAHN","code":"US-KY-KGY"},
          {"name":"GRAND RIVERS","code":"US-KY-R8R"},
          {"name":"GRAVEL SWITCH","code":"US-KY-UVW"},
          {"name":"GRAY","code":"US-KY-GYK"},
          {"name":"GRAYSON","code":"US-KY-GYO"},
          {"name":"GREENSBURG","code":"US-KY-AEH"},
          {"name":"GREENUP","code":"US-KY-ZDP"},
          {"name":"GREENVILLE","code":"US-KY-GVM"},
          {"name":"GRETHEL","code":"US-KY-GTH"},
          {"name":"GUTHRIE","code":"US-KY-GUH"},
          {"name":"HAGERHILL","code":"US-KY-HH2"},
          {"name":"HANSON","code":"US-KY-HSK"},
          {"name":"HARDINSBURG","code":"US-KY-HXG"},
          {"name":"HARLAN","code":"US-KY-HR6"},
          {"name":"HAROLD","code":"US-KY-A8N"},
          {"name":"HARRODSBURG","code":"US-KY-HBF"},
          {"name":"HARTFORD","code":"US-KY-HTF"},
          {"name":"HAWESVILLE","code":"US-KY-HZZ"},
          {"name":"HAZARD","code":"US-KY-UJL"},
          {"name":"HAZEL","code":"US-KY-HZ2"},
          {"name":"HAZEL GREEN","code":"US-KY-AZE"},
          {"name":"HEBRON","code":"US-KY-HBN"},
          {"name":"HENDERSON","code":"US-KY-XGD"},
          {"name":"HESTAND","code":"US-KY-HQ9"},
          {"name":"HICKMAN","code":"US-KY-HM3"},
          {"name":"HICKORY","code":"US-KY-HIY"},
          {"name":"HILLVIEW","code":"US-KY-VIW"},
          {"name":"HINDMAN","code":"US-KY-IXM"},
          {"name":"HITCHINS","code":"US-KY-KYH"},
          {"name":"HODGENVILLE","code":"US-KY-HGQ"},
          {"name":"HOLMES MILL","code":"US-KY-H9M"},
          {"name":"HOPKINSVILLE","code":"US-KY-HOP"},
          {"name":"HORSE CAVE","code":"US-KY-9VH"},
          {"name":"HUSTONVILLE","code":"US-KY-HUK"},
          {"name":"HYDEN","code":"US-KY-HYJ"},
          {"name":"INDEPENDENCE","code":"US-KY-INP"},
          {"name":"IRVINE","code":"US-KY-IVK"},
          {"name":"IVEL","code":"US-KY-IV8"},
          {"name":"JACKSON","code":"US-KY-JS3"},
          {"name":"JAMESTOWN","code":"US-KY-JMT"},
          {"name":"JEFFERSONTOWN","code":"US-KY-JET"},
          {"name":"JEFFERSONVILLE","code":"US-KY-ZJ9"},
          {"name":"JINKS","code":"US-KY-J6K"},
          {"name":"KANSAS","code":"US-KY-KSJ"},
          {"name":"KEENE","code":"US-KY-K2N"},
          {"name":"KETTLE","code":"US-KY-K6L"},
          {"name":"KEVIL","code":"US-KY-KQL"},
          {"name":"KIMPER","code":"US-KY-KPQ"},
          {"name":"LA CENTER","code":"US-KY-LZR"},
          {"name":"LA FAYETTE","code":"US-KY-LFE"},
          {"name":"LA GRANGE","code":"US-KY-LGR"},
          {"name":"LAFAYETTE","code":"US-KY-QLK"},
          {"name":"LANCASTER","code":"US-KY-LC5"},
          {"name":"LATONIA","code":"US-KY-LT2"},
          {"name":"LAWRENCEBURG","code":"US-KY-LAQ"},
          {"name":"LAWRENCEVILLE","code":"US-KY-LAV"},
          {"name":"LEATHERWOOD","code":"US-KY-L5Q"},
          {"name":"LEBANON","code":"US-KY-LFA"},
          {"name":"LEBANON JUNCTION","code":"US-KY-LJ2"},
          {"name":"LEITCHFIELD","code":"US-KY-LCF"},
          {"name":"LEWISBURG","code":"US-KY-LG7"},
          {"name":"LEWISPORT","code":"US-KY-LWD"},
          {"name":"LEXINGTON","code":"US-KY-LEX"},
          {"name":"LIBERTY","code":"US-KY-LY2"},
          {"name":"LIVERMORE","code":"US-KY-KKY"},
          {"name":"LOGANSPORT","code":"US-KY-OGP"},
          {"name":"LONDON","code":"US-KY-LOZ"},
          {"name":"LORETTO","code":"US-KY-TKY"},
          {"name":"LOUISA","code":"US-KY-KZY"},
          {"name":"LOUISVILLE","code":"US-KY-LUI"},
          {"name":"LOWMANSVILLE","code":"US-KY-LQL"},
          {"name":"LUDLOW","code":"US-KY-LLW"},
          {"name":"LYNCH","code":"US-KY-8HC"},
          {"name":"LYNDON","code":"US-KY-JYN"},
          {"name":"MACEO","code":"US-KY-MKP"},
          {"name":"MADISONVILLE","code":"US-KY-MVQ"},
          {"name":"MAGNOLIA","code":"US-KY-M3G"},
          {"name":"MANCHESTER","code":"US-KY-MCX"},
          {"name":"MARION","code":"US-KY-IJN"},
          {"name":"MARROWBONE","code":"US-KY-MWZ"},
          {"name":"MARTIN","code":"US-KY-ZAR"},
          {"name":"MAYFIELD","code":"US-KY-MAY"},
          {"name":"MAYSVILLE","code":"US-KY-MVU"},
          {"name":"MEANS","code":"US-KY-QMS"},
          {"name":"MIDDLESBORO","code":"US-KY-MBR"},
          {"name":"MIDWAY","code":"US-KY-M23"},
          {"name":"MILLERSBURG","code":"US-KY-ILB"},
          {"name":"MILLWOOD","code":"US-KY-IOO"},
          {"name":"MILTON","code":"US-KY-M8O"},
          {"name":"MINNIE","code":"US-KY-MX8"},
          {"name":"MIRACLE","code":"US-KY-M89"},
          {"name":"MIZE","code":"US-KY-XIZ"},
          {"name":"MONTICELLO","code":"US-KY-MOC"},
          {"name":"MOOREFIELD","code":"US-KY-MF2"},
          {"name":"MOREHEAD","code":"US-KY-MHR"},
          {"name":"MORGANFIELD","code":"US-KY-QGF"},
          {"name":"MORGANTOWN","code":"US-KY-MGG"},
          {"name":"MORTONS GAP","code":"US-KY-MJJ"},
          {"name":"MOUNT EDEN","code":"US-KY-M3E"},
          {"name":"MOUNT STERLING","code":"US-KY-MGK"},
          {"name":"MOUNT VERNON","code":"US-KY-MXV"},
          {"name":"MOUNT WASHINGTON","code":"US-KY-UMX"},
          {"name":"MOUTHCARD","code":"US-KY-MC7"},
          {"name":"MUNFORDVILLE","code":"US-KY-UNF"},
          {"name":"MURRAY","code":"US-KY-CEY"},
          {"name":"NEBO","code":"US-KY-7NO"},
          {"name":"NEW CASTLE","code":"US-KY-JWC"},
          {"name":"NEW HOPE","code":"US-KY-2H4"},
          {"name":"NEWPORT","code":"US-KY-XJU"},
          {"name":"NICHOLASVILLE","code":"US-KY-NCK"},
          {"name":"NORTONVILLE","code":"US-KY-OJV"},
          {"name":"OAKLAND","code":"US-KY-O4K"},
          {"name":"OAKVILLE","code":"US-KY-V6L"},
          {"name":"OLIVE HILL","code":"US-KY-OHF"},
          {"name":"OVEN FORK","code":"US-KY-VFK"},
          {"name":"OWENSBORO","code":"US-KY-OWB"},
          {"name":"OWENTON","code":"US-KY-QOW"},
          {"name":"OWINGSVILLE","code":"US-KY-O2W"},
          {"name":"PADUCAH","code":"US-KY-PAH"},
          {"name":"PAINTSVILLE","code":"US-KY-PAI"},
          {"name":"PARIS","code":"US-KY-PZT"},
          {"name":"PARK CITY","code":"US-KY-PC6"},
          {"name":"PARKERS LAKE","code":"US-KY-PA4"},
          {"name":"PETERSBURG","code":"US-KY-RUG"},
          {"name":"PEWEE VALLEY","code":"US-KY-EYC"},
          {"name":"PHELPS","code":"US-KY-IPP"},
          {"name":"PHILPOT","code":"US-KY-PPP"},
          {"name":"PIKEVILLE","code":"US-KY-PKK"},
          {"name":"PINE KNOT","code":"US-KY-IKN"},
          {"name":"PINEVILLE","code":"US-KY-P8Y"},
          {"name":"PLEASURE RIDGE PARK","code":"US-KY-PGK"},
          {"name":"PLEASUREVILLE","code":"US-KY-EIU"},
          {"name":"PRESTONSBURG","code":"US-KY-PB3"},
          {"name":"PRINCETON","code":"US-KY-PNK"},
          {"name":"PROSPECT","code":"US-KY-POP"},
          {"name":"PROVIDENCE, WEBSTER","code":"US-KY-VDC"},
          {"name":"RACELAND","code":"US-KY-RXA"},
          {"name":"RADCLIFF","code":"US-KY-QRD"},
          {"name":"RAVENNA","code":"US-KY-R8K"},
          {"name":"RICHARDSON","code":"US-KY-R4N"},
          {"name":"RICHMOND","code":"US-KY-RHM"},
          {"name":"RICHWOOD","code":"US-KY-RWO"},
          {"name":"ROBARDS","code":"US-KY-RBA"},
          {"name":"ROCHESTER","code":"US-KY-K5Y"},
          {"name":"ROCKFIELD","code":"US-KY-YIC"},
          {"name":"ROXANA","code":"US-KY-R8A"},
          {"name":"RUMSEY","code":"US-KY-YRJ"},
          {"name":"RUSH","code":"US-KY-RQY"},
          {"name":"RUSSELL","code":"US-KY-RKU"},
          {"name":"RUSSELL SPRINGS","code":"US-KY-RS5"},
          {"name":"RUSSELLVILLE","code":"US-KY-RVK"},
          {"name":"SACRAMENTO","code":"US-KY-9SR"},
          {"name":"SAINT HELENS","code":"US-KY-L2L"},
          {"name":"SALEM","code":"US-KY-K2S"},
          {"name":"SALVISA","code":"US-KY-DQA"},
          {"name":"SALYERSVILLE","code":"US-KY-ZSK"},
          {"name":"SCIENCE HILL","code":"US-KY-HL9"},
          {"name":"SCOTTSVILLE","code":"US-KY-SCV"},
          {"name":"SEBREE","code":"US-KY-YSB"},
          {"name":"SHARPSBURG","code":"US-KY-3SG"},
          {"name":"SHELBYVILLE","code":"US-KY-SVZ"},
          {"name":"SHEPHERDSVILLE","code":"US-KY-SQN"},
          {"name":"SHIVELY","code":"US-KY-IVE"},
          {"name":"SIDNEY","code":"US-KY-S7I"},
          {"name":"SILVER GROVE","code":"US-KY-VKY"},
          {"name":"SIMPSONVILLE","code":"US-KY-SIJ"},
          {"name":"SMITH","code":"US-KY-8YK"},
          {"name":"SMITHFIELD","code":"US-KY-SDE"},
          {"name":"SMITHS GROVE","code":"US-KY-SK6"},
          {"name":"SOMERSET","code":"US-KY-SME"},
          {"name":"SONORA","code":"US-KY-KTY"},
          {"name":"SOUTH SHORE","code":"US-KY-SSH"},
          {"name":"SOUTH UNION","code":"US-KY-YSU"},
          {"name":"SOUTHGATE","code":"US-KY-OUH"},
          {"name":"SPRINGFIELD","code":"US-KY-RNF"},
          {"name":"STAMPING GROUND","code":"US-KY-9SG"},
          {"name":"STANFORD","code":"US-KY-TAN"},
          {"name":"STANTON","code":"US-KY-SX8"},
          {"name":"STANVILLE","code":"US-KY-S4V"},
          {"name":"STONEY FORK","code":"US-KY-UP5"},
          {"name":"STURGIS","code":"US-KY-TUG"},
          {"name":"SUMMER SHADE","code":"US-KY-SU4"},
          {"name":"SYMSONIA","code":"US-KY-YMO"},
          {"name":"TAYLOR MILL","code":"US-KY-YLI"},
          {"name":"TAYLORSVILLE","code":"US-KY-YLV"},
          {"name":"TOMPKINSVILLE","code":"US-KY-TPM"},
          {"name":"TRENTON","code":"US-KY-TXE"},
          {"name":"TURNERS STATION","code":"US-KY-TUO"},
          {"name":"UNION","code":"US-KY-UN7"},
          {"name":"UNIONTOWN","code":"US-KY-UT2"},
          {"name":"VANCEBURG","code":"US-KY-VGS"},
          {"name":"VERONA","code":"US-KY-VRO"},
          {"name":"VERSAILLES","code":"US-KY-VSS"},
          {"name":"VICCO","code":"US-KY-VQO"},
          {"name":"VINE GROVE","code":"US-KY-VGR"},
          {"name":"VIRGIE","code":"US-KY-3VR"},
          {"name":"WALLINGFORD","code":"US-KY-YGF"},
          {"name":"WALTON","code":"US-KY-QWT"},
          {"name":"WARSAW","code":"US-KY-WZS"},
          {"name":"WEST LIBERTY","code":"US-KY-WLH"},
          {"name":"WEST LOUISVILLE","code":"US-KY-9WL"},
          {"name":"WHITE PLAINS","code":"US-KY-W7P"},
          {"name":"WHITESBURG","code":"US-KY-BRG"},
          {"name":"WHITLEY CITY","code":"US-KY-W2K"},
          {"name":"WHITNER","code":"US-KY-WHK"},
          {"name":"WICKLIFFE","code":"US-KY-WKY"},
          {"name":"WILDER","code":"US-KY-WII"},
          {"name":"WILLIAMSBURG","code":"US-KY-WBK"},
          {"name":"WILLIAMSTOWN","code":"US-KY-WS8"},
          {"name":"WILLISBURG","code":"US-KY-W8K"},
          {"name":"WILMORE","code":"US-KY-W6M"},
          {"name":"WINCHESTER","code":"US-KY-WIN"},
          {"name":"WOLF CREEK","code":"US-KY-WZB"},
          {"name":"WOODBURN","code":"US-KY-TVN"},
          {"name":"WOODMAN","code":"US-KY-WK7"},
          {"name":"WOORBURN","code":"US-KY-OEF"},
          {"name":"WORTHINGTON","code":"US-KY-W4T"},
          {"name":"WURTLAND","code":"US-KY-WUR"},
          {"name":"YORK","code":"US-KY-YKY"},
        ]
      },
      {
        name: 'Luisiana',
        code: 'US-LA',
        cities: [
          {"name":"ABBEVILLE","code":"US-LA-UAB"},
          {"name":"ABITA SPRINGS","code":"US-LA-TPG"},
          {"name":"ADDIS","code":"US-LA-ADD"},
          {"name":"AKERS","code":"US-LA-AKQ"},
          {"name":"ALBANY","code":"US-LA-A7Y"},
          {"name":"ALEXANDRIA","code":"US-LA-ESF"},
          {"name":"ALGIERS","code":"US-LA-UAY"},
          {"name":"ALLIANCE","code":"US-LA-AL3"},
          {"name":"AMA","code":"US-LA-AMD"},
          {"name":"AMELIA","code":"US-LA-AEI"},
          {"name":"AMITE","code":"US-LA-UBH"},
          {"name":"ANGOLA","code":"US-LA-ZAI"},
          {"name":"ARABI","code":"US-LA-AQX"},
          {"name":"ARCADIA","code":"US-LA-AIQ"},
          {"name":"ARCOLA","code":"US-LA-XAY"},
          {"name":"ARNAUDVILLE","code":"US-LA-DVZ"},
          {"name":"AVERY ISLAND","code":"US-LA-AVI"},
          {"name":"AVONDALE","code":"US-LA-AVD"},
          {"name":"BAKER","code":"US-LA-BKM"},
          {"name":"BALDWIN","code":"US-LA-LDK"},
          {"name":"BALL","code":"US-LA-BA9"},
          {"name":"BASKIN","code":"US-LA-QBA"},
          {"name":"BASTROP","code":"US-LA-BAS"},
          {"name":"BATCHELOR","code":"US-LA-BC9"},
          {"name":"BATON ROUGE","code":"US-LA-BTR"},
          {"name":"BAYOU VISTA","code":"US-LA-VI9"},
          {"name":"BEEKMAN","code":"US-LA-QKH"},
          {"name":"BELLE CHASE","code":"US-LA-9BC"},
          {"name":"BELLE CHASSE","code":"US-LA-BCS"},
          {"name":"BENTON","code":"US-LA-BN3"},
          {"name":"BERNICE","code":"US-LA-B5N"},
          {"name":"BERWICK","code":"US-LA-RWK"},
          {"name":"BLUFF CREEK","code":"US-LA-BC2"},
          {"name":"BOGALUSA","code":"US-LA-BXA"},
          {"name":"BOSSIER CITY","code":"US-LA-BSR"},
          {"name":"BOURG","code":"US-LA-BOR"},
          {"name":"BOUTTE","code":"US-LA-TUB"},
          {"name":"BOYCE","code":"US-LA-OYC"},
          {"name":"BRAITHWAITE","code":"US-LA-BWT"},
          {"name":"BREAUX BRIDGE","code":"US-LA-BXB"},
          {"name":"BRIAN","code":"US-LA-BXL"},
          {"name":"BRIDGE CITY","code":"US-LA-DIY"},
          {"name":"BROUSSARD, LAFAYETTE","code":"US-LA-BSY"},
          {"name":"BRUSLY","code":"US-LA-BL6"},
          {"name":"BUNKIE","code":"US-LA-BQK"},
          {"name":"BURAS","code":"US-LA-BUS"},
          {"name":"BURNSIDE","code":"US-LA-UDE"},
          {"name":"BUSH","code":"US-LA-DBH"},
          {"name":"CADE","code":"US-LA-KDE"},
          {"name":"CALCASIEU","code":"US-LA-CAU"},
          {"name":"CAMERON","code":"US-LA-YCN"},
          {"name":"CAMPTI","code":"US-LA-CIM"},
          {"name":"CARENCRO","code":"US-LA-RCO"},
          {"name":"CARLYSS","code":"US-LA-9SS"},
          {"name":"CARTERVILLE","code":"US-LA-AV7"},
          {"name":"CARVILLE","code":"US-LA-LXL"},
          {"name":"CENTERVILLE","code":"US-LA-CVZ"},
          {"name":"CHALMETTE","code":"US-LA-CLQ"},
          {"name":"CHAUVIN","code":"US-LA-KCV"},
          {"name":"CHOPIN","code":"US-LA-QHP"},
          {"name":"CHOUDRANT","code":"US-LA-UDR"},
          {"name":"CHURCH POINT","code":"US-LA-HCP"},
          {"name":"CLINTON","code":"US-LA-C4N"},
          {"name":"COLFAX","code":"US-LA-COF"},
          {"name":"COLUMBIA","code":"US-LA-C72"},
          {"name":"CONVENT","code":"US-LA-CEN"},
          {"name":"CONVERSE","code":"US-LA-ONV"},
          {"name":"COTTON VALLEY","code":"US-LA-TL9"},
          {"name":"COUSHATTA","code":"US-LA-CH7"},
          {"name":"COVINGTON","code":"US-LA-CWT"},
          {"name":"CROWLEY","code":"US-LA-CWL"},
          {"name":"CULLEN","code":"US-LA-UDN"},
          {"name":"CUT OFF","code":"US-LA-UOF"},
          {"name":"DARROW","code":"US-LA-DRR"},
          {"name":"DAVANT","code":"US-LA-DVT"},
          {"name":"DE RIDDER","code":"US-LA-DRI"},
          {"name":"DEER PARK","code":"US-LA-5DP"},
          {"name":"DELHI","code":"US-LA-DHX"},
          {"name":"DENHAM SPRINGS","code":"US-LA-DNM"},
          {"name":"DEQUINCY","code":"US-LA-QDQ"},
          {"name":"DERIDDER","code":"US-LA-DE3"},
          {"name":"DESTREHAN","code":"US-LA-NEW"},
          {"name":"DIXIE","code":"US-LA-EXX"},
          {"name":"DODSON","code":"US-LA-DS4"},
          {"name":"DONALDSONVILLE","code":"US-LA-DNA"},
          {"name":"DOWNSVILLE","code":"US-LA-DV8"},
          {"name":"EFFIE","code":"US-LA-EFZ"},
          {"name":"ELTON","code":"US-LA-EJQ"},
          {"name":"EMPIRE","code":"US-LA-EPE"},
          {"name":"ERATH","code":"US-LA-E7R"},
          {"name":"EUNICE","code":"US-LA-UCE"},
          {"name":"FARMERVILLE","code":"US-LA-FVF"},
          {"name":"FERRIDAY","code":"US-LA-QFI"},
          {"name":"FLORIEN","code":"US-LA-FMD"},
          {"name":"FOLSOM","code":"US-LA-FOJ"},
          {"name":"FORBING","code":"US-LA-FBQ"},
          {"name":"FOREST HILL","code":"US-LA-FH9"},
          {"name":"FORT POLK","code":"US-LA-POE"},
          {"name":"FRANKLIN","code":"US-LA-RNK"},
          {"name":"FRANKLINTON","code":"US-LA-FKB"},
          {"name":"GALLIANO","code":"US-LA-EPB"},
          {"name":"GARYVILLE","code":"US-LA-GYV"},
          {"name":"GEISMAR","code":"US-LA-GSM"},
          {"name":"GIBSON","code":"US-LA-8GI"},
          {"name":"GOLDEN MEADOW","code":"US-LA-GM3"},
          {"name":"GOLDONNA","code":"US-LA-GL4"},
          {"name":"GONZALES","code":"US-LA-GNZ"},
          {"name":"GOOD HOPE","code":"US-LA-GOP"},
          {"name":"GRAMBLING","code":"US-LA-GB6"},
          {"name":"GRAMERCY","code":"US-LA-GRY"},
          {"name":"GRAND CHENIER","code":"US-LA-G3C"},
          {"name":"GRAND COTEAU","code":"US-LA-G2U"},
          {"name":"GRAND ISLE","code":"US-LA-GIJ"},
          {"name":"GRAY","code":"US-LA-GYA"},
          {"name":"GREENWELL SPRINGS","code":"US-LA-GPS"},
          {"name":"GREENWOOD","code":"US-LA-G2W"},
          {"name":"GRETNA","code":"US-LA-GTL"},
          {"name":"GUEYDAN","code":"US-LA-GU3"},
          {"name":"HACKBERRY","code":"US-LA-KBR"},
          {"name":"HAHNVILLE","code":"US-LA-HAH"},
          {"name":"HAMMOND","code":"US-LA-HLO"},
          {"name":"HARAHAN","code":"US-LA-HRJ"},
          {"name":"HARRISONBURG","code":"US-LA-HAJ"},
          {"name":"HARVEY","code":"US-LA-HMJ"},
          {"name":"HAUGHTON","code":"US-LA-HGO"},
          {"name":"HAYNESVILLE","code":"US-LA-XHA"},
          {"name":"HESSMER","code":"US-LA-ZD4"},
          {"name":"HODGE","code":"US-LA-HG9"},
          {"name":"HOLDEN","code":"US-LA-HQ3"},
          {"name":"HOLLY RIDGE","code":"US-LA-OYR"},
          {"name":"HOMER","code":"US-LA-HMH"},
          {"name":"HOUMA","code":"US-LA-HUM"},
          {"name":"INDEPENDENCE","code":"US-LA-IPC"},
          {"name":"IOWA","code":"US-LA-IOQ"},
          {"name":"JACKSON","code":"US-LA-JKN"},
          {"name":"JEANERETTE","code":"US-LA-JTE"},
          {"name":"JEFFERSON","code":"US-LA-JFF"},
          {"name":"JEFFERSON ISLAND","code":"US-LA-JED"},
          {"name":"JENA","code":"US-LA-JNA"},
          {"name":"JENNINGS","code":"US-LA-JEN"},
          {"name":"JONESVILLE","code":"US-LA-JLX"},
          {"name":"KAPLAN","code":"US-LA-KPL"},
          {"name":"KEITHVILLE","code":"US-LA-KV2"},
          {"name":"KENDALL","code":"US-LA-KDL"},
          {"name":"KENNER","code":"US-LA-KES"},
          {"name":"KENTWOOD","code":"US-LA-KNU"},
          {"name":"KILLONA","code":"US-LA-KON"},
          {"name":"KINDER","code":"US-LA-KIJ"},
          {"name":"KROTZ SPRINGS","code":"US-LA-KZS"},
          {"name":"LA PLACE","code":"US-LA-LPL"},
          {"name":"LACASSINE","code":"US-LA-IKE"},
          {"name":"LACOMBE","code":"US-LA-LQB"},
          {"name":"LAFAYETTE","code":"US-LA-LFT"},
          {"name":"LAFITTE","code":"US-LA-LJF"},
          {"name":"LAKE ARTHUR","code":"US-LA-LK3"},
          {"name":"LAKE CHARLES","code":"US-LA-LCH"},
          {"name":"LAKE PROVIDENCE","code":"US-LA-YLP"},
          {"name":"LAKELAND","code":"US-LA-ZF6"},
          {"name":"LAROSE","code":"US-LA-LRS"},
          {"name":"LE COMPTE","code":"US-LA-LCG"},
          {"name":"LE MOYEN","code":"US-LA-ZHL"},
          {"name":"LEBLANC","code":"US-LA-QEC"},
          {"name":"LECOMPTE","code":"US-LA-LCP"},
          {"name":"LEESVILLE","code":"US-LA-LSV"},
          {"name":"LENA","code":"US-LA-LNQ"},
          {"name":"LIVINGSTON","code":"US-LA-LVO"},
          {"name":"LOCKPORT","code":"US-LA-LPT"},
          {"name":"LOGANSPORT","code":"US-LA-LST"},
          {"name":"LONGVILLE","code":"US-LA-LO4"},
          {"name":"LOOP TERMINAL","code":"US-LA-LO9"},
          {"name":"LOREAUVILLE","code":"US-LA-LUX"},
          {"name":"LOUISIANA OFFSHORE OIL PORT (LOOP)","code":"US-LA-LO8"},
          {"name":"LULING","code":"US-LA-LYG"},
          {"name":"LUTCHER","code":"US-LA-LUH"},
          {"name":"MADISONVILLE","code":"US-LA-INV"},
          {"name":"MAMOU","code":"US-LA-MUX"},
          {"name":"MANDEVILLE","code":"US-LA-MAB"},
          {"name":"MANSFIELD","code":"US-LA-AFD"},
          {"name":"MANSURA","code":"US-LA-NSU"},
          {"name":"MANY","code":"US-LA-NMY"},
          {"name":"MARINGOUIN","code":"US-LA-MGU"},
          {"name":"MARION","code":"US-LA-MN2"},
          {"name":"MARKSVILLE","code":"US-LA-ZEK"},
          {"name":"MARRERO","code":"US-LA-MAR"},
          {"name":"MARYLAND","code":"US-LA-ZJW"},
          {"name":"MATHEWS","code":"US-LA-MJW"},
          {"name":"MER ROUGE","code":"US-LA-ZXE"},
          {"name":"MERAUX","code":"US-LA-MXP"},
          {"name":"MERMENTAU","code":"US-LA-MNU"},
          {"name":"METAIRIE","code":"US-LA-MTE"},
          {"name":"MINDEN","code":"US-LA-MYX"},
          {"name":"MONROE","code":"US-LA-MLU"},
          {"name":"MONTGOMERY","code":"US-LA-3MG"},
          {"name":"MONTZ","code":"US-LA-ZPQ"},
          {"name":"MORGAN CITY","code":"US-LA-LMO"},
          {"name":"MORGAN CITY-PATTERSON APT","code":"US-LA-PTN"},
          {"name":"MORRIS","code":"US-LA-MR3"},
          {"name":"MOUNT AIRY","code":"US-LA-M8Y"},
          {"name":"MYRTLE GROVE","code":"US-LA-MYG"},
          {"name":"NAPOLEONVILLE","code":"US-LA-NVW"},
          {"name":"NATCHITOCHES","code":"US-LA-NTH"},
          {"name":"NEW IBERIA","code":"US-LA-ARA"},
          {"name":"NEW ORLEANS","code":"US-LA-MSY"},
          {"name":"NEW ROADS","code":"US-LA-N3R"},
          {"name":"NEWELLTON","code":"US-LA-NLT"},
          {"name":"NORCO","code":"US-LA-NCM"},
          {"name":"OAK GROVE","code":"US-LA-OGK"},
          {"name":"OAKDALE","code":"US-LA-ODA"},
          {"name":"OAKLAND","code":"US-LA-Z2G"},
          {"name":"OIL CITY","code":"US-LA-OTY"},
          {"name":"OPELOUSAS","code":"US-LA-OPL"},
          {"name":"OSTRICA","code":"US-LA-OST"},
          {"name":"PAINCOURTVILLE","code":"US-LA-XXQ"},
          {"name":"PALMETTO, BOSSIER","code":"US-LA-PQE"},
          {"name":"PALMETTO, SAINT LANDRY","code":"US-LA-PFM"},
          {"name":"PATTERSON","code":"US-LA-ZPA"},
          {"name":"PATTERSON-MORGAN CITY APT","code":"US-LA-PTN"},
          {"name":"PEARL RIVER","code":"US-LA-PR8"},
          {"name":"PELICAN","code":"US-LA-PQL"},
          {"name":"PIERRE PART","code":"US-LA-PPQ"},
          {"name":"PILOTTOWN","code":"US-LA-PIW"},
          {"name":"PINEVILLE","code":"US-LA-PVE"},
          {"name":"PLAQUEMINE","code":"US-LA-PLQ"},
          {"name":"PLAUCHEVILLE","code":"US-LA-PUU"},
          {"name":"PLEASANT HILL","code":"US-LA-PDH"},
          {"name":"POINTE A LA HACHE","code":"US-LA-PHC"},
          {"name":"POLLOCK","code":"US-LA-P2O"},
          {"name":"PONCHATOULA","code":"US-LA-PCS"},
          {"name":"PORT ALLEN","code":"US-LA-PLL"},
          {"name":"PORT BARRE","code":"US-LA-RTB"},
          {"name":"PORT EADS","code":"US-LA-QPR"},
          {"name":"PORT FOURCHON","code":"US-LA-FOC"},
          {"name":"PORT HUDSON","code":"US-LA-PHV"},
          {"name":"PORT OF SOUTH LOUISIANA","code":"US-LA-LU8"},
          {"name":"PORT SULPHUR","code":"US-LA-SUL"},
          {"name":"PRAIRIEVILLE","code":"US-LA-PRA"},
          {"name":"PRINCETON","code":"US-LA-PN6"},
          {"name":"RACELAND","code":"US-LA-RCB"},
          {"name":"RAYNE","code":"US-LA-RYL"},
          {"name":"RAYVILLE","code":"US-LA-RVY"},
          {"name":"REDDELL","code":"US-LA-RDZ"},
          {"name":"RESERVE","code":"US-LA-RSF"},
          {"name":"RIVER RIDGE","code":"US-LA-QQN"},
          {"name":"RIVERSIDE","code":"US-LA-R9S"},
          {"name":"ROBELINE","code":"US-LA-RE3"},
          {"name":"ROMEVILLE","code":"US-LA-ZH7"},
          {"name":"ROSELAND","code":"US-LA-XRL"},
          {"name":"ROSEPINE","code":"US-LA-RZP"},
          {"name":"RUSTON","code":"US-LA-RSN"},
          {"name":"SAINT AMANT","code":"US-LA-4SA"},
          {"name":"SAINT BERNARD PARISH","code":"US-LA-SBD"},
          {"name":"SAINT CHARLES","code":"US-LA-ZSC"},
          {"name":"SAINT FRANCISVILLE","code":"US-LA-SFV"},
          {"name":"SAINT GABRIEL","code":"US-LA-SGL"},
          {"name":"SAINT JAMES","code":"US-LA-SNJ"},
          {"name":"SAINT JAMES PARISH","code":"US-LA-SJ6"},
          {"name":"SAINT JOE","code":"US-LA-JJE"},
          {"name":"SAINT JOSEPH","code":"US-LA-YSJ"},
          {"name":"SAINT LOUIS","code":"US-LA-UTS"},
          {"name":"SAINT MARTINVILLE","code":"US-LA-SKM"},
          {"name":"SAINT ROSE","code":"US-LA-SRE"},
          {"name":"SAINT ROSE PLANTATION","code":"US-LA-AIP"},
          {"name":"SAREPTA","code":"US-LA-AEP"},
          {"name":"SCHRIEVER","code":"US-LA-S9C"},
          {"name":"SCOTT","code":"US-LA-ZKO"},
          {"name":"SHREVEPORT","code":"US-LA-SHV"},
          {"name":"SIBLEY","code":"US-LA-S5B"},
          {"name":"SICILY ISLAND","code":"US-LA-IYD"},
          {"name":"SIMSBORO","code":"US-LA-SIO"},
          {"name":"SINGER","code":"US-LA-IGR"},
          {"name":"SLAUGHTER","code":"US-LA-UHT"},
          {"name":"SLIDELL","code":"US-LA-UYN"},
          {"name":"SOMERSET","code":"US-LA-S73"},
          {"name":"SORRENTO","code":"US-LA-S9R"},
          {"name":"SOUTH BEND","code":"US-LA-S3B"},
          {"name":"SPRINGHILL","code":"US-LA-SIQ"},
          {"name":"STERLINGTON","code":"US-LA-SEX"},
          {"name":"STONEWALL","code":"US-LA-LA9"},
          {"name":"SULPHUR","code":"US-LA-SLP"},
          {"name":"SUNSET","code":"US-LA-SU6"},
          {"name":"SUNSHINE","code":"US-LA-9SH"},
          {"name":"SUPREME","code":"US-LA-SYX"},
          {"name":"TAFT","code":"US-LA-TFT"},
          {"name":"TALLA BENA","code":"US-LA-TBB"},
          {"name":"TALLULAH","code":"US-LA-TAU"},
          {"name":"TAYLOR","code":"US-LA-HQ7"},
          {"name":"TERRYTOWN","code":"US-LA-TYW"},
          {"name":"THIBODAUX","code":"US-LA-THI"},
          {"name":"TIOGA","code":"US-LA-TGA"},
          {"name":"TRENTON","code":"US-LA-YTR"},
          {"name":"URANIA","code":"US-LA-URN"},
          {"name":"VACHERIE","code":"US-LA-VC8"},
          {"name":"VENICE","code":"US-LA-VN3"},
          {"name":"VIDALIA","code":"US-LA-VDL"},
          {"name":"VILLE PLATTE","code":"US-LA-VPT"},
          {"name":"VINTON","code":"US-LA-DZN"},
          {"name":"VIOLET","code":"US-LA-VOT"},
          {"name":"VIVIAN","code":"US-LA-VVN"},
          {"name":"WAGGAMAN","code":"US-LA-WG2"},
          {"name":"WALKER, JACKSON","code":"US-LA-WK3"},
          {"name":"WALKER, LIVINGSTON","code":"US-LA-WK4"},
          {"name":"WASHINGTON","code":"US-LA-HQJ"},
          {"name":"WASHINGTON","code":"US-LA-LXZ"},
          {"name":"WATERPROOF","code":"US-LA-WPF"},
          {"name":"WEEKS","code":"US-LA-WKS"},
          {"name":"WELCOME","code":"US-LA-EOM"},
          {"name":"WELSH","code":"US-LA-WL5"},
          {"name":"WEST MONROE","code":"US-LA-WMX"},
          {"name":"WESTLAKE","code":"US-LA-WQL"},
          {"name":"WESTWEGO","code":"US-LA-WWO"},
          {"name":"WHITE CASTLE","code":"US-LA-FWC"},
          {"name":"WINNFIELD","code":"US-LA-WFN"},
          {"name":"WINNSBORO","code":"US-LA-WYL"},
          {"name":"WOODWORTH","code":"US-LA-W2W"},
          {"name":"YOUNGSVILLE","code":"US-LA-YGV"},
          {"name":"ZACHARY","code":"US-LA-ZQK"},
          {"name":"ZWOLLE","code":"US-LA-ZWL"},
        ]
      },
      {
        name: 'Maine',
        code: 'US-ME',
        cities: [
          {"name":"ABBOT","code":"US-ME-A8B"},
          {"name":"ACTON","code":"US-ME-8ON"},
          {"name":"ADDISON","code":"US-ME-IAB"},
          {"name":"ALBION","code":"US-ME-AO7"},
          {"name":"ALFRED","code":"US-ME-AFE"},
          {"name":"ALNA","code":"US-ME-8AL"},
          {"name":"AMHERST","code":"US-ME-7AH"},
          {"name":"ANSON","code":"US-ME-O9N"},
          {"name":"ARUNDEL","code":"US-ME-XJW"},
          {"name":"ASHLAND","code":"US-ME-A47"},
          {"name":"ATHENS","code":"US-ME-QAH"},
          {"name":"AUBURN","code":"US-ME-AUE"},
          {"name":"AUBURN-LEWISTON APT","code":"US-ME-LEW"},
          {"name":"AUGUSTA","code":"US-ME-AUG"},
          {"name":"AURORA","code":"US-ME-A8U"},
          {"name":"AVON LAKE","code":"US-ME-AVC"},
          {"name":"BAILEY ISLAND","code":"US-ME-EYI"},
          {"name":"BAILEYVILLE","code":"US-ME-ILY"},
          {"name":"BANGOR","code":"US-ME-BGR"},
          {"name":"BAR HARBOR","code":"US-ME-BHB"},
          {"name":"BASS HARBOR","code":"US-ME-9HA"},
          {"name":"BATH","code":"US-ME-BTH"},
          {"name":"BEALS","code":"US-ME-9BL"},
          {"name":"BELFAST","code":"US-ME-BEL"},
          {"name":"BELGRADE","code":"US-ME-8DG"},
          {"name":"BENTON","code":"US-ME-BTZ"},
          {"name":"BERWICK","code":"US-ME-BER"},
          {"name":"BETHEL","code":"US-ME-EQB"},
          {"name":"BIDDEFORD","code":"US-ME-BDJ"},
          {"name":"BINGHAM","code":"US-ME-AFM"},
          {"name":"BLAINE","code":"US-ME-9BI"},
          {"name":"BLUE HILL","code":"US-ME-8BU"},
          {"name":"BOOTHBAY","code":"US-ME-OBX"},
          {"name":"BOOTHBAY HARBOR","code":"US-ME-OYH"},
          {"name":"BOWDOIN","code":"US-ME-8BO"},
          {"name":"BOWDOINHAM","code":"US-ME-LJG"},
          {"name":"BRADFORD","code":"US-ME-B8R"},
          {"name":"BRADLEY","code":"US-ME-B8L"},
          {"name":"BREMEN","code":"US-ME-RMZ"},
          {"name":"BREWER","code":"US-ME-BXH"},
          {"name":"BRIDGEWATER","code":"US-ME-BDT"},
          {"name":"BRIDGTON","code":"US-ME-BT3"},
          {"name":"BRISTOL","code":"US-ME-IZO"},
          {"name":"BROOKLIN, HANCOCK","code":"US-ME-BL3"},
          {"name":"BROOKS","code":"US-ME-9OK"},
          {"name":"BROOKSVILLE","code":"US-ME-8VL"},
          {"name":"BROWNFIELD","code":"US-ME-B8F"},
          {"name":"BROWNVILLE","code":"US-ME-8BL"},
          {"name":"BRUNSWICK","code":"US-ME-NHZ"},
          {"name":"BUCKFIELD","code":"US-ME-KF2"},
          {"name":"BUCKSPORT","code":"US-ME-BUP"},
          {"name":"BUFFALO","code":"US-ME-BS7"},
          {"name":"BURLINGTON","code":"US-ME-B8U"},
          {"name":"BURNHAM","code":"US-ME-BNH"},
          {"name":"BUXTON","code":"US-ME-X8T"},
          {"name":"CALAIS","code":"US-ME-CAL"},
          {"name":"CAMBRIDGE","code":"US-ME-B7G"},
          {"name":"CAMDEN","code":"US-ME-AQQ"},
          {"name":"CANAAN","code":"US-ME-8CN"},
          {"name":"CANTON","code":"US-ME-9ON"},
          {"name":"CAPE ELIZABETH","code":"US-ME-CEL"},
          {"name":"CAPE NEDDICK","code":"US-ME-M6C"},
          {"name":"CARATUNK","code":"US-ME-9TU"},
          {"name":"CARIBOU","code":"US-ME-CAR"},
          {"name":"CARMEL","code":"US-ME-C9M"},
          {"name":"CARRABASSET VALLEY","code":"US-ME-B6V"},
          {"name":"CASCO","code":"US-ME-CS3"},
          {"name":"CASTINE","code":"US-ME-CAS"},
          {"name":"CHARLESTON","code":"US-ME-8ES"},
          {"name":"CHEBEAGUE ISLAND","code":"US-ME-8CI"},
          {"name":"CHELSEA","code":"US-ME-VHA"},
          {"name":"CHERRYFIELD","code":"US-ME-CFF"},
          {"name":"CHESTER","code":"US-ME-6CM"},
          {"name":"CHINA","code":"US-ME-QZF"},
          {"name":"CLINTON","code":"US-ME-QXZ"},
          {"name":"COLUMBIA FALLS","code":"US-ME-CF9"},
          {"name":"CORINNA","code":"US-ME-VJA"},
          {"name":"CORINTH","code":"US-ME-8CH"},
          {"name":"CORNISH","code":"US-ME-9IS"},
          {"name":"CRANBERRY ISLES","code":"US-ME-C9I"},
          {"name":"CUMBERLAND CENTER","code":"US-ME-UDT"},
          {"name":"CUSHING","code":"US-ME-8CU"},
          {"name":"DAMARISCOTTA","code":"US-ME-D8A"},
          {"name":"DANFORTH","code":"US-ME-6FT"},
          {"name":"DEER ISLE","code":"US-ME-8DI"},
          {"name":"DENMARK","code":"US-ME-D8E"},
          {"name":"DENNYSVILLE","code":"US-ME-8NY"},
          {"name":"DERBY","code":"US-ME-DPY"},
          {"name":"DETROIT","code":"US-ME-DT7"},
          {"name":"DEXTER","code":"US-ME-XFB"},
          {"name":"DIXFIELD","code":"US-ME-DXF"},
          {"name":"DIXMONT","code":"US-ME-D9I"},
          {"name":"DOVER FOXCROFT","code":"US-ME-8FX"},
          {"name":"DRESDEN","code":"US-ME-D2R"},
          {"name":"DURHAM","code":"US-ME-8DU"},
          {"name":"EAGLE LAKE","code":"US-ME-9EL"},
          {"name":"EAST BOOTHBAY","code":"US-ME-EXJ"},
          {"name":"EAST LIVERMORE","code":"US-ME-E8L"},
          {"name":"EAST MACHIAS","code":"US-ME-EM7"},
          {"name":"EAST MILLINOCKET","code":"US-ME-EMI"},
          {"name":"EAST WILTON","code":"US-ME-EW8"},
          {"name":"EASTON","code":"US-ME-XFF"},
          {"name":"EASTPORT","code":"US-ME-EPM"},
          {"name":"EDDINGTON","code":"US-ME-E2D"},
          {"name":"EDGECOMB","code":"US-ME-ECB"},
          {"name":"ELIOT","code":"US-ME-EL9"},
          {"name":"ELLSWORTH","code":"US-ME-ESR"},
          {"name":"ETNA","code":"US-ME-9ET"},
          {"name":"EUSTIS","code":"US-ME-EUT"},
          {"name":"EXETER","code":"US-ME-EXK"},
          {"name":"FAIRFIELD","code":"US-ME-YFE"},
          {"name":"FALMOUTH","code":"US-ME-FUA"},
          {"name":"FARMINGDALE","code":"US-ME-F8A"},
          {"name":"FARMINGTON","code":"US-ME-FMQ"},
          {"name":"FARMINGTON FALLS","code":"US-ME-FGS"},
          {"name":"FORT FAIRFIELD","code":"US-ME-FFF"},
          {"name":"FORT KENT","code":"US-ME-FKT"},
          {"name":"FRANKFORT","code":"US-ME-F8F"},
          {"name":"FRANKLIN","code":"US-ME-F8K"},
          {"name":"FREEDOM","code":"US-ME-F9M"},
          {"name":"FREEPORT","code":"US-ME-REP"},
          {"name":"FRENCHBORO","code":"US-ME-F8O"},
          {"name":"FRENCHVILLE","code":"US-ME-WFK"},
          {"name":"FRIENDSHIP","code":"US-ME-FSP"},
          {"name":"FRYEBURG","code":"US-ME-FRY"},
          {"name":"GARDINER","code":"US-ME-GDN"},
          {"name":"GARLAND","code":"US-ME-9GA"},
          {"name":"GEORGETOWN","code":"US-ME-G8E"},
          {"name":"GILFORD","code":"US-ME-GF2"},
          {"name":"GORHAM","code":"US-ME-GHH"},
          {"name":"GOULDSBORO","code":"US-ME-8GU"},
          {"name":"GRAND ISLE","code":"US-ME-9GI"},
          {"name":"GRAY","code":"US-ME-GRG"},
          {"name":"GREENBUSH","code":"US-ME-G8B"},
          {"name":"GREENE","code":"US-ME-G8R"},
          {"name":"GREENVILLE","code":"US-ME-GJV"},
          {"name":"GREENWOOD","code":"US-ME-GQW"},
          {"name":"GUILFORD","code":"US-ME-GUL"},
          {"name":"HALLOWELL","code":"US-ME-H8W"},
          {"name":"HAMPDEN","code":"US-ME-HA2"},
          {"name":"HANCOCK","code":"US-ME-HNK"},
          {"name":"HANOVER","code":"US-ME-8HV"},
          {"name":"HARMONY","code":"US-ME-9HY"},
          {"name":"HARPSWELL CENTER","code":"US-ME-HP4"},
          {"name":"HARRINGTON","code":"US-ME-HRG"},
          {"name":"HARRISON","code":"US-ME-H8A"},
          {"name":"HARTLAND","code":"US-ME-HAQ"},
          {"name":"HEBRON","code":"US-ME-8HE"},
          {"name":"HERMON","code":"US-ME-MRO"},
          {"name":"HINCKLEY","code":"US-ME-HKL"},
          {"name":"HIRAM","code":"US-ME-8MH"},
          {"name":"HODGDON","code":"US-ME-DGJ"},
          {"name":"HOLDEN","code":"US-ME-8HD"},
          {"name":"HOLLIS","code":"US-ME-HO3"},
          {"name":"HOPE","code":"US-ME-HP9"},
          {"name":"HOULTON","code":"US-ME-HUL"},
          {"name":"HOWLAND","code":"US-ME-HW6"},
          {"name":"HUDSON","code":"US-ME-H8U"},
          {"name":"ISLAND FALLS","code":"US-ME-IFL"},
          {"name":"ISLESBORO","code":"US-ME-I9S"},
          {"name":"JACKMAN","code":"US-ME-JAM"},
          {"name":"JAY","code":"US-ME-JAY"},
          {"name":"JEFFERSON","code":"US-ME-J8F"},
          {"name":"JONESBORO","code":"US-ME-J8N"},
          {"name":"JONESPORT","code":"US-ME-JON"},
          {"name":"KENDUSKEAG","code":"US-ME-K8M"},
          {"name":"KENNEBUNK","code":"US-ME-KBN"},
          {"name":"KENNEBUNKPORT","code":"US-ME-KBP"},
          {"name":"KINGFIELD","code":"US-ME-KDK"},
          {"name":"KITTERY","code":"US-ME-KXK"},
          {"name":"KNOX","code":"US-ME-KCX"},
          {"name":"LAGRANGE","code":"US-ME-D8W"},
          {"name":"LEBANON","code":"US-ME-IBO"},
          {"name":"LEE","code":"US-ME-L8E"},
          {"name":"LEEDS","code":"US-ME-L2E"},
          {"name":"LEVANT","code":"US-ME-9VT"},
          {"name":"LEWISTON","code":"US-ME-QLW"},
          {"name":"LEWISTON-AUBURN APT","code":"US-ME-LEW"},
          {"name":"LIBERTY","code":"US-ME-9LI"},
          {"name":"LIMERICK","code":"US-ME-IJQ"},
          {"name":"LIMESTONE","code":"US-ME-LIZ"},
          {"name":"LIMINGTON","code":"US-ME-8LI"},
          {"name":"LINCOLN","code":"US-ME-LC7"},
          {"name":"LINCOLN CENTER","code":"US-ME-LCE"},
          {"name":"LINCOLNVILLE","code":"US-ME-8IL"},
          {"name":"LISBON","code":"US-ME-LXB"},
          {"name":"LISBON FALLS","code":"US-ME-LF3"},
          {"name":"LITCHFIELD","code":"US-ME-L8F"},
          {"name":"LITTLE DEER ISLE","code":"US-ME-L2D"},
          {"name":"LIVERMORE","code":"US-ME-L4V"},
          {"name":"LIVERMORE FALLS","code":"US-ME-LVF"},
          {"name":"LONG ISLAND","code":"US-ME-OZD"},
          {"name":"LOVELL","code":"US-ME-VLJ"},
          {"name":"LUBEC","code":"US-ME-LB2"},
          {"name":"MACHIAS","code":"US-ME-ACI"},
          {"name":"MACHIASPORT","code":"US-ME-9MP"},
          {"name":"MADAWASKA","code":"US-ME-MAD"},
          {"name":"MADISON","code":"US-ME-MYY"},
          {"name":"MANCHESTER","code":"US-ME-MR7"},
          {"name":"MANSET","code":"US-ME-9ME"},
          {"name":"MAPLETON","code":"US-ME-MPM"},
          {"name":"MARS HILL","code":"US-ME-MX5"},
          {"name":"MARSHFIELD","code":"US-ME-M46"},
          {"name":"MASARDIS","code":"US-ME-9DI"},
          {"name":"MATTAWAMKEAG","code":"US-ME-6KT"},
          {"name":"MECHANIC FALLS","code":"US-ME-EAZ"},
          {"name":"MEDDYBEMPS","code":"US-ME-9MB"},
          {"name":"MEDWAY","code":"US-ME-M8A"},
          {"name":"MERCER","code":"US-ME-MZ2"},
          {"name":"MEXICO","code":"US-ME-XXX"},
          {"name":"MILBRIDGE","code":"US-ME-MLQ"},
          {"name":"MILFORD","code":"US-ME-MFO"},
          {"name":"MILLINOCKET","code":"US-ME-MLT"},
          {"name":"MILO","code":"US-ME-JMO"},
          {"name":"MINOT","code":"US-ME-8OT"},
          {"name":"MONMOUTH","code":"US-ME-MOH"},
          {"name":"MONROE","code":"US-ME-9OE"},
          {"name":"MONSON","code":"US-ME-6MS"},
          {"name":"MONTICELLO","code":"US-ME-M8L"},
          {"name":"MORRILL","code":"US-ME-RQR"},
          {"name":"MOUNT DESERT","code":"US-ME-DZT"},
          {"name":"MOUNT VERNON","code":"US-ME-UEO"},
          {"name":"NAPLES","code":"US-ME-YUD"},
          {"name":"NEW GLOUCESTER","code":"US-ME-NOU"},
          {"name":"NEW PORTLAND","code":"US-ME-EPL"},
          {"name":"NEW SHARON","code":"US-ME-NNZ"},
          {"name":"NEW SWEDEN","code":"US-ME-N9E"},
          {"name":"NEW VINEYARD","code":"US-ME-N9V"},
          {"name":"NEWCASTLE","code":"US-ME-AWS"},
          {"name":"NEWFIELD","code":"US-ME-N9W"},
          {"name":"NEWPORT","code":"US-ME-NOZ"},
          {"name":"NEWRY","code":"US-ME-8RY"},
          {"name":"NOBLEBORO","code":"US-ME-8NO"},
          {"name":"NORRIDGEWOCK","code":"US-ME-OWK"},
          {"name":"NORTH ANSON","code":"US-ME-NHS"},
          {"name":"NORTH BERWICK","code":"US-ME-NBM"},
          {"name":"NORTH HAVEN","code":"US-ME-9NV"},
          {"name":"NORTH MONMOUTH","code":"US-ME-UHO"},
          {"name":"NORTH VASSALBORO","code":"US-ME-NHV"},
          {"name":"NORTH WINDHAM","code":"US-ME-NVJ"},
          {"name":"NORTH YARMOUTH","code":"US-ME-NMU"},
          {"name":"NORWAY","code":"US-ME-N4Y"},
          {"name":"OAKFIELD","code":"US-ME-GKF"},
          {"name":"OAKLAND","code":"US-ME-OKD"},
          {"name":"OGUNQUIT","code":"US-ME-YOQ"},
          {"name":"OLD ORCHARD BEACH","code":"US-ME-OO2"},
          {"name":"OLD TOWN","code":"US-ME-OLD"},
          {"name":"ORIENT","code":"US-ME-O8R"},
          {"name":"ORLAND","code":"US-ME-ODX"},
          {"name":"ORONO","code":"US-ME-2OR"},
          {"name":"ORRINGTON","code":"US-ME-OR7"},
          {"name":"OWLS HEAD","code":"US-ME-2OH"},
          {"name":"OXFORD","code":"US-ME-OX2"},
          {"name":"PALERMO","code":"US-ME-P5M"},
          {"name":"PALMYRA","code":"US-ME-8MY"},
          {"name":"PARIS","code":"US-ME-P7S"},
          {"name":"PARSONSFIELD","code":"US-ME-9PA"},
          {"name":"PASSADUMKEAG","code":"US-ME-P8A"},
          {"name":"PATTEN","code":"US-ME-PQZ"},
          {"name":"PEJEPSCOT","code":"US-ME-PZJ"},
          {"name":"PEMBROKE","code":"US-ME-P8E"},
          {"name":"PENOBSCOT","code":"US-ME-P87"},
          {"name":"PERHAM","code":"US-ME-P9E"},
          {"name":"PERRY","code":"US-ME-9PE"},
          {"name":"PERU","code":"US-ME-P6N"},
          {"name":"PHILLIPS","code":"US-ME-8LH"},
          {"name":"PHIPPSBURG","code":"US-ME-8PP"},
          {"name":"PITTSFIELD","code":"US-ME-PTF"},
          {"name":"PLYMOUTH","code":"US-ME-8PY"},
          {"name":"POLAND","code":"US-ME-ZE6"},
          {"name":"POLAND SPRING","code":"US-ME-PLD"},
          {"name":"PORTAGE LAKE","code":"US-ME-P3L"},
          {"name":"PORTER","code":"US-ME-8PO"},
          {"name":"PORTLAND","code":"US-ME-PWM"},
          {"name":"POWNAL","code":"US-ME-8PW"},
          {"name":"PRESQUE ISLE","code":"US-ME-PQI"},
          {"name":"PRINCETON","code":"US-ME-PNN"},
          {"name":"PROSPECT HARBOR","code":"US-ME-PHM"},
          {"name":"RANDOLPH","code":"US-ME-R8D"},
          {"name":"RANGELEY","code":"US-ME-RGY"},
          {"name":"RAYMOND","code":"US-ME-YOD"},
          {"name":"READFIELD","code":"US-ME-8RF"},
          {"name":"RICHMOND","code":"US-ME-U6U"},
          {"name":"ROBBINSTON","code":"US-ME-9RO"},
          {"name":"ROCKLAND","code":"US-ME-RKD"},
          {"name":"ROCKPORT","code":"US-ME-RP3"},
          {"name":"ROCKWOOD","code":"US-ME-R8W"},
          {"name":"ROUND POND","code":"US-ME-RN4"},
          {"name":"ROXBURY","code":"US-ME-8RX"},
          {"name":"RUMFORD","code":"US-ME-RFM"},
          {"name":"SABATTUS","code":"US-ME-ABT"},
          {"name":"SACO","code":"US-ME-QSK"},
          {"name":"SAINT AGATHA","code":"US-ME-YTG"},
          {"name":"SAINT ALBANS","code":"US-ME-7IN"},
          {"name":"SAINT FRANCIS","code":"US-ME-9FS"},
          {"name":"SAINT GEORGE","code":"US-ME-ST9"},
          {"name":"SANDY POINT","code":"US-ME-SYP"},
          {"name":"SANFORD","code":"US-ME-SFM"},
          {"name":"SANGERVILLE","code":"US-ME-9SV"},
          {"name":"SCARBOROUGH","code":"US-ME-SZX"},
          {"name":"SEAL COVE","code":"US-ME-9VE"},
          {"name":"SEARSMONT","code":"US-ME-SM4"},
          {"name":"SEARSPORT","code":"US-ME-SRP"},
          {"name":"SEBAGO","code":"US-ME-8SB"},
          {"name":"SEBAGO LAKE","code":"US-ME-YKQ"},
          {"name":"SHAPLEIGH","code":"US-ME-8IG"},
          {"name":"SHAWMUT","code":"US-ME-USM"},
          {"name":"SHOWHEGAN","code":"US-ME-SKO"},
          {"name":"SKOWHEGAN","code":"US-ME-SH5"},
          {"name":"SMALL POINT","code":"US-ME-8PT"},
          {"name":"SMITHFIELD","code":"US-ME-S9I"},
          {"name":"SOLON","code":"US-ME-SO6"},
          {"name":"SORRENTO","code":"US-ME-9SO"},
          {"name":"SOUTH BERWICK","code":"US-ME-ZYW"},
          {"name":"SOUTH BRISTOL","code":"US-ME-S8B"},
          {"name":"SOUTH PARIS","code":"US-ME-SSA"},
          {"name":"SOUTH PORTLAND","code":"US-ME-SOX"},
          {"name":"SOUTH THOMASTON","code":"US-ME-9SA"},
          {"name":"SOUTH WINDHAM","code":"US-ME-SWX"},
          {"name":"SOUTHPORT","code":"US-ME-P8R"},
          {"name":"SOUTHWEST HARBOR","code":"US-ME-W8S"},
          {"name":"SPRINGFIELD","code":"US-ME-IID"},
          {"name":"SPRINGVALE","code":"US-ME-SG9"},
          {"name":"SPRUCE HEAD","code":"US-ME-UHD"},
          {"name":"STANDISH","code":"US-ME-TDH"},
          {"name":"STETSON","code":"US-ME-8TE"},
          {"name":"STEUBEN","code":"US-ME-9SE"},
          {"name":"STOCKHOLM","code":"US-ME-9HO"},
          {"name":"STOCKTON SPRINGS","code":"US-ME-7SS"},
          {"name":"STONEHAM","code":"US-ME-8AM"},
          {"name":"STONINGTON","code":"US-ME-SG4"},
          {"name":"STRONG","code":"US-ME-SXE"},
          {"name":"SULLIVAN","code":"US-ME-9VA"},
          {"name":"SUMNER","code":"US-ME-S8U"},
          {"name":"SURRY","code":"US-ME-8RR"},
          {"name":"SWANS ISLAND","code":"US-ME-IS9"},
          {"name":"TEMPLE","code":"US-ME-8MP"},
          {"name":"TENANTS HARBOUR","code":"US-ME-ZNH"},
          {"name":"THOMASTON","code":"US-ME-TSK"},
          {"name":"TOPSFIELD","code":"US-ME-T8F"},
          {"name":"TOPSHAM","code":"US-ME-TSH"},
          {"name":"TRENTON","code":"US-ME-T5N"},
          {"name":"TREVETT","code":"US-ME-8TR"},
          {"name":"TROY","code":"US-ME-TOY"},
          {"name":"TURNER","code":"US-ME-TNM"},
          {"name":"UNION","code":"US-ME-YUN"},
          {"name":"UNITY","code":"US-ME-UHI"},
          {"name":"VAN BUREN","code":"US-ME-VBN"},
          {"name":"VANCEBORO","code":"US-ME-VBO"},
          {"name":"VASSALBORO","code":"US-ME-VSB"},
          {"name":"VIENNA","code":"US-ME-V8E"},
          {"name":"VINALHAVEN","code":"US-ME-9VV"},
          {"name":"WAITE","code":"US-ME-9WI"},
          {"name":"WALDO","code":"US-ME-W2D"},
          {"name":"WALDOBORO","code":"US-ME-WDJ"},
          {"name":"WALLAGRASS","code":"US-ME-9GS"},
          {"name":"WALPOLE","code":"US-ME-AW9"},
          {"name":"WARREN","code":"US-ME-W3R"},
          {"name":"WASHBURN","code":"US-ME-9BW"},
          {"name":"WASHINGTON","code":"US-ME-8NG"},
          {"name":"WASHINGTON COUNTY","code":"US-ME-WC9"},
          {"name":"WATERBORO","code":"US-ME-WB9"},
          {"name":"WATERFORD","code":"US-ME-WTM"},
          {"name":"WATERVILLE","code":"US-ME-WVL"},
          {"name":"WAYNE","code":"US-ME-W9Y"},
          {"name":"WELD","code":"US-ME-W8E"},
          {"name":"WELLS","code":"US-ME-WZM"},
          {"name":"WEST BALDWIN","code":"US-ME-8BA"},
          {"name":"WEST BATH","code":"US-ME-WVZ"},
          {"name":"WEST KENNEBUNK","code":"US-ME-VWB"},
          {"name":"WEST MINOT","code":"US-ME-8WM"},
          {"name":"WEST PARIS","code":"US-ME-W8P"},
          {"name":"WESTBROOK","code":"US-ME-WTB"},
          {"name":"WESTFIELD","code":"US-ME-9FI"},
          {"name":"WHITEFIELD","code":"US-ME-9WF"},
          {"name":"WHITING","code":"US-ME-W2T"},
          {"name":"WILTON","code":"US-ME-WYT"},
          {"name":"WINDHAM","code":"US-ME-6DW"},
          {"name":"WINDHAM HILL","code":"US-ME-DHM"},
          {"name":"WINDSOR","code":"US-ME-QZJ"},
          {"name":"WINN","code":"US-ME-3NN"},
          {"name":"WINSLOW","code":"US-ME-WSE"},
          {"name":"WINTER HARBOR","code":"US-ME-WH5"},
          {"name":"WINTERPORT","code":"US-ME-WIE"},
          {"name":"WINTHROP","code":"US-ME-IRP"},
          {"name":"WISCASSET","code":"US-ME-ISS"},
          {"name":"WOODLAND","code":"US-ME-9WO"},
          {"name":"WOOLWICH","code":"US-ME-8WW"},
          {"name":"YARMOUTH","code":"US-ME-YAR"},
          {"name":"YORK","code":"US-ME-YO2"},
          {"name":"YORK BEACH","code":"US-ME-YKB"},
          {"name":"YORK HARBOR","code":"US-ME-ZYH"},
        ]
      },
      {
        name: 'Maryland',
        code: 'US-MD',
        cities: [
          {"name":"ABERDEEN","code":"US-MD-APG"},
          {"name":"ABINGDON","code":"US-MD-NGD"},
          {"name":"ACCIDENT","code":"US-MD-AJT"},
          {"name":"ACCOKEEK","code":"US-MD-JOK"},
          {"name":"ADAMSTOWN","code":"US-MD-AN6"},
          {"name":"ADELPHI","code":"US-MD-A9D"},
          {"name":"ANDREWS AFB","code":"US-MD-AS3"},
          {"name":"ANNAPOLIS","code":"US-MD-ANP"},
          {"name":"ANNAPOLIS JUNCTION","code":"US-MD-QAJ"},
          {"name":"ARBUTUS","code":"US-MD-JBM"},
          {"name":"ARLINGTON/BALTIMORE","code":"US-MD-AAP"},
          {"name":"ARNOLD","code":"US-MD-AZR"},
          {"name":"ARUNDEL VILLAGE","code":"US-MD-AVL"},
          {"name":"ASHTON","code":"US-MD-XST"},
          {"name":"AVENEL/WASHINGTON, D.C.","code":"US-MD-AVW"},
          {"name":"BALTIMORE","code":"US-MD-BAL"},
          {"name":"BALTIMORE-WASHINGTON INT APT","code":"US-MD-BWI"},
          {"name":"BARCLAY","code":"US-MD-BFK"},
          {"name":"BEALLSVILLE","code":"US-MD-B4V"},
          {"name":"BEL AIR","code":"US-MD-BLX"},
          {"name":"BELCAMP","code":"US-MD-BEJ"},
          {"name":"BELTSVILLE","code":"US-MD-BES"},
          {"name":"BENGIES","code":"US-MD-BNJ"},
          {"name":"BERLIN","code":"US-MD-BCI"},
          {"name":"BETHESDA","code":"US-MD-BZH"},
          {"name":"BISHOPVILLE","code":"US-MD-B3V"},
          {"name":"BLADENSBURG","code":"US-MD-BDZ"},
          {"name":"BLOOMINGTON","code":"US-MD-B2M"},
          {"name":"BOONSBORO","code":"US-MD-B3O"},
          {"name":"BOWIE","code":"US-MD-BQA"},
          {"name":"BOYDS","code":"US-MD-B9D"},
          {"name":"BRADDOCK HEIGHTS","code":"US-MD-OHG"},
          {"name":"BRANDYWINE","code":"US-MD-XDL"},
          {"name":"BRENTWOOD","code":"US-MD-BXG"},
          {"name":"BRIDGETOWN","code":"US-MD-BJT"},
          {"name":"BROOKEVILLE","code":"US-MD-O2V"},
          {"name":"BROOKLANDVILLE","code":"US-MD-BE4"},
          {"name":"BROOKLYN","code":"US-MD-BN5"},
          {"name":"BROOKLYN PARK","code":"US-MD-BJQ"},
          {"name":"BRUNSWICK","code":"US-MD-BU6"},
          {"name":"BRUNSWICK","code":"US-MD-WI7"},
          {"name":"BURTONSVILLE","code":"US-MD-BUV"},
          {"name":"BUTLER","code":"US-MD-BT9"},
          {"name":"CABIN JOHN","code":"US-MD-CJ4"},
          {"name":"CALIFORNIA","code":"US-MD-CA2"},
          {"name":"CALVERTON","code":"US-MD-C42"},
          {"name":"CAMBRIDGE","code":"US-MD-CGE"},
          {"name":"CAMP SPRINGS","code":"US-MD-ADW"},
          {"name":"CAPITOL HEIGHTS","code":"US-MD-ACD"},
          {"name":"CASCADE","code":"US-MD-C4U"},
          {"name":"CATONSVILLE","code":"US-MD-CVM"},
          {"name":"CAVETOWN","code":"US-MD-C5T"},
          {"name":"CECILTON","code":"US-MD-CYD"},
          {"name":"CENTREVILLE","code":"US-MD-C5E"},
          {"name":"CHARLOTTE HALL","code":"US-MD-JTH"},
          {"name":"CHELTENHAM","code":"US-MD-HJM"},
          {"name":"CHESAPEAKE CITY","code":"US-MD-CC8"},
          {"name":"CHESTER","code":"US-MD-2CS"},
          {"name":"CHESTERTOWN","code":"US-MD-CWN"},
          {"name":"CHEVERLY","code":"US-MD-CFY"},
          {"name":"CHEVY CHASE","code":"US-MD-QCC"},
          {"name":"CHURCHVILLE","code":"US-MD-HQC"},
          {"name":"CLAIBORNE","code":"US-MD-IBP"},
          {"name":"CLARKSBOURG","code":"US-MD-YKG"},
          {"name":"CLARKSBURG","code":"US-MD-C9L"},
          {"name":"CLARKSVILLE","code":"US-MD-KVI"},
          {"name":"CLEAR SPRING","code":"US-MD-2CP"},
          {"name":"CLINTON","code":"US-MD-CNB"},
          {"name":"COCKEYSVILLE","code":"US-MD-CCQ"},
          {"name":"COLLEGE PARK","code":"US-MD-CGS"},
          {"name":"COLORA","code":"US-MD-COR"},
          {"name":"COLUMBIA","code":"US-MD-ACW"},
          {"name":"CONOWINGO","code":"US-MD-C3W"},
          {"name":"COOKSVILLE","code":"US-MD-CK3"},
          {"name":"CORDOVA","code":"US-MD-CD7"},
          {"name":"COTTAGE CITY","code":"US-MD-C4C"},
          {"name":"COVE POINT","code":"US-MD-CP6"},
          {"name":"CRISFIELD","code":"US-MD-CRI"},
          {"name":"CROFTON","code":"US-MD-ZCN"},
          {"name":"CUMBERLAND","code":"US-MD-CBE"},
          {"name":"CURTIS BAY, BALTIMORE","code":"US-MD-USB"},
          {"name":"DAMASCUS","code":"US-MD-ZWF"},
          {"name":"DARLINGTON","code":"US-MD-DIT"},
          {"name":"DAVIDSONVILLE","code":"US-MD-DV2"},
          {"name":"DEER PARK","code":"US-MD-ERP"},
          {"name":"DELMAR","code":"US-MD-DMM"},
          {"name":"DENTON","code":"US-MD-ZDN"},
          {"name":"DERWOOD","code":"US-MD-DW2"},
          {"name":"DETOUR","code":"US-MD-VOU"},
          {"name":"DISTRICT HEIGHTS","code":"US-MD-JHO"},
          {"name":"DORSEY","code":"US-MD-DRS"},
          {"name":"DRAYDEN","code":"US-MD-D2D"},
          {"name":"DUNDALK","code":"US-MD-DDQ"},
          {"name":"DUNKIRK","code":"US-MD-K44"},
          {"name":"EARLEVILLE","code":"US-MD-M9D"},
          {"name":"EASTON","code":"US-MD-ESN"},
          {"name":"EDGEMERE","code":"US-MD-GME"},
          {"name":"EDGEWATER","code":"US-MD-EW7"},
          {"name":"EDGEWOOD","code":"US-MD-EDG"},
          {"name":"EDMONSTON","code":"US-MD-EOT"},
          {"name":"ELDERSBURG","code":"US-MD-EMC"},
          {"name":"ELKRIDGE","code":"US-MD-EKD"},
          {"name":"ELKTON","code":"US-MD-ELT"},
          {"name":"ELLICOTT CITY","code":"US-MD-ECC"},
          {"name":"ESSEX","code":"US-MD-ESE"},
          {"name":"FALLSTON","code":"US-MD-FL2"},
          {"name":"FAULKNER","code":"US-MD-FAQ"},
          {"name":"FEDERALSBURG","code":"US-MD-FDL"},
          {"name":"FINKSBURG","code":"US-MD-FNS"},
          {"name":"FLINTSTONE","code":"US-MD-5FS"},
          {"name":"FOREST HILL","code":"US-MD-FES"},
          {"name":"FORESTVILLE","code":"US-MD-FVL"},
          {"name":"FORK","code":"US-MD-FK3"},
          {"name":"FORT GEORGE G MEADE","code":"US-MD-FGA"},
          {"name":"FORT MEADE","code":"US-MD-FME"},
          {"name":"FORT WASHINGTON","code":"US-MD-FWY"},
          {"name":"FREDERICK","code":"US-MD-FDK"},
          {"name":"FREDERICK VILLAGE","code":"US-MD-FV4"},
          {"name":"FRIENDSHIP","code":"US-MD-ZFP"},
          {"name":"FRIENDSVILLE","code":"US-MD-FEV"},
          {"name":"FROSTBURG","code":"US-MD-FZH"},
          {"name":"FRUITLAND","code":"US-MD-ZFM"},
          {"name":"FULTON","code":"US-MD-M5D"},
          {"name":"GAITHERSBURG","code":"US-MD-GAI"},
          {"name":"GALENA","code":"US-MD-GZQ"},
          {"name":"GALESVILLE","code":"US-MD-VGL"},
          {"name":"GAMBRILLS","code":"US-MD-G2M"},
          {"name":"GARRISON","code":"US-MD-GAX"},
          {"name":"GERMANTOWN","code":"US-MD-GTN"},
          {"name":"GLEN ALLEN","code":"US-MD-GCD"},
          {"name":"GLEN ARM","code":"US-MD-ENR"},
          {"name":"GLEN BURNIE","code":"US-MD-GBM"},
          {"name":"GLENARDEN","code":"US-MD-9GL"},
          {"name":"GLENDALE","code":"US-MD-GAW"},
          {"name":"GLENNDALE","code":"US-MD-YNN"},
          {"name":"GLENWOOD","code":"US-MD-G9D"},
          {"name":"GLYNDON","code":"US-MD-GYN"},
          {"name":"GRANTSVILLE","code":"US-MD-GV8"},
          {"name":"GRASONVILLE","code":"US-MD-GRF"},
          {"name":"GREAT MILLS","code":"US-MD-237"},
          {"name":"GREENBELT","code":"US-MD-GLT"},
          {"name":"GREENMOUNT","code":"US-MD-GT3"},
          {"name":"GWYNN OAK","code":"US-MD-G8K"},
          {"name":"HAGERSTOWN","code":"US-MD-HGR"},
          {"name":"HALETHORPE","code":"US-MD-HPH"},
          {"name":"HAMPSTEAD","code":"US-MD-HPD"},
          {"name":"HANCOCK","code":"US-MD-H2N"},
          {"name":"HANOVER","code":"US-MD-HVM"},
          {"name":"HARMANS","code":"US-MD-HMA"},
          {"name":"HARWOOD","code":"US-MD-ZHW"},
          {"name":"HAVRE DE GRACE","code":"US-MD-HDC"},
          {"name":"HEBRON","code":"US-MD-KHN"},
          {"name":"HENDERSON","code":"US-MD-H2D"},
          {"name":"HENRYTON","code":"US-MD-HY9"},
          {"name":"HIGHLAND","code":"US-MD-HGY"},
          {"name":"HIGHLANDTOWN","code":"US-MD-QGW"},
          {"name":"HOLLYWOOD","code":"US-MD-IHW"},
          {"name":"HUNT VALLEY","code":"US-MD-HVD"},
          {"name":"HUNTINGTOWN","code":"US-MD-HW8"},
          {"name":"HURLOCK","code":"US-MD-HUC"},
          {"name":"HYATTSVILLE","code":"US-MD-XGZ"},
          {"name":"IJAMSVILLE","code":"US-MD-IJA"},
          {"name":"INDIAN HEAD","code":"US-MD-ZD9"},
          {"name":"JARRETTSVILLE","code":"US-MD-JRV"},
          {"name":"JESSUP","code":"US-MD-XHB"},
          {"name":"JOPPA","code":"US-MD-JOP"},
          {"name":"KENNEDYVILLE","code":"US-MD-KV4"},
          {"name":"KENSINGTON","code":"US-MD-2KT"},
          {"name":"KETTERING","code":"US-MD-NGG"},
          {"name":"KEYMAR","code":"US-MD-KY2"},
          {"name":"KEYSVILLE","code":"US-MD-ZYK"},
          {"name":"KINGSVILLE","code":"US-MD-KVG"},
          {"name":"LA PLATA","code":"US-MD-LAP"},
          {"name":"LA VALE","code":"US-MD-KVN"},
          {"name":"LANDOVER","code":"US-MD-LDR"},
          {"name":"LANGLEY PARK","code":"US-MD-6YP"},
          {"name":"LANHAM","code":"US-MD-LME"},
          {"name":"LANSDOWNE","code":"US-MD-LDW"},
          {"name":"LANSDOWNE-BALTIMORE","code":"US-MD-LB9"},
          {"name":"LARGO","code":"US-MD-LGQ"},
          {"name":"LAUREL","code":"US-MD-LRM"},
          {"name":"LEEDS","code":"US-MD-DJD"},
          {"name":"LEONARDTOWN","code":"US-MD-LTW"},
          {"name":"LEXINGTON PARK","code":"US-MD-L7X"},
          {"name":"LIBERTYTOWN","code":"US-MD-3TW"},
          {"name":"LINKWOOD","code":"US-MD-KW3"},
          {"name":"LINTHICUM","code":"US-MD-LNM"},
          {"name":"LINTHICUM HEIGHTS","code":"US-MD-LTU"},
          {"name":"LONG GREEN","code":"US-MD-LG5"},
          {"name":"LOTHIAN","code":"US-MD-LHX"},
          {"name":"LUKE","code":"US-MD-LUK"},
          {"name":"LUSBY","code":"US-MD-3LU"},
          {"name":"LUTHERVILLE-TIMONIUM","code":"US-MD-LHE"},
          {"name":"MANCHESTER","code":"US-MD-ZMN"},
          {"name":"MARLOW HEIGHTS","code":"US-MD-MWE"},
          {"name":"MARTIN MARIET/BALTIMORE","code":"US-MD-MTN"},
          {"name":"MARYLAND CITY","code":"US-MD-ZJV"},
          {"name":"MASSEY","code":"US-MD-QMZ"},
          {"name":"MCHENRY","code":"US-MD-M2H"},
          {"name":"MIDDLE RIVER","code":"US-MD-MDV"},
          {"name":"MIDDLETOWN","code":"US-MD-M44"},
          {"name":"MIDDLETOWN, FREDERICK","code":"US-MD-UQK"},
          {"name":"MILFORD MILL","code":"US-MD-M4M"},
          {"name":"MILLERSVILLE","code":"US-MD-MVB"},
          {"name":"MILLINGTON","code":"US-MD-MTL"},
          {"name":"MITCHELLVILLE","code":"US-MD-IHE"},
          {"name":"MONKTON","code":"US-MD-2KN"},
          {"name":"MONROVIA","code":"US-MD-ZMR"},
          {"name":"MONTGOMERY VILLAGE","code":"US-MD-NMV"},
          {"name":"MOUNT AIRY","code":"US-MD-MYM"},
          {"name":"MOUNT HEBRON","code":"US-MD-TBO"},
          {"name":"MOUNT SAVAGE","code":"US-MD-M5T"},
          {"name":"MOUNTAIN LAKE PARK","code":"US-MD-UTK"},
          {"name":"MYERSVILLE","code":"US-MD-MV5"},
          {"name":"NANTICOKE","code":"US-MD-NNO"},
          {"name":"NEW CARROLLTON","code":"US-MD-NCA"},
          {"name":"NEW MARKET","code":"US-MD-NM8"},
          {"name":"NEW WINDSOR","code":"US-MD-NWM"},
          {"name":"NEWBURG","code":"US-MD-NB7"},
          {"name":"NEWMARKET","code":"US-MD-KT9"},
          {"name":"NORTH BEACH","code":"US-MD-N4B"},
          {"name":"NORTH BETHESDA","code":"US-MD-TDJ"},
          {"name":"NORTH EAST","code":"US-MD-NAP"},
          {"name":"NORTH POTOMAC","code":"US-MD-NPZ"},
          {"name":"NOTTINGHAM","code":"US-MD-NT7"},
          {"name":"OAKLAND","code":"US-MD-OBT"},
          {"name":"OAKLAND MILLS","code":"US-MD-OXK"},
          {"name":"OCEAN CITY","code":"US-MD-OCE"},
          {"name":"ODENTON","code":"US-MD-ODN"},
          {"name":"OLDTOWN","code":"US-MD-OXE"},
          {"name":"OLNEY","code":"US-MD-OEY"},
          {"name":"ORCHARD BEACH","code":"US-MD-OQP"},
          {"name":"OWINGS","code":"US-MD-OW7"},
          {"name":"OWINGS MILLS","code":"US-MD-OWI"},
          {"name":"OXFORD","code":"US-MD-OXU"},
          {"name":"OXON HILL","code":"US-MD-OXH"},
          {"name":"PARKTON","code":"US-MD-4RK"},
          {"name":"PARKVILLE","code":"US-MD-PK4"},
          {"name":"PARSONSBURG","code":"US-MD-PG9"},
          {"name":"PASADENA","code":"US-MD-YPC"},
          {"name":"PATUXENT RIVER","code":"US-MD-NHK"},
          {"name":"PERRY HALL","code":"US-MD-WJ3"},
          {"name":"PERRYMAN","code":"US-MD-PYB"},
          {"name":"PERRYVILLE","code":"US-MD-PYI"},
          {"name":"PHOENIX","code":"US-MD-P2X"},
          {"name":"PIKESVILLE","code":"US-MD-PXZ"},
          {"name":"PINEY POINT","code":"US-MD-PYP"},
          {"name":"PINTO","code":"US-MD-Z4D"},
          {"name":"PITTSVILLE","code":"US-MD-M6D"},
          {"name":"POCOMOKE CITY","code":"US-MD-PKM"},
          {"name":"POMFRET","code":"US-MD-O4F"},
          {"name":"PORT DEPOSIT","code":"US-MD-ZE7"},
          {"name":"PORTLAND","code":"US-MD-POL"},
          {"name":"POTOMAC","code":"US-MD-PMK"},
          {"name":"PRESTON","code":"US-MD-PBV"},
          {"name":"PRINCE FREDERICK","code":"US-MD-PFK"},
          {"name":"PRINCESS ANNE","code":"US-MD-PA8"},
          {"name":"QUANTICO","code":"US-MD-NYJ"},
          {"name":"QUEEN ANNE","code":"US-MD-QNA"},
          {"name":"QUEENSTOWN","code":"US-MD-QUE"},
          {"name":"RANDALLSTOWN","code":"US-MD-RA9"},
          {"name":"REISTERSTOWN","code":"US-MD-RSW"},
          {"name":"RELAY","code":"US-MD-RLW"},
          {"name":"RHODESDALE","code":"US-MD-RH3"},
          {"name":"RIDGELY","code":"US-MD-RGZ"},
          {"name":"RISING SUN","code":"US-MD-RUN"},
          {"name":"RIVA","code":"US-MD-R6V"},
          {"name":"RIVERDALE","code":"US-MD-RVL"},
          {"name":"ROCKVILLE","code":"US-MD-RKV"},
          {"name":"ROSEDALE","code":"US-MD-RSA"},
          {"name":"SABILLASVILLE","code":"US-MD-IAI"},
          {"name":"SAINT CHARLES","code":"US-MD-SKC"},
          {"name":"SAINT INIGOES","code":"US-MD-S2E"},
          {"name":"SAINT MARYS CITY","code":"US-MD-XSM"},
          {"name":"SAINT MICHAELS","code":"US-MD-IIC"},
          {"name":"SALISBURY","code":"US-MD-SBY"},
          {"name":"SANDY SPRING","code":"US-MD-YZR"},
          {"name":"SAVAGE","code":"US-MD-SGE"},
          {"name":"SEVERN","code":"US-MD-SEV"},
          {"name":"SEVERNA PARK","code":"US-MD-SNR"},
          {"name":"SHOWELL","code":"US-MD-SIH"},
          {"name":"SILVER SPRING","code":"US-MD-SIS"},
          {"name":"SMITHSBURG","code":"US-MD-JHG"},
          {"name":"SNOW HILL","code":"US-MD-NSW"},
          {"name":"SOLOMONS","code":"US-MD-OMU"},
          {"name":"SOUTH BALTIMORE","code":"US-MD-S9B"},
          {"name":"SPARKS","code":"US-MD-SRM"},
          {"name":"SPARKS GLENCOE","code":"US-MD-FKG"},
          {"name":"SPARROWS POINT","code":"US-MD-PPO"},
          {"name":"SPRINGFIELD","code":"US-MD-SFK"},
          {"name":"STEVENSVILLE","code":"US-MD-SVV"},
          {"name":"STREET","code":"US-MD-ST6"},
          {"name":"SUDLERSVILLE","code":"US-MD-3SM"},
          {"name":"SUGARLAND","code":"US-MD-TKB"},
          {"name":"SUITLAND","code":"US-MD-SIL"},
          {"name":"SYKESVILLE","code":"US-MD-ZJF"},
          {"name":"TAKOMA PARK","code":"US-MD-ZIV"},
          {"name":"TANEYTOWN","code":"US-MD-TFY"},
          {"name":"TEMPLE HILLS","code":"US-MD-THH"},
          {"name":"TEXAS","code":"US-MD-TXA"},
          {"name":"THURMONT","code":"US-MD-URM"},
          {"name":"TIMONIUM","code":"US-MD-TUM"},
          {"name":"TOWSON","code":"US-MD-TWS"},
          {"name":"TRACYS LANDING","code":"US-MD-TR3"},
          {"name":"TRAPPE","code":"US-MD-TZ4"},
          {"name":"TUXEDO","code":"US-MD-TXD"},
          {"name":"UNION BRIDGE","code":"US-MD-UNB"},
          {"name":"UPPER MALBORO","code":"US-MD-MB9"},
          {"name":"UPPER MARLBORO","code":"US-MD-UPM"},
          {"name":"UPPERCO","code":"US-MD-UP8"},
          {"name":"VIENNA","code":"US-MD-VMD"},
          {"name":"WAGNERS POINT","code":"US-MD-WP7"},
          {"name":"WALDORF","code":"US-MD-WFM"},
          {"name":"WALKERSVILLE","code":"US-MD-XNX"},
          {"name":"WARWICK","code":"US-MD-WW3"},
          {"name":"WASHINGTON-BALTIMORE INT APT","code":"US-MD-BWI"},
          {"name":"WAVERLY","code":"US-MD-WFX"},
          {"name":"WEST FRIENDSHIP","code":"US-MD-289"},
          {"name":"WEST OCEAN CITY","code":"US-MD-W8C"},
          {"name":"WESTERNPORT","code":"US-MD-WPZ"},
          {"name":"WESTMINSTER","code":"US-MD-WMD"},
          {"name":"WESTOVER","code":"US-MD-ZXQ"},
          {"name":"WESTPORT","code":"US-MD-W6P"},
          {"name":"WHALEYSVILLE","code":"US-MD-AE3"},
          {"name":"WHEATON","code":"US-MD-WFA"},
          {"name":"WHITE MARSH","code":"US-MD-HIM"},
          {"name":"WHITE MARSH STATION","code":"US-MD-WWM"},
          {"name":"WHITE OAK","code":"US-MD-W9T"},
          {"name":"WHITE PLAINS","code":"US-MD-XWP"},
          {"name":"WHITEFORD","code":"US-MD-IFD"},
          {"name":"WILLIAMSPORT","code":"US-MD-WFP"},
          {"name":"WINDSOR MILL","code":"US-MD-WM9"},
          {"name":"WITTMAN","code":"US-MD-4WM"},
          {"name":"WOODBINE","code":"US-MD-OOD"},
          {"name":"WOODLAWN","code":"US-MD-W2N"},
          {"name":"WOODSBORO","code":"US-MD-QWQ"},
          {"name":"WOODSTOCK","code":"US-MD-WDK"},
          {"name":"WORTON","code":"US-MD-WO8"},
          {"name":"WYE MILLS","code":"US-MD-WM6"},
          {"name":"WYEMILLS","code":"US-MD-WYI"},
        ]
      },
      {
        name: 'Massachusetts',
        code: 'US-MA',
        cities: [
          {"name":"ABINGTON","code":"US-MA-UUM"},
          {"name":"ACCORD","code":"US-MA-A8C"},
          {"name":"ACTON","code":"US-MA-UAH"},
          {"name":"ACUSHNET","code":"US-MA-KUS"},
          {"name":"ADAMS","code":"US-MA-UAN"},
          {"name":"AGAWAM","code":"US-MA-XBL"},
          {"name":"ALFORD","code":"US-MA-A74"},
          {"name":"ALLSTON","code":"US-MA-XBE"},
          {"name":"AMESBURY","code":"US-MA-AMX"},
          {"name":"AMHERST","code":"US-MA-YAM"},
          {"name":"ANDOVER","code":"US-MA-ANR"},
          {"name":"ANNISQUAM HARBOUR","code":"US-MA-NQH"},
          {"name":"ARLINGTON","code":"US-MA-ZAT"},
          {"name":"ASHBURNHAM","code":"US-MA-HHA"},
          {"name":"ASHBY","code":"US-MA-4BY"},
          {"name":"ASHFIELD","code":"US-MA-AS5"},
          {"name":"ASHLAND","code":"US-MA-ASD"},
          {"name":"ASSONET","code":"US-MA-ASJ"},
          {"name":"ATHOL","code":"US-MA-AHO"},
          {"name":"ATTLEBORO","code":"US-MA-AOM"},
          {"name":"ATTLEBORO FALLS","code":"US-MA-A6M"},
          {"name":"AUBURN","code":"US-MA-ABU"},
          {"name":"AUBURNDALE","code":"US-MA-A9U"},
          {"name":"AVON","code":"US-MA-AVN"},
          {"name":"AYER","code":"US-MA-AER"},
          {"name":"BALDWINVILLE","code":"US-MA-AWV"},
          {"name":"BARNSTABLE","code":"US-MA-BB6"},
          {"name":"BARRE","code":"US-MA-BJE"},
          {"name":"BEACHWOOD","code":"US-MA-B22"},
          {"name":"BECKET","code":"US-MA-8CK"},
          {"name":"BEDFORD","code":"US-MA-BED"},
          {"name":"BELCHERTOWN","code":"US-MA-BT4"},
          {"name":"BELLINGHAM","code":"US-MA-BJX"},
          {"name":"BELMONT","code":"US-MA-JGT"},
          {"name":"BERKLEY","code":"US-MA-ZKY"},
          {"name":"BERLIN","code":"US-MA-M7D"},
          {"name":"BERNARDSTON","code":"US-MA-ERS"},
          {"name":"BEVERLY","code":"US-MA-BVY"},
          {"name":"BILLERICA","code":"US-MA-BMS"},
          {"name":"BLACKSTONE","code":"US-MA-A6H"},
          {"name":"BLANDFORD","code":"US-MA-FB8"},
          {"name":"BOLTON","code":"US-MA-BOL"},
          {"name":"BONDSVILLE","code":"US-MA-QBO"},
          {"name":"BOSTON","code":"US-MA-BOS"},
          {"name":"BOURNE","code":"US-MA-BO4"},
          {"name":"BOXBORO","code":"US-MA-BXC"},
          {"name":"BOXBOROUGH","code":"US-MA-9BX"},
          {"name":"BOXFORD","code":"US-MA-3XF"},
          {"name":"BOYLSTON","code":"US-MA-YBN"},
          {"name":"BRADFORD","code":"US-MA-ZQX"},
          {"name":"BRAINTREE","code":"US-MA-BTC"},
          {"name":"BRAYTON POINT","code":"US-MA-BNP"},
          {"name":"BREWSTER","code":"US-MA-BCW"},
          {"name":"BRIDGEWATER","code":"US-MA-BJW"},
          {"name":"BRIGHTON","code":"US-MA-RGH"},
          {"name":"BRIMFIELD","code":"US-MA-BF5"},
          {"name":"BROCKTON","code":"US-MA-BKS"},
          {"name":"BROOKFIELD","code":"US-MA-BKZ"},
          {"name":"BROOKLINE","code":"US-MA-BMA"},
          {"name":"BUCKLAND","code":"US-MA-4CB"},
          {"name":"BURLINGTON","code":"US-MA-BBF"},
          {"name":"BUZZARDS BAY","code":"US-MA-ZBG"},
          {"name":"BYFIELD","code":"US-MA-BY5"},
          {"name":"CAMBRIDGE","code":"US-MA-JHY"},
          {"name":"CANTON","code":"US-MA-ACU"},
          {"name":"CARLISLE","code":"US-MA-CSJ"},
          {"name":"CARVER","code":"US-MA-YCE"},
          {"name":"CATAUMET","code":"US-MA-UMO"},
          {"name":"CENTERVILLE","code":"US-MA-CT6"},
          {"name":"CHARLEMONT","code":"US-MA-3MT"},
          {"name":"CHARLESTOWN","code":"US-MA-CHN"},
          {"name":"CHARLTON","code":"US-MA-CA6"},
          {"name":"CHATHAM","code":"US-MA-CA3"},
          {"name":"CHELMSFORD","code":"US-MA-CMD"},
          {"name":"CHELSEA","code":"US-MA-CZS"},
          {"name":"CHESHIRE","code":"US-MA-8SE"},
          {"name":"CHESTER CENTER","code":"US-MA-ZER"},
          {"name":"CHESTERFIELD","code":"US-MA-FHC"},
          {"name":"CHESTNUT HILL","code":"US-MA-ENH"},
          {"name":"CHICOPEE","code":"US-MA-CEF"},
          {"name":"CHILMARK","code":"US-MA-RKZ"},
          {"name":"CLINTON","code":"US-MA-CCN"},
          {"name":"COHASSET","code":"US-MA-CJ2"},
          {"name":"COLRAIN","code":"US-MA-XEE"},
          {"name":"CONCORD","code":"US-MA-CCF"},
          {"name":"CONWAY","code":"US-MA-ZB8"},
          {"name":"CUMMINGTON","code":"US-MA-C9U"},
          {"name":"DALTON","code":"US-MA-QDT"},
          {"name":"DANVERS","code":"US-MA-DVR"},
          {"name":"DARTMOUTH","code":"US-MA-DQT"},
          {"name":"DEDHAM","code":"US-MA-DED"},
          {"name":"DEERFIELD","code":"US-MA-DDE"},
          {"name":"DENNIS","code":"US-MA-DJS"},
          {"name":"DEVENS","code":"US-MA-VNS"},
          {"name":"DEVENSCREST","code":"US-MA-QDV"},
          {"name":"DIGHTON","code":"US-MA-DGT"},
          {"name":"DORCHESTER","code":"US-MA-DZM"},
          {"name":"DOVER","code":"US-MA-AOV"},
          {"name":"DRACUT","code":"US-MA-XEV"},
          {"name":"DUDLEY","code":"US-MA-DKU"},
          {"name":"DUNSTABLE","code":"US-MA-DSE"},
          {"name":"DUXBURY","code":"US-MA-DUH"},
          {"name":"EAST BOSTON","code":"US-MA-EBN"},
          {"name":"EAST BRIDGEWATER","code":"US-MA-EAB"},
          {"name":"EAST BROOKFIELD","code":"US-MA-EBQ"},
          {"name":"EAST CAMBRIDGE","code":"US-MA-ECM"},
          {"name":"EAST DENNIS","code":"US-MA-TDN"},
          {"name":"EAST DOUGLAS","code":"US-MA-EDO"},
          {"name":"EAST FALMOUTH","code":"US-MA-ZEF"},
          {"name":"EAST FREETOWN","code":"US-MA-EFN"},
          {"name":"EAST HARWICH","code":"US-MA-EH6"},
          {"name":"EAST LONGMEADOW","code":"US-MA-ETL"},
          {"name":"EAST MANSFIELD","code":"US-MA-M33"},
          {"name":"EAST OTIS","code":"US-MA-E3O"},
          {"name":"EAST SANDWICH","code":"US-MA-E6S"},
          {"name":"EAST TAUNTON","code":"US-MA-ETU"},
          {"name":"EAST TEMPLETON","code":"US-MA-T6P"},
          {"name":"EAST WALPOLE","code":"US-MA-ZEP"},
          {"name":"EAST WEYMOUTH","code":"US-MA-EWY"},
          {"name":"EASTHAM","code":"US-MA-EHQ"},
          {"name":"EASTHAMPTON","code":"US-MA-EHN"},
          {"name":"EASTON","code":"US-MA-ETM"},
          {"name":"EDGARTOWN","code":"US-MA-ETW"},
          {"name":"ELIOT","code":"US-MA-ET5"},
          {"name":"ERVING","code":"US-MA-ER4"},
          {"name":"ESSEX","code":"US-MA-ES6"},
          {"name":"EVERETT","code":"US-MA-EVT"},
          {"name":"FAIRHAVEN","code":"US-MA-FVH"},
          {"name":"FALL RIVER","code":"US-MA-FAV"},
          {"name":"FALL RIVER-NEW BEDFORD APT","code":"US-MA-EWB"},
          {"name":"FALMOUTH","code":"US-MA-FMH"},
          {"name":"FAYVILLE","code":"US-MA-FQI"},
          {"name":"FEEDING HILLS","code":"US-MA-FGH"},
          {"name":"FISKDALE","code":"US-MA-FKD"},
          {"name":"FITCHBURG","code":"US-MA-FIT"},
          {"name":"FLORENCE","code":"US-MA-FLM"},
          {"name":"FLORIDA","code":"US-MA-FL4"},
          {"name":"FORT DEVENS","code":"US-MA-AYE"},
          {"name":"FOXBORO","code":"US-MA-FXB"},
          {"name":"FOXBOROUGH","code":"US-MA-IXG"},
          {"name":"FRAMINGHAM","code":"US-MA-FHM"},
          {"name":"FRANKLIN","code":"US-MA-FLN"},
          {"name":"GARDNER","code":"US-MA-GDM"},
          {"name":"GEORGETOWN","code":"US-MA-GXT"},
          {"name":"GILBERTVILLE","code":"US-MA-GEZ"},
          {"name":"GLENDALE","code":"US-MA-G2D"},
          {"name":"GLOUCESTER","code":"US-MA-GLO"},
          {"name":"GOSHEN","code":"US-MA-GN8"},
          {"name":"GRAFTON","code":"US-MA-GZO"},
          {"name":"GRANBY","code":"US-MA-GMY"},
          {"name":"GRANVILLE","code":"US-MA-VL4"},
          {"name":"GREAT BARRINGTON","code":"US-MA-GBR"},
          {"name":"GREENFIELD","code":"US-MA-GNH"},
          {"name":"GREENVILLE","code":"US-MA-GVQ"},
          {"name":"GRISWOLDVILLE","code":"US-MA-GSV"},
          {"name":"GROTON","code":"US-MA-GOT"},
          {"name":"GROVELAND","code":"US-MA-GVO"},
          {"name":"HADLEY","code":"US-MA-HDJ"},
          {"name":"HALIFAX","code":"US-MA-HFX"},
          {"name":"HAMPDEN","code":"US-MA-6HD"},
          {"name":"HANOVER","code":"US-MA-HVQ"},
          {"name":"HANSON","code":"US-MA-UJP"},
          {"name":"HARDWICK","code":"US-MA-HWK"},
          {"name":"HARVARD","code":"US-MA-HV5"},
          {"name":"HARWICH","code":"US-MA-HW3"},
          {"name":"HATFIELD","code":"US-MA-TFL"},
          {"name":"HAVERHILL","code":"US-MA-HAV"},
          {"name":"HAYDENVILLE","code":"US-MA-YDF"},
          {"name":"HEATH","code":"US-MA-6HA"},
          {"name":"HINGHAM","code":"US-MA-HGM"},
          {"name":"HINSDALE","code":"US-MA-6DH"},
          {"name":"HOLBROOK","code":"US-MA-HRK"},
          {"name":"HOLDEN","code":"US-MA-HQD"},
          {"name":"HOLLAND","code":"US-MA-QQD"},
          {"name":"HOLLISTON","code":"US-MA-XGP"},
          {"name":"HOLYOKE","code":"US-MA-UJQ"},
          {"name":"HOPEDALE","code":"US-MA-HPX"},
          {"name":"HOPKINTON","code":"US-MA-HKN"},
          {"name":"HOUSATONIC","code":"US-MA-HUQ"},
          {"name":"HUBBARDSTON","code":"US-MA-YHS"},
          {"name":"HUDSON","code":"US-MA-HDM"},
          {"name":"HULL","code":"US-MA-H3M"},
          {"name":"HUNTINGTON","code":"US-MA-TO8"},
          {"name":"HYANNIS","code":"US-MA-HYA"},
          {"name":"HYDE PARK","code":"US-MA-HYE"},
          {"name":"INDIAN ORCHARD","code":"US-MA-IO2"},
          {"name":"IPSWICH","code":"US-MA-IPW"},
          {"name":"JAMAICA PLAIN","code":"US-MA-JP8"},
          {"name":"KINGSTON","code":"US-MA-KGN"},
          {"name":"LAKEVILLE","code":"US-MA-LIL"},
          {"name":"LANCASTER","code":"US-MA-LCS"},
          {"name":"LANESBOROUGH","code":"US-MA-JLR"},
          {"name":"LAWRENCE","code":"US-MA-LWM"},
          {"name":"LEE","code":"US-MA-LEH"},
          {"name":"LEEDS","code":"US-MA-LE5"},
          {"name":"LEICESTER","code":"US-MA-LCM"},
          {"name":"LENOX","code":"US-MA-LNX"},
          {"name":"LENOX DALE","code":"US-MA-LXD"},
          {"name":"LEOMINSTER","code":"US-MA-LMR"},
          {"name":"LEVERETT","code":"US-MA-VR4"},
          {"name":"LEXINGTON","code":"US-MA-LXI"},
          {"name":"LINCOLN","code":"US-MA-LCQ"},
          {"name":"LINWOOD","code":"US-MA-U6D"},
          {"name":"LITTLETON","code":"US-MA-LTN"},
          {"name":"LONGMEADOW","code":"US-MA-ZLW"},
          {"name":"LOWELL","code":"US-MA-LOE"},
          {"name":"LUDLOW","code":"US-MA-LUW"},
          {"name":"LUNENBURG","code":"US-MA-LUG"},
          {"name":"LYNN","code":"US-MA-LZN"},
          {"name":"LYNNFIELD","code":"US-MA-7YT"},
          {"name":"MAGNOLIA","code":"US-MA-MAG"},
          {"name":"MALDEN","code":"US-MA-XII"},
          {"name":"MANCHAUG","code":"US-MA-M2U"},
          {"name":"MANCHESTER","code":"US-MA-ZRM"},
          {"name":"MANSFIELD","code":"US-MA-MNS"},
          {"name":"MARBLEHEAD","code":"US-MA-MH2"},
          {"name":"MARION","code":"US-MA-QAM"},
          {"name":"MARLBORO (MARLBOROUGH)","code":"US-MA-MXG"},
          {"name":"MARLBOROUGH, MIDDLESEX","code":"US-MA-MB2"},
          {"name":"MARSHFIELD","code":"US-MA-MFB"},
          {"name":"MARTHA'S VINEYARD","code":"US-MA-MVY"},
          {"name":"MASHPEE","code":"US-MA-ME7"},
          {"name":"MATTAPOISETT","code":"US-MA-MTQ"},
          {"name":"MAYNARD","code":"US-MA-UMY"},
          {"name":"MEDFIELD","code":"US-MA-MDC"},
          {"name":"MEDFORD","code":"US-MA-MFC"},
          {"name":"MEDWAY","code":"US-MA-MY9"},
          {"name":"MELROSE","code":"US-MA-MLO"},
          {"name":"MENDON","code":"US-MA-NDO"},
          {"name":"MENEMSHA","code":"US-MA-MN5"},
          {"name":"MERRIMAC","code":"US-MA-MM2"},
          {"name":"METHUEN","code":"US-MA-MZN"},
          {"name":"MIDDLEBORO","code":"US-MA-MID"},
          {"name":"MIDDLEFIELD","code":"US-MA-3DF"},
          {"name":"MIDDLETON","code":"US-MA-MYN"},
          {"name":"MILDFORD","code":"US-MA-M4F"},
          {"name":"MILFORD","code":"US-MA-MFK"},
          {"name":"MILLBURY","code":"US-MA-MBT"},
          {"name":"MILLIS","code":"US-MA-MS2"},
          {"name":"MILLVILLE","code":"US-MA-V8V"},
          {"name":"MILTON","code":"US-MA-MXL"},
          {"name":"MONROE BRIDGE","code":"US-MA-VOB"},
          {"name":"MONSON","code":"US-MA-MZS"},
          {"name":"MONTAGUE","code":"US-MA-M2G"},
          {"name":"MONTEREY","code":"US-MA-3MR"},
          {"name":"NAHANT","code":"US-MA-N5H"},
          {"name":"NANTUCKET","code":"US-MA-ACK"},
          {"name":"NATICK","code":"US-MA-NAK"},
          {"name":"NEEDHAM","code":"US-MA-NEE"},
          {"name":"NEEDHAM HEIGHTS","code":"US-MA-NDM"},
          {"name":"NEW BEDFORD","code":"US-MA-NBD"},
          {"name":"NEW BEDFORD-FALL RIVER APT","code":"US-MA-EWB"},
          {"name":"NEW BRAINTREE","code":"US-MA-6RE"},
          {"name":"NEW MARLBOROUGH","code":"US-MA-N8M"},
          {"name":"NEW SALEM","code":"US-MA-6NW"},
          {"name":"NEWBURY","code":"US-MA-ZGK"},
          {"name":"NEWBURYPORT","code":"US-MA-ZMP"},
          {"name":"NEWTON","code":"US-MA-NTN"},
          {"name":"NEWTON HIGHLANDS","code":"US-MA-TGX"},
          {"name":"NEWTON UPPER FALLS","code":"US-MA-NFM"},
          {"name":"NEWTONVILLE","code":"US-MA-NNF"},
          {"name":"NORFOLK","code":"US-MA-NFJ"},
          {"name":"NORTH ABINGTON","code":"US-MA-NAA"},
          {"name":"NORTH ADAMS","code":"US-MA-NA9"},
          {"name":"NORTH ANDOVER","code":"US-MA-XJM"},
          {"name":"NORTH ATTLEBORO","code":"US-MA-NTT"},
          {"name":"NORTH BILLERICA","code":"US-MA-NBA"},
          {"name":"NORTH BROOKFIELD","code":"US-MA-NBP"},
          {"name":"NORTH CHELMSFORD","code":"US-MA-NCF"},
          {"name":"NORTH DARTMOUTH","code":"US-MA-NDB"},
          {"name":"NORTH DIGHTON","code":"US-MA-NDG"},
          {"name":"NORTH EASTHAM","code":"US-MA-EN9"},
          {"name":"NORTH FALMOUTH","code":"US-MA-NFH"},
          {"name":"NORTH GRAFTON","code":"US-MA-NGR"},
          {"name":"NORTH OXFORD","code":"US-MA-NO2"},
          {"name":"NORTH PEMBROKE","code":"US-MA-NMP"},
          {"name":"NORTH READING","code":"US-MA-NRG"},
          {"name":"NORTH SCITUATE","code":"US-MA-NCT"},
          {"name":"NORTH TRURO","code":"US-MA-M2N"},
          {"name":"NORTH WEYMOUTH","code":"US-MA-NWY"},
          {"name":"NORTHAMPTON","code":"US-MA-NHM"},
          {"name":"NORTHBOROUGH","code":"US-MA-NBO"},
          {"name":"NORTHBRIDGE","code":"US-MA-NTG"},
          {"name":"NORTHFIELD","code":"US-MA-NF8"},
          {"name":"NORTON","code":"US-MA-NNM"},
          {"name":"NORWELL","code":"US-MA-NOW"},
          {"name":"NORWOOD","code":"US-MA-OWD"},
          {"name":"NUTTING LAKE","code":"US-MA-VGK"},
          {"name":"OAK BLUFFS","code":"US-MA-OB2"},
          {"name":"OAKHAM","code":"US-MA-HK5"},
          {"name":"ONSET","code":"US-MA-ONS"},
          {"name":"ORANGE","code":"US-MA-OAF"},
          {"name":"ORLEANS","code":"US-MA-OLN"},
          {"name":"OSTERVILLE","code":"US-MA-OV3"},
          {"name":"OTIS","code":"US-MA-8SI"},
          {"name":"OTTER RIVER","code":"US-MA-IXO"},
          {"name":"OXFORD","code":"US-MA-OXB"},
          {"name":"PALMER","code":"US-MA-PMX"},
          {"name":"PAXTON","code":"US-MA-5XT"},
          {"name":"PEABODY","code":"US-MA-PED"},
          {"name":"PEMBROKE","code":"US-MA-PBM"},
          {"name":"PEPPERELL","code":"US-MA-PEP"},
          {"name":"PETERSHAM","code":"US-MA-M7A"},
          {"name":"PHILLIPSTON","code":"US-MA-PH8"},
          {"name":"PINEHURST","code":"US-MA-PHS"},
          {"name":"PITTSFIELD","code":"US-MA-PSF"},
          {"name":"PLAINFIELD","code":"US-MA-FP8"},
          {"name":"PLAINVILLE","code":"US-MA-XKV"},
          {"name":"PLYMOUTH","code":"US-MA-PYM"},
          {"name":"PLYMPTON","code":"US-MA-PL4"},
          {"name":"POCASSET","code":"US-MA-PJC"},
          {"name":"PRINCETON","code":"US-MA-JNQ"},
          {"name":"PROVINCETOWN","code":"US-MA-PVC"},
          {"name":"QUINCY","code":"US-MA-MQI"},
          {"name":"RANDOLPH","code":"US-MA-RDH"},
          {"name":"RAYNHAM","code":"US-MA-XLF"},
          {"name":"READING","code":"US-MA-RDI"},
          {"name":"READVILLE","code":"US-MA-REA"},
          {"name":"REHOBOTH","code":"US-MA-RZX"},
          {"name":"REVERE","code":"US-MA-REZ"},
          {"name":"RICHMOND","code":"US-MA-9MH"},
          {"name":"ROCHDALE","code":"US-MA-ZRO"},
          {"name":"ROCHESTER","code":"US-MA-RCS"},
          {"name":"ROCKLAND","code":"US-MA-RCA"},
          {"name":"ROCKPORT","code":"US-MA-JRT"},
          {"name":"ROSLINDALE","code":"US-MA-RZD"},
          {"name":"ROWE","code":"US-MA-9WE"},
          {"name":"ROWLEY","code":"US-MA-RY2"},
          {"name":"ROXBURY","code":"US-MA-RXY"},
          {"name":"ROYALSTON","code":"US-MA-9YT"},
          {"name":"RUSSELL","code":"US-MA-USL"},
          {"name":"RUTLAND","code":"US-MA-RT7"},
          {"name":"SAGAMORE","code":"US-MA-TX4"},
          {"name":"SAGAMORE BEACH","code":"US-MA-G9C"},
          {"name":"SALEM","code":"US-MA-SLM"},
          {"name":"SALISBURY","code":"US-MA-SLS"},
          {"name":"SANDISFIELD","code":"US-MA-6SD"},
          {"name":"SANDWICH","code":"US-MA-SWC"},
          {"name":"SAUGUS","code":"US-MA-UGX"},
          {"name":"SAVOY","code":"US-MA-6SV"},
          {"name":"SCITUATE","code":"US-MA-SCU"},
          {"name":"SEEKONK","code":"US-MA-SWK"},
          {"name":"SHARON","code":"US-MA-SHM"},
          {"name":"SHEFFIELD","code":"US-MA-QSH"},
          {"name":"SHELBURNE FALLS","code":"US-MA-HFS"},
          {"name":"SHELDONVILLE","code":"US-MA-VSV"},
          {"name":"SHERBORN","code":"US-MA-ZSO"},
          {"name":"SHIRLEY","code":"US-MA-SJR"},
          {"name":"SHREWSBURY","code":"US-MA-SHU"},
          {"name":"SHUTESBURY","code":"US-MA-UY9"},
          {"name":"SILVER BEACH","code":"US-MA-555"},
          {"name":"SOMERSET","code":"US-MA-SS3"},
          {"name":"SOMERVILLE","code":"US-MA-UZB"},
          {"name":"SOUTH ATTLEBORO","code":"US-MA-JSB"},
          {"name":"SOUTH BARRE","code":"US-MA-SB5"},
          {"name":"SOUTH BOSTON","code":"US-MA-SBC"},
          {"name":"SOUTH DARTMOUTH","code":"US-MA-ODT"},
          {"name":"SOUTH DEERFIELD","code":"US-MA-DDF"},
          {"name":"SOUTH DENNIS","code":"US-MA-UHS"},
          {"name":"SOUTH EASTON","code":"US-MA-QSN"},
          {"name":"SOUTH GRAFTON","code":"US-MA-SOQ"},
          {"name":"SOUTH HADLEY","code":"US-MA-SYM"},
          {"name":"SOUTH HAMILTON","code":"US-MA-ZYX"},
          {"name":"SOUTH LEE","code":"US-MA-SDT"},
          {"name":"SOUTH NATICK","code":"US-MA-S88"},
          {"name":"SOUTH WALPOLE","code":"US-MA-QSW"},
          {"name":"SOUTH WELLFLEET","code":"US-MA-S5W"},
          {"name":"SOUTH WEYMOUTH","code":"US-MA-NZW"},
          {"name":"SOUTH YARMOUTH","code":"US-MA-OUY"},
          {"name":"SOUTHAMPTON","code":"US-MA-TN6"},
          {"name":"SOUTHBOROUGH","code":"US-MA-SZV"},
          {"name":"SOUTHBRIDGE","code":"US-MA-THD"},
          {"name":"SOUTHFIELD","code":"US-MA-SFX"},
          {"name":"SOUTHWICK","code":"US-MA-SZW"},
          {"name":"SPENCER","code":"US-MA-SPR"},
          {"name":"SPRINGFIELD","code":"US-MA-SFY"},
          {"name":"STERLING","code":"US-MA-XLS"},
          {"name":"STOCKBRIDGE","code":"US-MA-TCB"},
          {"name":"STONEHAM","code":"US-MA-STU"},
          {"name":"STOUGHTON","code":"US-MA-SOU"},
          {"name":"STOW","code":"US-MA-MMN"},
          {"name":"STURBRIDGE","code":"US-MA-TBI"},
          {"name":"SUDBURY","code":"US-MA-SUR"},
          {"name":"SUNDERLAND","code":"US-MA-5SR"},
          {"name":"SUTTON","code":"US-MA-TON"},
          {"name":"SWAMPSCOTT","code":"US-MA-USW"},
          {"name":"SWANSEA","code":"US-MA-AE5"},
          {"name":"TAUNTON","code":"US-MA-TNS"},
          {"name":"TAUTON","code":"US-MA-9TA"},
          {"name":"TEMPLETON","code":"US-MA-6MP"},
          {"name":"TEWKSBURY","code":"US-MA-TEW"},
          {"name":"THREE RIVERS","code":"US-MA-TRJ"},
          {"name":"TISBURY","code":"US-MA-TY3"},
          {"name":"TOPSFIELD","code":"US-MA-TF3"},
          {"name":"TOWNSEND","code":"US-MA-TWB"},
          {"name":"TRURO","code":"US-MA-TXO"},
          {"name":"TURNERS FALLS","code":"US-MA-M4U"},
          {"name":"TYNGSBORO","code":"US-MA-TG2"},
          {"name":"UPTON","code":"US-MA-UPT"},
          {"name":"UXBRIDGE","code":"US-MA-UXX"},
          {"name":"VINEYARD HAVEN","code":"US-MA-VYH"},
          {"name":"WABAN","code":"US-MA-WN4"},
          {"name":"WAKEFIELD","code":"US-MA-WKF"},
          {"name":"WALES","code":"US-MA-5SW"},
          {"name":"WALPOLE","code":"US-MA-WPL"},
          {"name":"WALTHAM","code":"US-MA-WLM"},
          {"name":"WARD HILL","code":"US-MA-WHZ"},
          {"name":"WARE","code":"US-MA-UWA"},
          {"name":"WAREHAM","code":"US-MA-WMV"},
          {"name":"WARREN","code":"US-MA-WXN"},
          {"name":"WARWICK","code":"US-MA-WRC"},
          {"name":"WATERTOWN","code":"US-MA-WTW"},
          {"name":"WATERVILLE","code":"US-MA-WVS"},
          {"name":"WAVERLEY","code":"US-MA-YEY"},
          {"name":"WAYLAND","code":"US-MA-WL2"},
          {"name":"WEBSTER","code":"US-MA-WEB"},
          {"name":"WELLESLEY","code":"US-MA-WEL"},
          {"name":"WELLFLEET","code":"US-MA-3OL"},
          {"name":"WENDELL","code":"US-MA-9DL"},
          {"name":"WENHAM","code":"US-MA-MM9"},
          {"name":"WEST BOYLSTON","code":"US-MA-WBM"},
          {"name":"WEST BRIDGEWATER","code":"US-MA-WBW"},
          {"name":"WEST BROOKFIELD","code":"US-MA-WBD"},
          {"name":"WEST CHESTERFIELD","code":"US-MA-WCF"},
          {"name":"WEST CHOP","code":"US-MA-WC2"},
          {"name":"WEST CONCORD","code":"US-MA-9WR"},
          {"name":"WEST GLOUCESTER","code":"US-MA-WGU"},
          {"name":"WEST GROTON","code":"US-MA-WGM"},
          {"name":"WEST HANOVER","code":"US-MA-WVR"},
          {"name":"WEST HARWICH","code":"US-MA-HWC"},
          {"name":"WEST HATFIELD","code":"US-MA-WED"},
          {"name":"WEST NEWBURY","code":"US-MA-WN5"},
          {"name":"WEST NEWTON","code":"US-MA-3TU"},
          {"name":"WEST ROXBURY","code":"US-MA-WRY"},
          {"name":"WEST SPRINGFIELD","code":"US-MA-WSP"},
          {"name":"WEST STOCKBRIDGE","code":"US-MA-9WB"},
          {"name":"WEST TISBURY","code":"US-MA-YYT"},
          {"name":"WEST WAREHAM","code":"US-MA-WWH"},
          {"name":"WEST WARREN","code":"US-MA-WWN"},
          {"name":"WEST YARMOUTH","code":"US-MA-WMU"},
          {"name":"WESTBOROUGH","code":"US-MA-WRO"},
          {"name":"WESTCHESTER","code":"US-MA-XNP"},
          {"name":"WESTFIELD","code":"US-MA-BAF"},
          {"name":"WESTFORD","code":"US-MA-WFR"},
          {"name":"WESTMINSTER","code":"US-MA-WMI"},
          {"name":"WESTON","code":"US-MA-WS4"},
          {"name":"WESTPORT","code":"US-MA-WPE"},
          {"name":"WESTPORT POINT","code":"US-MA-ZWI"},
          {"name":"WESTWOOD","code":"US-MA-VEW"},
          {"name":"WEYMOUTH","code":"US-MA-WEY"},
          {"name":"WHATELY","code":"US-MA-YWH"},
          {"name":"WHITINSVILLE","code":"US-MA-WXM"},
          {"name":"WHITMAN","code":"US-MA-WQA"},
          {"name":"WILBRAHAM","code":"US-MA-5WR"},
          {"name":"WILKINSONVILLE","code":"US-MA-WKM"},
          {"name":"WILLIAMSBURG","code":"US-MA-IMU"},
          {"name":"WILLIAMSTOWN","code":"US-MA-WMS"},
          {"name":"WILMINGTON","code":"US-MA-WOM"},
          {"name":"WINCHENDON","code":"US-MA-WCD"},
          {"name":"WINCHESTER","code":"US-MA-IHT"},
          {"name":"WINDSOR","code":"US-MA-5WO"},
          {"name":"WINTHROP","code":"US-MA-IHP"},
          {"name":"WOBURN","code":"US-MA-WBN"},
          {"name":"WOODS HOLE","code":"US-MA-WOH"},
          {"name":"WORCESTAR","code":"US-MA-W3C"},
          {"name":"WORCESTER","code":"US-MA-ORH"},
          {"name":"WORONOCO","code":"US-MA-6OC"},
          {"name":"WORTHINGTON CENTER","code":"US-MA-OHT"},
          {"name":"WRENTHAM","code":"US-MA-WX2"},
          {"name":"YARMOUTH","code":"US-MA-YAH"},
          {"name":"YARMOUTH PORT","code":"US-MA-YMP"},
        ]
      },
      {
        name: 'Míchigan',
        code: 'US-MI',
        cities: [
          {"name":"ACME","code":"US-MI-DMF"},
          {"name":"ADA","code":"US-MI-ADZ"},
          {"name":"ADDISON","code":"US-MI-YAI"},
          {"name":"ADRIAN","code":"US-MI-ADG"},
          {"name":"AFTON","code":"US-MI-AF9"},
          {"name":"AKRON","code":"US-MI-AKA"},
          {"name":"ALANSON","code":"US-MI-YAN"},
          {"name":"ALBA","code":"US-MI-TLB"},
          {"name":"ALBION","code":"US-MI-AOX"},
          {"name":"ALGER","code":"US-MI-AQG"},
          {"name":"ALGONAC","code":"US-MI-ALG"},
          {"name":"ALICIA","code":"US-MI-ACA"},
          {"name":"ALLEGAN","code":"US-MI-ALL"},
          {"name":"ALLEN","code":"US-MI-AN2"},
          {"name":"ALLEN PARK","code":"US-MI-APK"},
          {"name":"ALLENDALE","code":"US-MI-GKE"},
          {"name":"ALMA","code":"US-MI-AMN"},
          {"name":"ALMONT","code":"US-MI-ATJ"},
          {"name":"ALPENA","code":"US-MI-APN"},
          {"name":"ALTO","code":"US-MI-AO2"},
          {"name":"AMASA","code":"US-MI-AMM"},
          {"name":"ANCHORVILLE","code":"US-MI-AHF"},
          {"name":"ANN ARBOR","code":"US-MI-ARB"},
          {"name":"ARMADA","code":"US-MI-AAK"},
          {"name":"ASHLAND","code":"US-MI-8MA"},
          {"name":"ASHLEY","code":"US-MI-AS6"},
          {"name":"ATHENS","code":"US-MI-AN4"},
          {"name":"ATLANTA","code":"US-MI-QJA"},
          {"name":"AU GRES","code":"US-MI-XBI"},
          {"name":"AUBURN","code":"US-MI-ABM"},
          {"name":"AUBURN HEIGHTS","code":"US-MI-AUH"},
          {"name":"AUBURN HILLS","code":"US-MI-ABH"},
          {"name":"AUGUSTA","code":"US-MI-UUT"},
          {"name":"BAD AXE","code":"US-MI-BAA"},
          {"name":"BAILEY","code":"US-MI-BAI"},
          {"name":"BALDWIN","code":"US-MI-AWI"},
          {"name":"BANCROFT","code":"US-MI-FBT"},
          {"name":"BANGOR","code":"US-MI-BNQ"},
          {"name":"BANNISTER","code":"US-MI-IBI"},
          {"name":"BARAGA","code":"US-MI-RGA"},
          {"name":"BARODA","code":"US-MI-Z9B"},
          {"name":"BATH","code":"US-MI-JAH"},
          {"name":"BATTLE CREEK","code":"US-MI-BTL"},
          {"name":"BAY CITY","code":"US-MI-BCY"},
          {"name":"BAY CITY-SAGINAW-MIDLAND APT","code":"US-MI-MBS"},
          {"name":"BAY PORT","code":"US-MI-BP5"},
          {"name":"BEAVER ISLAND","code":"US-MI-BI2"},
          {"name":"BEAVERTON","code":"US-MI-BQ2"},
          {"name":"BEDFORD","code":"US-MI-BZF"},
          {"name":"BELDING","code":"US-MI-XCC"},
          {"name":"BELLAIRE","code":"US-MI-ACB"},
          {"name":"BELLEVILLE","code":"US-MI-BLZ"},
          {"name":"BELMONT","code":"US-MI-BMJ"},
          {"name":"BENTON HARBOR","code":"US-MI-BEH"},
          {"name":"BENZONIA","code":"US-MI-BN7"},
          {"name":"BERKLEY","code":"US-MI-BKL"},
          {"name":"BERRIEN CENTER","code":"US-MI-B9T"},
          {"name":"BERRIEN SPRINGS","code":"US-MI-B2R"},
          {"name":"BESSEMER","code":"US-MI-IBM"},
          {"name":"BEVERLY HILLS","code":"US-MI-BH3"},
          {"name":"BIG RAPIDS","code":"US-MI-WBR"},
          {"name":"BINGHAM FARMS","code":"US-MI-GHF"},
          {"name":"BIRCH RUN","code":"US-MI-ZZI"},
          {"name":"BIRMINGHAM","code":"US-MI-BIZ"},
          {"name":"BLANCHARD","code":"US-MI-AHQ"},
          {"name":"BLISSFIELD","code":"US-MI-BLS"},
          {"name":"BLOOMFIELD","code":"US-MI-FQA"},
          {"name":"BLOOMFIELD HILLS","code":"US-MI-OFH"},
          {"name":"BOYNE CITY","code":"US-MI-BYZ"},
          {"name":"BOYNE FALLS","code":"US-MI-BIU"},
          {"name":"BRAMPTON","code":"US-MI-BMP"},
          {"name":"BRECKENRIDGE","code":"US-MI-KRG"},
          {"name":"BREVORT","code":"US-MI-BM4"},
          {"name":"BRIDGEPORT","code":"US-MI-BPM"},
          {"name":"BRIDGEWATER","code":"US-MI-RWA"},
          {"name":"BRIDGMAN","code":"US-MI-RDN"},
          {"name":"BRIGHTON","code":"US-MI-BHO"},
          {"name":"BRIMLEY","code":"US-MI-BJM"},
          {"name":"BRITTON","code":"US-MI-TZJ"},
          {"name":"BRONSON","code":"US-MI-QTX"},
          {"name":"BROOKLYN","code":"US-MI-BK8"},
          {"name":"BROWN CITY","code":"US-MI-B2C"},
          {"name":"BROWNSTOWN","code":"US-MI-BQN"},
          {"name":"BROWNSTOWN TOWNSHIP","code":"US-MI-OWT"},
          {"name":"BRUCE CROSSING","code":"US-MI-QBQ"},
          {"name":"BUCHANAN","code":"US-MI-BUC"},
          {"name":"BURLINGTON","code":"US-MI-UIG"},
          {"name":"BURR OAK","code":"US-MI-BUQ"},
          {"name":"BURTON","code":"US-MI-QBU"},
          {"name":"BYRON","code":"US-MI-B7Y"},
          {"name":"BYRON CENTER","code":"US-MI-BYK"},
          {"name":"CADILLAC","code":"US-MI-CAD"},
          {"name":"CADMUS","code":"US-MI-CM5"},
          {"name":"CALCITE","code":"US-MI-MI3"},
          {"name":"CALEDONIA","code":"US-MI-CDZ"},
          {"name":"CALUMET","code":"US-MI-LMM"},
          {"name":"CAMDEN","code":"US-MI-CX8"},
          {"name":"CANADIAN LAKES","code":"US-MI-C9K"},
          {"name":"CANTON","code":"US-MI-CNT"},
          {"name":"CAPAC","code":"US-MI-XAP"},
          {"name":"CARLETON","code":"US-MI-CNL"},
          {"name":"CARO","code":"US-MI-CJO"},
          {"name":"CARROLLTON","code":"US-MI-KJR"},
          {"name":"CARSONVILLE","code":"US-MI-CSU"},
          {"name":"CASCO","code":"US-MI-CC4"},
          {"name":"CASEVILLE","code":"US-MI-C83"},
          {"name":"CASNOVIA","code":"US-MI-CSO"},
          {"name":"CASPIAN","code":"US-MI-CCP"},
          {"name":"CASS CITY","code":"US-MI-CC3"},
          {"name":"CASSOPOLIS","code":"US-MI-CZI"},
          {"name":"CEDAR","code":"US-MI-CQ5"},
          {"name":"CEDAR LAKE","code":"US-MI-CDU"},
          {"name":"CEDAR RIVER","code":"US-MI-DIV"},
          {"name":"CEDAR SPRINGS","code":"US-MI-ER6"},
          {"name":"CEMENT CITY","code":"US-MI-2CC"},
          {"name":"CENTER LINE","code":"US-MI-ZB3"},
          {"name":"CENTRAL LAKE","code":"US-MI-NLK"},
          {"name":"CENTREVILLE","code":"US-MI-NRV"},
          {"name":"CHAMPION","code":"US-MI-FNP"},
          {"name":"CHANNING","code":"US-MI-NZG"},
          {"name":"CHARLEVOIX","code":"US-MI-CSX"},
          {"name":"CHARLOTTE","code":"US-MI-CRQ"},
          {"name":"CHASSELL","code":"US-MI-VSL"},
          {"name":"CHEBOYGAN","code":"US-MI-CHG"},
          {"name":"CHELSEA","code":"US-MI-ICS"},
          {"name":"CHERRY BEND","code":"US-MI-C5H"},
          {"name":"CHESANING","code":"US-MI-CIJ"},
          {"name":"CHESTERFIELD","code":"US-MI-CFL"},
          {"name":"CHINA TOWNSHIP","code":"US-MI-C32"},
          {"name":"CHRISTMAS","code":"US-MI-3CH"},
          {"name":"CLARE","code":"US-MI-XRE"},
          {"name":"CLARKLAKE","code":"US-MI-CK8"},
          {"name":"CLARKSTON","code":"US-MI-LRT"},
          {"name":"CLARKSVILLE","code":"US-MI-KIV"},
          {"name":"CLAWSON","code":"US-MI-ZCS"},
          {"name":"CLAYTON","code":"US-MI-YTN"},
          {"name":"CLIFFORD","code":"US-MI-7LI"},
          {"name":"CLINTON","code":"US-MI-CNC"},
          {"name":"CLINTON, TOWNSHIP OF","code":"US-MI-LNH"},
          {"name":"CLIO","code":"US-MI-QL2"},
          {"name":"COLDWATER","code":"US-MI-CWA"},
          {"name":"COLEMAN","code":"US-MI-CMA"},
          {"name":"COLOMA","code":"US-MI-CMK"},
          {"name":"COLON","code":"US-MI-OL3"},
          {"name":"COLUMBIAVILLE","code":"US-MI-UVM"},
          {"name":"COLUMBUS","code":"US-MI-VDA"},
          {"name":"COMMERCE TOWNSHIP","code":"US-MI-OMM"},
          {"name":"COMSTOCK","code":"US-MI-CVC"},
          {"name":"COMSTOCK PARK","code":"US-MI-YCM"},
          {"name":"CONCORD","code":"US-MI-JJD"},
          {"name":"CONKLIN","code":"US-MI-NMC"},
          {"name":"CONSTANTINE","code":"US-MI-TNJ"},
          {"name":"CONWAY","code":"US-MI-OWY"},
          {"name":"COOPERSVILLE","code":"US-MI-ZCP"},
          {"name":"COPEMISH","code":"US-MI-2FL"},
          {"name":"CORUNNA","code":"US-MI-C22"},
          {"name":"COTTRELLVILLE","code":"US-MI-2TT"},
          {"name":"CROSWELL","code":"US-MI-CXA"},
          {"name":"CRYSTAL","code":"US-MI-C3R"},
          {"name":"CRYSTAL FALLS","code":"US-MI-CZ4"},
          {"name":"DANSVILLE","code":"US-MI-NY6"},
          {"name":"DAVISBURG","code":"US-MI-DBG"},
          {"name":"DAVISON","code":"US-MI-DVO"},
          {"name":"DE TOUR VILLAGE","code":"US-MI-DT4"},
          {"name":"DE WITT","code":"US-MI-DWT"},
          {"name":"DEARBORN","code":"US-MI-DEO"},
          {"name":"DEARBORN HEIGHTS","code":"US-MI-DHG"},
          {"name":"DECATUR","code":"US-MI-XEX"},
          {"name":"DEERFIELD","code":"US-MI-DLZ"},
          {"name":"DELTON","code":"US-MI-DN5"},
          {"name":"DETROIT","code":"US-MI-DET"},
          {"name":"DEXTER","code":"US-MI-YDX"},
          {"name":"DIMONDALE","code":"US-MI-DML"},
          {"name":"DOLLAR BAY","code":"US-MI-DXY"},
          {"name":"DORR","code":"US-MI-DDD"},
          {"name":"DOUGLAS, ALLEGAN","code":"US-MI-DOG"},
          {"name":"DOWAGIAC","code":"US-MI-DOW"},
          {"name":"DRAYTON PLAINS","code":"US-MI-DMI"},
          {"name":"DRUMMOND ISLAND","code":"US-MI-DRE"},
          {"name":"DRYDEN","code":"US-MI-YDA"},
          {"name":"DUNDEE","code":"US-MI-DND"},
          {"name":"DURAND","code":"US-MI-RUD"},
          {"name":"DUTTON","code":"US-MI-DU8"},
          {"name":"EAST GRAND RAPIDS","code":"US-MI-L4D"},
          {"name":"EAST JORDAN","code":"US-MI-EJD"},
          {"name":"EAST LANSING","code":"US-MI-EQ4"},
          {"name":"EAST LEROY","code":"US-MI-XXY"},
          {"name":"EAST TAWAS","code":"US-MI-ECA"},
          {"name":"EASTPOINTE","code":"US-MI-EP6"},
          {"name":"EATON RAPIDS","code":"US-MI-ETP"},
          {"name":"EAU CLAIRE","code":"US-MI-EIC"},
          {"name":"ECORSE","code":"US-MI-EKP"},
          {"name":"EDMORE","code":"US-MI-EM3"},
          {"name":"EDWARDSBURG","code":"US-MI-DWU"},
          {"name":"ELK RAPIDS","code":"US-MI-LR5"},
          {"name":"ELKTON","code":"US-MI-EKM"},
          {"name":"ELLSWORTH","code":"US-MI-EY5"},
          {"name":"ELMIRA","code":"US-MI-E89"},
          {"name":"ELSIE","code":"US-MI-EIL"},
          {"name":"ELWELL","code":"US-MI-EWE"},
          {"name":"EMPIRE","code":"US-MI-YZZ"},
          {"name":"ERIE","code":"US-MI-EE2"},
          {"name":"ESCANABA","code":"US-MI-ESC"},
          {"name":"ESSEXVILLE","code":"US-MI-EXY"},
          {"name":"EUREKA","code":"US-MI-EU8"},
          {"name":"EVART","code":"US-MI-EV2"},
          {"name":"FAIR HAVEN","code":"US-MI-RHV"},
          {"name":"FAIRGROVE","code":"US-MI-FAG"},
          {"name":"FAIRVIEW","code":"US-MI-FV8"},
          {"name":"FARMINGTON","code":"US-MI-FMI"},
          {"name":"FARMINGTON HILLS","code":"US-MI-FHI"},
          {"name":"FARWELL","code":"US-MI-FW8"},
          {"name":"FENNVILLE","code":"US-MI-FEJ"},
          {"name":"FENTON","code":"US-MI-FNO"},
          {"name":"FERNDALE","code":"US-MI-FEE"},
          {"name":"FERRYSBURG","code":"US-MI-FER"},
          {"name":"FIFE LAKE","code":"US-MI-FF4"},
          {"name":"FILER CITY","code":"US-MI-FTY"},
          {"name":"FLAT ROCK","code":"US-MI-F3K"},
          {"name":"FLATROCK","code":"US-MI-FLF"},
          {"name":"FLINT","code":"US-MI-FNT"},
          {"name":"FLUSHING","code":"US-MI-FUH"},
          {"name":"FORT GRATIOT","code":"US-MI-YFG"},
          {"name":"FOWLERVILLE","code":"US-MI-ZFV"},
          {"name":"FRANKENMUTH","code":"US-MI-FKM"},
          {"name":"FRANKFORT","code":"US-MI-FNF"},
          {"name":"FRANKLIN","code":"US-MI-KLF"},
          {"name":"FRASER","code":"US-MI-XFQ"},
          {"name":"FREELAND","code":"US-MI-FEN"},
          {"name":"FREEPORT","code":"US-MI-FPQ"},
          {"name":"FREMONT","code":"US-MI-FEM"},
          {"name":"FRUITPORT","code":"US-MI-UIH"},
          {"name":"FULTON","code":"US-MI-IUL"},
          {"name":"GALESBURG","code":"US-MI-GSU"},
          {"name":"GALIEN","code":"US-MI-JGL"},
          {"name":"GARDEN","code":"US-MI-GQD"},
          {"name":"GARDEN CITY","code":"US-MI-GCQ"},
          {"name":"GAYLORD","code":"US-MI-GYD"},
          {"name":"GIBRALTAR","code":"US-MI-GIT"},
          {"name":"GILFORD TOWNSHIP","code":"US-MI-R4D"},
          {"name":"GLADSTONE","code":"US-MI-GDE"},
          {"name":"GLADWIN","code":"US-MI-GDW"},
          {"name":"GOBLES","code":"US-MI-GZZ"},
          {"name":"GOODRICH","code":"US-MI-GIH"},
          {"name":"GRAND BLANC","code":"US-MI-QGB"},
          {"name":"GRAND HAVEN","code":"US-MI-GHN"},
          {"name":"GRAND JUNCTION","code":"US-MI-GZJ"},
          {"name":"GRAND LEDGE","code":"US-MI-GDG"},
          {"name":"GRAND RAPIDS","code":"US-MI-GRR"},
          {"name":"GRANDVILLE","code":"US-MI-GDI"},
          {"name":"GRANT","code":"US-MI-GTJ"},
          {"name":"GRASS LAKE","code":"US-MI-GSK"},
          {"name":"GRAWN","code":"US-MI-GWG"},
          {"name":"GRAYLING","code":"US-MI-QGH"},
          {"name":"GREENVILLE","code":"US-MI-GNM"},
          {"name":"GREENWOOD","code":"US-MI-ZDR"},
          {"name":"GREGORY","code":"US-MI-G2R"},
          {"name":"GROSSE ILE","code":"US-MI-ZGP"},
          {"name":"GROSSE POINT WOODS","code":"US-MI-GPW"},
          {"name":"GROSSE POINTE","code":"US-MI-GSS"},
          {"name":"GROSSE PONTE FARMS","code":"US-MI-GPF"},
          {"name":"GULLIVER","code":"US-MI-GUV"},
          {"name":"GWINN","code":"US-MI-3GW"},
          {"name":"HAGAR SHORES","code":"US-MI-H4S"},
          {"name":"HAMBURG","code":"US-MI-HXV"},
          {"name":"HAMILTON","code":"US-MI-HM2"},
          {"name":"HAMTRAMCK","code":"US-MI-XGU"},
          {"name":"HANCOCK","code":"US-MI-CMX"},
          {"name":"HANOVER","code":"US-MI-HVX"},
          {"name":"HARBOR BEACH","code":"US-MI-HB4"},
          {"name":"HARBOR SPRINGS","code":"US-MI-HS3"},
          {"name":"HARPER WOODS","code":"US-MI-RPW"},
          {"name":"HARRISON","code":"US-MI-HIR"},
          {"name":"HARRISON TOWNSHIP","code":"US-MI-H7P"},
          {"name":"HART","code":"US-MI-HRT"},
          {"name":"HARTFORD","code":"US-MI-HTT"},
          {"name":"HARTLAND","code":"US-MI-H7M"},
          {"name":"HASLETT","code":"US-MI-HA5"},
          {"name":"HASTINGS","code":"US-MI-HG2"},
          {"name":"HAZEL PARK","code":"US-MI-HZP"},
          {"name":"HEMLOCK","code":"US-MI-HEM"},
          {"name":"HICKORY CORNERS","code":"US-MI-IYO"},
          {"name":"HIGGINS LAKE","code":"US-MI-QGS"},
          {"name":"HIGHLAND","code":"US-MI-HL3"},
          {"name":"HIGHLAND PARK","code":"US-MI-HIP"},
          {"name":"HIGHLAND, OSCEOLA","code":"US-MI-HHN"},
          {"name":"HILLMAN","code":"US-MI-HJN"},
          {"name":"HILLSDALE","code":"US-MI-HSE"},
          {"name":"HOLLAND","code":"US-MI-HLM"},
          {"name":"HOLLY","code":"US-MI-HOC"},
          {"name":"HOLT","code":"US-MI-HCN"},
          {"name":"HOMER","code":"US-MI-HOZ"},
          {"name":"HONOR","code":"US-MI-KHO"},
          {"name":"HOPE","code":"US-MI-HPJ"},
          {"name":"HOPKINS","code":"US-MI-OPO"},
          {"name":"HORTON","code":"US-MI-HXT"},
          {"name":"HOUGHTON","code":"US-MI-HTL"},
          {"name":"HOUGHTON LAKE","code":"US-MI-HLK"},
          {"name":"HOWARD CITY","code":"US-MI-ZIT"},
          {"name":"HOWELL","code":"US-MI-HWL"},
          {"name":"HOXEYVILLE","code":"US-MI-XVH"},
          {"name":"HUBBARDSTON","code":"US-MI-UDO"},
          {"name":"HUBBELL","code":"US-MI-HBX"},
          {"name":"HUDSON","code":"US-MI-H9N"},
          {"name":"HUDSONVILLE","code":"US-MI-HUV"},
          {"name":"HUGHESVILLE","code":"US-MI-H3V"},
          {"name":"HUNTINGTON WOODS","code":"US-MI-HGW"},
          {"name":"IMLAY CITY","code":"US-MI-ILA"},
          {"name":"INDEPENDENCE TOWNSHIP","code":"US-MI-IPZ"},
          {"name":"INDIAN RIVER","code":"US-MI-IQV"},
          {"name":"INGALLS","code":"US-MI-JGA"},
          {"name":"INKSTER","code":"US-MI-IK2"},
          {"name":"INTERLOCHEN","code":"US-MI-IN4"},
          {"name":"IONIA","code":"US-MI-IIO"},
          {"name":"IONIA","code":"US-MI-NA4"},
          {"name":"IRA","code":"US-MI-IQA"},
          {"name":"IRON MOUNTAIN","code":"US-MI-IMT"},
          {"name":"IRON RIVER","code":"US-MI-IRW"},
          {"name":"IRONWOOD","code":"US-MI-IWD"},
          {"name":"IRVING","code":"US-MI-IR5"},
          {"name":"ISHPEMING","code":"US-MI-ISB"},
          {"name":"ITHACA","code":"US-MI-ITA"},
          {"name":"JACKSON","code":"US-MI-JXN"},
          {"name":"JAMESTOWN","code":"US-MI-JAD"},
          {"name":"JASPER","code":"US-MI-JAZ"},
          {"name":"JEDDO","code":"US-MI-JOD"},
          {"name":"JENISON","code":"US-MI-JNS"},
          {"name":"JONES","code":"US-MI-JO7"},
          {"name":"JONESVILLE","code":"US-MI-JOE"},
          {"name":"KALAMAZOO","code":"US-MI-AZO"},
          {"name":"KALKASKA","code":"US-MI-KLK"},
          {"name":"KAWKAWLIN","code":"US-MI-KW7"},
          {"name":"KEEGO HARBOR","code":"US-MI-KHB"},
          {"name":"KENT CITY","code":"US-MI-KCT"},
          {"name":"KENTON","code":"US-MI-KEO"},
          {"name":"KENTWOOD","code":"US-MI-KWD"},
          {"name":"KIMBALL","code":"US-MI-KMB"},
          {"name":"KINCHELOE","code":"US-MI-KCH"},
          {"name":"KINGSFORD","code":"US-MI-KFD"},
          {"name":"KINGSLEY","code":"US-MI-YUI"},
          {"name":"KINROSS","code":"US-MI-KRO"},
          {"name":"L'ANSE","code":"US-MI-LAJ"},
          {"name":"LA SALLE","code":"US-MI-IYL"},
          {"name":"LAKE CITY","code":"US-MI-3LM"},
          {"name":"LAKE LINDEN","code":"US-MI-LL7"},
          {"name":"LAKE ODESSA","code":"US-MI-LQO"},
          {"name":"LAKE ORION","code":"US-MI-LRP"},
          {"name":"LAKE ORION HEIGHTS","code":"US-MI-OXI"},
          {"name":"LAKEVIEW","code":"US-MI-LVW"},
          {"name":"LAKEVILLE","code":"US-MI-LQI"},
          {"name":"LAMBERTVILLE","code":"US-MI-LML"},
          {"name":"LANSING","code":"US-MI-LAN"},
          {"name":"LAPEER","code":"US-MI-APE"},
          {"name":"LAWRENCE","code":"US-MI-LWR"},
          {"name":"LAWTON","code":"US-MI-LW5"},
          {"name":"LE ROY","code":"US-MI-LR4"},
          {"name":"LEONARD","code":"US-MI-JND"},
          {"name":"LESLIE","code":"US-MI-LS5"},
          {"name":"LEVERING","code":"US-MI-LE4"},
          {"name":"LEWISTON","code":"US-MI-LVQ"},
          {"name":"LEXINGTON","code":"US-MI-LXM"},
          {"name":"LIBERTY","code":"US-MI-LHR"},
          {"name":"LINCOLN PARK","code":"US-MI-LIP"},
          {"name":"LINWOOD","code":"US-MI-YLW"},
          {"name":"LITCHFIELD","code":"US-MI-LTD"},
          {"name":"LIVINGSTON","code":"US-MI-XHX"},
          {"name":"LIVONIA","code":"US-MI-LVI"},
          {"name":"LOWELL","code":"US-MI-LPB"},
          {"name":"LUDINGTON","code":"US-MI-LDM"},
          {"name":"LUPTON","code":"US-MI-LP2"},
          {"name":"LYONS","code":"US-MI-ZYO"},
          {"name":"MACATAWA","code":"US-MI-ZTW"},
          {"name":"MACKINAC ISLAND","code":"US-MI-MCD"},
          {"name":"MACKINAW CITY","code":"US-MI-KWY"},
          {"name":"MACOMB","code":"US-MI-OMB"},
          {"name":"MADISON HEIGHTS","code":"US-MI-MSH"},
          {"name":"MANCELONA","code":"US-MI-M9A"},
          {"name":"MANCHESTER","code":"US-MI-MPC"},
          {"name":"MANISTEE","code":"US-MI-MBL"},
          {"name":"MANISTIQUE","code":"US-MI-ISQ"},
          {"name":"MANTON","code":"US-MI-IZM"},
          {"name":"MAPLE CITY","code":"US-MI-MQQ"},
          {"name":"MARCELLUS","code":"US-MI-MU6"},
          {"name":"MARION","code":"US-MI-M2R"},
          {"name":"MARLETTE","code":"US-MI-MQH"},
          {"name":"MARNE","code":"US-MI-QMM"},
          {"name":"MARQUETTE","code":"US-MI-MQT"},
          {"name":"MARSHALL","code":"US-MI-MHG"},
          {"name":"MARTIN","code":"US-MI-MAN"},
          {"name":"MARYSVILLE","code":"US-MI-MVV"},
          {"name":"MASON","code":"US-MI-MWJ"},
          {"name":"MATTAWAN","code":"US-MI-MNW"},
          {"name":"MAYVILLE","code":"US-MI-M3L"},
          {"name":"MCBAIN","code":"US-MI-KMZ"},
          {"name":"MECOSTA","code":"US-MI-UMQ"},
          {"name":"MELVINDALE","code":"US-MI-MGF"},
          {"name":"MEMPHIS","code":"US-MI-OMQ"},
          {"name":"MENDON","code":"US-MI-JJJ"},
          {"name":"MENOMINEE","code":"US-MI-MNM"},
          {"name":"MERRILL","code":"US-MI-MMD"},
          {"name":"MESICK","code":"US-MI-VSK"},
          {"name":"METAMORA","code":"US-MI-MM3"},
          {"name":"MICHIGAMME","code":"US-MI-MG8"},
          {"name":"MICHIGAN CENTER","code":"US-MI-MNC"},
          {"name":"MIDDLETON","code":"US-MI-DDT"},
          {"name":"MIDDLEVILLE","code":"US-MI-MVK"},
          {"name":"MIDLAND","code":"US-MI-ILD"},
          {"name":"MIDLAND-BAY CITY-SAGINAW APT","code":"US-MI-MBS"},
          {"name":"MILAN","code":"US-MI-UQZ"},
          {"name":"MILFORD","code":"US-MI-MF5"},
          {"name":"MILLINGTON","code":"US-MI-M9I"},
          {"name":"MINDEN CITY","code":"US-MI-MCY"},
          {"name":"MIO","code":"US-MI-MI2"},
          {"name":"MONROE","code":"US-MI-MOI"},
          {"name":"MONTAGUE","code":"US-MI-TGQ"},
          {"name":"MORENCI","code":"US-MI-MQR"},
          {"name":"MORRICE","code":"US-MI-XWQ"},
          {"name":"MOTTVILLE","code":"US-MI-MLL"},
          {"name":"MOUNT CLEMENS","code":"US-MI-MTC"},
          {"name":"MOUNT MORRIS","code":"US-MI-MMB"},
          {"name":"MOUNT PLEASANT","code":"US-MI-MOP"},
          {"name":"MULLIKEN","code":"US-MI-RMU"},
          {"name":"MUNGER","code":"US-MI-UEM"},
          {"name":"MUNISING","code":"US-MI-MGS"},
          {"name":"MUSKEGON","code":"US-MI-MKG"},
          {"name":"MUSKEGON HEIGHTS","code":"US-MI-5MO"},
          {"name":"NASHVILLE","code":"US-MI-VQV"},
          {"name":"NATIONAL CITY","code":"US-MI-NC2"},
          {"name":"NEW BALTIMORE","code":"US-MI-NBT"},
          {"name":"NEW BOSTON","code":"US-MI-NBW"},
          {"name":"NEW BUFFALO","code":"US-MI-NB3"},
          {"name":"NEW ERA","code":"US-MI-4NJ"},
          {"name":"NEW HUDSON","code":"US-MI-NHN"},
          {"name":"NEW TROY","code":"US-MI-XJS"},
          {"name":"NEWAYGO","code":"US-MI-NWO"},
          {"name":"NEWPORT","code":"US-MI-NEP"},
          {"name":"NILES","code":"US-MI-NLE"},
          {"name":"NORTH BRANCH","code":"US-MI-FHB"},
          {"name":"NORTH MUSKEGON","code":"US-MI-M9K"},
          {"name":"NORTHPORT","code":"US-MI-ZZH"},
          {"name":"NORTHVILLE","code":"US-MI-NTV"},
          {"name":"NORTON SHORES","code":"US-MI-NTR"},
          {"name":"NORWAY","code":"US-MI-NZO"},
          {"name":"NOVI","code":"US-MI-NOV"},
          {"name":"NUNICA","code":"US-MI-NZ9"},
          {"name":"OAK PARK, OAKLAND","code":"US-MI-OPC"},
          {"name":"OAKLEY","code":"US-MI-OK4"},
          {"name":"OAKWOOD, OAKLAND","code":"US-MI-OWU"},
          {"name":"OKEMOS","code":"US-MI-OKX"},
          {"name":"OLIVET","code":"US-MI-O7L"},
          {"name":"OMENA","code":"US-MI-O5M"},
          {"name":"ONAWAY","code":"US-MI-OW2"},
          {"name":"ONEKAMA","code":"US-MI-ONK"},
          {"name":"ONSTED","code":"US-MI-OTD"},
          {"name":"ONTONAGON","code":"US-MI-ONG"},
          {"name":"ORCHARD LAKE","code":"US-MI-OHK"},
          {"name":"ORION","code":"US-MI-O6N"},
          {"name":"ORTONVILLE","code":"US-MI-OOI"},
          {"name":"OSCODA","code":"US-MI-OSC"},
          {"name":"OSSEO","code":"US-MI-OS2"},
          {"name":"OSSINEKE","code":"US-MI-KZK"},
          {"name":"OSTHEMO","code":"US-MI-OEH"},
          {"name":"OTISVILLE","code":"US-MI-OZV"},
          {"name":"OTSEGO","code":"US-MI-TGH"},
          {"name":"OTTAWA LAKE","code":"US-MI-OTL"},
          {"name":"OVID","code":"US-MI-ZG4"},
          {"name":"OWENDALE","code":"US-MI-ODL"},
          {"name":"OWOSSO","code":"US-MI-OWO"},
          {"name":"OXFORD","code":"US-MI-OFM"},
          {"name":"PALMYRA","code":"US-MI-XPM"},
          {"name":"PARCHMENT","code":"US-MI-PZM"},
          {"name":"PARMA","code":"US-MI-PAM"},
          {"name":"PAW PAW","code":"US-MI-PPW"},
          {"name":"PECK","code":"US-MI-PK8"},
          {"name":"PELKIE","code":"US-MI-P37"},
          {"name":"PELLSTON","code":"US-MI-PXN"},
          {"name":"PENTWATER","code":"US-MI-PNP"},
          {"name":"PERRINTON","code":"US-MI-PIO"},
          {"name":"PERRY","code":"US-MI-IPE"},
          {"name":"PETERSBURG","code":"US-MI-M94"},
          {"name":"PETOSKEY","code":"US-MI-PET"},
          {"name":"PEWAMO","code":"US-MI-PZE"},
          {"name":"PIGEON","code":"US-MI-PIG"},
          {"name":"PINCONNING","code":"US-MI-NXT"},
          {"name":"PLAINWELL","code":"US-MI-PNW"},
          {"name":"PLYMOUTH","code":"US-MI-UXY"},
          {"name":"PONTIAC","code":"US-MI-PTK"},
          {"name":"PORT HURON","code":"US-MI-PHN"},
          {"name":"PORTAGE","code":"US-MI-PTE"},
          {"name":"PORTLAND","code":"US-MI-PWQ"},
          {"name":"POSEN","code":"US-MI-PS2"},
          {"name":"POTTERVILLE","code":"US-MI-VPL"},
          {"name":"PRESQUE ISLE","code":"US-MI-PSS"},
          {"name":"PRUDENVILLE","code":"US-MI-PUF"},
          {"name":"QUINCY","code":"US-MI-QUY"},
          {"name":"QUINNESEC","code":"US-MI-QN2"},
          {"name":"RALPH","code":"US-MI-JPH"},
          {"name":"RAPID CITY","code":"US-MI-IYI"},
          {"name":"RAVENNA","code":"US-MI-R8V"},
          {"name":"RAY","code":"US-MI-M59"},
          {"name":"READING","code":"US-MI-RGW"},
          {"name":"REDFORD","code":"US-MI-RDF"},
          {"name":"REED CITY","code":"US-MI-RCT"},
          {"name":"REESE","code":"US-MI-RMI"},
          {"name":"REMUS","code":"US-MI-RUQ"},
          {"name":"RICHLAND","code":"US-MI-RIQ"},
          {"name":"RICHMOND","code":"US-MI-RCN"},
          {"name":"RICHVILLE","code":"US-MI-R8M"},
          {"name":"RIGA","code":"US-MI-IG8"},
          {"name":"RILEY","code":"US-MI-R33"},
          {"name":"RIVER ROUGE","code":"US-MI-RRJ"},
          {"name":"RIVERDALE","code":"US-MI-RXE"},
          {"name":"RIVERSIDE","code":"US-MI-RVM"},
          {"name":"RIVERVIEW","code":"US-MI-RVW"},
          {"name":"RIVES JUNCTION","code":"US-MI-IEJ"},
          {"name":"ROCHESTER","code":"US-MI-RCZ"},
          {"name":"ROCHESTER HILLS/DETROIT","code":"US-MI-RHH"},
          {"name":"ROCKFORD","code":"US-MI-RCF"},
          {"name":"ROCKWOOD","code":"US-MI-RW2"},
          {"name":"ROGERS CITY","code":"US-MI-RCY"},
          {"name":"ROMEO","code":"US-MI-RMO"},
          {"name":"ROMULUS","code":"US-MI-ROU"},
          {"name":"ROSCOMMON","code":"US-MI-RCQ"},
          {"name":"ROSE CITY","code":"US-MI-REC"},
          {"name":"ROSEBUSH","code":"US-MI-B8H"},
          {"name":"ROSEVILLE","code":"US-MI-RSV"},
          {"name":"ROTHBURY","code":"US-MI-RYR"},
          {"name":"ROYAL OAK","code":"US-MI-RYM"},
          {"name":"RUDYARD","code":"US-MI-RDX"},
          {"name":"RUTH","code":"US-MI-R2H"},
          {"name":"SAGINAW","code":"US-MI-SGM"},
          {"name":"SAGINAW-MIDLAND-BAY CITY APT","code":"US-MI-MBS"},
          {"name":"SAGOLA","code":"US-MI-XSG"},
          {"name":"SAINT CHARLES","code":"US-MI-ZIB"},
          {"name":"SAINT CLAIR","code":"US-MI-SCL"},
          {"name":"SAINT CLAIR SHORES","code":"US-MI-CS4"},
          {"name":"SAINT JAMES","code":"US-MI-SJ2"},
          {"name":"SAINT JOHNS","code":"US-MI-SJ4"},
          {"name":"SAINT JOSEPH","code":"US-MI-SXX"},
          {"name":"SAINT LOUIS","code":"US-MI-SYT"},
          {"name":"SALINE","code":"US-MI-SJI"},
          {"name":"SANDUSKY","code":"US-MI-SDW"},
          {"name":"SANFORD","code":"US-MI-FU7"},
          {"name":"SARANAC","code":"US-MI-SRN"},
          {"name":"SAULT SAINTE MARIE","code":"US-MI-SSM"},
          {"name":"SAWYER","code":"US-MI-SWR"},
          {"name":"SCHOOLCRAFT","code":"US-MI-OLC"},
          {"name":"SCOTTS","code":"US-MI-XOS"},
          {"name":"SCOTTVILLE","code":"US-MI-SCN"},
          {"name":"SEARS","code":"US-MI-S3S"},
          {"name":"SEBEWAING","code":"US-MI-SWB"},
          {"name":"SHELBY","code":"US-MI-SDL"},
          {"name":"SHELBY TOWNSHIP","code":"US-MI-SN8"},
          {"name":"SHELBYVILLE","code":"US-MI-QYV"},
          {"name":"SHEPHERD","code":"US-MI-EFH"},
          {"name":"SHERIDAN","code":"US-MI-SJX"},
          {"name":"SHERWOOD","code":"US-MI-S3W"},
          {"name":"SIDNEY","code":"US-MI-IJY"},
          {"name":"SMITHS CREEK","code":"US-MI-SDF"},
          {"name":"SNOVER","code":"US-MI-V8S"},
          {"name":"SODUS","code":"US-MI-SOB"},
          {"name":"SOUTH BOARDMAN","code":"US-MI-TBM"},
          {"name":"SOUTH HAVEN","code":"US-MI-SOH"},
          {"name":"SOUTH LYON","code":"US-MI-S6L"},
          {"name":"SOUTH RANGE","code":"US-MI-SSP"},
          {"name":"SOUTH ROCKWOOD","code":"US-MI-SR3"},
          {"name":"SOUTHFIELD","code":"US-MI-SFJ"},
          {"name":"SOUTHGATE","code":"US-MI-SFA"},
          {"name":"SPARTA","code":"US-MI-SQH"},
          {"name":"SPRING ARBOR","code":"US-MI-NGA"},
          {"name":"SPRING LAKE","code":"US-MI-ZPL"},
          {"name":"SPRINGFIELD, CALHOUN","code":"US-MI-VSJ"},
          {"name":"SPRINGPORT, JACKSON","code":"US-MI-SP7"},
          {"name":"STANDISH","code":"US-MI-SH6"},
          {"name":"STANTON","code":"US-MI-7ST"},
          {"name":"STANWOOD","code":"US-MI-QTD"},
          {"name":"STEPHENSON","code":"US-MI-EPS"},
          {"name":"STERLING","code":"US-MI-SKA"},
          {"name":"STERLING HEIGHTS","code":"US-MI-SEH"},
          {"name":"STEVENSVILLE","code":"US-MI-SLW"},
          {"name":"STOCKBRIDGE","code":"US-MI-SKG"},
          {"name":"STURGIS","code":"US-MI-IRS"},
          {"name":"SUNFIELD","code":"US-MI-S5E"},
          {"name":"SUPERIOR TOWNSHIP","code":"US-MI-ST8"},
          {"name":"SUTTONS BAY","code":"US-MI-ZYY"},
          {"name":"SWARTZ CREEK","code":"US-MI-SXZ"},
          {"name":"SYLVAN LAKE","code":"US-MI-YNL"},
          {"name":"TAWAS CITY","code":"US-MI-TWY"},
          {"name":"TAYLOR","code":"US-MI-XNE"},
          {"name":"TECUMSEH","code":"US-MI-TZZ"},
          {"name":"TEKONSHA","code":"US-MI-TS2"},
          {"name":"TEMPERANCE","code":"US-MI-TEV"},
          {"name":"THOMPSONVILLE","code":"US-MI-THL"},
          {"name":"THREE OAKS","code":"US-MI-OI7"},
          {"name":"THREE RIVERS","code":"US-MI-HAI"},
          {"name":"TRAVERSE CITY","code":"US-MI-TVC"},
          {"name":"TRENTON","code":"US-MI-XNG"},
          {"name":"TROY","code":"US-MI-TYM"},
          {"name":"TWIN LAKE","code":"US-MI-TW2"},
          {"name":"UBLY","code":"US-MI-UBY"},
          {"name":"UNION","code":"US-MI-UNX"},
          {"name":"UNION CITY","code":"US-MI-UCI"},
          {"name":"UNIONVILLE","code":"US-MI-U2V"},
          {"name":"UTICA","code":"US-MI-UIZ"},
          {"name":"VAN BUREN TOWNSHIP","code":"US-MI-VBM"},
          {"name":"VANDERBILT","code":"US-MI-VDB"},
          {"name":"VASSAR","code":"US-MI-VAS"},
          {"name":"VERMILION","code":"US-MI-VR8"},
          {"name":"VERMONTVILLE","code":"US-MI-VRM"},
          {"name":"VESTABURG","code":"US-MI-VTB"},
          {"name":"VICKSBURG","code":"US-MI-VKB"},
          {"name":"WAKEFIELD","code":"US-MI-WFE"},
          {"name":"WALDRON","code":"US-MI-WDM"},
          {"name":"WALES","code":"US-MI-QLS"},
          {"name":"WALKER","code":"US-MI-LKR"},
          {"name":"WALKERVILLE","code":"US-MI-VLX"},
          {"name":"WALLACE","code":"US-MI-5WL"},
          {"name":"WALLED LAKE","code":"US-MI-WBL"},
          {"name":"WARREN","code":"US-MI-WAM"},
          {"name":"WASHINGTON","code":"US-MI-NGN"},
          {"name":"WATERFORD","code":"US-MI-WTF"},
          {"name":"WATERVLIET","code":"US-MI-WTV"},
          {"name":"WAYLAND","code":"US-MI-AYN"},
          {"name":"WAYNE","code":"US-MI-WNE"},
          {"name":"WAYNE COUNTY APT/DETROIT","code":"US-MI-DTW"},
          {"name":"WEBBERVILLE","code":"US-MI-WER"},
          {"name":"WEIDMAN","code":"US-MI-IMN"},
          {"name":"WELLS","code":"US-MI-IWU"},
          {"name":"WEST BLOOMFIELD","code":"US-MI-WBF"},
          {"name":"WEST BRANCH","code":"US-MI-WBC"},
          {"name":"WEST OLIVE","code":"US-MI-UIV"},
          {"name":"WESTLAND","code":"US-MI-WTD"},
          {"name":"WESTON","code":"US-MI-IWE"},
          {"name":"WHEELER","code":"US-MI-EEE"},
          {"name":"WHITE CLOUD","code":"US-MI-WXC"},
          {"name":"WHITE LAKE","code":"US-MI-WEK"},
          {"name":"WHITE PIGEON","code":"US-MI-WPI"},
          {"name":"WHITE PINE","code":"US-MI-ZTP"},
          {"name":"WHITEHALL","code":"US-MI-WMQ"},
          {"name":"WHITMORE LAKE","code":"US-MI-WMP"},
          {"name":"WHITTEMORE","code":"US-MI-ITM"},
          {"name":"WILLIAMSBURG","code":"US-MI-QWZ"},
          {"name":"WILLIAMSTON","code":"US-MI-IIM"},
          {"name":"WILLIS","code":"US-MI-IIS"},
          {"name":"WILLOW RUN","code":"US-MI-WRU"},
          {"name":"WILLOW RUN APT/DETROIT","code":"US-MI-YIP"},
          {"name":"WILSON","code":"US-MI-WZO"},
          {"name":"WINN","code":"US-MI-WQM"},
          {"name":"WIXOM","code":"US-MI-WIX"},
          {"name":"WOODHAVEN","code":"US-MI-DHV"},
          {"name":"WYANDOTTE","code":"US-MI-WYD"},
          {"name":"WYOMING","code":"US-MI-WYM"},
          {"name":"YALE","code":"US-MI-YAL"},
          {"name":"YPSILANTI","code":"US-MI-YPS"},
          {"name":"ZEELAND","code":"US-MI-ZLD"},
        ]
      },
      {
        name: 'Minnesota',
        code: 'US-MN',
        cities: [
          {"name":"ADA","code":"US-MN-DD2"},
          {"name":"ADRIAN","code":"US-MN-AI4"},
          {"name":"AFTON","code":"US-MN-FJT"},
          {"name":"AITKIN","code":"US-MN-KN9"},
          {"name":"ALBANY","code":"US-MN-AL2"},
          {"name":"ALBERT LEA","code":"US-MN-AEL"},
          {"name":"ALBERTVILLE","code":"US-MN-ALV"},
          {"name":"ALBORN","code":"US-MN-AO9"},
          {"name":"ALDEN","code":"US-MN-DNF"},
          {"name":"ALEXANDRIA","code":"US-MN-AXN"},
          {"name":"ALTURA","code":"US-MN-AXU"},
          {"name":"ALVARADO","code":"US-MN-AR2"},
          {"name":"ANDOVER","code":"US-MN-AD7"},
          {"name":"ANNANDALE","code":"US-MN-XAT"},
          {"name":"ANOKA","code":"US-MN-ANK"},
          {"name":"APPLE VALLEY","code":"US-MN-YAW"},
          {"name":"APPLETON","code":"US-MN-APP"},
          {"name":"ARDEN HILLS","code":"US-MN-ARH"},
          {"name":"ARLINGTON","code":"US-MN-ARE"},
          {"name":"ASHBY","code":"US-MN-HBY"},
          {"name":"ATWATER","code":"US-MN-YZY"},
          {"name":"AURORA","code":"US-MN-QQY"},
          {"name":"AUSTIN","code":"US-MN-AUM"},
          {"name":"AVON","code":"US-MN-AVF"},
          {"name":"BABBITT","code":"US-MN-2BB"},
          {"name":"BAGLEY","code":"US-MN-GAJ"},
          {"name":"BARNESVILLE","code":"US-MN-B7N"},
          {"name":"BARNUM","code":"US-MN-B3R"},
          {"name":"BAUDETTE","code":"US-MN-BDE"},
          {"name":"BAXTER","code":"US-MN-AXT"},
          {"name":"BAYPORT","code":"US-MN-BPB"},
          {"name":"BECKER","code":"US-MN-BKO"},
          {"name":"BELGRADE","code":"US-MN-OBJ"},
          {"name":"BELLE PLAINE","code":"US-MN-BP4"},
          {"name":"BEMIDJI","code":"US-MN-BJI"},
          {"name":"BENSON","code":"US-MN-BBB"},
          {"name":"BERTHA","code":"US-MN-B4T"},
          {"name":"BIG LAKE","code":"US-MN-BL4"},
          {"name":"BIG MOUNTAIN","code":"US-MN-BMO"},
          {"name":"BIGFORK","code":"US-MN-IGK"},
          {"name":"BIRD ISLAND","code":"US-MN-IUB"},
          {"name":"BIWABIK","code":"US-MN-IWX"},
          {"name":"BLACKDUCK","code":"US-MN-AFG"},
          {"name":"BLAINE","code":"US-MN-BNE"},
          {"name":"BLOMKEST","code":"US-MN-OGT"},
          {"name":"BLOOMING PRAIRIE","code":"US-MN-BPS"},
          {"name":"BLOOMINGTON","code":"US-MN-BQI"},
          {"name":"BLUE EARTH","code":"US-MN-LUF"},
          {"name":"BONGARDS","code":"US-MN-YBG"},
          {"name":"BRAINERD","code":"US-MN-BRD"},
          {"name":"BRANDON","code":"US-MN-BRP"},
          {"name":"BRECKENRIDGE","code":"US-MN-BYY"},
          {"name":"BREWSTER","code":"US-MN-6BS"},
          {"name":"BRICELYN","code":"US-MN-IYM"},
          {"name":"BRIGHTON","code":"US-MN-BN2"},
          {"name":"BROOK PARK","code":"US-MN-BPC"},
          {"name":"BROOKLYN CENTER","code":"US-MN-XCX"},
          {"name":"BROOKLYN PARK","code":"US-MN-BQP"},
          {"name":"BROOKS","code":"US-MN-B7K"},
          {"name":"BROOTEN","code":"US-MN-ZOB"},
          {"name":"BROWNS VALLEY","code":"US-MN-QVY"},
          {"name":"BROWNTON","code":"US-MN-BWW"},
          {"name":"BRUSHVALE","code":"US-MN-BCH"},
          {"name":"BUFFALO","code":"US-MN-UFL"},
          {"name":"BUFFALO LAKE","code":"US-MN-BUG"},
          {"name":"BUHL","code":"US-MN-B6L"},
          {"name":"BURNSVILLE","code":"US-MN-BYO"},
          {"name":"BYRON","code":"US-MN-YBO"},
          {"name":"CALEDONIA","code":"US-MN-EOI"},
          {"name":"CALLAWAY","code":"US-MN-KCJ"},
          {"name":"CALUMET","code":"US-MN-CZ2"},
          {"name":"CAMBRIDGE","code":"US-MN-CBD"},
          {"name":"CANBY","code":"US-MN-C4Y"},
          {"name":"CANNON FALLS","code":"US-MN-CFS"},
          {"name":"CARLOS","code":"US-MN-VAQ"},
          {"name":"CARLTON","code":"US-MN-LRH"},
          {"name":"CASTLE ROCK","code":"US-MN-LK9"},
          {"name":"CENTERVILLE","code":"US-MN-CWF"},
          {"name":"CHAMPLIN","code":"US-MN-NMH"},
          {"name":"CHANDLER","code":"US-MN-Z8R"},
          {"name":"CHANHASSEN","code":"US-MN-CHQ"},
          {"name":"CHASKA","code":"US-MN-ACM"},
          {"name":"CHATFIELD","code":"US-MN-CF7"},
          {"name":"CHISAGO CITY","code":"US-MN-HGC"},
          {"name":"CHISHOLM","code":"US-MN-QCI"},
          {"name":"CHISHOLM-HIBBING APT","code":"US-MN-HIB"},
          {"name":"CIRCLE PINES","code":"US-MN-ZLP"},
          {"name":"CLARA CITY","code":"US-MN-ZCC"},
          {"name":"CLAREMONT","code":"US-MN-C2M"},
          {"name":"CLEAR LAKE","code":"US-MN-CK7"},
          {"name":"CLEARWATER","code":"US-MN-EWG"},
          {"name":"CLINTON","code":"US-MN-CT3"},
          {"name":"CLOQUET","code":"US-MN-CQM"},
          {"name":"COHASSET","code":"US-MN-XEF"},
          {"name":"COKATO","code":"US-MN-COK"},
          {"name":"COLD SPRING","code":"US-MN-CDM"},
          {"name":"COLERAINE","code":"US-MN-OLR"},
          {"name":"COLLEGEVILLE","code":"US-MN-LGV"},
          {"name":"COLUMBIA HEIGHTS","code":"US-MN-UBQ"},
          {"name":"COLUMBUS","code":"US-MN-OB6"},
          {"name":"COOK","code":"US-MN-QCX"},
          {"name":"COON RAPIDS","code":"US-MN-XEG"},
          {"name":"CORCORAN","code":"US-MN-CO8"},
          {"name":"COTTAGE GROVE","code":"US-MN-AGG"},
          {"name":"COTTONWOOD","code":"US-MN-CZW"},
          {"name":"COURTLAND","code":"US-MN-CBR"},
          {"name":"CRANE LAKE","code":"US-MN-C8L"},
          {"name":"CROOKSTON","code":"US-MN-CKN"},
          {"name":"CROSBY","code":"US-MN-CGM"},
          {"name":"CROSSLAKE","code":"US-MN-CK4"},
          {"name":"CRYSTAL","code":"US-MN-YSA"},
          {"name":"DAKOTA","code":"US-MN-KOF"},
          {"name":"DALBO","code":"US-MN-DBO"},
          {"name":"DALTON","code":"US-MN-DT8"},
          {"name":"DANVERS","code":"US-MN-DV9"},
          {"name":"DASSEL","code":"US-MN-DA7"},
          {"name":"DAWSON","code":"US-MN-DWM"},
          {"name":"DAYTON","code":"US-MN-JYT"},
          {"name":"DEER CREEK","code":"US-MN-DR9"},
          {"name":"DEER RIVER","code":"US-MN-DMT"},
          {"name":"DEERWOOD","code":"US-MN-DWD"},
          {"name":"DELANO","code":"US-MN-DOX"},
          {"name":"DETROIT LAKES","code":"US-MN-DTL"},
          {"name":"DILWORTH","code":"US-MN-DWH"},
          {"name":"DODGE CENTER","code":"US-MN-XEU"},
          {"name":"DONALDSON","code":"US-MN-DD8"},
          {"name":"DOVER","code":"US-MN-D9V"},
          {"name":"DULUTH","code":"US-MN-DLH"},
          {"name":"DUNDAS","code":"US-MN-UDS"},
          {"name":"EAGAN","code":"US-MN-UFS"},
          {"name":"EAST GRAND FORKS","code":"US-MN-EGQ"},
          {"name":"EASTON","code":"US-MN-TKU"},
          {"name":"EDEN PRAIRIE","code":"US-MN-EDP"},
          {"name":"EDEN VALLEY","code":"US-MN-ZVY"},
          {"name":"EDGERTON","code":"US-MN-EN4"},
          {"name":"EDINA/MINNEAPOLIS","code":"US-MN-EDM"},
          {"name":"EITZEN","code":"US-MN-ITZ"},
          {"name":"ELBA","code":"US-MN-EB7"},
          {"name":"ELGIN","code":"US-MN-JGI"},
          {"name":"ELK RIVER","code":"US-MN-ER2"},
          {"name":"ELKO NEW MARKET","code":"US-MN-E5T"},
          {"name":"ELLENDALE","code":"US-MN-EJE"},
          {"name":"ELY","code":"US-MN-LYU"},
          {"name":"ELYSIAN","code":"US-MN-M7I"},
          {"name":"EMILY","code":"US-MN-EY8"},
          {"name":"ESKO","code":"US-MN-EKQ"},
          {"name":"EVELETH","code":"US-MN-EVM"},
          {"name":"EXCELSIOR","code":"US-MN-EXZ"},
          {"name":"EYOTA","code":"US-MN-E3Y"},
          {"name":"FAIRFAX","code":"US-MN-FF2"},
          {"name":"FAIRMONT","code":"US-MN-FRM"},
          {"name":"FARIBAULT","code":"US-MN-FBL"},
          {"name":"FARMINGTON","code":"US-MN-FIG"},
          {"name":"FERGUS FALLS","code":"US-MN-FFM"},
          {"name":"FIFTY LAKES","code":"US-MN-FYL"},
          {"name":"FINLAND","code":"US-MN-9FD"},
          {"name":"FLOODWOOD","code":"US-MN-FW2"},
          {"name":"FOLEY","code":"US-MN-FY2"},
          {"name":"FOREST LAKE","code":"US-MN-FSL"},
          {"name":"FORT RIPLEY","code":"US-MN-FBP"},
          {"name":"FORT SNELLING","code":"US-MN-TSN"},
          {"name":"FOSSTON","code":"US-MN-FSS"},
          {"name":"FOUNTAIN","code":"US-MN-FJN"},
          {"name":"FOXHOME","code":"US-MN-FX3"},
          {"name":"FRAZEE","code":"US-MN-FXE"},
          {"name":"FREEBORN","code":"US-MN-F3B"},
          {"name":"FREEPORT","code":"US-MN-RPO"},
          {"name":"FRIDLEY","code":"US-MN-FDU"},
          {"name":"FRONTENAC","code":"US-MN-FEQ"},
          {"name":"GARDEN CITY","code":"US-MN-GDC"},
          {"name":"GARRISON","code":"US-MN-RR7"},
          {"name":"GARY","code":"US-MN-GYX"},
          {"name":"GAYLORD","code":"US-MN-GAY"},
          {"name":"GIBBON","code":"US-MN-G2B"},
          {"name":"GILBERT","code":"US-MN-IBT"},
          {"name":"GLENCOE","code":"US-MN-GCZ"},
          {"name":"GLENVILLE","code":"US-MN-G5V"},
          {"name":"GLENWOOD","code":"US-MN-GWQ"},
          {"name":"GOLDEN VALLEY","code":"US-MN-GVY"},
          {"name":"GOOD THUNDER","code":"US-MN-3GT"},
          {"name":"GOODHUE","code":"US-MN-GMD"},
          {"name":"GRACEVILLE","code":"US-MN-GV7"},
          {"name":"GRAND MARAIS","code":"US-MN-GRM"},
          {"name":"GRAND MEADOW","code":"US-MN-GM7"},
          {"name":"GRAND PORTAGE","code":"US-MN-GRA"},
          {"name":"GRAND RAPIDS","code":"US-MN-GPZ"},
          {"name":"GRANITE FALLS","code":"US-MN-GFZ"},
          {"name":"GREEN ISLE","code":"US-MN-GIM"},
          {"name":"GREENBUSH","code":"US-MN-ZGH"},
          {"name":"GREENFIELD","code":"US-MN-EEF"},
          {"name":"GROVE CITY","code":"US-MN-VFC"},
          {"name":"HACKENSACK","code":"US-MN-HS2"},
          {"name":"HALLOCK","code":"US-MN-H3T"},
          {"name":"HAM LAKE","code":"US-MN-H7K"},
          {"name":"HAMBURG","code":"US-MN-U98"},
          {"name":"HAMEL","code":"US-MN-NHE"},
          {"name":"HAMPTON","code":"US-MN-HM6"},
          {"name":"HANCOCK","code":"US-MN-NOK"},
          {"name":"HANSKA","code":"US-MN-H5K"},
          {"name":"HARDWICK","code":"US-MN-5MN"},
          {"name":"HARMONY","code":"US-MN-M5Y"},
          {"name":"HARRIS","code":"US-MN-MZ4"},
          {"name":"HARTLAND","code":"US-MN-HL2"},
          {"name":"HASTINGS","code":"US-MN-XGR"},
          {"name":"HAWLEY","code":"US-MN-XGY"},
          {"name":"HAYFIELD","code":"US-MN-HZX"},
          {"name":"HENDERSON","code":"US-MN-H99"},
          {"name":"HENNING","code":"US-MN-HN5"},
          {"name":"HERMANTOWN","code":"US-MN-HT4"},
          {"name":"HEWITT","code":"US-MN-HWQ"},
          {"name":"HIBBING","code":"US-MN-IBB"},
          {"name":"HIBBING-CHISHOLM APT","code":"US-MN-HIB"},
          {"name":"HILL CITY","code":"US-MN-IIY"},
          {"name":"HILLS","code":"US-MN-HJI"},
          {"name":"HINES","code":"US-MN-2HM"},
          {"name":"HOKAH","code":"US-MN-HO2"},
          {"name":"HOPE","code":"US-MN-ZHZ"},
          {"name":"HOPKINS","code":"US-MN-HPO"},
          {"name":"HOUSTON","code":"US-MN-M6N"},
          {"name":"HOWARD LAKE","code":"US-MN-ZD6"},
          {"name":"HOYT LAKES","code":"US-MN-YKL"},
          {"name":"HUGO","code":"US-MN-HU9"},
          {"name":"HUTCHINSON","code":"US-MN-HCH"},
          {"name":"INTERNATIONAL FALLS","code":"US-MN-INL"},
          {"name":"INVER GROVE HEIGHTS","code":"US-MN-IVV"},
          {"name":"IRON JUNCTION","code":"US-MN-IRQ"},
          {"name":"IRONTON","code":"US-MN-IR6"},
          {"name":"ISABELLA","code":"US-MN-IB7"},
          {"name":"ISANTI","code":"US-MN-ISI"},
          {"name":"ISLE","code":"US-MN-IVA"},
          {"name":"IVANHOE","code":"US-MN-7MN"},
          {"name":"JACKSON","code":"US-MN-MJQ"},
          {"name":"JANESVILLE","code":"US-MN-JSV"},
          {"name":"JORDAN, SCOTT","code":"US-MN-JN3"},
          {"name":"KARLSTAD","code":"US-MN-KTD"},
          {"name":"KASOTA","code":"US-MN-KS2"},
          {"name":"KASSON","code":"US-MN-KOS"},
          {"name":"KEEWATIN","code":"US-MN-KT3"},
          {"name":"KELLOGG","code":"US-MN-5GL"},
          {"name":"KENNEDY","code":"US-MN-KYD"},
          {"name":"KENYON","code":"US-MN-EYO"},
          {"name":"KIESTER","code":"US-MN-K4R"},
          {"name":"KIMBALL","code":"US-MN-KM4"},
          {"name":"LAFAYETTE","code":"US-MN-FYX"},
          {"name":"LAKE BENTON","code":"US-MN-KBT"},
          {"name":"LAKE CITY","code":"US-MN-LKE"},
          {"name":"LAKE CRYSTAL","code":"US-MN-LC9"},
          {"name":"LAKE ELMO","code":"US-MN-LE2"},
          {"name":"LAKE PARK","code":"US-MN-LEK"},
          {"name":"LAKE WILSON","code":"US-MN-3LW"},
          {"name":"LAKEFIELD","code":"US-MN-8KF"},
          {"name":"LAKEVILLE","code":"US-MN-LVA"},
          {"name":"LANCASTER","code":"US-MN-LZC"},
          {"name":"LE CENTER","code":"US-MN-LC3"},
          {"name":"LE ROY","code":"US-MN-C2W"},
          {"name":"LE SUER","code":"US-MN-UQA"},
          {"name":"LE SUEUR","code":"US-MN-LUU"},
          {"name":"LESTER PRAIRIE","code":"US-MN-LSP"},
          {"name":"LINDSTROM","code":"US-MN-DTM"},
          {"name":"LINO LAKES","code":"US-MN-LLB"},
          {"name":"LITCHFIELD","code":"US-MN-LHF"},
          {"name":"LITTLE CANADA","code":"US-MN-LCD"},
          {"name":"LITTLE FALLS","code":"US-MN-LFS"},
          {"name":"LONG LAKE","code":"US-MN-LLE"},
          {"name":"LONG PRAIRIE","code":"US-MN-LLP"},
          {"name":"LONSDALE","code":"US-MN-L8D"},
          {"name":"LORETTO","code":"US-MN-LR9"},
          {"name":"LUCAN","code":"US-MN-VUC"},
          {"name":"LUTSEN","code":"US-MN-LU6"},
          {"name":"LUVERNE","code":"US-MN-UVN"},
          {"name":"MADELIA","code":"US-MN-MDA"},
          {"name":"MADISON","code":"US-MN-MA8"},
          {"name":"MAGNOLIA","code":"US-MN-M4Z"},
          {"name":"MAHNOMEN","code":"US-MN-MA5"},
          {"name":"MAHTOMEDI","code":"US-MN-MM8"},
          {"name":"MANKATO","code":"US-MN-MKT"},
          {"name":"MANTORVILLE","code":"US-MN-HY3"},
          {"name":"MAPLE GROVE","code":"US-MN-XMG"},
          {"name":"MAPLE ISLAND","code":"US-MN-MIM"},
          {"name":"MAPLE LAKE","code":"US-MN-YWO"},
          {"name":"MAPLE PLAIN","code":"US-MN-MP4"},
          {"name":"MAPLETON","code":"US-MN-XUY"},
          {"name":"MAPLEWOOD","code":"US-MN-NPQ"},
          {"name":"MARSHALL","code":"US-MN-MML"},
          {"name":"MAYER","code":"US-MN-YMM"},
          {"name":"MAYNARD","code":"US-MN-MYB"},
          {"name":"MCGREGOR","code":"US-MN-MG3"},
          {"name":"MEDFORD","code":"US-MN-ME4"},
          {"name":"MEDINA","code":"US-MN-NMZ"},
          {"name":"MELROSE","code":"US-MN-MMZ"},
          {"name":"MENAHGA","code":"US-MN-XXG"},
          {"name":"MENDOTA HEIGHTS","code":"US-MN-MDG"},
          {"name":"MENTOR","code":"US-MN-ZTO"},
          {"name":"MERIDEN","code":"US-MN-EIQ"},
          {"name":"MERRIFIELD","code":"US-MN-MZR"},
          {"name":"MILACA","code":"US-MN-ML4"},
          {"name":"MILTONA","code":"US-MN-M8M"},
          {"name":"MINNEAPOLIS","code":"US-MN-MES"},
          {"name":"MINNEAPOLIS/ST PAUL APT","code":"US-MN-MSP"},
          {"name":"MINNEOTA","code":"US-MN-IJT"},
          {"name":"MINNESOTA CITY","code":"US-MN-M84"},
          {"name":"MINNETONKA","code":"US-MN-MKA"},
          {"name":"MONTEVIDEO","code":"US-MN-MVE"},
          {"name":"MONTGOMERY","code":"US-MN-MGH"},
          {"name":"MONTICELLO","code":"US-MN-OTC"},
          {"name":"MONTROSE","code":"US-MN-ME3"},
          {"name":"MOORHEAD","code":"US-MN-XIH"},
          {"name":"MORA","code":"US-MN-MJM"},
          {"name":"MORRIS","code":"US-MN-MOX"},
          {"name":"MORRISTOWN","code":"US-MN-MW8"},
          {"name":"MORTON","code":"US-MN-JMN"},
          {"name":"MOTLEY","code":"US-MN-MOF"},
          {"name":"MOUND","code":"US-MN-OUD"},
          {"name":"MOUNDS VIEW","code":"US-MN-MJO"},
          {"name":"MOUNTAIN IRON","code":"US-MN-UII"},
          {"name":"MOUNTAIN LAKE","code":"US-MN-ML5"},
          {"name":"MURDOCK","code":"US-MN-UDK"},
          {"name":"NASHWAUK","code":"US-MN-DHW"},
          {"name":"NERSTRAND","code":"US-MN-OZX"},
          {"name":"NEVIS","code":"US-MN-ZVI"},
          {"name":"NEW AUBURN","code":"US-MN-AU4"},
          {"name":"NEW BRIGHTON","code":"US-MN-NBI"},
          {"name":"NEW GERMANY","code":"US-MN-NG6"},
          {"name":"NEW HOPE","code":"US-MN-NHO"},
          {"name":"NEW PRAGUE","code":"US-MN-NWP"},
          {"name":"NEW ULM","code":"US-MN-ULM"},
          {"name":"NEW YORK MILLS","code":"US-MN-NYQ"},
          {"name":"NEWPORT","code":"US-MN-NPM"},
          {"name":"NICOLLET","code":"US-MN-NQL"},
          {"name":"NORCROSS","code":"US-MN-NS9"},
          {"name":"NORTH BRANCH","code":"US-MN-NB2"},
          {"name":"NORTH MANKATO","code":"US-MN-NKA"},
          {"name":"NORTH OAKS","code":"US-MN-N9R"},
          {"name":"NORTHFIELD","code":"US-MN-NRE"},
          {"name":"NORWOOD","code":"US-MN-YNW"},
          {"name":"NOYES","code":"US-MN-NOY"},
          {"name":"OAK PARK HEIGHTS","code":"US-MN-OPJ"},
          {"name":"OAKDALE","code":"US-MN-OKE"},
          {"name":"ODESSA","code":"US-MN-O82"},
          {"name":"OGILVIE","code":"US-MN-OGF"},
          {"name":"OLIVIA","code":"US-MN-OIV"},
          {"name":"ONAMIA","code":"US-MN-ZNI"},
          {"name":"ORONO","code":"US-MN-R3N"},
          {"name":"ORONOCO","code":"US-MN-OO8"},
          {"name":"OSLO","code":"US-MN-OSL"},
          {"name":"OSSEO","code":"US-MN-OXS"},
          {"name":"OTSEGO","code":"US-MN-UY7"},
          {"name":"OTTAWA","code":"US-MN-W8T"},
          {"name":"OWATONNA","code":"US-MN-OWA"},
          {"name":"PALISADE","code":"US-MN-PDJ"},
          {"name":"PARK RAPIDS","code":"US-MN-PKD"},
          {"name":"PAYNESVILLE","code":"US-MN-PYW"},
          {"name":"PEASE","code":"US-MN-XPS"},
          {"name":"PELICAN RAPIDS","code":"US-MN-IPI"},
          {"name":"PENNOCK","code":"US-MN-P2K"},
          {"name":"PEQUOT LAKES","code":"US-MN-PQK"},
          {"name":"PERHAM","code":"US-MN-XKJ"},
          {"name":"PIERZ","code":"US-MN-RZB"},
          {"name":"PILLAGER","code":"US-MN-PGX"},
          {"name":"PINE CITY","code":"US-MN-PNY"},
          {"name":"PINE ISLAND","code":"US-MN-YPI"},
          {"name":"PINE RIVER","code":"US-MN-PJR"},
          {"name":"PINECREEK","code":"US-MN-PIN"},
          {"name":"PIPESTONE","code":"US-MN-PPE"},
          {"name":"PLAINVIEW","code":"US-MN-PVN"},
          {"name":"PLATO","code":"US-MN-PZD"},
          {"name":"PLYMOUTH","code":"US-MN-PYO"},
          {"name":"PONSFORD","code":"US-MN-PO7"},
          {"name":"PORTER","code":"US-MN-EQR"},
          {"name":"PRESTON","code":"US-MN-RET"},
          {"name":"PRINCETON","code":"US-MN-XKH"},
          {"name":"PRINSBURG","code":"US-MN-PRU"},
          {"name":"PRIOR LAKE","code":"US-MN-PKE"},
          {"name":"PROCTOR","code":"US-MN-PC7"},
          {"name":"RAINIER","code":"US-MN-RN3"},
          {"name":"RAMSEY","code":"US-MN-QNS"},
          {"name":"RANDOLPH","code":"US-MN-RD8"},
          {"name":"RANIER","code":"US-MN-IFR"},
          {"name":"RAYMOND","code":"US-MN-RMB"},
          {"name":"RED LAKE FALLS","code":"US-MN-JDL"},
          {"name":"RED WING","code":"US-MN-RDW"},
          {"name":"REDBY","code":"US-MN-R4B"},
          {"name":"REDWOOD FALLS","code":"US-MN-RWF"},
          {"name":"RENVILLE","code":"US-MN-JE7"},
          {"name":"RICE","code":"US-MN-RC3"},
          {"name":"RICHFIELD","code":"US-MN-RCM"},
          {"name":"RICHMOND","code":"US-MN-RMF"},
          {"name":"RIVERTON","code":"US-MN-RV6"},
          {"name":"ROBBINSDALE","code":"US-MN-RB7"},
          {"name":"ROCHESTER","code":"US-MN-RST"},
          {"name":"ROCKFORD","code":"US-MN-KOD"},
          {"name":"ROCKVILLE","code":"US-MN-R79"},
          {"name":"ROGERS","code":"US-MN-RJM"},
          {"name":"ROSEAU","code":"US-MN-ROX"},
          {"name":"ROSEMOUNT","code":"US-MN-RMM"},
          {"name":"ROSEVILLE","code":"US-MN-RVE"},
          {"name":"ROUND LAKE","code":"US-MN-RL2"},
          {"name":"ROYALTON","code":"US-MN-YAA"},
          {"name":"RUSH CITY","code":"US-MN-RHZ"},
          {"name":"RUSHFORD","code":"US-MN-RUF"},
          {"name":"RUSSELL","code":"US-MN-UED"},
          {"name":"SABIN","code":"US-MN-4IN"},
          {"name":"SACRED HEART","code":"US-MN-DHR"},
          {"name":"SAGINAW","code":"US-MN-GS3"},
          {"name":"SAINT ANTHONY","code":"US-MN-OSJ"},
          {"name":"SAINT AUGUSTA","code":"US-MN-S2U"},
          {"name":"SAINT BONIFACIUS","code":"US-MN-IFC"},
          {"name":"SAINT CHARLES","code":"US-MN-AAS"},
          {"name":"SAINT CLOUD","code":"US-MN-STC"},
          {"name":"SAINT HILAIRE","code":"US-MN-ZHI"},
          {"name":"SAINT JAMES","code":"US-MN-SJA"},
          {"name":"SAINT JOSEPH","code":"US-MN-SJP"},
          {"name":"SAINT LOUIS PARK","code":"US-MN-SLZ"},
          {"name":"SAINT MARTIN","code":"US-MN-SJ5"},
          {"name":"SAINT MICHAEL","code":"US-MN-TMI"},
          {"name":"SAINT PAUL","code":"US-MN-STP"},
          {"name":"SAINT PAUL PARK","code":"US-MN-S67"},
          {"name":"SAINT PETER","code":"US-MN-SIP"},
          {"name":"SAINT VINCENT","code":"US-MN-8VN"},
          {"name":"SANDSTONE","code":"US-MN-ZNS"},
          {"name":"SARATOGA","code":"US-MN-SRB"},
          {"name":"SARTELL","code":"US-MN-SL4"},
          {"name":"SAUK CENTRE","code":"US-MN-SC2"},
          {"name":"SAUK RAPIDS","code":"US-MN-SRA"},
          {"name":"SAVAGE","code":"US-MN-XLY"},
          {"name":"SAWYER","code":"US-MN-YXS"},
          {"name":"SCANLON","code":"US-MN-SC9"},
          {"name":"SHAFER","code":"US-MN-SF7"},
          {"name":"SHAKOPEE","code":"US-MN-SKE"},
          {"name":"SHOREVIEW","code":"US-MN-SHB"},
          {"name":"SHOREWOOD","code":"US-MN-DO9"},
          {"name":"SILVER BAY","code":"US-MN-SIB"},
          {"name":"SLAYTON","code":"US-MN-SY8"},
          {"name":"SLEEPY EYE","code":"US-MN-SYE"},
          {"name":"SOLWAY","code":"US-MN-L4W"},
          {"name":"SOUTH HAVEN","code":"US-MN-H77"},
          {"name":"SOUTH SAINT PAUL","code":"US-MN-SPT"},
          {"name":"SPICER","code":"US-MN-8SP"},
          {"name":"SPRING GROVE","code":"US-MN-PVM"},
          {"name":"SPRING LAKE PARK","code":"US-MN-RLH"},
          {"name":"SPRING PARK","code":"US-MN-SOS"},
          {"name":"SPRING VALLEY","code":"US-MN-JVJ"},
          {"name":"SPRINGFIELD","code":"US-MN-SNF"},
          {"name":"STACY","code":"US-MN-KSY"},
          {"name":"STANTON","code":"US-MN-SYN"},
          {"name":"STAPLES","code":"US-MN-VVK"},
          {"name":"STARBUCK","code":"US-MN-9SB"},
          {"name":"STEPHEN","code":"US-MN-ZSP"},
          {"name":"STEWART, MCLEOD","code":"US-MN-TWM"},
          {"name":"STEWARTVILLE","code":"US-MN-SV7"},
          {"name":"STILLWATER","code":"US-MN-TIS"},
          {"name":"STORDEN","code":"US-MN-5DR"},
          {"name":"SWANVILLE","code":"US-MN-S3V"},
          {"name":"TACONITE HARBOR","code":"US-MN-TCH"},
          {"name":"TAOPI","code":"US-MN-TQX"},
          {"name":"THIEF RIVER FALLS","code":"US-MN-TVF"},
          {"name":"TOWER","code":"US-MN-TW8"},
          {"name":"TRACY","code":"US-MN-TY2"},
          {"name":"TRUMAN","code":"US-MN-TUA"},
          {"name":"TWO HARBORS","code":"US-MN-THB"},
          {"name":"TYLER","code":"US-MN-TLX"},
          {"name":"VADNAIS HEIGHTS","code":"US-MN-VSH"},
          {"name":"VERMILLION","code":"US-MN-QVN"},
          {"name":"VESELI","code":"US-MN-V2L"},
          {"name":"VESTA","code":"US-MN-VEU"},
          {"name":"VILLARD","code":"US-MN-QID"},
          {"name":"VIRGINIA","code":"US-MN-VGI"},
          {"name":"WABASHA","code":"US-MN-WZA"},
          {"name":"WABASSO","code":"US-MN-WBA"},
          {"name":"WACONIA","code":"US-MN-WQO"},
          {"name":"WADENA","code":"US-MN-WNQ"},
          {"name":"WAITE PARK","code":"US-MN-ITP"},
          {"name":"WALKER","code":"US-MN-QWK"},
          {"name":"WANAMINGO","code":"US-MN-WAN"},
          {"name":"WARREN","code":"US-MN-WA9"},
          {"name":"WARROAD","code":"US-MN-RRT"},
          {"name":"WASECA","code":"US-MN-WKA"},
          {"name":"WATKINS","code":"US-MN-WKI"},
          {"name":"WATSON","code":"US-MN-S4O"},
          {"name":"WAVERLY","code":"US-MN-WY8"},
          {"name":"WAYZATA","code":"US-MN-YWZ"},
          {"name":"WEBSTER","code":"US-MN-YWB"},
          {"name":"WELCH","code":"US-MN-W8H"},
          {"name":"WELLS","code":"US-MN-WS7"},
          {"name":"WEST CONCORD","code":"US-MN-W9C"},
          {"name":"WEST SAINT PAUL","code":"US-MN-WPU"},
          {"name":"WHALAN","code":"US-MN-W2Q"},
          {"name":"WHEATON","code":"US-MN-W3H"},
          {"name":"WHITE BEAR LAKE","code":"US-MN-WTE"},
          {"name":"WILLIAMS","code":"US-MN-IMS"},
          {"name":"WILLMAR","code":"US-MN-ILL"},
          {"name":"WINDOM","code":"US-MN-MWM"},
          {"name":"WINGER","code":"US-MN-WJ2"},
          {"name":"WINNEBAGO, FARIBAULT","code":"US-MN-WGB"},
          {"name":"WINONA","code":"US-MN-ONA"},
          {"name":"WINSTED","code":"US-MN-WJN"},
          {"name":"WINTHROP","code":"US-MN-WIG"},
          {"name":"WINTON","code":"US-MN-5WT"},
          {"name":"WOODBURY","code":"US-MN-WDY"},
          {"name":"WORTHINGTON","code":"US-MN-OTG"},
          {"name":"WRENSHALL","code":"US-MN-WLL"},
          {"name":"WYKOFF","code":"US-MN-KFF"},
          {"name":"WYOMING","code":"US-MN-YZM"},
          {"name":"YOUNG AMERICA","code":"US-MN-YAC"},
          {"name":"ZIMMERMAN","code":"US-MN-ZIE"},
          {"name":"ZUMBROTA","code":"US-MN-ZUM"},
        ]
      },
      {
        name: 'Misisipi',
        code: 'US-MS',
        cities: [
          {"name":"ABBEVILLE","code":"US-MS-AV4"},
          {"name":"ABERDEEN","code":"US-MS-AEK"},
          {"name":"ACKERMAN","code":"US-MS-UAG"},
          {"name":"ALGOMA","code":"US-MS-AMZ"},
          {"name":"AMORY","code":"US-MS-ARY"},
          {"name":"ANGUILLA","code":"US-MS-RFK"},
          {"name":"ARTESIA","code":"US-MS-AJI"},
          {"name":"ASHLAND","code":"US-MS-AXM"},
          {"name":"BALDWYN","code":"US-MS-BWU"},
          {"name":"BALLGROUND","code":"US-MS-GXD"},
          {"name":"BASSFIELD","code":"US-MS-BD4"},
          {"name":"BATESVILLE","code":"US-MS-BVZ"},
          {"name":"BAY SAINT LOUIS","code":"US-MS-BSL"},
          {"name":"BAY SPRINGS","code":"US-MS-YIN"},
          {"name":"BELDEN","code":"US-MS-YBD"},
          {"name":"BELLEFONTAINE","code":"US-MS-BGJ"},
          {"name":"BELMONT","code":"US-MS-BMM"},
          {"name":"BELZONI","code":"US-MS-BEZ"},
          {"name":"BENTON","code":"US-MS-BM5"},
          {"name":"BILOXI","code":"US-MS-BIX"},
          {"name":"BLUE MOUNTAIN","code":"US-MS-XCN"},
          {"name":"BLUE SPRINGS","code":"US-MS-BPR"},
          {"name":"BOGUE CHITTO","code":"US-MS-BC4"},
          {"name":"BOLTON","code":"US-MS-OTX"},
          {"name":"BOONEVILLE","code":"US-MS-BOO"},
          {"name":"BOYLE","code":"US-MS-OYL"},
          {"name":"BRANDON","code":"US-MS-BHF"},
          {"name":"BRAXTON","code":"US-MS-XTB"},
          {"name":"BROOKHAVEN","code":"US-MS-BKN"},
          {"name":"BROOKLYN","code":"US-MS-VBJ"},
          {"name":"BROOKSVILLE","code":"US-MS-KSL"},
          {"name":"BRUCE","code":"US-MS-BCZ"},
          {"name":"BUDE","code":"US-MS-BUD"},
          {"name":"BURNSVILLE","code":"US-MS-XUV"},
          {"name":"BYHALIA","code":"US-MS-BHA"},
          {"name":"BYRAM","code":"US-MS-OBM"},
          {"name":"CALEDONIA","code":"US-MS-C2Q"},
          {"name":"CALHOUN CITY","code":"US-MS-CCI"},
          {"name":"CAMBRIDGE","code":"US-MS-CMB"},
          {"name":"CAMP SHELBY","code":"US-MS-CY7"},
          {"name":"CANTON","code":"US-MS-CNP"},
          {"name":"CARRIERE","code":"US-MS-TKX"},
          {"name":"CARSON","code":"US-MS-RNM"},
          {"name":"CARTHAGE","code":"US-MS-CGT"},
          {"name":"CENTERVILLE","code":"US-MS-VLB"},
          {"name":"CENTREVILLE","code":"US-MS-CE4"},
          {"name":"CHARLESTON","code":"US-MS-HRS"},
          {"name":"CHOCTAW","code":"US-MS-CKW"},
          {"name":"CLARKSDALE","code":"US-MS-CKM"},
          {"name":"CLEVELAND","code":"US-MS-CVD"},
          {"name":"CLINTON","code":"US-MS-CKC"},
          {"name":"COFFEEVILLE","code":"US-MS-9FE"},
          {"name":"COLDWATER","code":"US-MS-CWP"},
          {"name":"COLDWATER","code":"US-MS-QIM"},
          {"name":"COLLINS","code":"US-MS-CNA"},
          {"name":"COLUMBIA","code":"US-MS-CFM"},
          {"name":"COLUMBUS","code":"US-MS-UBS"},
          {"name":"COMO","code":"US-MS-QOM"},
          {"name":"CORINTH","code":"US-MS-CRX"},
          {"name":"CRENSHAW","code":"US-MS-RHA"},
          {"name":"CRYSTAL SPRINGS","code":"US-MS-CYH"},
          {"name":"DE KALB","code":"US-MS-DKL"},
          {"name":"DELISLE","code":"US-MS-DLE"},
          {"name":"DERMA","code":"US-MS-DIM"},
          {"name":"DIAMONDHEAD","code":"US-MS-DD4"},
          {"name":"DIBERVILLE","code":"US-MS-DBE"},
          {"name":"DREW","code":"US-MS-DRM"},
          {"name":"DUBLIN","code":"US-MS-6UB"},
          {"name":"DUMAS","code":"US-MS-DS2"},
          {"name":"DURANT","code":"US-MS-URA"},
          {"name":"ECRU","code":"US-MS-EQU"},
          {"name":"EDWARDS","code":"US-MS-EGD"},
          {"name":"ELLISVILLE","code":"US-MS-EVE"},
          {"name":"ENTERPRISE","code":"US-MS-EPP"},
          {"name":"ETTA","code":"US-MS-E3T"},
          {"name":"EUPORA","code":"US-MS-EUP"},
          {"name":"FALKNER","code":"US-MS-XFN"},
          {"name":"FAYETTE","code":"US-MS-YET"},
          {"name":"FERNWOOD","code":"US-MS-FIQ"},
          {"name":"FLORA","code":"US-MS-FOM"},
          {"name":"FLORENCE","code":"US-MS-FRC"},
          {"name":"FLOWOOD","code":"US-MS-FWD"},
          {"name":"FOREST","code":"US-MS-FTI"},
          {"name":"FOXWORTH","code":"US-MS-FX2"},
          {"name":"FULTON","code":"US-MS-FTZ"},
          {"name":"FURRS","code":"US-MS-RRF"},
          {"name":"GALLMAN","code":"US-MS-GMX"},
          {"name":"GAUTIER","code":"US-MS-GAU"},
          {"name":"GLOSTER","code":"US-MS-GLU"},
          {"name":"GLUCKSTADT","code":"US-MS-GKU"},
          {"name":"GOLDEN","code":"US-MS-GDO"},
          {"name":"GREENFIELD","code":"US-MS-GFM"},
          {"name":"GREENVILLE","code":"US-MS-GLH"},
          {"name":"GREENWOOD","code":"US-MS-GWO"},
          {"name":"GRENADA","code":"US-MS-UJH"},
          {"name":"GULFPORT","code":"US-MS-GPT"},
          {"name":"GUNTOWN","code":"US-MS-GTO"},
          {"name":"HAMILTON","code":"US-MS-HMZ"},
          {"name":"HATTIESBURG","code":"US-MS-HBG"},
          {"name":"HAZLEHURST","code":"US-MS-HZU"},
          {"name":"HERNANDO","code":"US-MS-HND"},
          {"name":"HICKORY","code":"US-MS-HK7"},
          {"name":"HICKORY FLAT, BENTON","code":"US-MS-YHI"},
          {"name":"HICKORY FLAT, CHICASAW","code":"US-MS-HYF"},
          {"name":"HOLLANDALE","code":"US-MS-OAD"},
          {"name":"HOLLY RIDGE","code":"US-MS-HOG"},
          {"name":"HOLLY SPRINGS, MARSHALL","code":"US-MS-HS7"},
          {"name":"HORN LAKE","code":"US-MS-HQA"},
          {"name":"HOULKA","code":"US-MS-HKQ"},
          {"name":"HOUSTON","code":"US-MS-HSM"},
          {"name":"INDIANOLA","code":"US-MS-IDN"},
          {"name":"INVERNESS","code":"US-MS-IVR"},
          {"name":"ISOLA","code":"US-MS-ISA"},
          {"name":"ITTA BENA","code":"US-MS-IBA"},
          {"name":"IUKA","code":"US-MS-IUK"},
          {"name":"JACKSON","code":"US-MS-JAN"},
          {"name":"JEFFERSON","code":"US-MS-JFL"},
          {"name":"JONESTOWN","code":"US-MS-JWM"},
          {"name":"KILN","code":"US-MS-KIU"},
          {"name":"KOSCIUSKO","code":"US-MS-OSX"},
          {"name":"LACKEY","code":"US-MS-YLC"},
          {"name":"LAKE CORMORANT","code":"US-MS-KRM"},
          {"name":"LAMONT","code":"US-MS-LM2"},
          {"name":"LAUDERDALE","code":"US-MS-M7L"},
          {"name":"LAUREL","code":"US-MS-LUL"},
          {"name":"LELAND","code":"US-MS-LLD"},
          {"name":"LENA","code":"US-MS-LN3"},
          {"name":"LEXINGTON","code":"US-MS-LXO"},
          {"name":"LIBERTY","code":"US-MS-7IB"},
          {"name":"LONG BEACH","code":"US-MS-LBH"},
          {"name":"LOUIN","code":"US-MS-OIJ"},
          {"name":"LOUISVILLE","code":"US-MS-LMS"},
          {"name":"LUCEDALE","code":"US-MS-LCU"},
          {"name":"LUDLOW","code":"US-MS-ULD"},
          {"name":"LUMBERTON","code":"US-MS-LBS"},
          {"name":"LUTHER","code":"US-MS-LS9"},
          {"name":"LYON","code":"US-MS-9LY"},
          {"name":"MABEN","code":"US-MS-MBX"},
          {"name":"MACON","code":"US-MS-9MC"},
          {"name":"MADISON","code":"US-MS-MSM"},
          {"name":"MAGEE","code":"US-MS-QME"},
          {"name":"MAGNOLIA","code":"US-MS-MN8"},
          {"name":"MANTACHIE","code":"US-MS-ATI"},
          {"name":"MANTEE","code":"US-MS-MTZ"},
          {"name":"MARKS","code":"US-MS-MMS"},
          {"name":"MATHISTON","code":"US-MS-MI6"},
          {"name":"MAYERSVILLE","code":"US-MS-M2Y"},
          {"name":"MAYHEW","code":"US-MS-MH3"},
          {"name":"MCCOMB","code":"US-MS-MCB"},
          {"name":"MEMPHIS","code":"US-MS-EI2"},
          {"name":"MENDENHALL","code":"US-MS-QMQ"},
          {"name":"MERIDIAN","code":"US-MS-MEI"},
          {"name":"MINERAL WELLS","code":"US-MS-W37"},
          {"name":"MITCHELL","code":"US-MS-MC9"},
          {"name":"MONEY","code":"US-MS-OES"},
          {"name":"MONTICELLO","code":"US-MS-MCZ"},
          {"name":"MORTON","code":"US-MS-MNQ"},
          {"name":"MOSELLE","code":"US-MS-OEL"},
          {"name":"MOSS POINT","code":"US-MS-MOG"},
          {"name":"MOUNT OLIVE","code":"US-MS-FVS"},
          {"name":"MYRTLE","code":"US-MS-XJL"},
          {"name":"NATCHEZ","code":"US-MS-HEZ"},
          {"name":"NEOSHO","code":"US-MS-NE9"},
          {"name":"NESBIT","code":"US-MS-NXZ"},
          {"name":"NETTLETON","code":"US-MS-NTO"},
          {"name":"NEW ALBANY","code":"US-MS-NAM"},
          {"name":"NEW AUGUSTA","code":"US-MS-NA2"},
          {"name":"NEWTON","code":"US-MS-NTM"},
          {"name":"NICHOLSON","code":"US-MS-NI8"},
          {"name":"NOXAPATER","code":"US-MS-NXP"},
          {"name":"OAKLAND","code":"US-MS-OQC"},
          {"name":"OCEAN SPRINGS","code":"US-MS-OSP"},
          {"name":"OKOLONA","code":"US-MS-OKA"},
          {"name":"OLIVE BRANCH","code":"US-MS-OLV"},
          {"name":"OXFORD","code":"US-MS-UOX"},
          {"name":"PACHUTA","code":"US-MS-PCW"},
          {"name":"PASCAGOULA","code":"US-MS-PGL"},
          {"name":"PASS CHRISTIAN","code":"US-MS-PCM"},
          {"name":"PATEL","code":"US-MS-5LT"},
          {"name":"PEARL","code":"US-MS-PES"},
          {"name":"PEARLINGTON","code":"US-MS-PTX"},
          {"name":"PELAHATCHIE","code":"US-MS-PHE"},
          {"name":"PERKINSTON","code":"US-MS-KDS"},
          {"name":"PETAL","code":"US-MS-PT4"},
          {"name":"PHILADELPHIA","code":"US-MS-PXX"},
          {"name":"PICAYUNE","code":"US-MS-PCU"},
          {"name":"PLANTERSVILLE","code":"US-MS-LRL"},
          {"name":"POCAHONTAS","code":"US-MS-PH2"},
          {"name":"PONTOTOC","code":"US-MS-POK"},
          {"name":"POPE","code":"US-MS-PMP"},
          {"name":"POPLARVILLE","code":"US-MS-YPV"},
          {"name":"PORT BIENVILLE","code":"US-MS-PBW"},
          {"name":"PORT GIBSON","code":"US-MS-PGB"},
          {"name":"PRAIRIE","code":"US-MS-PR6"},
          {"name":"PUCKETT","code":"US-MS-KKX"},
          {"name":"PURVIS","code":"US-MS-PVR"},
          {"name":"QUITMAN","code":"US-MS-QMN"},
          {"name":"RANDOLPH","code":"US-MS-RLP"},
          {"name":"RAYMOND","code":"US-MS-UYG"},
          {"name":"REDWOOD","code":"US-MS-DWR"},
          {"name":"RICHLAND","code":"US-MS-RJL"},
          {"name":"RICHTON","code":"US-MS-RC9"},
          {"name":"RIDGELAND","code":"US-MS-RDE"},
          {"name":"RIENZI","code":"US-MS-RZI"},
          {"name":"RIPLEY","code":"US-MS-RPM"},
          {"name":"ROBINSONVILLE","code":"US-MS-NSO"},
          {"name":"ROLLING FORK","code":"US-MS-RFY"},
          {"name":"ROSEDALE","code":"US-MS-RAE"},
          {"name":"RULEVILLE","code":"US-MS-RVI"},
          {"name":"SALLIS","code":"US-MS-SIZ"},
          {"name":"SALTILLO","code":"US-MS-SLL"},
          {"name":"SANDY HOOK","code":"US-MS-SO8"},
          {"name":"SARDIS","code":"US-MS-SSR"},
          {"name":"SAUCIER","code":"US-MS-S2R"},
          {"name":"SCOOBA","code":"US-MS-M8S"},
          {"name":"SCOTT","code":"US-MS-XOT"},
          {"name":"SEBASTOPOL","code":"US-MS-XLO"},
          {"name":"SEMINARY","code":"US-MS-EIY"},
          {"name":"SENATOBIA","code":"US-MS-SEN"},
          {"name":"SHANNON","code":"US-MS-SZN"},
          {"name":"SHAW","code":"US-MS-JXS"},
          {"name":"SHERMAN","code":"US-MS-SZM"},
          {"name":"SHIP ISLAND","code":"US-MS-IPX"},
          {"name":"SHUBUTA","code":"US-MS-SUB"},
          {"name":"SHUQUALAK","code":"US-MS-HQK"},
          {"name":"SIDON","code":"US-MS-IDM"},
          {"name":"SILVER CREEK","code":"US-MS-HQ6"},
          {"name":"SLEDGE","code":"US-MS-FLQ"},
          {"name":"SMITHVILLE","code":"US-MS-XT8"},
          {"name":"SOUTH HAVEN","code":"US-MS-UYT"},
          {"name":"SOUTHAVEN","code":"US-MS-SVQ"},
          {"name":"SOUTHHAVEN","code":"US-MS-HH7"},
          {"name":"STAR","code":"US-MS-QSA"},
          {"name":"STARKVILLE","code":"US-MS-SXK"},
          {"name":"STENNIS SPACE CENTER","code":"US-MS-TST"},
          {"name":"STONEVILLE","code":"US-MS-STV"},
          {"name":"STONEWALL","code":"US-MS-SWL"},
          {"name":"STURGIS","code":"US-MS-S5G"},
          {"name":"SUMMIT","code":"US-MS-2MM"},
          {"name":"SUMNER","code":"US-MS-S5U"},
          {"name":"SUMRALL, LAMAR","code":"US-MS-UAL"},
          {"name":"SUNFLOWER","code":"US-MS-JWR"},
          {"name":"TAYLOR","code":"US-MS-YLJ"},
          {"name":"TAYLORSVILLE","code":"US-MS-TYV"},
          {"name":"TCHULA","code":"US-MS-UTA"},
          {"name":"TERRY","code":"US-MS-QTY"},
          {"name":"TILLATOBA","code":"US-MS-IAT"},
          {"name":"TISHOMINGO","code":"US-MS-THJ"},
          {"name":"TREMONT","code":"US-MS-TMX"},
          {"name":"TRINITY","code":"US-MS-TT7"},
          {"name":"TROY","code":"US-MS-TYI"},
          {"name":"TUNICA","code":"US-MS-TNI"},
          {"name":"TUPELO","code":"US-MS-TUP"},
          {"name":"TUSCAN","code":"US-MS-TS8"},
          {"name":"TUTWILER","code":"US-MS-TUT"},
          {"name":"TYLERTOWN","code":"US-MS-TTQ"},
          {"name":"UNION","code":"US-MS-UNH"},
          {"name":"UNION, NESHABOA","code":"US-MS-XUI"},
          {"name":"UTICA","code":"US-MS-UCX"},
          {"name":"VAIDEN","code":"US-MS-VDN"},
          {"name":"VALLEY PARK","code":"US-MS-VY7"},
          {"name":"VANCLEAVE","code":"US-MS-VLV"},
          {"name":"VARDAMAN","code":"US-MS-VDW"},
          {"name":"VERNON","code":"US-MS-VM6"},
          {"name":"VERONA","code":"US-MS-VNA"},
          {"name":"VICKSBURG","code":"US-MS-VKS"},
          {"name":"WALLS","code":"US-MS-WLS"},
          {"name":"WALNUT GROVE","code":"US-MS-WAG"},
          {"name":"WATER VALLEY","code":"US-MS-WVA"},
          {"name":"WAVELAND","code":"US-MS-VOD"},
          {"name":"WAYNESBORO","code":"US-MS-WGS"},
          {"name":"WESSON","code":"US-MS-W2S"},
          {"name":"WEST POINT","code":"US-MS-WPM"},
          {"name":"WIGGINS","code":"US-MS-GGI"},
          {"name":"WINONA","code":"US-MS-WN7"},
          {"name":"WOODLAND","code":"US-MS-8PL"},
          {"name":"WOODVILLE","code":"US-MS-WOE"},
          {"name":"WREN","code":"US-MS-WN2"},
          {"name":"YAZOO CITY","code":"US-MS-YAZ"},
        ]
      },
      {
        name: 'Misuri',
        code: 'US-MO',
        cities: [
          {"name":"ADVANCE","code":"US-MO-AVX"},
          {"name":"AFFTON","code":"US-MO-AFF"},
          {"name":"ALBANY","code":"US-MO-5BN"},
          {"name":"ALEXANDRIA","code":"US-MO-AXY"},
          {"name":"ALTENBURG","code":"US-MO-GUA"},
          {"name":"ALTON","code":"US-MO-A4L"},
          {"name":"AMSTERDAM","code":"US-MO-774"},
          {"name":"ANDERSON","code":"US-MO-ANO"},
          {"name":"ANNAPOLIS","code":"US-MO-AM4"},
          {"name":"ANNISTON","code":"US-MO-NNT"},
          {"name":"ANTONIA","code":"US-MO-ANX"},
          {"name":"ARBELA","code":"US-MO-A4A"},
          {"name":"ARBYRD","code":"US-MO-AYD"},
          {"name":"ARCADIA","code":"US-MO-JDI"},
          {"name":"ARCHIE","code":"US-MO-6AR"},
          {"name":"ARNOLD","code":"US-MO-AXF"},
          {"name":"ASBURY","code":"US-MO-YB9"},
          {"name":"ASH GROVE","code":"US-MO-ZAV"},
          {"name":"ASHLAND","code":"US-MO-AHJ"},
          {"name":"AUGUSTA","code":"US-MO-VR9"},
          {"name":"AURORA","code":"US-MO-AUU"},
          {"name":"AUXVASSE","code":"US-MO-V8X"},
          {"name":"AVA","code":"US-MO-AQB"},
          {"name":"BALLWIN","code":"US-MO-BLL"},
          {"name":"BARNHART","code":"US-MO-BHT"},
          {"name":"BATES CITY","code":"US-MO-B3C"},
          {"name":"BEL RIDGE","code":"US-MO-YYX"},
          {"name":"BELLE","code":"US-MO-JBE"},
          {"name":"BELLEFONTAINE NEIGHBORS","code":"US-MO-XFD"},
          {"name":"BELTON","code":"US-MO-BZE"},
          {"name":"BERGER","code":"US-MO-B5G"},
          {"name":"BERKELEY","code":"US-MO-TXR"},
          {"name":"BERNIE","code":"US-MO-EIE"},
          {"name":"BETHANY","code":"US-MO-BHY"},
          {"name":"BETHEL","code":"US-MO-4BE"},
          {"name":"BILLINGS","code":"US-MO-BGS"},
          {"name":"BLACK","code":"US-MO-B97"},
          {"name":"BLAIRSTOWN","code":"US-MO-QXB"},
          {"name":"BLAND","code":"US-MO-BDQ"},
          {"name":"BLOOMFIELD","code":"US-MO-OFB"},
          {"name":"BLOOMSDALE","code":"US-MO-QXA"},
          {"name":"BLUE SPRINGS","code":"US-MO-BZY"},
          {"name":"BLYTHEDALE","code":"US-MO-B5D"},
          {"name":"BOLIVAR","code":"US-MO-BOV"},
          {"name":"BONNE TERRE","code":"US-MO-JBT"},
          {"name":"BOONVILLE","code":"US-MO-BVM"},
          {"name":"BOSS","code":"US-MO-2BO"},
          {"name":"BOURBON","code":"US-MO-B8N"},
          {"name":"BOWLING GREEN","code":"US-MO-OWL"},
          {"name":"BRANDSVILLE","code":"US-MO-QAQ"},
          {"name":"BRANSON","code":"US-MO-PLK"},
          {"name":"BRANSON WEST","code":"US-MO-BZV"},
          {"name":"BRAYMER","code":"US-MO-QA9"},
          {"name":"BRAYMER","code":"US-MO-ZQW"},
          {"name":"BRECKENRIDGE HILLS","code":"US-MO-KDQ"},
          {"name":"BRENTWOOD","code":"US-MO-JBW"},
          {"name":"BRIDGETON","code":"US-MO-BMZ"},
          {"name":"BRIGHTON","code":"US-MO-BG5"},
          {"name":"BRONAUGH","code":"US-MO-B9A"},
          {"name":"BROOKFIELD","code":"US-MO-OFI"},
          {"name":"BROOKLINE","code":"US-MO-BJK"},
          {"name":"BROOKLINE STATION","code":"US-MO-BO8"},
          {"name":"BROSELEY","code":"US-MO-B8S"},
          {"name":"BROWNWOOD","code":"US-MO-3BD"},
          {"name":"BRUNSWICK","code":"US-MO-RU9"},
          {"name":"BUCKNER","code":"US-MO-B2U"},
          {"name":"BUFFALO","code":"US-MO-BFU"},
          {"name":"BUNKER","code":"US-MO-BKB"},
          {"name":"BUTLER","code":"US-MO-BUM"},
          {"name":"CABOOL","code":"US-MO-CBM"},
          {"name":"CADET","code":"US-MO-CD5"},
          {"name":"CAIRO","code":"US-MO-9CO"},
          {"name":"CALIFORNIA","code":"US-MO-CFJ"},
          {"name":"CALLAO","code":"US-MO-ZBX"},
          {"name":"CAMDENTON","code":"US-MO-CDT"},
          {"name":"CAMERON","code":"US-MO-XAO"},
          {"name":"CAMPBELL","code":"US-MO-CPL"},
          {"name":"CANTON","code":"US-MO-CNZ"},
          {"name":"CAPE GIRARDEAU","code":"US-MO-CGI"},
          {"name":"CARROLLTON","code":"US-MO-CR2"},
          {"name":"CARTHAGE","code":"US-MO-CJQ"},
          {"name":"CARUTHERSVILLE","code":"US-MO-AHV"},
          {"name":"CASSVILLE","code":"US-MO-XSS"},
          {"name":"CATAWISSA","code":"US-MO-C8A"},
          {"name":"CATERVILLE","code":"US-MO-8CE"},
          {"name":"CEDAR HILL","code":"US-MO-VRH"},
          {"name":"CENTERVILLE","code":"US-MO-M37"},
          {"name":"CENTRALIA","code":"US-MO-ZCT"},
          {"name":"CHAFFEE","code":"US-MO-CQ9"},
          {"name":"CHARLESTON","code":"US-MO-CXS"},
          {"name":"CHERRYVILLE","code":"US-MO-VHY"},
          {"name":"CHESTERFIELD","code":"US-MO-CFE"},
          {"name":"CHICOPEE","code":"US-MO-CCE"},
          {"name":"CHILLICOTHE","code":"US-MO-UFC"},
          {"name":"CHURCH HILL","code":"US-MO-UFB"},
          {"name":"CLARK","code":"US-MO-CL4"},
          {"name":"CLARKSVILLE","code":"US-MO-UA2"},
          {"name":"CLAYCOMO","code":"US-MO-CC6"},
          {"name":"CLAYTON","code":"US-MO-CJD"},
          {"name":"CLEVELAND","code":"US-MO-CWX"},
          {"name":"CLINTON","code":"US-MO-CBT"},
          {"name":"COLE CAMP","code":"US-MO-7MI"},
          {"name":"COLUMBIA","code":"US-MO-COU"},
          {"name":"COLUMBUS","code":"US-MO-OLP"},
          {"name":"CONCORDIA","code":"US-MO-C5O"},
          {"name":"COSBY","code":"US-MO-7CB"},
          {"name":"COTTLEVILLE","code":"US-MO-VXO"},
          {"name":"CRAIG","code":"US-MO-A7T"},
          {"name":"CRANE","code":"US-MO-ZVQ"},
          {"name":"CRESTWOOD","code":"US-MO-CW9"},
          {"name":"CREVE COEUR","code":"US-MO-C4Q"},
          {"name":"CRYSTAL CITY","code":"US-MO-CCM"},
          {"name":"CUBA","code":"US-MO-OCU"},
          {"name":"DALLAS","code":"US-MO-DLB"},
          {"name":"DE SOTO","code":"US-MO-DSQ"},
          {"name":"DEERFIELD","code":"US-MO-DFM"},
          {"name":"DEFIANCE","code":"US-MO-DFC"},
          {"name":"DENVER","code":"US-MO-DTZ"},
          {"name":"DES PERES","code":"US-MO-DPR"},
          {"name":"DEXTER","code":"US-MO-DXE"},
          {"name":"DIAMOND","code":"US-MO-DMD"},
          {"name":"DIXON","code":"US-MO-DIQ"},
          {"name":"DONIPHAN","code":"US-MO-DPH"},
          {"name":"DRESDEN","code":"US-MO-2DD"},
          {"name":"DREXEL","code":"US-MO-DRL"},
          {"name":"DUDLEY","code":"US-MO-UD4"},
          {"name":"DUENWEG","code":"US-MO-D2G"},
          {"name":"DUTZOW","code":"US-MO-ZDZ"},
          {"name":"EAGLE ROCK","code":"US-MO-YGK"},
          {"name":"EAGLEVILLE","code":"US-MO-EGJ"},
          {"name":"EARTH CITY","code":"US-MO-ETC"},
          {"name":"EAST PRAIRIE","code":"US-MO-EPF"},
          {"name":"EDGERTON","code":"US-MO-DG3"},
          {"name":"EDINA","code":"US-MO-ED6"},
          {"name":"EL DORADO SPRINGS","code":"US-MO-EOO"},
          {"name":"ELDON","code":"US-MO-YED"},
          {"name":"ELLINGTON","code":"US-MO-EJG"},
          {"name":"ELLISVILLE","code":"US-MO-ELU"},
          {"name":"ELLSINORE","code":"US-MO-EZI"},
          {"name":"ELSBERRY","code":"US-MO-EEY"},
          {"name":"EMINENCE","code":"US-MO-EII"},
          {"name":"EOLIA","code":"US-MO-EQI"},
          {"name":"EUGENE","code":"US-MO-EUN"},
          {"name":"EUREKA, PERRY","code":"US-MO-EUK"},
          {"name":"EUREKA, SAINT LOUIS","code":"US-MO-EUR"},
          {"name":"EVERTON","code":"US-MO-ET7"},
          {"name":"EXCELSIOR SPRINGS","code":"US-MO-ZEO"},
          {"name":"EXETER","code":"US-MO-X5O"},
          {"name":"FAIRFAX","code":"US-MO-RFX"},
          {"name":"FAIRVIEW","code":"US-MO-IVW"},
          {"name":"FALCON","code":"US-MO-FQC"},
          {"name":"FARBER","code":"US-MO-9FR"},
          {"name":"FARMINGTON","code":"US-MO-FAM"},
          {"name":"FAYETTE","code":"US-MO-ZFY"},
          {"name":"FENTON","code":"US-MO-FNM"},
          {"name":"FERGUSON","code":"US-MO-ZGU"},
          {"name":"FESTUS","code":"US-MO-FUS"},
          {"name":"FLAT RIVER","code":"US-MO-UJB"},
          {"name":"FLORENCE","code":"US-MO-OEZ"},
          {"name":"FLORISSANT","code":"US-MO-FOT"},
          {"name":"FORDLAND","code":"US-MO-FD3"},
          {"name":"FOREST CITY","code":"US-MO-F5Y"},
          {"name":"FORISTELL","code":"US-MO-FGE"},
          {"name":"FORSYTH","code":"US-MO-FY5"},
          {"name":"FORT LEONARD WOOD","code":"US-MO-TBN"},
          {"name":"FORTUNA","code":"US-MO-FR5"},
          {"name":"FOUR SEASONS","code":"US-MO-4SE"},
          {"name":"FRANKFORD","code":"US-MO-KFS"},
          {"name":"FRANKLIN","code":"US-MO-FK6"},
          {"name":"FREDERICKTOWN","code":"US-MO-FED"},
          {"name":"FREEBURG","code":"US-MO-F6R"},
          {"name":"FROHNA","code":"US-MO-FHN"},
          {"name":"FRONTENAC","code":"US-MO-FEC"},
          {"name":"FULTON","code":"US-MO-FUT"},
          {"name":"GAINESVILLE","code":"US-MO-GIY"},
          {"name":"GALLATIN","code":"US-MO-GZX"},
          {"name":"GARDEN CITY","code":"US-MO-GCM"},
          {"name":"GARRISON","code":"US-MO-GSZ"},
          {"name":"GERALD","code":"US-MO-DQ9"},
          {"name":"GIBBONS","code":"US-MO-GBP"},
          {"name":"GIDEON","code":"US-MO-GID"},
          {"name":"GLADSTONE","code":"US-MO-GDS"},
          {"name":"GLASGOW","code":"US-MO-GGS"},
          {"name":"GLEN ALLEN","code":"US-MO-GA3"},
          {"name":"GLENWOOD","code":"US-MO-2GW"},
          {"name":"GOLDEN","code":"US-MO-OOL"},
          {"name":"GOLDEN CITY","code":"US-MO-G8N"},
          {"name":"GOODMAN","code":"US-MO-GM4"},
          {"name":"GORIN","code":"US-MO-GIQ"},
          {"name":"GOWER","code":"US-MO-GWM"},
          {"name":"GRAIN VALLEY","code":"US-MO-GIV"},
          {"name":"GRANDVIEW","code":"US-MO-GVW"},
          {"name":"GRANT CITY","code":"US-MO-GQS"},
          {"name":"GRAVOIS MILLS","code":"US-MO-G7M"},
          {"name":"GRAY SUMMIT","code":"US-MO-68T"},
          {"name":"GREEN CASTLE","code":"US-MO-G4D"},
          {"name":"GREEN PARK","code":"US-MO-VNP"},
          {"name":"GREEN RIDGE","code":"US-MO-GR3"},
          {"name":"GREENFIELD","code":"US-MO-GFH"},
          {"name":"GREENVILLE","code":"US-MO-GWE"},
          {"name":"GREENWOOD","code":"US-MO-EEO"},
          {"name":"GROVER","code":"US-MO-GQV"},
          {"name":"HALF WAY","code":"US-MO-ZHF"},
          {"name":"HAMILTON","code":"US-MO-XGM"},
          {"name":"HANNIBAL","code":"US-MO-HBL"},
          {"name":"HARRIS","code":"US-MO-JHR"},
          {"name":"HARRISBURG","code":"US-MO-4RG"},
          {"name":"HARRISONVILLE","code":"US-MO-HMO"},
          {"name":"HARVIELL","code":"US-MO-G4W"},
          {"name":"HARWOOD","code":"US-MO-AWQ"},
          {"name":"HAYTI","code":"US-MO-YIA"},
          {"name":"HAYWARD","code":"US-MO-HWM"},
          {"name":"HAZELWOOD","code":"US-MO-HZW"},
          {"name":"HENRIETTA","code":"US-MO-HT9"},
          {"name":"HERCULANEUM","code":"US-MO-HM4"},
          {"name":"HERMANN","code":"US-MO-RMA"},
          {"name":"HIGBEE","code":"US-MO-XGH"},
          {"name":"HIGGINSVILLE","code":"US-MO-HGV"},
          {"name":"HIGH HILL","code":"US-MO-IHH"},
          {"name":"HIGH RIDGE","code":"US-MO-HRQ"},
          {"name":"HIGHLANDVILLE","code":"US-MO-FXH"},
          {"name":"HILLSBORO","code":"US-MO-HI4"},
          {"name":"HOLLAND","code":"US-MO-LQD"},
          {"name":"HOLLISTER","code":"US-MO-HRM"},
          {"name":"HOLT","code":"US-MO-JHT"},
          {"name":"HOUSE SPRINGS","code":"US-MO-HU8"},
          {"name":"HOUSTON","code":"US-MO-HQN"},
          {"name":"HUGHESVILLE","code":"US-MO-H43"},
          {"name":"HUMPHREYS","code":"US-MO-H2H"},
          {"name":"HUNTSVILLE","code":"US-MO-M7B"},
          {"name":"HURON","code":"US-MO-HN9"},
          {"name":"IMPERIAL","code":"US-MO-IMR"},
          {"name":"INDEPENDENCE","code":"US-MO-IPM"},
          {"name":"IRONDALE","code":"US-MO-I3D"},
          {"name":"IRONTON","code":"US-MO-IRX"},
          {"name":"IVORY","code":"US-MO-IV3"},
          {"name":"JACKSON","code":"US-MO-JAA"},
          {"name":"JAMESPORT","code":"US-MO-JMP"},
          {"name":"JASPER","code":"US-MO-JPE"},
          {"name":"JEFFERSON CITY","code":"US-MO-JEF"},
          {"name":"JENKINS","code":"US-MO-JNK"},
          {"name":"JENNINGS","code":"US-MO-JS9"},
          {"name":"JONESBURG","code":"US-MO-ZF5"},
          {"name":"JOPLIN","code":"US-MO-JLN"},
          {"name":"KAHOKA","code":"US-MO-KH2"},
          {"name":"KAISER/LAKE OZARK","code":"US-MO-AIZ"},
          {"name":"KANSAS CITY","code":"US-MO-MKC"},
          {"name":"KANSAS CITY INTERNATIONAL APT","code":"US-MO-MCI"},
          {"name":"KEARNEY","code":"US-MO-KYE"},
          {"name":"KENNETT","code":"US-MO-KNT"},
          {"name":"KEYTESVILLE","code":"US-MO-6VI"},
          {"name":"KIMBERLING CITY","code":"US-MO-QKC"},
          {"name":"KINGDOM CITY","code":"US-MO-KGE"},
          {"name":"KINGSVILLE","code":"US-MO-KV9"},
          {"name":"KINLOCH","code":"US-MO-KL4"},
          {"name":"KIRBYVILLE","code":"US-MO-KAQ"},
          {"name":"KIRKSVILLE","code":"US-MO-IRK"},
          {"name":"KIRKWOOD","code":"US-MO-XKW"},
          {"name":"KNOB LICK","code":"US-MO-KC7"},
          {"name":"KNOB NOSTER","code":"US-MO-K99"},
          {"name":"LA GRANGE","code":"US-MO-LGK"},
          {"name":"LA MONTE","code":"US-MO-L4M"},
          {"name":"LABADIE","code":"US-MO-LD2"},
          {"name":"LADDONIA","code":"US-MO-L7D"},
          {"name":"LADUE","code":"US-MO-LZD"},
          {"name":"LAKE SAINT LOUIS","code":"US-MO-6ER"},
          {"name":"LAMAR","code":"US-MO-LDF"},
          {"name":"LAMPE","code":"US-MO-KPE"},
          {"name":"LATHAM","code":"US-MO-2LT"},
          {"name":"LATHROP","code":"US-MO-LZH"},
          {"name":"LAURIE","code":"US-MO-XL2"},
          {"name":"LEASBURG","code":"US-MO-LS3"},
          {"name":"LEBANON","code":"US-MO-LBM"},
          {"name":"LEE'S SUMMIT","code":"US-MO-LSM"},
          {"name":"LEEDS","code":"US-MO-XHK"},
          {"name":"LEES SUMMIT","code":"US-MO-LSU"},
          {"name":"LEMAY","code":"US-MO-LMY"},
          {"name":"LEXINGTON","code":"US-MO-QDU"},
          {"name":"LIBERTY","code":"US-MO-LIB"},
          {"name":"LICKING","code":"US-MO-LK6"},
          {"name":"LINCOLN","code":"US-MO-LL8"},
          {"name":"LINN","code":"US-MO-LNJ"},
          {"name":"LINN CREEK","code":"US-MO-QLF"},
          {"name":"LOCKWOOD","code":"US-MO-WOD"},
          {"name":"LOHMAN","code":"US-MO-7OH"},
          {"name":"LONE JACK","code":"US-MO-JKO"},
          {"name":"LOOSE CREEK","code":"US-MO-3LC"},
          {"name":"LOUISBURG","code":"US-MO-UIM"},
          {"name":"LOUISIANA","code":"US-MO-LUA"},
          {"name":"MACKS CREEK","code":"US-MO-EJK"},
          {"name":"MACON","code":"US-MO-MAC"},
          {"name":"MADISON","code":"US-MO-M3D"},
          {"name":"MALDEN","code":"US-MO-MAW"},
          {"name":"MANCHESTER","code":"US-MO-MNY"},
          {"name":"MANSFIELD","code":"US-MO-LFM"},
          {"name":"MAPLEWOOD","code":"US-MO-MW2"},
          {"name":"MARBLE HILL","code":"US-MO-M55"},
          {"name":"MARCELINE","code":"US-MO-MOV"},
          {"name":"MARIONVILLE","code":"US-MO-MVF"},
          {"name":"MARSHALL","code":"US-MO-MHL"},
          {"name":"MARSHFIELD","code":"US-MO-MF8"},
          {"name":"MARSTON","code":"US-MO-9MS"},
          {"name":"MARTHASVILLE","code":"US-MO-AHX"},
          {"name":"MARYLAND HEIGHTS","code":"US-MO-MHY"},
          {"name":"MARYVILLE","code":"US-MO-MVD"},
          {"name":"MATSON","code":"US-MO-M4T"},
          {"name":"MATTHEWS","code":"US-MO-MW9"},
          {"name":"MCBRIDE","code":"US-MO-MBI"},
          {"name":"MEMPHIS","code":"US-MO-EJS"},
          {"name":"MENDON","code":"US-MO-ENU"},
          {"name":"MERCER","code":"US-MO-EZC"},
          {"name":"META","code":"US-MO-MZB"},
          {"name":"MEXICO","code":"US-MO-MXI"},
          {"name":"MIDDLETOWN","code":"US-MO-ILQ"},
          {"name":"MILAN","code":"US-MO-OML"},
          {"name":"MILL SPRING","code":"US-MO-XRG"},
          {"name":"MILLER","code":"US-MO-M9R"},
          {"name":"MINER","code":"US-MO-NRZ"},
          {"name":"MINERAL POINT","code":"US-MO-M3M"},
          {"name":"MISSOURI CITY","code":"US-MO-URI"},
          {"name":"MOBERLY","code":"US-MO-MBY"},
          {"name":"MOKANE","code":"US-MO-M7K"},
          {"name":"MOLT","code":"US-MO-7MO"},
          {"name":"MONETT","code":"US-MO-MMO"},
          {"name":"MONROE CITY","code":"US-MO-KRC"},
          {"name":"MONTGOMERY CITY","code":"US-MO-M4G"},
          {"name":"MONTREAL","code":"US-MO-MT8"},
          {"name":"MOORESVILLE","code":"US-MO-2MV"},
          {"name":"MOREHOUSE","code":"US-MO-YRE"},
          {"name":"MORLEY","code":"US-MO-JGM"},
          {"name":"MOSCOW MILLS","code":"US-MO-MM4"},
          {"name":"MOUND CITY","code":"US-MO-M9C"},
          {"name":"MOUNDVILLE","code":"US-MO-ND5"},
          {"name":"MOUNT VERNON","code":"US-MO-OUV"},
          {"name":"MOUNTAIN GROVE","code":"US-MO-VMV"},
          {"name":"MOUNTAIN VIEW","code":"US-MO-UIP"},
          {"name":"NEELYS","code":"US-MO-NMO"},
          {"name":"NEOSHO","code":"US-MO-EOS"},
          {"name":"NEVADA","code":"US-MO-NVD"},
          {"name":"NEW BLOOMFIELD","code":"US-MO-NBL"},
          {"name":"NEW BOSTON","code":"US-MO-NW8"},
          {"name":"NEW FLORENCE","code":"US-MO-NFO"},
          {"name":"NEW FRANKLIN","code":"US-MO-NFG"},
          {"name":"NEW HAVEN","code":"US-MO-NH2"},
          {"name":"NEW LONDON","code":"US-MO-NQV"},
          {"name":"NEW MADRID","code":"US-MO-XNW"},
          {"name":"NEW MELLE","code":"US-MO-NZE"},
          {"name":"NEWARK","code":"US-MO-ZNA"},
          {"name":"NEWTOWN","code":"US-MO-UOU"},
          {"name":"NIANGUA","code":"US-MO-NZU"},
          {"name":"NIXA","code":"US-MO-NXI"},
          {"name":"NOEL","code":"US-MO-NOE"},
          {"name":"NORMANDY","code":"US-MO-5UN"},
          {"name":"NORTH KANSAS CITY","code":"US-MO-NKY"},
          {"name":"NORTHMOOR","code":"US-MO-NM4"},
          {"name":"NOVELTY","code":"US-MO-OVV"},
          {"name":"O'FALLON","code":"US-MO-OFL"},
          {"name":"OAK GROVE","code":"US-MO-IKG"},
          {"name":"ODESSA","code":"US-MO-ODG"},
          {"name":"OLATHIA","code":"US-MO-OHA"},
          {"name":"OLD MONROE","code":"US-MO-OZI"},
          {"name":"OLIVETTE","code":"US-MO-ZOT"},
          {"name":"ORAN","code":"US-MO-O2R"},
          {"name":"ORRICK","code":"US-MO-IHK"},
          {"name":"OSAGE BEACH","code":"US-MO-OSB"},
          {"name":"OSCAR","code":"US-MO-S4M"},
          {"name":"OSCEOLA","code":"US-MO-M2S"},
          {"name":"OTTERVILLE","code":"US-MO-OIM"},
          {"name":"OVERLAND","code":"US-MO-OVD"},
          {"name":"OWENSVILLE","code":"US-MO-OWV"},
          {"name":"OXLY","code":"US-MO-OXY"},
          {"name":"OZARK","code":"US-MO-ORK"},
          {"name":"PACIFIC","code":"US-MO-AC3"},
          {"name":"PAGEDALE","code":"US-MO-PGE"},
          {"name":"PALMYRA","code":"US-MO-PMR"},
          {"name":"PARK HILLS","code":"US-MO-PKH"},
          {"name":"PARKVILLE","code":"US-MO-KLE"},
          {"name":"PARMA","code":"US-MO-QRA"},
          {"name":"PATTON","code":"US-MO-JZP"},
          {"name":"PATTONSBURG","code":"US-MO-IQP"},
          {"name":"PEACE VALLEY","code":"US-MO-PSY"},
          {"name":"PECULIAR","code":"US-MO-VPE"},
          {"name":"PERRYVILLE","code":"US-MO-PYX"},
          {"name":"PEVELY","code":"US-MO-PVB"},
          {"name":"PHILLIPSBURG","code":"US-MO-PB6"},
          {"name":"PICKERING","code":"US-MO-JPK"},
          {"name":"PIEDMONT","code":"US-MO-PIQ"},
          {"name":"PIERCE CITY","code":"US-MO-AZ8"},
          {"name":"PINEVILLE","code":"US-MO-IIU"},
          {"name":"PITTSVILLE","code":"US-MO-PVO"},
          {"name":"PLATO","code":"US-MO-PL3"},
          {"name":"PLATTE","code":"US-MO-TJJ"},
          {"name":"PLATTE CITY","code":"US-MO-2PC"},
          {"name":"PLATTSBURG","code":"US-MO-PBX"},
          {"name":"PLEASANT HILL","code":"US-MO-PTI"},
          {"name":"PLEASANT HOPE","code":"US-MO-PO3"},
          {"name":"PLEASANT VALLEY","code":"US-MO-PEY"},
          {"name":"POMONA","code":"US-MO-PMO"},
          {"name":"POPLAR BLUFF","code":"US-MO-POF"},
          {"name":"PORTAGEVILLE","code":"US-MO-PGI"},
          {"name":"POTOSI","code":"US-MO-PSI"},
          {"name":"PRINCETON","code":"US-MO-P6C"},
          {"name":"PURDY","code":"US-MO-PDU"},
          {"name":"PUXICO","code":"US-MO-PX7"},
          {"name":"RANDOLPH","code":"US-MO-DCP"},
          {"name":"RAVENWOOD","code":"US-MO-RAQ"},
          {"name":"RAYMONDVILLE","code":"US-MO-RYD"},
          {"name":"RAYMORE","code":"US-MO-RAM"},
          {"name":"RAYTOWN","code":"US-MO-YRO"},
          {"name":"REEDS","code":"US-MO-RZE"},
          {"name":"REEDS SPRING","code":"US-MO-RS2"},
          {"name":"REPUBLIC","code":"US-MO-RU2"},
          {"name":"RICH HILL","code":"US-MO-RLX"},
          {"name":"RICHLAND","code":"US-MO-RRM"},
          {"name":"RICHMOND","code":"US-MO-HMC"},
          {"name":"RICHWOODS","code":"US-MO-RW3"},
          {"name":"RIDGEDALE","code":"US-MO-RG8"},
          {"name":"RIVERSIDE","code":"US-MO-YRS"},
          {"name":"ROACH","code":"US-MO-R9O"},
          {"name":"ROBY","code":"US-MO-RB4"},
          {"name":"ROCK PORT","code":"US-MO-KPR"},
          {"name":"ROCKPORT","code":"US-MO-RC4"},
          {"name":"ROGERSVILLE","code":"US-MO-RVZ"},
          {"name":"ROLLA","code":"US-MO-RLA"},
          {"name":"ROSEBUD","code":"US-MO-RBZ"},
          {"name":"RUTLEDGE","code":"US-MO-R2U"},
          {"name":"SAINT ALBANS","code":"US-MO-TBA"},
          {"name":"SAINT ANN","code":"US-MO-SA4"},
          {"name":"SAINT CHARLES","code":"US-MO-ABP"},
          {"name":"SAINT CHARLES","code":"US-MO-S7T"},
          {"name":"SAINT CLAIR, FRANKLIN","code":"US-MO-LIK"},
          {"name":"SAINT JAMES","code":"US-MO-AIJ"},
          {"name":"SAINT JOHN","code":"US-MO-JJ3"},
          {"name":"SAINT JOSEPH","code":"US-MO-STJ"},
          {"name":"SAINT LOUIS","code":"US-MO-STL"},
          {"name":"SAINT PAUL","code":"US-MO-PA3"},
          {"name":"SAINT PETERS","code":"US-MO-SQS"},
          {"name":"SAINT THOMAS","code":"US-MO-ITQ"},
          {"name":"SAINTE GENEVIEVE","code":"US-MO-TGN"},
          {"name":"SALEM","code":"US-MO-SMQ"},
          {"name":"SALISBURY","code":"US-MO-ISU"},
          {"name":"SARCOXIE","code":"US-MO-XX5"},
          {"name":"SAVANNAH","code":"US-MO-SA8"},
          {"name":"SAVERTON","code":"US-MO-VEJ"},
          {"name":"SCOTT CITY","code":"US-MO-SCQ"},
          {"name":"SEDALIA","code":"US-MO-DMO"},
          {"name":"SENATH","code":"US-MO-XAH"},
          {"name":"SENECA","code":"US-MO-SNE"},
          {"name":"SEYMOUR","code":"US-MO-SEJ"},
          {"name":"SHELBYVILLE","code":"US-MO-SQG"},
          {"name":"SHELDON","code":"US-MO-SQP"},
          {"name":"SHELL KNOB","code":"US-MO-EOB"},
          {"name":"SIBLEY","code":"US-MO-IBZ"},
          {"name":"SIKESTON","code":"US-MO-SIK"},
          {"name":"SILVA","code":"US-MO-ZVA"},
          {"name":"SKIDMORE","code":"US-MO-7SK"},
          {"name":"SLATER","code":"US-MO-SVM"},
          {"name":"SMITHTON","code":"US-MO-T9A"},
          {"name":"SMITHTON","code":"US-MO-TQQ"},
          {"name":"SOUTH RIVER","code":"US-MO-SST"},
          {"name":"SOUTH WEST CITY","code":"US-MO-SW4"},
          {"name":"SPICKARD","code":"US-MO-SK9"},
          {"name":"SPIRIT OF SAINT LOUIS APT/SAINT LOUIS","code":"US-MO-SUS"},
          {"name":"SPRINGFIELD","code":"US-MO-SGF"},
          {"name":"STANBERRY","code":"US-MO-TNB"},
          {"name":"STANTON","code":"US-MO-ZJA"},
          {"name":"STEEDMAN","code":"US-MO-S5M"},
          {"name":"STEELE","code":"US-MO-QTL"},
          {"name":"STEELVILLE","code":"US-MO-QTV"},
          {"name":"STOCKTON","code":"US-MO-JSK"},
          {"name":"STOTTS CITY","code":"US-MO-TZI"},
          {"name":"STOUTLAND","code":"US-MO-QUL"},
          {"name":"STRAFFORD","code":"US-MO-SFF"},
          {"name":"SUCCESS","code":"US-MO-SUQ"},
          {"name":"SUGAR CREEK","code":"US-MO-SKX"},
          {"name":"SULLIVAN","code":"US-MO-XSV"},
          {"name":"SUNRISE BEACH","code":"US-MO-ZZZ"},
          {"name":"SUNSET HILLS","code":"US-MO-UEA"},
          {"name":"SWEET SPRINGS","code":"US-MO-SS9"},
          {"name":"TARKIO","code":"US-MO-TK5"},
          {"name":"TAYLOR","code":"US-MO-TJO"},
          {"name":"TECUMSEH","code":"US-MO-TMZ"},
          {"name":"THAYER","code":"US-MO-TXY"},
          {"name":"THOMPSON","code":"US-MO-TO5"},
          {"name":"THOMPSONFIELD","code":"US-MO-THM"},
          {"name":"TIPTON","code":"US-MO-TP5"},
          {"name":"TOWN AND COUNTRY","code":"US-MO-TC4"},
          {"name":"TRENTON","code":"US-MO-TRX"},
          {"name":"TROY","code":"US-MO-TYU"},
          {"name":"TRUESDALE","code":"US-MO-M6I"},
          {"name":"UNION","code":"US-MO-UKO"},
          {"name":"UNION CITY","code":"US-MO-UC9"},
          {"name":"UNION, FRANKLIN","code":"US-MO-UOI"},
          {"name":"UNIONVILLE","code":"US-MO-UOV"},
          {"name":"UNIVERSITY","code":"US-MO-M6S"},
          {"name":"UNIVERSITY CITY","code":"US-MO-UVX"},
          {"name":"UTICA","code":"US-MO-UIC"},
          {"name":"VALLEY PARK","code":"US-MO-VPA"},
          {"name":"VAN BUREN","code":"US-MO-YVB"},
          {"name":"VANDALIA","code":"US-MO-VNL"},
          {"name":"VERONA","code":"US-MO-VEA"},
          {"name":"VERSAILLES","code":"US-MO-VRS"},
          {"name":"VIBURNUM","code":"US-MO-VRU"},
          {"name":"VICHY","code":"US-MO-VIH"},
          {"name":"VIENNA","code":"US-MO-VNM"},
          {"name":"VILLA RIDGE","code":"US-MO-54M"},
          {"name":"VINITA PARK","code":"US-MO-8TV"},
          {"name":"WALNUT GROVE","code":"US-MO-WGR"},
          {"name":"WALNUT SHADE","code":"US-MO-H9S"},
          {"name":"WARRENSBURG","code":"US-MO-SZL"},
          {"name":"WARRENTON","code":"US-MO-WRT"},
          {"name":"WARSAW","code":"US-MO-WWC"},
          {"name":"WASHINGTON","code":"US-MO-WSK"},
          {"name":"WAVERLY","code":"US-MO-WVH"},
          {"name":"WAYLAND","code":"US-MO-WYF"},
          {"name":"WAYNESVILLE","code":"US-MO-WAZ"},
          {"name":"WEATHERBY","code":"US-MO-2WY"},
          {"name":"WEBB CITY","code":"US-MO-XBW"},
          {"name":"WEBSTER GROVES","code":"US-MO-WG6"},
          {"name":"WELDON SPRING","code":"US-MO-4WS"},
          {"name":"WELLSVILLE","code":"US-MO-WVM"},
          {"name":"WENTZVILLE","code":"US-MO-WTZ"},
          {"name":"WEST ALTON","code":"US-MO-WA8"},
          {"name":"WEST PLAINS","code":"US-MO-WPN"},
          {"name":"WESTON","code":"US-MO-WSY"},
          {"name":"WHITEWATER","code":"US-MO-WW5"},
          {"name":"WILDWOOD","code":"US-MO-WQD"},
          {"name":"WILLARD","code":"US-MO-IWL"},
          {"name":"WILLOW SPRINGS","code":"US-MO-ZWO"},
          {"name":"WINDSOR","code":"US-MO-6MO"},
          {"name":"WOODSON TERRACE/ST LOUIS","code":"US-MO-VTR"},
          {"name":"WRIGHT CITY","code":"US-MO-WCU"},
        ]
      },
      {
        name: 'Montana',
        code: 'US-MT',
        cities: [
          {"name":"ALDER","code":"US-MT-A75"},
          {"name":"ANACONDA","code":"US-MT-A4N"},
          {"name":"ARLEE","code":"US-MT-XAI"},
          {"name":"AUGUSTA","code":"US-MT-AJG"},
          {"name":"BAKER","code":"US-MT-BK3"},
          {"name":"BELGRADE","code":"US-MT-BGX"},
          {"name":"BIG SANDY","code":"US-MT-BSM"},
          {"name":"BIG SKY","code":"US-MT-B5S"},
          {"name":"BIG TIMBER","code":"US-MT-B3T"},
          {"name":"BIGFORK","code":"US-MT-BF4"},
          {"name":"BILLINGS","code":"US-MT-BIL"},
          {"name":"BIRNEY","code":"US-MT-BI7"},
          {"name":"BONNER","code":"US-MT-BN8"},
          {"name":"BOYD","code":"US-MT-TBY"},
          {"name":"BOZEMAN","code":"US-MT-BZN"},
          {"name":"BRADY","code":"US-MT-RZY"},
          {"name":"BRIDGER","code":"US-MT-BIQ"},
          {"name":"BROADUS","code":"US-MT-BDX"},
          {"name":"BUTTE","code":"US-MT-BTM"},
          {"name":"CAMERON","code":"US-MT-CR6"},
          {"name":"CARTER","code":"US-MT-TJQ"},
          {"name":"CASCADE","code":"US-MT-CDA"},
          {"name":"CHOTEAU","code":"US-MT-C8H"},
          {"name":"CLANCY","code":"US-MT-ZCY"},
          {"name":"COLSTRIP","code":"US-MT-CS8"},
          {"name":"COLUMBIA FALLS","code":"US-MT-YCU"},
          {"name":"COLUMBUS","code":"US-MT-CB8"},
          {"name":"CONRAD","code":"US-MT-CD6"},
          {"name":"CORVALLIS","code":"US-MT-YCV"},
          {"name":"CROW AGENCY","code":"US-MT-OZC"},
          {"name":"CULBERTSON","code":"US-MT-4CU"},
          {"name":"CUT BANK","code":"US-MT-CTB"},
          {"name":"DARBY","code":"US-MT-DXB"},
          {"name":"DAYTON","code":"US-MT-QYT"},
          {"name":"DEER LODGE","code":"US-MT-DEG"},
          {"name":"DEL BONITA","code":"US-MT-DBA"},
          {"name":"DELL","code":"US-MT-DL8"},
          {"name":"DENTON","code":"US-MT-DTE"},
          {"name":"DILLON","code":"US-MT-DLN"},
          {"name":"DRUMMOND","code":"US-MT-DRU"},
          {"name":"EDGAR","code":"US-MT-E9D"},
          {"name":"ENNIS","code":"US-MT-E5N"},
          {"name":"ETHRIDGE","code":"US-MT-TDG"},
          {"name":"EUREKA","code":"US-MT-UKC"},
          {"name":"EVERGREEN","code":"US-MT-VGC"},
          {"name":"FAIRFIELD","code":"US-MT-8MT"},
          {"name":"FLORENCE","code":"US-MT-FR7"},
          {"name":"FORSYTH","code":"US-MT-FYS"},
          {"name":"FORT BENTON","code":"US-MT-FBO"},
          {"name":"GALLATIN GATEWAY","code":"US-MT-GG2"},
          {"name":"GARDINER","code":"US-MT-8GA"},
          {"name":"GEYSER","code":"US-MT-GS5"},
          {"name":"GILDFORD","code":"US-MT-IDF"},
          {"name":"GLASGOW","code":"US-MT-GSG"},
          {"name":"GLEN","code":"US-MT-QGE"},
          {"name":"GLENDIVE","code":"US-MT-GDV"},
          {"name":"GREAT FALLS","code":"US-MT-GTF"},
          {"name":"GREENOUGH","code":"US-MT-GR7"},
          {"name":"HAMILTON","code":"US-MT-HLT"},
          {"name":"HARDIN","code":"US-MT-HRD"},
          {"name":"HARLOWTON","code":"US-MT-XUX"},
          {"name":"HAVRE","code":"US-MT-HVR"},
          {"name":"HELENA","code":"US-MT-HLN"},
          {"name":"HELMVILLE","code":"US-MT-EJV"},
          {"name":"HERON","code":"US-MT-QRN"},
          {"name":"HILGER","code":"US-MT-HR5"},
          {"name":"HINGHAM","code":"US-MT-HGG"},
          {"name":"HUNTLEY","code":"US-MT-UEY"},
          {"name":"INVERNESS","code":"US-MT-IV7"},
          {"name":"JACKSON","code":"US-MT-JAJ"},
          {"name":"JORDAN","code":"US-MT-JDN"},
          {"name":"KALISPELL","code":"US-MT-FCA"},
          {"name":"KREMLIN","code":"US-MT-KML"},
          {"name":"LAUREL","code":"US-MT-LUQ"},
          {"name":"LEWISTOWN","code":"US-MT-LWT"},
          {"name":"LIBBY","code":"US-MT-LBZ"},
          {"name":"LINCOLN","code":"US-MT-IQN"},
          {"name":"LIVINGSTON","code":"US-MT-LVM"},
          {"name":"LOGAN","code":"US-MT-LG4"},
          {"name":"LOLO","code":"US-MT-YOL"},
          {"name":"MALMSTROM AFB","code":"US-MT-MM6"},
          {"name":"MALTA","code":"US-MT-MLK"},
          {"name":"MANHATTAN","code":"US-MT-2MH"},
          {"name":"MILES CITY","code":"US-MT-MLS"},
          {"name":"MISSOULA","code":"US-MT-MSO"},
          {"name":"MONTANA CITY","code":"US-MT-JTA"},
          {"name":"MORGAN","code":"US-MT-MRG"},
          {"name":"NYE","code":"US-MT-YEZ"},
          {"name":"OLNEY","code":"US-MT-OJN"},
          {"name":"OPHEIM","code":"US-MT-OPH"},
          {"name":"PABLO","code":"US-MT-ZPO"},
          {"name":"PENDROY","code":"US-MT-D9Y"},
          {"name":"PHILIPSBURG","code":"US-MT-IIP"},
          {"name":"PIEGAN","code":"US-MT-PGN"},
          {"name":"PLENTYWOOD","code":"US-MT-PWD"},
          {"name":"POLSON","code":"US-MT-POS"},
          {"name":"PONY","code":"US-MT-YY2"},
          {"name":"POPLAR","code":"US-MT-OPZ"},
          {"name":"QUINLAN","code":"US-MT-9QL"},
          {"name":"RAYMOND","code":"US-MT-RND"},
          {"name":"RED LODGE","code":"US-MT-RLJ"},
          {"name":"REXFORD","code":"US-MT-RXF"},
          {"name":"ROLLINS","code":"US-MT-RJS"},
          {"name":"RONAN","code":"US-MT-RNW"},
          {"name":"ROOSVILLE","code":"US-MT-ROO"},
          {"name":"ROUNDUP","code":"US-MT-RPX"},
          {"name":"SAINT IGNATIUS","code":"US-MT-TGI"},
          {"name":"SAND COULEE","code":"US-MT-DCE"},
          {"name":"SAVAGE","code":"US-MT-VGE"},
          {"name":"SCOBEY","code":"US-MT-SCO"},
          {"name":"SHELBY","code":"US-MT-SBX"},
          {"name":"SHEPHERD","code":"US-MT-HHD"},
          {"name":"SIDNEY","code":"US-MT-SDY"},
          {"name":"SILVER BOW","code":"US-MT-SEW"},
          {"name":"SILVER STAR","code":"US-MT-ITK"},
          {"name":"SOMERS","code":"US-MT-OFP"},
          {"name":"STEVENSVILLE","code":"US-MT-TES"},
          {"name":"STOCKETT","code":"US-MT-TK9"},
          {"name":"SUNBURST","code":"US-MT-7SM"},
          {"name":"SWEET GRASS","code":"US-MT-SWG"},
          {"name":"THOMPSON FALLS","code":"US-MT-THF"},
          {"name":"THREE FORKS","code":"US-MT-TFK"},
          {"name":"THREE FORKS JUNCTION","code":"US-MT-QTF"},
          {"name":"TREGO","code":"US-MT-TG8"},
          {"name":"TROUT CREEK","code":"US-MT-TZQ"},
          {"name":"TROY","code":"US-MT-TM8"},
          {"name":"TURNER","code":"US-MT-TUR"},
          {"name":"ULM","code":"US-MT-UDM"},
          {"name":"VICTOR","code":"US-MT-VCW"},
          {"name":"WEST YELLOWSTONE","code":"US-MT-WYS"},
          {"name":"WHITE SULPHUR SPRINGS","code":"US-MT-W6S"},
          {"name":"WHITEFISH","code":"US-MT-WXI"},
          {"name":"WHITEHALL","code":"US-MT-2MA"},
          {"name":"WHITETAIL","code":"US-MT-WHS"},
          {"name":"WHITLASH","code":"US-MT-WHL"},
          {"name":"WILLOW CREEK","code":"US-MT-WK8"},
          {"name":"WINSTON","code":"US-MT-8AN"},
          {"name":"WOLF POINT","code":"US-MT-OLF"},
        ]
      },
      {
        name: 'Nebraska',
        code: 'US-NE',
        cities: [{"name":"AINSWORTH","code":"US-NE-AW2"},
          {"name":"ALBION","code":"US-NE-AB8"},
          {"name":"ALDA","code":"US-NE-AD4"},
          {"name":"ALLIANCE","code":"US-NE-AIA"},
          {"name":"ARAPAHOE","code":"US-NE-XRP"},
          {"name":"ARNOLD","code":"US-NE-A99"},
          {"name":"ASHLAND","code":"US-NE-NEX"},
          {"name":"ASHTON","code":"US-NE-HJT"},
          {"name":"ATKINSON","code":"US-NE-AN3"},
          {"name":"ATLANTA","code":"US-NE-QAN"},
          {"name":"AUBURN","code":"US-NE-QAE"},
          {"name":"AURORA","code":"US-NE-AUQ"},
          {"name":"AVOCA","code":"US-NE-A9A"},
          {"name":"AXTELL","code":"US-NE-AX4"},
          {"name":"BARTLETT","code":"US-NE-BA2"},
          {"name":"BASSETT","code":"US-NE-BB9"},
          {"name":"BAYARD","code":"US-NE-BYD"},
          {"name":"BEATRICE","code":"US-NE-BIE"},
          {"name":"BEAVER CITY","code":"US-NE-VRC"},
          {"name":"BEAVER CROSSING","code":"US-NE-B9C"},
          {"name":"BELLEVUE","code":"US-NE-BEG"},
          {"name":"BELVIDERE","code":"US-NE-VD9"},
          {"name":"BENNET","code":"US-NE-BO6"},
          {"name":"BENNINGTON","code":"US-NE-B96"},
          {"name":"BLADEN","code":"US-NE-8LY"},
          {"name":"BLAIR","code":"US-NE-BAJ"},
          {"name":"BLOOMFIELD","code":"US-NE-OFE"},
          {"name":"BLUE SPRINGS","code":"US-NE-JBP"},
          {"name":"BOYS TOWN","code":"US-NE-B4Y"},
          {"name":"BRIDGEPORT","code":"US-NE-BGP"},
          {"name":"BROKEN BOW","code":"US-NE-BBW"},
          {"name":"BROWNVILLE","code":"US-NE-B5W"},
          {"name":"BURCHARD","code":"US-NE-UHR"},
          {"name":"BURWELL","code":"US-NE-UWE"},
          {"name":"CAMBRIDGE","code":"US-NE-C45"},
          {"name":"CAMPBELL","code":"US-NE-CP8"},
          {"name":"CARROLL","code":"US-NE-R9L"},
          {"name":"CEDAR BLUFFS","code":"US-NE-C3B"},
          {"name":"CENTRAL CITY","code":"US-NE-UCN"},
          {"name":"CERESCO","code":"US-NE-NQQ"},
          {"name":"CHADRON","code":"US-NE-CDR"},
          {"name":"CHALCO","code":"US-NE-JCO"},
          {"name":"CHAPMAN","code":"US-NE-CP7"},
          {"name":"CLARKS","code":"US-NE-KSQ"},
          {"name":"CLAY CENTER","code":"US-NE-ZCI"},
          {"name":"CLEARWATER","code":"US-NE-CWR"},
          {"name":"COLUMBUS","code":"US-NE-OLU"},
          {"name":"COZAD","code":"US-NE-QCZ"},
          {"name":"CRAWFORD","code":"US-NE-CW8"},
          {"name":"CREIGHTON","code":"US-NE-IGQ"},
          {"name":"CRETE","code":"US-NE-CTF"},
          {"name":"CROFTON","code":"US-NE-RFT"},
          {"name":"CULBERTSON","code":"US-NE-ULB"},
          {"name":"CURTIS","code":"US-NE-ICU"},
          {"name":"DAKOTA CITY","code":"US-NE-DKC"},
          {"name":"DANNEBROG","code":"US-NE-DA5"},
          {"name":"DAVID CITY","code":"US-NE-DTY"},
          {"name":"DE WITT","code":"US-NE-DWX"},
          {"name":"DECATUR","code":"US-NE-DSU"},
          {"name":"DENTON","code":"US-NE-DTQ"},
          {"name":"DESHLER","code":"US-NE-DSR"},
          {"name":"DIX","code":"US-NE-YIX"},
          {"name":"DODGE","code":"US-NE-DDG"},
          {"name":"DONIPHAN","code":"US-NE-DNH"},
          {"name":"DUNCAN","code":"US-NE-D7C"},
          {"name":"EAGLE","code":"US-NE-EEJ"},
          {"name":"EDDYVILLE","code":"US-NE-EY3"},
          {"name":"EDGAR","code":"US-NE-EJR"},
          {"name":"ELKHORN","code":"US-NE-EKR"},
          {"name":"ELM CREEK","code":"US-NE-EQC"},
          {"name":"ELMWOOD","code":"US-NE-IXX"},
          {"name":"ELWOOD","code":"US-NE-EW9"},
          {"name":"ENDICOTT","code":"US-NE-EQT"},
          {"name":"EWING","code":"US-NE-EG2"},
          {"name":"FAIRBURY","code":"US-NE-FBY"},
          {"name":"FAIRMONT","code":"US-NE-J66"},
          {"name":"FALLS CITY","code":"US-NE-FSY"},
          {"name":"FARNAM","code":"US-NE-FNA"},
          {"name":"FIRTH","code":"US-NE-IRH"},
          {"name":"FORT CALHOUN","code":"US-NE-FTC"},
          {"name":"FRANKLIN","code":"US-NE-K23"},
          {"name":"FREMONT","code":"US-NE-FET"},
          {"name":"FRIEND","code":"US-NE-FD8"},
          {"name":"FULLERTON","code":"US-NE-FUZ"},
          {"name":"FUNK","code":"US-NE-FUK"},
          {"name":"GENEVA","code":"US-NE-GEV"},
          {"name":"GENOA","code":"US-NE-GN9"},
          {"name":"GERING","code":"US-NE-XFU"},
          {"name":"GIBBON","code":"US-NE-GBQ"},
          {"name":"GOEHNER","code":"US-NE-N3E"},
          {"name":"GORDON","code":"US-NE-GO3"},
          {"name":"GOTHENBURG","code":"US-NE-GHG"},
          {"name":"GRAND ISLAND","code":"US-NE-GRI"},
          {"name":"GRANT","code":"US-NE-GNB"},
          {"name":"GREENWOOD","code":"US-NE-GW3"},
          {"name":"GRETNA","code":"US-NE-G3T"},
          {"name":"GURLEY","code":"US-NE-RLY"},
          {"name":"HARTINGTON","code":"US-NE-TGD"},
          {"name":"HASTINGS","code":"US-NE-HSI"},
          {"name":"HEBRON","code":"US-NE-YHR"},
          {"name":"HENDERSON","code":"US-NE-HXR"},
          {"name":"HERSHEY","code":"US-NE-KHZ"},
          {"name":"HOLDREGE","code":"US-NE-HDE"},
          {"name":"HOLMESVILLE","code":"US-NE-ZHQ"},
          {"name":"HOOPER","code":"US-NE-4OO"},
          {"name":"HOWELLS","code":"US-NE-NE7"},
          {"name":"HUBBELL","code":"US-NE-HVB"},
          {"name":"HUMBOLDT","code":"US-NE-H7D"},
          {"name":"HUMPHREY","code":"US-NE-HU6"},
          {"name":"IMPERIAL","code":"US-NE-IML"},
          {"name":"KEARNEY","code":"US-NE-EAR"},
          {"name":"KENESAW","code":"US-NE-KAW"},
          {"name":"KIMBALL","code":"US-NE-KM8"},
          {"name":"LA VISTA","code":"US-NE-VII"},
          {"name":"LAMAR","code":"US-NE-LM8"},
          {"name":"LAUREL","code":"US-NE-LQU"},
          {"name":"LEIGH","code":"US-NE-LGF"},
          {"name":"LEXINGTON","code":"US-NE-LXN"},
          {"name":"LINCOLN","code":"US-NE-LNK"},
          {"name":"LINDSAY","code":"US-NE-LZK"},
          {"name":"LODGEPOLE","code":"US-NE-DPL"},
          {"name":"LOOMIS","code":"US-NE-7OO"},
          {"name":"LOUISVILLE","code":"US-NE-L3S"},
          {"name":"LOUP CITY","code":"US-NE-LC2"},
          {"name":"LYONS","code":"US-NE-LQS"},
          {"name":"MADISON","code":"US-NE-MSB"},
          {"name":"MALCOLM","code":"US-NE-M24"},
          {"name":"MALMO","code":"US-NE-M4A"},
          {"name":"MARQUETTE","code":"US-NE-QTT"},
          {"name":"MAXWELL","code":"US-NE-M3X"},
          {"name":"MAYWOOD","code":"US-NE-M3W"},
          {"name":"MCCOOK","code":"US-NE-MCK"},
          {"name":"MEAD","code":"US-NE-MD4"},
          {"name":"MILFORD","code":"US-NE-KMF"},
          {"name":"MINATARE","code":"US-NE-MXK"},
          {"name":"MINDEN","code":"US-NE-MN6"},
          {"name":"MITCHELL","code":"US-NE-MIH"},
          {"name":"MORRILL","code":"US-NE-MOK"},
          {"name":"MORSE BLUFF","code":"US-NE-MB3"},
          {"name":"MULLEN","code":"US-NE-MHN"},
          {"name":"MURDOCK","code":"US-NE-YMK"},
          {"name":"MURRAY","code":"US-NE-7MU"},
          {"name":"NEBRASKA CITY","code":"US-NE-NBK"},
          {"name":"NELIGH","code":"US-NE-4NE"},
          {"name":"NELSON","code":"US-NE-NZL"},
          {"name":"NEWMAN GROVE","code":"US-NE-NAG"},
          {"name":"NIOBRARA","code":"US-NE-N5B"},
          {"name":"NORFOLK","code":"US-NE-OFK"},
          {"name":"NORTH BEND","code":"US-NE-NID"},
          {"name":"NORTH LOUP","code":"US-NE-NLQ"},
          {"name":"NORTH PLATTE","code":"US-NE-LBF"},
          {"name":"O'NEILL","code":"US-NE-ONL"},
          {"name":"OAK","code":"US-NE-QA8"},
          {"name":"ODESSA","code":"US-NE-ZOA"},
          {"name":"OFFUTT","code":"US-NE-OF8"},
          {"name":"OGALLALA","code":"US-NE-OGA"},
          {"name":"OMAHA","code":"US-NE-OMA"},
          {"name":"ORCHARD","code":"US-NE-OCD"},
          {"name":"ORD","code":"US-NE-OJR"},
          {"name":"OSHKOSH","code":"US-NE-OKS"},
          {"name":"OSMOND","code":"US-NE-ODQ"},
          {"name":"OVERTON","code":"US-NE-OV9"},
          {"name":"OXFORD","code":"US-NE-OSF"},
          {"name":"PAPILLION","code":"US-NE-IOX"},
          {"name":"PAWNEE CITY","code":"US-NE-PWX"},
          {"name":"PETERSBURG","code":"US-NE-PBP"},
          {"name":"PHILLIPS","code":"US-NE-PH9"},
          {"name":"PIERCE","code":"US-NE-P7N"},
          {"name":"PLAINVIEW","code":"US-NE-PFW"},
          {"name":"PLATTE CENTER","code":"US-NE-PDC"},
          {"name":"PLATTSMOUTH","code":"US-NE-YTM"},
          {"name":"PRAGUE","code":"US-NE-N7B"},
          {"name":"RALSTON","code":"US-NE-RAX"},
          {"name":"RANDOLPH","code":"US-NE-DOP"},
          {"name":"RAVENNA","code":"US-NE-RVD"},
          {"name":"RED CLOUD","code":"US-NE-R4C"},
          {"name":"RISING CITY","code":"US-NE-R9C"},
          {"name":"ROCA","code":"US-NE-RO5"},
          {"name":"RUSKIN","code":"US-NE-RXN"},
          {"name":"SAINT EDWARD","code":"US-NE-TDW"},
          {"name":"SALEM","code":"US-NE-S9L"},
          {"name":"SCHUYLER","code":"US-NE-SHJ"},
          {"name":"SCOTTSBLUFF","code":"US-NE-BFF"},
          {"name":"SCRIBNER","code":"US-NE-SCB"},
          {"name":"SEWARD","code":"US-NE-NSR"},
          {"name":"SHELBY","code":"US-NE-NE5"},
          {"name":"SHELTON","code":"US-NE-JLT"},
          {"name":"SIDNEY","code":"US-NE-SNY"},
          {"name":"SNYDER","code":"US-NE-SSN"},
          {"name":"SOUTH SIOUX CITY","code":"US-NE-SSO"},
          {"name":"SPARKS","code":"US-NE-KP3"},
          {"name":"SPRINGFIELD","code":"US-NE-S8P"},
          {"name":"SPRINGVIEW","code":"US-NE-SW9"},
          {"name":"STAPLETON","code":"US-NE-TPN"},
          {"name":"SUPERIOR","code":"US-NE-UPR"},
          {"name":"SUTHERLAND","code":"US-NE-UEN"},
          {"name":"SUTTON","code":"US-NE-S55"},
          {"name":"SYRACUSE","code":"US-NE-YRA"},
          {"name":"TAYLOR","code":"US-NE-TY4"},
          {"name":"TECUMSEH","code":"US-NE-T7E"},
          {"name":"TEKAMAH","code":"US-NE-TMH"},
          {"name":"THEDFORD","code":"US-NE-TDF"},
          {"name":"THURSTON","code":"US-NE-TQS"},
          {"name":"TILDEN","code":"US-NE-T4D"},
          {"name":"TRUMBULL","code":"US-NE-UUL"},
          {"name":"ULYSSES","code":"US-NE-UZS"},
          {"name":"UTICA","code":"US-NE-UUI"},
          {"name":"VALENTINE","code":"US-NE-VTN"},
          {"name":"VALLEY","code":"US-NE-VLY"},
          {"name":"VALPARAISO","code":"US-NE-VPO"},
          {"name":"WACO","code":"US-NE-WC8"},
          {"name":"WAHOO","code":"US-NE-NAO"},
          {"name":"WAKEFIELD","code":"US-NE-WJK"},
          {"name":"WALTON","code":"US-NE-W7L"},
          {"name":"WATERLOO","code":"US-NE-XWA"},
          {"name":"WAVERLY","code":"US-NE-WVB"},
          {"name":"WAYNE","code":"US-NE-WPY"},
          {"name":"WEEPING WATER","code":"US-NE-EPG"},
          {"name":"WEST POINT","code":"US-NE-WP8"},
          {"name":"WILBER","code":"US-NE-WI3"},
          {"name":"WINNEBAGO","code":"US-NE-NGX"},
          {"name":"WOOD RIVER","code":"US-NE-WR7"},
          {"name":"YORK","code":"US-NE-YRN"},
          {"name":"YUTAN","code":"US-NE-NYN"},]
      },
      {
        name: 'Nevada',
        code: 'US-NV',
        cities: [
          {"name":"ALAMO","code":"US-NV-AM8"},
          {"name":"AMARGOSA VALLEY","code":"US-NV-AGY"},
          {"name":"AUSTIN","code":"US-NV-ASQ"},
          {"name":"BAKER","code":"US-NV-B2K"},
          {"name":"BASALT","code":"US-NV-B7A"},
          {"name":"BATTLE MOUNTAIN","code":"US-NV-BAM"},
          {"name":"BEATTY","code":"US-NV-BTY"},
          {"name":"BEOWAWE","code":"US-NV-OWW"},
          {"name":"BLUE DIAMOND","code":"US-NV-NUU"},
          {"name":"BOULDER CITY","code":"US-NV-BLD"},
          {"name":"CARLIN","code":"US-NV-CNV"},
          {"name":"CARSON CITY","code":"US-NV-CSN"},
          {"name":"CRYSTAL BAY","code":"US-NV-N8V"},
          {"name":"DAYTON","code":"US-NV-DYJ"},
          {"name":"DYER","code":"US-NV-YEX"},
          {"name":"ELKO","code":"US-NV-EKO"},
          {"name":"ELY","code":"US-NV-ELY"},
          {"name":"EMPIRE","code":"US-NV-EPW"},
          {"name":"EUREKA","code":"US-NV-EUE"},
          {"name":"FALLON","code":"US-NV-NFL"},
          {"name":"FERNLEY","code":"US-NV-FYR"},
          {"name":"GABBS","code":"US-NV-GAB"},
          {"name":"GARDNERVILLE","code":"US-NV-GVF"},
          {"name":"GERLACH","code":"US-NV-GCH"},
          {"name":"GLENBROOK","code":"US-NV-N7E"},
          {"name":"GOLCONDA","code":"US-NV-GCA"},
          {"name":"GOODSPRINGS","code":"US-NV-G8S"},
          {"name":"HAWTHORNE","code":"US-NV-HTH"},
          {"name":"HAZEN","code":"US-NV-HZK"},
          {"name":"HENDERSON","code":"US-NV-HNZ"},
          {"name":"INCLINE VILLAGE","code":"US-NV-IVG"},
          {"name":"INDIAN SPRINGS","code":"US-NV-INS"},
          {"name":"JACKPOT","code":"US-NV-KPT"},
          {"name":"JEAN","code":"US-NV-JGE"},
          {"name":"LAS VEGAS","code":"US-NV-LAS"},
          {"name":"LATHROP WELLS","code":"US-NV-LTH"},
          {"name":"LAUGHLIN","code":"US-NV-LGH"},
          {"name":"LOCKWOOD","code":"US-NV-4LN"},
          {"name":"LOVELOCK","code":"US-NV-LOL"},
          {"name":"MANHATTAN","code":"US-NV-NXH"},
          {"name":"MCCARRAN","code":"US-NV-NVM"},
          {"name":"MERCURY","code":"US-NV-DRA"},
          {"name":"MESQUITE","code":"US-NV-MFH"},
          {"name":"MIDAS","code":"US-NV-MI9"},
          {"name":"MILL CITY","code":"US-NV-ICI"},
          {"name":"MINDEN","code":"US-NV-MEV"},
          {"name":"MOAPA","code":"US-NV-OAP"},
          {"name":"MOUND HOUSE","code":"US-NV-XZS"},
          {"name":"NELLIS AIR FORCE BASE","code":"US-NV-NS8"},
          {"name":"NORTH LAS VEGAS","code":"US-NV-NVS"},
          {"name":"OVERTON, CLARK","code":"US-NV-GHJ"},
          {"name":"PAHRUMP","code":"US-NV-PQV"},
          {"name":"PANACA","code":"US-NV-XPC"},
          {"name":"PARADISE VALLEY","code":"US-NV-P2V"},
          {"name":"PATRICK","code":"US-NV-PZK"},
          {"name":"PIOCHE","code":"US-NV-PI4"},
          {"name":"PRIMM","code":"US-NV-ZPM"},
          {"name":"RENO","code":"US-NV-RNO"},
          {"name":"RENO-STEAD","code":"US-NV-RS3"},
          {"name":"ROUND MOUNTAIN","code":"US-NV-ROM"},
          {"name":"RUBY VALLEY","code":"US-NV-RVU"},
          {"name":"RUTH","code":"US-NV-R8H"},
          {"name":"SANDY VALLEY","code":"US-NV-DV7"},
          {"name":"SILVER PEAK","code":"US-NV-IVP"},
          {"name":"SILVER SPRINGS","code":"US-NV-S4S"},
          {"name":"SLOAN","code":"US-NV-NN6"},
          {"name":"SPANISH SPRINGS","code":"US-NV-ZUS"},
          {"name":"SPARKS","code":"US-NV-SPK"},
          {"name":"SPRING CREEK","code":"US-NV-IGC"},
          {"name":"STATELINE","code":"US-NV-TTI"},
          {"name":"SUN VALLEY","code":"US-NV-JUV"},
          {"name":"TONOPAH","code":"US-NV-TPH"},
          {"name":"TUSCARORA","code":"US-NV-TU2"},
          {"name":"VALMY","code":"US-NV-VYM"},
          {"name":"VERDI","code":"US-NV-VEI"},
          {"name":"WADSWORTH","code":"US-NV-WW6"},
          {"name":"WELLS","code":"US-NV-LWL"},
          {"name":"WEST WENDOVER","code":"US-NV-WW4"},
          {"name":"WINNEMUCCA","code":"US-NV-WMC"},
          {"name":"YERINGTON","code":"US-NV-EYR"},
          {"name":"YUCCA FLAT","code":"US-NV-UCC"},
          {"name":"ZEPHYR COVE","code":"US-NV-ZFC"},
        ]
      },
      {
        name: 'Nuevo Hampshire',
        code: 'US-NH',
        cities: [
          {"name":"ACWORTH","code":"US-NH-6WT"},
          {"name":"ALLENSTOWN","code":"US-NH-ATX"},
          {"name":"ALSTEAD","code":"US-NH-AFA"},
          {"name":"ALTON BAY","code":"US-NH-A7B"},
          {"name":"AMHERST","code":"US-NH-UBI"},
          {"name":"ANDOVER","code":"US-NH-T3G"},
          {"name":"ANTRIM","code":"US-NH-AH3"},
          {"name":"ASHLAND","code":"US-NH-AHL"},
          {"name":"ATKINSON","code":"US-NH-TKD"},
          {"name":"AUBURN","code":"US-NH-AB9"},
          {"name":"BARNSTEAD","code":"US-NH-BA8"},
          {"name":"BARRINGTON","code":"US-NH-QRG"},
          {"name":"BARTLETT","code":"US-NH-ZLB"},
          {"name":"BATH","code":"US-NH-QBH"},
          {"name":"BEDFORD","code":"US-NH-DFR"},
          {"name":"BELMONT","code":"US-NH-BM8"},
          {"name":"BENNINGTON","code":"US-NH-GGT"},
          {"name":"BERLIN","code":"US-NH-BML"},
          {"name":"BETHLEHEM","code":"US-NH-EHE"},
          {"name":"BOSCAWEN","code":"US-NH-OSE"},
          {"name":"BOW","code":"US-NH-BOX"},
          {"name":"BOW CENTER","code":"US-NH-BC3"},
          {"name":"BRENTWOOD","code":"US-NH-RTW"},
          {"name":"BRETTON WOODS","code":"US-NH-BW6"},
          {"name":"BRISTOL","code":"US-NH-IOB"},
          {"name":"BROOKLINE","code":"US-NH-UBT"},
          {"name":"CAMPTON","code":"US-NH-CM6"},
          {"name":"CANAAN","code":"US-NH-NN2"},
          {"name":"CANDIA","code":"US-NH-9CN"},
          {"name":"CANTERBURY","code":"US-NH-NT5"},
          {"name":"CENTER HARBOR","code":"US-NH-H6C"},
          {"name":"CENTER TUFTONBORO","code":"US-NH-8TU"},
          {"name":"CHARLESTOWN","code":"US-NH-ZJD"},
          {"name":"CHESTER","code":"US-NH-KXR"},
          {"name":"CHESTERFIELD","code":"US-NH-CFG"},
          {"name":"CHICHESTER","code":"US-NH-YCH"},
          {"name":"CLAREMONT","code":"US-NH-CNH"},
          {"name":"COLEBROOK","code":"US-NH-CKO"},
          {"name":"CONCORD","code":"US-NH-CON"},
          {"name":"CONTOOCOOK","code":"US-NH-CTI"},
          {"name":"CONWAY","code":"US-NH-CWY"},
          {"name":"CORNISH","code":"US-NH-8CO"},
          {"name":"DALTON","code":"US-NH-DN4"},
          {"name":"DANBURY","code":"US-NH-DAU"},
          {"name":"DANVILLE","code":"US-NH-DHE"},
          {"name":"DEERFIELD","code":"US-NH-DFE"},
          {"name":"DERRY","code":"US-NH-DNP"},
          {"name":"DIXVILLE NOTCH","code":"US-NH-3DX"},
          {"name":"DOVER","code":"US-NH-DRV"},
          {"name":"DUBLIN","code":"US-NH-DZI"},
          {"name":"DURHAM","code":"US-NH-DH2"},
          {"name":"EAST KINGSTON","code":"US-NH-EK8"},
          {"name":"EAST SWANZEY","code":"US-NH-ES2"},
          {"name":"EATON CENTER","code":"US-NH-9EC"},
          {"name":"EFFINGHAM","code":"US-NH-IGJ"},
          {"name":"ELLSWORTH","code":"US-NH-EH3"},
          {"name":"ENFIELD","code":"US-NH-E4F"},
          {"name":"EPPING","code":"US-NH-EP7"},
          {"name":"EPSOM","code":"US-NH-8EP"},
          {"name":"ERROL","code":"US-NH-ERR"},
          {"name":"EXETER","code":"US-NH-EXE"},
          {"name":"FARMINGTON","code":"US-NH-FPH"},
          {"name":"FITZWILLIAM","code":"US-NH-FZW"},
          {"name":"FRANCESTOWN","code":"US-NH-F9S"},
          {"name":"FRANCONIA","code":"US-NH-FVA"},
          {"name":"FRANKLIN","code":"US-NH-FKI"},
          {"name":"FREMONT","code":"US-NH-F8R"},
          {"name":"GEORGES MILLS","code":"US-NH-GML"},
          {"name":"GILFORD","code":"US-NH-GLF"},
          {"name":"GILMANTON","code":"US-NH-G7W"},
          {"name":"GILMANTON","code":"US-NH-GYM"},
          {"name":"GILSUM","code":"US-NH-G9M"},
          {"name":"GOFFSTOWN","code":"US-NH-GFW"},
          {"name":"GONIC","code":"US-NH-8NH"},
          {"name":"GORHAM","code":"US-NH-GHA"},
          {"name":"GOSHEN","code":"US-NH-8GO"},
          {"name":"GRAFTON","code":"US-NH-GPN"},
          {"name":"GREENFIELD","code":"US-NH-GF9"},
          {"name":"GREENLAND","code":"US-NH-GNL"},
          {"name":"GREENVILLE","code":"US-NH-GGV"},
          {"name":"GROTON","code":"US-NH-GZT"},
          {"name":"GROVETON","code":"US-NH-GRT"},
          {"name":"GUILD","code":"US-NH-GUD"},
          {"name":"HAMPSTEAD","code":"US-NH-5MP"},
          {"name":"HAMPTON","code":"US-NH-HAX"},
          {"name":"HAMPTON FALLS","code":"US-NH-HXP"},
          {"name":"HAMSHIRE","code":"US-NH-HHZ"},
          {"name":"HANCOCK","code":"US-NH-8HA"},
          {"name":"HANOVER","code":"US-NH-ZHV"},
          {"name":"HANOVER-LEBANON-WHITE RIVER APT","code":"US-NH-LEB"},
          {"name":"HARRISVILLE","code":"US-NH-H3R"},
          {"name":"HAVERHILL","code":"US-NH-VVH"},
          {"name":"HENNIKER","code":"US-NH-HKR"},
          {"name":"HILL","code":"US-NH-8HI"},
          {"name":"HILLSBORO","code":"US-NH-QHI"},
          {"name":"HINSDALE","code":"US-NH-HDL"},
          {"name":"HOLDERNESS","code":"US-NH-8HL"},
          {"name":"HOLLIS","code":"US-NH-HSH"},
          {"name":"HOOKSETT","code":"US-NH-KSH"},
          {"name":"HUDSON","code":"US-NH-HDU"},
          {"name":"JACKSON","code":"US-NH-5KS"},
          {"name":"JAFFREY","code":"US-NH-AFN"},
          {"name":"JEFFERSON","code":"US-NH-JRS"},
          {"name":"KEENE","code":"US-NH-EEN"},
          {"name":"KINGSTON","code":"US-NH-KT5"},
          {"name":"LACONIA","code":"US-NH-LCI"},
          {"name":"LANCASTER","code":"US-NH-TR6"},
          {"name":"LANGDON","code":"US-NH-GXJ"},
          {"name":"LEBANON","code":"US-NH-LN7"},
          {"name":"LEE","code":"US-NH-VNV"},
          {"name":"LEMPSTER","code":"US-NH-8LE"},
          {"name":"LINCOLN","code":"US-NH-NH5"},
          {"name":"LISBON","code":"US-NH-LJB"},
          {"name":"LITCHFIELD","code":"US-NH-L9T"},
          {"name":"LITTLETON","code":"US-NH-LTL"},
          {"name":"LONDONDERRY","code":"US-NH-LOD"},
          {"name":"LOUDON","code":"US-NH-N3L"},
          {"name":"LYME","code":"US-NH-LMQ"},
          {"name":"LYNDEBOROUGH","code":"US-NH-LB6"},
          {"name":"MADBURY","code":"US-NH-2MB"},
          {"name":"MADISON","code":"US-NH-6SN"},
          {"name":"MANCHESTER","code":"US-NH-MHT"},
          {"name":"MARLBOROUGH","code":"US-NH-MGX"},
          {"name":"MARLOW","code":"US-NH-8MR"},
          {"name":"MELVIN VILLAGE","code":"US-NH-9VL"},
          {"name":"MEREDITH","code":"US-NH-MD5"},
          {"name":"MERRIMACK","code":"US-NH-MRM"},
          {"name":"MILAN","code":"US-NH-9MI"},
          {"name":"MILFORD","code":"US-NH-MFW"},
          {"name":"MILTON","code":"US-NH-IJL"},
          {"name":"MILTON MILLS","code":"US-NH-M2M"},
          {"name":"MIRROR LAKE","code":"US-NH-IRL"},
          {"name":"MONROE","code":"US-NH-M4X"},
          {"name":"MONT VERNON","code":"US-NH-TV7"},
          {"name":"MOULTONBOROUGH","code":"US-NH-QUG"},
          {"name":"NASHUA","code":"US-NH-ASH"},
          {"name":"NELSON","code":"US-NH-N8L"},
          {"name":"NEW BOSTON","code":"US-NH-B5T"},
          {"name":"NEW CASTLE","code":"US-NH-6CW"},
          {"name":"NEW DURHAM","code":"US-NH-8ND"},
          {"name":"NEW HAMPTON","code":"US-NH-P6W"},
          {"name":"NEW IPSWICH","code":"US-NH-QNN"},
          {"name":"NEW LONDON","code":"US-NH-6WN"},
          {"name":"NEW LONDONDERRY","code":"US-NH-NL2"},
          {"name":"NEWBURY","code":"US-NH-8NW"},
          {"name":"NEWFIELDS","code":"US-NH-NEI"},
          {"name":"NEWINGTON","code":"US-NH-NWZ"},
          {"name":"NEWMARKET","code":"US-NH-NMK"},
          {"name":"NEWPORT","code":"US-NH-NWH"},
          {"name":"NEWTON","code":"US-NH-NWU"},
          {"name":"NORTH CONWAY","code":"US-NH-NC5"},
          {"name":"NORTH HAMPTON","code":"US-NH-JNH"},
          {"name":"NORTH STRATFORD","code":"US-NH-8DN"},
          {"name":"NORTH WALPOLE","code":"US-NH-NJW"},
          {"name":"NORTH WOODSTOCK","code":"US-NH-YNO"},
          {"name":"NORTHFIELD","code":"US-NH-QQF"},
          {"name":"NORTHWOOD","code":"US-NH-N5W"},
          {"name":"NOTTINGHAM","code":"US-NH-NT4"},
          {"name":"ORFORD","code":"US-NH-OF2"},
          {"name":"OSSIPEE","code":"US-NH-N8H"},
          {"name":"PELHAM","code":"US-NH-QPE"},
          {"name":"PEMBROKE","code":"US-NH-JUY"},
          {"name":"PENACOOK","code":"US-NH-YPJ"},
          {"name":"PETERBOROUGH","code":"US-NH-YPB"},
          {"name":"PIERMONT","code":"US-NH-PI3"},
          {"name":"PITTSBURG","code":"US-NH-6TS"},
          {"name":"PITTSFIELD","code":"US-NH-ITT"},
          {"name":"PLAINFIELD","code":"US-NH-YPD"},
          {"name":"PLAISTOW","code":"US-NH-PZX"},
          {"name":"PLYMOUTH","code":"US-NH-PVH"},
          {"name":"PORTSMOUTH","code":"US-NH-PSM"},
          {"name":"RANDOLPH","code":"US-NH-8RA"},
          {"name":"RAYMOND","code":"US-NH-RYO"},
          {"name":"RICHMOND","code":"US-NH-IHD"},
          {"name":"RINDGE","code":"US-NH-9RI"},
          {"name":"ROCHESTER","code":"US-NH-ROR"},
          {"name":"ROLLINSFORD","code":"US-NH-R3O"},
          {"name":"RUMNEY","code":"US-NH-RU8"},
          {"name":"RYE","code":"US-NH-RYJ"},
          {"name":"RYE BEACH","code":"US-NH-RH6"},
          {"name":"SALEM","code":"US-NH-SXA"},
          {"name":"SALISBURY","code":"US-NH-SY6"},
          {"name":"SANBORNTON","code":"US-NH-SZ2"},
          {"name":"SANBORNVILLE","code":"US-NH-SV2"},
          {"name":"SANDOWN","code":"US-NH-S8A"},
          {"name":"SEABROOK","code":"US-NH-EBK"},
          {"name":"SILVER LAKE","code":"US-NH-9KS"},
          {"name":"SOMERSWORTH","code":"US-NH-SSX"},
          {"name":"SOUTH HAMPTON","code":"US-NH-ZJX"},
          {"name":"SPOFFORD","code":"US-NH-PFZ"},
          {"name":"SPRINGFIELD","code":"US-NH-IED"},
          {"name":"STODDARD","code":"US-NH-8SO"},
          {"name":"STRAFFORD","code":"US-NH-8FF"},
          {"name":"STRATHAM","code":"US-NH-SMH"},
          {"name":"SUGAR HILL","code":"US-NH-9SU"},
          {"name":"SULLIVAN","code":"US-NH-ULV"},
          {"name":"SUNAPEE","code":"US-NH-KEP"},
          {"name":"SUNCOOK","code":"US-NH-SKQ"},
          {"name":"SUTTON","code":"US-NH-8UT"},
          {"name":"SWANZEY","code":"US-NH-SW5"},
          {"name":"TAMWORTH","code":"US-NH-RMX"},
          {"name":"TEMPLE","code":"US-NH-9TP"},
          {"name":"THORNTON","code":"US-NH-TH5"},
          {"name":"TILTON","code":"US-NH-TL2"},
          {"name":"TROY","code":"US-NH-T6Y"},
          {"name":"TWIN MOUNTAIN","code":"US-NH-8WN"},
          {"name":"WALPOLE","code":"US-NH-WLP"},
          {"name":"WARNER","code":"US-NH-JRN"},
          {"name":"WARREN","code":"US-NH-8WA"},
          {"name":"WASHINGTON","code":"US-NH-8WS"},
          {"name":"WATERVILLE VALLEY","code":"US-NH-WZY"},
          {"name":"WEARE","code":"US-NH-WCJ"},
          {"name":"WENTWORTH","code":"US-NH-WNH"},
          {"name":"WEST CHESTERFIELD","code":"US-NH-6WF"},
          {"name":"WEST LEBANON","code":"US-NH-WPQ"},
          {"name":"WEST OSSIPEE","code":"US-NH-W8O"},
          {"name":"WEST PETERBOROUGH","code":"US-NH-8WP"},
          {"name":"WEST SPRINGFIELD","code":"US-NH-WPG"},
          {"name":"WEST SWANZEY","code":"US-NH-NZY"},
          {"name":"WESTMORELAND","code":"US-NH-W3M"},
          {"name":"WHITEFIELD","code":"US-NH-HIE"},
          {"name":"WILMOT","code":"US-NH-ZFS"},
          {"name":"WILTON","code":"US-NH-WIO"},
          {"name":"WINCHESTER","code":"US-NH-WCH"},
          {"name":"WINDHAM","code":"US-NH-WKH"},
          {"name":"WOLFEBORO","code":"US-NH-WFB"},
          {"name":"WOODSTOCK","code":"US-NH-ZW3"},
          {"name":"WOODSVILLE","code":"US-NH-WOV"},
        ]
      },
      {
        name: 'Nueva Jersey',
        code: 'US-NJ',
        cities: [
          {"name":"ABERDEEN","code":"US-NJ-AB3"},
          {"name":"ABSECON","code":"US-NJ-ACX"},
          {"name":"ADELPHIA","code":"US-NJ-AHP"},
          {"name":"ALLAMUCHY","code":"US-NJ-UOY"},
          {"name":"ALLENDALE","code":"US-NJ-UAZ"},
          {"name":"ALLENHURST","code":"US-NJ-NUS"},
          {"name":"ALLENTOWN","code":"US-NJ-AEW"},
          {"name":"ALLENWOOD","code":"US-NJ-AWO"},
          {"name":"ALPHA","code":"US-NJ-APA"},
          {"name":"ALPINE","code":"US-NJ-APY"},
          {"name":"AMBROSE CHANNEL LIGHT","code":"US-NJ-QHH"},
          {"name":"ANDOVER","code":"US-NJ-AN9"},
          {"name":"ANNANDALE","code":"US-NJ-A7N"},
          {"name":"APPLEGARTH","code":"US-NJ-APR"},
          {"name":"ARDENA","code":"US-NJ-ZAA"},
          {"name":"ARLINGTON","code":"US-NJ-QAR"},
          {"name":"ASBURY","code":"US-NJ-AJU"},
          {"name":"ASBURY PARK","code":"US-NJ-ARX"},
          {"name":"ASBURY, GLOUCESTER","code":"US-NJ-ASB"},
          {"name":"ASBURY, WARREN","code":"US-NJ-ASU"},
          {"name":"ATLANTIC CITY","code":"US-NJ-AIY"},
          {"name":"ATLANTIC HIGHLANDS","code":"US-NJ-AHZ"},
          {"name":"AUGUSTA","code":"US-NJ-AU2"},
          {"name":"AURA","code":"US-NJ-KAR"},
          {"name":"AVALON","code":"US-NJ-9AV"},
          {"name":"AVENEL","code":"US-NJ-AVE"},
          {"name":"AVON-BY-THE-SEA","code":"US-NJ-AVH"},
          {"name":"BAIRDSVILLE","code":"US-NJ-BJ2"},
          {"name":"BARNEGAT","code":"US-NJ-BG7"},
          {"name":"BARRINGTON","code":"US-NJ-BRN"},
          {"name":"BASKING RIDGE","code":"US-NJ-BSK"},
          {"name":"BAY HEAD","code":"US-NJ-982"},
          {"name":"BAYONNE","code":"US-NJ-BAY"},
          {"name":"BAYVILLE","code":"US-NJ-BQX"},
          {"name":"BAYWAY","code":"US-NJ-8BY"},
          {"name":"BEACH HAVEN","code":"US-NJ-EHX"},
          {"name":"BEACHWOOD","code":"US-NJ-JWD"},
          {"name":"BEATTYSTOWN","code":"US-NJ-BCQ"},
          {"name":"BEDMINSTER","code":"US-NJ-BCD"},
          {"name":"BELFORD","code":"US-NJ-QBF"},
          {"name":"BELLE MEAD","code":"US-NJ-BLJ"},
          {"name":"BELLEVILLE","code":"US-NJ-UBU"},
          {"name":"BELLMAWR","code":"US-NJ-BWR"},
          {"name":"BELMAR","code":"US-NJ-BLM"},
          {"name":"BELVIDERE","code":"US-NJ-XBU"},
          {"name":"BERGEN","code":"US-NJ-2BG"},
          {"name":"BERGENFIELD","code":"US-NJ-BRF"},
          {"name":"BERKELEY HEIGHTS","code":"US-NJ-BHE"},
          {"name":"BERLIN","code":"US-NJ-XBT"},
          {"name":"BERNARDSVILLE","code":"US-NJ-BRV"},
          {"name":"BEVERLY","code":"US-NJ-XDF"},
          {"name":"BIRCH HILLS","code":"US-NJ-BI4"},
          {"name":"BIRMINGHAM","code":"US-NJ-BIN"},
          {"name":"BLACKWOOD","code":"US-NJ-BQW"},
          {"name":"BLAIRSTOWN","code":"US-NJ-B4L"},
          {"name":"BLOOMFIELD","code":"US-NJ-BMN"},
          {"name":"BLOOMINGDALE","code":"US-NJ-BGL"},
          {"name":"BLOOMSBURY","code":"US-NJ-BBQ"},
          {"name":"BLUE ANCHOR","code":"US-NJ-BQJ"},
          {"name":"BOGOTA","code":"US-NJ-BOG"},
          {"name":"BONHAMTOWN","code":"US-NJ-BPW"},
          {"name":"BOONTON","code":"US-NJ-BTB"},
          {"name":"BORDENTOWN","code":"US-NJ-BOJ"},
          {"name":"BOUND BROOK","code":"US-NJ-BBK"},
          {"name":"BRADEVELT","code":"US-NJ-BJV"},
          {"name":"BRADLEY BEACH","code":"US-NJ-QBD"},
          {"name":"BRAINARDS","code":"US-NJ-BQS"},
          {"name":"BRANCHBURG","code":"US-NJ-9BG"},
          {"name":"BRANCHBURG PARK","code":"US-NJ-BNU"},
          {"name":"BRANCHVILLE","code":"US-NJ-AHI"},
          {"name":"BRICK","code":"US-NJ-YBK"},
          {"name":"BRICKTOWN","code":"US-NJ-BIT"},
          {"name":"BRIDGEPORT","code":"US-NJ-BPG"},
          {"name":"BRIDGETON","code":"US-NJ-BGY"},
          {"name":"BRIDGEWATER","code":"US-NJ-BQF"},
          {"name":"BROADWAY","code":"US-NJ-BPQ"},
          {"name":"BROOKLAWN","code":"US-NJ-B8K"},
          {"name":"BROOKSIDE","code":"US-NJ-5OO"},
          {"name":"BROWNS MILLS","code":"US-NJ-JBJ"},
          {"name":"BROWNS MILLS","code":"US-NJ-QXY"},
          {"name":"BRUNSWICK GARDENS","code":"US-NJ-BW4"},
          {"name":"BUDD LAKE","code":"US-NJ-BDK"},
          {"name":"BUENA","code":"US-NJ-BJY"},
          {"name":"BURLINGTON","code":"US-NJ-BUH"},
          {"name":"BUTLER","code":"US-NJ-BGG"},
          {"name":"CALDWELL","code":"US-NJ-CDW"},
          {"name":"CALIFON","code":"US-NJ-QCJ"},
          {"name":"CAMDEN","code":"US-NJ-CDE"},
          {"name":"CANTON","code":"US-NJ-T9N"},
          {"name":"CAPE MAY","code":"US-NJ-CGF"},
          {"name":"CAPE MAY COURT HOUSE","code":"US-NJ-ZQH"},
          {"name":"CARLSTADT","code":"US-NJ-UAK"},
          {"name":"CARNEYS POINT","code":"US-NJ-AEY"},
          {"name":"CARRS CORNER","code":"US-NJ-C97"},
          {"name":"CARTERET","code":"US-NJ-CSF"},
          {"name":"CEDAR BROOK","code":"US-NJ-NQB"},
          {"name":"CEDAR GROVE","code":"US-NJ-CED"},
          {"name":"CEDAR KNOLLS","code":"US-NJ-CJJ"},
          {"name":"CEDARVILLE","code":"US-NJ-XDQ"},
          {"name":"CHARLESTON SPRINGS","code":"US-NJ-C64"},
          {"name":"CHATHAM","code":"US-NJ-CH8"},
          {"name":"CHATSWORTH","code":"US-NJ-7CW"},
          {"name":"CHERRY HILL","code":"US-NJ-CHX"},
          {"name":"CHESTER","code":"US-NJ-CYZ"},
          {"name":"CHESTERFIELD","code":"US-NJ-C7F"},
          {"name":"CHROME","code":"US-NJ-HYM"},
          {"name":"CINNAMINSON","code":"US-NJ-XDU"},
          {"name":"CLARK","code":"US-NJ-NJC"},
          {"name":"CLARKSBORO","code":"US-NJ-CKJ"},
          {"name":"CLARKSBURG","code":"US-NJ-CG4"},
          {"name":"CLAYTON","code":"US-NJ-CYN"},
          {"name":"CLEMENTON","code":"US-NJ-C2E"},
          {"name":"CLERMONT","code":"US-NJ-JC5"},
          {"name":"CLIFFSIDE PARK","code":"US-NJ-CSD"},
          {"name":"CLIFFWOOD","code":"US-NJ-CLF"},
          {"name":"CLIFTON","code":"US-NJ-CFU"},
          {"name":"CLINTON","code":"US-NJ-CNG"},
          {"name":"CLINTON TOWNSHIP","code":"US-NJ-CQJ"},
          {"name":"CLOSTER/NEW JERSEY","code":"US-NJ-CXR"},
          {"name":"CLYDE","code":"US-NJ-C3Y"},
          {"name":"COLLINGSWOOD","code":"US-NJ-CWD"},
          {"name":"COLONIA","code":"US-NJ-CZ8"},
          {"name":"COLTS NECK","code":"US-NJ-CTN"},
          {"name":"COLUMBIA","code":"US-NJ-YNJ"},
          {"name":"COLUMBUS","code":"US-NJ-YBC"},
          {"name":"COONTOWN","code":"US-NJ-C89"},
          {"name":"CORBIN CITY","code":"US-NJ-IOY"},
          {"name":"CRANBURY","code":"US-NJ-CQE"},
          {"name":"CRANDON LAKES","code":"US-NJ-UFD"},
          {"name":"CRANFORD","code":"US-NJ-CQG"},
          {"name":"CREAM RIDGE","code":"US-NJ-CR3"},
          {"name":"CRESSKILL","code":"US-NJ-CYJ"},
          {"name":"CROXTON","code":"US-NJ-V7X"},
          {"name":"DAYTON","code":"US-NJ-DYT"},
          {"name":"DEAL","code":"US-NJ-DAF"},
          {"name":"DEANS","code":"US-NJ-DEJ"},
          {"name":"DEEPWATER","code":"US-NJ-DPW"},
          {"name":"DEER PARK, CAMDEN","code":"US-NJ-DP2"},
          {"name":"DEERFIELD","code":"US-NJ-DJ3"},
          {"name":"DELAIR","code":"US-NJ-DLA"},
          {"name":"DELANCO","code":"US-NJ-DLJ"},
          {"name":"DELAWARE","code":"US-NJ-D3W"},
          {"name":"DELRAN","code":"US-NJ-DRJ"},
          {"name":"DEMAREST","code":"US-NJ-DM8"},
          {"name":"DENNISVILLE","code":"US-NJ-DJV"},
          {"name":"DENVILLE","code":"US-NJ-DNL"},
          {"name":"DEPTFORD","code":"US-NJ-DPF"},
          {"name":"DEPTFORD TOWNSHIP","code":"US-NJ-DWB"},
          {"name":"DOVER","code":"US-NJ-DVJ"},
          {"name":"DUMONT","code":"US-NJ-DMJ"},
          {"name":"DUNELLEN","code":"US-NJ-DUL"},
          {"name":"EAGLE POINT","code":"US-NJ-EAG"},
          {"name":"EARLE","code":"US-NJ-EAE"},
          {"name":"EAST BRUNSWICK","code":"US-NJ-EBR"},
          {"name":"EAST FREEHOLD","code":"US-NJ-EFJ"},
          {"name":"EAST HANOVER","code":"US-NJ-EHA"},
          {"name":"EAST KEANSBURG","code":"US-NJ-EKB"},
          {"name":"EAST MILLSTONE","code":"US-NJ-EA9"},
          {"name":"EAST NEWARK","code":"US-NJ-ETK"},
          {"name":"EAST ORANGE","code":"US-NJ-EOG"},
          {"name":"EAST RUTHERFORD","code":"US-NJ-ERU"},
          {"name":"EAST WINDSOR","code":"US-NJ-EWS"},
          {"name":"EASTAMPTON TOWNSHIP","code":"US-NJ-NXM"},
          {"name":"EATONTOWN","code":"US-NJ-EAJ"},
          {"name":"EDGAR","code":"US-NJ-ED9"},
          {"name":"EDGEWATER","code":"US-NJ-EGW"},
          {"name":"EDGEWATER PARK","code":"US-NJ-ET8"},
          {"name":"EDGEWOOD","code":"US-NJ-EDD"},
          {"name":"EDISON","code":"US-NJ-EDN"},
          {"name":"EGG HARBOR CITY","code":"US-NJ-EGG"},
          {"name":"EGG HARBOR TOWNSHIP","code":"US-NJ-GWP"},
          {"name":"ELBERT","code":"US-NJ-JLB"},
          {"name":"ELIZABETH","code":"US-NJ-EZA"},
          {"name":"ELIZABETHPORT","code":"US-NJ-ZIP"},
          {"name":"ELLISDALE","code":"US-NJ-ESJ"},
          {"name":"ELMER","code":"US-NJ-EL4"},
          {"name":"ELMWOOD PARK","code":"US-NJ-EWP"},
          {"name":"ELWOOD","code":"US-NJ-EWQ"},
          {"name":"EMERSON","code":"US-NJ-EQS"},
          {"name":"ENGLEWOOD","code":"US-NJ-EWD"},
          {"name":"ENGLEWOOD CLIFFS","code":"US-NJ-ENG"},
          {"name":"ENGLISHTOWN","code":"US-NJ-UFX"},
          {"name":"ERIAL","code":"US-NJ-QRL"},
          {"name":"ESSEX FELLS","code":"US-NJ-EXF"},
          {"name":"EVERETT","code":"US-NJ-EV4"},
          {"name":"EWING","code":"US-NJ-QEI"},
          {"name":"FAIR HAVEN","code":"US-NJ-FH2"},
          {"name":"FAIR LAWN","code":"US-NJ-FLY"},
          {"name":"FAIR VIEW","code":"US-NJ-AIW"},
          {"name":"FAIRFIELD","code":"US-NJ-FLA"},
          {"name":"FAIRTON","code":"US-NJ-NJF"},
          {"name":"FAIRVIEW","code":"US-NJ-FRE"},
          {"name":"FANWOOD","code":"US-NJ-FWJ"},
          {"name":"FAR HILLS","code":"US-NJ-FHS"},
          {"name":"FARMINGDALE","code":"US-NJ-FMJ"},
          {"name":"FARMINGTON","code":"US-NJ-FZM"},
          {"name":"FIELDSBORO","code":"US-NJ-FDJ"},
          {"name":"FINDERNE","code":"US-NJ-XFL"},
          {"name":"FLAGTOWN","code":"US-NJ-FTO"},
          {"name":"FLANDERS","code":"US-NJ-FDZ"},
          {"name":"FLEMINGTON","code":"US-NJ-FLE"},
          {"name":"FLORENCE","code":"US-NJ-FLR"},
          {"name":"FLORHAM PARK","code":"US-NJ-FP2"},
          {"name":"FOLSOM","code":"US-NJ-FM3"},
          {"name":"FORDS","code":"US-NJ-AEB"},
          {"name":"FORKED RIVER","code":"US-NJ-FDV"},
          {"name":"FORT DIX","code":"US-NJ-WRI"},
          {"name":"FORT LEE","code":"US-NJ-FOL"},
          {"name":"FORT MONMOUTH","code":"US-NJ-FTM"},
          {"name":"FRANKLIN","code":"US-NJ-FNN"},
          {"name":"FRANKLIN LAKES","code":"US-NJ-FRL"},
          {"name":"FRANKLINVILLE","code":"US-NJ-FQV"},
          {"name":"FREEHOLD","code":"US-NJ-FEH"},
          {"name":"FRENCHTOWN","code":"US-NJ-JWY"},
          {"name":"GALLOWAY","code":"US-NJ-GXW"},
          {"name":"GARDEN CITY","code":"US-NJ-GAR"},
          {"name":"GARFIELD","code":"US-NJ-GAF"},
          {"name":"GARWOOD","code":"US-NJ-XFZ"},
          {"name":"GEORGIA","code":"US-NJ-G5G"},
          {"name":"GIBBSBORO","code":"US-NJ-GBB"},
          {"name":"GIBBSTOWN","code":"US-NJ-GIB"},
          {"name":"GILLETTE","code":"US-NJ-GIL"},
          {"name":"GLASSBORO","code":"US-NJ-GLR"},
          {"name":"GLEN GARDNER","code":"US-NJ-QGG"},
          {"name":"GLEN RIDGE","code":"US-NJ-GJQ"},
          {"name":"GLEN ROCK","code":"US-NJ-GLK"},
          {"name":"GLENDORA","code":"US-NJ-G7Y"},
          {"name":"GLOUCESTER CITY","code":"US-NJ-GLC"},
          {"name":"GREAT MEADOWS","code":"US-NJ-W9G"},
          {"name":"GREEN BROOK","code":"US-NJ-GBK"},
          {"name":"GREEN POND","code":"US-NJ-GPD"},
          {"name":"GRENLOCH","code":"US-NJ-GL2"},
          {"name":"GUTTENBERG","code":"US-NJ-GUG"},
          {"name":"HACKENSACK","code":"US-NJ-HCK"},
          {"name":"HACKETTSTOWN","code":"US-NJ-HCT"},
          {"name":"HADDON HEIGHTS","code":"US-NJ-HH9"},
          {"name":"HADDONFIELD","code":"US-NJ-HFL"},
          {"name":"HAINESPORT","code":"US-NJ-HNR"},
          {"name":"HALEDON","code":"US-NJ-HLD"},
          {"name":"HAMBURG","code":"US-NJ-HAM"},
          {"name":"HAMILTON SQUARE","code":"US-NJ-HSQ"},
          {"name":"HAMILTON, MONMOUTH","code":"US-NJ-HMG"},
          {"name":"HAMILTON, OCEAN","code":"US-NJ-HJO"},
          {"name":"HAMMONTON","code":"US-NJ-XGL"},
          {"name":"HAMPTON","code":"US-NJ-GMP"},
          {"name":"HANCOCKS BRIDGE","code":"US-NJ-H6B"},
          {"name":"HANOVER","code":"US-NJ-HAN"},
          {"name":"HARDWICK","code":"US-NJ-HJ7"},
          {"name":"HARRINGTON PARK","code":"US-NJ-HGP"},
          {"name":"HARRISON","code":"US-NJ-HRN"},
          {"name":"HARVEY CEDARS","code":"US-NJ-HC3"},
          {"name":"HASBROUCK HEIGHTS","code":"US-NJ-ZHU"},
          {"name":"HASKELL","code":"US-NJ-QHJ"},
          {"name":"HAWORTH","code":"US-NJ-H3W"},
          {"name":"HAWTHORNE","code":"US-NJ-HTI"},
          {"name":"HAZLET","code":"US-NJ-HZE"},
          {"name":"HERBERTSVILLE","code":"US-NJ-DF4"},
          {"name":"HIBERNIA","code":"US-NJ-HFB"},
          {"name":"HIGH BRIDGE","code":"US-NJ-H9B"},
          {"name":"HIGHLANDS","code":"US-NJ-HGS"},
          {"name":"HIGHTSTOWN","code":"US-NJ-UJT"},
          {"name":"HILLSBOROUGH","code":"US-NJ-HSU"},
          {"name":"HILLSDALE","code":"US-NJ-IDU"},
          {"name":"HILLSIDE","code":"US-NJ-HLS"},
          {"name":"HO-HO-KUS","code":"US-NJ-HHK"},
          {"name":"HOBOKEN","code":"US-NJ-HBK"},
          {"name":"HOLMDEL","code":"US-NJ-HDZ"},
          {"name":"HOPATCONG","code":"US-NJ-8HO"},
          {"name":"HOPE","code":"US-NJ-H9E"},
          {"name":"HOPELAWN","code":"US-NJ-HZJ"},
          {"name":"HOPEWELL","code":"US-NJ-HEW"},
          {"name":"HOWELL","code":"US-NJ-HOJ"},
          {"name":"HOWLAND HOOK","code":"US-NJ-H4H"},
          {"name":"HURFFVILLE","code":"US-NJ-HV7"},
          {"name":"INDIAN MILLS","code":"US-NJ-TGB"},
          {"name":"IRVINGTON","code":"US-NJ-IRJ"},
          {"name":"ISELIN","code":"US-NJ-INJ"},
          {"name":"JACKSON","code":"US-NJ-NJJ"},
          {"name":"JACKSONVILLE","code":"US-NJ-JQS"},
          {"name":"JACOBSTOWN","code":"US-NJ-OWS"},
          {"name":"JAMESBURG","code":"US-NJ-JMB"},
          {"name":"JERSEY CITY","code":"US-NJ-JEC"},
          {"name":"JOBSTOWN","code":"US-NJ-QJB"},
          {"name":"JOHNSONBURG","code":"US-NJ-JO2"},
          {"name":"KEANSBURG","code":"US-NJ-KUG"},
          {"name":"KEARNY","code":"US-NJ-XHE"},
          {"name":"KEASBEY","code":"US-NJ-KEA"},
          {"name":"KENDALL PARK","code":"US-NJ-KU4"},
          {"name":"KENILWORTH","code":"US-NJ-KWO"},
          {"name":"KENVIL","code":"US-NJ-KV5"},
          {"name":"KEYPORT","code":"US-NJ-KEY"},
          {"name":"KINGSTON","code":"US-NJ-KNJ"},
          {"name":"KINNELON","code":"US-NJ-UJX"},
          {"name":"LAFAYETTE","code":"US-NJ-LFW"},
          {"name":"LAKE HIAWATHA","code":"US-NJ-LHW"},
          {"name":"LAKE HOPATCONG","code":"US-NJ-K89"},
          {"name":"LAKEHURST","code":"US-NJ-NEL"},
          {"name":"LAKEWOOD","code":"US-NJ-LKW"},
          {"name":"LAMBERTVILLE","code":"US-NJ-LMV"},
          {"name":"LANDING","code":"US-NJ-LZV"},
          {"name":"LANDISVILLE","code":"US-NJ-LDV"},
          {"name":"LANOKA HARBOR","code":"US-NJ-LN8"},
          {"name":"LAURENCE HARBOR","code":"US-NJ-5BL"},
          {"name":"LAVALLETTE","code":"US-NJ-ZVE"},
          {"name":"LAWNSIDE","code":"US-NJ-LW7"},
          {"name":"LAWRENCE TOWNSHIP","code":"US-NJ-LTP"},
          {"name":"LAWRENCEVILLE","code":"US-NJ-LRV"},
          {"name":"LEBANON","code":"US-NJ-XHL"},
          {"name":"LEDGEWOOD","code":"US-NJ-LWO"},
          {"name":"LEONARDO","code":"US-NJ-DOJ"},
          {"name":"LEONIA","code":"US-NJ-QLO"},
          {"name":"LINCOLN PARK","code":"US-NJ-LPJ"},
          {"name":"LINCROFT","code":"US-NJ-ZLJ"},
          {"name":"LINDEN","code":"US-NJ-LDJ"},
          {"name":"LINDENWOLD","code":"US-NJ-LW3"},
          {"name":"LINWOOD","code":"US-NJ-IWC"},
          {"name":"LITTLE FALLS","code":"US-NJ-LFJ"},
          {"name":"LITTLE FERRY","code":"US-NJ-LTF"},
          {"name":"LITTLE SILVER","code":"US-NJ-EIR"},
          {"name":"LIVINGSTON","code":"US-NJ-LVG"},
          {"name":"LOCUST","code":"US-NJ-XLT"},
          {"name":"LODI","code":"US-NJ-LOI"},
          {"name":"LOGAN TOWNSHIP","code":"US-NJ-YLO"},
          {"name":"LONG BEACH","code":"US-NJ-LB3"},
          {"name":"LONG BRANCH","code":"US-NJ-LCJ"},
          {"name":"LONG VALLEY","code":"US-NJ-LGJ"},
          {"name":"LONGPORT","code":"US-NJ-UNJ"},
          {"name":"LOPATCONG","code":"US-NJ-LJP"},
          {"name":"LUMBERTON","code":"US-NJ-LUM"},
          {"name":"LYNDHURST","code":"US-NJ-LDT"},
          {"name":"MADISON","code":"US-NJ-QMJ"},
          {"name":"MAHER TERMINAL","code":"US-NJ-MQ4"},
          {"name":"MAHWAH","code":"US-NJ-MHW"},
          {"name":"MALAGA","code":"US-NJ-MGA"},
          {"name":"MANAHAWKIN","code":"US-NJ-AAW"},
          {"name":"MANALAPAN","code":"US-NJ-APZ"},
          {"name":"MANASQUAN","code":"US-NJ-ZMQ"},
          {"name":"MANCHESTER","code":"US-NJ-M7W"},
          {"name":"MANCHESTER","code":"US-NJ-NMJ"},
          {"name":"MANNINGTON","code":"US-NJ-MT7"},
          {"name":"MANNINGTON MILLS","code":"US-NJ-AIM"},
          {"name":"MANTOLOKING","code":"US-NJ-N6J"},
          {"name":"MANTUA","code":"US-NJ-QMU"},
          {"name":"MANVILLE","code":"US-NJ-JVI"},
          {"name":"MAPLE SHADE","code":"US-NJ-XJA"},
          {"name":"MAPLEWOOD","code":"US-NJ-MPW"},
          {"name":"MARLBORO","code":"US-NJ-MBB"},
          {"name":"MARLTON","code":"US-NJ-MJT"},
          {"name":"MARTINSVILLE","code":"US-NJ-MV8"},
          {"name":"MATAWAN","code":"US-NJ-TAW"},
          {"name":"MAURICETOWN","code":"US-NJ-UCW"},
          {"name":"MAYS LANDING","code":"US-NJ-MYJ"},
          {"name":"MAYWOOD","code":"US-NJ-MYW"},
          {"name":"MEDFORD","code":"US-NJ-UQN"},
          {"name":"MENDHAM","code":"US-NJ-ZEY"},
          {"name":"MERCERVILLE","code":"US-NJ-MVS"},
          {"name":"MERCHANTVILLE","code":"US-NJ-M7E"},
          {"name":"METUCHEN","code":"US-NJ-MEU"},
          {"name":"MICKLETON","code":"US-NJ-XIQ"},
          {"name":"MIDDLESEX","code":"US-NJ-MIS"},
          {"name":"MIDDLETOWN","code":"US-NJ-MIJ"},
          {"name":"MIDLAND PARK","code":"US-NJ-MDL"},
          {"name":"MILFORD","code":"US-NJ-MFT"},
          {"name":"MILLBURN","code":"US-NJ-XIR"},
          {"name":"MILLINGTON","code":"US-NJ-MGY"},
          {"name":"MILLSTONE","code":"US-NJ-IWH"},
          {"name":"MILLSTONE TOWNSHIP","code":"US-NJ-MP9"},
          {"name":"MILLTOWN","code":"US-NJ-MMJ"},
          {"name":"MILLVILLE","code":"US-NJ-MIV"},
          {"name":"MILLVILLE","code":"US-NJ-VLZ"},
          {"name":"MILVILLE","code":"US-NJ-VL9"},
          {"name":"MINE HILL","code":"US-NJ-MHZ"},
          {"name":"MINOTOLA","code":"US-NJ-2MI"},
          {"name":"MONMOUTH BEACH","code":"US-NJ-M9O"},
          {"name":"MONMOUTH JUNCTION","code":"US-NJ-MJU"},
          {"name":"MONROE","code":"US-NJ-OWH"},
          {"name":"MONROE","code":"US-NJ-QMX"},
          {"name":"MONROE TOWNSHIP","code":"US-NJ-ONR"},
          {"name":"MONROEVILLE","code":"US-NJ-QXO"},
          {"name":"MONTAGUE","code":"US-NJ-7UE"},
          {"name":"MONTCLAIR","code":"US-NJ-MLR"},
          {"name":"MONTVALE","code":"US-NJ-QMV"},
          {"name":"MONTVILLE","code":"US-NJ-MDB"},
          {"name":"MOONACHIE/NEW JERSEY","code":"US-NJ-MOO"},
          {"name":"MOORESTOWN","code":"US-NJ-MRJ"},
          {"name":"MORGANVILLE","code":"US-NJ-VJM"},
          {"name":"MORICHES","code":"US-NJ-ZH6"},
          {"name":"MORRIS PLAINS","code":"US-NJ-MPA"},
          {"name":"MORRISTOWN","code":"US-NJ-MMU"},
          {"name":"MORRISVILLE","code":"US-NJ-MV9"},
          {"name":"MOUNT ARLINGTON","code":"US-NJ-UNM"},
          {"name":"MOUNT FREEDOM","code":"US-NJ-M3F"},
          {"name":"MOUNT HOLLY","code":"US-NJ-LLY"},
          {"name":"MOUNT LAUREL","code":"US-NJ-LUJ"},
          {"name":"MOUNT OLIVE","code":"US-NJ-MQV"},
          {"name":"MOUNT ROYAL","code":"US-NJ-9MY"},
          {"name":"MOUNTAIN LAKES","code":"US-NJ-ULS"},
          {"name":"MOUNTAINSIDE","code":"US-NJ-MSJ"},
          {"name":"MOUNTAINVILLE","code":"US-NJ-MMV"},
          {"name":"MOUNTS MILLS","code":"US-NJ-ZMM"},
          {"name":"MULLICA HILL","code":"US-NJ-MHJ"},
          {"name":"MURRAY HILL","code":"US-NJ-MRR"},
          {"name":"NEPTUNE","code":"US-NJ-9NT"},
          {"name":"NEPTUNE CITY","code":"US-NJ-NPC"},
          {"name":"NESHANIC STATION","code":"US-NJ-NSN"},
          {"name":"NETCONG","code":"US-NJ-NEQ"},
          {"name":"NEW BRUNSWICK","code":"US-NJ-NBJ"},
          {"name":"NEW EGYPT","code":"US-NJ-NGP"},
          {"name":"NEW GRETNA","code":"US-NJ-G22"},
          {"name":"NEW MILFORD","code":"US-NJ-NMF"},
          {"name":"NEW PROVIDENCE","code":"US-NJ-NPX"},
          {"name":"NEW VERNON","code":"US-NJ-N2V"},
          {"name":"NEWARK","code":"US-NJ-EWR"},
          {"name":"NEWFIELD","code":"US-NJ-NWF"},
          {"name":"NEWFOUNDLAND","code":"US-NJ-NFY"},
          {"name":"NEWTON","code":"US-NJ-NWN"},
          {"name":"NORMA","code":"US-NJ-DMV"},
          {"name":"NORTH ARLINGTON","code":"US-NJ-NJT"},
          {"name":"NORTH BERGEN","code":"US-NJ-NBN"},
          {"name":"NORTH BRANCH","code":"US-NJ-XJN"},
          {"name":"NORTH BRUNSWICK","code":"US-NJ-NBS"},
          {"name":"NORTH CALDWELL","code":"US-NJ-2CW"},
          {"name":"NORTH HALEDON","code":"US-NJ-NH9"},
          {"name":"NORTH PLAINFIELD","code":"US-NJ-ND8"},
          {"name":"NORTH WILDWOOD","code":"US-NJ-NW2"},
          {"name":"NORTHFIELD","code":"US-NJ-NHL"},
          {"name":"NORTHVALE","code":"US-NJ-NVL"},
          {"name":"NORWOOD","code":"US-NJ-NRD"},
          {"name":"NUTLEY","code":"US-NJ-NUT"},
          {"name":"OAK ISLAND","code":"US-NJ-OKI"},
          {"name":"OAK RIDGE","code":"US-NJ-ORE"},
          {"name":"OAKHURST","code":"US-NJ-OKH"},
          {"name":"OAKLAND","code":"US-NJ-XKB"},
          {"name":"OCEAN","code":"US-NJ-OCY"},
          {"name":"OCEAN CITY","code":"US-NJ-OEC"},
          {"name":"OCEAN GATE","code":"US-NJ-OAM"},
          {"name":"OCEAN VIEW","code":"US-NJ-OIW"},
          {"name":"OCEANPORT","code":"US-NJ-OCP"},
          {"name":"OCEANVIEW","code":"US-NJ-OC5"},
          {"name":"OGDENSBURG","code":"US-NJ-OGU"},
          {"name":"OLD BRIDGE","code":"US-NJ-ODR"},
          {"name":"OLD TAPPAN","code":"US-NJ-OPN"},
          {"name":"ORADELL","code":"US-NJ-ORA"},
          {"name":"ORANGE","code":"US-NJ-JNJ"},
          {"name":"OXFORD","code":"US-NJ-OXW"},
          {"name":"PALISADE","code":"US-NJ-PDE"},
          {"name":"PALISADES PARK","code":"US-NJ-PSA"},
          {"name":"PALMYRA","code":"US-NJ-PBJ"},
          {"name":"PARAMUS","code":"US-NJ-PRM"},
          {"name":"PARK RIDGE","code":"US-NJ-9PR"},
          {"name":"PARKRIDGE","code":"US-NJ-PRK"},
          {"name":"PARLIN","code":"US-NJ-PUJ"},
          {"name":"PARSIPPANY","code":"US-NJ-PPY"},
          {"name":"PASSAIC","code":"US-NJ-PSE"},
          {"name":"PATERSON","code":"US-NJ-PAT"},
          {"name":"PAULSBORO","code":"US-NJ-PAU"},
          {"name":"PEAPACK","code":"US-NJ-EPU"},
          {"name":"PEDRICKTOWN","code":"US-NJ-PKN"},
          {"name":"PENNINGTON","code":"US-NJ-NNG"},
          {"name":"PENNS GROVE","code":"US-NJ-PGP"},
          {"name":"PENNSAUKEN","code":"US-NJ-PPS"},
          {"name":"PENNSVILLE","code":"US-NJ-PFJ"},
          {"name":"PEQUANNOCK","code":"US-NJ-PQN"},
          {"name":"PERRINEVILLE","code":"US-NJ-P7V"},
          {"name":"PERTH AMBOY","code":"US-NJ-PAY"},
          {"name":"PETERSBURG","code":"US-NJ-UPG"},
          {"name":"PHILLIPSBURG","code":"US-NJ-PHI"},
          {"name":"PICATINNY ARSENAL","code":"US-NJ-PY5"},
          {"name":"PINE BROOK","code":"US-NJ-PBO"},
          {"name":"PINE HILL","code":"US-NJ-VHP"},
          {"name":"PISCATAWAY","code":"US-NJ-PIL"},
          {"name":"PITMAN","code":"US-NJ-PMN"},
          {"name":"PITTSGROVE","code":"US-NJ-TGV"},
          {"name":"PITTSTOWN","code":"US-NJ-P28"},
          {"name":"PLAINFIELD","code":"US-NJ-PLF"},
          {"name":"PLAINSBORO","code":"US-NJ-XKM"},
          {"name":"PLEASANTVILLE","code":"US-NJ-UXV"},
          {"name":"PLUCKEMIN","code":"US-NJ-PJU"},
          {"name":"POINT PLEASANT","code":"US-NJ-P6P"},
          {"name":"POINT PLEASANT","code":"US-NJ-ZHC"},
          {"name":"POINT PLEASANT BEACH","code":"US-NJ-PP3"},
          {"name":"POMPTON","code":"US-NJ-PO8"},
          {"name":"POMPTON LAKES","code":"US-NJ-P7L"},
          {"name":"POMPTON PLAINS","code":"US-NJ-PMJ"},
          {"name":"PORT ELIZABETH","code":"US-NJ-PEB"},
          {"name":"PORT MONMOUTH","code":"US-NJ-PM3"},
          {"name":"PORT MURRAY","code":"US-NJ-URR"},
          {"name":"PORT NEWARK","code":"US-NJ-PNJ"},
          {"name":"PORT NORRIS","code":"US-NJ-OTI"},
          {"name":"PORT READING","code":"US-NJ-PRD"},
          {"name":"PORT REPUBLIC","code":"US-NJ-PUX"},
          {"name":"PORT WASHINGTON","code":"US-NJ-PTY"},
          {"name":"POTTERSTOWN","code":"US-NJ-TTJ"},
          {"name":"PRINCETON","code":"US-NJ-PCT"},
          {"name":"PRINCETON JUNCTION","code":"US-NJ-PJO"},
          {"name":"PROSPECT","code":"US-NJ-YPP"},
          {"name":"QUAKERTOWN","code":"US-NJ-QKT"},
          {"name":"RAHWAY","code":"US-NJ-RAH"},
          {"name":"RAMSEY","code":"US-NJ-RMJ"},
          {"name":"RANCOCAS","code":"US-NJ-XRN"},
          {"name":"RANDOLPH","code":"US-NJ-RDP"},
          {"name":"RARITAN","code":"US-NJ-RAT"},
          {"name":"READINGTON","code":"US-NJ-2RG"},
          {"name":"RED BANK","code":"US-NJ-REN"},
          {"name":"RICHWOOD","code":"US-NJ-ZFA"},
          {"name":"RIDGEFIELD","code":"US-NJ-UYE"},
          {"name":"RIDGEFIELD PARK","code":"US-NJ-RFP"},
          {"name":"RIDGEWOOD","code":"US-NJ-RWD"},
          {"name":"RINGOES","code":"US-NJ-YGO"},
          {"name":"RINGWOOD","code":"US-NJ-RIJ"},
          {"name":"RIO GRANDE","code":"US-NJ-RGF"},
          {"name":"RIVER EDGE","code":"US-NJ-RQQ"},
          {"name":"RIVERDALE","code":"US-NJ-RDL"},
          {"name":"RIVERSIDE","code":"US-NJ-RSD"},
          {"name":"RIVERTON","code":"US-NJ-RVJ"},
          {"name":"ROBBINSVILLE","code":"US-NJ-RBV"},
          {"name":"ROBERTSVILLE","code":"US-NJ-RVB"},
          {"name":"ROBINSVILLE","code":"US-NJ-ZFB"},
          {"name":"ROCHELLE PARK","code":"US-NJ-R8O"},
          {"name":"ROCKAWAY","code":"US-NJ-RKJ"},
          {"name":"ROCKLEIGH","code":"US-NJ-RXH"},
          {"name":"ROCKPORT","code":"US-NJ-RKO"},
          {"name":"ROEBLING","code":"US-NJ-RQJ"},
          {"name":"ROSEDALE","code":"US-NJ-ROZ"},
          {"name":"ROSELAND","code":"US-NJ-RLB"},
          {"name":"ROSELLE","code":"US-NJ-RLE"},
          {"name":"ROSELLE PARK","code":"US-NJ-ZH2"},
          {"name":"ROSEMONT","code":"US-NJ-QRM"},
          {"name":"ROSENHAYN","code":"US-NJ-RON"},
          {"name":"RUMSON","code":"US-NJ-RUW"},
          {"name":"RUNNEMEDE","code":"US-NJ-YRD"},
          {"name":"RUTHERFORD","code":"US-NJ-UYI"},
          {"name":"SADDLE BROOK","code":"US-NJ-SBK"},
          {"name":"SADDLE RIVER","code":"US-NJ-RV4"},
          {"name":"SALEM","code":"US-NJ-SEM"},
          {"name":"SAYREVILLE","code":"US-NJ-XNA"},
          {"name":"SCOBEYVILLE","code":"US-NJ-SCW"},
          {"name":"SCOTCH PLAINS","code":"US-NJ-SPJ"},
          {"name":"SEA GIRT","code":"US-NJ-GXI"},
          {"name":"SEABROOK","code":"US-NJ-SBJ"},
          {"name":"SEASIDE HEIGHTS","code":"US-NJ-SH2"},
          {"name":"SECAUCUS","code":"US-NJ-UYK"},
          {"name":"SEWAREN","code":"US-NJ-SWN"},
          {"name":"SEWELL","code":"US-NJ-YSW"},
          {"name":"SHAMONG","code":"US-NJ-SH4"},
          {"name":"SHILOH","code":"US-NJ-OYJ"},
          {"name":"SHIP BOTTOM","code":"US-NJ-SBQ"},
          {"name":"SHORT HILLS","code":"US-NJ-XSY"},
          {"name":"SHREWSBURY","code":"US-NJ-EZY"},
          {"name":"SICKLERVILLE","code":"US-NJ-SV5"},
          {"name":"SKILLMAN","code":"US-NJ-SQM"},
          {"name":"SOMERDALE","code":"US-NJ-SMV"},
          {"name":"SOMERS POINT","code":"US-NJ-S5P"},
          {"name":"SOMERSET","code":"US-NJ-SOJ"},
          {"name":"SOMERVILLE","code":"US-NJ-SOM"},
          {"name":"SOUTH AMBOY","code":"US-NJ-ZZM"},
          {"name":"SOUTH BRUNSWICK","code":"US-NJ-XLN"},
          {"name":"SOUTH HACKENSACK/NEW JERSEY","code":"US-NJ-HKS"},
          {"name":"SOUTH KEARNY","code":"US-NJ-SK2"},
          {"name":"SOUTH ORANGE","code":"US-NJ-THX"},
          {"name":"SOUTH PLAINFIELD","code":"US-NJ-SFD"},
          {"name":"SOUTH RIVER","code":"US-NJ-SSE"},
          {"name":"SOUTHAMPTON","code":"US-NJ-ZFJ"},
          {"name":"SPARTA","code":"US-NJ-SZA"},
          {"name":"SPOTSWOOD","code":"US-NJ-SAW"},
          {"name":"SPRING LAKE","code":"US-NJ-SX7"},
          {"name":"SPRINGFIELD","code":"US-NJ-SFI"},
          {"name":"STANHOPE","code":"US-NJ-STN"},
          {"name":"STEWARTSVILLE","code":"US-NJ-QTW"},
          {"name":"STILLWATER","code":"US-NJ-VSW"},
          {"name":"STIRLING","code":"US-NJ-TRG"},
          {"name":"STOCKTON","code":"US-NJ-ZOS"},
          {"name":"STRATFORD","code":"US-NJ-SDZ"},
          {"name":"STRATHMERE","code":"US-NJ-SMM"},
          {"name":"SUCCASUNNA","code":"US-NJ-SQJ"},
          {"name":"SUMMIT","code":"US-NJ-UYX"},
          {"name":"SURF CITY","code":"US-NJ-SC5"},
          {"name":"SUSSEX","code":"US-NJ-UEX"},
          {"name":"SWEDESBORO","code":"US-NJ-SDS"},
          {"name":"TABERNACLE","code":"US-NJ-TBQ"},
          {"name":"TAVISTOCK","code":"US-NJ-KS6"},
          {"name":"TEANECK","code":"US-NJ-TEA"},
          {"name":"TENAFLY","code":"US-NJ-TNF"},
          {"name":"TENNENT","code":"US-NJ-TEN"},
          {"name":"TETERBORO","code":"US-NJ-TEB"},
          {"name":"TEWKSBURY","code":"US-NJ-TW9"},
          {"name":"THOROFARE","code":"US-NJ-TRF"},
          {"name":"THREE BRIDGES","code":"US-NJ-THE"},
          {"name":"TINTON FALLS","code":"US-NJ-TIN"},
          {"name":"TOMS RIVER","code":"US-NJ-MJX"},
          {"name":"TOTOWA","code":"US-NJ-TOT"},
          {"name":"TOWACO","code":"US-NJ-TWJ"},
          {"name":"TOWNSHIP OF WOOLWICH","code":"US-NJ-WP2"},
          {"name":"TRENTON","code":"US-NJ-TTN"},
          {"name":"TUCKERTON","code":"US-NJ-TN5"},
          {"name":"TURNERSVILLE","code":"US-NJ-TUE"},
          {"name":"UNION","code":"US-NJ-UON"},
          {"name":"UNION BEACH","code":"US-NJ-UNA"},
          {"name":"UNION CITY","code":"US-NJ-UCJ"},
          {"name":"UPPER FREEHOLD","code":"US-NJ-UPF"},
          {"name":"UPPER SADDLE RIVER","code":"US-NJ-UPS"},
          {"name":"VAUXHALL","code":"US-NJ-VHX"},
          {"name":"VENTNOR CITY","code":"US-NJ-VYA"},
          {"name":"VERNO","code":"US-NJ-VR3"},
          {"name":"VERNON","code":"US-NJ-V74"},
          {"name":"VERNON, CAMDEN","code":"US-NJ-VNO"},
          {"name":"VERONA","code":"US-NJ-VER"},
          {"name":"VIENNA","code":"US-NJ-VNJ"},
          {"name":"VINCENTOWN","code":"US-NJ-XVT"},
          {"name":"VINELAND","code":"US-NJ-VIN"},
          {"name":"VOORHEES","code":"US-NJ-VHJ"},
          {"name":"WALDWICK","code":"US-NJ-WWK"},
          {"name":"WALL","code":"US-NJ-WLJ"},
          {"name":"WALL TOWNSHIP","code":"US-NJ-YNP"},
          {"name":"WALLINGTON","code":"US-NJ-WLT"},
          {"name":"WANAQUE","code":"US-NJ-IWQ"},
          {"name":"WANTAGE","code":"US-NJ-WG5"},
          {"name":"WARREN","code":"US-NJ-W4R"},
          {"name":"WARRENVILLE","code":"US-NJ-WNI"},
          {"name":"WASHINGTON","code":"US-NJ-WQY"},
          {"name":"WASHINGTON TOWNSHIP","code":"US-NJ-GIP"},
          {"name":"WASHINGTON TOWNSHIP","code":"US-NJ-Y7T"},
          {"name":"WATCHUNG","code":"US-NJ-WA6"},
          {"name":"WATERFORD WORKS","code":"US-NJ-WFJ"},
          {"name":"WAYNE","code":"US-NJ-WYN"},
          {"name":"WEEHAWKEN","code":"US-NJ-WJQ"},
          {"name":"WENONAH","code":"US-NJ-WZZ"},
          {"name":"WEST BERLIN","code":"US-NJ-WBS"},
          {"name":"WEST CALDWELL","code":"US-NJ-WCL"},
          {"name":"WEST CREEK","code":"US-NJ-WCE"},
          {"name":"WEST DEPTFORD","code":"US-NJ-WDP"},
          {"name":"WEST LONG BRANCH","code":"US-NJ-WLQ"},
          {"name":"WEST MILFORD","code":"US-NJ-WM7"},
          {"name":"WEST NEW YORK","code":"US-NJ-WNY"},
          {"name":"WEST ORANGE/NEW JERSEY","code":"US-NJ-WOR"},
          {"name":"WEST PATERSON","code":"US-NJ-WP9"},
          {"name":"WEST PORTAL","code":"US-NJ-WXP"},
          {"name":"WEST TRENTON","code":"US-NJ-WT4"},
          {"name":"WEST WINDSOR","code":"US-NJ-WWJ"},
          {"name":"WEST WINFIELD","code":"US-NJ-EFU"},
          {"name":"WESTAMPTON","code":"US-NJ-WEP"},
          {"name":"WESTFIELD","code":"US-NJ-WFU"},
          {"name":"WESTHAMPTON","code":"US-NJ-QWH"},
          {"name":"WESTVILLE","code":"US-NJ-WVE"},
          {"name":"WESTWOOD","code":"US-NJ-XOI"},
          {"name":"WHARTON","code":"US-NJ-WTQ"},
          {"name":"WHIPPANY","code":"US-NJ-WHI"},
          {"name":"WHITEHOUSE","code":"US-NJ-ZWH"},
          {"name":"WHITEHOUSE STATION","code":"US-NJ-WS6"},
          {"name":"WILDWOOD","code":"US-NJ-WWD"},
          {"name":"WILLIAMSTOWN","code":"US-NJ-WZJ"},
          {"name":"WILLINGBORO","code":"US-NJ-WLO"},
          {"name":"WILLS POINT","code":"US-NJ-WPB"},
          {"name":"WINDSOR","code":"US-NJ-WNJ"},
          {"name":"WINSLOW","code":"US-NJ-6NJ"},
          {"name":"WOOD-RIDGE","code":"US-NJ-UZI"},
          {"name":"WOODBINE","code":"US-NJ-WB8"},
          {"name":"WOODBRIDGE","code":"US-NJ-WBZ"},
          {"name":"WOODBURY","code":"US-NJ-WOJ"},
          {"name":"WOODCLIFF LAKES","code":"US-NJ-8WL"},
          {"name":"WOODLAND PARK","code":"US-NJ-DP8"},
          {"name":"WOODSTOWN","code":"US-NJ-WDO"},
          {"name":"WOOLWICH TOWNSHIP","code":"US-NJ-W4W"},
          {"name":"WRIGHTSTOWN","code":"US-NJ-WGZ"},
          {"name":"WYCKOFF","code":"US-NJ-ZWK"},
          {"name":"YARDVILLE","code":"US-NJ-YDV"},
          {"name":"YORKETOWN","code":"US-NJ-YK9"},
        ]
      },
      {
        name: 'Nuevo México',
        code: 'US-NM',
        cities: [
          {"name":"ABIQUIU","code":"US-NM-AQ2"},
          {"name":"ALAMOGORDO","code":"US-NM-ALM"},
          {"name":"ALBUQUERQUE","code":"US-NM-ABQ"},
          {"name":"ALCADE","code":"US-NM-AC5"},
          {"name":"ALCALDE","code":"US-NM-XAC"},
          {"name":"ALGODONES","code":"US-NM-QGD"},
          {"name":"ANGEL FIRE","code":"US-NM-AXX"},
          {"name":"ANIMAS","code":"US-NM-AM3"},
          {"name":"ANTHONY","code":"US-NM-TNY"},
          {"name":"ARREY","code":"US-NM-AE9"},
          {"name":"ARROYO SECO","code":"US-NM-AR6"},
          {"name":"ARTESIA","code":"US-NM-ATS"},
          {"name":"AZTEC","code":"US-NM-ZZT"},
          {"name":"BELEN","code":"US-NM-BLN"},
          {"name":"BERNALILLO","code":"US-NM-BEO"},
          {"name":"BLOOMFIELD","code":"US-NM-B9F"},
          {"name":"BOSQUE FARMS","code":"US-NM-BQR"},
          {"name":"BUCHANAN","code":"US-NM-YUA"},
          {"name":"CANNON AFB","code":"US-NM-JFB"},
          {"name":"CAPITAN","code":"US-NM-C6P"},
          {"name":"CARLSBAD","code":"US-NM-CNM"},
          {"name":"CARRIZOZO","code":"US-NM-UFH"},
          {"name":"CARSON","code":"US-NM-C5S"},
          {"name":"CASA BLANCA","code":"US-NM-JCJ"},
          {"name":"CHAMA","code":"US-NM-QHM"},
          {"name":"CHAMBERINO","code":"US-NM-VHB"},
          {"name":"CHIMAYO","code":"US-NM-CI6"},
          {"name":"CLAYTON","code":"US-NM-CAO"},
          {"name":"CLOVIS","code":"US-NM-CVN"},
          {"name":"COLUMBUS","code":"US-NM-CUS"},
          {"name":"CORRALES","code":"US-NM-CE3"},
          {"name":"COYOTE","code":"US-NM-3CY"},
          {"name":"CUBA","code":"US-NM-CUA"},
          {"name":"CULEBRA","code":"US-NM-QUB"},
          {"name":"DALIES","code":"US-NM-IJS"},
          {"name":"DEMING","code":"US-NM-DMN"},
          {"name":"DEXTER","code":"US-NM-DXI"},
          {"name":"DORA","code":"US-NM-DJO"},
          {"name":"EDGEWOOD","code":"US-NM-DGO"},
          {"name":"EL PRADO","code":"US-NM-EP4"},
          {"name":"ELIDA","code":"US-NM-EI3"},
          {"name":"ESPANOLA","code":"US-NM-ESO"},
          {"name":"ESTANCIA","code":"US-NM-TA7"},
          {"name":"EUNICE","code":"US-NM-EUJ"},
          {"name":"FARMINGTON","code":"US-NM-FMN"},
          {"name":"FORT SUMNER","code":"US-NM-FSU"},
          {"name":"FORT WINGATE","code":"US-NM-FWG"},
          {"name":"FRUITLAND","code":"US-NM-F9R"},
          {"name":"GALLUP","code":"US-NM-GUP"},
          {"name":"GRANTS","code":"US-NM-GNT"},
          {"name":"HAGERMAN","code":"US-NM-VHG"},
          {"name":"HANOVER","code":"US-NM-OVG"},
          {"name":"HATCH","code":"US-NM-YHH"},
          {"name":"HOBBS","code":"US-NM-HOB"},
          {"name":"HOLLOMAN AIR FORCE BASE","code":"US-NM-OMN"},
          {"name":"HOLMAN","code":"US-NM-HO8"},
          {"name":"HONDO","code":"US-NM-4DJ"},
          {"name":"HURLEY","code":"US-NM-HU2"},
          {"name":"JAL","code":"US-NM-JJA"},
          {"name":"KIRTLAND","code":"US-NM-KTI"},
          {"name":"LA LUZ","code":"US-NM-LUZ"},
          {"name":"LA MESA","code":"US-NM-LM5"},
          {"name":"LA UNION","code":"US-NM-LU9"},
          {"name":"LAS CRUCES","code":"US-NM-LRU"},
          {"name":"LAS VEGAS","code":"US-NM-LVS"},
          {"name":"LEMITAR","code":"US-NM-YIR"},
          {"name":"LOCO HILLS","code":"US-NM-LH5"},
          {"name":"LORDSBURG","code":"US-NM-LSB"},
          {"name":"LOS ALAMOS","code":"US-NM-LAM"},
          {"name":"LOS LUNAS","code":"US-NM-LLU"},
          {"name":"LOVING","code":"US-NM-L3V"},
          {"name":"LOVINGTON","code":"US-NM-LOJ"},
          {"name":"LYDEN","code":"US-NM-L7Y"},
          {"name":"MENTMORE","code":"US-NM-MX9"},
          {"name":"MESILLA PARK","code":"US-NM-MSX"},
          {"name":"MESQUITE","code":"US-NM-MQS"},
          {"name":"MILAN","code":"US-NM-MN9"},
          {"name":"MIMBRES","code":"US-NM-IMB"},
          {"name":"MORA","code":"US-NM-MMA"},
          {"name":"MORIARTY","code":"US-NM-MY5"},
          {"name":"OHKAY OWINGEH","code":"US-NM-OYX"},
          {"name":"OROGRANDE","code":"US-NM-ZOG"},
          {"name":"OTTO","code":"US-NM-OTO"},
          {"name":"PENASCO","code":"US-NM-ZQ4"},
          {"name":"PERALTA","code":"US-NM-PE7"},
          {"name":"PLACITAS","code":"US-NM-9LA"},
          {"name":"PORTALES","code":"US-NM-PXM"},
          {"name":"PREWITT","code":"US-NM-RWP"},
          {"name":"QUEMADO","code":"US-NM-QM9"},
          {"name":"RADIUM SPRINGS","code":"US-NM-RPS"},
          {"name":"RANCHOS DE TAOS","code":"US-NM-8DD"},
          {"name":"RATON","code":"US-NM-RAN"},
          {"name":"RED RIVER","code":"US-NM-R9V"},
          {"name":"RINCON","code":"US-NM-NJN"},
          {"name":"RIO RANCHO","code":"US-NM-RRC"},
          {"name":"RODEO","code":"US-NM-RQE"},
          {"name":"ROSWELL","code":"US-NM-ROW"},
          {"name":"RUIDOSO","code":"US-NM-RUI"},
          {"name":"SALEM","code":"US-NM-JSM"},
          {"name":"SAN ANTONIO","code":"US-NM-SA5"},
          {"name":"SAN JON","code":"US-NM-S8J"},
          {"name":"SAN JUAN PUEBLO","code":"US-NM-JPB"},
          {"name":"SAN YSIDRO","code":"US-NM-YSO"},
          {"name":"SANDIA PARK","code":"US-NM-DIP"},
          {"name":"SANTA ANA PUEBLO","code":"US-NM-XZB"},
          {"name":"SANTA CLARA","code":"US-NM-TCM"},
          {"name":"SANTA FE","code":"US-NM-SAF"},
          {"name":"SANTA ROSA","code":"US-NM-AOA"},
          {"name":"SANTA TERESA","code":"US-NM-SXT"},
          {"name":"SILVER CITY","code":"US-NM-SVC"},
          {"name":"SIXELA","code":"US-NM-SXM"},
          {"name":"SOCORRO","code":"US-NM-ONM"},
          {"name":"SUNLAND PARK","code":"US-NM-XSP"},
          {"name":"TAOS","code":"US-NM-TSM"},
          {"name":"TESUQUE","code":"US-NM-Q6T"},
          {"name":"TIJERAS","code":"US-NM-TIJ"},
          {"name":"TORREON","code":"US-NM-QRR"},
          {"name":"TRUTH OR CONSEQUENCES","code":"US-NM-TCS"},
          {"name":"TUCUMCARI","code":"US-NM-TCC"},
          {"name":"TYRONE","code":"US-NM-TYB"},
          {"name":"VADO","code":"US-NM-VAX"},
          {"name":"VALDEZ","code":"US-NM-VAZ"},
          {"name":"VANADIUM","code":"US-NM-VDM"},
          {"name":"VAUGHN","code":"US-NM-VN2"},
          {"name":"WAGON MOUND","code":"US-NM-WM2"},
          {"name":"WATERFLOW","code":"US-NM-TFW"},
          {"name":"WHITE ROCK","code":"US-NM-TCK"},
          {"name":"WHITE SANDS","code":"US-NM-WSD"},
          {"name":"WILLARD","code":"US-NM-ZWJ"},
          {"name":"WINSTON","code":"US-NM-WXT"},
          {"name":"ZITLALTEPEC","code":"US-NM-Z9T"},
        ]
      },
      {
        name: 'Nueva York',
        code: 'US-NY',
        cities: [
          {"name":"ACCORD","code":"US-NY-AC9"},
          {"name":"ACRA","code":"US-NY-QCA"},
          {"name":"ADAMS CENTER","code":"US-NY-ADH"},
          {"name":"ADDISON","code":"US-NY-XDS"},
          {"name":"AIRMONT","code":"US-NY-A2M"},
          {"name":"AKRON","code":"US-NY-AKR"},
          {"name":"ALABAMA","code":"US-NY-A9L"},
          {"name":"ALBANY","code":"US-NY-ALB"},
          {"name":"ALBERTSON","code":"US-NY-OBQ"},
          {"name":"ALBION","code":"US-NY-AON"},
          {"name":"ALDEN","code":"US-NY-YLF"},
          {"name":"ALEXANDER","code":"US-NY-A37"},
          {"name":"ALEXANDRIA BAY","code":"US-NY-AXB"},
          {"name":"ALFRED","code":"US-NY-QAF"},
          {"name":"ALFRED STATION","code":"US-NY-AFS"},
          {"name":"ALLEGANY","code":"US-NY-NYZ"},
          {"name":"ALLENTOWN, ALLEGANY","code":"US-NY-AT2"},
          {"name":"ALSEN","code":"US-NY-AEN"},
          {"name":"ALTAMONT","code":"US-NY-ZTM"},
          {"name":"ALTON","code":"US-NY-KLQ"},
          {"name":"AMAGANSETT","code":"US-NY-AMF"},
          {"name":"AMHERST","code":"US-NY-AMH"},
          {"name":"AMITYVILLE","code":"US-NY-AYZ"},
          {"name":"AMSTERDAM","code":"US-NY-AMS"},
          {"name":"ANCRAM","code":"US-NY-ACQ"},
          {"name":"ANDERSON","code":"US-NY-NDE"},
          {"name":"ANDES","code":"US-NY-AJS"},
          {"name":"ANDOVER","code":"US-NY-AN8"},
          {"name":"ANGELICA","code":"US-NY-AJC"},
          {"name":"ANGOLA","code":"US-NY-ANH"},
          {"name":"ANNANDALE-ON-HUDSON","code":"US-NY-DHD"},
          {"name":"ANTWERP","code":"US-NY-A6N"},
          {"name":"APALACHIN","code":"US-NY-API"},
          {"name":"APPLETON","code":"US-NY-AUP"},
          {"name":"AQUEBOGUE","code":"US-NY-AQO"},
          {"name":"ARCADE","code":"US-NY-AC2"},
          {"name":"ARDSLEY","code":"US-NY-ADY"},
          {"name":"ARGYLE","code":"US-NY-JYR"},
          {"name":"ARKPORT","code":"US-NY-NK7"},
          {"name":"ARLINGTON","code":"US-NY-JIG"},
          {"name":"ARMONK","code":"US-NY-AXC"},
          {"name":"ARVERNE","code":"US-NY-A8V"},
          {"name":"ASHVILLE","code":"US-NY-A9S"},
          {"name":"ASTORIA/QUEENS/NEW YORK","code":"US-NY-ASA"},
          {"name":"ATHENS","code":"US-NY-ATB"},
          {"name":"ATLANTIC BEACH","code":"US-NY-ZNB"},
          {"name":"ATTICA","code":"US-NY-AQ7"},
          {"name":"AUBURN","code":"US-NY-AUB"},
          {"name":"AURORA","code":"US-NY-AUR"},
          {"name":"AVA","code":"US-NY-QAV"},
          {"name":"AVERILL PARK","code":"US-NY-AV9"},
          {"name":"AVOCA","code":"US-NY-2AV"},
          {"name":"AVON","code":"US-NY-AVV"},
          {"name":"BABYLON","code":"US-NY-BBY"},
          {"name":"BAINBRIDGE","code":"US-NY-BCA"},
          {"name":"BAITING HOLLOW","code":"US-NY-NGH"},
          {"name":"BALDWIN","code":"US-NY-BAD"},
          {"name":"BALDWINSVILLE","code":"US-NY-BDV"},
          {"name":"BALLSTON LAKE","code":"US-NY-2BY"},
          {"name":"BALLSTON SPA","code":"US-NY-BQY"},
          {"name":"BALMAT","code":"US-NY-XBS"},
          {"name":"BALTIMORE","code":"US-NY-Z2A"},
          {"name":"BARKER","code":"US-NY-BK9"},
          {"name":"BASOM","code":"US-NY-B6M"},
          {"name":"BATAVIA","code":"US-NY-BIA"},
          {"name":"BATH","code":"US-NY-ATH"},
          {"name":"BATTENVILLE","code":"US-NY-AFV"},
          {"name":"BAY SHORE","code":"US-NY-BSH"},
          {"name":"BAYPORT","code":"US-NY-BPZ"},
          {"name":"BAYSIDE","code":"US-NY-B3Y"},
          {"name":"BAYVILLE","code":"US-NY-YBV"},
          {"name":"BEACON","code":"US-NY-BCN"},
          {"name":"BEACON HILLS","code":"US-NY-BAH"},
          {"name":"BEAVER FALLS","code":"US-NY-BVF"},
          {"name":"BEDFORD","code":"US-NY-BJF"},
          {"name":"BEDFORD HILLS","code":"US-NY-BQQ"},
          {"name":"BELLE HARBOR","code":"US-NY-BE8"},
          {"name":"BELLEROSE","code":"US-NY-ZUA"},
          {"name":"BELLEVILLE","code":"US-NY-YXL"},
          {"name":"BELLMORE","code":"US-NY-EZE"},
          {"name":"BELLPORT","code":"US-NY-BPP"},
          {"name":"BELMONT","code":"US-NY-6MB"},
          {"name":"BERGEN","code":"US-NY-RGB"},
          {"name":"BERGHOLTZ","code":"US-NY-BRH"},
          {"name":"BERLIN","code":"US-NY-BRI"},
          {"name":"BETHEL","code":"US-NY-BZJ"},
          {"name":"BETHEL","code":"US-NY-TZH"},
          {"name":"BETHPAGE","code":"US-NY-BPA"},
          {"name":"BIG FLATS","code":"US-NY-AFP"},
          {"name":"BIG TREE","code":"US-NY-2BT"},
          {"name":"BINGHAMTON","code":"US-NY-BIO"},
          {"name":"BINGHAMTON-ENDICOTT-JOHNSON CITY APT","code":"US-NY-BGM"},
          {"name":"BLASDELL","code":"US-NY-LJD"},
          {"name":"BLAUVELT","code":"US-NY-BUY"},
          {"name":"BLODGETT MILLS","code":"US-NY-BZG"},
          {"name":"BLOOMFIELD","code":"US-NY-BF7"},
          {"name":"BLOOMINGBURG","code":"US-NY-BMB"},
          {"name":"BLOOMINGTON","code":"US-NY-BMR"},
          {"name":"BLUE POINT","code":"US-NY-BP6"},
          {"name":"BOHEMIA","code":"US-NY-BOH"},
          {"name":"BOLIVAR","code":"US-NY-8VO"},
          {"name":"BOLTON LANDING","code":"US-NY-ZBI"},
          {"name":"BOMBAY","code":"US-NY-ZA8"},
          {"name":"BOONVILLE","code":"US-NY-BO9"},
          {"name":"BOSTON","code":"US-NY-YBS"},
          {"name":"BOVINA CENTER","code":"US-NY-OVC"},
          {"name":"BOWMANSVILLE","code":"US-NY-OQI"},
          {"name":"BRANT LAKE","code":"US-NY-B3L"},
          {"name":"BRASHER FALLS","code":"US-NY-B2A"},
          {"name":"BRENTWOOD","code":"US-NY-BQB"},
          {"name":"BREWERTON","code":"US-NY-B2W"},
          {"name":"BREWSTER","code":"US-NY-BWZ"},
          {"name":"BRIARCLIFF MANOR","code":"US-NY-BFJ"},
          {"name":"BRIDGEHAMPTON","code":"US-NY-BHC"},
          {"name":"BRIDGEPORT","code":"US-NY-BDB"},
          {"name":"BRIDGEWATER","code":"US-NY-BGW"},
          {"name":"BROCKPORT","code":"US-NY-BPK"},
          {"name":"BRONX","code":"US-NY-BRX"},
          {"name":"BRONXVILLE","code":"US-NY-BNX"},
          {"name":"BROOKFIELD","code":"US-NY-BF2"},
          {"name":"BROOKHAVEN","code":"US-NY-ZBH"},
          {"name":"BROOKLYN/NEW YORK","code":"US-NY-BOY"},
          {"name":"BROOKVILLE","code":"US-NY-BV5"},
          {"name":"BROWNVILLE","code":"US-NY-QBV"},
          {"name":"BRUSHTON","code":"US-NY-VXB"},
          {"name":"BUCHANAN","code":"US-NY-KCB"},
          {"name":"BUFFALO","code":"US-NY-BUF"},
          {"name":"BULLVILLE","code":"US-NY-UVE"},
          {"name":"BULVILLE","code":"US-NY-UBZ"},
          {"name":"BURDETT","code":"US-NY-B9N"},
          {"name":"BURKE","code":"US-NY-XRK"},
          {"name":"BURLINGTON","code":"US-NY-UOG"},
          {"name":"BURLINGTON FLATS","code":"US-NY-UGF"},
          {"name":"BURT","code":"US-NY-BTU"},
          {"name":"BYRON","code":"US-NY-BY7"},
          {"name":"CALEDONIA","code":"US-NY-CO7"},
          {"name":"CALLICOON","code":"US-NY-CA9"},
          {"name":"CALVERTON","code":"US-NY-CTO"},
          {"name":"CAMBRIDGE","code":"US-NY-CR7"},
          {"name":"CAMDEN","code":"US-NY-ZCD"},
          {"name":"CAMILLUS","code":"US-NY-IUS"},
          {"name":"CAMPBELL","code":"US-NY-CPE"},
          {"name":"CAMPBELL HALL","code":"US-NY-CEH"},
          {"name":"CANAJOHARIE","code":"US-NY-CJH"},
          {"name":"CANANDAIGUA","code":"US-NY-CND"},
          {"name":"CANASTOTA","code":"US-NY-CNS"},
          {"name":"CANDOR","code":"US-NY-D6C"},
          {"name":"CANISTEO","code":"US-NY-C2O"},
          {"name":"CANTON","code":"US-NY-XAN"},
          {"name":"CAPE VINCENT","code":"US-NY-CVI"},
          {"name":"CARLE PLACE","code":"US-NY-CPC"},
          {"name":"CARLISLE","code":"US-NY-CI7"},
          {"name":"CARMAN","code":"US-NY-CRJ"},
          {"name":"CARMEL","code":"US-NY-CMZ"},
          {"name":"CARTHAGE","code":"US-NY-QCH"},
          {"name":"CASTILE","code":"US-NY-OMW"},
          {"name":"CASTLETON ON HUDSON","code":"US-NY-COH"},
          {"name":"CASTORLAND","code":"US-NY-CL2"},
          {"name":"CATATONK","code":"US-NY-AOK"},
          {"name":"CATO","code":"US-NY-QAC"},
          {"name":"CATSKILL","code":"US-NY-ACG"},
          {"name":"CATTARAUGUS","code":"US-NY-TTT"},
          {"name":"CAYUGA","code":"US-NY-AYG"},
          {"name":"CAYUTA","code":"US-NY-CYU"},
          {"name":"CAZENOVIA","code":"US-NY-ZCZ"},
          {"name":"CEDARHURST","code":"US-NY-YYQ"},
          {"name":"CELORON","code":"US-NY-KCE"},
          {"name":"CEMENTON","code":"US-NY-ACS"},
          {"name":"CENTER MORICHES","code":"US-NY-NMS"},
          {"name":"CENTEREACH","code":"US-NY-CCW"},
          {"name":"CENTERPORT","code":"US-NY-CTT"},
          {"name":"CENTERPORT, GAYUGA","code":"US-NY-CP3"},
          {"name":"CENTRAL BRIDGE","code":"US-NY-N5Y"},
          {"name":"CENTRAL ISLIP","code":"US-NY-CIP"},
          {"name":"CENTRAL VALLEY","code":"US-NY-TLV"},
          {"name":"CHADWICKS","code":"US-NY-ZZD"},
          {"name":"CHAFFEE","code":"US-NY-FFC"},
          {"name":"CHAMPLAIN","code":"US-NY-CHM"},
          {"name":"CHAPPAQUA","code":"US-NY-P3N"},
          {"name":"CHARLOTTE","code":"US-NY-QZQ"},
          {"name":"CHASE MILLS","code":"US-NY-HXL"},
          {"name":"CHATEAUGAY","code":"US-NY-CUX"},
          {"name":"CHATHAM","code":"US-NY-CIF"},
          {"name":"CHAUTAUQUA","code":"US-NY-QCQ"},
          {"name":"CHAZY","code":"US-NY-DHY"},
          {"name":"CHEEKTOWAGA","code":"US-NY-CKT"},
          {"name":"CHELSEA","code":"US-NY-QCS"},
          {"name":"CHEMUNG","code":"US-NY-GMG"},
          {"name":"CHENANGO BRIDGE","code":"US-NY-DGE"},
          {"name":"CHENANGO FORKS","code":"US-NY-HFK"},
          {"name":"CHERRY VALLEY","code":"US-NY-NY7"},
          {"name":"CHESTER","code":"US-NY-CSR"},
          {"name":"CHESTERTOWN","code":"US-NY-KCW"},
          {"name":"CHESTNUT RIDGE","code":"US-NY-CDG"},
          {"name":"CHILI","code":"US-NY-CII"},
          {"name":"CHITTENANGO","code":"US-NY-CGO"},
          {"name":"CHURCHVILLE","code":"US-NY-ICJ"},
          {"name":"CHURUBUSCO","code":"US-NY-UUB"},
          {"name":"CICERO","code":"US-NY-CCC"},
          {"name":"CITY ISLAND","code":"US-NY-IS3"},
          {"name":"CLARENCE","code":"US-NY-AEC"},
          {"name":"CLARENCE CENTER","code":"US-NY-CC9"},
          {"name":"CLAVERACK","code":"US-NY-CLK"},
          {"name":"CLAY","code":"US-NY-CY6"},
          {"name":"CLAYTON","code":"US-NY-CLY"},
          {"name":"CLERMONT","code":"US-NY-CXN"},
          {"name":"CLEVELAND","code":"US-NY-3CV"},
          {"name":"CLIFTON PARK","code":"US-NY-CFP"},
          {"name":"CLIFTON SPRINGS","code":"US-NY-CSS"},
          {"name":"CLINTON","code":"US-NY-IKT"},
          {"name":"CLYDE","code":"US-NY-CYA"},
          {"name":"CLYMER","code":"US-NY-YME"},
          {"name":"COBLESKILL","code":"US-NY-S2K"},
          {"name":"COCHECTON","code":"US-NY-QOH"},
          {"name":"COEYMANS","code":"US-NY-OYF"},
          {"name":"COHOCTON","code":"US-NY-KKO"},
          {"name":"COHOES","code":"US-NY-COO"},
          {"name":"COLD SPRING","code":"US-NY-LPM"},
          {"name":"COLD SPRING HARBOR","code":"US-NY-HQ2"},
          {"name":"COLDEN","code":"US-NY-CDQ"},
          {"name":"COLLEGE POINT","code":"US-NY-CPN"},
          {"name":"COLLINS","code":"US-NY-OIX"},
          {"name":"COLONIE","code":"US-NY-CE2"},
          {"name":"COMMACK","code":"US-NY-CMF"},
          {"name":"CONEWANGO VALLEY","code":"US-NY-WG7"},
          {"name":"CONGERS","code":"US-NY-NGS"},
          {"name":"CONKLIN","code":"US-NY-NKL"},
          {"name":"COOPERSTOWN","code":"US-NY-COP"},
          {"name":"COPAKE","code":"US-NY-KOP"},
          {"name":"COPAKE FALLS","code":"US-NY-OPE"},
          {"name":"COPENHAGEN","code":"US-NY-KOG"},
          {"name":"COPIAGUE","code":"US-NY-CET"},
          {"name":"CORAM","code":"US-NY-UFF"},
          {"name":"CORFU","code":"US-NY-RFU"},
          {"name":"CORINTH","code":"US-NY-OIH"},
          {"name":"CORNING","code":"US-NY-OIG"},
          {"name":"CORNWALL, TOWN OF","code":"US-NY-NWL"},
          {"name":"CORONA","code":"US-NY-QYC"},
          {"name":"CORTLAND","code":"US-NY-CTX"},
          {"name":"COWLESVILLE","code":"US-NY-C7W"},
          {"name":"COXSACKIE, GREENE","code":"US-NY-CS6"},
          {"name":"CRANBERRY LAKE","code":"US-NY-CPD"},
          {"name":"CRARYVILLE","code":"US-NY-4CR"},
          {"name":"CROTON FALLS","code":"US-NY-C9F"},
          {"name":"CROTON-ON-HUDSON","code":"US-NY-THU"},
          {"name":"CUBA","code":"US-NY-NYB"},
          {"name":"CUTCHOGUE","code":"US-NY-UTC"},
          {"name":"DANSVILLE","code":"US-NY-DSV"},
          {"name":"DANVILLE","code":"US-NY-DIL"},
          {"name":"DARIEN CENTER","code":"US-NY-DIR"},
          {"name":"DAYTON","code":"US-NY-DYD"},
          {"name":"DE WITT","code":"US-NY-DWN"},
          {"name":"DEANSBORO","code":"US-NY-D2E"},
          {"name":"DEER PARK","code":"US-NY-DPK"},
          {"name":"DEFERIET","code":"US-NY-DEF"},
          {"name":"DELANSON","code":"US-NY-D2L"},
          {"name":"DELEVAN","code":"US-NY-DVA"},
          {"name":"DELHI","code":"US-NY-YDP"},
          {"name":"DENMARK","code":"US-NY-ZC2"},
          {"name":"DENVER","code":"US-NY-DAJ"},
          {"name":"DEPEW","code":"US-NY-DPE"},
          {"name":"DEPOSIT","code":"US-NY-DPI"},
          {"name":"DERBY","code":"US-NY-ERB"},
          {"name":"DEWITT","code":"US-NY-DEW"},
          {"name":"DEWITTVILLE","code":"US-NY-DTI"},
          {"name":"DEXTER","code":"US-NY-DXT"},
          {"name":"DIX HILLS","code":"US-NY-DI7"},
          {"name":"DOBBS FERRY","code":"US-NY-DBF"},
          {"name":"DOLGEVILLE","code":"US-NY-QDY"},
          {"name":"DOUGLASTON/QUEENS/NEW YORK","code":"US-NY-DGS"},
          {"name":"DOVER PLAINS","code":"US-NY-VOI"},
          {"name":"DOWNSVILLE","code":"US-NY-DWQ"},
          {"name":"DRESDEN","code":"US-NY-AAU"},
          {"name":"DRYDEN","code":"US-NY-D8Y"},
          {"name":"DUANESBURG","code":"US-NY-5DB"},
          {"name":"DUNDEE","code":"US-NY-DYK"},
          {"name":"DUNKIRK","code":"US-NY-DKK"},
          {"name":"EAGLE BRIDGE","code":"US-NY-DMQ"},
          {"name":"EAST AMHERST","code":"US-NY-E7T"},
          {"name":"EAST AURORA","code":"US-NY-EAO"},
          {"name":"EAST BLOOMFIELD","code":"US-NY-ZTB"},
          {"name":"EAST BRANCH","code":"US-NY-D4B"},
          {"name":"EAST CHATHAM","code":"US-NY-EQN"},
          {"name":"EAST CONCORD","code":"US-NY-ECX"},
          {"name":"EAST DURHAM","code":"US-NY-ED8"},
          {"name":"EAST ELMHURST","code":"US-NY-EHU"},
          {"name":"EAST FARMINGDALE","code":"US-NY-EFR"},
          {"name":"EAST FISHKILL","code":"US-NY-TVK"},
          {"name":"EAST GLENVILLE","code":"US-NY-EGZ"},
          {"name":"EAST GREENBUSH","code":"US-NY-EGY"},
          {"name":"EAST HAMPTON","code":"US-NY-HTO"},
          {"name":"EAST ISLIP","code":"US-NY-IS8"},
          {"name":"EAST JEWETT","code":"US-NY-JW7"},
          {"name":"EAST MEADOW","code":"US-NY-EM9"},
          {"name":"EAST MEREDITH","code":"US-NY-E2M"},
          {"name":"EAST MORICHES","code":"US-NY-EXM"},
          {"name":"EAST NEW YORK","code":"US-NY-5EN"},
          {"name":"EAST NORTHPORT","code":"US-NY-EAP"},
          {"name":"EAST NORWICH","code":"US-NY-R9W"},
          {"name":"EAST OTTO","code":"US-NY-O3T"},
          {"name":"EAST PALMYRA","code":"US-NY-EYH"},
          {"name":"EAST PATCHOGUE","code":"US-NY-EZP"},
          {"name":"EAST PEMBROKE","code":"US-NY-EPJ"},
          {"name":"EAST QUOGUE","code":"US-NY-EQG"},
          {"name":"EAST ROCHESTER","code":"US-NY-ERC"},
          {"name":"EAST ROCKAWAY","code":"US-NY-EKY"},
          {"name":"EAST SETAUKET","code":"US-NY-QET"},
          {"name":"EAST SPRINGFIELD","code":"US-NY-EQ9"},
          {"name":"EAST SYRACUSE","code":"US-NY-ESY"},
          {"name":"EASTCHESTER","code":"US-NY-ETJ"},
          {"name":"EASTON","code":"US-NY-EZN"},
          {"name":"EASTPORT","code":"US-NY-E79"},
          {"name":"EDEN","code":"US-NY-EDY"},
          {"name":"EDGEWOOD","code":"US-NY-EOQ"},
          {"name":"EDGEWOOD, SUFFOLK","code":"US-NY-EW5"},
          {"name":"ELBA","code":"US-NY-NYE"},
          {"name":"ELBRIDGE","code":"US-NY-ELG"},
          {"name":"ELIZABETHTOWN","code":"US-NY-EBW"},
          {"name":"ELIZAVILLE","code":"US-NY-EZ2"},
          {"name":"ELKA PARK","code":"US-NY-EP2"},
          {"name":"ELLENVILLE","code":"US-NY-EV3"},
          {"name":"ELLICOTTVILLE","code":"US-NY-ECV"},
          {"name":"ELMA","code":"US-NY-EFA"},
          {"name":"ELMA, ERIE","code":"US-NY-EL2"},
          {"name":"ELMHURST","code":"US-NY-EH2"},
          {"name":"ELMIRA","code":"US-NY-ELM"},
          {"name":"ELMIRA HEIGHTS","code":"US-NY-EMR"},
          {"name":"ELMONT","code":"US-NY-QDM"},
          {"name":"ELMSFORD","code":"US-NY-UFR"},
          {"name":"ENDICOTT","code":"US-NY-ENC"},
          {"name":"ENDICOTT-JOHNSON CITY-BINGHAMTON APT","code":"US-NY-BGM"},
          {"name":"ENDWELL","code":"US-NY-W9N"},
          {"name":"ENGLEWOOD","code":"US-NY-EW4"},
          {"name":"ERWINS","code":"US-NY-QEW"},
          {"name":"ESOPUS","code":"US-NY-ES8"},
          {"name":"ESPERANCE","code":"US-NY-EPZ"},
          {"name":"ESSEX","code":"US-NY-3EX"},
          {"name":"EVANS MILLS","code":"US-NY-E9M"},
          {"name":"FAIRFIELD","code":"US-NY-FFY"},
          {"name":"FAIRPORT","code":"US-NY-XFP"},
          {"name":"FALCONER","code":"US-NY-AOR"},
          {"name":"FANCHER","code":"US-NY-FCR"},
          {"name":"FAR ROCKAWAY, QUEENS","code":"US-NY-FR2"},
          {"name":"FARMINGDALE","code":"US-NY-FRG"},
          {"name":"FARMINGTON","code":"US-NY-FGT"},
          {"name":"FARMINGVILLE","code":"US-NY-FGQ"},
          {"name":"FAYETTEVILLE","code":"US-NY-FY9"},
          {"name":"FERNDALE","code":"US-NY-QFN"},
          {"name":"FEURA BUSH","code":"US-NY-XFJ"},
          {"name":"FILLMORE","code":"US-NY-FMO"},
          {"name":"FINDLEY LAKE","code":"US-NY-47L"},
          {"name":"FISHERS","code":"US-NY-OQQ"},
          {"name":"FISHERS ISLAND","code":"US-NY-FID"},
          {"name":"FISHKILL","code":"US-NY-FIK"},
          {"name":"FLANDERS","code":"US-NY-FDQ"},
          {"name":"FLEISCHMANNS","code":"US-NY-FMZ"},
          {"name":"FLEMING","code":"US-NY-3FL"},
          {"name":"FLOODWOOD","code":"US-NY-FFW"},
          {"name":"FLORAL PARK","code":"US-NY-FLP"},
          {"name":"FLORIDA","code":"US-NY-FOA"},
          {"name":"FLUSHING APT/NEW YORK","code":"US-NY-FLU"},
          {"name":"FLUSHING/QUEENS/NEW YORK","code":"US-NY-FSG"},
          {"name":"FONDA","code":"US-NY-FOY"},
          {"name":"FOREST HILLS/QUEENS/NEW YORK","code":"US-NY-FHL"},
          {"name":"FORESTBURG","code":"US-NY-F3R"},
          {"name":"FORESTPORT","code":"US-NY-KOQ"},
          {"name":"FORESTVILLE","code":"US-NY-F9V"},
          {"name":"FORT ANN","code":"US-NY-FN7"},
          {"name":"FORT COVINGTON","code":"US-NY-FCO"},
          {"name":"FORT DRUM","code":"US-NY-FUW"},
          {"name":"FORT EDWARD","code":"US-NY-FTE"},
          {"name":"FORT MONTGOMERY","code":"US-NY-FMR"},
          {"name":"FORT PLAIN","code":"US-NY-7FP"},
          {"name":"FRANKFORT","code":"US-NY-FKR"},
          {"name":"FRANKLIN SPRINGS","code":"US-NY-KYR"},
          {"name":"FRANKLIN SQUARE","code":"US-NY-FSR"},
          {"name":"FRASER","code":"US-NY-FZY"},
          {"name":"FREDONIA","code":"US-NY-FEO"},
          {"name":"FREEDOM","code":"US-NY-VFM"},
          {"name":"FREEHOLD","code":"US-NY-FE3"},
          {"name":"FREEPORT","code":"US-NY-FRX"},
          {"name":"FREEVILLE","code":"US-NY-FV9"},
          {"name":"FREMONT CENTER","code":"US-NY-FC9"},
          {"name":"FRESH MEADOWS","code":"US-NY-FSE"},
          {"name":"FREWSBURG","code":"US-NY-FB9"},
          {"name":"FULTON","code":"US-NY-FUN"},
          {"name":"FULTONVILLE","code":"US-NY-FV3"},
          {"name":"GABRIELS","code":"US-NY-GBF"},
          {"name":"GAINESVILLE","code":"US-NY-FUC"},
          {"name":"GANSEVOORT","code":"US-NY-GVR"},
          {"name":"GARDEN CITY","code":"US-NY-JHC"},
          {"name":"GARDEN CITY PARK","code":"US-NY-GP8"},
          {"name":"GARDINER","code":"US-NY-NXR"},
          {"name":"GARFIELD","code":"US-NY-GFR"},
          {"name":"GARNERVILLE","code":"US-NY-GV2"},
          {"name":"GARRATTSVILLE","code":"US-NY-GAE"},
          {"name":"GARRISON","code":"US-NY-GA7"},
          {"name":"GASPORT","code":"US-NY-GSQ"},
          {"name":"GEDDES","code":"US-NY-DD9"},
          {"name":"GENESEO","code":"US-NY-GES"},
          {"name":"GENEVA","code":"US-NY-GEN"},
          {"name":"GENOA","code":"US-NY-7GO"},
          {"name":"GERMANTOWN","code":"US-NY-GMZ"},
          {"name":"GETZVILLE","code":"US-NY-GZE"},
          {"name":"GHENT","code":"US-NY-GHB"},
          {"name":"GLEN COVE","code":"US-NY-GLN"},
          {"name":"GLEN HEAD","code":"US-NY-GH4"},
          {"name":"GLEN OAKS","code":"US-NY-GO4"},
          {"name":"GLEN SPEY","code":"US-NY-GPY"},
          {"name":"GLENDALE","code":"US-NY-GDL"},
          {"name":"GLENMONT","code":"US-NY-GTZ"},
          {"name":"GLENS FALLS","code":"US-NY-GFL"},
          {"name":"GLENVILLE","code":"US-NY-GV5"},
          {"name":"GLENWOOD LANDING","code":"US-NY-GW9"},
          {"name":"GLOVERSVILLE","code":"US-NY-GVK"},
          {"name":"GORHAM","code":"US-NY-ORM"},
          {"name":"GOSHEN","code":"US-NY-OEN"},
          {"name":"GOUVERNEUR","code":"US-NY-GVN"},
          {"name":"GRAND GORGE","code":"US-NY-DGG"},
          {"name":"GRAND ISLAND","code":"US-NY-GDK"},
          {"name":"GRANVILLE","code":"US-NY-GVI"},
          {"name":"GREAT NECK","code":"US-NY-GNK"},
          {"name":"GREECE","code":"US-NY-ZDK"},
          {"name":"GREEN ISLAND","code":"US-NY-XFV"},
          {"name":"GREEN POINT","code":"US-NY-GP9"},
          {"name":"GREENBORO","code":"US-NY-GEB"},
          {"name":"GREENE","code":"US-NY-EEG"},
          {"name":"GREENFIELD","code":"US-NY-RU6"},
          {"name":"GREENLAWN","code":"US-NY-G4R"},
          {"name":"GREENPORT","code":"US-NY-GP4"},
          {"name":"GREENVALE","code":"US-NY-GVJ"},
          {"name":"GREENVILLE","code":"US-NY-GNY"},
          {"name":"GREENWICH","code":"US-NY-GRW"},
          {"name":"GROTON","code":"US-NY-GTQ"},
          {"name":"GUILDERLAND","code":"US-NY-GL3"},
          {"name":"GUILDERLAND CENTER","code":"US-NY-GUW"},
          {"name":"HAGAMAN","code":"US-NY-HQG"},
          {"name":"HALCOTT CENTER","code":"US-NY-TKC"},
          {"name":"HALFMOON","code":"US-NY-HFQ"},
          {"name":"HALL","code":"US-NY-H4L"},
          {"name":"HAMBURG","code":"US-NY-H9G"},
          {"name":"HAMBURG","code":"US-NY-HG7"},
          {"name":"HAMDEN","code":"US-NY-NY3"},
          {"name":"HAMILTON","code":"US-NY-HJK"},
          {"name":"HAMLIN","code":"US-NY-ZNY"},
          {"name":"HAMMONDSPORT","code":"US-NY-HMP"},
          {"name":"HANCOCK","code":"US-NY-NCC"},
          {"name":"HARFORD","code":"US-NY-HCZ"},
          {"name":"HARPURSVILLE","code":"US-NY-HP2"},
          {"name":"HARRIMAN","code":"US-NY-HRI"},
          {"name":"HARRISON","code":"US-NY-ZHS"},
          {"name":"HARTFORD","code":"US-NY-QHD"},
          {"name":"HARTSDALE","code":"US-NY-HAS"},
          {"name":"HASTINGS-ON-HUDSON","code":"US-NY-HOX"},
          {"name":"HAUPPAUGE","code":"US-NY-HAP"},
          {"name":"HAVERSTRAW","code":"US-NY-HVT"},
          {"name":"HAWTHORNE","code":"US-NY-HAW"},
          {"name":"HEBRON","code":"US-NY-HZQ"},
          {"name":"HECTOR","code":"US-NY-HR4"},
          {"name":"HEMPSTEAD","code":"US-NY-HEP"},
          {"name":"HENDERSON","code":"US-NY-NDS"},
          {"name":"HENRIETTA","code":"US-NY-HT8"},
          {"name":"HERKIMER","code":"US-NY-HHY"},
          {"name":"HEUVELTON","code":"US-NY-VLT"},
          {"name":"HEWLETT","code":"US-NY-HWX"},
          {"name":"HICKSVILLE","code":"US-NY-UJM"},
          {"name":"HIGH FALLS","code":"US-NY-IHF"},
          {"name":"HIGHLAND","code":"US-NY-HIH"},
          {"name":"HIGHLAND FALLS","code":"US-NY-HF2"},
          {"name":"HIGHLAND MILLS","code":"US-NY-HI7"},
          {"name":"HILLBURN","code":"US-NY-HBM"},
          {"name":"HILLSDALE","code":"US-NY-9NY"},
          {"name":"HILTON","code":"US-NY-TLQ"},
          {"name":"HIMROD","code":"US-NY-OHD"},
          {"name":"HOGANSBURG","code":"US-NY-HGB"},
          {"name":"HOLBROOK","code":"US-NY-ZHK"},
          {"name":"HOLCOMB","code":"US-NY-HYH"},
          {"name":"HOLLAND","code":"US-NY-XGJ"},
          {"name":"HOLLEY","code":"US-NY-NYH"},
          {"name":"HOLLIS","code":"US-NY-HLU"},
          {"name":"HOLMES","code":"US-NY-YHM"},
          {"name":"HOLTSVILLE","code":"US-NY-HOV"},
          {"name":"HOMER","code":"US-NY-HOR"},
          {"name":"HONEOYE","code":"US-NY-OYE"},
          {"name":"HONEOYE FALLS","code":"US-NY-HFA"},
          {"name":"HOOSICK FALLS","code":"US-NY-XGB"},
          {"name":"HOPEWELL JUNCTION","code":"US-NY-HJ2"},
          {"name":"HORNELL","code":"US-NY-HNW"},
          {"name":"HORSEHEADS","code":"US-NY-HRH"},
          {"name":"HOWARD BEACH/QUEENS/NEW YORK","code":"US-NY-XGO"},
          {"name":"HUBBARDSVILLE","code":"US-NY-HU5"},
          {"name":"HUDSON","code":"US-NY-HCC"},
          {"name":"HUDSON FALLS","code":"US-NY-HFY"},
          {"name":"HUGUENOT","code":"US-NY-HGU"},
          {"name":"HUNTER","code":"US-NY-H6R"},
          {"name":"HUNTER","code":"US-NY-KHQ"},
          {"name":"HUNTINGTON","code":"US-NY-HUH"},
          {"name":"HUNTINGTON STATION","code":"US-NY-ZHG"},
          {"name":"HUNTS POINT/BRONX/NEW YORK","code":"US-NY-HUI"},
          {"name":"HURLEY","code":"US-NY-HUZ"},
          {"name":"HYDE PARK","code":"US-NY-HEK"},
          {"name":"ILION","code":"US-NY-IOZ"},
          {"name":"INDIAN LAKE","code":"US-NY-7IL"},
          {"name":"INTERLAKEN","code":"US-NY-IEK"},
          {"name":"INWOOD","code":"US-NY-INO"},
          {"name":"IRONDEQUOIT","code":"US-NY-DQ2"},
          {"name":"IRVING","code":"US-NY-V9N"},
          {"name":"IRVINGTON","code":"US-NY-IVT"},
          {"name":"ISLAND PARK","code":"US-NY-IAP"},
          {"name":"ISLANDIA","code":"US-NY-YIE"},
          {"name":"ISLIP","code":"US-NY-ISP"},
          {"name":"ISLIP TERRACE","code":"US-NY-IT2"},
          {"name":"ITHACA","code":"US-NY-ITH"},
          {"name":"JACKSON HEIGHTS","code":"US-NY-JKH"},
          {"name":"JAMAICA/QUEENS/NEW YORK","code":"US-NY-UJV"},
          {"name":"JAMESPORT","code":"US-NY-JPY"},
          {"name":"JAMESTOWN","code":"US-NY-JHW"},
          {"name":"JAMESVILLE","code":"US-NY-JVE"},
          {"name":"JEFFERSON","code":"US-NY-JFA"},
          {"name":"JEFFERSONVILLE","code":"US-NY-JEI"},
          {"name":"JERICHO","code":"US-NY-JRI"},
          {"name":"JOHN F. KENNEDY APT/NEW YORK","code":"US-NY-JFK"},
          {"name":"JOHNSBURG","code":"US-NY-JQB"},
          {"name":"JOHNSON","code":"US-NY-JNY"},
          {"name":"JOHNSON CITY","code":"US-NY-JOY"},
          {"name":"JOHNSON CITY-BINGHAMTON-ENDICOTT APT","code":"US-NY-BGM"},
          {"name":"JOHNSTOWN","code":"US-NY-JTW"},
          {"name":"JORDAN","code":"US-NY-JRD"},
          {"name":"KANONA","code":"US-NY-JKA"},
          {"name":"KATONAH","code":"US-NY-KAT"},
          {"name":"KEENE VALLEY","code":"US-NY-KVZ"},
          {"name":"KEESEVILLE","code":"US-NY-M3Z"},
          {"name":"KENDALL","code":"US-NY-ZDL"},
          {"name":"KENILWORTH","code":"US-NY-KWW"},
          {"name":"KENMORE","code":"US-NY-KMR"},
          {"name":"KERHONKSON","code":"US-NY-QZK"},
          {"name":"KEW GARDENS, QUEENS","code":"US-NY-KG2"},
          {"name":"KIAMESHA LAKE","code":"US-NY-KMS"},
          {"name":"KILL BUCK","code":"US-NY-JBQ"},
          {"name":"KINDERHOOK","code":"US-NY-KHK"},
          {"name":"KING FERRY","code":"US-NY-KGF"},
          {"name":"KINGS PARK","code":"US-NY-KSP"},
          {"name":"KINGSTON","code":"US-NY-KGT"},
          {"name":"KIRKVILLE","code":"US-NY-KR5"},
          {"name":"KIRKWOOD","code":"US-NY-KIR"},
          {"name":"LA FARGEVILLE","code":"US-NY-KGV"},
          {"name":"LA GUARDIA APT/NEW YORK","code":"US-NY-LGA"},
          {"name":"LACKAWANNA","code":"US-NY-XHP"},
          {"name":"LACONA","code":"US-NY-LC4"},
          {"name":"LAFAYETTE","code":"US-NY-LA6"},
          {"name":"LAGRANGEVILLE","code":"US-NY-QLG"},
          {"name":"LAKE GEORGE","code":"US-NY-LG2"},
          {"name":"LAKE GROVE","code":"US-NY-LK7"},
          {"name":"LAKE HUNTINGTON","code":"US-NY-LU7"},
          {"name":"LAKE KATERINE","code":"US-NY-LK2"},
          {"name":"LAKE KATRINE","code":"US-NY-KYQ"},
          {"name":"LAKE PLACID","code":"US-NY-LKP"},
          {"name":"LAKE RONKONKOMA","code":"US-NY-KKM"},
          {"name":"LAKE SUCCESS","code":"US-NY-LSS"},
          {"name":"LAKE VIEW, ERIE","code":"US-NY-KVW"},
          {"name":"LAKEVILLE, LIVINGSTON","code":"US-NY-KIE"},
          {"name":"LAKEWOOD","code":"US-NY-LKY"},
          {"name":"LAMONT","code":"US-NY-LMD"},
          {"name":"LANCASTER","code":"US-NY-LAC"},
          {"name":"LANSING, TOMKINS","code":"US-NY-LSZ"},
          {"name":"LARCHMONT","code":"US-NY-ZLR"},
          {"name":"LATHAM","code":"US-NY-LTM"},
          {"name":"LAUREL","code":"US-NY-TQJ"},
          {"name":"LAURELTON","code":"US-NY-UA7"},
          {"name":"LAURENS","code":"US-NY-LUS"},
          {"name":"LAWRENCE","code":"US-NY-LWE"},
          {"name":"LE ROY","code":"US-NY-UQG"},
          {"name":"LEE","code":"US-NY-LQE"},
          {"name":"LEICESTER","code":"US-NY-7LS"},
          {"name":"LEVITTOWN","code":"US-NY-LEO"},
          {"name":"LEWISTON","code":"US-NY-LUT"},
          {"name":"LIBERTY","code":"US-NY-LJY"},
          {"name":"LIDO BEACH","code":"US-NY-LOB"},
          {"name":"LIMA","code":"US-NY-LJM"},
          {"name":"LINDEN HILL","code":"US-NY-DD6"},
          {"name":"LINDENHURST","code":"US-NY-LDH"},
          {"name":"LINWOOD","code":"US-NY-LD4"},
          {"name":"LISBON","code":"US-NY-IBN"},
          {"name":"LITTLE FALLS","code":"US-NY-LLF"},
          {"name":"LITTLE GENESEE","code":"US-NY-LTG"},
          {"name":"LITTLE NECK/QUEENS/NEW YORK","code":"US-NY-LYK"},
          {"name":"LITTLE VALLEY","code":"US-NY-L8Y"},
          {"name":"LIVERPOOL","code":"US-NY-LPX"},
          {"name":"LIVINGSTON","code":"US-NY-LVY"},
          {"name":"LIVINGSTON MANOR","code":"US-NY-LM4"},
          {"name":"LIVONIA","code":"US-NY-V8I"},
          {"name":"LOCKPORT","code":"US-NY-LOP"},
          {"name":"LOCUST VALLEY","code":"US-NY-LEY"},
          {"name":"LODI","code":"US-NY-LO7"},
          {"name":"LONG BEACH","code":"US-NY-NGC"},
          {"name":"LONG EDDY","code":"US-NY-DYP"},
          {"name":"LONG ISLAND CITY/QUEENS/NEW YORK","code":"US-NY-XHO"},
          {"name":"LONG LAKE","code":"US-NY-LO3"},
          {"name":"LOUDONVILLE","code":"US-NY-QYL"},
          {"name":"LOWVILLE","code":"US-NY-LWF"},
          {"name":"LYNBROOK","code":"US-NY-LYB"},
          {"name":"LYNDONVILLE","code":"US-NY-NYK"},
          {"name":"LYONS","code":"US-NY-LYY"},
          {"name":"LYONS FALLS","code":"US-NY-YSF"},
          {"name":"LYSANDER","code":"US-NY-ZYA"},
          {"name":"MACEDON","code":"US-NY-XIG"},
          {"name":"MADISON","code":"US-NY-MO7"},
          {"name":"MAHOPAC","code":"US-NY-MAJ"},
          {"name":"MALONE","code":"US-NY-NYM"},
          {"name":"MALTA","code":"US-NY-MLX"},
          {"name":"MAMARONECK","code":"US-NY-UQJ"},
          {"name":"MANCHESTER","code":"US-NY-NKR"},
          {"name":"MANHASSET","code":"US-NY-MH8"},
          {"name":"MANHATTAN/NEW YORK","code":"US-NY-MNH"},
          {"name":"MANHEIM CENTER","code":"US-NY-ZMY"},
          {"name":"MANLIUS","code":"US-NY-AIU"},
          {"name":"MANNSVILLE","code":"US-NY-NSV"},
          {"name":"MANORVILLE","code":"US-NY-MRA"},
          {"name":"MARATHON","code":"US-NY-NYA"},
          {"name":"MARCELLUS","code":"US-NY-ZLS"},
          {"name":"MARCY","code":"US-NY-ZAY"},
          {"name":"MARGARETVILLE","code":"US-NY-ZEU"},
          {"name":"MARION","code":"US-NY-MXB"},
          {"name":"MARLBORO","code":"US-NY-MBZ"},
          {"name":"MASPETH","code":"US-NY-MPB"},
          {"name":"MASSAPEQUA","code":"US-NY-AQU"},
          {"name":"MASSENA","code":"US-NY-MSS"},
          {"name":"MASTIC","code":"US-NY-ZTC"},
          {"name":"MASTIC BEACH","code":"US-NY-MZ7"},
          {"name":"MATTITUCK","code":"US-NY-XUK"},
          {"name":"MATTYDALE","code":"US-NY-MTD"},
          {"name":"MAYBROOK","code":"US-NY-MYQ"},
          {"name":"MAYVILLE","code":"US-NY-AY7"},
          {"name":"MAYWOOD, ALBANY","code":"US-NY-MQF"},
          {"name":"MCCONNELLSVILLE","code":"US-NY-ZXW"},
          {"name":"MCDONOUGH","code":"US-NY-9DH"},
          {"name":"MCGRAW","code":"US-NY-M64"},
          {"name":"MECHANICVILLE","code":"US-NY-MHV"},
          {"name":"MEDFORD","code":"US-NY-MDE"},
          {"name":"MEDINA","code":"US-NY-MDK"},
          {"name":"MELLENVILLE","code":"US-NY-M34"},
          {"name":"MELROSE","code":"US-NY-M5L"},
          {"name":"MELVILLE","code":"US-NY-ZMV"},
          {"name":"MENANDS","code":"US-NY-NAD"},
          {"name":"MENDON","code":"US-NY-M8N"},
          {"name":"MEREDITH","code":"US-NY-ME5"},
          {"name":"MERRICK","code":"US-NY-MER"},
          {"name":"MIDDLE FALLS","code":"US-NY-IFH"},
          {"name":"MIDDLE GRANVILLE","code":"US-NY-MGL"},
          {"name":"MIDDLE GROVE","code":"US-NY-M9G"},
          {"name":"MIDDLE ISLAND","code":"US-NY-ZIX"},
          {"name":"MIDDLE VILLAGE","code":"US-NY-MVG"},
          {"name":"MIDDLEPORT","code":"US-NY-M3P"},
          {"name":"MIDDLETOWN","code":"US-NY-MIY"},
          {"name":"MILAN","code":"US-NY-7ML"},
          {"name":"MILFORD","code":"US-NY-QFD"},
          {"name":"MILLBROOK","code":"US-NY-ZOO"},
          {"name":"MILLER PLACE","code":"US-NY-IPL"},
          {"name":"MILLERTON","code":"US-NY-MT4"},
          {"name":"MILLWOOD","code":"US-NY-MW7"},
          {"name":"MILTON","code":"US-NY-MZG"},
          {"name":"MINEOLA","code":"US-NY-MNA"},
          {"name":"MINEVILLE","code":"US-NY-XIV"},
          {"name":"MOHAWK","code":"US-NY-M67"},
          {"name":"MOHEGAN LAKE","code":"US-NY-OH9"},
          {"name":"MONROE","code":"US-NY-VMO"},
          {"name":"MONSEY","code":"US-NY-MSE"},
          {"name":"MONTAUK","code":"US-NY-MTP"},
          {"name":"MONTEBELLO","code":"US-NY-MB6"},
          {"name":"MONTGOMERY","code":"US-NY-MGJ"},
          {"name":"MONTICELLO","code":"US-NY-MSV"},
          {"name":"MONTOUR FALLS","code":"US-NY-URF"},
          {"name":"MOOERS","code":"US-NY-MO3"},
          {"name":"MOOERS FORKS","code":"US-NY-M2F"},
          {"name":"MOORES MILL","code":"US-NY-MQE"},
          {"name":"MORAVIA","code":"US-NY-VIJ"},
          {"name":"MORICHES","code":"US-NY-2MR"},
          {"name":"MORRISTOWN","code":"US-NY-MRT"},
          {"name":"MORRISVILLE","code":"US-NY-MZL"},
          {"name":"MOUNT KISCO","code":"US-NY-MKS"},
          {"name":"MOUNT MORRIS","code":"US-NY-OUO"},
          {"name":"MOUNT SINAI","code":"US-NY-JUI"},
          {"name":"MOUNT UPTON","code":"US-NY-UUE"},
          {"name":"MOUNT VERNON","code":"US-NY-MVH"},
          {"name":"MOUNT VERNON","code":"US-NY-YMV"},
          {"name":"MOUNTAINVILLE","code":"US-NY-HHF"},
          {"name":"MUNNSVILLE","code":"US-NY-UNV"},
          {"name":"NANUET","code":"US-NY-NAE"},
          {"name":"NAPANOCH","code":"US-NY-NH8"},
          {"name":"NAPLES","code":"US-NY-NPK"},
          {"name":"NARROWSBURG","code":"US-NY-NGB"},
          {"name":"NASSAU","code":"US-NY-NYU"},
          {"name":"NATURAL BRIDGE","code":"US-NY-NAT"},
          {"name":"NESCONSET","code":"US-NY-NES"},
          {"name":"NEW BERLIN","code":"US-NY-NBR"},
          {"name":"NEW CITY","code":"US-NY-NJY"},
          {"name":"NEW CONNECTICUT","code":"US-NY-CN6"},
          {"name":"NEW HAMBURG","code":"US-NY-N6H"},
          {"name":"NEW HAMPTON","code":"US-NY-N9H"},
          {"name":"NEW HARTFORD","code":"US-NY-QND"},
          {"name":"NEW HYDE PARK","code":"US-NY-NHY"},
          {"name":"NEW KINGSTON","code":"US-NY-NKG"},
          {"name":"NEW PALTZ","code":"US-NY-NEZ"},
          {"name":"NEW ROCHELLE","code":"US-NY-UXO"},
          {"name":"NEW WINDSOR","code":"US-NY-NWI"},
          {"name":"NEW YORK","code":"US-NY-NYC"},
          {"name":"NEW YORK MILLS","code":"US-NY-Z2E"},
          {"name":"NEWARK","code":"US-NY-NNY"},
          {"name":"NEWARK VALLEY","code":"US-NY-NUV"},
          {"name":"NEWBURGH","code":"US-NY-SWF"},
          {"name":"NEWFANE","code":"US-NY-NFE"},
          {"name":"NEWFIELD","code":"US-NY-NFD"},
          {"name":"NEWTON FALLS","code":"US-NY-NFS"},
          {"name":"NEWTONVILLE","code":"US-NY-NN5"},
          {"name":"NIAGARA FALLS","code":"US-NY-IAG"},
          {"name":"NICHOLS","code":"US-NY-SN9"},
          {"name":"NICHOLS, TIOGA","code":"US-NY-NS3"},
          {"name":"NISKAYUNA","code":"US-NY-IYF"},
          {"name":"NORFOLK","code":"US-NY-NFK"},
          {"name":"NORTH","code":"US-NY-NRR"},
          {"name":"NORTH BABYLON","code":"US-NY-NBY"},
          {"name":"NORTH BAY SHORE","code":"US-NY-NBV"},
          {"name":"NORTH BELLMORE","code":"US-NY-NLM"},
          {"name":"NORTH BLENHEIM","code":"US-NY-RHB"},
          {"name":"NORTH CHILI","code":"US-NY-N8C"},
          {"name":"NORTH COLLINS","code":"US-NY-NRN"},
          {"name":"NORTH CREEK","code":"US-NY-2NC"},
          {"name":"NORTH HAVEN","code":"US-NY-NV8"},
          {"name":"NORTH HOOSICK","code":"US-NY-NH4"},
          {"name":"NORTH JAVA","code":"US-NY-9JV"},
          {"name":"NORTH LAWRENCE","code":"US-NY-NLY"},
          {"name":"NORTH LINDENHURST","code":"US-NY-N9T"},
          {"name":"NORTH MERRICK","code":"US-NY-NM7"},
          {"name":"NORTH RIVER","code":"US-NY-OIR"},
          {"name":"NORTH ROSE","code":"US-NY-NQZ"},
          {"name":"NORTH SALEM","code":"US-NY-NY5"},
          {"name":"NORTH SYRACUSE","code":"US-NY-NSI"},
          {"name":"NORTH TONAWANDA","code":"US-NY-XJQ"},
          {"name":"NORTH WHITE PLAINS","code":"US-NY-NXU"},
          {"name":"NORTHPORT","code":"US-NY-NQP"},
          {"name":"NORTHVILLE (RIVERHEAD)","code":"US-NY-NVR"},
          {"name":"NORWICH","code":"US-NY-OIC"},
          {"name":"NORWOOD","code":"US-NY-ZNO"},
          {"name":"NUNDA","code":"US-NY-NXN"},
          {"name":"NYACK","code":"US-NY-NYX"},
          {"name":"OAK HILL","code":"US-NY-FKH"},
          {"name":"OAKDALE","code":"US-NY-OQD"},
          {"name":"OAKFIELD","code":"US-NY-OK2"},
          {"name":"OAKLAND GARDENS","code":"US-NY-O5G"},
          {"name":"OCEANSIDE","code":"US-NY-XJX"},
          {"name":"ODESSA","code":"US-NY-ODS"},
          {"name":"OGDENSBURG","code":"US-NY-OGS"},
          {"name":"OLD BETHPAGE","code":"US-NY-OPG"},
          {"name":"OLD BROOKVILLE","code":"US-NY-DXX"},
          {"name":"OLD CHATHAM","code":"US-NY-OC6"},
          {"name":"OLD WESTBURY","code":"US-NY-OW9"},
          {"name":"OLEAN","code":"US-NY-OLE"},
          {"name":"ONEIDA","code":"US-NY-OND"},
          {"name":"ONEONTA","code":"US-NY-ONH"},
          {"name":"ONONDAGA","code":"US-NY-OGQ"},
          {"name":"ONTARIO","code":"US-NY-OTF"},
          {"name":"ORANGEBURG","code":"US-NY-OBG"},
          {"name":"ORCHARD PARK","code":"US-NY-OPY"},
          {"name":"ORISKANY","code":"US-NY-OKY"},
          {"name":"ORISKANY FALLS","code":"US-NY-OFY"},
          {"name":"OSSINING","code":"US-NY-OSS"},
          {"name":"OSWEGO","code":"US-NY-OSW"},
          {"name":"OTEGO","code":"US-NY-OQG"},
          {"name":"OTISVILLE","code":"US-NY-TSE"},
          {"name":"OVID","code":"US-NY-VID"},
          {"name":"OWEGO","code":"US-NY-OWG"},
          {"name":"OXFORD","code":"US-NY-OX3"},
          {"name":"OYSTER BAY","code":"US-NY-OYB"},
          {"name":"OZONE PARK/QUEENS/NEW YORK","code":"US-NY-OZO"},
          {"name":"PAINTED POST","code":"US-NY-PWY"},
          {"name":"PALATINE BRIDGE","code":"US-NY-PBE"},
          {"name":"PALENVILLE","code":"US-NY-PV8"},
          {"name":"PALISADES","code":"US-NY-IAS"},
          {"name":"PALMYRA","code":"US-NY-PZQ"},
          {"name":"PARISH","code":"US-NY-PSU"},
          {"name":"PATCHOGUE","code":"US-NY-PCP"},
          {"name":"PATTERSON","code":"US-NY-PAN"},
          {"name":"PAVILION","code":"US-NY-P49"},
          {"name":"PAWLING","code":"US-NY-PWL"},
          {"name":"PEARL RIVER","code":"US-NY-PAV"},
          {"name":"PECKSVILLE","code":"US-NY-PEK"},
          {"name":"PECONIC","code":"US-NY-QPN"},
          {"name":"PEEKSKILL","code":"US-NY-ZGF"},
          {"name":"PELHAM","code":"US-NY-PHW"},
          {"name":"PELHAM MANOR","code":"US-NY-PEX"},
          {"name":"PENFIELD","code":"US-NY-NFI"},
          {"name":"PENN YAN","code":"US-NY-PQQ"},
          {"name":"PERRY","code":"US-NY-NYP"},
          {"name":"PERU","code":"US-NY-PGU"},
          {"name":"PETERSBURG","code":"US-NY-TSB"},
          {"name":"PHELPS","code":"US-NY-PPX"},
          {"name":"PHILADELPHIA","code":"US-NY-PDP"},
          {"name":"PHILLIPSBURG","code":"US-NY-ZPG"},
          {"name":"PHILMONT","code":"US-NY-PH3"},
          {"name":"PHOENICIA","code":"US-NY-P6H"},
          {"name":"PHOENIX","code":"US-NY-XH5"},
          {"name":"PIERMONT","code":"US-NY-R9Y"},
          {"name":"PIFFARD","code":"US-NY-PFY"},
          {"name":"PINE BUSH","code":"US-NY-YPN"},
          {"name":"PINE CITY","code":"US-NY-PE3"},
          {"name":"PINE ISLAND","code":"US-NY-PNI"},
          {"name":"PINE PLAINS","code":"US-NY-QPY"},
          {"name":"PINE VALLEY","code":"US-NY-NAY"},
          {"name":"PITTSFIELD","code":"US-NY-PZF"},
          {"name":"PITTSFORD","code":"US-NY-PDY"},
          {"name":"PITTSTOWN","code":"US-NY-TSW"},
          {"name":"PLAINFIELD CENTER","code":"US-NY-XPF"},
          {"name":"PLAINVIEW","code":"US-NY-PVX"},
          {"name":"PLAINVILLE","code":"US-NY-ZG7"},
          {"name":"PLANDOME","code":"US-NY-JDP"},
          {"name":"PLATTEKILL","code":"US-NY-PJT"},
          {"name":"PLATTSBURGH","code":"US-NY-PLB"},
          {"name":"PLEASANT VALLEY","code":"US-NY-ZHJ"},
          {"name":"PLEASANTVILLE","code":"US-NY-VPP"},
          {"name":"POESTENKILL","code":"US-NY-OTK"},
          {"name":"POLAND","code":"US-NY-YXD"},
          {"name":"POMONA","code":"US-NY-PMA"},
          {"name":"PORT BYRON","code":"US-NY-Q3R"},
          {"name":"PORT CHESTER","code":"US-NY-UXZ"},
          {"name":"PORT EWEN","code":"US-NY-PE2"},
          {"name":"PORT IVORY/STATEN ISL/NEW YORK","code":"US-NY-PIY"},
          {"name":"PORT JEFFERSON","code":"US-NY-PTJ"},
          {"name":"PORT JERVIS","code":"US-NY-PJV"},
          {"name":"PORT MORRIS, BRONX","code":"US-NY-PM4"},
          {"name":"PORT WASHINGTON","code":"US-NY-POW"},
          {"name":"PORTAGEVILLE","code":"US-NY-ZPT"},
          {"name":"PORTER CORNERS","code":"US-NY-TC5"},
          {"name":"PORTVILLE","code":"US-NY-VTP"},
          {"name":"POTSDAM","code":"US-NY-PPT"},
          {"name":"POUGHKEEPSIE","code":"US-NY-POU"},
          {"name":"POUGHQUAG","code":"US-NY-OGH"},
          {"name":"POUND RIDGE","code":"US-NY-PG8"},
          {"name":"PRATTSBURG","code":"US-NY-PUG"},
          {"name":"PRATTSVILLE","code":"US-NY-9PV"},
          {"name":"PREBLE","code":"US-NY-PR9"},
          {"name":"PULASKI","code":"US-NY-PUA"},
          {"name":"PULTNEYVILLE","code":"US-NY-P5U"},
          {"name":"PURCHASE","code":"US-NY-YP5"},
          {"name":"PURLING","code":"US-NY-P4G"},
          {"name":"QUEENS VILLAGE","code":"US-NY-QVG"},
          {"name":"QUEENS/NEW YORK","code":"US-NY-QEE"},
          {"name":"QUEENSBURY","code":"US-NY-QBY"},
          {"name":"QUOGUE","code":"US-NY-QUO"},
          {"name":"RAMAPO","code":"US-NY-RYP"},
          {"name":"RANDOLPH","code":"US-NY-RJD"},
          {"name":"RANSOMVILLE","code":"US-NY-RAV"},
          {"name":"RAVENA","code":"US-NY-XRA"},
          {"name":"RED CREEK","code":"US-NY-RKQ"},
          {"name":"RED HOOK","code":"US-NY-REW"},
          {"name":"REGO PARK, QUEENS","code":"US-NY-RP2"},
          {"name":"RENSSELAER","code":"US-NY-RER"},
          {"name":"RETSOF","code":"US-NY-RT6"},
          {"name":"REXFORD","code":"US-NY-4EX"},
          {"name":"RHINEBECK","code":"US-NY-ZBK"},
          {"name":"RHODE ISLAND","code":"US-NY-RHO"},
          {"name":"RICHFIELD SPRINGS","code":"US-NY-D5F"},
          {"name":"RICHLAND","code":"US-NY-QCL"},
          {"name":"RICHMOND","code":"US-NY-ZKF"},
          {"name":"RICHMOND HILL/QUEENS/NEW YORK","code":"US-NY-RIX"},
          {"name":"RICHMONDVILLE","code":"US-NY-RHL"},
          {"name":"RIDGE","code":"US-NY-R5D"},
          {"name":"RIDGEWOOD","code":"US-NY-RGO"},
          {"name":"RIFTON","code":"US-NY-RQF"},
          {"name":"RIPARIUS","code":"US-NY-IAJ"},
          {"name":"RIPLEY","code":"US-NY-RP5"},
          {"name":"RIVERDALE","code":"US-NY-RV5"},
          {"name":"RIVERHEAD","code":"US-NY-RHD"},
          {"name":"ROCHESTER","code":"US-NY-ROC"},
          {"name":"ROCK CITY FALLS","code":"US-NY-OYA"},
          {"name":"ROCK HILL","code":"US-NY-R8L"},
          {"name":"ROCK TAVERN","code":"US-NY-XRT"},
          {"name":"ROCKAWAY BEACH","code":"US-NY-RW8"},
          {"name":"ROCKAWAY PARKS","code":"US-NY-RYK"},
          {"name":"ROCKLAND","code":"US-NY-KLA"},
          {"name":"ROCKVILLE CENTRE","code":"US-NY-QRC"},
          {"name":"ROME","code":"US-NY-RME"},
          {"name":"ROMULUS","code":"US-NY-RUZ"},
          {"name":"RONKONKOMA","code":"US-NY-RKK"},
          {"name":"ROOSEVELTOWN","code":"US-NY-RVT"},
          {"name":"ROSCOE","code":"US-NY-R5C"},
          {"name":"ROSEDALE","code":"US-NY-RDQ"},
          {"name":"ROSENDALE","code":"US-NY-OJE"},
          {"name":"ROSETON","code":"US-NY-RJT"},
          {"name":"ROSLYN","code":"US-NY-YL8"},
          {"name":"ROSLYN HEIGHTS","code":"US-NY-RYN"},
          {"name":"ROTTERDAM","code":"US-NY-RAJ"},
          {"name":"ROTTERDAM JUNCTION","code":"US-NY-RJN"},
          {"name":"ROUND LAKE","code":"US-NY-RL4"},
          {"name":"ROUSES POINT","code":"US-NY-RSX"},
          {"name":"RUSH","code":"US-NY-5US"},
          {"name":"RYE","code":"US-NY-ZRY"},
          {"name":"RYE BROOK","code":"US-NY-RRK"},
          {"name":"SAG HARBOR","code":"US-NY-2SH"},
          {"name":"SAG HARBOR","code":"US-NY-GXH"},
          {"name":"SAGAPONACK","code":"US-NY-SP2"},
          {"name":"SAINT ALBANS","code":"US-NY-TDA"},
          {"name":"SAINT JAMES","code":"US-NY-TJS"},
          {"name":"SAINT JOHNSVILLE","code":"US-NY-TOU"},
          {"name":"SALAMANCA","code":"US-NY-ZIN"},
          {"name":"SALEM","code":"US-NY-L7S"},
          {"name":"SALISBURY MILLS","code":"US-NY-YLS"},
          {"name":"SALT POINT","code":"US-NY-TLP"},
          {"name":"SANBORN","code":"US-NY-NBQ"},
          {"name":"SANDS POINT","code":"US-NY-ZDS"},
          {"name":"SANDY CREEK","code":"US-NY-SY3"},
          {"name":"SANFORD","code":"US-NY-S3F"},
          {"name":"SANGERFIELD","code":"US-NY-S9A"},
          {"name":"SARANAC LAKE","code":"US-NY-SLK"},
          {"name":"SARATOGA SPRINGS","code":"US-NY-SGA"},
          {"name":"SARDINIA","code":"US-NY-AII"},
          {"name":"SAUGERTIES","code":"US-NY-SUV"},
          {"name":"SAVANNAH","code":"US-NY-XMX"},
          {"name":"SAYVILLE","code":"US-NY-SYS"},
          {"name":"SCARSDALE","code":"US-NY-SCG"},
          {"name":"SCHAGHTICOKE","code":"US-NY-GHK"},
          {"name":"SCHENECTADY","code":"US-NY-SCH"},
          {"name":"SCHENEVUS","code":"US-NY-NVU"},
          {"name":"SCHODACK LANDING","code":"US-NY-SKN"},
          {"name":"SCHOHARIE","code":"US-NY-SR8"},
          {"name":"SCHUYLERVILLE","code":"US-NY-SV3"},
          {"name":"SCIPIO CENTER","code":"US-NY-IJC"},
          {"name":"SCOTIA","code":"US-NY-SOA"},
          {"name":"SCOTTSVILLE","code":"US-NY-OTV"},
          {"name":"SEA CLIFF","code":"US-NY-7OL"},
          {"name":"SEAFORD","code":"US-NY-QSF"},
          {"name":"SELDEN","code":"US-NY-EXL"},
          {"name":"SELKIRK","code":"US-NY-SKI"},
          {"name":"SENECA","code":"US-NY-UYP"},
          {"name":"SENECA FALLS","code":"US-NY-SFS"},
          {"name":"SHARON SPRINGS","code":"US-NY-SHS"},
          {"name":"SHELTER ISLAND","code":"US-NY-XSI"},
          {"name":"SHELTER ISLAND HEIGHTS","code":"US-NY-HDH"},
          {"name":"SHERBURNE","code":"US-NY-SRR"},
          {"name":"SHERIDAN","code":"US-NY-SRD"},
          {"name":"SHERMAN","code":"US-NY-DZZ"},
          {"name":"SHERRILL","code":"US-NY-RRI"},
          {"name":"SHIRLEY","code":"US-NY-WSH"},
          {"name":"SHOKAN","code":"US-NY-XSA"},
          {"name":"SHOREHAM","code":"US-NY-XLZ"},
          {"name":"SHORTSVILLE","code":"US-NY-SS8"},
          {"name":"SHROON","code":"US-NY-S5L"},
          {"name":"SHRUB OAK","code":"US-NY-SHK"},
          {"name":"SIDNEY","code":"US-NY-SXY"},
          {"name":"SILVER CREEK","code":"US-NY-ZII"},
          {"name":"SILVER SPRINGS","code":"US-NY-SVR"},
          {"name":"SKANEATELES","code":"US-NY-KNE"},
          {"name":"SKANEATELES FALLS","code":"US-NY-KAF"},
          {"name":"SLATE HILL","code":"US-NY-SVK"},
          {"name":"SLEEPY HOLLOW","code":"US-NY-SO3"},
          {"name":"SLEEPY HOLLOW MANOR","code":"US-NY-SH9"},
          {"name":"SLINGERLANDS","code":"US-NY-ZXS"},
          {"name":"SLOATSBURG","code":"US-NY-OTB"},
          {"name":"SMITHTOWN","code":"US-NY-STY"},
          {"name":"SMYRNA","code":"US-NY-NYY"},
          {"name":"SNYDER","code":"US-NY-NYR"},
          {"name":"SODUS","code":"US-NY-UYO"},
          {"name":"SODUS POINT","code":"US-NY-SOD"},
          {"name":"SOLVAY","code":"US-NY-OVA"},
          {"name":"SOMERS","code":"US-NY-OJS"},
          {"name":"SOUTH ALBANY","code":"US-NY-S6A"},
          {"name":"SOUTH BETHLEHEM","code":"US-NY-HH4"},
          {"name":"SOUTH DAYTON","code":"US-NY-SOI"},
          {"name":"SOUTH FALLSBURG","code":"US-NY-QPZ"},
          {"name":"SOUTH GLENS FALLS","code":"US-NY-JGF"},
          {"name":"SOUTH JAMESPORT","code":"US-NY-3SJ"},
          {"name":"SOUTH OZONE PARK","code":"US-NY-OZN"},
          {"name":"SOUTH PLYMOUTH","code":"US-NY-N7Y"},
          {"name":"SOUTH SALEM","code":"US-NY-TDQ"},
          {"name":"SOUTH WALES","code":"US-NY-OUW"},
          {"name":"SOUTH WINDSOR","code":"US-NY-XZR"},
          {"name":"SOUTHAMPTON","code":"US-NY-SSK"},
          {"name":"SOUTHFIELDS","code":"US-NY-TFS"},
          {"name":"SOUTHOLD","code":"US-NY-OUX"},
          {"name":"SPARROW BUSH","code":"US-NY-PWU"},
          {"name":"SPENCER","code":"US-NY-SPC"},
          {"name":"SPENCERPORT","code":"US-NY-SP6"},
          {"name":"SPRING VALLEY, ROCKLAND","code":"US-NY-SPV"},
          {"name":"SPRINGFIELD GARDENS","code":"US-NY-SFG"},
          {"name":"SPRINGVILLE","code":"US-NY-IGL"},
          {"name":"SPRINGVILLE, SUFFOLK","code":"US-NY-XPG"},
          {"name":"STAATSBURG","code":"US-NY-GB8"},
          {"name":"STAFFORD","code":"US-NY-XTF"},
          {"name":"STAMFORD","code":"US-NY-SDJ"},
          {"name":"STANFORDVILLE","code":"US-NY-JDV"},
          {"name":"STANLEY","code":"US-NY-EYZ"},
          {"name":"STATEN ISLAND","code":"US-NY-UYU"},
          {"name":"STEPHENTOWN","code":"US-NY-TEP"},
          {"name":"STILLWATER","code":"US-NY-IWZ"},
          {"name":"STITTVILLE","code":"US-NY-S4T"},
          {"name":"STONE RIDGE, ULSTER","code":"US-NY-YYR"},
          {"name":"STONY BROOK","code":"US-NY-ZTY"},
          {"name":"STONY POINT","code":"US-NY-S8T"},
          {"name":"STONY POINT, ROCKLAND","code":"US-NY-NYI"},
          {"name":"STORMVILLE","code":"US-NY-ZST"},
          {"name":"STOW","code":"US-NY-VOW"},
          {"name":"STRATFORD","code":"US-NY-AHG"},
          {"name":"STRYKERSVILLE","code":"US-NY-YKR"},
          {"name":"SUFFERN","code":"US-NY-SUJ"},
          {"name":"SUGAR LOAF","code":"US-NY-UGL"},
          {"name":"SUNNYSIDE, QUEENS","code":"US-NY-SS6"},
          {"name":"SWAN LAKE","code":"US-NY-6SL"},
          {"name":"SYOSSET","code":"US-NY-SYQ"},
          {"name":"SYRACUSE","code":"US-NY-SYR"},
          {"name":"TALLMAN","code":"US-NY-TAK"},
          {"name":"TANNERSVILLE","code":"US-NY-T7V"},
          {"name":"TAPPAN","code":"US-NY-TAP"},
          {"name":"TARRYTOWN","code":"US-NY-TWZ"},
          {"name":"THERESA","code":"US-NY-TQE"},
          {"name":"THOMPSONVILLE","code":"US-NY-OVJ"},
          {"name":"THORNWOOD","code":"US-NY-NYT"},
          {"name":"TICONDEROGA","code":"US-NY-TIC"},
          {"name":"TILLSON","code":"US-NY-TL8"},
          {"name":"TIVOLI","code":"US-NY-T23"},
          {"name":"TOMKINS COVE","code":"US-NY-TKN"},
          {"name":"TONAWANDA","code":"US-NY-UZF"},
          {"name":"TOTTENVILLE, STATEN ISLAND","code":"US-NY-TTV"},
          {"name":"TROUT CREEK","code":"US-NY-TCR"},
          {"name":"TROUT RIVER","code":"US-NY-TRR"},
          {"name":"TROY","code":"US-NY-TRY"},
          {"name":"TRUMANSBURG","code":"US-NY-T9R"},
          {"name":"TUCKAHOE","code":"US-NY-XTY"},
          {"name":"TULLY","code":"US-NY-TLY"},
          {"name":"TUPPER LAKE","code":"US-NY-TP2"},
          {"name":"TUSCAN","code":"US-NY-TT9"},
          {"name":"TUXEDO PARK","code":"US-NY-XND"},
          {"name":"ULSTER PARK","code":"US-NY-UL5"},
          {"name":"UNADILLA","code":"US-NY-UNW"},
          {"name":"UNION SPRINGS","code":"US-NY-USN"},
          {"name":"UNIONDALE","code":"US-NY-UOD"},
          {"name":"UPPER BROOKVILLE","code":"US-NY-YUP"},
          {"name":"UPPER JAY","code":"US-NY-UPJ"},
          {"name":"UPPER SAINT REGIS","code":"US-NY-QUS"},
          {"name":"UPTON","code":"US-NY-XUP"},
          {"name":"UTICA","code":"US-NY-UCA"},
          {"name":"VAILSGATE","code":"US-NY-VAI"},
          {"name":"VALATIE","code":"US-NY-VAT"},
          {"name":"VALHALLA","code":"US-NY-VHH"},
          {"name":"VALLEY COTTAGE","code":"US-NY-VCJ"},
          {"name":"VALLEY FALLS","code":"US-NY-V6F"},
          {"name":"VALLEY STREAM","code":"US-NY-VSR"},
          {"name":"VAN BUREN","code":"US-NY-VB9"},
          {"name":"VAN ETTEN","code":"US-NY-VTZ"},
          {"name":"VANDALIA","code":"US-NY-VDD"},
          {"name":"VERNON","code":"US-NY-VNN"},
          {"name":"VERNON CENTER","code":"US-NY-VRJ"},
          {"name":"VESTAL","code":"US-NY-VJZ"},
          {"name":"VICTOR","code":"US-NY-VIC"},
          {"name":"VOORHEESVILLE","code":"US-NY-VOO"},
          {"name":"WADDINGTON","code":"US-NY-WAD"},
          {"name":"WADING RIVER","code":"US-NY-WR4"},
          {"name":"WAINSCOTT","code":"US-NY-WIY"},
          {"name":"WALDEN","code":"US-NY-WDA"},
          {"name":"WALES","code":"US-NY-W2A"},
          {"name":"WALES CENTER","code":"US-NY-2WC"},
          {"name":"WALLKILL","code":"US-NY-WAK"},
          {"name":"WALTON","code":"US-NY-JWO"},
          {"name":"WAMPSVILLE","code":"US-NY-WP3"},
          {"name":"WANTAGH","code":"US-NY-TWH"},
          {"name":"WAPPINGERS FALLS","code":"US-NY-WFS"},
          {"name":"WARNERS","code":"US-NY-WRS"},
          {"name":"WARREN","code":"US-NY-Y4Y"},
          {"name":"WARRENSBURG","code":"US-NY-WR8"},
          {"name":"WARSAW","code":"US-NY-ARW"},
          {"name":"WARWICK","code":"US-NY-WRK"},
          {"name":"WASHINGTONVILLE","code":"US-NY-WSL"},
          {"name":"WASSAIC","code":"US-NY-WA5"},
          {"name":"WATER MILL","code":"US-NY-AEM"},
          {"name":"WATERFORD","code":"US-NY-UZK"},
          {"name":"WATERLOO","code":"US-NY-WOO"},
          {"name":"WATERPORT","code":"US-NY-WP6"},
          {"name":"WATERTOWN","code":"US-NY-ART"},
          {"name":"WATERVILLE","code":"US-NY-W23"},
          {"name":"WATERVLIET","code":"US-NY-XOE"},
          {"name":"WATKINS GLEN","code":"US-NY-WKG"},
          {"name":"WAVERLY","code":"US-NY-WFY"},
          {"name":"WAYLAND","code":"US-NY-WXQ"},
          {"name":"WAYNE","code":"US-NY-YNE"},
          {"name":"WEBSTER","code":"US-NY-UZJ"},
          {"name":"WEEDSPORT","code":"US-NY-EES"},
          {"name":"WELLSVILLE","code":"US-NY-ELZ"},
          {"name":"WEST BABYLON","code":"US-NY-EBB"},
          {"name":"WEST CHAZY","code":"US-NY-EZF"},
          {"name":"WEST COXSACKIE","code":"US-NY-WCX"},
          {"name":"WEST HARRISON","code":"US-NY-EHO"},
          {"name":"WEST HAVERSTRAW","code":"US-NY-S8X"},
          {"name":"WEST HEMPSTEAD","code":"US-NY-WH4"},
          {"name":"WEST HENRIETTA","code":"US-NY-WEE"},
          {"name":"WEST HURLEY","code":"US-NY-ZHY"},
          {"name":"WEST ISLIP","code":"US-NY-W4S"},
          {"name":"WEST NYACK","code":"US-NY-UZL"},
          {"name":"WEST POINT","code":"US-NY-WOT"},
          {"name":"WEST SAYVILLE","code":"US-NY-YLY"},
          {"name":"WEST SENECA","code":"US-NY-WSA"},
          {"name":"WEST WINFIELD","code":"US-NY-TDZ"},
          {"name":"WESTBURY","code":"US-NY-WBY"},
          {"name":"WESTCHESTER COUNTY APT","code":"US-NY-HPN"},
          {"name":"WESTERLO","code":"US-NY-WE2"},
          {"name":"WESTERNVILLE","code":"US-NY-WN8"},
          {"name":"WESTFIELD","code":"US-NY-WXY"},
          {"name":"WESTHAMPTON","code":"US-NY-FOK"},
          {"name":"WESTHAMPTON BEACH","code":"US-NY-UWB"},
          {"name":"WESTMORELAND","code":"US-NY-TRQ"},
          {"name":"WESTON MILLS","code":"US-NY-WNM"},
          {"name":"WESTTOWN","code":"US-NY-IOT"},
          {"name":"WHEATFIELD","code":"US-NY-W3F"},
          {"name":"WHITE LAKE","code":"US-NY-KKW"},
          {"name":"WHITE PLAINS","code":"US-NY-WTS"},
          {"name":"WHITEHALL","code":"US-NY-WHN"},
          {"name":"WHITESBORO","code":"US-NY-WH2"},
          {"name":"WHITESTONE/QUEENS/NEW YORK","code":"US-NY-WHO"},
          {"name":"WILLIAMSBURG/BROOKLYN/NEW YORK","code":"US-NY-WGY"},
          {"name":"WILLIAMSON","code":"US-NY-WIS"},
          {"name":"WILLIAMSTOWN","code":"US-NY-ZJM"},
          {"name":"WILLIAMSVILLE","code":"US-NY-IIA"},
          {"name":"WILLIANSTOWN","code":"US-NY-XZW"},
          {"name":"WILLISTON PARK","code":"US-NY-W4L"},
          {"name":"WILLSBORO","code":"US-NY-WSI"},
          {"name":"WILMINGTON","code":"US-NY-IZG"},
          {"name":"WILSON","code":"US-NY-8LS"},
          {"name":"WILTON","code":"US-NY-WIM"},
          {"name":"WINDSOR","code":"US-NY-XNS"},
          {"name":"WINGDALE","code":"US-NY-IGP"},
          {"name":"WOLCOTT","code":"US-NY-OOT"},
          {"name":"WOODBOURNE","code":"US-NY-WB6"},
          {"name":"WOODBURY","code":"US-NY-8TT"},
          {"name":"WOODBURY, NASSAU","code":"US-NY-WDU"},
          {"name":"WOODGATE","code":"US-NY-WO7"},
          {"name":"WOODHAVEN","code":"US-NY-VHW"},
          {"name":"WOODHULL","code":"US-NY-ODU"},
          {"name":"WOODMERE","code":"US-NY-8XC"},
          {"name":"WOODRIDGE","code":"US-NY-YWG"},
          {"name":"WOODS MILL","code":"US-NY-XNY"},
          {"name":"WOODSIDE/QUEENS/NEW YORK","code":"US-NY-WOS"},
          {"name":"WOODSTOCK","code":"US-NY-WO3"},
          {"name":"WORCESTER","code":"US-NY-WOC"},
          {"name":"WURTSBORO","code":"US-NY-TWO"},
          {"name":"WYANDANCH","code":"US-NY-XWY"},
          {"name":"WYNANTSKILL","code":"US-NY-YN4"},
          {"name":"WYOMING","code":"US-NY-YOM"},
          {"name":"YAPHANK","code":"US-NY-YNK"},
          {"name":"YONKERS","code":"US-NY-YON"},
          {"name":"YORK","code":"US-NY-YK8"},
          {"name":"YORKTOWN HEIGHTS","code":"US-NY-YKH"},
          {"name":"YORKVILLE","code":"US-NY-ZJU"},
          {"name":"YOUNGSTOWN","code":"US-NY-YTW"},
        ]
      },
      {
        name: 'Carolina del Norte',
        code: 'US-NC',
        cities: [
          {"name":"ABERDEEN","code":"US-NC-UAE"},
          {"name":"ABERDIN","code":"US-NC-AN7"},
          {"name":"ADVANCE","code":"US-NC-UAR"},
          {"name":"AHOSKIE","code":"US-NC-AHE"},
          {"name":"ALAMANCE","code":"US-NC-AMC"},
          {"name":"ALBEMARLE","code":"US-NC-UAD"},
          {"name":"ALEXIS","code":"US-NC-5G3"},
          {"name":"ALLENTON","code":"US-NC-QWU"},
          {"name":"ALTAMAHAW","code":"US-NC-UBD"},
          {"name":"ALTAPASS","code":"US-NC-PSQ"},
          {"name":"ANDREWS","code":"US-NC-AGM"},
          {"name":"ANGIER","code":"US-NC-ANG"},
          {"name":"ANSONVILLE","code":"US-NC-VFR"},
          {"name":"APEX","code":"US-NC-APX"},
          {"name":"ARCHDALE","code":"US-NC-XAZ"},
          {"name":"ARDEN","code":"US-NC-AYY"},
          {"name":"ASH","code":"US-NC-AS8"},
          {"name":"ASHEBORO","code":"US-NC-ASR"},
          {"name":"ASHEVILLE","code":"US-NC-AEV"},
          {"name":"ATKINSON","code":"US-NC-ZAS"},
          {"name":"ATLANTIC","code":"US-NC-AT5"},
          {"name":"ATLANTIC BEACH","code":"US-NC-TBH"},
          {"name":"AULANDER","code":"US-NC-AUY"},
          {"name":"AURORA","code":"US-NC-AAN"},
          {"name":"AVON","code":"US-NC-ZVO"},
          {"name":"AYDEN","code":"US-NC-YEN"},
          {"name":"BADIN","code":"US-NC-BAN"},
          {"name":"BAHAMA","code":"US-NC-B7M"},
          {"name":"BAILEY","code":"US-NC-BA4"},
          {"name":"BAKERSVILLE","code":"US-NC-BE9"},
          {"name":"BALFOUR","code":"US-NC-BAW"},
          {"name":"BANNER ELK","code":"US-NC-BE2"},
          {"name":"BARNARDSVILLE","code":"US-NC-VL3"},
          {"name":"BATH","code":"US-NC-AFH"},
          {"name":"BATTLEBORO","code":"US-NC-BBO"},
          {"name":"BAYSHORE","code":"US-NC-8BH"},
          {"name":"BEAUFORT","code":"US-NC-BFO"},
          {"name":"BELEWS CREEK","code":"US-NC-ESK"},
          {"name":"BELHAVEN","code":"US-NC-BPV"},
          {"name":"BELMONT","code":"US-NC-BMQ"},
          {"name":"BENSON","code":"US-NC-BNZ"},
          {"name":"BESSEMER CITY","code":"US-NC-AAD"},
          {"name":"BETHEL, PITT","code":"US-NC-EEB"},
          {"name":"BETHLEHEM","code":"US-NC-JHL"},
          {"name":"BEULAVILLE","code":"US-NC-BCL"},
          {"name":"BISCOE","code":"US-NC-BDS"},
          {"name":"BLACK CREEK","code":"US-NC-B4C"},
          {"name":"BLACK MOUNTAIN","code":"US-NC-BQM"},
          {"name":"BLADENBORO","code":"US-NC-BXO"},
          {"name":"BLOUNTS CREEK","code":"US-NC-B7T"},
          {"name":"BLOWING ROCK","code":"US-NC-BWK"},
          {"name":"BOILING SPRINGS","code":"US-NC-IBW"},
          {"name":"BOLIVIA","code":"US-NC-B7V"},
          {"name":"BOLTON","code":"US-NC-UOT"},
          {"name":"BOOMER","code":"US-NC-B88"},
          {"name":"BOONE","code":"US-NC-XCP"},
          {"name":"BOONVILLE","code":"US-NC-BVL"},
          {"name":"BOSTIC","code":"US-NC-BX4"},
          {"name":"BRASSTOWN","code":"US-NC-BA6"},
          {"name":"BREVARD","code":"US-NC-BCV"},
          {"name":"BRIDGETON","code":"US-NC-IDQ"},
          {"name":"BROADWAY","code":"US-NC-BW7"},
          {"name":"BROWNS SUMMIT","code":"US-NC-XCL"},
          {"name":"BRYSON CITY","code":"US-NC-BYF"},
          {"name":"BUNN","code":"US-NC-BNY"},
          {"name":"BURGAW","code":"US-NC-BAU"},
          {"name":"BURLINGTON","code":"US-NC-XDD"},
          {"name":"BURNSVILLE","code":"US-NC-BNI"},
          {"name":"BUTNER","code":"US-NC-ZBT"},
          {"name":"CALABASH","code":"US-NC-LBQ"},
          {"name":"CALYPSO","code":"US-NC-YDS"},
          {"name":"CAMDEN","code":"US-NC-VTM"},
          {"name":"CAMERON","code":"US-NC-CMU"},
          {"name":"CAMP LEJEUNE","code":"US-NC-CLJ"},
          {"name":"CAMPBELL","code":"US-NC-C7B"},
          {"name":"CANDLER","code":"US-NC-CDF"},
          {"name":"CANDOR","code":"US-NC-CNR"},
          {"name":"CANTON","code":"US-NC-CNQ"},
          {"name":"CAPE CARTERET","code":"US-NC-TPC"},
          {"name":"CAPE FEAR","code":"US-NC-CFN"},
          {"name":"CAPE HATTERAS","code":"US-NC-CPH"},
          {"name":"CAROLINA BEACH","code":"US-NC-KIQ"},
          {"name":"CARRBORO","code":"US-NC-ZRR"},
          {"name":"CARTHAGE","code":"US-NC-TAG"},
          {"name":"CARY","code":"US-NC-CFC"},
          {"name":"CASAR","code":"US-NC-FCS"},
          {"name":"CASHIERS","code":"US-NC-CJS"},
          {"name":"CASTALIA","code":"US-NC-C7S"},
          {"name":"CASTLE HAYNE","code":"US-NC-CHY"},
          {"name":"CATAWBA","code":"US-NC-CTQ"},
          {"name":"CEDAR FALLS","code":"US-NC-XDP"},
          {"name":"CEDAR GROVE","code":"US-NC-YCG"},
          {"name":"CEDAR HILL","code":"US-NC-C88"},
          {"name":"CEDAR POINT","code":"US-NC-CD9"},
          {"name":"CHADBOURN","code":"US-NC-CHB"},
          {"name":"CHAPEL HILL","code":"US-NC-CHE"},
          {"name":"CHARLESTON","code":"US-NC-NCS"},
          {"name":"CHARLOTTE","code":"US-NC-CLT"},
          {"name":"CHERRY POINT","code":"US-NC-CPO"},
          {"name":"CHERRYVILLE","code":"US-NC-CHJ"},
          {"name":"CHINA GROVE","code":"US-NC-HNO"},
          {"name":"CHOCOWINITY","code":"US-NC-CQW"},
          {"name":"CLAREMONT","code":"US-NC-CEO"},
          {"name":"CLARKTON","code":"US-NC-CJK"},
          {"name":"CLAYTON","code":"US-NC-CME"},
          {"name":"CLEMMONS","code":"US-NC-CMO"},
          {"name":"CLEVELAND","code":"US-NC-CVT"},
          {"name":"CLIFFSIDE","code":"US-NC-ACN"},
          {"name":"CLIMAX","code":"US-NC-XRR"},
          {"name":"CLINTON","code":"US-NC-CTZ"},
          {"name":"CLYDE","code":"US-NC-5CY"},
          {"name":"COATS","code":"US-NC-VOS"},
          {"name":"COFIELD","code":"US-NC-5CF"},
          {"name":"COLERAIN","code":"US-NC-IXC"},
          {"name":"COLFAX","code":"US-NC-OFX"},
          {"name":"COLUMBIA","code":"US-NC-CUB"},
          {"name":"COLUMBUS","code":"US-NC-CZX"},
          {"name":"CONCORD","code":"US-NC-XEA"},
          {"name":"CONNELLY SPRINGS","code":"US-NC-C25"},
          {"name":"CONNELLYS SPRINGS","code":"US-NC-CXU"},
          {"name":"CONOVER","code":"US-NC-CUN"},
          {"name":"CONWAY","code":"US-NC-C2Y"},
          {"name":"COOLEEMEE","code":"US-NC-CCZ"},
          {"name":"CORDOVA","code":"US-NC-OYV"},
          {"name":"CORNELIUS","code":"US-NC-CN7"},
          {"name":"COROLLA","code":"US-NC-ZZC"},
          {"name":"COVE CITY","code":"US-NC-C28"},
          {"name":"CRABTREE","code":"US-NC-C3T"},
          {"name":"CRAMERTON","code":"US-NC-ZRC"},
          {"name":"CREEDMOOR","code":"US-NC-ZCE"},
          {"name":"CROSSNORE","code":"US-NC-9CR"},
          {"name":"CULLOWHEE","code":"US-NC-UOH"},
          {"name":"CURRIE","code":"US-NC-KUR"},
          {"name":"DALLAS","code":"US-NC-DAC"},
          {"name":"DAVIDSON","code":"US-NC-DAO"},
          {"name":"DAVIE","code":"US-NC-DVE"},
          {"name":"DEEP GAP","code":"US-NC-E5P"},
          {"name":"DENTON","code":"US-NC-ENT"},
          {"name":"DENVER","code":"US-NC-DID"},
          {"name":"DOBSON","code":"US-NC-DOB"},
          {"name":"DREXEL","code":"US-NC-DRX"},
          {"name":"DUART","code":"US-NC-URT"},
          {"name":"DUBLIN","code":"US-NC-7DB"},
          {"name":"DUCK","code":"US-NC-DUF"},
          {"name":"DUDLEY","code":"US-NC-DHU"},
          {"name":"DUNN","code":"US-NC-DUZ"},
          {"name":"DURHAM","code":"US-NC-DUR"},
          {"name":"DURHAM-RALEIGH APT","code":"US-NC-RDU"},
          {"name":"EAGLE SPRINGS","code":"US-NC-E8S"},
          {"name":"EARL","code":"US-NC-ERE"},
          {"name":"EAST BEND","code":"US-NC-EBD"},
          {"name":"EAST FAYETTEVILLE","code":"US-NC-YEF"},
          {"name":"EAST FLAT ROCK","code":"US-NC-ERK"},
          {"name":"EAST LAKE","code":"US-NC-E2A"},
          {"name":"EDEN","code":"US-NC-EDB"},
          {"name":"EDENTON","code":"US-NC-EDE"},
          {"name":"EDNEYVILLE","code":"US-NC-EVF"},
          {"name":"ELIZABETH CITY","code":"US-NC-ECG"},
          {"name":"ELIZABETHTOWN","code":"US-NC-EZW"},
          {"name":"ELK PARK","code":"US-NC-KRK"},
          {"name":"ELKIN","code":"US-NC-EIN"},
          {"name":"ELLENBORO","code":"US-NC-YEB"},
          {"name":"ELLERBE","code":"US-NC-EQ3"},
          {"name":"ELM CITY","code":"US-NC-EC3"},
          {"name":"ELON","code":"US-NC-QEN"},
          {"name":"EMERALD ISLE","code":"US-NC-ZZX"},
          {"name":"ENFIELD","code":"US-NC-EFL"},
          {"name":"ENKA","code":"US-NC-ENK"},
          {"name":"ENNICE","code":"US-NC-EN5"},
          {"name":"ERWIN","code":"US-NC-EWJ"},
          {"name":"ETHER","code":"US-NC-ETH"},
          {"name":"ETOWAH","code":"US-NC-TWP"},
          {"name":"EVERETTS","code":"US-NC-ZC8"},
          {"name":"FAIR BLUFF","code":"US-NC-F2B"},
          {"name":"FAIRFIELD","code":"US-NC-FDF"},
          {"name":"FAIRMONT","code":"US-NC-FAN"},
          {"name":"FAIRVIEW","code":"US-NC-F2W"},
          {"name":"FAISON","code":"US-NC-FZO"},
          {"name":"FAITH","code":"US-NC-NZA"},
          {"name":"FALCON","code":"US-NC-FC2"},
          {"name":"FALLSTON","code":"US-NC-FS2"},
          {"name":"FARMVILLE","code":"US-NC-FRV"},
          {"name":"FAYETTEVILLE","code":"US-NC-FAY"},
          {"name":"FLAT ROCK","code":"US-NC-LTR"},
          {"name":"FLETCHER","code":"US-NC-FHE"},
          {"name":"FOREST CITY","code":"US-NC-FCN"},
          {"name":"FORT BRAGG","code":"US-NC-FBG"},
          {"name":"FOUNTAIN","code":"US-NC-FT6"},
          {"name":"FOUR OAKS","code":"US-NC-FR6"},
          {"name":"FRANKLIN","code":"US-NC-FQL"},
          {"name":"FRANKLINTON","code":"US-NC-FTN"},
          {"name":"FRANKLINVILLE","code":"US-NC-FRA"},
          {"name":"FREMONT","code":"US-NC-FQM"},
          {"name":"FRIENDSHIP","code":"US-NC-FRF"},
          {"name":"FUQUAY-VARINA","code":"US-NC-FUV"},
          {"name":"GARLAND","code":"US-NC-GKD"},
          {"name":"GARNER","code":"US-NC-GNC"},
          {"name":"GARYSBURG","code":"US-NC-GYS"},
          {"name":"GASTON","code":"US-NC-GSN"},
          {"name":"GASTONIA","code":"US-NC-XFS"},
          {"name":"GATES","code":"US-NC-G4E"},
          {"name":"GATESVILLE","code":"US-NC-RJC"},
          {"name":"GIBSON","code":"US-NC-GB5"},
          {"name":"GIBSONVILLE","code":"US-NC-GB9"},
          {"name":"GLEN RAVEN","code":"US-NC-NRA"},
          {"name":"GLENOLA","code":"US-NC-GLB"},
          {"name":"GLENVILLE","code":"US-NC-G2V"},
          {"name":"GLENWOOD, MCDOWELL","code":"US-NC-GZW"},
          {"name":"GODWIN","code":"US-NC-GOD"},
          {"name":"GOLD HILL","code":"US-NC-GH2"},
          {"name":"GOLDSBORO","code":"US-NC-GSB"},
          {"name":"GOLDSTON","code":"US-NC-GLJ"},
          {"name":"GRAHAM","code":"US-NC-GMR"},
          {"name":"GRANDY","code":"US-NC-GHY"},
          {"name":"GRANITE FALLS","code":"US-NC-GFA"},
          {"name":"GRANITE QUARRY","code":"US-NC-GQY"},
          {"name":"GRASSY CREEK","code":"US-NC-GC4"},
          {"name":"GREEN MOUNTAIN","code":"US-NC-GMU"},
          {"name":"GREENSBORO","code":"US-NC-GBO"},
          {"name":"GREENSBORO-HIGH POINT APT","code":"US-NC-GSO"},
          {"name":"GREENVILLE","code":"US-NC-PGV"},
          {"name":"GRIFTON","code":"US-NC-G2F"},
          {"name":"GROVER","code":"US-NC-GOF"},
          {"name":"HALIFAX","code":"US-NC-IFX"},
          {"name":"HAMILTON","code":"US-NC-HM7"},
          {"name":"HAMLET","code":"US-NC-HML"},
          {"name":"HAMPSTEAD","code":"US-NC-HP8"},
          {"name":"HAMPTONVILLE","code":"US-NC-HFV"},
          {"name":"HANRAHAN","code":"US-NC-YHN"},
          {"name":"HARBINGER","code":"US-NC-DHZ"},
          {"name":"HARRELLS","code":"US-NC-H2A"},
          {"name":"HARRIS","code":"US-NC-RZS"},
          {"name":"HARRISBURG","code":"US-NC-HSR"},
          {"name":"HATTERAS","code":"US-NC-HNC"},
          {"name":"HAVELOCK","code":"US-NC-HL5"},
          {"name":"HAW RIVER","code":"US-NC-HRX"},
          {"name":"HAYESVILLE","code":"US-NC-HYV"},
          {"name":"HAZELWOOD","code":"US-NC-HZD"},
          {"name":"HENDERSON","code":"US-NC-HEN"},
          {"name":"HENDERSONVILLE","code":"US-NC-HEV"},
          {"name":"HERTFORD","code":"US-NC-HD4"},
          {"name":"HICKORY","code":"US-NC-HKY"},
          {"name":"HIDDENITE","code":"US-NC-HDD"},
          {"name":"HIGH POINT","code":"US-NC-HPZ"},
          {"name":"HIGH POINT-GREENSBORO APT","code":"US-NC-GSO"},
          {"name":"HIGHLANDS","code":"US-NC-ZHH"},
          {"name":"HILDEBRAN","code":"US-NC-HDR"},
          {"name":"HILLSBOROUGH","code":"US-NC-HSG"},
          {"name":"HOBBSVILLE","code":"US-NC-OVQ"},
          {"name":"HOBGOOD","code":"US-NC-2GD"},
          {"name":"HOFFMAN","code":"US-NC-HFF"},
          {"name":"HOLDEN BEACH","code":"US-NC-HB6"},
          {"name":"HOLLY RIDGE","code":"US-NC-OYG"},
          {"name":"HOLLY SPRINGS, CARTERET","code":"US-NC-HS5"},
          {"name":"HOPE MILLS","code":"US-NC-HXM"},
          {"name":"HORSESHOE","code":"US-NC-HOS"},
          {"name":"HOT SPRINGS","code":"US-NC-H6S"},
          {"name":"HUBERT","code":"US-NC-HFU"},
          {"name":"HUDSON","code":"US-NC-XGV"},
          {"name":"HUNTERSVILLE","code":"US-NC-XGS"},
          {"name":"ICARD","code":"US-NC-ICQ"},
          {"name":"INDIAN TRAIL","code":"US-NC-ITR"},
          {"name":"IRON STATION","code":"US-NC-IS2"},
          {"name":"IVANHOE","code":"US-NC-IV4"},
          {"name":"JACKSONVILLE","code":"US-NC-OAJ"},
          {"name":"JAMESTOWN","code":"US-NC-JAT"},
          {"name":"JAMESVILLE","code":"US-NC-4JM"},
          {"name":"JARVISBURG","code":"US-NC-JSG"},
          {"name":"JEFFERSON","code":"US-NC-JFZ"},
          {"name":"JONESVILLE","code":"US-NC-JOI"},
          {"name":"JULIAN","code":"US-NC-JUL"},
          {"name":"KANNAPOLIS","code":"US-NC-KNP"},
          {"name":"KELFORD","code":"US-NC-KOL"},
          {"name":"KELLY","code":"US-NC-KY5"},
          {"name":"KENANSVILLE","code":"US-NC-KNV"},
          {"name":"KENLY","code":"US-NC-KN3"},
          {"name":"KERNERSVILLE","code":"US-NC-KRN"},
          {"name":"KILL DEVIL HILLS","code":"US-NC-FFA"},
          {"name":"KING","code":"US-NC-KIG"},
          {"name":"KINGS MOUNTAIN","code":"US-NC-KIN"},
          {"name":"KINGSMOUNTAIN","code":"US-NC-KMT"},
          {"name":"KINGSTON","code":"US-NC-K5T"},
          {"name":"KINGSTOWN","code":"US-NC-KGO"},
          {"name":"KINSTON","code":"US-NC-ISO"},
          {"name":"KITTRELL","code":"US-NC-JTL"},
          {"name":"KITTY HAWK","code":"US-NC-KTT"},
          {"name":"KNIGHTDALE","code":"US-NC-KNI"},
          {"name":"LA GRANGE","code":"US-NC-GGL"},
          {"name":"LAKE TOXAWAY","code":"US-NC-KXY"},
          {"name":"LAKE WACCAMAW","code":"US-NC-WCW"},
          {"name":"LANDIS","code":"US-NC-LFD"},
          {"name":"LANSING","code":"US-NC-LAG"},
          {"name":"LATTIMORE","code":"US-NC-5NC"},
          {"name":"LAUREL HILL","code":"US-NC-LH9"},
          {"name":"LAUREL HILL, LINCOLN","code":"US-NC-LHJ"},
          {"name":"LAUREL SPRINGS","code":"US-NC-IUP"},
          {"name":"LAURINBURG","code":"US-NC-LRB"},
          {"name":"LAWNDALE","code":"US-NC-AWA"},
          {"name":"LAWSONVILLE","code":"US-NC-AWZ"},
          {"name":"LEICESTER","code":"US-NC-LC6"},
          {"name":"LELAND","code":"US-NC-LLC"},
          {"name":"LENOIR","code":"US-NC-LER"},
          {"name":"LEWISTON","code":"US-NC-LVZ"},
          {"name":"LEWISVILLE","code":"US-NC-LWZ"},
          {"name":"LEXINGTON","code":"US-NC-LXG"},
          {"name":"LIBERTY","code":"US-NC-LBI"},
          {"name":"LILESVILLE","code":"US-NC-LV4"},
          {"name":"LILLINGTON","code":"US-NC-LLL"},
          {"name":"LINCOLNTON","code":"US-NC-LCT"},
          {"name":"LINWOOD","code":"US-NC-LNZ"},
          {"name":"LITTLETON","code":"US-NC-LT9"},
          {"name":"LONGWOOD","code":"US-NC-L44"},
          {"name":"LOUISBURG","code":"US-NC-LFN"},
          {"name":"LOVE VALLEY","code":"US-NC-L9Y"},
          {"name":"LOWELL","code":"US-NC-OWE"},
          {"name":"LOWGAP","code":"US-NC-LXP"},
          {"name":"LUCAMA","code":"US-NC-UCM"},
          {"name":"LUMBER BRIDGE","code":"US-NC-LUB"},
          {"name":"LUMBERTON","code":"US-NC-LBT"},
          {"name":"LUPTON","code":"US-NC-LP3"},
          {"name":"LYNN","code":"US-NC-ZNN"},
          {"name":"MADISON","code":"US-NC-MDI"},
          {"name":"MAGGIE VALLEY","code":"US-NC-M7V"},
          {"name":"MAGNOLIA","code":"US-NC-M6L"},
          {"name":"MAIDEN","code":"US-NC-UQO"},
          {"name":"MANTEO","code":"US-NC-MEO"},
          {"name":"MARBLE","code":"US-NC-ARQ"},
          {"name":"MARION","code":"US-NC-MIN"},
          {"name":"MARS HILL","code":"US-NC-YMH"},
          {"name":"MARSHALL","code":"US-NC-NMA"},
          {"name":"MARSHVILLE","code":"US-NC-MHD"},
          {"name":"MARSTON","code":"US-NC-M4S"},
          {"name":"MATTHEWS","code":"US-NC-MUA"},
          {"name":"MAXTON","code":"US-NC-MXE"},
          {"name":"MAYODAN","code":"US-NC-MYO"},
          {"name":"MCADENVILLE","code":"US-NC-MCV"},
          {"name":"MCLEANSVILLE","code":"US-NC-QMC"},
          {"name":"MEBANE","code":"US-NC-MBN"},
          {"name":"MERRY HILL","code":"US-NC-MH7"},
          {"name":"MICAVILLE","code":"US-NC-M3V"},
          {"name":"MIDDLEBURG","code":"US-NC-HQ4"},
          {"name":"MIDDLESEX","code":"US-NC-IDD"},
          {"name":"MIDLAND","code":"US-NC-IAL"},
          {"name":"MIDWAY PARK","code":"US-NC-M2P"},
          {"name":"MILL SPRING","code":"US-NC-TMJ"},
          {"name":"MILLS RIVER","code":"US-NC-MS9"},
          {"name":"MINERAL SPRINGS","code":"US-NC-IPU"},
          {"name":"MINNEAPOLIS","code":"US-NC-M5N"},
          {"name":"MINT HILL","code":"US-NC-TLI"},
          {"name":"MISENHEIMER","code":"US-NC-NCE"},
          {"name":"MOCKSVILLE","code":"US-NC-MKV"},
          {"name":"MONCURE","code":"US-NC-MCP"},
          {"name":"MONROE","code":"US-NC-MNR"},
          {"name":"MONROE PARK","code":"US-NC-MPQ"},
          {"name":"MOORESBORO","code":"US-NC-OBO"},
          {"name":"MOORESVILLE","code":"US-NC-XJK"},
          {"name":"MORAVIAN FALLS","code":"US-NC-MF4"},
          {"name":"MOREHEAD CITY","code":"US-NC-MRH"},
          {"name":"MORGANTON","code":"US-NC-MRN"},
          {"name":"MORGANTOWN","code":"US-NC-MJZ"},
          {"name":"MORRISVILLE","code":"US-NC-XZM"},
          {"name":"MORVEN","code":"US-NC-OQV"},
          {"name":"MOUNT AIRY","code":"US-NC-MKY"},
          {"name":"MOUNT GILEAD","code":"US-NC-MGQ"},
          {"name":"MOUNT HOLLY","code":"US-NC-MHX"},
          {"name":"MOUNT OLIVE","code":"US-NC-MNO"},
          {"name":"MOUNT PLEASANT, CABARRUS","code":"US-NC-UPA"},
          {"name":"MOUNT ULLA","code":"US-NC-OUU"},
          {"name":"MOUNTAIN HOME","code":"US-NC-MHU"},
          {"name":"MOYOCK","code":"US-NC-M4Y"},
          {"name":"MURFREESBORO","code":"US-NC-MUC"},
          {"name":"MURPHY","code":"US-NC-ZMU"},
          {"name":"NAGS HEAD","code":"US-NC-KQV"},
          {"name":"NAPLES","code":"US-NC-NPL"},
          {"name":"NASHVILLE","code":"US-NC-NAS"},
          {"name":"NEBO, MCDOWELL","code":"US-NC-EBO"},
          {"name":"NEW BERN","code":"US-NC-EWN"},
          {"name":"NEW HAVEN","code":"US-NC-NH3"},
          {"name":"NEW HILL","code":"US-NC-NHJ"},
          {"name":"NEW LONDON","code":"US-NC-NXA"},
          {"name":"NEWPORT","code":"US-NC-NP4"},
          {"name":"NEWTON","code":"US-NC-OXP"},
          {"name":"NEWTON GROVE","code":"US-NC-NGV"},
          {"name":"NORLINA","code":"US-NC-NRL"},
          {"name":"NORTH CHARLOTTE","code":"US-NC-NCH"},
          {"name":"NORTH COVE","code":"US-NC-NCV"},
          {"name":"NORTH WILKESBORO","code":"US-NC-NWB"},
          {"name":"NORWOOD","code":"US-NC-NXO"},
          {"name":"OAK ISLAND","code":"US-NC-OAI"},
          {"name":"OAK RIDGE","code":"US-NC-OR4"},
          {"name":"OAKBORO","code":"US-NC-YOB"},
          {"name":"OCEAN ISLE BEACH","code":"US-NC-OBH"},
          {"name":"OGDEN","code":"US-NC-A2X"},
          {"name":"OLD FORT","code":"US-NC-OFT"},
          {"name":"OLIN","code":"US-NC-OXL"},
          {"name":"OTTO","code":"US-NC-I8C"},
          {"name":"OXFORD","code":"US-NC-OXF"},
          {"name":"PANTEGO","code":"US-NC-TEG"},
          {"name":"PARKTON","code":"US-NC-PKT"},
          {"name":"PATTERSON","code":"US-NC-PNQ"},
          {"name":"PAW CREEK","code":"US-NC-PW3"},
          {"name":"PEACHLAND","code":"US-NC-PD7"},
          {"name":"PELHAM","code":"US-NC-EHF"},
          {"name":"PEMBROKE","code":"US-NC-PWK"},
          {"name":"PENDLETON","code":"US-NC-PJE"},
          {"name":"PENROSE","code":"US-NC-PN5"},
          {"name":"PFAFFTOWN","code":"US-NC-PFT"},
          {"name":"PIKEVILLE","code":"US-NC-PK6"},
          {"name":"PILOT MOUNTAIN","code":"US-NC-PM8"},
          {"name":"PINE HALL","code":"US-NC-PNH"},
          {"name":"PINE LEVEL, JOHNSTON","code":"US-NC-NLV"},
          {"name":"PINEBLUFF","code":"US-NC-PBL"},
          {"name":"PINEHURST","code":"US-NC-PHZ"},
          {"name":"PINETOPS","code":"US-NC-PTP"},
          {"name":"PINEVILLE","code":"US-NC-PNV"},
          {"name":"PINK HILL","code":"US-NC-PQ9"},
          {"name":"PINNACLE","code":"US-NC-ZHA"},
          {"name":"PISGAH FOREST","code":"US-NC-PGF"},
          {"name":"PITTSBORO","code":"US-NC-QPO"},
          {"name":"PLEASANT GARDEN","code":"US-NC-XTG"},
          {"name":"PLEASANT HILL","code":"US-NC-P9T"},
          {"name":"PLYMOUTH","code":"US-NC-XKN"},
          {"name":"POLKTON","code":"US-NC-NKK"},
          {"name":"POLLOCKSVILLE","code":"US-NC-ZKV"},
          {"name":"POPLAR BRANCH","code":"US-NC-OBZ"},
          {"name":"POWELLS POINT","code":"US-NC-PP4"},
          {"name":"PRINCETON","code":"US-NC-P4N"},
          {"name":"PRINCEVILLE","code":"US-NC-PZI"},
          {"name":"PROVIDENCE","code":"US-NC-PV5"},
          {"name":"RAEFORD","code":"US-NC-RFN"},
          {"name":"RALEIGH","code":"US-NC-RAG"},
          {"name":"RALEIGH-DURHAM APT","code":"US-NC-RDU"},
          {"name":"RAMSEUR","code":"US-NC-RSR"},
          {"name":"RANDLEMAN","code":"US-NC-RNJ"},
          {"name":"RANLO","code":"US-NC-RA7"},
          {"name":"RED SPRINGS","code":"US-NC-RPG"},
          {"name":"REGAL","code":"US-NC-QRE"},
          {"name":"REIDSVILLE","code":"US-NC-RIV"},
          {"name":"RESEARCH TRIANGLE PARK","code":"US-NC-YTP"},
          {"name":"RHODHISS","code":"US-NC-ODP"},
          {"name":"RICH SQUARE","code":"US-NC-ZE9"},
          {"name":"RICHFIELD","code":"US-NC-YYU"},
          {"name":"RICHLANDS","code":"US-NC-RCI"},
          {"name":"RIDGECREST","code":"US-NC-RT9"},
          {"name":"RIDGEWAY","code":"US-NC-XVX"},
          {"name":"RIEGELWOOD","code":"US-NC-RGL"},
          {"name":"ROANOKE RAPIDS","code":"US-NC-RZZ"},
          {"name":"ROARING RIVER","code":"US-NC-IIR"},
          {"name":"ROBBINS","code":"US-NC-RBS"},
          {"name":"ROBBINSVILLE","code":"US-NC-RBB"},
          {"name":"ROBERSONVILLE","code":"US-NC-RRO"},
          {"name":"ROCK HILL","code":"US-NC-RHC"},
          {"name":"ROCKINGHAM","code":"US-NC-RGM"},
          {"name":"ROCKWELL","code":"US-NC-KWE"},
          {"name":"ROCKY MOUNT","code":"US-NC-RWI"},
          {"name":"ROCKY POINT","code":"US-NC-RCP"},
          {"name":"RODANTHE","code":"US-NC-DTK"},
          {"name":"ROLESVILLE","code":"US-NC-RVF"},
          {"name":"RONDA","code":"US-NC-QDA"},
          {"name":"ROPER","code":"US-NC-NCW"},
          {"name":"ROSE HILL","code":"US-NC-RZH"},
          {"name":"ROSEBORO","code":"US-NC-RSB"},
          {"name":"ROSEVILLE","code":"US-NC-RV9"},
          {"name":"ROSMAN","code":"US-NC-RS7"},
          {"name":"ROXBORO","code":"US-NC-RXB"},
          {"name":"RURAL HALL","code":"US-NC-RQH"},
          {"name":"RUTHERFORD COLLEGE","code":"US-NC-RC2"},
          {"name":"RUTHERFORDTON","code":"US-NC-RUR"},
          {"name":"SAINT PAULS","code":"US-NC-SXH"},
          {"name":"SALEMBURG","code":"US-NC-S2B"},
          {"name":"SALISBURY","code":"US-NC-SRW"},
          {"name":"SALUDA","code":"US-NC-S74"},
          {"name":"SANDY RIDGE","code":"US-NC-IW9"},
          {"name":"SANFORD","code":"US-NC-SCF"},
          {"name":"SAPPHIRE","code":"US-NC-S7P"},
          {"name":"SAWMILLS","code":"US-NC-XYZ"},
          {"name":"SAXAPAHAW","code":"US-NC-S5A"},
          {"name":"SCOTLAND NECK","code":"US-NC-SCD"},
          {"name":"SEABOARD","code":"US-NC-S7B"},
          {"name":"SEAGROVE","code":"US-NC-EOV"},
          {"name":"SELMA","code":"US-NC-SQC"},
          {"name":"SEMINOLE","code":"US-NC-SXO"},
          {"name":"SEMORA","code":"US-NC-NCZ"},
          {"name":"SEVEN SPRINGS","code":"US-NC-Q6Z"},
          {"name":"SEVERN","code":"US-NC-SVN"},
          {"name":"SEVIER","code":"US-NC-SXR"},
          {"name":"SHALLOTTE","code":"US-NC-SLX"},
          {"name":"SHANNON","code":"US-NC-S2N"},
          {"name":"SHARPSBURG","code":"US-NC-RPB"},
          {"name":"SHELBY","code":"US-NC-SEY"},
          {"name":"SHERRILLS FORD","code":"US-NC-S2F"},
          {"name":"SILER CITY","code":"US-NC-SIR"},
          {"name":"SILVER CITY","code":"US-NC-SZI"},
          {"name":"SIMPSON","code":"US-NC-SI9"},
          {"name":"SIMS","code":"US-NC-IMC"},
          {"name":"SKYLAND","code":"US-NC-UYZ"},
          {"name":"SMITHFIELD","code":"US-NC-SMF"},
          {"name":"SMITHS","code":"US-NC-ZSM"},
          {"name":"SMYRNA","code":"US-NC-S7Y"},
          {"name":"SNEADS FERRY","code":"US-NC-SKD"},
          {"name":"SNOW HILL","code":"US-NC-XSW"},
          {"name":"SOPHIA","code":"US-NC-SAZ"},
          {"name":"SOUTH MILLS","code":"US-NC-AA9"},
          {"name":"SOUTHERN PINES","code":"US-NC-SOP"},
          {"name":"SOUTHPORT","code":"US-NC-XMK"},
          {"name":"SPARTA","code":"US-NC-ZSR"},
          {"name":"SPENCER","code":"US-NC-ECR"},
          {"name":"SPINDALE","code":"US-NC-SEQ"},
          {"name":"SPRING HOPE","code":"US-NC-SRO"},
          {"name":"SPRING LAKE","code":"US-NC-SL3"},
          {"name":"SPRUCE PINE","code":"US-NC-SPU"},
          {"name":"STALEY","code":"US-NC-SZY"},
          {"name":"STALLINGS","code":"US-NC-TAI"},
          {"name":"STANFIELD","code":"US-NC-SF5"},
          {"name":"STANLEY","code":"US-NC-UZA"},
          {"name":"STANTONSBURG","code":"US-NC-2SB"},
          {"name":"STAR","code":"US-NC-YRX"},
          {"name":"STATESVILLE","code":"US-NC-SVH"},
          {"name":"STEDMAN","code":"US-NC-YDM"},
          {"name":"STOKESDALE","code":"US-NC-KVE"},
          {"name":"STONEVILLE","code":"US-NC-SVX"},
          {"name":"STONY POINT","code":"US-NC-YOT"},
          {"name":"STOVALL","code":"US-NC-S25"},
          {"name":"SUMMERFIELD","code":"US-NC-UME"},
          {"name":"SUNNY POINT","code":"US-NC-SUT"},
          {"name":"SUNSET BEACH","code":"US-NC-S99"},
          {"name":"SUPPLY BRUNSWICK COUNTY","code":"US-NC-LQF"},
          {"name":"SURF CITY","code":"US-NC-UIY"},
          {"name":"SWAN QUARTER","code":"US-NC-SQ2"},
          {"name":"SWANNANOA","code":"US-NC-SNO"},
          {"name":"SWEPSONVILLE","code":"US-NC-SWZ"},
          {"name":"SYLVA","code":"US-NC-SL6"},
          {"name":"TABOR CITY","code":"US-NC-QTB"},
          {"name":"TAR HEEL","code":"US-NC-TQH"},
          {"name":"TARBORO","code":"US-NC-UZD"},
          {"name":"TAYLORSVILLE","code":"US-NC-TYN"},
          {"name":"TEACHEY","code":"US-NC-T9C"},
          {"name":"THOMASVILLE","code":"US-NC-TVE"},
          {"name":"THURMOND","code":"US-NC-TH4"},
          {"name":"TILLERY","code":"US-NC-TIQ"},
          {"name":"TIMBERLAKE","code":"US-NC-TLE"},
          {"name":"TOBACCOVILLE","code":"US-NC-TQO"},
          {"name":"TODD","code":"US-NC-7OD"},
          {"name":"TODDVILLE","code":"US-NC-TDV"},
          {"name":"TRENTON","code":"US-NC-TNZ"},
          {"name":"TRINITY","code":"US-NC-TIR"},
          {"name":"TRINITY PARK","code":"US-NC-TYK"},
          {"name":"TROUTMAN","code":"US-NC-TRZ"},
          {"name":"TROY","code":"US-NC-TYX"},
          {"name":"TRYON","code":"US-NC-ZNT"},
          {"name":"TURKEY","code":"US-NC-TUQ"},
          {"name":"TYNER","code":"US-NC-YNR"},
          {"name":"UNION GROVE","code":"US-NC-XUG"},
          {"name":"VALDESE","code":"US-NC-VAL"},
          {"name":"VALE","code":"US-NC-VNQ"},
          {"name":"VALLE CRUCIS","code":"US-NC-VDS"},
          {"name":"VANCEBORO","code":"US-NC-VNB"},
          {"name":"VASS","code":"US-NC-VA8"},
          {"name":"WACO","code":"US-NC-W3O"},
          {"name":"WADE","code":"US-NC-WE8"},
          {"name":"WADESBORO","code":"US-NC-DEB"},
          {"name":"WADEVILLE","code":"US-NC-WV2"},
          {"name":"WAGRAM","code":"US-NC-WAQ"},
          {"name":"WAKE FOREST","code":"US-NC-WKR"},
          {"name":"WALKERTOWN","code":"US-NC-AKW"},
          {"name":"WALLACE","code":"US-NC-WLE"},
          {"name":"WALLBURG","code":"US-NC-WB7"},
          {"name":"WALNUT COVE","code":"US-NC-XKS"},
          {"name":"WALSTONBURG","code":"US-NC-IC9"},
          {"name":"WANCHESE","code":"US-NC-QWA"},
          {"name":"WARNE","code":"US-NC-WAR"},
          {"name":"WARRENTON","code":"US-NC-WFT"},
          {"name":"WARSAW","code":"US-NC-WSW"},
          {"name":"WASHINGTON","code":"US-NC-OCW"},
          {"name":"WAXHAW","code":"US-NC-WXH"},
          {"name":"WAYNESVILLE","code":"US-NC-WYV"},
          {"name":"WEAVERVILLE","code":"US-NC-WVV"},
          {"name":"WEBSTER","code":"US-NC-W3T"},
          {"name":"WELCOME","code":"US-NC-WCO"},
          {"name":"WELDON","code":"US-NC-WEO"},
          {"name":"WENDELL","code":"US-NC-WDX"},
          {"name":"WEST END","code":"US-NC-WNZ"},
          {"name":"WEST JEFFERSON","code":"US-NC-XNV"},
          {"name":"WESTFIELD","code":"US-NC-WFL"},
          {"name":"WHISPERING PINES","code":"US-NC-PW7"},
          {"name":"WHITAKERS","code":"US-NC-WKT"},
          {"name":"WHITE LAKE","code":"US-NC-W3L"},
          {"name":"WHITE OAK","code":"US-NC-VDK"},
          {"name":"WHITEVILLE","code":"US-NC-WVN"},
          {"name":"WHITSETT","code":"US-NC-ITE"},
          {"name":"WHITTIER","code":"US-NC-VHT"},
          {"name":"WILKESBORO","code":"US-NC-IKB"},
          {"name":"WILLIAMSTON","code":"US-NC-WQX"},
          {"name":"WILLOW SPRING","code":"US-NC-IWS"},
          {"name":"WILMINGTON","code":"US-NC-ILM"},
          {"name":"WILSON","code":"US-NC-WSO"},
          {"name":"WINDSOR, BERTIE","code":"US-NC-INB"},
          {"name":"WINFALL","code":"US-NC-IAU"},
          {"name":"WINGATE","code":"US-NC-XWG"},
          {"name":"WINSTON SALEM","code":"US-NC-INT"},
          {"name":"WINTERVILLE","code":"US-NC-TRW"},
          {"name":"WINTON","code":"US-NC-TWQ"},
          {"name":"WOODFIN","code":"US-NC-UO4"},
          {"name":"WRIGHTSVILLE BEACH","code":"US-NC-WGC"},
          {"name":"YADKINVILLE","code":"US-NC-YAD"},
          {"name":"YANCEYVILLE","code":"US-NC-YCY"},
          {"name":"YOUNGSVILLE","code":"US-NC-YVL"},
          {"name":"ZEBULON","code":"US-NC-ZBL"},
          {"name":"ZIONVILLE","code":"US-NC-ZNL"},
          {"name":"ZIRCONIA","code":"US-NC-ZR9"},
        ]
      },
      {
        name: 'Dakota del Norte',
        code: 'US-ND',
        cities: [
          {"name":"ABERCROMBIE","code":"US-ND-AC8"},
          {"name":"ALEXANDER","code":"US-ND-AKX"},
          {"name":"AMBROSE","code":"US-ND-AMB"},
          {"name":"ANAMOOSE","code":"US-ND-AQF"},
          {"name":"ANTLER","code":"US-ND-ANT"},
          {"name":"ARNEGARD","code":"US-ND-ZRG"},
          {"name":"ASHLEY","code":"US-ND-ASY"},
          {"name":"BALDWIN","code":"US-ND-BZQ"},
          {"name":"BEACH","code":"US-ND-EAC"},
          {"name":"BELCOURT","code":"US-ND-BC7"},
          {"name":"BELFIELD","code":"US-ND-ELF"},
          {"name":"BENEDICT","code":"US-ND-NDT"},
          {"name":"BEULAH","code":"US-ND-XUL"},
          {"name":"BINFORD","code":"US-ND-IQF"},
          {"name":"BISMARCK","code":"US-ND-BIS"},
          {"name":"BONETRAILL","code":"US-ND-BT6"},
          {"name":"BOTTINEAU","code":"US-ND-B4O"},
          {"name":"BOWMAN","code":"US-ND-BWM"},
          {"name":"BUXTON","code":"US-ND-BPU"},
          {"name":"CANDO","code":"US-ND-XEC"},
          {"name":"CARBURY","code":"US-ND-CBY"},
          {"name":"CARRINGTON","code":"US-ND-IGO"},
          {"name":"CARSON","code":"US-ND-5ON"},
          {"name":"CASSELTON","code":"US-ND-RWX"},
          {"name":"CAVALIER","code":"US-ND-C4L"},
          {"name":"CENTER","code":"US-ND-ZT8"},
          {"name":"COLGATE","code":"US-ND-GTD"},
          {"name":"COLUMBUS","code":"US-ND-OD6"},
          {"name":"COOPERSTOWN","code":"US-ND-CW4"},
          {"name":"COURTENAY","code":"US-ND-C6Y"},
          {"name":"CRETE","code":"US-ND-EE5"},
          {"name":"CROSBY","code":"US-ND-CSY"},
          {"name":"DENHOFF","code":"US-ND-DHF"},
          {"name":"DEVILS LAKE","code":"US-ND-DVL"},
          {"name":"DICKINSON","code":"US-ND-DIK"},
          {"name":"DRAYTON","code":"US-ND-DY9"},
          {"name":"DUNSEITH","code":"US-ND-DUN"},
          {"name":"DURBIN","code":"US-ND-DJB"},
          {"name":"DWIGHT","code":"US-ND-DVG"},
          {"name":"EDGELEY","code":"US-ND-EYD"},
          {"name":"ELDRIDGE","code":"US-ND-EID"},
          {"name":"ELLENDALE","code":"US-ND-LZL"},
          {"name":"ENDERLIN","code":"US-ND-EEQ"},
          {"name":"ENGLEVALE","code":"US-ND-NGE"},
          {"name":"FAIRMOUNT","code":"US-ND-FMK"},
          {"name":"FARGO","code":"US-ND-FAR"},
          {"name":"FESSENDEN","code":"US-ND-FNU"},
          {"name":"FINLEY","code":"US-ND-FEY"},
          {"name":"FORDVILLE","code":"US-ND-VDF"},
          {"name":"FORT YATES","code":"US-ND-6YR"},
          {"name":"FORTUNA","code":"US-ND-FRT"},
          {"name":"FRYBURG","code":"US-ND-FY3"},
          {"name":"FULLERTON","code":"US-ND-FUR"},
          {"name":"GALESBURG","code":"US-ND-GS8"},
          {"name":"GARRISON","code":"US-ND-GJU"},
          {"name":"GASCOYNE","code":"US-ND-GY2"},
          {"name":"GEORGETOWN","code":"US-ND-GT7"},
          {"name":"GLEN ULLIN","code":"US-ND-GUI"},
          {"name":"GRAFTON","code":"US-ND-GFT"},
          {"name":"GRAND FORKS","code":"US-ND-GFK"},
          {"name":"GRANDIN","code":"US-ND-GR4"},
          {"name":"GRASSY BUTTE","code":"US-ND-JYB"},
          {"name":"GWINNER","code":"US-ND-GWI"},
          {"name":"HAMBERG","code":"US-ND-HEG"},
          {"name":"HAMILTON","code":"US-ND-C4R"},
          {"name":"HANNAH","code":"US-ND-HNA"},
          {"name":"HANSBORO","code":"US-ND-HBO"},
          {"name":"HARMON","code":"US-ND-HYQ"},
          {"name":"HARVEY","code":"US-ND-VEY"},
          {"name":"HATTON","code":"US-ND-QHA"},
          {"name":"HAZEN","code":"US-ND-HZC"},
          {"name":"HEBRON","code":"US-ND-HN3"},
          {"name":"HETTINGER","code":"US-ND-H2T"},
          {"name":"HILLSBORO","code":"US-ND-H8S"},
          {"name":"HOPE","code":"US-ND-VOP"},
          {"name":"HORACE","code":"US-ND-HJZ"},
          {"name":"JAMESTOWN","code":"US-ND-JMS"},
          {"name":"KENMARE","code":"US-ND-KZM"},
          {"name":"KILLDEER","code":"US-ND-KXD"},
          {"name":"KINDRED","code":"US-ND-KNR"},
          {"name":"KULM","code":"US-ND-KUM"},
          {"name":"LAKOTA","code":"US-ND-LA3"},
          {"name":"LAMOURE","code":"US-ND-LZM"},
          {"name":"LANGDON","code":"US-ND-LDO"},
          {"name":"LANSFORD","code":"US-ND-LF7"},
          {"name":"LARIMORE","code":"US-ND-LRR"},
          {"name":"LEEDS","code":"US-ND-LD6"},
          {"name":"LEHR","code":"US-ND-L2H"},
          {"name":"LEONARD","code":"US-ND-ENZ"},
          {"name":"LEROY","code":"US-ND-EUY"},
          {"name":"LINTON","code":"US-ND-LN2"},
          {"name":"LISBON, RANSOM","code":"US-ND-LJI"},
          {"name":"MADDOCK","code":"US-ND-DOC"},
          {"name":"MAIDA","code":"US-ND-MAI"},
          {"name":"MANDAN","code":"US-ND-NDN"},
          {"name":"MANNING","code":"US-ND-IFG"},
          {"name":"MAPLETON","code":"US-ND-NDK"},
          {"name":"MAYVILLE","code":"US-ND-MA4"},
          {"name":"MCCANNA","code":"US-ND-CA5"},
          {"name":"MCCLUSKY","code":"US-ND-C7U"},
          {"name":"MCKENZIE","code":"US-ND-KZE"},
          {"name":"MEDINA","code":"US-ND-DQM"},
          {"name":"MICHIGAN","code":"US-ND-IGX"},
          {"name":"MINOT","code":"US-ND-MOT"},
          {"name":"MINTO","code":"US-ND-QMT"},
          {"name":"MOHALL","code":"US-ND-3MO"},
          {"name":"MUNICH","code":"US-ND-M8D"},
          {"name":"NAPOLEON","code":"US-ND-NX5"},
          {"name":"NECHE","code":"US-ND-NEC"},
          {"name":"NEW SALEM","code":"US-ND-NQY"},
          {"name":"NEW TOWN","code":"US-ND-OZW"},
          {"name":"NOONAN","code":"US-ND-NOO"},
          {"name":"NORTHGATE","code":"US-ND-NGT"},
          {"name":"NORTHWOOD","code":"US-ND-WO6"},
          {"name":"OAKES","code":"US-ND-JYU"},
          {"name":"PEMBINA","code":"US-ND-PMB"},
          {"name":"PLAZA","code":"US-ND-JLZ"},
          {"name":"PORTAL","code":"US-ND-PQT"},
          {"name":"RAY","code":"US-ND-RY6"},
          {"name":"RED RIVER","code":"US-ND-RDR"},
          {"name":"REEDER","code":"US-ND-RD2"},
          {"name":"REGENT","code":"US-ND-QRH"},
          {"name":"RICHARDTON","code":"US-ND-RCJ"},
          {"name":"ROLLA","code":"US-ND-RQL"},
          {"name":"RUGBY","code":"US-ND-UGG"},
          {"name":"SAINT JOHN","code":"US-ND-SJO"},
          {"name":"SARLES","code":"US-ND-SAO"},
          {"name":"SCRANTON","code":"US-ND-DCN"},
          {"name":"SHERWOOD","code":"US-ND-SHW"},
          {"name":"SOUTH HEART","code":"US-ND-UHA"},
          {"name":"SPIRITWOOD","code":"US-ND-SZ3"},
          {"name":"STANLEY","code":"US-ND-S5Y"},
          {"name":"STEELE","code":"US-ND-NDZ"},
          {"name":"STERLING","code":"US-ND-RZF"},
          {"name":"SYDNEY","code":"US-ND-YDY"},
          {"name":"TAPPEN","code":"US-ND-TPD"},
          {"name":"THOMPSON","code":"US-ND-TPJ"},
          {"name":"TIOGA","code":"US-ND-VEX"},
          {"name":"TOLLEY","code":"US-ND-TZL"},
          {"name":"TURTLE LAKE","code":"US-ND-TL6"},
          {"name":"UNDERWOOD","code":"US-ND-UND"},
          {"name":"VALLEY CITY","code":"US-ND-VYY"},
          {"name":"VELVA","code":"US-ND-VVA"},
          {"name":"WAHPETON","code":"US-ND-WAH"},
          {"name":"WALHALLA","code":"US-ND-VLH"},
          {"name":"WARWICK","code":"US-ND-W25"},
          {"name":"WASHBURN","code":"US-ND-W74"},
          {"name":"WATFORD CITY","code":"US-ND-WF3"},
          {"name":"WEST FARGO","code":"US-ND-WFG"},
          {"name":"WESTHOPE","code":"US-ND-WHP"},
          {"name":"WILLISTON","code":"US-ND-ISN"},
          {"name":"WIMBLEDON","code":"US-ND-W9B"},
          {"name":"WISHEK","code":"US-ND-WXK"},
          {"name":"ZEELAND","code":"US-ND-ZLA"},
        ]
      },
      {
        name: 'Ohio',
        code: 'US-OH',
        cities: [
          {"name":"ABERDEEN","code":"US-OH-AHD"},
          {"name":"ADA","code":"US-OH-AJJ"},
          {"name":"ADDISON","code":"US-OH-ADX"},
          {"name":"ADDYSTON","code":"US-OH-UAS"},
          {"name":"ADRIAN","code":"US-OH-AQA"},
          {"name":"AKRON","code":"US-OH-AKH"},
          {"name":"ALBANY","code":"US-OH-UBA"},
          {"name":"ALEXANDRIA","code":"US-OH-AZ4"},
          {"name":"ALGER","code":"US-OH-AJE"},
          {"name":"ALLEDONIA","code":"US-OH-A3A"},
          {"name":"ALLIANCE","code":"US-OH-AIH"},
          {"name":"ALPHA","code":"US-OH-ALP"},
          {"name":"ALVADA","code":"US-OH-AV8"},
          {"name":"ALVORDTON","code":"US-OH-AV5"},
          {"name":"AMELIA","code":"US-OH-OHM"},
          {"name":"AMHERST","code":"US-OH-ZAH"},
          {"name":"AMLIN","code":"US-OH-AM9"},
          {"name":"ANDERSON","code":"US-OH-DQO"},
          {"name":"ANDOVER","code":"US-OH-AR3"},
          {"name":"ANNA","code":"US-OH-NNA"},
          {"name":"ANSONIA","code":"US-OH-ANS"},
          {"name":"ANTWERP","code":"US-OH-ZAQ"},
          {"name":"APPLE CREEK","code":"US-OH-APQ"},
          {"name":"ARCANUM","code":"US-OH-VUM"},
          {"name":"ARCHBOLD","code":"US-OH-ARO"},
          {"name":"ASHLAND","code":"US-OH-ASK"},
          {"name":"ASHTABULA","code":"US-OH-ASF"},
          {"name":"ASHVILLE","code":"US-OH-ASO"},
          {"name":"ATHENS","code":"US-OH-ATO"},
          {"name":"ATTICA","code":"US-OH-XTC"},
          {"name":"AUGUSTA","code":"US-OH-UDZ"},
          {"name":"AURORA","code":"US-OH-AUF"},
          {"name":"AUSTINBURG","code":"US-OH-ASG"},
          {"name":"AUSTINTOWN","code":"US-OH-AUV"},
          {"name":"AVON","code":"US-OH-AVQ"},
          {"name":"AVON LAKE","code":"US-OH-AVK"},
          {"name":"BAINBRIDGE","code":"US-OH-B8G"},
          {"name":"BAKER CITY","code":"US-OH-BK4"},
          {"name":"BAKERSVILLE","code":"US-OH-KRS"},
          {"name":"BALTIC","code":"US-OH-QBC"},
          {"name":"BALTIMORE","code":"US-OH-BZB"},
          {"name":"BARBERTON","code":"US-OH-BAT"},
          {"name":"BARLOW","code":"US-OH-BAO"},
          {"name":"BARNESVILLE","code":"US-OH-BZC"},
          {"name":"BARTON","code":"US-OH-9BT"},
          {"name":"BASCOM","code":"US-OH-BSC"},
          {"name":"BATAVIA","code":"US-OH-BUX"},
          {"name":"BAY VILLAGE","code":"US-OH-B8V"},
          {"name":"BEACH CITY","code":"US-OH-BH7"},
          {"name":"BEACHWOOD","code":"US-OH-BEQ"},
          {"name":"BEAVER","code":"US-OH-BV9"},
          {"name":"BEAVER DAM","code":"US-OH-BEV"},
          {"name":"BEAVERCREEK","code":"US-OH-VCR"},
          {"name":"BEDFORD","code":"US-OH-XCA"},
          {"name":"BEDFORD HEIGHTS","code":"US-OH-BDH"},
          {"name":"BELLAIRE","code":"US-OH-RE9"},
          {"name":"BELLBROOK","code":"US-OH-B44"},
          {"name":"BELLE CENTER","code":"US-OH-B3E"},
          {"name":"BELLEFONTAINE","code":"US-OH-BFN"},
          {"name":"BELLEVUE","code":"US-OH-XBX"},
          {"name":"BELLVILLE","code":"US-OH-BFV"},
          {"name":"BELMONT","code":"US-OH-BMT"},
          {"name":"BELOIT","code":"US-OH-AFJ"},
          {"name":"BELPRE","code":"US-OH-BEP"},
          {"name":"BEREA","code":"US-OH-EEA"},
          {"name":"BERGHOLZ","code":"US-OH-BHZ"},
          {"name":"BERLIN HEIGHTS","code":"US-OH-IIQ"},
          {"name":"BERLIN, HOLMES","code":"US-OH-ZBE"},
          {"name":"BETHEL, CLERMONT","code":"US-OH-BHU"},
          {"name":"BETTSVILLE","code":"US-OH-QBT"},
          {"name":"BEVERLY","code":"US-OH-BVR"},
          {"name":"BIDWELL","code":"US-OH-BW2"},
          {"name":"BIG PRAIRIE","code":"US-OH-BZR"},
          {"name":"BIRMINGHAM","code":"US-OH-XY2"},
          {"name":"BLACKLICK","code":"US-OH-BZZ"},
          {"name":"BLAKESLEE","code":"US-OH-FKE"},
          {"name":"BLANCHESTER","code":"US-OH-BCX"},
          {"name":"BLISSFIELD","code":"US-OH-ISF"},
          {"name":"BLOOMINGBURG","code":"US-OH-BBU"},
          {"name":"BLOOMINGDALE","code":"US-OH-OGL"},
          {"name":"BLUE ASH","code":"US-OH-XDC"},
          {"name":"BLUFFTON","code":"US-OH-BHK"},
          {"name":"BOARDMAN","code":"US-OH-BDM"},
          {"name":"BOLIVAR","code":"US-OH-VRR"},
          {"name":"BOSTON HEIGHTS","code":"US-OH-B5H"},
          {"name":"BOTKINS","code":"US-OH-BTK"},
          {"name":"BOWERSTON","code":"US-OH-BWN"},
          {"name":"BOWLING GREEN","code":"US-OH-BWO"},
          {"name":"BRADNER","code":"US-OH-BHD"},
          {"name":"BRATENAHL","code":"US-OH-B23"},
          {"name":"BRECKSVILLE","code":"US-OH-BKV"},
          {"name":"BREMEN","code":"US-OH-BEM"},
          {"name":"BREWSTER","code":"US-OH-BRR"},
          {"name":"BRIDGEPORT","code":"US-OH-B2P"},
          {"name":"BRILLIANT","code":"US-OH-TJB"},
          {"name":"BRIMFIELD","code":"US-OH-BZX"},
          {"name":"BROADVIEW HEIGHTS","code":"US-OH-DVH"},
          {"name":"BROADVIEW, SUMMIT","code":"US-OH-BV2"},
          {"name":"BROOK PARK","code":"US-OH-BPO"},
          {"name":"BROOKFIELD","code":"US-OH-BFZ"},
          {"name":"BROOKLYN","code":"US-OH-BKU"},
          {"name":"BROOKLYN HEIGHTS","code":"US-OH-BHS"},
          {"name":"BROOKVILLE","code":"US-OH-OOV"},
          {"name":"BRUNSWICK","code":"US-OH-BCK"},
          {"name":"BRYAN","code":"US-OH-BRY"},
          {"name":"BUCKEYE LAKE","code":"US-OH-BFY"},
          {"name":"BUCYRUS","code":"US-OH-BXW"},
          {"name":"BUFFALO","code":"US-OH-FYQ"},
          {"name":"BURBANK","code":"US-OH-BB7"},
          {"name":"BURTON","code":"US-OH-BUT"},
          {"name":"BYESVILLE","code":"US-OH-VBL"},
          {"name":"CADIZ","code":"US-OH-QAZ"},
          {"name":"CALDWELL","code":"US-OH-CAQ"},
          {"name":"CALEDONIA","code":"US-OH-CCD"},
          {"name":"CAMBRIDGE","code":"US-OH-CBH"},
          {"name":"CAMDEN","code":"US-OH-C2D"},
          {"name":"CAMP DENNISON","code":"US-OH-NNI"},
          {"name":"CANAL FULTON","code":"US-OH-CFI"},
          {"name":"CANAL WINCHESTER","code":"US-OH-XEQ"},
          {"name":"CANFIELD","code":"US-OH-CNF"},
          {"name":"CANTON","code":"US-OH-CCO"},
          {"name":"CARBONDALE","code":"US-OH-C9D"},
          {"name":"CARDINGTON","code":"US-OH-3OH"},
          {"name":"CAREY","code":"US-OH-CXJ"},
          {"name":"CARLISLE","code":"US-OH-CRL"},
          {"name":"CARROLL","code":"US-OH-CWU"},
          {"name":"CARROLLTON","code":"US-OH-CQR"},
          {"name":"CASSTOWN","code":"US-OH-TGW"},
          {"name":"CASTALIA","code":"US-OH-AL8"},
          {"name":"CEDARVILLE","code":"US-OH-XDO"},
          {"name":"CELINA","code":"US-OH-CEA"},
          {"name":"CENTERBURG","code":"US-OH-CBS"},
          {"name":"CENTERVILLE","code":"US-OH-CXW"},
          {"name":"CHAGRIN FALLS","code":"US-OH-CFO"},
          {"name":"CHANDLERSVILLE","code":"US-OH-C9V"},
          {"name":"CHARDON","code":"US-OH-CDO"},
          {"name":"CHARM","code":"US-OH-CA8"},
          {"name":"CHATTANOOGA","code":"US-OH-ZB5"},
          {"name":"CHESAPEAKE","code":"US-OH-HTW"},
          {"name":"CHESHIRE","code":"US-OH-HKH"},
          {"name":"CHESHIRE","code":"US-OH-KQC"},
          {"name":"CHESTER","code":"US-OH-SE9"},
          {"name":"CHESTERLAND","code":"US-OH-C2L"},
          {"name":"CHILLICOTHE","code":"US-OH-CIY"},
          {"name":"CINCINNATI","code":"US-OH-CVG"},
          {"name":"CIRCLEVILLE","code":"US-OH-CIE"},
          {"name":"CLARINGTON","code":"US-OH-C7L"},
          {"name":"CLARKSVILLE","code":"US-OH-C2K"},
          {"name":"CLAVES","code":"US-OH-VXJ"},
          {"name":"CLAYTON","code":"US-OH-CYT"},
          {"name":"CLEVELAND","code":"US-OH-CLE"},
          {"name":"CLEVELAND HEIGHTS","code":"US-OH-C4V"},
          {"name":"CLEVES","code":"US-OH-VCS"},
          {"name":"CLINTON","code":"US-OH-CT9"},
          {"name":"CLOVERDALE","code":"US-OH-DCD"},
          {"name":"CLYDE","code":"US-OH-CYB"},
          {"name":"COALTON","code":"US-OH-5CO"},
          {"name":"COLDWATER","code":"US-OH-COW"},
          {"name":"COLLEGE CORNER","code":"US-OH-QCG"},
          {"name":"COLUMBIA","code":"US-OH-C73"},
          {"name":"COLUMBIA STATION","code":"US-OH-CAF"},
          {"name":"COLUMBIANA","code":"US-OH-COG"},
          {"name":"COLUMBUS","code":"US-OH-CMH"},
          {"name":"COLUMBUS GROVE","code":"US-OH-CGQ"},
          {"name":"CONCORD, LAKE","code":"US-OH-LCX"},
          {"name":"CONESVILLE","code":"US-OH-OIP"},
          {"name":"CONNEAUT","code":"US-OH-CDY"},
          {"name":"CONTINENTAL","code":"US-OH-NIU"},
          {"name":"CONVOY","code":"US-OH-OC4"},
          {"name":"COPLEY","code":"US-OH-CPY"},
          {"name":"CORTLAND","code":"US-OH-ZB9"},
          {"name":"COSHOCTON","code":"US-OH-CSC"},
          {"name":"COVINGTON","code":"US-OH-OVI"},
          {"name":"CRESTLINE","code":"US-OH-C7R"},
          {"name":"CRESTON","code":"US-OH-OHR"},
          {"name":"CROOKSVILLE","code":"US-OH-OOS"},
          {"name":"CROTON","code":"US-OH-CYO"},
          {"name":"CURTICE","code":"US-OH-IUC"},
          {"name":"CUSTAR","code":"US-OH-USR"},
          {"name":"CUYAHOGA FALLS","code":"US-OH-CUJ"},
          {"name":"CUYAHOGA HEIGHTS","code":"US-OH-CU5"},
          {"name":"CYNTHIANA","code":"US-OH-6CY"},
          {"name":"DALTON","code":"US-OH-QDL"},
          {"name":"DANVILLE","code":"US-OH-AGK"},
          {"name":"DAYTON","code":"US-OH-DAY"},
          {"name":"DECATUR","code":"US-OH-DC9"},
          {"name":"DEER PARK","code":"US-OH-XFA"},
          {"name":"DEERFIELD","code":"US-OH-DFL"},
          {"name":"DEFIANCE","code":"US-OH-DFI"},
          {"name":"DELAWARE","code":"US-OH-DEL"},
          {"name":"DELHI","code":"US-OH-DIJ"},
          {"name":"DELLROY","code":"US-OH-DQY"},
          {"name":"DELPHOS","code":"US-OH-DEP"},
          {"name":"DELTA","code":"US-OH-DA2"},
          {"name":"DENNISON","code":"US-OH-OH6"},
          {"name":"DESHLER","code":"US-OH-DSH"},
          {"name":"DEXTER","code":"US-OH-XXT"},
          {"name":"DEXTER CITY","code":"US-OH-DX2"},
          {"name":"DOVER","code":"US-OH-DOO"},
          {"name":"DUBLIN","code":"US-OH-DUO"},
          {"name":"DUNBRIDGE","code":"US-OH-DUE"},
          {"name":"DUNCAN FALLS","code":"US-OH-ZC4"},
          {"name":"DUNDEE","code":"US-OH-DE4"},
          {"name":"DUNKIRK","code":"US-OH-DKI"},
          {"name":"EAST CANTON","code":"US-OH-ECZ"},
          {"name":"EAST FAIRFIELD","code":"US-OH-4HE"},
          {"name":"EAST LAKE","code":"US-OH-E2L"},
          {"name":"EAST LIBERTY","code":"US-OH-ETY"},
          {"name":"EAST LIVERPOOL","code":"US-OH-ELO"},
          {"name":"EAST PALESTINE","code":"US-OH-YEP"},
          {"name":"EAST SPARTA","code":"US-OH-ESA"},
          {"name":"EAST SPRINGFIELD","code":"US-OH-E2S"},
          {"name":"EASTON","code":"US-OH-E5G"},
          {"name":"EATON","code":"US-OH-EAQ"},
          {"name":"EDGERTON","code":"US-OH-EN6"},
          {"name":"EDGEWATER BEACH","code":"US-OH-EJT"},
          {"name":"EDON","code":"US-OH-JVN"},
          {"name":"ELDORADO","code":"US-OH-E5D"},
          {"name":"ELIDA","code":"US-OH-ELX"},
          {"name":"ELKTON","code":"US-OH-QKO"},
          {"name":"ELMORE","code":"US-OH-EMJ"},
          {"name":"ELYRIA","code":"US-OH-EIA"},
          {"name":"ENGLEWOOD","code":"US-OH-OZ2"},
          {"name":"ENON","code":"US-OH-ENM"},
          {"name":"ETNA","code":"US-OH-XTA"},
          {"name":"EUCLID","code":"US-OH-EUC"},
          {"name":"EVENDALE","code":"US-OH-EV7"},
          {"name":"EVENDALE","code":"US-OH-EVL"},
          {"name":"FAIRBORN","code":"US-OH-FBA"},
          {"name":"FAIRFAX, HAMILTON","code":"US-OH-FXO"},
          {"name":"FAIRFIELD","code":"US-OH-FAO"},
          {"name":"FAIRLAWN","code":"US-OH-FL3"},
          {"name":"FAIRPORT HARBOR VILLAGE","code":"US-OH-FPT"},
          {"name":"FAIRVIEW PARK","code":"US-OH-FVP"},
          {"name":"FARMER","code":"US-OH-JFM"},
          {"name":"FARMERSVILLE","code":"US-OH-6FV"},
          {"name":"FARMERSVILLE","code":"US-OH-VG3"},
          {"name":"FAYETTE","code":"US-OH-FY7"},
          {"name":"FAYETTEVILLE","code":"US-OH-FV2"},
          {"name":"FERNALD","code":"US-OH-FN2"},
          {"name":"FINDLAY","code":"US-OH-FDY"},
          {"name":"FLAT ROCK","code":"US-OH-FLC"},
          {"name":"FLEMING","code":"US-OH-ZXH"},
          {"name":"FLETCHER","code":"US-OH-YFH"},
          {"name":"FOREST PARK","code":"US-OH-FPK"},
          {"name":"FOREST, HARDIN","code":"US-OH-FVO"},
          {"name":"FORT LORAMIE","code":"US-OH-FJL"},
          {"name":"FORT RECOVERY","code":"US-OH-FVR"},
          {"name":"FOSTORIA","code":"US-OH-FSO"},
          {"name":"FRANKFORT","code":"US-OH-FKF"},
          {"name":"FRANKLIN","code":"US-OH-FLH"},
          {"name":"FRANKLIN FURNACE","code":"US-OH-FAU"},
          {"name":"FRAZEYSBURG","code":"US-OH-YFZ"},
          {"name":"FREDERICKSBURG","code":"US-OH-FDE"},
          {"name":"FREDERICKTOWN","code":"US-OH-FCW"},
          {"name":"FREEPORT","code":"US-OH-UEP"},
          {"name":"FREMONT","code":"US-OH-XFK"},
          {"name":"FRESNO","code":"US-OH-FQN"},
          {"name":"GAHANNA","code":"US-OH-GAH"},
          {"name":"GALENA","code":"US-OH-GZN"},
          {"name":"GALION","code":"US-OH-GQQ"},
          {"name":"GALLIPOLIS","code":"US-OH-GAP"},
          {"name":"GALLOWAY","code":"US-OH-GX9"},
          {"name":"GAMBIER","code":"US-OH-GMB"},
          {"name":"GARFIELD HEIGHTS","code":"US-OH-GHZ"},
          {"name":"GARRETTSVILLE","code":"US-OH-GZV"},
          {"name":"GENEVA","code":"US-OH-UJI"},
          {"name":"GENOA, OTTAWA","code":"US-OH-GN2"},
          {"name":"GEORGETOWN","code":"US-OH-G6T"},
          {"name":"GEORGETOWN, VERMILION","code":"US-OH-GNJ"},
          {"name":"GERMANTOWN","code":"US-OH-GMO"},
          {"name":"GETTYSBURG","code":"US-OH-YUR"},
          {"name":"GIBSONBURG","code":"US-OH-GIS"},
          {"name":"GIRARD","code":"US-OH-RXX"},
          {"name":"GLENDALE","code":"US-OH-QGO"},
          {"name":"GLENFORD","code":"US-OH-GEF"},
          {"name":"GLENMONT","code":"US-OH-G4H"},
          {"name":"GLENWILLOW","code":"US-OH-GWW"},
          {"name":"GLOUSTER","code":"US-OH-GSE"},
          {"name":"GNADENHUTTEN","code":"US-OH-GND"},
          {"name":"GOSHEN","code":"US-OH-GKH"},
          {"name":"GRAFTON","code":"US-OH-GFO"},
          {"name":"GRAND RAPIDS","code":"US-OH-VZI"},
          {"name":"GRAND RIVER","code":"US-OH-GKQ"},
          {"name":"GRANDVIEW","code":"US-OH-G95"},
          {"name":"GRANDVIEW HEIGHTS","code":"US-OH-VGH"},
          {"name":"GRANVILLE","code":"US-OH-GVC"},
          {"name":"GREEN","code":"US-OH-O2O"},
          {"name":"GREEN SPRINGS","code":"US-OH-GCP"},
          {"name":"GREENFIELD","code":"US-OH-GNF"},
          {"name":"GREENVILLE","code":"US-OH-GGH"},
          {"name":"GREENWICH","code":"US-OH-GW7"},
          {"name":"GREENWOOD, GUERNSEY","code":"US-OH-GNS"},
          {"name":"GROVE CITY","code":"US-OH-JGC"},
          {"name":"GROVEPORT","code":"US-OH-GVP"},
          {"name":"GYPSUM","code":"US-OH-GPU"},
          {"name":"HAMERSVILLE","code":"US-OH-HVH"},
          {"name":"HAMILTON","code":"US-OH-HAO"},
          {"name":"HAMMONDSVILLE","code":"US-OH-HMD"},
          {"name":"HANGING ROCK","code":"US-OH-HGK"},
          {"name":"HANNIBAL","code":"US-OH-HBP"},
          {"name":"HANOVERTON","code":"US-OH-HNN"},
          {"name":"HARPERSFIELD","code":"US-OH-HRP"},
          {"name":"HARPSTER","code":"US-OH-H88"},
          {"name":"HARRISON","code":"US-OH-ZRN"},
          {"name":"HARRISVILLE","code":"US-OH-HVU"},
          {"name":"HARROD","code":"US-OH-H9D"},
          {"name":"HARTFORD","code":"US-OH-ZYR"},
          {"name":"HARTVILLE","code":"US-OH-HVI"},
          {"name":"HARVEYSBURG","code":"US-OH-9HV"},
          {"name":"HASKINS","code":"US-OH-HKI"},
          {"name":"HAVERHILL","code":"US-OH-HHO"},
          {"name":"HAVILAND","code":"US-OH-YHA"},
          {"name":"HAYDENVILLE","code":"US-OH-QHY"},
          {"name":"HAYNESVILLE","code":"US-OH-HYN"},
          {"name":"HEATH","code":"US-OH-XGT"},
          {"name":"HEBRON","code":"US-OH-HEB"},
          {"name":"HENNESSY","code":"US-OH-HY5"},
          {"name":"HICKSVILLE","code":"US-OH-PK5"},
          {"name":"HIGHLAND HEIGHTS","code":"US-OH-HIK"},
          {"name":"HIGHPOINT","code":"US-OH-HIT"},
          {"name":"HILLIARD","code":"US-OH-HJL"},
          {"name":"HILLSBORO, OHIO","code":"US-OH-HHX"},
          {"name":"HINCKLEY","code":"US-OH-HIZ"},
          {"name":"HIRAM","code":"US-OH-HR2"},
          {"name":"HOLGATE","code":"US-OH-O2G"},
          {"name":"HOLIDAY CITY","code":"US-OH-HDI"},
          {"name":"HOLLAND","code":"US-OH-HOO"},
          {"name":"HOLMESVILLE","code":"US-OH-H2V"},
          {"name":"HOMER","code":"US-OH-QQR"},
          {"name":"HOMEWORTH","code":"US-OH-HWH"},
          {"name":"HOOVEN","code":"US-OH-5HO"},
          {"name":"HORTON","code":"US-OH-HTU"},
          {"name":"HOUSTON","code":"US-OH-HUX"},
          {"name":"HUBBARD","code":"US-OH-XGA"},
          {"name":"HUBER HEIGHTS","code":"US-OH-QHT"},
          {"name":"HUDSON","code":"US-OH-HUO"},
          {"name":"HUNTSVILLE","code":"US-OH-HVF"},
          {"name":"HURON","code":"US-OH-HUB"},
          {"name":"IBERIA","code":"US-OH-IBE"},
          {"name":"INDEPENDENCE","code":"US-OH-IPD"},
          {"name":"IRONDALE","code":"US-OH-ID6"},
          {"name":"IRONTON","code":"US-OH-IRN"},
          {"name":"IVORYDALE","code":"US-OH-IVF"},
          {"name":"JACKSON","code":"US-OH-JAK"},
          {"name":"JACKSON CENTER","code":"US-OH-JCR"},
          {"name":"JACKSONTOWN","code":"US-OH-JJH"},
          {"name":"JAMESTOWN","code":"US-OH-JWN"},
          {"name":"JEFFERSON","code":"US-OH-JFN"},
          {"name":"JEFFERSONVILLE","code":"US-OH-JFO"},
          {"name":"JEROMESVILLE","code":"US-OH-JOG"},
          {"name":"JEWELL","code":"US-OH-W9L"},
          {"name":"JOHNSTON","code":"US-OH-JHN"},
          {"name":"JOHNSTOWN","code":"US-OH-JOW"},
          {"name":"JUNCTION CITY, PERRY","code":"US-OH-JUC"},
          {"name":"KALIDA","code":"US-OH-KLD"},
          {"name":"KENSINGTON","code":"US-OH-KSG"},
          {"name":"KENT","code":"US-OH-KTO"},
          {"name":"KENTON","code":"US-OH-KNO"},
          {"name":"KERR","code":"US-OH-6RR"},
          {"name":"KETTERING","code":"US-OH-KTR"},
          {"name":"KETTLERSVILLE","code":"US-OH-KLV"},
          {"name":"KIDRON","code":"US-OH-KID"},
          {"name":"KILLBUCK","code":"US-OH-KIK"},
          {"name":"KINGMAN","code":"US-OH-ZUD"},
          {"name":"KINGS MILLS","code":"US-OH-KGM"},
          {"name":"KINGSTON","code":"US-OH-KGC"},
          {"name":"KINSMAN, BELMONT","code":"US-OH-KMN"},
          {"name":"KINSMAN, TRUMBULL","code":"US-OH-KSA"},
          {"name":"KIRBY","code":"US-OH-6RB"},
          {"name":"KIRTLAND","code":"US-OH-KCQ"},
          {"name":"KITTS HILL","code":"US-OH-IIJ"},
          {"name":"LA RUE","code":"US-OH-LRN"},
          {"name":"LAFAYETTE","code":"US-OH-LF2"},
          {"name":"LAFFERTY","code":"US-OH-L9F"},
          {"name":"LAGRANGE","code":"US-OH-ZNG"},
          {"name":"LAKESIDE","code":"US-OH-LS8"},
          {"name":"LAKESIDE MARBLEHEAD","code":"US-OH-KDB"},
          {"name":"LAKEWOOD","code":"US-OH-LKD"},
          {"name":"LANCASTER","code":"US-OH-LNC"},
          {"name":"LANGSVILLE","code":"US-OH-XIB"},
          {"name":"LANSING","code":"US-OH-AIG"},
          {"name":"LAURELVILLE","code":"US-OH-QLH"},
          {"name":"LEAVITTSBURG","code":"US-OH-LVX"},
          {"name":"LEBANON","code":"US-OH-LNB"},
          {"name":"LEESBURG","code":"US-OH-LSO"},
          {"name":"LEETONIA","code":"US-OH-EET"},
          {"name":"LEIPSIC","code":"US-OH-LSJ"},
          {"name":"LEWIS CENTER","code":"US-OH-LEQ"},
          {"name":"LEWISBURG","code":"US-OH-LBU"},
          {"name":"LEXINGTON","code":"US-OH-LKQ"},
          {"name":"LIBERTY","code":"US-OH-LHP"},
          {"name":"LIBERTY TOWNSHIP","code":"US-OH-L8T"},
          {"name":"LIMA","code":"US-OH-LIA"},
          {"name":"LINNDALE","code":"US-OH-LQX"},
          {"name":"LISBON","code":"US-OH-LZI"},
          {"name":"LITTLE HOCKING","code":"US-OH-LHK"},
          {"name":"LOCKBOURNE","code":"US-OH-LCK"},
          {"name":"LOCKLAND","code":"US-OH-LKL"},
          {"name":"LODI","code":"US-OH-LOC"},
          {"name":"LOGAN","code":"US-OH-OGN"},
          {"name":"LONDON","code":"US-OH-LDN"},
          {"name":"LORAIN","code":"US-OH-LOR"},
          {"name":"LORDSTOWN","code":"US-OH-LLO"},
          {"name":"LOUDONVILLE","code":"US-OH-LUO"},
          {"name":"LOUISVILLE","code":"US-OH-LUV"},
          {"name":"LOVELAND","code":"US-OH-XHT"},
          {"name":"LOWELL","code":"US-OH-XLW"},
          {"name":"LOWELLVILLE","code":"US-OH-JWV"},
          {"name":"LUCASVILLE","code":"US-OH-LV9"},
          {"name":"LUCKEY","code":"US-OH-LU2"},
          {"name":"LYNDHURST","code":"US-OH-QYN"},
          {"name":"LYONS","code":"US-OH-LOY"},
          {"name":"MACEDONIA","code":"US-OH-XIF"},
          {"name":"MADEIRA","code":"US-OH-ME6"},
          {"name":"MADISON","code":"US-OH-AAL"},
          {"name":"MAGNOLIA","code":"US-OH-OIA"},
          {"name":"MAINEVILLE","code":"US-OH-IEI"},
          {"name":"MALINTA","code":"US-OH-M3A"},
          {"name":"MALTA","code":"US-OH-ZGB"},
          {"name":"MALVERN","code":"US-OH-MVR"},
          {"name":"MANCHESTER","code":"US-OH-NSK"},
          {"name":"MANSFIELD","code":"US-OH-MFD"},
          {"name":"MANTUA","code":"US-OH-MT9"},
          {"name":"MAPLE GROVE","code":"US-OH-MGZ"},
          {"name":"MAPLE HEIGHTS","code":"US-OH-MAP"},
          {"name":"MARBLEHEAD","code":"US-OH-HD9"},
          {"name":"MARENGO","code":"US-OH-M2A"},
          {"name":"MARIA STEIN","code":"US-OH-ISK"},
          {"name":"MARIEMONT","code":"US-OH-MRI"},
          {"name":"MARIETTA","code":"US-OH-RTT"},
          {"name":"MARION","code":"US-OH-MNN"},
          {"name":"MARSEILLES","code":"US-OH-ZEX"},
          {"name":"MARTEL","code":"US-OH-XJE"},
          {"name":"MARTINS FERRY","code":"US-OH-MFY"},
          {"name":"MARTINSBURG","code":"US-OH-MB7"},
          {"name":"MARYSVILLE","code":"US-OH-MRV"},
          {"name":"MASON","code":"US-OH-UXE"},
          {"name":"MASSILLON","code":"US-OH-XJF"},
          {"name":"MASURY","code":"US-OH-MSU"},
          {"name":"MAUMEE","code":"US-OH-MMY"},
          {"name":"MAYFIELD","code":"US-OH-M9F"},
          {"name":"MAYFIELD HEIGHTS","code":"US-OH-M3H"},
          {"name":"MAYFIELD, BUTLER","code":"US-OH-MF7"},
          {"name":"MAYFIELD, CUYAHOGA","code":"US-OH-AYF"},
          {"name":"MCARTHUR","code":"US-OH-MCU"},
          {"name":"MCCLURE","code":"US-OH-JMU"},
          {"name":"MCCOMBS","code":"US-OH-XXZ"},
          {"name":"MCCONNELSVILLE","code":"US-OH-MVZ"},
          {"name":"MCDERMOTT","code":"US-OH-MDT"},
          {"name":"MCDONALD, MUSKINGUM","code":"US-OH-DOD"},
          {"name":"MCDONALD, TRUMBULL","code":"US-OH-MD8"},
          {"name":"MEDINA","code":"US-OH-MDQ"},
          {"name":"MEDWAY","code":"US-OH-DWF"},
          {"name":"MENTOR","code":"US-OH-MEA"},
          {"name":"MESOPOTAMIA","code":"US-OH-ME9"},
          {"name":"METAMORA","code":"US-OH-EMF"},
          {"name":"MIAMISBURG","code":"US-OH-IAM"},
          {"name":"MIAMITOWN","code":"US-OH-IMW"},
          {"name":"MIAMIVILLE","code":"US-OH-MAM"},
          {"name":"MIDDLE POINT","code":"US-OH-7MP"},
          {"name":"MIDDLEBRANCH","code":"US-OH-XXH"},
          {"name":"MIDDLEBURG HEIGHTS","code":"US-OH-MBV"},
          {"name":"MIDDLEBURG, LOGAN","code":"US-OH-DBU"},
          {"name":"MIDDLEFIELD","code":"US-OH-QMI"},
          {"name":"MIDDLEPORT","code":"US-OH-MIP"},
          {"name":"MIDDLETOWN","code":"US-OH-MWO"},
          {"name":"MIDLAND","code":"US-OH-M4D"},
          {"name":"MIDVALE","code":"US-OH-IDV"},
          {"name":"MILAN","code":"US-OH-LIM"},
          {"name":"MILFORD","code":"US-OH-MRQ"},
          {"name":"MILFORD CENTER","code":"US-OH-FKC"},
          {"name":"MILLBURY","code":"US-OH-XID"},
          {"name":"MILLERSBURG","code":"US-OH-MLV"},
          {"name":"MILLERSPORT","code":"US-OH-ILR"},
          {"name":"MILLERSVILLE","code":"US-OH-IIF"},
          {"name":"MINERAL CITY","code":"US-OH-MC4"},
          {"name":"MINERAL RIDGE","code":"US-OH-4AL"},
          {"name":"MINERVA","code":"US-OH-MNV"},
          {"name":"MINGO JUNCTION","code":"US-OH-XIM"},
          {"name":"MINSTER","code":"US-OH-MZO"},
          {"name":"MOGADORE","code":"US-OH-MGD"},
          {"name":"MONCLOVA","code":"US-OH-MQ2"},
          {"name":"MONROE","code":"US-OH-NRO"},
          {"name":"MONROEVILLE","code":"US-OH-O8M"},
          {"name":"MONTGOMERY","code":"US-OH-NGY"},
          {"name":"MONTPELIER","code":"US-OH-MMP"},
          {"name":"MONTREAL","code":"US-OH-M2T"},
          {"name":"MORAINE","code":"US-OH-MRE"},
          {"name":"MORELAND HILLS","code":"US-OH-9OH"},
          {"name":"MORRAL","code":"US-OH-MJC"},
          {"name":"MORRISTOWN","code":"US-OH-ITW"},
          {"name":"MORROW","code":"US-OH-MW6"},
          {"name":"MOUNT EATON","code":"US-OH-ME2"},
          {"name":"MOUNT GILEAD","code":"US-OH-UGO"},
          {"name":"MOUNT HOPE","code":"US-OH-MH9"},
          {"name":"MOUNT ORAB","code":"US-OH-MXQ"},
          {"name":"MOUNT PERRY","code":"US-OH-PY4"},
          {"name":"MOUNT STERLING","code":"US-OH-YMS"},
          {"name":"MOUNT VERNON","code":"US-OH-M2V"},
          {"name":"MOUNT VERNON","code":"US-OH-MWT"},
          {"name":"MUNROE FALLS","code":"US-OH-MF9"},
          {"name":"NAPOLEON","code":"US-OH-NAL"},
          {"name":"NASHPORT","code":"US-OH-N2P"},
          {"name":"NASHVILLE","code":"US-OH-NJV"},
          {"name":"NAVARRE","code":"US-OH-NVF"},
          {"name":"NEGLEY","code":"US-OH-NEG"},
          {"name":"NELSONVILLE","code":"US-OH-NNV"},
          {"name":"NEW ALBANY","code":"US-OH-QWB"},
          {"name":"NEW BLOOMINGTON","code":"US-OH-7NB"},
          {"name":"NEW BREMEN","code":"US-OH-UXI"},
          {"name":"NEW CARLISLE","code":"US-OH-NER"},
          {"name":"NEW CONCORD","code":"US-OH-ONZ"},
          {"name":"NEW HAMPSHIRE","code":"US-OH-EHH"},
          {"name":"NEW HOLLAND","code":"US-OH-NH7"},
          {"name":"NEW KNOXVILLE","code":"US-OH-NKX"},
          {"name":"NEW LEBANON","code":"US-OH-NL4"},
          {"name":"NEW LEXINGTON","code":"US-OH-NLX"},
          {"name":"NEW LONDON","code":"US-OH-NED"},
          {"name":"NEW MARSHFIELD","code":"US-OH-NSF"},
          {"name":"NEW MIAMI","code":"US-OH-NMI"},
          {"name":"NEW MIDDLETOWN","code":"US-OH-N6M"},
          {"name":"NEW PARIS","code":"US-OH-NP8"},
          {"name":"NEW PHILADELPHIA","code":"US-OH-PHD"},
          {"name":"NEW RICHMOND","code":"US-OH-QRQ"},
          {"name":"NEW VIENNA","code":"US-OH-NVH"},
          {"name":"NEW WASHINGTON","code":"US-OH-9NW"},
          {"name":"NEW WATERFORD","code":"US-OH-ERF"},
          {"name":"NEW WESTON","code":"US-OH-NWQ"},
          {"name":"NEWARK","code":"US-OH-NEK"},
          {"name":"NEWBURG","code":"US-OH-NWR"},
          {"name":"NEWBURGH HEIGHTS","code":"US-OH-JNW"},
          {"name":"NEWBURY","code":"US-OH-NEY"},
          {"name":"NEWCOMERSTOWN","code":"US-OH-NXQ"},
          {"name":"NEWKIRK","code":"US-OH-NR6"},
          {"name":"NEWPORT","code":"US-OH-P5W"},
          {"name":"NEWTON FALLS","code":"US-OH-ZNF"},
          {"name":"NEWTOWN","code":"US-OH-NWX"},
          {"name":"NILES","code":"US-OH-NLS"},
          {"name":"NORTH BALTIMORE","code":"US-OH-NBH"},
          {"name":"NORTH BEND","code":"US-OH-NND"},
          {"name":"NORTH BLOOMFIELD","code":"US-OH-RTM"},
          {"name":"NORTH CANTON","code":"US-OH-UXJ"},
          {"name":"NORTH COLLEGE HILL","code":"US-OH-NC4"},
          {"name":"NORTH JACKSON","code":"US-OH-NJ2"},
          {"name":"NORTH KINGSVILLE","code":"US-OH-NKE"},
          {"name":"NORTH LAWRENCE","code":"US-OH-NLB"},
          {"name":"NORTH LEWISBURG","code":"US-OH-NLU"},
          {"name":"NORTH LIBERTY","code":"US-OH-THY"},
          {"name":"NORTH LIMA","code":"US-OH-NLA"},
          {"name":"NORTH OLMSTED","code":"US-OH-NOD"},
          {"name":"NORTH RANDALL","code":"US-OH-XRO"},
          {"name":"NORTH RIDGEVILLE","code":"US-OH-NJR"},
          {"name":"NORTH ROYALTON","code":"US-OH-ZNR"},
          {"name":"NORTHFIELD","code":"US-OH-QNF"},
          {"name":"NORTHWOOD, LOGAN","code":"US-OH-NW4"},
          {"name":"NORTHWOOD, WOOD","code":"US-OH-NHW"},
          {"name":"NORTON, SUMMIT","code":"US-OH-NNU"},
          {"name":"NORWALK","code":"US-OH-XJR"},
          {"name":"NORWOOD","code":"US-OH-NDY"},
          {"name":"NOVA","code":"US-OH-XJP"},
          {"name":"NOVELTY","code":"US-OH-ZGW"},
          {"name":"OAK HARBOR","code":"US-OH-OHO"},
          {"name":"OAK HILL","code":"US-OH-OAH"},
          {"name":"OAKWOOD","code":"US-OH-O2A"},
          {"name":"OAKWOOD VILLAGE","code":"US-OH-ZOV"},
          {"name":"OAKWOOD, PAULDING","code":"US-OH-OKG"},
          {"name":"OBERLIN","code":"US-OH-XOR"},
          {"name":"OBETZ","code":"US-OH-OBE"},
          {"name":"OHIO CITY","code":"US-OH-ZE2"},
          {"name":"OKEANA","code":"US-OH-OZU"},
          {"name":"OKOLONA","code":"US-OH-OKZ"},
          {"name":"OLD FORT","code":"US-OH-OFO"},
          {"name":"OLMSTED FALLS","code":"US-OH-YOF"},
          {"name":"ONTARIO","code":"US-OH-OT5"},
          {"name":"ORANGE","code":"US-OH-2OV"},
          {"name":"OREGON","code":"US-OH-OEG"},
          {"name":"ORRVILLE","code":"US-OH-ORR"},
          {"name":"ORWELL","code":"US-OH-OEQ"},
          {"name":"OSTRANDER","code":"US-OH-ORX"},
          {"name":"OTTAWA","code":"US-OH-OTQ"},
          {"name":"OTTOVILLE","code":"US-OH-TOV"},
          {"name":"OVERPECK","code":"US-OH-OVR"},
          {"name":"OXFORD","code":"US-OH-OXD"},
          {"name":"PAINESVILLE","code":"US-OH-PVZ"},
          {"name":"PANDORA","code":"US-OH-PKO"},
          {"name":"PARKMAN","code":"US-OH-KM9"},
          {"name":"PARMA","code":"US-OH-PBH"},
          {"name":"PATASKALA","code":"US-OH-AKL"},
          {"name":"PATRIOT","code":"US-OH-QPI"},
          {"name":"PAULDING","code":"US-OH-PAD"},
          {"name":"PAYNE","code":"US-OH-PY2"},
          {"name":"PEEBLES","code":"US-OH-P2E"},
          {"name":"PEMBERVILLE","code":"US-OH-PEM"},
          {"name":"PENINSULA","code":"US-OH-ULA"},
          {"name":"PEORIA","code":"US-OH-JP9"},
          {"name":"PEPPER PIKE","code":"US-OH-P4K"},
          {"name":"PERRY","code":"US-OH-PQY"},
          {"name":"PERRYSBURG","code":"US-OH-XKQ"},
          {"name":"PERRYSVILLE","code":"US-OH-PYV"},
          {"name":"PETTISVILLE","code":"US-OH-PV7"},
          {"name":"PHILO","code":"US-OH-PJI"},
          {"name":"PICKERINGTON","code":"US-OH-PXQ"},
          {"name":"PIKETON","code":"US-OH-PKU"},
          {"name":"PIONEER","code":"US-OH-PYH"},
          {"name":"PIQUA","code":"US-OH-PQA"},
          {"name":"PITSBURG","code":"US-OH-PBR"},
          {"name":"PLAIN CITY","code":"US-OH-PLX"},
          {"name":"PLAINFIELD","code":"US-OH-IFI"},
          {"name":"PLAINVIEW","code":"US-OH-PSW"},
          {"name":"PLEASANT CITY","code":"US-OH-PJY"},
          {"name":"PLEASANT PLAIN","code":"US-OH-PAP"},
          {"name":"PLYMOUTH","code":"US-OH-PLH"},
          {"name":"POLAND","code":"US-OH-QPD"},
          {"name":"POMEROY","code":"US-OH-PX3"},
          {"name":"PORT CLINTON","code":"US-OH-PCN"},
          {"name":"PORT WILLIAM","code":"US-OH-2WM"},
          {"name":"PORTAGE","code":"US-OH-OG9"},
          {"name":"PORTLAND","code":"US-OH-O2H"},
          {"name":"PORTSMOUTH","code":"US-OH-PMH"},
          {"name":"POTTERSBURG","code":"US-OH-POG"},
          {"name":"POWELL","code":"US-OH-PO5"},
          {"name":"PRINCETON","code":"US-OH-VPC"},
          {"name":"PROCTORVILLE","code":"US-OH-PVV"},
          {"name":"PROSPECT","code":"US-OH-98P"},
          {"name":"PUT-IN-BAY","code":"US-OH-PU2"},
          {"name":"QUAKER CITY","code":"US-OH-QAY"},
          {"name":"RACINE","code":"US-OH-ZUR"},
          {"name":"RAHWAY","code":"US-OH-RAY"},
          {"name":"RANDOLPH","code":"US-OH-OL9"},
          {"name":"RAVENNA","code":"US-OH-RVN"},
          {"name":"RAY","code":"US-OH-RJX"},
          {"name":"RAYMOND","code":"US-OH-RM3"},
          {"name":"READING","code":"US-OH-RDJ"},
          {"name":"RENO","code":"US-OH-RO4"},
          {"name":"REYNOLDSBURG","code":"US-OH-RYG"},
          {"name":"RICHFIELD","code":"US-OH-REQ"},
          {"name":"RICHLAND","code":"US-OH-YGT"},
          {"name":"RICHMOND","code":"US-OH-RCD"},
          {"name":"RICHMOND DALE","code":"US-OH-IAF"},
          {"name":"RICHMOND HEIGHTS","code":"US-OH-RMS"},
          {"name":"RICHWOOD","code":"US-OH-RXI"},
          {"name":"RICKENBACKER INTERNATIONAL APT","code":"US-OH-RIB"},
          {"name":"RIDGEVILLE CORNERS","code":"US-OH-DLC"},
          {"name":"RIMER","code":"US-OH-RIM"},
          {"name":"RIO GRANDE","code":"US-OH-RE4"},
          {"name":"RITTMAN","code":"US-OH-RTN"},
          {"name":"RIVERSIDE","code":"US-OH-OH4"},
          {"name":"ROCK CREEK","code":"US-OH-RC6"},
          {"name":"ROCKFORD","code":"US-OH-RFO"},
          {"name":"ROCKY RIVER","code":"US-OH-RKY"},
          {"name":"ROGERS","code":"US-OH-ZQ2"},
          {"name":"ROOTSTOWN","code":"US-OH-R34"},
          {"name":"ROSEVILLE","code":"US-OH-ROS"},
          {"name":"ROSS","code":"US-OH-RS4"},
          {"name":"ROSSBURG","code":"US-OH-R8G"},
          {"name":"ROSSFORD","code":"US-OH-RSO"},
          {"name":"RUSSELLS POINT","code":"US-OH-RUO"},
          {"name":"RUSSIA","code":"US-OH-USS"},
          {"name":"SABINA","code":"US-OH-SBB"},
          {"name":"SAGAMORE HILLS","code":"US-OH-SJH"},
          {"name":"SAINT BERNARD","code":"US-OH-SSB"},
          {"name":"SAINT CLAIRSVILLE","code":"US-OH-SCS"},
          {"name":"SAINT HENRY","code":"US-OH-HZA"},
          {"name":"SAINT JAMES","code":"US-OH-SJM"},
          {"name":"SAINT LOUISVILLE","code":"US-OH-TSY"},
          {"name":"SAINT MARYS","code":"US-OH-SMR"},
          {"name":"SAINT PARIS","code":"US-OH-IFS"},
          {"name":"SALEM","code":"US-OH-SAE"},
          {"name":"SALESVILLE","code":"US-OH-QVE"},
          {"name":"SANDUSKY, ERIE","code":"US-OH-SKY"},
          {"name":"SARDINIA","code":"US-OH-S2D"},
          {"name":"SAVANNAH","code":"US-OH-SH8"},
          {"name":"SAYBROOK","code":"US-OH-SB4"},
          {"name":"SCIOTOVILLE","code":"US-OH-2SV"},
          {"name":"SEBRING","code":"US-OH-SEB"},
          {"name":"SENECAVILLE","code":"US-OH-XMJ"},
          {"name":"SEVILLE","code":"US-OH-ZVL"},
          {"name":"SHADYSIDE","code":"US-OH-SHY"},
          {"name":"SHAKER HEIGHTS","code":"US-OH-XSK"},
          {"name":"SHANDON","code":"US-OH-DOZ"},
          {"name":"SHARON CENTER","code":"US-OH-CXZ"},
          {"name":"SHARONVILLE","code":"US-OH-XMA"},
          {"name":"SHEFFIELD","code":"US-OH-HEI"},
          {"name":"SHEFFIELD LAKE","code":"US-OH-SL9"},
          {"name":"SHEFFIELD VILLAGE","code":"US-OH-HFG"},
          {"name":"SHELBY","code":"US-OH-SBI"},
          {"name":"SHILOH","code":"US-OH-VVO"},
          {"name":"SHREVE","code":"US-OH-OHS"},
          {"name":"SIDNEY","code":"US-OH-SDN"},
          {"name":"SILVERTON","code":"US-OH-VET"},
          {"name":"SMITHFIELD","code":"US-OH-UYV"},
          {"name":"SMITHVILLE, WAYNE","code":"US-OH-IHV"},
          {"name":"SOLON","code":"US-OH-SOO"},
          {"name":"SOMERVILLE","code":"US-OH-OMV"},
          {"name":"SOUTH CHARLESTON","code":"US-OH-OUL"},
          {"name":"SOUTH EUCLID","code":"US-OH-S3C"},
          {"name":"SOUTH LEBANON","code":"US-OH-S6H"},
          {"name":"SOUTH POINT","code":"US-OH-SPH"},
          {"name":"SOUTH SOLON","code":"US-OH-S8S"},
          {"name":"SOUTH VIENNA","code":"US-OH-SV8"},
          {"name":"SOUTH WEBSTER","code":"US-OH-WWB"},
          {"name":"SPENCER","code":"US-OH-SPP"},
          {"name":"SPENCERVILLE","code":"US-OH-ECE"},
          {"name":"SPRINGBORO","code":"US-OH-SQO"},
          {"name":"SPRINGDALE","code":"US-OH-SRL"},
          {"name":"SPRINGFIELD","code":"US-OH-SGH"},
          {"name":"SPRINGHILLS","code":"US-OH-RGK"},
          {"name":"STERLING","code":"US-OH-HZ3"},
          {"name":"STEUBENVILLE","code":"US-OH-UYJ"},
          {"name":"STILLWATER","code":"US-OH-IAK"},
          {"name":"STONY RIDGE","code":"US-OH-DQE"},
          {"name":"STOW","code":"US-OH-SWT"},
          {"name":"STRASBURG","code":"US-OH-SBG"},
          {"name":"STRATTON","code":"US-OH-ST5"},
          {"name":"STREETSBORO","code":"US-OH-SBH"},
          {"name":"STRONGSVILLE","code":"US-OH-SSV"},
          {"name":"STRUTHERS","code":"US-OH-TRS"},
          {"name":"STRYKER","code":"US-OH-KRT"},
          {"name":"SUFFIELD","code":"US-OH-F7D"},
          {"name":"SUGAR CREEK","code":"US-OH-UGC"},
          {"name":"SUGARCREEK","code":"US-OH-ZSU"},
          {"name":"SULLIVAN","code":"US-OH-UVS"},
          {"name":"SUMMITVILLE","code":"US-OH-SVO"},
          {"name":"SUNBURY","code":"US-OH-SRZ"},
          {"name":"SWANTON","code":"US-OH-SWQ"},
          {"name":"SYCAMORE","code":"US-OH-ZIS"},
          {"name":"SYLVANIA","code":"US-OH-YL9"},
          {"name":"SYRACUSE","code":"US-OH-YCS"},
          {"name":"TALLMADGE","code":"US-OH-TMG"},
          {"name":"THOMPSON","code":"US-OH-TPS"},
          {"name":"THORNVILLE","code":"US-OH-TVH"},
          {"name":"TIFFIN","code":"US-OH-TFN"},
          {"name":"TILTONSVILLE","code":"US-OH-IVC"},
          {"name":"TIPP CITY","code":"US-OH-UZE"},
          {"name":"TOLEDO","code":"US-OH-TOL"},
          {"name":"TORONTO","code":"US-OH-TOD"},
          {"name":"TRENTON","code":"US-OH-TNX"},
          {"name":"TROTWOOD","code":"US-OH-TRO"},
          {"name":"TROY","code":"US-OH-TYH"},
          {"name":"TWINSBURG","code":"US-OH-TBG"},
          {"name":"UHRICHSVILLE","code":"US-OH-UHV"},
          {"name":"UNIONTOWN","code":"US-OH-UOO"},
          {"name":"UNIOPOLIS","code":"US-OH-UZP"},
          {"name":"UNIVERSITY HEIGHTS","code":"US-OH-UH3"},
          {"name":"UPPER ARLINGTON","code":"US-OH-UPQ"},
          {"name":"UPPER SANDUSKY","code":"US-OH-UPE"},
          {"name":"URBANA","code":"US-OH-URO"},
          {"name":"URBANCREST","code":"US-OH-UCR"},
          {"name":"UTICA","code":"US-OH-UTI"},
          {"name":"VALLEY CITY","code":"US-OH-VCO"},
          {"name":"VALLEY VIEW","code":"US-OH-ZVV"},
          {"name":"VAN BUREN","code":"US-OH-VB6"},
          {"name":"VAN WERT","code":"US-OH-VNW"},
          {"name":"VANDALIA","code":"US-OH-VDH"},
          {"name":"VERMILION","code":"US-OH-VE9"},
          {"name":"VERSAILLES","code":"US-OH-XVE"},
          {"name":"VIENNA","code":"US-OH-VHO"},
          {"name":"WADSWORTH","code":"US-OH-WDH"},
          {"name":"WAKEMAN","code":"US-OH-WA7"},
          {"name":"WALBRIDGE","code":"US-OH-WBG"},
          {"name":"WALDO","code":"US-OH-8UY"},
          {"name":"WALHONDING","code":"US-OH-W7A"},
          {"name":"WALNUT CREEK","code":"US-OH-WUC"},
          {"name":"WALNUT HILLS","code":"US-OH-XQX"},
          {"name":"WALTON HILLS","code":"US-OH-WJP"},
          {"name":"WAPAKONETA","code":"US-OH-AXV"},
          {"name":"WARREN","code":"US-OH-WRN"},
          {"name":"WARRENSVILLE HEIGHTS","code":"US-OH-WHH"},
          {"name":"WARRENTON","code":"US-OH-WUO"},
          {"name":"WARSAW","code":"US-OH-WWG"},
          {"name":"WASHINGTON COURT HOUSE","code":"US-OH-WCQ"},
          {"name":"WATERFORD","code":"US-OH-XWF"},
          {"name":"WATERLOO","code":"US-OH-W2O"},
          {"name":"WATERVILLE","code":"US-OH-TEI"},
          {"name":"WAUSEON","code":"US-OH-WUN"},
          {"name":"WAVERLY","code":"US-OH-WAV"},
          {"name":"WAYNESBURG","code":"US-OH-W6Y"},
          {"name":"WAYNESFIELD","code":"US-OH-WF7"},
          {"name":"WAYNESVILLE","code":"US-OH-YWL"},
          {"name":"WELLINGTON","code":"US-OH-WGN"},
          {"name":"WELLSTON","code":"US-OH-OHW"},
          {"name":"WELLSVILLE","code":"US-OH-W22"},
          {"name":"WEST ALEXANDRIA","code":"US-OH-TAX"},
          {"name":"WEST CARROLLTON","code":"US-OH-WCA"},
          {"name":"WEST CHESTER","code":"US-OH-WZC"},
          {"name":"WEST FARMINGTON","code":"US-OH-WF6"},
          {"name":"WEST JEFFERSON, MADISON","code":"US-OH-WJM"},
          {"name":"WEST JEFFERSON, WILLIAMS","code":"US-OH-WJW"},
          {"name":"WEST LAFAYETTE","code":"US-OH-WZF"},
          {"name":"WEST LIBERTY","code":"US-OH-W7B"},
          {"name":"WEST MILTON","code":"US-OH-JWQ"},
          {"name":"WEST PORTSMOUTH","code":"US-OH-WR3"},
          {"name":"WEST SALEM","code":"US-OH-FHK"},
          {"name":"WEST UNION","code":"US-OH-EUO"},
          {"name":"WEST UNITY","code":"US-OH-WUT"},
          {"name":"WESTCHESTER","code":"US-OH-WC6"},
          {"name":"WESTERVILLE","code":"US-OH-WTY"},
          {"name":"WESTLAKE","code":"US-OH-WLX"},
          {"name":"WESTON","code":"US-OH-OHE"},
          {"name":"WHIPPLE","code":"US-OH-WZX"},
          {"name":"WHITEHALL","code":"US-OH-TTL"},
          {"name":"WHITEHOUSE","code":"US-OH-WWS"},
          {"name":"WICKLIFFE","code":"US-OH-WIC"},
          {"name":"WILKESVILLE","code":"US-OH-WKV"},
          {"name":"WILLARD","code":"US-OH-WLA"},
          {"name":"WILLIAMSBURG","code":"US-OH-WUZ"},
          {"name":"WILLOUGHBY","code":"US-OH-LNN"},
          {"name":"WILLOW WOOD","code":"US-OH-WIW"},
          {"name":"WILMINGTON","code":"US-OH-ILN"},
          {"name":"WINCHESTER","code":"US-OH-WFZ"},
          {"name":"WINDHAM","code":"US-OH-WHM"},
          {"name":"WINESBURG","code":"US-OH-2HO"},
          {"name":"WINTERSVILLE","code":"US-OH-WQZ"},
          {"name":"WOODLAWN","code":"US-OH-WON"},
          {"name":"WOODMERE","code":"US-OH-8XD"},
          {"name":"WOODSFIELD","code":"US-OH-W5F"},
          {"name":"WOODVILLE","code":"US-OH-WV4"},
          {"name":"WOOSTER","code":"US-OH-BJJ"},
          {"name":"WORTHINGTON","code":"US-OH-WGH"},
          {"name":"WRIGHT-PATTERSON","code":"US-OH-RW5"},
          {"name":"XENIA","code":"US-OH-XOL"},
          {"name":"YELLOW SPRINGS","code":"US-OH-YS7"},
          {"name":"YORKSHIRE","code":"US-OH-YYH"},
          {"name":"YOUNGSTOWN","code":"US-OH-YNG"},
          {"name":"ZANESFIELD","code":"US-OH-8UR"},
          {"name":"ZANESVILLE","code":"US-OH-ZZV"},
        ]
      },
      {
        name: 'Oklahoma',
        code: 'US-OK',
        cities: [
          {"name":"ADA","code":"US-OK-ADT"},
          {"name":"ADAIR","code":"US-OK-2AR"},
          {"name":"AFTON","code":"US-OK-AS7"},
          {"name":"ALLEN","code":"US-OK-A3N"},
          {"name":"ALTUS","code":"US-OK-LTS"},
          {"name":"ALVA","code":"US-OK-XAK"},
          {"name":"AMES","code":"US-OK-A7K"},
          {"name":"ANADARKO","code":"US-OK-NKO"},
          {"name":"ANTLERS","code":"US-OK-ATE"},
          {"name":"APACHE","code":"US-OK-A77"},
          {"name":"ARAPAHO","code":"US-OK-OZP"},
          {"name":"ARDMORE","code":"US-OK-ADM"},
          {"name":"ARKOMA","code":"US-OK-KOM"},
          {"name":"ARNETT","code":"US-OK-OAR"},
          {"name":"ATOKA","code":"US-OK-AOD"},
          {"name":"AVARD","code":"US-OK-XBK"},
          {"name":"BALKO","code":"US-OK-QAK"},
          {"name":"BARNSDALL","code":"US-OK-UVB"},
          {"name":"BARTLESVILLE","code":"US-OK-BVO"},
          {"name":"BEAVER","code":"US-OK-BVX"},
          {"name":"BEGGS","code":"US-OK-ZGG"},
          {"name":"BESSIE","code":"US-OK-BS5"},
          {"name":"BIG CABIN","code":"US-OK-IXB"},
          {"name":"BIXBY","code":"US-OK-BXY"},
          {"name":"BLACKWELL","code":"US-OK-BWL"},
          {"name":"BLANCHARD","code":"US-OK-B8C"},
          {"name":"BLOCKER","code":"US-OK-KHR"},
          {"name":"BOISE CITY","code":"US-OK-BO7"},
          {"name":"BOSWELL","code":"US-OK-OS6"},
          {"name":"BRACKENRIDGE","code":"US-OK-RG5"},
          {"name":"BRAMAN","code":"US-OK-OB5"},
          {"name":"BRISTOW","code":"US-OK-YIW"},
          {"name":"BROKEN ARROW","code":"US-OK-BWQ"},
          {"name":"BROKEN BOW","code":"US-OK-BB3"},
          {"name":"BUFFALO","code":"US-OK-B99"},
          {"name":"BURLINGTON","code":"US-OK-JGZ"},
          {"name":"CACHE","code":"US-OK-CC7"},
          {"name":"CALUMET","code":"US-OK-UET"},
          {"name":"CAMERON","code":"US-OK-AQN"},
          {"name":"CANTON","code":"US-OK-AJN"},
          {"name":"CARMEN","code":"US-OK-QCM"},
          {"name":"CARNEGIE","code":"US-OK-NGI"},
          {"name":"CARRIER","code":"US-OK-Z2B"},
          {"name":"CARTER","code":"US-OK-TQR"},
          {"name":"CASTLE","code":"US-OK-OK7"},
          {"name":"CATOOSA","code":"US-OK-XEL"},
          {"name":"CEMENT","code":"US-OK-EZM"},
          {"name":"CHANDLER","code":"US-OK-DFH"},
          {"name":"CHATTANOOGA","code":"US-OK-C6A"},
          {"name":"CHECOTAH","code":"US-OK-C3H"},
          {"name":"CHELSEA","code":"US-OK-CS5"},
          {"name":"CHEROKEE","code":"US-OK-CKA"},
          {"name":"CHEYENNE","code":"US-OK-C3N"},
          {"name":"CHEYENNE","code":"US-OK-CY9"},
          {"name":"CHICKASHA","code":"US-OK-CHK"},
          {"name":"CHOCTAW","code":"US-OK-HW9"},
          {"name":"CHOUTEAU","code":"US-OK-C2H"},
          {"name":"CLAREMORE","code":"US-OK-LRE"},
          {"name":"CLARITA","code":"US-OK-CXE"},
          {"name":"CLEVELAND","code":"US-OK-VND"},
          {"name":"CLINTON","code":"US-OK-CSM"},
          {"name":"COALGATE","code":"US-OK-CG2"},
          {"name":"COLCORD","code":"US-OK-C9R"},
          {"name":"COLEMAN","code":"US-OK-OLZ"},
          {"name":"COLLINSVILLE","code":"US-OK-HVC"},
          {"name":"COMANCHE","code":"US-OK-CMJ"},
          {"name":"COMMERCE","code":"US-OK-OMR"},
          {"name":"COPAN","code":"US-OK-OPB"},
          {"name":"CUSHING","code":"US-OK-CUH"},
          {"name":"DAVIS","code":"US-OK-DVS"},
          {"name":"DEL CITY","code":"US-OK-D2C"},
          {"name":"DENTONVILLE","code":"US-OK-DE7"},
          {"name":"DEWEY","code":"US-OK-DWO"},
          {"name":"DILL CITY","code":"US-OK-DCY"},
          {"name":"DOVER","code":"US-OK-OER"},
          {"name":"DRUMRIGHT","code":"US-OK-DH3"},
          {"name":"DUKE","code":"US-OK-DUK"},
          {"name":"DUNCAN","code":"US-OK-DUC"},
          {"name":"DURANT","code":"US-OK-DUA"},
          {"name":"EAKLY","code":"US-OK-QEY"},
          {"name":"EDMOND","code":"US-OK-EOD"},
          {"name":"EL RENO","code":"US-OK-QEL"},
          {"name":"ELK CITY","code":"US-OK-ELK"},
          {"name":"ENID","code":"US-OK-WDG"},
          {"name":"EUFAULA","code":"US-OK-EF3"},
          {"name":"FAIRLAND","code":"US-OK-FAJ"},
          {"name":"FAIRVIEW","code":"US-OK-F2R"},
          {"name":"FANSHAWE","code":"US-OK-FS6"},
          {"name":"FARGO","code":"US-OK-6FG"},
          {"name":"FELT","code":"US-OK-FEK"},
          {"name":"FLETCHER","code":"US-OK-JFT"},
          {"name":"FORGAN","code":"US-OK-F2G"},
          {"name":"FORT GIBSON","code":"US-OK-FTS"},
          {"name":"FORT SILL","code":"US-OK-FSI"},
          {"name":"FREDERICK","code":"US-OK-FDR"},
          {"name":"GAGE","code":"US-OK-GAG"},
          {"name":"GLENPOOL","code":"US-OK-GPP"},
          {"name":"GRANITE","code":"US-OK-GTK"},
          {"name":"GROVE","code":"US-OK-GFQ"},
          {"name":"GUTHRIE","code":"US-OK-GOK"},
          {"name":"GUYMON","code":"US-OK-GUY"},
          {"name":"HAILEYVILLE","code":"US-OK-HLV"},
          {"name":"HAMMON","code":"US-OK-HA6"},
          {"name":"HARRAH","code":"US-OK-H6A"},
          {"name":"HARTSHORNE","code":"US-OK-YHT"},
          {"name":"HASKELL","code":"US-OK-HAG"},
          {"name":"HEALDTON","code":"US-OK-HAD"},
          {"name":"HEAVENER","code":"US-OK-H4R"},
          {"name":"HELENA","code":"US-OK-HZN"},
          {"name":"HENNESSEY","code":"US-OK-HY7"},
          {"name":"HENRYETTA","code":"US-OK-HYO"},
          {"name":"HINTON","code":"US-OK-HTA"},
          {"name":"HOBART","code":"US-OK-HBR"},
          {"name":"HOLDENVILLE","code":"US-OK-HO4"},
          {"name":"HOOKER","code":"US-OK-QHE"},
          {"name":"HOWE","code":"US-OK-HWB"},
          {"name":"HUGO","code":"US-OK-HUG"},
          {"name":"HULBERT","code":"US-OK-HBT"},
          {"name":"IDABEL","code":"US-OK-QIL"},
          {"name":"INDIAHOMA","code":"US-OK-I2D"},
          {"name":"INOLA","code":"US-OK-IO8"},
          {"name":"JAY","code":"US-OK-JYO"},
          {"name":"JENKS","code":"US-OK-JUK"},
          {"name":"JONES","code":"US-OK-JNE"},
          {"name":"KANSAS","code":"US-OK-KA3"},
          {"name":"KELLYVILLE","code":"US-OK-EYE"},
          {"name":"KEOTA","code":"US-OK-KTZ"},
          {"name":"KETCHUM","code":"US-OK-KHU"},
          {"name":"KEYES","code":"US-OK-KY4"},
          {"name":"KIEFER","code":"US-OK-KFE"},
          {"name":"KINGFISHER","code":"US-OK-KIF"},
          {"name":"KIOWA","code":"US-OK-KWA"},
          {"name":"KONAWA","code":"US-OK-KW2"},
          {"name":"KREBS","code":"US-OK-KRE"},
          {"name":"KREMLIN","code":"US-OK-ZOX"},
          {"name":"LAHOMA","code":"US-OK-LHD"},
          {"name":"LAVERNE","code":"US-OK-VFE"},
          {"name":"LAWTON","code":"US-OK-LAW"},
          {"name":"LEBANON","code":"US-OK-XZO"},
          {"name":"LEEDEY","code":"US-OK-5FQ"},
          {"name":"LEHIGH","code":"US-OK-KK8"},
          {"name":"LINDSAY","code":"US-OK-IDS"},
          {"name":"LOCUST GROVE","code":"US-OK-XHN"},
          {"name":"LUTHER","code":"US-OK-LU4"},
          {"name":"MACOMB","code":"US-OK-MB4"},
          {"name":"MADILL","code":"US-OK-MIL"},
          {"name":"MANCHESTER","code":"US-OK-UMZ"},
          {"name":"MANNFORD","code":"US-OK-YNF"},
          {"name":"MARBLE CITY","code":"US-OK-M4C"},
          {"name":"MARIETTA","code":"US-OK-MIR"},
          {"name":"MARLOW","code":"US-OK-AOW"},
          {"name":"MARSHALL","code":"US-OK-XRH"},
          {"name":"MAXWELL","code":"US-OK-MWU"},
          {"name":"MAYSVILLE","code":"US-OK-YSL"},
          {"name":"MCALESTER","code":"US-OK-MLC"},
          {"name":"MEAD","code":"US-OK-M2E"},
          {"name":"MEDFORD","code":"US-OK-3MD"},
          {"name":"MEEKER","code":"US-OK-MK3"},
          {"name":"MENO","code":"US-OK-ZME"},
          {"name":"MIAMI","code":"US-OK-MIO"},
          {"name":"MIDWEST CITY","code":"US-OK-IDW"},
          {"name":"MILBURN","code":"US-OK-M2B"},
          {"name":"MILL CREEK","code":"US-OK-ZMK"},
          {"name":"MINCO","code":"US-OK-NC7"},
          {"name":"MOFFETT","code":"US-OK-FFJ"},
          {"name":"MOORE","code":"US-OK-OOF"},
          {"name":"MOORELAND","code":"US-OK-OED"},
          {"name":"MOUNDS","code":"US-OK-OUK"},
          {"name":"MULDROW","code":"US-OK-ZMW"},
          {"name":"MUSKOGEE","code":"US-OK-MKO"},
          {"name":"MUSTANG","code":"US-OK-UMU"},
          {"name":"NEWCASTLE","code":"US-OK-NC6"},
          {"name":"NEWKIRK","code":"US-OK-9KI"},
          {"name":"NICHOLS HILLS","code":"US-OK-NH6"},
          {"name":"NINNEKAH","code":"US-OK-NN3"},
          {"name":"NOBLE","code":"US-OK-OBL"},
          {"name":"NORMAN","code":"US-OK-OUN"},
          {"name":"NOWATA","code":"US-OK-NTK"},
          {"name":"OCHELATA","code":"US-OK-C7H"},
          {"name":"OILTON","code":"US-OK-IUN"},
          {"name":"OKARCHE","code":"US-OK-ZHE"},
          {"name":"OKEENE","code":"US-OK-OKN"},
          {"name":"OKEMAH","code":"US-OK-OMJ"},
          {"name":"OKLAHOMA CITY","code":"US-OK-OKC"},
          {"name":"OKMULGEE","code":"US-OK-OKM"},
          {"name":"OOLOGAH","code":"US-OK-OO3"},
          {"name":"OWASSO","code":"US-OK-OW3"},
          {"name":"PANAMA","code":"US-OK-5PO"},
          {"name":"PAOLI","code":"US-OK-VPI"},
          {"name":"PARK HILL","code":"US-OK-PH6"},
          {"name":"PAULS VALLEY","code":"US-OK-PVJ"},
          {"name":"PAWHUSKA","code":"US-OK-PWH"},
          {"name":"PAWNEE","code":"US-OK-QAW"},
          {"name":"PERKINS","code":"US-OK-PR3"},
          {"name":"PERRY","code":"US-OK-PRR"},
          {"name":"PICHER","code":"US-OK-P3R"},
          {"name":"POCOLA","code":"US-OK-PCL"},
          {"name":"PONCA CITY","code":"US-OK-PNC"},
          {"name":"POTEAU","code":"US-OK-RKR"},
          {"name":"PRAGUE","code":"US-OK-OUP"},
          {"name":"PRYOR","code":"US-OK-PYR"},
          {"name":"PURCELL","code":"US-OK-PUZ"},
          {"name":"QUAPAW","code":"US-OK-QP2"},
          {"name":"QUINTON","code":"US-OK-QUN"},
          {"name":"RAMONA","code":"US-OK-RXM"},
          {"name":"RATLIFF CITY","code":"US-OK-RTY"},
          {"name":"RATTAN","code":"US-OK-RFA"},
          {"name":"RED ROCK","code":"US-OK-R2K"},
          {"name":"RINGOLD","code":"US-OK-R3G"},
          {"name":"RINGWOOD","code":"US-OK-R3W"},
          {"name":"ROFF","code":"US-OK-RFF"},
          {"name":"ROLAND","code":"US-OK-OLK"},
          {"name":"ROME","code":"US-OK-RQM"},
          {"name":"ROSSTON","code":"US-OK-YVS"},
          {"name":"RUSH SPRINGS","code":"US-OK-RHP"},
          {"name":"RYAN","code":"US-OK-RYH"},
          {"name":"SAINT LOUIS","code":"US-OK-OK9"},
          {"name":"SALLISAW","code":"US-OK-SLA"},
          {"name":"SAND SPRINGS","code":"US-OK-SDQ"},
          {"name":"SAPULPA","code":"US-OK-APU"},
          {"name":"SAWYER","code":"US-OK-ZFF"},
          {"name":"SAYRE","code":"US-OK-SY2"},
          {"name":"SEILING","code":"US-OK-O8K"},
          {"name":"SEMINOLE","code":"US-OK-ZMD"},
          {"name":"SHADY POINT","code":"US-OK-IYP"},
          {"name":"SHANGRI-LA","code":"US-OK-NRI"},
          {"name":"SHATTUCK","code":"US-OK-SUK"},
          {"name":"SHAWNEE","code":"US-OK-SNL"},
          {"name":"SHIDLER","code":"US-OK-IDK"},
          {"name":"SKIATOOK","code":"US-OK-TKQ"},
          {"name":"SNYDER","code":"US-OK-NYD"},
          {"name":"SOUTHARD","code":"US-OK-SSF"},
          {"name":"SPERRY","code":"US-OK-EHY"},
          {"name":"STIGLER","code":"US-OK-VVR"},
          {"name":"STILLWATER","code":"US-OK-SWO"},
          {"name":"STILWELL","code":"US-OK-QSL"},
          {"name":"STRATFORD","code":"US-OK-ZTT"},
          {"name":"STRINGTOWN","code":"US-OK-SG6"},
          {"name":"STROUD","code":"US-OK-SUD"},
          {"name":"SULPHUR","code":"US-OK-LPU"},
          {"name":"SWEETWATER","code":"US-OK-WW8"},
          {"name":"TAHLEQUAH","code":"US-OK-TGU"},
          {"name":"TALIHINA","code":"US-OK-TA2"},
          {"name":"TECUMSEH","code":"US-OK-TCE"},
          {"name":"TEXHOMA","code":"US-OK-T2X"},
          {"name":"THACKERVILLE","code":"US-OK-QVL"},
          {"name":"THOMAS","code":"US-OK-TXM"},
          {"name":"TINKER AIR FORCE BASE","code":"US-OK-YTB"},
          {"name":"TISHOMINGO","code":"US-OK-TSI"},
          {"name":"TONKAWA","code":"US-OK-TOK"},
          {"name":"TULSA","code":"US-OK-TUL"},
          {"name":"TUTTLE","code":"US-OK-TT8"},
          {"name":"VALLIANT","code":"US-OK-VI4"},
          {"name":"VELMA","code":"US-OK-VEB"},
          {"name":"VICI","code":"US-OK-VJC"},
          {"name":"VINITA","code":"US-OK-VIA"},
          {"name":"WAGONER","code":"US-OK-WGE"},
          {"name":"WALTERS","code":"US-OK-RRR"},
          {"name":"WANETTE","code":"US-OK-9WT"},
          {"name":"WATONGA","code":"US-OK-YWQ"},
          {"name":"WAURIKA","code":"US-OK-WUY"},
          {"name":"WAYNE","code":"US-OK-WYE"},
          {"name":"WAYNOKA","code":"US-OK-W3K"},
          {"name":"WEATHERFORD","code":"US-OK-WYA"},
          {"name":"WELCH","code":"US-OK-OWC"},
          {"name":"WESTVILLE","code":"US-OK-ZIY"},
          {"name":"WEWOKA","code":"US-OK-WUE"},
          {"name":"WILBURTON","code":"US-OK-XNN"},
          {"name":"WILLOW","code":"US-OK-WXL"},
          {"name":"WISTER","code":"US-OK-JTR"},
          {"name":"WOODWARD","code":"US-OK-WWR"},
          {"name":"WYNNEWOOD","code":"US-OK-W9W"},
          {"name":"YALE","code":"US-OK-IYA"},
          {"name":"YUKON","code":"US-OK-YKO"},
        ]
      },
      {
        name: 'Oregón',
        code: 'US-OR',
        cities: [
          {"name":"ADAMS","code":"US-OR-UAT"},
          {"name":"ADRIAN","code":"US-OR-UAO"},
          {"name":"ALBANY","code":"US-OR-AYK"},
          {"name":"ALBANY-CORVALLIS APT","code":"US-OR-CVO"},
          {"name":"ALOHA","code":"US-OR-AHA"},
          {"name":"AMITY","code":"US-OR-AMJ"},
          {"name":"APPLEGATE","code":"US-OR-YPL"},
          {"name":"ARLINGTON","code":"US-OR-ARF"},
          {"name":"ASHLAND","code":"US-OR-ASI"},
          {"name":"ASTORIA","code":"US-OR-AST"},
          {"name":"ATHENA","code":"US-OR-THN"},
          {"name":"AUMSVILLE","code":"US-OR-USV"},
          {"name":"AURORA","code":"US-OR-AUX"},
          {"name":"BAKER","code":"US-OR-BKE"},
          {"name":"BAKER CITY","code":"US-OR-Y9K"},
          {"name":"BANDON","code":"US-OR-BDY"},
          {"name":"BANKS","code":"US-OR-NKS"},
          {"name":"BAY CITY","code":"US-OR-YIY"},
          {"name":"BEAVER","code":"US-OR-EER"},
          {"name":"BEAVER CREEK","code":"US-OR-BE7"},
          {"name":"BEAVER CREEK","code":"US-OR-QBJ"},
          {"name":"BEAVERTON","code":"US-OR-BVW"},
          {"name":"BEND","code":"US-OR-BZO"},
          {"name":"BEND-REDMOND APT","code":"US-OR-RDM"},
          {"name":"BETHANY","code":"US-OR-BHH"},
          {"name":"BLACHLY","code":"US-OR-BC5"},
          {"name":"BOARDMAN","code":"US-OR-BDA"},
          {"name":"BONANZA","code":"US-OR-78B"},
          {"name":"BORING","code":"US-OR-BHG"},
          {"name":"BRADWOOD","code":"US-OR-BRA"},
          {"name":"BRIGHTWOOD","code":"US-OR-BHW"},
          {"name":"BROOKINGS","code":"US-OR-BOK"},
          {"name":"BROOKS","code":"US-OR-BRS"},
          {"name":"BROWNSVILLE","code":"US-OR-B7W"},
          {"name":"BURLINGTON","code":"US-OR-BGV"},
          {"name":"BURNS","code":"US-OR-BNO"},
          {"name":"CAMAS VALLEY","code":"US-OR-CV5"},
          {"name":"CANBY","code":"US-OR-CAB"},
          {"name":"CANNON BEACH","code":"US-OR-CBX"},
          {"name":"CANYONVILLE","code":"US-OR-YLL"},
          {"name":"CAPE BLANCO","code":"US-OR-PB7"},
          {"name":"CARLTON","code":"US-OR-AOG"},
          {"name":"CARVER","code":"US-OR-CXB"},
          {"name":"CASCADE LOCKS","code":"US-OR-CZK"},
          {"name":"CAVE JUNCTION","code":"US-OR-CWJ"},
          {"name":"CEDAR HILLS","code":"US-OR-CDJ"},
          {"name":"CENTRAL POINT","code":"US-OR-YCP"},
          {"name":"CHARLESTON","code":"US-OR-CJT"},
          {"name":"CHESHIRE","code":"US-OR-C29"},
          {"name":"CHILOQUIN","code":"US-OR-CHZ"},
          {"name":"CHRISTMAS VALLEY","code":"US-OR-6CV"},
          {"name":"CLACKAMAS","code":"US-OR-CQX"},
          {"name":"CLATSKANIE","code":"US-OR-YCK"},
          {"name":"CLOVERDALE","code":"US-OR-CD8"},
          {"name":"COBURG","code":"US-OR-CUR"},
          {"name":"COLTON","code":"US-OR-ZXX"},
          {"name":"COLUMBIA CITY","code":"US-OR-8OL"},
          {"name":"COOS BAY","code":"US-OR-COB"},
          {"name":"COQUILLE","code":"US-OR-OQL"},
          {"name":"CORNELIUS","code":"US-OR-CZG"},
          {"name":"CORNELIUS PASS","code":"US-OR-CZH"},
          {"name":"CORVALLIS","code":"US-OR-CVL"},
          {"name":"CORVALLIS-ALBANY APT","code":"US-OR-CVO"},
          {"name":"COTTAGE GROVE","code":"US-OR-TGG"},
          {"name":"COVE","code":"US-OR-KOV"},
          {"name":"CRESCENT LAKE","code":"US-OR-3CL"},
          {"name":"CRESWELL","code":"US-OR-ERX"},
          {"name":"CULVER","code":"US-OR-CV8"},
          {"name":"DAIRY","code":"US-OR-D8R"},
          {"name":"DALLAS","code":"US-OR-DAE"},
          {"name":"DAMASCUS","code":"US-OR-DSC"},
          {"name":"DAYS CREEK","code":"US-OR-DYC"},
          {"name":"DAYTON","code":"US-OR-XET"},
          {"name":"DAYVILLE","code":"US-OR-IYV"},
          {"name":"DEER ISLAND","code":"US-OR-JDS"},
          {"name":"DONALD","code":"US-OR-DXO"},
          {"name":"DRAIN","code":"US-OR-DNR"},
          {"name":"DUFUR","code":"US-OR-DU2"},
          {"name":"DUNDEE","code":"US-OR-UEZ"},
          {"name":"DURHAM","code":"US-OR-DHH"},
          {"name":"DURKEE","code":"US-OR-UEE"},
          {"name":"EAGLE CREEK","code":"US-OR-E3C"},
          {"name":"EAGLE POINT","code":"US-OR-E3P"},
          {"name":"ELKTON","code":"US-OR-EL8"},
          {"name":"ENTERPRISE","code":"US-OR-ENP"},
          {"name":"ESTACADA","code":"US-OR-EXQ"},
          {"name":"EUGENE","code":"US-OR-EUG"},
          {"name":"FAIRVIEW","code":"US-OR-FVI"},
          {"name":"FALL CREEK","code":"US-OR-FGK"},
          {"name":"FIELDS","code":"US-OR-FS5"},
          {"name":"FLORENCE","code":"US-OR-FQE"},
          {"name":"FOREST GROVE","code":"US-OR-FGV"},
          {"name":"FORT KLAMATH","code":"US-OR-ZKH"},
          {"name":"FORT ROCK","code":"US-OR-F5R"},
          {"name":"GARDINER","code":"US-OR-GDR"},
          {"name":"GASTON","code":"US-OR-GSX"},
          {"name":"GERVAIS","code":"US-OR-GE9"},
          {"name":"GLADSTONE","code":"US-OR-GTU"},
          {"name":"GLENDALE","code":"US-OR-OR8"},
          {"name":"GOLD BEACH","code":"US-OR-GOL"},
          {"name":"GOLD HILL","code":"US-OR-GH3"},
          {"name":"GOVERNMENT CAMP","code":"US-OR-G5C"},
          {"name":"GRAND RONDE","code":"US-OR-6R3"},
          {"name":"GRANDE RONDE","code":"US-OR-G9A"},
          {"name":"GRANTS PASS","code":"US-OR-XFX"},
          {"name":"GRASS VALLEY","code":"US-OR-GY6"},
          {"name":"GRESHAM","code":"US-OR-GHO"},
          {"name":"GUILD'S LAKE","code":"US-OR-G5L"},
          {"name":"HALSEY","code":"US-OR-HZO"},
          {"name":"HAMBURG","code":"US-OR-ZH8"},
          {"name":"HAMMOND","code":"US-OR-HXB"},
          {"name":"HAPPY VALLEY","code":"US-OR-HQV"},
          {"name":"HARBOR","code":"US-OR-HOI"},
          {"name":"HARRISBURG","code":"US-OR-HB3"},
          {"name":"HERMISTON","code":"US-OR-HES"},
          {"name":"HILLSBORO","code":"US-OR-HIO"},
          {"name":"HINKLE","code":"US-OR-XGI"},
          {"name":"HOOD RIVER","code":"US-OR-HDX"},
          {"name":"HUBBARD","code":"US-OR-HBI"},
          {"name":"IDANHA","code":"US-OR-IHA"},
          {"name":"IMBLER","code":"US-OR-IM3"},
          {"name":"INDEPENDENCE","code":"US-OR-IDE"},
          {"name":"IRRIGON","code":"US-OR-IRR"},
          {"name":"JACKSONVILLE","code":"US-OR-J3C"},
          {"name":"JASPER","code":"US-OR-JAB"},
          {"name":"JEFFERSON","code":"US-OR-WY3"},
          {"name":"JEWELL","code":"US-OR-JW3"},
          {"name":"JOHN DAY","code":"US-OR-JDA"},
          {"name":"JOHNSON CITY","code":"US-OR-JOC"},
          {"name":"JUNCTION CITY","code":"US-OR-JUN"},
          {"name":"KEIZER","code":"US-OR-KZR"},
          {"name":"KELLOGG","code":"US-OR-KLO"},
          {"name":"KENO","code":"US-OR-KXO"},
          {"name":"KENT","code":"US-OR-KJT"},
          {"name":"KENTON","code":"US-OR-KYO"},
          {"name":"KERBY","code":"US-OR-KRY"},
          {"name":"KING CITY","code":"US-OR-KCY"},
          {"name":"KLAMATH FALLS","code":"US-OR-LMT"},
          {"name":"LA GRANDE","code":"US-OR-LGD"},
          {"name":"LA PINE","code":"US-OR-LPH"},
          {"name":"LAFAYETTE","code":"US-OR-YFQ"},
          {"name":"LAKE OSWEGO","code":"US-OR-LOO"},
          {"name":"LAKEVIEW","code":"US-OR-LKV"},
          {"name":"LEBANON","code":"US-OR-LON"},
          {"name":"LEXINGTON","code":"US-OR-XON"},
          {"name":"LINCOLN CITY","code":"US-OR-YOC"},
          {"name":"LINNTON","code":"US-OR-LN5"},
          {"name":"LOWELL","code":"US-OR-LW6"},
          {"name":"LYONS","code":"US-OR-LZY"},
          {"name":"MADISON-MADRAS APT","code":"US-OR-MDJ"},
          {"name":"MADRAS","code":"US-OR-MDM"},
          {"name":"MADRAS-MADISON APT","code":"US-OR-MDJ"},
          {"name":"MALIN","code":"US-OR-MLN"},
          {"name":"MAPLETON","code":"US-OR-M4P"},
          {"name":"MAYWOOD PARK","code":"US-OR-MZK"},
          {"name":"MCMINNVILLE","code":"US-OR-MMC"},
          {"name":"MCNARY","code":"US-OR-MRX"},
          {"name":"MEDFORD","code":"US-OR-MFR"},
          {"name":"MERRILL","code":"US-OR-VMR"},
          {"name":"METOLIUS","code":"US-OR-2MT"},
          {"name":"METZGER","code":"US-OR-MTY"},
          {"name":"MILTON-FREEWATER","code":"US-OR-MZH"},
          {"name":"MILWAUKIE","code":"US-OR-MWX"},
          {"name":"MOLALLA","code":"US-OR-MOL"},
          {"name":"MONMOUTH","code":"US-OR-OO9"},
          {"name":"MONROE","code":"US-OR-OVN"},
          {"name":"MORO","code":"US-OR-XOO"},
          {"name":"MOSIER","code":"US-OR-YMR"},
          {"name":"MOUNT ANGEL","code":"US-OR-OMO"},
          {"name":"MULINO","code":"US-OR-MU7"},
          {"name":"MURPHY","code":"US-OR-URP"},
          {"name":"MYRTLE CREEK","code":"US-OR-YTK"},
          {"name":"MYRTLE POINT","code":"US-OR-MFG"},
          {"name":"NEWBERG","code":"US-OR-NUG"},
          {"name":"NEWPORT","code":"US-OR-NPO"},
          {"name":"NORTH BEND","code":"US-OR-OTH"},
          {"name":"NORTH PLAINS","code":"US-OR-NPY"},
          {"name":"NORTH POWDER","code":"US-OR-NDH"},
          {"name":"NOTI","code":"US-OR-TXQ"},
          {"name":"NYSSA","code":"US-OR-NSZ"},
          {"name":"OAK GROVE","code":"US-OR-OGR"},
          {"name":"OAKLAND","code":"US-OR-KND"},
          {"name":"OAKRIDGE","code":"US-OR-OQR"},
          {"name":"ODELL","code":"US-OR-ODE"},
          {"name":"ONTARIO","code":"US-OR-ONO"},
          {"name":"OREGON CITY","code":"US-OR-ORC"},
          {"name":"PACIFIC CITY","code":"US-OR-PFC"},
          {"name":"PARKROSE","code":"US-OR-PKZ"},
          {"name":"PENDLETON","code":"US-OR-PDT"},
          {"name":"PHILOMATH","code":"US-OR-YPH"},
          {"name":"PHOENIX","code":"US-OR-ONX"},
          {"name":"PLEASANT HILL","code":"US-OR-PL7"},
          {"name":"PORT ORFORD","code":"US-OR-PO2"},
          {"name":"PORTLAND","code":"US-OR-PDX"},
          {"name":"PRAIRIE CITY","code":"US-OR-IZY"},
          {"name":"PRESCOTT","code":"US-OR-RSC"},
          {"name":"PRINEVILLE","code":"US-OR-PRZ"},
          {"name":"PROSPECT","code":"US-OR-XKR"},
          {"name":"RAINIER","code":"US-OR-RAI"},
          {"name":"REDMOND","code":"US-OR-RMD"},
          {"name":"REDMOND-BEND APT","code":"US-OR-RDM"},
          {"name":"RICHLAND","code":"US-OR-IHL"},
          {"name":"RICKREALL","code":"US-OR-ZIC"},
          {"name":"RIDDLE","code":"US-OR-RIR"},
          {"name":"RIVERGATE","code":"US-OR-RGJ"},
          {"name":"ROCKWOOD","code":"US-OR-RQO"},
          {"name":"ROGUE RIVER","code":"US-OR-RGU"},
          {"name":"ROME","code":"US-OR-REO"},
          {"name":"ROSEBURG","code":"US-OR-RBG"},
          {"name":"SAGINAW","code":"US-OR-AGA"},
          {"name":"SAINT HELENS","code":"US-OR-STH"},
          {"name":"SAINT PAUL","code":"US-OR-NPU"},
          {"name":"SALEM","code":"US-OR-SLE"},
          {"name":"SANDY","code":"US-OR-SJD"},
          {"name":"SCAPPOOSE","code":"US-OR-SCA"},
          {"name":"SCHOLLS","code":"US-OR-SZC"},
          {"name":"SCIO","code":"US-OR-S44"},
          {"name":"SCOTT MILLS","code":"US-OR-S7M"},
          {"name":"SEAL ROCK","code":"US-OR-ZRK"},
          {"name":"SEASIDE","code":"US-OR-YSE"},
          {"name":"SELMA","code":"US-OR-OSM"},
          {"name":"SHEDD","code":"US-OR-YSD"},
          {"name":"SHERIDAN","code":"US-OR-SJG"},
          {"name":"SHERWOOD","code":"US-OR-SJU"},
          {"name":"SHOREWOOD","code":"US-OR-SJV"},
          {"name":"SILVERTON","code":"US-OR-XVN"},
          {"name":"SISTERS","code":"US-OR-YER"},
          {"name":"SIXES","code":"US-OR-KSX"},
          {"name":"SOUTH BEACH","code":"US-OR-BS6"},
          {"name":"SPRINGFIELD","code":"US-OR-SPY"},
          {"name":"STANFIELD","code":"US-OR-SF8"},
          {"name":"STAYTON","code":"US-OR-SAY"},
          {"name":"SUBLIMITY","code":"US-OR-SUZ"},
          {"name":"SUN RIVER","code":"US-OR-SUO"},
          {"name":"SUTHERLIN","code":"US-OR-SLD"},
          {"name":"SWEET HOME","code":"US-OR-HOE"},
          {"name":"TALENT","code":"US-OR-YTL"},
          {"name":"TANGENT","code":"US-OR-ZTG"},
          {"name":"TERREBONNE","code":"US-OR-NJQ"},
          {"name":"THE DALLES","code":"US-OR-DLS"},
          {"name":"TIGARD","code":"US-OR-TID"},
          {"name":"TILLAMOOK","code":"US-OR-TLM"},
          {"name":"TOLEDO","code":"US-OR-TDX"},
          {"name":"TROUTDALE","code":"US-OR-TTD"},
          {"name":"TUALATIN","code":"US-OR-TWL"},
          {"name":"TULSA","code":"US-OR-RVS"},
          {"name":"TURNER","code":"US-OR-YTU"},
          {"name":"UMATILLA","code":"US-OR-UMA"},
          {"name":"UMPQUA","code":"US-OR-9QA"},
          {"name":"VALE","code":"US-OR-YVA"},
          {"name":"VENETA","code":"US-OR-VE7"},
          {"name":"VERNONIA","code":"US-OR-V5N"},
          {"name":"WALTERVILLE","code":"US-OR-2WL"},
          {"name":"WARM SPRINGS","code":"US-OR-RMR"},
          {"name":"WARREN","code":"US-OR-VWR"},
          {"name":"WARRENTON","code":"US-OR-WTO"},
          {"name":"WASCO","code":"US-OR-WC7"},
          {"name":"WAUNA","code":"US-OR-WAU"},
          {"name":"WAUNA RANGE","code":"US-OR-WQU"},
          {"name":"WEST LINN","code":"US-OR-WJL"},
          {"name":"WEST UNION","code":"US-OR-WUQ"},
          {"name":"WESTON","code":"US-OR-XOB"},
          {"name":"WHITE CITY","code":"US-OR-WCY"},
          {"name":"WILLAMETTE CITY","code":"US-OR-WIR"},
          {"name":"WILLIAMS","code":"US-OR-WL7"},
          {"name":"WILSONVILLE","code":"US-OR-WJX"},
          {"name":"WINSTON","code":"US-OR-WQT"},
          {"name":"WOOD VILLAGE","code":"US-OR-WUV"},
          {"name":"WOODBURN","code":"US-OR-WBJ"},
          {"name":"YACHATS","code":"US-OR-Y9O"},
          {"name":"YAMHILL","code":"US-OR-YHJ"},
        ]
      },
      {
        name: 'Pensilvania',
        code: 'US-PA',
        cities: [
          {"name":"AARONSBURG","code":"US-PA-AA7"},
          {"name":"ABBOTTSTOWN","code":"US-PA-AQW"},
          {"name":"ABINGTON","code":"US-PA-AP3"},
          {"name":"ADAMSTOWN","code":"US-PA-AEJ"},
          {"name":"ADDISON","code":"US-PA-QDS"},
          {"name":"ADRIAN","code":"US-PA-AD9"},
          {"name":"AKRON","code":"US-PA-ARN"},
          {"name":"ALBION (ERIE COUNTY)","code":"US-PA-AB4"},
          {"name":"ALBRIGHTSVILLE","code":"US-PA-AB6"},
          {"name":"ALBURTIS","code":"US-PA-ALU"},
          {"name":"ALDAN","code":"US-PA-2AD"},
          {"name":"ALEXANDRIA","code":"US-PA-AXA"},
          {"name":"ALIQUIPPA","code":"US-PA-AQP"},
          {"name":"ALLEGHENY","code":"US-PA-UBP"},
          {"name":"ALLEGHENY COUNTY APT/PITTSBURGH","code":"US-PA-AG3"},
          {"name":"ALLENPORT","code":"US-PA-AJP"},
          {"name":"ALLENSVILLE","code":"US-PA-A4V"},
          {"name":"ALLENTOWN","code":"US-PA-AWN"},
          {"name":"ALLENWOOD","code":"US-PA-AZW"},
          {"name":"ALLISON PARK","code":"US-PA-ALK"},
          {"name":"ALTOONA","code":"US-PA-AOO"},
          {"name":"ALUM BANK","code":"US-PA-QAB"},
          {"name":"AMBLER","code":"US-PA-AMR"},
          {"name":"AMBRIDGE","code":"US-PA-UBF"},
          {"name":"ANALOMINK","code":"US-PA-A56"},
          {"name":"ANDREAS","code":"US-PA-DGQ"},
          {"name":"ANNVILLE","code":"US-PA-AIL"},
          {"name":"APOLLO","code":"US-PA-ZAP"},
          {"name":"ARCHBALD","code":"US-PA-AHB"},
          {"name":"ARDMORE","code":"US-PA-DM6"},
          {"name":"ARISTES","code":"US-PA-A3R"},
          {"name":"ARMAGH","code":"US-PA-AH9"},
          {"name":"ARNOLD","code":"US-PA-AQL"},
          {"name":"ASHLAND","code":"US-PA-XBH"},
          {"name":"ASHLEY","code":"US-PA-AHY"},
          {"name":"ASHVILLE","code":"US-PA-A22"},
          {"name":"ASPERS","code":"US-PA-UBR"},
          {"name":"ASTON","code":"US-PA-XBF"},
          {"name":"ATGLEN","code":"US-PA-AGL"},
          {"name":"ATHENS","code":"US-PA-ATN"},
          {"name":"ATLANTIC","code":"US-PA-AC6"},
          {"name":"ATLASBURG","code":"US-PA-ABW"},
          {"name":"AUBURN","code":"US-PA-5AU"},
          {"name":"AUDUBON","code":"US-PA-AAQ"},
          {"name":"AULTMAN","code":"US-PA-UTM"},
          {"name":"AVOCA","code":"US-PA-AOC"},
          {"name":"AVONDALE","code":"US-PA-AFB"},
          {"name":"AVONMORE","code":"US-PA-AVM"},
          {"name":"BADEN","code":"US-PA-BAE"},
          {"name":"BAGDAD","code":"US-PA-BAG"},
          {"name":"BAINBRIDGE","code":"US-PA-B9G"},
          {"name":"BAKERSTOWN","code":"US-PA-BKP"},
          {"name":"BALA-CYNWYD","code":"US-PA-ZBC"},
          {"name":"BALLY","code":"US-PA-BLY"},
          {"name":"BANGOR","code":"US-PA-QGR"},
          {"name":"BARKEYVILLE","code":"US-PA-VQL"},
          {"name":"BARNESVILLE","code":"US-PA-BV3"},
          {"name":"BARREE","code":"US-PA-BJA"},
          {"name":"BARTO","code":"US-PA-JBA"},
          {"name":"BARTONSVILLE","code":"US-PA-PE8"},
          {"name":"BATH","code":"US-PA-BQH"},
          {"name":"BEAVER","code":"US-PA-BEB"},
          {"name":"BEAVER FALLS","code":"US-PA-BFP"},
          {"name":"BEAVERTOWN","code":"US-PA-VRW"},
          {"name":"BEDFORD","code":"US-PA-XBZ"},
          {"name":"BEDMINSTER","code":"US-PA-DFT"},
          {"name":"BEECH CREEK","code":"US-PA-EEH"},
          {"name":"BELDEN","code":"US-PA-IZP"},
          {"name":"BELLE VERNON","code":"US-PA-BVN"},
          {"name":"BELLEFONTE","code":"US-PA-ZBF"},
          {"name":"BELLEFONTE-CLEARFIELD APT","code":"US-PA-PSB"},
          {"name":"BELLEVILLE","code":"US-PA-BLQ"},
          {"name":"BELLEVUE","code":"US-PA-EVU"},
          {"name":"BELLWOOD","code":"US-PA-EWO"},
          {"name":"BELMONT","code":"US-PA-ZBM"},
          {"name":"BENSALEM","code":"US-PA-BNM"},
          {"name":"BENTLEYVILLE","code":"US-PA-VYL"},
          {"name":"BENTON","code":"US-PA-BT8"},
          {"name":"BERLIN","code":"US-PA-BR7"},
          {"name":"BERNVILLE","code":"US-PA-OBV"},
          {"name":"BERRYSBURG","code":"US-PA-XGG"},
          {"name":"BERWICK","code":"US-PA-XDH"},
          {"name":"BERWYN","code":"US-PA-BEW"},
          {"name":"BESSEMER","code":"US-PA-QBS"},
          {"name":"BETHANY","code":"US-PA-7YH"},
          {"name":"BETHAYRES","code":"US-PA-AFX"},
          {"name":"BETHEL","code":"US-PA-BTG"},
          {"name":"BETHEL PARK","code":"US-PA-TKP"},
          {"name":"BETHLEHEM","code":"US-PA-BEX"},
          {"name":"BIGLER","code":"US-PA-BI5"},
          {"name":"BIGLERVILLE","code":"US-PA-BIP"},
          {"name":"BIRD IN HAND","code":"US-PA-XCG"},
          {"name":"BIRDSBORO","code":"US-PA-BIB"},
          {"name":"BIRMINGHAM","code":"US-PA-IBJ"},
          {"name":"BLAIN","code":"US-PA-JIB"},
          {"name":"BLAIRSVILLE","code":"US-PA-BSI"},
          {"name":"BLAKESLEE","code":"US-PA-BJZ"},
          {"name":"BLANDON","code":"US-PA-BDN"},
          {"name":"BLAWNOX","code":"US-PA-LXW"},
          {"name":"BLOOMSBURG","code":"US-PA-BSB"},
          {"name":"BLOSSBURG","code":"US-PA-URG"},
          {"name":"BLUE BALL","code":"US-PA-BB5"},
          {"name":"BLUE BALL","code":"US-PA-QAL"},
          {"name":"BLUE BELL","code":"US-PA-BBX"},
          {"name":"BLUE RIDGE SUMMIT","code":"US-PA-BJP"},
          {"name":"BOOTHWYN","code":"US-PA-XDA"},
          {"name":"BOSTON","code":"US-PA-ZHN"},
          {"name":"BOSWELL","code":"US-PA-PBS"},
          {"name":"BOWMANSTOWN","code":"US-PA-BMW"},
          {"name":"BOWMANSVILLE","code":"US-PA-BWV"},
          {"name":"BOYERS","code":"US-PA-BY3"},
          {"name":"BOYERTOWN","code":"US-PA-BOE"},
          {"name":"BRACKENRIDGE","code":"US-PA-BX2"},
          {"name":"BRADDOCK","code":"US-PA-BDD"},
          {"name":"BRADFORD","code":"US-PA-BFD"},
          {"name":"BRANDONVILLE","code":"US-PA-B9P"},
          {"name":"BRAVE","code":"US-PA-6RA"},
          {"name":"BREEZEWOOD","code":"US-PA-ZWB"},
          {"name":"BREINIGSVILLE","code":"US-PA-BVI"},
          {"name":"BRIDESBURG","code":"US-PA-XCE"},
          {"name":"BRIDGEBURG","code":"US-PA-BJB"},
          {"name":"BRIDGEPORT","code":"US-PA-XCS"},
          {"name":"BRIDGEVILLE","code":"US-PA-XCT"},
          {"name":"BRIER HILL","code":"US-PA-ERH"},
          {"name":"BRIGHTON HEIGHTS","code":"US-PA-BTO"},
          {"name":"BRISTOL","code":"US-PA-UAC"},
          {"name":"BROCKPORT","code":"US-PA-BK7"},
          {"name":"BROCKWAY","code":"US-PA-BKY"},
          {"name":"BROGUE","code":"US-PA-OUE"},
          {"name":"BROOKHAVEN","code":"US-PA-KHA"},
          {"name":"BROOKVILLE","code":"US-PA-BVP"},
          {"name":"BROOMALL","code":"US-PA-XCM"},
          {"name":"BROWNSTOWN","code":"US-PA-BR9"},
          {"name":"BROWNSVILLE","code":"US-PA-BV8"},
          {"name":"BRUSH VALLEY","code":"US-PA-VDG"},
          {"name":"BRYN MAWR","code":"US-PA-BYE"},
          {"name":"BUENA VISTA","code":"US-PA-KBI"},
          {"name":"BULGER","code":"US-PA-B3G"},
          {"name":"BUNOLA","code":"US-PA-ULO"},
          {"name":"BURGETTSTOWN","code":"US-PA-BR4"},
          {"name":"BURLEY","code":"US-PA-BYP"},
          {"name":"BURLINGTON","code":"US-PA-B9L"},
          {"name":"BURNHAM","code":"US-PA-BHP"},
          {"name":"BURNSIDE","code":"US-PA-UPD"},
          {"name":"BUSHKILL","code":"US-PA-BS9"},
          {"name":"BUSTLETON","code":"US-PA-XCB"},
          {"name":"BUTLER","code":"US-PA-BTP"},
          {"name":"BYBERRY","code":"US-PA-BYQ"},
          {"name":"CABOT","code":"US-PA-IYS"},
          {"name":"CALIFORNIA","code":"US-PA-IFF"},
          {"name":"CALLERY","code":"US-PA-YYY"},
          {"name":"CAMBRIDGE SPRINGS","code":"US-PA-UFJ"},
          {"name":"CAMP HILL","code":"US-PA-XDZ"},
          {"name":"CAMPHILL","code":"US-PA-ADC"},
          {"name":"CANONSBURG","code":"US-PA-CNN"},
          {"name":"CANTON","code":"US-PA-AOJ"},
          {"name":"CARBONDALE","code":"US-PA-CQD"},
          {"name":"CARLISLE","code":"US-PA-UFI"},
          {"name":"CARLTON","code":"US-PA-YCR"},
          {"name":"CARMICHAELS","code":"US-PA-CXK"},
          {"name":"CARNEGIE","code":"US-PA-CRE"},
          {"name":"CARROLLTOWN","code":"US-PA-DCW"},
          {"name":"CASTANEA","code":"US-PA-C4P"},
          {"name":"CATASAUQUA","code":"US-PA-XEJ"},
          {"name":"CATAWISSA","code":"US-PA-KWS"},
          {"name":"CECIL","code":"US-PA-ZB2"},
          {"name":"CENTER VALLEY","code":"US-PA-CWQ"},
          {"name":"CENTRAL","code":"US-PA-ZRL"},
          {"name":"CENTRE HALL","code":"US-PA-VVZ"},
          {"name":"CHADDS FORD","code":"US-PA-CFR"},
          {"name":"CHALFONT","code":"US-PA-CHF"},
          {"name":"CHAMBERSBURG","code":"US-PA-CBB"},
          {"name":"CHAMBERSVILLE","code":"US-PA-C9B"},
          {"name":"CHAMPION","code":"US-PA-CJM"},
          {"name":"CHARLEROI","code":"US-PA-ZOI"},
          {"name":"CHARLESTON","code":"US-PA-YI3"},
          {"name":"CHATHAM","code":"US-PA-UHH"},
          {"name":"CHELSEA","code":"US-PA-C9H"},
          {"name":"CHERRY TREE","code":"US-PA-9CT"},
          {"name":"CHERRYVILLE","code":"US-PA-YV9"},
          {"name":"CHESTER","code":"US-PA-CHT"},
          {"name":"CHESTER HEIGHTS","code":"US-PA-TEJ"},
          {"name":"CHESTER SPRINGS","code":"US-PA-CPX"},
          {"name":"CHESTERBROOK","code":"US-PA-CX9"},
          {"name":"CHESWICK","code":"US-PA-CWK"},
          {"name":"CHEVY CHASE HEIGHTS","code":"US-PA-3HV"},
          {"name":"CHILDS","code":"US-PA-CDP"},
          {"name":"CHRISTIANA","code":"US-PA-CT7"},
          {"name":"CHURCHTOWN","code":"US-PA-JJP"},
          {"name":"CLAIRTON","code":"US-PA-CGN"},
          {"name":"CLARENCE","code":"US-PA-CA7"},
          {"name":"CLARENDON HEIGHTS","code":"US-PA-ZDC"},
          {"name":"CLARION","code":"US-PA-QCO"},
          {"name":"CLARK","code":"US-PA-ZCK"},
          {"name":"CLARKS SUMMIT","code":"US-PA-CKU"},
          {"name":"CLARKSVILLE","code":"US-PA-CK6"},
          {"name":"CLAYSBURG","code":"US-PA-QCB"},
          {"name":"CLEARFIELD","code":"US-PA-ZCL"},
          {"name":"CLEARFIELD-BELLEFONTE APT","code":"US-PA-PSB"},
          {"name":"CLEARVILLE","code":"US-PA-2PA"},
          {"name":"CLEONA","code":"US-PA-XER"},
          {"name":"CLIFFORD TOWNSHIP","code":"US-PA-2TO"},
          {"name":"CLIFTON","code":"US-PA-TCF"},
          {"name":"CLIFTON HEIGHTS","code":"US-PA-CFH"},
          {"name":"CLINTON","code":"US-PA-CQ3"},
          {"name":"COAL CENTER","code":"US-PA-QCN"},
          {"name":"COAL RUN, NORTHUMBERLAND","code":"US-PA-YCO"},
          {"name":"COAL TOWNSHIP","code":"US-PA-CQ6"},
          {"name":"COALDALE","code":"US-PA-KL8"},
          {"name":"COALTOWN","code":"US-PA-TTC"},
          {"name":"COATESVILLE","code":"US-PA-CTH"},
          {"name":"COCHRANTON","code":"US-PA-COJ"},
          {"name":"COCHRANVILLE","code":"US-PA-C9N"},
          {"name":"CODORUS","code":"US-PA-C6S"},
          {"name":"CODORUS FURNANCE","code":"US-PA-ZUO"},
          {"name":"COGAN STATION","code":"US-PA-CG8"},
          {"name":"COLLEGEVILLE","code":"US-PA-CGV"},
          {"name":"COLLINGDALE","code":"US-PA-CGL"},
          {"name":"COLMAR","code":"US-PA-COX"},
          {"name":"COLUMBIA","code":"US-PA-CKP"},
          {"name":"COLUMBIA CROSS ROADS","code":"US-PA-ICD"},
          {"name":"COLVER","code":"US-PA-C3V"},
          {"name":"COLWYN","code":"US-PA-9CW"},
          {"name":"CONCORDVILLE","code":"US-PA-ONC"},
          {"name":"CONESTOGA","code":"US-PA-C5A"},
          {"name":"CONFLUENCE","code":"US-PA-C7N"},
          {"name":"CONNEAUTVILLE","code":"US-PA-CV3"},
          {"name":"CONNELLSVILLE","code":"US-PA-CQP"},
          {"name":"CONSHOHOCKEN","code":"US-PA-CSH"},
          {"name":"CONWAY","code":"US-PA-Q7I"},
          {"name":"CONYNGHAM","code":"US-PA-CY5"},
          {"name":"COOPERSBURG","code":"US-PA-CPU"},
          {"name":"COPLAY","code":"US-PA-QCV"},
          {"name":"CORAOPOLIS","code":"US-PA-CPP"},
          {"name":"CORNWELLS HEIGHTS","code":"US-PA-CZD"},
          {"name":"CORRY","code":"US-PA-CYP"},
          {"name":"COUDERSPORT","code":"US-PA-CZQ"},
          {"name":"COURTDALE","code":"US-PA-OUT"},
          {"name":"COVINGTON","code":"US-PA-VNG"},
          {"name":"COVINGTON TOWNSHIP","code":"US-PA-IWP"},
          {"name":"COWANSVILLE","code":"US-PA-PA6"},
          {"name":"CRABTREE","code":"US-PA-ZRA"},
          {"name":"CRAFTON","code":"US-PA-CFQ"},
          {"name":"CRANBERRY TOWNSHIP","code":"US-PA-RYT"},
          {"name":"CREEKSIDE","code":"US-PA-QAP"},
          {"name":"CREIGHTON","code":"US-PA-CQK"},
          {"name":"CRESCENT TOWNSHIP","code":"US-PA-WJC"},
          {"name":"CRESCO","code":"US-PA-CTG"},
          {"name":"CRESSON","code":"US-PA-CR8"},
          {"name":"CRESSONA","code":"US-PA-CJA"},
          {"name":"CROYDON","code":"US-PA-CRF"},
          {"name":"CRUM LYNNE","code":"US-PA-ULC"},
          {"name":"CRYSTAL LAKE","code":"US-PA-CYE"},
          {"name":"CUDDY","code":"US-PA-YCB"},
          {"name":"CURRYVILLE","code":"US-PA-CU8"},
          {"name":"CURWENSVILLE","code":"US-PA-CUI"},
          {"name":"DALLAS","code":"US-PA-DSP"},
          {"name":"DALLASTOWN","code":"US-PA-DAZ"},
          {"name":"DALMATIA","code":"US-PA-DL6"},
          {"name":"DALTON","code":"US-PA-DPQ"},
          {"name":"DAMASCUS","code":"US-PA-D3U"},
          {"name":"DANBORO","code":"US-PA-DAH"},
          {"name":"DANIELSVILLE","code":"US-PA-DNW"},
          {"name":"DANVILLE","code":"US-PA-DAP"},
          {"name":"DARBY","code":"US-PA-DBB"},
          {"name":"DARLINGTON","code":"US-PA-DLP"},
          {"name":"DAUBERVILLE","code":"US-PA-EIP"},
          {"name":"DAUPHIN","code":"US-PA-DA8"},
          {"name":"DAVIDSVILLE","code":"US-PA-DA3"},
          {"name":"DAYTON","code":"US-PA-D2Y"},
          {"name":"DEER LAKE, SCHUYLKILL","code":"US-PA-DLQ"},
          {"name":"DEER PARK","code":"US-PA-DPC"},
          {"name":"DELANO","code":"US-PA-DLU"},
          {"name":"DELAWARE WATER GAP","code":"US-PA-DWG"},
          {"name":"DELMONT","code":"US-PA-DVQ"},
          {"name":"DELTA","code":"US-PA-DT9"},
          {"name":"DENVER","code":"US-PA-DNQ"},
          {"name":"DERRY","code":"US-PA-QDR"},
          {"name":"DEVON","code":"US-PA-DEV"},
          {"name":"DICKSON CITY","code":"US-PA-DSY"},
          {"name":"DILLSBURG","code":"US-PA-DMG"},
          {"name":"DINGMANS FERRY","code":"US-PA-DFP"},
          {"name":"DONEGAL","code":"US-PA-DCZ"},
          {"name":"DONORA","code":"US-PA-DRP"},
          {"name":"DOUGLASSVILLE","code":"US-PA-DGL"},
          {"name":"DOVER","code":"US-PA-DR2"},
          {"name":"DOWNEY","code":"US-PA-DWZ"},
          {"name":"DOWNINGTOWN","code":"US-PA-DNT"},
          {"name":"DOYLESTOWN","code":"US-PA-DYL"},
          {"name":"DRAVOSBURG","code":"US-PA-DVB"},
          {"name":"DRESHER","code":"US-PA-DRC"},
          {"name":"DRUMS","code":"US-PA-UMS"},
          {"name":"DU BOIS","code":"US-PA-DU4"},
          {"name":"DUBLIN","code":"US-PA-DUI"},
          {"name":"DUBOIS","code":"US-PA-DUJ"},
          {"name":"DUNBAR","code":"US-PA-DB8"},
          {"name":"DUNCANSVILLE","code":"US-PA-DUV"},
          {"name":"DUNMORE","code":"US-PA-DNZ"},
          {"name":"DUPONT","code":"US-PA-PUD"},
          {"name":"DUQUESNE","code":"US-PA-ZDQ"},
          {"name":"DURHAM","code":"US-PA-DXH"},
          {"name":"DURYEA","code":"US-PA-DYA"},
          {"name":"DUSHORE","code":"US-PA-DUS"},
          {"name":"EAGLE","code":"US-PA-EGL"},
          {"name":"EAGLES MERE","code":"US-PA-E33"},
          {"name":"EAGLEVILLE","code":"US-PA-EAV"},
          {"name":"EAST BANGOR","code":"US-PA-EGB"},
          {"name":"EAST BERLIN","code":"US-PA-EBL"},
          {"name":"EAST BUTLER","code":"US-PA-EBT"},
          {"name":"EAST EARL","code":"US-PA-EE3"},
          {"name":"EAST FREEDOM","code":"US-PA-EFM"},
          {"name":"EAST GOSHEN","code":"US-PA-EGO"},
          {"name":"EAST GREENVILLE","code":"US-PA-UFT"},
          {"name":"EAST MCKEESPORT","code":"US-PA-EQP"},
          {"name":"EAST NORRITON","code":"US-PA-EIO"},
          {"name":"EAST PENN","code":"US-PA-EP5"},
          {"name":"EAST PETERSBURG","code":"US-PA-V4V"},
          {"name":"EAST PITTSBURGH","code":"US-PA-E9T"},
          {"name":"EAST STROUDSBURG","code":"US-PA-ESP"},
          {"name":"EASTON","code":"US-PA-ABE"},
          {"name":"EBENSBURG","code":"US-PA-EBE"},
          {"name":"EDDYSTONE","code":"US-PA-ESX"},
          {"name":"EDGEMONT","code":"US-PA-EGM"},
          {"name":"EDINBORO","code":"US-PA-QEB"},
          {"name":"EDINBURG","code":"US-PA-2PP"},
          {"name":"EDWARDSVILLE","code":"US-PA-DWS"},
          {"name":"EFFORT","code":"US-PA-EFT"},
          {"name":"EIGHTY FOUR","code":"US-PA-EIH"},
          {"name":"ELDORADO","code":"US-PA-ED5"},
          {"name":"ELDRED","code":"US-PA-E77"},
          {"name":"ELIZABETHTOWN","code":"US-PA-EZT"},
          {"name":"ELIZABETHVILLE","code":"US-PA-EIZ"},
          {"name":"ELKINS PARK","code":"US-PA-EIK"},
          {"name":"ELKLAND","code":"US-PA-ELQ"},
          {"name":"ELLPORT","code":"US-PA-EQZ"},
          {"name":"ELLSWORTH","code":"US-PA-EHZ"},
          {"name":"ELLWOOD CITY","code":"US-PA-XFH"},
          {"name":"ELM","code":"US-PA-EM6"},
          {"name":"ELTON","code":"US-PA-OAO"},
          {"name":"ELVERSON","code":"US-PA-EVO"},
          {"name":"ELYSBURG","code":"US-PA-EYS"},
          {"name":"EMIGSVILLE","code":"US-PA-EGS"},
          {"name":"EMLENTON","code":"US-PA-EMX"},
          {"name":"EMMAUS","code":"US-PA-EMU"},
          {"name":"EMPORIUM","code":"US-PA-EUI"},
          {"name":"EMSWORTH","code":"US-PA-EMH"},
          {"name":"ENDEAVOR","code":"US-PA-END"},
          {"name":"ENOLA","code":"US-PA-EL7"},
          {"name":"ENON VALLEY","code":"US-PA-OYU"},
          {"name":"EPHRATA","code":"US-PA-EHP"},
          {"name":"ERIE","code":"US-PA-ERI"},
          {"name":"ESSINGTON","code":"US-PA-ETX"},
          {"name":"ETNA","code":"US-PA-ET3"},
          {"name":"EVANS CITY","code":"US-PA-EVC"},
          {"name":"EVANSVILLE","code":"US-PA-EV9"},
          {"name":"EVERETT","code":"US-PA-EVZ"},
          {"name":"EVERSON","code":"US-PA-VSO"},
          {"name":"EXETER","code":"US-PA-EXP"},
          {"name":"EXPORT","code":"US-PA-EPT"},
          {"name":"EXTON","code":"US-PA-EXN"},
          {"name":"EYNON","code":"US-PA-EYP"},
          {"name":"FAIR HILL","code":"US-PA-FHP"},
          {"name":"FAIRCHANCE","code":"US-PA-F2C"},
          {"name":"FAIRFIELD","code":"US-PA-PA5"},
          {"name":"FAIRLESS HILLS","code":"US-PA-FAH"},
          {"name":"FAIRMONT CITY","code":"US-PA-QFC"},
          {"name":"FAIRMOUNT","code":"US-PA-F9A"},
          {"name":"FAIRVIEW","code":"US-PA-FVE"},
          {"name":"FALLS","code":"US-PA-F5S"},
          {"name":"FALLS CREEK","code":"US-PA-FXK"},
          {"name":"FALLSINGTON","code":"US-PA-FAS"},
          {"name":"FARMERS VALLEY","code":"US-PA-7FV"},
          {"name":"FARMINGTON","code":"US-PA-FA7"},
          {"name":"FARMINGTON HILL","code":"US-PA-QFM"},
          {"name":"FARMINGTON, FAYETTE","code":"US-PA-FNG"},
          {"name":"FARRELL","code":"US-PA-FAP"},
          {"name":"FAWN GROVE","code":"US-PA-FGR"},
          {"name":"FAYETTEVILLE","code":"US-PA-FYW"},
          {"name":"FEASTERVILLE","code":"US-PA-FEA"},
          {"name":"FEASTERVILLE-TREVOSE","code":"US-PA-F37"},
          {"name":"FELTON","code":"US-PA-FEX"},
          {"name":"FINLEYVILLE","code":"US-PA-FYI"},
          {"name":"FISHERTOWN","code":"US-PA-FSW"},
          {"name":"FLEETWOOD","code":"US-PA-FWP"},
          {"name":"FLINTON","code":"US-PA-FZN"},
          {"name":"FLOREFFE","code":"US-PA-FPU"},
          {"name":"FLOURTOWN","code":"US-PA-FOQ"},
          {"name":"FOGELSVILLE","code":"US-PA-FOG"},
          {"name":"FOLCROFT","code":"US-PA-UJD"},
          {"name":"FOMBELL","code":"US-PA-OMY"},
          {"name":"FORD CITY","code":"US-PA-FCT"},
          {"name":"FOREST CITY","code":"US-PA-FZC"},
          {"name":"FORKSVILLE","code":"US-PA-FXZ"},
          {"name":"FORT HILL","code":"US-PA-8FI"},
          {"name":"FORT INDIANTOWN","code":"US-PA-MUI"},
          {"name":"FORT LITTLETON","code":"US-PA-FO7"},
          {"name":"FORT LOUDON","code":"US-PA-FL9"},
          {"name":"FORT WASHINGTON","code":"US-PA-QFW"},
          {"name":"FORTY FORT","code":"US-PA-FOV"},
          {"name":"FOUNTAIN HILL","code":"US-PA-FH7"},
          {"name":"FOXCROFT","code":"US-PA-FXC"},
          {"name":"FRACKVILLE","code":"US-PA-FTJ"},
          {"name":"FRANCONIA","code":"US-PA-FSA"},
          {"name":"FRANK","code":"US-PA-FQP"},
          {"name":"FRANKFORD","code":"US-PA-FFD"},
          {"name":"FRANKFORT SPRINGS","code":"US-PA-FRK"},
          {"name":"FRANKLIN","code":"US-PA-FKL"},
          {"name":"FRAZER","code":"US-PA-FRZ"},
          {"name":"FREDERICK","code":"US-PA-XZD"},
          {"name":"FREDERICKSBURG","code":"US-PA-FRW"},
          {"name":"FREDERICKTOWN","code":"US-PA-FKW"},
          {"name":"FREDONIA","code":"US-PA-FYO"},
          {"name":"FREEDOM","code":"US-PA-FZZ"},
          {"name":"FREELAND","code":"US-PA-FQD"},
          {"name":"FREEPORT","code":"US-PA-EE8"},
          {"name":"FRIEDENS","code":"US-PA-FWQ"},
          {"name":"FRISCO","code":"US-PA-FCK"},
          {"name":"FROSTBURG","code":"US-PA-FUG"},
          {"name":"FURLONG","code":"US-PA-YZV"},
          {"name":"GALETON","code":"US-PA-ZGQ"},
          {"name":"GALILEE","code":"US-PA-GA4"},
          {"name":"GAP","code":"US-PA-AGP"},
          {"name":"GARDNER","code":"US-PA-GDY"},
          {"name":"GARDNERS","code":"US-PA-GPE"},
          {"name":"GAYLY","code":"US-PA-GA9"},
          {"name":"GENESEE","code":"US-PA-G9E"},
          {"name":"GERMANSVILLE","code":"US-PA-GJF"},
          {"name":"GETTYSBURG","code":"US-PA-GTY"},
          {"name":"GIBRALTAR","code":"US-PA-UJG"},
          {"name":"GIBSON","code":"US-PA-GIZ"},
          {"name":"GIBSONIA","code":"US-PA-GJJ"},
          {"name":"GILBERTSVILLE","code":"US-PA-GXV"},
          {"name":"GILLETT","code":"US-PA-GT8"},
          {"name":"GIRARD","code":"US-PA-GRJ"},
          {"name":"GLADWYNE","code":"US-PA-G2P"},
          {"name":"GLASSPORT","code":"US-PA-GPX"},
          {"name":"GLEN HOPE","code":"US-PA-GHP"},
          {"name":"GLEN MILLS","code":"US-PA-GMC"},
          {"name":"GLEN ROCK","code":"US-PA-GOC"},
          {"name":"GLENMOORE","code":"US-PA-GEM"},
          {"name":"GLENOLDEN","code":"US-PA-GNE"},
          {"name":"GLENSHAW","code":"US-PA-GWL"},
          {"name":"GLENSIDE","code":"US-PA-GLI"},
          {"name":"GOODVILLE, LANCASTER","code":"US-PA-GQI"},
          {"name":"GORDON","code":"US-PA-GOX"},
          {"name":"GORDONVILLE","code":"US-PA-GVZ"},
          {"name":"GOULDSBORO","code":"US-PA-GGO"},
          {"name":"GOWEN CITY","code":"US-PA-GYZ"},
          {"name":"GRAMPIAN","code":"US-PA-G8P"},
          {"name":"GRAND VALLEY","code":"US-PA-JGV"},
          {"name":"GRANTHAM","code":"US-PA-2GP"},
          {"name":"GRANTVILLE","code":"US-PA-GR5"},
          {"name":"GRANVILLE","code":"US-PA-3PA"},
          {"name":"GRATERFORD","code":"US-PA-GP3"},
          {"name":"GRATZ","code":"US-PA-GAZ"},
          {"name":"GREELEY","code":"US-PA-GYP"},
          {"name":"GREEN LANE","code":"US-PA-GLP"},
          {"name":"GREEN TREE","code":"US-PA-GTP"},
          {"name":"GREENCASTLE","code":"US-PA-GRP"},
          {"name":"GREENFIELD TOWNSHIP","code":"US-PA-G43"},
          {"name":"GREENSBORO","code":"US-PA-KRB"},
          {"name":"GREENSBURG","code":"US-PA-UJK"},
          {"name":"GREENTOWN","code":"US-PA-PA9"},
          {"name":"GREENVILLE","code":"US-PA-GNP"},
          {"name":"GROVE CITY","code":"US-PA-GRO"},
          {"name":"GUYS MILLS","code":"US-PA-GQM"},
          {"name":"GWYNEDD","code":"US-PA-GWY"},
          {"name":"HADLEY","code":"US-PA-HDY"},
          {"name":"HALIFAX","code":"US-PA-YHX"},
          {"name":"HALLSTEAD","code":"US-PA-HS6"},
          {"name":"HAMBURG","code":"US-PA-HMQ"},
          {"name":"HAMILTON SQUARE","code":"US-PA-HMB"},
          {"name":"HANOVER","code":"US-PA-HO5"},
          {"name":"HANOVER TOWNSHIP","code":"US-PA-ZVT"},
          {"name":"HANOVER TOWNSHIP, LUZERNE","code":"US-PA-HNP"},
          {"name":"HARBORCREEK","code":"US-PA-RRZ"},
          {"name":"HARDING","code":"US-PA-HDP"},
          {"name":"HARFORD","code":"US-PA-HRF"},
          {"name":"HARLEYSVILLE","code":"US-PA-HVP"},
          {"name":"HARMONY","code":"US-PA-HMY"},
          {"name":"HARRISBURG","code":"US-PA-HAR"},
          {"name":"HARRISON","code":"US-PA-6HP"},
          {"name":"HARRISON CITY","code":"US-PA-IZC"},
          {"name":"HARRISVILLE","code":"US-PA-HWV"},
          {"name":"HARVEYS LAKE","code":"US-PA-H5L"},
          {"name":"HARWICK","code":"US-PA-ICP"},
          {"name":"HATBORO","code":"US-PA-HTP"},
          {"name":"HATFIELD, MONTGOMERY","code":"US-PA-HAT"},
          {"name":"HAVERFORD","code":"US-PA-VFD"},
          {"name":"HAVERTOWN","code":"US-PA-HAA"},
          {"name":"HAWLEY","code":"US-PA-AWL"},
          {"name":"HAWTHORN","code":"US-PA-YHW"},
          {"name":"HAZELTON MILLS","code":"US-PA-ZD2"},
          {"name":"HAZLE TOWNSHIP","code":"US-PA-ZES"},
          {"name":"HAZLETON","code":"US-PA-HZL"},
          {"name":"HEGINS","code":"US-PA-HG3"},
          {"name":"HEIDELBERG","code":"US-PA-HDQ"},
          {"name":"HEIDLERSBURG","code":"US-PA-HBZ"},
          {"name":"HELLERTOWN","code":"US-PA-HEL"},
          {"name":"HERMITAGE","code":"US-PA-HGX"},
          {"name":"HERSHEY","code":"US-PA-HEH"},
          {"name":"HIBBS","code":"US-PA-IBS"},
          {"name":"HICKORY","code":"US-PA-HK2"},
          {"name":"HIDDEN VALLEY","code":"US-PA-ZAX"},
          {"name":"HIGHSPIRE","code":"US-PA-HGI"},
          {"name":"HOLLAND","code":"US-PA-HLX"},
          {"name":"HOLLIDAYSBURG","code":"US-PA-HDB"},
          {"name":"HOLLSOPPLE","code":"US-PA-ZPP"},
          {"name":"HOLMES","code":"US-PA-VOE"},
          {"name":"HOLTWOOD","code":"US-PA-VHD"},
          {"name":"HOME","code":"US-PA-OJM"},
          {"name":"HOMER CITY","code":"US-PA-QHR"},
          {"name":"HOMESTEAD","code":"US-PA-WQ3"},
          {"name":"HOMETOWN","code":"US-PA-HT6"},
          {"name":"HONESDALE","code":"US-PA-HOD"},
          {"name":"HONEY BROOK","code":"US-PA-HNY"},
          {"name":"HONEY GROVE","code":"US-PA-YOP"},
          {"name":"HORSHAM","code":"US-PA-HOA"},
          {"name":"HOUSTON","code":"US-PA-HSO"},
          {"name":"HOWARD","code":"US-PA-HXA"},
          {"name":"HUGHESTOWN","code":"US-PA-H6H"},
          {"name":"HUGHESVILLE","code":"US-PA-HV2"},
          {"name":"HUMMELS WHARF","code":"US-PA-2AP"},
          {"name":"HUMMELSTOWN","code":"US-PA-HWP"},
          {"name":"HUNKER","code":"US-PA-KER"},
          {"name":"HUNTINGDON","code":"US-PA-HTG"},
          {"name":"HUNTINGDON VALLEY","code":"US-PA-HVY"},
          {"name":"HYDE","code":"US-PA-HYD"},
          {"name":"HYDE PARK","code":"US-PA-PDK"},
          {"name":"IMLER","code":"US-PA-IZL"},
          {"name":"IMPERIAL","code":"US-PA-IMP"},
          {"name":"INDIANA","code":"US-PA-IDI"},
          {"name":"INDIANOLA","code":"US-PA-IL9"},
          {"name":"INDUSTRY","code":"US-PA-INU"},
          {"name":"IRVINE","code":"US-PA-IVX"},
          {"name":"IRVING","code":"US-PA-IRV"},
          {"name":"IRVONA","code":"US-PA-IRF"},
          {"name":"IRWIN","code":"US-PA-IPA"},
          {"name":"IVYLAND","code":"US-PA-IVY"},
          {"name":"JACKSON","code":"US-PA-JCS"},
          {"name":"JACKSON CENTER","code":"US-PA-JKC"},
          {"name":"JAMESTOWN, CAMBRIA","code":"US-PA-JSN"},
          {"name":"JEANNETTE","code":"US-PA-JEA"},
          {"name":"JEFFERSON","code":"US-PA-QJE"},
          {"name":"JEFFERSON HILLS","code":"US-PA-JE2"},
          {"name":"JEFFERSONVILLE","code":"US-PA-JEE"},
          {"name":"JENKINS TOWNSHIP","code":"US-PA-JHI"},
          {"name":"JENKINTOWN","code":"US-PA-JKT"},
          {"name":"JERMYN","code":"US-PA-JMY"},
          {"name":"JERSEY SHORE","code":"US-PA-ZJS"},
          {"name":"JESSUP","code":"US-PA-JEP"},
          {"name":"JIM THORPE","code":"US-PA-JTP"},
          {"name":"JOHNSONBURG","code":"US-PA-JHB"},
          {"name":"JOHNSTON","code":"US-PA-JS4"},
          {"name":"JOHNSTOWN","code":"US-PA-JST"},
          {"name":"JONESTOWN","code":"US-PA-JNT"},
          {"name":"JOSEPHTOWN","code":"US-PA-JTN"},
          {"name":"JULIAN","code":"US-PA-JUA"},
          {"name":"KANE","code":"US-PA-KAN"},
          {"name":"KARNS CITY","code":"US-PA-KCZ"},
          {"name":"KARTHAUS","code":"US-PA-KHX"},
          {"name":"KEMPTON","code":"US-PA-KMP"},
          {"name":"KENNETT SQUARE","code":"US-PA-KNN"},
          {"name":"KENSINGTON","code":"US-PA-KTP"},
          {"name":"KERSEY","code":"US-PA-KRR"},
          {"name":"KIMBERTON","code":"US-PA-KIM"},
          {"name":"KING OF PRUSSIA","code":"US-PA-KPD"},
          {"name":"KINGSLEY","code":"US-PA-KYG"},
          {"name":"KINGSTON","code":"US-PA-KNS"},
          {"name":"KINZERS","code":"US-PA-ZVK"},
          {"name":"KIRBY","code":"US-PA-KB9"},
          {"name":"KIRKWOOD","code":"US-PA-KRZ"},
          {"name":"KITTANNING","code":"US-PA-KTG"},
          {"name":"KLINGERSTOWN","code":"US-PA-KG5"},
          {"name":"KNOX","code":"US-PA-KOX"},
          {"name":"KNOXVILLE","code":"US-PA-KXL"},
          {"name":"KOPPEL","code":"US-PA-KPP"},
          {"name":"KREAMER","code":"US-PA-KRP"},
          {"name":"KULPMONT","code":"US-PA-KUL"},
          {"name":"KULPSVILLE","code":"US-PA-KUP"},
          {"name":"KUNKLETOWN","code":"US-PA-K2T"},
          {"name":"KUTZTOWN","code":"US-PA-KUT"},
          {"name":"KYLERTOWN","code":"US-PA-KYT"},
          {"name":"LACEYVILLE","code":"US-PA-FYJ"},
          {"name":"LACKAWAXEN","code":"US-PA-8PV"},
          {"name":"LAFAYETTE HILL","code":"US-PA-LH2"},
          {"name":"LAFLIN","code":"US-PA-LFP"},
          {"name":"LAHASKA","code":"US-PA-PZV"},
          {"name":"LAKE ARIEL","code":"US-PA-KJA"},
          {"name":"LAKE CITY","code":"US-PA-XHJ"},
          {"name":"LAKE COMO","code":"US-PA-L6C"},
          {"name":"LAKE WINOLA","code":"US-PA-LXJ"},
          {"name":"LAMAR","code":"US-PA-LL9"},
          {"name":"LAMPETER","code":"US-PA-LMP"},
          {"name":"LANCASTER","code":"US-PA-LNS"},
          {"name":"LANDENBERG","code":"US-PA-LDG"},
          {"name":"LANDISVILLE","code":"US-PA-LNL"},
          {"name":"LANGELOTH","code":"US-PA-LHH"},
          {"name":"LANGHORNE","code":"US-PA-XHS"},
          {"name":"LANSDALE","code":"US-PA-LDA"},
          {"name":"LANSDOWNE","code":"US-PA-QLD"},
          {"name":"LANSFORD","code":"US-PA-LZP"},
          {"name":"LATROBE","code":"US-PA-LBE"},
          {"name":"LAUREL","code":"US-PA-L9R"},
          {"name":"LAURELDALE","code":"US-PA-QLR"},
          {"name":"LAURYS STATION","code":"US-PA-YTI"},
          {"name":"LAWRENCE","code":"US-PA-LWN"},
          {"name":"LAWRENCEVILLE","code":"US-PA-LVC"},
          {"name":"LEBANON","code":"US-PA-LBP"},
          {"name":"LEECHBURG","code":"US-PA-LEC"},
          {"name":"LEEPER","code":"US-PA-QEP"},
          {"name":"LEESPORT","code":"US-PA-LEP"},
          {"name":"LEETSDALE","code":"US-PA-LSL"},
          {"name":"LEHIGH","code":"US-PA-LEG"},
          {"name":"LEHIGH VALLEY","code":"US-PA-LHO"},
          {"name":"LEHIGHTON","code":"US-PA-LHG"},
          {"name":"LEMASTERS","code":"US-PA-PMS"},
          {"name":"LEMONT FURNACE","code":"US-PA-LMU"},
          {"name":"LEMOYNE","code":"US-PA-LYP"},
          {"name":"LENHARTSVILLE","code":"US-PA-LV7"},
          {"name":"LENNI","code":"US-PA-ENI"},
          {"name":"LEOLA","code":"US-PA-LLA"},
          {"name":"LESTER","code":"US-PA-LEZ"},
          {"name":"LEVITTOWN","code":"US-PA-LTT"},
          {"name":"LEWIS RUN","code":"US-PA-IRU"},
          {"name":"LEWISBERRY","code":"US-PA-LBR"},
          {"name":"LEWISBURG","code":"US-PA-LFQ"},
          {"name":"LEWISTOWN","code":"US-PA-L7W"},
          {"name":"LEWISTOWN, MIFFLIN","code":"US-PA-LWW"},
          {"name":"LIBERTY","code":"US-PA-PL5"},
          {"name":"LIGONIER","code":"US-PA-LIG"},
          {"name":"LIMA","code":"US-PA-7IM"},
          {"name":"LIMERICK","code":"US-PA-LMK"},
          {"name":"LINCOLN UNIVERSITY","code":"US-PA-3QZ"},
          {"name":"LINE LEXINGTON","code":"US-PA-LL6"},
          {"name":"LINESVILLE","code":"US-PA-LIQ"},
          {"name":"LINFIELD","code":"US-PA-ZLF"},
          {"name":"LINWOOD","code":"US-PA-LIW"},
          {"name":"LIONVILLE","code":"US-PA-LIN"},
          {"name":"LITITZ","code":"US-PA-LTI"},
          {"name":"LITTLESTOWN","code":"US-PA-TLW"},
          {"name":"LITTLETOWN","code":"US-PA-IIL"},
          {"name":"LIVERPOOL","code":"US-PA-LVP"},
          {"name":"LOCK HAVEN","code":"US-PA-LHV"},
          {"name":"LOCUST SUMMIT","code":"US-PA-XHY"},
          {"name":"LONDONDERRY","code":"US-PA-OJY"},
          {"name":"LONG POND","code":"US-PA-L9D"},
          {"name":"LORETTO","code":"US-PA-QLT"},
          {"name":"LOYALHANNA","code":"US-PA-LY4"},
          {"name":"LOYSVILLE","code":"US-PA-L7O"},
          {"name":"LUTHERSBURG","code":"US-PA-L2B"},
          {"name":"LUZERNE","code":"US-PA-UZR"},
          {"name":"LYKENS","code":"US-PA-YKE"},
          {"name":"LYON STATION","code":"US-PA-LYQ"},
          {"name":"MABEL","code":"US-PA-QMB"},
          {"name":"MACUNGIE","code":"US-PA-MGB"},
          {"name":"MADISON","code":"US-PA-DQS"},
          {"name":"MAHANOY CITY","code":"US-PA-YLM"},
          {"name":"MAINLAND","code":"US-PA-ML6"},
          {"name":"MALVERN","code":"US-PA-MWN"},
          {"name":"MANAYUNK","code":"US-PA-MY2"},
          {"name":"MANCHESTER","code":"US-PA-YMC"},
          {"name":"MANHEIM","code":"US-PA-MHF"},
          {"name":"MANNS CHOICE","code":"US-PA-OMZ"},
          {"name":"MANOR","code":"US-PA-MZ9"},
          {"name":"MANSFIELD","code":"US-PA-MAS"},
          {"name":"MANVER","code":"US-PA-MWP"},
          {"name":"MARBLE","code":"US-PA-NN9"},
          {"name":"MARCUS HOOK","code":"US-PA-MAH"},
          {"name":"MARIENVILLE","code":"US-PA-RL6"},
          {"name":"MARIETTA","code":"US-PA-MPD"},
          {"name":"MARION","code":"US-PA-XJC"},
          {"name":"MARLIN","code":"US-PA-ML2"},
          {"name":"MARS","code":"US-PA-XJB"},
          {"name":"MARTINS CREEK","code":"US-PA-MQP"},
          {"name":"MARTINSBURG","code":"US-PA-AIR"},
          {"name":"MARYSVILLE","code":"US-PA-MYP"},
          {"name":"MASONTOWN","code":"US-PA-QER"},
          {"name":"MAYFIELD","code":"US-PA-MFP"},
          {"name":"MCADOO","code":"US-PA-MCA"},
          {"name":"MCALISTERVILLE","code":"US-PA-MA2"},
          {"name":"MCCLELLANDTOWN","code":"US-PA-M3C"},
          {"name":"MCCLURE","code":"US-PA-VMC"},
          {"name":"MCCONNELLSBURG","code":"US-PA-MNB"},
          {"name":"MCDONALD","code":"US-PA-MDP"},
          {"name":"MCELHATTAN","code":"US-PA-LHC"},
          {"name":"MCKEAN","code":"US-PA-KMD"},
          {"name":"MCKEES ROCKS","code":"US-PA-MKJ"},
          {"name":"MCKEESPORT","code":"US-PA-MKR"},
          {"name":"MCMURRAY","code":"US-PA-MCM"},
          {"name":"MCSHERRYSTOWN","code":"US-PA-HYT"},
          {"name":"MCVEYTOWN","code":"US-PA-MVT"},
          {"name":"MEADOW LANDS","code":"US-PA-MWD"},
          {"name":"MEADVILLE","code":"US-PA-MEJ"},
          {"name":"MECHANICSBURG","code":"US-PA-MEG"},
          {"name":"MECHANICSBURG, BEAVER","code":"US-PA-ECI"},
          {"name":"MEDIA","code":"US-PA-XIJ"},
          {"name":"MEHOOPANY","code":"US-PA-MEH"},
          {"name":"MELROSE PARK","code":"US-PA-EKU"},
          {"name":"MERCER","code":"US-PA-MQX"},
          {"name":"MERCERSBURG","code":"US-PA-MBP"},
          {"name":"MERRITTSTOWN","code":"US-PA-IWN"},
          {"name":"MERTZTOWN","code":"US-PA-MZP"},
          {"name":"MESHOPPEN","code":"US-PA-ZPN"},
          {"name":"MEYERSDALE","code":"US-PA-MYD"},
          {"name":"MIDDLEBURG","code":"US-PA-LRG"},
          {"name":"MIDDLEBURY CENTER","code":"US-PA-URY"},
          {"name":"MIDDLETON","code":"US-PA-YYV"},
          {"name":"MIDDLETOWN","code":"US-PA-UQT"},
          {"name":"MIDLAND","code":"US-PA-ZID"},
          {"name":"MIFFLINBURG","code":"US-PA-IFB"},
          {"name":"MIFFLINTOWN","code":"US-PA-M2I"},
          {"name":"MIFFLINVILLE","code":"US-PA-MFN"},
          {"name":"MILAN","code":"US-PA-ZIM"},
          {"name":"MILDRED","code":"US-PA-JMD"},
          {"name":"MILESBURG","code":"US-PA-UQY"},
          {"name":"MILFORD","code":"US-PA-MFQ"},
          {"name":"MILL CREEK","code":"US-PA-MC3"},
          {"name":"MILL HALL","code":"US-PA-MHP"},
          {"name":"MILLERSBURG","code":"US-PA-MUG"},
          {"name":"MILLERSTOWN","code":"US-PA-QJT"},
          {"name":"MILLERSVILLE","code":"US-PA-ISE"},
          {"name":"MILLHEIM","code":"US-PA-OZB"},
          {"name":"MILLMONT","code":"US-PA-IMY"},
          {"name":"MILLVALE","code":"US-PA-3LV"},
          {"name":"MILLVILLE","code":"US-PA-MV2"},
          {"name":"MILROY","code":"US-PA-MZE"},
          {"name":"MILTON","code":"US-PA-MIC"},
          {"name":"MINERAL SPRINGS","code":"US-PA-YY3"},
          {"name":"MINERSVILLE","code":"US-PA-MVP"},
          {"name":"MIQUON","code":"US-PA-IQU"},
          {"name":"MODENA","code":"US-PA-MEY"},
          {"name":"MOHNTON","code":"US-PA-ZOH"},
          {"name":"MOHRSVILLE","code":"US-PA-OH7"},
          {"name":"MONACA","code":"US-PA-MNP"},
          {"name":"MONESSEN","code":"US-PA-QJU"},
          {"name":"MONONGAHELA","code":"US-PA-MXX"},
          {"name":"MONROEVILLE","code":"US-PA-VML"},
          {"name":"MONT ALTO","code":"US-PA-M5A"},
          {"name":"MONTELLO","code":"US-PA-M4O"},
          {"name":"MONTGOMERY","code":"US-PA-QMG"},
          {"name":"MONTGOMERYVILLE","code":"US-PA-UXG"},
          {"name":"MONTOURSVILLE","code":"US-PA-MBC"},
          {"name":"MONTROSE","code":"US-PA-QMR"},
          {"name":"MOON","code":"US-PA-NIP"},
          {"name":"MOON TOWNSHIP","code":"US-PA-M7P"},
          {"name":"MOONSTOWN","code":"US-PA-QMP"},
          {"name":"MOORESTOWN","code":"US-PA-OOR"},
          {"name":"MOOSIC","code":"US-PA-UXB"},
          {"name":"MORGAN","code":"US-PA-MR9"},
          {"name":"MORGANTOWN","code":"US-PA-MGP"},
          {"name":"MORRIS","code":"US-PA-IQS"},
          {"name":"MORRISVILLE","code":"US-PA-XIO"},
          {"name":"MORTON","code":"US-PA-JZN"},
          {"name":"MOSCOW","code":"US-PA-QMA"},
          {"name":"MOUNT BETHEL","code":"US-PA-MB5"},
          {"name":"MOUNT BETHEL","code":"US-PA-MXT"},
          {"name":"MOUNT BRADDOCK","code":"US-PA-TBK"},
          {"name":"MOUNT CARMEL","code":"US-PA-MTK"},
          {"name":"MOUNT GRETNA","code":"US-PA-M5G"},
          {"name":"MOUNT HOLLY SPRINGS","code":"US-PA-QHS"},
          {"name":"MOUNT JEWETT","code":"US-PA-OJW"},
          {"name":"MOUNT JOY","code":"US-PA-MJ2"},
          {"name":"MOUNT JOY","code":"US-PA-MJY"},
          {"name":"MOUNT MORRIS","code":"US-PA-UNS"},
          {"name":"MOUNT PLEASANT","code":"US-PA-MTT"},
          {"name":"MOUNT PLEASANT MILLS","code":"US-PA-UPI"},
          {"name":"MOUNT POCONO","code":"US-PA-MPO"},
          {"name":"MOUNT UNION","code":"US-PA-MUU"},
          {"name":"MOUNT UNION, HUNTINGDON","code":"US-PA-ONU"},
          {"name":"MOUNT WOLF","code":"US-PA-MWF"},
          {"name":"MOUNTAIN TOP","code":"US-PA-MUV"},
          {"name":"MOUNTVILLE","code":"US-PA-XJH"},
          {"name":"MUHLENBERG","code":"US-PA-H98"},
          {"name":"MUNCY","code":"US-PA-MUN"},
          {"name":"MURRYSVILLE","code":"US-PA-XYS"},
          {"name":"MYERSTOWN","code":"US-PA-YES"},
          {"name":"NANTICOKE","code":"US-PA-NNC"},
          {"name":"NARBERTH","code":"US-PA-N84"},
          {"name":"NARVON","code":"US-PA-NVO"},
          {"name":"NARVON","code":"US-PA-WZQ"},
          {"name":"NATRONA HEIGHTS","code":"US-PA-NAH"},
          {"name":"NAZARETH","code":"US-PA-NAZ"},
          {"name":"NEEDMORE","code":"US-PA-NZQ"},
          {"name":"NESCOPECK","code":"US-PA-NE3"},
          {"name":"NESQUEHONING","code":"US-PA-NSQ"},
          {"name":"NEVILLE ISLAND","code":"US-PA-NVI"},
          {"name":"NEW ALBANY","code":"US-PA-NJA"},
          {"name":"NEW ALEXANDRIA","code":"US-PA-NA3"},
          {"name":"NEW BALTIMORE","code":"US-PA-NB9"},
          {"name":"NEW BERLIN","code":"US-PA-NUY"},
          {"name":"NEW BETHLEHEM","code":"US-PA-NEH"},
          {"name":"NEW BRIGHTON","code":"US-PA-NET"},
          {"name":"NEW BRITAIN","code":"US-PA-NXB"},
          {"name":"NEW BUFFALO","code":"US-PA-NB6"},
          {"name":"NEW CASTLE","code":"US-PA-NCP"},
          {"name":"NEW CENTERVILLE","code":"US-PA-NVP"},
          {"name":"NEW COLUMBIA","code":"US-PA-ZWC"},
          {"name":"NEW CUMBERLAND","code":"US-PA-NWD"},
          {"name":"NEW EAGLE","code":"US-PA-NXG"},
          {"name":"NEW ENTERPRISE","code":"US-PA-NP7"},
          {"name":"NEW FLORENCE","code":"US-PA-NFQ"},
          {"name":"NEW FREEDOM","code":"US-PA-NFR"},
          {"name":"NEW GALILEE","code":"US-PA-IWG"},
          {"name":"NEW GENEVA","code":"US-PA-NG8"},
          {"name":"NEW HOLLAND","code":"US-PA-UXL"},
          {"name":"NEW HOPE","code":"US-PA-NHI"},
          {"name":"NEW KENSINGTON","code":"US-PA-NKP"},
          {"name":"NEW KINGSTOWN","code":"US-PA-NEF"},
          {"name":"NEW LONDON","code":"US-PA-NW7"},
          {"name":"NEW MILFORD","code":"US-PA-NMD"},
          {"name":"NEW OXFORD","code":"US-PA-NXF"},
          {"name":"NEW PHILADELPHIA","code":"US-PA-NPP"},
          {"name":"NEW PROVIDENCE","code":"US-PA-NVC"},
          {"name":"NEW RINGGOLD","code":"US-PA-N4R"},
          {"name":"NEW SALEM","code":"US-PA-NWS"},
          {"name":"NEW STANTON","code":"US-PA-NST"},
          {"name":"NEW TRIPOLI","code":"US-PA-N5T"},
          {"name":"NEW WILMINGTON","code":"US-PA-NEU"},
          {"name":"NEWELL","code":"US-PA-NL6"},
          {"name":"NEWMANSTOWN","code":"US-PA-UWN"},
          {"name":"NEWPORT, PERRY","code":"US-PA-NRP"},
          {"name":"NEWPORTVILLE","code":"US-PA-QNE"},
          {"name":"NEWRY","code":"US-PA-NQR"},
          {"name":"NEWTOWN","code":"US-PA-NTW"},
          {"name":"NEWTOWN SQUARE","code":"US-PA-NTQ"},
          {"name":"NEWVILLE","code":"US-PA-ZNE"},
          {"name":"NICETOWN","code":"US-PA-NT3"},
          {"name":"NICHOLSON","code":"US-PA-NI7"},
          {"name":"NIXON","code":"US-PA-NIX"},
          {"name":"NORMALVILLE","code":"US-PA-4OR"},
          {"name":"NORRISTOWN","code":"US-PA-UXQ"},
          {"name":"NORTH BEND","code":"US-PA-ND9"},
          {"name":"NORTH EAST","code":"US-PA-NTE"},
          {"name":"NORTH HUNTINGDON","code":"US-PA-NHU"},
          {"name":"NORTH MIDDLETON","code":"US-PA-PN9"},
          {"name":"NORTH VERSAILLES","code":"US-PA-ZJY"},
          {"name":"NORTH WALES","code":"US-PA-NFT"},
          {"name":"NORTHAMPTON","code":"US-PA-QNH"},
          {"name":"NORTHERN CAMBRIA","code":"US-PA-EJB"},
          {"name":"NORTHUMBERLAND","code":"US-PA-NUM"},
          {"name":"NORWOOD","code":"US-PA-NXL"},
          {"name":"NOTTINGHAM","code":"US-PA-NHG"},
          {"name":"OAK","code":"US-PA-OKP"},
          {"name":"OAKDALE","code":"US-PA-UXR"},
          {"name":"OAKLAND","code":"US-PA-OV5"},
          {"name":"OAKMONT","code":"US-PA-UXS"},
          {"name":"OAKS","code":"US-PA-OAS"},
          {"name":"OHIO","code":"US-PA-OHB"},
          {"name":"OHIOPYLE","code":"US-PA-OHY"},
          {"name":"OHIOVIEW","code":"US-PA-OHV"},
          {"name":"OIL CITY","code":"US-PA-OIL"},
          {"name":"OLANTA","code":"US-PA-OXT"},
          {"name":"OLD FORGE","code":"US-PA-XJZ"},
          {"name":"OLEY","code":"US-PA-3LY"},
          {"name":"OLYPHANT","code":"US-PA-QOA"},
          {"name":"ONO","code":"US-PA-ONP"},
          {"name":"ORANGEVILLE","code":"US-PA-OV2"},
          {"name":"OREFIELD","code":"US-PA-XKD"},
          {"name":"ORELAND","code":"US-PA-ODJ"},
          {"name":"ORRSTOWN","code":"US-PA-OWN"},
          {"name":"ORRTANNA","code":"US-PA-OTP"},
          {"name":"ORWIGSBURG","code":"US-PA-OWP"},
          {"name":"OTTSVILLE","code":"US-PA-OSV"},
          {"name":"OXFORD","code":"US-PA-ZXO"},
          {"name":"PALM","code":"US-PA-PQM"},
          {"name":"PALMER","code":"US-PA-PL2"},
          {"name":"PALMERTON","code":"US-PA-PLA"},
          {"name":"PALMERTOWN","code":"US-PA-PMZ"},
          {"name":"PALMYRA","code":"US-PA-PYY"},
          {"name":"PAOLI","code":"US-PA-AOL"},
          {"name":"PARADISE","code":"US-PA-3PL"},
          {"name":"PARK JUNCTION","code":"US-PA-PKJ"},
          {"name":"PARKER FORD","code":"US-PA-PKR"},
          {"name":"PARKESBURG","code":"US-PA-PKP"},
          {"name":"PARKWOOD","code":"US-PA-PGW"},
          {"name":"PARNASSUS","code":"US-PA-UVP"},
          {"name":"PAXINOS","code":"US-PA-PXS"},
          {"name":"PEACH BOTTOM","code":"US-PA-YL2"},
          {"name":"PEACH GLEN","code":"US-PA-PCG"},
          {"name":"PECKVILLE","code":"US-PA-PK2"},
          {"name":"PEN ARGYL","code":"US-PA-PYD"},
          {"name":"PENFIELD","code":"US-PA-PF7"},
          {"name":"PENN RUN","code":"US-PA-PNR"},
          {"name":"PENNDEL","code":"US-PA-PEN"},
          {"name":"PENNSBURG","code":"US-PA-NNJ"},
          {"name":"PENNSDALE","code":"US-PA-QVZ"},
          {"name":"PENNSYLVANIA FURNACE","code":"US-PA-QV2"},
          {"name":"PEQUEA","code":"US-PA-QKA"},
          {"name":"PERKASIE","code":"US-PA-PKS"},
          {"name":"PERKIOMENVILLE","code":"US-PA-KMV"},
          {"name":"PERRYOPOLIS","code":"US-PA-PYS"},
          {"name":"PETERSBURG","code":"US-PA-PBU"},
          {"name":"PETROLIA","code":"US-PA-PEO"},
          {"name":"PHILADELPHIA","code":"US-PA-PHL"},
          {"name":"PHILADELPHIA NORTH EAST APT","code":"US-PA-PNE"},
          {"name":"PHILIPSBURG","code":"US-PA-ZPC"},
          {"name":"PHOENIXVILLE","code":"US-PA-PXV"},
          {"name":"PILLOW","code":"US-PA-PP7"},
          {"name":"PINE FORGE","code":"US-PA-PF6"},
          {"name":"PINE GROVE","code":"US-PA-PZG"},
          {"name":"PINE GROVE, CLEARFIELD","code":"US-PA-PGJ"},
          {"name":"PIPERSVILLE","code":"US-PA-PEV"},
          {"name":"PITCAIRN","code":"US-PA-PCF"},
          {"name":"PITMAN","code":"US-PA-IMJ"},
          {"name":"PITTSBURG","code":"US-PA-PI9"},
          {"name":"PITTSBURGH","code":"US-PA-PIT"},
          {"name":"PITTSFIELD","code":"US-PA-PTZ"},
          {"name":"PITTSTON","code":"US-PA-PTO"},
          {"name":"PITTSVILLE","code":"US-PA-PVQ"},
          {"name":"PLAINS","code":"US-PA-LPY"},
          {"name":"PLEASANT GAP","code":"US-PA-PNG"},
          {"name":"PLEASANT HALL","code":"US-PA-P8H"},
          {"name":"PLEASANT HILLS","code":"US-PA-PH7"},
          {"name":"PLEASANT UNITY","code":"US-PA-PU7"},
          {"name":"PLUMSTEADVILLE","code":"US-PA-PUV"},
          {"name":"PLYMOUTH","code":"US-PA-PMY"},
          {"name":"PLYMOUTH MEETING","code":"US-PA-PMM"},
          {"name":"POCONO MANOR","code":"US-PA-POR"},
          {"name":"POCONO PINES","code":"US-PA-9PP"},
          {"name":"POCONO SUMMIT","code":"US-PA-P9S"},
          {"name":"POINT BREEZE","code":"US-PA-PB9"},
          {"name":"POINT LOOKOUT","code":"US-PA-UPX"},
          {"name":"POINT MARION","code":"US-PA-TMD"},
          {"name":"POLK","code":"US-PA-PG7"},
          {"name":"POMEROY","code":"US-PA-2PM"},
          {"name":"PORT ALLEGANY","code":"US-PA-PTG"},
          {"name":"PORT CARBON","code":"US-PA-P5C"},
          {"name":"PORT PROVIDENCE","code":"US-PA-PPR"},
          {"name":"PORT RICHMOND","code":"US-PA-RI4"},
          {"name":"PORT TREVORTON","code":"US-PA-PVT"},
          {"name":"PORTAGE","code":"US-PA-PJG"},
          {"name":"PORTERSVILLE","code":"US-PA-JRW"},
          {"name":"PORTLAND","code":"US-PA-XPO"},
          {"name":"PORTLAND MILLS","code":"US-PA-PM6"},
          {"name":"POTTSTOWN","code":"US-PA-PTW"},
          {"name":"POTTSVILLE","code":"US-PA-PSV"},
          {"name":"PROSPECT PARK","code":"US-PA-PPK"},
          {"name":"PROSPECT, BUTLER","code":"US-PA-PS7"},
          {"name":"PULASKI, LAWRENCE","code":"US-PA-PXU"},
          {"name":"PUNXSUTAWNEY","code":"US-PA-PXW"},
          {"name":"QUAKERTOWN","code":"US-PA-UKT"},
          {"name":"QUARRYVILLE","code":"US-PA-ZQE"},
          {"name":"RADNOR","code":"US-PA-RAR"},
          {"name":"RAHNS","code":"US-PA-RHN"},
          {"name":"RANKIN","code":"US-PA-YYZ"},
          {"name":"RANSOM","code":"US-PA-RNS"},
          {"name":"READING","code":"US-PA-RDG"},
          {"name":"REAMSTOWN","code":"US-PA-REK"},
          {"name":"RED HILL","code":"US-PA-RHY"},
          {"name":"RED LION","code":"US-PA-RLN"},
          {"name":"REEDSVILLE","code":"US-PA-REL"},
          {"name":"REHRERSBURG","code":"US-PA-R5B"},
          {"name":"REINHOLDS","code":"US-PA-R9H"},
          {"name":"RENFREW","code":"US-PA-XZQ"},
          {"name":"RENO","code":"US-PA-RNA"},
          {"name":"REYNOLDSVILLE","code":"US-PA-YRY"},
          {"name":"RHEEMS","code":"US-PA-JRA"},
          {"name":"RICHBORO","code":"US-PA-RQC"},
          {"name":"RICHFIELD","code":"US-PA-RF8"},
          {"name":"RICHLAND","code":"US-PA-RZK"},
          {"name":"RICHLANDTOWN","code":"US-PA-RC7"},
          {"name":"RIDDLESBURG","code":"US-PA-RB6"},
          {"name":"RIDGWAY","code":"US-PA-XLE"},
          {"name":"RIDLEY PARK","code":"US-PA-RPK"},
          {"name":"RIMERSBURG","code":"US-PA-RB3"},
          {"name":"RINGGOLD","code":"US-PA-RJO"},
          {"name":"RINGTOWN","code":"US-PA-RGN"},
          {"name":"RIVERSIDE","code":"US-PA-RI7"},
          {"name":"ROARING BRANCH","code":"US-PA-R84"},
          {"name":"ROARING SPRING","code":"US-PA-RRS"},
          {"name":"ROBESONIA","code":"US-PA-RBE"},
          {"name":"ROCHESTER","code":"US-PA-RHR"},
          {"name":"ROCKWOOD","code":"US-PA-RWU"},
          {"name":"RONKS","code":"US-PA-ROJ"},
          {"name":"ROSEMONT","code":"US-PA-ROP"},
          {"name":"ROSETO","code":"US-PA-R9T"},
          {"name":"ROTHSVILLE","code":"US-PA-R2E"},
          {"name":"ROUSEVILLE","code":"US-PA-RYV"},
          {"name":"ROYERSFORD","code":"US-PA-UYH"},
          {"name":"RUFFS DALE","code":"US-PA-RFE"},
          {"name":"RURAL VALLEY","code":"US-PA-R5V"},
          {"name":"RUSHLAND","code":"US-PA-RU3"},
          {"name":"SACRAMENTO","code":"US-PA-TKG"},
          {"name":"SADSBURYVILLE","code":"US-PA-UDV"},
          {"name":"SAEGERTOWN","code":"US-PA-XLL"},
          {"name":"SAINT CLAIR","code":"US-PA-SKZ"},
          {"name":"SAINT CLAIR","code":"US-PA-ST7"},
          {"name":"SAINT MARYS","code":"US-PA-STQ"},
          {"name":"SAINT PETERS","code":"US-PA-QSZ"},
          {"name":"SAINT THOMAS","code":"US-PA-XTM"},
          {"name":"SALISBURY","code":"US-PA-IUR"},
          {"name":"SALTSBURG","code":"US-PA-ZXB"},
          {"name":"SALUNGA","code":"US-PA-6SA"},
          {"name":"SANDY LAKE","code":"US-PA-AYL"},
          {"name":"SARVER","code":"US-PA-SJ9"},
          {"name":"SAXONBURG","code":"US-PA-SB2"},
          {"name":"SAXTON","code":"US-PA-AX2"},
          {"name":"SAYLORSBURG","code":"US-PA-YGB"},
          {"name":"SAYRE","code":"US-PA-AYJ"},
          {"name":"SCHAEFFERSTOWN","code":"US-PA-SFT"},
          {"name":"SCHENLEY","code":"US-PA-SCP"},
          {"name":"SCHNECKSVILLE","code":"US-PA-SK5"},
          {"name":"SCHUYLKILL","code":"US-PA-SKL"},
          {"name":"SCHUYLKILL HAVEN","code":"US-PA-H7A"},
          {"name":"SCHUYLKILL HAVEN","code":"US-PA-SKU"},
          {"name":"SCHWENKSVILLE","code":"US-PA-S77"},
          {"name":"SCOTLAND","code":"US-PA-9SD"},
          {"name":"SCOTT TOWNSHIP","code":"US-PA-TW6"},
          {"name":"SCOTTDALE","code":"US-PA-SZE"},
          {"name":"SCRANTON","code":"US-PA-SCR"},
          {"name":"SECANE","code":"US-PA-SC4"},
          {"name":"SEIPLE","code":"US-PA-SEC"},
          {"name":"SELINSGROVE","code":"US-PA-SEG"},
          {"name":"SELLERSVILLE","code":"US-PA-XVI"},
          {"name":"SENECA","code":"US-PA-ZYC"},
          {"name":"SEVEN FIELDS","code":"US-PA-SV9"},
          {"name":"SEVEN VALLEYS","code":"US-PA-KVV"},
          {"name":"SEWICKLEY","code":"US-PA-EWC"},
          {"name":"SHADE GAP","code":"US-PA-DGP"},
          {"name":"SHADY GROVE","code":"US-PA-DYG"},
          {"name":"SHAMOKIN","code":"US-PA-YSH"},
          {"name":"SHARON","code":"US-PA-SHQ"},
          {"name":"SHARON HILL","code":"US-PA-SHL"},
          {"name":"SHARPSBURG","code":"US-PA-S7G"},
          {"name":"SHARPSVILLE","code":"US-PA-SJS"},
          {"name":"SHARTLESVILLE","code":"US-PA-SL8"},
          {"name":"SHAVERTOWN","code":"US-PA-S9V"},
          {"name":"SHAWNEE ON DELAWARE","code":"US-PA-8SD"},
          {"name":"SHEFFIELD","code":"US-PA-S5D"},
          {"name":"SHELOCTA","code":"US-PA-S8H"},
          {"name":"SHEPPTON","code":"US-PA-9SP"},
          {"name":"SHERMANS DALE","code":"US-PA-SML"},
          {"name":"SHICKSHINNY","code":"US-PA-SVB"},
          {"name":"SHIPPENSBURG","code":"US-PA-SSG"},
          {"name":"SHIPPENVILLE","code":"US-PA-YNV"},
          {"name":"SHIPPINGPORT","code":"US-PA-ZHP"},
          {"name":"SHIREMANSTOWN","code":"US-PA-SVF"},
          {"name":"SHIRLEYSBURG","code":"US-PA-SB6"},
          {"name":"SHOEMAKERSVILLE","code":"US-PA-KVM"},
          {"name":"SHOHOLA","code":"US-PA-H78"},
          {"name":"SHREWSBURY","code":"US-PA-QSY"},
          {"name":"SIDMAN","code":"US-PA-S4N"},
          {"name":"SILVERDALE","code":"US-PA-S4D"},
          {"name":"SIMPSON","code":"US-PA-UKJ"},
          {"name":"SINKING SPRING","code":"US-PA-SKS"},
          {"name":"SKIPPACK","code":"US-PA-SP3"},
          {"name":"SLATEDALE","code":"US-PA-ZTD"},
          {"name":"SLATINGTON","code":"US-PA-SVP"},
          {"name":"SLIPPERY ROCK","code":"US-PA-SVT"},
          {"name":"SMETHPORT","code":"US-PA-TTH"},
          {"name":"SMITHFIELD","code":"US-PA-SF3"},
          {"name":"SMITHTON","code":"US-PA-ITN"},
          {"name":"SMOCK","code":"US-PA-QSQ"},
          {"name":"SMOKETOWN","code":"US-PA-KTW"},
          {"name":"SNOW SHOE","code":"US-PA-YWV"},
          {"name":"SOMERSET","code":"US-PA-SZR"},
          {"name":"SOUDERTON","code":"US-PA-SOT"},
          {"name":"SOUTH ABINGTON","code":"US-PA-A9B"},
          {"name":"SOUTH CANAAN","code":"US-PA-CQ2"},
          {"name":"SOUTH FORK","code":"US-PA-SF4"},
          {"name":"SOUTH PARK","code":"US-PA-S98"},
          {"name":"SOUTH WILLAMSPORT","code":"US-PA-IOH"},
          {"name":"SOUTHAMPTON","code":"US-PA-SJL"},
          {"name":"SOUTHWARK","code":"US-PA-XMR"},
          {"name":"SPARTANSBURG","code":"US-PA-SPB"},
          {"name":"SPRAGGS","code":"US-PA-SP9"},
          {"name":"SPRING CITY","code":"US-PA-UYL"},
          {"name":"SPRING GROVE","code":"US-PA-ZSG"},
          {"name":"SPRING HOUSE","code":"US-PA-SFH"},
          {"name":"SPRING MILLS","code":"US-PA-P7M"},
          {"name":"SPRINGDALE","code":"US-PA-SPD"},
          {"name":"SPRINGFIELD","code":"US-PA-NGF"},
          {"name":"SPROUL","code":"US-PA-YSP"},
          {"name":"ST. PETERSBURG","code":"US-PA-P4T"},
          {"name":"STATE COLLEGE","code":"US-PA-SCE"},
          {"name":"STEELTON","code":"US-PA-QQX"},
          {"name":"STERLING","code":"US-PA-SEK"},
          {"name":"STEVENS","code":"US-PA-SS7"},
          {"name":"STOCKERTOWN","code":"US-PA-STT"},
          {"name":"STONEBORO","code":"US-PA-TOF"},
          {"name":"STOWE","code":"US-PA-XTW"},
          {"name":"STOYSTOWN","code":"US-PA-QST"},
          {"name":"STRAFFORD","code":"US-PA-S8F"},
          {"name":"STRASBURG","code":"US-PA-QQZ"},
          {"name":"STRAUSSTOWN","code":"US-PA-STW"},
          {"name":"STRINESTOWN","code":"US-PA-XMS"},
          {"name":"STRONGSTOWN","code":"US-PA-QGN"},
          {"name":"STROUDSBURG","code":"US-PA-SDG"},
          {"name":"SUGAR GROVE","code":"US-PA-SGX"},
          {"name":"SUGAR RUN","code":"US-PA-S9N"},
          {"name":"SUMMERHILL","code":"US-PA-SUH"},
          {"name":"SUMMERVILLE","code":"US-PA-URV"},
          {"name":"SUMMIT","code":"US-PA-SZS"},
          {"name":"SUMMIT STATION","code":"US-PA-SM9"},
          {"name":"SUMNEYTOWN","code":"US-PA-S5N"},
          {"name":"SUNBURY","code":"US-PA-SRJ"},
          {"name":"SUSQUEHANNA","code":"US-PA-SH7"},
          {"name":"SUTERSVILLE","code":"US-PA-SV6"},
          {"name":"SWARTHMORE/PHILADELPHIA","code":"US-PA-XWR"},
          {"name":"SWATARA","code":"US-PA-SW7"},
          {"name":"SWIFTWATER","code":"US-PA-IWT"},
          {"name":"SWISSVALE","code":"US-PA-SWI"},
          {"name":"SYKESVILLE","code":"US-PA-S6K"},
          {"name":"TAFTON","code":"US-PA-TFX"},
          {"name":"TAMAQUA","code":"US-PA-TMQ"},
          {"name":"TANNERSVILLE","code":"US-PA-TQV"},
          {"name":"TARENTUM","code":"US-PA-TTM"},
          {"name":"TATAMY","code":"US-PA-AY6"},
          {"name":"TAYLOR","code":"US-PA-TYP"},
          {"name":"TELFORD","code":"US-PA-TEF"},
          {"name":"TEMPLE","code":"US-PA-TMP"},
          {"name":"TERRE HILL","code":"US-PA-TRD"},
          {"name":"THOMASVILLE","code":"US-PA-TMV"},
          {"name":"THOMPSONTOWN","code":"US-PA-TPW"},
          {"name":"THORNDALE","code":"US-PA-THR"},
          {"name":"THROOP","code":"US-PA-TPY"},
          {"name":"TIOGA","code":"US-PA-TIG"},
          {"name":"TIONESTA","code":"US-PA-TQT"},
          {"name":"TIPTON","code":"US-PA-TTP"},
          {"name":"TITUSVILLE","code":"US-PA-TIT"},
          {"name":"TOBYHANNA","code":"US-PA-TBP"},
          {"name":"TOMHICKEN","code":"US-PA-YTO"},
          {"name":"TOPTON","code":"US-PA-XNF"},
          {"name":"TOUGHKENAMON","code":"US-PA-ZTU"},
          {"name":"TOWANDA","code":"US-PA-TOW"},
          {"name":"TOWER CITY","code":"US-PA-TWC"},
          {"name":"TRAFFORD","code":"US-PA-TFF"},
          {"name":"TRAINER","code":"US-PA-TRA"},
          {"name":"TRANSFER","code":"US-PA-TFR"},
          {"name":"TRAPPE","code":"US-PA-TP4"},
          {"name":"TREMONT","code":"US-PA-TOX"},
          {"name":"TREVORTON","code":"US-PA-TVR"},
          {"name":"TREVOSE","code":"US-PA-TVS"},
          {"name":"TROY","code":"US-PA-YTY"},
          {"name":"TRUMBAUERSVILLE","code":"US-PA-TBV"},
          {"name":"TULLYTOWN","code":"US-PA-TTW"},
          {"name":"TUNKHANNOCK","code":"US-PA-TKK"},
          {"name":"TURTLE CREEK","code":"US-PA-TLC"},
          {"name":"TUSCARORA","code":"US-PA-TZC"},
          {"name":"TWIN ROCKS","code":"US-PA-2RK"},
          {"name":"TYLERSPORT","code":"US-PA-TP8"},
          {"name":"TYRONE","code":"US-PA-XNK"},
          {"name":"ULYSSES","code":"US-PA-ZLQ"},
          {"name":"UNION","code":"US-PA-UNI"},
          {"name":"UNION CITY","code":"US-PA-UNN"},
          {"name":"UNION DALE","code":"US-PA-UDP"},
          {"name":"UNIONTOWN","code":"US-PA-UTN"},
          {"name":"UNIONVILLE","code":"US-PA-UV9"},
          {"name":"UNIVERSITY PARK","code":"US-PA-UK7"},
          {"name":"UPPER CHICHESTER","code":"US-PA-9UC"},
          {"name":"UPPER DARBY","code":"US-PA-UDY"},
          {"name":"UWCHLAND","code":"US-PA-UWC"},
          {"name":"VALENCIA","code":"US-PA-V7E"},
          {"name":"VALLEY FORGE","code":"US-PA-VFG"},
          {"name":"VALLEY VIEW","code":"US-PA-VVW"},
          {"name":"VANDERGRIFT","code":"US-PA-VGF"},
          {"name":"VANPORT","code":"US-PA-VPV"},
          {"name":"VERONA","code":"US-PA-VEO"},
          {"name":"VILLANOVA","code":"US-PA-P3V"},
          {"name":"VOGLEYVILLE","code":"US-PA-VOY"},
          {"name":"VOLANT","code":"US-PA-VL2"},
          {"name":"WAGONTOWN","code":"US-PA-WGW"},
          {"name":"WALKERTOWN","code":"US-PA-WUA"},
          {"name":"WALL","code":"US-PA-AWP"},
          {"name":"WALLINGFORD","code":"US-PA-WF5"},
          {"name":"WALNUTPORT","code":"US-PA-WNU"},
          {"name":"WALSTON","code":"US-PA-W4A"},
          {"name":"WAMPUM","code":"US-PA-WMM"},
          {"name":"WAPWALLOPEN","code":"US-PA-JWW"},
          {"name":"WARFORDSBURG","code":"US-PA-YWF"},
          {"name":"WARMINSTER","code":"US-PA-WRM"},
          {"name":"WARREN","code":"US-PA-WPA"},
          {"name":"WARREN CENTER","code":"US-PA-W6B"},
          {"name":"WARRENDALE","code":"US-PA-WRR"},
          {"name":"WARRINGTON","code":"US-PA-WRA"},
          {"name":"WASHINGTON","code":"US-PA-WSG"},
          {"name":"WASHINGTON CROSSING","code":"US-PA-WCG"},
          {"name":"WASHINGTONVILLE","code":"US-PA-WG8"},
          {"name":"WATERFORD","code":"US-PA-WXX"},
          {"name":"WATERVILLE","code":"US-PA-999"},
          {"name":"WATSONTOWN","code":"US-PA-ASW"},
          {"name":"WAYMART","code":"US-PA-WYR"},
          {"name":"WAYNE","code":"US-PA-WNP"},
          {"name":"WAYNESBORO","code":"US-PA-WYO"},
          {"name":"WAYNESBURG","code":"US-PA-WAY"},
          {"name":"WAYNESVILLE","code":"US-PA-WNV"},
          {"name":"WEATHERLY","code":"US-PA-WEH"},
          {"name":"WEISSPORT","code":"US-PA-IPB"},
          {"name":"WELLSBORO","code":"US-PA-WBP"},
          {"name":"WELLSVILLE","code":"US-PA-KJZ"},
          {"name":"WENDEL","code":"US-PA-YWY"},
          {"name":"WERNERSVILLE","code":"US-PA-WVO"},
          {"name":"WEST ALEXANDER","code":"US-PA-W8A"},
          {"name":"WEST CHESTER","code":"US-PA-WCT"},
          {"name":"WEST CONSHOHOCKEN","code":"US-PA-WCK"},
          {"name":"WEST EASTON","code":"US-PA-W97"},
          {"name":"WEST ELIZABETH","code":"US-PA-WEZ"},
          {"name":"WEST GROVE","code":"US-PA-L3Y"},
          {"name":"WEST HAZELTON","code":"US-PA-WZN"},
          {"name":"WEST HAZLETON","code":"US-PA-WHA"},
          {"name":"WEST HOMESTEAD","code":"US-PA-YWE"},
          {"name":"WEST LAWN (BERK COUNTY)","code":"US-PA-L9W"},
          {"name":"WEST MIDDLESEX","code":"US-PA-WMN"},
          {"name":"WEST MIFFLIN","code":"US-PA-WMF"},
          {"name":"WEST PITTSBURG","code":"US-PA-TPB"},
          {"name":"WEST PITTSTON","code":"US-PA-WQP"},
          {"name":"WEST POINT","code":"US-PA-WTP"},
          {"name":"WEST READING","code":"US-PA-ZDG"},
          {"name":"WEST YORK","code":"US-PA-WYK"},
          {"name":"WESTMORELAND","code":"US-PA-WML"},
          {"name":"WESTMORELAND CITY","code":"US-PA-WY9"},
          {"name":"WESTPORT","code":"US-PA-WPP"},
          {"name":"WEXFORD","code":"US-PA-WXD"},
          {"name":"WHEATLAND","code":"US-PA-WGL"},
          {"name":"WHITE DEER","code":"US-PA-WD4"},
          {"name":"WHITE HAVEN","code":"US-PA-W2H"},
          {"name":"WHITE OAK","code":"US-PA-WXB"},
          {"name":"WHITEHALL","code":"US-PA-WHY"},
          {"name":"WILCOX","code":"US-PA-YWI"},
          {"name":"WILDWOOD","code":"US-PA-IOD"},
          {"name":"WILKES-BARRE","code":"US-PA-WBE"},
          {"name":"WILKES-BARRE/SCRANTON APT","code":"US-PA-AVP"},
          {"name":"WILKINSBURG","code":"US-PA-W4K"},
          {"name":"WILLIAMSBURG","code":"US-PA-W54"},
          {"name":"WILLIAMSPORT","code":"US-PA-IPT"},
          {"name":"WILLIAMSTOWN","code":"US-PA-WZP"},
          {"name":"WILLOW GROVE","code":"US-PA-NXX"},
          {"name":"WILLOW HILL","code":"US-PA-IWW"},
          {"name":"WILLOW STREET","code":"US-PA-5WS"},
          {"name":"WILMERDING","code":"US-PA-IME"},
          {"name":"WINBURNE","code":"US-PA-YXW"},
          {"name":"WIND GAP","code":"US-PA-WNG"},
          {"name":"WIND RIDGE","code":"US-PA-W56"},
          {"name":"WINDBER","code":"US-PA-WID"},
          {"name":"WINFIELD","code":"US-PA-WFV"},
          {"name":"WISSAHICKON","code":"US-PA-WIZ"},
          {"name":"WOMELSDORF","code":"US-PA-WDF"},
          {"name":"WOODLAND","code":"US-PA-ZND"},
          {"name":"WOOLRICH","code":"US-PA-YWR"},
          {"name":"WORCESTER","code":"US-PA-KOW"},
          {"name":"WORTHINGTON","code":"US-PA-VWH"},
          {"name":"WRIGHTSVILLE","code":"US-PA-XOG"},
          {"name":"WYALUSING","code":"US-PA-WYC"},
          {"name":"WYNCOTE","code":"US-PA-BQ9"},
          {"name":"WYNNEWOOD","code":"US-PA-QWW"},
          {"name":"WYOMING","code":"US-PA-WYP"},
          {"name":"WYOMISSING","code":"US-PA-WGP"},
          {"name":"WYSOX","code":"US-PA-WY4"},
          {"name":"YARDLEY","code":"US-PA-YAP"},
          {"name":"YATESBORO","code":"US-PA-YAT"},
          {"name":"YEADON","code":"US-PA-YVN"},
          {"name":"YEAGERTOWN","code":"US-PA-YT7"},
          {"name":"YOE","code":"US-PA-YEO"},
          {"name":"YORK","code":"US-PA-THV"},
          {"name":"YORK HAVEN","code":"US-PA-YRH"},
          {"name":"YORK SPRINGS","code":"US-PA-YS8"},
          {"name":"YOUNGSTOWN","code":"US-PA-YUT"},
          {"name":"YOUNGSVILLE","code":"US-PA-YOU"},
          {"name":"YOUNGWOOD","code":"US-PA-YGW"},
          {"name":"ZELIENOPLE","code":"US-PA-ZEL"},
          {"name":"ZIEGLERVILLE","code":"US-PA-ZGL"},
          {"name":"ZIONSVILLE","code":"US-PA-ZYV"},
          {"name":"ZULLINGER","code":"US-PA-ZUL"},
        ]
      },
      {
        name: 'Puerto Rico',
        code: 'US-PR',
        cities: [{"name":"SAN JOSE","code":"US-PR-S2J"},]
      },
      {
        name: 'Rhode Island',
        code: 'US-RI',
        cities: [
          {"name":"ASHAWAY","code":"US-RI-AHW"},
          {"name":"ASHTON","code":"US-RI-AS2"},
          {"name":"BARRINGTON","code":"US-RI-BRZ"},
          {"name":"BERKELEY","code":"US-RI-BQU"},
          {"name":"BLOCK ISLAND","code":"US-RI-BID"},
          {"name":"BRADFORD","code":"US-RI-BQD"},
          {"name":"BRISTOL","code":"US-RI-BSE"},
          {"name":"CAROLINA","code":"US-RI-ICO"},
          {"name":"CENTRAL FALLS","code":"US-RI-ACH"},
          {"name":"CHARLESTOWN","code":"US-RI-CJW"},
          {"name":"CHEPACHET","code":"US-RI-CCX"},
          {"name":"CLAYVILLE","code":"US-RI-Y9V"},
          {"name":"COVENTRY","code":"US-RI-ADO"},
          {"name":"CRANSTON","code":"US-RI-CQH"},
          {"name":"CUMBERLAND","code":"US-RI-CUE"},
          {"name":"DAVISVILLE","code":"US-RI-DVV"},
          {"name":"EAST GREENWICH","code":"US-RI-UFU"},
          {"name":"EAST PROVIDENCE","code":"US-RI-EPR"},
          {"name":"ESMOND","code":"US-RI-ESM"},
          {"name":"EXETER","code":"US-RI-EXR"},
          {"name":"FISKEVILLE","code":"US-RI-KFI"},
          {"name":"FORESTDALE","code":"US-RI-RH5"},
          {"name":"FOSTER","code":"US-RI-OSR"},
          {"name":"GLENDALE","code":"US-RI-GEJ"},
          {"name":"GLOCESTER","code":"US-RI-GC9"},
          {"name":"GREENVILLE","code":"US-RI-GYE"},
          {"name":"HARRISVILLE","code":"US-RI-HVL"},
          {"name":"HOPE","code":"US-RI-9HP"},
          {"name":"HOPE VALLEY","code":"US-RI-HPV"},
          {"name":"HOPKINTON","code":"US-RI-KK5"},
          {"name":"JAMESTOWN","code":"US-RI-JSW"},
          {"name":"JOHNSTON","code":"US-RI-JOS"},
          {"name":"KENYON","code":"US-RI-KYN"},
          {"name":"KINGSTON","code":"US-RI-KSO"},
          {"name":"LINCOLN","code":"US-RI-LCN"},
          {"name":"LITTLE COMPTON","code":"US-RI-TMT"},
          {"name":"MANVILLE","code":"US-RI-AVR"},
          {"name":"MAPLEVILLE","code":"US-RI-MPX"},
          {"name":"MIDDLETOWN","code":"US-RI-YMT"},
          {"name":"MILLVILLE","code":"US-RI-MVI"},
          {"name":"NARRAGANSETT","code":"US-RI-NGU"},
          {"name":"NASONVILLE","code":"US-RI-NS5"},
          {"name":"NEWPORT","code":"US-RI-NPT"},
          {"name":"NORTH KINGSTON BEACH","code":"US-RI-NK6"},
          {"name":"NORTH KINGSTOWN","code":"US-RI-NKT"},
          {"name":"NORTH PROVIDENCE","code":"US-RI-NP3"},
          {"name":"NORTH SCITUATE","code":"US-RI-JTU"},
          {"name":"NORTH SMITHFIELD","code":"US-RI-NSM"},
          {"name":"OAKLAND","code":"US-RI-DN8"},
          {"name":"PASCOAG","code":"US-RI-PXC"},
          {"name":"PAWTUCKET","code":"US-RI-PAW"},
          {"name":"PEACE DALE","code":"US-RI-PDA"},
          {"name":"POINT JUDITH","code":"US-RI-PJH"},
          {"name":"PORTSMOUTH","code":"US-RI-POM"},
          {"name":"PROVIDENCE","code":"US-RI-PVD"},
          {"name":"QUONSET POINT","code":"US-RI-NCO"},
          {"name":"RICHMOND","code":"US-RI-RXD"},
          {"name":"RIVERSIDE","code":"US-RI-VSE"},
          {"name":"ROCKVILLE","code":"US-RI-RK7"},
          {"name":"RUMFORD","code":"US-RI-RFB"},
          {"name":"SAUNDERSTOWN","code":"US-RI-ZUN"},
          {"name":"SAYLESVILLE","code":"US-RI-AYV"},
          {"name":"SHANNOCK","code":"US-RI-AZK"},
          {"name":"SLATERSVILLE","code":"US-RI-SLV"},
          {"name":"SLATERVILLE","code":"US-RI-RI8"},
          {"name":"SLOCUM","code":"US-RI-UXM"},
          {"name":"SMITHFIELD","code":"US-RI-SFZ"},
          {"name":"SOUTH KINGSTOWN","code":"US-RI-S7K"},
          {"name":"TIVERTON","code":"US-RI-TIV"},
          {"name":"WAKEFIELD","code":"US-RI-AKF"},
          {"name":"WARREN","code":"US-RI-WAJ"},
          {"name":"WARWICK","code":"US-RI-UZO"},
          {"name":"WEST GLOCESTER","code":"US-RI-4WR"},
          {"name":"WEST GREENWICH","code":"US-RI-WEG"},
          {"name":"WEST KINGSTON","code":"US-RI-KXT"},
          {"name":"WEST WARWICK","code":"US-RI-XOJ"},
          {"name":"WESTERLY","code":"US-RI-WST"},
          {"name":"WILDES CORNER","code":"US-RI-XNT"},
          {"name":"WOOD RIVER JUNCTION","code":"US-RI-WJU"},
          {"name":"WOONSOCKET","code":"US-RI-SFN"},
          {"name":"WYOMING","code":"US-RI-YMG"},
        ]
      },
      {
        name: 'Carolina del Sur',
        code: 'US-SC',
        cities: [
          {"name":"ABBEVILLE","code":"US-SC-ABF"},
          {"name":"ABERDEEN","code":"US-SC-XBA"},
          {"name":"ADAMS RUN","code":"US-SC-UAJ"},
          {"name":"AIKEN","code":"US-SC-AIK"},
          {"name":"ALLENDALE","code":"US-SC-EDL"},
          {"name":"ANDERSON","code":"US-SC-AND"},
          {"name":"ANDREWS","code":"US-SC-ADR"},
          {"name":"ARCADIA","code":"US-SC-ARZ"},
          {"name":"AWENDAW","code":"US-SC-AWW"},
          {"name":"AYNOR","code":"US-SC-AY2"},
          {"name":"BALDWIN","code":"US-SC-BAQ"},
          {"name":"BAMBERG","code":"US-SC-BBM"},
          {"name":"BARNWELL","code":"US-SC-BNL"},
          {"name":"BATESBURG","code":"US-SC-BBG"},
          {"name":"BATH","code":"US-SC-XBP"},
          {"name":"BEAUFORT","code":"US-SC-BFT"},
          {"name":"BEECH ISLAND","code":"US-SC-BEE"},
          {"name":"BELTON","code":"US-SC-YBT"},
          {"name":"BENNETTSVILLE","code":"US-SC-BTN"},
          {"name":"BETHUNE","code":"US-SC-BTE"},
          {"name":"BISHOPVILLE","code":"US-SC-BIV"},
          {"name":"BLACKSBURG","code":"US-SC-BBH"},
          {"name":"BLACKSTOCK","code":"US-SC-VBK"},
          {"name":"BLACKVILLE","code":"US-SC-JBI"},
          {"name":"BLAIR","code":"US-SC-BIW"},
          {"name":"BLUFFTON","code":"US-SC-BFQ"},
          {"name":"BLYTHEWOOD","code":"US-SC-UBV"},
          {"name":"BOILING SPRINGS, BARNWELL","code":"US-SC-BPH"},
          {"name":"BONNEAU","code":"US-SC-BU7"},
          {"name":"BOWMAN","code":"US-SC-QWM"},
          {"name":"BRADLEY","code":"US-SC-B2D"},
          {"name":"BRANCHVILLE","code":"US-SC-BHV"},
          {"name":"BUFFALO","code":"US-SC-BZ2"},
          {"name":"CALHOUN FALLS","code":"US-SC-AHS"},
          {"name":"CAMDEN","code":"US-SC-CDN"},
          {"name":"CAMPOBELLO","code":"US-SC-CBO"},
          {"name":"CARLISLE","code":"US-SC-QLC"},
          {"name":"CARLISLE, SPARTANBURG","code":"US-SC-CL7"},
          {"name":"CARTERSVILLE","code":"US-SC-VYZ"},
          {"name":"CATAWBA","code":"US-SC-CTA"},
          {"name":"CAYCE","code":"US-SC-CYC"},
          {"name":"CENTRAL","code":"US-SC-CTL"},
          {"name":"CHAPIN","code":"US-SC-CAI"},
          {"name":"CHARLESTON","code":"US-SC-CHS"},
          {"name":"CHARLESTON HEIGHTS","code":"US-SC-ZGT"},
          {"name":"CHERAW","code":"US-SC-HCW"},
          {"name":"CHEROKEE FALLS","code":"US-SC-CKF"},
          {"name":"CHESNEE","code":"US-SC-CQS"},
          {"name":"CHESTER","code":"US-SC-CQZ"},
          {"name":"CHESTERFIELD","code":"US-SC-TFI"},
          {"name":"CLEMSON","code":"US-SC-CEU"},
          {"name":"CLIFTON","code":"US-SC-IFQ"},
          {"name":"CLINTON","code":"US-SC-CLN"},
          {"name":"CLIO","code":"US-SC-QL3"},
          {"name":"CLOVER","code":"US-SC-CXV"},
          {"name":"COLUMBIA","code":"US-SC-CAE"},
          {"name":"CONESTEE","code":"US-SC-QSE"},
          {"name":"CONWAY","code":"US-SC-ACZ"},
          {"name":"COOPER RIVER LANDING","code":"US-SC-C5R"},
          {"name":"COPE","code":"US-SC-DZC"},
          {"name":"COWARD","code":"US-SC-ZJH"},
          {"name":"COWPENS","code":"US-SC-CPQ"},
          {"name":"CROSS","code":"US-SC-8CR"},
          {"name":"DALZELL","code":"US-SC-DZL"},
          {"name":"DANIEL ISLAND","code":"US-SC-DI8"},
          {"name":"DARLINGTON","code":"US-SC-DRN"},
          {"name":"DAUFUSKIE ISLAND","code":"US-SC-DI2"},
          {"name":"DENMARK","code":"US-SC-DMK"},
          {"name":"DILLON","code":"US-SC-DLL"},
          {"name":"DONALDS","code":"US-SC-DDS"},
          {"name":"DORCHESTER","code":"US-SC-DC5"},
          {"name":"DUNCAN","code":"US-SC-DNK"},
          {"name":"DUNN","code":"US-SC-DUM"},
          {"name":"EARLY BRANCH","code":"US-SC-EYB"},
          {"name":"EASLEY","code":"US-SC-EAL"},
          {"name":"EASTOVER","code":"US-SC-EAS"},
          {"name":"EDGEFIELD","code":"US-SC-EDF"},
          {"name":"EDGEMOOR","code":"US-SC-E3M"},
          {"name":"EDISTO ISLAND","code":"US-SC-DTS"},
          {"name":"EFFINGHAM","code":"US-SC-EGH"},
          {"name":"ELGIN","code":"US-SC-EGE"},
          {"name":"ELLOREE","code":"US-SC-EOR"},
          {"name":"ENOREE","code":"US-SC-EON"},
          {"name":"ESTILL","code":"US-SC-ES9"},
          {"name":"FAIRFAX","code":"US-SC-FYA"},
          {"name":"FAIRFOREST","code":"US-SC-FFR"},
          {"name":"FLORENCE","code":"US-SC-FLO"},
          {"name":"FOLLY BEACH","code":"US-SC-FYB"},
          {"name":"FORT JACKSON","code":"US-SC-FJS"},
          {"name":"FORT LAWN","code":"US-SC-FWN"},
          {"name":"FORT MILL","code":"US-SC-FML"},
          {"name":"FOUNTAIN INN","code":"US-SC-FIS"},
          {"name":"FROGMORE","code":"US-SC-FGM"},
          {"name":"FURMAN","code":"US-SC-FJA"},
          {"name":"GABLE","code":"US-SC-GB4"},
          {"name":"GADSDEN","code":"US-SC-DE6"},
          {"name":"GAFFNEY","code":"US-SC-AEE"},
          {"name":"GARDEN CITY","code":"US-SC-GCB"},
          {"name":"GASTON","code":"US-SC-GAT"},
          {"name":"GEORGETOWN","code":"US-SC-GGE"},
          {"name":"GILBERT","code":"US-SC-GIC"},
          {"name":"GOOSE CREEK","code":"US-SC-GSC"},
          {"name":"GRACE","code":"US-SC-GCE"},
          {"name":"GRANITEVILLE","code":"US-SC-GTV"},
          {"name":"GRAY COURT","code":"US-SC-GC3"},
          {"name":"GREAT FALLS","code":"US-SC-RTF"},
          {"name":"GREELEYVILLE","code":"US-SC-EJL"},
          {"name":"GREEN POND","code":"US-SC-GPH"},
          {"name":"GREEN VALLEY","code":"US-SC-8GV"},
          {"name":"GREENVILLE","code":"US-SC-GV9"},
          {"name":"GREENVILLE-GREER APT","code":"US-SC-GSP"},
          {"name":"GREENWOOD","code":"US-SC-GRD"},
          {"name":"GREER","code":"US-SC-GXX"},
          {"name":"GREER-GREENVILLE APT","code":"US-SC-GSP"},
          {"name":"GRESHAM","code":"US-SC-GJH"},
          {"name":"HAMER","code":"US-SC-HMK"},
          {"name":"HAMPTON","code":"US-SC-UJS"},
          {"name":"HANAHAN","code":"US-SC-UJN"},
          {"name":"HARDEEVILLE","code":"US-SC-HVJ"},
          {"name":"HARLEYVILLE","code":"US-SC-ZDY"},
          {"name":"HARMONY","code":"US-SC-HSC"},
          {"name":"HARTSVILLE","code":"US-SC-HVS"},
          {"name":"HEATH SPRINGS","code":"US-SC-THG"},
          {"name":"HEMINGWAY","code":"US-SC-HWY"},
          {"name":"HICKORY GROVE, YORK","code":"US-SC-HKG"},
          {"name":"HILTON HEAD ISLAND","code":"US-SC-HHH"},
          {"name":"HODGES","code":"US-SC-HDG"},
          {"name":"HOLLY HILL","code":"US-SC-HHQ"},
          {"name":"HOLLYWOOD","code":"US-SC-OYW"},
          {"name":"HONEA PATH","code":"US-SC-OEP"},
          {"name":"HOPKINS","code":"US-SC-KNC"},
          {"name":"HUGER","code":"US-SC-QHG"},
          {"name":"INDIAN LAND","code":"US-SC-IUF"},
          {"name":"INMAN","code":"US-SC-INM"},
          {"name":"IRMO","code":"US-SC-IRM"},
          {"name":"ISLE OF PALMS","code":"US-SC-IOP"},
          {"name":"IVA","code":"US-SC-IV2"},
          {"name":"JACKSON, AIKEN","code":"US-SC-JS7"},
          {"name":"JACKSON, GEORGETOWN","code":"US-SC-JAG"},
          {"name":"JACKSONBORO","code":"US-SC-JKB"},
          {"name":"JAMES ISLAND","code":"US-SC-JXI"},
          {"name":"JAMESTOWN","code":"US-SC-QJM"},
          {"name":"JEFFERSON","code":"US-SC-JFR"},
          {"name":"JENKINSVILLE","code":"US-SC-JKL"},
          {"name":"JOANNA","code":"US-SC-JNB"},
          {"name":"JOHNS ISLAND","code":"US-SC-JIS"},
          {"name":"JOHNSONVILLE","code":"US-SC-JHV"},
          {"name":"JOHNSTON","code":"US-SC-JHE"},
          {"name":"JONESVILLE","code":"US-SC-JOL"},
          {"name":"KERSHAW","code":"US-SC-KRW"},
          {"name":"KIAWAH ISLAND","code":"US-SC-KIA"},
          {"name":"KINARDS","code":"US-SC-KD5"},
          {"name":"KINGS CREEK","code":"US-SC-KCS"},
          {"name":"KINGSTREE","code":"US-SC-KGS"},
          {"name":"LA FRANCE","code":"US-SC-LFH"},
          {"name":"LADSON","code":"US-SC-LDZ"},
          {"name":"LAKE CITY","code":"US-SC-LKC"},
          {"name":"LAKE WYLIE","code":"US-SC-7LW"},
          {"name":"LAMAR","code":"US-SC-LMF"},
          {"name":"LANCASTER","code":"US-SC-LNW"},
          {"name":"LANDO","code":"US-SC-LNO"},
          {"name":"LANDRUM","code":"US-SC-LDU"},
          {"name":"LANE","code":"US-SC-YLQ"},
          {"name":"LANGLEY","code":"US-SC-LGY"},
          {"name":"LATTA","code":"US-SC-TTA"},
          {"name":"LAURENS","code":"US-SC-LSC"},
          {"name":"LEEDS","code":"US-SC-LED"},
          {"name":"LEESVILLE","code":"US-SC-UJZ"},
          {"name":"LEXINGTON","code":"US-SC-LET"},
          {"name":"LIBERTY","code":"US-SC-LBY"},
          {"name":"LITTLE MOUNTAIN","code":"US-SC-LMX"},
          {"name":"LITTLE RIVER","code":"US-SC-LR3"},
          {"name":"LONGS","code":"US-SC-JNG"},
          {"name":"LORIS","code":"US-SC-LRZ"},
          {"name":"LUGOFF","code":"US-SC-LOF"},
          {"name":"LYMAN","code":"US-SC-UQH"},
          {"name":"LYNCHBURG","code":"US-SC-LYC"},
          {"name":"MADISON","code":"US-SC-MO9"},
          {"name":"MANNING","code":"US-SC-MNG"},
          {"name":"MARIETTA","code":"US-SC-RTA"},
          {"name":"MARION","code":"US-SC-MSR"},
          {"name":"MARTIN","code":"US-SC-QYG"},
          {"name":"MAULDIN","code":"US-SC-MAU"},
          {"name":"MAYO","code":"US-SC-MY6"},
          {"name":"MCBEE","code":"US-SC-XIE"},
          {"name":"MCCLELLANVILLE","code":"US-SC-V9V"},
          {"name":"MCCONNELLS","code":"US-SC-ZXY"},
          {"name":"MCCORMICK","code":"US-SC-OIK"},
          {"name":"MEGGETT","code":"US-SC-32M"},
          {"name":"MONCKS CORNER","code":"US-SC-MNK"},
          {"name":"MONETTA","code":"US-SC-M6T"},
          {"name":"MOORE","code":"US-SC-MBW"},
          {"name":"MOUNT HOLLY","code":"US-SC-UQS"},
          {"name":"MOUNT PLEASANT","code":"US-SC-MPH"},
          {"name":"MOUNTAIN REST","code":"US-SC-MU5"},
          {"name":"MOUNTVILLE","code":"US-SC-UTV"},
          {"name":"MULLINS","code":"US-SC-MXU"},
          {"name":"MURRELLS INLET","code":"US-SC-RT3"},
          {"name":"MYRTLE BEACH","code":"US-SC-MYR"},
          {"name":"NESMITH","code":"US-SC-NZT"},
          {"name":"NEW ELLENTON","code":"US-SC-NZC"},
          {"name":"NEW PROSPECT","code":"US-SC-NPS"},
          {"name":"NEWBERRY","code":"US-SC-NBZ"},
          {"name":"NICHOLS","code":"US-SC-NCL"},
          {"name":"NINETY SIX","code":"US-SC-NIY"},
          {"name":"NORRIS","code":"US-SC-NR9"},
          {"name":"NORTH","code":"US-SC-NOR"},
          {"name":"NORTH AUGUSTA","code":"US-SC-NAU"},
          {"name":"NORTH CHARLESTON","code":"US-SC-NTS"},
          {"name":"NORTH MYRTLE BEACH","code":"US-SC-NMB"},
          {"name":"NORTH SANTEE","code":"US-SC-NSA"},
          {"name":"OLAR","code":"US-SC-FIZ"},
          {"name":"ORANGEBURG","code":"US-SC-OGB"},
          {"name":"PACOLET","code":"US-SC-ZG9"},
          {"name":"PAGELAND","code":"US-SC-PQR"},
          {"name":"PAMPLICO","code":"US-SC-PPJ"},
          {"name":"PARRIS ISLAND","code":"US-SC-XKL"},
          {"name":"PAULINE","code":"US-SC-ZUI"},
          {"name":"PAWLEYS ISLAND","code":"US-SC-ZPI"},
          {"name":"PAXVILLE","code":"US-SC-PX4"},
          {"name":"PELION","code":"US-SC-ZE5"},
          {"name":"PELZER","code":"US-SC-YPZ"},
          {"name":"PENDLETON","code":"US-SC-PDO"},
          {"name":"PERRY","code":"US-SC-PY3"},
          {"name":"PICKENS","code":"US-SC-LQK"},
          {"name":"PIEDMONT","code":"US-SC-PIJ"},
          {"name":"PINELAND","code":"US-SC-P2D"},
          {"name":"PINEVILLE","code":"US-SC-XKO"},
          {"name":"PONTIAC","code":"US-SC-PIB"},
          {"name":"PORT ROYAL","code":"US-SC-PRJ"},
          {"name":"PRINCETON","code":"US-SC-4S4"},
          {"name":"PROSPERITY","code":"US-SC-XKP"},
          {"name":"RAVENEL","code":"US-SC-RV7"},
          {"name":"REIDVILLE","code":"US-SC-RV2"},
          {"name":"RICHBURG","code":"US-SC-RXJ"},
          {"name":"RIDGE SPRING","code":"US-SC-GPJ"},
          {"name":"RIDGELAND","code":"US-SC-RGE"},
          {"name":"RIDGEVILLE","code":"US-SC-RIS"},
          {"name":"RIDGEWAY","code":"US-SC-ZRW"},
          {"name":"ROCK HILL","code":"US-SC-RKH"},
          {"name":"ROEBUCK","code":"US-SC-RQB"},
          {"name":"ROWESVILLE","code":"US-SC-XLK"},
          {"name":"RUBY","code":"US-SC-RUY"},
          {"name":"RUFFIN","code":"US-SC-YRF"},
          {"name":"SAINT ANDREWS","code":"US-SC-S2A"},
          {"name":"SAINT ANDREWS","code":"US-SC-S3A"},
          {"name":"SAINT GEORGE","code":"US-SC-SGS"},
          {"name":"SAINT MATTHEWS","code":"US-SC-SMB"},
          {"name":"SAINT STEPHEN","code":"US-SC-ZSH"},
          {"name":"SALEM","code":"US-SC-S29"},
          {"name":"SALLEY","code":"US-SC-JLL"},
          {"name":"SALUDA","code":"US-SC-UDA"},
          {"name":"SAMPIT","code":"US-SC-SMP"},
          {"name":"SANDY RUN","code":"US-SC-S3R"},
          {"name":"SANDY SPRINGS","code":"US-SC-AYP"},
          {"name":"SANTEE","code":"US-SC-SA6"},
          {"name":"SEABROOK","code":"US-SC-EQQ"},
          {"name":"SENECA","code":"US-SC-SQW"},
          {"name":"SHAW AIR FORCE BASE","code":"US-SC-ZFH"},
          {"name":"SILVERSTREET","code":"US-SC-S5V"},
          {"name":"SIMPSONVILLE","code":"US-SC-SRY"},
          {"name":"SIX MILE","code":"US-SC-SI4"},
          {"name":"SLATER","code":"US-SC-SQX"},
          {"name":"SOCIETY HILL","code":"US-SC-SXS"},
          {"name":"SOUTH SUMTER","code":"US-SC-S2S"},
          {"name":"SPARTANBURG","code":"US-SC-SPA"},
          {"name":"SPRINGFIELD","code":"US-SC-IEZ"},
          {"name":"STARR","code":"US-SC-XAR"},
          {"name":"STARTEX","code":"US-SC-XMT"},
          {"name":"STONE","code":"US-SC-STO"},
          {"name":"SUMMERTON","code":"US-SC-UMR"},
          {"name":"SUMMERVILLE","code":"US-SC-UZC"},
          {"name":"SUMMIT","code":"US-SC-AAO"},
          {"name":"SUMTER","code":"US-SC-SSC"},
          {"name":"SURFSIDE BEACH","code":"US-SC-FBC"},
          {"name":"SWANSEA","code":"US-SC-SS5"},
          {"name":"TATUM","code":"US-SC-TTU"},
          {"name":"TAYLORS","code":"US-SC-TYQ"},
          {"name":"TEGA CAY","code":"US-SC-TCQ"},
          {"name":"TIMMONSVILLE","code":"US-SC-XTE"},
          {"name":"TRAVELERS REST","code":"US-SC-XNJ"},
          {"name":"TRENTON","code":"US-SC-XTO"},
          {"name":"ULMER","code":"US-SC-UER"},
          {"name":"UNION","code":"US-SC-UCS"},
          {"name":"UNION SOUTH CAROLINA","code":"US-SC-USC"},
          {"name":"VAN WYCK","code":"US-SC-VWC"},
          {"name":"VANCE","code":"US-SC-4AC"},
          {"name":"VARNVILLE","code":"US-SC-ZH5"},
          {"name":"WADMALAW ISLAND","code":"US-SC-WW9"},
          {"name":"WAGENER","code":"US-SC-QWE"},
          {"name":"WALHALLA","code":"US-SC-WHJ"},
          {"name":"WALLACE","code":"US-SC-WBT"},
          {"name":"WALTERBORO","code":"US-SC-RBW"},
          {"name":"WANDO","code":"US-SC-WFH"},
          {"name":"WARD","code":"US-SC-YWD"},
          {"name":"WARE SHOALS","code":"US-SC-ASS"},
          {"name":"WARRENVILLE","code":"US-SC-WZR"},
          {"name":"WEDGEFIELD","code":"US-SC-W9F"},
          {"name":"WELLFORD","code":"US-SC-WEF"},
          {"name":"WEST COLUMBIA","code":"US-SC-WCB"},
          {"name":"WEST UNION","code":"US-SC-WEU"},
          {"name":"WESTMINSTER","code":"US-SC-WMR"},
          {"name":"WHITE STONE","code":"US-SC-WJE"},
          {"name":"WHITESVILLE","code":"US-SC-WVG"},
          {"name":"WHITMIRE","code":"US-SC-IWM"},
          {"name":"WILKINS","code":"US-SC-WIK"},
          {"name":"WILLIAMSTON","code":"US-SC-WWL"},
          {"name":"WILLINGTON","code":"US-SC-ZFU"},
          {"name":"WILLISTON","code":"US-SC-UZN"},
          {"name":"WINDSOR","code":"US-SC-OW6"},
          {"name":"WINNSBORO","code":"US-SC-WNB"},
          {"name":"WOODRUFF","code":"US-SC-WOU"},
          {"name":"YEMASSEE","code":"US-SC-YMA"},
          {"name":"YORK","code":"US-SC-YOR"},
        ]
      },
      {
        name: 'Dakota del Sur',
        code: 'US-SD',
        cities: [
          {"name":"ABERDEEN","code":"US-SD-ABR"},
          {"name":"ALCESTER","code":"US-SD-ALJ"},
          {"name":"ALPENA","code":"US-SD-A4P"},
          {"name":"AMHERST","code":"US-SD-ZAM"},
          {"name":"ANDOVER","code":"US-SD-AJR"},
          {"name":"ARLINGTON","code":"US-SD-AGT"},
          {"name":"ARTESIAN","code":"US-SD-TIZ"},
          {"name":"ASTORIA","code":"US-SD-A9T"},
          {"name":"AURORA","code":"US-SD-UOR"},
          {"name":"BALTIC","code":"US-SD-B7C"},
          {"name":"BELLE FOURCHE","code":"US-SD-FUO"},
          {"name":"BERESFORD","code":"US-SD-QKF"},
          {"name":"BIG STONE CITY","code":"US-SD-GQJ"},
          {"name":"BLACKHAWK","code":"US-SD-BK2"},
          {"name":"BOWDLE","code":"US-SD-B9W"},
          {"name":"BOX ELDER","code":"US-SD-B2X"},
          {"name":"BRANDON","code":"US-SD-AFI"},
          {"name":"BRANDT","code":"US-SD-BD7"},
          {"name":"BRIDGEWATER","code":"US-SD-B7S"},
          {"name":"BRITTON","code":"US-SD-TTO"},
          {"name":"BROOKINGS","code":"US-SD-BKX"},
          {"name":"BRUCE","code":"US-SD-BZU"},
          {"name":"BRYANT, HAMLIN","code":"US-SD-BT2"},
          {"name":"BURKE","code":"US-SD-K55"},
          {"name":"BYANT","code":"US-SD-AGB"},
          {"name":"CANISTOTA","code":"US-SD-IKS"},
          {"name":"CANTON","code":"US-SD-CTK"},
          {"name":"CARPENTER","code":"US-SD-CP5"},
          {"name":"CASTLEWOOD","code":"US-SD-DK6"},
          {"name":"CHAMBERLAIN","code":"US-SD-CH9"},
          {"name":"CLAIRE CITY","code":"US-SD-7CL"},
          {"name":"CLARK","code":"US-SD-9CK"},
          {"name":"CLEAR LAKE","code":"US-SD-DS3"},
          {"name":"COLMAN","code":"US-SD-CO6"},
          {"name":"CORSICA","code":"US-SD-4CO"},
          {"name":"CUSTER","code":"US-SD-USE"},
          {"name":"DAKOTA DUNES","code":"US-SD-DTD"},
          {"name":"DE SMET","code":"US-SD-DNX"},
          {"name":"DEADWOOD","code":"US-SD-DDW"},
          {"name":"EAGLE BUTTE","code":"US-SD-EB8"},
          {"name":"EDEN","code":"US-SD-ED3"},
          {"name":"EDGEMONT","code":"US-SD-DGM"},
          {"name":"EGAN","code":"US-SD-EJA"},
          {"name":"ELK POINT","code":"US-SD-E2P"},
          {"name":"ELKTON","code":"US-SD-EKZ"},
          {"name":"ELLSWORTH AIR FORCE BASE","code":"US-SD-EHB"},
          {"name":"EMERY","code":"US-SD-EMY"},
          {"name":"ENNING","code":"US-SD-E9E"},
          {"name":"ESTELLINE","code":"US-SD-ET9"},
          {"name":"ETHAN","code":"US-SD-S9D"},
          {"name":"FAIRBURN","code":"US-SD-FIU"},
          {"name":"FAIRVIEW","code":"US-SD-2DS"},
          {"name":"FAULKTON","code":"US-SD-FKO"},
          {"name":"FLANDREAU","code":"US-SD-YFD"},
          {"name":"FORT PIERRE","code":"US-SD-FPI"},
          {"name":"FRANKFORT","code":"US-SD-FKZ"},
          {"name":"FREEMAN","code":"US-SD-F2E"},
          {"name":"FRUITDALE","code":"US-SD-IUI"},
          {"name":"GARRETSON","code":"US-SD-ZXG"},
          {"name":"GAYVILLE, YANKTON","code":"US-SD-GYH"},
          {"name":"GEDDES","code":"US-SD-GS2"},
          {"name":"GETTYSBURG","code":"US-SD-G4B"},
          {"name":"GREGORY","code":"US-SD-JGG"},
          {"name":"GRENVILLE","code":"US-SD-QGI"},
          {"name":"GROTON","code":"US-SD-GOO"},
          {"name":"HAROLD","code":"US-SD-HJR"},
          {"name":"HARRISBURG","code":"US-SD-HAU"},
          {"name":"HARROLD","code":"US-SD-2HR"},
          {"name":"HARTFORD","code":"US-SD-HF4"},
          {"name":"HAYES","code":"US-SD-FQH"},
          {"name":"HERREID","code":"US-SD-HE2"},
          {"name":"HIGHMORE","code":"US-SD-8HM"},
          {"name":"HILL CITY","code":"US-SD-HCI"},
          {"name":"HITCHCOCK","code":"US-SD-IOK"},
          {"name":"HOSMER","code":"US-SD-HM5"},
          {"name":"HOT SPRINGS","code":"US-SD-HP3"},
          {"name":"HOVEN","code":"US-SD-7HV"},
          {"name":"HOWARD","code":"US-SD-HWR"},
          {"name":"HUDSON","code":"US-SD-H4U"},
          {"name":"HURON","code":"US-SD-HON"},
          {"name":"IPSWICH","code":"US-SD-ISC"},
          {"name":"ISABEL","code":"US-SD-78D"},
          {"name":"JEFFERSON","code":"US-SD-JT3"},
          {"name":"KADOKA","code":"US-SD-KDD"},
          {"name":"KEYSTONE","code":"US-SD-KTE"},
          {"name":"LAKE ANDES","code":"US-SD-LA5"},
          {"name":"LAKE NORDEN","code":"US-SD-LKO"},
          {"name":"LAKE PRESTON","code":"US-SD-LP8"},
          {"name":"LEAD","code":"US-SD-LDQ"},
          {"name":"LEMMON","code":"US-SD-LEM"},
          {"name":"LENNOX","code":"US-SD-QOX"},
          {"name":"LESTERVILLE","code":"US-SD-LZS"},
          {"name":"LETCHER","code":"US-SD-LC8"},
          {"name":"LOWER BRULE","code":"US-SD-LB7"},
          {"name":"LYONS","code":"US-SD-LXS"},
          {"name":"MADISON","code":"US-SD-XMD"},
          {"name":"MANSFIELD","code":"US-SD-S7F"},
          {"name":"MARION","code":"US-SD-M4R"},
          {"name":"MCINTOSH","code":"US-SD-5TH"},
          {"name":"MENNO","code":"US-SD-MO6"},
          {"name":"MIDLAND","code":"US-SD-XAD"},
          {"name":"MILBANK","code":"US-SD-MBK"},
          {"name":"MILESVILLE","code":"US-SD-IVZ"},
          {"name":"MILLER","code":"US-SD-IL2"},
          {"name":"MISSION","code":"US-SD-4MS"},
          {"name":"MISSION HILL","code":"US-SD-5SM"},
          {"name":"MITCHELL","code":"US-SD-MHE"},
          {"name":"MOBRIDGE","code":"US-SD-MBG"},
          {"name":"NEMO","code":"US-SD-NKM"},
          {"name":"NORTH SIOUX CITY","code":"US-SD-NSC"},
          {"name":"OLDHAM","code":"US-SD-OH2"},
          {"name":"ONIDA","code":"US-SD-OID"},
          {"name":"PARKER","code":"US-SD-P6K"},
          {"name":"PARKSTON","code":"US-SD-AKS"},
          {"name":"PHILIP","code":"US-SD-PHP"},
          {"name":"PICKSTOWN","code":"US-SD-P3T"},
          {"name":"PIEDMONT","code":"US-SD-S78"},
          {"name":"PIERRE","code":"US-SD-PIR"},
          {"name":"PINE RIDGE","code":"US-SD-XPR"},
          {"name":"PLANKINTON","code":"US-SD-PK7"},
          {"name":"PLATTE","code":"US-SD-P3E"},
          {"name":"PRESHO","code":"US-SD-PUO"},
          {"name":"RAPID CITY","code":"US-SD-RAP"},
          {"name":"REVA","code":"US-SD-V8R"},
          {"name":"REVILLO","code":"US-SD-RE2"},
          {"name":"ROSHOLT","code":"US-SD-OSO"},
          {"name":"ROWENA","code":"US-SD-R5W"},
          {"name":"SALEM","code":"US-SD-QLM"},
          {"name":"SCOTLAND","code":"US-SD-SO2"},
          {"name":"SIOUX FALLS","code":"US-SD-FSD"},
          {"name":"SISSETON","code":"US-SD-SS2"},
          {"name":"SPEARFISH","code":"US-SD-SPF"},
          {"name":"SPRINGFIELD","code":"US-SD-IGI"},
          {"name":"STURGIS","code":"US-SD-ZTI"},
          {"name":"TABOR","code":"US-SD-TB3"},
          {"name":"TEA","code":"US-SD-TIF"},
          {"name":"TIMBER LAKE","code":"US-SD-TL3"},
          {"name":"TRENT","code":"US-SD-TZE"},
          {"name":"TRIPP","code":"US-SD-TIP"},
          {"name":"UTICA","code":"US-SD-UC8"},
          {"name":"VALE","code":"US-SD-QVA"},
          {"name":"VALLEY SPRINGS","code":"US-SD-VSG"},
          {"name":"VEBLEN","code":"US-SD-VLN"},
          {"name":"VERMILLION","code":"US-SD-VEM"},
          {"name":"VIBORG","code":"US-SD-VBG"},
          {"name":"VOLGA","code":"US-SD-VLG"},
          {"name":"WAGNER","code":"US-SD-XNU"},
          {"name":"WALL","code":"US-SD-WZL"},
          {"name":"WATERTOWN","code":"US-SD-ATY"},
          {"name":"WEBSTER","code":"US-SD-WT2"},
          {"name":"WENTWORTH","code":"US-SD-WW2"},
          {"name":"WESTPORT","code":"US-SD-WP4"},
          {"name":"WETONKA","code":"US-SD-UU2"},
          {"name":"WHITE","code":"US-SD-ZWT"},
          {"name":"WHITE RIVER","code":"US-SD-IRI"},
          {"name":"WINNER","code":"US-SD-XWZ"},
          {"name":"WOONSOCKET","code":"US-SD-OSK"},
          {"name":"WORTHING","code":"US-SD-W8D"},
          {"name":"YANKTON","code":"US-SD-YKN"},
        ]
      },
      {
        name: 'Tennessee',
        code: 'US-TN',
        cities: [
          {"name":"ADAMS","code":"US-TN-AKM"},
          {"name":"ADAMSVILLE","code":"US-TN-UAM"},
          {"name":"AFTON","code":"US-TN-AFT"},
          {"name":"ALAMO","code":"US-TN-AMT"},
          {"name":"ALCOA","code":"US-TN-ACO"},
          {"name":"ALEXANDRIA","code":"US-TN-XAA"},
          {"name":"ALGOOD","code":"US-TN-YAG"},
          {"name":"ALTAMONT","code":"US-TN-AM7"},
          {"name":"ANDERSONVILLE","code":"US-TN-AOZ"},
          {"name":"ANTIOCH","code":"US-TN-TIO"},
          {"name":"ARDMORE","code":"US-TN-AME"},
          {"name":"ARLINGTON, HOUSTON","code":"US-TN-AGW"},
          {"name":"ARLINGTON, KNOX","code":"US-TN-AT3"},
          {"name":"ARLINGTON, SHELBY","code":"US-TN-IGT"},
          {"name":"ARRINGTON","code":"US-TN-NGO"},
          {"name":"ASHLAND","code":"US-TN-AYQ"},
          {"name":"ASHLAND CITY","code":"US-TN-XBN"},
          {"name":"ATHENS","code":"US-TN-MMI"},
          {"name":"ATOKA","code":"US-TN-AK2"},
          {"name":"AUBURNTOWN","code":"US-TN-A3T"},
          {"name":"BAKEWELL","code":"US-TN-QBZ"},
          {"name":"BARTLETT","code":"US-TN-BBP"},
          {"name":"BAXTER","code":"US-TN-BXR"},
          {"name":"BEAN STATION","code":"US-TN-BST"},
          {"name":"BEERSHEBA","code":"US-TN-BS4"},
          {"name":"BELFAST","code":"US-TN-QBL"},
          {"name":"BELL BUCKLE","code":"US-TN-QBB"},
          {"name":"BELLS","code":"US-TN-BJS"},
          {"name":"BELVIDERE","code":"US-TN-VDR"},
          {"name":"BEMIS","code":"US-TN-BS8"},
          {"name":"BENTON","code":"US-TN-BQO"},
          {"name":"BETHER SPRINGS","code":"US-TN-BNS"},
          {"name":"BIG SANDY","code":"US-TN-BIY"},
          {"name":"BIRCHWOOD","code":"US-TN-B3W"},
          {"name":"BLAINE","code":"US-TN-VBI"},
          {"name":"BLOUNTVILLE","code":"US-TN-OUI"},
          {"name":"BLUFF CITY","code":"US-TN-BIF"},
          {"name":"BOLIVAR","code":"US-TN-XCR"},
          {"name":"BON AQUA","code":"US-TN-OAQ"},
          {"name":"BRADFORD","code":"US-TN-9BD"},
          {"name":"BRENTWOOD","code":"US-TN-BWE"},
          {"name":"BRIGHTON","code":"US-TN-RHT"},
          {"name":"BRISTOL","code":"US-TN-BSO"},
          {"name":"BRISTOL-JOHNSON CITY-KINGSPORT (TRI-CITIES REGIONAL) APT","code":"US-TN-TRI"},
          {"name":"BROWNSVILLE","code":"US-TN-BSD"},
          {"name":"BRUCETON","code":"US-TN-BUB"},
          {"name":"BUCHANAN","code":"US-TN-BPJ"},
          {"name":"BULLS GAP","code":"US-TN-BG3"},
          {"name":"BURLISON","code":"US-TN-QRI"},
          {"name":"BURNS","code":"US-TN-BS3"},
          {"name":"BYBEE","code":"US-TN-JBB"},
          {"name":"BYRDSTOWN","code":"US-TN-YDW"},
          {"name":"CALHOUN","code":"US-TN-CU7"},
          {"name":"CAMDEN","code":"US-TN-CAT"},
          {"name":"CARTHAGE","code":"US-TN-CGX"},
          {"name":"CARYVILLE","code":"US-TN-XEN"},
          {"name":"CEDAR HILL","code":"US-TN-CX6"},
          {"name":"CELINA","code":"US-TN-CL8"},
          {"name":"CENTERVILLE","code":"US-TN-GHM"},
          {"name":"CHAPEL HILL","code":"US-TN-CIH"},
          {"name":"CHAPMANSBORO","code":"US-TN-CB4"},
          {"name":"CHARLESTON","code":"US-TN-CTC"},
          {"name":"CHARLOTTE","code":"US-TN-KHL"},
          {"name":"CHATTANOOGA","code":"US-TN-CHA"},
          {"name":"CHUCKEY","code":"US-TN-ZCF"},
          {"name":"CHURCH HILL","code":"US-TN-CRH"},
          {"name":"CLARKRANGE","code":"US-TN-T5L"},
          {"name":"CLARKSBURG","code":"US-TN-CLX"},
          {"name":"CLARKSVILLE","code":"US-TN-CKV"},
          {"name":"CLEVELAND","code":"US-TN-UAI"},
          {"name":"CLIFTON","code":"US-TN-IFT"},
          {"name":"CLINTON","code":"US-TN-CQT"},
          {"name":"COALFIELD","code":"US-TN-ZQZ"},
          {"name":"COLLEGE GROVE","code":"US-TN-CGG"},
          {"name":"COLLEGEDALE","code":"US-TN-CGD"},
          {"name":"COLLIERVILLE","code":"US-TN-CJE"},
          {"name":"COLLINWOOD","code":"US-TN-CIW"},
          {"name":"COLUMBIA","code":"US-TN-MRC"},
          {"name":"COOKEVILLE","code":"US-TN-UAU"},
          {"name":"COOPERTOWN","code":"US-TN-T24"},
          {"name":"COPPERHILL","code":"US-TN-CPI"},
          {"name":"CORDOVA","code":"US-TN-DOA"},
          {"name":"CORINTH","code":"US-TN-XEM"},
          {"name":"CORNERSVILLE","code":"US-TN-CN8"},
          {"name":"COSBY","code":"US-TN-CB3"},
          {"name":"COTTAGE GROVE","code":"US-TN-CG9"},
          {"name":"COTTONTOWN","code":"US-TN-CT4"},
          {"name":"COUNCE","code":"US-TN-CUC"},
          {"name":"COVINGTON","code":"US-TN-AAH"},
          {"name":"COWAN","code":"US-TN-ZWN"},
          {"name":"CRAB ORCHARD","code":"US-TN-CO2"},
          {"name":"CROSS PLAINS","code":"US-TN-UOP"},
          {"name":"CROSSVILLE","code":"US-TN-CSV"},
          {"name":"CRUMP","code":"US-TN-UMK"},
          {"name":"CULLEOKA","code":"US-TN-UOZ"},
          {"name":"CUMBERLAND CITY","code":"US-TN-UDC"},
          {"name":"CUMBERLAND FURNACE","code":"US-TN-QCU"},
          {"name":"DANDRIDGE","code":"US-TN-DAX"},
          {"name":"DAYTON","code":"US-TN-DAT"},
          {"name":"DECATUR","code":"US-TN-DTV"},
          {"name":"DECATURVILLE","code":"US-TN-8DV"},
          {"name":"DECHERD","code":"US-TN-DCH"},
          {"name":"DEER LODGE","code":"US-TN-DL7"},
          {"name":"DEL RIO","code":"US-TN-DRZ"},
          {"name":"DELANO","code":"US-TN-DOQ"},
          {"name":"DICKSON","code":"US-TN-ZKN"},
          {"name":"DOVER","code":"US-TN-DZR"},
          {"name":"DOYLE","code":"US-TN-XFC"},
          {"name":"DRESDEN","code":"US-TN-DSD"},
          {"name":"DUCKTOWN","code":"US-TN-DC3"},
          {"name":"DUNLAP","code":"US-TN-UNL"},
          {"name":"DYER","code":"US-TN-DYS"},
          {"name":"DYERSBURG","code":"US-TN-DYR"},
          {"name":"EADS","code":"US-TN-XED"},
          {"name":"EAGLEVILLE","code":"US-TN-E2G"},
          {"name":"EAST RIDGE","code":"US-TN-E6R"},
          {"name":"ELIZABETHTON","code":"US-TN-EZB"},
          {"name":"ELKTON","code":"US-TN-E99"},
          {"name":"ELORA","code":"US-TN-REU"},
          {"name":"ENGLEWOOD","code":"US-TN-EEW"},
          {"name":"ERIN","code":"US-TN-ERJ"},
          {"name":"ERWIN","code":"US-TN-ERW"},
          {"name":"ESTILL SPRINGS","code":"US-TN-ETZ"},
          {"name":"ETHRIDGE","code":"US-TN-EHG"},
          {"name":"ETOWAH","code":"US-TN-ETO"},
          {"name":"EVA","code":"US-TN-EVQ"},
          {"name":"EVANSVILLE","code":"US-TN-E9V"},
          {"name":"FAIRFIELD","code":"US-TN-FAE"},
          {"name":"FAIRVIEW","code":"US-TN-FEW"},
          {"name":"FAYETTEVILLE","code":"US-TN-FYM"},
          {"name":"FOREST HILLS","code":"US-TN-F4H"},
          {"name":"FRANKLIN","code":"US-TN-UJA"},
          {"name":"FRIENDSHIP","code":"US-TN-FPG"},
          {"name":"FRIENDSVILLE","code":"US-TN-FNV"},
          {"name":"GAINESBORO","code":"US-TN-GBT"},
          {"name":"GALLATIN","code":"US-TN-GAN"},
          {"name":"GALLAWAY","code":"US-TN-AWY"},
          {"name":"GATES","code":"US-TN-GT2"},
          {"name":"GATLINBURG","code":"US-TN-GKT"},
          {"name":"GEORGETOWN","code":"US-TN-GW8"},
          {"name":"GERMANTOWN","code":"US-TN-XGW"},
          {"name":"GLADEVILLE","code":"US-TN-G4L"},
          {"name":"GLEASON","code":"US-TN-GLZ"},
          {"name":"GOODLETTSVILLE","code":"US-TN-GDT"},
          {"name":"GORDONSVILLE","code":"US-TN-GOV"},
          {"name":"GRAND JUNCTION","code":"US-TN-GJN"},
          {"name":"GRAY","code":"US-TN-GY5"},
          {"name":"GREENBACK","code":"US-TN-GBX"},
          {"name":"GREENBRIER, ROBERTSON","code":"US-TN-GBI"},
          {"name":"GREENEVILLE","code":"US-TN-GTS"},
          {"name":"GREENFIELD","code":"US-TN-GEI"},
          {"name":"GREENLAND","code":"US-TN-GNN"},
          {"name":"GREENVILLE","code":"US-TN-GCY"},
          {"name":"GUILD","code":"US-TN-GIG"},
          {"name":"HALLS","code":"US-TN-HLJ"},
          {"name":"HAMPSHIRE","code":"US-TN-PHR"},
          {"name":"HAMPTON","code":"US-TN-H2P"},
          {"name":"HARRIMAN","code":"US-TN-HYZ"},
          {"name":"HARRISON","code":"US-TN-TS9"},
          {"name":"HARTSVILLE","code":"US-TN-VHL"},
          {"name":"HELENWOOD","code":"US-TN-HWU"},
          {"name":"HENDERSON","code":"US-TN-HDT"},
          {"name":"HENDERSONVILLE","code":"US-TN-HDV"},
          {"name":"HENNING","code":"US-TN-HNJ"},
          {"name":"HENRY","code":"US-TN-HEY"},
          {"name":"HERMITAGE","code":"US-TN-HRA"},
          {"name":"HILLSBORO","code":"US-TN-HB5"},
          {"name":"HIXON","code":"US-TN-H9X"},
          {"name":"HIXSON","code":"US-TN-HXS"},
          {"name":"HOHENWALD","code":"US-TN-HOH"},
          {"name":"HOLLADAY","code":"US-TN-H8D"},
          {"name":"HOLSTON","code":"US-TN-HYC"},
          {"name":"HORNSBY","code":"US-TN-HO6"},
          {"name":"HOUSTON","code":"US-TN-HUW"},
          {"name":"HUMBOLDT","code":"US-TN-HHB"},
          {"name":"HUNTINGDON","code":"US-TN-HGD"},
          {"name":"HUNTINGTON","code":"US-TN-UTG"},
          {"name":"HUNTSVILLE","code":"US-TN-HNT"},
          {"name":"HURON","code":"US-TN-TNH"},
          {"name":"IRON CITY","code":"US-TN-IRY"},
          {"name":"JACKSBORO","code":"US-TN-JQK"},
          {"name":"JACKSON","code":"US-TN-MKL"},
          {"name":"JACKSONVILLE","code":"US-TN-JV2"},
          {"name":"JAMESTOWN","code":"US-TN-TCW"},
          {"name":"JASPER","code":"US-TN-APT"},
          {"name":"JEFFERSON CITY","code":"US-TN-JCN"},
          {"name":"JELLICO","code":"US-TN-JLC"},
          {"name":"JOELTON","code":"US-TN-JLO"},
          {"name":"JOHNSON CITY","code":"US-TN-JCI"},
          {"name":"JOHNSON CITY-KINGSPORT-BRISTOL (TRI-CITIES REGIONAL) APT","code":"US-TN-TRI"},
          {"name":"JONESBORO","code":"US-TN-JOO"},
          {"name":"JONESBOROUGH","code":"US-TN-JBZ"},
          {"name":"KELSO","code":"US-TN-K39"},
          {"name":"KENTON","code":"US-TN-KNQ"},
          {"name":"KINGSPORT","code":"US-TN-KI2"},
          {"name":"KINGSPORT-BRISTOL-JOHNSON CITY (TRI-CITIES REGIONAL) APT","code":"US-TN-TRI"},
          {"name":"KINGSTON","code":"US-TN-KTQ"},
          {"name":"KINGSTON SPRINGS","code":"US-TN-KS3"},
          {"name":"KIRKLAND","code":"US-TN-KL3"},
          {"name":"KNOXVILLE","code":"US-TN-TYS"},
          {"name":"KODAK","code":"US-TN-KDA"},
          {"name":"LA FOLLETTE","code":"US-TN-LFO"},
          {"name":"LA VERGNE","code":"US-TN-LVV"},
          {"name":"LAFAYETTE","code":"US-TN-LQJ"},
          {"name":"LAKE CITY","code":"US-TN-LCY"},
          {"name":"LAKELAND","code":"US-TN-KEJ"},
          {"name":"LAVINIA","code":"US-TN-LV3"},
          {"name":"LAWRENCEBURG","code":"US-TN-XHH"},
          {"name":"LEBANON","code":"US-TN-LBN"},
          {"name":"LENOIR CITY","code":"US-TN-LZZ"},
          {"name":"LEOMA","code":"US-TN-LM3"},
          {"name":"LEWISBURG","code":"US-TN-LBG"},
          {"name":"LEXINGTON","code":"US-TN-LEL"},
          {"name":"LIBERTY HILL, WILLIAMSON","code":"US-TN-LTY"},
          {"name":"LIBERTY, DE KALB","code":"US-TN-IBY"},
          {"name":"LIMESTONE","code":"US-TN-LT7"},
          {"name":"LINDEN","code":"US-TN-LDE"},
          {"name":"LIVINGSTON","code":"US-TN-LYV"},
          {"name":"LOOKOUT MOUNTAIN","code":"US-TN-ZEJ"},
          {"name":"LORETTO","code":"US-TN-LT8"},
          {"name":"LOUDON","code":"US-TN-XHU"},
          {"name":"LOUISVILLE","code":"US-TN-LOS"},
          {"name":"LOWLAND","code":"US-TN-LLQ"},
          {"name":"LUPTON CITY","code":"US-TN-UPY"},
          {"name":"LURAY","code":"US-TN-LRY"},
          {"name":"LUTTRELL, UNION","code":"US-TN-LL4"},
          {"name":"LUTTS","code":"US-TN-LYZ"},
          {"name":"LYLES","code":"US-TN-QLY"},
          {"name":"LYNCHBURG","code":"US-TN-LYX"},
          {"name":"LYNNVILLE","code":"US-TN-2LV"},
          {"name":"MADISON","code":"US-TN-MAO"},
          {"name":"MADISONVILLE","code":"US-TN-IOE"},
          {"name":"MANCHESTER","code":"US-TN-AQS"},
          {"name":"MARTIN","code":"US-TN-MMT"},
          {"name":"MARVIN","code":"US-TN-ZVX"},
          {"name":"MARYVILLE","code":"US-TN-MRL"},
          {"name":"MASCOT","code":"US-TN-AOT"},
          {"name":"MASON","code":"US-TN-QON"},
          {"name":"MAURY CITY","code":"US-TN-YCT"},
          {"name":"MAYNARDVILLE","code":"US-TN-YVF"},
          {"name":"MCDONALD","code":"US-TN-DGD"},
          {"name":"MCEWEN","code":"US-TN-ZWM"},
          {"name":"MCKENZIE","code":"US-TN-UQX"},
          {"name":"MCMINNVILLE","code":"US-TN-RNC"},
          {"name":"MEDON","code":"US-TN-MN3"},
          {"name":"MELROSE","code":"US-TN-MO2"},
          {"name":"MEMPHIS","code":"US-TN-MEM"},
          {"name":"MIDDLEBURG","code":"US-TN-D7D"},
          {"name":"MIDDLETON","code":"US-TN-MJP"},
          {"name":"MIDWAY","code":"US-TN-MWY"},
          {"name":"MILAN","code":"US-TN-MXN"},
          {"name":"MILLINGTON","code":"US-TN-MZD"},
          {"name":"MISTON","code":"US-TN-M5I"},
          {"name":"MONROE","code":"US-TN-XZ9"},
          {"name":"MONTEAGLE","code":"US-TN-2MO"},
          {"name":"MONTEREY","code":"US-TN-MZY"},
          {"name":"MONTGOMERY","code":"US-TN-YGY"},
          {"name":"MOORESBURG","code":"US-TN-OUB"},
          {"name":"MOORESVILLE","code":"US-TN-M3O"},
          {"name":"MORGANTOWN","code":"US-TN-MWG"},
          {"name":"MORRISON","code":"US-TN-MJF"},
          {"name":"MORRISTOWN","code":"US-TN-MOR"},
          {"name":"MOSCOW","code":"US-TN-9MW"},
          {"name":"MOSHEIM","code":"US-TN-EIB"},
          {"name":"MOSS","code":"US-TN-MJN"},
          {"name":"MOUNT JULIET","code":"US-TN-MJB"},
          {"name":"MOUNT PLEASANT","code":"US-TN-MPL"},
          {"name":"MOUNTAIN CITY","code":"US-TN-MFM"},
          {"name":"MOUNTAIN HOME","code":"US-TN-MUM"},
          {"name":"MUNFORD","code":"US-TN-M7F"},
          {"name":"MURFREESBORO","code":"US-TN-MFU"},
          {"name":"NASHVILLE","code":"US-TN-BNA"},
          {"name":"NEW HOPE","code":"US-TN-N4H"},
          {"name":"NEW JOHNSONVILLE","code":"US-TN-NWJ"},
          {"name":"NEW MARKET","code":"US-TN-NJP"},
          {"name":"NEW TAZEWELL","code":"US-TN-NWW"},
          {"name":"NEWBERN","code":"US-TN-NBE"},
          {"name":"NEWCOMB","code":"US-TN-NCB"},
          {"name":"NEWPORT","code":"US-TN-NPR"},
          {"name":"NIOTA","code":"US-TN-NIA"},
          {"name":"NOLENSVILLE","code":"US-TN-NZV"},
          {"name":"NORMANDY","code":"US-TN-NXK"},
          {"name":"NORRIS","code":"US-TN-NIQ"},
          {"name":"NUNNELLY","code":"US-TN-NLL"},
          {"name":"OAK RIDGE","code":"US-TN-OKR"},
          {"name":"OAKDALE, MORGAN","code":"US-TN-ODD"},
          {"name":"OAKFIELD","code":"US-TN-KFL"},
          {"name":"OAKLAND, FAYETTE","code":"US-TN-KAD"},
          {"name":"OBION","code":"US-TN-OBI"},
          {"name":"OCOEE","code":"US-TN-OC7"},
          {"name":"OCOEE, POLK","code":"US-TN-OZE"},
          {"name":"OLD FORT","code":"US-TN-OFJ"},
          {"name":"OLD HICKORY","code":"US-TN-XKA"},
          {"name":"ONEIDA","code":"US-TN-OYI"},
          {"name":"OOLTEWAH","code":"US-TN-ZOW"},
          {"name":"ORLINDA","code":"US-TN-QOL"},
          {"name":"PARIS","code":"US-TN-PHT"},
          {"name":"PARROTTSVILLE","code":"US-TN-ZTS"},
          {"name":"PARSONS","code":"US-TN-PSZ"},
          {"name":"PELHAM","code":"US-TN-JPM"},
          {"name":"PETERSBURG","code":"US-TN-TSR"},
          {"name":"PIGEON FORGE","code":"US-TN-YPF"},
          {"name":"PIKEVILLE","code":"US-TN-PIK"},
          {"name":"PINEY FLATS","code":"US-TN-EYF"},
          {"name":"PINSON","code":"US-TN-PI2"},
          {"name":"PIONEER","code":"US-TN-NE4"},
          {"name":"PIPERTON","code":"US-TN-PZP"},
          {"name":"PLAINFIELD","code":"US-TN-PF2"},
          {"name":"PLEASANT HILL","code":"US-TN-P4S"},
          {"name":"PLEASANT SHADE","code":"US-TN-PZ6"},
          {"name":"PLEASANT VIEW","code":"US-TN-NIW"},
          {"name":"PLEASANT VIEW, CHEATHAM","code":"US-TN-PWZ"},
          {"name":"PLEASANTVILLE","code":"US-TN-9PT"},
          {"name":"POCAHONTAS","code":"US-TN-PHH"},
          {"name":"POCAHONTAS","code":"US-TN-QPH"},
          {"name":"PORTLAND","code":"US-TN-PQD"},
          {"name":"POWELL","code":"US-TN-TNO"},
          {"name":"PRIMM SPRINGS","code":"US-TN-IQM"},
          {"name":"PROSPECT","code":"US-TN-RPC"},
          {"name":"PULASKI","code":"US-TN-PKL"},
          {"name":"PURYEAR","code":"US-TN-UUU"},
          {"name":"RED BANK","code":"US-TN-RKB"},
          {"name":"RED BOILING SPRINGS","code":"US-TN-RBP"},
          {"name":"RICEVILLE","code":"US-TN-ICV"},
          {"name":"RICKMAN","code":"US-TN-KU8"},
          {"name":"RIPLEY","code":"US-TN-RPL"},
          {"name":"ROAN MOUNTAIN","code":"US-TN-R2M"},
          {"name":"ROCKFORD","code":"US-TN-ROF"},
          {"name":"ROCKVALE","code":"US-TN-OAL"},
          {"name":"ROCKWOOD","code":"US-TN-RKW"},
          {"name":"ROGERSVILLE","code":"US-TN-RGV"},
          {"name":"ROSSVILLE","code":"US-TN-ROQ"},
          {"name":"RUSSELLVILLE","code":"US-TN-RUE"},
          {"name":"RUTHERFORD","code":"US-TN-ZUT"},
          {"name":"RUTLEDGE","code":"US-TN-R7T"},
          {"name":"SALE CREEK","code":"US-TN-ECK"},
          {"name":"SALTILLO","code":"US-TN-S2T"},
          {"name":"SAVANNAH","code":"US-TN-TSA"},
          {"name":"SCOTTS HILL","code":"US-TN-S4H"},
          {"name":"SELMER","code":"US-TN-SQE"},
          {"name":"SEQUATCHIE","code":"US-TN-SQ5"},
          {"name":"SEVIERVILLE","code":"US-TN-SVU"},
          {"name":"SEWANEE","code":"US-TN-UOS"},
          {"name":"SEYMOUR","code":"US-TN-EYU"},
          {"name":"SHELBYVILLE","code":"US-TN-SYI"},
          {"name":"SIGNAL MOUNTAIN","code":"US-TN-S3M"},
          {"name":"SMARTT","code":"US-TN-SDB"},
          {"name":"SMITHVILLE","code":"US-TN-YSM"},
          {"name":"SMYRNA","code":"US-TN-MQY"},
          {"name":"SNEEDVILLE","code":"US-TN-SQY"},
          {"name":"SODDY DAISY","code":"US-TN-2SD"},
          {"name":"SODDY-DAISY","code":"US-TN-XML"},
          {"name":"SOMERVILLE","code":"US-TN-QSV"},
          {"name":"SOUTH JOHNSON CITY","code":"US-TN-SDR"},
          {"name":"SOUTH PITTSBURG","code":"US-TN-SWU"},
          {"name":"SPARTA","code":"US-TN-SPX"},
          {"name":"SPEEDWELL","code":"US-TN-7BA"},
          {"name":"SPENCER","code":"US-TN-QXC"},
          {"name":"SPRING CITY","code":"US-TN-SGD"},
          {"name":"SPRING HILL","code":"US-TN-IR3"},
          {"name":"SPRINGFIELD","code":"US-TN-SRG"},
          {"name":"SPRINGVILLE","code":"US-TN-XMN"},
          {"name":"STANTON","code":"US-TN-SO7"},
          {"name":"STANTONVILLE","code":"US-TN-TV8"},
          {"name":"STRAWBERRY PLAINS","code":"US-TN-S9Y"},
          {"name":"SURGOINSVILLE","code":"US-TN-SGO"},
          {"name":"SWEETWATER","code":"US-TN-WWE"},
          {"name":"TALBOTT","code":"US-TN-TNT"},
          {"name":"TAZEWELL","code":"US-TN-TZW"},
          {"name":"TELFORD","code":"US-TN-FOF"},
          {"name":"TELLICO PLAINS","code":"US-TN-TPI"},
          {"name":"TEN MILE","code":"US-TN-TME"},
          {"name":"TENNESSEE RIDGE","code":"US-TN-ZFK"},
          {"name":"TERRELL","code":"US-TN-TEE"},
          {"name":"THOMPSONS STATION","code":"US-TN-TVT"},
          {"name":"THREE WAY","code":"US-TN-T3W"},
          {"name":"TIPTONVILLE","code":"US-TN-TPV"},
          {"name":"TRENTON","code":"US-TN-TRN"},
          {"name":"TRI-CITIES REGIONAL AIRPORT","code":"US-TN-TRI"},
          {"name":"TROY","code":"US-TN-TTY"},
          {"name":"TULLAHOMA","code":"US-TN-THA"},
          {"name":"UNICOI","code":"US-TN-UCO"},
          {"name":"UNION CITY","code":"US-TN-UCY"},
          {"name":"UNIONVILLE","code":"US-TN-BU8"},
          {"name":"VANLEER","code":"US-TN-VNR"},
          {"name":"VERONA","code":"US-TN-V2R"},
          {"name":"VONORE","code":"US-TN-VQN"},
          {"name":"WALLAND","code":"US-TN-6NT"},
          {"name":"WARTBURG","code":"US-TN-WTU"},
          {"name":"WASHBURN","code":"US-TN-URW"},
          {"name":"WATERTOWN","code":"US-TN-WWZ"},
          {"name":"WAVERLY","code":"US-TN-WVQ"},
          {"name":"WAYNESBORO","code":"US-TN-WYU"},
          {"name":"WESTMORELAND","code":"US-TN-W2M"},
          {"name":"WHITE BLUFF","code":"US-TN-WHB"},
          {"name":"WHITE HOUSE","code":"US-TN-WHU"},
          {"name":"WHITE PINE","code":"US-TN-WH3"},
          {"name":"WHITES CREEK","code":"US-TN-WC5"},
          {"name":"WHITESBURG","code":"US-TN-XWH"},
          {"name":"WHITEVILLE","code":"US-TN-WV9"},
          {"name":"WHITLOCK","code":"US-TN-WJI"},
          {"name":"WHITWELL","code":"US-TN-W2L"},
          {"name":"WILDERSVILLE","code":"US-TN-2WW"},
          {"name":"WILLISTON","code":"US-TN-WI4"},
          {"name":"WINCHESTER","code":"US-TN-WCV"},
          {"name":"WINFIELD","code":"US-TN-WXF"},
          {"name":"WOODBURY","code":"US-TN-QDB"},
          {"name":"WOODLAWN","code":"US-TN-KWL"},
          {"name":"WOODSTOCK","code":"US-TN-WUD"},
        ]
      },
      {
        name: 'Texas',
        code: 'US-TX',
        cities: [
          {"name":"ABBOTT","code":"US-TX-9AB"},
          {"name":"ABERNATHY","code":"US-TX-UAF"},
          {"name":"ABILENE","code":"US-TX-ABI"},
          {"name":"ACTON","code":"US-TX-TZO"},
          {"name":"ADDISON","code":"US-TX-DDX"},
          {"name":"ADKINS","code":"US-TX-RZU"},
          {"name":"ADRIAN","code":"US-TX-5AN"},
          {"name":"AGUA DULCE","code":"US-TX-T7X"},
          {"name":"ALAMO","code":"US-TX-AMO"},
          {"name":"ALBA","code":"US-TX-XAB"},
          {"name":"ALBANY","code":"US-TX-UBE"},
          {"name":"ALDINE","code":"US-TX-AD8"},
          {"name":"ALEDO","code":"US-TX-AQD"},
          {"name":"ALICE","code":"US-TX-ALI"},
          {"name":"ALIEF","code":"US-TX-3AF"},
          {"name":"ALLEN","code":"US-TX-XAL"},
          {"name":"ALLEYTON","code":"US-TX-9AT"},
          {"name":"ALLISON","code":"US-TX-IXS"},
          {"name":"ALPINE","code":"US-TX-ALE"},
          {"name":"ALTA LOMA","code":"US-TX-AJM"},
          {"name":"ALTO","code":"US-TX-YW9"},
          {"name":"ALVARADO","code":"US-TX-ZAD"},
          {"name":"ALVIN","code":"US-TX-AZC"},
          {"name":"AMARILLO","code":"US-TX-AMA"},
          {"name":"AMELIA","code":"US-TX-XXS"},
          {"name":"AMES","code":"US-TX-QEA"},
          {"name":"AMHERST","code":"US-TX-XSF"},
          {"name":"ANAHUAC","code":"US-TX-AQH"},
          {"name":"ANDERSON","code":"US-TX-9AN"},
          {"name":"ANDREWS","code":"US-TX-UBO"},
          {"name":"ANGLETON","code":"US-TX-AGX"},
          {"name":"ANNA","code":"US-TX-BK6"},
          {"name":"ANNONA","code":"US-TX-A5N"},
          {"name":"ANSON","code":"US-TX-A4S"},
          {"name":"ANTHONY","code":"US-TX-UBM"},
          {"name":"ARANSAS PASS","code":"US-TX-YAS"},
          {"name":"ARCOLA","code":"US-TX-AR4"},
          {"name":"ARGYLE","code":"US-TX-9AR"},
          {"name":"ARLINGTON","code":"US-TX-ARJ"},
          {"name":"ARTESIA","code":"US-TX-A2R"},
          {"name":"ARTHUR CITY","code":"US-TX-AC7"},
          {"name":"ASHLAND","code":"US-TX-6TA"},
          {"name":"ASPERMONT","code":"US-TX-A3S"},
          {"name":"ATASCOCITA","code":"US-TX-AT9"},
          {"name":"ATHENS","code":"US-TX-AWX"},
          {"name":"ATLANTA","code":"US-TX-TAT"},
          {"name":"ATRECO","code":"US-TX-ATR"},
          {"name":"AUBREY","code":"US-TX-AY8"},
          {"name":"AUSTIN","code":"US-TX-AUS"},
          {"name":"AVALON","code":"US-TX-AVB"},
          {"name":"AVERY","code":"US-TX-AVT"},
          {"name":"AVINGER","code":"US-TX-AWJ"},
          {"name":"AZLE","code":"US-TX-AZL"},
          {"name":"BACLIFF","code":"US-TX-BF9"},
          {"name":"BALCH SPRINGS","code":"US-TX-BPN"},
          {"name":"BALLINGER","code":"US-TX-BLR"},
          {"name":"BALMORHEA","code":"US-TX-BA7"},
          {"name":"BANDERA","code":"US-TX-ZGM"},
          {"name":"BARBOURS CUT","code":"US-TX-BBT"},
          {"name":"BARKER","code":"US-TX-B9K"},
          {"name":"BARSTOW","code":"US-TX-B3S"},
          {"name":"BARTLETT","code":"US-TX-XTX"},
          {"name":"BARTONVILLE","code":"US-TX-XB3"},
          {"name":"BASTROP","code":"US-TX-ZBP"},
          {"name":"BATESVILLE","code":"US-TX-8BT"},
          {"name":"BATSON","code":"US-TX-QBN"},
          {"name":"BAY CITY","code":"US-TX-BBC"},
          {"name":"BAYPORT","code":"US-TX-BPX"},
          {"name":"BAYSIDE","code":"US-TX-BY6"},
          {"name":"BAYTOWN","code":"US-TX-HPY"},
          {"name":"BEACH CITY","code":"US-TX-7BC"},
          {"name":"BEASLEY","code":"US-TX-BY4"},
          {"name":"BEAUMONT","code":"US-TX-BPT"},
          {"name":"BEDFORD","code":"US-TX-BOD"},
          {"name":"BEE CAVE","code":"US-TX-VXE"},
          {"name":"BEEVILLE","code":"US-TX-NIR"},
          {"name":"BELLAIRE","code":"US-TX-BRE"},
          {"name":"BELLMEAD","code":"US-TX-TXL"},
          {"name":"BELLVILLE","code":"US-TX-BVB"},
          {"name":"BELMONT","code":"US-TX-IQT"},
          {"name":"BELTON","code":"US-TX-XCZ"},
          {"name":"BENBROOK","code":"US-TX-8BE"},
          {"name":"BERCLAIR","code":"US-TX-QCD"},
          {"name":"BERGER","code":"US-TX-BJG"},
          {"name":"BERTRAM","code":"US-TX-XCY"},
          {"name":"BIG BEND","code":"US-TX-BB4"},
          {"name":"BIG LAKE","code":"US-TX-GQB"},
          {"name":"BIG SPRING","code":"US-TX-HCA"},
          {"name":"BISHOP","code":"US-TX-BHX"},
          {"name":"BLACKLAND, ROCKWELL","code":"US-TX-BL2"},
          {"name":"BLANCO","code":"US-TX-B5L"},
          {"name":"BLANCONIA","code":"US-TX-B2T"},
          {"name":"BLEIBLERVILLE","code":"US-TX-IBH"},
          {"name":"BLOOMBURG","code":"US-TX-8BB"},
          {"name":"BLOOMINGTON","code":"US-TX-BLO"},
          {"name":"BLUE MOUND","code":"US-TX-KBM"},
          {"name":"BLUFF DALE","code":"US-TX-B6D"},
          {"name":"BLUM","code":"US-TX-3BL"},
          {"name":"BOERNE","code":"US-TX-OEB"},
          {"name":"BOGATA","code":"US-TX-B6Y"},
          {"name":"BOLING","code":"US-TX-BG4"},
          {"name":"BON WIER","code":"US-TX-BWX"},
          {"name":"BONGER","code":"US-TX-BOZ"},
          {"name":"BONHAM","code":"US-TX-BOM"},
          {"name":"BOOKER","code":"US-TX-BO2"},
          {"name":"BORGER","code":"US-TX-RGP"},
          {"name":"BOSTON","code":"US-TX-OWQ"},
          {"name":"BOWIE","code":"US-TX-TXB"},
          {"name":"BOYD","code":"US-TX-B2Y"},
          {"name":"BRACKETTVILLE","code":"US-TX-8BV"},
          {"name":"BRADY","code":"US-TX-BBD"},
          {"name":"BRAZORIA","code":"US-TX-BZT"},
          {"name":"BRECKENRIDGE","code":"US-TX-BKD"},
          {"name":"BREMOND","code":"US-TX-BM6"},
          {"name":"BRENHAM","code":"US-TX-XCO"},
          {"name":"BRIARCLIFF","code":"US-TX-9BF"},
          {"name":"BRIDGE CITY","code":"US-TX-IGY"},
          {"name":"BRIDGEPORT","code":"US-TX-DRB"},
          {"name":"BROCK","code":"US-TX-B3K"},
          {"name":"BROOKELAND","code":"US-TX-B6O"},
          {"name":"BROOKSHIRE","code":"US-TX-XDI"},
          {"name":"BROOKSTON","code":"US-TX-B5K"},
          {"name":"BROWNFIELD","code":"US-TX-BXU"},
          {"name":"BROWNSVILLE","code":"US-TX-BRO"},
          {"name":"BROWNWOOD","code":"US-TX-BWD"},
          {"name":"BRUCEVILLE","code":"US-TX-UCV"},
          {"name":"BRYAN","code":"US-TX-CFD"},
          {"name":"BRYSON","code":"US-TX-QYS"},
          {"name":"BUDA","code":"US-TX-BDU"},
          {"name":"BUFFALO","code":"US-TX-UTX"},
          {"name":"BULLARD","code":"US-TX-ULR"},
          {"name":"BULVERDE","code":"US-TX-BU9"},
          {"name":"BURKBURNETT","code":"US-TX-3BB"},
          {"name":"BURLESON","code":"US-TX-BND"},
          {"name":"BURNET","code":"US-TX-7BU"},
          {"name":"BURNETT","code":"US-TX-TTB"},
          {"name":"CACTUS","code":"US-TX-CAC"},
          {"name":"CACTUS (MOORE COUNTY)","code":"US-TX-QUC"},
          {"name":"CADDO MILLS","code":"US-TX-XDY"},
          {"name":"CADIZ","code":"US-TX-YYI"},
          {"name":"CALDWELL","code":"US-TX-AWE"},
          {"name":"CALVERT","code":"US-TX-8CV"},
          {"name":"CAMDEN","code":"US-TX-DKN"},
          {"name":"CAMERON","code":"US-TX-CXM"},
          {"name":"CAMPBELL","code":"US-TX-APB"},
          {"name":"CANADIAN","code":"US-TX-XC2"},
          {"name":"CANTON","code":"US-TX-TCN"},
          {"name":"CANUTILLO","code":"US-TX-UTL"},
          {"name":"CANYON","code":"US-TX-AYO"},
          {"name":"CANYON CITY","code":"US-TX-CY3"},
          {"name":"CANYON LAKE","code":"US-TX-ZHX"},
          {"name":"CARLTON","code":"US-TX-TE2"},
          {"name":"CARMINE","code":"US-TX-C8R"},
          {"name":"CARRIZO SPRINGS","code":"US-TX-CZT"},
          {"name":"CARROLLTON","code":"US-TX-CAX"},
          {"name":"CARTHAGE","code":"US-TX-CJP"},
          {"name":"CASTLE HILLS","code":"US-TX-C4H"},
          {"name":"CASTROVILLE","code":"US-TX-TVG"},
          {"name":"CAT SPRING","code":"US-TX-QAX"},
          {"name":"CATARINA","code":"US-TX-8CT"},
          {"name":"CEDAR BAYOU","code":"US-TX-CBV"},
          {"name":"CEDAR CREEK","code":"US-TX-YXT"},
          {"name":"CEDAR HILL","code":"US-TX-ZCH"},
          {"name":"CEDAR HILL","code":"US-TX-ZZR"},
          {"name":"CEDAR PARK","code":"US-TX-CPK"},
          {"name":"CEE VEE","code":"US-TX-EFV"},
          {"name":"CELINA","code":"US-TX-ICK"},
          {"name":"CENTER","code":"US-TX-GXG"},
          {"name":"CENTER POINT","code":"US-TX-C6E"},
          {"name":"CENTER, LIMESTONE","code":"US-TX-ZCX"},
          {"name":"CHANNELVIEW","code":"US-TX-CVU"},
          {"name":"CHANNING","code":"US-TX-CA4"},
          {"name":"CHARLESTON","code":"US-TX-CST"},
          {"name":"CHARLOTTE","code":"US-TX-TX2"},
          {"name":"CHEEK","code":"US-TX-4CK"},
          {"name":"CHICO","code":"US-TX-C8I"},
          {"name":"CHILDRESS","code":"US-TX-CDS"},
          {"name":"CHILLICOTHE","code":"US-TX-C3L"},
          {"name":"CHINA","code":"US-TX-ZHO"},
          {"name":"CHOCOLATE BAYOU","code":"US-TX-CCL"},
          {"name":"CHRIESMAN","code":"US-TX-IEM"},
          {"name":"CHRISTINE","code":"US-TX-C8E"},
          {"name":"CIBOLO","code":"US-TX-ZCB"},
          {"name":"CISCO","code":"US-TX-CIS"},
          {"name":"CLARENDON","code":"US-TX-XCW"},
          {"name":"CLARK","code":"US-TX-K8R"},
          {"name":"CLARKSVILLE","code":"US-TX-XEO"},
          {"name":"CLEAR LAKE SHORES","code":"US-TX-7CS"},
          {"name":"CLEARLAKE","code":"US-TX-CLC"},
          {"name":"CLEBURNE","code":"US-TX-CBN"},
          {"name":"CLEVELAND","code":"US-TX-CAV"},
          {"name":"CLIFTON","code":"US-TX-CFX"},
          {"name":"CLINT","code":"US-TX-VCL"},
          {"name":"CLUTE","code":"US-TX-CU2"},
          {"name":"CLYDE","code":"US-TX-YDB"},
          {"name":"COCKRELL HILL","code":"US-TX-CK9"},
          {"name":"COLDSPRING","code":"US-TX-8CP"},
          {"name":"COLEMAN","code":"US-TX-COM"},
          {"name":"COLLEGE STATION","code":"US-TX-CLL"},
          {"name":"COLLEYVILLE","code":"US-TX-CT2"},
          {"name":"COLLINSVILLE","code":"US-TX-2CV"},
          {"name":"COLORADO CITY","code":"US-TX-CXP"},
          {"name":"COLUMBUS","code":"US-TX-VCB"},
          {"name":"COMANCHE","code":"US-TX-CMC"},
          {"name":"COMBES","code":"US-TX-OMS"},
          {"name":"COMFORT","code":"US-TX-OMF"},
          {"name":"COMMERCE","code":"US-TX-OMC"},
          {"name":"COMYN","code":"US-TX-CZY"},
          {"name":"CONCAN","code":"US-TX-NZN"},
          {"name":"CONCORD","code":"US-TX-ODZ"},
          {"name":"CONROE","code":"US-TX-CXO"},
          {"name":"CONVERSE","code":"US-TX-VTX"},
          {"name":"COOPER","code":"US-TX-XO2"},
          {"name":"COPELL","code":"US-TX-C3O"},
          {"name":"COPPELL","code":"US-TX-CEJ"},
          {"name":"COPPER CANYON","code":"US-TX-CO9"},
          {"name":"COPPERAS COVE","code":"US-TX-TGC"},
          {"name":"CORINTH","code":"US-TX-TX9"},
          {"name":"CORPUS CHRISTI","code":"US-TX-CRP"},
          {"name":"CORRIGAN","code":"US-TX-IGZ"},
          {"name":"CORSICANA","code":"US-TX-CRS"},
          {"name":"COTULLA","code":"US-TX-COT"},
          {"name":"COUPLAND","code":"US-TX-OPD"},
          {"name":"COYANOSA","code":"US-TX-C9Y"},
          {"name":"CRANDALL","code":"US-TX-KRA"},
          {"name":"CRANE","code":"US-TX-CCG"},
          {"name":"CRAWFORD","code":"US-TX-C4W"},
          {"name":"CREEDMOOR","code":"US-TX-EMV"},
          {"name":"CRESSON","code":"US-TX-ZJQ"},
          {"name":"CROCKETT","code":"US-TX-XOK"},
          {"name":"CROSBY","code":"US-TX-CJY"},
          {"name":"CROSBYTON","code":"US-TX-CXY"},
          {"name":"CROSS ROADS","code":"US-TX-C2S"},
          {"name":"CROSSROADS","code":"US-TX-QCR"},
          {"name":"CROWELL","code":"US-TX-5CM"},
          {"name":"CROWLEY","code":"US-TX-OZY"},
          {"name":"CRYSTAL CITY","code":"US-TX-CYY"},
          {"name":"CUERO","code":"US-TX-CR4"},
          {"name":"CUSHING","code":"US-TX-XHW"},
          {"name":"CUT AND SHOOT","code":"US-TX-CUF"},
          {"name":"CYPRESS","code":"US-TX-CYX"},
          {"name":"D'HANIS","code":"US-TX-DHC"},
          {"name":"DAINGERFIELD","code":"US-TX-DFG"},
          {"name":"DAL-NOR","code":"US-TX-DLR"},
          {"name":"DALHART","code":"US-TX-DHT"},
          {"name":"DALLAS","code":"US-TX-DAL"},
          {"name":"DALLAS-FORT WORTH INT APT","code":"US-TX-DFW"},
          {"name":"DANBURY","code":"US-TX-DUY"},
          {"name":"DANEVANG","code":"US-TX-DV6"},
          {"name":"DAWN","code":"US-TX-TDD"},
          {"name":"DAYTON","code":"US-TX-DTX"},
          {"name":"DE KALB","code":"US-TX-DKB"},
          {"name":"DE LEON","code":"US-TX-ZCQ"},
          {"name":"DE SOTO","code":"US-TX-Z2C"},
          {"name":"DECATUR","code":"US-TX-DEU"},
          {"name":"DECKER PRAIRIE","code":"US-TX-9DP"},
          {"name":"DEER PARK","code":"US-TX-DEX"},
          {"name":"DEFENSE","code":"US-TX-DFX"},
          {"name":"DEL RIO","code":"US-TX-DRT"},
          {"name":"DEL VALLE","code":"US-TX-DVM"},
          {"name":"DELEON","code":"US-TX-DON"},
          {"name":"DELL CITY","code":"US-TX-DC4"},
          {"name":"DENISON","code":"US-TX-DSZ"},
          {"name":"DENNIS","code":"US-TX-D2N"},
          {"name":"DENTON","code":"US-TX-DTN"},
          {"name":"DENVER CITY","code":"US-TX-DVC"},
          {"name":"DEPORT","code":"US-TX-DPO"},
          {"name":"DESOTO","code":"US-TX-DOT"},
          {"name":"DETROIT","code":"US-TX-ZC3"},
          {"name":"DEVINE","code":"US-TX-DVD"},
          {"name":"DIBOLL","code":"US-TX-DIB"},
          {"name":"DICKINSON","code":"US-TX-DSX"},
          {"name":"DILLEY","code":"US-TX-DY3"},
          {"name":"DILWORTH","code":"US-TX-DW7"},
          {"name":"DIME BOX","code":"US-TX-DB3"},
          {"name":"DIMMITT","code":"US-TX-3DT"},
          {"name":"DOMINO","code":"US-TX-DX3"},
          {"name":"DONNA","code":"US-TX-XEY"},
          {"name":"DOWLING","code":"US-TX-3DW"},
          {"name":"DRESDEN","code":"US-TX-DR8"},
          {"name":"DRIFTWOOD","code":"US-TX-DBW"},
          {"name":"DRIPPING SPRINGS","code":"US-TX-TDS"},
          {"name":"DRYDEN","code":"US-TX-DYN"},
          {"name":"DUBLIN","code":"US-TX-DBL"},
          {"name":"DUMAS","code":"US-TX-DUX"},
          {"name":"DUNCANVILLE","code":"US-TX-DV3"},
          {"name":"EAGLE LAKE","code":"US-TX-ELA"},
          {"name":"EAGLE PASS","code":"US-TX-EGP"},
          {"name":"EARLY","code":"US-TX-34L"},
          {"name":"EAST BERNARD","code":"US-TX-TBE"},
          {"name":"EASTLAND","code":"US-TX-ETN"},
          {"name":"EDCOUCH","code":"US-TX-EDX"},
          {"name":"EDDY","code":"US-TX-EJY"},
          {"name":"EDEN, CONCHO","code":"US-TX-ENX"},
          {"name":"EDEN, NACOGDOCHES","code":"US-TX-QEM"},
          {"name":"EDGEWOOD","code":"US-TX-DGK"},
          {"name":"EDINBURG","code":"US-TX-EDQ"},
          {"name":"EDNA","code":"US-TX-9JQ"},
          {"name":"EDROY","code":"US-TX-EY2"},
          {"name":"EL CAMPO","code":"US-TX-ECP"},
          {"name":"EL PASO","code":"US-TX-ELP"},
          {"name":"ELDON","code":"US-TX-EDT"},
          {"name":"ELDORADO","code":"US-TX-DDO"},
          {"name":"ELGIN","code":"US-TX-ZEG"},
          {"name":"ELKHART","code":"US-TX-EKF"},
          {"name":"ELM MOTT","code":"US-TX-EQM"},
          {"name":"ELMENDORF","code":"US-TX-XRW"},
          {"name":"ELWOOD","code":"US-TX-3LF"},
          {"name":"EMORY","code":"US-TX-YEM"},
          {"name":"ENCINO","code":"US-TX-ECF"},
          {"name":"ENERGY","code":"US-TX-N8G"},
          {"name":"ENGLEWOOD YARD","code":"US-TX-ND2"},
          {"name":"ENNIS","code":"US-TX-ENE"},
          {"name":"ENOCHS","code":"US-TX-EOJ"},
          {"name":"ETTER","code":"US-TX-ETT"},
          {"name":"EULESS","code":"US-TX-ELC"},
          {"name":"EUSTACE","code":"US-TX-EKC"},
          {"name":"EVADALE","code":"US-TX-EVA"},
          {"name":"EVANT","code":"US-TX-EVJ"},
          {"name":"EVERMAN","code":"US-TX-EVX"},
          {"name":"FABENS","code":"US-TX-FAB"},
          {"name":"FAIRFIELD","code":"US-TX-FAX"},
          {"name":"FAIRVIEW","code":"US-TX-TX3"},
          {"name":"FALFURRIAS","code":"US-TX-FFH"},
          {"name":"FALLS CITY","code":"US-TX-FJI"},
          {"name":"FARMERS BRANCH","code":"US-TX-FBX"},
          {"name":"FARMERSVILLE","code":"US-TX-VF3"},
          {"name":"FARWELL","code":"US-TX-FAW"},
          {"name":"FATE","code":"US-TX-FXA"},
          {"name":"FAYETTEVILLE","code":"US-TX-TXF"},
          {"name":"FERRIS","code":"US-TX-FEI"},
          {"name":"FISCHER","code":"US-TX-F3S"},
          {"name":"FLATONIA","code":"US-TX-FTP"},
          {"name":"FLINT","code":"US-TX-FXI"},
          {"name":"FLORENCE","code":"US-TX-FLX"},
          {"name":"FLORESVILLE","code":"US-TX-FL6"},
          {"name":"FLOWER MOUND","code":"US-TX-XFG"},
          {"name":"FLOYDADA","code":"US-TX-FDD"},
          {"name":"FOLLETT","code":"US-TX-F2L"},
          {"name":"FOREST HILL","code":"US-TX-FH8"},
          {"name":"FORESTBURG","code":"US-TX-F4R"},
          {"name":"FORNEY","code":"US-TX-FNX"},
          {"name":"FORSAN","code":"US-TX-FS7"},
          {"name":"FORT BLISS","code":"US-TX-YFB"},
          {"name":"FORT DAVIS","code":"US-TX-QFA"},
          {"name":"FORT HANCOCK","code":"US-TX-F3H"},
          {"name":"FORT HOOD","code":"US-TX-FHO"},
          {"name":"FORT SAM HOUSTON","code":"US-TX-F8H"},
          {"name":"FORT STOCKTON","code":"US-TX-FST"},
          {"name":"FORT WORTH","code":"US-TX-FWT"},
          {"name":"FRANKEL CITY","code":"US-TX-FRS"},
          {"name":"FRANKLIN","code":"US-TX-NKN"},
          {"name":"FRANKSTON","code":"US-TX-FNJ"},
          {"name":"FREDERICKSBURG","code":"US-TX-FXG"},
          {"name":"FREDONIA","code":"US-TX-FD2"},
          {"name":"FREEPORT","code":"US-TX-FPO"},
          {"name":"FREER","code":"US-TX-FQB"},
          {"name":"FRESNO","code":"US-TX-FQS"},
          {"name":"FRIENDSWOOD","code":"US-TX-FIW"},
          {"name":"FRIONA","code":"US-TX-FTX"},
          {"name":"FRISCO","code":"US-TX-FIX"},
          {"name":"FROST","code":"US-TX-FSZ"},
          {"name":"FULSHEAR","code":"US-TX-FHA"},
          {"name":"FULTON","code":"US-TX-UTD"},
          {"name":"GAIL","code":"US-TX-G2A"},
          {"name":"GAINESVILLE","code":"US-TX-GLE"},
          {"name":"GALENA PARK","code":"US-TX-GPK"},
          {"name":"GALLATIN","code":"US-TX-ZDI"},
          {"name":"GALVESTON","code":"US-TX-GLS"},
          {"name":"GANADO","code":"US-TX-GD5"},
          {"name":"GARDEN CITY","code":"US-TX-GEC"},
          {"name":"GARDENDALE","code":"US-TX-GT9"},
          {"name":"GARFIELD","code":"US-TX-GF7"},
          {"name":"GARLAND","code":"US-TX-GRX"},
          {"name":"GARY","code":"US-TX-GT4"},
          {"name":"GATESVILLE","code":"US-TX-GJI"},
          {"name":"GENOA","code":"US-TX-GJ4"},
          {"name":"GEORGE BUSH INTERCONTINENTAL APT/HOUSTON","code":"US-TX-IA9"},
          {"name":"GEORGETOWN","code":"US-TX-GTW"},
          {"name":"GIDDINGS","code":"US-TX-GDD"},
          {"name":"GILMER","code":"US-TX-GIX"},
          {"name":"GIRARD","code":"US-TX-VGD"},
          {"name":"GLADEWATER","code":"US-TX-TXG"},
          {"name":"GLADEWATER-LONGVIEW-KILGORE APT","code":"US-TX-GGG"},
          {"name":"GLEN ROSE","code":"US-TX-GLY"},
          {"name":"GLENDALE","code":"US-TX-G6L"},
          {"name":"GODLEY","code":"US-TX-QOY"},
          {"name":"GOLDEN","code":"US-TX-OG5"},
          {"name":"GOLDSMITH","code":"US-TX-GO8"},
          {"name":"GOLDTHWAITE","code":"US-TX-QGX"},
          {"name":"GOLIAD","code":"US-TX-GL7"},
          {"name":"GONZALES","code":"US-TX-GOZ"},
          {"name":"GOODRICH","code":"US-TX-USZ"},
          {"name":"GORDONVILLE","code":"US-TX-GO5"},
          {"name":"GORMAN","code":"US-TX-GOM"},
          {"name":"GRAFORD","code":"US-TX-FVD"},
          {"name":"GRAHAM","code":"US-TX-GHT"},
          {"name":"GRANBURY","code":"US-TX-GBY"},
          {"name":"GRAND PRAIRIE","code":"US-TX-GDP"},
          {"name":"GRAND SALINE","code":"US-TX-GJS"},
          {"name":"GRANDFALLS","code":"US-TX-NDF"},
          {"name":"GRANDVIEW","code":"US-TX-XXA"},
          {"name":"GRANDVIEW, DAWSON","code":"US-TX-DIW"},
          {"name":"GRANGER","code":"US-TX-GGX"},
          {"name":"GRANITE SHOALS","code":"US-TX-GAS"},
          {"name":"GRAPELAND","code":"US-TX-GPL"},
          {"name":"GRAPEVINE","code":"US-TX-GPV"},
          {"name":"GREENVIEW HILLS","code":"US-TX-GWH"},
          {"name":"GREENVILLE","code":"US-TX-GVT"},
          {"name":"GREGORY","code":"US-TX-GOY"},
          {"name":"GRIFFIN","code":"US-TX-GFI"},
          {"name":"GROESBECK","code":"US-TX-GB3"},
          {"name":"GROVES","code":"US-TX-YGS"},
          {"name":"GUN BARREL CITY","code":"US-TX-GU6"},
          {"name":"GUNTER","code":"US-TX-GX4"},
          {"name":"GUSTINE","code":"US-TX-GU4"},
          {"name":"HALLETTSVILLE","code":"US-TX-HV8"},
          {"name":"HALLSVILLE","code":"US-TX-HJV"},
          {"name":"HALTOM CITY","code":"US-TX-HCY"},
          {"name":"HAMILTON","code":"US-TX-HHM"},
          {"name":"HAMLIN","code":"US-TX-XGK"},
          {"name":"HANEY","code":"US-TX-HN2"},
          {"name":"HANKAMER","code":"US-TX-H8K"},
          {"name":"HARBOR ISLAND","code":"US-TX-HJS"},
          {"name":"HARDIN","code":"US-TX-HJX"},
          {"name":"HARKER HEIGHTS","code":"US-TX-HHG"},
          {"name":"HARLINGEN","code":"US-TX-HRL"},
          {"name":"HARPER","code":"US-TX-G8D"},
          {"name":"HART","code":"US-TX-TAR"},
          {"name":"HARTLEY","code":"US-TX-HQY"},
          {"name":"HARWOOD","code":"US-TX-HKW"},
          {"name":"HASKELL","code":"US-TX-HQL"},
          {"name":"HASLET","code":"US-TX-HXC"},
          {"name":"HAWKINS","code":"US-TX-HWT"},
          {"name":"HEARNE","code":"US-TX-HEA"},
          {"name":"HEATH","code":"US-TX-HE4"},
          {"name":"HEBBRONVILLE","code":"US-TX-HBJ"},
          {"name":"HEBERT","code":"US-TX-HB9"},
          {"name":"HELOTES","code":"US-TX-HOQ"},
          {"name":"HEMPHILL TEXAS","code":"US-TX-H3P"},
          {"name":"HEMPSTEAD","code":"US-TX-HEX"},
          {"name":"HEMSTEAD","code":"US-TX-HD3"},
          {"name":"HENDERSON","code":"US-TX-HXN"},
          {"name":"HENRIETTA","code":"US-TX-HTX"},
          {"name":"HEREFORD","code":"US-TX-HFR"},
          {"name":"HEWITT","code":"US-TX-HXI"},
          {"name":"HICKORY CREEK","code":"US-TX-HCO"},
          {"name":"HICO","code":"US-TX-HC6"},
          {"name":"HIDALGO","code":"US-TX-HID"},
          {"name":"HIGHLAND VILLAGE","code":"US-TX-HVG"},
          {"name":"HIGHLAND, DALLAS","code":"US-TX-HL7"},
          {"name":"HIGHLANDS","code":"US-TX-HIN"},
          {"name":"HILLISTER","code":"US-TX-IIT"},
          {"name":"HILLSBORO","code":"US-TX-HLL"},
          {"name":"HITCHCOCK","code":"US-TX-HI6"},
          {"name":"HOCHHEIM","code":"US-TX-HCQ"},
          {"name":"HOCKLEY","code":"US-TX-YZE"},
          {"name":"HONDO","code":"US-TX-XGN"},
          {"name":"HONEY GROVE","code":"US-TX-YHQ"},
          {"name":"HOOKS","code":"US-TX-HKZ"},
          {"name":"HOPE","code":"US-TX-HP5"},
          {"name":"HORIZON CITY","code":"US-TX-HXZ"},
          {"name":"HORSESHOE BAY","code":"US-TX-H5B"},
          {"name":"HOUSTON","code":"US-TX-HOU"},
          {"name":"HOUSTON HEIGHTS","code":"US-TX-3HH"},
          {"name":"HOWE","code":"US-TX-OWX"},
          {"name":"HUFFMAN","code":"US-TX-HFZ"},
          {"name":"HUGHES SPRINGS","code":"US-TX-HPG"},
          {"name":"HULL","code":"US-TX-H9L"},
          {"name":"HUMBLE","code":"US-TX-HMX"},
          {"name":"HUNGERFORD","code":"US-TX-HXF"},
          {"name":"HUNT","code":"US-TX-HUU"},
          {"name":"HUNTSVILLE","code":"US-TX-HTV"},
          {"name":"HURST","code":"US-TX-HUR"},
          {"name":"HUTCHINS","code":"US-TX-HTC"},
          {"name":"HUTTO","code":"US-TX-HT5"},
          {"name":"IDALOU","code":"US-TX-2DU"},
          {"name":"IMPERIAL","code":"US-TX-I4P"},
          {"name":"INDUSTRY","code":"US-TX-XZZ"},
          {"name":"INGLESIDE","code":"US-TX-YIG"},
          {"name":"INGRAM","code":"US-TX-ZFW"},
          {"name":"IOLA","code":"US-TX-IO7"},
          {"name":"IOWA PARK","code":"US-TX-IPK"},
          {"name":"IRA","code":"US-TX-IAV"},
          {"name":"IRAAN","code":"US-TX-IRB"},
          {"name":"IRVING","code":"US-TX-IRT"},
          {"name":"ITALY","code":"US-TX-IAY"},
          {"name":"ITASCA","code":"US-TX-IT3"},
          {"name":"JACINTO CITY","code":"US-TX-JCX"},
          {"name":"JACKSBORO","code":"US-TX-JRO"},
          {"name":"JACKSONVILLE","code":"US-TX-JKV"},
          {"name":"JARRELL","code":"US-TX-JRR"},
          {"name":"JASPER","code":"US-TX-JAS"},
          {"name":"JEFFERSON","code":"US-TX-JEO"},
          {"name":"JEWETT","code":"US-TX-JTT"},
          {"name":"JOHNSON CITY","code":"US-TX-JCY"},
          {"name":"JOLLY","code":"US-TX-JY2"},
          {"name":"JOLLYVILLE","code":"US-TX-JV3"},
          {"name":"JONESBORO","code":"US-TX-9JB"},
          {"name":"JONESTOWN","code":"US-TX-JNN"},
          {"name":"JOSHUA","code":"US-TX-JTX"},
          {"name":"JOURDANTON","code":"US-TX-JN2"},
          {"name":"JUÁREZ","code":"US-TX-J9Z"},
          {"name":"JUNCTION","code":"US-TX-JCT"},
          {"name":"JUSTIN","code":"US-TX-JSI"},
          {"name":"KAMAY","code":"US-TX-KQM"},
          {"name":"KATY","code":"US-TX-KTX"},
          {"name":"KAUFMAN","code":"US-TX-KFA"},
          {"name":"KEENAN","code":"US-TX-KNA"},
          {"name":"KEENE","code":"US-TX-KN2"},
          {"name":"KELLER","code":"US-TX-KEX"},
          {"name":"KEMAH","code":"US-TX-KEM"},
          {"name":"KEMP","code":"US-TX-9KP"},
          {"name":"KENDLETON","code":"US-TX-ZKD"},
          {"name":"KENEDY","code":"US-TX-KDY"},
          {"name":"KENNEDALE","code":"US-TX-KED"},
          {"name":"KERENS","code":"US-TX-KX2"},
          {"name":"KERMIT","code":"US-TX-KHM"},
          {"name":"KERRVILLE","code":"US-TX-ERV"},
          {"name":"KILGORE","code":"US-TX-KGR"},
          {"name":"KILGORE-GLADEWATER-LONGVIEW APT","code":"US-TX-GGG"},
          {"name":"KILLEEN","code":"US-TX-ILE"},
          {"name":"KINGSBURY","code":"US-TX-KG4"},
          {"name":"KINGSLAND","code":"US-TX-KGL"},
          {"name":"KINGSVILLE","code":"US-TX-NQI"},
          {"name":"KINGWOOD","code":"US-TX-KIW"},
          {"name":"KINWOOD","code":"US-TX-KIO"},
          {"name":"KIRBY","code":"US-TX-KI9"},
          {"name":"KLEIN","code":"US-TX-KYI"},
          {"name":"KLONDIKE","code":"US-TX-KDI"},
          {"name":"KNIPPA","code":"US-TX-KP2"},
          {"name":"KOSSE","code":"US-TX-KST"},
          {"name":"KOUNTZE","code":"US-TX-KNZ"},
          {"name":"KRESS","code":"US-TX-KS7"},
          {"name":"KRUGERVILLE","code":"US-TX-K9G"},
          {"name":"KRUM","code":"US-TX-KRU"},
          {"name":"KYLE","code":"US-TX-KYZ"},
          {"name":"LA COSTE","code":"US-TX-L2C"},
          {"name":"LA FERIA","code":"US-TX-LFX"},
          {"name":"LA GRANGE","code":"US-TX-LGX"},
          {"name":"LA MARQUE","code":"US-TX-LM9"},
          {"name":"LA PORTE","code":"US-TX-UQF"},
          {"name":"LA PRYOR","code":"US-TX-T4L"},
          {"name":"LA VERNIA","code":"US-TX-LQZ"},
          {"name":"LA WARD","code":"US-TX-L3W"},
          {"name":"LACKLAND CITY","code":"US-TX-LKJ"},
          {"name":"LADONIA","code":"US-TX-LDX"},
          {"name":"LAGO VISTA","code":"US-TX-LV2"},
          {"name":"LAGUNA VISTA","code":"US-TX-9LV"},
          {"name":"LAKE CREEK","code":"US-TX-KKP"},
          {"name":"LAKE DALLAS","code":"US-TX-AKD"},
          {"name":"LAKE JACKSON","code":"US-TX-LJN"},
          {"name":"LAKE WORTH","code":"US-TX-ULW"},
          {"name":"LAKESIDE","code":"US-TX-LKS"},
          {"name":"LAKEWAY","code":"US-TX-ZEB"},
          {"name":"LAMESA","code":"US-TX-XHG"},
          {"name":"LAMPASAS","code":"US-TX-LAZ"},
          {"name":"LANCASTER","code":"US-TX-LTX"},
          {"name":"LANE CITY","code":"US-TX-TY9"},
          {"name":"LAREDO","code":"US-TX-LRD"},
          {"name":"LATEXO","code":"US-TX-7AX"},
          {"name":"LAUGHLIN AIR FORCE BASE","code":"US-TX-LFB"},
          {"name":"LAVON","code":"US-TX-QLV"},
          {"name":"LEAGUE CITY","code":"US-TX-ZLT"},
          {"name":"LEAKEY","code":"US-TX-LK4"},
          {"name":"LEANDER","code":"US-TX-LEA"},
          {"name":"LEDBETTER","code":"US-TX-DBM"},
          {"name":"LEESBURG","code":"US-TX-LB4"},
          {"name":"LENORAH","code":"US-TX-LH6"},
          {"name":"LEON VALLEY","code":"US-TX-VXL"},
          {"name":"LEONARD","code":"US-TX-587"},
          {"name":"LEVELLAND","code":"US-TX-LLZ"},
          {"name":"LEWISVILLE","code":"US-TX-LJV"},
          {"name":"LEXINGTON","code":"US-TX-IXI"},
          {"name":"LIBERTY","code":"US-TX-LBX"},
          {"name":"LIBERTY HILL","code":"US-TX-LH8"},
          {"name":"LIBERTY HILL, MILAM","code":"US-TX-LH3"},
          {"name":"LINDALE","code":"US-TX-LID"},
          {"name":"LINDEN","code":"US-TX-LI3"},
          {"name":"LINDSAY","code":"US-TX-ULU"},
          {"name":"LINN","code":"US-TX-LQN"},
          {"name":"LITTLE ELM","code":"US-TX-L7E"},
          {"name":"LITTLE ELM","code":"US-TX-LE7"},
          {"name":"LITTLE RIVER","code":"US-TX-LR6"},
          {"name":"LITTLEFIELD","code":"US-TX-LIF"},
          {"name":"LIVERPOOL","code":"US-TX-L8V"},
          {"name":"LIVINGSTON","code":"US-TX-LIX"},
          {"name":"LLANO","code":"US-TX-LL2"},
          {"name":"LOCKHART","code":"US-TX-LQH"},
          {"name":"LOCKNEY","code":"US-TX-LQY"},
          {"name":"LOLITA","code":"US-TX-OIT"},
          {"name":"LOMETA","code":"US-TX-LTA"},
          {"name":"LONE STAR","code":"US-TX-LSX"},
          {"name":"LONG MOTT","code":"US-TX-GQT"},
          {"name":"LONGVIEW","code":"US-TX-ZLV"},
          {"name":"LONGVIEW HEIGHTS","code":"US-TX-YZH"},
          {"name":"LONGVIEW-KILGORE-GLADEWATER APT","code":"US-TX-GGG"},
          {"name":"LOOP","code":"US-TX-2LO"},
          {"name":"LORENA","code":"US-TX-N6L"},
          {"name":"LORENZO","code":"US-TX-L5T"},
          {"name":"LOS FRESNOS","code":"US-TX-OFS"},
          {"name":"LOS INDIOS","code":"US-TX-LII"},
          {"name":"LOTT","code":"US-TX-LQT"},
          {"name":"LOUISE","code":"US-TX-LOU"},
          {"name":"LOVELADY","code":"US-TX-LLI"},
          {"name":"LUBBOCK","code":"US-TX-LBB"},
          {"name":"LUFKIN","code":"US-TX-LFK"},
          {"name":"LULING","code":"US-TX-ULG"},
          {"name":"LUMBERTON","code":"US-TX-LMB"},
          {"name":"LYFORD","code":"US-TX-YFR"},
          {"name":"LYTLE","code":"US-TX-L5E"},
          {"name":"MABANK","code":"US-TX-MWB"},
          {"name":"MADISONVILLE","code":"US-TX-DIX"},
          {"name":"MAGNOLIA","code":"US-TX-AGI"},
          {"name":"MALAKOFF","code":"US-TX-MKF"},
          {"name":"MALONE","code":"US-TX-AOE"},
          {"name":"MALVERN","code":"US-TX-MA7"},
          {"name":"MANOR","code":"US-TX-MAQ"},
          {"name":"MANSFIELD","code":"US-TX-MFL"},
          {"name":"MANVEL","code":"US-TX-MAV"},
          {"name":"MAPLETON","code":"US-TX-NZX"},
          {"name":"MARATHON","code":"US-TX-M7T"},
          {"name":"MARBLE FALLS","code":"US-TX-MFA"},
          {"name":"MARFA","code":"US-TX-MRF"},
          {"name":"MARION","code":"US-TX-MA9"},
          {"name":"MARKHAM","code":"US-TX-MKH"},
          {"name":"MARLIN","code":"US-TX-M9L"},
          {"name":"MARQUEZ","code":"US-TX-QEZ"},
          {"name":"MARSHALL","code":"US-TX-ASL"},
          {"name":"MARTINDALE","code":"US-TX-ZRT"},
          {"name":"MARYNEAL","code":"US-TX-MY4"},
          {"name":"MASON","code":"US-TX-YAQ"},
          {"name":"MATADOR","code":"US-TX-MD2"},
          {"name":"MATAGORDA ISLAND","code":"US-TX-MGI"},
          {"name":"MATHIS","code":"US-TX-ZMI"},
          {"name":"MAURICEVILLE","code":"US-TX-NJS"},
          {"name":"MAY","code":"US-TX-Y2M"},
          {"name":"MAYPEARL","code":"US-TX-YEH"},
          {"name":"MCALLEN","code":"US-TX-TXC"},
          {"name":"MCALLEN-MISSION APT","code":"US-TX-MFE"},
          {"name":"MCCAMEY","code":"US-TX-MPN"},
          {"name":"MCDADE","code":"US-TX-MD3"},
          {"name":"MCGREGOR","code":"US-TX-MG4"},
          {"name":"MCKINNEY","code":"US-TX-MCT"},
          {"name":"MCNEIL","code":"US-TX-JIL"},
          {"name":"MCQUEENEY","code":"US-TX-QNY"},
          {"name":"MEADOWS PLACE","code":"US-TX-EOP"},
          {"name":"MEDINA","code":"US-TX-DIN"},
          {"name":"MEGARGEL","code":"US-TX-JJK"},
          {"name":"MELISSA","code":"US-TX-VMJ"},
          {"name":"MELVIN","code":"US-TX-YJN"},
          {"name":"MEMPHIS","code":"US-TX-MTX"},
          {"name":"MENDOTA","code":"US-TX-DTB"},
          {"name":"MENTONE","code":"US-TX-MX3"},
          {"name":"MERCEDES","code":"US-TX-MRS"},
          {"name":"MERIDIAN","code":"US-TX-M3R"},
          {"name":"MERKEL","code":"US-TX-JEK"},
          {"name":"MERTZON","code":"US-TX-ERT"},
          {"name":"MESQUITE","code":"US-TX-MSQ"},
          {"name":"MEXIA","code":"US-TX-MAX"},
          {"name":"MIAMI","code":"US-TX-IMV"},
          {"name":"MICO","code":"US-TX-IQO"},
          {"name":"MIDFIELD","code":"US-TX-XFW"},
          {"name":"MIDKIFF","code":"US-TX-MK9"},
          {"name":"MIDLAND","code":"US-TX-MAF"},
          {"name":"MIDLAND AIRPARK","code":"US-TX-MDD"},
          {"name":"MIDLOTHIAN","code":"US-TX-MIB"},
          {"name":"MILFORD","code":"US-TX-XIK"},
          {"name":"MILLERSVIEW","code":"US-TX-9MV"},
          {"name":"MILLICAN","code":"US-TX-T9X"},
          {"name":"MILLSAP","code":"US-TX-MZ8"},
          {"name":"MINDEN","code":"US-TX-3MN"},
          {"name":"MINEOLA","code":"US-TX-MIX"},
          {"name":"MINERAL","code":"US-TX-EYA"},
          {"name":"MINERAL WELLS","code":"US-TX-MWL"},
          {"name":"MINGUS","code":"US-TX-M7G"},
          {"name":"MISSION","code":"US-TX-ZMX"},
          {"name":"MISSION-MCALLEN APT","code":"US-TX-MFE"},
          {"name":"MISSOURI CITY","code":"US-TX-MC5"},
          {"name":"MONAHANS","code":"US-TX-MIF"},
          {"name":"MONT BELVIEU","code":"US-TX-MTG"},
          {"name":"MONTE ALTO","code":"US-TX-MXZ"},
          {"name":"MONTGOMERY","code":"US-TX-MG6"},
          {"name":"MOODY","code":"US-TX-OOY"},
          {"name":"MORGAN POINT","code":"US-TX-2MP"},
          {"name":"MORGANS POINT","code":"US-TX-MPP"},
          {"name":"MORSE","code":"US-TX-4MT"},
          {"name":"MORTON","code":"US-TX-ZMT"},
          {"name":"MOSCOW","code":"US-TX-M7C"},
          {"name":"MOULTON","code":"US-TX-X3L"},
          {"name":"MOUNT PLEASANT","code":"US-TX-MPS"},
          {"name":"MOUNT VERNON","code":"US-TX-MV3"},
          {"name":"MOUNT VERNON","code":"US-TX-MVX"},
          {"name":"MUENSTER","code":"US-TX-MST"},
          {"name":"MULDOON","code":"US-TX-MU9"},
          {"name":"MULESHOE","code":"US-TX-MUH"},
          {"name":"MUMFORD","code":"US-TX-TM9"},
          {"name":"MURCHISON","code":"US-TX-MUS"},
          {"name":"MURPHY","code":"US-TX-MU8"},
          {"name":"MUSTANG RIDGE","code":"US-TX-M7R"},
          {"name":"NACOGDOCHES","code":"US-TX-OCH"},
          {"name":"NAPLES","code":"US-TX-NPJ"},
          {"name":"NASH","code":"US-TX-NNB"},
          {"name":"NASH, ELLIS","code":"US-TX-NHC"},
          {"name":"NASSAU BAY","code":"US-TX-N2B"},
          {"name":"NAVASOTA","code":"US-TX-VSA"},
          {"name":"NECHES","code":"US-TX-KHS"},
          {"name":"NEDERLAND","code":"US-TX-NDX"},
          {"name":"NEEDVILLE","code":"US-TX-NVE"},
          {"name":"NEVADA","code":"US-TX-NVT"},
          {"name":"NEW BOSTON","code":"US-TX-NBX"},
          {"name":"NEW BRAUNFELS","code":"US-TX-NHB"},
          {"name":"NEW CANEY","code":"US-TX-NCY"},
          {"name":"NEW COLONY","code":"US-TX-2TX"},
          {"name":"NEW DEAL","code":"US-TX-NXD"},
          {"name":"NEW SUMMERFIELD","code":"US-TX-NUF"},
          {"name":"NEW ULM","code":"US-TX-RUM"},
          {"name":"NEW WAVERLY","code":"US-TX-NWV"},
          {"name":"NEW YORK","code":"US-TX-T5X"},
          {"name":"NEWARK","code":"US-TX-NRK"},
          {"name":"NEWTON","code":"US-TX-NXW"},
          {"name":"NIXON","code":"US-TX-X8N"},
          {"name":"NOCONA","code":"US-TX-NTX"},
          {"name":"NOLAN","code":"US-TX-NLZ"},
          {"name":"NOLANVILLE","code":"US-TX-NVX"},
          {"name":"NOME","code":"US-TX-NMX"},
          {"name":"NORMANGEE","code":"US-TX-NMG"},
          {"name":"NORTH BRANCH","code":"US-TX-NB5"},
          {"name":"NORTH HOUSTON","code":"US-TX-N2R"},
          {"name":"NORTH RICHLAND HILLS","code":"US-TX-NOI"},
          {"name":"NORTHLAKE","code":"US-TX-9NL"},
          {"name":"NURSERY","code":"US-TX-NFU"},
          {"name":"O'DONNELL","code":"US-TX-ODO"},
          {"name":"OAK POINT","code":"US-TX-KOI"},
          {"name":"OAK RIDGE NORTH","code":"US-TX-O3A"},
          {"name":"OAKLAND","code":"US-TX-OK3"},
          {"name":"OAKWOOD","code":"US-TX-OW5"},
          {"name":"ODEM","code":"US-TX-ODM"},
          {"name":"ODESSA","code":"US-TX-OSD"},
          {"name":"OILTON","code":"US-TX-ITB"},
          {"name":"OKLAHOMA","code":"US-TX-OKO"},
          {"name":"OLD GLORY","code":"US-TX-O7G"},
          {"name":"OLD OCEAN","code":"US-TX-LDC"},
          {"name":"OLMITO","code":"US-TX-OMG"},
          {"name":"OLNEY","code":"US-TX-ONY"},
          {"name":"OLTON","code":"US-TX-QOT"},
          {"name":"ONALASKA","code":"US-TX-O9K"},
          {"name":"ORANGE","code":"US-TX-ORG"},
          {"name":"ORE CITY","code":"US-TX-9OC"},
          {"name":"ORLA","code":"US-TX-OR3"},
          {"name":"OVERTON","code":"US-TX-OV4"},
          {"name":"OVILLA","code":"US-TX-OVP"},
          {"name":"OYSTER CREEK","code":"US-TX-YRC"},
          {"name":"OZONA","code":"US-TX-OZA"},
          {"name":"PADUCAH","code":"US-TX-XKG"},
          {"name":"PALACIOS","code":"US-TX-PSX"},
          {"name":"PALESTINE","code":"US-TX-PSN"},
          {"name":"PALMER","code":"US-TX-GPA"},
          {"name":"PALMVIEW","code":"US-TX-7PV"},
          {"name":"PALO PINTO","code":"US-TX-PGZ"},
          {"name":"PAMPA","code":"US-TX-PPA"},
          {"name":"PANHANDLE, CARSON","code":"US-TX-PHY"},
          {"name":"PANHANDLE, CASS","code":"US-TX-PH4"},
          {"name":"PANTEGO","code":"US-TX-TCG"},
          {"name":"PARADISE","code":"US-TX-VPD"},
          {"name":"PARIS","code":"US-TX-PRX"},
          {"name":"PASADENA","code":"US-TX-PAS"},
          {"name":"PATTISON","code":"US-TX-DPS"},
          {"name":"PEARLAND","code":"US-TX-PND"},
          {"name":"PEARSALL","code":"US-TX-PEU"},
          {"name":"PECOS","code":"US-TX-PEQ"},
          {"name":"PENDLETON","code":"US-TX-PDD"},
          {"name":"PENITAS","code":"US-TX-P9N"},
          {"name":"PENWELL","code":"US-TX-JNL"},
          {"name":"PERRYTON","code":"US-TX-EYN"},
          {"name":"PETERSBURG","code":"US-TX-PBB"},
          {"name":"PETROLIA","code":"US-TX-PXI"},
          {"name":"PETTY","code":"US-TX-EQY"},
          {"name":"PFLUGERVILLE","code":"US-TX-PFV"},
          {"name":"PHARR","code":"US-TX-PFX"},
          {"name":"PHOENIX","code":"US-TX-5JD"},
          {"name":"PILOT POINT","code":"US-TX-PQP"},
          {"name":"PINEHURST","code":"US-TX-QIT"},
          {"name":"PITTSBURG","code":"US-TX-PHG"},
          {"name":"PLACEDO","code":"US-TX-P9C"},
          {"name":"PLAINS","code":"US-TX-PLJ"},
          {"name":"PLAINVIEW","code":"US-TX-PVW"},
          {"name":"PLANO","code":"US-TX-PLZ"},
          {"name":"PLANTERSVILLE","code":"US-TX-2PV"},
          {"name":"PLEASANTON","code":"US-TX-PT5"},
          {"name":"POINT","code":"US-TX-T9S"},
          {"name":"POINT COMFORT","code":"US-TX-PCR"},
          {"name":"PONDER","code":"US-TX-PZN"},
          {"name":"POOLVILLE","code":"US-TX-9PO"},
          {"name":"PORT ALTO","code":"US-TX-QPT"},
          {"name":"PORT ARANSAS","code":"US-TX-TZS"},
          {"name":"PORT ARTHUR","code":"US-TX-POA"},
          {"name":"PORT BOLIVAR","code":"US-TX-POB"},
          {"name":"PORT ISABEL","code":"US-TX-PIS"},
          {"name":"PORT LAVACA","code":"US-TX-PLV"},
          {"name":"PORT NECHES","code":"US-TX-PON"},
          {"name":"PORTER","code":"US-TX-PXE"},
          {"name":"PORTLAND","code":"US-TX-DTP"},
          {"name":"POST","code":"US-TX-PZS"},
          {"name":"POTEET","code":"US-TX-PT8"},
          {"name":"POTTSBORO","code":"US-TX-PSR"},
          {"name":"POWDERLY","code":"US-TX-TXX"},
          {"name":"PRAIRIE HILL, LIMESTONE","code":"US-TX-IHI"},
          {"name":"PRAIRIE HILL, WASHINGTON","code":"US-TX-XPH"},
          {"name":"PRAIRIE VIEW","code":"US-TX-PZW"},
          {"name":"PREMONT","code":"US-TX-ZWZ"},
          {"name":"PRESIDIO","code":"US-TX-PRE"},
          {"name":"PRINCETON","code":"US-TX-TPX"},
          {"name":"PROGRESO","code":"US-TX-PRG"},
          {"name":"PROSPER","code":"US-TX-QPX"},
          {"name":"PUERTO RICO","code":"US-TX-PR7"},
          {"name":"PYOTE","code":"US-TX-YP9"},
          {"name":"QUANAH","code":"US-TX-QNT"},
          {"name":"QUEEN CITY","code":"US-TX-ZQU"},
          {"name":"QUINTANA","code":"US-TX-QUT"},
          {"name":"QUITAQUE","code":"US-TX-QTG"},
          {"name":"QUITMAN","code":"US-TX-QTC"},
          {"name":"RALLS","code":"US-TX-XKZ"},
          {"name":"RANGER","code":"US-TX-RGR"},
          {"name":"RAYMONDVILLE","code":"US-TX-RMV"},
          {"name":"RAYWOOD","code":"US-TX-ZJL"},
          {"name":"REAGAN","code":"US-TX-YGA"},
          {"name":"RED BLUFF","code":"US-TX-RB5"},
          {"name":"RED OAK","code":"US-TX-RQK"},
          {"name":"RED RIVER AD","code":"US-TX-RRV"},
          {"name":"RED ROCK","code":"US-TX-EOF"},
          {"name":"REDWATER","code":"US-TX-RPD"},
          {"name":"REFUGIO","code":"US-TX-RFG"},
          {"name":"REKLAW","code":"US-TX-RK9"},
          {"name":"RENO","code":"US-TX-RNQ"},
          {"name":"RHOME","code":"US-TX-RBM"},
          {"name":"RICE","code":"US-TX-RJI"},
          {"name":"RICHARDSON","code":"US-TX-RIN"},
          {"name":"RICHLAND HILLS","code":"US-TX-XLG"},
          {"name":"RICHMOND","code":"US-TX-XLA"},
          {"name":"RIESEL","code":"US-TX-RIZ"},
          {"name":"RIO GRANDE CITY","code":"US-TX-RGC"},
          {"name":"RIO HONDO","code":"US-TX-RH8"},
          {"name":"ROANOKE","code":"US-TX-RNE"},
          {"name":"ROBSTOWN","code":"US-TX-RT8"},
          {"name":"ROCHESTER","code":"US-TX-RXT"},
          {"name":"ROCKDALE","code":"US-TX-RCK"},
          {"name":"ROCKPORT","code":"US-TX-RKP"},
          {"name":"ROCKSPRINGS","code":"US-TX-RS6"},
          {"name":"ROCKWALL","code":"US-TX-XLI"},
          {"name":"ROGERS","code":"US-TX-RGQ"},
          {"name":"ROMA","code":"US-TX-FAL"},
          {"name":"ROPESVILLE","code":"US-TX-RVP"},
          {"name":"ROSANKY","code":"US-TX-RK2"},
          {"name":"ROSCOE","code":"US-TX-UOE"},
          {"name":"ROSE CITY","code":"US-TX-QOI"},
          {"name":"ROSEBUD","code":"US-TX-IUD"},
          {"name":"ROSENBERG","code":"US-TX-RSG"},
          {"name":"ROSHARON","code":"US-TX-RHQ"},
          {"name":"ROSSER","code":"US-TX-RR2"},
          {"name":"ROSSVILLE","code":"US-TX-7T7"},
          {"name":"ROTAN","code":"US-TX-RTX"},
          {"name":"ROUND ROCK","code":"US-TX-RRX"},
          {"name":"ROUND TOP","code":"US-TX-RQT"},
          {"name":"ROWENA","code":"US-TX-RZW"},
          {"name":"ROWLETT","code":"US-TX-ZRE"},
          {"name":"ROYSE CITY","code":"US-TX-RYX"},
          {"name":"RULE","code":"US-TX-RUL"},
          {"name":"RUSK","code":"US-TX-UKG"},
          {"name":"RYE","code":"US-TX-RYZ"},
          {"name":"SABINAL","code":"US-TX-USJ"},
          {"name":"SABINE","code":"US-TX-SAB"},
          {"name":"SABINE PASS","code":"US-TX-NSS"},
          {"name":"SACHSE","code":"US-TX-SX2"},
          {"name":"SAGINAW","code":"US-TX-SJQ"},
          {"name":"SAINT JO","code":"US-TX-SJ3"},
          {"name":"SALADO","code":"US-TX-575"},
          {"name":"SALT FLAT","code":"US-TX-TFP"},
          {"name":"SALTILLO","code":"US-TX-S7O"},
          {"name":"SAN ANGELO","code":"US-TX-SJT"},
          {"name":"SAN ANTONIO","code":"US-TX-SAT"},
          {"name":"SAN AUGUSTINE","code":"US-TX-SA2"},
          {"name":"SAN BENITO","code":"US-TX-SBZ"},
          {"name":"SAN DIEGO","code":"US-TX-SD2"},
          {"name":"SAN FELIPE","code":"US-TX-NLP"},
          {"name":"SAN GABRIEL","code":"US-TX-SG7"},
          {"name":"SAN JUAN","code":"US-TX-JSZ"},
          {"name":"SAN LEON","code":"US-TX-7DN"},
          {"name":"SAN MARCOS","code":"US-TX-SMC"},
          {"name":"SAN SABA","code":"US-TX-ZSS"},
          {"name":"SAN YGNACIO","code":"US-TX-YGC"},
          {"name":"SAND HILLS","code":"US-TX-SD7"},
          {"name":"SANDERSON","code":"US-TX-DSA"},
          {"name":"SANGER","code":"US-TX-SGJ"},
          {"name":"SANTA ANNA","code":"US-TX-NNX"},
          {"name":"SANTA ELENA","code":"US-TX-TQA"},
          {"name":"SANTA FE","code":"US-TX-FEZ"},
          {"name":"SANTA ROSA","code":"US-TX-ZRS"},
          {"name":"SANTO","code":"US-TX-P8P"},
          {"name":"SARITA","code":"US-TX-S4Y"},
          {"name":"SAVOY","code":"US-TX-OYT"},
          {"name":"SCHERTZ","code":"US-TX-SRT"},
          {"name":"SCHULENBURG","code":"US-TX-SBW"},
          {"name":"SCOTTSVILLE","code":"US-TX-SZF"},
          {"name":"SEABROOK","code":"US-TX-SEO"},
          {"name":"SEADRIFT","code":"US-TX-XLR"},
          {"name":"SEAGOVILLE","code":"US-TX-SVI"},
          {"name":"SEAGRAVES","code":"US-TX-SGK"},
          {"name":"SEALY","code":"US-TX-SLY"},
          {"name":"SEBASTIAN","code":"US-TX-XNB"},
          {"name":"SEGUIN","code":"US-TX-SQU"},
          {"name":"SELMA","code":"US-TX-SQD"},
          {"name":"SEMINOLE","code":"US-TX-SOE"},
          {"name":"SEQUIN","code":"US-TX-TSQ"},
          {"name":"SEVEN POINTS","code":"US-TX-EVP"},
          {"name":"SEYMOUR","code":"US-TX-SET"},
          {"name":"SHALLOWATER","code":"US-TX-S9H"},
          {"name":"SHAMROCK, WHEELER","code":"US-TX-SR5"},
          {"name":"SHELBYVILLE","code":"US-TX-IYE"},
          {"name":"SHELDON","code":"US-TX-XMB"},
          {"name":"SHENANDOAH","code":"US-TX-DOH"},
          {"name":"SHEPHERD","code":"US-TX-HPE"},
          {"name":"SHEPPARD","code":"US-TX-XSX"},
          {"name":"SHERMAN","code":"US-TX-SHP"},
          {"name":"SHERMAN-DENISON","code":"US-TX-PNX"},
          {"name":"SHINER","code":"US-TX-INR"},
          {"name":"SHOREACRES","code":"US-TX-RC5"},
          {"name":"SIERRA BLANCA","code":"US-TX-IEB"},
          {"name":"SILSBEE","code":"US-TX-XLP"},
          {"name":"SILVERTON","code":"US-TX-ILJ"},
          {"name":"SIMONTON","code":"US-TX-SM8"},
          {"name":"SINTON","code":"US-TX-2SI"},
          {"name":"SLATON","code":"US-TX-XME"},
          {"name":"SMITHVILLE, BASTROP","code":"US-TX-S2V"},
          {"name":"SNYDER","code":"US-TX-SNK"},
          {"name":"SOCORRO","code":"US-TX-XSR"},
          {"name":"SOMERSET","code":"US-TX-SOZ"},
          {"name":"SONORA","code":"US-TX-7TS"},
          {"name":"SOUTH HOUSTON","code":"US-TX-HQU"},
          {"name":"SOUTH PADRE ISLAND","code":"US-TX-PXD"},
          {"name":"SOUTHLAKE","code":"US-TX-ZLE"},
          {"name":"SPEARMAN","code":"US-TX-OPM"},
          {"name":"SPICEWOOD","code":"US-TX-SKV"},
          {"name":"SPLENDORA","code":"US-TX-SD8"},
          {"name":"SPRING","code":"US-TX-ABS"},
          {"name":"SPRING BRANCH","code":"US-TX-SB7"},
          {"name":"SPRING VALLEY","code":"US-TX-GTX"},
          {"name":"SPRINGLAKE","code":"US-TX-SGP"},
          {"name":"SPRINGTOWN","code":"US-TX-SP8"},
          {"name":"SPUR","code":"US-TX-SU5"},
          {"name":"SPURGER","code":"US-TX-UEC"},
          {"name":"STAFFORD","code":"US-TX-STD"},
          {"name":"STAMFORD","code":"US-TX-STF"},
          {"name":"STANTON","code":"US-TX-STX"},
          {"name":"STAR","code":"US-TX-KTA"},
          {"name":"STEPHENVILLE","code":"US-TX-SEP"},
          {"name":"STERLING CITY","code":"US-TX-YTE"},
          {"name":"STOCKDALE","code":"US-TX-SD4"},
          {"name":"STONEWALL","code":"US-TX-S9E"},
          {"name":"STRANG","code":"US-TX-SNB"},
          {"name":"STRATFORD","code":"US-TX-3ST"},
          {"name":"SUDAN","code":"US-TX-SDU"},
          {"name":"SUGAR LAND","code":"US-TX-SGR"},
          {"name":"SULLIVAN CITY","code":"US-TX-UVT"},
          {"name":"SULPHUR SPRINGS","code":"US-TX-SLR"},
          {"name":"SUMMERFIELD","code":"US-TX-KSF"},
          {"name":"SUMNER","code":"US-TX-UMN"},
          {"name":"SUNNYVALE","code":"US-TX-SVD"},
          {"name":"SUNRAY","code":"US-TX-UNR"},
          {"name":"SUNSET VALLEY","code":"US-TX-S8L"},
          {"name":"SWEENY","code":"US-TX-DXU"},
          {"name":"SWEETWATER","code":"US-TX-SWW"},
          {"name":"TAFT","code":"US-TX-8AF"},
          {"name":"TAHOKA","code":"US-TX-TAH"},
          {"name":"TALCO","code":"US-TX-TC7"},
          {"name":"TARZAN","code":"US-TX-TZN"},
          {"name":"TATUM","code":"US-TX-TZU"},
          {"name":"TAYLOR","code":"US-TX-TYT"},
          {"name":"TEAGUE","code":"US-TX-UG2"},
          {"name":"TEMPLE","code":"US-TX-TPL"},
          {"name":"TENAHA","code":"US-TX-T4X"},
          {"name":"TENNESSEE COLONY","code":"US-TX-TJC"},
          {"name":"TERLINGUA","code":"US-TX-TL5"},
          {"name":"TERRELL","code":"US-TX-TRL"},
          {"name":"TEXARKANA","code":"US-TX-TEX"},
          {"name":"TEXAS CITY","code":"US-TX-TXT"},
          {"name":"THE COLONY","code":"US-TX-CY2"},
          {"name":"THE HILLS","code":"US-TX-TH7"},
          {"name":"THE WOODLANDS","code":"US-TX-THW"},
          {"name":"THOMPSONS","code":"US-TX-T9H"},
          {"name":"THORNDALE","code":"US-TX-RN6"},
          {"name":"THRALL","code":"US-TX-TH2"},
          {"name":"THREE RIVERS","code":"US-TX-XTV"},
          {"name":"TILDEN","code":"US-TX-TD8"},
          {"name":"TIMPSON","code":"US-TX-TT5"},
          {"name":"TIOGA","code":"US-TX-NS4"},
          {"name":"TOKIO","code":"US-TX-KIP"},
          {"name":"TOLAR","code":"US-TX-TXS"},
          {"name":"TOM BEAN","code":"US-TX-TQB"},
          {"name":"TOMBALL","code":"US-TX-TOB"},
          {"name":"TORNILLO","code":"US-TX-QTN"},
          {"name":"TOW","code":"US-TX-T5W"},
          {"name":"TRENTON","code":"US-TX-T6N"},
          {"name":"TRINIDAD","code":"US-TX-9TD"},
          {"name":"TRINITY","code":"US-TX-INZ"},
          {"name":"TROPHY CLUB","code":"US-TX-TR7"},
          {"name":"TROUP","code":"US-TX-TXP"},
          {"name":"TROY","code":"US-TX-TQZ"},
          {"name":"TULIA","code":"US-TX-TIA"},
          {"name":"TURKEY","code":"US-TX-TKR"},
          {"name":"TYE","code":"US-TX-TQY"},
          {"name":"TYLER","code":"US-TX-TYR"},
          {"name":"UNIVERSAL CITY","code":"US-TX-KVY"},
          {"name":"UNIVERSITY PARK","code":"US-TX-UKK"},
          {"name":"UVALDE","code":"US-TX-UVA"},
          {"name":"VALENTINE","code":"US-TX-VTE"},
          {"name":"VALLEY VIEW","code":"US-TX-VLW"},
          {"name":"VAN","code":"US-TX-ZFN"},
          {"name":"VAN ALSTYNE","code":"US-TX-VLS"},
          {"name":"VAN HORN","code":"US-TX-VHN"},
          {"name":"VEGA","code":"US-TX-VEG"},
          {"name":"VENUS","code":"US-TX-VE2"},
          {"name":"VERNON","code":"US-TX-VRT"},
          {"name":"VICKERY","code":"US-TX-VCK"},
          {"name":"VICTORIA","code":"US-TX-VCT"},
          {"name":"VICTORY CITY","code":"US-TX-VTY"},
          {"name":"VIDOR","code":"US-TX-VDO"},
          {"name":"VINTON","code":"US-TX-VIB"},
          {"name":"VIOLA","code":"US-TX-VIO"},
          {"name":"VOCA","code":"US-TX-TXZ"},
          {"name":"VON ORMY","code":"US-TX-ZJE"},
          {"name":"WACO","code":"US-TX-ACT"},
          {"name":"WADSWORTH","code":"US-TX-WDT"},
          {"name":"WAELDER","code":"US-TX-QWD"},
          {"name":"WALBURG","code":"US-TX-VXS"},
          {"name":"WALL","code":"US-TX-JQW"},
          {"name":"WALLER","code":"US-TX-VLL"},
          {"name":"WALLISVILLE","code":"US-TX-WLV"},
          {"name":"WALNUT SPRINGS","code":"US-TX-WG3"},
          {"name":"WARRENTON","code":"US-TX-TWG"},
          {"name":"WASKOM","code":"US-TX-ZJN"},
          {"name":"WAVERLY","code":"US-TX-XOF"},
          {"name":"WAXAHACHIE","code":"US-TX-WAX"},
          {"name":"WEATHERFORD","code":"US-TX-WEA"},
          {"name":"WEBSTER","code":"US-TX-WB3"},
          {"name":"WEBSTER, HARRIS","code":"US-TX-AFW"},
          {"name":"WEESATCHE","code":"US-TX-W2E"},
          {"name":"WEIMAR","code":"US-TX-WJR"},
          {"name":"WELLINGTON","code":"US-TX-7HZ"},
          {"name":"WELLMAN","code":"US-TX-WNX"},
          {"name":"WESLACO","code":"US-TX-WKO"},
          {"name":"WEST","code":"US-TX-W5T"},
          {"name":"WEST COLUMBIA","code":"US-TX-WB4"},
          {"name":"WEST LAKE HILLS","code":"US-TX-3TX"},
          {"name":"WEST ORANGE","code":"US-TX-XOW"},
          {"name":"WESTLAKE","code":"US-TX-WK2"},
          {"name":"WESTMINSTER","code":"US-TX-RWM"},
          {"name":"WHARTON","code":"US-TX-WHT"},
          {"name":"WHITE OAK","code":"US-TX-WTX"},
          {"name":"WHITE SETTLEMENT","code":"US-TX-IYT"},
          {"name":"WHITEHOUSE","code":"US-TX-OUJ"},
          {"name":"WHITESBORO","code":"US-TX-WH8"},
          {"name":"WHITEWRIGHT","code":"US-TX-W9H"},
          {"name":"WHITNEY","code":"US-TX-WT5"},
          {"name":"WHITSETT","code":"US-TX-WT6"},
          {"name":"WICHITA FALLS","code":"US-TX-SPS"},
          {"name":"WICKETT","code":"US-TX-IKC"},
          {"name":"WILLIAMS","code":"US-TX-WIA"},
          {"name":"WILLIS","code":"US-TX-WYX"},
          {"name":"WILLOW PARK","code":"US-TX-W26"},
          {"name":"WILLS POINT","code":"US-TX-ILS"},
          {"name":"WILMER","code":"US-TX-WXR"},
          {"name":"WILSON","code":"US-TX-WQI"},
          {"name":"WIMBERLEY","code":"US-TX-WJB"},
          {"name":"WINDTHORST","code":"US-TX-IW3"},
          {"name":"WINFIELD","code":"US-TX-INF"},
          {"name":"WINGATE","code":"US-TX-WGX"},
          {"name":"WINK","code":"US-TX-INK"},
          {"name":"WINNIE","code":"US-TX-WXE"},
          {"name":"WINNSBORO","code":"US-TX-WJJ"},
          {"name":"WINONA","code":"US-TX-WN9"},
          {"name":"WINTERS","code":"US-TX-IZR"},
          {"name":"WOLFE CITY","code":"US-TX-V67"},
          {"name":"WOLFFORTH","code":"US-TX-WOF"},
          {"name":"WOODLAWN","code":"US-TX-3OO"},
          {"name":"WOODSBORO","code":"US-TX-DBK"},
          {"name":"WOODVILLE","code":"US-TX-WV7"},
          {"name":"WOODWAY","code":"US-TX-DWY"},
          {"name":"WORTHAM","code":"US-TX-WRX"},
          {"name":"WRIGHT CITY","code":"US-TX-W9U"},
          {"name":"WYLIE","code":"US-TX-WYH"},
          {"name":"YOAKUM","code":"US-TX-YOA"},
          {"name":"YORKTOWN","code":"US-TX-YKT"},
          {"name":"ZAPATA","code":"US-TX-ZTA"},
          {"name":"ZITA","code":"US-TX-IZI"},
        ]
      },
      {
        name: 'Utah',
        code: 'US-UT',
        cities: [
          {"name":"ALPINE","code":"US-UT-AP2"},
          {"name":"ALTA","code":"US-UT-XBO"},
          {"name":"ALTAMONT","code":"US-UT-TMO"},
          {"name":"AMERICAN FORK","code":"US-UT-ZAF"},
          {"name":"ARAGONITE","code":"US-UT-RG4"},
          {"name":"AURORA","code":"US-UT-UOA"},
          {"name":"AXTELL","code":"US-UT-VXT"},
          {"name":"BEAR RIVER CITY","code":"US-UT-UHY"},
          {"name":"BEAVER","code":"US-UT-8EQ"},
          {"name":"BERYL","code":"US-UT-EYL"},
          {"name":"BINGHAM CANYON","code":"US-UT-YUU"},
          {"name":"BLANDING","code":"US-UT-BDG"},
          {"name":"BLUFF","code":"US-UT-XDB"},
          {"name":"BLUFFDALE","code":"US-UT-LFF"},
          {"name":"BONANZA","code":"US-UT-BON"},
          {"name":"BOUNTIFUL","code":"US-UT-BTF"},
          {"name":"BRIAN HEAD","code":"US-UT-B2H"},
          {"name":"BRIGHAM CITY","code":"US-UT-BMC"},
          {"name":"BRYCE","code":"US-UT-BCE"},
          {"name":"BULLFROG BASIN","code":"US-UT-BFG"},
          {"name":"CARBONVILLE","code":"US-UT-9CB"},
          {"name":"CASTLE DALE","code":"US-UT-CE6"},
          {"name":"CEDAR CITY","code":"US-UT-CDC"},
          {"name":"CEDAR HILLS","code":"US-UT-EHS"},
          {"name":"CENTERVILLE","code":"US-UT-ETI"},
          {"name":"CHARLESTON","code":"US-UT-C84"},
          {"name":"CIRCLEVILLE","code":"US-UT-IEV"},
          {"name":"CLEARFIELD","code":"US-UT-CQU"},
          {"name":"CLEVELAND","code":"US-UT-VZD"},
          {"name":"CLIFTON","code":"US-UT-UCF"},
          {"name":"CLINTON","code":"US-UT-JCL"},
          {"name":"COALVILLE","code":"US-UT-VTJ"},
          {"name":"COLLINSTON","code":"US-UT-OSN"},
          {"name":"COPPERTON","code":"US-UT-C9P"},
          {"name":"CORINNE","code":"US-UT-JCE"},
          {"name":"COTTONWOOD HEIGHTS","code":"US-UT-DGH"},
          {"name":"DELTA","code":"US-UT-DTA"},
          {"name":"DEWEYVILLE","code":"US-UT-YVC"},
          {"name":"DRAPER","code":"US-UT-DRQ"},
          {"name":"DUGWAY","code":"US-UT-DPG"},
          {"name":"EAGLE MOUNTAIN","code":"US-UT-3GL"},
          {"name":"EDEN","code":"US-UT-DJE"},
          {"name":"ELBERTA","code":"US-UT-BZ4"},
          {"name":"ENTERPRISE, WASHINGTON","code":"US-UT-EEP"},
          {"name":"EPHRAIM","code":"US-UT-ERM"},
          {"name":"ERDA","code":"US-UT-ER9"},
          {"name":"EUREKA","code":"US-UT-ER7"},
          {"name":"FARMINGTON","code":"US-UT-FGJ"},
          {"name":"FARR WEST","code":"US-UT-U7T"},
          {"name":"FILLMORE","code":"US-UT-FIL"},
          {"name":"FREMONT JUNCTION","code":"US-UT-FPJ"},
          {"name":"GARLAND","code":"US-UT-UGD"},
          {"name":"GLENDALE","code":"US-UT-GD3"},
          {"name":"GRANTSVILLE","code":"US-UT-GTE"},
          {"name":"GREEN RIVER","code":"US-UT-RVR"},
          {"name":"HANKSVILLE","code":"US-UT-HVE"},
          {"name":"HEBER CITY","code":"US-UT-HC2"},
          {"name":"HENEFER","code":"US-UT-UTF"},
          {"name":"HERRIMAN","code":"US-UT-ZQQ"},
          {"name":"HIGHLAND","code":"US-UT-UT4"},
          {"name":"HILDALE","code":"US-UT-HKD"},
          {"name":"HILL AIR FORCE BASE","code":"US-UT-HIF"},
          {"name":"HOLDEN","code":"US-UT-HD6"},
          {"name":"HOLLADAY","code":"US-UT-HD2"},
          {"name":"HONEYVILLE","code":"US-UT-HV6"},
          {"name":"HOOPER","code":"US-UT-HO7"},
          {"name":"HUNTINGTON","code":"US-UT-UTT"},
          {"name":"HUNTSVILLE","code":"US-UT-V8L"},
          {"name":"HURRICANE","code":"US-UT-HRC"},
          {"name":"HYDE PARK","code":"US-UT-YDK"},
          {"name":"HYRUM","code":"US-UT-HYU"},
          {"name":"IVINS","code":"US-UT-IV5"},
          {"name":"KAMAS","code":"US-UT-KM7"},
          {"name":"KANAB","code":"US-UT-KNB"},
          {"name":"KANOSH","code":"US-UT-KAO"},
          {"name":"KAYSVILLE","code":"US-UT-KAY"},
          {"name":"KEARNS","code":"US-UT-7UT"},
          {"name":"LA VERKIN","code":"US-UT-LV5"},
          {"name":"LAKE POINT","code":"US-UT-QLP"},
          {"name":"LAPOINT","code":"US-UT-LPI"},
          {"name":"LAYTON","code":"US-UT-LTJ"},
          {"name":"LEAMINGTON","code":"US-UT-LM7"},
          {"name":"LEEDS","code":"US-UT-LD5"},
          {"name":"LEHI","code":"US-UT-LEI"},
          {"name":"LEVAN","code":"US-UT-LV8"},
          {"name":"LEWISTON","code":"US-UT-LZO"},
          {"name":"LINDON","code":"US-UT-ZLN"},
          {"name":"LOGAN","code":"US-UT-LGU"},
          {"name":"MAGNA","code":"US-UT-XIU"},
          {"name":"MANTI","code":"US-UT-NTJ"},
          {"name":"MAPLETON","code":"US-UT-M9T"},
          {"name":"MENDON","code":"US-UT-M2O"},
          {"name":"MIDVALE","code":"US-UT-MZC"},
          {"name":"MIDWAY","code":"US-UT-M7Y"},
          {"name":"MILFORD","code":"US-UT-MLF"},
          {"name":"MINERSVILLE","code":"US-UT-M88"},
          {"name":"MOAB","code":"US-UT-CNY"},
          {"name":"MONA","code":"US-UT-4MU"},
          {"name":"MONTICELLO","code":"US-UT-MXC"},
          {"name":"MONUMENT VALLEY GLDNGS","code":"US-UT-GMV"},
          {"name":"MORGAN","code":"US-UT-GGM"},
          {"name":"MORONI","code":"US-UT-MR6"},
          {"name":"MOUNT PLEASANT","code":"US-UT-MSD"},
          {"name":"MOUNTAIN GREEN","code":"US-UT-TGM"},
          {"name":"MURRAY","code":"US-UT-MUY"},
          {"name":"MYTON","code":"US-UT-M7O"},
          {"name":"NEPHI","code":"US-UT-NPH"},
          {"name":"NEWCASTLE","code":"US-UT-YNC"},
          {"name":"NIBLEY","code":"US-UT-QNX"},
          {"name":"NORTH LOGAN","code":"US-UT-NOJ"},
          {"name":"NORTH OGDEN","code":"US-UT-YGD"},
          {"name":"NORTH SALT LAKE","code":"US-UT-NSL"},
          {"name":"OAKLEY","code":"US-UT-OK8"},
          {"name":"OGDEN","code":"US-UT-OGD"},
          {"name":"ORANGEVILLE","code":"US-UT-OGV"},
          {"name":"OREM","code":"US-UT-OEU"},
          {"name":"PANGUITCH","code":"US-UT-PNU"},
          {"name":"PARK CITY","code":"US-UT-PAC"},
          {"name":"PAROWAN","code":"US-UT-PWB"},
          {"name":"PAYSON","code":"US-UT-PYU"},
          {"name":"PETERSBORO","code":"US-UT-2PB"},
          {"name":"PLEASANT GROVE","code":"US-UT-PXG"},
          {"name":"PLEASANT VIEW, WEBER","code":"US-UT-XOX"},
          {"name":"PLYMOUTH","code":"US-UT-XOU"},
          {"name":"PRICE","code":"US-UT-PUC"},
          {"name":"PROMONTORY","code":"US-UT-PXR"},
          {"name":"PROVIDENCE","code":"US-UT-VEC"},
          {"name":"PROVO","code":"US-UT-PVU"},
          {"name":"RANDLETT","code":"US-UT-T5R"},
          {"name":"REDMOND","code":"US-UT-RMQ"},
          {"name":"RICHFIELD","code":"US-UT-RIF"},
          {"name":"RICHMOND","code":"US-UT-RM4"},
          {"name":"RIVERDALE","code":"US-UT-RD9"},
          {"name":"RIVERTON","code":"US-UT-YIV"},
          {"name":"ROOSEVELT","code":"US-UT-ROL"},
          {"name":"ROWLEY","code":"US-UT-RWY"},
          {"name":"ROY","code":"US-UT-RY8"},
          {"name":"RUSH VALLEY","code":"US-UT-RY4"},
          {"name":"SAINT GEORGE","code":"US-UT-SGU"},
          {"name":"SALEM","code":"US-UT-ZLM"},
          {"name":"SALINA","code":"US-UT-SBO"},
          {"name":"SALT LAKE CITY","code":"US-UT-SLC"},
          {"name":"SANDY","code":"US-UT-SJE"},
          {"name":"SANTA CLARA","code":"US-UT-SKR"},
          {"name":"SANTAQUIN","code":"US-UT-IUQ"},
          {"name":"SARATOGA SPRINGS","code":"US-UT-S6T"},
          {"name":"SCOFIELD","code":"US-UT-XLQ"},
          {"name":"SIGURD","code":"US-UT-SU9"},
          {"name":"SKULL VALLEY","code":"US-UT-KUE"},
          {"name":"SMITHFIELD","code":"US-UT-FTU"},
          {"name":"SNOWBIRD","code":"US-UT-4SN"},
          {"name":"SOUTH JORDAN","code":"US-UT-XSJ"},
          {"name":"SOUTH OGDEN","code":"US-UT-2OG"},
          {"name":"SOUTH SALT LAKE","code":"US-UT-S8K"},
          {"name":"SPANISH FORK","code":"US-UT-SFU"},
          {"name":"SPRING CITY","code":"US-UT-IQI"},
          {"name":"SPRINGDALE","code":"US-UT-3SP"},
          {"name":"SPRINGVILLE","code":"US-UT-SNU"},
          {"name":"STANSBURY PARK","code":"US-UT-SR9"},
          {"name":"SUNDANCE","code":"US-UT-SD5"},
          {"name":"SUNSET","code":"US-UT-SS4"},
          {"name":"SYRACUSE","code":"US-UT-JAU"},
          {"name":"TAYLORSVILLE","code":"US-UT-TYC"},
          {"name":"TOOELE","code":"US-UT-TOO"},
          {"name":"TOQUERVILLE","code":"US-UT-Q5V"},
          {"name":"TORREY","code":"US-UT-TR2"},
          {"name":"TREMONTON","code":"US-UT-TRT"},
          {"name":"TRENTON","code":"US-UT-TR5"},
          {"name":"VERNAL","code":"US-UT-VEL"},
          {"name":"VEYO","code":"US-UT-VYO"},
          {"name":"VINEYARD","code":"US-UT-VED"},
          {"name":"VIRGIN","code":"US-UT-IGU"},
          {"name":"WASHINGTON","code":"US-UT-WGT"},
          {"name":"WASHINGTON TERRACE","code":"US-UT-W9A"},
          {"name":"WELLINGTON","code":"US-UT-WYY"},
          {"name":"WENDOVER","code":"US-UT-ENV"},
          {"name":"WEST BOUNTIFUL","code":"US-UT-W2B"},
          {"name":"WEST HAVEN","code":"US-UT-TVQ"},
          {"name":"WEST JORDAN","code":"US-UT-WEJ"},
          {"name":"WEST VALLEY CITY","code":"US-UT-WVC"},
          {"name":"WILLARD","code":"US-UT-R6T"},
          {"name":"WOODLAND HILLS","code":"US-UT-NIS"},
          {"name":"WOODS CROSS","code":"US-UT-DSS"},
        ]
      },
      {
        name: 'Vermont',
        code: 'US-VT',
        cities: [
          {"name":"ALBANY","code":"US-VT-AYX"},
          {"name":"ALBURG","code":"US-VT-ALR"},
          {"name":"ARLINGTON","code":"US-VT-XBC"},
          {"name":"ASCUTNEY","code":"US-VT-EY9"},
          {"name":"BAKERSFIELD","code":"US-VT-7BK"},
          {"name":"BARNARD","code":"US-VT-VM9"},
          {"name":"BARNET","code":"US-VT-BJR"},
          {"name":"BARRE","code":"US-VT-VBR"},
          {"name":"BARRE-MONTPELIER APT","code":"US-VT-MPV"},
          {"name":"BARTON","code":"US-VT-8TN"},
          {"name":"BEECHER FALLS","code":"US-VT-BEF"},
          {"name":"BELLOWS FALLS","code":"US-VT-BWF"},
          {"name":"BELVIDERE CENTER","code":"US-VT-BE3"},
          {"name":"BENNINGTON","code":"US-VT-BGN"},
          {"name":"BENSON","code":"US-VT-B77"},
          {"name":"BERLIN CORNERS","code":"US-VT-ZEI"},
          {"name":"BETHEL","code":"US-VT-XBV"},
          {"name":"BONDVILLE","code":"US-VT-JNI"},
          {"name":"BRADFORD","code":"US-VT-BD2"},
          {"name":"BRANDON","code":"US-VT-BR5"},
          {"name":"BRATTLEBORO","code":"US-VT-BBR"},
          {"name":"BRIDGEWATER","code":"US-VT-IGW"},
          {"name":"BRIDGEWATER CORNERS","code":"US-VT-IEU"},
          {"name":"BRIDPORT","code":"US-VT-QRP"},
          {"name":"BRISTOL","code":"US-VT-BZL"},
          {"name":"BROOKFIELD","code":"US-VT-8BK"},
          {"name":"BURLINGTON","code":"US-VT-BTV"},
          {"name":"CABOT","code":"US-VT-CB7"},
          {"name":"CALAIS","code":"US-VT-7CI"},
          {"name":"CAMBRIDGE","code":"US-VT-CB6"},
          {"name":"CAMBRIDGEPORT","code":"US-VT-C4B"},
          {"name":"CANAAN","code":"US-VT-A5A"},
          {"name":"CASTLETON","code":"US-VT-CT5"},
          {"name":"CAVENDISH","code":"US-VT-CVX"},
          {"name":"CHARLOTTE","code":"US-VT-JCA"},
          {"name":"CHELSEA","code":"US-VT-6HC"},
          {"name":"CHESTER","code":"US-VT-ACL"},
          {"name":"CHESTER DEPOT","code":"US-VT-TDT"},
          {"name":"CHITTENDEN","code":"US-VT-7TT"},
          {"name":"COLCHESTER","code":"US-VT-CCV"},
          {"name":"CONCORD","code":"US-VT-7CD"},
          {"name":"CORINTH","code":"US-VT-7OR"},
          {"name":"CORNWALL","code":"US-VT-OQW"},
          {"name":"COVENTRY","code":"US-VT-7CY"},
          {"name":"CRAFTSBURY","code":"US-VT-7CF"},
          {"name":"DANBY","code":"US-VT-DNY"},
          {"name":"DANVILLE","code":"US-VT-7DV"},
          {"name":"DERBY","code":"US-VT-JYY"},
          {"name":"DERBY CENTER","code":"US-VT-DBX"},
          {"name":"DERBY LINE","code":"US-VT-DBY"},
          {"name":"DORSET","code":"US-VT-DT3"},
          {"name":"EAST ALBURG","code":"US-VT-EBU"},
          {"name":"EAST BURKE","code":"US-VT-EZK"},
          {"name":"EAST DORSET","code":"US-VT-EJO"},
          {"name":"EAST HAVEN","code":"US-VT-7EV"},
          {"name":"EAST MONTPELIER","code":"US-VT-7EM"},
          {"name":"EAST RANDOLPH","code":"US-VT-ER8"},
          {"name":"EDEN","code":"US-VT-EV6"},
          {"name":"ELMORE","code":"US-VT-E8M"},
          {"name":"ENOSBURG CENTER","code":"US-VT-NUR"},
          {"name":"ENOSBURG FALLS","code":"US-VT-EBF"},
          {"name":"ESSEX","code":"US-VT-8SS"},
          {"name":"ESSEX JUNCTION","code":"US-VT-ESS"},
          {"name":"FAIR HAVEN","code":"US-VT-FAA"},
          {"name":"FAIRFAX","code":"US-VT-AIX"},
          {"name":"FAIRFIELD","code":"US-VT-7FF"},
          {"name":"FAIRLEE","code":"US-VT-JFI"},
          {"name":"FERRISBURGH","code":"US-VT-FBH"},
          {"name":"FLORENCE","code":"US-VT-OEE"},
          {"name":"FOREST DALE","code":"US-VT-FSQ"},
          {"name":"FRANKLIN","code":"US-VT-FK4"},
          {"name":"GEORGIA","code":"US-VT-GGA"},
          {"name":"GILMAN","code":"US-VT-GMH"},
          {"name":"GRAFTON","code":"US-VT-3VT"},
          {"name":"GRANBY","code":"US-VT-7GY"},
          {"name":"GRAND ISLE","code":"US-VT-7GI"},
          {"name":"GRANITEVILLE","code":"US-VT-GQL"},
          {"name":"GRANVILLE","code":"US-VT-GKL"},
          {"name":"GREENSBORO","code":"US-VT-3GR"},
          {"name":"GREENSBORO BEND","code":"US-VT-GXB"},
          {"name":"GROTON","code":"US-VT-N5G"},
          {"name":"GUILDHALL","code":"US-VT-GDJ"},
          {"name":"HANCOCK","code":"US-VT-QHC"},
          {"name":"HARDWICK","code":"US-VT-AJ7"},
          {"name":"HARTFORD","code":"US-VT-8HR"},
          {"name":"HARTLAND","code":"US-VT-HTB"},
          {"name":"HIGHGATE CENTER","code":"US-VT-XGE"},
          {"name":"HIGHGATE SPRINGS","code":"US-VT-HIS"},
          {"name":"HINESBURG","code":"US-VT-HIU"},
          {"name":"HORTONIA","code":"US-VT-H9T"},
          {"name":"HUNTINGTON","code":"US-VT-7HU"},
          {"name":"HYDE PARK","code":"US-VT-YPK"},
          {"name":"IRASBURG","code":"US-VT-IBR"},
          {"name":"ISLAND POND","code":"US-VT-DN6"},
          {"name":"ISLE LA MOTTE","code":"US-VT-L3M"},
          {"name":"JAMAICA","code":"US-VT-5MJ"},
          {"name":"JAY","code":"US-VT-VJY"},
          {"name":"JEFFERSONVILLE","code":"US-VT-JRL"},
          {"name":"JERICHO","code":"US-VT-JRH"},
          {"name":"JOHNSON","code":"US-VT-9HJ"},
          {"name":"JONESVILLE","code":"US-VT-5VJ"},
          {"name":"KILLINGTON","code":"US-VT-IIG"},
          {"name":"KILLINGTON VILLAGE","code":"US-VT-KLT"},
          {"name":"LANDGROVE","code":"US-VT-JLJ"},
          {"name":"LEBANON-WHITE RIVER-HANOVER APT","code":"US-VT-LEB"},
          {"name":"LONDONDERRY","code":"US-VT-LD8"},
          {"name":"LOWELL","code":"US-VT-6WL"},
          {"name":"LOWER WATERFORD","code":"US-VT-LW8"},
          {"name":"LUDLOW","code":"US-VT-UDW"},
          {"name":"LUNENBURG","code":"US-VT-UEB"},
          {"name":"LYNDON","code":"US-VT-7LY"},
          {"name":"LYNDONVILLE","code":"US-VT-LLX"},
          {"name":"MANCHESTER","code":"US-VT-YMX"},
          {"name":"MANCHESTER CENTER","code":"US-VT-MNJ"},
          {"name":"MARLBORO","code":"US-VT-7MA"},
          {"name":"MARSHFIELD","code":"US-VT-8FL"},
          {"name":"MIDDLEBURY","code":"US-VT-MBU"},
          {"name":"MIDDLETOWN SPRINGS","code":"US-VT-7MS"},
          {"name":"MILTON","code":"US-VT-MT5"},
          {"name":"MONKTON","code":"US-VT-7MK"},
          {"name":"MONTGOMERY","code":"US-VT-7MG"},
          {"name":"MONTGOMERY CENTER","code":"US-VT-GZY"},
          {"name":"MONTPELIER","code":"US-VT-MPI"},
          {"name":"MONTPELIER-BARRE APT","code":"US-VT-MPV"},
          {"name":"MORETOWN","code":"US-VT-M9V"},
          {"name":"MORGAN","code":"US-VT-7MR"},
          {"name":"MORRISVILLE","code":"US-VT-ORS"},
          {"name":"MOUNT HOLLY","code":"US-VT-7MH"},
          {"name":"NEW HAVEN","code":"US-VT-7NH"},
          {"name":"NEWBURY","code":"US-VT-7NW"},
          {"name":"NEWFANE","code":"US-VT-7NE"},
          {"name":"NEWPORT","code":"US-VT-EFK"},
          {"name":"NORTH BENNINGTON","code":"US-VT-NTB"},
          {"name":"NORTH CLARENDON","code":"US-VT-NCR"},
          {"name":"NORTH FERRISBURGH","code":"US-VT-IFU"},
          {"name":"NORTH HARTLAND","code":"US-VT-NHQ"},
          {"name":"NORTH HERO","code":"US-VT-6TH"},
          {"name":"NORTH SPRINGFIELD","code":"US-VT-NSP"},
          {"name":"NORTH TROY","code":"US-VT-NTY"},
          {"name":"NORTHFIELD","code":"US-VT-NTF"},
          {"name":"NORTON","code":"US-VT-NRT"},
          {"name":"NORWICH","code":"US-VT-NQW"},
          {"name":"ORANGE","code":"US-VT-OGX"},
          {"name":"ORLEANS","code":"US-VT-ORJ"},
          {"name":"ORWELL","code":"US-VT-ORW"},
          {"name":"PAWLET","code":"US-VT-PJW"},
          {"name":"PEACHAM","code":"US-VT-7PC"},
          {"name":"PERKINSVILLE","code":"US-VT-P9K"},
          {"name":"PERU","code":"US-VT-7PE"},
          {"name":"PITTSFIELD","code":"US-VT-PF3"},
          {"name":"PITTSFORD","code":"US-VT-XPD"},
          {"name":"PLAINFIELD","code":"US-VT-PF4"},
          {"name":"PLYMOUTH","code":"US-VT-GYU"},
          {"name":"POST MILLS","code":"US-VT-PM7"},
          {"name":"POULTNEY","code":"US-VT-YPU"},
          {"name":"POWNAL","code":"US-VT-YPW"},
          {"name":"PROCTOR","code":"US-VT-PDR"},
          {"name":"PROCTORSVILLE","code":"US-VT-PV6"},
          {"name":"PUTNEY","code":"US-VT-PUT"},
          {"name":"QUECHEE","code":"US-VT-QEH"},
          {"name":"RANDOLPH","code":"US-VT-RPZ"},
          {"name":"RANDOLPH CENTER","code":"US-VT-TXH"},
          {"name":"READING","code":"US-VT-7RD"},
          {"name":"READSBORO","code":"US-VT-7RB"},
          {"name":"RICHFORD","code":"US-VT-RFR"},
          {"name":"RICHMOND","code":"US-VT-RMH"},
          {"name":"RIPTON","code":"US-VT-7RI"},
          {"name":"ROCHESTER","code":"US-VT-9RH"},
          {"name":"ROCKINGHAM","code":"US-VT-KGH"},
          {"name":"ROXBURY","code":"US-VT-7RX"},
          {"name":"ROYALTON","code":"US-VT-9RT"},
          {"name":"RUPERT","code":"US-VT-7RU"},
          {"name":"RUTLAND","code":"US-VT-RUT"},
          {"name":"RYEGATE","code":"US-VT-G5T"},
          {"name":"S.BURLINGTON","code":"US-VT-ZBR"},
          {"name":"SAINT ALBANS","code":"US-VT-STA"},
          {"name":"SAINT ALBANS BAY","code":"US-VT-IVQ"},
          {"name":"SAINT JOHNSBURY","code":"US-VT-JBY"},
          {"name":"SAINT JOHNSBURY CENTER","code":"US-VT-C33"},
          {"name":"SALISBURY","code":"US-VT-8VT"},
          {"name":"SEARSBURG","code":"US-VT-JUR"},
          {"name":"SHAFTSBURY","code":"US-VT-S2H"},
          {"name":"SHARON","code":"US-VT-SN3"},
          {"name":"SHEFFIELD","code":"US-VT-7SE"},
          {"name":"SHELBURNE","code":"US-VT-SB8"},
          {"name":"SHELDON","code":"US-VT-7SH"},
          {"name":"SHELDON SPRINGS","code":"US-VT-SQK"},
          {"name":"SHOREHAM","code":"US-VT-SRH"},
          {"name":"SOUTH BURLINGTON","code":"US-VT-QSB"},
          {"name":"SOUTH HERO","code":"US-VT-7HE"},
          {"name":"SOUTH LONDONDERRY","code":"US-VT-TDY"},
          {"name":"SOUTH ROYALTON","code":"US-VT-YLN"},
          {"name":"SPRINGFIELD","code":"US-VT-VSF"},
          {"name":"STAIN GEORGE","code":"US-VT-V3R"},
          {"name":"STAMFORD","code":"US-VT-7SF"},
          {"name":"STARKSBORO","code":"US-VT-7SB"},
          {"name":"STOCKBRIDGE","code":"US-VT-KGB"},
          {"name":"STOWE","code":"US-VT-MVL"},
          {"name":"STRAFFORD","code":"US-VT-S7D"},
          {"name":"STRATTON MOUNTAIN","code":"US-VT-TIU"},
          {"name":"SUNDERLAND","code":"US-VT-V87"},
          {"name":"SUTTON","code":"US-VT-VMY"},
          {"name":"SWANTON","code":"US-VT-SWA"},
          {"name":"TAFTSVILLE","code":"US-VT-TFV"},
          {"name":"THETFORD","code":"US-VT-UUD"},
          {"name":"TOPSHAM","code":"US-VT-VOH"},
          {"name":"TOWNSHEND","code":"US-VT-TSD"},
          {"name":"TROY","code":"US-VT-TCY"},
          {"name":"TUNBRIDGE","code":"US-VT-7TU"},
          {"name":"UNDERHILL","code":"US-VT-UH2"},
          {"name":"VERGENNES","code":"US-VT-VZG"},
          {"name":"VERNON","code":"US-VT-7VN"},
          {"name":"VERSHIRE","code":"US-VT-VHR"},
          {"name":"WAITSFIELD","code":"US-VT-WAI"},
          {"name":"WALLINGFORD","code":"US-VT-WGF"},
          {"name":"WARDSBORO","code":"US-VT-7WA"},
          {"name":"WARREN","code":"US-VT-VRZ"},
          {"name":"WASHINGTON","code":"US-VT-H6T"},
          {"name":"WATERBURY","code":"US-VT-WBV"},
          {"name":"WATERFORD","code":"US-VT-WF8"},
          {"name":"WEBSTERVILLE","code":"US-VT-WV5"},
          {"name":"WELLS RIVER","code":"US-VT-WR6"},
          {"name":"WEST BURKE","code":"US-VT-URK"},
          {"name":"WEST DOVER","code":"US-VT-WD2"},
          {"name":"WEST FAIRLEE","code":"US-VT-7WF"},
          {"name":"WEST HALIFAX","code":"US-VT-EFX"},
          {"name":"WEST HAVEN","code":"US-VT-WE6"},
          {"name":"WEST NEWBURY","code":"US-VT-EJU"},
          {"name":"WEST RUTLAND","code":"US-VT-XTR"},
          {"name":"WEST WINDSOR","code":"US-VT-ESI"},
          {"name":"WESTFIELD","code":"US-VT-W7E"},
          {"name":"WESTFORD","code":"US-VT-FHW"},
          {"name":"WESTMINSTER","code":"US-VT-WCM"},
          {"name":"WESTON","code":"US-VT-7WN"},
          {"name":"WEYBRIDGE","code":"US-VT-U3V"},
          {"name":"WHITE RIVER JUNCTION","code":"US-VT-WVT"},
          {"name":"WHITE RIVER-HANOVER-LEBANON APT","code":"US-VT-LEB"},
          {"name":"WHITING","code":"US-VT-5WH"},
          {"name":"WHITINGHAM","code":"US-VT-7WG"},
          {"name":"WILDER","code":"US-VT-VTD"},
          {"name":"WILLIAMSTOWN","code":"US-VT-XWW"},
          {"name":"WILLISTON","code":"US-VT-WIL"},
          {"name":"WILMINGTON","code":"US-VT-WMT"},
          {"name":"WINDSOR","code":"US-VT-WIV"},
          {"name":"WINOOSKI","code":"US-VT-WNK"},
          {"name":"WOLCOTT","code":"US-VT-8WT"},
          {"name":"WOODBURY","code":"US-VT-7WY"},
          {"name":"WOODSTOCK","code":"US-VT-WK6"},
          {"name":"WORCESTER","code":"US-VT-W7C"},
        ]
      },
      {
        name: 'Virginia',
        code: 'US-VA',
        cities: [
          {"name":"ABINGDON","code":"US-VA-VJI"},
          {"name":"ACCOMAC","code":"US-VA-AQC"},
          {"name":"AFTON","code":"US-VA-AFQ"},
          {"name":"ALDIE","code":"US-VA-AL7"},
          {"name":"ALEXANDRIA","code":"US-VA-AXR"},
          {"name":"ALTAVISTA","code":"US-VA-ATV"},
          {"name":"ALTON","code":"US-VA-ONB"},
          {"name":"AMELIA","code":"US-VA-AML"},
          {"name":"AMHERST","code":"US-VA-AHR"},
          {"name":"AMISSVILLE","code":"US-VA-A78"},
          {"name":"AMPTHILL","code":"US-VA-XBD"},
          {"name":"ANNANDALE","code":"US-VA-AJA"},
          {"name":"APPALACHIA","code":"US-VA-XAV"},
          {"name":"APPOMATTOX","code":"US-VA-XAX"},
          {"name":"ARARAT","code":"US-VA-A2A"},
          {"name":"ARLINGTON","code":"US-VA-ARP"},
          {"name":"ARODA","code":"US-VA-4AR"},
          {"name":"ARRINGTON","code":"US-VA-YZA"},
          {"name":"ARVONIA","code":"US-VA-VZN"},
          {"name":"ASHBURN","code":"US-VA-QAS"},
          {"name":"ASHLAND","code":"US-VA-ALD"},
          {"name":"ATKINS","code":"US-VA-AIS"},
          {"name":"ATLANTIC","code":"US-VA-NIC"},
          {"name":"AUGUSTA SPRINGS","code":"US-VA-VAU"},
          {"name":"AUSTINVILLE","code":"US-VA-JLV"},
          {"name":"AXTON","code":"US-VA-AXO"},
          {"name":"AYLETT","code":"US-VA-AYT"},
          {"name":"BACOVA","code":"US-VA-BQV"},
          {"name":"BARBOURSVILLE","code":"US-VA-RUX"},
          {"name":"BASSETT","code":"US-VA-BSS"},
          {"name":"BASTIAN","code":"US-VA-BCP"},
          {"name":"BASYE","code":"US-VA-8AS"},
          {"name":"BEALETON","code":"US-VA-VAA"},
          {"name":"BEAVERDAM","code":"US-VA-BR2"},
          {"name":"BEDFORD","code":"US-VA-XBY"},
          {"name":"BENA","code":"US-VA-BEN"},
          {"name":"BENT MOUNTAIN","code":"US-VA-B37"},
          {"name":"BERRYVILLE","code":"US-VA-BYV"},
          {"name":"BIG ISLAND","code":"US-VA-XCD"},
          {"name":"BIG ROCK","code":"US-VA-BR6"},
          {"name":"BIG STONE GAP","code":"US-VA-IHG"},
          {"name":"BLACKSBURG","code":"US-VA-BCB"},
          {"name":"BLACKSTONE","code":"US-VA-BKT"},
          {"name":"BLAIRS","code":"US-VA-BSV"},
          {"name":"BLAIRS","code":"US-VA-BXZ"},
          {"name":"BLAND","code":"US-VA-BDC"},
          {"name":"BLOXOM","code":"US-VA-B5X"},
          {"name":"BLUE RIDGE","code":"US-VA-UDG"},
          {"name":"BLUEFIELD","code":"US-VA-VBS"},
          {"name":"BOLINGTON","code":"US-VA-ZXT"},
          {"name":"BOONES MILL","code":"US-VA-8MV"},
          {"name":"BOSTON","code":"US-VA-BSN"},
          {"name":"BOTETOURT","code":"US-VA-OTU"},
          {"name":"BOWLING GREEN","code":"US-VA-APH"},
          {"name":"BOYCE","code":"US-VA-ZBY"},
          {"name":"BOYDTON","code":"US-VA-ZYD"},
          {"name":"BOYKINS","code":"US-VA-YKS"},
          {"name":"BRACEY","code":"US-VA-ECY"},
          {"name":"BRANDY STATION","code":"US-VA-BFH"},
          {"name":"BREAKS","code":"US-VA-BQZ"},
          {"name":"BRIDGEWATER","code":"US-VA-BHR"},
          {"name":"BRISTOL","code":"US-VA-BFS"},
          {"name":"BRISTOW","code":"US-VA-VBW"},
          {"name":"BROAD RUN","code":"US-VA-FU8"},
          {"name":"BROADLANDS","code":"US-VA-7BR"},
          {"name":"BROADWAY","code":"US-VA-BDW"},
          {"name":"BRODNAX","code":"US-VA-BXQ"},
          {"name":"BROOKNEAL","code":"US-VA-BN4"},
          {"name":"BUCHANAN","code":"US-VA-BJH"},
          {"name":"BUENA VISTA","code":"US-VA-BVS"},
          {"name":"BUFFALO JUNCTION","code":"US-VA-UJC"},
          {"name":"BUMPASS","code":"US-VA-BZP"},
          {"name":"BURKE","code":"US-VA-UKE"},
          {"name":"BURKEVILLE","code":"US-VA-UKV"},
          {"name":"CALLANDS","code":"US-VA-C23"},
          {"name":"CALLAWAY","code":"US-VA-9AY"},
          {"name":"CALVERTON","code":"US-VA-AEO"},
          {"name":"CANA","code":"US-VA-NZJ"},
          {"name":"CAPE CHARLES","code":"US-VA-CCJ"},
          {"name":"CAPE HENRY","code":"US-VA-XPY"},
          {"name":"CARROLLTON","code":"US-VA-QLN"},
          {"name":"CASTLEWOOD","code":"US-VA-YCW"},
          {"name":"CATLETT","code":"US-VA-KVT"},
          {"name":"CEDAR BLUFF","code":"US-VA-DFF"},
          {"name":"CENTER CROSS","code":"US-VA-DCC"},
          {"name":"CENTREVILLE","code":"US-VA-EVH"},
          {"name":"CHAMPLAIN","code":"US-VA-AP9"},
          {"name":"CHANTILLY","code":"US-VA-CYL"},
          {"name":"CHARLES CITY","code":"US-VA-ZZY"},
          {"name":"CHARLOTTESVILLE","code":"US-VA-CHO"},
          {"name":"CHASE CITY","code":"US-VA-HSY"},
          {"name":"CHATHAM","code":"US-VA-CWM"},
          {"name":"CHERITON","code":"US-VA-CKH"},
          {"name":"CHESAPEAKE","code":"US-VA-UFG"},
          {"name":"CHESTER","code":"US-VA-CSZ"},
          {"name":"CHESTERFIELD","code":"US-VA-XDR"},
          {"name":"CHILHOWIE","code":"US-VA-CWE"},
          {"name":"CHINCOTEAGUE","code":"US-VA-WAL"},
          {"name":"CHRISTIANSBURG","code":"US-VA-CBG"},
          {"name":"CHUCKATUCK","code":"US-VA-CCK"},
          {"name":"CHURCHVILLE","code":"US-VA-CCH"},
          {"name":"CLAREMONT","code":"US-VA-CO3"},
          {"name":"CLARKSVILLE","code":"US-VA-CVY"},
          {"name":"CLAUDVILLE","code":"US-VA-C4D"},
          {"name":"CLEAR BROOK","code":"US-VA-CB2"},
          {"name":"CLEVELAND","code":"US-VA-QVD"},
          {"name":"CLIFTON","code":"US-VA-CF4"},
          {"name":"CLIFTON FORGE","code":"US-VA-FTF"},
          {"name":"CLINTWOOD","code":"US-VA-IOU"},
          {"name":"CLOVER, HALIFAX","code":"US-VA-OVX"},
          {"name":"CLOVERDALE","code":"US-VA-CVV"},
          {"name":"COBBS CREEK","code":"US-VA-CK2"},
          {"name":"COEBURN","code":"US-VA-C7E"},
          {"name":"COLLINSVILLE","code":"US-VA-OSI"},
          {"name":"COLONIAL HEIGHTS","code":"US-VA-CNJ"},
          {"name":"COLUMBIA","code":"US-VA-CB5"},
          {"name":"CONCORD","code":"US-VA-FGF"},
          {"name":"COURTLAND","code":"US-VA-VXQ"},
          {"name":"COVESVILLE","code":"US-VA-CJV"},
          {"name":"COVINGTON","code":"US-VA-CVJ"},
          {"name":"CREWE","code":"US-VA-VGW"},
          {"name":"CRIMORA","code":"US-VA-C74"},
          {"name":"CROSS JUNCTION","code":"US-VA-CXG"},
          {"name":"CROZET","code":"US-VA-CZR"},
          {"name":"CRYSTAL HILL","code":"US-VA-9CH"},
          {"name":"CULPEPER","code":"US-VA-AAR"},
          {"name":"CUMBERLAND","code":"US-VA-5CV"},
          {"name":"DAHLGREN","code":"US-VA-DGN"},
          {"name":"DALE CITY","code":"US-VA-DC2"},
          {"name":"DALEVILLE","code":"US-VA-DV4"},
          {"name":"DAMASCUS","code":"US-VA-DMU"},
          {"name":"DANVILLE","code":"US-VA-DAN"},
          {"name":"DAVENPORT","code":"US-VA-9VP"},
          {"name":"DAYTON","code":"US-VA-DYV"},
          {"name":"DELAPLANE","code":"US-VA-DPX"},
          {"name":"DENDRON","code":"US-VA-FT3"},
          {"name":"DEWITT","code":"US-VA-VW2"},
          {"name":"DIGGS","code":"US-VA-D3G"},
          {"name":"DILLWYN","code":"US-VA-UFP"},
          {"name":"DINWIDDIE","code":"US-VA-D3D"},
          {"name":"DISPUTANTA","code":"US-VA-DPV"},
          {"name":"DOSWELL","code":"US-VA-DOS"},
          {"name":"DRAPER","code":"US-VA-D2P"},
          {"name":"DRY FORK, PITTSYLVANIA","code":"US-VA-DFK"},
          {"name":"DUBLIN","code":"US-VA-PSK"},
          {"name":"DUFFIELD","code":"US-VA-DUD"},
          {"name":"DUGSPUR","code":"US-VA-DG2"},
          {"name":"DULLES","code":"US-VA-LLH"},
          {"name":"DULLES INT APT/WASHINGTON","code":"US-VA-IAD"},
          {"name":"DUMFRIES","code":"US-VA-DFS"},
          {"name":"EAGLE ROCK, BOTETOURT","code":"US-VA-ER3"},
          {"name":"EARLYSVILLE","code":"US-VA-3VA"},
          {"name":"EAST DANVILLE","code":"US-VA-EZV"},
          {"name":"EDINBURG","code":"US-VA-EQX"},
          {"name":"ELKTON","code":"US-VA-EKV"},
          {"name":"ELKWOOD","code":"US-VA-EKW"},
          {"name":"ELLISTON","code":"US-VA-EIT"},
          {"name":"EMPORIA","code":"US-VA-ERA"},
          {"name":"ESMONT","code":"US-VA-E8T"},
          {"name":"EVINGTON","code":"US-VA-E5V"},
          {"name":"EWING","code":"US-VA-E2W"},
          {"name":"EXMORE","code":"US-VA-EXA"},
          {"name":"FABER","code":"US-VA-FB4"},
          {"name":"FAIRFAX","code":"US-VA-FXX"},
          {"name":"FAIRFAX STATION","code":"US-VA-FXS"},
          {"name":"FAIRFIELD","code":"US-VA-FFV"},
          {"name":"FALLS CHURCH","code":"US-VA-FAC"},
          {"name":"FALLS MILLS","code":"US-VA-XFM"},
          {"name":"FALMOUTH","code":"US-VA-F2M"},
          {"name":"FARMVILLE","code":"US-VA-FMV"},
          {"name":"FARNHAM","code":"US-VA-8MF"},
          {"name":"FENTRESS","code":"US-VA-FTR"},
          {"name":"FIELDALE","code":"US-VA-FIE"},
          {"name":"FINCASTLE","code":"US-VA-FJC"},
          {"name":"FISHERSVILLE","code":"US-VA-FHV"},
          {"name":"FLINT HILL","code":"US-VA-FH5"},
          {"name":"FLOYD","code":"US-VA-VAF"},
          {"name":"FOREST","code":"US-VA-FS4"},
          {"name":"FORK UNION","code":"US-VA-FKU"},
          {"name":"FORT BELVOIR","code":"US-VA-DAA"},
          {"name":"FORT CHISWELL","code":"US-VA-7CH"},
          {"name":"FORT EUSTIS","code":"US-VA-FAF"},
          {"name":"FORT LEE","code":"US-VA-V7A"},
          {"name":"FRANCONIA","code":"US-VA-ZFO"},
          {"name":"FRANKLIN","code":"US-VA-FKN"},
          {"name":"FREDERICKSBURG","code":"US-VA-FDB"},
          {"name":"FREE UNION","code":"US-VA-FU5"},
          {"name":"FREEMAN","code":"US-VA-FR9"},
          {"name":"FRONT ROYAL","code":"US-VA-FRR"},
          {"name":"FULKS RUN","code":"US-VA-V8A"},
          {"name":"GAINESVILLE","code":"US-VA-GII"},
          {"name":"GALAX","code":"US-VA-GLX"},
          {"name":"GATE CITY","code":"US-VA-GQX"},
          {"name":"GLADE SPRING","code":"US-VA-GSR"},
          {"name":"GLADSTONE","code":"US-VA-G88"},
          {"name":"GLADYS","code":"US-VA-GL6"},
          {"name":"GLASGOW","code":"US-VA-QZG"},
          {"name":"GLEN ALLEN","code":"US-VA-GEU"},
          {"name":"GLOUCESTER","code":"US-VA-G2C"},
          {"name":"GOOCHLAND","code":"US-VA-VZZ"},
          {"name":"GOODE","code":"US-VA-G8V"},
          {"name":"GORDONSVILLE","code":"US-VA-GVE"},
          {"name":"GORE","code":"US-VA-GVA"},
          {"name":"GOSHEN","code":"US-VA-GOG"},
          {"name":"GRAFTON","code":"US-VA-GXF"},
          {"name":"GREAT FALLS","code":"US-VA-ZGS"},
          {"name":"GREEN BAY","code":"US-VA-G55"},
          {"name":"GREENWICH","code":"US-VA-GNA"},
          {"name":"GRETNA","code":"US-VA-GZR"},
          {"name":"GROTTOES","code":"US-VA-GOW"},
          {"name":"GRUNDY","code":"US-VA-GDU"},
          {"name":"GUM SPRING","code":"US-VA-GS4"},
          {"name":"HADENSVILLE","code":"US-VA-VHS"},
          {"name":"HALIFAX","code":"US-VA-HIX"},
          {"name":"HALLWOOD","code":"US-VA-3HW"},
          {"name":"HAMILTON","code":"US-VA-HT2"},
          {"name":"HAMPTON","code":"US-VA-HNV"},
          {"name":"HAMPTON ROADS PT","code":"US-VA-HR3"},
          {"name":"HAMPTON-WILLIAMSBURG-NEWPORT NEWS APT","code":"US-VA-PHF"},
          {"name":"HANOVER","code":"US-VA-HVZ"},
          {"name":"HARPERSVILLE","code":"US-VA-HR9"},
          {"name":"HARRISBURG","code":"US-VA-Z2D"},
          {"name":"HARRISONBURG","code":"US-VA-HBV"},
          {"name":"HAVELOCK","code":"US-VA-HV9"},
          {"name":"HAYES","code":"US-VA-HYX"},
          {"name":"HAYMARKET","code":"US-VA-HY8"},
          {"name":"HEATHSVILLE","code":"US-VA-HHV"},
          {"name":"HENRICO","code":"US-VA-IUO"},
          {"name":"HENRY","code":"US-VA-HR8"},
          {"name":"HERNDON","code":"US-VA-HRV"},
          {"name":"HILLSBORO","code":"US-VA-6LB"},
          {"name":"HILLSVILLE","code":"US-VA-HIV"},
          {"name":"HINTON","code":"US-VA-HTN"},
          {"name":"HIWASSEE","code":"US-VA-HWS"},
          {"name":"HOPEWELL","code":"US-VA-HPW"},
          {"name":"HOT SPRINGS","code":"US-VA-HSP"},
          {"name":"HUME","code":"US-VA-8HU"},
          {"name":"HUNTINGTON","code":"US-VA-HTZ"},
          {"name":"HUNTLY","code":"US-VA-UTY"},
          {"name":"HURT","code":"US-VA-HUJ"},
          {"name":"INDEPENDENCE","code":"US-VA-IDC"},
          {"name":"INGLESIDE","code":"US-VA-9GN"},
          {"name":"IRVINGTON","code":"US-VA-IR7"},
          {"name":"IVY","code":"US-VA-IFY"},
          {"name":"JAMAICA","code":"US-VA-JA2"},
          {"name":"JAMESVILLE","code":"US-VA-JMV"},
          {"name":"JEFFERSONTON","code":"US-VA-J2V"},
          {"name":"JETERSVILLE","code":"US-VA-JTV"},
          {"name":"KELLER","code":"US-VA-KLR"},
          {"name":"KENBRIDGE","code":"US-VA-KEG"},
          {"name":"KEOKEE","code":"US-VA-KKQ"},
          {"name":"KESWICK","code":"US-VA-DKW"},
          {"name":"KEYSVILLE","code":"US-VA-KEV"},
          {"name":"KILMANROCK","code":"US-VA-KR2"},
          {"name":"KILMARNOCK","code":"US-VA-KK4"},
          {"name":"KING GEORGE","code":"US-VA-KGG"},
          {"name":"KING WILLIAM","code":"US-VA-KWM"},
          {"name":"LA CROSSE","code":"US-VA-XSO"},
          {"name":"LADYSMITH","code":"US-VA-ULH"},
          {"name":"LANEVIEW","code":"US-VA-QNW"},
          {"name":"LANGLEY","code":"US-VA-YGZ"},
          {"name":"LAWRENCEVILLE","code":"US-VA-LVL"},
          {"name":"LAWYERS","code":"US-VA-LW2"},
          {"name":"LEBANON","code":"US-VA-L9B"},
          {"name":"LEESBURG","code":"US-VA-LXU"},
          {"name":"LEXINGTON","code":"US-VA-LXH"},
          {"name":"LIGHTFOOT","code":"US-VA-XHM"},
          {"name":"LIGNUM","code":"US-VA-5LM"},
          {"name":"LINVILLE","code":"US-VA-LZE"},
          {"name":"LIVELY","code":"US-VA-JLI"},
          {"name":"LOCUST HILL, MIDDLESEX","code":"US-VA-LHY"},
          {"name":"LOCUSTVILLE","code":"US-VA-LTV"},
          {"name":"LONG ISLAND","code":"US-VA-LI4"},
          {"name":"LORTON","code":"US-VA-LPV"},
          {"name":"LOUISA","code":"US-VA-LOW"},
          {"name":"LOVETTSVILLE","code":"US-VA-VTT"},
          {"name":"LOVINGSTON","code":"US-VA-LV6"},
          {"name":"LOW MOOR","code":"US-VA-TOZ"},
          {"name":"LOWMOOR","code":"US-VA-ZG5"},
          {"name":"LURAY","code":"US-VA-LRA"},
          {"name":"LYNCHBURG","code":"US-VA-LYH"},
          {"name":"LYNDHURST","code":"US-VA-YHU"},
          {"name":"LYNNHAVEN ROADS","code":"US-VA-YVO"},
          {"name":"MADISON","code":"US-VA-MS4"},
          {"name":"MADISON HEIGHTS","code":"US-VA-MH5"},
          {"name":"MAIDENS","code":"US-VA-MS3"},
          {"name":"MANAKIN","code":"US-VA-M2K"},
          {"name":"MANAKIN-SABOT","code":"US-VA-9MT"},
          {"name":"MANASSAS","code":"US-VA-MNZ"},
          {"name":"MANASSAS PARK","code":"US-VA-KMK"},
          {"name":"MANQUIN","code":"US-VA-MQ3"},
          {"name":"MAPPSVILLE","code":"US-VA-MEK"},
          {"name":"MARION","code":"US-VA-XJD"},
          {"name":"MARKHAM","code":"US-VA-MK5"},
          {"name":"MARSHALL","code":"US-VA-MQ9"},
          {"name":"MARTINSVILLE","code":"US-VA-MTV"},
          {"name":"MATTAPONI","code":"US-VA-KMJ"},
          {"name":"MAX MEADOWS","code":"US-VA-MXM"},
          {"name":"MCGAHEYSVILLE","code":"US-VA-MZ3"},
          {"name":"MCKENNEY","code":"US-VA-MKN"},
          {"name":"MCLEAN/WASHINGTON, D.C.","code":"US-VA-XQM"},
          {"name":"MEADOWS OF DAN","code":"US-VA-JJQ"},
          {"name":"MECHANICSVILLE","code":"US-VA-MEC"},
          {"name":"MELFA","code":"US-VA-MFV"},
          {"name":"MENDOTA","code":"US-VA-DO7"},
          {"name":"MERRIFIELD","code":"US-VA-VM4"},
          {"name":"MIDDLEBURG","code":"US-VA-MG9"},
          {"name":"MIDDLETOWN","code":"US-VA-MWV"},
          {"name":"MIDLAND","code":"US-VA-YML"},
          {"name":"MIDLOTHIAN","code":"US-VA-MED"},
          {"name":"MILFORD","code":"US-VA-QAO"},
          {"name":"MILLWOOD","code":"US-VA-ZEZ"},
          {"name":"MINERAL","code":"US-VA-IEA"},
          {"name":"MONETA","code":"US-VA-MQG"},
          {"name":"MONROE","code":"US-VA-NRX"},
          {"name":"MONTEREY","code":"US-VA-YTX"},
          {"name":"MONTPELIER","code":"US-VA-XIX"},
          {"name":"MONTROSS","code":"US-VA-KMX"},
          {"name":"MONTVALE","code":"US-VA-M8V"},
          {"name":"MORATTICO","code":"US-VA-MJS"},
          {"name":"MOUNT CRAWFORD","code":"US-VA-MWR"},
          {"name":"MOUNT JACKSON","code":"US-VA-UQV"},
          {"name":"MOUTH OF WILSON","code":"US-VA-MO4"},
          {"name":"NARROWS","code":"US-VA-NAB"},
          {"name":"NASSAWADOX","code":"US-VA-N8W"},
          {"name":"NATURAL BRIDGE","code":"US-VA-NLH"},
          {"name":"NATURAL BRIDGE STATION","code":"US-VA-HPC"},
          {"name":"NELLYSFORD","code":"US-VA-N4L"},
          {"name":"NELSONIA","code":"US-VA-NOQ"},
          {"name":"NEW BALTIMORE","code":"US-VA-NB8"},
          {"name":"NEW CASTLE","code":"US-VA-NW9"},
          {"name":"NEW CHURCH","code":"US-VA-NUH"},
          {"name":"NEW POINT","code":"US-VA-QZN"},
          {"name":"NEWBERN","code":"US-VA-V4B"},
          {"name":"NEWINGTON","code":"US-VA-NQG"},
          {"name":"NEWMARKET","code":"US-VA-W4O"},
          {"name":"NEWPORT","code":"US-VA-VAP"},
          {"name":"NEWPORT NEWS","code":"US-VA-NNS"},
          {"name":"NEWPORT NEWS-HAMPTON-WILLIAMSBURG APT","code":"US-VA-PHF"},
          {"name":"NICKELSVILLE","code":"US-VA-NV3"},
          {"name":"NOKESVILLE","code":"US-VA-QNV"},
          {"name":"NORCROSS","code":"US-VA-NCX"},
          {"name":"NORFOLK","code":"US-VA-ORF"},
          {"name":"NORTH TAZEWELL","code":"US-VA-NTZ"},
          {"name":"NORTON","code":"US-VA-NOG"},
          {"name":"OAK GROVE","code":"US-VA-AKJ"},
          {"name":"OAK HILL, FAIRFAX","code":"US-VA-OAV"},
          {"name":"OAK HILL, PITTSYLVANIA","code":"US-VA-OHL"},
          {"name":"OAKTON","code":"US-VA-OKU"},
          {"name":"OAKWOOD, BUCHANAN","code":"US-VA-OAW"},
          {"name":"OCEANA","code":"US-VA-NTU"},
          {"name":"OILVILLE","code":"US-VA-OVB"},
          {"name":"ORANGE","code":"US-VA-OGE"},
          {"name":"ORDINARY","code":"US-VA-ODY"},
          {"name":"PAINTER","code":"US-VA-PE9"},
          {"name":"PALMYRA","code":"US-VA-PA7"},
          {"name":"PAMPLIN","code":"US-VA-QPM"},
          {"name":"PARKER","code":"US-VA-PK9"},
          {"name":"PARKSLEY","code":"US-VA-PKY"},
          {"name":"PATRICK SPRINGS","code":"US-VA-JKR"},
          {"name":"PEARISBURG","code":"US-VA-EUX"},
          {"name":"PENNINGTON GAP","code":"US-VA-XKK"},
          {"name":"PETERSBURG","code":"US-VA-PTB"},
          {"name":"PINEY RIVER","code":"US-VA-PI5"},
          {"name":"POQUOSON","code":"US-VA-QOS"},
          {"name":"PORT REPUBLIC","code":"US-VA-YPR"},
          {"name":"PORTSMOUTH","code":"US-VA-PTM"},
          {"name":"POTOMAC FALLS","code":"US-VA-VPM"},
          {"name":"POUND","code":"US-VA-PN4"},
          {"name":"POUNDING MILL","code":"US-VA-PDM"},
          {"name":"POWHATAN","code":"US-VA-ZPW"},
          {"name":"PRINCE GEORGE","code":"US-VA-JPG"},
          {"name":"PROSPECT","code":"US-VA-PP9"},
          {"name":"PROVIDENCE FORGE","code":"US-VA-PFG"},
          {"name":"PULASKI","code":"US-VA-UYB"},
          {"name":"PURCELLVILLE","code":"US-VA-PUI"},
          {"name":"QUANTICO","code":"US-VA-NYG"},
          {"name":"QUICKSBURG","code":"US-VA-QKS"},
          {"name":"QUINTON","code":"US-VA-Q5T"},
          {"name":"RADFORD","code":"US-VA-XLD"},
          {"name":"RAPIDAN","code":"US-VA-YRP"},
          {"name":"RAVEN","code":"US-VA-RN8"},
          {"name":"RAWLINGS","code":"US-VA-8UP"},
          {"name":"RED OAK","code":"US-VA-RKM"},
          {"name":"REEDVILLE","code":"US-VA-REV"},
          {"name":"REMINGTON","code":"US-VA-XLJ"},
          {"name":"RESCUE","code":"US-VA-RES"},
          {"name":"RESTON","code":"US-VA-RTV"},
          {"name":"RICE","code":"US-VA-2V2"},
          {"name":"RICH CREEK","code":"US-VA-RHK"},
          {"name":"RICHLANDS","code":"US-VA-RLV"},
          {"name":"RICHMOND","code":"US-VA-RIC"},
          {"name":"RIDGEWAY, HENRY","code":"US-VA-RJV"},
          {"name":"RINER","code":"US-VA-R2R"},
          {"name":"RINGGOLD","code":"US-VA-RXV"},
          {"name":"RIPPLEMEAD","code":"US-VA-RP4"},
          {"name":"RIVERVILLE","code":"US-VA-RL9"},
          {"name":"RIXEYVILLE","code":"US-VA-VYX"},
          {"name":"ROANOKE","code":"US-VA-ROA"},
          {"name":"ROCHELLE","code":"US-VA-R55"},
          {"name":"ROCK ENON SPRINGS","code":"US-VA-RP7"},
          {"name":"ROCKVILLE","code":"US-VA-R2V"},
          {"name":"ROCKY MOUNT","code":"US-VA-ROV"},
          {"name":"ROSELAND","code":"US-VA-RJZ"},
          {"name":"ROWE","code":"US-VA-RWV"},
          {"name":"RUCKERSVILLE","code":"US-VA-RUK"},
          {"name":"RURAL RETREAT","code":"US-VA-RRE"},
          {"name":"RUSTBURG","code":"US-VA-T5G"},
          {"name":"RUTHER GLEN","code":"US-VA-RVG"},
          {"name":"SAINT PAUL","code":"US-VA-SAL"},
          {"name":"SALEM","code":"US-VA-UYQ"},
          {"name":"SALTVILLE","code":"US-VA-SAP"},
          {"name":"SALUDA","code":"US-VA-KLU"},
          {"name":"SANDSTON","code":"US-VA-SAX"},
          {"name":"SCHUYLER","code":"US-VA-SE6"},
          {"name":"SCOTTSBURG","code":"US-VA-OZG"},
          {"name":"SCOTTSVILLE","code":"US-VA-TTS"},
          {"name":"SEDLEY","code":"US-VA-S2Y"},
          {"name":"SEVERN","code":"US-VA-SVJ"},
          {"name":"SEWELL'S POINT","code":"US-VA-S5T"},
          {"name":"SHACKELEFORDS","code":"US-VA-S97"},
          {"name":"SHACKLEFORDS","code":"US-VA-S5F"},
          {"name":"SHENANDOAH","code":"US-VA-SZH"},
          {"name":"SKIPPERS","code":"US-VA-SKP"},
          {"name":"SMITHFIELD","code":"US-VA-SMT"},
          {"name":"SOUTH BOSTON","code":"US-VA-SOG"},
          {"name":"SOUTH HILL","code":"US-VA-SDO"},
          {"name":"SPENCER","code":"US-VA-SC8"},
          {"name":"SPERRYVILLE","code":"US-VA-2SP"},
          {"name":"SPOTSYLVANIA","code":"US-VA-XXO"},
          {"name":"SPRING GROVE","code":"US-VA-S3G"},
          {"name":"SPRINGFIELD","code":"US-VA-SPG"},
          {"name":"STAFFORD","code":"US-VA-STB"},
          {"name":"STANLEY","code":"US-VA-SYJ"},
          {"name":"STANLEYTOWN","code":"US-VA-SZT"},
          {"name":"STATESVILLE","code":"US-VA-ZIQ"},
          {"name":"STAUNTON","code":"US-VA-SHD"},
          {"name":"STEPHENS","code":"US-VA-4ST"},
          {"name":"STERLING","code":"US-VA-SGV"},
          {"name":"STEVENSBURG","code":"US-VA-ZSB"},
          {"name":"STONY CREEK","code":"US-VA-S5C"},
          {"name":"STRASBURG","code":"US-VA-SRS"},
          {"name":"STUART","code":"US-VA-ST2"},
          {"name":"STUARTS DRAFT","code":"US-VA-TUD"},
          {"name":"SUFFOLK","code":"US-VA-SUF"},
          {"name":"SURRY","code":"US-VA-QRY"},
          {"name":"SUTHERLAND","code":"US-VA-ZSD"},
          {"name":"SUTHERLIN","code":"US-VA-ZIJ"},
          {"name":"SWOOPE","code":"US-VA-ZS2"},
          {"name":"SWORDS CREEK","code":"US-VA-DSK"},
          {"name":"TABB","code":"US-VA-TAB"},
          {"name":"TAPPAHANNOCK","code":"US-VA-TKV"},
          {"name":"TASLEY","code":"US-VA-TZY"},
          {"name":"TAZEWELL","code":"US-VA-TZV"},
          {"name":"TEMPERANCEVILLE","code":"US-VA-TEM"},
          {"name":"THE PLAINS","code":"US-VA-TP9"},
          {"name":"TIMBERVILLE","code":"US-VA-TMB"},
          {"name":"TOANO","code":"US-VA-TXV"},
          {"name":"TOPPING","code":"US-VA-V27"},
          {"name":"TREVILIANS","code":"US-VA-ZJB"},
          {"name":"TRIANGLE","code":"US-VA-TNV"},
          {"name":"TROUTVILLE","code":"US-VA-UZG"},
          {"name":"TROY","code":"US-VA-TYO"},
          {"name":"TYRO","code":"US-VA-TYJ"},
          {"name":"TYSONS CORNER","code":"US-VA-T9Y"},
          {"name":"UNION HALL","code":"US-VA-UH7"},
          {"name":"UPPERVILLE","code":"US-VA-UPV"},
          {"name":"VANSANT","code":"US-VA-VST"},
          {"name":"VERONA","code":"US-VA-VRV"},
          {"name":"VICTORIA","code":"US-VA-XZV"},
          {"name":"VIENNA","code":"US-VA-VIV"},
          {"name":"VINT HILL FARMS","code":"US-VA-VHF"},
          {"name":"VINTON","code":"US-VA-XNM"},
          {"name":"VIOLA","code":"US-VA-VOL"},
          {"name":"VIRGINIA BEACH","code":"US-VA-VAB"},
          {"name":"VIRGINIA CITY","code":"US-VA-VGT"},
          {"name":"WAKE","code":"US-VA-WK5"},
          {"name":"WAKEFIELD","code":"US-VA-WKD"},
          {"name":"WALKERTON","code":"US-VA-WKN"},
          {"name":"WARM SPRINGS","code":"US-VA-9WS"},
          {"name":"WARRENTON","code":"US-VA-WVJ"},
          {"name":"WARSAW","code":"US-VA-WR2"},
          {"name":"WASHINGTON","code":"US-VA-W88"},
          {"name":"WAVERLY","code":"US-VA-WXV"},
          {"name":"WAYNESBORO","code":"US-VA-WYB"},
          {"name":"WEBER CITY","code":"US-VA-WEC"},
          {"name":"WEST POINT","code":"US-VA-VI2"},
          {"name":"WEYERS CAVE","code":"US-VA-WGQ"},
          {"name":"WHITE POST","code":"US-VA-W2P"},
          {"name":"WHITE STONE","code":"US-VA-2WS"},
          {"name":"WICOMICO CHURCH","code":"US-VA-WC4"},
          {"name":"WILLIAMSBURG","code":"US-VA-WLG"},
          {"name":"WILLIAMSBURG-NEWPORT NEWS-HAMPTON APT","code":"US-VA-PHF"},
          {"name":"WILLSTON","code":"US-VA-VAW"},
          {"name":"WILMINGTON","code":"US-VA-IMG"},
          {"name":"WINCHESTER","code":"US-VA-WGO"},
          {"name":"WINDSOR","code":"US-VA-XWN"},
          {"name":"WINTERGREEN","code":"US-VA-8WE"},
          {"name":"WIRTZ","code":"US-VA-VWW"},
          {"name":"WISE","code":"US-VA-LNP"},
          {"name":"WOODBRIDGE","code":"US-VA-WBX"},
          {"name":"WOODFORD","code":"US-VA-WJD"},
          {"name":"WOODSTOCK","code":"US-VA-WD6"},
          {"name":"WYTHEVILLE","code":"US-VA-WEV"},
          {"name":"YALE","code":"US-VA-Y4L"},
          {"name":"YORKTOWN","code":"US-VA-YKW"},
        ]
      },
      {
        name: 'Washington',
        code: 'US-WA',
        cities: [
          {"name":"ABERDEEN","code":"US-WA-GHR"},
          {"name":"ACME","code":"US-WA-QZA"},
          {"name":"AIRWAY HEIGHTS","code":"US-WA-AYH"},
          {"name":"ALGONA","code":"US-WA-UBJ"},
          {"name":"ALLYN","code":"US-WA-AY3"},
          {"name":"AMANDA PARK","code":"US-WA-AP4"},
          {"name":"AMBOY","code":"US-WA-A72"},
          {"name":"ANACORTES","code":"US-WA-OTS"},
          {"name":"ARLINGTON","code":"US-WA-AZZ"},
          {"name":"ASOTIN","code":"US-WA-OAX"},
          {"name":"AUBURN","code":"US-WA-AUJ"},
          {"name":"BAINBRIDGE ISLAND","code":"US-WA-BII"},
          {"name":"BALLARD/SEATTLE","code":"US-WA-AFY"},
          {"name":"BASIN CITY","code":"US-WA-BBS"},
          {"name":"BATTLE GROUND","code":"US-WA-BRU"},
          {"name":"BAY CENTER","code":"US-WA-B8Y"},
          {"name":"BEAVER","code":"US-WA-B9Z"},
          {"name":"BELFAIR","code":"US-WA-BA3"},
          {"name":"BELLEVUE","code":"US-WA-BVE"},
          {"name":"BELLINGHAM","code":"US-WA-BLI"},
          {"name":"BENTON CITY","code":"US-WA-IJM"},
          {"name":"BEVERLY","code":"US-WA-VBY"},
          {"name":"BINGEN","code":"US-WA-XCF"},
          {"name":"BLACK DIAMOND","code":"US-WA-KDM"},
          {"name":"BLAINE","code":"US-WA-BWS"},
          {"name":"BLAKELY ISLAND","code":"US-WA-BYW"},
          {"name":"BOEING FIELD APT","code":"US-WA-BF3"},
          {"name":"BONNEY LAKE","code":"US-WA-GQZ"},
          {"name":"BOTHELL","code":"US-WA-YTH"},
          {"name":"BOUNDARY","code":"US-WA-BPF"},
          {"name":"BOW","code":"US-WA-BW9"},
          {"name":"BREMERTON","code":"US-WA-PWT"},
          {"name":"BREWSTER","code":"US-WA-BXI"},
          {"name":"BRIER","code":"US-WA-BQG"},
          {"name":"BRINNON","code":"US-WA-B2N"},
          {"name":"BRUSH PRAIRIE","code":"US-WA-SQ3"},
          {"name":"BUCKLEY","code":"US-WA-BUZ"},
          {"name":"BUENA","code":"US-WA-BUE"},
          {"name":"BURBANK","code":"US-WA-BB2"},
          {"name":"BURIEN","code":"US-WA-UIE"},
          {"name":"BURLINGTON","code":"US-WA-BGA"},
          {"name":"CAMANO IS.","code":"US-WA-CMW"},
          {"name":"CAMAS","code":"US-WA-CMS"},
          {"name":"CARSON","code":"US-WA-RZQ"},
          {"name":"CASHMERE","code":"US-WA-XEI"},
          {"name":"CASTLE ROCK","code":"US-WA-C7A"},
          {"name":"CATHCART","code":"US-WA-CW5"},
          {"name":"CATHLAMET","code":"US-WA-CE9"},
          {"name":"CENTER ISLAND","code":"US-WA-CWS"},
          {"name":"CENTRALIA","code":"US-WA-CRA"},
          {"name":"CHATTAROY","code":"US-WA-YXX"},
          {"name":"CHEHALIS","code":"US-WA-CLS"},
          {"name":"CHELAN","code":"US-WA-XDV"},
          {"name":"CHELAN FALLS","code":"US-WA-FCQ"},
          {"name":"CHENEY","code":"US-WA-ENY"},
          {"name":"CHERRY POINT","code":"US-WA-CP4"},
          {"name":"CHEWELAH","code":"US-WA-HW2"},
          {"name":"CHIMACUM","code":"US-WA-UM9"},
          {"name":"CHINOOK","code":"US-WA-CJC"},
          {"name":"CLALLAM BAY","code":"US-WA-XMY"},
          {"name":"CLARKSTON","code":"US-WA-CKL"},
          {"name":"CLE ELUM","code":"US-WA-LEU"},
          {"name":"CLINTON","code":"US-WA-CI9"},
          {"name":"COLBERT","code":"US-WA-COL"},
          {"name":"COLFAX","code":"US-WA-YCX"},
          {"name":"COLLEGE PLACE","code":"US-WA-L5P"},
          {"name":"COLVILLE","code":"US-WA-VE8"},
          {"name":"CONCRETE","code":"US-WA-NQC"},
          {"name":"CONNELL","code":"US-WA-XEB"},
          {"name":"COSMOPOLIS","code":"US-WA-CPS"},
          {"name":"COULEE DAM","code":"US-WA-OJU"},
          {"name":"COUPEVILLE","code":"US-WA-CBL"},
          {"name":"COVINGTON","code":"US-WA-VGB"},
          {"name":"COWICHE","code":"US-WA-YHE"},
          {"name":"CRANE ISLAND","code":"US-WA-CKR"},
          {"name":"CRYSTAL MOUNTAIN","code":"US-WA-YAU"},
          {"name":"CUSTER","code":"US-WA-UTR"},
          {"name":"DANVILLE","code":"US-WA-AAT"},
          {"name":"DARRINGTON","code":"US-WA-DN3"},
          {"name":"DAVENPORT","code":"US-WA-D3P"},
          {"name":"DAYTON","code":"US-WA-DYW"},
          {"name":"DECATUR ISLAND","code":"US-WA-DTR"},
          {"name":"DEER HARBOR","code":"US-WA-YUO"},
          {"name":"DEMING","code":"US-WA-DPM"},
          {"name":"DES MOINES","code":"US-WA-DSN"},
          {"name":"DIAMOND","code":"US-WA-DD7"},
          {"name":"DU PONT","code":"US-WA-ABX"},
          {"name":"DUPONT","code":"US-WA-PUP"},
          {"name":"DUVALL","code":"US-WA-DZA"},
          {"name":"EAST GREENWICH","code":"US-WA-EH5"},
          {"name":"EAST WENATCHEE","code":"US-WA-E9W"},
          {"name":"EASTON","code":"US-WA-ESW"},
          {"name":"EASTSOUND","code":"US-WA-ESD"},
          {"name":"EATONVILLE","code":"US-WA-ETV"},
          {"name":"EDGEWOOD","code":"US-WA-EWZ"},
          {"name":"EDMONDS","code":"US-WA-EOW"},
          {"name":"ELLENSBURG","code":"US-WA-ELN"},
          {"name":"ELLSWORTH","code":"US-WA-EWT"},
          {"name":"ELMA","code":"US-WA-EAW"},
          {"name":"ELTOPIA","code":"US-WA-E9P"},
          {"name":"ENTIAT","code":"US-WA-EN7"},
          {"name":"ENUMCLAW","code":"US-WA-ECW"},
          {"name":"EPHRATA","code":"US-WA-EPH"},
          {"name":"EVERETT","code":"US-WA-PAE"},
          {"name":"EVERGREEN","code":"US-WA-EXG"},
          {"name":"EVERSON","code":"US-WA-ZC9"},
          {"name":"FAIRCHILD AIR FORCE BASE","code":"US-WA-FBS"},
          {"name":"FAIRFIELD","code":"US-WA-FFE"},
          {"name":"FALL CITY","code":"US-WA-DFY"},
          {"name":"FARMINGTON","code":"US-WA-VFI"},
          {"name":"FEDERAL WAY","code":"US-WA-FDW"},
          {"name":"FELIDA","code":"US-WA-FYD"},
          {"name":"FERNDALE","code":"US-WA-FDT"},
          {"name":"FIFE","code":"US-WA-FFS"},
          {"name":"FIRCREST","code":"US-WA-FZT"},
          {"name":"FISHER","code":"US-WA-FZR"},
          {"name":"FIVE CORNERS","code":"US-WA-FYC"},
          {"name":"FORD","code":"US-WA-F2D"},
          {"name":"FORT LEWIS","code":"US-WA-YLE"},
          {"name":"FOX ISLAND","code":"US-WA-FXL"},
          {"name":"FREDERICKSON","code":"US-WA-FDS"},
          {"name":"FREELAND","code":"US-WA-FNB"},
          {"name":"FRIDAY HARBOR","code":"US-WA-FRD"},
          {"name":"FRONTIER","code":"US-WA-FRO"},
          {"name":"GARFIELD","code":"US-WA-GFX"},
          {"name":"GEORGE","code":"US-WA-GWA"},
          {"name":"GEORGETOWN","code":"US-WA-GEW"},
          {"name":"GIG HARBOR","code":"US-WA-GGZ"},
          {"name":"GLEED","code":"US-WA-XFT"},
          {"name":"GOLD BAR","code":"US-WA-DJA"},
          {"name":"GOLDENDALE","code":"US-WA-GLA"},
          {"name":"GOOSE PRAIRIE","code":"US-WA-GPR"},
          {"name":"GRAHAM","code":"US-WA-GRQ"},
          {"name":"GRAND COULEE","code":"US-WA-GCO"},
          {"name":"GRANDVIEW","code":"US-WA-GFV"},
          {"name":"GRANGER","code":"US-WA-AGR"},
          {"name":"GRANITE FALLS","code":"US-WA-GFS"},
          {"name":"GRAPEVIEW","code":"US-WA-GP7"},
          {"name":"GRAYS HARBOR CITY","code":"US-WA-GHC"},
          {"name":"GRAYS RIVER","code":"US-WA-GRV"},
          {"name":"GREENACRES","code":"US-WA-ZDO"},
          {"name":"HAMILTON","code":"US-WA-2HA"},
          {"name":"HARRAH","code":"US-WA-HXH"},
          {"name":"HAZEL DELL","code":"US-WA-HQZ"},
          {"name":"HIGHLANDS","code":"US-WA-HQW"},
          {"name":"HILLYARD","code":"US-WA-H9Y"},
          {"name":"HOODSPORT","code":"US-WA-9WA"},
          {"name":"HOQUIAM","code":"US-WA-HQM"},
          {"name":"ILWACO","code":"US-WA-IQL"},
          {"name":"INDIANOLA","code":"US-WA-IDL"},
          {"name":"INTERBAY","code":"US-WA-IYG"},
          {"name":"IONE","code":"US-WA-IOJ"},
          {"name":"ISSAQUAH","code":"US-WA-IQH"},
          {"name":"KALAMA","code":"US-WA-KAM"},
          {"name":"KELSO","code":"US-WA-KLS"},
          {"name":"KENMORE AIR HARBOR","code":"US-WA-KEH"},
          {"name":"KENMORE/SEATTLE","code":"US-WA-KAH"},
          {"name":"KENNEWICK","code":"US-WA-KWC"},
          {"name":"KENT","code":"US-WA-KET"},
          {"name":"KETTLE FALLS","code":"US-WA-KFW"},
          {"name":"KEYPORT","code":"US-WA-KYP"},
          {"name":"KINGSTON","code":"US-WA-KG8"},
          {"name":"KIRKLAND","code":"US-WA-XHF"},
          {"name":"KITTITAS","code":"US-WA-KIZ"},
          {"name":"LA CENTER","code":"US-WA-2LC"},
          {"name":"LA CONNER","code":"US-WA-XHI"},
          {"name":"LACEY","code":"US-WA-LAY"},
          {"name":"LAKE BAY","code":"US-WA-L4B"},
          {"name":"LAKE FOREST PARK","code":"US-WA-LPK"},
          {"name":"LAKE STEVENS","code":"US-WA-LS2"},
          {"name":"LAKEWOOD","code":"US-WA-YLA"},
          {"name":"LANGLEY","code":"US-WA-LGL"},
          {"name":"LAURIER","code":"US-WA-LAU"},
          {"name":"LEAVENWORTH","code":"US-WA-LVN"},
          {"name":"LIBERTY LAKE","code":"US-WA-LLK"},
          {"name":"LILLIWAUP","code":"US-WA-LL5"},
          {"name":"LINCOLN","code":"US-WA-LI5"},
          {"name":"LIND","code":"US-WA-YLD"},
          {"name":"LONG BEACH","code":"US-WA-YBH"},
          {"name":"LONGVIEW","code":"US-WA-LOG"},
          {"name":"LOPEZ ISLAND","code":"US-WA-LPS"},
          {"name":"LOWDEN","code":"US-WA-7SD"},
          {"name":"LYLE","code":"US-WA-3WA"},
          {"name":"LYNDEN","code":"US-WA-LYN"},
          {"name":"LYNNWOOD","code":"US-WA-LYF"},
          {"name":"MABTON","code":"US-WA-ZAN"},
          {"name":"MADISON PARK","code":"US-WA-YMD"},
          {"name":"MALAGA","code":"US-WA-MG5"},
          {"name":"MALDEN","code":"US-WA-D88"},
          {"name":"MALOTT","code":"US-WA-XIT"},
          {"name":"MALTBY","code":"US-WA-AQJ"},
          {"name":"MANCHESTER","code":"US-WA-MCR"},
          {"name":"MANSON","code":"US-WA-YMN"},
          {"name":"MAPLE VALLEY","code":"US-WA-MV7"},
          {"name":"MARYSVILLE","code":"US-WA-MYS"},
          {"name":"MATTAWA","code":"US-WA-WXJ"},
          {"name":"MCCHORD AIR FORCE BASE","code":"US-WA-MFS"},
          {"name":"MCCLEARY","code":"US-WA-QMW"},
          {"name":"MCMILLIN","code":"US-WA-MMW"},
          {"name":"MEAD","code":"US-WA-ME8"},
          {"name":"MEDINA","code":"US-WA-VMX"},
          {"name":"MERCER ISLAND","code":"US-WA-MQZ"},
          {"name":"MESA","code":"US-WA-EZS"},
          {"name":"METALINE FALLS","code":"US-WA-MTF"},
          {"name":"MICA","code":"US-WA-ICM"},
          {"name":"MILL CREEK","code":"US-WA-MKW"},
          {"name":"MILTON","code":"US-WA-8RE"},
          {"name":"MINNEHAHA","code":"US-WA-MHA"},
          {"name":"MONROE","code":"US-WA-MOW"},
          {"name":"MONTESANO","code":"US-WA-MZW"},
          {"name":"MOORLANDS","code":"US-WA-MPF"},
          {"name":"MORTON","code":"US-WA-OT3"},
          {"name":"MOSES LAKE","code":"US-WA-MWH"},
          {"name":"MOSSYROCK","code":"US-WA-89M"},
          {"name":"MOUNT VERNON","code":"US-WA-MVW"},
          {"name":"MOUNTLAKE TERRACE","code":"US-WA-XJI"},
          {"name":"MOXEE CITY","code":"US-WA-XXE"},
          {"name":"MUKILTEO","code":"US-WA-MKI"},
          {"name":"NACHES","code":"US-WA-YNA"},
          {"name":"NEAH BAY","code":"US-WA-NEB"},
          {"name":"NESPELEM","code":"US-WA-N2M"},
          {"name":"NEWCASTLE","code":"US-WA-NKC"},
          {"name":"NEWMAN LAKE","code":"US-WA-NL7"},
          {"name":"NEWPORT","code":"US-WA-NP6"},
          {"name":"NIGHTHAWK","code":"US-WA-NIH"},
          {"name":"NINE MILE FALLS","code":"US-WA-N3F"},
          {"name":"NOOKSACK","code":"US-WA-KKE"},
          {"name":"NORTH BEND","code":"US-WA-NOB"},
          {"name":"NORTH BONNEVILLE","code":"US-WA-9NB"},
          {"name":"NORTH CITY","code":"US-WA-NXY"},
          {"name":"OAK HARBOR","code":"US-WA-ODW"},
          {"name":"OAKESDALE","code":"US-WA-KSE"},
          {"name":"OAKVILLE","code":"US-WA-OPV"},
          {"name":"OCEAN SHORES","code":"US-WA-OS8"},
          {"name":"ODESSA","code":"US-WA-KQI"},
          {"name":"OKANOGAN","code":"US-WA-O4G"},
          {"name":"OLYMPIA","code":"US-WA-OLM"},
          {"name":"OMAK","code":"US-WA-OMK"},
          {"name":"ONALASKA","code":"US-WA-OAA"},
          {"name":"ORCAS","code":"US-WA-XJY"},
          {"name":"ORCHARDS","code":"US-WA-OCJ"},
          {"name":"ORONDO","code":"US-WA-YDO"},
          {"name":"OROVILLE","code":"US-WA-OVW"},
          {"name":"ORTING","code":"US-WA-XKE"},
          {"name":"OTHELLO","code":"US-WA-XKF"},
          {"name":"OTIS ORCHARDS","code":"US-WA-OOW"},
          {"name":"OUTLOOK","code":"US-WA-3VC"},
          {"name":"PACIFIC","code":"US-WA-PF8"},
          {"name":"PALOUSE","code":"US-WA-PZU"},
          {"name":"PARKER","code":"US-WA-PKW"},
          {"name":"PARKWATER-SPOKANE","code":"US-WA-PW2"},
          {"name":"PASCO","code":"US-WA-PSC"},
          {"name":"PATEROS","code":"US-WA-XKT"},
          {"name":"PATERSON","code":"US-WA-PWG"},
          {"name":"PATTERSON","code":"US-WA-PT2"},
          {"name":"PESHASTIN","code":"US-WA-P2N"},
          {"name":"PLYMOUTH","code":"US-WA-P5Y"},
          {"name":"POINT ROBERTS","code":"US-WA-PRS"},
          {"name":"POINT WELLS","code":"US-WA-OW4"},
          {"name":"POMEROY","code":"US-WA-PO6"},
          {"name":"PORT ANGELES","code":"US-WA-CLM"},
          {"name":"PORT BLAKELY","code":"US-WA-PRY"},
          {"name":"PORT GAMBLE","code":"US-WA-PGH"},
          {"name":"PORT HADLOCK","code":"US-WA-ABC"},
          {"name":"PORT LUDLOW","code":"US-WA-PLU"},
          {"name":"PORT MADISON","code":"US-WA-PDI"},
          {"name":"PORT ORCHARD","code":"US-WA-PXO"},
          {"name":"PORT TOWNSEND","code":"US-WA-TWD"},
          {"name":"POULSBO","code":"US-WA-PUL"},
          {"name":"PRESCOTT","code":"US-WA-PQW"},
          {"name":"PRESTON","code":"US-WA-PRH"},
          {"name":"PROSSER","code":"US-WA-PWS"},
          {"name":"PUGET SOUND","code":"US-WA-PGQ"},
          {"name":"PULLMAN","code":"US-WA-PUW"},
          {"name":"PUYALLUP","code":"US-WA-PUY"},
          {"name":"QUILLAYUTE","code":"US-WA-UIL"},
          {"name":"QUINCY","code":"US-WA-QNC"},
          {"name":"RAINIER","code":"US-WA-QIE"},
          {"name":"RANDLE","code":"US-WA-RD3"},
          {"name":"RAVENSDALE","code":"US-WA-RV8"},
          {"name":"RAYMOND","code":"US-WA-ACC"},
          {"name":"REDMOND","code":"US-WA-XLC"},
          {"name":"RENTON","code":"US-WA-RNT"},
          {"name":"REPUBLIC","code":"US-WA-RPU"},
          {"name":"RETSIL","code":"US-WA-RTJ"},
          {"name":"RICHLAND","code":"US-WA-RLD"},
          {"name":"RICHMOND BEACH","code":"US-WA-RJJ"},
          {"name":"RICHMOND HIGHLANDS","code":"US-WA-RJH"},
          {"name":"RIDGEFIELD","code":"US-WA-RLF"},
          {"name":"RITZVILLE","code":"US-WA-RZV"},
          {"name":"ROCHE HARBOR","code":"US-WA-RCE"},
          {"name":"ROCHESTER","code":"US-WA-RCW"},
          {"name":"ROCK ISLAND","code":"US-WA-4OK"},
          {"name":"ROCKFORD","code":"US-WA-VKD"},
          {"name":"ROCKPORT","code":"US-WA-RPR"},
          {"name":"ROOSEVELT","code":"US-WA-RLT"},
          {"name":"ROSALIA","code":"US-WA-RZA"},
          {"name":"ROSARIO","code":"US-WA-RSJ"},
          {"name":"ROY","code":"US-WA-RYW"},
          {"name":"ROYAL CITY","code":"US-WA-XLB"},
          {"name":"RUSSELL","code":"US-WA-RZL"},
          {"name":"SAMMAMISH","code":"US-WA-ZMS"},
          {"name":"SATSOP","code":"US-WA-ZOP"},
          {"name":"SEABECK","code":"US-WA-SK8"},
          {"name":"SEATAC","code":"US-WA-SEZ"},
          {"name":"SEATTLE","code":"US-WA-SEA"},
          {"name":"SEAVIEW","code":"US-WA-S6E"},
          {"name":"SEDRO WOOLLEY","code":"US-WA-SW2"},
          {"name":"SELAH","code":"US-WA-EHW"},
          {"name":"SEQUIM","code":"US-WA-SQV"},
          {"name":"SHELTON","code":"US-WA-SHN"},
          {"name":"SHORELINE","code":"US-WA-SRI"},
          {"name":"SILVERDALE","code":"US-WA-SIF"},
          {"name":"SKYKOMISH","code":"US-WA-S5K"},
          {"name":"SMOKEY POINT","code":"US-WA-YSK"},
          {"name":"SNOHOMISH","code":"US-WA-SNI"},
          {"name":"SNOQUALMIE","code":"US-WA-NQA"},
          {"name":"SNOQUALMIE PASS","code":"US-WA-A3Q"},
          {"name":"SOAP LAKE","code":"US-WA-SE3"},
          {"name":"SOUTH BEND","code":"US-WA-ACJ"},
          {"name":"SOUTH PRAIRIE","code":"US-WA-S3P"},
          {"name":"SPANAWAY","code":"US-WA-SWY"},
          {"name":"SPANGLE","code":"US-WA-ISG"},
          {"name":"SPOKANE","code":"US-WA-GEG"},
          {"name":"SPOKANE VALLEY","code":"US-WA-PKV"},
          {"name":"SPRAGUE","code":"US-WA-ZZQ"},
          {"name":"STANWOOD","code":"US-WA-SNW"},
          {"name":"STARTUP","code":"US-WA-SU2"},
          {"name":"STEILACOOM","code":"US-WA-SIC"},
          {"name":"STEVENSON","code":"US-WA-EVS"},
          {"name":"STUART ISLAND","code":"US-WA-SSW"},
          {"name":"SULTAN","code":"US-WA-ULN"},
          {"name":"SUMAS","code":"US-WA-ACF"},
          {"name":"SUMNER","code":"US-WA-XMH"},
          {"name":"SUNNYSIDE","code":"US-WA-SUY"},
          {"name":"SUQUAMISH","code":"US-WA-UIQ"},
          {"name":"TACOMA","code":"US-WA-TIW"},
          {"name":"TAHUYA","code":"US-WA-TA3"},
          {"name":"TATOOSH ISLAND","code":"US-WA-TDI"},
          {"name":"TEKOA","code":"US-WA-TKO"},
          {"name":"TENINO","code":"US-WA-TQI"},
          {"name":"THORP","code":"US-WA-TJP"},
          {"name":"TOLEDO","code":"US-WA-TDO"},
          {"name":"TONASKET","code":"US-WA-TKT"},
          {"name":"TOPPENISH","code":"US-WA-TPP"},
          {"name":"TOUCHET","code":"US-WA-TH8"},
          {"name":"TRI-CITIES","code":"US-WA-YTC"},
          {"name":"TROUT LAKE","code":"US-WA-TQU"},
          {"name":"TUKWILA","code":"US-WA-TUK"},
          {"name":"TULALIP","code":"US-WA-UQQ"},
          {"name":"TUMWATER","code":"US-WA-TUW"},
          {"name":"TWISP","code":"US-WA-TWI"},
          {"name":"UNDERWOOD","code":"US-WA-UDD"},
          {"name":"UNION","code":"US-WA-UN8"},
          {"name":"UNION GAP","code":"US-WA-YUG"},
          {"name":"UNIVERSITY PLACE","code":"US-WA-UUP"},
          {"name":"USK","code":"US-WA-USK"},
          {"name":"VALLEYFORD","code":"US-WA-JVD"},
          {"name":"VANCOUVER","code":"US-WA-VAN"},
          {"name":"VASHON","code":"US-WA-VSX"},
          {"name":"VASHON ISLAND","code":"US-WA-YVI"},
          {"name":"VILLARD JUNCTION","code":"US-WA-VJ2"},
          {"name":"WAITSBURG","code":"US-WA-IBG"},
          {"name":"WALDRON ISLAND","code":"US-WA-WDN"},
          {"name":"WALLA WALLA","code":"US-WA-ALW"},
          {"name":"WALLULA","code":"US-WA-WUW"},
          {"name":"WAPATO","code":"US-WA-WPW"},
          {"name":"WARDEN","code":"US-WA-WEW"},
          {"name":"WASHOUGAL","code":"US-WA-WXG"},
          {"name":"WATERVILLE","code":"US-WA-TIL"},
          {"name":"WAUNA","code":"US-WA-YWN"},
          {"name":"WENATCHEE","code":"US-WA-EAT"},
          {"name":"WEST RICHLAND","code":"US-WA-W7R"},
          {"name":"WEST SOUND","code":"US-WA-WSX"},
          {"name":"WESTPORT","code":"US-WA-WSZ"},
          {"name":"WHIDBEY ISLAND","code":"US-WA-NUW"},
          {"name":"WHITE SALMON","code":"US-WA-WEI"},
          {"name":"WHITE SWAN","code":"US-WA-WZW"},
          {"name":"WILLAPA","code":"US-WA-WIH"},
          {"name":"WILMA","code":"US-WA-WMA"},
          {"name":"WINCHESTER","code":"US-WA-YST"},
          {"name":"WINLOCK","code":"US-WA-WQW"},
          {"name":"WOODINVILLE","code":"US-WA-WDV"},
          {"name":"WOODLAND","code":"US-WA-WUX"},
          {"name":"WOODWAY","code":"US-WA-WUF"},
          {"name":"YACOLT","code":"US-WA-YA2"},
          {"name":"YAKIMA","code":"US-WA-YKM"},
          {"name":"YELM","code":"US-WA-YYE"},
          {"name":"ZILLAH","code":"US-WA-ZIL"},
        ]
      },
      {
        name: 'Virginia Occidental',
        code: 'US-WV',
        cities: [
          {"name":"ADAMSTON","code":"US-WV-DST"},
          {"name":"ADRIAN","code":"US-WV-AR8"},
          {"name":"ALBRIGHT","code":"US-WV-QQE"},
          {"name":"ALLOY","code":"US-WV-AOY"},
          {"name":"ANMOORE","code":"US-WV-ANM"},
          {"name":"APPLE GROVE","code":"US-WV-XAU"},
          {"name":"ASHFORD","code":"US-WV-9AS"},
          {"name":"ASHLAND-HUNTINGTON APT","code":"US-WV-HTS"},
          {"name":"ATHENS","code":"US-WV-AT6"},
          {"name":"AURORA","code":"US-WV-4UR"},
          {"name":"BARBOURSVILLE","code":"US-WV-BAB"},
          {"name":"BARRACKVILLE","code":"US-WV-9BA"},
          {"name":"BARTOW","code":"US-WV-YTJ"},
          {"name":"BAYARD","code":"US-WV-BYJ"},
          {"name":"BEAVER","code":"US-WV-VBZ"},
          {"name":"BECKLEY","code":"US-WV-BKW"},
          {"name":"BEECH BOTTOM","code":"US-WV-BBV"},
          {"name":"BELLE","code":"US-WV-BLE"},
          {"name":"BELLINGTON","code":"US-WV-JBL"},
          {"name":"BELMONT","code":"US-WV-4BM"},
          {"name":"BENS RUN","code":"US-WV-B5R"},
          {"name":"BENWOOD","code":"US-WV-BW3"},
          {"name":"BERKELEY SPRINGS","code":"US-WV-BEI"},
          {"name":"BEVERLY","code":"US-WV-EVY"},
          {"name":"BICKMORE","code":"US-WV-IKM"},
          {"name":"BIG CREEK","code":"US-WV-GC8"},
          {"name":"BLACK BETSY","code":"US-WV-6BY"},
          {"name":"BLUEFIELD","code":"US-WV-AFL"},
          {"name":"BRADLEY","code":"US-WV-B9Y"},
          {"name":"BRADSHAW","code":"US-WV-W7V"},
          {"name":"BRIDGEPORT","code":"US-WV-XDJ"},
          {"name":"BRUCETON MILLS","code":"US-WV-BCM"},
          {"name":"BUCHANNON","code":"US-WV-UCQ"},
          {"name":"BUCKHANNON","code":"US-WV-BHN"},
          {"name":"BUFFALO","code":"US-WV-FFO"},
          {"name":"BUNKER HILL","code":"US-WV-B3H"},
          {"name":"CABIN CREEK","code":"US-WV-C3C"},
          {"name":"CAIRO","code":"US-WV-QIR"},
          {"name":"CANVAS","code":"US-WV-2WV"},
          {"name":"CAROLINA","code":"US-WV-OIQ"},
          {"name":"CEDAR GROVE","code":"US-WV-XZA"},
          {"name":"CEREDO","code":"US-WV-W6T"},
          {"name":"CHAPMANVILLE","code":"US-WV-VCH"},
          {"name":"CHARLES TOWN","code":"US-WV-CTS"},
          {"name":"CHARLESTON","code":"US-WV-CRW"},
          {"name":"CHESTER","code":"US-WV-CR5"},
          {"name":"CLARKSBURG","code":"US-WV-CKB"},
          {"name":"CLENDENIN","code":"US-WV-IUH"},
          {"name":"COLLIERS","code":"US-WV-G7C"},
          {"name":"CONGO","code":"US-WV-ZB7"},
          {"name":"CORDOVA","code":"US-WV-CYV"},
          {"name":"COWEN, WEBSTER","code":"US-WV-CW2"},
          {"name":"CRAB ORCHARD","code":"US-WV-CO5"},
          {"name":"CRAIGSVILLE","code":"US-WV-GZG"},
          {"name":"CROSS LANES","code":"US-WV-XL4"},
          {"name":"CULLODEN","code":"US-WV-CN4"},
          {"name":"CYCLONE","code":"US-WV-YDL"},
          {"name":"DAILEY","code":"US-WV-DAI"},
          {"name":"DANESE","code":"US-WV-DS7"},
          {"name":"DANIELS","code":"US-WV-DIS"},
          {"name":"DAVIS","code":"US-WV-D2V"},
          {"name":"DAVISVILLE, WOOD","code":"US-WV-III"},
          {"name":"DAVY","code":"US-WV-DA6"},
          {"name":"DELBARTON","code":"US-WV-VV7"},
          {"name":"DELRAY","code":"US-WV-DYB"},
          {"name":"DRENNEN","code":"US-WV-D3N"},
          {"name":"DUNBAR","code":"US-WV-DWV"},
          {"name":"DUNMORE","code":"US-WV-DU3"},
          {"name":"DURBIN","code":"US-WV-DUW"},
          {"name":"ECKMAN","code":"US-WV-EK3"},
          {"name":"ELEANOR","code":"US-WV-ILW"},
          {"name":"ELIZABETH","code":"US-WV-EBH"},
          {"name":"ELKINS","code":"US-WV-EKN"},
          {"name":"ELKVIEW","code":"US-WV-EKE"},
          {"name":"ELLENBORO","code":"US-WV-NZB"},
          {"name":"ELMORE","code":"US-WV-EMO"},
          {"name":"ESKDALE","code":"US-WV-EKL"},
          {"name":"EVANSVILLE","code":"US-WV-EVK"},
          {"name":"FAIRLEA","code":"US-WV-9FA"},
          {"name":"FAIRMONT","code":"US-WV-XFI"},
          {"name":"FAIRVIEW","code":"US-WV-VWF"},
          {"name":"FALLING WATERS","code":"US-WV-FNW"},
          {"name":"FARMINGTON","code":"US-WV-WV3"},
          {"name":"FAYETTEVILLE","code":"US-WV-YTT"},
          {"name":"FENWICK","code":"US-WV-V3W"},
          {"name":"FLEMINGTON","code":"US-WV-FFZ"},
          {"name":"FOLLANSBEE","code":"US-WV-FOO"},
          {"name":"FOREST HILL","code":"US-WV-9FH"},
          {"name":"FORT ASHBY","code":"US-WV-FBI"},
          {"name":"FORT GAY","code":"US-WV-FQG"},
          {"name":"FRANK","code":"US-WV-FQR"},
          {"name":"FRANKFORD","code":"US-WV-FF6"},
          {"name":"FRANKLIN","code":"US-WV-FIN"},
          {"name":"FRAZIERS BOTTOM","code":"US-WV-FBM"},
          {"name":"FRIENDLY","code":"US-WV-FND"},
          {"name":"GALLIPOLIS FERRY","code":"US-WV-GFE"},
          {"name":"GASSAWAY","code":"US-WV-GSY"},
          {"name":"GAULEY BRIDGE","code":"US-WV-GB7"},
          {"name":"GHENT","code":"US-WV-G2T"},
          {"name":"GLASGOW","code":"US-WV-GKW"},
          {"name":"GLEN DALE","code":"US-WV-NDL"},
          {"name":"GLEN DANIEL","code":"US-WV-GGD"},
          {"name":"GLEN EASTON","code":"US-WV-2GE"},
          {"name":"GLEN JEAN","code":"US-WV-NJE"},
          {"name":"GLENDALE","code":"US-WV-GWV"},
          {"name":"GLENVILLE","code":"US-WV-GZ2"},
          {"name":"GRAFTON","code":"US-WV-GAO"},
          {"name":"GRANTSVILLE","code":"US-WV-G3V"},
          {"name":"GREAT CACAPON","code":"US-WV-9G2"},
          {"name":"GREEN BANK","code":"US-WV-GK3"},
          {"name":"HALF WAY","code":"US-WV-H5W"},
          {"name":"HALLTOWN","code":"US-WV-HN4"},
          {"name":"HARPERS FERRY","code":"US-WV-HFW"},
          {"name":"HARRISVILLE","code":"US-WV-HVW"},
          {"name":"HEATERS","code":"US-WV-HS4"},
          {"name":"HEDGESVILLE","code":"US-WV-KHG"},
          {"name":"HEPZIBAH","code":"US-WV-HZB"},
          {"name":"HICO","code":"US-WV-9HI"},
          {"name":"HIGH VIEW","code":"US-WV-HIW"},
          {"name":"HILLSBORO","code":"US-WV-IQB"},
          {"name":"HINTON","code":"US-WV-HI2"},
          {"name":"HOLDEN","code":"US-WV-HQO"},
          {"name":"HORNER","code":"US-WV-9HR"},
          {"name":"HUNTINGTON","code":"US-WV-HNU"},
          {"name":"HUNTINGTON-ASHLAND APT","code":"US-WV-HTS"},
          {"name":"HURRICANE","code":"US-WV-HU3"},
          {"name":"INSTITUTE","code":"US-WV-INA"},
          {"name":"INWOOD","code":"US-WV-IWV"},
          {"name":"JANE LEW","code":"US-WV-JAE"},
          {"name":"JULIAN","code":"US-WV-JUQ"},
          {"name":"JUSTICE","code":"US-WV-J2T"},
          {"name":"KEARNEYSVILLE","code":"US-WV-KYV"},
          {"name":"KENNA","code":"US-WV-DKA"},
          {"name":"KENOVA","code":"US-WV-KVA"},
          {"name":"KESLERS CROSS LANES","code":"US-WV-V2N"},
          {"name":"KEYSER","code":"US-WV-KY3"},
          {"name":"KEYSTONE","code":"US-WV-KS4"},
          {"name":"KINGWOOD","code":"US-WV-KGD"},
          {"name":"LANSING","code":"US-WV-LQG"},
          {"name":"LEFT HAND","code":"US-WV-9LH"},
          {"name":"LESAGE","code":"US-WV-LGM"},
          {"name":"LESTER","code":"US-WV-ZVR"},
          {"name":"LETART","code":"US-WV-JZT"},
          {"name":"LEVELS","code":"US-WV-LE6"},
          {"name":"LEWISBURG","code":"US-WV-LWB"},
          {"name":"LINDEN","code":"US-WV-6ML"},
          {"name":"LINDSIDE","code":"US-WV-LIE"},
          {"name":"LOGAN","code":"US-WV-OQN"},
          {"name":"LONG REACH","code":"US-WV-LRC"},
          {"name":"MABIE","code":"US-WV-XIC"},
          {"name":"MABSCOTT","code":"US-WV-3SD"},
          {"name":"MADISON","code":"US-WV-QMD"},
          {"name":"MAIDSVILLE","code":"US-WV-IBL"},
          {"name":"MAN","code":"US-WV-VMA"},
          {"name":"MANNINGTON","code":"US-WV-QNG"},
          {"name":"MARLINTON","code":"US-WV-M4L"},
          {"name":"MARTINSBURG","code":"US-WV-MRB"},
          {"name":"MASONTOWN","code":"US-WV-VXI"},
          {"name":"MATEWAN","code":"US-WV-M9W"},
          {"name":"MAYSVILLE","code":"US-WV-YSI"},
          {"name":"MCDOWELL","code":"US-WV-DJW"},
          {"name":"MCMECHEN","code":"US-WV-EHJ"},
          {"name":"MEADOW BRIDGE","code":"US-WV-M6B"},
          {"name":"MELISSA","code":"US-WV-FG4"},
          {"name":"MIDDLEBOURNE","code":"US-WV-M4B"},
          {"name":"MIDDLEWAY","code":"US-WV-DDY"},
          {"name":"MILLWOOD","code":"US-WV-MW4"},
          {"name":"MILTON","code":"US-WV-ZNM"},
          {"name":"MINERAL WELLS","code":"US-WV-IWF"},
          {"name":"MINERALWELLS","code":"US-WV-MW5"},
          {"name":"MOATSVILLE","code":"US-WV-VIZ"},
          {"name":"MONAVILLE","code":"US-WV-M4V"},
          {"name":"MONTGOMERY","code":"US-WV-OTE"},
          {"name":"MOOREFIELD","code":"US-WV-MF3"},
          {"name":"MORGANTOWN","code":"US-WV-MGW"},
          {"name":"MOUNDSVILLE","code":"US-WV-MFZ"},
          {"name":"MOUNT HOPE","code":"US-WV-ULZ"},
          {"name":"MOUNT NEBO","code":"US-WV-QNB"},
          {"name":"MOUNT STORM","code":"US-WV-M75"},
          {"name":"MULLENS","code":"US-WV-MU3"},
          {"name":"MURRAYSVILLE","code":"US-WV-WQ2"},
          {"name":"NATRIUM","code":"US-WV-NRM"},
          {"name":"NEW CUMBERLAND","code":"US-WV-NCU"},
          {"name":"NEW HAVEN","code":"US-WV-YNH"},
          {"name":"NEW MARTINSVILLE","code":"US-WV-NM2"},
          {"name":"NEWELL","code":"US-WV-NVG"},
          {"name":"NITRO","code":"US-WV-NIT"},
          {"name":"NORTHFORK","code":"US-WV-NKZ"},
          {"name":"OAK HILL","code":"US-WV-KHJ"},
          {"name":"ONA","code":"US-WV-OA7"},
          {"name":"PADEN CITY","code":"US-WV-PCY"},
          {"name":"PARKERSBURG","code":"US-WV-PKB"},
          {"name":"PARSONS","code":"US-WV-PWV"},
          {"name":"PAW PAW","code":"US-WV-UXT"},
          {"name":"PAX","code":"US-WV-PX2"},
          {"name":"PENNSBORO","code":"US-WV-PBZ"},
          {"name":"PETERSBURG","code":"US-WV-PGC"},
          {"name":"PHILIPPI","code":"US-WV-PPI"},
          {"name":"PIEDMONT","code":"US-WV-PNT"},
          {"name":"PINEVILLE","code":"US-WV-PMC"},
          {"name":"POCA","code":"US-WV-POX"},
          {"name":"POCATALICO","code":"US-WV-OAT"},
          {"name":"POINT PLEASANT","code":"US-WV-PPZ"},
          {"name":"PRICHARD","code":"US-WV-PT7"},
          {"name":"PRINCETON","code":"US-WV-BLF"},
          {"name":"PROCTOR","code":"US-WV-PR5"},
          {"name":"RAINELLE","code":"US-WV-RNU"},
          {"name":"RAND","code":"US-WV-ZE8"},
          {"name":"RANSON","code":"US-WV-RAS"},
          {"name":"RAVENSWOOD","code":"US-WV-RWW"},
          {"name":"RED HOUSE","code":"US-WV-DHP"},
          {"name":"REEDSVILLE","code":"US-WV-EIV"},
          {"name":"REEDY","code":"US-WV-RYY"},
          {"name":"RENICK","code":"US-WV-IZK"},
          {"name":"RICHWOOD","code":"US-WV-RXW"},
          {"name":"RIDGELEY","code":"US-WV-RG9"},
          {"name":"RIPLEY","code":"US-WV-RPE"},
          {"name":"ROCK","code":"US-WV-K9C"},
          {"name":"ROCKET CENTER","code":"US-WV-9RC"},
          {"name":"ROCKPORT","code":"US-WV-KOB"},
          {"name":"RODERFIELD","code":"US-WV-REF"},
          {"name":"RONCEVERTE","code":"US-WV-ROT"},
          {"name":"SAINT ALBANS","code":"US-WV-SA3"},
          {"name":"SAINT MARYS","code":"US-WV-SMA"},
          {"name":"SALEM","code":"US-WV-MBO"},
          {"name":"SAND FORK","code":"US-WV-S24"},
          {"name":"SCOTT DEPOT","code":"US-WV-DZQ"},
          {"name":"SHADY SPRING","code":"US-WV-RYS"},
          {"name":"SHENANDOAH JUNCTION","code":"US-WV-SJ8"},
          {"name":"SHEPHERDSTOWN","code":"US-WV-8ST"},
          {"name":"SHINNSTON","code":"US-WV-SKF"},
          {"name":"SHREWSBURY","code":"US-WV-QZY"},
          {"name":"SISSONVILLE","code":"US-WV-ISV"},
          {"name":"SISTERSVILLE","code":"US-WV-IST"},
          {"name":"SMITHVILLE, RITCHIE","code":"US-WV-QSM"},
          {"name":"SMOOT","code":"US-WV-O7T"},
          {"name":"SNOWSHOE","code":"US-WV-SN5"},
          {"name":"SOPHIA","code":"US-WV-S9O"},
          {"name":"SOUTH CHARLESTON","code":"US-WV-SCT"},
          {"name":"SPELTER","code":"US-WV-9SL"},
          {"name":"SPENCER","code":"US-WV-WVP"},
          {"name":"STONEWOOD","code":"US-WV-XVS"},
          {"name":"SUMMERSVILLE","code":"US-WV-SWV"},
          {"name":"SUMMIT POINT","code":"US-WV-SWP"},
          {"name":"SUTTON","code":"US-WV-SUP"},
          {"name":"SWITZER","code":"US-WV-ZSW"},
          {"name":"TAMS","code":"US-WV-XTS"},
          {"name":"TEAYS","code":"US-WV-TS3"},
          {"name":"TERRA ALTA","code":"US-WV-RR9"},
          {"name":"THACKER","code":"US-WV-THK"},
          {"name":"THOMAS","code":"US-WV-TM2"},
          {"name":"THORNTON","code":"US-WV-TWV"},
          {"name":"TRIADELPHIA","code":"US-WV-TDP"},
          {"name":"UNEEDA","code":"US-WV-UDF"},
          {"name":"UNION, MONROE","code":"US-WV-UIO"},
          {"name":"VALLEY GROVE","code":"US-WV-VEF"},
          {"name":"VIENNA","code":"US-WV-VWV"},
          {"name":"WALKER","code":"US-WV-W2V"},
          {"name":"WARDENSVILLE","code":"US-WV-WAE"},
          {"name":"WASHINGTON","code":"US-WV-WWV"},
          {"name":"WAVERLY","code":"US-WV-VRY"},
          {"name":"WEBSTER SPRINGS","code":"US-WV-WZ4"},
          {"name":"WEIRTON","code":"US-WV-WET"},
          {"name":"WELLSBURG","code":"US-WV-WES"},
          {"name":"WEST COLUMBIA","code":"US-WV-WE7"},
          {"name":"WEST LIBERTY","code":"US-WV-7WL"},
          {"name":"WEST LOGAN","code":"US-WV-WE5"},
          {"name":"WESTON","code":"US-WV-WZE"},
          {"name":"WHEELING","code":"US-WV-HLG"},
          {"name":"WHITE SULPHUR SPRINGS","code":"US-WV-SSU"},
          {"name":"WHITESVILLE","code":"US-WV-WHV"},
          {"name":"WHITMAN","code":"US-WV-IAQ"},
          {"name":"WILLIAMSTOWN","code":"US-WV-YWS"},
          {"name":"WILLOW ISLAND","code":"US-WV-WWY"},
          {"name":"WINDSOR HEIGHTS","code":"US-WV-ZRI"},
          {"name":"WINFIELD","code":"US-WV-WF9"},
          {"name":"WORTHINGTON","code":"US-WV-ZWG"},
        ]
      },
      {
        name: 'Wisconsin',
        code: 'US-WI',
        cities: [
          {"name":"ABBOTSFORD","code":"US-WI-ABD"},
          {"name":"ABRAMS","code":"US-WI-QYA"},
          {"name":"ADAMS","code":"US-WI-AZM"},
          {"name":"ADELL","code":"US-WI-AJD"},
          {"name":"ADRIAN","code":"US-WI-DIA"},
          {"name":"ALBANY","code":"US-WI-AYW"},
          {"name":"ALGOMA","code":"US-WI-QAG"},
          {"name":"ALLENTON","code":"US-WI-ZAG"},
          {"name":"ALMA","code":"US-WI-XAM"},
          {"name":"ALMENA","code":"US-WI-N6A"},
          {"name":"ALMOND","code":"US-WI-AO5"},
          {"name":"ALTOONA","code":"US-WI-AZP"},
          {"name":"AMERY","code":"US-WI-AHH"},
          {"name":"AMHERST","code":"US-WI-XWI"},
          {"name":"ANTIGO","code":"US-WI-UBN"},
          {"name":"APPLETON","code":"US-WI-ATW"},
          {"name":"ARBOR VITAE","code":"US-WI-AV2"},
          {"name":"ARCADIA","code":"US-WI-ADI"},
          {"name":"ARENA","code":"US-WI-4RN"},
          {"name":"ARGYLE","code":"US-WI-A3Y"},
          {"name":"ARLINGTON","code":"US-WI-XBM"},
          {"name":"ARPIN","code":"US-WI-RPI"},
          {"name":"ASHIPPUN","code":"US-WI-W24"},
          {"name":"ASHLAND","code":"US-WI-ASX"},
          {"name":"ASHWAUBENON","code":"US-WI-AWB"},
          {"name":"ATHENS","code":"US-WI-THZ"},
          {"name":"AUBURNDALE","code":"US-WI-ZJR"},
          {"name":"AUGUSTA","code":"US-WI-UUA"},
          {"name":"AVOCA","code":"US-WI-AA3"},
          {"name":"BABCOCK","code":"US-WI-BAK"},
          {"name":"BALDWIN","code":"US-WI-ZBW"},
          {"name":"BALSAM LAKE","code":"US-WI-9BK"},
          {"name":"BANCROFT","code":"US-WI-JNF"},
          {"name":"BANGOR","code":"US-WI-BG2"},
          {"name":"BARABOO","code":"US-WI-XBQ"},
          {"name":"BARNEVELD","code":"US-WI-VDX"},
          {"name":"BARRON","code":"US-WI-YRR"},
          {"name":"BAY CITY","code":"US-WI-YBZ"},
          {"name":"BAY VIEW","code":"US-WI-YEW"},
          {"name":"BAYFIELD","code":"US-WI-BFI"},
          {"name":"BAYSIDE","code":"US-WI-UYD"},
          {"name":"BEAR CREEK","code":"US-WI-IBK"},
          {"name":"BEAVER DAM","code":"US-WI-BVA"},
          {"name":"BEETOWN","code":"US-WI-FBW"},
          {"name":"BELGIUM","code":"US-WI-BGU"},
          {"name":"BELLEVILLE","code":"US-WI-BVK"},
          {"name":"BELMONT","code":"US-WI-XXB"},
          {"name":"BELOIT","code":"US-WI-BOT"},
          {"name":"BENTON","code":"US-WI-8EN"},
          {"name":"BERLIN","code":"US-WI-BRB"},
          {"name":"BIG BEND","code":"US-WI-GYQ"},
          {"name":"BINGHAMTON","code":"US-WI-IGH"},
          {"name":"BIRCHWOOD","code":"US-WI-IWO"},
          {"name":"BIRNAMWOOD","code":"US-WI-WO4"},
          {"name":"BIRON","code":"US-WI-BI3"},
          {"name":"BLACK CREEK","code":"US-WI-BLC"},
          {"name":"BLACK RIVER FALLS","code":"US-WI-LCV"},
          {"name":"BLAINE","code":"US-WI-BLA"},
          {"name":"BLAIR","code":"US-WI-VBA"},
          {"name":"BLOOMER","code":"US-WI-OOM"},
          {"name":"BLUE MOUNDS","code":"US-WI-2BM"},
          {"name":"BLUE RIVER","code":"US-WI-B9R"},
          {"name":"BONDUEL","code":"US-WI-BOU"},
          {"name":"BOSCOBEL","code":"US-WI-BBE"},
          {"name":"BOWLER","code":"US-WI-BOB"},
          {"name":"BOYCEVILLE","code":"US-WI-BYC"},
          {"name":"BOYD","code":"US-WI-YDD"},
          {"name":"BRANCH","code":"US-WI-ZA9"},
          {"name":"BRANDON","code":"US-WI-KBD"},
          {"name":"BRILLION","code":"US-WI-XCJ"},
          {"name":"BRISTOL","code":"US-WI-BSJ"},
          {"name":"BRODHEAD","code":"US-WI-BH4"},
          {"name":"BROKAW","code":"US-WI-ZVH"},
          {"name":"BROOKFIELD","code":"US-WI-XCH"},
          {"name":"BROOKLYN","code":"US-WI-AFZ"},
          {"name":"BROWN DEER","code":"US-WI-XCQ"},
          {"name":"BROWNSVILLE","code":"US-WI-ZVB"},
          {"name":"BRUCE","code":"US-WI-BHI"},
          {"name":"BRUSSELS","code":"US-WI-B6S"},
          {"name":"BRYANT","code":"US-WI-9BY"},
          {"name":"BURLINGTON","code":"US-WI-BUW"},
          {"name":"BUTLER","code":"US-WI-XDG"},
          {"name":"BUTTERNUT","code":"US-WI-BGH"},
          {"name":"CALEDONIA","code":"US-WI-CXX"},
          {"name":"CAMBRIA","code":"US-WI-WMB"},
          {"name":"CAMBRIDGE","code":"US-WI-AMG"},
          {"name":"CAMERON","code":"US-WI-ZCM"},
          {"name":"CAMP DOUGLAS","code":"US-WI-VOK"},
          {"name":"CAMPBELLSPORT","code":"US-WI-QCP"},
          {"name":"CAROLINE","code":"US-WI-KCA"},
          {"name":"CASCO","code":"US-WI-ZQO"},
          {"name":"CASHTON","code":"US-WI-CN9"},
          {"name":"CATAWBA","code":"US-WI-A54"},
          {"name":"CEDAR GROVE","code":"US-WI-CGR"},
          {"name":"CEDARBURG","code":"US-WI-CEG"},
          {"name":"CENTURIA","code":"US-WI-N7T"},
          {"name":"CHETEK","code":"US-WI-XTK"},
          {"name":"CHILTON","code":"US-WI-CNI"},
          {"name":"CHIPPEWA FALLS","code":"US-WI-CPF"},
          {"name":"CLAM FALLS","code":"US-WI-CF5"},
          {"name":"CLEAR LAKE","code":"US-WI-LQA"},
          {"name":"CLEVELAND","code":"US-WI-CVW"},
          {"name":"CLINTON","code":"US-WI-CWZ"},
          {"name":"CLINTONVILLE","code":"US-WI-CLI"},
          {"name":"CLOVER","code":"US-WI-XVR"},
          {"name":"CLYMAN","code":"US-WI-LZA"},
          {"name":"COBB","code":"US-WI-OBB"},
          {"name":"COCHRANE","code":"US-WI-RAW"},
          {"name":"COLBY","code":"US-WI-C8Y"},
          {"name":"COLEMAN","code":"US-WI-OEM"},
          {"name":"COLFAX","code":"US-WI-8CF"},
          {"name":"COLUMBIA","code":"US-WI-COV"},
          {"name":"COLUMBUS","code":"US-WI-CBW"},
          {"name":"COMBINED LOCKS","code":"US-WI-XDM"},
          {"name":"COON VALLEY","code":"US-WI-VYD"},
          {"name":"COTTAGE GROVE","code":"US-WI-CG3"},
          {"name":"CRANDON","code":"US-WI-RWZ"},
          {"name":"CRIVITZ","code":"US-WI-CQ7"},
          {"name":"CROSS PLAINS","code":"US-WI-CXH"},
          {"name":"CUBA CITY","code":"US-WI-CY4"},
          {"name":"CUDAHY","code":"US-WI-CUD"},
          {"name":"CUMBERLAND","code":"US-WI-ZCU"},
          {"name":"CURTISS","code":"US-WI-TSS"},
          {"name":"CUSTER","code":"US-WI-UTE"},
          {"name":"DALE","code":"US-WI-YDE"},
          {"name":"DANBURY","code":"US-WI-DBZ"},
          {"name":"DANE","code":"US-WI-DNE"},
          {"name":"DARIEN","code":"US-WI-DDR"},
          {"name":"DARLINGTON","code":"US-WI-DGY"},
          {"name":"DE FOREST","code":"US-WI-DFO"},
          {"name":"DE PERE","code":"US-WI-DRW"},
          {"name":"DEERBROOK","code":"US-WI-6DB"},
          {"name":"DEERFIELD","code":"US-WI-DFD"},
          {"name":"DELAFIELD","code":"US-WI-DFZ"},
          {"name":"DELAVAN","code":"US-WI-DEA"},
          {"name":"DENMARK","code":"US-WI-DXS"},
          {"name":"DICKEYVILLE","code":"US-WI-Y99"},
          {"name":"DODGEVILLE","code":"US-WI-DDV"},
          {"name":"DORCHESTER","code":"US-WI-DCS"},
          {"name":"DOUSMAN","code":"US-WI-DO2"},
          {"name":"DOWNING","code":"US-WI-DOI"},
          {"name":"DOWNSVILLE","code":"US-WI-DXV"},
          {"name":"DRESSER","code":"US-WI-DSW"},
          {"name":"DUNBAR","code":"US-WI-DBJ"},
          {"name":"DURAND","code":"US-WI-DXD"},
          {"name":"EAGLE","code":"US-WI-XXP"},
          {"name":"EAGLE RIVER","code":"US-WI-EGV"},
          {"name":"EAST RIO","code":"US-WI-ER5"},
          {"name":"EAST TROY","code":"US-WI-TYY"},
          {"name":"EASTMAN","code":"US-WI-E3W"},
          {"name":"EAU CLAIRE","code":"US-WI-EAU"},
          {"name":"EDEN","code":"US-WI-EWF"},
          {"name":"EDGAR","code":"US-WI-EGA"},
          {"name":"EDGERTON","code":"US-WI-EGT"},
          {"name":"EGG HARBOR","code":"US-WI-GGB"},
          {"name":"ELDORADO","code":"US-WI-EQD"},
          {"name":"ELK MOUND","code":"US-WI-EYX"},
          {"name":"ELKHART LAKE","code":"US-WI-EKK"},
          {"name":"ELKHORN","code":"US-WI-ELH"},
          {"name":"ELLSWORTH","code":"US-WI-EZL"},
          {"name":"ELM GROVE","code":"US-WI-EXV"},
          {"name":"ELMWOOD","code":"US-WI-EWX"},
          {"name":"ELMWOOD, PIERCE","code":"US-WI-EW3"},
          {"name":"ELROY","code":"US-WI-ERY"},
          {"name":"EMBARRASS","code":"US-WI-E7M"},
          {"name":"ENDEAVOR","code":"US-WI-EPV"},
          {"name":"ETTRICK","code":"US-WI-W5I"},
          {"name":"EVANSVILLE","code":"US-WI-ESV"},
          {"name":"EXELAND","code":"US-WI-EXD"},
          {"name":"FAIRWATER","code":"US-WI-FW3"},
          {"name":"FALL CREEK","code":"US-WI-FFK"},
          {"name":"FALL RIVER","code":"US-WI-FAZ"},
          {"name":"FENNIMORE","code":"US-WI-PNM"},
          {"name":"FERRYVILLE","code":"US-WI-XZ7"},
          {"name":"FITCHBURG","code":"US-WI-FIB"},
          {"name":"FLORENCE","code":"US-WI-FCE"},
          {"name":"FOND DU LAC","code":"US-WI-FLD"},
          {"name":"FONTANA","code":"US-WI-FJO"},
          {"name":"FOREST JUNCTION","code":"US-WI-FJ3"},
          {"name":"FORESTVILLE","code":"US-WI-FV7"},
          {"name":"FORT ATKINSON","code":"US-WI-XFO"},
          {"name":"FOUNTAIN CITY","code":"US-WI-F3C"},
          {"name":"FOX LAKE","code":"US-WI-YFX"},
          {"name":"FOX POINT","code":"US-WI-FXP"},
          {"name":"FRANKLIN","code":"US-WI-FLW"},
          {"name":"FRANKSVILLE","code":"US-WI-FVW"},
          {"name":"FREDERIC","code":"US-WI-FW9"},
          {"name":"FREDONIA","code":"US-WI-FZD"},
          {"name":"FREISLAND","code":"US-WI-FDP"},
          {"name":"FREMONT","code":"US-WI-F6E"},
          {"name":"FRIENDSHIP","code":"US-WI-FDH"},
          {"name":"FRIESLAND","code":"US-WI-FZA"},
          {"name":"GALESVILLE","code":"US-WI-G2L"},
          {"name":"GAYS MILLS","code":"US-WI-GMS"},
          {"name":"GENESEE","code":"US-WI-GJC"},
          {"name":"GENOA","code":"US-WI-GQO"},
          {"name":"GENOA CITY","code":"US-WI-OIY"},
          {"name":"GERMANTOWN","code":"US-WI-GMN"},
          {"name":"GILLETT","code":"US-WI-GTT"},
          {"name":"GLEASON","code":"US-WI-GS6"},
          {"name":"GLEN FLORA","code":"US-WI-ZLO"},
          {"name":"GLENDALE","code":"US-WI-GEE"},
          {"name":"GLIDDEN","code":"US-WI-ZGE"},
          {"name":"GOODMAN","code":"US-WI-GGP"},
          {"name":"GRAFTON","code":"US-WI-GFN"},
          {"name":"GRAND CHUTE","code":"US-WI-GCU"},
          {"name":"GRAND VIEW","code":"US-WI-GW6"},
          {"name":"GRANTON","code":"US-WI-RAO"},
          {"name":"GRANTSBURG","code":"US-WI-GBU"},
          {"name":"GRANVILLE","code":"US-WI-GR9"},
          {"name":"GREEN BAY","code":"US-WI-GRB"},
          {"name":"GREEN LAKE","code":"US-WI-GR6"},
          {"name":"GREENBUSH","code":"US-WI-GBW"},
          {"name":"GREENDALE","code":"US-WI-GN4"},
          {"name":"GREENFIELD","code":"US-WI-GJE"},
          {"name":"GREENVILLE","code":"US-WI-GIE"},
          {"name":"GREENWOOD, CLARK","code":"US-WI-GWC"},
          {"name":"HAGER CITY","code":"US-WI-XGC"},
          {"name":"HALES CORNERS","code":"US-WI-HZR"},
          {"name":"HAMMOND","code":"US-WI-HMM"},
          {"name":"HANCOCK","code":"US-WI-W7S"},
          {"name":"HARTFORD","code":"US-WI-HFO"},
          {"name":"HARTLAND","code":"US-WI-HA9"},
          {"name":"HAUGEN","code":"US-WI-UGQ"},
          {"name":"HAWKINS","code":"US-WI-HWZ"},
          {"name":"HAYWARD","code":"US-WI-HYR"},
          {"name":"HAZELHURST","code":"US-WI-HZ9"},
          {"name":"HILBERT","code":"US-WI-HBB"},
          {"name":"HILLSBORO","code":"US-WI-ZH3"},
          {"name":"HINGHAM","code":"US-WI-H2G"},
          {"name":"HIXTON","code":"US-WI-IXT"},
          {"name":"HOBART","code":"US-WI-QOB"},
          {"name":"HOLCOMBE","code":"US-WI-HZM"},
          {"name":"HOLLAND","code":"US-WI-WOL"},
          {"name":"HOLMEN","code":"US-WI-HFM"},
          {"name":"HONEY CREEK","code":"US-WI-HCE"},
          {"name":"HORICON","code":"US-WI-HRW"},
          {"name":"HORTONVILLE","code":"US-WI-H7V"},
          {"name":"HOULTON","code":"US-WI-OUZ"},
          {"name":"HOWARD","code":"US-WI-HXW"},
          {"name":"HUBERTUS","code":"US-WI-HU4"},
          {"name":"HUDSON","code":"US-WI-HDW"},
          {"name":"HUMBIRD","code":"US-WI-H7W"},
          {"name":"HURLEY","code":"US-WI-HUY"},
          {"name":"HUSTISFORD","code":"US-WI-ZJT"},
          {"name":"IOLA","code":"US-WI-ILF"},
          {"name":"IRON RIDGE","code":"US-WI-QII"},
          {"name":"IRON RIVER","code":"US-WI-IIV"},
          {"name":"IXONIA","code":"US-WI-IXN"},
          {"name":"JACKSON","code":"US-WI-JAW"},
          {"name":"JANESVILLE","code":"US-WI-JVL"},
          {"name":"JEFFERSON","code":"US-WI-JFS"},
          {"name":"JEFFERSON JUNCTION","code":"US-WI-JFJ"},
          {"name":"JIM FALLS","code":"US-WI-JIM"},
          {"name":"JOHNSON CREEK","code":"US-WI-XHC"},
          {"name":"JUDA","code":"US-WI-JUD"},
          {"name":"JUNCTION CITY","code":"US-WI-JC4"},
          {"name":"JUNEAU","code":"US-WI-UNU"},
          {"name":"KANSASVILLE","code":"US-WI-KSV"},
          {"name":"KAUKAUNA","code":"US-WI-KAU"},
          {"name":"KENOSHA","code":"US-WI-ENW"},
          {"name":"KESHENA","code":"US-WI-KZA"},
          {"name":"KEWASKUM","code":"US-WI-KWI"},
          {"name":"KEWAUNEE","code":"US-WI-KWU"},
          {"name":"KIEL","code":"US-WI-KEI"},
          {"name":"KIELER","code":"US-WI-KI3"},
          {"name":"KIMBERLY","code":"US-WI-KBL"},
          {"name":"KING","code":"US-WI-KG3"},
          {"name":"KNAPP","code":"US-WI-KAP"},
          {"name":"KOHLER","code":"US-WI-KOR"},
          {"name":"KRAKOW","code":"US-WI-KK3"},
          {"name":"KRONENWETTER","code":"US-WI-KR3"},
          {"name":"LA CROSSE","code":"US-WI-LSE"},
          {"name":"LA FARGE","code":"US-WI-LFZ"},
          {"name":"LA POINTE","code":"US-WI-JPP"},
          {"name":"LAC DU FLAMBEAU","code":"US-WI-7LD"},
          {"name":"LADYSMITH","code":"US-WI-ZYT"},
          {"name":"LAKE DELTON","code":"US-WI-KDT"},
          {"name":"LAKE GENEVA","code":"US-WI-XES"},
          {"name":"LAKE MILLS","code":"US-WI-XHQ"},
          {"name":"LAKEMILLS","code":"US-WI-LMW"},
          {"name":"LANCASTER","code":"US-WI-ZLI"},
          {"name":"LAND O' LAKES","code":"US-WI-LLS"},
          {"name":"LANNON","code":"US-WI-LN9"},
          {"name":"LAONA","code":"US-WI-LNU"},
          {"name":"LENA","code":"US-WI-LWX"},
          {"name":"LITTLE CHUTE","code":"US-WI-LTC"},
          {"name":"LITTLE SUAMICO","code":"US-WI-L2S"},
          {"name":"LODI","code":"US-WI-ILO"},
          {"name":"LOGANVILLE","code":"US-WI-L3G"},
          {"name":"LOMIRA","code":"US-WI-XHR"},
          {"name":"LONE ROCK","code":"US-WI-LNR"},
          {"name":"LOWELL","code":"US-WI-LJW"},
          {"name":"LOYAL","code":"US-WI-LY7"},
          {"name":"LUCK","code":"US-WI-LUC"},
          {"name":"LUXEMBURG","code":"US-WI-XR9"},
          {"name":"LYNDON STATION","code":"US-WI-YND"},
          {"name":"LYONS","code":"US-WI-YOI"},
          {"name":"MADISON","code":"US-WI-MSN"},
          {"name":"MANAWA","code":"US-WI-MWW"},
          {"name":"MANITOWISH WATERS","code":"US-WI-IZW"},
          {"name":"MANITOWOC","code":"US-WI-MTW"},
          {"name":"MAPLE BLUFF","code":"US-WI-ZPU"},
          {"name":"MARATHON","code":"US-WI-MHH"},
          {"name":"MARINETTE","code":"US-WI-MOY"},
          {"name":"MARION","code":"US-WI-IMA"},
          {"name":"MARKESAN","code":"US-WI-MKQ"},
          {"name":"MARSHALL","code":"US-WI-QML"},
          {"name":"MARSHFIELD","code":"US-WI-MFI"},
          {"name":"MASON, BAYFIELD","code":"US-WI-ONQ"},
          {"name":"MATTOON","code":"US-WI-KMA"},
          {"name":"MAUSTON","code":"US-WI-TSX"},
          {"name":"MAYVILLE","code":"US-WI-MYI"},
          {"name":"MAZOMANIE","code":"US-WI-MZV"},
          {"name":"MCFARLAND","code":"US-WI-MF6"},
          {"name":"MEDFORD","code":"US-WI-MDF"},
          {"name":"MELLEN","code":"US-WI-ZML"},
          {"name":"MENASHA","code":"US-WI-MEZ"},
          {"name":"MENOMONEE FALLS","code":"US-WI-MMF"},
          {"name":"MENOMONIE","code":"US-WI-MMM"},
          {"name":"MEQUON","code":"US-WI-MQU"},
          {"name":"MERCER","code":"US-WI-DMC"},
          {"name":"MERRILL","code":"US-WI-RRL"},
          {"name":"MERRIMAC","code":"US-WI-RM8"},
          {"name":"MERTON","code":"US-WI-M7N"},
          {"name":"MIDDLETON","code":"US-WI-MDW"},
          {"name":"MILLADORE","code":"US-WI-WM4"},
          {"name":"MILLSTON","code":"US-WI-ISZ"},
          {"name":"MILLTOWN","code":"US-WI-MM5"},
          {"name":"MILTON","code":"US-WI-M8I"},
          {"name":"MILWAUKEE","code":"US-WI-MKE"},
          {"name":"MINDORO","code":"US-WI-DHO"},
          {"name":"MINERAL POINT, IOWA","code":"US-WI-MP3"},
          {"name":"MINOCQUA","code":"US-WI-ARV"},
          {"name":"MINONG","code":"US-WI-WMG"},
          {"name":"MISHICOT","code":"US-WI-JMZ"},
          {"name":"MODENA","code":"US-WI-OJX"},
          {"name":"MONDOVI","code":"US-WI-ODI"},
          {"name":"MONONA","code":"US-WI-IMO"},
          {"name":"MONROE","code":"US-WI-MWI"},
          {"name":"MONTELLO","code":"US-WI-OLO"},
          {"name":"MONTFORT","code":"US-WI-XQZ"},
          {"name":"MONTICELLO","code":"US-WI-IEO"},
          {"name":"MOSEL","code":"US-WI-M9S"},
          {"name":"MOSINEE","code":"US-WI-INX"},
          {"name":"MOUNT HOREB","code":"US-WI-MH4"},
          {"name":"MOUNT PLEASANT","code":"US-WI-UPN"},
          {"name":"MOUNTAIN","code":"US-WI-MU4"},
          {"name":"MUKWONAGO","code":"US-WI-MKZ"},
          {"name":"MUSCODA","code":"US-WI-MUZ"},
          {"name":"MUSKEGO","code":"US-WI-USG"},
          {"name":"NASHOTAH","code":"US-WI-7AS"},
          {"name":"NECEDAH","code":"US-WI-NEJ"},
          {"name":"NEENAH","code":"US-WI-UXK"},
          {"name":"NEILLSVILLE","code":"US-WI-IEW"},
          {"name":"NEKOOSA","code":"US-WI-KOE"},
          {"name":"NELSON","code":"US-WI-QNL"},
          {"name":"NELSONVILLE, PORTAGE","code":"US-WI-NSJ"},
          {"name":"NEW AUBURN","code":"US-WI-NUB"},
          {"name":"NEW BERLIN","code":"US-WI-NBB"},
          {"name":"NEW FRANKEN","code":"US-WI-NFN"},
          {"name":"NEW GLARUS","code":"US-WI-NXS"},
          {"name":"NEW HOLSTEIN","code":"US-WI-NWE"},
          {"name":"NEW LISBON","code":"US-WI-NLI"},
          {"name":"NEW LONDON","code":"US-WI-NLD"},
          {"name":"NEW RICHMOND","code":"US-WI-RNH"},
          {"name":"NEWALD","code":"US-WI-NDW"},
          {"name":"NEWTON","code":"US-WI-QKW"},
          {"name":"NIAGARA","code":"US-WI-NGW"},
          {"name":"NICHOLS","code":"US-WI-IHU"},
          {"name":"NORTH FREEDOM","code":"US-WI-NFW"},
          {"name":"NORTH PRAIRIE","code":"US-WI-NPE"},
          {"name":"OAK CREEK","code":"US-WI-OAC"},
          {"name":"OAKFIELD","code":"US-WI-OKF"},
          {"name":"OCONOMOWOC","code":"US-WI-OCO"},
          {"name":"OCONTO","code":"US-WI-OCQ"},
          {"name":"OCONTO FALLS","code":"US-WI-XJV"},
          {"name":"OGDENSBURG","code":"US-WI-OG2"},
          {"name":"OMRO","code":"US-WI-OM2"},
          {"name":"ONALASKA","code":"US-WI-ZOK"},
          {"name":"ONEIDA","code":"US-WI-ONI"},
          {"name":"ONTARIO","code":"US-WI-W7I"},
          {"name":"OOSTBURG","code":"US-WI-OSU"},
          {"name":"OREGON","code":"US-WI-OR9"},
          {"name":"OSCEOLA","code":"US-WI-OEO"},
          {"name":"OSHKOSH","code":"US-WI-OSH"},
          {"name":"OSSEO","code":"US-WI-OZZ"},
          {"name":"OWEN","code":"US-WI-ZOE"},
          {"name":"OXFORD","code":"US-WI-OFQ"},
          {"name":"PALMYRA","code":"US-WI-ZPY"},
          {"name":"PARDEEVILLE","code":"US-WI-PDF"},
          {"name":"PARK FALLS","code":"US-WI-PKF"},
          {"name":"PEARSON","code":"US-WI-PS6"},
          {"name":"PEMBINE","code":"US-WI-PM9"},
          {"name":"PEPIN","code":"US-WI-WX7"},
          {"name":"PESHTIGO","code":"US-WI-PSH"},
          {"name":"PEWAUKEE","code":"US-WI-PEW"},
          {"name":"PHILLIPS","code":"US-WI-PJP"},
          {"name":"PIGEON FALLS","code":"US-WI-PF5"},
          {"name":"PINE RIVE","code":"US-WI-P4R"},
          {"name":"PITTSVILLE","code":"US-WI-ITV"},
          {"name":"PLAIN","code":"US-WI-PN8"},
          {"name":"PLAINFIELD","code":"US-WI-LNF"},
          {"name":"PLATTEVILLE","code":"US-WI-TTZ"},
          {"name":"PLEASANT PRAIRIE","code":"US-WI-PLP"},
          {"name":"PLOVER","code":"US-WI-PLW"},
          {"name":"PLYMOUTH","code":"US-WI-PMW"},
          {"name":"POLAR","code":"US-WI-S6O"},
          {"name":"PORT EDWARDS","code":"US-WI-PDW"},
          {"name":"PORT WASHINGTON","code":"US-WI-PWA"},
          {"name":"PORTAGE","code":"US-WI-XKX"},
          {"name":"POSKIN","code":"US-WI-PUK"},
          {"name":"POTTER","code":"US-WI-PT6"},
          {"name":"POUND","code":"US-WI-PWJ"},
          {"name":"POWERS LAKE","code":"US-WI-XKY"},
          {"name":"POY SIPPI","code":"US-WI-VPJ"},
          {"name":"POYNETTE","code":"US-WI-POV"},
          {"name":"PRAIRIE DU CHIEN","code":"US-WI-PCD"},
          {"name":"PRAIRIE DU SAC","code":"US-WI-PS3"},
          {"name":"PRENTICE","code":"US-WI-PRW"},
          {"name":"PRESCOTT","code":"US-WI-PC5"},
          {"name":"PRINCETON","code":"US-WI-KZN"},
          {"name":"PULASKI","code":"US-WI-PYK"},
          {"name":"RACINE","code":"US-WI-RAC"},
          {"name":"RANDOLPH","code":"US-WI-RNP"},
          {"name":"RANDOM LAKE","code":"US-WI-RLK"},
          {"name":"RAYMOND","code":"US-WI-RM2"},
          {"name":"READSTOWN","code":"US-WI-RD5"},
          {"name":"REDGRANITE","code":"US-WI-RJG"},
          {"name":"REEDSBURG","code":"US-WI-RDS"},
          {"name":"REEDSVILLE","code":"US-WI-REI"},
          {"name":"REESEVILLE","code":"US-WI-EEV"},
          {"name":"RHINELANDER","code":"US-WI-RHI"},
          {"name":"RICE LAKE","code":"US-WI-RIE"},
          {"name":"RICHFIELD","code":"US-WI-QPC"},
          {"name":"RICHLAND CENTER","code":"US-WI-RLC"},
          {"name":"RICHMOND","code":"US-WI-RMW"},
          {"name":"RIDGELAND","code":"US-WI-RZG"},
          {"name":"RIDGEWAY","code":"US-WI-RIT"},
          {"name":"RINGLE","code":"US-WI-RAF"},
          {"name":"RIO","code":"US-WI-R88"},
          {"name":"RIPON","code":"US-WI-RPN"},
          {"name":"RIVER FALLS","code":"US-WI-RFW"},
          {"name":"RIVER HILLS","code":"US-WI-R4V"},
          {"name":"ROBERTS","code":"US-WI-RBT"},
          {"name":"ROCHESTER","code":"US-WI-OHX"},
          {"name":"ROCK SPRINGS","code":"US-WI-VWS"},
          {"name":"ROCKLAND","code":"US-WI-RQD"},
          {"name":"ROSENDALE","code":"US-WI-ROD"},
          {"name":"ROSHOLT","code":"US-WI-R2T"},
          {"name":"ROTHSCHILD","code":"US-WI-RTS"},
          {"name":"SAINT CLOUD","code":"US-WI-S2C"},
          {"name":"SAINT CROIX FALLS","code":"US-WI-TCO"},
          {"name":"SAINT FRANCIS","code":"US-WI-SF9"},
          {"name":"SAINT GERMAIN","code":"US-WI-JIR"},
          {"name":"SAINT NAZIANZ","code":"US-WI-SZZ"},
          {"name":"SARONA","code":"US-WI-A98"},
          {"name":"SAUK CITY","code":"US-WI-SC3"},
          {"name":"SAUKVILLE","code":"US-WI-VVV"},
          {"name":"SAXEVILLE","code":"US-WI-XVL"},
          {"name":"SAYNER","code":"US-WI-YNY"},
          {"name":"SCHOFIELD","code":"US-WI-SFW"},
          {"name":"SEYMOUR","code":"US-WI-SYW"},
          {"name":"SHARON","code":"US-WI-ISH"},
          {"name":"SHAWANO","code":"US-WI-SWS"},
          {"name":"SHEBOYGAN","code":"US-WI-SBM"},
          {"name":"SHEBOYGAN FALLS","code":"US-WI-SBF"},
          {"name":"SHELBY","code":"US-WI-W7W"},
          {"name":"SHELDON","code":"US-WI-Z4X"},
          {"name":"SHELL LAKE","code":"US-WI-HL8"},
          {"name":"SHERWOOD","code":"US-WI-SR7"},
          {"name":"SHOREWOOD","code":"US-WI-S9W"},
          {"name":"SHULLSBURG","code":"US-WI-HJB"},
          {"name":"SILVER LAKE","code":"US-WI-IXL"},
          {"name":"SIREN","code":"US-WI-IRE"},
          {"name":"SISTER BAY","code":"US-WI-8IS"},
          {"name":"SLINGER","code":"US-WI-IGE"},
          {"name":"SOLDIERS GROVE","code":"US-WI-JDG"},
          {"name":"SOMERSET","code":"US-WI-OMP"},
          {"name":"SOUTH MILWAUKEE","code":"US-WI-MK6"},
          {"name":"SOUTH WAYNE","code":"US-WI-OHJ"},
          {"name":"SPARTA","code":"US-WI-CMY"},
          {"name":"SPENCER","code":"US-WI-SNZ"},
          {"name":"SPOONER","code":"US-WI-SPO"},
          {"name":"SPRING GREEN","code":"US-WI-QSG"},
          {"name":"SPRING VALLEY","code":"US-WI-VYW"},
          {"name":"STANLEY","code":"US-WI-NYL"},
          {"name":"STEVENS POINT","code":"US-WI-STE"},
          {"name":"STODDARD","code":"US-WI-TDU"},
          {"name":"STOUGHTON","code":"US-WI-ZTN"},
          {"name":"STRATFORD","code":"US-WI-TFO"},
          {"name":"STRUM","code":"US-WI-UCT"},
          {"name":"STURGEON BAY","code":"US-WI-SUE"},
          {"name":"STURTEVANT","code":"US-WI-SRX"},
          {"name":"SUAMICO","code":"US-WI-S9U"},
          {"name":"SUN PRAIRIE","code":"US-WI-SPE"},
          {"name":"SUPERIOR","code":"US-WI-SUW"},
          {"name":"SURING","code":"US-WI-SGZ"},
          {"name":"SUSSEX","code":"US-WI-SSY"},
          {"name":"TAYLOR","code":"US-WI-TAO"},
          {"name":"THERESA","code":"US-WI-TRE"},
          {"name":"THIENSVILLE","code":"US-WI-TSV"},
          {"name":"THORP","code":"US-WI-TQP"},
          {"name":"THREE LAKES","code":"US-WI-QTK"},
          {"name":"TIGERTON","code":"US-WI-TGR"},
          {"name":"TOMAH","code":"US-WI-QTH"},
          {"name":"TOMAHAWK","code":"US-WI-TMW"},
          {"name":"TONY","code":"US-WI-TY5"},
          {"name":"TREMPEALEAU","code":"US-WI-T2R"},
          {"name":"TREVOR","code":"US-WI-TVO"},
          {"name":"TUNNEL CITY","code":"US-WI-TUI"},
          {"name":"TURTLE LAKE","code":"US-WI-TTK"},
          {"name":"TWIN LAKES","code":"US-WI-TVV"},
          {"name":"TWO RIVERS","code":"US-WI-TWR"},
          {"name":"UNION CENTER","code":"US-WI-ZUC"},
          {"name":"UNION GROVE","code":"US-WI-UGR"},
          {"name":"UNITY","code":"US-WI-UWI"},
          {"name":"VALDERS","code":"US-WI-VAD"},
          {"name":"VAN BUREN","code":"US-WI-VBE"},
          {"name":"VERNON","code":"US-WI-VFN"},
          {"name":"VERONA","code":"US-WI-VRA"},
          {"name":"VESPER","code":"US-WI-VES"},
          {"name":"VIOLA","code":"US-WI-VIX"},
          {"name":"VIROQUA","code":"US-WI-VIR"},
          {"name":"WALDO","code":"US-WI-WGA"},
          {"name":"WALES","code":"US-WI-W3A"},
          {"name":"WALWORTH","code":"US-WI-WAW"},
          {"name":"WARRENS","code":"US-WI-WR9"},
          {"name":"WASHBURN","code":"US-WI-WZU"},
          {"name":"WASHINGTON ISLAND","code":"US-WI-GSA"},
          {"name":"WATERFORD","code":"US-WI-TEY"},
          {"name":"WATERLOO","code":"US-WI-ZWA"},
          {"name":"WATERTOWN","code":"US-WI-WAF"},
          {"name":"WAUKESHA","code":"US-WI-UES"},
          {"name":"WAUMANDEE","code":"US-WI-W5D"},
          {"name":"WAUNAKEE","code":"US-WI-WKW"},
          {"name":"WAUPACA","code":"US-WI-WUP"},
          {"name":"WAUPUN","code":"US-WI-WUU"},
          {"name":"WAUSAU","code":"US-WI-AUW"},
          {"name":"WAUSAUKEE","code":"US-WI-USU"},
          {"name":"WAUTOMA","code":"US-WI-WWX"},
          {"name":"WAUWATOSA","code":"US-WI-XOH"},
          {"name":"WAXDALE","code":"US-WI-XNR"},
          {"name":"WEBSTER","code":"US-WI-W3S"},
          {"name":"WEST ALLIS","code":"US-WI-WEQ"},
          {"name":"WEST BEND","code":"US-WI-ETB"},
          {"name":"WEST MILWAUKEE","code":"US-WI-WMW"},
          {"name":"WEST SALEM","code":"US-WI-WSS"},
          {"name":"WESTBORO","code":"US-WI-WQB"},
          {"name":"WESTBY","code":"US-WI-VSY"},
          {"name":"WESTFIELD","code":"US-WI-WF2"},
          {"name":"WESTON","code":"US-WI-WZT"},
          {"name":"WEYAUWEGA","code":"US-WI-WWI"},
          {"name":"WHEATLAND","code":"US-WI-WFW"},
          {"name":"WHITE LAKE","code":"US-WI-XWE"},
          {"name":"WHITEHALL","code":"US-WI-WHW"},
          {"name":"WHITELAW","code":"US-WI-2WA"},
          {"name":"WHITEWATER","code":"US-WI-WWQ"},
          {"name":"WHITING","code":"US-WI-WTI"},
          {"name":"WILLIAMS BAY","code":"US-WI-3AY"},
          {"name":"WILMOT","code":"US-WI-WT7"},
          {"name":"WILSON","code":"US-WI-IOF"},
          {"name":"WILTON","code":"US-WI-WT8"},
          {"name":"WINDSOR","code":"US-WI-XOC"},
          {"name":"WINNECONNE","code":"US-WI-YEC"},
          {"name":"WISCONSIN DELLS","code":"US-WI-WNF"},
          {"name":"WISCONSIN RAPIDS","code":"US-WI-ISW"},
          {"name":"WITHEE","code":"US-WI-W44"},
          {"name":"WITTENBERG","code":"US-WI-WB2"},
          {"name":"WONEWOC","code":"US-WI-WOG"},
          {"name":"WOODRUFF","code":"US-WI-W2R"},
          {"name":"WOODVILLE","code":"US-WI-WJV"},
          {"name":"WRIGHTSTOWN","code":"US-WI-WXS"},
          {"name":"WYOMING","code":"US-WI-WYW"},
          {"name":"YORKVILLE","code":"US-WI-YW7"},
          {"name":"ZENDA","code":"US-WI-ZDA"},
        ]
      },
      {
        name: 'Wyoming',
        code: 'US-WY',
        cities: [
          {"name":"AFTON","code":"US-WY-AFO"},
          {"name":"ALADDIN","code":"US-WY-DNJ"},
          {"name":"ALBANY","code":"US-WY-AB2"},
          {"name":"ALBIN","code":"US-WY-AQI"},
          {"name":"ALPINE","code":"US-WY-AP5"},
          {"name":"ALTA","code":"US-WY-TUU"},
          {"name":"ALVA","code":"US-WY-AV6"},
          {"name":"BAR NUNN","code":"US-WY-RNI"},
          {"name":"BASIN","code":"US-WY-XBR"},
          {"name":"BIG HORN","code":"US-WY-BH8"},
          {"name":"BIG PINEY","code":"US-WY-BPI"},
          {"name":"BOSLER","code":"US-WY-BL5"},
          {"name":"BUFFALO","code":"US-WY-BYG"},
          {"name":"BURLINGTON","code":"US-WY-XCU"},
          {"name":"CASPER","code":"US-WY-CPR"},
          {"name":"CHEYENNE","code":"US-WY-CYS"},
          {"name":"CODY","code":"US-WY-COD"},
          {"name":"COKEVILLE","code":"US-WY-YWW"},
          {"name":"COLONY","code":"US-WY-CGY"},
          {"name":"DIAMONDVILLE","code":"US-WY-DM4"},
          {"name":"DOUGLAS/CONVERSE","code":"US-WY-DGW"},
          {"name":"DUBOIS","code":"US-WY-DBV"},
          {"name":"ETNA","code":"US-WY-ET2"},
          {"name":"EVANSTON","code":"US-WY-EVW"},
          {"name":"EVANSVILLE","code":"US-WY-VNI"},
          {"name":"FALLS","code":"US-WY-FA9"},
          {"name":"FE WARREN AIR FORCE BASE","code":"US-WY-JJW"},
          {"name":"FORT BRIDGER","code":"US-WY-FBR"},
          {"name":"FRANNIE","code":"US-WY-7BW"},
          {"name":"GILLETTE","code":"US-WY-GCC"},
          {"name":"GLENROCK","code":"US-WY-GQK"},
          {"name":"GREEN RIVER","code":"US-WY-GVV"},
          {"name":"GREYBULL","code":"US-WY-GEY"},
          {"name":"GUERNSEY","code":"US-WY-GUN"},
          {"name":"HANNA","code":"US-WY-HNX"},
          {"name":"HULETT","code":"US-WY-UEU"},
          {"name":"JACKSON","code":"US-WY-JAC"},
          {"name":"KAYCEE","code":"US-WY-KC3"},
          {"name":"KELLY","code":"US-WY-KY7"},
          {"name":"KEMMERER","code":"US-WY-EMM"},
          {"name":"KIRBY","code":"US-WY-KR4"},
          {"name":"LA BARGE","code":"US-WY-LB8"},
          {"name":"LANCE CREEK","code":"US-WY-EQK"},
          {"name":"LANDER","code":"US-WY-LND"},
          {"name":"LARAMIE","code":"US-WY-LAR"},
          {"name":"LINGLE","code":"US-WY-LI2"},
          {"name":"LOVELL","code":"US-WY-LLV"},
          {"name":"LOVELL-POWELL APT","code":"US-WY-POY"},
          {"name":"LUSK","code":"US-WY-LSK"},
          {"name":"MEDICINE BOW","code":"US-WY-VMB"},
          {"name":"MEETEETSE","code":"US-WY-EM8"},
          {"name":"MILLS","code":"US-WY-JML"},
          {"name":"MORAN","code":"US-WY-MX7"},
          {"name":"NEWCASTLE","code":"US-WY-ECS"},
          {"name":"NORTH JAVA","code":"US-WY-JVA"},
          {"name":"OPAL","code":"US-WY-OP8"},
          {"name":"OSAGE","code":"US-WY-OG8"},
          {"name":"PINE BLUFFS","code":"US-WY-QPS"},
          {"name":"PINEDALE","code":"US-WY-PD8"},
          {"name":"POINT OF ROCKS","code":"US-WY-PFS"},
          {"name":"POWELL","code":"US-WY-PRQ"},
          {"name":"POWELL-LOVELL APT","code":"US-WY-POY"},
          {"name":"RANCHESTER","code":"US-WY-RW7"},
          {"name":"RAWLINS","code":"US-WY-RWL"},
          {"name":"RELIANCE","code":"US-WY-IAC"},
          {"name":"RIVERTON","code":"US-WY-RIW"},
          {"name":"ROCK SPRINGS","code":"US-WY-RKS"},
          {"name":"ROZET","code":"US-WY-YRZ"},
          {"name":"SARATOGA","code":"US-WY-SAA"},
          {"name":"SAVERY","code":"US-WY-S6Y"},
          {"name":"SHERIDAN","code":"US-WY-SHR"},
          {"name":"SHOSHONI","code":"US-WY-SN4"},
          {"name":"SINCLAIR","code":"US-WY-SC7"},
          {"name":"SUNDANCE","code":"US-WY-SUC"},
          {"name":"TEN SLEEP","code":"US-WY-TSL"},
          {"name":"TETON VILLAGE","code":"US-WY-TET"},
          {"name":"THAYNE","code":"US-WY-TY8"},
          {"name":"THERMOPOLIS","code":"US-WY-THP"},
          {"name":"TORRINGTON","code":"US-WY-TOR"},
          {"name":"UPTON","code":"US-WY-UWY"},
          {"name":"WAMSUTTER","code":"US-WY-WU3"},
          {"name":"WAPITI","code":"US-WY-IIH"},
          {"name":"WENDOVER","code":"US-WY-WDW"},
          {"name":"WESTON","code":"US-WY-WY7"},
          {"name":"WHEATLAND","code":"US-WY-EAN"},
          {"name":"WILSON","code":"US-WY-W5L"},
          {"name":"WORLAND","code":"US-WY-WRL"},
          {"name":"WRIGHT","code":"US-WY-WGJ"},
        ]
      }
    ]
  },
  {
    name: 'Grenada',
    code: 'GD',
    dialCode: '+1-473',
    flag: 'flag_grenada.png',
    states: [
      {
        name: 'Saint Andrew',
        code: 'GD-01',
        cities: []
      },
      {
        name: 'Saint David',
        code: 'GD-02',
        cities: []
      },
      {
        name: 'Saint George',
        code: 'GD-03',
        cities: []
      },
      {
        name: 'Saint John',
        code: 'GD-04',
        cities: []
      },
      {
        name: 'Saint Mark',
        code: 'GD-05',
        cities: []
      },
      {
        name: 'Saint Patrick',
        code: 'GD-06',
        cities: []
      },
      {
        name: 'Southern Grenadine Islands',
        code: 'GD-10',
        cities: []
      }
    ]
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    dialCode: '+590',
    flag: 'flag_guadeloupe.png',
    states: [
      {
        name: 'Basse-Terre',
        code: 'GP-01',
        cities: []
      },
    ]
  },
  {
    name: 'Guatemala',
    code: 'GT',
    dialCode: '+502',
    flag: 'flag_guatemala.png',
    states: [
      {
        name: 'Alta Verapaz',
        code: 'GT-AV',
        cities: [{"name":"CARCHÁ","code":"GT-AV-TMP164337"},
          {"name":"SAN CRISTÓBAL VERAPAZ","code":"GT-AV-SCV"},
          {"name":"SAN JUAN CHAMELCO","code":"GT-AV-SJC"},
          {"name":"SANTA CRUZ VERAPAZ","code":"GT-AV-SRV"},
          {"name":"TACTIC","code":"GT-AV-TAC"},]
      },
      {
        name: 'Baja Verapaz',
        code: 'GT-BV',
        cities: [{"name":"EL CARMEN","code":"GT-BV-E8C"},
          {"name":"NINO PERDIDO","code":"GT-BV-NNO"},
          {"name":"PURULHÁ","code":"GT-BV-PUR"},
          {"name":"SALAMÁ","code":"GT-BV-SLM"},
          {"name":"SAN JERÓNIMO","code":"GT-BV-SJE"},]
      },
      {
        name: 'Chimaltenango',
        code: 'GT-CM',
        cities: [{"name":"EL TEJAR","code":"GT-CM-ELT"},
          {"name":"LOS TABLONES","code":"GT-CM-LOX"},
          {"name":"PARRAMOS","code":"GT-CM-PAR"},
          {"name":"PATZICÍA","code":"GT-CM-PTZ"},
          {"name":"PATZÚN","code":"GT-CM-PAN"},
          {"name":"SAN ANDRÉS ITZAPA","code":"GT-CM-SAI"},
          {"name":"TECPÁN GUATEMALA","code":"GT-CM-TCP"},]
      },
      {
        name: 'Chiquimula',
        code: 'GT-CQ',
        cities: [{"name":"CAMOTÁN","code":"GT-CQ-CAM"},
          {"name":"CHIQUIMULA","code":"GT-CQ-TMP86758"},
          {"name":"ESQUIPULAS","code":"GT-CQ-ESQ"},]
      },
      {
        name: 'El Progreso',
        code: 'GT-PR',
        cities: [{"name":"EL JICARO","code":"GT-PR-EJO"},
          {"name":"EL PROGRESO","code":"GT-PR-PRO"},
          {"name":"EL RANCHO","code":"GT-PR-ELR"},
          {"name":"GUASTATOYA","code":"GT-PR-GUT"},
          {"name":"SAN AGUSTÍN ACASAGUASTLÁN","code":"GT-PR-SA2"},
          {"name":"SAN CRISTÓBAL ACASAGUASTLÁN","code":"GT-PR-SCA"},
          {"name":"SANARATE","code":"GT-PR-SAR"},
          {"name":"SANSARE","code":"GT-PR-PPR"},]
      },
      {
        name: 'Escuintla',
        code: 'GT-ES',
        cities: [{"name":"IZTAPA","code":"GT-ES-ITA"},
          {"name":"LA DEMOCRACIA","code":"GT-ES-LDE"},
          {"name":"LA GOMERA","code":"GT-ES-LGA"},
          {"name":"MASAGUA","code":"GT-ES-MAS"},
          {"name":"NUEVA CONCEPCIÓN","code":"GT-ES-8RT"},
          {"name":"OBERO","code":"GT-ES-OBE"},
          {"name":"PUERTO SAN JOSÉ","code":"GT-ES-PSJ"},
          {"name":"SAN JOSÉ","code":"GT-ES-SN2"},
          {"name":"SANTA LUCÍA","code":"GT-ES-TMP86825"},
          {"name":"SANTA LUCÍA COTZUMALGUAPA","code":"GT-ES-SLC"},]
      },
      {
        name: 'Guatemala',
        code: 'GT-GU',
        cities: [{"name":"AMATITLÁN","code":"GT-GU-AMA"},
          {"name":"BÁRCENAS","code":"GT-GU-BAR"},
          {"name":"BOCA DEL MONTE","code":"GT-GU-BOC"},
          {"name":"CHINAUTLA","code":"GT-GU-CHT"},
          {"name":"CIUDAD DE GUATEMALA","code":"GT-GU-TMP86827"},
          {"name":"FRAIJANES","code":"GT-GU-TMP86769"},
          {"name":"GUATEMALA CITY","code":"GT-GU-TMP86770"},
          {"name":"IXCÁN","code":"GT-GU-IXC"},
          {"name":"MIXCO","code":"GT-GU-MIX"},
          {"name":"PALENCIA","code":"GT-GU-PLA"},
          {"name":"PETAPA","code":"GT-GU-PET"},
          {"name":"SAN JOSÉ DEL GOLFO","code":"GT-GU-SJG"},
          {"name":"SAN JUAN SACATEPÉQUEZ","code":"GT-GU-SJS"},
          {"name":"SAN MIGUEL PETAPA","code":"GT-GU-SMI"},
          {"name":"SAN PEDRO AYAMPUC","code":"GT-GU-SPY"},
          {"name":"SAN PEDRO SACATEPÉQUEZ","code":"GT-GU-SPS"},
          {"name":"SAN RAIMUNDO","code":"GT-GU-SRM"},
          {"name":"SAN RAYMUNDO","code":"GT-GU-SAN"},
          {"name":"SANTA CATARINA PINULA","code":"GT-GU-SCP"},
          {"name":"SANTA ELENA BARILLAS","code":"GT-GU-EBS"},
          {"name":"VILLA CANALES","code":"GT-GU-VLL"},
          {"name":"VILLA NUEVA","code":"GT-GU-VLN"},
          {"name":"VILLALOBOS","code":"GT-GU-VLO"},
          {"name":"VISTA HERMOSA","code":"GT-GU-TMP86779"},]
      },
      {
        name: 'Huehuetenango',
        code: 'GT-HU',
        cities: [{"name":"EL SALVADOR","code":"GT-HU-ESV"},
          {"name":"MAGDALENA","code":"GT-HU-MAG"},
          {"name":"SANTA BÁRBARA","code":"GT-HU-SBR"},]
      },
      {
        name: 'Izacal',
        code: 'GT-IZ',
        cities: [{"name":"EL ESTOR","code":"GT-IZ-ELE"},
          {"name":"ENTRE RÍOS","code":"GT-IZ-ERI"},
          {"name":"IZABAL","code":"GT-IZ-JZB"},
          {"name":"LÍVINGSTON","code":"GT-IZ-LIV"},
          {"name":"LOS AMATES","code":"GT-IZ-LAS"},
          {"name":"MORALES","code":"GT-IZ-MOR"},
          {"name":"PUERTO BARRIOS","code":"GT-IZ-TMP86782"},
          {"name":"PUERTO SANTO TOMÁS DE CASTILLA","code":"GT-IZ-STC"},
          {"name":"QUIRIGUÁ","code":"GT-IZ-QUI"},
          {"name":"SAN MARCOS","code":"GT-IZ-SMS"},
          {"name":"SANTO TOMÁS DE CASTILLA","code":"GT-IZ-IZ4"},]
      },
      {
        name: 'Jalapa',
        code: 'GT-JA',
        cities: [{"name":"MATAQUESCUINTLA","code":"GT-JA-MAT"},
          {"name":"MONJAS","code":"GT-JA-MJS"},]
      },
      {
        name: 'Jutiapa',
        code: 'GT-JU',
        cities: [{"name":"ASUNCIÓN MITA","code":"GT-JU-AMI"},
          {"name":"JUTIAPA","code":"GT-JU-JUT"},
          {"name":"SAN CRISTÓBAL FRONTERA","code":"GT-JU-SCF"},]
      },
      {
        name: 'Petén',
        code: 'GT-PE',
        cities: [{"name":"SAN BENITO","code":"GT-PE-SBE"},
          {"name":"SAN FRANCISCO","code":"GT-PE-SFR"},
          {"name":"SANTA ELENA","code":"GT-PE-SEP"},]
      },
      {
        name: 'Quetzaltenango',
        code: 'GT-QZ',
        cities: [{"name":"COATEPEQUE","code":"GT-QZ-CTF"},
          {"name":"TECUN UMAN","code":"GT-QZ-TUC"},]
      },
      {
        name: 'Quiiché',
        code: 'GT-QC',
        cities: [{"name":"CHAJUL","code":"GT-QC-CJL"},
          {"name":"CHICHICASTENANGO","code":"GT-QC-CCO"},
          {"name":"NEBAJ","code":"GT-QC-NEB"},
          {"name":"PLAYA GRANDE","code":"GT-QC-PKJ"},
          {"name":"QUICHÉ","code":"GT-QC-AQB"},
          {"name":"SAN JUAN COTZAL","code":"GT-QC-SAJ"},
          {"name":"USPANTÁN","code":"GT-QC-USP"},]
      },
      {
        name: 'Retalhuleu',
        code: 'GT-RE',
        cities: [{"name":"CHAMPERICO","code":"GT-RE-CHR"},
          {"name":"SAN SEBASTIÁN","code":"GT-RE-SEB"},
          {"name":"SANTA CRUZ MULUÁ","code":"GT-RE-SCM"},]
      },
      {
        name: 'Sacaatepéquez',
        code: 'GT-SA',
        cities: [{"name":"ANTIGUA GUATEMALA","code":"GT-SA-AGA"},
          {"name":"CIUDAD VIEJA","code":"GT-SA-CDV"},
          {"name":"JOCOTENANGO","code":"GT-SA-JTG"},
          {"name":"MAGDALENA MILPAS ALTAS","code":"GT-SA-MMA"},
          {"name":"SAN BARTOLOMÉ MILPAS ALTAS","code":"GT-SA-SAB"},
          {"name":"SAN LUCAS","code":"GT-SA-SNL"},
          {"name":"SAN LUCAS SACATEPÉQUEZ","code":"GT-SA-SAL"},
          {"name":"SAN MIGUEL DUEÑAS","code":"GT-SA-SMD"},
          {"name":"SANTA LUCÍA MILPAS ALTAS","code":"GT-SA-SLU"},
          {"name":"SANTA MARÍA CAUQUÉ","code":"GT-SA-SMC"},
          {"name":"SANTIAGO SACATEPÉQUEZ","code":"GT-SA-SAS"},
          {"name":"SANTO TOMÁS MILPAS ALTAS","code":"GT-SA-STM"},
          {"name":"SUMPANGO","code":"GT-SA-SUM"},]
      },
      {
        name: 'San Marcos',
        code: 'GT-SM',
        cities: [{"name":"CIUDAD TECÚN UMÁN","code":"GT-SM-CTU"},
          {"name":"EL QUETZAL","code":"GT-SM-EQZ"},
          {"name":"LA BLANCA","code":"GT-SM-LBA"},
          {"name":"SAN MARCOS","code":"GT-SM-SAM"},
          {"name":"SAN RAFAEL PIE DE LA CUESTA","code":"GT-SM-SRA"},
          {"name":"TILAPA","code":"GT-SM-TPA"},]
      },
      {
        name: 'Santa Rosa',
        code: 'GT-SR',
        cities: [{"name":"BARBERENA","code":"GT-SR-BAB"},
          {"name":"CHIQUIMULILLA","code":"GT-SR-CHQ"},
          {"name":"EL PINO","code":"GT-SR-ELP"},
          {"name":"LOS ESCLAVOS","code":"GT-SR-LEC"},
          {"name":"NUEVA SANTA ROSA","code":"GT-SR-NSR"},
          {"name":"SAN RAFAEL LAS FLORES","code":"GT-SR-SRL"},
          {"name":"SANTA ROSA DE LIMA","code":"GT-SR-PSA"},
          {"name":"TAXISCO","code":"GT-SR-TAX"},]
      },
      {
        name: 'Sololá',
        code: 'GT-SO',
        cities: [{"name":"CONCEPCION","code":"GT-SO-CCN"},
          {"name":"SAN LUCAS TOLIMÁN","code":"GT-SO-SLT"},
          {"name":"SANTIAGO ATITLÁN","code":"GT-SO-SNT"},
          {"name":"SOLOLA","code":"GT-SO-SOL"},]
      },
      {
        name: 'Suchitepéquez',
        code: 'GT-SU',
        cities: [{"name":"CHICACAO","code":"GT-SU-CHC"},
          {"name":"COCALES","code":"GT-SU-CLS"},
          {"name":"LA MAQUINA","code":"GT-SU-LA2"},
          {"name":"NAHUALATE","code":"GT-SU-NAH"},
          {"name":"PATULUL","code":"GT-SU-PAT"},
          {"name":"RÍO BRAVO","code":"GT-SU-RIO"},
          {"name":"SAN ANTONIO SUCHITEPÉQUEZ","code":"GT-SU-SAA"},
          {"name":"SANTA BÁRBARA","code":"GT-SU-SBA"},
          {"name":"SANTO DOMINGO SUCHITEPÉQUEZ","code":"GT-SU-SDS"},
          {"name":"SANTO TOMÁS LA UNIÓN","code":"GT-SU-STU"},]
      },
      {
        name: 'Totoonicapán',
        code: 'GT-TO',
        cities: [{"name":"TOTONICAPÁN","code":"GT-TO-TOT"},]
      },
      {
        name: 'Zacapa',
        code: 'GT-ZA',
        cities: [{"name":"ESTANZUELA","code":"GT-ZA-EST"},
          {"name":"SANTA CRUZ","code":"GT-ZA-SAC"},
          {"name":"SANTO TOMAS","code":"GT-ZA-STZ"},
          {"name":"USUMATLÁN","code":"GT-ZA-USU"},]
      },
    ]
  },
  {
    name: 'Honduras',
    code: 'HN',
    dialCode: '+504',
    flag: 'flag_honduras.png',
    states: [
      {
        name: 'Atlántida',
        code: 'HN-AT',
        cities: [{"name":"ARIZONA","code":"HN-AT-AZA"},
          {"name":"LA CEIBA","code":"HN-AT-TMP86927"},]
      },
      {
        name: 'Choluteca',
        code: 'HN-CH',
        cities: [{"name":"APACILAGUA","code":"HN-CH-AW4"},
          {"name":"CHOLUTECA","code":"HN-CH-TMP86923"},
          {"name":"EL PORVENIR","code":"HN-CH-ELR"},
          {"name":"MARCOVIA","code":"HN-CH-CTG"},
          {"name":"MONJARÁS","code":"HN-CH-MON"},
          {"name":"NAMASIGUE","code":"HN-CH-4NC"},
          {"name":"SAN JERÓNIMO","code":"HN-CH-SJR"},
          {"name":"SAN MARCOS DE COLÓN","code":"HN-CH-SMC"},
          {"name":"SANTA ANA DE YUSGUARE","code":"HN-CH-SDY"},]
      },
      {
        name: 'Colón',
        code: 'HN-CL',
        cities: [{"name":"ISLETA","code":"HN-CL-4IS"},
          {"name":"SONAGUERA","code":"HN-CL-SON"},]
      },
      {
        name: 'Comayagua',
        code: 'HN-CM',
        cities: [{"name":"OJOS DE AGUA","code":"HN-CM-ODA"},
          {"name":"PIMIENTA","code":"HN-CM-HAB"},
          {"name":"VALLE DE ÁNGELES","code":"HN-CM-VDA"},
          {"name":"VILLA DE SAN ANTONIO","code":"HN-CM-VAA"},
          {"name":"YARUMELA","code":"HN-CM-YAR"},]
      },
      {
        name: 'Copán',
        code: 'HN-CP',
        cities: [{"name":"AMATILLO","code":"HN-CP-HAD"},
          {"name":"BUENA VISTA","code":"HN-CP-BVA"},
          {"name":"LA ENTRADA","code":"HN-CP-LTD"},]
      },
      {
        name: 'Cortés',
        code: 'HN-CR',
        cities: [{"name":"BARACOA","code":"HN-CR-BRC"},
          {"name":"BÚFALO","code":"HN-CR-BUF"},
          {"name":"CARACOL","code":"HN-CR-HAN"},
          {"name":"CHAMELECÓN","code":"HN-CR-CHA"},
          {"name":"CHOLOMA","code":"HN-CR-TMP86943"},
          {"name":"COFRADIA","code":"HN-CR-CFR"},
          {"name":"CORINTO","code":"HN-CR-HAC"},
          {"name":"CUYAMEL","code":"HN-CR-CUL"},
          {"name":"EL CALÁN","code":"HN-CR-ELC"},
          {"name":"EL CHILE","code":"HN-CR-ECH"},
          {"name":"LA MASICA","code":"HN-CR-LMA"},
          {"name":"OMOA","code":"HN-CR-OMO"},
          {"name":"PIMIENTA","code":"HN-CR-PIM"},
          {"name":"POTRERILLOS","code":"HN-CR-PTC"},
          {"name":"PUERTO CORTÉS","code":"HN-CR-TMP86925"},
          {"name":"RIO BLANQUITO","code":"HN-CR-HAH"},
          {"name":"RÍO NANCE","code":"HN-CR-RIO"},
          {"name":"SAN FRANCISCO DE YOJOA","code":"HN-CR-SFY"},
          {"name":"SAN MANUEL","code":"HN-CR-SMH"},
          {"name":"SAN PEDRO SULA","code":"HN-CR-TMP86950"},
          {"name":"SANTA CRUZ DE YOJOA","code":"HN-CR-HAI"},
          {"name":"VILLANUEVA","code":"HN-CR-VNA"},]
      },
      {
        name: 'El Paraíso',
        code: 'HN-EP',
        cities: [{"name":"EL PARAISO","code":"HN-EP-EPO"},
          {"name":"LA PAZ","code":"HN-EP-HAE"},
          {"name":"VALLE DE JAMASTRÁN","code":"HN-EP-VDJ"},]
      },
      {
        name: 'Francisco Morazán',
        code: 'HN-FM',
        cities: [{"name":"COMAYAGUELA","code":"HN-FM-TMP86956"},
          {"name":"EL TIZATILLO","code":"HN-FM-RIZ"},
          {"name":"EL ZAMORANO","code":"HN-FM-HAM"},
          {"name":"SABANAGRANDE","code":"HN-FM-SGD"},
          {"name":"SAN BUENAVENTURA","code":"HN-FM-VBS"},
          {"name":"SAN IGNACIO","code":"HN-FM-HAL"},
          {"name":"SAN JUAN DE FLORES","code":"HN-FM-XZQ"},
          {"name":"SANTA LUCÍA","code":"HN-FM-SLA"},
          {"name":"TAMARA","code":"HN-FM-HAK"},
          {"name":"TEGUCIGALPA","code":"HN-FM-TMP86962"},
          {"name":"VALLE DEL ZAMORANO","code":"HN-FM-VDZ"},
          {"name":"ZAMBRANO","code":"HN-FM-ZBN"},]
      },
      {
        name: 'Gracias a Dios',
        code: 'HN-GD',
        cities: [{"name":"AUAS","code":"HN-GD-AHS"},]
      },
      {
        name: 'Intibucá',
        code: 'HN-IN',
        cities: [{"name":"CONCORDIA","code":"HN-IN-COR"},
          {"name":"INTIBUCÁ","code":"HN-IN-ITB"},
          {"name":"JESUS DE OTORO","code":"HN-IN-JDO"},]
      },
      {
        name: 'Islas de la Bahía',
        code: 'HN-IB',
        cities: []
      },
      {
        name: 'La Paz',
        code: 'HN-LP',
        cities: [{"name":"CHICHICASTE","code":"HN-LP-CHI"},]
      },
      {
        name: 'Lempira',
        code: 'HN-LE',
        cities: [{"name":"SANTA RITA","code":"HN-LE-SRI"},]
      },
      {
        name: 'Ocotepeque',
        code: 'HN-OC',
        cities: [{"name":"EL POY","code":"HN-OC-EPY"},
          {"name":"NUEVA OCOTEPEQUE","code":"HN-OC-NOC"},
          {"name":"OCOTEPEQUE","code":"HN-OC-HAF"},]
      },
      {
        name: 'Olancho',
        code: 'HN-OL',
        cities: [{"name":"COFRADÍA","code":"HN-OL-COF"},]
      },
      {
        name: 'Santa Bárbara',
        code: 'HN-SB',
        cities: [{"name":"CHUMBAGUA","code":"HN-SB-CHU"},
          {"name":"EL MOCHITO","code":"HN-SB-EMO"},
          {"name":"LA FLECHA","code":"HN-SB-HAA"},
          {"name":"LAS VEGAS","code":"HN-SB-LVS"},
          {"name":"NACO","code":"HN-SB-NAC"},
          {"name":"PINALEJO","code":"HN-SB-PAJ"},
          {"name":"QUIMISTAN","code":"HN-SB-HAG"},
          {"name":"SAN MARCOS","code":"HN-SB-SMA"},
          {"name":"SULA","code":"HN-SB-HAJ"},
          {"name":"TRINIDAD","code":"HN-SB-HAO"},
          {"name":"ZAMBRANO","code":"HN-SB-ZMR"},]
      },
      {
        name: 'Valle',
        code: 'HN-VA',
        cities: [{"name":"NACAOME","code":"HN-VA-NA6"},
          {"name":"PASO DE VELAS","code":"HN-VA-PDV"},]
      },
      {
        name: 'Yoro',
        code: 'HN-YO',
        cities: [{"name":"CUYAMAPA","code":"HN-YO-CUY"},
          {"name":"EL NEGRITO","code":"HN-YO-ENO"},
          {"name":"EL OCOTILLO","code":"HN-YO-OCO"},
          {"name":"EL PROGRESO","code":"HN-YO-PRO"},
          {"name":"SANTA RITA","code":"HN-YO-SAN"},
          {"name":"YORO","code":"HN-YO-YOR"},]
      },
    ]
  },
  {
    name: 'Islas Turcas',
    code: 'TC',
    dialCode: '+1-649',
    flag: 'flag_turks_islands.png',
    states: [
      {
        name: 'Grand Turk',
        code: 'TC-01',
        cities: []
      }
    ]
  },
  {
    name: 'Jamaica',
    code: 'JM',
    dialCode: '+1-876',
    flag: 'flag_jamaica.png',
    states: [
      {
        name: 'Clarendon',
        code: 'JM-13',
        cities: [{"name":"FRANKFIELD","code":"JM-13-FRF"},
          {"name":"HALSE HALL","code":"JM-13-HAL"},
          {"name":"MONYMUSK","code":"JM-13-8MM"},
          {"name":"NEW YARMOUTH","code":"JM-13-NYM"},]
      },
      {
        name: 'Hanover',
        code: 'JM-09',
        cities: []
      },
      {
        name: 'Kingston',
        code: 'JM-01',
        cities: [{"name":"KINGSTON","code":"JM-01-TMP108471"},
          {"name":"KINGSTON TINSON AIRPORT","code":"JM-01-KTP"},
          {"name":"VINEYARD","code":"JM-01-VIN"},]
      },
      {
        name: 'Manchester',
        code: 'JM-12',
        cities: [{"name":"CHRISTIANA","code":"JM-12-CHA"},
          {"name":"KNOCKPATRICK","code":"JM-12-KNK"},
          {"name":"NEWPORT","code":"JM-12-NET"},]
      },
      {
        name: 'Portland',
        code: 'JM-04',
        cities: [{"name":"CHARLES TOWN","code":"JM-04-CST"},
          {"name":"FAIRY HILL","code":"JM-04-FHP"},]
      },
      {
        name: 'Saint Andrew',
        code: 'JM-02',
        cities: [{"name":"CROSS ROADS","code":"JM-02-TMP108483"},
          {"name":"RED HILLS","code":"JM-02-TMP108509"},
          {"name":"SWALLOWFIELD","code":"JM-02-TMP108511"},]
      },
      {
        name: 'Saint Ann',
        code: 'JM-06',
        cities: [{"name":"BROWNS TOWN","code":"JM-06-BRN"},
          {"name":"DISCOVERY BAY","code":"JM-06-DIY"},
          {"name":"MONEAGUE","code":"JM-06-MNG"},
          {"name":"SAINT ANN'S BAY","code":"JM-06-SAW"},]
      },
      {
        name: 'Saint Catherine',
        code: 'JM-14',
        cities: [{"name":"EWARTON","code":"JM-14-EWN"},
          {"name":"LINSTEAD","code":"JM-14-LID"},
          {"name":"OLD HARBOUR","code":"JM-14-OLR"},
          {"name":"PORT HENDERSON","code":"JM-14-PHE"},
          {"name":"PORTMORE","code":"JM-14-TMP108523"},
          {"name":"SPANISH TOWN","code":"JM-14-TMP108495"},
          {"name":"WORTHY PARK","code":"JM-14-WPK"},]
      },
      {
        name: 'Saint Elizabeth',
        code: 'JM-11',
        cities: [{"name":"ROSE HALL","code":"JM-11-ROH"},
          {"name":"SANTA CRUZ","code":"JM-11-STC"},]
      },
      {
        name: 'Saint James',
        code: 'JM-08',
        cities: []
      },
      {
        name: 'Saint Mary',
        code: 'JM-05',
        cities: [{"name":"HIGHGATE","code":"JM-05-HIE"},
          {"name":"PORT MARIA","code":"JM-05-POA"},]
      },
      {
        name: 'Saint Thomas',
        code: 'JM-03',
        cities: [{"name":"MORANT BAY","code":"JM-03-MOB"},
          {"name":"SERGE ISLAND","code":"JM-03-SED"},
          {"name":"YALLAHS","code":"JM-03-YAS"},]
      },
      {
        name: 'Trelawny',
        code: 'JM-07',
        cities: [{"name":"HAMPDEN","code":"JM-07-HMP"},
          {"name":"TRELAWNY","code":"JM-07-TR9"},
          {"name":"WAKEFIELD","code":"JM-07-WKF"},]
      },
      {
        name: 'Westmoreland',
        code: 'JM-10',
        cities: []
      }
    ]
  },
  {
    name: 'Martinique',
    code: 'MQ',
    dialCode: '+596',
    flag: 'flag_martinique.png',
    states: [
      {
        name: 'Fort-de-France',
        code: 'MQ-01',
        cities: []
      }
    ]
  },
  {
    name: 'México',
    code: 'MX',
    dialCode: '+52',
    flag: 'flag_mexico.png',
    states: [
      {
        name: 'Aguascalientes',
        code: 'MX-AGU',
        cities: [
          {"name":"AGUASCALIENTES","code":"MX-AGU-AGU"},
          {"name":"ARELLANO","code":"MX-AGU-TMP114917"},
          {"name":"CIÉNEGAS","code":"MX-AGU-CNS"},
          {"name":"JESUS MARIA","code":"MX-AGU-JES"},
          {"name":"LA TROJE","code":"MX-AGU-LTJ"},
          {"name":"RINCON DE ROMOS","code":"MX-AGU-RDR"},
          {"name":"SAN FRANCISCO DE LOS ROMOS","code":"MX-AGU-SFS"},
        ]
      },
      {
        name: 'Baja California',
        code: 'MX-BCN',
        cities: [
          {"name":"AGUA DULCE","code":"MX-BCN-AGD"},
          {"name":"BAHIA DE LOS ANGELES","code":"MX-BCN-BAA"},
          {"name":"CATAVIÑA","code":"MX-BCN-CTA"},
          {"name":"CEDROS","code":"MX-BCN-CED"},
          {"name":"CEDROS ISLAND","code":"MX-BCN-CRI"},
          {"name":"DISTRITO FEDERAL","code":"MX-BCN-DFL"},
          {"name":"EL ROSARIO","code":"MX-BCN-LRS"},
          {"name":"EL SAUZAL","code":"MX-BCN-ESZ"},
          {"name":"ENSENADA","code":"MX-BCN-ESE"},
          {"name":"FRANCISCO ZARCO","code":"MX-BCN-FRZ"},
          {"name":"ISLA CEDROS","code":"MX-BCN-ISD"},
          {"name":"LERMA","code":"MX-BCN-LER"},
          {"name":"MEXICALI","code":"MX-BCN-MXL"},
          {"name":"MEXICALI","code":"MX-BCN-VDM"},
          {"name":"PUEBLA","code":"MX-BCN-K6R"},
          {"name":"PUNTA BAJA","code":"MX-BCN-PBJ"},
          {"name":"PUNTA COLONET","code":"MX-BCN-PUC"},
          {"name":"ROSARITO","code":"MX-BCN-ZRO"},
          {"name":"ROSARITO TERMINAL","code":"MX-BCN-RST"},
          {"name":"SAN JOSÉ DE CASTILLO","code":"MX-BCN-SJS"},
          {"name":"TECATE","code":"MX-BCN-TCT"},
          {"name":"TIJUANA","code":"MX-BCN-TIJ"},
          {"name":"URUAPAN","code":"MX-BCN-2NB"},
        ]
      },
      {
        name: 'Baja California Sur',
        code: 'MX-BCS',
        cities: [
          {"name":"CABO SAN LUCAS","code":"MX-BCS-CSL"},
          {"name":"CIUDAD CONSTITUCIÓN","code":"MX-BCS-CUA"},
          {"name":"EL VIZCAINO","code":"MX-BCS-EVI"},
          {"name":"ISLA SAN MARCOS","code":"MX-BCS-SMI"},
          {"name":"LA PAZ","code":"MX-BCS-LAP"},
          {"name":"LORETO","code":"MX-BCS-LRO"},
          {"name":"LOS CABOS","code":"MX-BCS-LCB"},
          {"name":"MULEGÉ","code":"MX-BCS-MUG"},
          {"name":"PICHILINGUE","code":"MX-BCS-PIC"},
          {"name":"PUERTO ESCONDIDO","code":"MX-BCS-PES"},
          {"name":"SAN CARLOS","code":"MX-BCS-SCR"},
          {"name":"SAN COSME","code":"MX-BCS-SCM"},
          {"name":"SAN FELIPE","code":"MX-BCS-SFH"},
          {"name":"SAN JOSE DEL CABO","code":"MX-BCS-ZMI"},
          {"name":"SANTA ROSALIA","code":"MX-BCS-SRL"},
          {"name":"SANTO TOMAS","code":"MX-BCS-ZMA"},
        ]
      },
      {
        name: 'Campeche',
        code: 'MX-CAM',
        cities: [
          {"name":"CALKINI","code":"MX-CAM-CLK"},
          {"name":"CAMPECHE","code":"MX-CAM-CPE"},
          {"name":"CHAMPOTON","code":"MX-CAM-CPM"},
          {"name":"CHINA","code":"MX-CAM-CHN"},
          {"name":"CIUDAD DEL CARMEN","code":"MX-CAM-CME"},
          {"name":"ESCARCEGA","code":"MX-CAM-ECC"},
          {"name":"ESTADO DE MEXICO","code":"MX-CAM-EDM"},
          {"name":"HIDALGO","code":"MX-CAM-HDG"},
          {"name":"HOPELCHEN","code":"MX-CAM-HOP"},
          {"name":"SABANCUY","code":"MX-CAM-SAB"},
          {"name":"SEYBAPLAYA","code":"MX-CAM-SEY"},
          {"name":"TENABÓ","code":"MX-CAM-TEN"},
        ]
      },
      {
        name: 'Chiapas',
        code: 'MX-CHP',
        cities: [
          {"name":"ANGEL ALBINO CORZO","code":"MX-CHP-AAC"},
          {"name":"ARRIAGA","code":"MX-CHP-ARR"},
          {"name":"BENEMERITO DE LAS AMERICAS","code":"MX-CHP-BDA"},
          {"name":"BENITO JUAREZ","code":"MX-CHP-TMP115026"},
          {"name":"CHIAPA DE CORZO","code":"MX-CHP-CCZ"},
          {"name":"CHIMALAPA","code":"MX-CHP-CML"},
          {"name":"CIUDAD CUAUTHEMOC","code":"MX-CHP-CCU"},
          {"name":"CIUDAD HIDALGO","code":"MX-CHP-HID"},
          {"name":"COPAINALA","code":"MX-CHP-COP"},
          {"name":"FRONTERA COMALAPA","code":"MX-CHP-FRC"},
          {"name":"LA TRINITARIA","code":"MX-CHP-TRI"},
          {"name":"MAPASTEPEC","code":"MX-CHP-MPP"},
          {"name":"MONTERREY","code":"MX-CHP-TTE"},
          {"name":"NUEVO LEÓN","code":"MX-CHP-NLN"},
          {"name":"OCOSINGO","code":"MX-CHP-OCS"},
          {"name":"OCOZOCUAUTLA","code":"MX-CHP-OZA"},
          {"name":"OSTUACÁN","code":"MX-CHP-OT2"},
          {"name":"PICHUCALCO","code":"MX-CHP-PU2"},
          {"name":"PIJIJIAPAN","code":"MX-CHP-PIJ"},
          {"name":"PUERTO MADERO","code":"MX-CHP-PMD"},
          {"name":"PUERTO RICO","code":"MX-CHP-PRC"},
          {"name":"SIMOJOVEL DE ALLENDE","code":"MX-CHP-SIJ"},
          {"name":"SOCONUSCO","code":"MX-CHP-SOC"},
          {"name":"SUCHIATE","code":"MX-CHP-SCH"},
          {"name":"TAPACHULA","code":"MX-CHP-TAP"},
          {"name":"TAPACHULAS","code":"MX-CHP-TCH"},
          {"name":"TONALA","code":"MX-CHP-TNL"},
          {"name":"TUXTLA GUTIÉRREZ","code":"MX-CHP-TGZ"},
          {"name":"VILLA FLORES","code":"MX-CHP-VIF"},
        ]
      },
      {
        name: 'Chihuahua',
        code: 'MX-CHH',
        cities: [
          {"name":"AHUMADA","code":"MX-CHH-AHU"},
          {"name":"ANAHUAC","code":"MX-CHH-ANA"},
          {"name":"ASCENCION","code":"MX-CHH-ACC"},
          {"name":"BACHÍNIVA","code":"MX-CHH-BAC"},
          {"name":"BENITO JUAREZ","code":"MX-CHH-BEJ"},
          {"name":"BUENAVENTURA","code":"MX-CHH-BUE"},
          {"name":"CAMARGO","code":"MX-CHH-AAO"},
          {"name":"CHIHUAHUA","code":"MX-CHH-CUU"},
          {"name":"CHIHUAHUA CITY","code":"MX-CHH-TMP115144"},
          {"name":"CIUDAD CAMARGO","code":"MX-CHH-CCG"},
          {"name":"CIUDAD JUÁREZ","code":"MX-CHH-CJS"},
          {"name":"DELICIAS","code":"MX-CHH-DCS"},
          {"name":"EL SAUCITO","code":"MX-CHH-ESA"},
          {"name":"GUAZAPARES","code":"MX-CHH-GZP"},
          {"name":"HIDALGO DEL PARRAL","code":"MX-CHH-HDP"},
          {"name":"JANOS","code":"MX-CHH-TMP115162"},
          {"name":"JIMÉNEZ","code":"MX-CHH-JMN"},
          {"name":"JUAREZ","code":"MX-CHH-JUZ"},
          {"name":"MADERA","code":"MX-CHH-MAD"},
          {"name":"MEOQUI","code":"MX-CHH-MQI"},
          {"name":"MORIS","code":"MX-CHH-MRS"},
          {"name":"NAMIQUIPA","code":"MX-CHH-TMP115173"},
          {"name":"OJINAGA","code":"MX-CHH-OJI"},
          {"name":"PLOMOSAS","code":"MX-CHH-PLO"},
          {"name":"RIO DE OCAMPO","code":"MX-CHH-RDO"},
          {"name":"SAMALAYUCA","code":"MX-CHH-SYU"},
          {"name":"SAN JERONIMO","code":"MX-CHH-SJH"},
          {"name":"SAN PEDRO","code":"MX-CHH-ZML"},
          {"name":"SAUCILLO","code":"MX-CHH-ZSO"},
          {"name":"TEPEHUANES","code":"MX-CHH-TPS"},
          {"name":"TORREÓN DE ARRIBA","code":"MX-CHH-TOR"},
        ]
      },
      {
        name: 'Ciudad de México',
        code: 'MX-CMX',
        cities: [
          {"name":"ALVARO OBREGON","code":"MX-CMX-TMP116532"},
          {"name":"AZCAPOTZALCO","code":"MX-CMX-APZ"},
          {"name":"BENITO JUAREZ","code":"MX-CMX-TMP116544"},
          {"name":"CIUDAD DE MÉXICO","code":"MX-CMX-MEX"},
          {"name":"COYOACÁN","code":"MX-CMX-TMP116556"},
          {"name":"CUAJIMALPA","code":"MX-CMX-TMP116557"},
          {"name":"CUAUHTEMOC","code":"MX-CMX-TMP116558"},
          {"name":"IZTAPALAPA","code":"MX-CMX-TMP116585"},
          {"name":"MEXICO CITY","code":"MX-CMX-TMP116608"},
          {"name":"MÉXICO DISTRITO FEDERAL","code":"MX-CMX-TMP115289"},
          {"name":"MIGUEL HIDALGO","code":"MX-CMX-TMP116610"},
          {"name":"SANTA FE","code":"MX-CMX-TMP116674"},
          {"name":"TLALPAM","code":"MX-CMX-TMP115294"},
          {"name":"TLALPAN","code":"MX-CMX-TMP116695"},
          {"name":"VILLAHERMOSA","code":"MX-CMX-TMP116708"},
        ]
      },
      {
        name: 'Coahuila',
        code: 'MX-COA',
        cities: [
          {"name":"ARTEAGA","code":"MX-COA-AHO"},
          {"name":"AVANTE","code":"MX-COA-AVA"},
          {"name":"CIUDAD LERDO","code":"MX-COA-LED"},
          {"name":"CUATROCIENEGAS DE CARRANZA","code":"MX-COA-CTG"},
          {"name":"EL PASO","code":"MX-COA-ELP"},
          {"name":"ESCOBEDO","code":"MX-COA-ECB"},
          {"name":"FRONTERA","code":"MX-COA-FTR"},
          {"name":"GENERAL CEPEDA","code":"MX-COA-GEN"},
          {"name":"LAGUNA DEL REY","code":"MX-COA-LDR"},
          {"name":"MATAMOROS","code":"MX-COA-MMA"},
          {"name":"MELCHOR MUZQUIZ","code":"MX-COA-MEM"},
          {"name":"MONCLOVA","code":"MX-COA-LOV"},
          {"name":"NAVA","code":"MX-COA-NVA"},
          {"name":"NUEVA ROSITA","code":"MX-COA-NUR"},
          {"name":"PARRAS DE LA FUENTE","code":"MX-COA-PRS"},
          {"name":"PIEDRAS NEGRAS","code":"MX-COA-PIE"},
          {"name":"RAMOS ARIZPE","code":"MX-COA-RMA"},
          {"name":"SABINAS","code":"MX-COA-SBN"},
          {"name":"SALTILLO","code":"MX-COA-SLW"},
          {"name":"SAN BUENAVENTURA","code":"MX-COA-SBA"},
          {"name":"SAN JUAN DE SABINAS","code":"MX-COA-DSS"},
          {"name":"SAN PEDRO","code":"MX-COA-SPD"},
          {"name":"SIERRA MOJADA","code":"MX-COA-SIE"},
          {"name":"TORREON","code":"MX-COA-TRC"},
          {"name":"VIESCA","code":"MX-COA-VIE"},
          {"name":"VIZCAYA","code":"MX-COA-OAY"},
        ]
      },
      {
        name: 'Colima',
        code: 'MX-COL',
        cities: [
          {"name":"CERRO DE ORTEGA","code":"MX-COL-COO"},
          {"name":"COLIMA","code":"MX-COL-COL"},
          {"name":"MANZANILLO","code":"MX-COL-ZLO"},
          {"name":"SAN FRANCISCO JAVIER","code":"MX-COL-SFJ"},
          {"name":"TECOMÁN","code":"MX-COL-TCM"},
        ]
      },
      {
        name: 'Durango',
        code: 'MX-DUR',
        cities: [
          {"name":"ATOTONILCO","code":"MX-DUR-ATT"},
          {"name":"CANATLAN","code":"MX-DUR-CAN"},
          {"name":"CUENCAME","code":"MX-DUR-CUE"},
          {"name":"DINAMITA","code":"MX-DUR-DIN"},
          {"name":"DURANGO","code":"MX-DUR-DGO"},
          {"name":"FRANCISCO I. MADERO","code":"MX-DUR-FIM"},
          {"name":"GÓMEZ PALACIO","code":"MX-DUR-GMP"},
          {"name":"GUADALUPE VICTORIA","code":"MX-DUR-GVI"},
          {"name":"GUANACEVI","code":"MX-DUR-GUV"},
          {"name":"JALISCO","code":"MX-DUR-JLC"},
          {"name":"RODEO","code":"MX-DUR-ROD"},
          {"name":"SAN FRANCISCO DE ASIS","code":"MX-DUR-SFA"},
          {"name":"SANTIAGO PAPASQUIARO","code":"MX-DUR-SAP"},
          {"name":"VALLE HERMOSO","code":"MX-DUR-VHM"},
        ]
      },
      {
        name: 'Guanajuato',
        code: 'MX-GUA',
        cities: [
          {"name":"ABASOLO","code":"MX-GUA-ASO"},
          {"name":"ACAMBARO","code":"MX-GUA-ACB"},
          {"name":"APASEO EL ALTO","code":"MX-GUA-AEA"},
          {"name":"APASEO EL GRANDE","code":"MX-GUA-APA"},
          {"name":"CASACUARÁN","code":"MX-GUA-TMP115822"},
          {"name":"CELAYA","code":"MX-GUA-CLY"},
          {"name":"COMONFORT","code":"MX-GUA-CON"},
          {"name":"CORONEO","code":"MX-GUA-COE"},
          {"name":"CORTAZAR","code":"MX-GUA-CTZ"},
          {"name":"DOLORES HIDALGO","code":"MX-GUA-DHD"},
          {"name":"GRANJAS","code":"MX-GUA-GRA"},
          {"name":"GUANAJUATO","code":"MX-GUA-GUA"},
          {"name":"IRAPUATO","code":"MX-GUA-IRP"},
          {"name":"JARAL DEL PROGRESO","code":"MX-GUA-JDP"},
          {"name":"LA ESTANCIA","code":"MX-GUA-LAE"},
          {"name":"LA PURÍSIMA","code":"MX-GUA-LPU"},
          {"name":"LÉON","code":"MX-GUA-LEN"},
          {"name":"LEÓN DE LOS ALDAMAS","code":"MX-GUA-TMP115877"},
          {"name":"MANUEL DOBLADO","code":"MX-GUA-MDB"},
          {"name":"MOROLEÓN","code":"MX-GUA-MOR"},
          {"name":"NUMARÁN","code":"MX-GUA-NUM"},
          {"name":"OBRAJUELO","code":"MX-GUA-OBJ"},
          {"name":"PENJAMO","code":"MX-GUA-PJM"},
          {"name":"PUERTO INTERIOR LEON","code":"MX-GUA-9PI"},
          {"name":"PURISIMA DE BUSTOS","code":"MX-GUA-PDB"},
          {"name":"PURISIMA DEL RINCON","code":"MX-GUA-PDR"},
          {"name":"PURÍSIMA DEL RINCÓN","code":"MX-GUA-PMR"},
          {"name":"ROMITA","code":"MX-GUA-RMT"},
          {"name":"SALAMANCA","code":"MX-GUA-SMC"},
          {"name":"SALVATIERRA","code":"MX-GUA-SAL"},
          {"name":"SAN DIEGO DE LA UNIÓN","code":"MX-GUA-SDU"},
          {"name":"SAN FRANCISCO DEL RINCON","code":"MX-GUA-SFR"},
          {"name":"SAN JOSÉ ITURBIDE","code":"MX-GUA-SJI"},
          {"name":"SAN LUIS DE LA PAZ","code":"MX-GUA-SLU"},
          {"name":"SAN MIGUEL DE ALLENDE","code":"MX-GUA-SMG"},
          {"name":"SANTA ROSA DE LIMA","code":"MX-GUA-STR"},
          {"name":"SILAO","code":"MX-GUA-SIL"},
          {"name":"URIANGATO","code":"MX-GUA-URI"},
          {"name":"VALLE DE SANTIAGO","code":"MX-GUA-VDS"},
          {"name":"VILLAGRAN","code":"MX-GUA-VGN"},
          {"name":"YURIRIA","code":"MX-GUA-YUR"},
        ]
      },
      {
        name: 'Guerrero',
        code: 'MX-GRO',
        cities: [
          {"name":"ACAPULCO","code":"MX-GRO-ACA"},
          {"name":"ARCELIA","code":"MX-GRO-ARC"},
          {"name":"AYUTLA DE LOS LIBRES","code":"MX-GRO-AYL"},
          {"name":"CHILPANCINGO","code":"MX-GRO-CHI"},
          {"name":"CIUDAD ALTAMIRANO","code":"MX-GRO-CIA"},
          {"name":"CUAJINICUILAPA","code":"MX-GRO-CJI"},
          {"name":"CUTZAMALÁ DE PINZÓN","code":"MX-GRO-TMP116002"},
          {"name":"EL COACOYUL","code":"MX-GRO-ELC"},
          {"name":"EL NARANJILLO","code":"MX-GRO-ELN"},
          {"name":"IGUALA","code":"MX-GRO-IGU"},
          {"name":"IGUALA DE LA INDEPENDENCIA","code":"MX-GRO-IIG"},
          {"name":"IXTAPA/ZIHUATANEJO","code":"MX-GRO-ZIH"},
          {"name":"MEXICO","code":"MX-GRO-TMP116024"},
          {"name":"OMETEPEC","code":"MX-GRO-OME"},
          {"name":"PAPALOTEPEC","code":"MX-GRO-EDN"},
          {"name":"POCHUTLA","code":"MX-GRO-PCA"},
          {"name":"SAN JOSÉ VISTA HERMOSA","code":"MX-GRO-GUE"},
          {"name":"TAXCO DE ALARCÓN","code":"MX-GRO-TXA"},
          {"name":"TECPAN DE GELEANA","code":"MX-GRO-TDG"},
          {"name":"TELOLOAPAN","code":"MX-GRO-TEL"},
          {"name":"TILZAPOTLA","code":"MX-GRO-TLZ"},
          {"name":"TIXTLA","code":"MX-GRO-TIX"},
          {"name":"TLAXMALAC","code":"MX-GRO-TLX"},
          {"name":"ZIHUATANEJO","code":"MX-GRO-XIH"},
          {"name":"ZUMPANGO DEL RIO","code":"MX-GRO-ZDR"},
        ]
      },
      {
        name: 'Hidalgo',
        code: 'MX-HID',
        cities: [
          {"name":"ACAXOCHITLÁN","code":"MX-HID-ACX"},
          {"name":"ACOCULCO","code":"MX-HID-TMP116076"},
          {"name":"ACTOPAN","code":"MX-HID-JCP"},
          {"name":"AHUAZOTEPEC","code":"MX-HID-AXT"},
          {"name":"ALFAJAYUCAN","code":"MX-HID-ALF"},
          {"name":"APAN","code":"MX-HID-APN"},
          {"name":"ATITALAQUIA","code":"MX-HID-ATI"},
          {"name":"ATOTONILCO DE TULA","code":"MX-HID-ALT"},
          {"name":"CIUDAD SAHAGÚN","code":"MX-HID-CSG"},
          {"name":"CUAUTEPEC DE HINOJOSA","code":"MX-HID-CDH"},
          {"name":"HUEHUETOCA","code":"MX-HID-HHA"},
          {"name":"HUEJUTLA DE REYES","code":"MX-HID-HUJ"},
          {"name":"HUICHAPAN","code":"MX-HID-HPA"},
          {"name":"IXMIQUILPAN","code":"MX-HID-IXM"},
          {"name":"LOLOTLA","code":"MX-HID-LOL"},
          {"name":"MINERAL DE LA REFORMA","code":"MX-HID-MDR"},
          {"name":"MIXQUIAHUALA","code":"MX-HID-MXQ"},
          {"name":"MOLANGO","code":"MX-HID-MOL"},
          {"name":"NÁPOLES","code":"MX-HID-NOL"},
          {"name":"NONOALCO","code":"MX-HID-NNA"},
          {"name":"NOPALA","code":"MX-HID-NOP"},
          {"name":"PLOMOSAS","code":"MX-HID-PLM"},
          {"name":"PROGRESO DE OBREGÓN","code":"MX-HID-PDO"},
          {"name":"SAN FELIPE ORIZATLAN","code":"MX-HID-SFO"},
          {"name":"SAN JUAN SOLIS","code":"MX-HID-SAS"},
          {"name":"SANTIAGO TULANTEPEC","code":"MX-HID-STU"},
          {"name":"SONORA","code":"MX-HID-SNR"},
          {"name":"TEPEAPULCO","code":"MX-HID-HTP"},
          {"name":"TEPEJI DE OCAMPO","code":"MX-HID-TPR"},
          {"name":"TEPEJI DEL RIO","code":"MX-HID-TR2"},
          {"name":"TEZONTEPEC DE ALDAMA","code":"MX-HID-TEA"},
          {"name":"TIZAYUCA","code":"MX-HID-TIZ"},
          {"name":"TLAUTLA","code":"MX-HID-TLU"},
          {"name":"TOLCAYUCA","code":"MX-HID-TLY"},
          {"name":"TULA DE ALLENDE","code":"MX-HID-TUL"},
          {"name":"TULANCINGO","code":"MX-HID-TLI"},
          {"name":"ZACUALTIPAN","code":"MX-HID-ZTN"},
          {"name":"ZAPOTLÁN DE JUÁREZ","code":"MX-HID-TMP116223"},
          {"name":"ZEMPOALA","code":"MX-HID-XEM"},
          {"name":"ZIMAPÁN","code":"MX-HID-ZPM"},
        ]
      },
      {
        name: 'Jalisco',
        code: 'MX-JAL',
        cities: [
          {"name":"ACATIC","code":"MX-JAL-ACT"},
          {"name":"ACATLÁN DE JUÁREZ","code":"MX-JAL-EPZ"},
          {"name":"AMATITÁN","code":"MX-JAL-ATN"},
          {"name":"AMECA","code":"MX-JAL-AME"},
          {"name":"ANTONIO ESCOBEDO","code":"MX-JAL-AOE"},
          {"name":"ARANDAS","code":"MX-JAL-ARA"},
          {"name":"ATEQUIZA","code":"MX-JAL-ATE"},
          {"name":"ATOTONILCO","code":"MX-JAL-TMP116240"},
          {"name":"ATOTONILCO EL ALTO","code":"MX-JAL-ATO"},
          {"name":"ATOTONILQUILLO","code":"MX-JAL-AQL"},
          {"name":"ATOYAC","code":"MX-JAL-AYC"},
          {"name":"AUTLAN","code":"MX-JAL-AUT"},
          {"name":"AYOTLAN","code":"MX-JAL-AYO"},
          {"name":"AYUTLA","code":"MX-JAL-AYU"},
          {"name":"BARRA DE NAVIDAD","code":"MX-JAL-BDN"},
          {"name":"BUGAMBILIAS","code":"MX-JAL-TMP116252"},
          {"name":"CANADAS DE OBREGÓN","code":"MX-JAL-COB"},
          {"name":"CAPILLA DE GUADALUPE","code":"MX-JAL-CDG"},
          {"name":"CASIMIRO CASTILLO","code":"MX-JAL-CAS"},
          {"name":"CHAMELA","code":"MX-JAL-CHM"},
          {"name":"CHAPALA","code":"MX-JAL-CPL"},
          {"name":"CIHUATLÁN","code":"MX-JAL-CHU"},
          {"name":"CIUDAD DEL SOL","code":"MX-JAL-CDS"},
          {"name":"CIUDAD GUZMÁN","code":"MX-JAL-GUZ"},
          {"name":"CUAUTLA","code":"MX-JAL-CTL"},
          {"name":"CUQUIO","code":"MX-JAL-CQO"},
          {"name":"DEGOLLADO","code":"MX-JAL-DEG"},
          {"name":"EL ARENAL","code":"MX-JAL-EAJ"},
          {"name":"EL SALTO","code":"MX-JAL-ESO"},
          {"name":"GÓMEZ FARÍAS","code":"MX-JAL-GMF"},
          {"name":"GUADALAJARA","code":"MX-JAL-GDL"},
          {"name":"IXTLAHUACAN DE LOS MEMBRILLOS","code":"MX-JAL-JLM"},
          {"name":"JACONA","code":"MX-JAL-JCN"},
          {"name":"JALISCO DEL REFUGIO","code":"MX-JAL-JDR"},
          {"name":"JALOSTOTITLAN","code":"MX-JAL-JAO"},
          {"name":"JESÚS MARÍA","code":"MX-JAL-JSM"},
          {"name":"JILOTLAN DE LOS DOLORES","code":"MX-JAL-JDD"},
          {"name":"JOCOTEPEC","code":"MX-JAL-PCJ"},
          {"name":"JUANACATLÁN","code":"MX-JAL-JUL"},
          {"name":"LA BARCA","code":"MX-JAL-LBR"},
          {"name":"LA COFRADÍA","code":"MX-JAL-COF"},
          {"name":"MAGDALENA","code":"MX-JAL-MDA"},
          {"name":"MAGDALENA/HOSTOTIPAQUILLO","code":"MX-JAL-MHT"},
          {"name":"MASCOTA","code":"MX-JAL-MCA"},
          {"name":"MATATLÁN","code":"MX-JAL-M6N"},
          {"name":"MAZAMITLA","code":"MX-JAL-MAZ"},
          {"name":"OCOTLAN","code":"MX-JAL-OCO"},
          {"name":"OJUELOS","code":"MX-JAL-OJU"},
          {"name":"PERULA","code":"MX-JAL-PER"},
          {"name":"PONCITLAN","code":"MX-JAL-PTN"},
          {"name":"PUERTO VALLARTA","code":"MX-JAL-PVR"},
          {"name":"SAN CRISTOBAL DE LA BARRANCA","code":"MX-JAL-SCB"},
          {"name":"SAN GABRIEL","code":"MX-JAL-SGL"},
          {"name":"SAN ISIDRO MAZATEPEC","code":"MX-JAL-T8Z"},
          {"name":"SAN JOSÉ DEL CASTILLO","code":"MX-JAL-SCO"},
          {"name":"SAN JUAN DE LOS LAGOS","code":"MX-JAL-SJL"},
          {"name":"SAN JUANITO ESCOBEDO","code":"MX-JAL-SAJ"},
          {"name":"SAN MIGUEL EL ALTO","code":"MX-JAL-SME"},
          {"name":"SANTA CRUZ DE LAS FLORES","code":"MX-JAL-SCF"},
          {"name":"SANTA MARÍA TEQUEPEXPAN","code":"MX-JAL-SMQ"},
          {"name":"SAYULA","code":"MX-JAL-9SY"},
          {"name":"TALA","code":"MX-JAL-TAL"},
          {"name":"TAMAZULA","code":"MX-JAL-TMZ"},
          {"name":"TECALITLÁN","code":"MX-JAL-TEI"},
          {"name":"TECOLOTLAN","code":"MX-JAL-TEC"},
          {"name":"TEPATITLÁN DE MORELOS","code":"MX-JAL-TEP"},
          {"name":"TEQUILA","code":"MX-JAL-TEQ"},
          {"name":"TESISTAN","code":"MX-JAL-TST"},
          {"name":"TLAJOMULCO","code":"MX-JAL-TMP116425"},
          {"name":"TLAJOMULCO DE ZÚÑIGA","code":"MX-JAL-TMO"},
          {"name":"TLAQUEPAQUE","code":"MX-JAL-7TL"},
          {"name":"TOLIMÁN","code":"MX-JAL-TOL"},
          {"name":"TOMATLAN","code":"MX-JAL-TOM"},
          {"name":"TONALA","code":"MX-JAL-TON"},
          {"name":"TONAYA","code":"MX-JAL-7TY"},
          {"name":"TOTOTLÁN","code":"MX-JAL-9TJ"},
          {"name":"TULTITAN","code":"MX-JAL-TLT"},
          {"name":"TUXCACUESCO","code":"MX-JAL-TXS"},
          {"name":"USMAJAC","code":"MX-JAL-9UM"},
          {"name":"VILLA CORONA","code":"MX-JAL-VC5"},
          {"name":"VILLA GUERRERO","code":"MX-JAL-VGR"},
          {"name":"VILLA HIDALGO","code":"MX-JAL-VIH"},
          {"name":"ZACOALCO DE TORRES","code":"MX-JAL-ZDT"},
          {"name":"ZAPOPAN","code":"MX-JAL-ZAP"},
          {"name":"ZAPOTILTIC","code":"MX-JAL-ZPC"},
          {"name":"ZAPOTLAN DEL REY","code":"MX-JAL-ZLR"},
          {"name":"ZAPOTLANEJO","code":"MX-JAL-LFD"},
        ]
      },
      {
        name: 'México',
        code: 'MX-MEX',
        cities: [
          {"name":"ACOLMAN","code":"MX-MEX-ACM"},
          {"name":"ACULCO DE ESPINOSA","code":"MX-MEX-ADE"},
          {"name":"ALMOLOYA DE JUAREZ","code":"MX-MEX-ADJ"},
          {"name":"APAXCO DE OCAMPO","code":"MX-MEX-APX"},
          {"name":"ATIZAPÁN","code":"MX-MEX-ATA"},
          {"name":"ATIZAPAN DE ZARAGOZA","code":"MX-MEX-AZA"},
          {"name":"ATLACOMULCO DE FABELA","code":"MX-MEX-ACO"},
          {"name":"AVANDARO","code":"MX-MEX-TMP115436"},
          {"name":"AXAPUSCO","code":"MX-MEX-AXA"},
          {"name":"CALIMAYA DE DIAZ GONZALEZ","code":"MX-MEX-CLM"},
          {"name":"CALPULALPAN","code":"MX-MEX-CAL"},
          {"name":"CAPULHUAC","code":"MX-MEX-9CP"},
          {"name":"CHALCO","code":"MX-MEX-ZMB"},
          {"name":"CHAPA DE MOTA","code":"MX-MEX-CDM"},
          {"name":"CHICOLOAPAN","code":"MX-MEX-SVC"},
          {"name":"CHICONCUAC","code":"MX-MEX-ZMC"},
          {"name":"CHIMALHUACÁN (SANTA MARÍA CHIMALHUACÁN)","code":"MX-MEX-CMH"},
          {"name":"COACALCO","code":"MX-MEX-COC"},
          {"name":"COACALCO DE BERRIOZÁBAL","code":"MX-MEX-BEZ"},
          {"name":"COATEPEC HARINAS","code":"MX-MEX-CPA"},
          {"name":"CONTEPEC","code":"MX-MEX-CTC"},
          {"name":"CUAJIMALPA","code":"MX-MEX-CUJ"},
          {"name":"CUAJIMALPA (LOS CUERVOS)","code":"MX-MEX-TMP115493"},
          {"name":"CUAUTITLAN","code":"MX-MEX-CTN"},
          {"name":"CUAUTITLÁN IZCALLI","code":"MX-MEX-CUT"},
          {"name":"DZILAM DE BRAVO","code":"MX-MEX-DZI"},
          {"name":"ECATEPEC","code":"MX-MEX-EPC"},
          {"name":"ECATEPEC DE MORELOS","code":"MX-MEX-ETM"},
          {"name":"ECATEPEC MORELOS","code":"MX-MEX-TMP116474"},
          {"name":"HUEHUETOCA","code":"MX-MEX-HUH"},
          {"name":"HUIXQUILUCAN","code":"MX-MEX-HXC"},
          {"name":"HUIXQUILUCAN DE DEGOLLADO","code":"MX-MEX-HXD"},
          {"name":"IXTAPALUCA","code":"MX-MEX-ZIP"},
          {"name":"IXTLAHUACA","code":"MX-MEX-XHC"},
          {"name":"IXTLAHUACA DE RAYON","code":"MX-MEX-IR5"},
          {"name":"IXTLAHUACA DE VILLADA","code":"MX-MEX-QXT"},
          {"name":"IXTLAHUACAN","code":"MX-MEX-IXT"},
          {"name":"JALTEPEC","code":"MX-MEX-JLP"},
          {"name":"JILOTEPEC","code":"MX-MEX-JIL"},
          {"name":"JILOTEPEC DE ABASOLO","code":"MX-MEX-JTC"},
          {"name":"JILOTZINGO","code":"MX-MEX-JIZ"},
          {"name":"JOCOTITLAN","code":"MX-MEX-JOC"},
          {"name":"JUCHITEPEC","code":"MX-MEX-JUC"},
          {"name":"LERMA DE VILLADA","code":"MX-MEX-LDV"},
          {"name":"LOMAS DE CHAPULTEPEC","code":"MX-MEX-LC2"},
          {"name":"LOPEZ MATEOS","code":"MX-MEX-LOP"},
          {"name":"LOS REYES","code":"MX-MEX-LRY"},
          {"name":"METEPEC","code":"MX-MEX-MPC"},
          {"name":"MIGUEL HIDALGO","code":"MX-MEX-MHI"},
          {"name":"NAUCALPAN","code":"MX-MEX-NAL"},
          {"name":"NAUCALPAN DE JUÁREZ","code":"MX-MEX-NDJ"},
          {"name":"NEXTLALPAN","code":"MX-MEX-SAN"},
          {"name":"NEZAHUALCÓYOTL","code":"MX-MEX-NHY"},
          {"name":"NICOLAS ROMERO","code":"MX-MEX-NRO"},
          {"name":"OCOYOACAC","code":"MX-MEX-OYO"},
          {"name":"OTUMBA","code":"MX-MEX-OTU"},
          {"name":"OZUMBA","code":"MX-MEX-OZU"},
          {"name":"POLOTITLAN","code":"MX-MEX-OPT"},
          {"name":"SAN ANDRES TIMILPAN","code":"MX-MEX-TIM"},
          {"name":"SAN ANTONIO LA ISLA","code":"MX-MEX-ZMK"},
          {"name":"SAN FELIPE DEL PROGRESO","code":"MX-MEX-SFP"},
          {"name":"SAN JUAN TUXTEPEC","code":"MX-MEX-TXP"},
          {"name":"SAN MARTÍN DE LAS PIRÁMIDES","code":"MX-MEX-PDP"},
          {"name":"SAN MARTIN OBISPO","code":"MX-MEX-SOB"},
          {"name":"SAN MATEO ATENCO","code":"MX-MEX-SMA"},
          {"name":"SAN MIGUEL","code":"MX-MEX-TMP115655"},
          {"name":"SAN PEDRO TOTOLTEPEC","code":"MX-MEX-SPT"},
          {"name":"SAN SALVADOR ATENCO","code":"MX-MEX-SSA"},
          {"name":"SANTA CLARA COATITLA","code":"MX-MEX-XLA"},
          {"name":"SANTA CRUZ TECAMAC","code":"MX-MEX-SCT"},
          {"name":"SANTA CRUZ TEPEXPAN","code":"MX-MEX-TPX"},
          {"name":"SANTIAGO TIANGUISTENCO DE GALEANA","code":"MX-MEX-STG"},
          {"name":"TANGANCÍCUARO","code":"MX-MEX-TAG"},
          {"name":"TECAMAC","code":"MX-MEX-TCA"},
          {"name":"TEMASCALAPA","code":"MX-MEX-CZP"},
          {"name":"TENANCINGO","code":"MX-MEX-TDT"},
          {"name":"TENANGO DEL VALLE","code":"MX-MEX-TDV"},
          {"name":"TEOLOYUCAN","code":"MX-MEX-TEO"},
          {"name":"TEOTIHUACAN","code":"MX-MEX-TEU"},
          {"name":"TEPETLAOXTOC DE HIDALGO","code":"MX-MEX-TPW"},
          {"name":"TEPETLIXPAC","code":"MX-MEX-4TE"},
          {"name":"TEPOTZOTLÁN","code":"MX-MEX-TPZ"},
          {"name":"TEXCOCO","code":"MX-MEX-TXO"},
          {"name":"TEXCOCO DE MORA","code":"MX-MEX-TXM"},
          {"name":"TEZOYUCA","code":"MX-MEX-TEY"},
          {"name":"TIANGUISTENCO","code":"MX-MEX-TGT"},
          {"name":"TLALMANALCO DE VELÁZQUEZ","code":"MX-MEX-TVZ"},
          {"name":"TLALNEPANTLA","code":"MX-MEX-TMP115733"},
          {"name":"TLALNEPANTLA DE BAZ","code":"MX-MEX-TLP"},
          {"name":"TOLUCA","code":"MX-MEX-TLC"},
          {"name":"TONATICO","code":"MX-MEX-TCO"},
          {"name":"TULTEPEC","code":"MX-MEX-TPC"},
          {"name":"TULTITLÁN","code":"MX-MEX-TLN"},
          {"name":"VALLE DE BRAVO","code":"MX-MEX-VDB"},
          {"name":"VILLA GUERRERO","code":"MX-MEX-VGO"},
          {"name":"VILLA NICOLÁS ROMERO","code":"MX-MEX-VNR"},
          {"name":"XALOSTOC","code":"MX-MEX-XAL"},
          {"name":"XOCHIMILCO","code":"MX-MEX-XMO"},
          {"name":"XONACATLÁN","code":"MX-MEX-XON"},
          {"name":"ZINACANTEPEC","code":"MX-MEX-ZMJ"},
          {"name":"ZUMPAHUACÁN","code":"MX-MEX-ZPU"},
          {"name":"ZUMPANGO DE OCAMPO","code":"MX-MEX-ZUM"},
        ]
      },
      {
        name: 'Michoacán',
        code: 'MX-MIC',
        cities: [
          {"name":"ACUITZIO DEL CANJE","code":"MX-MIC-AZ2"},
          {"name":"ANTUNEZ","code":"MX-MIC-ATZ"},
          {"name":"ARIO DE ROSALES","code":"MX-MIC-ADR"},
          {"name":"ATUNES","code":"MX-MIC-ABB"},
          {"name":"CHAVINDA","code":"MX-MIC-CHV"},
          {"name":"CHILCHOTA","code":"MX-MIC-CCT"},
          {"name":"CIUDAD HIDALGO","code":"MX-MIC-ZMF"},
          {"name":"COAHUAYANA DE HIDALGO","code":"MX-MIC-OAH"},
          {"name":"COALCOMAN","code":"MX-MIC-CCN"},
          {"name":"COTIJA","code":"MX-MIC-COT"},
          {"name":"CUTZIO","code":"MX-MIC-CIO"},
          {"name":"ECUANDUREO","code":"MX-MIC-ECU"},
          {"name":"EL RANCHITO","code":"MX-MIC-ELR"},
          {"name":"HEROICA ZITACUARO","code":"MX-MIC-HZR"},
          {"name":"HUETAMO","code":"MX-MIC-HUT"},
          {"name":"IRIMBO","code":"MX-MIC-IRI"},
          {"name":"JACONA DE PLANCARTE","code":"MX-MIC-JPR"},
          {"name":"JIQUILPAN DE JUAREZ","code":"MX-MIC-JIQ"},
          {"name":"LA HUACANA","code":"MX-MIC-LAH"},
          {"name":"LA PIEDAD","code":"MX-MIC-PID"},
          {"name":"LA PIEDAD DE CABADAS","code":"MX-MIC-LPC"},
          {"name":"LÁZARO CÁRDENAS","code":"MX-MIC-LZC"},
          {"name":"LOMBARDÍA","code":"MX-MIC-CIM"},
          {"name":"MARAVATIO","code":"MX-MIC-MAV"},
          {"name":"MICHACAN","code":"MX-MIC-MCN"},
          {"name":"MICHOACAN","code":"MX-MIC-TMP116794"},
          {"name":"MORELIA","code":"MX-MIC-MLM"},
          {"name":"NUEVA ITALIA DE RUIZ","code":"MX-MIC-NIR"},
          {"name":"NUEVO URECHO","code":"MX-MIC-NUO"},
          {"name":"PARÁCUARO","code":"MX-MIC-PAQ"},
          {"name":"PATZCUARO","code":"MX-MIC-PZO"},
          {"name":"PERIBAN","code":"MX-MIC-ZPN"},
          {"name":"PERIBAN DE RAMOS","code":"MX-MIC-M5C"},
          {"name":"PURÉPERO","code":"MX-MIC-PPR"},
          {"name":"QUIROGA","code":"MX-MIC-QUI"},
          {"name":"SAHUAYO","code":"MX-MIC-SAO"},
          {"name":"SAN JOSÉ DE GRACIAS","code":"MX-MIC-SJG"},
          {"name":"SANTA CLARA","code":"MX-MIC-SCA"},
          {"name":"SANTA CLARA DEL COBRE","code":"MX-MIC-SCC"},
          {"name":"SANTIAGO TANGAMANDAPIO","code":"MX-MIC-SGP"},
          {"name":"TACAMBARO","code":"MX-MIC-TAC"},
          {"name":"TANCÍTARO","code":"MX-MIC-TTR"},
          {"name":"TANGANCÍCUARO DE ARISTA","code":"MX-MIC-TDA"},
          {"name":"TANHUATO DE GUERRERO","code":"MX-MIC-SW2"},
          {"name":"TARETÁN","code":"MX-MIC-TAN"},
          {"name":"TARÍMBARO","code":"MX-MIC-TAR"},
          {"name":"TINGÜINDÍN","code":"MX-MIC-TIN"},
          {"name":"TIRIPETÍO","code":"MX-MIC-TRP"},
          {"name":"TLALPUJAHUA DE RAYÓN","code":"MX-MIC-TDR"},
          {"name":"URUAPAN DEL PROGRESO","code":"MX-MIC-UPN"},
          {"name":"VENUSTIANO CARRANZA","code":"MX-MIC-VOC"},
          {"name":"VISTA HERMOSA","code":"MX-MIC-VHA"},
          {"name":"ZACÁN","code":"MX-MIC-TMP116889"},
          {"name":"ZACAPU","code":"MX-MIC-ZCP"},
          {"name":"ZAKAPU","code":"MX-MIC-ZKP"},
          {"name":"ZAMORA","code":"MX-MIC-ZMM"},
          {"name":"ZAMORA MICHOACAN","code":"MX-MIC-ZMN"},
        ]
      },
      {
        name: 'Morelos',
        code: 'MX-MOR',
        cities: [
          {"name":"AHUATEPEC","code":"MX-MOR-JHT"},
          {"name":"AMACUZAC","code":"MX-MOR-AMZ"},
          {"name":"ATLACOMULCO","code":"MX-MOR-ALC"},
          {"name":"ATLATLAHUCAN","code":"MX-MOR-ATH"},
          {"name":"AYALA","code":"MX-MOR-AYA"},
          {"name":"CIVAC","code":"MX-MOR-VAC"},
          {"name":"CUAUTLA MORELOS","code":"MX-MOR-CUM"},
          {"name":"CUERNAVACA","code":"MX-MOR-CVC"},
          {"name":"EMILIANO ZAPATA","code":"MX-MOR-EMZ"},
          {"name":"HUAZULCO","code":"MX-MOR-HZC"},
          {"name":"JIUTEPEC (TEMIXCO)","code":"MX-MOR-JIU"},
          {"name":"JOJUTLA","code":"MX-MOR-JOJ"},
          {"name":"MIACATLAN","code":"MX-MOR-MTL"},
          {"name":"PUENTE DE IXTLA","code":"MX-MOR-PDI"},
          {"name":"TEPETZINGO","code":"MX-MOR-TPG"},
          {"name":"TEPOZTLAN","code":"MX-MOR-MOT"},
          {"name":"TLALNEPANTLA","code":"MX-MOR-TLA"},
          {"name":"XOCHIMANCAS","code":"MX-MOR-9AS"},
          {"name":"XOCHITEPEC","code":"MX-MOR-ZMH"},
          {"name":"YAUTEPEC","code":"MX-MOR-YAU"},
          {"name":"YECAPIXTLA","code":"MX-MOR-YZX"},
          {"name":"ZACATEPEC","code":"MX-MOR-ZTP"},
        ]
      },
      {
        name: 'Nayarit',
        code: 'MX-NAY',
        cities: [
          {"name":"ACAPONETA","code":"MX-NAY-ACP"},
          {"name":"BAHÍA DE BANDERAS","code":"MX-NAY-BDB"},
          {"name":"GUAYABITOS","code":"MX-NAY-GYA"},
          {"name":"NAYARIT","code":"MX-NAY-NAY"},
          {"name":"PUNTA DE MITA","code":"MX-NAY-ZPA"},
          {"name":"ROSAMORADA","code":"MX-NAY-ROS"},
          {"name":"SAN BLAS","code":"MX-NAY-SBS"},
          {"name":"SANTA TERESA","code":"MX-NAY-STT"},
          {"name":"SAYULITA","code":"MX-NAY-Y9S"},
          {"name":"TEPIC","code":"MX-NAY-TPQ"},
          {"name":"XALISCO","code":"MX-NAY-XLS"},
        ]
      },
      {
        name: 'Nuevo León',
        code: 'MX-NLE',
        cities: [
          {"name":"ALLENDE","code":"MX-NLE-ALL"},
          {"name":"ARAMBERRI","code":"MX-NLE-AMB"},
          {"name":"CADEREYTA JIMÉNEZ","code":"MX-NLE-ZCA"},
          {"name":"CIENEGA DE FLORES","code":"MX-NLE-CDF"},
          {"name":"CIÉNEGA DE FLORES","code":"MX-NLE-CGF"},
          {"name":"COLOMBIA","code":"MX-NLE-5CL"},
          {"name":"DOCTOR ARROYO","code":"MX-NLE-DOC"},
          {"name":"EL CARMEN","code":"MX-NLE-ECA"},
          {"name":"ESCOBEDO","code":"MX-NLE-ESC"},
          {"name":"GALEANA","code":"MX-NLE-GLN"},
          {"name":"GARCIA","code":"MX-NLE-GNL"},
          {"name":"GARZA GARCÍA","code":"MX-NLE-GGA"},
          {"name":"GENERAL ESCOBEDO","code":"MX-NLE-GES"},
          {"name":"GENERAL TERÁN","code":"MX-NLE-GET"},
          {"name":"GUADALUPE","code":"MX-NLE-GLP"},
          {"name":"HIDALGO","code":"MX-NLE-HDL"},
          {"name":"ITURBIDE","code":"MX-NLE-NLE"},
          {"name":"LA ASCENCIÓN","code":"MX-NLE-LSN"},
          {"name":"LAMPAZOS","code":"MX-NLE-LPZ"},
          {"name":"LAMPAZOS DE NARANJO","code":"MX-NLE-LDN"},
          {"name":"LINARES","code":"MX-NLE-LIN"},
          {"name":"MEZQUITAL","code":"MX-NLE-MEZ"},
          {"name":"MONTEMORELOS","code":"MX-NLE-MTM"},
          {"name":"MONTERREY","code":"MX-NLE-MTY"},
          {"name":"SABINAS HIDALGO","code":"MX-NLE-SHO"},
          {"name":"SALINAS VICTORIA","code":"MX-NLE-SAV"},
          {"name":"SAN NICOLÁS DE LOS GARZA","code":"MX-NLE-SND"},
          {"name":"SAN PEDRO GARZA GARCIA","code":"MX-NLE-SPG"},
          {"name":"SANTA CATARINA","code":"MX-NLE-STC"},
          {"name":"SANTIAGO","code":"MX-NLE-TMP117164"},
          {"name":"VILLA DE GARCÍA","code":"MX-NLE-VDG"},
        ]
      },
      {
        name: 'Oaxaca',
        code: 'MX-OAX',
        cities: [
          {"name":"AYOTZINTEPEC","code":"MX-OAX-AYH"},
          {"name":"CHAHUITES","code":"MX-OAX-CHH"},
          {"name":"ECATEPEC","code":"MX-OAX-ETP"},
          {"name":"HUAJUAPAN","code":"MX-OAX-HJP"},
          {"name":"HUATULCO (SANTA MARÍA HUATULCO)","code":"MX-OAX-HTO"},
          {"name":"JILOTEPEC","code":"MX-OAX-JTP"},
          {"name":"JUCHITÁN DE ZARAGOZA","code":"MX-OAX-JDZ"},
          {"name":"LOMA BONITA","code":"MX-OAX-LOB"},
          {"name":"MARISCALA","code":"MX-OAX-MSC"},
          {"name":"MATIAS ROMERO","code":"MX-OAX-MTR"},
          {"name":"OAXACA","code":"MX-OAX-OAX"},
          {"name":"PUERTO ÁNGEL","code":"MX-OAX-PNG"},
          {"name":"PUTLA DE GUERRERO","code":"MX-OAX-PUT"},
          {"name":"SALINA CRUZ","code":"MX-OAX-SCX"},
          {"name":"SAN AGUSTIN DE LAS JUNTAS","code":"MX-OAX-SDJ"},
          {"name":"SAN ANDRES HUAXPALTEPEC","code":"MX-OAX-SAH"},
          {"name":"SAN CARLOS YAUTEPEC","code":"MX-OAX-SCY"},
          {"name":"SAN DIONISIO OCOTEPEC","code":"MX-OAX-SDO"},
          {"name":"SAN FRANCISCO","code":"MX-OAX-SJF"},
          {"name":"SAN FRANCISCO LACHIGOLÓ","code":"MX-OAX-SFL"},
          {"name":"SAN JUAN BAUTISTA TUXTEPEC","code":"MX-OAX-SJT"},
          {"name":"SAN PABLO ETLA","code":"MX-OAX-SPE"},
          {"name":"SAN PEDRO TAPANATEPEC","code":"MX-OAX-STP"},
          {"name":"SAN SEBASTIAN","code":"MX-OAX-SSB"},
          {"name":"SANTA CRUZ AMILPAS","code":"MX-OAX-SCI"},
          {"name":"SANTA CRUZ ITUNDUJIA","code":"MX-OAX-SAC"},
          {"name":"SANTA CRUZ PAPALUTLA","code":"MX-OAX-SPA"},
          {"name":"SANTIAGO MATATLAN","code":"MX-OAX-SAM"},
          {"name":"SANTIAGO YAVEO","code":"MX-OAX-SAY"},
          {"name":"SANTO DOMINGO TIANGUISTENGO","code":"MX-OAX-SDT"},
          {"name":"TELIXTLAHUACA","code":"MX-OAX-TXH"},
          {"name":"TEOTITLÁN DEL VALLE","code":"MX-OAX-TEV"},
          {"name":"TLACOLULA DE MATAMOROS","code":"MX-OAX-TDM"},
          {"name":"TLAXIACO","code":"MX-OAX-TAX"},
          {"name":"UNIÓN HIDALGO","code":"MX-OAX-UHO"},
          {"name":"VILLA DE ZAACHILA","code":"MX-OAX-VDZ"},
        ]
      },
      {
        name: 'Puebla',
        code: 'MX-PUE',
        cities: [
          {"name":"ACAJETE","code":"MX-PUE-ACE"},
          {"name":"ACATLÁN DE OSORIO","code":"MX-PUE-ADO"},
          {"name":"ACATZINGO DE HIDALGO","code":"MX-PUE-ADH"},
          {"name":"AMOZOC DE MOTA","code":"MX-PUE-AMC"},
          {"name":"ATLIXCO","code":"MX-PUE-ATL"},
          {"name":"CHACHAPA","code":"MX-PUE-CHP"},
          {"name":"CHIPILIO DE FRANCISCO JAVIER MINA","code":"MX-PUE-CFJ"},
          {"name":"CHOLULA","code":"MX-PUE-CHA"},
          {"name":"COATEPEC","code":"MX-PUE-COQ"},
          {"name":"CUAUTINCHAN","code":"MX-PUE-CUH"},
          {"name":"CUAUTLANCINGO","code":"MX-PUE-CAU"},
          {"name":"CUETZALAN DEL PROGRESO","code":"MX-PUE-CDP"},
          {"name":"HUAUCHINANGO","code":"MX-PUE-HUO"},
          {"name":"HUEJOTZINGO","code":"MX-PUE-HJZ"},
          {"name":"IZÚCAR DE MATAMOROS","code":"MX-PUE-IS7"},
          {"name":"JILOTEPEC","code":"MX-PUE-JPC"},
          {"name":"JOLALPAN","code":"MX-PUE-JOL"},
          {"name":"LIBRES","code":"MX-PUE-LIB"},
          {"name":"NOPALUCAN","code":"MX-PUE-NPC"},
          {"name":"PUEBLA","code":"MX-PUE-PBC"},
          {"name":"PUEBLA CITY","code":"MX-PUE-TMP117468"},
          {"name":"QUECHOLAC","code":"MX-PUE-QUP"},
          {"name":"SAN ANDRÉS CHOLULA","code":"MX-PUE-AND"},
          {"name":"SAN DIEGO","code":"MX-PUE-SDG"},
          {"name":"SAN GREGORIO ATZOMPA","code":"MX-PUE-SGA"},
          {"name":"SAN JOSÉ CHIAPA","code":"MX-PUE-P7E"},
          {"name":"SAN JUAN ATZOMPAN","code":"MX-PUE-MP6"},
          {"name":"SAN MARTIN TEXMELUCAN","code":"MX-PUE-SMT"},
          {"name":"SAN MIGUEL XOXTLA","code":"MX-PUE-SGX"},
          {"name":"SAN PEDRO CHOLULA","code":"MX-PUE-SPC"},
          {"name":"SAN PEDRO IZHUATEPEC","code":"MX-PUE-SPL"},
          {"name":"SANTA ISABEL CHOLULA","code":"MX-PUE-SIC"},
          {"name":"TECAMACHALCO","code":"MX-PUE-TEM"},
          {"name":"TEPANCO DE LÓPEZ","code":"MX-PUE-TDL"},
          {"name":"TEPANGO DE RODRÍGUEZ","code":"MX-PUE-Z9T"},
          {"name":"TEPEOJUMA","code":"MX-PUE-TPJ"},
          {"name":"TEPETLALCO","code":"MX-PUE-TPT"},
          {"name":"TEPEYAHUALCO","code":"MX-PUE-ZMG"},
          {"name":"TEZIUTLAN","code":"MX-PUE-TEZ"},
          {"name":"TLANEPANTLA","code":"MX-PUE-TLL"},
          {"name":"TLATLAUQUITEPEC","code":"MX-PUE-TQP"},
          {"name":"XICOTEPEC DE JUAREZ","code":"MX-PUE-XER"},
          {"name":"ZACATLAN","code":"MX-PUE-ZAC"},
          {"name":"ZARAGOZA","code":"MX-PUE-ZRZ"},
        ]
      },
      {
        name: 'Querétaro',
        code: 'MX-QUE',
        cities: [
          {"name":"AJUCHITLÁN","code":"MX-QUE-QMX"},
          {"name":"AMEALCO DE BONFIL","code":"MX-QUE-ADB"},
          {"name":"BERNAL","code":"MX-QUE-BER"},
          {"name":"COLON","code":"MX-QUE-CLN"},
          {"name":"CORREGIDORA","code":"MX-QUE-COR"},
          {"name":"EL MARQUES","code":"MX-QUE-EMQ"},
          {"name":"EL PUEBLITO","code":"MX-QUE-EPO"},
          {"name":"EZEQUIEL MONTES","code":"MX-QUE-EZE"},
          {"name":"JURICA","code":"MX-QUE-TMP117597"},
          {"name":"JURIQUILLA","code":"MX-QUE-TMP117598"},
          {"name":"PEDRO ESCOBEDO","code":"MX-QUE-OEO"},
          {"name":"QUERÉTARO","code":"MX-QUE-QRO"},
          {"name":"QUERÉTARO CITY","code":"MX-QUE-TMP117612"},
          {"name":"SAN JUAN DEL RIO","code":"MX-QUE-SJO"},
          {"name":"SANTA ROSA DE JÁUREGUI","code":"MX-QUE-SRJ"},
          {"name":"SANTIAGO DE QUERÉTARO","code":"MX-QUE-SQE"},
          {"name":"TEQUISQUIAPAN","code":"MX-QUE-TQQ"},
          {"name":"VENEGAS","code":"MX-QUE-QET"},
          {"name":"VILLA DEL PUEBLITO","code":"MX-QUE-BVL"},
        ]
      },
      {
        name: 'Quintana Roo',
        code: 'MX-ROO',
        cities: [
          {"name":"BENITOJUÁREZ","code":"MX-ROO-G6H"},
          {"name":"CANCÚN","code":"MX-ROO-CUN"},
          {"name":"CAOBAS","code":"MX-ROO-CAO"},
          {"name":"CHETUMAL","code":"MX-ROO-CTM"},
          {"name":"COZUMEL","code":"MX-ROO-CZM"},
          {"name":"FELIPE CARRILLO PUERTO","code":"MX-ROO-FCP"},
          {"name":"ISLA MUJERES","code":"MX-ROO-ISJ"},
          {"name":"MAHAHUAL","code":"MX-ROO-MAR"},
          {"name":"NOH-BEC","code":"MX-ROO-ROO"},
          {"name":"PUERTO COSTA MAYA","code":"MX-ROO-COM"},
          {"name":"PUERTO MORELOS","code":"MX-ROO-PMS"},
          {"name":"PUNTA VENADO","code":"MX-ROO-PVN"},
          {"name":"SOLIDARIDAD","code":"MX-ROO-SOL"},
          {"name":"XPICHIL","code":"MX-ROO-XPI"},
        ]
      },
      {
        name: 'San Luis Potosí',
        code: 'MX-SLP',
        cities: [
          {"name":"AHUALULCO","code":"MX-SLP-AH5"},
          {"name":"CEDRAL","code":"MX-SLP-ALG"},
          {"name":"CHARCAS","code":"MX-SLP-CCS"},
          {"name":"CIUDAD VALLES","code":"MX-SLP-CDV"},
          {"name":"COAHUILA","code":"MX-SLP-DD5"},
          {"name":"HUEHUETLAN","code":"MX-SLP-HHL"},
          {"name":"HUICHIHUAYAN","code":"MX-SLP-HHY"},
          {"name":"MATEHUALA","code":"MX-SLP-MTE"},
          {"name":"MEXQUITIC DE CARMONA","code":"MX-SLP-MDC"},
          {"name":"OBREGÓN","code":"MX-SLP-OBR"},
          {"name":"RIO VERDE","code":"MX-SLP-RIV"},
          {"name":"SAN LUIS POTOSÍ","code":"MX-SLP-SLP"},
          {"name":"SAN NICOLÁS TOLENTINO","code":"MX-SLP-SNT"},
          {"name":"SOLEDAD DE GRACIANO SÁNCHEZ","code":"MX-SLP-SGS"},
          {"name":"VALLEJO","code":"MX-SLP-VAL"},
          {"name":"VANEGAS","code":"MX-SLP-VES"},
          {"name":"VANEGAS DE ABAJO","code":"MX-SLP-VDA"},
          {"name":"VILLA DE REYES","code":"MX-SLP-VDR"},
          {"name":"XICOHTZINCO","code":"MX-SLP-XZC"},
        ]
      },
      {
        name: 'Sinaloa',
        code: 'MX-SIN',
        cities: [
          {"name":"AHOME","code":"MX-SIN-AHM"},
          {"name":"CHOACAHUI","code":"MX-SIN-BF2"},
          {"name":"CHOIX","code":"MX-SIN-CHO"},
          {"name":"CULIACÁN","code":"MX-SIN-CUL"},
          {"name":"EL FUERTE","code":"MX-SIN-ELF"},
          {"name":"EL ROSARIO","code":"MX-SIN-ERS"},
          {"name":"ESCUINAPA DE HIDALGO","code":"MX-SIN-EHG"},
          {"name":"ESCUINAPAN","code":"MX-SIN-SCP"},
          {"name":"GUAMÚCHIL","code":"MX-SIN-GUL"},
          {"name":"GUASAVE","code":"MX-SIN-GSV"},
          {"name":"LA CRUZ","code":"MX-SIN-LCZ"},
          {"name":"MAZATLAN","code":"MX-SIN-MZT"},
          {"name":"NAVOLATO","code":"MX-SIN-NVO"},
          {"name":"PUERTA","code":"MX-SIN-PTA"},
          {"name":"ROSARIO","code":"MX-SIN-RSR"},
          {"name":"SAN IGNACIO","code":"MX-SIN-SIO"},
          {"name":"TAMAULIPAS","code":"MX-SIN-TMP"},
          {"name":"TOPOLOBAMPO","code":"MX-SIN-TPB"},
        ]
      },
      {
        name: 'Sonora',
        code: 'MX-SON',
        cities: [
          {"name":"AGUA PRIETA","code":"MX-SON-APR"},
          {"name":"ARIZPE","code":"MX-SON-ARZ"},
          {"name":"BAHIA KINO","code":"MX-SON-BAK"},
          {"name":"BANAMICHI","code":"MX-SON-BAN"},
          {"name":"BATACOSA","code":"MX-SON-BTA"},
          {"name":"CABORCA VIEJO","code":"MX-SON-CBV"},
          {"name":"CAJEME","code":"MX-SON-JCM"},
          {"name":"CANANEA","code":"MX-SON-CNA"},
          {"name":"CIUDAD OBREGON","code":"MX-SON-CEN"},
          {"name":"COAHUILA","code":"MX-SON-CHL"},
          {"name":"CUMPAS","code":"MX-SON-CMP"},
          {"name":"EMPALME","code":"MX-SON-EPM"},
          {"name":"FRONTERAS","code":"MX-SON-FTZ"},
          {"name":"GOLFO DE SANTA CLARA","code":"MX-SON-GSC"},
          {"name":"GUAYMAS","code":"MX-SON-GYM"},
          {"name":"HERMOSILLO","code":"MX-SON-HMO"},
          {"name":"HEROICA CABORCA","code":"MX-SON-HEA"},
          {"name":"HUATABAMPO","code":"MX-SON-HUA"},
          {"name":"IMURIS","code":"MX-SON-IMU"},
          {"name":"LAS NORIAS","code":"MX-SON-SOO"},
          {"name":"MAGDALENA DE KINO","code":"MX-SON-MDK"},
          {"name":"MIGUEL ALEMÁN","code":"MX-SON-MGL"},
          {"name":"NAVOJOA","code":"MX-SON-NVS"},
          {"name":"NOGALES","code":"MX-SON-NOG"},
          {"name":"PUERTO LIBERTAD","code":"MX-SON-PUL"},
          {"name":"PUERTO PEÑASCO","code":"MX-SON-PPE"},
          {"name":"RAYON","code":"MX-SON-RAY"},
          {"name":"SAN MIGUEL DE HORCASITAS","code":"MX-SON-SMH"},
          {"name":"SANTA ANA","code":"MX-SON-SN2"},
          {"name":"SONOYTA","code":"MX-SON-SON"},
          {"name":"YAVAROS","code":"MX-SON-YAV"},
        ]
      },
      {
        name: 'Tabasco',
        code: 'MX-TAB',
        cities: [
          {"name":"BALANCAN","code":"MX-TAB-BAL"},
          {"name":"CENTRO","code":"MX-TAB-CTR"},
          {"name":"CHILTEPEC","code":"MX-TAB-TAB"},
          {"name":"CIUDAD PEMEX","code":"MX-TAB-CPX"},
          {"name":"CUNDUACAN","code":"MX-TAB-CDC"},
          {"name":"DOS BOCAS TERMINAL","code":"MX-TAB-DBT"},
          {"name":"EL CHAPO","code":"MX-TAB-ECT"},
          {"name":"FRONTERA","code":"MX-TAB-FRN"},
          {"name":"HUIMANGUILLO","code":"MX-TAB-HUI"},
          {"name":"MICHOACÁN","code":"MX-TAB-MIC"},
          {"name":"NACAJUCA","code":"MX-TAB-NAC"},
          {"name":"PARAISO","code":"MX-TAB-PRO"},
          {"name":"POMOCA","code":"MX-TAB-POM"},
          {"name":"TACOTALPA","code":"MX-TAB-TCP"},
          {"name":"TEAPA","code":"MX-TAB-TPA"},
          {"name":"TENOSIQUE DE PINO SUAREZ","code":"MX-TAB-TSQ"},
          {"name":"VILLAHERMOSA","code":"MX-TAB-VSA"},
        ]
      },
      {
        name: 'Tamaulipas',
        code: 'MX-TAM',
        cities: [
          {"name":"ALDAMA","code":"MX-TAM-ALD"},
          {"name":"ALTAMIRA","code":"MX-TAM-ATM"},
          {"name":"ATIZAPÁN DE ZARAGOZA","code":"MX-TAM-ADZ"},
          {"name":"BARRETAL","code":"MX-TAM-EA2"},
          {"name":"CIUDAD MADERO","code":"MX-TAM-CDD"},
          {"name":"CIUDAD MANTE","code":"MX-TAM-MMC"},
          {"name":"CIUDAD MIGUEL ALEMAN","code":"MX-TAM-CMA"},
          {"name":"CIUDAD RÍO BRAVO","code":"MX-TAM-RBV"},
          {"name":"CIUDAD VICTORIA","code":"MX-TAM-CVM"},
          {"name":"GONZÁLES","code":"MX-TAM-GNS"},
          {"name":"GONZÁLEZ","code":"MX-TAM-GON"},
          {"name":"HIDALGO","code":"MX-TAM-HGO"},
          {"name":"JAUMAVE","code":"MX-TAM-JU2"},
          {"name":"LLERA DE CANALES","code":"MX-TAM-LDC"},
          {"name":"MATAMOROS","code":"MX-TAM-MAM"},
          {"name":"MIRAMAR","code":"MX-TAM-MIR"},
          {"name":"NUEVO LAREDO","code":"MX-TAM-NLD"},
          {"name":"REYNOSA","code":"MX-TAM-REX"},
          {"name":"SAN FERNANDO","code":"MX-TAM-SFD"},
          {"name":"SAN IGNACIO CERRO GORDO","code":"MX-TAM-LSR"},
          {"name":"SOTO LA MARINA","code":"MX-TAM-SOT"},
          {"name":"TAMPICO","code":"MX-TAM-TAM"},
          {"name":"TULA","code":"MX-TAM-TUA"},
          {"name":"VALLE HERMOSO","code":"MX-TAM-VRO"},
        ]
      },
      {
        name: 'Tlaxcala',
        code: 'MX-TLA',
        cities: [
          {"name":"ACUAMANALA","code":"MX-TLA-ACL"},
          {"name":"APIZACO","code":"MX-TLA-API"},
          {"name":"CONTLA","code":"MX-TLA-CLA"},
          {"name":"HUAMANTLA","code":"MX-TLA-HAU"},
          {"name":"IXTACUIXTLA DE MARIANO MATAMOROS","code":"MX-TLA-IMM"},
          {"name":"ORIENTAL","code":"MX-TLA-ORL"},
          {"name":"PANOTLA","code":"MX-TLA-PNT"},
          {"name":"PAPALOTLA","code":"MX-TLA-PAP"},
          {"name":"PAPALOTLA DE XICOHTÉNCATL","code":"MX-TLA-PDX"},
          {"name":"SAN COSME XALOSTOC","code":"MX-TLA-CXC"},
          {"name":"SANTA ANA CHIAUTEMPAN","code":"MX-TLA-SA3"},
          {"name":"SANTA MARIA IXTULCO","code":"MX-TLA-SMX"},
          {"name":"SANTO TORIBIO XICOHTZINGO","code":"MX-TLA-ST2"},
          {"name":"TEOLOCHOLCO","code":"MX-TLA-SLT"},
          {"name":"TEQUIXQUITLA","code":"MX-TLA-CAR"},
          {"name":"TETLA","code":"MX-TLA-TTL"},
          {"name":"TIAXCALA","code":"MX-TLA-TXC"},
          {"name":"TLAXCO","code":"MX-TLA-T3A"},
          {"name":"ZITLALTEPEC DE TRINIDAD SÁNCHEZ SANTOS","code":"MX-TLA-ZIT"},
        ]
      },
      {
        name: 'Veracruz',
        code: 'MX-VER',
        cities: [
          {"name":"ACAJETE","code":"MX-VER-JJT"},
          {"name":"ACATLÁN","code":"MX-VER-AAN"},
          {"name":"ÁLAMO","code":"MX-VER-ALO"},
          {"name":"ALVARADO","code":"MX-VER-AVD"},
          {"name":"AMATLÁN DE LOS REYES","code":"MX-VER-MDE"},
          {"name":"APAZAPAN","code":"MX-VER-AZP"},
          {"name":"ATZALAN","code":"MX-VER-AZL"},
          {"name":"BOCA DEL RÍO","code":"MX-VER-BDR"},
          {"name":"CATEMACO","code":"MX-VER-CMC"},
          {"name":"CHAPULTEPEC","code":"MX-VER-CPC"},
          {"name":"COATEPAC","code":"MX-VER-CTP"},
          {"name":"COATEPEC","code":"MX-VER-T8P"},
          {"name":"COATZACOALCOS","code":"MX-VER-COA"},
          {"name":"CÓRDOBA","code":"MX-VER-CDU"},
          {"name":"COSOLEACAQUE","code":"MX-VER-COS"},
          {"name":"COTAXTLA","code":"MX-VER-CTX"},
          {"name":"CUITLÁHUAC","code":"MX-VER-CUI"},
          {"name":"EL FORTIN","code":"MX-VER-TMP115779"},
          {"name":"EL HIGO","code":"MX-VER-EHV"},
          {"name":"EMILIANO ZAPATA","code":"MX-VER-E2Z"},
          {"name":"FORTIN DE LAS FLORES","code":"MX-VER-FLF"},
          {"name":"HUATUSCO DE CHICUELLAR","code":"MX-VER-HDC"},
          {"name":"HUAYACOCOTLA","code":"MX-VER-HYC"},
          {"name":"HUILOAPAN","code":"MX-VER-HUP"},
          {"name":"ISLA","code":"MX-VER-ISL"},
          {"name":"IXHUATEPEC","code":"MX-VER-AC3"},
          {"name":"IXHUATLAN DEL SURESTE","code":"MX-VER-IST"},
          {"name":"IXTACZOQUITLÁN","code":"MX-VER-IA2"},
          {"name":"JALACINGO","code":"MX-VER-JAA"},
          {"name":"JOSE AZUETA","code":"MX-VER-JAZ"},
          {"name":"JUAN RODRIGUEZ CLARA","code":"MX-VER-JRC"},
          {"name":"LA TINAJA","code":"MX-VER-EXL"},
          {"name":"LOS REYES","code":"MX-VER-LRE"},
          {"name":"MARTÍNEZ DE LA TORRE","code":"MX-VER-MLT"},
          {"name":"MINATITLAN","code":"MX-VER-MTT"},
          {"name":"MISANTLA","code":"MX-VER-MSL"},
          {"name":"NAOLINCO","code":"MX-VER-NAO"},
          {"name":"NAUTLA","code":"MX-VER-NAU"},
          {"name":"ORIZABA","code":"MX-VER-ORI"},
          {"name":"PAJARITOS","code":"MX-VER-PAJ"},
          {"name":"PAPANTLA","code":"MX-VER-PPT"},
          {"name":"PASO DE OVEJAS","code":"MX-VER-PSO"},
          {"name":"PEROTE","code":"MX-VER-PRT"},
          {"name":"PINOLTEPEC","code":"MX-VER-PLC"},
          {"name":"PLATON SANCHEZ","code":"MX-VER-PLS"},
          {"name":"PLAYA LINDA","code":"MX-VER-LPY"},
          {"name":"PUENTE NACIONAL","code":"MX-VER-PNA"},
          {"name":"PUÉRTO MEXICO","code":"MX-VER-PMX"},
          {"name":"RABON GRANDE/COATZACOALCOS","code":"MX-VER-RBG"},
          {"name":"RÍO BLANCO","code":"MX-VER-RBL"},
          {"name":"SAN ANDRES TUXTLA","code":"MX-VER-SAX"},
          {"name":"SAN JOSE CHIPILA","code":"MX-VER-ZMD"},
          {"name":"SAN RAFAEL","code":"MX-VER-SRF"},
          {"name":"SANTIAGO TUXTLA","code":"MX-VER-STX"},
          {"name":"TAMOS","code":"MX-VER-TMS"},
          {"name":"TANTOYUCA","code":"MX-VER-TTY"},
          {"name":"TEAPA","code":"MX-VER-TPV"},
          {"name":"TEMAPACHE","code":"MX-VER-TPH"},
          {"name":"TENENEXPAN","code":"MX-VER-TNP"},
          {"name":"TIERRA BLANCA","code":"MX-VER-TBC"},
          {"name":"TIHUATLÁN","code":"MX-VER-THT"},
          {"name":"TLACOTEPEC DE MEJÍA","code":"MX-VER-TLM"},
          {"name":"TLAPACOYAN","code":"MX-VER-TPN"},
          {"name":"TRES VALLES","code":"MX-VER-TVS"},
          {"name":"TUXPAN","code":"MX-VER-TUX"},
          {"name":"URSULO GALVÁN","code":"MX-VER-UGA"},
          {"name":"VENTA PARADA","code":"MX-VER-VEP"},
          {"name":"VERACRUZ","code":"MX-VER-VER"},
          {"name":"VILLA AZUETA","code":"MX-VER-AZT"},
        ]
      },
      {
        name: 'Yucatán',
        code: 'MX-YUC',
        cities: [
          {"name":"ACANCEH","code":"MX-YUC-ANH"},
          {"name":"AKIL","code":"MX-YUC-AKI"},
          {"name":"BACA","code":"MX-YUC-BCA"},
          {"name":"CELESTUN","code":"MX-YUC-CET"},
          {"name":"CHABLEKAL","code":"MX-YUC-TMP118429"},
          {"name":"DZITAS","code":"MX-YUC-DZT"},
          {"name":"HUNUCMÁ","code":"MX-YUC-HUN"},
          {"name":"MÉRIDA","code":"MX-YUC-MID"},
          {"name":"MOTUL DE FELIPE CARRILLO PUERTO","code":"MX-YUC-MDF"},
          {"name":"OXKUTZCAB","code":"MX-YUC-OXK"},
          {"name":"PROGRESO","code":"MX-YUC-PGO"},
          {"name":"SANTA CLARA","code":"MX-YUC-SCL"},
          {"name":"TIXKOKOB","code":"MX-YUC-ZTB"},
          {"name":"UMAN","code":"MX-YUC-UMN"},
          {"name":"VALLADOLID","code":"MX-YUC-VAD"},
        ]
      },
      {
        name: 'Zacatecas',
        code: 'MX-ZAC',
        cities: [
          {"name":"APASEO","code":"MX-ZAC-APO"},
          {"name":"CALERA VICTOR ROSALES","code":"MX-ZAC-CVR"},
          {"name":"CONCEPCION DEL ORO","code":"MX-ZAC-CDO"},
          {"name":"FRESNILLO","code":"MX-ZAC-FRE"},
          {"name":"FRESNILLO DE GONZÁLEZ ECHEVERRÍA","code":"MX-ZAC-FGE"},
          {"name":"HUEHUETOCA","code":"MX-ZAC-HUE"},
          {"name":"JEREZ","code":"MX-ZAC-JZA"},
          {"name":"JUCHIPILA","code":"MX-ZAC-JUP"},
          {"name":"LUIS MOYA","code":"MX-ZAC-LUM"},
          {"name":"OJO CALIENTE","code":"MX-ZAC-OJO"},
          {"name":"PINOS","code":"MX-ZAC-PIN"},
          {"name":"SAN TIBURCIO","code":"MX-ZAC-SUN"},
          {"name":"SOMBRERETE","code":"MX-ZAC-SOM"},
          {"name":"TABASCO","code":"MX-ZAC-TSC"},
          {"name":"TEUL DE GONZALEZ ORTEGA","code":"MX-ZAC-TGO"},
          {"name":"ZACATECAS","code":"MX-ZAC-ZCL"},
        ]
      }
    ]
  },
  {
    name: 'Montserrat',
    code: 'MS',
    dialCode: '+1664',
    flag: 'flag_montserrat.png',
    states: [
      {
        name: 'Saint Anthony',
        code: 'MS-SA',
        cities: []
      }
    ]
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    dialCode: '+505',
    flag: 'flag_nicaragua.png',
    states: [
      {
        name: 'Atlántico Norte',
        code: 'NI-AN',
        cities: [{"name":"BOCA DEL TORO","code":"NI-AN-TMP164310"},
          {"name":"WASLALA","code":"NI-AN-WAS"},]
      },
      {
        name: 'Atlántico Sur',
        code: 'NI-AS',
        cities: [{"name":"RAMA","code":"NI-AS-RAM"},]
      },
      {
        name: 'Boaco',
        code: 'NI-BO',
        cities: [{"name":"BOACO","code":"NI-BO-BOA"},
          {"name":"CAMOAPA","code":"NI-BO-CAP"},
          {"name":"SAN LORENZO","code":"NI-BO-SLO"},]
      },
      {
        name: 'Carazo',
        code: 'NI-CA',
        cities: [{"name":"DIRIAMBA","code":"NI-CA-DIR"},
          {"name":"DOLORES","code":"NI-CA-DOL"},
          {"name":"SAN MARCOS","code":"NI-CA-SMR"},]
      },
      {
        name: 'Chinandega',
        code: 'NI-CI',
        cities: [{"name":"CHICHIGALPA","code":"NI-CI-CCG"},
          {"name":"CHINANDEGA","code":"NI-CI-TMP164356"},
          {"name":"EL VIEJO","code":"NI-CI-ZEO"},
          {"name":"MINA EL LIMON","code":"NI-CI-ZMN"},
          {"name":"POTOSÍ","code":"NI-CI-PTI"},]
      },
      {
        name: 'Chontales',
        code: 'NI-CO',
        cities: [{"name":"ACOYAPA","code":"NI-CO-ACO"},
          {"name":"JUIGALPA","code":"NI-CO-JGP"},
          {"name":"LA LIBERTAD","code":"NI-CO-LIB"},
          {"name":"LIBERTAD-CHONTALES","code":"NI-CO-ZLI"},]
      },
      {
        name: 'Estelí',
        code: 'NI-ES',
        cities: [{"name":"CONDEGA","code":"NI-ES-CDG"},
          {"name":"ESTELÍ","code":"NI-ES-EST"},
          {"name":"LA TRINIDAD","code":"NI-ES-LTD"},
          {"name":"PUEBLO NUEVO","code":"NI-ES-PON"},]
      },
      {
        name: 'Granada',
        code: 'NI-GR',
        cities: [{"name":"DIRIOMO","code":"NI-GR-DIM"},
          {"name":"GRANADA","code":"NI-GR-GRA"},
          {"name":"MALACATOYA","code":"NI-GR-MAL"},]
      },
      {
        name: 'Jinotega',
        code: 'NI-JI',
        cities: [{"name":"JINOTEGA","code":"NI-JI-JNO"},
          {"name":"SAN RAFAEL DEL NORTE","code":"NI-JI-SRN"},]
      },
      {
        name: 'León',
        code: 'NI-LE',
        cities: [{"name":"EL JICARAL","code":"NI-LE-EJC"},
          {"name":"EL SAUCE","code":"NI-LE-ELL"},
          {"name":"LA PAZ CENTRO","code":"NI-LE-LPC"},
          {"name":"MIRAMAR","code":"NI-LE-MR3"},
          {"name":"MIRAMAR/NAGAROTE","code":"NI-LE-MR2"},
          {"name":"NAGAROTE","code":"NI-LE-NGE"},]
      },
      {
        name: 'Madriz',
        code: 'NI-MD',
        cities: [{"name":"PALACAGÜINA","code":"NI-MD-PAL"},
          {"name":"SAN JUAN RIO COCO","code":"NI-MD-SJC"},
          {"name":"SOMOTO","code":"NI-MD-SOM"},
          {"name":"TOTOGALPA","code":"NI-MD-8LG"},]
      },
      {
        name: 'Managua',
        code: 'NI-MN',
        cities: [{"name":"CIUDAD SANDINO","code":"NI-MN-SDC"},
          {"name":"LAS MADERAS","code":"NI-MN-LM8"},
          {"name":"LOS BRASILES","code":"NI-MN-ZLS"},
          {"name":"MANAGUA","code":"NI-MN-TMP164372"},
          {"name":"MASACHAPA","code":"NI-MN-MAC"},
          {"name":"MATEARE","code":"NI-MN-MAE"},
          {"name":"MONTELIMAR","code":"NI-MN-MTE"},
          {"name":"SAN BENITO (SAN FRANCISCO LIBRE)","code":"NI-MN-SBS"},
          {"name":"SAN BENITO (TIPITAPA)","code":"NI-MN-SBT"},
          {"name":"SAN FRANCISCO LIBRE","code":"NI-MN-SFL"},
          {"name":"SAN RAFAEL DEL SUR","code":"NI-MN-SRS"},
          {"name":"TIPITAPA","code":"NI-MN-TPA"},
          {"name":"VILLA EL CARMEN","code":"NI-MN-VEC"},]
      },
      {
        name: 'Masaya',
        code: 'NI-MS',
        cities: [{"name":"NINDIRI","code":"NI-MS-NII"},
          {"name":"NIQUINOHOMO","code":"NI-MS-NQO"},
          {"name":"SAN JUAN DE ORIENTE","code":"NI-MS-SJO"},
          {"name":"TISMA","code":"NI-MS-TIM"},]
      },
      {
        name: 'Matagalpa',
        code: 'NI-MT',
        cities: [{"name":"EL TUMA","code":"NI-MT-ETU"},
          {"name":"MATAGALPA","code":"NI-MT-TMP164376"},
          {"name":"MATIGUÁS","code":"NI-MT-MAT"},
          {"name":"SÉBACO","code":"NI-MT-SEB"},]
      },
      {
        name: 'Nueva Segovia',
        code: 'NI-NS',
        cities: [{"name":"OCOTAL","code":"NI-NS-OCO"},
          {"name":"QUILALI","code":"NI-NS-QUI"},]
      },
      {
        name: 'Río San Juan',
        code: 'NI-SJ',
        cities: []
      },
      {
        name: 'Rivas',
        code: 'NI-RI',
        cities: []
      }
    ]
  },
  {
    name: 'Panamá',
    code: 'PA',
    dialCode: '+507',
    flag: 'flag_panama.png',
    states: [
      {
        name: 'Bocas del Toro',
        code: 'PA-1',
        cities: [{"name":"BASTIMENTOS","code":"PA-1-BST"},
          {"name":"BOCAS DEL TORO","code":"PA-1-BOC"},
          {"name":"CAUCHERO","code":"PA-1-CCH"},
          {"name":"CHANGUINOLA","code":"PA-1-CHX"},
          {"name":"CHILIBRE","code":"PA-1-CHB"},
          {"name":"COCHIGRO","code":"PA-1-CCG"},
          {"name":"EL EMPALME","code":"PA-1-EEP"},
          {"name":"GUABITO","code":"PA-1-GBT"},
          {"name":"LA GLORIA","code":"PA-1-LGL"},
          {"name":"LAS DELICIAS","code":"PA-1-LDE"},
          {"name":"PUERTO ALMIRANTE","code":"PA-1-PAE"},
          {"name":"PUNTA LAUREL","code":"PA-1-PUL"},
          {"name":"PUNTA RÓBALO","code":"PA-1-PRB"},
          {"name":"RAMBALA","code":"PA-1-RBA"},
          {"name":"TIERRA OSCURA","code":"PA-1-TIE"},
          {"name":"VALLE DE AGUAS ARRIBA","code":"PA-1-VAA"},
          {"name":"VALLE DEL RISCO","code":"PA-1-VDR"},]
      },
      {
        name: 'Chiriquí',
        code: 'PA-4',
        cities: [{"name":"ALANJE","code":"PA-4-ALA"},
          {"name":"ALTO BOQUETE","code":"PA-4-ABQ"},
          {"name":"BACO","code":"PA-4-BAC"},
          {"name":"BÁGALA","code":"PA-4-BAG"},
          {"name":"BIJAGUAL","code":"PA-4-BJG"},
          {"name":"BOCA CHICA","code":"PA-4-BCC"},
          {"name":"BOCA DEL MONTE","code":"PA-4-BDM"},
          {"name":"BOQUERÓN","code":"PA-4-BOQ"},
          {"name":"BREÑÓN","code":"PA-4-BNN"},
          {"name":"BUGABA","code":"PA-4-PZA"},
          {"name":"CALDERA","code":"PA-4-CLD"},
          {"name":"CAÑAS GORDAS","code":"PA-4-CGS"},
          {"name":"CANTA GALLO","code":"PA-4-CGO"},
          {"name":"CERRO PUNTA","code":"PA-4-CPA"},
          {"name":"CHIRIQUÍ","code":"PA-4-CHQ"},
          {"name":"DIVALÁ","code":"PA-4-DIV"},
          {"name":"DOLEGA","code":"PA-4-DLG"},
          {"name":"DOMINICAL","code":"PA-4-DMN"},
          {"name":"EL BONGO","code":"PA-4-EBG"},
          {"name":"EL NANCITO","code":"PA-4-ENO"},
          {"name":"EL PORVENIR","code":"PA-4-PVE"},
          {"name":"EL PUERTO","code":"PA-4-EPU"},
          {"name":"EL TEJAR","code":"PA-4-ETJ"},
          {"name":"EL VELADERO","code":"PA-4-EVO"},
          {"name":"GÓMEZ","code":"PA-4-GMZ"},
          {"name":"GUABAL","code":"PA-4-GBL"},
          {"name":"GUACA","code":"PA-4-GUA"},
          {"name":"GUALACA","code":"PA-4-G8L"},
          {"name":"GUARUMAL","code":"PA-4-GRL"},
          {"name":"GUAYABAL","code":"PA-4-GYL"},
          {"name":"HORCONCITOS","code":"PA-4-HRC"},
          {"name":"JUAY","code":"PA-4-JUY"},
          {"name":"JUSTO FIDEL PALACIOS","code":"PA-4-JFP"},
          {"name":"LA CONCEPCION","code":"PA-4-LCO"},
          {"name":"LA ESTRELLA","code":"PA-4-LES"},
          {"name":"LAJAS DE TOLÉ","code":"PA-4-LJT"},
          {"name":"LAS LAJAS","code":"PA-4-LJS"},
          {"name":"LAS LOMAS","code":"PA-4-LLO"},
          {"name":"LOS ALGARROBOS","code":"PA-4-LAL"},
          {"name":"LOS ANASTACIOS","code":"PA-4-LSA"},
          {"name":"LOS ÁNGELES","code":"PA-4-LGE"},
          {"name":"LOS NARANJOS","code":"PA-4-LNR"},
          {"name":"MONTE LIRIO","code":"PA-4-MOL"},
          {"name":"PACORA","code":"PA-4-PCO"},
          {"name":"PAJA DE SOMBRERO","code":"PA-4-PAJ"},
          {"name":"PALMIRA","code":"PA-4-PMR"},
          {"name":"PALO GRANDE","code":"PA-4-PLG"},
          {"name":"PARAÍSO","code":"PA-4-PRA"},
          {"name":"PEDREGAL","code":"PA-4-PED"},
          {"name":"PLAZA DE CAISÁN","code":"PA-4-PLZ"},
          {"name":"POTRERILLOS","code":"PA-4-PRS"},
          {"name":"POTRERILLOS ABAJO","code":"PA-4-PAB"},
          {"name":"POTRERO DE CAÑA","code":"PA-4-PDC"},
          {"name":"PROGRESO","code":"PA-4-PZC"},
          {"name":"PUERTO ARMUELLES","code":"PA-4-AML"},
          {"name":"QUEBRADA DE PIEDRA","code":"PA-4-QDP"},
          {"name":"QUERÉVALO","code":"PA-4-QVL"},
          {"name":"RENACIMIENTO","code":"PA-4-RMT"},
          {"name":"RINCÓN","code":"PA-4-RIN"},
          {"name":"RIO SERENO","code":"PA-4-RSO"},
          {"name":"RODOLFO AGUILAR DELGADO","code":"PA-4-RUD"},
          {"name":"SAN ANDRÉS","code":"PA-4-SNA"},
          {"name":"SAN CARLOS","code":"PA-4-SCR"},
          {"name":"SAN FÉLIX","code":"PA-4-SFX"},
          {"name":"SAN JOSÉ DE DAVID","code":"PA-4-SJD"},
          {"name":"SAN JUAN","code":"PA-4-SJU"},
          {"name":"SAN LORENZO","code":"PA-4-NZO"},
          {"name":"SANTA CLARA","code":"PA-4-SAN"},
          {"name":"SANTA CRUZ","code":"PA-4-SCU"},
          {"name":"SANTA CRUZ","code":"PA-4-SCZ"},
          {"name":"SANTA LUCIA","code":"PA-4-SLA"},
          {"name":"SANTO DOMINGO","code":"PA-4-SDM"},
          {"name":"SANTO TOMÁS","code":"PA-4-SNT"},
          {"name":"SORTOVÁ","code":"PA-4-STV"},
          {"name":"TINAJAS","code":"PA-4-TIN"},
          {"name":"TOLÉ","code":"PA-4-TOL"},
          {"name":"VELADERO","code":"PA-4-VEL"},
          {"name":"VOLCÁN","code":"PA-4-VOL"},]
      },
      {
        name: 'Coclé',
        code: 'PA-2',
        cities: [{"name":"AGUADULCE","code":"PA-2-TMP124773"},
          {"name":"ANTÓN","code":"PA-2-ANT"},
          {"name":"BARRIOS UNIDOS","code":"PA-2-BUS"},
          {"name":"CABALLERO","code":"PA-2-CBL"},
          {"name":"CAÑAVERAL","code":"PA-2-CNV"},
          {"name":"CAPELLANÍA","code":"PA-2-CPL"},
          {"name":"CHIGUIRÍ ARRIBA","code":"PA-2-CGA"},
          {"name":"CIRUELITO","code":"PA-2-CRT"},
          {"name":"COCLÉ","code":"PA-2-CCL"},
          {"name":"EL CAÑO","code":"PA-2-ELN"},
          {"name":"EL CHIRÚ","code":"PA-2-ECH"},
          {"name":"EL CIRUELITO","code":"PA-2-ECU"},
          {"name":"EL COCO","code":"PA-2-CCO"},
          {"name":"EL COPÉ","code":"PA-2-ECP"},
          {"name":"EL CRISTO","code":"PA-2-ECS"},
          {"name":"EL HARINO","code":"PA-2-EHR"},
          {"name":"EL PALMAR","code":"PA-2-EPR"},
          {"name":"EL PICACHO","code":"PA-2-EPH"},
          {"name":"EL POTRERO","code":"PA-2-PTR"},
          {"name":"EL VALLE","code":"PA-2-EVL"},
          {"name":"GUZMÁN","code":"PA-2-GZN"},
          {"name":"LA PINTADA","code":"PA-2-LPT"},
          {"name":"LAS LOMAS","code":"PA-2-LAS"},
          {"name":"LAS TABLAS","code":"PA-2-LTB"},
          {"name":"LLANO GRANDE","code":"PA-2-LGN"},
          {"name":"LOS UVEROS","code":"PA-2-LOU"},
          {"name":"OLÁ","code":"PA-2-OLA"},
          {"name":"PENONOME","code":"PA-2-PEN"},
          {"name":"RÍO GRANDE","code":"PA-2-PGR"},
          {"name":"RÍO HATO","code":"PA-2-RHA"},
          {"name":"RÍO INDIO","code":"PA-2-RID"},
          {"name":"SANTA RITA","code":"PA-2-SRA"},
          {"name":"SCARLETT MARTINEZ INTL APT/RIO HATO","code":"PA-2-RIH"},
          {"name":"TOABRÉ","code":"PA-2-TOA"},
          {"name":"TOZA","code":"PA-2-TOZ"},
          {"name":"VISTA HERMOSA","code":"PA-2-VHE"},]
      },
      {
        name: 'Colón',
        code: 'PA-3',
        cities: [{"name":"ACHIOTE","code":"PA-3-ACH"},
          {"name":"BAHÍA LAS MINAS","code":"PA-3-PBM"},
          {"name":"BUENA VISTA","code":"PA-3-CON"},
          {"name":"CACIQUE","code":"PA-3-CCQ"},
          {"name":"CATIVÁ","code":"PA-3-CTV"},
          {"name":"COCLÉ DEL NORTE","code":"PA-3-CCN"},
          {"name":"COLÓN","code":"PA-3-ONX"},
          {"name":"COLÓN FREE ZONE","code":"PA-3-CFZ"},
          {"name":"CRISTÓBAL","code":"PA-3-CTB"},
          {"name":"EL GUABO","code":"PA-3-EGB"},
          {"name":"ESCOBAL","code":"PA-3-ESC"},
          {"name":"FUERTE DAVIS","code":"PA-3-FUA"},
          {"name":"GARROTE","code":"PA-3-GRR"},
          {"name":"GOBEA","code":"PA-3-GBE"},
          {"name":"ISLA GRANDE","code":"PA-3-IGE"},
          {"name":"LA ARENOSA","code":"PA-3-LAA"},
          {"name":"LA ENCANTADA","code":"PA-3-LEN"},
          {"name":"MARÍA CHIQUITA","code":"PA-3-MCH"},
          {"name":"MIGUEL DE LA BORDA","code":"PA-3-MDB"},
          {"name":"MIRAMAR","code":"PA-3-MIA"},
          {"name":"NOMBRE DE DIOS","code":"PA-3-NDD"},
          {"name":"NUEVA PROVIDENCIA","code":"PA-3-NPR"},
          {"name":"NUEVO CHAGRES","code":"PA-3-NCH"},
          {"name":"NUEVO SAN JUAN","code":"PA-3-NSJ"},
          {"name":"PALENQUE","code":"PA-3-PAL"},
          {"name":"PALMAS BELLAS","code":"PA-3-PLB"},
          {"name":"PALMIRA","code":"PA-3-PLM"},
          {"name":"PIÑA","code":"PA-3-PNA"},
          {"name":"PORTOBELO","code":"PA-3-PBE"},
          {"name":"PUERTO PILÓN","code":"PA-3-PUP"},
          {"name":"SABANITAS","code":"PA-3-SBT"},
          {"name":"SALAMANCA","code":"PA-3-SLM"},
          {"name":"SALUD","code":"PA-3-SLD"},
          {"name":"SANTA ISABEL","code":"PA-3-SIS"},
          {"name":"VIENTO FRÍO","code":"PA-3-VFR"},]
      },
      {
        name: 'Darién',
        code: 'PA-5',
        cities: [{"name":"AGUA FRIA","code":"PA-5-AGF"},
          {"name":"BAHÍA PIÑA","code":"PA-5-BFQ"},
          {"name":"BOCA DE CUPE","code":"PA-5-BDC"},
          {"name":"CAMOGANTÍ","code":"PA-5-CMG"},
          {"name":"CUCUNATÍ","code":"PA-5-CUC"},
          {"name":"EL REAL DE SANTA MARÍA","code":"PA-5-RSM"},
          {"name":"GARACHINÉ","code":"PA-5-GHE"},
          {"name":"LA CHORRERA","code":"PA-5-CRR"},
          {"name":"LA PALMA","code":"PA-5-PLP"},
          {"name":"METETÍ","code":"PA-5-MET"},
          {"name":"OCÚ","code":"PA-5-OCU"},
          {"name":"PAYA","code":"PA-5-PYA"},
          {"name":"PINOGANA","code":"PA-5-PGA"},
          {"name":"PÚCURO","code":"PA-5-PUC"},
          {"name":"PUERTO PIÑA","code":"PA-5-PPA"},
          {"name":"RIO CONGO ARRIBA","code":"PA-5-RCA"},
          {"name":"SAMBU","code":"PA-5-SAX"},
          {"name":"SANTA FE","code":"PA-5-SFW"},
          {"name":"SETEGANTÍ","code":"PA-5-STG"},
          {"name":"TAIMATÍ","code":"PA-5-TMT"},
          {"name":"TUCUTÍ","code":"PA-5-TUC"},
          {"name":"VACAMONTE","code":"PA-5-VMT"},
          {"name":"YAPE","code":"PA-5-YAP"},]
      },
      {
        name: 'Guna Yala',
        code: 'PA-KY',
        cities: [{"name":"CORAZÓN DE JESÚS","code":"PA-KY-CZJ"},
          {"name":"PUERTO OBALDÍA","code":"PA-KY-PUE"},
          {"name":"RÍO AZÚCAR","code":"PA-KY-RIZ"},
          {"name":"TICANTIQUÍ","code":"PA-KY-TJC"},
          {"name":"TUBUALÁ","code":"PA-KY-TUW"},]
      },
      {
        name: 'Herrera',
        code: 'PA-6',
        cities: [{"name":"CABUYA","code":"PA-6-CYA"},
          {"name":"CERRO LARGO","code":"PA-6-CLG"},
          {"name":"CHEPO","code":"PA-6-CHO"},
          {"name":"CHITRE","code":"PA-6-CTD"},
          {"name":"CHUMICAL","code":"PA-6-CML"},
          {"name":"CHUPAMPA","code":"PA-6-CPP"},
          {"name":"DIVISA","code":"PA-6-DVA"},
          {"name":"EL BARRERO","code":"PA-6-ELB"},
          {"name":"EL CALABACITO","code":"PA-6-ECL"},
          {"name":"EL CAPURÍ","code":"PA-6-ELP"},
          {"name":"EL CEDRO","code":"PA-6-ECD"},
          {"name":"EL LIMÓN","code":"PA-6-ELL"},
          {"name":"EL PÁJARO","code":"PA-6-EPJ"},
          {"name":"EL PEDREGOSO","code":"PA-6-EPG"},
          {"name":"EL TORO","code":"PA-6-ETR"},
          {"name":"ENTRADERO DEL CASTILLO","code":"PA-6-EDC"},
          {"name":"LAS CABRAS","code":"PA-6-LCB"},
          {"name":"LAS MINAS","code":"PA-6-MNP"},
          {"name":"LLANO DE LA CRUZ","code":"PA-6-LCZ"},
          {"name":"LLANO GRANDE","code":"PA-6-LLA"},
          {"name":"LOS CANELOS","code":"PA-6-LCS"},
          {"name":"LOS CERROS DE PAJA","code":"PA-6-LCP"},
          {"name":"LOS POZOS","code":"PA-6-LSP"},
          {"name":"MENCHACA","code":"PA-6-MEN"},
          {"name":"PARÍS","code":"PA-6-PIS"},
          {"name":"PARITA","code":"PA-6-PAR"},
          {"name":"PEÑAS CHATAS","code":"PA-6-PNC"},
          {"name":"PESÉ","code":"PA-6-PES"},
          {"name":"PORTOBELILLO","code":"PA-6-PTB"},
          {"name":"POTUGA","code":"PA-6-POT"},
          {"name":"QUEBRADA DEL ROSARIO ARRIBA","code":"PA-6-QDR"},
          {"name":"QUEBRADA EL CIPRIÁN","code":"PA-6-QEC"},
          {"name":"RINCÓN HONDO","code":"PA-6-RHO"},]
      },
      {
        name: 'Los Santos',
        code: 'PA-7',
        cities: [{"name":"AGUA BUENA","code":"PA-7-ABA"},
          {"name":"AGUA BUENA, TONOSI","code":"PA-7-ABN"},
          {"name":"ALTOS DE GÜERA","code":"PA-7-ADG"},
          {"name":"BAHÍA HONDA","code":"PA-7-BHH"},
          {"name":"BAJO CORRAL","code":"PA-7-BCL"},
          {"name":"BAJOS DE GÜERA","code":"PA-7-BGA"},
          {"name":"BAYANO","code":"PA-7-BAY"},
          {"name":"CAMBUTAL","code":"PA-7-CMB"},
          {"name":"CAÑAS","code":"PA-7-CNS"},
          {"name":"CHEPO","code":"PA-7-CHE"},
          {"name":"CHUPÁ","code":"PA-7-CUP"},
          {"name":"COROZAL","code":"PA-7-CRZ"},
          {"name":"EL BEBEDERO","code":"PA-7-EBD"},
          {"name":"EL CACAO","code":"PA-7-ECC"},
          {"name":"EL CAÑAFÍSTULO","code":"PA-7-ELF"},
          {"name":"EL CARATE","code":"PA-7-ELT"},
          {"name":"EL CEDRO","code":"PA-7-ECR"},
          {"name":"EL CIRIAL (ISLA IGUANA)","code":"PA-7-ECI"},
          {"name":"EL COCAL","code":"PA-7-ELO"},
          {"name":"EL CORTEZO","code":"PA-7-ECT"},
          {"name":"EL EJIDO","code":"PA-7-EJO"},
          {"name":"EL ESPINAL","code":"PA-7-EES"},
          {"name":"EL GUÁSIMO","code":"PA-7-EGU"},
          {"name":"EL MACANO","code":"PA-7-EMC"},
          {"name":"EL MANANTIAL","code":"PA-7-EMN"},
          {"name":"EL MUÑOZ","code":"PA-7-EMZ"},
          {"name":"EL PEDREGOSO","code":"PA-7-EPD"},
          {"name":"FLORES","code":"PA-7-FLZ"},
          {"name":"GUARARÉ","code":"PA-7-GRE"},
          {"name":"GUARARÉ ARRIBA","code":"PA-7-GAR"},
          {"name":"HERRERA","code":"PA-7-HER"},
          {"name":"ISLA DE CAÑAS","code":"PA-7-IDC"},
          {"name":"LA ARENA","code":"PA-7-LRN"},
          {"name":"LA COLORADA","code":"PA-7-LCL"},
          {"name":"LA ENEA","code":"PA-7-LEA"},
          {"name":"LA ESPIGADILLA","code":"PA-7-LSS"},
          {"name":"LA GARITA","code":"PA-7-LGR"},
          {"name":"LA MESA","code":"PA-7-LME"},
          {"name":"LA MIEL","code":"PA-7-LAM"},
          {"name":"LA MIEL","code":"PA-7-LML"},
          {"name":"LA PALMA","code":"PA-7-LPL"},
          {"name":"LA PASERA","code":"PA-7-LPA"},
          {"name":"LA TIZA","code":"PA-7-LTZ"},
          {"name":"LA TRONOSA","code":"PA-7-LTO"},
          {"name":"LA VILLA DE LOS SANTOS","code":"PA-7-LVS"},
          {"name":"LAJAMINA","code":"PA-7-LJA"},
          {"name":"LAS CRUCES","code":"PA-7-LCU"},
          {"name":"LAS GUABAS","code":"PA-7-LAG"},
          {"name":"LAS PALMAS","code":"PA-7-LPS"},
          {"name":"LAS PALMITAS","code":"PA-7-LLT"},
          {"name":"LAS TRANCAS","code":"PA-7-LAT"},
          {"name":"LLANO ABAJO","code":"PA-7-LAB"},
          {"name":"LLANO DE PIEDRA","code":"PA-7-LPI"},
          {"name":"LLANO LARGO","code":"PA-7-LRG"},
          {"name":"LOS DESTILADEROS","code":"PA-7-LDS"},
          {"name":"LOS OLIVOS","code":"PA-7-LOO"},
          {"name":"LOS SANTOS","code":"PA-7-PZB"},
          {"name":"MACARACAS","code":"PA-7-MAC"},
          {"name":"MADROÑO","code":"PA-7-MAD"},
          {"name":"MARIABÉ","code":"PA-7-MAR"},
          {"name":"MOGOLLON","code":"PA-7-MOG"},
          {"name":"NUARIO","code":"PA-7-NUA"},
          {"name":"ORIA","code":"PA-7-ORI"},
          {"name":"PALMIRA","code":"PA-7-PMA"},
          {"name":"PARAÍSO","code":"PA-7-PRO"},
          {"name":"PARITILLA","code":"PA-7-PTL"},
          {"name":"PEDREGAL","code":"PA-7-PDL"},
          {"name":"PEÑA BLANCA","code":"PA-7-PNB"},
          {"name":"PERALES","code":"PA-7-PEL"},
          {"name":"POCRÍ","code":"PA-7-PCI"},
          {"name":"PUERTO ESCONDIDO","code":"PA-7-PUT"},
          {"name":"PUNTA MALA","code":"PA-7-PTM"},
          {"name":"PUNTA TIGRE","code":"PA-7-PTG"},
          {"name":"RÍO HONDO","code":"PA-7-RHD"},
          {"name":"SAN JOSÉ","code":"PA-7-SJO"},
          {"name":"SANTO DOMINGO","code":"PA-7-SDG"},
          {"name":"TONOSÍ","code":"PA-7-TON"},
          {"name":"TRES QUEBRADAS","code":"PA-7-TQU"},
          {"name":"VALLE RICO","code":"PA-7-VRO"},
          {"name":"VALLERRIQUITO","code":"PA-7-VLL"},
          {"name":"VILLA LOURDES","code":"PA-7-VIL"},]
      },
      {
        name: 'Panamá',
        code: 'PA-8',
        cities: [{"name":"ALBROOK","code":"PA-8-VQR"},
          {"name":"ALCALDE DÍAZ","code":"PA-8-ADZ"},
          {"name":"ANCON","code":"PA-8-ACO"},
          {"name":"ARENOSA","code":"PA-8-ASA"},
          {"name":"ARRAIJÁN","code":"PA-8-ARR"},
          {"name":"BELISARIO FRIAS","code":"PA-8-BFS"},
          {"name":"BRUJAS","code":"PA-8-BJS"},
          {"name":"CAIMITILLO","code":"PA-8-CMT"},
          {"name":"CAÑITA","code":"PA-8-CNT"},
          {"name":"CERRO AZUL","code":"PA-8-CZL"},
          {"name":"CHEPILLO","code":"PA-8-CHP"},
          {"name":"CHILIBRE","code":"PA-8-CHI"},
          {"name":"CHIMÁN","code":"PA-8-CHN"},
          {"name":"CIUDAD DE PANAMÁ","code":"PA-8-TMP124729"},
          {"name":"COCOLI","code":"PA-8-CLI"},
          {"name":"CURUNDU","code":"PA-8-CUR"},
          {"name":"EL CANGREJO","code":"PA-8-ELC"},
          {"name":"EL CHORRILLO","code":"PA-8-ERR"},
          {"name":"EL DORADO","code":"PA-8-DOR"},
          {"name":"FORT SHERMAN","code":"PA-8-FOS"},
          {"name":"GONZALO VÁSQUEZ","code":"PA-8-GVZ"},
          {"name":"HOWARD","code":"PA-8-ARD"},
          {"name":"JUAN DIAZ","code":"PA-8-TMP124808"},
          {"name":"LA ENSENADA","code":"PA-8-LAE"},
          {"name":"LA ESMERALDA","code":"PA-8-LEM"},
          {"name":"LA GUINEA","code":"PA-8-LGU"},
          {"name":"LA ZANGUENGA","code":"PA-8-LZA"},
          {"name":"LAS CUMBRES","code":"PA-8-LUM"},
          {"name":"LAS MAÑANITAS","code":"PA-8-LMN"},
          {"name":"LAS MARGARITAS","code":"PA-8-LMG"},
          {"name":"LAS YAYAS ADENTRO","code":"PA-8-LYA"},
          {"name":"LLANITO VERDE","code":"PA-8-LLV"},
          {"name":"LLANO SANTO","code":"PA-8-LST"},
          {"name":"MARCOS A. GELABERT INTERNATIONAL APT/PANAMÁ","code":"PA-8-PAC"},
          {"name":"MELONES","code":"PA-8-MEL"},
          {"name":"OTOQUE OCCIDENTE","code":"PA-8-OTO"},
          {"name":"OTOQUE ORIENTE","code":"PA-8-OTQ"},
          {"name":"PACORA","code":"PA-8-PCR"},
          {"name":"PANAMA CANAL","code":"PA-8-PCN"},
          {"name":"PANAMA CITY","code":"PA-8-TMP124818"},
          {"name":"PANAMA PACIFICO","code":"PA-8-PPF"},
          {"name":"PANAMÁ, CIUDAD DE","code":"PA-8-PTY"},
          {"name":"PARQUE LEFEVRE","code":"PA-8-PLF"},
          {"name":"PÁSIGA","code":"PA-8-PSG"},
          {"name":"PEDREGAL","code":"PA-8-PDE"},
          {"name":"PEDREGAL","code":"PA-8-PDG"},
          {"name":"PEDREGAL","code":"PA-8-PDR"},
          {"name":"PLAYA CORONADO","code":"PA-8-COR"},
          {"name":"PUEBLO NUEVO","code":"PA-8-PVO"},
          {"name":"RÍO ABAJO","code":"PA-8-RAB"},
          {"name":"RODMAN","code":"PA-8-ROD"},
          {"name":"ROUSSEAU","code":"PA-8-ROU"},
          {"name":"SABOGA","code":"PA-8-SAB"},
          {"name":"SAJALICES","code":"PA-8-SJL"},
          {"name":"SALSIPUEDES","code":"PA-8-SAL"},
          {"name":"SAN FRANCISCO","code":"PA-8-SFA"},
          {"name":"SAN MARTÍN","code":"PA-8-SMT"},
          {"name":"SAN MIGUELITO","code":"PA-8-SMG"},
          {"name":"TABOGA","code":"PA-8-TBO"},
          {"name":"TOCUMEN","code":"PA-8-TOC"},
          {"name":"TORTI","code":"PA-8-TOR"},
          {"name":"UNIÓN SANTEÑA","code":"PA-8-UNS"},
          {"name":"VACAMONTE","code":"PA-8-VAC"},
          {"name":"ZANGUENGA","code":"PA-8-ZAN"},]
      },
      {
        name: 'Panamá Oeste',
        code: 'PA-10',
        cities: [{"name":"BAHÍA HONDA","code":"PA-10-BHP"},
          {"name":"BARNIZAL","code":"PA-10-BNZ"},
          {"name":"BISVALLES","code":"PA-10-BVS"},
          {"name":"CALIDONIA","code":"PA-10-CLA"},
          {"name":"CALOVÉBORA","code":"PA-10-CLV"},
          {"name":"CANTO DEL LLANO","code":"PA-10-CDL"},
          {"name":"CARLOS SANTANA AVILA","code":"PA-10-CSA"},
          {"name":"CATORCE DE NOVIEMBRE","code":"PA-10-CDN"},
          {"name":"CEBACO","code":"PA-10-CBC"},
          {"name":"CERRO DE CASA","code":"PA-10-CCA"},
          {"name":"COROZAL","code":"PA-10-COZ"},
          {"name":"CORRAL FALSO","code":"PA-10-CFL"},
          {"name":"EDWIN FABREGA","code":"PA-10-EDF"},
          {"name":"EL ALTO","code":"PA-10-ELA"},
          {"name":"EL AROMILLO","code":"PA-10-ELM"},
          {"name":"EL HIGO","code":"PA-10-LHI"},
          {"name":"EL LLANO","code":"PA-10-LLL"},
          {"name":"EL MARAÑÓN","code":"PA-10-ENN"},
          {"name":"EL MARÍA","code":"PA-10-EMR"},
          {"name":"EL PANTANO","code":"PA-10-EPT"},
          {"name":"EL PICADOR","code":"PA-10-EPC"},
          {"name":"EL POTRERO","code":"PA-10-PRR"},
          {"name":"EL PRADO","code":"PA-10-EPO"},
          {"name":"EL RINCON","code":"PA-10-RNN"},
          {"name":"GUARUMAL","code":"PA-10-GRA"},
          {"name":"HICACO","code":"PA-10-HCC"},
          {"name":"ISLA GOBERNADORA","code":"PA-10-IGA"},
          {"name":"LA CARRILLO","code":"PA-10-LCR"},
          {"name":"LA COLORADA","code":"PA-10-LCA"},
          {"name":"LA GARCEANA","code":"PA-10-LGA"},
          {"name":"LA LAGUNA","code":"PA-10-LLU"},
          {"name":"LA MESA","code":"PA-10-LMS"},
          {"name":"LA MONTAÑUELA","code":"PA-10-LMT"},
          {"name":"LA PEÑA","code":"PA-10-LPN"},
          {"name":"LA RAYA DE CALOBRE","code":"PA-10-LRC"},
          {"name":"LA RAYA DE SANTA MARÍA","code":"PA-10-LRS"},
          {"name":"LA SOLEDAD","code":"PA-10-LDD"},
          {"name":"LA SOLEDAD","code":"PA-10-LSD"},
          {"name":"LA YEGUADA","code":"PA-10-LYE"},
          {"name":"LAS CRUCES","code":"PA-10-LAC"},
          {"name":"LAS GUÍAS","code":"PA-10-LGI"},
          {"name":"LAS HUACAS","code":"PA-10-LHU"},
          {"name":"LAS PALMAS","code":"PA-10-LAP"},
          {"name":"LLANO GRANDE","code":"PA-10-LAN"},
          {"name":"LOLÁ","code":"PA-10-LOL"},
          {"name":"LOS ALGARROBOS","code":"PA-10-LOG"},
          {"name":"LOS CASTILLOS","code":"PA-10-LCT"},
          {"name":"LOS HATILLOS","code":"PA-10-LHA"},
          {"name":"LOS MILAGROS","code":"PA-10-LMI"},
          {"name":"LOS VALLES","code":"PA-10-LVA"},
          {"name":"MONJARÁS","code":"PA-10-MON"},
          {"name":"MONTIJO","code":"PA-10-MNJ"},
          {"name":"PIXVAE","code":"PA-10-PXV"},
          {"name":"PUERTO VIDAL","code":"PA-10-PVL"},
          {"name":"QUEBRADA DE ORO","code":"PA-10-QDO"},
          {"name":"QUEBRO","code":"PA-10-QUB"},
          {"name":"RINCÓN LARGO","code":"PA-10-RLO"},
          {"name":"RÍO GRANDE","code":"PA-10-GDE"},
          {"name":"SAN BARTOLO","code":"PA-10-SBA"},
          {"name":"SAN JOSÉ","code":"PA-10-SJE"},
          {"name":"SAN JOSÉ","code":"PA-10-SNJ"},
          {"name":"SAN JUAN","code":"PA-10-SJA"},
          {"name":"SAN MARCELO","code":"PA-10-SNM"},
          {"name":"SAN MARTIN DE PORRES","code":"PA-10-SMP"},
          {"name":"SAN PEDRO DEL ESPINO","code":"PA-10-SPE"},
          {"name":"SANTA FE","code":"PA-10-SFE"},
          {"name":"SONÁ","code":"PA-10-S2N"},
          {"name":"URRACA","code":"PA-10-URR"},
          {"name":"VIGUÍ","code":"PA-10-VIG"},
          {"name":"VISTA ALEGRE","code":"PA-10-VIS"},
          {"name":"ZAPOTILLO","code":"PA-10-ZAP"},]
      },
      {
        name: 'Veraguas',
        code: 'PA-9',
        cities: [{"name":"AMADOR","code":"PA-9-AMD"},
          {"name":"ARENAS","code":"PA-9-ARS"},
          {"name":"AROSEMENA","code":"PA-9-AMA"},
          {"name":"ATALAYA","code":"PA-9-ATA"},
          {"name":"BEJUCO","code":"PA-9-BJO"},
          {"name":"BUENOS AIRES","code":"PA-9-BAS"},
          {"name":"CABUYA","code":"PA-9-CBA"},
          {"name":"CAIMITO","code":"PA-9-CAM"},
          {"name":"CALOBRE","code":"PA-9-CLB"},
          {"name":"CAMPANA","code":"PA-9-CMP"},
          {"name":"CAPIRA","code":"PA-9-CAP"},
          {"name":"CERMEÑO","code":"PA-9-CMO"},
          {"name":"CHAME","code":"PA-9-CHM"},
          {"name":"CHICÁ","code":"PA-9-CHC"},
          {"name":"CIRÍ DE LOS SOTOS","code":"PA-9-CLS"},
          {"name":"CIRÍ GRANDE","code":"PA-9-CRP"},
          {"name":"EL CACAO","code":"PA-9-ECA"},
          {"name":"EL COCO","code":"PA-9-ECO"},
          {"name":"EL ESPINO","code":"PA-9-EEN"},
          {"name":"EL HIGO","code":"PA-9-EHI"},
          {"name":"EL LÍBANO","code":"PA-9-LLI"},
          {"name":"GUADALUPE","code":"PA-9-GLP"},
          {"name":"GUAYABITO","code":"PA-9-GYO"},
          {"name":"LA LAGUNA","code":"PA-9-LLG"},
          {"name":"LA TRINIDAD","code":"PA-9-LTR"},
          {"name":"LAS LAJAS","code":"PA-9-LAJ"},
          {"name":"LAS OLLAS ARRIBA","code":"PA-9-LOA"},
          {"name":"LAS UVAS","code":"PA-9-LUV"},
          {"name":"LÍDICE","code":"PA-9-LDC"},
          {"name":"LOS LLANITOS","code":"PA-9-LON"},
          {"name":"MENDOZA","code":"PA-9-MED"},
          {"name":"NUEVA GORGONA","code":"PA-9-NGA"},
          {"name":"NUEVO EMPERADOR","code":"PA-9-NEM"},
          {"name":"PLAYA LEONA","code":"PA-9-PLY"},
          {"name":"PUERTO CAIMITO","code":"PA-9-PCA"},
          {"name":"PUNTA CHAME","code":"PA-9-PCH"},
          {"name":"SAJALICES","code":"PA-9-SAJ"},
          {"name":"SANTA RITA","code":"PA-9-STR"},
          {"name":"SANTA ROSA","code":"PA-9-SNR"},
          {"name":"SORÁ","code":"PA-9-SOR"},
          {"name":"TONOSÍ","code":"PA-9-TNS"},
          {"name":"VERACRUZ","code":"PA-9-VCZ"},
          {"name":"VILLA CARMEN","code":"PA-9-VCA"},
          {"name":"VILLA ROSARIO","code":"PA-9-VIR"},]
      },
    ]
  },
  {
    name: 'Paraguay',
    code: 'PY',
    dialCode: '+595',
    flag: 'flag_paraguay.png',
    states: [
      {
        name: 'Alto Paraguay',
        code: 'PY-16',
        cities: [{"name":"BAHIA NEGRA","code":"PY-16-BFA"},
          {"name":"PUERTO GUARANI","code":"PY-16-PGU"},
          {"name":"PUERTO LEDA","code":"PY-16-PBT"},]
      },
      {
        name: 'Alto Paraná',
        code: 'PY-10',
        cities: [{"name":"CIUDAD DEL ESTE","code":"PY-10-TMP124939"},
          {"name":"HERNANDARIAS","code":"PY-10-HER"},
          {"name":"MINGA GUAZU","code":"PY-10-MIN"},
          {"name":"PRESIDENTE FRANCO","code":"PY-10-JPF"},
          {"name":"SAN ALBERTO","code":"PY-10-SBT"},
          {"name":"SANTA RITA","code":"PY-10-JSR"},
          {"name":"SANTA ROSA DEL MONDAY","code":"PY-10-SRM"},]
      },
      {
        name: 'Amambay',
        code: 'PY-13',
        cities: []
      },
      {
        name: 'Asunción',
        code: 'PY-ASU',
        cities: [{"name":"ASUNCIÓN","code":"PY-ASU-ASU"},
          {"name":"FENIX","code":"PY-ASU-FNX"},]
      },
      {
        name: 'Boquerón',
        code: 'PY-19',
        cities: [{"name":"COLONIA NEULAND","code":"PY-19-CND"},
          {"name":"LOMA PLATA","code":"PY-19-MPT"},
          {"name":"MAYOR INFANTE RIVAROLA","code":"PY-19-INF"},]
      },
      {
        name: 'Caaguazú',
        code: 'PY-5',
        cities: [{"name":"CAAGUAZU","code":"PY-5-GGU"},
          {"name":"CORONEL OVIEDO","code":"PY-5-COO"},]
      },
      {
        name: 'Caazapá',
        code: 'PY-6',
        cities: []
      },
      {
        name: 'Canindeyú',
        code: 'PY-14',
        cities: [{"name":"COLONIA CATUETE","code":"PY-14-CTT"},
          {"name":"KATUETE","code":"PY-14-KTT"},
          {"name":"NUEVA ESPERANZA","code":"PY-14-NES"},
          {"name":"SALTO DEL GUAIRÁ","code":"PY-14-CAN"},]
      },
      {
        name: 'Central',
        code: 'PY-11',
        cities: [{"name":"AREGUA","code":"PY-11-ARE"},
          {"name":"BARRIO CAACUPÉ MÍ","code":"PY-11-BCM"},
          {"name":"CAPIATA","code":"PY-11-CCP"},
          {"name":"COLONIA MARIANO ROQUE ALONSO","code":"PY-11-MRA"},
          {"name":"GUARAMBARE","code":"PY-11-GUA"},
          {"name":"ITAUGUA","code":"PY-11-CIG"},
          {"name":"J. E. ESTIGARRIBIA","code":"PY-11-JEE"},
          {"name":"LAMBARE","code":"PY-11-LAM"},
          {"name":"LIMPIO","code":"PY-11-LPO"},
          {"name":"LUQUE","code":"PY-11-LUQ"},
          {"name":"NEMBY","code":"PY-11-TQU"},
          {"name":"PUERTO SARA","code":"PY-11-PSA"},
          {"name":"YPACARAI","code":"PY-11-YCI"},
          {"name":"YPANE","code":"PY-11-YPE"},]
      },
      {
        name: 'Concepción',
        code: 'PY-1',
        cities: [{"name":"CALERA CUE","code":"PY-1-CAC"},
          {"name":"CONCEPCIÓN","code":"PY-1-CNP"},
          {"name":"ITACURUBI","code":"PY-1-ITC"},
          {"name":"PUERTO PINASCO","code":"PY-1-PPS"},]
      },
      {
        name: 'Cordillera',
        code: 'PY-3',
        cities: [{"name":"ARROYOS Y ESTEROS","code":"PY-3-AYE"},
          {"name":"CAACUPÉ","code":"PY-3-CAA"},
          {"name":"CARAGUATAY","code":"PY-3-CRG"},
          {"name":"EUSEBIO AYALA","code":"PY-3-WQA"},
          {"name":"ITACURUBI","code":"PY-3-ITA"},
          {"name":"SAN BERNARDINO","code":"PY-3-TMP124872"},
          {"name":"TOBATI","code":"PY-3-TBA"},]
      },
      {
        name: 'Guairá',
        code: 'PY-4',
        cities: [{"name":"ITURBE","code":"PY-4-IUB"},
          {"name":"VILLARRICA","code":"PY-4-VLR"},]
      },
      {
        name: 'Itapúa',
        code: 'PY-7',
        cities: [{"name":"BELLA VISTA","code":"PY-7-BVT"},
          {"name":"CARLOS ANTONIO LOPEZ","code":"PY-7-CAL"},
          {"name":"CARMEN DEL PARANÁ","code":"PY-7-CDP"},
          {"name":"ENCARNACIÓN","code":"PY-7-ENO"},
          {"name":"FRAM","code":"PY-7-FRM"},
          {"name":"HOHENAU","code":"PY-7-HHU"},
          {"name":"ITAPUA POTY","code":"PY-7-TPT"},
          {"name":"PUERTO SAN JOSÉ","code":"PY-7-PSJ"},
          {"name":"SAN JOSÉ","code":"PY-7-SJE"},
          {"name":"SAN PEDRO DEL PARANÁ","code":"PY-7-SPP"},
          {"name":"TRINIDAD","code":"PY-7-TND"},]
      },
      {
        name: 'Misiones',
        code: 'PY-8',
        cities: [{"name":"SAN YGNACIO","code":"PY-8-SYG"},]
      },
      {
        name: 'Ñeembucú',
        code: 'PY-12',
        cities: []
      },
      {
        name: 'Paraguarí',
        code: 'PY-9',
        cities: [{"name":"VILLA FLORIDA","code":"PY-9-VFL"},
          {"name":"YBYCUI","code":"PY-9-Y2U"},]
      },
      {
        name: 'Presidente Hayes',
        code: 'PY-15',
        cities: [{"name":"BENJAMIN ACEVAL","code":"PY-15-BAL"},
          {"name":"ESTANCIA LOMA PYTA","code":"PY-15-ELP"},
          {"name":"JOSÉ FALCÓN","code":"PY-15-JFC"},
          {"name":"VILLA HAYES","code":"PY-15-VHS"},]
      },
      {
        name: 'San Pedro',
        code: 'PY-2',
        cities: [{"name":"PUERTO ANTEQUERA","code":"PY-2-PAN"},
          {"name":"SAN PEDRO DE YCUAMANDIYU","code":"PY-2-PYS"},]
      },
    ]
  },
  {
    name: 'Perú',
    code: 'PE',
    dialCode: '+51',
    flag: 'flag_peru.png',
    states: [
      {
        name: 'Amazonas',
        code: 'PE-AMA',
        cities: [
          {"name":"BAGUA","code":"PE-AMA-BAG"},
          {"name":"RODRÍGUEZ DE MENDOZA","code":"PE-AMA-RIM"},
        ]
      },
      {
        name: 'Áncash',
        code: 'PE-ANC',
        cities: [
          {"name":"AIJA","code":"PE-ANC-AIJ"},
          {"name":"CARHUAZ","code":"PE-ANC-CHZ"},
          {"name":"CASMA","code":"PE-ANC-CAS"},
          {"name":"CORONGO","code":"PE-ANC-COR"},
          {"name":"HUARI","code":"PE-ANC-HUI"},
          {"name":"OCROS","code":"PE-ANC-OCR"},
          {"name":"POMABAMBA","code":"PE-ANC-POM"},
          {"name":"PUNTA LOBITOS","code":"PE-ANC-PLO"},
          {"name":"RECUAY","code":"PE-ANC-REC"},
          {"name":"SIHUAS","code":"PE-ANC-SIH"},
          {"name":"YUNGAY","code":"PE-ANC-YUN"},
        ]
      },
      {
        name: 'Apurímac',
        code: 'PE-APU',
        cities: [
          {"name":"ABANCAY","code":"PE-APU-AB4"},
          {"name":"ANTABAMBA","code":"PE-APU-ANT"},
          {"name":"CHINCHEROS","code":"PE-APU-CHS"},
        ]
      },
      {
        name: 'Arequipa',
        code: 'PE-ARE',
        cities: [
          {"name":"ACARÍ","code":"PE-ARE-ACA"},
          {"name":"AREQUIPA","code":"PE-ARE-TMP124976"},
          {"name":"CAILLOMA","code":"PE-ARE-CAI"},
          {"name":"LA PLANCHADA","code":"PE-ARE-LPN"},
        ]
      },
      {
        name: 'Ayacucho',
        code: 'PE-AYA',
        cities: [
          {"name":"CANGALLO","code":"PE-AYA-CAN"},
          {"name":"HUANCA SANCOS","code":"PE-AYA-HSA"},
          {"name":"HUANTA","code":"PE-AYA-HNT"},
          {"name":"MOYOBAMBA","code":"PE-AYA-MBA"},
          {"name":"VILCASHUAMÁN","code":"PE-AYA-VHN"},
        ]
      },
      {
        name: 'Cajamarca',
        code: 'PE-CAJ',
        cities: [
          {"name":"CAJABAMBA","code":"PE-CAJ-CAJ"},
          {"name":"CAJAMARCA","code":"PE-CAJ-TMP124996"},
          {"name":"CELENDÍN","code":"PE-CAJ-CEL"},
          {"name":"CHOTA","code":"PE-CAJ-CHT"},
          {"name":"CONTUMAZÁ","code":"PE-CAJ-COZ"},
          {"name":"CUTERVO","code":"PE-CAJ-CUT"},
          {"name":"SAN IGNACIO","code":"PE-CAJ-SAI"},
          {"name":"SAN PABLO","code":"PE-CAJ-SNP"},
        ]
      },
      {
        name: 'Callao',
        code: 'PE-CAL',
        cities: [
          {"name":"CARMEN DE LA LEGUA","code":"PE-CAL-CDL"},
          {"name":"LA PERLA","code":"PE-CAL-TMP125004"},
          {"name":"OQUENDO","code":"PE-CAL-OQU"},
        ]
      },
      {
        name: 'Cusco',
        code: 'PE-CUS',
        cities: [
          {"name":"ACOMAYO","code":"PE-CUS-ACO"},
          {"name":"ANTA","code":"PE-CUS-TMP125006"},
          {"name":"CALCA","code":"PE-CUS-CAC"},
          {"name":"CUSCO","code":"PE-CUS-TMP125008"},
          {"name":"CUZCO","code":"PE-CUS-CUZ"},
          {"name":"MARA","code":"PE-CUS-MAR"},
          {"name":"PARURO","code":"PE-CUS-PAO"},
          {"name":"PAUCARTAMBO","code":"PE-CUS-PAU"},
          {"name":"QUINCE MIL","code":"PE-CUS-UMI"},
          {"name":"TAMBO DE MORA","code":"PE-CUS-TDM"},
          {"name":"URUBAMBA","code":"PE-CUS-URU"},
        ]
      },
      {
        name: 'Huancavelica',
        code: 'PE-HUV',
        cities: [
          {"name":"ACOBAMBA","code":"PE-HUV-ACB"},
          {"name":"CASTROVIRREYNA","code":"PE-HUV-CTY"},
          {"name":"CHURCAMPA","code":"PE-HUV-CHP"},
          {"name":"HUANCAVELICA","code":"PE-HUV-HUN"},
          {"name":"HUAYTARÁ","code":"PE-HUV-HYT"},
          {"name":"SULLANA","code":"PE-HUV-SUL"},
        ]
      },
      {
        name: 'Huánuco',
        code: 'PE-HUC',
        cities: [
          {"name":"AMBO","code":"PE-HUC-HAN"},
          {"name":"HUACAYBAMBA","code":"PE-HUC-HYB"},
          {"name":"LAURICOCHA","code":"PE-HUC-LCH"},
          {"name":"PUERTO INCA","code":"PE-HUC-PIA"},
          {"name":"TINGO MARÍA","code":"PE-HUC-TGI"},
        ]
      },
      {
        name: 'Ica',
        code: 'PE-ICA',
        cities: [
          {"name":"CHANCHAMAYO","code":"PE-ICA-CHA"},
          {"name":"CHINCHA ALTA","code":"PE-ICA-CAA"},
          {"name":"CHINCHA BAJA","code":"PE-ICA-CBJ"},
          {"name":"HACIENDA LAS MERCEDES","code":"PE-ICA-TMP125027"},
          {"name":"ICA","code":"PE-ICA-ICA"},
          {"name":"NASCA","code":"PE-ICA-NS8"},
          {"name":"PALPA","code":"PE-ICA-PAP"},
          {"name":"PARACAS","code":"PE-ICA-PRC"},
        ]
      },
      {
        name: 'Junín',
        code: 'PE-JUN',
        cities: [
          {"name":"CHUPACA","code":"PE-JUN-CUP"},
          {"name":"COMAS","code":"PE-JUN-TMP125042"},
          {"name":"CONCEPCIÓN","code":"PE-JUN-CCP"},
          {"name":"HUANCAYO","code":"PE-JUN-JHY"},
          {"name":"JUNÍN","code":"PE-JUN-JUN"},
          {"name":"LA MERCED","code":"PE-JUN-LMC"},
          {"name":"SAN AGUSTIN","code":"PE-JUN-TMP125049"},
          {"name":"SATIPO","code":"PE-JUN-SIP"},
          {"name":"TARMA","code":"PE-JUN-TRM"},
        ]
      },
      {
        name: 'La Libertad',
        code: 'PE-LAL',
        cities: [
          {"name":"CHEPEN","code":"PE-LAL-CHE"},
          {"name":"GUADALUPE","code":"PE-LAL-GDP"},
          {"name":"OTUZCO","code":"PE-LAL-OTU"},
          {"name":"PUEBLO LIBRE","code":"PE-LAL-CXZ"},
          {"name":"SANTIAGO DE CHUCO","code":"PE-LAL-SDC"},
          {"name":"TRUJILLO","code":"PE-LAL-TMP125068"},
          {"name":"VIRÚ","code":"PE-LAL-VIR"},
        ]
      },
      {
        name: 'Lambayeque',
        code: 'PE-LAM',
        cities: [
          {"name":"FERREÑAFE","code":"PE-LAM-FER"},
          {"name":"LAMBAYEQUE","code":"PE-LAM-LBQ"},
          {"name":"OLMOS","code":"PE-LAM-OLM"},
        ]
      },
      {
        name: 'Lima',
        code: 'PE-LIM',
        cities: [
          {"name":"ANCÓN","code":"PE-LIM-ANC"},
          {"name":"BARRANCA","code":"PE-LIM-BAA"},
          {"name":"CAJATAMBO","code":"PE-LIM-CJT"},
          {"name":"CANTA","code":"PE-LIM-CTA"},
          {"name":"CARABAYLLO","code":"PE-LIM-L8M"},
          {"name":"CERRO AZUL","code":"PE-LIM-CRZ"},
          {"name":"CHORILLOS","code":"PE-LIM-CHO"},
          {"name":"COMAS","code":"PE-LIM-COM"},
          {"name":"HUARAL","code":"PE-LIM-HUA"},
          {"name":"LA MOLINA","code":"PE-LIM-LAM"},
          {"name":"LA VICTORIA","code":"PE-LIM-TMP125084"},
          {"name":"LIMA","code":"PE-LIM-TMP125085"},
          {"name":"LINCE","code":"PE-LIM-LCE"},
          {"name":"LOS OLIVOS","code":"PE-LIM-LOV"},
          {"name":"LURIGANCHO","code":"PE-LIM-TMP125086"},
          {"name":"LURÍN","code":"PE-LIM-LUR"},
          {"name":"MAGDALENA","code":"PE-LIM-MAG"},
          {"name":"MALA","code":"PE-LIM-MAL"},
          {"name":"MONTERRICO CHICO","code":"PE-LIM-MCH"},
          {"name":"OYON","code":"PE-LIM-OYO"},
          {"name":"PACHACAMAC","code":"PE-LIM-PCM"},
          {"name":"PAMPA MELCHORITA","code":"PE-LIM-PMC"},
          {"name":"PRO","code":"PE-LIM-TMP125089"},
          {"name":"PUENTE PIEDRA","code":"PE-LIM-PPA"},
          {"name":"PUNTA HERMOSA","code":"PE-LIM-PHM"},
          {"name":"SAN BARTOLO","code":"PE-LIM-BAR"},
          {"name":"SAN BORJA/LIMA","code":"PE-LIM-LSB"},
          {"name":"SAN ISIDRO","code":"PE-LIM-SDR"},
          {"name":"SAN MARTÍN DE PORRAS","code":"PE-LIM-TMP125092"},
          {"name":"SAN VICENTE DE CANETE","code":"PE-LIM-L9M"},
          {"name":"SURCO","code":"PE-LIM-RCO"},
          {"name":"SURQUILLO","code":"PE-LIM-SUR"},
          {"name":"VILLA EL SALVADOR","code":"PE-LIM-VES"},
          {"name":"YAUYOS","code":"PE-LIM-YAY"},
        ]
      },
      {
        name: 'Loreto',
        code: 'PE-LOR',
        cities: [
          {"name":"CORONEL PORTILLO","code":"PE-LOR-ULY"},
          {"name":"LAGUNAS","code":"PE-LOR-LAG"},
          {"name":"PUNCHANA","code":"PE-LOR-PUA"},
          {"name":"REQUENA","code":"PE-LOR-REQ"},
        ]
      },
      {
        name: 'Madre de Dios',
        code: 'PE-MDD',
        cities: []
      },
      {
        name: 'Moquegua',
        code: 'PE-MOQ',
        cities: [{"name":"MOQUEGUA","code":"PE-MOQ-MOQ"},]
      },
      {
        name: 'Pasco',
        code: 'PE-PAS',
        cities: [{"name":"CERRO DE PASCO","code":"PE-PAS-PSC"},]
      },
      {
        name: 'Piura',
        code: 'PE-PIU',
        cities: [
          {"name":"BAYOVAR","code":"PE-PIU-PUB"},
          {"name":"HUANCABAMBA","code":"PE-PIU-HCB"},
        ]
      },
      {
        name: 'Puno',
        code: 'PE-PUN',
        cities: [
          {"name":"AZÁNGARO","code":"PE-PUN-AZA"},
          {"name":"CHUCUITO","code":"PE-PUN-CHC"},
          {"name":"DESAGUADERO","code":"PE-PUN-DES"},
          {"name":"HUANCANÉ","code":"PE-PUN-HNC"},
          {"name":"LAMPA","code":"PE-PUN-LMP"},
          {"name":"MOHO","code":"PE-PUN-MOH"},
          {"name":"SANDIA","code":"PE-PUN-SDA"},
          {"name":"YUNGUYO","code":"PE-PUN-YUG"},
        ]
      },
      {
        name: 'San Martín',
        code: 'PE-SAM',
        cities: [
          {"name":"JUANJUÍ","code":"PE-SAM-JJI"},
          {"name":"LAMAS","code":"PE-SAM-LMA"},
          {"name":"MORALES","code":"PE-SAM-MRL"},
          {"name":"MOYOBAMBA","code":"PE-SAM-MBP"},
          {"name":"TARAPOTO","code":"PE-SAM-TMP125195"},
          {"name":"TOCACHE","code":"PE-SAM-HCT"},
        ]
      },
      {
        name: 'Tacna',
        code: 'PE-TAC',
        cities: [
          {"name":"CANDARAVE","code":"PE-TAC-CND"},
          {"name":"TARATA","code":"PE-TAC-TAR"},
        ]
      },
      {
        name: 'Tumbes',
        code: 'PE-TUM',
        cities: [
          {"name":"ZARUMILLA","code":"PE-TUM-ZAR"},
        ]
      },
      {
        name: 'Ucayali',
        code: 'PE-UCA',
        cities: [
          {"name":"ANGAMOS","code":"PE-UCA-ANG"},
          {"name":"ATALAYA","code":"PE-UCA-ATY"},
          {"name":"PUERTO CALLAO","code":"PE-UCA-PUE"},
        ]
      }
    ]
  },
  {
    name: 'San Andrés y Providencia',
    code: 'SP',
    dialCode: '+503',
    flag: 'flag_colombia.png',
    states: [
      {
        name: 'San Andrés y Providencia',
        code: 'SPS',
        cities: [{"name":"SAN ANDRÉS, PROVIDENCIA & SANTA CATALINA","code":"SPS"},]
      }
    ]
  },
  {
    name: 'San Bartolomé',
    code: 'BL',
    dialCode: '+590',
    flag: 'flag_saint-barthelemy.png',
    states: [
      {
        name: 'San Bartolomé',
        code: 'BL-01',
        cities: []
      }
    ]
  },
  {
    name: 'San Cristóbal y Nieves',
    code: 'KN',
    dialCode: '+1869',
    flag: 'flag_saints-kitts-and-nevis.png',
    states: [
      {
        name: 'Saint Kitts',
        code: 'KN-K',
        cities: []
      }
    ]
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    dialCode: '+590',
    flag: 'flag_saint-martin.png',
    states: [
      {
        name: 'Saint Martin',
        code: 'MAF-SAI',
        cities: [{"name":"SAINT MARTIN (FRENCH PART)","code":"MAF-MAF-SAI-SAI"},]
      }
    ]
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    dialCode: '+1758',
    flag: 'flag_saint-lucia.png',
    states: [
      {
        name: 'Anse-la-Raye',
        code: 'LC-01',
        cities: []
      },
      {
        name: 'Canaries',
        code: 'LC-12',
        cities: []
      },
      {
        name: 'Castries',
        code: 'LC-02',
        cities: []
      },
      {
        name: 'Choiseul',
        code: 'LC-03',
        cities: []
      },
      {
        name: 'Dennery',
        code: 'LC-05',
        cities: []
      },
      {
        name: 'Gros Islet',
        code: 'LC-06',
        cities: []
      },
      {
        name: 'Laborie',
        code: 'LC-07',
        cities: []
      },
      {
        name: 'Micoud',
        code: 'LC-08',
        cities: []
      },
      {
        name: 'Soufrière',
        code: 'LC-10',
        cities: []
      },
      {
        name: 'Vieux Fort',
        code: 'LC-11',
        cities: [
          {"name":"VIEUX FORT","code":"LC-11-UVF"},
        ]
      }
    ]
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    dialCode: '+1784',
    flag: 'flag_saint-vincent.png',
    states: [
      {
        name: 'Charlotte',
        code: 'VC-01',
        cities: []
      },
      {
        name: 'Grenadines',
        code: 'VC-06',
        cities: []
      },
      {
        name: 'Saint Andrew',
        code: 'VC-02',
        cities: []
      },
      {
        name: 'Saint David',
        code: 'VC-03',
        cities: []
      },
      {
        name: 'Saint George',
        code: 'VC-04',
        cities: []
      },
      {
        name: 'Saint Patrick',
        code: 'VC-05',
        cities: []
      }
    ]
  },
  {
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
    dialCode: '+1721',
    flag: 'flag_sint-maarten.png',
    states: [
      {
        name: 'Sint Maarten',
        code: 'SX-A',
        cities: [
          {"name":"Cole Bay","code":"SX-A-CLB"},
          {"name":"Cul de Sac","code":"SX-A-CLS"},
          {"name":"Little Bay","code":"SX-A-LTB"},
          {"name":"Lower Prince's Quarter","code":"SX-A-LPQ"},
          {"name":"Lowlands","code":"SX-A-LWL"},
          {"name":"Philipsburg","code":"SX-A-PHI"},
          {"name":"Simpson Bay","code":"SX-A-SPB"},
          {"name":"Upper Prince's Quarter","code":"SX-A-UPQ"},
        ]
      }
    ]
  },
  {
    name: 'Trinidad y Tobago',
    code: 'TT',
    dialCode: '+1868',
    flag: 'flag_trinidad-and-tobago.png',
    states: [
      {
        name: 'Arima',
        code: 'TT-ARI',
        cities: [{"name":"ARIMA","code":"TT-ARI-ZAA"},
          {"name":"CARONI","code":"TT-ARI-CAR"},]
      },
      {
        name: 'Chaguanas',
        code: 'TT-CHA',
        cities: [{"name":"CHAGUANAS","code":"TT-CHA-CHG"},
          {"name":"CUNUPIA","code":"TT-CHA-CUN"},
          {"name":"LONGDENVILLE","code":"TT-CHA-LOE"},]
      },
      {
        name: 'Couva-Tabaquite-Talparo',
        code: 'TT-CTT',
        cities: [{"name":"BALMAIN","code":"TT-CTT-BAN"},
          {"name":"CARAPICHAIMA","code":"TT-CTT-CPC"},
          {"name":"CHASE VILLAGE","code":"TT-CTT-CHE"},
          {"name":"CLAXTON BAY","code":"TT-CTT-CLA"},
          {"name":"DABADIE","code":"TT-CTT-7AD"},
          {"name":"SAINT MARY'S","code":"TT-CTT-FPT"},]
      },
      {
        name: 'Diego Martin',
        code: 'TT-DMN',
        cities: [{"name":"DIEGO MARTIN","code":"TT-DMN-DMT"},
          {"name":"GLENCOE","code":"TT-DMN-GNE"},
          {"name":"MARAVAL","code":"TT-DMN-MVL"},]
      },
      {
        name: 'Mayaro-Rio Claro',
        code: 'TT-MRC',
        cities: []
      },
      {
        name: 'Penal-Debe',
        code: 'TT-PED',
        cities: [{"name":"BARRACKPORE","code":"TT-PED-BAE"},]
      },
      {
        name: 'Point Fortin',
        code: 'TT-PTF',
        cities: [{"name":"GUAPO","code":"TT-PTF-GUO"},
          {"name":"WOODBROOK","code":"TT-PTF-WDK"},]
      },
      {
        name: 'Port of Spain',
        code: 'TT-POS',
        cities: [{"name":"CHAMPS FLEURS/PORT-OF-SPAIN","code":"TT-POS-CHF"},
          {"name":"MORVANT/PORT-OF-SPAIN","code":"TT-POS-MVT"},
          {"name":"PORT OF SPAIN","code":"TT-POS-TMP164457"},
          {"name":"PORT-OF-SPAIN","code":"TT-POS-POS"},
          {"name":"SAINT CLAIR","code":"TT-POS-PSC"},
          {"name":"SAN JUAN/PORT-OF-SPAIN","code":"TT-POS-SAN"},
          {"name":"TOCO","code":"TT-POS-TOC"},]
      },
      {
        name: 'Princes Town',
        code: 'TT-PRT',
        cities: []
      },
      {
        name: 'San Fernando',
        code: 'TT-SFO',
        cities: [{"name":"PENAL","code":"TT-SFO-PNL"},]
      },
      {
        name: 'San Juan-Laventille',
        code: 'TT-SJL',
        cities: [{"name":"BARATARIA","code":"TT-SJL-4BT"},
          {"name":"MOUNT HOPE","code":"TT-SJL-MHP"},]
      },
      {
        name: 'Sangre Grande',
        code: 'TT-SGE',
        cities: [{"name":"SANGRE GRANDE","code":"TT-SGE-SAG"},
          {"name":"VALENCIA","code":"TT-SGE-VCA"},]
      },
      {
        name: 'Siparia',
        code: 'TT-SIP',
        cities: [{"name":"FYZABAD","code":"TT-SIP-FXD"},]
      },
      {
        name: 'Tobago',
        code: 'TT-TOB',
        cities: []
      },
      {
        name: 'Tuna Puna-Piarco',
        code: 'TT-TUP',
        cities: [{"name":"AROUCA","code":"TT-TUP-ARA"},
          {"name":"CUREPE","code":"TT-TUP-CUR"},
          {"name":"DABADIE","code":"TT-TUP-DAE"},
          {"name":"MACOYA","code":"TT-TUP-MCY"},
          {"name":"TACARIGUA","code":"TT-TUP-TAA"},
          {"name":"TRINCITY","code":"TT-TUP-TCY"},
          {"name":"VALSAYN","code":"TT-TUP-TOB"},]
      },
    ]
  },
  {
    name: 'Uruguay',
    code: 'UY',
    dialCode: '+598',
    flag: 'flag_uruguay.png',
    states: [
      {
        name: 'Artigas',
        code: 'UY-AR',
        cities: []
      },
      {
        name: 'Canelones',
        code: 'UY-CA',
        cities: [{"name":"BARROS BLANCOS","code":"UY-CA-TMP163969"},
          {"name":"CANELONES","code":"UY-CA-TMP163971"},
          {"name":"EL PINAR","code":"UY-CA-TMP163974"},
          {"name":"JOANICO","code":"UY-CA-JCO"},
          {"name":"PROGRESO","code":"UY-CA-PRO"},
          {"name":"SAN JACINTO","code":"UY-CA-SAO"},
          {"name":"SANTA LUCIA","code":"UY-CA-SLA"},]
      },
      {
        name: 'Cerro Largo',
        code: 'UY-CL',
        cities: [{"name":"ARBOLITO","code":"UY-CL-ABT"},]
      },
      {
        name: 'Colonia',
        code: 'UY-CO',
        cities: [{"name":"CAMPANA","code":"UY-CO-TMP164007"},
          {"name":"CARRASCO","code":"UY-CO-CAO"},
          {"name":"COLONIA DEL SACRAMENTO","code":"UY-CO-CDS"},
          {"name":"COLONIA SUIZA","code":"UY-CO-CLZ"},
          {"name":"CONCHILLAS","code":"UY-CO-CCL"},
          {"name":"JUAN L. LACAZE","code":"UY-CO-JLC"},
          {"name":"NUEVA HELVECIA","code":"UY-CO-NVH"},
          {"name":"OMBÚES DE LAVALLE","code":"UY-CO-OMB"},
          {"name":"PUNTA PEREYRA","code":"UY-CO-PTP"},
          {"name":"ROSARIO","code":"UY-CO-ROS"},
          {"name":"TARARIRAS","code":"UY-CO-TAS"},
          {"name":"VALDENSE","code":"UY-CO-VAL"}]
      },
      {
        name: 'Durazno',
        code: 'UY-DU',
        cities: [{"name":"SANTA BERNARDINA","code":"UY-DU-SAA"}]
      },
      {
        name: 'Flores',
        code: 'UY-FS',
        cities: []
      },
      {
        name: 'Florida',
        code: 'UY-FD',
        cities: [{"name":"ZONA FRANCA FLORIDA","code":"UY-FD-ZFL"}]
      },
      {
        name: 'Lavalleja',
        code: 'UY-LA',
        cities: []
      },
      {
        name: 'Maldonado',
        code: 'UY-MA',
        cities: [{"name":"AIGUA","code":"UY-MA-AIG"},
          {"name":"PAN DE AZUCAR","code":"UY-MA-PAZ"},
          {"name":"PIRIÁPOLIS","code":"UY-MA-PIR"},
          {"name":"PUNTA DEL ESTE","code":"UY-MA-TMP164087"},
          {"name":"SAN CARLOS","code":"UY-MA-SCA"},]
      },
      {
        name: 'Montevideo',
        code: 'UY-MO',
        cities: [{"name":"CARRASCO","code":"UY-MO-TMP164025"},
          {"name":"MONTEVIDEO","code":"UY-MO-TMP164034"},
          {"name":"PASO DE CARRASCO","code":"UY-MO-PDC"},
          {"name":"PUNTA CARRETAS","code":"UY-MO-PCA"},
          {"name":"ZONA FRANCA MONTEVIDEO","code":"UY-MO-ZFM"},]
      },
      {
        name: 'Paysandú',
        code: 'UY-PA',
        cities: [{"name":"PAYSANDÚ","code":"UY-PA-TMP164042"},]
      },
      {
        name: 'Río Negro',
        code: 'UY-RN',
        cities: [{"name":"NUEVO BERLIN","code":"UY-RN-NVN"},
          {"name":"YOUNG","code":"UY-RN-YOG"},]
      },
      {
        name: 'Rivera',
        code: 'UY-RV',
        cities: []
      },
      {
        name: 'Rocha',
        code: 'UY-RO',
        cities: [{"name":"LASCANO","code":"UY-RO-LSC"},]
      },
      {
        name: 'Salto',
        code: 'UY-SA',
        cities: []
      },
      {
        name: 'San José',
        code: 'UY-SJ',
        cities: [{"name":"LIBERTAD","code":"UY-SJ-LIB"},
          {"name":"ZONA FRANCA LIBERTAD","code":"UY-SJ-ZLI"},]
      },
      {
        name: 'Soriano',
        code: 'UY-SO',
        cities: [{"name":"CARDONA","code":"UY-SO-CDN"},
          {"name":"DOLORES","code":"UY-SO-DOL"},
          {"name":"SORIANO","code":"UY-SO-SOO"},]
      },
      {
        name: 'Tacuarembó',
        code: 'UY-TA',
        cities: []
      },
      {
        name: 'Treinta y Tres',
        code: 'UY-TT',
        cities: []
      }
    ]
  },
  {
    name: 'Venezuela',
    code: 'VE',
    dialCode: '+58',
    flag: 'flag_venezuela.png',
    states: [
      {
        name: 'Amazonas',
        code: 'VE-Z',
        cities: [
          {"name":"PUERTO AYACUCHO","code":"VE-Z-TMP162371"},
        ]
      },
      {
        name: 'Anzoátegui',
        code: 'VE-B',
        cities: [
          {"name":"ANACO","code":"VE-B-TMP162374"},
          {"name":"BARCELONA","code":"VE-B-TMP162376"},
          {"name":"CANTAURA","code":"VE-B-CUA"},
          {"name":"EL CHAURE","code":"VE-B-ELC"},
          {"name":"EL JOSE","code":"VE-B-ELJ"},
          {"name":"GUANTA","code":"VE-B-TMP162384"},
          {"name":"GUARAGUAO","code":"VE-B-GGU"},
          {"name":"JOSE TERMINAL","code":"VE-B-JOT"},
          {"name":"LECHERIA","code":"VE-B-TMP162386"},
          {"name":"LECHERIAS","code":"VE-B-TMP162387"},
          {"name":"PUERTO CRUZ","code":"VE-B-TMP162391"},
          {"name":"SAN DIEGO DE CABRUTICA","code":"VE-B-SDC"},
          {"name":"SAN JOSÉ DE GUANIPA","code":"VE-B-SJG"},
        ]
      },
      {
        name: 'Apure',
        code: 'VE-C',
        cities: [
          {"name":"EL PINAL","code":"VE-C-EPL"},
          {"name":"MANTECAL","code":"VE-C-MTC"},
        ]
      },
      {
        name: 'Aragua',
        code: 'VE-D',
        cities: [
          {"name":"CAGUA","code":"VE-D-CGA"},
          {"name":"COLONIA TOVAR","code":"VE-D-AGX"},
          {"name":"EL CONSEJO","code":"VE-D-ECJ"},
          {"name":"LA ENCRUCIJADA","code":"VE-D-ENC"},
          {"name":"LA VICTORIA","code":"VE-D-LAV"},
          {"name":"LAS TEJERIAS","code":"VE-D-LTE"},
          {"name":"MARACAY","code":"VE-D-TMP162424"},
          {"name":"SAN CASIMIRO","code":"VE-D-SAC"},
          {"name":"SANTA CRUZ, ARAGUA","code":"VE-D-SCR"},
          {"name":"TURMERO","code":"VE-D-AR2"},
          {"name":"VARGAS","code":"VE-D-9VA"},
          {"name":"VILLA DE CURA","code":"VE-D-VDC"},
        ]
      },
      {
        name: 'Barinas',
        code: 'VE-E',
        cities: [
          {"name":"BARINAS","code":"VE-E-TMP162441"},
          {"name":"LA MORITA","code":"VE-E-LMT"},
        ]
      },
      {
        name: 'Bolívar',
        code: 'VE-F',
        cities: [
          {"name":"CARABOBO","code":"VE-F-CAB"},
          {"name":"CIUDAD BOLÍVAR","code":"VE-F-CBL"},
          {"name":"CIUDAD GUAYANA","code":"VE-F-TMP162451"},
          {"name":"LOS PIJIGUAOS","code":"VE-F-LPJ"},
          {"name":"PARAI-TEPUÍ","code":"VE-F-PPH"},
          {"name":"PUERTO ORDAZ","code":"VE-F-TMP162455"},
          {"name":"SANTA ELENA DE UAIRÉN","code":"VE-F-SEU"},
          {"name":"SCOTLAND","code":"VE-F-UPT"},
        ]
      },
      {
        name: 'Carabobo',
        code: 'VE-G',
        cities: [
          {"name":"BORBURATA","code":"VE-G-BOR"},
          {"name":"CARABOBO","code":"VE-G-TMP162474"},
          {"name":"LA YAGUARA","code":"VE-G-LAY"},
          {"name":"LOS GUAYOS","code":"VE-G-LGU"},
          {"name":"MARIARA","code":"VE-G-MAA"},
          {"name":"MORÓN","code":"VE-G-MOR"},
          {"name":"NAGUANAGUA","code":"VE-G-TMP162485"},
          {"name":"SAN DIEGO","code":"VE-G-SDO"},
          {"name":"SAN JOAQUÍN","code":"VE-G-SJQ"},
          {"name":"SANTA RITA","code":"VE-G-SAR"},
          {"name":"TOCUYITO","code":"VE-G-TMP162489"},
          {"name":"VALENCIA","code":"VE-G-TMP162491"},
        ]
      },
      {
        name: 'Cojedes',
        code: 'VE-H',
        cities: [
          {"name":"TINAQUILLO","code":"VE-H-TIN"},
        ]
      },
      {
        name: 'Delta Amacuro',
        code: 'VE-Y',
        cities: [
        ]
      },
      {
        name: 'Distrito Capital',
        code: 'VE-A',
        cities: [
          {"name":"ANTIMANO","code":"VE-A-TMP162460"},
          {"name":"CARACAS","code":"VE-A-TMP162462"},
          {"name":"EL JUNQUITO","code":"VE-A-EJT"},
          {"name":"EL RECREO","code":"VE-A-TMP162463"},
          {"name":"GUARACARUMBO","code":"VE-A-GUO"},
          {"name":"LA RINCONADA","code":"VE-A-RIN"},
          {"name":"LOS DOS COMINOS","code":"VE-A-LDC"},
          {"name":"MAIQUETÍA","code":"VE-A-MAI"},
        ]
      },
      {
        name: 'Falcón',
        code: 'VE-I',
        cities: [
          {"name":"GUANADITO","code":"VE-I-DG8"},
          {"name":"MENE DE MAUROA","code":"VE-I-MMR"},
          {"name":"PUERTO CUMAREBO","code":"VE-I-CMR"},
          {"name":"PUNTA CARDÓN","code":"VE-I-PCN"},
          {"name":"PUNTO FIJO","code":"VE-I-PFI"},
          {"name":"TUREN","code":"VE-I-TEN"},
        ]
      },
      {
        name: 'Guárico',
        code: 'VE-J',
        cities: [
          {"name":"CHAGUARAMAS","code":"VE-J-JCH"},
          {"name":"LOS NARANJEROS","code":"VE-J-LNA"},
          {"name":"SAN JUAN DE LOS MORROS","code":"VE-J-SJM"},
          {"name":"SANTA TERESA MIRANDA","code":"VE-J-SAA"},
        ]
      },
      {
        name: 'Lara',
        code: 'VE-K',
        cities: [
          {"name":"BARQUISIMETO","code":"VE-K-TMP162572"},
          {"name":"CABUDARE","code":"VE-K-CRD"},
          {"name":"DUACA","code":"VE-K-DUA"},
          {"name":"EL TOCUYO","code":"VE-K-ETC"},
          {"name":"QUIBOR","code":"VE-K-QUI"},
        ]
      },
      {
        name: 'Mérida',
        code: 'VE-L',
        cities: [
          {"name":"BOCA GRANDE","code":"VE-L-BOC"},
          {"name":"EJIDO","code":"VE-L-LME"},
          {"name":"LOS ÁNGELES","code":"VE-L-TMP162594"},
          {"name":"MÉRIDA","code":"VE-L-MRD"},
          {"name":"MUCUCHÍES","code":"VE-L-TMP162596"},
          {"name":"PARACOTOS","code":"VE-L-PCT"},
          {"name":"TOVAR MUNICIPALITY","code":"VE-L-TMP162598"},
        ]
      },
      {
        name: 'Miranda',
        code: 'VE-M',
        cities: [
          {"name":"ALTAMIRA","code":"VE-M-TMP162603"},
          {"name":"ARAIRA","code":"VE-M-ARR"},
          {"name":"BARUTA","code":"VE-M-MI6"},
          {"name":"CARACAS","code":"VE-M-TMP164101"},
          {"name":"CARRIZAL","code":"VE-M-CAL"},
          {"name":"CHACAO","code":"VE-M-TMP162618"},
          {"name":"CHARALLAVE","code":"VE-M-CHA"},
          {"name":"CUA","code":"VE-M-CUU"},
          {"name":"EL CAFETAL","code":"VE-M-ECL"},
          {"name":"EL HATILLO","code":"VE-M-EHT"},
          {"name":"EL SOMBRERO","code":"VE-M-ESB"},
          {"name":"FILA DE MARICHES","code":"VE-M-FDM"},
          {"name":"GUARENAS","code":"VE-M-NAS"},
          {"name":"GUATIRE","code":"VE-M-GTM"},
          {"name":"HIGUEROTE","code":"VE-M-HIE"},
          {"name":"ISLA DE MARGARITA","code":"VE-M-IDM"},
          {"name":"LA CARLOTA","code":"VE-M-TMP162631"},
          {"name":"LA TRINIDAD","code":"VE-M-OLT"},
          {"name":"LAS MERCEDES","code":"VE-M-TMP162634"},
          {"name":"LOS ANAUCOS","code":"VE-M-LAS"},
          {"name":"LOS RUICES","code":"VE-M-TMP162636"},
          {"name":"LOS TEQUES","code":"VE-M-TMP162637"},
          {"name":"OCUMARE DEL TUY","code":"VE-M-ODT"},
          {"name":"PETARE","code":"VE-M-TMP162642"},
          {"name":"SAN ANTONIO","code":"VE-M-TMP162645"},
          {"name":"SAN FRANCISCO DE YARE","code":"VE-M-SFY"},
        ]
      },
      {
        name: 'Monagas',
        code: 'VE-N',
        cities: [
          {"name":"CARIPITO","code":"VE-N-CAR"},
          {"name":"MATURÍN","code":"VE-N-TMP162665"},
          {"name":"PUNTA DE MATA","code":"VE-N-PDM"},
        ]
      },
      {
        name: 'Nueva Esparta',
        code: 'VE-O',
        cities: [
          {"name":"AGUA DE VACA","code":"VE-O-ADV"},
          {"name":"EL VALLE DEL ESPÍRITU SANTO","code":"VE-O-TMP162674"},
          {"name":"LA ASUNCION","code":"VE-O-LSN"},
          {"name":"PAMPATAR","code":"VE-O-TMP162680"},
          {"name":"PORLAMAR","code":"VE-O-TMP162682"},
        ]
      },
      {
        name: 'Portuguesa',
        code: 'VE-P',
        cities: [
          {"name":"ACARIGUA","code":"VE-P-TMP162687"},
          {"name":"ARAURE","code":"VE-P-AUR"},
          {"name":"EL PLAYON","code":"VE-P-EPY"},
          {"name":"OCUMARE","code":"VE-P-OCU"},
          {"name":"PUERTO PIRITU","code":"VE-P-PPT"},
        ]
      },
      {
        name: 'Sucre',
        code: 'VE-R',
        cities: [
          {"name":"PUERTO CARENERO","code":"VE-R-PCO"},
        ]
      },
      {
        name: 'Táchira',
        code: 'VE-S',
        cities: [
          {"name":"INDEPENDENCIA","code":"VE-S-IND"},
          {"name":"MICHELENA","code":"VE-S-TMP162735"},
          {"name":"SAN ANTONIO DEL TÁCHIRA","code":"VE-S-SAT"},
          {"name":"SAN CRISTÓBAL","code":"VE-S-TMP162739"},
          {"name":"SEBORUCO","code":"VE-S-SBR"},
          {"name":"TÁRIBA","code":"VE-S-TMP162743"},
          {"name":"UREÑA","code":"VE-S-YUR"},
        ]
      },
      {
        name: 'Trujillo',
        code: 'VE-T',
        cities: [
          {"name":"CRISTOBAL","code":"VE-T-CRI"},
          {"name":"LA CEJITA","code":"VE-T-TMP162747"},
          {"name":"SABANA GRANDE","code":"VE-T-SAG"},
          {"name":"TRENTINO","code":"VE-T-TNR"},
          {"name":"TRUJILLO","code":"VE-T-TRO"},
        ]
      },
      {
        name: 'Vargas',
        code: 'VE-X',
        cities: [
          {"name":"CARABALLEDA","code":"VE-X-TMP162751"},
          {"name":"CATIA LA MAR","code":"VE-X-TMP162753"},
          {"name":"LA GUAIRA","code":"VE-X-TMP162756"},
          {"name":"MAIQUETÍA","code":"VE-X-TMP162758"},
        ]
      },
      {
        name: 'Yaracuy',
        code: 'VE-U',
        cities: [
          {"name":"CHIVACOA","code":"VE-U-CHC"},
          {"name":"NIRGUA","code":"VE-U-NIR"},
          {"name":"SABANA DE PARRA","code":"VE-U-SDP"},
          {"name":"SAN FELIPE","code":"VE-U-TMP162775"},
        ]
      },
      {
        name: 'Zulia',
        code: 'VE-V',
        cities: [
          {"name":"CHIQUINQUIRÁ","code":"VE-V-CHQ"},
          {"name":"CURAZAITO","code":"VE-V-CUR"},
          {"name":"LA VILLA","code":"VE-V-VIL"},
          {"name":"LAGUNILLAS","code":"VE-V-TMP162801"},
          {"name":"MARACAIBO","code":"VE-V-TMP162804"},
          {"name":"SAN FRANCISCO","code":"VE-V-TMP162810"},
          {"name":"SANTA BÁRBARA","code":"VE-V-TMP162813"},
          {"name":"SANTA CRUZ DE MARA","code":"VE-V-SCM"},
          {"name":"SANTA CRUZ DEL ZULIA","code":"VE-V-STB"},
          {"name":"SANTA RITA","code":"VE-V-ZU7"},
          {"name":"TAMARE","code":"VE-V-TAM"},
        ]
      }
    ]
  },
  {
    name: 'Virgin Islands (British)',
    code: 'VG',
    dialCode: '+1284',
    flag: 'flag_british-virgin-islands.png',
    states: [
      {
        name: 'Virgin Islands (British)',
        code: 'VGB',
        cities: []
      }
    ]
  },
];