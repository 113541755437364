import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import PaymentLink from './pages/PaymentLink';
import FirstPayment from './pages/FirstPayment';
import LanguageContext from './contexts/LanguageContext';
import './styles/App.css';
import { captureConsoleLogs } from './logger'; 
import FlywirePaymentForm from 'pages/TestFlywire';

function App() {
  
  return (
      <Router>
        <AppContent />
      </Router>
  );
}

function AppContent() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const languageMap: { [key: string]: string } = {
        EN: 'en',
        ES: 'es',
        PT: 'pt',
    };
    const lang = queryParams.get('lang') ?? 'EN';
    const [selectedLanguage, setSelectedLanguage] = useState<string>(languageMap[lang]);
  
    const changeLanguage = (newLanguage: string) => {
      setSelectedLanguage(newLanguage);
    };
  
    useEffect(() => {
      captureConsoleLogs();
    }, []);

    return (
      <LanguageContext.Provider value={{ language: selectedLanguage, changeLanguage }}>
        <div className="App">
          <Routes>
            <Route path="/" element={<FirstPayment language={selectedLanguage}/>} />
            <Route path="/link" element={<PaymentLink language={selectedLanguage}/>} />
            <Route path="/test-flywire" element={<FlywirePaymentForm/>} />
          </Routes>
        </div>
      </LanguageContext.Provider>
    );
}

export default App;