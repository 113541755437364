import React from "react";
import { languageMessages } from "traductor/PageConfirmation";
import '../styles/ScreenConfirmationLink.css'

function PageConfirmation({language, firstname, amount, policy, card, date, type, isComplete}: { language: string, firstname: string, amount: string, policy: string, card: string, date: string, type: string, isComplete: boolean}) {

        const today = new Date();

        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
        const year = today.getFullYear();

        const formattedDate = `${month}/${day}/${year}`;

        const { title1, title2, title3, subtitle1, subtitle2, subtitle3, subtitle4, data1, data2, data3, data4, data5, data6, button1, button2 } = languageMessages[language];

        return (
            <div className='app-confirmation'>
                <div className='app-body-confirmation'>
                    <div className='img' style={type === 'payment' || !isComplete ? { backgroundImage: 'url("check-payment.png")', backgroundSize: '120px' } : { backgroundImage: 'url("icon-card.gif")', backgroundSize: '120px' }}></div>
                    {isComplete && 
                        <div className='text'>
                            <div className='title'>{firstname}, {type === 'payment' ? title1 : title2}</div>
                            <div className='subtitle' style={type === 'payment' ? {display: 'block'} : {display: 'none'}}> {subtitle1} <b>{amount}</b> {subtitle2}</div>
                            <div className='subtitle' style={type === 'payment' ? {display: 'none'} : {display: 'block'}}> {subtitle3}</div>
                        </div>
                    }
                    {!isComplete && <div className='text'>
                        <div className='title'>{title3}</div>
                        <div className='subtitle' style={type === 'payment' ? {display: 'block'} : {display: 'none'}}> {subtitle4}</div>
                    </div>}
                    {isComplete && <div className='paymentInfoC'>
                        <div className="container">
                            <div className="content">
                                <div className='field'>{data1}</div>
                                <div className='data'>{policy}</div>
                            </div>
                        </div>
                        <div className="container" style={type === 'payment' ? {display: 'none'} : {display: 'block'}}>
                            <div className="content">
                                <div className='field'>{data4}</div>
                                <div className='data'>**** **** **** {card}</div>
                            </div>
                        </div>
                        <div className="container" style={type === 'payment' ? {display: 'none'} : {display: 'block'}}>
                            <div className="content">
                                <div className='field'>{data5}</div>
                                <div className='data'>{firstname}</div>
                            </div>
                        </div>
                        <div className="container" style={type === 'payment' ? {display: 'none'} : {display: 'block'}}>
                            <div className="content">
                                <div className='field'>{data6}</div>
                                <div className='data'>{date}</div>
                            </div>
                        </div>
                        <div className="container" style={type === 'payment' ? {display: 'block'} : {display: 'none'}}>
                            <div className="content">
                                <div className='field'>{data2}</div>
                                <div className='data'>{formattedDate}</div>
                            </div>
                        </div>
                        <div className="container" style={type === 'payment' ? {display: 'block'} : {display: 'none'}}>
                            <div className="content">
                                <div className='field'>{data3}</div>
                                <div className='data' style={{color: '#00D090'}}>{amount}</div>
                            </div>
                        </div>
                    </div>}
                    {!isComplete && 
                        <div className='paymentPendingScreen'></div>
                    }
                    {isComplete && <div className="container-button">
                        <button className='button-c' onClick={() => { localStorage.setItem('selectedLinkOption', 'payment'); window.location.reload() }}>{type === 'payment' ? button1 : button2}</button>
                    </div>}
                </div>
            </div>
      );
}

export default PageConfirmation;