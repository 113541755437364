export const languageMessages = (language:string, key: string) => {
  const messages: any = {
    en: {
      titleApproved: 'Your Olé application has been approved!',
      titleRegular: 'You are one step away from requesting your Olé life insurance',
      subtitleApproved: 'Pay now to <b>activate your policy</b> and <b>protect your loved ones:</b>',
      //subtitleRegular: 'You may see temporary <b>hold of USD $1</b> during our evaluation process.',
      subtitleRegular: '',
      description: 'Your premium will be:',
      authorizePayments: ' I authorize recurring payments.',
      payNowAprroved: 'Pay now',
      payNowRegular: 'Add a debit or credit card',
      frequency: {
        M: 'Month',
        A: 'Year',
        S: 'Semester',
        Q: 'Quarter',
      },
      skip: 'Skip step',
      errorMessages: {
        payment: {
            title: 'There was a problem with your payment',
            subtitle: 'Please try a different payment method or review your card information.',
            buttonText: 'Try again'
        },
        session: {
            title: 'There was a problem getting your information',
            subtitle: 'Please try again.',
            buttonText: 'Try again'
        },
        existsSession: {
            title: 'Thank you!',
            subtitle: 'Your request has already been sent.',
            buttonText: ''
        }
      },
    },
    es: {
      titleApproved: '¡Tu solicitud de Olé ha sido aprobada!',
      titleRegular: 'Estás a un paso de solicitar tu seguro de vida Olé',
      subtitleApproved: 'Paga ahora para <b>activar tu póliza</b> y <b>proteger a tus seres queridos.</b>',
      //subtitleRegular: 'Es posible que vea <b>una retención temporal de USD $1</b> durante nuestro proceso de evaluación.',
      subtitleRegular: '',
      description: 'Su prima será:',
      authorizePayments: 'Autorizo pagos recurrentes',
      payNowAprroved: 'Pagar ahora',
      payNowRegular: 'Agregar tarjeta de débito o crédito',
      frequency: {
        M: 'Mes',
        A: 'Año',
        S: 'Semestre',
        Q: 'Trimestre',
      },
      skip: 'Omitir paso',
      errorMessages: {
        payment: {
            title: 'Hubo un problema con tu pago',
            subtitle: 'Por favor, intenta con un método de pago diferente o revisa la información de tu tarjeta.',
            buttonText: 'Intentar de nuevo'
        },
        session: {
            title: 'Hubo un problema al obtener tu información',
            subtitle: 'Por favor, inténtalo de nuevo.',
            buttonText: 'Intentar de nuevo'
        },
        existsSession: {
            title: '¡Gracias!',
            subtitle: 'Tu solicitud ya ha sido enviada con éxito.',
            buttonText: ''
        }
      },
    },
    pt: {
      titleApproved: 'Sua solicitação de Olé foi aprovada!',
      titleRegular: 'Você está a um passo de solicitar seu seguro de vida Olé',
      subtitleApproved: 'Pague agora para <b>ativar sua pólvora</b> e <b>proteger seus seres queridos.</b>',
      //subtitleRegular: 'Você pode ver <b>uma retenção temporária de USD $1</b> durante nosso processo de avaliação.',
      subtitleRegular: '',
      description: 'Seu prêmio será:',
      authorizePayments: 'Autorização de pagamentos recorrentes',
      payNowAprroved: 'Pague agora',
      payNowRegular: 'Adicionar cartão de débito ou crédito',
      frequency: {
        M: 'Mês',
        A: 'Ano',
        S: 'Semestre',
        Q: 'Trimestre',
      },
      skip: 'Pular etapa',
      errorMessages: {
        payment: {
            title: 'Houve um problema com seu pagamento',
            subtitle: 'Por favor, tente um método de pagamento diferente ou revise as informações do seu cartão.',
            buttonText: 'Tentar novamente'
        },
        session: {
            title: 'Houve um problema ao obter suas informações',
            subtitle: 'Por favor, tente novamente.',
            buttonText: 'Tentar novamente'
        },
        existsSession: {
            title: 'Obrigado',
            subtitle: 'Sua solicitação já foi enviada.',
            buttonText: ''
        }
      },
    },
  }
  return messages[language][key];
};