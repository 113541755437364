import { BASE_URL } from "config";
import { LinkResponse } from "./types";
import { uuidv4 } from "services/common";
import { REACT_APP_OLE_API_KEY } from '../../config';

const getHashInfo = async (token: string): Promise<LinkResponse> => {
    try {
        const response = await fetch(`${BASE_URL}/api-payments/v1/payments/pending/${token}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': REACT_APP_OLE_API_KEY,
                'X-User': 'web',
                'X-Request-ID': uuidv4()
            },
        });
        if (!response.ok) {
            throw new Error('Error fetching session');
        }
        return await response.json();
    } catch (error) {
        //console.error('Error getting session:', error);
        throw error;
    }
}

export { getHashInfo };