import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { REACT_APP_FLYWIRE_SCRIPT_URL, REACT_APP_FLYWIRE_ENV, REACT_APP_FLYWIRE_CODE, REACT_APP_FLYWIRE_CALLBACK_URL, REACT_APP_FLYWIRE_CALLBACK_VERSION } from '../config';


function FormFlyware({ objectData, isModal, retry, session, onCompleteFlywire }: { objectData: any, isModal: boolean, retry: any, session: any, onCompleteFlywire: Function}) {
  const navigate = useNavigate();
  const [inExecution, setInExecution] = useState(false);
  useEffect(() => {

    if (objectData === null || objectData === undefined) {
      return;
    }
    const flywireScriptUrl = REACT_APP_FLYWIRE_SCRIPT_URL;
    if ( retry < 0 ) {
      return;
    }
    
    let divModal = ''
    if ( !isModal ) {
        divModal = '#embedTarget';
        
        document.documentElement.style.setProperty('--fwpCloseLinkDisplay', 'none');
    } else {
        document.documentElement.style.setProperty('--fwpCloseLinkDisplay', 'block');
    }

    let screen = window.location.href.includes("link") ? 'linkPayment' : 'firstPayment';
    let recurringType = 'optional_tokenization'
    if (objectData.automaticApproval) {
      if (objectData.recurrent) {
        recurringType = 'tokenization';
      } else {
        recurringType = 'optional_tokenization';
      }
    } else {
      recurringType = 'tokenization';
    }
    const stateSplit = objectData.state.split('-');
    const state = stateSplit[1] ? stateSplit[1].trim() : stateSplit[0].trim();
    var config = {    
      env: REACT_APP_FLYWIRE_ENV,
      recipientCode: objectData.recipientCode || REACT_APP_FLYWIRE_CODE,
      recurringType: recurringType,
      amount: objectData.amount,
      firstName: objectData.firstName,
      address: objectData.address,
      lastName: objectData.lastName,
      email: objectData.email,
      phone: objectData.phone,
      city: objectData.cityForFlywire || objectData.city,
      state: state,
      country: objectData.country,
      zip: objectData.zipCode,
      locale: objectData.language,
      recipientFields: {
        invoice_number: objectData.idApplication,
        session_id: session,
        quotation: objectData.idQuotation,
        document_number: objectData.idPerson,
        payer_relation: objectData.relationship,
        payer_type: objectData.payingForPolicy,
        payment_installments: objectData.paymentIds?.join(','),
        recurrent: objectData.recurrent,
      },
      paymentOptionsConfig: 
      {
        "sort": [
          { type: ["credit_card", "online", "bank_transfer"] },
          { currency: ["foreign", "local"]},
        ]
      },
      onInvalidInput: function(errors: any) {
        console.error('Flywire[Validations errors]', errors);
      },
      requestPayerInfo: false,
      requestRecipientInfo: false,
      embedTo: divModal,
      onCompleteCallback: async function(args: any) {
        if(screen === 'firstPayment') {
          onCompleteFlywire({"success": true, "args": args, "session": session});
        } else {
          if (inExecution === false) {
            setInExecution(true);
            onCompleteFlywire({"success": true, "args": args});
          }
        }
      },
      onCancel: function() {
        console.log('Flywire [canceled event]', "Flywire Form was cancelled");
      },
      callbackId: objectData.idQuotation,
      callbackUrl: REACT_APP_FLYWIRE_CALLBACK_URL,
      callbackVersion: REACT_APP_FLYWIRE_CALLBACK_VERSION,
    };
    console.log('Flywire Configuration', JSON.stringify(config))
    const target = document.getElementById('embedTarget');

    if (target && target.firstChild) {
      while (target.firstChild) {
        target.removeChild(target.firstChild);
      }
    }

    const loadFlywireScript = (callback: ((this: GlobalEventHandlers, ev: Event) => any) | null) => {
      const script = document.createElement('script');
      script.src = flywireScriptUrl ?? '';
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    
    const initializeFlywire = () => {
      if (target && target.firstChild) {
        return; 
      }
      // @ts-ignore
      window.FlywirePayment.initiate(config);
    };
    // @ts-ignore
    if (window.FlywirePayment) {
      initializeFlywire();
    } else {
      loadFlywireScript(initializeFlywire);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry, isModal, session, inExecution, navigate]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id='embedTarget'></div>
    </div>
  );   
}

export default FormFlyware;