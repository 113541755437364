import React from 'react';
import './styles/oleDialog.css';

type PopupInformationProps = {
    title: string;
    information: Map<string, string>;
    onClose: () => void;
};

const Dialog: React.FC<PopupInformationProps> = ({ title, information, onClose }) => {
  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <div className="dialog-header">
          <div className="close-button" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
              <path d="M20.8042 21.531C20.4779 21.8573 20.0353 22.0406 19.5738 22.0406C19.1124 22.0406 18.6698 21.8573 18.3435 21.531L14.4996 17.1375L10.6556 21.5296C10.4945 21.6933 10.3026 21.8235 10.091 21.9127C9.87929 22.0019 9.65205 22.0483 9.42236 22.0493C9.19267 22.0502 8.96507 22.0056 8.75268 21.9182C8.5403 21.8307 8.34733 21.7021 8.18491 21.5396C8.0225 21.3772 7.89384 21.1843 7.80638 20.9719C7.71891 20.7595 7.67436 20.5319 7.67529 20.3022C7.67623 20.0725 7.72263 19.8453 7.81182 19.6336C7.90102 19.4219 8.03124 19.23 8.19497 19.0689L12.1941 14.5014L8.19352 9.93103C8.02979 9.76994 7.89957 9.57803 7.81037 9.36636C7.72118 9.15469 7.67478 8.92746 7.67384 8.69777C7.67291 8.46808 7.71746 8.24048 7.80493 8.02809C7.89239 7.8157 8.02105 7.62273 8.18346 7.46032C8.34588 7.2979 8.53885 7.16925 8.75123 7.08178C8.96362 6.99431 9.19122 6.94976 9.42091 6.9507C9.6506 6.95163 9.87784 6.99804 10.0895 7.08723C10.3012 7.17642 10.4931 7.30664 10.6542 7.47038L14.4996 11.8653L18.3435 7.47038C18.5046 7.30664 18.6965 7.17642 18.9082 7.08723C19.1199 6.99804 19.3471 6.95163 19.5768 6.9507C19.8065 6.94976 20.0341 6.99431 20.2465 7.08178C20.4588 7.16925 20.6518 7.2979 20.8142 7.46032C20.9767 7.62273 21.1053 7.8157 21.1928 8.02809C21.2802 8.24048 21.3248 8.46808 21.3239 8.69777C21.3229 8.92746 21.2765 9.15469 21.1873 9.36636C21.0981 9.57803 20.9679 9.76994 20.8042 9.93103L16.8051 14.5014L20.8042 19.0689C20.966 19.2305 21.0943 19.4224 21.1819 19.6337C21.2694 19.8449 21.3145 20.0713 21.3145 20.3C21.3145 20.5286 21.2694 20.7551 21.1819 20.9663C21.0943 21.1775 20.966 21.3694 20.8042 21.531Z" fill="#C2CEE3"/>
            </svg>
          </div>
        </div>
        <div className="dialog-body">
          {/*<div className="icon"><img src='icon.png' alt='icon' /></div>*/}
          <h2 className="info" >{title}</h2>
          <div>
            {Array.from(information).map(([key, value]) => (
              <div key={key} className="information">
                <span className="key">{key}</span>
                <span className="value">&nbsp;&nbsp;{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
