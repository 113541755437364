import { SessionResponse } from './types';
import { BASE_URL, REACT_APP_OLE_API_KEY } from '../../config';
import { uuidv4 } from 'services/common';

const getSession = async (token: string): Promise<SessionResponse> => {
  try {
    const response = await fetch(`${BASE_URL}/api-payments/v1/session?token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': REACT_APP_OLE_API_KEY,
        'X-User': 'web',
        'X-Request-ID': uuidv4()
      }
    });
    if (!response.ok) {
      throw new Error('Error fetching session');
    }
    return await response.json();
  } catch (error) {
    console.error('[session]', error);
    throw error;
  }
};

export { getSession };