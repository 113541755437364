import React, { useState, useContext, useRef, useEffect } from 'react';
import LanguageContext from '../contexts/LanguageContext';
import './styles/oleCountrySelect.css';

const countries = [
    { value: 'en', label: 'English', flag: 'flag_eeuu.png' },
    { value: 'es', label: 'Español', flag: 'flag_spain.png' },
    { value: 'pt', label: 'Português', flag: 'flag_portuguese.png' },
];

const CountrySelect = ({defaultValue}: { defaultValue: string }) => {
    const { changeLanguage } = useContext(LanguageContext);
    //const defaultValue = 'US';
    const [selectedCountry, setSelectedCountry] = useState(defaultValue);
    const [selectedCountryData, setSelectedCountryData] = useState<any>(countries);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownToggle = () => {
      setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      setSelectedCountryData(countries.find(country => country.value === selectedCountry))
      setSelectedCountry(defaultValue);
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [defaultValue, selectedCountry]);

    const handleChangeLanguage = (country: { value: string }) => {
      setSelectedCountryData(countries.find(country => country.value === selectedCountry))
      setSelectedCountry(country.value);
      changeLanguage(country.value); // Explicitly define the type of the 'country' parameter and cast it to keyof typeof languageMap
      setIsOpen(false);

    };

    // Encuentra el país seleccionado para mostrar la bandera y el idioma.
    //const selectedCountryData = countries.find(country => country.value === selectedCountry);

    return (
        <div className='oleCountrySelect' ref={dropdownRef}>
            {/* Botón para abrir y cerrar el menú desplegable */}
            <button onClick={handleDropdownToggle} className='dropdown-toggle'>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <img
                        src={selectedCountryData?.flag}
                        alt={selectedCountryData?.label}
                        style={{ width: '24px', marginRight: '10px' }}
                    />
                    <span>{selectedCountryData?.label}</span>
                    <div className='dropdown-icon'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                          <path d="M0.289401 0.399703C0.380541 0.272999 0.488798 0.172476 0.607975 0.10389C0.727152 0.0353039 0.854909 4.73789e-08 0.983933 5.45294e-08C1.11296 6.16799e-08 1.24071 0.0353039 1.35989 0.10389C1.47907 0.172477 1.58733 0.272999 1.67847 0.399703L5.50086 5.70275L9.32325 0.399703C9.50745 0.144149 9.75728 0.000579272 10.0178 0.000579287C10.2783 0.000579301 10.5281 0.144149 10.7123 0.399703C10.8965 0.655258 11 1.00186 11 1.36327C11 1.72468 10.8965 2.07129 10.7123 2.32684L6.19047 8.6003C6.09932 8.727 5.99107 8.82753 5.87189 8.89611C5.75271 8.9647 5.62496 9 5.49593 9C5.36691 9 5.23915 8.9647 5.11997 8.89611C5.0008 8.82753 4.89254 8.727 4.8014 8.6003L0.279549 2.32684C-0.0948091 1.80747 -0.0948089 0.932742 0.289401 0.399703Z" fill="#5B667A"/>
                      </svg>
                    </div>
                </div>
            </button>

            {/* Menú desplegable */}
            {isOpen && (
              <ul className='dropdown-menu'>
                {countries.map((country) => (
                  <li
                    key={country.value}
                    onClick={() => handleChangeLanguage(country)}
                    className={`dropdown-item ${selectedCountry === country.value ? 'selected' : ''}`}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={country.flag}
                        alt={country.label}
                        style={{ width: '24px', marginRight: '10px' }}
                      />
                      {country.label}
                    </div>
                  </li>
                ))}
              </ul>
            )}
        </div>
    );
};

export default CountrySelect;
