import { REACT_APP_FLYWIRE_CODE_OTHER, REACT_APP_FLYWIRE_CODE_US } from "config";

const DEFAULT_PAYMASTER = "1";
interface LinkResponse {
    id_subscription: number,
    id_quotation: string,
    id_application: string,
    policy_number: string,
    id_person: string,
    frequency: string,
    currency: string,
    country: string,
    language: string,
    first_name: string,
    last_name: string,
    email: string,
    plan: string,
    term: string,
    payer: Payer,
    payments: PaymentPending[],
    has_paid_payments: boolean,

    /*has_schedule: boolean,
    amount: number,
    address: string,
    city: string,
    paymaster: string,
    paymaster_relationship: string,
    
    zip: string,
    state: string,*/
}

interface Payer {
    first_name: string,
    last_name: string,
    middle_name: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    phone: string,
    email: string,
    paymaster: string,
    paymaster_relationship: string,
}

interface PaymentPending {
    id_payment: number,
    id_transaction_provider: string,
    id_alternate_system: string,
    payment_date: string,
    status: string,
    amount: number,
    currency: string,
    expired: boolean,
}

interface Due {
    id: number;
    period: string;
    dueDate: string;
    currency: string;
    symbol: string;
    premium: number;
    checked: boolean;
    withDelay: boolean;
    disabled: boolean;
}

interface TransformedData {
    idApplication: string | undefined;
    idQuotation: string | undefined;
    idPerson: string | undefined;
    insured: string | undefined;
    payer: string | undefined;
    policyOwner: string | undefined;
    policyNumber: string | undefined;
    plan: string | undefined;
    term: string | undefined;
    premium: string | undefined;
    paymentFrequency: string | undefined;
    firstName: string | undefined;
    payerFirstName: string | undefined;
    lastName: string | undefined;
    payerLastName: string | undefined;
    address: string | undefined;
    city: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    amountStart: number | undefined;
    amount: number | undefined;
    country: string | undefined;
    dues: Due[];
    currency: string | undefined;
    symbol: string | undefined;
    recurrent?: boolean | undefined;
    language?: string | undefined;
    automaticApproval?: boolean | undefined;
    state: string | undefined;
    zipCode: string | undefined;
    payingForPolicy?: string | undefined;
    relationship?: string | undefined;
    paymentIds?: number[] | undefined;
    recipientCode?: string;
    hasPaidPayment?: boolean;
}

function getDateCustom(date: string): string {
    if (!date || date === '' || date.length < 10) {
        return '';
    }
    const day = date.substring(8, 10);
    const month = date.substring(5, 7);
    const year = date.substring(0, 4);
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate
}

function transformLinkResponse(linkResponse: LinkResponse): TransformedData {
    let flywireCode = REACT_APP_FLYWIRE_CODE_OTHER;
    if (linkResponse.country === 'US') {
      flywireCode = REACT_APP_FLYWIRE_CODE_US;
    }
    const transformedData: TransformedData = {
        idApplication: linkResponse?.id_application || '',
        idQuotation: linkResponse.id_quotation,
        idPerson: linkResponse.id_person,
        insured: `${linkResponse.first_name} ${linkResponse.last_name}`,
        payer: `${linkResponse.payer.first_name} ${linkResponse.payer.last_name}`,
        policyOwner: `${linkResponse.payer.first_name} ${linkResponse.payer.last_name}`,
        policyNumber: linkResponse?.policy_number || linkResponse?.id_application,
        plan: linkResponse?.plan,
        term: linkResponse?.term,
        premium: `${linkResponse?.currency} $${linkResponse?.payments[0].amount}`, // Suponiendo que solo haya un pago pendiente
        paymentFrequency: linkResponse.frequency,
        firstName: linkResponse.first_name,
        payerFirstName: linkResponse.payer.first_name,
        lastName: linkResponse.last_name,
        payerLastName: linkResponse.payer.last_name,
        address: linkResponse.payer.address1,
        city: linkResponse.payer.city,
        email: linkResponse.payer.email,
        phone: linkResponse.payer.phone,
        amountStart: linkResponse.payments[0].amount,
        amount: localStorage.getItem('selectedLinkOption') === 'payment' ? linkResponse.payments[0].amount : 0.0,
        country: linkResponse.country,
        dues: linkResponse.payments.map((payment, index) => ({
            id: payment.id_payment,
            period: '',
            dueDate: getDateCustom(payment.payment_date.substring(0, 10)),
            currency: payment.currency,
            symbol: '$',
            premium: payment.amount,
            checked: index === 0,
            withDelay: payment.expired,
            disabled: index > 1
        })),
        currency: linkResponse.currency,
        symbol: '$',
        recurrent: true,
        automaticApproval: true,
        zipCode: linkResponse.payer.zip,
        payingForPolicy: DEFAULT_PAYMASTER,
        relationship: linkResponse.payer.paymaster_relationship,
        state: linkResponse.payer.state,
        paymentIds: [linkResponse.payments[0].id_payment],
        recipientCode: flywireCode,
        hasPaidPayment: linkResponse.has_paid_payments,
    };
    return transformedData;
}

export type { LinkResponse, TransformedData };
export { transformLinkResponse };