import React from 'react';
import './styles/oleCheckbox.css';

interface OleCheckboxProps {
  id: number;
  checked?: boolean;
  onChange?: (id: number) => void;
  disabled?: boolean;
  text: string;
}

const OleCheckbox: React.FC<OleCheckboxProps> = ({ id, checked = false, onChange = () => {}, disabled = false, text }) => {
  return (
    <div className={`oleCheckbox ${disabled ? 'disabled' : ''}`}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => !disabled && onChange(id)}
          disabled={disabled}
        />
        <span
          style={{
            backgroundColor: disabled ? '#C2CEE3' : checked ? '#0BD' : '#FFF',
            color: disabled ? '#C2CEE3' : checked ? '#FFF' : '#0BD',
          }}
        >
          {checked ? <img src="check.svg" alt='check'/> : ''}
        </span>
      </label>
      <span onClick={() => !disabled && onChange(id)}>{text}</span>
    </div>
  );
};

export default OleCheckbox;