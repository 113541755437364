export const languageMessages = (language:string, key: string) => {
    const messages: any = {
      en: {
        payingForPolicy: 'Who is paying for the policy?',
        payingForPolicyOptions: [
          { value: '1', label: 'Policy owner / Insured' },
          { value: '2', label: 'Relative' },
          { value: '3', label: 'Other' },
        ],
        firstName: 'First Name',
        lastName: 'First Last Name',
        email: 'Email',
        phone: 'Phone Number',
        country: 'Country',
        state: 'State / Region',
        city: 'City',
        zipCode: 'Zip Code',
        billingAddress: 'Billing Address',
        relationship: 'What’s the relationship between the paymaster and the insured?',
        selectOption: 'Select',
        authorizeRecurrence: 'I authorize recurring payments.',
        payNow: 'Pay Now',
        validationFields: 'Complete the requested fields.',
        payerInformation: 'Billing Information',
        searchCountry: 'Search Country',
        searchState: 'Search State',
        searchCity: 'Search City',
      },
      es: {
        payingForPolicy: '¿Quién está pagando por esta póliza?',
        payingForPolicyOptions: [
          {
            value: '1',
            label: 'Titular de la póliza / Asegurado',
          },
          {
            value: '2',
            label: 'Pariente',
          },
          {
            value: '3',
            label: 'Otro',
          },
        ],
        firstName: 'Primer nombre',
        lastName: 'Primer apellido',
        email: 'Correo Electrónico',
        phone: 'Número de teléfono',
        country: 'País',
        state: 'Estado / Región',
        city: 'Ciudad',
        zipCode: 'Código postal',
        billingAddress: 'Dirección de facturación',
        relationship: '¿Qué relación existe entre el pagador y el asegurado?',
        selectOption: 'Seleccione',
        authorizeRecurrence: 'Autorizo pagos recurrentes.',
        payNow: 'Pagar ahora',
        validationFields: 'Complete los campos solicitados.',
        payerInformation: 'Información de facturación',
        searchCountry: 'Buscar país',
        searchState: 'Buscar estado',
        searchCity: 'Buscar ciudad',
      },
      pt: {
        payingForPolicy: 'Quem etá pagando por esta apólice?',
        payingForPolicyOptions: [
          {
            value: '1',
            label: 'Titular da apólice / Segurado',
          },
          {
            value: '2',
            label: 'Relativo',
          },
          {
            value: '3',
            label: 'Outro',
          },
        ],
        firstName: 'Primeiro nome',
        lastName: 'Primeiro chamado',
        email: 'Correio eletrônico',
        phone: 'Número de telefone',
        country: 'País',
        state: 'Estado / Região',
        city: 'Cidade',
        zipCode: 'Código postal',
        billingAddress: 'Endereço de cobrança',
        relationship: 'Qual a relação entre o pagador e o garantido?',
        selectOption: 'Selecione',
        authorizeRecurrence: 'Autorizo pagamentos recorrentes.',
        payNow: 'Pagar agora',
        validationFields: 'Preencha os campos solicitados.',
        payerInformation: 'Informações de pagamento',
        searchCountry: 'Pesquisar país',
        searchState: 'Pesquisar estado',
        searchCity: 'Pesquisar cidade',
      },
    }
    return messages[language][key];
  };