import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import { REACT_APP_AWS_RUM_APPLICATION_ID, REACT_APP_AWS_RUM_ENDPOINT, REACT_APP_AWS_RUM_IDENTITY_POOL_ID, REACT_APP_AWS_RUM_REGION } from 'config';

  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    identityPoolId: REACT_APP_AWS_RUM_IDENTITY_POOL_ID || '',
    endpoint: REACT_APP_AWS_RUM_ENDPOINT || '',
    telemetries: ["performance","errors",[ 'http', { addXRayTraceIdHeader: true } ]],
    allowCookies: true,
    enableXRay: true
  };
  const APPLICATION_ID = REACT_APP_AWS_RUM_APPLICATION_ID || '';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = REACT_APP_AWS_RUM_REGION || '';

  if(!APPLICATION_ID || !APPLICATION_REGION) {
    console.error('AWS RUM configuration is missing required environment variables.');
  }

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );

export default awsRum;