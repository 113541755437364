import React from 'react';
import './styles/oleDialog.css';
import HTMLRenderer from './GlobalHTMLParser';

type DialogProps = {
    title: string;
    img?: string;
    subtitle: string;
    buttonText: string;
    showClose?: boolean;
    classname?: string;
    onClose?: () => void;
    onButtonClick?: () => void;
};

const Dialog: React.FC<DialogProps> = ({ title, img, subtitle, buttonText, showClose= true, classname, onClose, onButtonClick }) => {
  return (
    <div className={`dialog-overlay`}>
      <div className={`dialog-content ${classname}`}>
        <div className="dialog-header">
          <div className="close-button" onClick={onClose} style={showClose ? {display: 'block'} : {display: 'none'}}>X</div>
        </div>
        <div className={`dialog-body ${classname}`} style={showClose ? {paddingTop: '0px'} : {paddingTop: '18px'}}>
          <div className="icon"><img src={img || 'icon.png'} alt='icon' /></div>
          <h2>{title}</h2>
          <p><HTMLRenderer html={subtitle}/></p>
          <button style={buttonText ? {} : {display: 'none'}} onClick={onButtonClick}>{buttonText}</button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
