import React from 'react';

function HTMLRenderer({ html }: { html: string }) {
    const createMarkup = (html: string) => {
        return { __html: html };
    };

    const parseHTML = (html: string) => {
        return html;
    };

    return (
        <div dangerouslySetInnerHTML={createMarkup(parseHTML(html))} />
    );
}

export default HTMLRenderer;
