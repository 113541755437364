import React, { ReactNode, useEffect, useRef } from 'react';

interface LayoutProps {
  children: ReactNode;
  inactivityTime?: number;
  onInactive?: () => void;
}

const Layout = ({ children, inactivityTime = 60000, onInactive }: LayoutProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (onInactive) {
        onInactive();
      }
    }, inactivityTime);
  };

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
    // eslint-disable-next-line
  }, [inactivityTime]);

  return <div>{children}</div>;
};

export default Layout;
