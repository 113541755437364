import { REACT_APP_FLYWIRE_CODE_OTHER, REACT_APP_FLYWIRE_CODE_US } from "config";

interface SessionFormData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    country: string;
    state: string;
    zipCode: string;
  }
  
interface SessionResponse {
    action: string;
    showOmitPayment: boolean;
    language: string;
    idQuotation: string;
    idPerson: string;
    typePerson: string;
    idApplication: string;
    frequency: string;
    amount: number;
    currency: string;
    automaticApproval: boolean;
    formData: SessionFormData;
    redirectURL: string;
    subscriptionHash: string;
  }
  
  interface TransformedData {
    policyOwner: string | undefined;
    policyNumber: string | undefined;
    paymentFrequency: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    address: string | undefined;
    city: string | undefined;
    email: string | undefined;
    phone: string | undefined;
    amountStart: number | undefined;
    amount: number | undefined;
    country: string | undefined;
    state: string | undefined;
    zipCode?: string;
    currency: string | undefined;
    currencySymbol: string | undefined;
    idQuotation: string | undefined;
    idApplication: string | undefined;
    automaticApproval: boolean | undefined;
    showOmitPayment?: boolean;
    recurrent?: boolean;
    language?: string;
    idPerson?: string;
    frequency?: string;
    typeRecurrency?: string;
    payingForPolicy?: string;
    relationship?: string;
    recipientCode?: string;
    redirectUrl?: string;
}
  function showOmitPayment(sessionResponse: SessionResponse): boolean {
    if (!sessionResponse.automaticApproval && (sessionResponse.frequency === 'A' || sessionResponse.frequency === 'S')) {
      return true;
    }
    return false
  }
  function getTypeRecurrency(sessionResponse: SessionResponse): string {
    if (sessionResponse.frequency === 'M' || sessionResponse.frequency === 'Q') {
      return 'tokenization'
    }
    return 'optional_tokenization'
  }

  function transformObjectResponse(sessionResponse: SessionResponse): TransformedData {
    //console.log(sessionResponse)
    let flywireCode = REACT_APP_FLYWIRE_CODE_OTHER;
    if (sessionResponse?.formData.country === 'US') {
      flywireCode = REACT_APP_FLYWIRE_CODE_US
    }
    const transformedData: TransformedData = {
      amount: sessionResponse.automaticApproval ? sessionResponse?.amount : 0,
      currencySymbol: '$',
      policyOwner: `${sessionResponse?.formData.firstName + ' ' + sessionResponse?.formData.lastName}`,
      policyNumber: sessionResponse?.idApplication,
      paymentFrequency: sessionResponse?.frequency || '',
      firstName: sessionResponse?.formData.firstName || '',
      lastName: sessionResponse?.formData.lastName || '',
      address: sessionResponse?.formData.address || '',
      city: sessionResponse?.formData.city || '',
      email: sessionResponse?.formData.email || '',
      phone: sessionResponse?.formData.phone || '',
      amountStart: sessionResponse?.amount || 0,
      country: sessionResponse?.formData.country || '',
      idQuotation: sessionResponse?.idQuotation || '',
      idApplication: sessionResponse?.idApplication || '',
      automaticApproval: sessionResponse?.automaticApproval || false,
      showOmitPayment: showOmitPayment(sessionResponse),
      recurrent: true,
      language: sessionResponse?.language || '',
      idPerson: sessionResponse?.idPerson || '',
      currency: sessionResponse?.currency || '',
      state: sessionResponse?.formData.state || '',
      zipCode: sessionResponse?.formData.zipCode || '',
      frequency: sessionResponse?.frequency || '',
      typeRecurrency: getTypeRecurrency(sessionResponse),
      payingForPolicy: '1',
      relationship: '',
      recipientCode: flywireCode,
      redirectUrl: sessionResponse?.redirectURL || ''
    };
    return transformedData;
}

export type { SessionResponse, SessionFormData };
export { transformObjectResponse };