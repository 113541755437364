import React, { useState, useRef, useEffect } from 'react';
import './styles/oleDropdownCountry.css';
import {languageMessages} from './traductor/globalValidation';

const OleDropdownCountry = ({ 
    label, 
    placeholder, 
    value, 
    onChange = () => {}, 
    options = [], 
    style = {}, 
    error='', 
    width, 
    onError = () => {},
    language = 'en',
    validation = true,
    required = true,
    icon = '',
    filter = false,
    textFilter = 'Filter...',
    disabled = false,
}: {
    label: string;
    placeholder: string;
    value: any;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    options?: any[];
    style: any;
    error: string;
    width?: string;
    onError?: (error: string) => void;
    language?: string;
    validation?: boolean;
    required?: boolean;
    icon?: string;
    filter?: boolean;
    textFilter?: string;
    disabled?: boolean;
}) => {
const [isOpen, setIsOpen] = useState(false);
const [selectedValue, setSelectedValue] = useState(value);
const [selectedIcon, setSelectedIcon] = useState(icon);
const [errorNative, setErrorNative] = useState('');
const [filterText, setFilterText] = useState('');
const dropdownRef = useRef(null);

useEffect(() => {
    const handleClickOutside = (event: { target: any; }) => {
        if (dropdownRef.current && !((dropdownRef.current as HTMLElement).contains(event.target))) {
            setIsOpen(false);
            setFilterText('');
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [dropdownRef]);

const validate = (value: any) => {
    let validationError = '';
    if (required && value === '') {
        validationError = languageMessages(language, "required");
    } else if (required && options.find(opt => opt.value === value) === undefined) {
        validationError = languageMessages(language, "invalidOption");
    } else {
        validationError = '';
    }
    setErrorNative(validationError);
    onError(validationError);
}

useEffect(() => {
    if (value !== undefined) {
      setSelectedValue(value);
      setSelectedIcon(options.find(opt => opt.value === selectedValue)?.icon);
    }
    validate(value);
    // eslint-disable-next-line
}, [value, options, language]);

useEffect(() => {
    validate(value);
    // eslint-disable-next-line
}, []);

  const handleOptionClick = (option: { value: any; }) => {
    validate(option.value);
    setSelectedValue(option.value);
    setIsOpen(false);
    onChange(option.value);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const omitAccesnt = (text: string) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /*const filteredOptions = options
    .filter(option => 
      omitAccesnt(option.label.toLowerCase())
      .includes(omitAccesnt(filterText.toLowerCase())));*/
  const filteredOptions = options.filter(option => 
    omitAccesnt(option.label.toLowerCase()).includes(omitAccesnt(filterText.toLowerCase())) ||
    (option.alt && omitAccesnt(option.alt.toLowerCase()).includes(omitAccesnt(filterText.toLowerCase())))
  );
      

  return (
    <div className={`dropdown-wrapper open ${disabled ? 'disabled' : ''}`} ref={dropdownRef} style={{width: width}}>
      {label && <label className="dropdown-label">{label}</label>}
      <div 
        className={`dropdown-header ${errorNative ? 'error' : ''} ${disabled ? 'disabled' : ''}`} 
        onClick={() => !disabled && setIsOpen(!isOpen)} 
        style={style}
      >
        <span className={`dropdown-placeholder ${selectedValue ? 'has-value' : ''}`}>
          {selectedIcon && <img src={selectedIcon} alt='flag' width={'16px'} />}&nbsp;
          {selectedValue ? options.find(opt => opt.value === selectedValue)?.label : placeholder}
        </span>
        <span className="dropdown-arrow"></span>
      </div>
      {isOpen && (
        <ul className="dropdown-list-country">
          {filter && (
            <li className="dropdown-filter-country">
              <div className="search-container">
                <span className="search-icon"></span>
                <input 
                  type="text" 
                  placeholder={textFilter} 
                  value={filterText} 
                  onChange={handleFilterChange} 
                  disabled={disabled}
                />
              </div>
            </li>
          )}
          {filteredOptions.map((option) => (
            <li 
              key={option.alt} 
              className={`dropdown-option-country ${disabled ? 'disabled' : ''}`} 
              onClick={() => handleOptionClick(option)}
            >
              <div className='content'>
                <div>{option.icon && <img style={{marginBottom: '-3px'}} src={option.icon} alt={option.label} width={'16px'} />}</div>
                <div>{option.alt}</div>
                <div style={{width: '80px'}}>{option.label}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {validation && errorNative && <div style={{width: '100%', display: 'flex'}}><span className="error-message">{errorNative}</span></div>}
    </div>
  );
};

export default OleDropdownCountry;
