import React from 'react';

const FlywirePaymentForm: React.FC = () => {
  // Datos de la transacción y configuración
  const objectData = {
    automaticApproval: true,
    recurrent: true,
    amount: 100,
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@example.com',
    phone: '123456789',
    address: '123 Main St',
    city: 'New York',
    state: 'NY',
    country: 'US',
    zipCode: '10001',
    language: 'en',
    idApplication: 'INV12345',
    idQuotation: 'QTN12345',
    idPerson: 'ID123456',
    relationship: 'self',
    payingForPolicy: 'health',
    paymentIds: [1, 2, 3],
  };

  const session = 'YOUR_SESSION_ID';
  const flywireScriptUrl = 'https://checkout.flywire.com/flywire-payment.js';
  const flywireEnv = 'demo';
  const flywireRecipientCode = 'OLT';
  const callbackUrl = 'https://microservices.dev.oleinsurance.com/api-payments/v1/webhooks/flywire/';
  const callbackVersion = '2';
  let inExecution = false;

  // Cargar el script de Flywire
  const loadFlywireScript = (callback: () => void) => {
    const script = document.createElement('script');
    script.src = flywireScriptUrl;
    script.async = true;
    script.onload = callback;
    document.body.appendChild(script);
  };

  // Inicializar Flywire al cargar el componente
  const initializeFlywire = () => {
    //const divModal = '#embedTarget';

    const recurringType = objectData.automaticApproval
      ? (objectData.recurrent ? 'tokenization' : 'optional_tokenization')
      : 'tokenization';

    const stateSplit = objectData.state.split('-');
    const state = stateSplit[1] ? stateSplit[1].trim() : stateSplit[0].trim();

    const config = {
      env: flywireEnv,
      recipientCode: flywireRecipientCode,
      recurringType: recurringType,
      amount: objectData.amount,
      firstName: objectData.firstName,
      lastName: objectData.lastName,
      email: objectData.email,
      phone: objectData.phone,
      address: objectData.address,
      city: objectData.city,
      state: state,
      country: objectData.country,
      zip: objectData.zipCode,
      locale: objectData.language,
      recipientFields: {
        invoice_number: objectData.idApplication,
        session_id: session,
        quotation: objectData.idQuotation,
        document_number: objectData.idPerson,
        payer_relation: objectData.relationship,
        payer_type: objectData.payingForPolicy,
        payment_installments: objectData.paymentIds.join(','),
        recurrent: objectData.recurrent,
      },
      paymentOptionsConfig: {
        sort: [
          { type: ["credit_card", "online", "bank_transfer"] },
          { currency: ["foreign", "local"] },
        ]
      },
      onInvalidInput: (errors: any) => {
        console.error('Flywire[Validation errors]', errors);
      },
      requestPayerInfo: false,
      requestRecipientInfo: false,
      //embedTo: divModal,
      onCompleteCallback: (args: any) => {
        if (!inExecution) {
          inExecution = true;
          console.log("Payment completed", args);
        }
      },
      onCancel: () => {
        console.log('Flywire [cancelled event]', "Flywire Form was cancelled");
      },
      callbackId: objectData.idQuotation,
      callbackUrl: callbackUrl,
      callbackVersion: callbackVersion,
    };

    console.log('Flywire Configuration', JSON.stringify(config));

    const target = document.getElementById('embedTarget');
    if (target && target.firstChild) {
      while (target.firstChild) {
        target.removeChild(target.firstChild);
      }
    }

    // @ts-ignore
    if (window.FlywirePayment) {
      // @ts-ignore
      window.FlywirePayment.initiate(config);
    } else {
      loadFlywireScript(() => {
        // @ts-ignore
        window.FlywirePayment.initiate(config);
      });
    }
  };

  // useEffect para ejecutar al montar el componente
  /*useEffect(() => {
    initializeFlywire();
    // eslint-disable-next-line
  }, []);*/

  return (
    <div><button onClick={initializeFlywire}>Pay</button></div>
    /*<div style={{ width: '100%', height: '700px' }}>
      <div id="embedTarget" style={{ width: '100%', height: '100%' }}></div>
    </div>*/
  );
};

export default FlywirePaymentForm;
