// PhoneNumberInput.js
import React, { useEffect, useState } from 'react';
import { countries } from './resources/countries';
import './styles/phoneNumberInput.css';
import OleInputText from './OleInputText';
import { languageMessages } from './traductor/globalValidation';
import OleDropdownCountry from './oleDropdownCountry';

const PhoneNumberInput = ({
    value,
    language = 'en',
    onChange = () => {},
    onError = () => {},
}:{
    value: any;
    language?: string;
    onChange?: (value: string) => void;
    onError?: (error: string) => void;
}) => {
  const [errorNative, setErrorNative] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    function identifyCountry(phoneNumber:string) {
        const normalizedNumber = phoneNumber.replace(/[\s()-]/g, '');
        let matchedCountry = null;
      
        for (const country of countries) {
          if (normalizedNumber.startsWith(country.dialCode.replace(/[-\s]/g, ''))) {
            // Prioriza el país con el código de marcación más largo (más específico)
            if (!matchedCountry || country.dialCode.length > matchedCountry.dialCode.length) {
              matchedCountry = country;
            }
          }
        }
      
        return matchedCountry ? matchedCountry.dialCode : '';
    }

    //setPhoneNumber(value || '');
    const startsWithPlus = value?.startsWith('+');
    let phoneNumberInput = value;
    let selectedCountryDefault = '';
    if (startsWithPlus) {
        selectedCountryDefault = identifyCountry(value);
        phoneNumberInput = value.replace(selectedCountryDefault, '');
    }
    evaluateError(selectedCountryDefault, phoneNumberInput);
    setPhoneNumber(phoneNumberInput || '');
    setSelectedCountry(selectedCountryDefault);
  // eslint-disable-next-line
  }, [value]);

  const evaluateError = (country: string, number: string) => {
    let errorValidate = ''
    if (!country || country === ''){
        errorValidate = languageMessages(language, 'required')
    }
    if (!number || number === ''){
        errorValidate = languageMessages(language, 'required')
    }
    setErrorNative(errorValidate)
    onError(errorValidate);
    return errorValidate;
  }

  const evaluateFieldError = (value: string) => {
    let errorValidate = ''
    if (!value || value === ''){
        errorValidate = languageMessages(language, 'required')
    }
    setErrorNative(errorValidate)
    onError(errorValidate);
    return errorValidate;
  }

  const handleCountryChange = (value: any) => {
    evaluateFieldError(value);
    setSelectedCountry(value);
    onChange(value + phoneNumber);
  };

  const handlePhoneChange = (value: any) => {
    evaluateFieldError(value);
    setPhoneNumber(value);
    onChange(selectedCountry + value);
  };

  return (
    <div>
        <div className={`phone-input-container`}>
            <OleDropdownCountry
                label=""
                placeholder=""
                value={selectedCountry}
                onChange={handleCountryChange}
                options={countries.map(country => ({ value: country.dialCode, label: country.dialCode, icon: country.flag, alt: country.name }))}
                style={{}}
                error={errorNative}
                width='180px'
                validation={false}
                language={language}
                filter={true}
                onError={(error) => {setErrorNative(error); onError(error);}}
            />
            <OleInputText
                format='number'
                style={{}}
                label=""
                placeholder=""
                value={phoneNumber}
                onChange={handlePhoneChange}
                error={errorNative}
                language={language}
                className={errorNative ? 'error' : ''}
            />
        </div>
        {errorNative && <div style={{width: '100%', display: 'flex', marginTop: '-20px', marginBottom: '15px'}}><span className="error-message">{errorNative}</span></div>}
    </div>
  );
};

export default PhoneNumberInput;
