export const languageMessages: { [key: string]: { title: any; subtitle: any; step: any, flywire: any } } = {
    en: {
      title: {
        auto: "Congratulations! Your loved ones are now covered with Olé",
        regular: "Your application has been successfully sent!",
        pending: "You are one step away from getting your policy"
      },
      subtitle: {
        auto: "We will send your life insurance policy to <span>{param1}</span> in the next 24 hours.",
        regular: "We will send your evaluation confirmation to <span>{param1}</span> in the next 24 hours.",
        pending: "Make the bank transfer to complete the process. Once your payment is confirmed, we will send your Olé life insurance policy to <span>{param1}</span>"
      },
      step: {
        auto: "ASA-steps-EN.png",
        regular: "REG-steps-EN.png",
        pending: "PEN-steps-EN.png"
      },
      flywire: {
        title: "On your statement the name Flywire will appear in the description.",
        subtitle: "<div class='dialog__succes_flywire_subtitle'>Flywire is an authorized payment processing partner for Ole Life.</div><br/>If you have any questions on your statement, please reach out to <b>",
        disclaimer: "On your statement you may see the names <b>“OIG”</b> or <b>“Flywire”</b> as the charge description. If you have any questions on your statement, please reach out to <b>service@olelife.com</b>"
      }
    },
    es: {
        title: {
            auto: "¡Felicitaciones! Tus seres queridos ya están protegidos con Olé",
            regular: "¡Tu solicitud ha sido enviada con éxito!",
            pending: "Estás a un paso de obtener tu póliza"
        },
        subtitle: {
            auto: 'En las próximas 24 horas enviaremos tu póliza de vida a <span>{param1}<span>',
            regular: 'En las próximas 24 horas enviaremos la confirmación de tu evaluación a <span>{param1}</span>',
            pending: 'Realiza la transferencia bancaria para completar el proceso. Una vez confirmado el pago, enviaremos tu póliza de seguro de vida Olé <span>{param1}</span>'
        },
        step: {
            auto: "ASA-steps-ES.png",
            regular: "REG-steps-ES.png",
            pending: "PEN-steps-ES.png"
        },
        flywire: {
            title: "El nombre Flywire aparecerá en la descripción de tu estado de cuenta bancario.",
            subtitle: "<div class='dialog__succes_flywire_subtitle'>Flywire es un socio autorizado de procesamiento de pagos de Olé Life.</div><br/>Si tienes alguna pregunta sobre tu estado de cuenta, comunícate con <b>servicio@olelife.com</b>",
            disclaimer: "En tu estado de cuenta podrás ver los nombres <b>“OIG”</b> o <b>“Flywire”</b> como descripción del cargo. Si tienes alguna pregunta sobre tu estado de cuenta, comunícate con <b>servicio@olelife.com</b>"
        }
    },
    pt: {
        title: {
            auto: 'Parabéns! Seus entes queridos já estão protegidos com Olé',
            regular: 'Sua solicitação foi enviada com sucesso!',
            pending: 'Você está a um passo de obter sua apólice'
        },
        subtitle: {
            auto: 'Nas próximas 24 horas enviaremos sua apólice de vida para <span>{param1}</span>',
            regular: 'Nas próximas 24 horas enviaremos a confirmação da sua avaliação para <span>{param1}</span>',
            pending: 'Faça a transferência bancária para concluir o processo. Assim que o pagamento for confirmado, enviaremos sua apólice de seguro de vida Olé para <span>{param1}</span>'
        },
        step: {
            auto: "ASA-steps-PT.png",
            regular: "REG-steps-PT.png",
            pending: "PEN-steps-PT.png"
        },
        flywire: {
            title: "O nome Flywire aparecerá na descrição do seu estado de conta bancária.",
            subtitle: "<div class='dialog__succes_flywire_subtitle'>Flywire é um sócio autorizado para processamento de pagamentos da Olé Life.</div><br/>Se você tiver alguma dúvida sobre seu estado de conta, comunique-se com <b>servicio@olelife.com</b>",
            disclaimer: "Em seu extrato, você poderá ver os nomes <b>“OIG”</b> ou <b>“Flywire”</b> como descrição da cobrança. Se você tiver alguma dúvida sobre seu extrato, entre em contato com <b>service@olelife.com</b>"
        }
    },
};