export const languageMessages = (language:string, key: string) => {
    const messages: any = {
        en: {
            titleStep1: 'Select the premiums you want to pay',
            titleStep2: 'Make a payment',
            policyInformation: 'Policy Information',
            payerInformation: 'Payer Information',
            insured: 'Insured:',
            policyOwner: 'Policy Owner:',
            policyNumber: 'Policy Number:',
            plan: 'Plan:',
            term: 'Term:',
            premium: 'Premium:',
            paymentFrequency: 'Payment Frequency',
            selectPremiums: 'Select the premiums you want to pay',
            recurringPaymentsAuthorization: 'By submitting my information, I authorize recurring payments as per the modal fee I chose in my application.',
            infoUsageNotice: 'Olé Insurance Group Corp I.I. will only use your information for the purposes of payment of this term policy.',
            continueButton: 'Continue',
            tableTitle1: 'Period',
            tableTitle2: 'Due Date',
            tableTitle3: 'Premium',
            totalPayment: 'Total Payment',
            payNowAprroved: 'Pay now',
            updateCard: 'Only update card',
            authorizeMessage: 'By updating a debit or credit card, you authorize recurring payments for your life insurance payment.',
            frequency: {
                M: 'Months',
                A: 'Years',
                S: 'Semesters',
                Q: 'Quarters',
            },
            period: {
                M: 'Monthly Premium',
                A: 'Annual Premium',
                S: 'Semi-Annual Premium',
                Q: 'Quarterly Premium',
            },
            errorMessages: {
                payment: {
                    title: 'There was a problem with your payment',
                    subtitle: 'Please try a different payment method or review your card information.',
                    buttonText: 'Try again'
                },
                session: {
                    title: 'There was a problem getting your information',
                    subtitle: 'Please try again.',
                    buttonText: 'Try again'
                },
                notPendingPayments: {
                    title: 'Congratulations!',
                    subtitle: 'You have paid all your installments.',
                    buttonText: ''
                }
            }
        },
        es: {
            titleStep1: 'Seleccione las primas que desea pagar',
            titleStep2: 'Realizar un pago',
            policyInformation: 'Información de póliza',
            payerInformation: 'Información del pagador',
            insured: 'Asegurado:',
            policyOwner: 'Titular de póliza:',
            policyNumber: 'Número de póliza:',
            plan: 'Plan:',
            term: 'Término:',
            premium: 'Prima:',
            paymentFrequency: 'Frecuencia de pago',
            selectPremiums: 'Seleccione las primas que desea pagar',
            recurringPaymentsAuthorization: 'Al enviar mi información, yo autorizo pagos recurrentes de acuerdo con la tarifa que elegí en mi solicitud.',
            infoUsageNotice: 'Olé Insurance Group Corp I.I. solo utilizará tu información con fines de pago para esta póliza.',
            continueButton: 'Continuar',
            tableTitle1: 'Periodo',
            tableTitle2: 'Fecha de vencimiento',
            tableTitle3: 'Prima',
            totalPayment: 'Pago total',
            payNowAprroved: 'Pagar ahora',
            updateCard: 'Actualizar tarjeta',
            authorizeMessage: 'Al actualizar tu tarjeta de débito o crédito, estás autorizando los pagos recurrentes para tu seguro de vida.',
            frequency: {
                M: 'Meses',
                A: 'Años',
                S: 'Semestres',
                Q: 'Trimestres',
            },
            period: {
                M: 'Prima mensual',
                A: 'Prima anual',
                S: 'Prima semestral',
                Q: 'Prima trimestral',
            },
            errorMessages: {
                payment: {
                    title: 'Hubo un problema con su pago',
                    subtitle: 'Por favor, intente un método de pago diferente o revise la información de su tarjeta.',
                    buttonText: 'Intentar de nuevo'
                },
                session: {
                    title: 'Hubo un problema para obtener su información',
                    subtitle: 'Por favor, intente de nuevo.',
                    buttonText: 'Intentar de nuevo'
                },
                notPendingPayments: {
                    title: '¡Felicidades!',
                    subtitle: 'Has realizado el pago de todas tus cuotas.',
                    buttonText: ''
                }
            }
        },
        pt: {
            titleStep1: 'Selecione os prêmios que deseja pagar',
            titleStep2: 'Faça um pagamento',
            policyInformation: 'Informações sobre apólice',
            payerInformation: 'Informações do pagador',
            insured: 'Segurado:',
            policyOwner: 'Titular da apólice:',
            policyNumber: 'Número da apólice:',
            plan: 'Plano:',
            term: 'Termo:',
            premium: 'Primo:',
            paymentFrequency: 'Data de vencimento',
            selectPremiums: 'Selecione os prêmios que deseja pagar',
            recurringPaymentsAuthorization: 'Ao submeter esta informação, autorizo pagamentos recorrentes de acordo com a taxa que escolhi na minha candidatura.',
            infoUsageNotice: 'Olé Insurance Group Corp I.I. Ele usará suas informações apenas para fins de pagamento desta política.',
            continueButton: 'Continuar',
            tableTitle1: 'Período',
            tableTitle2: 'Data de vencimento',
            tableTitle3: 'Primo',
            totalPayment: 'Pagamento total',
            payNowAprroved: 'Pagar agora',
            updateCard: 'Atualizar apenas o cartão',
            authorizeMessage: 'Ao atualizar sua tarjeta de débito ou crédito, você estará autorizando os pagamentos recorrentes para seu seguro de vida.',
            frequency: {
                M: 'Meses',
                A: 'Anos',
                S: 'Semestres',
                Q: 'Trimestres',
            },
            period: {
                M: 'Primo mensal',
                A: 'Primo anual',
                S: 'Primo semestral',
                Q: 'Primo trimestral',
            },
            errorMessages: {
                payment: {
                    title: 'Houve um problema com o seu pagamento',
                    subtitle: 'Por favor, tente um método de pagamento diferente ou revise as informações do seu cartão.',
                    buttonText: 'Tente novamente'
                },
                session: {
                    title: 'Houve um problema ao obter suas informações',
                    subtitle: 'Por favor, tente novamente.',
                    buttonText: 'Tente novamente'
                },
                notPendingPayments: {
                    title: 'Parabéns!',
                    subtitle: 'Você concluiu o pagamento de todas as suas encomendas',
                    buttonText: ''
                }
            }
        }
    };
    return messages[language][key];
};