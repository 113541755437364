export const languageMessages: { [key: string]: { title1: string; title2: string, title3:string, subtitle1: string; subtitle2: string, subtitle3: string, subtitle4: string, data1: string; data2: string; data3: string; data4: string, data5: string, data6: string, button1: string, button2: string } } = {
    en: {
      title1: 'thank you for your payment',
      title2: 'your card was updated!',
      title3: 'Do not forget to make the bank transfer to complete the proccess before the due date',
      subtitle1: 'We have received your',
      subtitle2: 'payment for your life insurance policy.',
      subtitle3: 'We have added a new card as a payment method for your policy.',
      subtitle4: 'Once your payment is confirmed, we will send the receipt to your email within the next 3 business days.',
      data1: 'Policy Number',
      data2: 'Date of Payment',
      data3: 'Total Paid',
      data4: 'Debit or Credit Card',
      data5: 'Card Holder',
      data6: 'Next Payment Date',
      button1: 'New payment',
      button2: 'Make a payment now',
    },
    es: {
        title1: 'gracias por tu pago',
        title2: '¡tu tarjeta ha sido actualizada!',
        title3: 'No olvides realizar la transferencia bancaria para completar el proceso antes de la fecha límite',
        subtitle1: 'Hemos recibido tu pago de',
        subtitle2: 'para tu póliza de seguro de vida.',
        subtitle3: 'Hemos agregado una nueva tarjeta como método de pago para tu póliza.',
        subtitle4: 'Una vez confirmado el pago, te enviaremos el recibo a tu correo electrónico en los próximos 3 días hábiles.',
        data1: 'Número de póliza',
        data2: 'Fecha de pago',
        data3: 'Pago total',
        data4: 'Tarjeta de débito o crédito',
        data5: 'Titular de la tarjeta',
        data6: 'Próxima fecha de pago',
        button1: 'Nuevo pago',
        button2: 'Realizar un pago ahora',
    },
    pt: {
        title1: 'obrigado por seu pagamento',
        title2: 'seu cartão foi atualizado!',
        title3: 'Não se esqueça de fazer a transferência bancária para concluir o processo antes do prazo',
        subtitle1: 'Recebemos seu pagamento de',
        subtitle2: 'pela sua apólice de seguro de vida.',
        subtitle3: 'Adicionamos um novo cartão como método de pagamento para sua apólice.',
        subtitle4: 'Assim que o pagamento for confirmado, enviaremos o recibo para seu e-mail nos próximos 3 dias úteis.',
        data1: 'Número da apólice',
        data2: 'Data de pagamento',
        data3: 'Total pago',
        data4: 'Cartão de débito ou crédito',
        data5: 'Titular do cartão',
        data6: 'Próxima data de pagamento',
        button1: 'Novo pagamento',
        button2: 'Faça um pagamento agora',
    },
  };