import React, {useContext} from 'react';
import OleCountrySelect from './OleCountrySelect';
import LinkOptionContext from '../contexts/LinkOptionContext';
import { useState } from 'react';
import './styles/header.css'

function Header ({ paymentType, language, showLogo }: { paymentType: string, language: string, showLogo: boolean }) {
    const [selectedOption, setSelectedOption] = useState(localStorage.getItem('selectedLinkOption') || 'payment');
    const { changeOption } = useContext(LinkOptionContext);
    const hideStyle = { display: 'none' };
    const showStyle = { display: 'block', backgroundImage: 'url("logo_ole.svg")'};

    const handleChangeOption = (option: React.SetStateAction<string>) => {
        setSelectedOption(option);
        changeOption(option);
    };

    return (
        <div className={'app-header-' + paymentType}>
            <div className='main'>
                <div className={'logo-' + paymentType} style={ showLogo ? showStyle : hideStyle }></div>
                <div className={'countries-' + paymentType}><OleCountrySelect defaultValue={language} /></div>
            </div>
            <div className={'options-' + paymentType}>
                {language === 'en' && (
                <>
                    <div className={selectedOption === 'payment' ? 'option-payment active' : 'option-payment inactive'} onClick={() => handleChangeOption('payment')}>Make a Payment</div>
                    <div className={selectedOption === 'update' ? 'option-update active' : 'option-update inactive'} onClick={() => handleChangeOption('update')}>Only update Card</div>
                </>)}
                {language === 'es' && (
                <>
                    <div className={selectedOption === 'payment' ? 'option-payment active' : 'option-payment inactive'} onClick={() => handleChangeOption('payment')}>Realizar pago</div>
                    <div className={selectedOption === 'update' ? 'option-update active' : 'option-update inactive'} onClick={() => handleChangeOption('update')}>Actualizar tarjeta</div>
                </>)}
                {language === 'pt' && (
                <>
                    <div className={selectedOption === 'payment' ? 'option-payment active' : 'option-payment inactive'} onClick={() => handleChangeOption('payment')}>Fazer pagamento</div>
                    <div className={selectedOption === 'update' ? 'option-update active' : 'option-update inactive'} onClick={() => handleChangeOption('update')}>Atualizar apenas o cartão</div>
                </>)}
            </div>
        </div>
    )
}

export default Header;